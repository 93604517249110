import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssessmentPublishedModal } from '../AssessmentPublishedModal/AssessmentPublishedModal';
import { CompletedAssessments } from '../CompletedAssessments/CompletedAssessments';
import '../AssessmentsSummary/AssessmentsSummary.scss';
import { ApplicationState } from '../../types/applicationState';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { RecentlyCreatedAssessments } from '../RecentlyCreatedAssessments/RecentlyCreatedAssessments';
import { saveSelectedAssessmentTab } from '../../store/assessments/assessmentsSlice';
import { AssessmentTabs } from '../../store/assessments/assessmentsState';
import AssessmentsTemplates from '../AssessmentsTemplates/AssessmentsTemplates';
import { ErrorBoundary } from '@sentry/react';
import { PaymentLandingPageChecker } from '../../features/pricing/PaymentLandingPageChecker/PaymentLandingPageChecker';

export const AssessmentsOut: React.FC = () => {
  const dispatch = useDispatch();

  const isFetchingAssessments = useSelector(
    (state: ApplicationState) => state.assessments.isFetchingAssessments
  );

  useEffect(() => {
    dispatch(saveSelectedAssessmentTab(AssessmentTabs.Outgoing));
  }, []);

  if (isFetchingAssessments) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PaymentLandingPageChecker type="assessments">
        <ErrorBoundary
          fallback={
            <div className="adoptechErrorBoundary">
              An unexpected error has happened while retrieving outgoing
              assessments. Please contact support!
            </div>
          }
        >
          <div className="assessmentsSummary">
            <AssessmentsTemplates />
            <RecentlyCreatedAssessments />
            <CompletedAssessments assessmentTab={AssessmentTabs.Outgoing} />
          </div>
          <AssessmentPublishedModal />
        </ErrorBoundary>
      </PaymentLandingPageChecker>
    </>
  );
};
