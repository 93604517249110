/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DataRoomEntityMovePayload
 */
export interface DataRoomEntityMovePayload {
  /**
   *
   * @type {Array<string>}
   * @memberof DataRoomEntityMovePayload
   */
  entitiesIds: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityMovePayload
   */
  parentEntityId: string;
}

export function DataRoomEntityMovePayloadFromJSON(
  json: any
): DataRoomEntityMovePayload {
  return DataRoomEntityMovePayloadFromJSONTyped(json, false);
}

export function DataRoomEntityMovePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DataRoomEntityMovePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entitiesIds: json['entities_ids'],
    parentEntityId: json['parent_entity_id'],
  };
}

export function DataRoomEntityMovePayloadToJSON(
  value?: DataRoomEntityMovePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entities_ids: value.entitiesIds,
    parent_entity_id: value.parentEntityId,
  };
}
