/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Progress,
  ProgressFromJSON,
  ProgressFromJSONTyped,
  ProgressToJSON,
  Question,
  QuestionFromJSON,
  QuestionFromJSONTyped,
  QuestionToJSON,
} from './';

/**
 *
 * @export
 * @interface QuestionGroup
 */
export interface QuestionGroup {
  /**
   *
   * @type {string}
   * @memberof QuestionGroup
   */
  questionGroupId: string;
  /**
   *
   * @type {string}
   * @memberof QuestionGroup
   */
  questionGroupName: string;
  /**
   *
   * @type {Progress}
   * @memberof QuestionGroup
   */
  progress: Progress;
  /**
   *
   * @type {Array<Question>}
   * @memberof QuestionGroup
   */
  questions: Array<Question>;
}

export function QuestionGroupFromJSON(json: any): QuestionGroup {
  return QuestionGroupFromJSONTyped(json, false);
}

export function QuestionGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    questionGroupId: json['question_group_id'],
    questionGroupName: json['question_group_name'],
    progress: ProgressFromJSON(json['progress']),
    questions: (json['questions'] as Array<any>).map(QuestionFromJSON),
  };
}

export function QuestionGroupToJSON(value?: QuestionGroup | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question_group_id: value.questionGroupId,
    question_group_name: value.questionGroupName,
    progress: ProgressToJSON(value.progress),
    questions: (value.questions as Array<any>).map(QuestionToJSON),
  };
}
