/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LegalRegisterModelStats
 */
export interface LegalRegisterModelStats {
  /**
   *
   * @type {number}
   * @memberof LegalRegisterModelStats
   */
  legislationsCount?: number;
  /**
   *
   * @type {number}
   * @memberof LegalRegisterModelStats
   */
  actionsCount?: number;
}

export function LegalRegisterModelStatsFromJSON(
  json: any
): LegalRegisterModelStats {
  return LegalRegisterModelStatsFromJSONTyped(json, false);
}

export function LegalRegisterModelStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LegalRegisterModelStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    legislationsCount: !exists(json, 'legislations_count')
      ? undefined
      : json['legislations_count'],
    actionsCount: !exists(json, 'actions_count')
      ? undefined
      : json['actions_count'],
  };
}

export function LegalRegisterModelStatsToJSON(
  value?: LegalRegisterModelStats | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    legislations_count: value.legislationsCount,
    actions_count: value.actionsCount,
  };
}
