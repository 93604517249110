import React from 'react';
import './MainMenuFooter.scss';
import moment from 'moment';

const MainMenuFooter: React.FC = () => {
  return (
    <div className="mainMenuFooter">
      &copy; {moment().year()} Adoptech Limited
    </div>
  );
};

export default MainMenuFooter;
