import { VendorUser } from '../swagger';
import { sort } from './sort';

export const sortByOwner = (
  a: VendorUser = {},
  b: VendorUser = {},
  direction: 1 | -1
) => {
  return sort(a.fullName || '', b.fullName || '', direction);
};
