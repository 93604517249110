import React, { useMemo } from 'react';
import { Question } from '../../swagger';
import { AdoptechCheckbox } from '../AdoptechCheckbox/AdoptechCheckbox';
import './CheckboxesAnswer.scss';
import { CommonAnswerType } from '../../types/CommonAnswerType';
import { OnChangeAnswerOptions } from '../AnswerFormControl/AnswerFormControl';
import { policyAnswerCommonFields } from '../../functions/policyAnswerCommonFields';

interface CheckboxesAnswerProps {
  answers: CommonAnswerType[];
  disabled?: boolean;
  readonly?: boolean;
  onChange: (value: CommonAnswerType[], options: OnChangeAnswerOptions) => void;
  question: Question;
  indexProperty: 'name' | 'propertyName';
  usingNewSurveyStructure: boolean;
}

export const CheckboxesAnswer: React.FC<CheckboxesAnswerProps> = props => {
  const calculateAllState = (checkboxAnswers: CommonAnswerType[]): boolean => {
    const hasAtLeastOneUncheckedValue = checkboxAnswers.some(answer => {
      const propertyName = answer[props.indexProperty].toString();
      return (
        answer.value === 'f' &&
        !propertyName.endsWith('.other') &&
        !propertyName.endsWith('.none')
      );
    });

    return !hasAtLeastOneUncheckedValue;
  };

  // Use only one "piece of parent state": `props.answers` to read, convert it temporary to `answers` and update with `onChange`.
  // But: "props.answers" is always empty array for AgreementQuestion, TableAnswerEditModal and not empty in PolicyQuestion, AssessmentQuestion
  // => use props.question.questionRows for compatibility
  const parentAnswers = useMemo(
    () =>
      props.answers.length > 0
        ? props.answers
        : (props.question.questionRows
            .map(question => question.answer)
            .filter(answer => answer) as CommonAnswerType[]),
    [props.answers, props.question.questionRows]
  );
  const answers: CommonAnswerType[] = props.question.questionRows.map(
    question => {
      let value = 'f';
      let previousAnswer: CommonAnswerType;
      const rowName = question[props.indexProperty];

      if (parentAnswers) {
        previousAnswer = parentAnswers.find(
          a => a[props.indexProperty] === rowName
        );
        if (previousAnswer) value = previousAnswer.value;
      }

      const fields = {
        [props.indexProperty]: rowName,
        ...policyAnswerCommonFields(props),
        value,
      };

      if (props.usingNewSurveyStructure) {
        return {
          ...fields,
          questionRowId: question.id,
          id: question.answer?.id,
        };
      }

      return fields;
    }
  );

  const allSelected = calculateAllState(answers);

  const handleClick = (propertyName: string) => {
    const newAnswers = [...answers];
    const currentAnswer = newAnswers.find(
      na => na[props.indexProperty] === propertyName
    );
    currentAnswer.value = currentAnswer.value === 't' ? 'f' : 't';

    if (
      !(
        currentAnswer[props.indexProperty].endsWith('.none') ||
        currentAnswer[props.indexProperty].endsWith('.other')
      )
    ) {
      newAnswers
        .filter(answer => answer[props.indexProperty].endsWith('.none'))
        .forEach(answer => (answer.value = 'f'));
    }

    currentAnswer.closestQuestion =
      policyAnswerCommonFields(props).closestQuestion;
    props.onChange(newAnswers, { isCheckboxAnswer: true });
  };

  const handleSelectAll = () => {
    const newAnswers = [...answers];
    props.question.questionRows.forEach(qr => {
      const answer = newAnswers.find(
        na => na[props.indexProperty] === qr[props.indexProperty]
      );

      if (answer[props.indexProperty].endsWith('.none')) {
        answer.value = 'f';
        return;
      }
      answer.value = allSelected ? 'f' : 't';
      answer.closestQuestion = policyAnswerCommonFields(props).closestQuestion;
    });

    props.onChange(newAnswers, { isCheckboxAnswer: true });
  };
  return (
    <div className="checkboxesAnswer">
      <div className="checkboxesAnswer--boxes">
        <AdoptechCheckbox
          checked={allSelected}
          className="checkboxesAnswer--selectAll"
          disabled={props.disabled || props.readonly}
          id={`${props.question.id}--selectAll}`}
          onChange={handleSelectAll}
          label="Select all"
        />
        {props.question.questionRows.map(r => {
          const questionRowAnswerValue = r[props.indexProperty];

          return (
            <AdoptechCheckbox
              id={questionRowAnswerValue}
              key={questionRowAnswerValue}
              checked={
                answers.findIndex(
                  v =>
                    v[props.indexProperty] === questionRowAnswerValue &&
                    v.value === 't'
                ) > -1
              }
              disabled={props.disabled || props.readonly}
              onChange={() => {
                handleClick(questionRowAnswerValue);
              }}
              label={r.rowText}
            />
          );
        })}
      </div>
    </div>
  );
};
