import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ShareableType, VendorPolicyDetails } from '../../swagger';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { PolicyProgress } from '../PolicyProgress/PolicyProgress';
import AdoptechOverflowLine from '../AdoptechOverflowLine/AdoptechOverflowLine';
import { MeatballMenu } from '../MeatballMenu/MeatballMenu';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { AccessObject } from '../../types/accessObject';
import {
  hasFeature,
  mapShareFeaturesToAccessLevels,
} from '../../functions/access';
import { showShareModal } from '../../store/vendors/vendorsSlice';
import { openVendorPolicyPdf } from '../../store/policies/policiesThunks';
import { PdfOpenMode } from '../../types/pdfOpenMode';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { isVendorPolicyFilled } from '../../functions/isVendorPolicyFilled';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { policyPageItemRouteURL } from '../Routes/Routes';

export const PolicyGetLinkInfo =
  '<b>Restricted</b> Only people with Security Manager role will be able to open this link.';
interface InProgressPolicyRowProps {
  policy: VendorPolicyDetails;
  baseCss: string;
  baseTableCss: string;
  setSelectedPolicyId: (id: string) => void;
  setShowDeleteModal: (show: boolean) => void;
}

export const InProgressPolicyRow: React.FC<InProgressPolicyRowProps> = ({
  policy,
  baseCss,
  baseTableCss,
  setSelectedPolicyId,
  setShowDeleteModal,
}) => {
  const dispatch = useDispatch();
  const isFilledIn = isVendorPolicyFilled(policy);

  const handlePolicyClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    e.stopPropagation();
    dispatch(push(policyPageItemRouteURL(policy.id)));
  };
  return (
    <div
      onClick={handlePolicyClick}
      className={
        baseCss + ' ' + baseTableCss + '--row ' + baseTableCss + '--slim'
      }
    >
      <div>{policy.name}</div>
      <div>{policy.categoryName}</div>
      <AdoptechOverflowLine>
        <UserAvatar showTooltip hideText user={policy.owner} size="tag" />
      </AdoptechOverflowLine>
      <PolicyProgress id={policy.id} />
      <div className="adoptechTable--actions">
        <MeatballMenu leftHandPointer>
          <Dropdown.Item onClick={handlePolicyClick}>
            <FontAwesomeIcon className="meatballMenu--icon" icon={faPen} />
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            disabled={!hasFeature(AccessObject.share, policy?.access)}
            onClick={e => {
              e.stopPropagation();
              dispatch(
                showShareModal({
                  shareableId: policy.id,
                  showOnlyGetLink: true,
                  getLinkInfo: PolicyGetLinkInfo,
                  shareableType: ShareableType.VendorPolicy,
                  accessLevels: mapShareFeaturesToAccessLevels(
                    policy.access.feature
                  ),
                  relativePath: policy.relativePath,
                })
              );
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faLink} />
            Get link
          </Dropdown.Item>
          {isFilledIn && (
            <Dropdown.Item
              className="meatballMenu--item"
              onClick={e => {
                e.stopPropagation();
                dispatch(openVendorPolicyPdf(policy.id, PdfOpenMode.Preview));
              }}
            >
              <FontAwesomeIcon className="meatballMenu--icon" icon={faEye} />
              Preview
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={e => {
              e.stopPropagation();
              setSelectedPolicyId(policy.id);
              setShowDeleteModal(true);
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faTrashAlt} />
            Delete
          </Dropdown.Item>
        </MeatballMenu>
      </div>
    </div>
  );
};
