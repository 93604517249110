import { createSelector } from '@reduxjs/toolkit';
import { sort } from '../../../functions/sort';
import { sortByDate } from '../../../functions/sortByDate';
import { ApplicationState } from '../../../types/applicationState';
import { ColumnSort } from '../../../types/columnSort';
import { Grid } from '../../../types/grid';
import { selectSortSettings } from '../../../selectors/selectSortSettings';
import { CalendarItemModel } from '../../../swagger/models/CalendarItemModel';

export const sortCalendarItems = (
  a: CalendarItemModel,
  b: CalendarItemModel,
  columnSort: ColumnSort
) => {
  const typedColumn = columnSort.name as keyof CalendarItemModel;

  if (['date'].includes(typedColumn)) {
    const dateColumn = typedColumn as 'date';
    return sortByDate(a[dateColumn], b[dateColumn], columnSort.direction);
  }

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};
export const isAction = (item: CalendarItemModel) =>
  item.type === 'VendorAction';
export const isEvent = (item: CalendarItemModel) => item.type === 'VendorEvent';
export const isCorrectiveAction = (item: CalendarItemModel) =>
  item.type === 'CorrectiveAction';
export const isIncident = (item: CalendarItemModel) =>
  item.type === 'IncidentEvent';
export const selectCalendarHSItems = createSelector(
  (state: ApplicationState) => state.calendar.items,
  selectSortSettings(Grid.Calendar),
  (items: CalendarItemModel[], sortSetting) => {
    return [...items].sort((a, b) =>
      sortCalendarItems(a, b, sortSetting.columnSort)
    );
  }
);
