/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CompanySector
 */
export interface CompanySector {
  /**
   *
   * @type {string}
   * @memberof CompanySector
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySector
   */
  source?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySector
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySector
   */
  description?: string;
}

export function CompanySectorFromJSON(json: any): CompanySector {
  return CompanySectorFromJSONTyped(json, false);
}

export function CompanySectorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanySector {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    source: !exists(json, 'source') ? undefined : json['source'],
    code: !exists(json, 'code') ? undefined : json['code'],
    description: !exists(json, 'description') ? undefined : json['description'],
  };
}

export function CompanySectorToJSON(value?: CompanySector | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    source: value.source,
    code: value.code,
    description: value.description,
  };
}
