/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReminderCreatePayloadReminder
 */
export interface ReminderCreatePayloadReminder {
  /**
   *
   * @type {string}
   * @memberof ReminderCreatePayloadReminder
   */
  type: ReminderCreatePayloadReminderTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ReminderCreatePayloadReminder
   */
  relatedToId: string | null;
  /**
   *
   * @type {string}
   * @memberof ReminderCreatePayloadReminder
   */
  relatedToType: ReminderCreatePayloadReminderRelatedToTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ReminderCreatePayloadReminder
   */
  remindAt: string;
}

export function ReminderCreatePayloadReminderFromJSON(
  json: any
): ReminderCreatePayloadReminder {
  return ReminderCreatePayloadReminderFromJSONTyped(json, false);
}

export function ReminderCreatePayloadReminderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReminderCreatePayloadReminder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    relatedToId: json['related_to_id'],
    relatedToType: json['related_to_type'],
    remindAt: json['remind_at'],
  };
}

export function ReminderCreatePayloadReminderToJSON(
  value?: ReminderCreatePayloadReminder | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    related_to_id: value.relatedToId,
    related_to_type: value.relatedToType,
    remind_at: value.remindAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ReminderCreatePayloadReminderTypeEnum {
  ReminderReview = 'Reminder::Review',
}
/**
 * @export
 * @enum {string}
 */
export enum ReminderCreatePayloadReminderRelatedToTypeEnum {
  VendorDocument = 'VendorDocument',
  VendorPolicy = 'VendorPolicy',
  Agreement = 'Agreement',
}
