/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedTaskRecurring,
  SharedTaskRecurringFromJSON,
  SharedTaskRecurringFromJSONTyped,
  SharedTaskRecurringToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface TaskModel
 */
export interface TaskModel {
  /**
   *
   * @type {string}
   * @memberof TaskModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TaskModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TaskModel
   */
  identifier?: string;
  /**
   *
   * @type {string}
   * @memberof TaskModel
   */
  status?: TaskModelStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TaskModel
   */
  description: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TaskModel
   */
  custom?: boolean;
  /**
   *
   * @type {string}
   * @memberof TaskModel
   */
  descriptionAsHtml: string | null;
  /**
   *
   * @type {VendorUser}
   * @memberof TaskModel
   */
  assignee?: VendorUser;
  /**
   *
   * @type {string}
   * @memberof TaskModel
   */
  reviewDate?: string | null;
  /**
   *
   * @type {SharedTaskRecurring}
   * @memberof TaskModel
   */
  recurring?: SharedTaskRecurring;
  /**
   *
   * @type {string}
   * @memberof TaskModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof TaskModel
   */
  updatedAt: string;
}

export function TaskModelFromJSON(json: any): TaskModel {
  return TaskModelFromJSONTyped(json, false);
}

export function TaskModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TaskModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    identifier: !exists(json, 'identifier') ? undefined : json['identifier'],
    status: !exists(json, 'status') ? undefined : json['status'],
    description: json['description'],
    custom: !exists(json, 'custom') ? undefined : json['custom'],
    descriptionAsHtml: json['description_as_html'],
    assignee: !exists(json, 'assignee')
      ? undefined
      : VendorUserFromJSON(json['assignee']),
    reviewDate: !exists(json, 'review_date') ? undefined : json['review_date'],
    recurring: !exists(json, 'recurring')
      ? undefined
      : SharedTaskRecurringFromJSON(json['recurring']),
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function TaskModelToJSON(value?: TaskModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    identifier: value.identifier,
    status: value.status,
    description: value.description,
    custom: value.custom,
    description_as_html: value.descriptionAsHtml,
    assignee: VendorUserToJSON(value.assignee),
    review_date: value.reviewDate,
    recurring: SharedTaskRecurringToJSON(value.recurring),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum TaskModelStatusEnum {
  Pending = 'pending',
  Compliant = 'compliant',
  Failed = 'failed',
}
