import { faBallotCheck } from '@fortawesome/pro-light-svg-icons/faBallotCheck';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import './AdoptechTile.scss';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';

interface AdoptechTileProps {
  name: string;
  description: string;
  icon?: IconDefinition;
  onClick: () => void;
  size: 'small' | 'medium' | 'large';
  buttonText: string;
  customTitle?: React.ReactNode;
  available?: boolean;
}
export const AdoptechTile: React.FC<AdoptechTileProps> = ({
  name,
  description,
  icon,
  onClick,
  size,
  buttonText,
  customTitle,
  available,
}) => {
  const baseCss = 'adoptechTile';
  return (
    <div
      className={classNames(baseCss, {
        'adoptechTile--medium': size === 'medium',
        'adoptechTile--large': size === 'large',
      })}
    >
      <div className={baseCss + '--tileHeader'}>
        <div className={baseCss + '--tileHeaderIcon'}>
          <FontAwesomeIcon icon={icon} />
        </div>
        {customTitle ? customTitle : name}
        {available === true && (
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="adoptechTile--greenTick"
          />
        )}
      </div>
      <div className={baseCss + '--tileDescription'}>{description}</div>
      <button className={baseCss + '--tileAction'} onClick={onClick}>
        {buttonText}
        <div className={baseCss + '--tileActionIcon'}>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </button>
    </div>
  );
};

AdoptechTile.defaultProps = {
  icon: faBallotCheck,
};
