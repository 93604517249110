import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import { fetchAssessmentResponse } from '../../store/assessments/assessmentsThunks';
import { ApplicationState } from '../../types/applicationState';
import { COMPLETED_TEXT } from '../../types/constants';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { AssessmentCompletedResponsesBody } from '../AssessmentCompletedResponseBody/AssessmentCompletedResponseBody';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { Panel } from '../Panel/Panel';
import './AssessmentCompletedResponses.scss';

export const AssessmentCompletedResponses: React.FC = () => {
  const [assessmentOpen, setAssessmentOpen] = useState<string>();

  const dispatch = useDispatch();

  const isFetchingIncomingAssessmentResponses = useSelector(
    (state: ApplicationState) =>
      state.assessments.isFetchingIncomingAssessmentResponses
  );
  const incomingAssessmentResponses = useSelector(
    (state: ApplicationState) => state.assessments.incomingAssessmentResponses
  );

  const responses = incomingAssessmentResponses?.filter(
    response => response.status === COMPLETED_TEXT
  );

  if (!responses) {
    return null;
  }

  const handleAccordionBodyLoad = (i: string) => {
    dispatch(fetchAssessmentResponse(i));
    setAssessmentOpen(i);
  };

  return (
    <div className="assessmentCompletedResponses">
      <div className="assessmentCompletedResponses--header">
        Responses ({responses.length})
      </div>
      <div className="assessmentCompletedResponses--body">
        {isFetchingIncomingAssessmentResponses && <LoadingSpinner />}
        {!isFetchingIncomingAssessmentResponses && !responses.length && (
          <Panel>
            <div className="assessmentCompletedResponses--noResponse">
              No responses
            </div>
          </Panel>
        )}
        <Accordion>
          {responses.map((response, i) => (
            <AdoptechAccordionCard
              key={response.id}
              index={response.id}
              title={
                <div className="assessmentCompletedResponses--panelContent">
                  <div className="assessmentCompletedResponses--vendorName">
                    {response.vendorName}
                  </div>
                  <div className="assessmentCompletedResponses--lastModified">
                    <div className="assessmentCompletedResponses--stateIcon">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                    <div className="assessmentCompletedResponses--lastModifiedDate">
                      {response.assessmentResponseAnswers?.length > 0 &&
                        formatShortDate(response.updatedAt)}
                    </div>
                  </div>
                </div>
              }
              callbackOnOpen={handleAccordionBodyLoad}
            >
              {assessmentOpen === response.id && (
                <AssessmentCompletedResponsesBody />
              )}
            </AdoptechAccordionCard>
          ))}
        </Accordion>
      </div>
    </div>
  );
};
