import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitaliseFirst } from '../../functions/capitaliseFirst';
import {
  showDeletePaymentMethodModal,
  showAddPaymentMethodModal,
  setCurrentPaymentMethodId,
} from '../../store/user/userSlice';

import { PaymentMethodModalMode } from '../../store/user/userState';

import { fetchPaymentMethods } from '../../store/user/userThunks';
import { PaymentMethod } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { DeletePaymentMethodModal } from '../DeletePaymentMethodModal/DeletePaymentMethodModal';
import { IconButton } from '../IconButton/IconButton';
import './ManagePaymentMethodsGrid.scss';
import { selectActiveSubscription } from '../../selectors/selectActiveSubscription';

export const ManagePaymentMethodsGrid: React.FC = () => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();

  const isManageBillingModalShowing = useSelector(
    (state: ApplicationState) => state.vendors.isManageBillingModalShowing
  );

  const paymentMethods = useSelector(
    (state: ApplicationState) => state.user.paymentMethods
  );

  const hasActiveSubscription = !!useSelector(selectActiveSubscription);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isManageBillingModalShowing && hasActiveSubscription) {
      dispatch(fetchPaymentMethods());
    }
  }, [isManageBillingModalShowing]);

  return (
    <React.Fragment>
      <div className="managePaymentMethodsGrid">
        {paymentMethods.length > 0 && (
          <AdoptechTable modal className="managePaymentMethodsGrid--table">
            <thead>
              <tr className="managePaymentMethodsGrid--row">
                <th>Card type</th>
                <th>Number</th>
                <th>Expiry date</th>
                <th />
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paymentMethods.map(p => {
                return (
                  <tr className="managePaymentMethodsGrid--row" key={p.id}>
                    <td>
                      <div className="managePaymentMethodsGrid--cardType">
                        <FontAwesomeIcon icon={faCreditCard} />
                        {capitaliseFirst(p.card.brand)}
                      </div>
                    </td>
                    <td>Ending ............{p.card.last4}</td>
                    <td>
                      {p.card.expMonth}/{p.card.expYear}
                    </td>
                    <td>{p.card.isDefault ? 'Default' : '-'}</td>
                    <td>
                      <div className="adoptechTable--actions managePaymentMethodsGrid--actions">
                        <IconButton
                          ariaLabel="Edit card expiration date"
                          icon={faPen}
                          onClick={() => {
                            dispatch(setCurrentPaymentMethodId(p.id));
                            dispatch(
                              showAddPaymentMethodModal(
                                PaymentMethodModalMode.Edit
                              )
                            );
                          }}
                          tooltip="Edit"
                        />
                        <IconButton
                          ariaLabel="Delete"
                          icon={faTrashAlt}
                          onClick={() => {
                            setPaymentMethod(p);
                            dispatch(showDeletePaymentMethodModal());
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </AdoptechTable>
        )}
        {paymentMethods.length === 0 && (
          <div className="managePaymentMethodsGrid--message">
            No payment methods found
          </div>
        )}
      </div>

      <DeletePaymentMethodModal paymentMethod={paymentMethod} />
    </React.Fragment>
  );
};
