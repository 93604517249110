import { useEffect } from 'react';
import useIdle from 'react-use/lib/useIdle';

export const useIdleLogout = ({ afterIdle }: { afterIdle: () => void }) => {
  const isIdle = useIdle(30 * 60 * 1000);

  useEffect(() => {
    if (isIdle) {
      afterIdle();
    }
  }, [isIdle]);
};
