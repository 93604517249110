import React from 'react';
import {
  TrusthubDocumentModel,
  TrusthubSectionModel,
} from '../../../../../../swagger/trusthub';
import { Accordion } from 'react-bootstrap';
import { AdoptechAccordionCard } from '../../../../../../components/AdoptechAccordionCard/AdoptechAccordionCard';
import { AdoptechCheckbox } from '../../../../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { AddDocumentPermissionFormData } from '../TrusthubUserAdminAddDocumentPermissionDrawer';

export interface DocumentPermissionTableRowProps {
  section: TrusthubSectionModel;
  formData: AddDocumentPermissionFormData;
  setFormData: React.Dispatch<
    React.SetStateAction<AddDocumentPermissionFormData>
  >;
}

// for user flow doc permission modal
export const trusthubSectionName = (name: string) => {
  return name
    .replace('Reports', 'Information Security - Reports')
    .replace('Policies', 'Information Security - Policies');
};

export const DocumentPermissionTableRow: React.FC<
  DocumentPermissionTableRowProps
> = ({ section, formData, setFormData }) => {
  // if doc is not restricted => show it always preselected ( ignore any checkbox and select all handle logic on it )
  // => we have 2 lists:
  // sectionDocsToSelect - docs to work on selection ( all docs except preselected )
  // sectionDocsToView - docs to display ( all docs always )

  const sectionDocsToSelect = section.trusthubDocuments.filter(
    doc => doc.restricted
  );

  const sectionDocsToView = section.trusthubDocuments;

  const sectionIsChecked = (
    formDataObject: DocumentPermissionTableRowProps['formData']
  ) => {
    return sectionDocsToSelect.every(doc =>
      (formDataObject.selectedDocumentsIds || []).includes(doc.id)
    );
  };
  const sectionIsCheckedAll = sectionIsChecked(formData);

  const handleSectionSelectAll = () => {
    setFormData(prevFormData => {
      const prevSectionIsCheckedAll = sectionIsChecked(prevFormData);

      const ids = prevFormData.selectedDocumentsIds || [];

      const excludeIds = ids.filter(
        id => !sectionDocsToSelect.map(doc => doc.id).includes(id)
      );

      const allIds = ids.concat(sectionDocsToSelect.map(doc => doc.id));

      const payload = {
        selectedDocumentsIds: prevSectionIsCheckedAll ? excludeIds : allIds,
      };

      return {
        ...prevFormData,
        ...payload,
      };
    });
  };

  const isDocumentChecked = (document: TrusthubDocumentModel): boolean => {
    return (formData.selectedDocumentsIds || []).includes(document.id);
  };

  const handleDocumentSelect = (document: TrusthubDocumentModel) => {
    setFormData(prevFormData => {
      const docChecked = (prevFormData.selectedDocumentsIds || []).includes(
        document.id
      );
      const allIds = (prevFormData.selectedDocumentsIds || []).concat(
        document.id
      );
      const excludeIds = (prevFormData.selectedDocumentsIds || []).filter(
        id => id !== document.id
      );

      const payload = {
        selectedDocumentsIds: docChecked ? excludeIds : allIds,
      };

      return {
        ...prevFormData,
        ...payload,
      };
    });
  };

  return (
    <Accordion key={section.id} defaultActiveKey={section.id}>
      <AdoptechAccordionCard
        title={trusthubSectionName(section.name)}
        index={section.id}
        cardBodyClass="card-body--centered pt-2"
        headerClass="positionInitial"
        iconSize="small"
        noMargin
      >
        <AdoptechCheckbox
          key={section.id + 'select-all'}
          color="white"
          checked={sectionIsCheckedAll}
          id={section.id + 'select-all'}
          onChange={handleSectionSelectAll}
          disabled={sectionDocsToView.every(doc => !doc.restricted)}
          label="Select All"
          className="select-all-docs"
        />

        {sectionDocsToView.map(doc => {
          const isDisabled = !doc.restricted;
          return (
            <AdoptechCheckbox
              key={doc.id}
              color="white"
              checked={isDisabled || isDocumentChecked(doc)}
              id={`checkbox-${doc.id}`}
              onChange={() => handleDocumentSelect(doc)}
              label={doc.name}
              className="select-doc"
              disabled={isDisabled}
            />
          );
        })}
      </AdoptechAccordionCard>
    </Accordion>
  );
};
