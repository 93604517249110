import React, { useState } from 'react';
import {
  ReportCreatePayloadReport,
  ReportModelTypeEnum,
} from '../../../swagger';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { useDispatch, useSelector } from 'react-redux';
import AdoptechHS from '../../AdoptechHS/AdoptechHS';
import {
  LegalRegisterSection,
  legalSectionValid,
} from './LegalRegisterSection/LegalRegisterSection';
import {
  PestelRegisterSection,
  pestelSectionValid,
} from './PestelRegisterSection/PestelRegisterSection';
import {
  RiskRegisterSection,
  riskSectionValid,
} from './RiskRegisterSection/RiskRegisterSection';
import {
  VendorRegisterSection,
  vendorSectionValid,
} from './VendorRegisterSection/VendorRegisterSection';
import { SoaSection, soaSectionValid } from './SoaSection/SoaSection';
import {
  SoaSummarySection,
  soaSummarySectionValid,
} from './SoaSummarySection/SoaSummarySection';
import './ReportsManageDrawer.scss';
import { ApplicationState } from '../../../types/applicationState';
import {
  createReport,
  uploadReport,
} from '../../../store/reports/reportsThunks';
import { reportsRoute } from '../../Routes/Routes';
import { push } from 'connected-react-router';
import { registerTitle } from '../../../functions/registerTitle';
import {
  UploadedReportSection,
  uploadedReportSectionValid,
} from './UploadedReportSection/UploadedReportSection';
import { formatFinal } from './ReportFreeVersionField';

interface ReportsManageDrawerProps {
  onClose(): void;
  type: `${ReportModelTypeEnum}`;
}

export interface ReportForm extends ReportCreatePayloadReport {
  completedAt: string;
  file: File;
  hasVersion: boolean;
  name: string;
  version: string;
}
type updateReportFieldType = (
  field: keyof ReportForm,
  value: ValueOf<ReportForm>
) => void;

type updateReportFormType = (newForm: Partial<ReportForm>) => void;

export interface ReportSectionProps {
  formData: ReportForm;
  updateField: updateReportFieldType;
  updateForm: updateReportFormType;
}
type ValueOf<T> = T[keyof T];

export const ReportsManageDrawer: React.FC<ReportsManageDrawerProps> = ({
  onClose,
  type,
}) => {
  const [formData, setFormData] = useState<ReportForm>({ type } as ReportForm);
  const updateField: updateReportFieldType = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const updateForm: updateReportFormType = newForm => {
    setFormData({
      ...formData,
      ...newForm,
    });
  };

  const { isCreatingReport } = useSelector(
    (state: ApplicationState) => state.reports
  );
  const dispatch = useDispatch();
  const formIsValid = () => {
    if (formData.type === ReportModelTypeEnum.LegalRegister)
      return legalSectionValid(formData);
    if (formData.type === ReportModelTypeEnum.PestelRegister)
      return pestelSectionValid(formData);
    if (formData.type === ReportModelTypeEnum.RiskRegister)
      return riskSectionValid(formData);
    if (formData.type === ReportModelTypeEnum.StatementOfApplicability)
      return soaSectionValid(formData);
    if (formData.type === ReportModelTypeEnum.StatementOfApplicabilitySummary)
      return soaSummarySectionValid(formData);
    if (formData.type === ReportModelTypeEnum.VendorRegister)
      return vendorSectionValid(formData);
    if (formData.type === ReportModelTypeEnum.Uploaded)
      return uploadedReportSectionValid(formData);
    console.error(`form type  ${formData.type} is not supported`);
    return false;
  };

  const onSuccess = () => {
    dispatch(push(reportsRoute)); // cleanup all get params
    onClose();
  };

  const isReportUploading = formData.type === 'Report::Uploaded';

  const onConfirm = () => {
    const version = formatFinal(formData.version);
    const { hasVersion, ...otherFields } = formData;
    const payload = { version, ...otherFields };
    const thunk = isReportUploading ? uploadReport : createReport;
    dispatch(thunk(payload, onSuccess));
  };

  const Footer = (
    <>
      <AdoptechButton onClick={onClose} variant={AdoptechButtonVariant.White}>
        Cancel
      </AdoptechButton>
      <AdoptechButton
        disabled={!formIsValid()}
        onClick={onConfirm}
        variant={AdoptechButtonVariant.Primary}
        busy={isCreatingReport}
      >
        {isReportUploading ? 'Upload' : 'Add'}
      </AdoptechButton>
    </>
  );

  const sectionProps = { formData, updateField, updateForm };
  let section;
  switch (type) {
    case 'Report::LegalRegister':
      section = <LegalRegisterSection {...sectionProps} />;
      break;
    case 'Report::PestelRegister':
      section = <PestelRegisterSection {...sectionProps} />;
      break;
    case 'Report::RiskRegister':
      section = <RiskRegisterSection {...sectionProps} />;
      break;
    case 'Report::StatementOfApplicability':
      section = <SoaSection {...sectionProps} />;
      break;
    case 'Report::StatementOfApplicabilitySummary':
      section = <SoaSummarySection {...sectionProps} />;
      break;
    case 'Report::VendorRegister':
      section = <VendorRegisterSection {...sectionProps} />;
      break;
    case 'Report::Uploaded':
      section = <UploadedReportSection {...sectionProps} />;
      break;
    default:
      console.error(`Report type ${type} is not supported yet`);
  }

  const baseCss = 'reportsManageDrawer';

  return (
    <AdoptechHS
      title={`Add ${registerTitle(type.replace('Report::', ''))} Report`}
      show
      onClose={onClose}
      showConfirmationWarning={false}
      footer={Footer}
      extraClass={baseCss}
    >
      {section}
    </AdoptechHS>
  );
};
