/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EmailArray
 */
export interface EmailArray {
  /**
   *
   * @type {Array<string>}
   * @memberof EmailArray
   */
  addresses?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof EmailArray
   */
  invalid?: Array<string>;
}

export function EmailArrayFromJSON(json: any): EmailArray {
  return EmailArrayFromJSONTyped(json, false);
}

export function EmailArrayFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailArray {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    addresses: !exists(json, 'addresses') ? undefined : json['addresses'],
    invalid: !exists(json, 'invalid') ? undefined : json['invalid'],
  };
}

export function EmailArrayToJSON(value?: EmailArray | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    addresses: value.addresses,
    invalid: value.invalid,
  };
}
