/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ExternalShare
 */
export interface ExternalShare {
  /**
   *
   * @type {string}
   * @memberof ExternalShare
   */
  user?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalShare
   */
  emailAddresses?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalShare
   */
  subject?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalShare
   */
  message?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExternalShare
   */
  deadline?: string | null;
}

export function ExternalShareFromJSON(json: any): ExternalShare {
  return ExternalShareFromJSONTyped(json, false);
}

export function ExternalShareFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExternalShare {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    user: !exists(json, 'user') ? undefined : json['user'],
    emailAddresses: !exists(json, 'email_addresses')
      ? undefined
      : json['email_addresses'],
    subject: !exists(json, 'subject') ? undefined : json['subject'],
    message: !exists(json, 'message') ? undefined : json['message'],
    deadline: !exists(json, 'deadline') ? undefined : json['deadline'],
  };
}

export function ExternalShareToJSON(value?: ExternalShare | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user: value.user,
    email_addresses: value.emailAddresses,
    subject: value.subject,
    message: value.message,
    deadline: value.deadline,
  };
}
