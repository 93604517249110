/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PolicyCategory
 */
export interface PolicyCategory {
  /**
   *
   * @type {string}
   * @memberof PolicyCategory
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyCategory
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyCategory
   */
  identifier?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyCategory
   */
  summary?: string | null;
  /**
   *
   * @type {string}
   * @memberof PolicyCategory
   */
  summaryAsHtml?: string | null;
}

export function PolicyCategoryFromJSON(json: any): PolicyCategory {
  return PolicyCategoryFromJSONTyped(json, false);
}

export function PolicyCategoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PolicyCategory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    identifier: !exists(json, 'identifier') ? undefined : json['identifier'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    summaryAsHtml: !exists(json, 'summary_as_html')
      ? undefined
      : json['summary_as_html'],
  };
}

export function PolicyCategoryToJSON(value?: PolicyCategory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    identifier: value.identifier,
    summary: value.summary,
    summary_as_html: value.summaryAsHtml,
  };
}
