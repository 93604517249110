import classNames from 'classnames';
import React from 'react';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import './GenericModal.scss';

interface GenericModalProps {
  backdrop?: true | false | 'static';
  cancelButtonLabel?: string;
  className?: string;
  closeButtonLabel?: string;
  closeButtonVariant?: AdoptechButtonVariant;
  isBusy?: boolean;
  onCancel?: () => void;
  onClose: () => void;
  show: boolean;
  showCancelButton?: boolean;
  title: string;
}

export const GenericModal: React.FC<GenericModalProps> = props => {
  const classes = classNames(props.className, 'genericModal');
  const footerClasses = classNames({
    'genericModal--footer': true,
    'genericModal--footer-twoButtons': props.showCancelButton,
  });

  return (
    <Modal
      backdrop={props.backdrop}
      centered
      className={classes}
      onHide={props.onCancel}
      show={props.show}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Body>
        <div className="genericModal--body">{props.children}</div>
      </Modal.Body>
      <Modal.Footer>
        <div className={footerClasses}>
          {props.showCancelButton && (
            <AdoptechButton
              disabled={props.isBusy}
              onClick={() => {
                if (props.onCancel) {
                  props.onCancel();
                } else {
                  props.onClose();
                }
              }}
            >
              {props.cancelButtonLabel}
            </AdoptechButton>
          )}
          <AdoptechButton
            busy={props.isBusy}
            onClick={() => props.onClose()}
            variant={props.closeButtonVariant}
          >
            {props.closeButtonLabel}
          </AdoptechButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

GenericModal.defaultProps = {
  backdrop: true,
  cancelButtonLabel: 'Cancel',
  closeButtonLabel: 'CLOSE',
  closeButtonVariant: AdoptechButtonVariant.Primary,
  showCancelButton: false,
};
