import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { canIndex } from '../../functions/access';
import { selectInProgressPolicies } from '../../selectors/selectInProgressPolicies';
import { AccessObject } from '../../types/accessObject';
import { InProgressPoliciesGrid } from '../InProgressPoliciesGrid/InProgressPoliciesGrid';
import { Lozenge } from '../Lozenge/Lozenge';
import { NoDataText } from '../NoDataText/NoDataText';
import './InProgressPolicies.scss';

export const InProgressPolicies: React.FC = () => {
  const count = useSelector(selectInProgressPolicies).length;
  const titleRef = useRef();
  const canSeePolicyCategories = canIndex(AccessObject.policy_categories);
  if (!canSeePolicyCategories) return null;

  return (
    <div className="inProgressPolicies">
      <div className="inProgressPolicies--header">
        <div className="inProgressPolicies--title" ref={titleRef}>
          Drafts
          <Lozenge value={count} />
        </div>
      </div>
      {count === 0 && <NoDataText text="There are no draft policies" />}
      <InProgressPoliciesGrid />
    </div>
  );
};
