import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTrusthubCategories,
  fetchTrustHubSection,
  updateTrustHubSection,
} from '../store/trusthubThunks';
import { ApplicationState } from '../../../types/applicationState';
import {
  TrusthubSectionModel,
  TrusthubDocumentModel,
  SectionsSectionIdPatchRequest,
  TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesDocumentTypeEnum,
  TrusthubDocumentExtended,
  TrusthubDocumentModelVisibilityEnum,
  TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesVisibilityEnum,
  TrusthubCategoryModel,
} from '../../../swagger/trusthub';
import { ApiRequestStatus } from '../../../store/global/globalState';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { uniqArrayObjects } from '../../../functions/uniqueArrayObjects';

export interface EditSectionProps {
  section: TrusthubSectionModel;
  onClose: () => void;
  baseCss?: string;
  titleBar?: JSX.Element;
  editSectionHandler?: EditSectionHandlerType;
  bulkActionsRow?: JSX.Element;
  identifier?: string; //used for bottom sections reports and policies
}

export type TrusthubFormDocument = {
  documentId: string; // id can be null for templates
  checked: boolean;
  visibility: FormDocumentVisibility;
  trusthubCategoryId: string; // used for controls
  description: string;
  rowOrder?: number; // needed only for controls admin right panel for ordering
};

export type EditSectionHandlerType = {
  fetchingCurrentSection: boolean;
  sectionDescription: any;
  setSectionDescription: React.Dispatch<any>;
  handleCheckboxChange: (checked: boolean, doc: TrusthubDocumentModel) => void;
  updateSectionStatus: ApiRequestStatus;
  sortedDocsToEdit: TrusthubDocumentExtended[];
  handleSave: () => void;
  fetchCurrentSection: () => void;
  handleCheckboxAllChange: (opts?: { filterIds?: string[] }) => void;
  handleVisibilityChange: (e: { value: any }) => void;
  formDocuments: TrusthubFormDocument[];
  // for controls section
  fetchCategories: (vendorIdOrDomain: string) => void;
  formCategories: TrusthubCategoryModel[];
  setFormCategories: React.Dispatch<TrusthubCategoryModel[]>;
  handleDeselectAll: () => void;
  handleAddControlsToCategory: ({
    categoryId,
    controlIds,
  }: {
    categoryId: string;
    controlIds: string[];
  }) => void;
  handleAddControlsToRow: ({
    categoryId,
    controlIds,
    rowTargetRowOrder,
  }: {
    categoryId: string;
    controlIds: string[];
    rowTargetRowOrder: number;
  }) => void;
  handleDeleteControl: (controlId: string) => void;
  updateDocumentField: (documentId: string, field: string, value: any) => void;
};

export enum FormDocumentVisibility {
  Destroy = 'destroy',
  Public = TrusthubDocumentModelVisibilityEnum.Public,
  PrivateUnlocked = TrusthubDocumentModelVisibilityEnum.PrivateUnlocked,
  PrivateLocked = TrusthubDocumentModelVisibilityEnum.PrivateLocked,
}

// Logic for fetching and saving current section ( after checkboxes items selected ) in Edit mode

export const useEditTrustHubSection = ({
  section,
  onClose,
}: EditSectionProps) => {
  const dispatch = useAppDispatch();

  const [formDocuments, setFormDocuments] = useState<TrusthubFormDocument[]>(
    []
  );

  const { updateSectionStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );

  const [fetchingCurrentSection, setFetchingCurrentSection] = useState(false);
  const [sectionDescription, setSectionDescription] = useState(null);
  const fetchCurrentSection = () => {
    setSectionDescription(section.description);
    setFetchingCurrentSection(true);
    dispatch(
      fetchTrustHubSection(
        {
          includeTemplates: true,
          sectionId: section.id,
        },
        (sectionPayload: TrusthubSectionModel) => {
          setFetchingCurrentSection(false);

          setFormDocuments(
            sectionPayload.trusthubDocuments.map(doc => ({
              documentId: doc.documentId,
              checked: false,
              description: doc.description,
              trusthubCategoryId: doc.trusthubCategoryId,
              rowOrder: doc.rowOrder,
              visibility:
                doc.id || section.identifier === 'controls'
                  ? (doc.visibility as unknown as FormDocumentVisibility)
                  : FormDocumentVisibility.Destroy,
            }))
          );
        }
      )
    );
  };

  // for categories ( controls section )
  const [formCategories, setFormCategories] = useState<TrusthubCategoryModel[]>(
    []
  );

  const fetchCategories = async (vendorIdOrDomain: string) => {
    const categories = await dispatch(
      fetchTrusthubCategories({ vendorIdOrDomain })
    ).unwrap();
    setFormCategories(categories);
  };

  const handleCheckboxChange: EditSectionHandlerType['handleCheckboxChange'] = (
    checked,
    doc
  ) => {
    setFormDocuments(prevFormDocuments => {
      return prevFormDocuments.map(prevFormDoc => {
        if (prevFormDoc.documentId === doc.documentId) {
          return {
            ...prevFormDoc,
            checked: checked,
          };
        }
        return prevFormDoc;
      });
    });
  };

  const handleAddControlsToCategory: EditSectionHandlerType['handleAddControlsToCategory'] =
    ({ categoryId, controlIds }) => {
      return setFormDocuments(prevFormDocuments => {
        const lastIndex = (arr: TrusthubFormDocument[]) => {
          if (arr.length > 0) {
            return arr[arr.length - 1].rowOrder;
          } else {
            return 0;
          }
        };

        return prevFormDocuments.map(prevFormDoc => {
          if ((controlIds || []).includes(prevFormDoc.documentId)) {
            return {
              ...prevFormDoc,
              trusthubCategoryId: categoryId,
              checked: false,
              visibility: section.trusthubDocuments.find(
                doc => doc.documentId === prevFormDoc.documentId
              )?.visibility as unknown as FormDocumentVisibility,
              rowOrder:
                lastIndex(
                  prevFormDocuments
                    .filter(doc => doc.trusthubCategoryId === categoryId)
                    .sort((a, b) => a.rowOrder - b.rowOrder)
                ) + 1,
            };
          } else {
            return { ...prevFormDoc, checked: false };
          }
        });
      });
    };

  const insertArrayAfterIndex = <T extends object>(
    originalArray: T[],
    newArray: T[],
    index: number
  ): T[] => {
    return [
      ...originalArray.slice(0, index + 1),
      ...newArray,
      ...originalArray.slice(index + 1),
    ];
  };

  const handleAddControlsToRow: EditSectionHandlerType['handleAddControlsToRow'] =
    ({ categoryId, controlIds, rowTargetRowOrder }) => {
      if (!categoryId || !controlIds || rowTargetRowOrder === undefined) {
        console.error('invalid drag and drop params', {
          categoryId,
          controlIds,
          rowTargetRowOrder,
        });
        return;
      }
      setFormDocuments(prevFormDocuments => {
        const newControls = controlIds.map(controlId => {
          const formDoc = prevFormDocuments.find(
            doc => doc.documentId === controlId
          );

          return formDoc;
        });

        // needed to exclude self if move to item in the same category
        const listWithoutDocs = (
          list: TrusthubFormDocument[],
          newList: TrusthubFormDocument[]
        ) => {
          return list.filter(
            categoryDoc =>
              !newList
                .map(doc => doc.documentId)
                .includes(categoryDoc.documentId)
          );
        };

        const categoryDocuments = listWithoutDocs(
          [...prevFormDocuments].filter(
            doc => doc.trusthubCategoryId === categoryId
          ),
          newControls
        ).sort((a, b) => a.rowOrder - b.rowOrder);

        const rowTargetRowIndex = categoryDocuments.findIndex(
          doc => doc.rowOrder === rowTargetRowOrder
        );

        if (rowTargetRowIndex < 0) {
          // if drag and drop on the same place
          return prevFormDocuments;
        }

        const newCategoryDocuments = insertArrayAfterIndex(
          categoryDocuments,
          newControls,
          rowTargetRowIndex
        );

        const newSortedDocuments = newCategoryDocuments.map((doc, index) => {
          return {
            ...doc,
            rowOrder: index,
            checked: false,
            trusthubCategoryId: categoryId,
            visibility: section.trusthubDocuments.find(
              trusthubDoc => trusthubDoc.documentId === doc.documentId
            )?.visibility as unknown as FormDocumentVisibility,
          };
        });

        return prevFormDocuments.map(prevFormDocument => {
          return (
            newSortedDocuments.find(
              sortedDoc => sortedDoc.documentId === prevFormDocument.documentId
            ) || prevFormDocument
          );
        });
      });
    };

  // used for controls when change framework
  const handleDeselectAll = () => {
    setFormDocuments(prevFormDocuments => {
      return prevFormDocuments.map(prevFormDoc => {
        return { ...prevFormDoc, checked: false };
      });
    });
  };

  const handleDeleteControl = (controlId: string) => {
    setFormDocuments(prevFormDocuments => {
      return prevFormDocuments.map(doc => {
        if (doc.documentId === controlId) {
          return {
            ...doc,
            checked: false,
            trusthubCategoryId: null,
            visibility: FormDocumentVisibility.Destroy,
            rowOrder: 0,
          };
        }
        return doc;
      });
    });
  };

  const handleVisibilityChange = (e: { value: any }) => {
    const newVisibility = e.value;
    setFormDocuments(prevFormDocuments => {
      return prevFormDocuments.map(prevFormDoc => {
        return prevFormDoc.checked
          ? {
              ...prevFormDoc,
              visibility: newVisibility,
              checked: false,
            }
          : prevFormDoc;
      });
    });
  };
  const handleCheckboxAllChange = (opts?: { filterIds?: string[] }) => {
    if (opts.filterIds) {
      // for left panel in controls filter by visible items
      return setFormDocuments(prevFormDocuments => {
        const allFilteredDocsSelected = prevFormDocuments
          .filter(prevFormDoc =>
            opts.filterIds.includes(prevFormDoc.documentId)
          )
          .every(prevFormDoc => {
            return prevFormDoc.checked;
          });

        return prevFormDocuments.map(prevFormDoc => {
          return {
            ...prevFormDoc,
            checked: opts.filterIds.includes(prevFormDoc.documentId)
              ? !allFilteredDocsSelected
              : false,
          };
        });
      });
    }

    setFormDocuments(prevFormDocuments => {
      const allDocsSelected = prevFormDocuments.every(prevFormDoc => {
        return prevFormDoc.checked;
      });
      return prevFormDocuments.map(prevFormDoc => {
        return {
          ...prevFormDoc,
          checked: !allDocsSelected,
        };
      });
    });
  };

  // used for updating control description
  const updateDocumentField = (
    documentId: string,
    field: string,
    value: any
  ) => {
    setFormDocuments(prevFormDocuments => {
      return prevFormDocuments.map(prevFormDoc => {
        if (prevFormDoc.documentId === documentId) {
          return {
            ...prevFormDoc,
            [field]: value,
          };
        }
        return prevFormDoc;
      });
    });
  };

  const handleSave = () => {
    const trusthubDocumentsAttributes = section.trusthubDocuments
      .map(doc => {
        const isControls = section.identifier === 'controls';
        const formDoc = formDocuments.find(
          formDocument => doc.documentId === formDocument.documentId
        );

        const rightPanelControl = formDoc.trusthubCategoryId;
        const leftPanelRemovedControl =
          !formDoc.trusthubCategoryId &&
          formDoc.visibility === FormDocumentVisibility.Destroy;

        if (isControls && !(rightPanelControl || leftPanelRemovedControl)) {
          return null;
        }

        const payloadDoc: SectionsSectionIdPatchRequest['body']['trusthubSection']['trusthubDocumentsAttributes'][0] =
          {
            id: doc.id,
            destroy: formDoc.visibility === FormDocumentVisibility.Destroy,
            name: doc.name,
            documentId: doc.documentId,
            trusthubCategoryId: formDoc.trusthubCategoryId,
            rowOrderPosition: formDoc.rowOrder,
            description: formDoc.description,
            visibility:
              formDoc.visibility === FormDocumentVisibility.Destroy
                ? null
                : (formDoc.visibility as unknown as TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesVisibilityEnum),
            documentType:
              doc.documentType as TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesDocumentTypeEnum,
          };
        return payloadDoc;
      })
      .filter(doc => doc);
    const payload: SectionsSectionIdPatchRequest = {
      sectionId: section.id,
      body: {
        trusthubSection: {
          description: sectionDescription,
          trusthubDocumentsAttributes: trusthubDocumentsAttributes,
        },
      },
    };

    dispatch(updateTrustHubSection(payload, () => onClose()));
  };

  const docsToEdit = section?.trusthubDocuments || [];
  const sortedDocsToEdit = [...docsToEdit].sort(
    (a, b) => a.rowOrder - b.rowOrder
  );

  const result: EditSectionHandlerType = {
    fetchingCurrentSection,
    sectionDescription,
    setSectionDescription,
    handleCheckboxChange,
    updateSectionStatus,
    handleVisibilityChange,
    sortedDocsToEdit,
    handleSave,
    fetchCurrentSection,
    handleCheckboxAllChange,
    formDocuments,
    fetchCategories,
    formCategories,
    setFormCategories,
    handleDeselectAll,
    handleAddControlsToCategory,
    handleAddControlsToRow,
    handleDeleteControl,
    updateDocumentField,
  };
  return result;
};
