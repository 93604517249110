import React from 'react';

interface AdoptechPanelProps {
  className?: string;
  padding?: string;
  borderRadius?: string;
}
export const AdoptechPanel: React.FC<AdoptechPanelProps> = props => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: props.padding || '16px',
        borderRadius: props.borderRadius || '8px',
      }}
      className={props.className}
    >
      {props.children}
    </div>
  );
};
