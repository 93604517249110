import React, { DOMAttributes } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import {
  AssessmentResponseAnswer,
  Question,
  QuestionLayoutEnum,
} from '../../swagger';
import { AdoptechButton } from '../AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { AnswerFormControl } from '../AnswerFormControl/AnswerFormControl';
import './AssessmentResponseLibraryPopup.scss';
import { formatShortDate } from '../../functions/formatShortDate';
import { useAssessmentHistory } from '../AssessmentResponseHistoryPopup/AssessmentResponseHistoryPopup';

interface AssessmentResponseLibraryPopupProps {
  assessmentResponseId: string;
  previousAnswer: AssessmentResponseAnswer;
  question: Question;
  questions: Question[];
  onAnswerClick: (
    library: AssessmentResponseAnswer[],
    entry: AssessmentResponseAnswer
  ) => void;
}

const baseCss = 'assessmentResponseLibraryPopup';
// TODO: reuse AdoptechDropdownToggle
const LibraryButton = React.forwardRef<HTMLDivElement, DOMAttributes<any>>(
  (props, ref) => (
    <div
      className={baseCss + '--button'}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        props.onClick(e);
      }}
      ref={ref}
    >
      <AdoptechButton>Library</AdoptechButton>
    </div>
  )
);

export const AssessmentResponseLibraryPopup: React.FC<
  AssessmentResponseLibraryPopupProps
> = ({
  assessmentResponseId,
  previousAnswer,
  question,
  questions,
  onAnswerClick,
}) => {
  const { history, isFetchingAssessmentResponseHistory, fetchHistory } =
    useAssessmentHistory({ assessmentResponseId, question });

  const historyExist = history.length > 0;

  if (question?.layout === QuestionLayoutEnum.FileSelect) {
    return null;
  }

  const tableLibrary: { [key: string]: AssessmentResponseAnswer[] } = {};
  if (historyExist && question.layout === QuestionLayoutEnum.TableLayout) {
    history.forEach(entry => {
      tableLibrary[entry.assessmentResponseId]
        ? tableLibrary[entry.assessmentResponseId].push(entry)
        : (tableLibrary[entry.assessmentResponseId] = [entry]);
    });
  }

  const renderTableLibrary = (
    tableLibraryAnswers: AssessmentResponseAnswer[],
    index: number
  ) => {
    const entry = tableLibraryAnswers[0];
    return renderEntry(entry, index, tableLibraryAnswers);
  };

  let prevAnswer = previousAnswer?.value;

  const renderEntry = (
    entry: AssessmentResponseAnswer,
    index: number,
    libraryAnswers: AssessmentResponseAnswer[]
  ) => {
    const isConfirmed = prevAnswer === entry.value;
    prevAnswer = entry.value;

    return (
      <div
        onClick={() => {
          onAnswerClick(libraryAnswers, entry);
        }}
        className="assessmentResponseLibraryPopup--libraryEntry"
        key={entry.id}
      >
        <div className="assessmentResponseLibraryPopup--header">
          <div className="assessmentResponseLibraryPopup--prevAnswer">
            Previous answer
          </div>
          <div className="assessmentResponseLibraryPopup--lastUsed">
            {`Last used: ${formatShortDate(
              isConfirmed ? entry.confirmedAt : entry.updatedAt
            )}`}
          </div>
        </div>
        <div className="assessmentResponseLibraryPopup--header2">
          <div className="assessmentResponseLibraryPopup--by">
            {isConfirmed ? entry.confirmedBy : entry.updatedBy}
          </div>
        </div>
        <AnswerFormControl
          question={question}
          questions={questions}
          answers={libraryAnswers}
          previousAnswer={entry}
          indexProperty="name"
          onChange={() => {}}
          readonly
          asTextReadonly
          responseId={entry.assessmentResponseId}
        />
      </div>
    );
  };

  return (
    <div className="assessmentResponseLibraryPopup">
      <Dropdown
        as={ButtonGroup}
        onToggle={isShowing => {
          if (isShowing) fetchHistory();
        }}
        drop="up"
        alignRight
      >
        <Dropdown.Toggle as={LibraryButton} />

        <DropdownMenu popperConfig={{ strategy: 'fixed' }} alignRight>
          <div className="assessmentResponseLibraryPopup--body">
            {historyExist && (
              <div className="assessmentResponseLibraryPopup--title">{`Library of previous answers (${history.length})`}</div>
            )}
            <div className="assessmentResponseLibraryPopup--library">
              {historyExist && (
                <>
                  {question.layout === QuestionLayoutEnum.TableLayout
                    ? Object.values(tableLibrary).map(renderTableLibrary)
                    : history.map(renderEntry)}
                </>
              )}
              {isFetchingAssessmentResponseHistory && <LoadingSpinner />}
              {!isFetchingAssessmentResponseHistory &&
                !historyExist &&
                'There is no previous answer to this question'}
            </div>
          </div>
          <div className="assessmentResponseLibraryPopup--pointer" />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
