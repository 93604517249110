/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorTeamModel
 */
export interface VendorTeamModel {
  /**
   *
   * @type {string}
   * @memberof VendorTeamModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VendorTeamModel
   */
  vendorId?: string;
  /**
   *
   * @type {string}
   * @memberof VendorTeamModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof VendorTeamModel
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorTeamModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof VendorTeamModel
   */
  updatedAt?: string;
  /**
   *
   * @type {Array<VendorUser>}
   * @memberof VendorTeamModel
   */
  vendorUsers?: Array<VendorUser>;
}

export function VendorTeamModelFromJSON(json: any): VendorTeamModel {
  return VendorTeamModelFromJSONTyped(json, false);
}

export function VendorTeamModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorTeamModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    vendorId: !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    vendorUsers: !exists(json, 'vendor_users')
      ? undefined
      : (json['vendor_users'] as Array<any>).map(VendorUserFromJSON),
  };
}

export function VendorTeamModelToJSON(value?: VendorTeamModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    vendor_id: value.vendorId,
    name: value.name,
    description: value.description,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    vendor_users:
      value.vendorUsers === undefined
        ? undefined
        : (value.vendorUsers as Array<any>).map(VendorUserToJSON),
  };
}
