/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum AssertionSource {
  Manual = 'manual',
  Adoptech = 'adoptech',
  AwsIam = 'aws_iam',
  GitHub = 'git_hub',
  Veremark = 'veremark',
}

export function AssertionSourceFromJSON(json: any): AssertionSource {
  return AssertionSourceFromJSONTyped(json, false);
}

export function AssertionSourceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionSource {
  return json as AssertionSource;
}

export function AssertionSourceToJSON(value?: AssertionSource | null): any {
  return value as any;
}
