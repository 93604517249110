/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AgreementCreatePayloadAgreementSignatories
 */
export interface AgreementCreatePayloadAgreementSignatories {
  /**
   *
   * @type {string}
   * @memberof AgreementCreatePayloadAgreementSignatories
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementCreatePayloadAgreementSignatories
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementCreatePayloadAgreementSignatories
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementCreatePayloadAgreementSignatories
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementCreatePayloadAgreementSignatories
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementCreatePayloadAgreementSignatories
   */
  companyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementCreatePayloadAgreementSignatories
   */
  companyAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementCreatePayloadAgreementSignatories
   */
  companyNumber?: string | null;
}

export function AgreementCreatePayloadAgreementSignatoriesFromJSON(
  json: any
): AgreementCreatePayloadAgreementSignatories {
  return AgreementCreatePayloadAgreementSignatoriesFromJSONTyped(json, false);
}

export function AgreementCreatePayloadAgreementSignatoriesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementCreatePayloadAgreementSignatories {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: !exists(json, 'type') ? undefined : json['type'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    email: !exists(json, 'email') ? undefined : json['email'],
    address: !exists(json, 'address') ? undefined : json['address'],
    companyName: !exists(json, 'company_name')
      ? undefined
      : json['company_name'],
    companyAddress: !exists(json, 'company_address')
      ? undefined
      : json['company_address'],
    companyNumber: !exists(json, 'company_number')
      ? undefined
      : json['company_number'],
  };
}

export function AgreementCreatePayloadAgreementSignatoriesToJSON(
  value?: AgreementCreatePayloadAgreementSignatories | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    first_name: value.firstName,
    last_name: value.lastName,
    email: value.email,
    address: value.address,
    company_name: value.companyName,
    company_address: value.companyAddress,
    company_number: value.companyNumber,
  };
}
