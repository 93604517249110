/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CorrectiveActionExtendedAllOf,
  CorrectiveActionExtendedAllOfFromJSON,
  CorrectiveActionExtendedAllOfFromJSONTyped,
  CorrectiveActionExtendedAllOfToJSON,
  CorrectiveActionModel,
  CorrectiveActionModelFromJSON,
  CorrectiveActionModelFromJSONTyped,
  CorrectiveActionModelToJSON,
  SharedAttachmentModel,
  SharedAttachmentModelFromJSON,
  SharedAttachmentModelFromJSONTyped,
  SharedAttachmentModelToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface CorrectiveActionExtended
 */
export interface CorrectiveActionExtended extends CorrectiveActionModel {
  /**
   *
   * @type {Array<SharedAttachmentModel>}
   * @memberof CorrectiveActionExtended
   */
  attachments?: Array<SharedAttachmentModel>;
}

export function CorrectiveActionExtendedFromJSON(
  json: any
): CorrectiveActionExtended {
  return CorrectiveActionExtendedFromJSONTyped(json, false);
}

export function CorrectiveActionExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CorrectiveActionExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...CorrectiveActionModelFromJSONTyped(json, ignoreDiscriminator),
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(SharedAttachmentModelFromJSON),
  };
}

export function CorrectiveActionExtendedToJSON(
  value?: CorrectiveActionExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...CorrectiveActionModelToJSON(value),
    attachments:
      value.attachments === undefined
        ? undefined
        : (value.attachments as Array<any>).map(SharedAttachmentModelToJSON),
  };
}
