import React, { useState } from 'react';
import { EditTestFormSection, isTestAutomatic } from '../../EditTestForm';
import { useChildClass } from '../../../../../../../../hooks/useChildClass';
import './EditTestFormLastResultsList.scss';
import { formatDate } from '../../../../../../../../functions/formatDate';
import {
  AssertionModelStatusEnum,
  AssertionResultModel,
  AssertionResultModelStatusEnum,
  TaskExtended,
  TaskModelStatusEnum,
} from '../../../../../../../../swagger';
import {
  fetchAssertionDetailsSilent,
  patchAssertionResultExclude,
  patchAssertionResultInclude,
} from '../../../../../../../../store/compliance/complianceThunks';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../../../../types/applicationState';
import { LoadingSpinner } from '../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import {
  setControlFormModel,
  setTempTask,
  updateHiddenEditTaskDrawerControls,
  updateTaskInTheTree,
} from '../../../../../../../../store/compliance/complianceSlice';
import { TestForm } from '../../../EditTestDrawer';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../../../../components/AdoptechButton/AdoptechButton';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { complianceTestsRoute } from '../../../../../../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus } from '@fortawesome/pro-light-svg-icons/faCircleMinus';
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons';
import { ExcludeResultModal } from '../ExcludeResultModal/ExcludeResultModal';
import { setCurrentAgreement } from '../../../../../../../../store/agreements/agreementsSlice';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal/ConfirmationModal';
import { CommandConfirmation } from '../../../../../../../../types/CommandConfirmation';
import classNames from 'classnames';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { testResultEvidences } from '../../../../../../tests/TestResult';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';

export const useUpdateHiddenControlDrawer = () => {
  const dispatch = useDispatch<ThunkDispatch<any, undefined, AnyAction>>();
  const { tempTask, editControlFormModel } = useSelector(
    (state: ApplicationState) => state.compliance
  );

  const updateHiddenControlDrawer = (taskStatus: TaskModelStatusEnum) => {
    const updatedTasks = (editControlFormModel.tasks || []).map(task =>
      task.id === tempTask.id ? { ...task, status: taskStatus } : task
    );

    dispatch(
      setControlFormModel({
        ...editControlFormModel,
        tasks: updatedTasks,
      })
    );
  };

  return updateHiddenControlDrawer;
};

export const calculateTaskStatus = (
  taskAssertions: TaskExtended['assertions']
) => {
  const visibleAssertions = (taskAssertions || []).filter(
    assertion => assertion.available
  );
  const isEmpty = visibleAssertions.length === 0;
  const allCompliant = visibleAssertions.every(
    taskAssertion => taskAssertion.status === AssertionModelStatusEnum.Compliant
  );
  const hasFailed = visibleAssertions.some(
    taskAssertion => taskAssertion.status === AssertionModelStatusEnum.Failed
  );

  if (isEmpty) return TaskModelStatusEnum.Pending;

  if (allCompliant) return TaskModelStatusEnum.Compliant;
  if (hasFailed) return TaskModelStatusEnum.Failed;

  return TaskModelStatusEnum.Pending;
};

// if we run test OR exclude test ids OR change frequency => tests status changed =>
// we need to update this test in control check drawer + control check status + test drawer

export const useUpdateAssertionEverywhere = () => {
  const dispatch = useAppDispatch();
  const { tempTask } = useSelector(
    (state: ApplicationState) => state.compliance
  );

  const updateHiddenEditControlDrawerTasks = useUpdateHiddenControlDrawer();

  const updateAssertionEverywhere = (
    assertionPayload: TestForm,
    props: React.PropsWithChildren<EditTestFormSection>
  ) => {
    props.onChange({
      ...props.formData,
      ...assertionPayload,
    });

    const newAssertions = tempTask?.assertions.map(taskAssertion =>
      taskAssertion.id !== props.formData.id
        ? taskAssertion
        : { ...taskAssertion, ...assertionPayload }
    );

    const taskStatus = calculateTaskStatus(newAssertions);
    const newTempTaskPayload = {
      assertions: newAssertions,
      status: taskStatus,
    };
    dispatch(setTempTask({ ...tempTask, ...newTempTaskPayload }));

    dispatch(
      updateTaskInTheTree({
        status: taskStatus,
        id: tempTask.id,
        partialUpdate: true,
      })
    );

    updateHiddenEditControlDrawerTasks(taskStatus);
    dispatch(updateHiddenEditTaskDrawerControls());
  };

  return updateAssertionEverywhere;
};

const parseJSONPayload = (result: AssertionResultModel) => {
  try {
    const message = JSON.parse(result.payload).message;

    if (typeof message === 'string' && message.trim() !== '') {
      return message.replace(/\n/g, '<br>');
    }

    return null;
  } catch (error) {
    console.error('Error parsing JSON:', error.message);
    return null;
  }
};

const testResultTitle = (result: AssertionResultModel) =>
  result.comment || parseJSONPayload(result);

export const EditTestFormLastResultsList: React.FC<
  EditTestFormSection
> = props => {
  const baseCss = 'editTestResultsList';
  const childClass = useChildClass(baseCss);
  const dispatch = useAppDispatch();
  const isAuto = isTestAutomatic(props.formData);
  const {
    patchAssertionResultIncludeStatus,
    patchAssertionResultExcludeStatus,
    fetchAssertionDetailsSilentStatus,
  } = useSelector((state: ApplicationState) => state.compliance);
  const excludeResults = (props.formData.latestRunResults || []).filter(
    result => result.excluded
  );
  const includeResults = (props.formData.latestRunResults || []).filter(
    result => !result.excluded
  );
  const updateAssertionEveryWhere = useUpdateAssertionEverywhere();

  const handleExclude = async (
    event: React.SyntheticEvent,
    result: AssertionResultModel
  ) => {
    setShowExcludeModal(true);
    setCurrentResultId(result.id);
  };

  const handleInclude = async (
    event: React.SyntheticEvent,
    result: AssertionResultModel
  ) => {
    const handleIncludeConfirm = async () => {
      await dispatch(
        patchAssertionResultInclude({
          assertionResultId: result.id,
        })
      );
      await fetchAssertionAfterExcludeOrInclude();
    };
    command({
      title: 'Confirm inclusion',
      subject: { name: testResultTitle(result), type: 'Action' },
      description: 'Click CONFIRM to include this test.',
      buttonVariant: AdoptechButtonVariant.Warning,
      onConfirm: handleIncludeConfirm,
    });
  };

  const isExcluding =
    patchAssertionResultExcludeStatus == 'loading' ||
    fetchAssertionDetailsSilentStatus === 'loading';

  const handleViewLog = () =>
    dispatch(push(`${complianceTestsRoute}?search=${props.formData?.name}`));
  const isIncluding = patchAssertionResultIncludeStatus == 'loading';

  const hasIncludeResults = includeResults.length > 0;
  const hasExcludeResults = excludeResults.length > 0;
  const canExclude = includeResults.length > 1;
  const [showExcludeModal, setShowExcludeModal] = useState(false);
  const [currentResultId, setCurrentResultId] =
    useState<AssertionResultModel['id']>(null);
  const [currentCommand, command] = useState<CommandConfirmation>(null);
  const fetchAssertionAfterExcludeOrInclude = async () => {
    const { status, latestRunResults } = await dispatch(
      fetchAssertionDetailsSilent({ assertionId: props.formData.id })
    ).unwrap();
    const assertionPayload = { status, latestRunResults };
    updateAssertionEveryWhere(assertionPayload, props);
  };
  const handleCancelExcludeModal = async (
    isConfirmed: boolean,
    reasonForExclusion?: string
  ) => {
    if (!isConfirmed) {
      setShowExcludeModal(false);
      setCurrentResultId(null);
      return;
    }
    setShowExcludeModal(false);
    await dispatch(
      patchAssertionResultExclude({
        assertionResultId: currentResultId,
        body: {
          assertionResult: {
            reasonForExclusion,
          },
        },
      })
    );
    await fetchAssertionAfterExcludeOrInclude();
    setCurrentResultId(null);
  };

  return (
    <>
      <div className={childClass('header')}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div>Latest test results</div>{' '}
          {(isExcluding || isIncluding) && <LoadingSpinner inlineSmall />}
        </div>
        {hasIncludeResults && (
          <div className={childClass('viewEventLogButton')}>
            <AdoptechButton icon={faEye} size="middle2" onClick={handleViewLog}>
              View Test Log
            </AdoptechButton>
          </div>
        )}
      </div>
      {hasIncludeResults && (
        <div>
          <div
            className={
              childClass('tableHeaders') +
              ' ' +
              (isAuto ? 'four-columns' : 'five-columns')
            }
          >
            <div className={childClass('label')}></div>
            <div className={childClass('label')}>Test</div>
            <div className={childClass('label')}>Time</div>
            {!isAuto && <div className={childClass('label')}>Evidence</div>}
            <div className={childClass('label')}></div>
          </div>
          {includeResults.map(result => {
            const isCompliant =
              result.status === AssertionResultModelStatusEnum.Compliant;
            return (
              <div
                className={
                  childClass('tableRow') +
                  ' ' +
                  (isAuto ? 'four-columns' : 'five-columns') +
                  ' ' +
                  childClass('tableRow--large')
                }
                key={result.id}
              >
                <div
                  className={classNames(baseCss + '--results-header-icon', {
                    green: isCompliant,
                    red: !isCompliant,
                  })}
                >
                  <FontAwesomeIcon
                    icon={isCompliant ? faCheckCircle : faExclamationCircle}
                  />
                </div>
                <div
                  className={childClass('name')}
                  dangerouslySetInnerHTML={{
                    __html: testResultTitle(result),
                  }}
                ></div>
                <div className={childClass('lastRun')}>
                  {formatDate(result.createdAt, 'longDateTime1')}
                </div>
                {!isAuto && (
                  <div className={childClass('evidences')}>
                    {testResultEvidences(result)}
                  </div>
                )}
                {canExclude && !(isExcluding || isIncluding) && (
                  <div
                    title="Exclude"
                    onClick={(e: React.SyntheticEvent) =>
                      handleExclude(e, result)
                    }
                    className={childClass('excludeIcon')}
                  >
                    <FontAwesomeIcon icon={faCircleMinus} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {hasExcludeResults && (
        <>
          <div className={childClass('header')}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <div>Excluded test results</div>{' '}
            </div>
          </div>

          <div>
            <div className={childClass('tableHeaders') + ' three-columns'}>
              <div className={childClass('label')}>Test</div>
              <div className={childClass('label')}>Time</div>
              <div className={childClass('label')}></div>
            </div>
            {excludeResults.map(result => (
              <div
                className={
                  childClass('tableRow') +
                  ' three-columns ' +
                  childClass('tableRow--large')
                }
                key={result.id}
              >
                <div
                  className={childClass('name')}
                  title={result.reasonForExclusion}
                  dangerouslySetInnerHTML={{
                    __html: testResultTitle(result),
                  }}
                ></div>
                <div className={childClass('lastRun')}>
                  {formatDate(result.createdAt, 'longDateTime1')}
                </div>
                {!(isExcluding || isIncluding) && (
                  <div
                    title="Include"
                    onClick={(e: React.SyntheticEvent) =>
                      handleInclude(e, result)
                    }
                    className={childClass('includeIcon')}
                  >
                    <FontAwesomeIcon icon={faCirclePlus} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
      {showExcludeModal && (
        <ExcludeResultModal
          show={showExcludeModal}
          onCancel={handleCancelExcludeModal}
        />
      )}
      <ConfirmationModal
        command={currentCommand}
        onCancel={_confirmed => command(null)}
      />
    </>
  );
};
