import React, { useRef, useState } from 'react';
import './AdoptechFileInput.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';

interface AdoptechFileInputProps<T> {
  onChange: (value: T) => void;
  label: string;
  accept?: string;
  name: string;
  urls: string[]; // initial previews
  extraClass?: string;
  multiple?: boolean;
  placeholder?: string;
}

export const AdoptechFileInput: React.FC<
  AdoptechFileInputProps<File[]>
> = props => {
  const baseCss = 'adoptechFileInput';
  const hasError = !props.urls;
  const inputRef = useRef<HTMLInputElement>();
  const [imagePreviews, setImagePreviews] = useState<string[]>(
    props.urls || []
  );

  const [fileNames, setFileNames] = useState<string[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.currentTarget.files);

    const previews = selectedFiles.map(file => URL.createObjectURL(file));
    const names = selectedFiles.map(file => file.name);
    setFileNames(names);
    setImagePreviews(previews);
    props.onChange(selectedFiles);
  };

  const handleClick = () => inputRef.current.click();
  const acceptImage = props.accept?.split(',').includes('image');
  const hasPlaceholder = props.placeholder !== undefined;
  return (
    <div
      onClick={handleClick}
      className={classNames(baseCss, {
        hasError,
        ...(props.extraClass ? { [props.extraClass]: true } : {}),
      })}
    >
      <div className={baseCss + '--labelWithPreview'}>
        <div>{props.label}</div>

        {hasPlaceholder && (
          <div className={baseCss + '--placeholder'}>{props.placeholder}</div>
        )}
        {!hasPlaceholder && (
          <div>
            {acceptImage &&
              imagePreviews.length > 0 &&
              imagePreviews.map((preview, index) => (
                <img key={index} src={preview} />
              ))}
            {fileNames.length === 0 && `No ${props.name} uploaded`}

            {!acceptImage && fileNames.length > 0 && fileNames.join(', ')}
          </div>
        )}
      </div>

      <div className={baseCss + '--wrapper'}>
        <input
          hidden
          accept={props.accept}
          type="file"
          ref={inputRef}
          id="file-input"
          onChange={handleChange}
          multiple={props.multiple}
        />

        <FontAwesomeIcon className="icon" icon={faCloudUpload} />
      </div>
    </div>
  );
};
