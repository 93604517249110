/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Survey,
  SurveyFromJSON,
  SurveyFromJSONTyped,
  SurveyToJSON,
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsFromJSONTyped,
  UserDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface AgreementExtendedAllOf
 */
export interface AgreementExtendedAllOf {
  /**
   *
   * @type {Survey}
   * @memberof AgreementExtendedAllOf
   */
  survey: Survey;
  /**
   *
   * @type {UserDetails}
   * @memberof AgreementExtendedAllOf
   */
  updatedBy?: UserDetails;
  /**
   *
   * @type {string}
   * @memberof AgreementExtendedAllOf
   */
  content?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AgreementExtendedAllOf
   */
  contentIsCustom?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AgreementExtendedAllOf
   */
  useBrandingColor?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AgreementExtendedAllOf
   */
  useCompanyLogo?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof AgreementExtendedAllOf
   */
  modesAvailable?: Array<AgreementExtendedAllOfModesAvailableEnum>;
}

export function AgreementExtendedAllOfFromJSON(
  json: any
): AgreementExtendedAllOf {
  return AgreementExtendedAllOfFromJSONTyped(json, false);
}

export function AgreementExtendedAllOfFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementExtendedAllOf {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    survey: SurveyFromJSON(json['survey']),
    updatedBy: !exists(json, 'updated_by')
      ? undefined
      : UserDetailsFromJSON(json['updated_by']),
    content: !exists(json, 'content') ? undefined : json['content'],
    contentIsCustom: !exists(json, 'content_is_custom')
      ? undefined
      : json['content_is_custom'],
    useBrandingColor: !exists(json, 'use_branding_color')
      ? undefined
      : json['use_branding_color'],
    useCompanyLogo: !exists(json, 'use_company_logo')
      ? undefined
      : json['use_company_logo'],
    modesAvailable: !exists(json, 'modes_available')
      ? undefined
      : json['modes_available'],
  };
}

export function AgreementExtendedAllOfToJSON(
  value?: AgreementExtendedAllOf | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    survey: SurveyToJSON(value.survey),
    updated_by: UserDetailsToJSON(value.updatedBy),
    content: value.content,
    content_is_custom: value.contentIsCustom,
    use_branding_color: value.useBrandingColor,
    use_company_logo: value.useCompanyLogo,
    modes_available: value.modesAvailable,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AgreementExtendedAllOfModesAvailableEnum {
  Survey = 'survey',
  Preview = 'preview',
  Edit = 'edit',
}
