/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FrameworkRequirementCategoryModel
 */
export interface FrameworkRequirementCategoryModel {
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementCategoryModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementCategoryModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementCategoryModel
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementCategoryModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementCategoryModel
   */
  updatedAt: string;
}

export function FrameworkRequirementCategoryModelFromJSON(
  json: any
): FrameworkRequirementCategoryModel {
  return FrameworkRequirementCategoryModelFromJSONTyped(json, false);
}

export function FrameworkRequirementCategoryModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkRequirementCategoryModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    identifier: json['identifier'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function FrameworkRequirementCategoryModelToJSON(
  value?: FrameworkRequirementCategoryModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    identifier: value.identifier,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
