import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Answer, Question } from '../../../swagger';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import AdoptechModal from '../../AdoptechModal/AdoptechModal';
import { AnswerFormControl } from '../../AnswerFormControl/AnswerFormControl';
import './TableAnswerEditModal2.scss';
import { TableActions } from '../TableAnswer2';
import { CommonAnswerType } from '../../../types/CommonAnswerType';
import { Interweave } from 'interweave';

interface TableAnswerMap {
  [key: string]: Answer;
}

interface TableAnswerEditModal2Props {
  rowToChange: { index: number; row: Answer[] };
  question: Question;
  action: TableActions;
  onCancel(): void;
  onUpdate(answers: Answer[]): void;
}

export const TableAnswerEditModal2: React.FC<TableAnswerEditModal2Props> = ({
  rowToChange,
  action,
  question,
  onCancel,
  onUpdate,
}) => {
  const [tableAnswers, setTableAnswers] = useState<TableAnswerMap>({});
  const updateButtonDisabled = Object.values(tableAnswers).some(
    answer => !answer?.value
  );

  const getCurrentAnswerOutsideModal = (questionId: string) => {
    return rowToChange?.row?.find(answer => answer.questionId === questionId);
  };

  useEffect(() => {
    const answers: TableAnswerMap = {};
    question.table.questions.forEach(tableQuestion => {
      return (answers[tableQuestion.propertyName] =
        getCurrentAnswerOutsideModal(tableQuestion.id));
    });
    setTableAnswers(answers);
  }, []);

  const handleChange = (
    answers: CommonAnswerType[],
    questionId: string,
    answerId?: string
  ) => {
    setTableAnswers(currentAnswers => ({
      ...currentAnswers,
      [answers[0].propertyName]: {
        ...answers[0],
        questionId: questionId,
        index: rowToChange.index,
        id: answerId,
        closestQuestion: {
          id: question.id,
          layout: 'table_layout',
        },
      },
    }));
  };

  const handleUpdate = () => {
    if (updateButtonDisabled) {
      return;
    }

    if (action === TableActions.View) {
      onCancel();
      return;
    }

    onUpdate(Object.values(tableAnswers));
    onCancel();
  };

  return (
    <AdoptechModal className="tableAnswerEditModal2" onHide={onCancel} show>
      <Modal.Header>{action} Table Row</Modal.Header>
      <Modal.Body>
        {question.table.questions.map(tableQuestion => (
          <div
            className="tableAnswerEditModal2--question"
            key={tableQuestion.id}
          >
            <div className="tableAnswerEditModal2--questionText">
              {tableQuestion.questionText}
            </div>
            <div className="tableAnswerEditModal2--explanatoryText">
              <Interweave content={tableQuestion.explanatoryTextAsHtml} />
            </div>
            <div>
              <AnswerFormControl
                disabled={action === TableActions.View}
                usingNewSurveyStructure
                question={tableQuestion}
                questions={[]}
                answers={[]}
                previousAnswer={tableAnswers[tableQuestion.propertyName]}
                indexProperty="propertyName"
                onChange={(answers: CommonAnswerType[]) =>
                  handleChange(
                    answers,
                    tableQuestion.id,
                    tableAnswers[tableQuestion.propertyName]?.id
                  )
                }
                disableDebounce
              />
            </div>
            <hr />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={onCancel}>Cancel</AdoptechButton>
        <AdoptechButton
          variant={AdoptechButtonVariant.Primary}
          onClick={handleUpdate}
          disabled={updateButtonDisabled}
        >
          {action === TableActions.Add && 'Add'}
          {action === TableActions.Edit && 'Save'}
          {action === TableActions.View && 'OK'}
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
