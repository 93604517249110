/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrusthubAccessRequestCreatePayloadTrusthubAccessRequest
 */
export interface TrusthubAccessRequestCreatePayloadTrusthubAccessRequest {
  /**
   *
   * @type {string}
   * @memberof TrusthubAccessRequestCreatePayloadTrusthubAccessRequest
   */
  trusthubVendorUserId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TrusthubAccessRequestCreatePayloadTrusthubAccessRequest
   */
  trusthubDocumentIds?: Array<string>;
}

export function TrusthubAccessRequestCreatePayloadTrusthubAccessRequestFromJSON(
  json: any
): TrusthubAccessRequestCreatePayloadTrusthubAccessRequest {
  return TrusthubAccessRequestCreatePayloadTrusthubAccessRequestFromJSONTyped(
    json,
    false
  );
}

export function TrusthubAccessRequestCreatePayloadTrusthubAccessRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubAccessRequestCreatePayloadTrusthubAccessRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    trusthubVendorUserId: !exists(json, 'trusthub_vendor_user_id')
      ? undefined
      : json['trusthub_vendor_user_id'],
    trusthubDocumentIds: !exists(json, 'trusthub_document_ids')
      ? undefined
      : json['trusthub_document_ids'],
  };
}

export function TrusthubAccessRequestCreatePayloadTrusthubAccessRequestToJSON(
  value?: TrusthubAccessRequestCreatePayloadTrusthubAccessRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    trusthub_vendor_user_id: value.trusthubVendorUserId,
    trusthub_document_ids: value.trusthubDocumentIds,
  };
}
