export const getClauseLastUpdatedInfo = (
  updatedAt: string,
  updatedBy: string
): { updatedAt: string; updatedBy: string } => {
  if (updatedAt && updatedBy) {
    return {
      updatedAt,
      updatedBy,
    };
  }
  return null;
};
