import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentType } from '../../../constants/DocumentType';
import { canIndex } from '../../../functions/access';
import { getPolicyDocuments } from '../../../functions/getPolicyDocuments';
import { PdfPreviewEntityPaths } from '../../../functions/routePathsHelpers';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { openPdfPreview } from '../../../store/global/globalSlice';
import {
  closeManageRejectionModal,
  showDocumentLibraryModal,
} from '../../../store/policies/policiesSlice';
import {
  fetchPoliciesForVendor,
  fetchVendorPoliciesDetails,
} from '../../../store/policies/policiesThunks';
import {
  hideSuccessfulVendorDocumentApprovalToast,
  hideSuccessfulVendorDocumentRejectionToast,
  hideSuccessfulVendorDocumentUpdatedToast,
  showSuccessfulVendorDocumentUpdatedToast,
} from '../../../store/vendors/vendorsSlice';
import { fetchVendorPolicyDocuments } from '../../../store/vendors/vendorsThunks';
import { VendorDocument, VendorPolicy } from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';
import { ApplicationState } from '../../../types/applicationState';
import { PdfOpenMode } from '../../../types/pdfOpenMode';
import ExistingPolicyWarningModal, {
  ExistingPolicyWarningModalMode,
} from '../../../components/ExistingPolicyWarningModal/ExistingPolicyWarningModal';
import { InProgressPolicies } from '../../../components/InProgressPolicies/InProgressPolicies';
import { LivePolicies } from '../LivePolicies/LivePolicies';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { ManagePolicyRejectionModal } from '../../../components/ManagePolicyRejectionModal/ManagePolicyRejectionModal';
import {
  MessageToast,
  MessageToastVariant,
} from '../../../components/MessageToast/MessageToast';
import { PendingApprovalPolicies } from '../PendingApprovalPolicies/PendingApprovalPolicies';
import { PolicyDrawer } from '../../../components/PolicyDrawer/PolicyDrawer';
import PolicyTiles from '../PolicyTiles/PolicyTiles';
import { policyPageItemRouteURL } from '../../../components/Routes/Routes';

const PoliciesPageTables: React.FC = () => {
  const dispatch = useDispatch();

  const isExistingPolicyWarningModalShowing = useSelector(
    (state: ApplicationState) =>
      state.policies.isExistingPolicyWarningModalShowing
  );

  const isShowingSuccessfulVendorDocumentApprovalToast = useSelector(
    (state: ApplicationState) =>
      state.vendors.documentState.isShowingSuccessfulVendorDocumentApprovalToast
  );

  const isShowingSuccessfulVendorDocumentUpdatedToast = useSelector(
    (state: ApplicationState) =>
      state.vendors.documentState.isShowingSuccessfulVendorDocumentUpdatedToast
  );

  const isShowingSuccessfulVendorDocumentRejectionToast = useSelector(
    (state: ApplicationState) =>
      state.vendors.documentState
        .isShowingSuccessfulVendorDocumentRejectionToast
  );
  const { isDocumentLibraryModalShowing } = useSelector(
    (state: ApplicationState) => state.policies
  );
  const isManageRejectionModalShowing = useSelector(
    (state: ApplicationState) => state.policies.isManageRejectionModalShowing
  );
  const isFetchingVendorPolicies = useSelector(
    (state: ApplicationState) => state.policies.isFetchingVendorPolicies
  );
  const isFetchingPolicies = useSelector(
    (state: ApplicationState) => state.policies.isFetchingPolicies
  );
  const isFetchingVendorPolicyDocuments = useSelector(
    (state: ApplicationState) =>
      state.vendors.documentState.isFetchingVendorPolicyDocuments
  );
  const isPreviewingPdf = useSelector(
    (state: ApplicationState) => state.global.isPreviewingPdf
  );

  const addOrUpdatePolicyMode = useSelector(
    (state: ApplicationState) => state.policies.existingPolicyWarningModalMode
  );

  const policyDocuments = useSelector(getPolicyDocuments);
  const location = useSelector(
    (state: ApplicationState) => state.router.location
  );
  const currentVendor = useSelector(selectCurrentVendor);
  const queryParams = useQueryParams();
  const vendorDocumentId = queryParams.get('vendorDocumentId');
  const previewMode = queryParams.get('mode') || PdfOpenMode.Preview;

  const canIndexVendorPolicies = canIndex(AccessObject.vendor_policies);
  const canViewDraftPolicies = canIndex(AccessObject.policy_categories);
  const showDraft = window.location.href.includes('draft');

  const refreshPolicies = () => {
    dispatch(fetchVendorPoliciesDetails());
    canIndexVendorPolicies && dispatch(fetchPoliciesForVendor());
    dispatch(fetchVendorPolicyDocuments());
  };

  const handleAddOrUpdatePolicy = (vendorPolicy: VendorPolicy) => {
    const editModes = [
      ExistingPolicyWarningModalMode.Add,
      ExistingPolicyWarningModalMode.Update,
    ];

    if (editModes.includes(addOrUpdatePolicyMode)) {
      dispatch(fetchPoliciesForVendor());
      dispatch(push(policyPageItemRouteURL(vendorPolicy.id)));
      return;
    }

    refreshPolicies();
    dispatch(closeManageRejectionModal());
    dispatch(showSuccessfulVendorDocumentUpdatedToast());
  };

  useEffect(() => {
    refreshPolicies();
  }, [currentVendor?.id]);

  useEffect(() => {
    if (location.pathname.includes('/library')) {
      dispatch(showDocumentLibraryModal());
    }
  }, [location]);

  useEffect(() => {
    if (!policyDocuments?.length || !vendorDocumentId || isPreviewingPdf) {
      return;
    }
    const livePolicyToPreview = policyDocuments.find(
      (vendorDocument: VendorDocument) => vendorDocument.id === vendorDocumentId
    );
    if (!livePolicyToPreview) {
      return;
    }

    dispatch(
      openPdfPreview({
        dueDate: livePolicyToPreview.vendorDocumentAttestation.attestationDueAt,
        mode: previewMode as PdfOpenMode,
        documentType: DocumentType.Policy,
        title: livePolicyToPreview.name,
        vendorDocument: livePolicyToPreview,
        navigateUrlOnClose: PdfPreviewEntityPaths.policies,
      })
    );
  }, [vendorDocumentId, location, policyDocuments, previewMode]);

  const isLoading =
    !isDocumentLibraryModalShowing &&
    (isFetchingVendorPolicies ||
      isFetchingPolicies ||
      isFetchingVendorPolicyDocuments);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="companyPolicies">
            {showDraft && canViewDraftPolicies && (
              <>
                <PolicyTiles />
                <InProgressPolicies />
              </>
            )}

            {!showDraft && (
              <>
                <PendingApprovalPolicies />

                <LivePolicies />
              </>
            )}
            <PolicyDrawer />
            <MessageToast
              autohide
              show={isShowingSuccessfulVendorDocumentRejectionToast}
              onClose={() =>
                dispatch(hideSuccessfulVendorDocumentRejectionToast())
              }
              variant={MessageToastVariant.Warning}
            >
              Policy has been successfully rejected.
            </MessageToast>
            <MessageToast
              autohide
              show={isShowingSuccessfulVendorDocumentUpdatedToast}
              onClose={() =>
                dispatch(hideSuccessfulVendorDocumentUpdatedToast())
              }
              variant={MessageToastVariant.Primary}
            >
              Your document has been successfully created.
            </MessageToast>
            {isManageRejectionModalShowing && <ManagePolicyRejectionModal />}
          </div>

          <MessageToast
            autohide
            show={isShowingSuccessfulVendorDocumentApprovalToast}
            onClose={() =>
              dispatch(hideSuccessfulVendorDocumentApprovalToast())
            }
          >
            Policy has been successfully approved.
          </MessageToast>

          {isExistingPolicyWarningModalShowing && (
            <ExistingPolicyWarningModal onSuccess={handleAddOrUpdatePolicy} />
          )}
        </>
      )}
    </>
  );
};

export default PoliciesPageTables;
