/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum TrusthubSharedStatus {
  Pending = 'pending',
  Approved = 'approved',
  Denied = 'denied',
}

export function TrusthubSharedStatusFromJSON(json: any): TrusthubSharedStatus {
  return TrusthubSharedStatusFromJSONTyped(json, false);
}

export function TrusthubSharedStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubSharedStatus {
  return json as TrusthubSharedStatus;
}

export function TrusthubSharedStatusToJSON(
  value?: TrusthubSharedStatus | null
): any {
  return value as any;
}
