import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';
import { TrustHubSectionProps } from '../CompanyDetailsSection/CompanyDetailsSection';
import classNames from 'classnames';
import { faCloudDownloadAlt } from '@fortawesome/pro-light-svg-icons/faCloudDownloadAlt';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { TrusthubDocumentModel } from '../../../swagger/trusthub/models/TrusthubDocumentModel';
import { DocumentsSection } from '../DocumentsSection/DocumentsSection';
import { certificatesLogos } from '../admin/EditSectionRow/EditSectionRow';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck';
import { faLeaf } from '@fortawesome/pro-light-svg-icons/faLeaf';

interface SectionRowProps {
  identifier: string;
  baseCss: string;
  doc: TrusthubDocumentModel;
  onDocumentClick: (doc: TrusthubDocumentModel) => void;
  showDocumentDownloadIcon: boolean;
}

export const SectionRow: React.FC<SectionRowProps> = ({
  identifier,
  baseCss,
  doc,
  onDocumentClick,
  showDocumentDownloadIcon,
}) => {
  const { fetchVendorDocumentFileStatus, fetchingVendorDocumentIdFile } =
    useSelector((state: ApplicationState) => state.trustHub);

  const isCertificates = baseCss.includes('certificate');
  return (
    <div
      className={baseCss + '--document'}
      onClick={() => onDocumentClick && onDocumentClick(doc)}
    >
      {isCertificates ? (
        <img
          src={certificatesLogos[doc.name as keyof typeof certificatesLogos]}
          className={baseCss + '--documentLogo'}
        />
      ) : (
        <FontAwesomeIcon
          icon={identifier === 'compliance' ? faShieldCheck : faLeaf}
        />
      )}
      <div className={baseCss + '--documentTitle'}>{doc.name}</div>
      {fetchVendorDocumentFileStatus === 'loading' &&
      fetchingVendorDocumentIdFile === doc.id ? (
        <LoadingSpinner inlineSmall />
      ) : (
        <FontAwesomeIcon
          icon={doc.restricted ? faLockAlt : faCloudDownloadAlt}
          className={classNames({
            invisible: !showDocumentDownloadIcon,
          })}
        />
      )}
    </div>
  );
};
