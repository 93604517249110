import { faArchive } from '@fortawesome/pro-light-svg-icons/faArchive';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  canCreate,
  hasFeature,
  mapShareFeaturesToAccessLevels,
} from '../../functions/access';
import { capitalize } from '../../functions/capitalize';
import { formatShortDate } from '../../functions/formatShortDate';
import { selectAllDocuments } from '../../selectors/selectAllDocuments';
import { showEditOwnerModal } from '../../store/global/globalSlice';
import { showShareModal } from '../../store/vendors/vendorsSlice';
import {
  archiveVendorDocument,
  fetchVendorDocumentDownload,
} from '../../store/vendors/vendorsThunks';
import { ShareableType, VendorDocument } from '../../swagger';
import { AccessObject } from '../../types/accessObject';
import { Grid } from '../../types/grid';
import { OwnerObjectType } from '../../types/OwnerObjectType';
import { PdfOpenMode } from '../../types/pdfOpenMode';
import { MeatballMenu } from '../MeatballMenu/MeatballMenu';
import { NoDataText } from '../NoDataText/NoDataText';
import { SortableTableHeader } from '../SortableTableHeader/SortableTableHeader';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import './AllDocuments.scss';
import { allDocumentsPageItemRoute } from '../Routes/Routes';
import AdoptechGridTable from '../AdoptechGridTable/AdoptechGridTable';
import AdoptechOverflowLine from '../AdoptechOverflowLine/AdoptechOverflowLine';

export const AllDocuments: React.FC = () => {
  const allDocuments = useSelector(selectAllDocuments);

  const baseTableCss = 'adoptechGridTable';

  return (
    <div className="allDocuments">
      {allDocuments.length > 0 ? (
        <AdoptechGridTable
          header={
            <div className={baseTableCss + '--header'}>
              <SortableTableHeader<VendorDocument>
                columnName="name"
                grid={Grid.AllDocuments}
                label="Name"
              />
              <SortableTableHeader<VendorDocument>
                columnName="version"
                grid={Grid.AllDocuments}
                label="Version"
              />
              <SortableTableHeader<VendorDocument>
                columnName="documentType"
                grid={Grid.AllDocuments}
                label="Type"
              />
              <SortableTableHeader<VendorDocument>
                columnName="updatedAt"
                grid={Grid.AllDocuments}
                label="Last updated"
              />
              <SortableTableHeader<VendorDocument>
                columnName="owner"
                grid={Grid.AllDocuments}
                label="Owner"
              />
              <SortableTableHeader<VendorDocument>
                columnName="owner"
                disabledSort
                grid={Grid.AllDocuments}
                label="Actions"
              />
            </div>
          }
        >
          {allDocuments.map(doc => (
            <AllDocumentsRow key={doc.id} doc={doc} />
          ))}
        </AdoptechGridTable>
      ) : (
        <NoDataText text="No documents found" />
      )}
    </div>
  );
};

const AllDocumentsRow: React.FC<{
  doc: VendorDocument;
}> = ({ doc }) => {
  const baseTableCss = 'adoptechGridTable';

  const dispatch = useDispatch();
  const canManageDocuments = canCreate(AccessObject.vendor_documents);

  return (
    <div className={baseTableCss + '--row'}>
      <AdoptechOverflowLine>{doc.name}</AdoptechOverflowLine>
      <div className="allDocuments--version">{doc.version}</div>
      <AdoptechOverflowLine>
        {capitalize(doc.documentType)}
      </AdoptechOverflowLine>
      <div className="allDocuments--updatedAt">
        {formatShortDate(doc.updatedAt)}
      </div>
      <AdoptechOverflowLine>
        <div
          onClick={() =>
            canManageDocuments
              ? dispatch(
                  showEditOwnerModal({
                    object: doc,
                    type: OwnerObjectType.VendorDocument,
                  })
                )
              : {}
          }
        >
          <UserAvatar user={doc.owner} size="small" />
        </div>
      </AdoptechOverflowLine>
      <div className="allDocuments--actions">
        <MeatballMenu>
          <Dropdown.Item
            onClick={e => {
              dispatch(
                push(
                  `${allDocumentsPageItemRoute.replace(
                    ':documentId',
                    doc.id
                  )}?mode=${PdfOpenMode.Preview}`
                )
              );
              e.stopPropagation();
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faEye} />
            Preview
          </Dropdown.Item>
          <Dropdown.Item
            className="meatballMenu--item"
            disabled={!hasFeature(AccessObject.share, doc?.access)}
            onClick={e => {
              dispatch(
                showShareModal({
                  shareableId: doc.id,
                  shareableType: ShareableType.VendorDocument,
                  accessLevels: mapShareFeaturesToAccessLevels(
                    doc.access.feature
                  ),
                  relativePath: doc.relativePath,
                })
              );
              e.stopPropagation();
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faUserPlus} />
            Share
          </Dropdown.Item>
          {canManageDocuments && (
            <Dropdown.Item
              onClick={e => {
                dispatch(
                  showEditOwnerModal({
                    object: doc,
                    type: OwnerObjectType.VendorDocument,
                  })
                );
                e.stopPropagation();
              }}
            >
              <FontAwesomeIcon
                className="meatballMenu--icon"
                icon={faFileContract}
              />
              Edit owner
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={e => {
              dispatch(
                fetchVendorDocumentDownload({
                  id: doc.id,
                  successCallback: vendorDocumentDownload => {
                    window.location.href = vendorDocumentDownload.document;
                  },
                })
              );

              e.stopPropagation();
            }}
          >
            <FontAwesomeIcon
              className="meatballMenu--icon"
              icon={faCloudDownload}
            />
            Download
          </Dropdown.Item>
          <Dropdown.Item
            onClick={e => {
              dispatch(archiveVendorDocument(doc));
              e.stopPropagation();
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faArchive} />
            Archive
          </Dropdown.Item>
        </MeatballMenu>
      </div>
    </div>
  );
};
