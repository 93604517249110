import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { formatShortDate } from '../../functions/formatShortDate';
import {
  VendorDocumentApproval,
  VendorDocumentApprovalStatusEnum,
} from '../../swagger';
import './ApprovedStatus.scss';

export interface ApprovedStatusProps {
  approval?: VendorDocumentApproval;
  notRequestedTitle?: string;
}

export const ApprovedStatus: React.FC<ApprovedStatusProps> = ({
  approval,
  notRequestedTitle = '',
}) => {
  if (
    !approval ||
    (approval.status !== VendorDocumentApprovalStatusEnum.Current &&
      approval.status !== VendorDocumentApprovalStatusEnum.WasNotRequested)
  ) {
    return null;
  }

  const classes = classNames(
    'approvedStatus--icon',
    'approvedStatus--icon-green'
  );

  const icon = faCheckCircle;

  return (
    <div className="approvedStatus">
      {approval.status === VendorDocumentApprovalStatusEnum.Current && (
        <FontAwesomeIcon
          className={classes}
          icon={icon}
          title={approval.status}
        />
      )}
      {formatShortDate(approval.lastApprovedAt) || notRequestedTitle}
    </div>
  );
};
