import { getMetaContent } from './getMetaContent';

export const getRequest = async (url: string) => {
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-CSRF-Token': getMetaContent('csrf-token'),
    },
  });
};
