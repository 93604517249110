import React, { useRef, useState } from 'react';
import AdoptechHS from '../AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import {
  LegalRegisterModel,
  ReviewFrequencyEnum,
  VendorsVendorIdReviewHistoryItemsGetRelatedToTypeEnum,
} from '../../swagger';
import { OverviewSection } from './OverviewSection';
import { Tab, Tabs } from 'react-bootstrap';
import { ReviewHistoryItems } from '../compliance/ReviewHistoryItems/ReviewHistoryItems';
import './RegisterReviewDrawer.scss';
import { RegistryHeaderProps } from '../RegistryHeader/RegisterHeader';
import { useDispatch, useSelector } from 'react-redux';
import { patchLegalRegister } from '../../store/legalRegister/legalRegisterThunks';
import { patchPestelRegister } from '../../store/pestel/pestelThunks';
import { ApplicationState } from '../../types/applicationState';

interface RegisterReviewDrawerProps {
  onClose: (updateNeeded: boolean) => void;
  register: RegistryHeaderProps['register'];
  registerType: RegistryHeaderProps['registerType'];
}

export interface RegisterReviewFormProps {
  reviewComments: string;
  nextReviewDate: string;
  reviewSubmitted: boolean;
  reviewFrequency: ReviewFrequencyEnum;
}

export enum TabsEnum {
  Details = 'Details',
  ReviewHistoryItemsTab = 'Review History',
}

export const RegisterReviewDrawer: React.FC<RegisterReviewDrawerProps> = ({
  onClose,
  register,
  registerType,
}) => {
  const drawerBackgroundRef = useRef<HTMLDivElement>();
  const [formData, setFormData] = useState<RegisterReviewFormProps>(null);
  const [selectedTab, setSelectedTab] = useState<TabsEnum>(TabsEnum.Details);

  const [originalRegister, _] =
    useState<RegistryHeaderProps['register']>(register);

  const frequencyChanged =
    formData?.reviewFrequency &&
    formData?.reviewFrequency !==
      (originalRegister as LegalRegisterModel)?.reviewFrequency;
  const reviewFieldsChanged =
    Boolean(formData?.reviewComments) || frequencyChanged;

  const reviewFieldsChangedAndNotSaved =
    !formData?.reviewSubmitted && reviewFieldsChanged;
  const onCloseAndCleanup = () => {
    setFormData(null);
    onClose(true);
  };

  const baseCss = 'registerReviewDrawer';
  const isLegal = registerType === 'LegalRegister';
  const dispatch = useDispatch();

  const isPatchingLegalRegister = useSelector(
    (state: ApplicationState) => state.legalRegister.isPatchingLegalRegister
  );
  const isPatchingPestelRegister = useSelector(
    (state: ApplicationState) => state.pestel.isPatchingPestelRegister
  );
  return (
    <AdoptechHS
      title="Register Review"
      show
      noFormPadding
      ref={drawerBackgroundRef}
      showConfirmationWarning={reviewFieldsChangedAndNotSaved}
      onClose={onCloseAndCleanup}
      footer={
        <>
          <AdoptechButton
            onClick={() => drawerBackgroundRef.current.click()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={() => {
              const payload = {
                reviewFrequency: formData.reviewFrequency,
              };
              if (!payload.reviewFrequency) return onCloseAndCleanup();

              const patchThunk = isLegal
                ? patchLegalRegister
                : patchPestelRegister;
              dispatch(patchThunk(payload, onCloseAndCleanup));
            }}
            variant={AdoptechButtonVariant.Primary}
            disabled={false}
            busy={isPatchingLegalRegister || isPatchingPestelRegister}
          >
            Save
          </AdoptechButton>
        </>
      }
      extraClass="adoptechHS--medium editLegislationHS"
    >
      {register ? (
        <div className={baseCss}>
          <div className={baseCss + '--formContainer'}>
            <Tabs
              activeKey={selectedTab}
              onSelect={(key: string) => {
                setSelectedTab(key as TabsEnum);
              }}
            >
              <Tab title={TabsEnum.Details} eventKey={TabsEnum.Details}>
                {selectedTab === TabsEnum.Details && (
                  <OverviewSection
                    formData={formData || ({} as RegisterReviewFormProps)}
                    setFormData={setFormData}
                    register={register}
                    registerType={registerType}
                  />
                )}
              </Tab>
              <Tab
                title={TabsEnum.ReviewHistoryItemsTab}
                eventKey={TabsEnum.ReviewHistoryItemsTab}
                disabled={!register}
              >
                {selectedTab === TabsEnum.ReviewHistoryItemsTab && (
                  <ReviewHistoryItems
                    title="Register review history"
                    type={
                      isLegal
                        ? VendorsVendorIdReviewHistoryItemsGetRelatedToTypeEnum.LegalRegister
                        : VendorsVendorIdReviewHistoryItemsGetRelatedToTypeEnum.PestelRegister
                    }
                    id={register?.id}
                  />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </AdoptechHS>
  );
};
