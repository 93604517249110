import { getMetaContent } from '../../../functions/getMetaContent';
import {
  VendorActionsVendorActionIdPatchRequest,
  VendorEventsVendorEventIdPatchRequest,
} from '../../../swagger';
import {
  createCorrectiveActionParams,
  createEventParams,
  createIncidentParams,
  createNewActionParams,
  updateActionParams,
  updateCorrectiveActionParams,
  updateEventParams,
  updateIncidentParams,
} from './calendarThunks';

export type WithoutVendorId<T> = Omit<T, 'vendorId'>;

// All helpers needed to support multiple attachments upload
// In both cases:
// - type: file, or type: string, format: binary
// Swagger Code Generator makes two mistakes in the generated code:
//   1. `useFormData` not used which results in an invalid `Content-Type` header
//   2. Incorrect handling of arrays of files, resulting in an invalid array of files format
// - Swagger documentation: https://swagger.io/docs/specification/2-0/file-upload/
// - Related issue: https://github.com/OAI/OpenAPI-Specification/issues/254
// TODO: Consider updating to the latest version of https://github.com/OpenAPITools/openapi-generator-cli,
//       particularly version 2.0, to potentially resolve these issues.
// TODO: Plan for an update to OpenAPI 3.0, as it supports multiple file uploads natively.

export const buildActionFormData = (
  payload: WithoutVendorId<
    createNewActionParams['payload'] | updateActionParams
  >
): FormData => {
  const formData = new FormData();
  const root = 'vendor_action';
  formData.append(`${root}[name]`, payload.vendorActionName);
  if (payload.vendorActionDescription) {
    formData.append(`${root}[description]`, payload.vendorActionDescription);
  }
  formData.append(`${root}[due_date]`, payload.vendorActionDueDate);
  formData.append(`${root}[action_type]`, payload.vendorActionActionType);
  formData.append(`${root}[owner_id]`, payload.vendorActionOwnerId);

  if (payload.vendorActionUrl) {
    formData.append(`${root}[url]`, payload.vendorActionUrl);
  }

  if (payload.vendorActionAttachments) {
    payload.vendorActionAttachments.forEach(file => {
      formData.append(`${root}[attachments][]`, file);
    });
  }

  type UpdatePayload = VendorActionsVendorActionIdPatchRequest;
  const updatePayload = payload as UpdatePayload;
  if (updatePayload.vendorActionDeletedAttachmentIds) {
    updatePayload.vendorActionDeletedAttachmentIds.forEach(id => {
      formData.append(`${root}[deleted_attachment_ids][]`, id as any);
    });
  }

  if (updatePayload.vendorActionCompleted !== undefined) {
    formData.append(
      `${root}[completed]`,
      updatePayload.vendorActionCompleted as any
    );
  }

  return formData;
};

export const rawRequest = async (path: string, options: RequestInit) => {
  const rawResponse = await fetch(path, {
    ...{
      headers: {
        'X-CSRF-Token': getMetaContent('csrf-token'),
      },
    },
    ...options,
  });
  return rawResponse;
};

export const buildCorrectiveActionFormData = (
  payload: WithoutVendorId<
    createCorrectiveActionParams | updateCorrectiveActionParams
  >
): FormData => {
  const formData = new FormData();
  const root = 'corrective_action';
  formData.append(`${root}[name]`, payload.correctiveActionName);
  formData.append(`${root}[description]`, payload.correctiveActionDescription);
  formData.append(
    `${root}[date_identified]`,
    payload.correctiveActionDateIdentified
  );
  formData.append(`${root}[raised_by]`, payload.correctiveActionRaisedBy);
  formData.append(`${root}[root_cause]`, payload.correctiveActionRootCause);
  formData.append(`${root}[action_type]`, payload.correctiveActionActionType);
  formData.append(
    `${root}[corrective_actions]`,
    payload.correctiveActionCorrectiveActions
  );

  if (payload.correctiveActionIdentifier) {
    formData.append(`${root}[identifier]`, payload.correctiveActionIdentifier);
  }

  if (payload.correctiveActionOwnerId) {
    formData.append(`${root}[owner_id]`, payload.correctiveActionOwnerId);
  }

  if (payload.correctiveActionUrl) {
    formData.append(`${root}[url]`, payload.correctiveActionUrl);
  }

  if (payload.correctiveActionAttachments) {
    payload.correctiveActionAttachments.forEach(file => {
      formData.append(`${root}[attachments][]`, file);
    });
  }

  type UpdatePayload = updateCorrectiveActionParams;
  const updatePayload = payload as UpdatePayload;
  if (updatePayload.correctiveActionDeletedAttachmentIds) {
    updatePayload.correctiveActionDeletedAttachmentIds.forEach(id => {
      formData.append(`${root}[deleted_attachment_ids][]`, id as any);
    });
  }

  if (updatePayload.correctiveActionCompleted !== undefined) {
    formData.append(
      `${root}[completed]`,
      updatePayload.correctiveActionCompleted as any
    );
  }

  return formData;
};

export const buildIncidentFormData = (
  payload: WithoutVendorId<createIncidentParams | updateIncidentParams>
): FormData => {
  const formData = new FormData();
  const root = 'incident_event';

  formData.append(`${root}[name]`, payload.incidentEventName);
  formData.append(`${root}[description]`, payload.incidentEventDescription);
  formData.append(
    `${root}[date_identified]`,
    payload.incidentEventDateIdentified
  );
  formData.append(`${root}[raised_by]`, payload.incidentEventRaisedBy);
  formData.append(`${root}[root_cause]`, payload.incidentEventRootCause);
  formData.append(
    `${root}[preventative_actions]`,
    payload.incidentEventPreventativeActions
  );
  formData.append(`${root}[actions_taken]`, payload.incidentEventActionsTaken);
  formData.append(
    `${root}[data_breach]`,
    payload.incidentEventDataBreach.toString()
  );

  if (payload.incidentEventIdentifier) {
    formData.append(`${root}[identifier]`, payload.incidentEventIdentifier);
  }

  if (payload.incidentEventOwnerId) {
    formData.append(`${root}[owner_id]`, payload.incidentEventOwnerId);
  }

  if (payload.incidentEventUrl) {
    formData.append(`${root}[url]`, payload.incidentEventUrl);
  }

  if (payload.incidentEventAttachments) {
    payload.incidentEventAttachments.forEach(file => {
      formData.append(`${root}[attachments][]`, file);
    });
  }

  if (payload.incidentEventDataBreachDetails) {
    formData.append(
      `${root}[data_breach_details]`,
      payload.incidentEventDataBreachDetails
    );
  }

  type UpdatePayload = updateIncidentParams;
  const updatePayload = payload as UpdatePayload;

  if (updatePayload.incidentEventDeletedAttachmentIds) {
    updatePayload.incidentEventDeletedAttachmentIds.forEach(id => {
      formData.append(`${root}[deleted_attachment_ids][]`, id as any);
    });
  }

  if (updatePayload.incidentEventCompleted !== undefined) {
    formData.append(
      `${root}[completed]`,
      updatePayload.incidentEventCompleted.toString()
    );
  }

  return formData;
};

export const buildEventFormData = (
  payload: WithoutVendorId<createEventParams | updateEventParams>
): FormData => {
  const formData = new FormData();
  const root = 'vendor_event';
  formData.append(`${root}[name]`, payload.vendorEventName);
  formData.append(`${root}[description]`, payload.vendorEventDescription ?? '');
  formData.append(`${root}[date]`, payload.vendorEventDate);
  formData.append(`${root}[event_type]`, payload.vendorEventEventType);
  formData.append(`${root}[owner_id]`, payload.vendorEventOwnerId);

  if (payload.vendorEventUrl) {
    formData.append(`${root}[url]`, payload.vendorEventUrl);
  }

  if (payload.vendorEventAttachments) {
    payload.vendorEventAttachments.forEach(file => {
      formData.append(`${root}[attachments][]`, file);
    });
  }

  type UpdatePayload = VendorEventsVendorEventIdPatchRequest;
  const updatePayload = payload as UpdatePayload;
  if (updatePayload.vendorEventDeletedAttachmentIds) {
    updatePayload.vendorEventDeletedAttachmentIds.forEach(id => {
      formData.append(`${root}[deleted_attachment_ids][]`, id as any);
    });
  }

  return formData;
};
