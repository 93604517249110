import { Interweave } from 'interweave';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useQueryParams } from '../../hooks/useQueryParams';
import { AgreementTemplateModel } from '../../swagger/models/AgreementTemplateModel';
import { Policy } from '../../swagger/models/Policy';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import PartOfMyPlanBanner from '../PartOfMyPlanBanner/PartOfMyPlanBanner';
import './DocumentLibraryDescriptionModal.scss';

const DocumentLibraryDescriptionModal = ({
  selectedDocument,
  onCancel,
  onCreate,
}: {
  selectedDocument: Policy | AgreementTemplateModel;
  onCancel: () => void;
  onCreate: () => void;
}) => {
  const vendorProducts = useSelector(
    (state: ApplicationState) => state.vendors.vendorProducts
  );
  const queryParams = useQueryParams();

  if (!selectedDocument) {
    return null;
  }

  const currentVendorProducts = queryParams.get('agreementTemplateId')
    ? vendorProducts?.agreementTemplates
    : vendorProducts?.policies;

  const isPartOfPlan = currentVendorProducts?.some(
    template => template.name === selectedDocument.name
  );

  const baseCss = 'documentLibraryDescriptionModal';

  return (
    <AdoptechModal onHide={onCancel} show className={baseCss}>
      <Modal.Header>
        <div className="documentLibraryDescriptionModal--title">
          {selectedDocument.name}
          {isPartOfPlan && <PartOfMyPlanBanner />}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className={`${baseCss}--descriptionTitle`}>Summary</div>
        <div className={`${baseCss}--summaryAsHtml`}>
          <Interweave content={selectedDocument.summaryAsHtml} />
        </div>
        <div className={`${baseCss}--descriptionTitle`}>Description</div>
        <div className="documentLibraryDescriptionModal--descriptionAsHtml">
          <Interweave content={selectedDocument.descriptionAsHtml} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={onCancel}>Cancel</AdoptechButton>
        <AdoptechButton
          onClick={onCreate}
          variant={AdoptechButtonVariant.Primary}
        >
          Create
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};

export default DocumentLibraryDescriptionModal;
