import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentAgreement } from '../../../selectors/selectCurrentAgreement';
import {
  patchAgreement,
  updateExternalAgreementSignatory,
} from '../../../store/agreements/agreementsThunks';
import { AgreementSignatoriesIdPatchRequest } from '../../../swagger';
import { updateAgreementsEditFormField } from '../../../store/agreements/agreementsSlice';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { DocumentUploadStepIsValid } from './DocumentUploadStep/DocumentUploadStep';
import {
  AgreementEditFormStep,
  AgreementSignatoryModelExtended,
} from './EditAgreementForm';
import { PrimaryStepIsValid } from './PrimaryStep/PrimaryStep';
import { SignatoryStepIsValid } from './SignatoriesEditStep/SignatoriesEditStep';
import { capitaliseFirst } from '../../../functions/capitaliseFirst';
import { handleSubmitClass } from '../../ExternalSignatoryForm/ExternalSignatoryForm';

interface FooterProps {
  onClose: () => void;
  upload?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ onClose, upload }) => {
  const baseCss = 'editAgreementForm';
  const dispatch = useDispatch();
  const form = useSelector(
    (state: ApplicationState) => state.agreements.agreementsEditForm
  );

  const signatories = form.signatoriesWithDocs || [];

  const updatedSignatoryIds = useSelector(
    (state: ApplicationState) =>
      state.agreements.currentAgreementSignatoriesUpdated
  );

  const signatoriesToUpdate = useSelector((state: ApplicationState) =>
    state.agreements.currentAgreementSignatories?.filter(cas =>
      updatedSignatoryIds.includes(cas.id)
    )
  );

  const currentId = useSelector(
    (state: ApplicationState) => state.agreements.currentAgreementId
  );

  const isPrimary = form.step === AgreementEditFormStep.Primary;
  const currentAgreement = useSelector(selectCurrentAgreement);
  const patchSignatory = (
    signatoryUpdatePayload: AgreementSignatoriesIdPatchRequest
  ) => {
    dispatch(
      updateExternalAgreementSignatory({
        body: signatoryUpdatePayload,
        onSuccess: () => {},
        onError: () => {},
      })
    );
  };

  const convertToUpdatePayload = (
    signatory: AgreementSignatoryModelExtended
  ): AgreementSignatoriesIdPatchRequest => {
    const { id, documentBinary, ...signatoryFields } = signatory;

    const payload = Object.fromEntries(
      Object.entries(signatoryFields).map(([k, v]) => [
        `agreementSignatory${capitaliseFirst(k)}`,
        v,
      ])
    ) as unknown as AgreementSignatoriesIdPatchRequest;

    const body: AgreementSignatoriesIdPatchRequest = {
      ...payload,
      agreementSignatoryDocument: documentBinary,
      id,
    };

    return body;
  };

  const handleSubmit = () => {
    if (form.step == AgreementEditFormStep.SignatoriesEdit) {
      (
        document.getElementsByClassName(
          handleSubmitClass
        )[0] as HTMLElement | null
      )?.click();
    }

    if (upload) {
      dispatch(
        updateAgreementsEditFormField({
          step: AgreementEditFormStep.Primary,
        })
      );
    } else {
      const toBeUpdated = signatoriesToUpdate.filter(
        stu => !signatories.find(swd => swd.id === stu.id)
      );
      toBeUpdated.forEach(stbu =>
        patchSignatory({
          ...stbu,
          agreementSignatoryStatus: stbu.status,
        })
      );

      signatories.forEach(
        (extendedSignatory: AgreementSignatoryModelExtended) =>
          patchSignatory(convertToUpdatePayload(extendedSignatory))
      );
    }

    if (!isPrimary && upload) return;

    dispatch(
      patchAgreement({
        agreementId: currentAgreement.id,
        reviewDate: form.reviewDate,
        name: form.name,
        ownerId: form.owner?.id,
        useBrandingColor: form.useBrandingColor,
        useCompanyLogo: form.useCompanyLogo,
        onSuccess: onClose,
      })
    );
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  const isStepValid = () => {
    if (isPrimary) {
      return PrimaryStepIsValid(form);
    }

    if (form.step === AgreementEditFormStep.SignatoriesEdit) {
      return SignatoryStepIsValid(currentAgreement, form);
    }

    if (form.step === AgreementEditFormStep.DocumentUpload) {
      return DocumentUploadStepIsValid(currentAgreement, form);
    }

    return false;
  };
  const { isCreatingOrUpdatingSignatory, isPatchingAgreement } = useSelector(
    (state: ApplicationState) => state.agreements
  );
  const isBusy = isCreatingOrUpdatingSignatory || isPatchingAgreement;

  return (
    <>
      {(isPrimary || upload) && (
        <AdoptechButton
          onClick={handleCancel}
          variant={AdoptechButtonVariant.White}
        >
          Cancel
        </AdoptechButton>
      )}
      <div className={baseCss + '--buttons-row'}>
        <AdoptechButton
          disabled={!isStepValid()}
          onClick={handleSubmit}
          busy={isBusy}
          variant={AdoptechButtonVariant.Primary}
        >
          {upload ? 'UPLOAD' : 'DONE'}
        </AdoptechButton>
      </div>
    </>
  );
};
