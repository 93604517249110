import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faCommentEdit } from '@fortawesome/pro-light-svg-icons/faCommentEdit';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';

import { faArchive } from '@fortawesome/pro-light-svg-icons/faArchive';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hasFeature } from '../../../functions/access';

import {
  setCurrentAgreementId,
  toggleEditAgreementDrawer,
  updateAgreementsEditFormField,
} from '../../../store/agreements/agreementsSlice';
import { archiveAgreement } from '../../../store/agreements/agreementsThunks';
import { fetchVendorDocumentDownload } from '../../../store/vendors/vendorsThunks';
import { AgreementModel, AgreementModelStatusEnum } from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';
import { RowProps } from '../AgreementsList/AgreementsRow';
import { MeatballMenu } from '../../MeatballMenu/MeatballMenu';
import { AgreementEditFormStep } from '../EditAgreementForm/EditAgreementForm';
import { useParams } from 'react-router';

interface MeatballProps {
  agreement: RowProps['agreement'];
  handleDeleteClick: RowProps['handleDeleteClick'];
}
const isDraft = (agreement: AgreementModel) =>
  agreement.status === AgreementModelStatusEnum.Draft;
const isCompleted = (agreement: AgreementModel) =>
  agreement.status === AgreementModelStatusEnum.Signed;

export const agreementsAccess = (agreement: AgreementModel) => {
  return {
    canEditOwner: hasFeature(AccessObject.manage_ownership, agreement?.access),
    canEditReviewDate: hasFeature(
      AccessObject.set_review_reminder,
      agreement?.access
    ),
    canShare: hasFeature(AccessObject.share, agreement?.access),
    canEditBranding: isDraft(agreement),
    canDelete: isDraft(agreement),
    canEditName: isDraft(agreement),
    canEditSignatories: isDraft(agreement),
    canArchive: isCompleted(agreement),
    canUploadSignatoryDocument:
      agreement.status === AgreementModelStatusEnum.AwaitingSignature,
  };
};

export const AgreementMeatballMenu: React.FC<MeatballProps> = ({
  agreement,
  handleDeleteClick,
}) => {
  const dispatch = useDispatch();
  const access = agreementsAccess(agreement);

  const showHSMenu = () => {
    dispatch(setCurrentAgreementId(agreement.id));
    dispatch(toggleEditAgreementDrawer());
  };

  const handleViewOrEditSignatories = () => {
    if (agreementsAccess(agreement).canEditSignatories) {
      dispatch(
        updateAgreementsEditFormField({
          step: AgreementEditFormStep.SignatoriesEdit,
        })
      );
    }
    showHSMenu();
  };
  const params = useParams() as { id?: string };
  const isAgreementShowPage = params.id;
  return (
    <MeatballMenu>
      {access.canEditName && (
        <Dropdown.Item className="meatballMenu--item" onClick={showHSMenu}>
          <FontAwesomeIcon className="meatballMenu--icon" icon={faPen} />
          Edit name
        </Dropdown.Item>
      )}
      {access.canEditOwner && (
        <Dropdown.Item onClick={showHSMenu}>
          <FontAwesomeIcon className="meatballMenu--icon" icon={faPen} />
          Edit owner
        </Dropdown.Item>
      )}

      <Dropdown.Item
        className="meatballMenu--item"
        disabled={!access.canEditReviewDate}
        onClick={showHSMenu}
      >
        <FontAwesomeIcon className="meatballMenu--icon" icon={faCalendar} />
        Set review date
      </Dropdown.Item>
      {agreementsAccess(agreement).canEditBranding && (
        <Dropdown.Item className="meatballMenu--item" onClick={showHSMenu}>
          <FontAwesomeIcon
            className="meatballMenu--icon"
            icon={faCommentEdit}
          />
          Edit branding
        </Dropdown.Item>
      )}
      {agreement.status !== 'rejected' && (
        <>
          <Dropdown.Item
            className="meatballMenu--item"
            onClick={handleViewOrEditSignatories}
          >
            <FontAwesomeIcon
              className="meatballMenu--icon"
              icon={faFileContract}
            />
            {isDraft(agreement) ? 'Signatories' : 'View Signatories'}
          </Dropdown.Item>
        </>
      )}

      {agreementsAccess(agreement).canArchive && (
        <Dropdown.Item
          onClick={() =>
            dispatch(
              archiveAgreement({
                id: agreement.id,
                name: agreement.name,
              })
            )
          }
        >
          <FontAwesomeIcon className="meatballMenu--icon" icon={faArchive} />
          Archive
        </Dropdown.Item>
      )}

      {agreementsAccess(agreement).canDelete ? (
        <>
          <Dropdown.Divider />
          <Dropdown.Item
            className="meatballMenu--item"
            onClick={() => {
              dispatch(() => handleDeleteClick(agreement.id));
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faTrashAlt} />
            Delete
          </Dropdown.Item>
        </>
      ) : (
        !isAgreementShowPage && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() => {
                dispatch(
                  fetchVendorDocumentDownload({
                    id: agreement.vendorDocumentId,
                    successCallback: vendorDocumentDownload => {
                      window.location.href = vendorDocumentDownload.document;
                    },
                  })
                );
              }}
            >
              <FontAwesomeIcon
                className="meatballMenu--icon"
                icon={faCloudDownload}
              />
              Download
            </Dropdown.Item>
          </>
        )
      )}
    </MeatballMenu>
  );
};
