import React from 'react';
import { Panel } from '../../Panel/Panel';
import './AgreementsTextEditorFooter.scss';
import { UserDetails } from '../../../swagger';
import { formatLongDate } from '../../../functions/formatLongDate';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';

interface AgreementsTextEditorFooterProps {
  doneDisabled?: boolean;
  onDone: () => void;
  updatedBy?: UserDetails;
  updatedAt: string;
}

const AgreementsTextEditorFooter: React.FC<
  AgreementsTextEditorFooterProps
> = props => {
  return (
    <Panel>
      <div className="agreementsTextEditorFooter">
        {props.updatedBy ? (
          <>
            <div className="agreementsTextEditorFooter--label">
              Last update by
            </div>
            <div className="agreementsTextEditorFooter--lastUpdatedBy">
              {props.updatedBy.fullName}
            </div>
          </>
        ) : (
          <div className="agreementsTextEditorFooter--label">
            Last updated at
          </div>
        )}
        <div className="agreementsTextEditorFooter--lastUpdatedAt">
          {formatLongDate(props.updatedAt)}
        </div>
        <div className="agreementsTextEditorFooter--button">
          <AdoptechButton
            disabled={props.doneDisabled}
            onClick={props.onDone}
            type="button"
            variant={AdoptechButtonVariant.Primary}
          >
            DONE
          </AdoptechButton>
        </div>
      </div>
    </Panel>
  );
};

export default AgreementsTextEditorFooter;
