import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SuggestionComponentProps,
  Tag,
  TagComponentProps,
} from 'react-tag-autocomplete';
import { isValidEmailAddress } from '../../functions/isValidEmailAddress';
import { fetchShareSuggestions } from '../../store/vendors/vendorsThunks';
import { ShareableType } from '../../swagger/models';
import { ApplicationState } from '../../types/applicationState';
import { AdoptechTagAutocomplete } from '../AdoptechTagAutocomplete/AdoptechTagAutocomplete';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { selectUsers } from '../../selectors/selectUsers';

interface ShareAutocompleteProps {
  usersToShare: Tag[];
  onUpdate: (newUsers: Tag[]) => void;
  vendorId: string;
  shareableType: ShareableType;
  shareableId: string;
  minQueryLength?: number;
}

export const ShareAutocomplete: React.FC<ShareAutocompleteProps> = ({
  usersToShare,
  onUpdate,
  vendorId,
  shareableType,
  shareableId,
  minQueryLength,
}: ShareAutocompleteProps) => {
  const dispatch = useDispatch();
  const shareSuggestions = useSelector(
    (state: ApplicationState) => state.vendors.shareSuggestions
  );
  const [suggestionOptions, setSuggestionOptions] = useState<Tag[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputValue) {
        dispatch(
          fetchShareSuggestions({
            textSearch: inputValue,
            id: vendorId,
            shareableType,
            shareableId,
          })
        );
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [inputValue]);

  useEffect(() => {
    if (shareSuggestions) {
      const options = shareSuggestions.data?.map(item => {
        if (item?.user) {
          return {
            id: item.user.id,
            name: item.user.email,
          };
        }
      });
      options && setSuggestionOptions(options);
    }
  }, [shareSuggestions]);

  const vendorUsers = useSelector((state: ApplicationState) =>
    selectUsers(state)
  );

  const getUser = (userId?: Tag['id']) => {
    if (!userId) return;
    return vendorUsers?.find(u => u.userId === userId.toString());
  };

  const TagComponent: React.FC<TagComponentProps> = props => {
    const user = getUser(props.tag.id);

    return (
      <button onClick={props.onDelete} className="react-tags__selected-tag">
        {user ? <UserAvatar user={user} size="tag" /> : props.tag.name}
      </button>
    );
  };

  const SuggestionComponent: React.FC<SuggestionComponentProps> = props => {
    const user = getUser(props.item.id);
    return user ? <UserAvatar user={user} size="medium" /> : null;
  };

  return (
    <AdoptechTagAutocomplete
      minQueryLength={minQueryLength || 1}
      suggestionsFilter={(suggestion: Tag) =>
        !usersToShare.find(
          (userToShare: Tag) => userToShare.name === suggestion.name
        )
      }
      onAddition={(tag: Tag) => onUpdate([...usersToShare, tag])}
      onValidate={(tag: Tag) => {
        if (!isValidEmailAddress(tag.name)) return false;
        return !usersToShare.find(
          (userToShare: Tag) => userToShare.name === tag.name
        );
      }}
      onDelete={(index: number) => {
        const newUsersToShare = [...usersToShare];
        newUsersToShare.splice(index, 1);
        onUpdate(newUsersToShare);
      }}
      onInput={(input: string) => {
        setInputValue(input);
      }}
      placeholderText=""
      allowNew
      tags={usersToShare}
      suggestions={suggestionOptions}
      noSuggestionsText="No matching users"
      removeButtonText="Click to remove"
      tagComponent={TagComponent}
      suggestionComponent={SuggestionComponent}
    />
  );
};
