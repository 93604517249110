/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DataRoomEntityFolderCreatePayloadFolder,
  DataRoomEntityFolderCreatePayloadFolderFromJSON,
  DataRoomEntityFolderCreatePayloadFolderFromJSONTyped,
  DataRoomEntityFolderCreatePayloadFolderToJSON,
} from './';

/**
 *
 * @export
 * @interface DataRoomEntityFolderCreatePayload
 */
export interface DataRoomEntityFolderCreatePayload {
  /**
   *
   * @type {DataRoomEntityFolderCreatePayloadFolder}
   * @memberof DataRoomEntityFolderCreatePayload
   */
  folder: DataRoomEntityFolderCreatePayloadFolder;
}

export function DataRoomEntityFolderCreatePayloadFromJSON(
  json: any
): DataRoomEntityFolderCreatePayload {
  return DataRoomEntityFolderCreatePayloadFromJSONTyped(json, false);
}

export function DataRoomEntityFolderCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DataRoomEntityFolderCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    folder: DataRoomEntityFolderCreatePayloadFolderFromJSON(json['folder']),
  };
}

export function DataRoomEntityFolderCreatePayloadToJSON(
  value?: DataRoomEntityFolderCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    folder: DataRoomEntityFolderCreatePayloadFolderToJSON(value.folder),
  };
}
