import React, { useEffect, useState } from 'react';
import { AuditModelRatingEnum, VendorUserRoles } from '../../../swagger';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { AdoptechTextInput2 } from '../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { AdoptechReactSelect2 } from '../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechDatePicker2 } from '../../../components/AdoptechDatePicker2/AdoptechDatePicker2';
import { useOwnerOptions } from '../../../hooks/useOwnerOptions';
import { DropZone } from '../../../components/DropZone/DropZone';
import {
  UploadAuditPayload,
  fetchAudits,
  uploadAudit,
} from './store/auditsThunks';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import './AuditsUploadDrawer.scss';

const baseCss = 'auditsUploadDrawer';

export const validation = (
  data: UploadAuditPayload
): Record<string, string[]> => ({
  name: data.name ? null : ['Audit name must be set'],
  owner: data.owner?.id ? null : ['Owner must be set'],
  startedAt: data.startedAt ? null : ['Start date must be set'],
  completedAt: data.completedAt ? null : ['End date must be set'],
  rating: data.rating ? null : ['Rating must be set'],
  origin: data.origin ? null : ['Rating must be set'],
  file: data.file ? null : ['File must be attached'],
});

type Field =
  | 'name'
  | 'origin'
  | 'owner'
  | 'startedAt'
  | 'completedAt'
  | 'rating'
  | 'file';

interface Props {
  show: boolean;
  onClose(): void;
}

export const AuditsUploadDrawer: React.FC<Props> = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({} as UploadAuditPayload);
  const setName = (name: string) => setData({ ...data, name });
  const setFile = (file: File) => setData({ ...data, file });

  const { users, ownerOptions } = useOwnerOptions({
    onlyRoles: [
      VendorUserRoles.Admin,
      VendorUserRoles.SecurityManager,
      VendorUserRoles.Auditor,
    ],
  });

  const inProgress = useSelector(
    (state: ApplicationState) => state.audits.isCreatingAudit
  );

  const originOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'Vendor', value: 'vendor' },
    { label: 'Supplier', value: 'supplier' },
    { label: 'Surveillance', value: 'surveillance' },
    { label: 'External', value: 'external' },
  ];

  const ratingOptions = [
    { label: 'Compliant', value: AuditModelRatingEnum.Compliant },
    {
      label: 'Improvement needed',
      value: AuditModelRatingEnum.ImprovementNeeded,
    },
    {
      label: 'Not compliant',
      value: AuditModelRatingEnum.NotCompliant,
    },
  ];

  const startDate = data.startedAt ? new Date(data.startedAt) : null;
  const endDate = data.completedAt ? new Date(data.completedAt) : null;

  useEffect(() => {
    if (endDate && endDate < startDate) setData({ ...data, completedAt: null });
  }, [startDate]);

  const busy = inProgress;

  const hasError = (field: Field) => !!validation(data)[field];

  const formIsValid = Object.values(validation(data)).every(v => !v);
  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  const submitChanges = async () => {
    await dispatch(uploadAudit(data));
    dispatch(fetchAudits(vendorId));
    onClose();
  };

  const Footer = (
    <>
      <AdoptechButton onClick={onClose} variant={AdoptechButtonVariant.White}>
        Cancel
      </AdoptechButton>
      <AdoptechButton
        disabled={!formIsValid}
        onClick={submitChanges}
        variant={AdoptechButtonVariant.Primary}
        busy={busy}
      >
        Add
      </AdoptechButton>
    </>
  );

  return (
    <AdoptechHS
      title="Upload audit"
      show={show}
      onClose={onClose}
      showConfirmationWarning={false}
      footer={Footer}
      extraClass="auditsEditDrawer"
    >
      <div className={baseCss}>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--label'}>Name your audit</div>
          <AdoptechTextInput2
            label="Audit name"
            placeholder="Audit name example: ISO 27001 internal audit"
            onChange={e => setName(e.currentTarget.value)}
            id="auditsUploadFormName"
            hasError={hasError('name')}
            value={data.name || ''}
            type="text"
          />
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--label'}>Type of audit</div>
          <AdoptechReactSelect2
            label="Type"
            id="auditsUploadFormType"
            options={originOptions}
            hasError={hasError('origin')}
            onChange={e => {
              setData({
                ...data,
                origin: e.value,
              });
            }}
            value={originOptions.find(option => option.value == data.origin)}
          />
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--label'}>
            Who is responsible for this audit report?
          </div>
          <AdoptechReactSelect2
            label="Owner"
            id="auditsUploadFormOwner"
            options={ownerOptions}
            hasError={hasError('owner')}
            onChange={e => {
              setData({
                ...data,
                owner: users.find(user => user.id === e.value),
              });
            }}
            value={ownerOptions.find(option => option.value == data.owner?.id)}
          />
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--label'}>
            What date did the audit start?
          </div>
          <AdoptechDatePicker2
            labelText="Start date"
            placeHolderText="select date"
            outputDate={startDate}
            hasError={hasError('startedAt')}
            deadLines={[]}
            minDate={null}
            onDateSelect={d => {
              setData({
                ...data,
                startedAt: d.toJSON().split('T')[0],
              });
            }}
          />
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--label'}>
            What date did the audit end?
          </div>
          <AdoptechDatePicker2
            labelText="End date"
            placeHolderText="select date"
            outputDate={endDate}
            hasError={hasError('completedAt')}
            deadLines={[]}
            minDate={startDate}
            onDateSelect={d => {
              setData({
                ...data,
                completedAt: d.toJSON().split('T')[0],
              });
            }}
          />
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--label'}>
            Please give the audit a compliance rating
          </div>
          <AdoptechReactSelect2
            label="Rating"
            id="auditsUploadFormType"
            options={ratingOptions}
            hasError={hasError('origin')}
            onChange={e => {
              setData({
                ...data,
                rating: e.value as AuditModelRatingEnum,
              });
            }}
            value={ratingOptions.find(option => option.value == data.rating)}
          />
        </div>
        <br />
        <div className={baseCss + '--fieldRow'}>
          <DropZone accept=".pdf" onChange={setFile} />
        </div>
      </div>
    </AdoptechHS>
  );
};
