import { useEffect } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { useSelector } from 'react-redux';
import { selectCurrentVendorUser } from '../selectors/selectCurrentVendorUser';
import { ApplicationState } from '../types/applicationState';
import { postChatWidgetToken } from '../store/vendors/vendorsThunks';
import {
  initChatWidget,
  removeChatWidget,
} from '../functions/chatWidgetHelper';
import { VendorUserRoles } from '../swagger/models/VendorUserRoles';
import { isTrusthubAdminRoute } from '../components/Routes/Routes';
import { selectCurrentVendor } from '../selectors/selectCurrentVendor';

const useChatWidgetToken = () => {
  const { isInitialised } = useSelector(
    (state: ApplicationState) => state.global
  );
  const dispatch = useAppDispatch();
  const vendorUser = useSelector(selectCurrentVendorUser);
  const vendor = useSelector(selectCurrentVendor);
  const isTrusthub = isTrusthubAdminRoute();
  useEffect(() => {
    if (!isInitialised) return;

    if (isTrusthub) {
      removeChatWidget();
      return;
    }

    const fetchTokenAndInitChatWidget = async () => {
      const response = await dispatch(postChatWidgetToken()).unwrap();
      const token = response.token;
      window.hsConversationsSettings = {
        identificationEmail: vendorUser.email,
        identificationToken: token,
      };
      initChatWidget();
    };
    if (vendorUser && vendor?.id) {
      fetchTokenAndInitChatWidget();
    } else {
      initChatWidget();
    }
  }, [vendorUser?.email, isInitialised, isTrusthub, vendor?.id]);
};

export default useChatWidgetToken;
