import React, { useState } from 'react';
import './OutgoingAssessmentHeader.scss';
import { Panel } from '../Panel/Panel';
import { AssessmentTemplateExtended } from '../../swagger';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import GenerateAssessmentModal from '../GenerateAssessmentModal/GenerateAssessmentModal';

interface OutgoingAssessmentHeaderProps {
  assessment: AssessmentTemplateExtended;
}

const OutgoingAssessmentHeader: React.FC<
  OutgoingAssessmentHeaderProps
> = props => {
  const [generateAssessmentModalShowing, setGenerateAssessmentModalShowing] =
    useState(false);

  return (
    <>
      <div className="outgoingAssessmentHeader">
        <Panel>
          <div className="outgoingAssessmentHeader--panel">
            <div className="outgoingAssessmentHeader--lhs">
              <div className="outgoingAssessmentHeader--label">
                Template name
              </div>
              <div className="outgoingAssessmentHeader--name">
                {props.assessment.name}
              </div>
            </div>
            <div className="outgoingAssessmentHeader--rhs">
              <AdoptechButton
                onClick={() => setGenerateAssessmentModalShowing(true)}
                uppercase
                variant={AdoptechButtonVariant.SuccessFilled}
              >
                Generate
              </AdoptechButton>
            </div>
          </div>
        </Panel>
      </div>
      {generateAssessmentModalShowing && (
        <GenerateAssessmentModal
          onHide={() => setGenerateAssessmentModalShowing(false)}
          show={generateAssessmentModalShowing}
          templateName={props.assessment.name}
        />
      )}
    </>
  );
};

export default OutgoingAssessmentHeader;
