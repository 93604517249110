import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { formatShortDate } from '../../functions/formatShortDate';
import {
  VendorDocumentAttestation,
  VendorDocumentAttestationStatusEnum,
} from '../../swagger';
import './AttestedStatus.scss';

const AttestedStatusEnum = {
  Green: 'green',
  Amber: 'amber',
  Red: 'red',
};

export interface AttestedStatusProps {
  attestation: VendorDocumentAttestation;
}

export const AttestedStatus: React.FC<AttestedStatusProps> = props => {
  if (!props.attestation) {
    return null;
  }

  let status: string;
  if (
    props.attestation.lastAttestedAt &&
    props.attestation.status === VendorDocumentAttestationStatusEnum.Current
  ) {
    status = AttestedStatusEnum.Green;
  } else if (
    props.attestation.status ===
      VendorDocumentAttestationStatusEnum.Requested ||
    props.attestation.status === VendorDocumentAttestationStatusEnum.Imminent
  ) {
    status = AttestedStatusEnum.Amber;
  } else if (
    props.attestation.status === VendorDocumentAttestationStatusEnum.Overdue ||
    props.attestation.status === VendorDocumentAttestationStatusEnum.Expired
  ) {
    status = AttestedStatusEnum.Red;
  }

  const classes = classNames({
    'attestedStatus--icon': true,
    'attestedStatus--icon-green': status === AttestedStatusEnum.Green,
    'attestedStatus--icon-amber': status === AttestedStatusEnum.Amber,
    'attestedStatus--icon-red': status === AttestedStatusEnum.Red,
  });

  let icon: IconProp;

  switch (status) {
    case AttestedStatusEnum.Green:
      icon = faCheckCircle;
      break;
    case AttestedStatusEnum.Amber:
      icon = faExclamationCircle;
      break;
    case AttestedStatusEnum.Red:
      icon = faTimesCircle;
      break;
  }

  const getStatusText = () => {
    if (status === AttestedStatusEnum.Green) {
      return formatShortDate(props.attestation.lastAttestedAt);
    } else if (status === AttestedStatusEnum.Amber) {
      return 'Pending';
    } else {
      return 'Overdue';
    }
  };

  return (
    <div className="attestedStatus">
      {icon && (
        <>
          <FontAwesomeIcon
            className={classes}
            icon={icon}
            title={props.attestation.status}
          />
          {getStatusText()}
        </>
      )}
    </div>
  );
};
