/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ControlReviewFrequencyEnum,
  ControlReviewFrequencyEnumFromJSON,
  ControlReviewFrequencyEnumFromJSONTyped,
  ControlReviewFrequencyEnumToJSON,
} from './';

/**
 *
 * @export
 * @interface ReviewHistoryItemCreatePayloadReviewHistoryItem
 */
export interface ReviewHistoryItemCreatePayloadReviewHistoryItem {
  /**
   *
   * @type {string}
   * @memberof ReviewHistoryItemCreatePayloadReviewHistoryItem
   */
  relatedToId: string;
  /**
   *
   * @type {string}
   * @memberof ReviewHistoryItemCreatePayloadReviewHistoryItem
   */
  relatedToType: ReviewHistoryItemCreatePayloadReviewHistoryItemRelatedToTypeEnum;
  /**
   *
   * @type {ControlReviewFrequencyEnum}
   * @memberof ReviewHistoryItemCreatePayloadReviewHistoryItem
   */
  reviewFrequency: ControlReviewFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof ReviewHistoryItemCreatePayloadReviewHistoryItem
   */
  reviewComment?: string | null;
}

export function ReviewHistoryItemCreatePayloadReviewHistoryItemFromJSON(
  json: any
): ReviewHistoryItemCreatePayloadReviewHistoryItem {
  return ReviewHistoryItemCreatePayloadReviewHistoryItemFromJSONTyped(
    json,
    false
  );
}

export function ReviewHistoryItemCreatePayloadReviewHistoryItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReviewHistoryItemCreatePayloadReviewHistoryItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    relatedToId: json['related_to_id'],
    relatedToType: json['related_to_type'],
    reviewFrequency: ControlReviewFrequencyEnumFromJSON(
      json['review_frequency']
    ),
    reviewComment: !exists(json, 'review_comment')
      ? undefined
      : json['review_comment'],
  };
}

export function ReviewHistoryItemCreatePayloadReviewHistoryItemToJSON(
  value?: ReviewHistoryItemCreatePayloadReviewHistoryItem | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    related_to_id: value.relatedToId,
    related_to_type: value.relatedToType,
    review_frequency: ControlReviewFrequencyEnumToJSON(value.reviewFrequency),
    review_comment: value.reviewComment,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ReviewHistoryItemCreatePayloadReviewHistoryItemRelatedToTypeEnum {
  Control = 'Control',
  LegalRegister = 'LegalRegister',
  PestelRegister = 'PestelRegister',
}
