import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { AuditTemplateModel } from '../../../swagger';
import { auditsPageRoute } from '../../../components/Routes/Routes';
import { AuditsStartNewDrawer } from './AuditsStartNewDrawer';
import './AuditsTemplates.scss';
import { ApplicationState } from '../../../types/applicationState';
import { fetchAuditTemplates } from './store/auditsThunks';
import { AdoptechTile } from '../../../components/AdoptechTile/AdoptechTile';
import { AdoptechPageNavBar } from '../../../components/AdoptechPageNavBar/AdoptechPageNavBar';

export const AuditsTemplates: React.FC = () => {
  const baseCss = 'auditsTemplates';
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAuditTemplates());
  }, []);

  const templates = useSelector(
    (state: ApplicationState) => state.audits.templates
  );

  const [currentTemplate, setCurrentTemplate] =
    useState<AuditTemplateModel>(null);
  const unsetCurrentTemplate = () => setCurrentTemplate(null);

  const goBack = () => dispatch(push(auditsPageRoute));

  const startNewWith = (template: AuditTemplateModel) => {
    setCurrentTemplate(template);
  };

  return (
    <>
      <AdoptechPageNavBar goBack={goBack} content={<>Start new</>} />
      <div className={baseCss + '--list'}>
        {templates.map(template => (
          <AdoptechTile
            key={template.id}
            size="small"
            name={template.name}
            description={template.summary}
            buttonText="Start new"
            onClick={() => {
              startNewWith(template);
            }}
          />
        ))}
      </div>
      <AuditsStartNewDrawer
        templateId={currentTemplate?.id}
        onClose={unsetCurrentTemplate}
      />
    </>
  );
};
