import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import {
  Answer,
  AuditModelRatingEnum,
  QuestionTable,
  VendorUser,
} from '../../../swagger';
import { AdoptechReactSelect } from '../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { TableAnswer2Props } from '../../../components/TableAnswer2/TableAnswer2';
import './AuditReviewTableAnswer.scss';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { AdoptechTextArea } from '../../../components/AdoptechTextArea/AdoptechTextArea';
import { auditRating } from './AuditsPage';
import { reactSelectLightTheme } from '../../../functions/reactSelectCustomTheme';
import { ApplicationState } from '../../../types/applicationState';
import classNames from 'classnames';
import { IconButton } from '../../../components/IconButton/IconButton';
import { setAuditQuestionTableAnswers } from './store/auditsSlice';
import { globalUserId, useOwnerOptions } from '../../../hooks/useOwnerOptions';
import { normalizeTableAnswers } from '../../../functions/normalizeTableAnswers';

export const addToggleClass = 'add-new-toggle';

export const isMyAnswer = (answer: Answer, currentVendorUser: VendorUser) => {
  const newAnswer = !answer.id;
  if (newAnswer) return true;
  return answer.userName === currentVendorUser.fullName;
};

export const AuditReviewTableAnswer: React.FC<TableAnswer2Props> = ({
  question,
  onChange,
  onDeleteTable2Answers,
}) => {
  // Avoid using local state answers here. Use only redux store (question?.table?.answers) for checking answers outside of this component
  const answers = normalizeTableAnswers(question);
  const dispatch = useDispatch();
  const setAnswers = (answersPayload: Answer[]) => {
    const answersFormatForRedux: QuestionTable['answers'] = [answersPayload];
    dispatch(
      setAuditQuestionTableAnswers({
        id: question.id,
        answers: answersFormatForRedux,
      })
    );
  };
  const baseCss = 'auditReviewTable';
  const { ownerOptions } = useOwnerOptions({
    valuesAsGlobalId: true,
    includingGuest: true,
  });
  const currentVendorUser = useSelector(selectCurrentVendorUser);
  const ratingOptions = Object.entries(AuditModelRatingEnum).map(entry => {
    return { label: auditRating(entry[0]), value: entry[1] };
  });

  const { isPostingAuditAnswer, isFetchingAudit } = useSelector(
    (state: ApplicationState) => state.audits
  );

  const answerByType = (array: Answer[], type: string) =>
    array.find(answer => answer.propertyName.endsWith(type)) ||
    ({ propertyName: type, value: '' } as Answer);
  const updateAnswer = (
    currentAnswer: Answer,
    value: AuditModelRatingEnum | string // rating or comments or "user global id format"
  ) => {
    const newAnswers = answers.map(answer => {
      return answer.id === currentAnswer.id &&
        answer.propertyName === currentAnswer.propertyName
        ? { ...answer, value }
        : answer;
    });
    setAnswers(newAnswers);
    const { propertyName } = currentAnswer;
    const isSelectField =
      propertyName.endsWith('.auditor_name') ||
      propertyName.endsWith('.rating');
    if (isSelectField) {
      // if select field, call BE request without waiting blur
      onUpdateRequest(newAnswers);
    }
  };

  // Send request to the backend only if ALL answers in the row are filled in
  // ELSE empty answers will be created but on reload empty answers will be not returned from the survey =>
  // page will be broken

  const onUpdateRequest = (newAnswers: Answer[]) => {
    const myAnswers = newAnswers.filter(answer =>
      isMyAnswer(answer, currentVendorUser)
    );
    onChange(myAnswers, { isTable2Answer: true });
  };

  const sortedIndexes = [...new Set(answers.map(answer => answer.index))];
  const isDisabledDeletion = isPostingAuditAnswer || isFetchingAudit;
  const onAddClick = () => {
    const numbers = (answers || []).map(answer => answer.rowOrder);
    const rowOrder = numbers.length > 0 ? Math.max(...numbers) + 1 : 0;
    const index = answers.length > 0 ? answers.length + 1 : 0;

    const attrs: Answer = {
      value: null,
      questionId: question.id,
      index,
      rowOrder,
    };
    const newAnswers: Answer[] = question.table.questions.map(childQuestion => {
      const customAttrs = childQuestion.propertyName.endsWith('.auditor_name')
        ? {
            value: globalUserId(currentVendorUser),
          }
        : {};

      return {
        ...attrs,
        questionId: childQuestion.id,
        propertyName: childQuestion.propertyName,
        value: null,
        ...customAttrs,
      };
    });
    setAnswers([...answers].concat(newAnswers));
  };

  return (
    <div className={baseCss}>
      <div className={baseCss + '--header'}>
        {question.questionColumns.map(column => (
          <div key={column.columnText}>{column.columnText}</div>
        ))}
        <div></div>
      </div>
      <div className={addToggleClass} onClick={onAddClick} />
      {sortedIndexes.sort().map(index => {
        const row = answers.filter(answer => answer.index === index);
        const commentsAnswer = answerByType(row, '.comments');
        const ownerAnswer = answerByType(row, '.auditor_name');
        const ratingAnswer = answerByType(row, '.rating');
        const isDisabled = row.some(
          answer => !isMyAnswer(answer, currentVendorUser)
        );
        return (
          <div
            key={row[0].questionRowId}
            className={classNames({
              [`${baseCss}--row`]: true,
              [`${baseCss}--error-row`]:
                ratingAnswer.value === AuditModelRatingEnum.NotCompliant,
            })}
          >
            <div>
              <AdoptechReactSelect
                isDisabled
                id="review-owner"
                options={ownerOptions}
                onChange={e => {
                  updateAnswer(ownerAnswer, e.value);
                }}
                value={ownerOptions.find(
                  option => option.value === ownerAnswer.value
                )}
                placeholder="Please select"
                label={null}
              />
            </div>
            <div>
              <AdoptechReactSelect
                label={null}
                id="review-rating"
                options={ratingOptions}
                isDisabled={isDisabled}
                hasError={!ratingAnswer.value && !!commentsAnswer.value}
                onChange={e => {
                  updateAnswer(ratingAnswer, e.value as AuditModelRatingEnum);
                }}
                value={ratingOptions.find(
                  option => option.value == ratingAnswer.value
                )}
                placeholder="Please select"
                theme={reactSelectLightTheme}
              />
            </div>
            <div>
              <AdoptechTextArea
                hasError={!!ratingAnswer.value && !commentsAnswer.value}
                label={null}
                id={`${baseCss}description`}
                disabled={isDisabled}
                value={commentsAnswer.value || ''}
                onChange={e => {
                  updateAnswer(commentsAnswer, e.currentTarget.value);
                }}
                onBlur={e => {
                  onUpdateRequest(answers);
                }}
                icon={faPen}
                placeholder="Enter auditor comments"
              />
            </div>
            <div className={baseCss + '--remove-column'}>
              <IconButton
                ariaLabel="Delete"
                icon={faTrashAlt}
                disabled={isDisabled || isDisabledDeletion}
                onClick={() => {
                  const indexes = row.map(answer => answer.index);
                  const rowToDelete = answers.filter(answer =>
                    indexes.includes(answer.index)
                  );
                  const answersOutsideRow = answers.filter(
                    answer => !indexes.includes(answer.index)
                  );
                  setAnswers(answersOutsideRow);
                  onDeleteTable2Answers(rowToDelete);
                }}
                tooltip={isDisabledDeletion ? 'Please wait ...' : 'Delete'}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
