import classNames from 'classnames';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ZXCVBNResult } from 'zxcvbn';
import { ProgressBar } from '../../../../src/components/ProgressBar/ProgressBar';
import './PasswordStrength.scss';

interface PasswordStrengthProps {
  password: string;
  result: ZXCVBNResult;
}

export const PasswordStrength: React.FC<PasswordStrengthProps> = props => {
  let progress = (props.result.score / 4) * 100;

  if (props.password !== '' && progress === 0) {
    progress = 10;
  }

  let strength = '';

  switch (progress) {
    case 10:
      strength = 'Very weak (minimum of Good required)';
      break;
    case 25:
      strength = 'Weak (minimum of Good required)';
      break;
    case 50:
      strength = 'Good';
      break;
    case 75:
      strength = 'Strong';
      break;
    case 100:
      strength = 'Very strong';
      break;
  }

  const passwordStrengthClasses = classNames({
    passwordStrength: true,
    'passwordStrength-hidden': props.password === '',
  });

  const popover = (
    <Popover id="passwordAdvice">
      <Popover.Title as="h3">Password advice</Popover.Title>
      <Popover.Content>
        <div className="passwordStrength--advice">
          {props.result.feedback.warning !== '' && (
            <React.Fragment>
              <div className="passwordStrength--adviceWarning">
                Warning: {props.result.feedback.warning}
              </div>
              <hr />
            </React.Fragment>
          )}
          {props.result.feedback.suggestions.length > 0 && (
            <React.Fragment>
              {props.result.feedback.suggestions.map(s => {
                return <li key={s}>{s}</li>;
              })}
            </React.Fragment>
          )}
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className={passwordStrengthClasses}>
      <div className="passwordStrength--row">
        <div>
          <span
            className={`passwordStrength--text passwordStrength--text-${progress}`}
          >
            {strength}
          </span>
        </div>
        <div>
          {(props.result.feedback.suggestions.length > 0 ||
            props.result.feedback.warning !== '') && (
            <OverlayTrigger
              overlay={popover}
              placement="auto"
              trigger={['hover', 'focus']}
            >
              <div className="passwordStrength--info">See guide</div>
            </OverlayTrigger>
          )}
        </div>
      </div>
      <div className="passwordStrength--bar">
        <ProgressBar progress={progress} hasRadius />
      </div>
    </div>
  );
};
