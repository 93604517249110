import React, { useEffect } from 'react';
import './PageHeader.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageLabel } from '../../selectors/selectPageLabel';
import { Notifications } from '../Notifications/Notifications';
import { UserBadge } from '../UserBadge/UserBadge';
import AssessmentsMenu, {
  assessmentsMenuVisibleRoute,
} from '../AssessmentsMenu/AssessmentsMenu';
import { selectRouteName } from '../../selectors/selectRouteName';
import GlobalSearch from '../GlobalSearch/GlobalSearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { ApplicationState } from '../../types/applicationState';
import {
  setGlobalSearchBarVisible,
  showHelpMenu,
} from '../../store/global/globalSlice';
import { faClose } from '@fortawesome/pro-light-svg-icons/faClose';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons/faCircleQuestion';

export const setPageTitle = (title: string) => {
  document.title = title;
};
const PageHeader: React.FC = () => {
  const dispatch = useDispatch();
  const pageLabel = useSelector(selectPageLabel);
  const routeName = useSelector(selectRouteName);
  const searchBarVisible = useSelector(
    (state: ApplicationState) => state.global.globalSearchBarVisible
  );

  useEffect(() => {
    setPageTitle(pageLabel);
  }, [pageLabel]);

  return (
    <div className="pageHeader">
      <div className="pageHeader--label">{pageLabel}</div>
      {routeName === assessmentsMenuVisibleRoute && (
        <div className="pageHeader--menu">
          <AssessmentsMenu />
        </div>
      )}
      <div className="pageHeader--searchArea">
        {searchBarVisible && <GlobalSearch />}
      </div>
      <div className="pageHeader--toolbarArea">
        <div className="pageHeader--toolbar">
          <FontAwesomeIcon
            className="pageHeader--icon"
            icon={searchBarVisible ? faClose : faSearch}
            onClick={() => {
              dispatch(setGlobalSearchBarVisible(!searchBarVisible));
            }}
          />
          <FontAwesomeIcon
            className="pageHeader--icon"
            icon={faCircleQuestion}
            onClick={() => {
              dispatch(showHelpMenu());
            }}
          />
          <Notifications />
          <UserBadge />
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
