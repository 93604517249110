import { Interweave } from 'interweave';
import React from 'react';
import './AdoptechRadioButton.scss';

export interface AdoptechRadioButtonProps {
  checked: boolean;
  disabled?: boolean;
  id: string;
  label?: string;
  onChange?: (value: string) => void;
  value: string;
}

export const AdoptechRadioButton: React.FC<
  AdoptechRadioButtonProps
> = props => {
  return (
    <div className="adoptechRadioButton">
      <input
        checked={props.checked}
        disabled={props.disabled}
        id={props.id}
        onChange={() => props.onChange(props.value)}
        value={props.value}
        type="radio"
      />
      <label htmlFor={props.id}>
        <Interweave content={props.label} />
      </label>
    </div>
  );
};
