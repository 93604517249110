/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AgreementSignatoryUpdateInternalPayload
 */
export interface AgreementSignatoryUpdateInternalPayload {
  /**
   *
   * @type {Array<string>}
   * @memberof AgreementSignatoryUpdateInternalPayload
   */
  agreementSignatoriesUsersIds: Array<string>;
}

export function AgreementSignatoryUpdateInternalPayloadFromJSON(
  json: any
): AgreementSignatoryUpdateInternalPayload {
  return AgreementSignatoryUpdateInternalPayloadFromJSONTyped(json, false);
}

export function AgreementSignatoryUpdateInternalPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementSignatoryUpdateInternalPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    agreementSignatoriesUsersIds: json['agreement_signatories_users_ids'],
  };
}

export function AgreementSignatoryUpdateInternalPayloadToJSON(
  value?: AgreementSignatoryUpdateInternalPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    agreement_signatories_users_ids: value.agreementSignatoriesUsersIds,
  };
}
