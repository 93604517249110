import { sort } from '../../../../functions/sort';
import { ColumnSort } from '../../../../types/columnSort';
import { ApplicationState } from '../../../../types/applicationState';
import { selectSortSettings } from '../../../../selectors/selectSortSettings';
import { Grid } from '../../../../types/grid';
import { createSelector } from '@reduxjs/toolkit';
import { TrusthubVendorUserModel } from '../../../../swagger/trusthub/models/TrusthubVendorUserModel';

const sortCompanyUsers = (
  a: TrusthubVendorUserModel,
  b: TrusthubVendorUserModel,
  columnSort: ColumnSort
) => {
  const typedColumn = columnSort.name as keyof TrusthubVendorUserModel;

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

export const selectCompanyUsers = createSelector(
  (state: ApplicationState) => state.trustHub.companyVendorUsers,
  selectSortSettings(Grid.TrustHubUserAdminCompanyUsers),
  (users: TrusthubVendorUserModel[], sortSetting) => {
    return [...users].sort((a, b) =>
      sortCompanyUsers(a, b, sortSetting.columnSort)
    );
  }
);
