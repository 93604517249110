import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideIsPaymentSuccessfulShowing } from '../../store/policies/policiesSlice';
import { ApplicationState } from '../../types/applicationState';
import { MessageToast } from '../MessageToast/MessageToast';

export const PaymentSuccessfulToast = () => {
  const dispatch = useDispatch();

  const isPaymentSuccessfulShowing = useSelector(
    (state: ApplicationState) => state.policies.isPaymentSuccessfulShowing
  );

  return (
    <MessageToast
      onClose={() => dispatch(hideIsPaymentSuccessfulShowing())}
      show={isPaymentSuccessfulShowing}
    >
      Your subscription has been created successfully.
    </MessageToast>
  );
};
