import { useDispatch } from 'react-redux';
import { setBackgroundColor } from '../store/global/globalSlice';
import { GlobalState } from '../store/global/globalState';
import { useEffect } from 'react';

export const useBackground = (color: GlobalState['backgroundColor']) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBackgroundColor(color));
    return () => {
      dispatch(setBackgroundColor());
    };
  }, []);
};
