import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePestelItemForm } from '../../store/pestel/pestelSlice';
import {
  createPestelItem,
  fetchPestelItem,
  fetchPestelRegister,
  updatePestelItem,
} from '../../store/pestel/pestelThunks';
import { PestelItemCreatePayloadPestelItem } from '../../swagger/models/PestelItemCreatePayloadPestelItem';
import { PestelItemModel } from '../../swagger/models/PestelItemModel';
import { ApplicationState } from '../../types/applicationState';
import { PestelItemForm } from './ManagePestelItemsDrawer';
import { validation as overviewValidation } from './OverviewSection';
import { validation as assessmentValidation } from './AssessmentSection';

export const useManagePestelItemsController = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const pestelState = useSelector((state: ApplicationState) => state.pestel);

  const {
    pestelItemForm: formData,
    isCreatingPestelItem,
    isUpdatingPestelItem,
    isFetchingPestelItem,
    pestelItems,
    pestelRegister: register,
  } = pestelState;
  // For simplicity don't compare with original state and ignore if user revert his changes to original.
  const [isTouched, setIsTouched] = useState(false);

  const onCloseAndCleanup = () => {
    dispatch(updatePestelItemForm({}));
    onClose();
  };

  useEffect(() => {
    if (formData.id) dispatch(fetchPestelItem(formData.id));
  }, [formData.id]);

  const onSubmit = () => {
    const payload = formData;
    const updateStats = () => {
      dispatch(fetchPestelRegister());
    };
    if (formData.id) {
      dispatch(
        updatePestelItem(formData.id, payload, () => {
          onCloseAndCleanup();
        })
      );
      return;
    }
    const createPayload = {
      pestelItem: payload as Required<PestelItemCreatePayloadPestelItem>,
    };
    dispatch(
      createPestelItem(createPayload, () => {
        updateStats();
        onCloseAndCleanup();
      })
    );
  };
  const isSubmitting = isCreatingPestelItem || isUpdatingPestelItem;
  const validation = !!formData && {
    ...overviewValidation(formData, pestelItems),
    ...assessmentValidation(formData, pestelItems),
  };

  const formValid =
    !!validation && Object.values(validation).every(error => !error);

  const submitButtonDisabled = !formValid || !isTouched;
  const isLoading = isFetchingPestelItem;
  const dispatch = useDispatch();

  const setFormData = (updatedFormData: PestelItemForm) => {
    setIsTouched(true);
    dispatch(updatePestelItemForm(updatedFormData));
  };
  type ValueOf<T> = T[keyof T];
  const updateField = (
    field: keyof PestelItemModel,
    value: ValueOf<PestelItemModel>
  ) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const sectionProps = {
    formData,
    isTouched,
    updateField,
  };

  return {
    isTouched,
    onCloseAndCleanup,
    onSubmit,
    isSubmitting,
    submitButtonDisabled,
    isLoading,
    formData,
    sectionProps,
  };
};
