import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchChecklistTemplateDetails } from '../../store/checklists/checklistsThunks';
import { ApplicationState } from '../../types/applicationState';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { ChecklistSummaryTile } from '../ChecklistSummaryTile/ChecklistSummaryTile';
import { ChecklistTemplateItem } from '../ChecklistTemplateItem/ChecklistTemplateItem';
import './ChecklistTemplate.scss';

export const ChecklistTemplate: React.FC = () => {
  const dispatch = useDispatch();

  const { checklistTemplateId } = useParams() as {
    checklistTemplateId: string;
  };

  useEffect(() => {
    dispatch(fetchChecklistTemplateDetails({ checklistTemplateId }));
  }, [checklistTemplateId]);

  const checklistTemplate = useSelector((state: ApplicationState) =>
    state.checklists.checklistTemplates.find(
      checklistTmpl => checklistTmpl.id === checklistTemplateId
    )
  );
  return (
    <div className="checklistTemplate">
      <div className="checklistTemplate--summary">
        <div
          className="checklistTemplate--back"
          onClick={() => {
            dispatch(push('/r/companyInformation/checklists'));
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
        <div className="checklistTemplate--summaryTile">
          <ChecklistSummaryTile checklist={checklistTemplate} isTemplate />
        </div>
      </div>
      <div className="checklistTemplate--main">
        <Accordion>
          {checklistTemplate?.itemsGroups?.map(group => (
            <AdoptechAccordionCard
              index={group.questionGroupId}
              key={group.questionGroupId}
              title={group.questionGroupName}
              nested
              nestedVerticalOffset={-245}
              headerOffset={115}
            >
              {group.items.map(item => (
                <ChecklistTemplateItem item={item} key={item.questionId} />
              ))}
            </AdoptechAccordionCard>
          ))}
        </Accordion>
      </div>
    </div>
  );
};
