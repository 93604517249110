import React from 'react';
import { SelectionOption } from '../types/selectionOption';
import { StylesConfig } from 'react-select';

type IsMulti = false;

export const reactSelectCustomStyles: (
  showUserAvatar?: boolean,
  additionalStyling?: { [key: string]: React.CSSProperties }
) => StylesConfig<SelectionOption, IsMulti> = (
  showUserAvatar?: boolean,
  additionalStyling?: { [key: string]: React.CSSProperties }
) => ({
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    backgroundColor: '#f2f2f2',
    borderRadius: '2px;',
    borderColor: '#dddddd',
    '&:hover': {
      borderColor: '#aaaaaa',
    },
    minHeight: '40px',
    ...additionalStyling?.control,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor:
      showUserAvatar && state.isSelected ? '#f2f2f2' : provided.backgroundColor,
    cursor: 'pointer',
    ...additionalStyling?.option,
  }),
  singleValue: provided => ({
    ...provided,
    color: '#666665',
    ...additionalStyling?.singleValue,
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: 'hsl(0,0%,85%)',
    ...additionalStyling?.multiValue,
  }),
  placeholder: provided => ({
    ...provided,
    color: '#666665',
    opacity: 0.5,
    ...additionalStyling?.placeholder,
  }),
  valueContainer: provided => ({
    ...provided,
    paddingLeft: '5px',
    ...additionalStyling?.valueContainer,
  }),
  clearIndicator: provided => ({
    ...provided,

    ...additionalStyling?.clearIndicator,
  }),

  container: provided => ({
    ...provided,

    ...additionalStyling?.container,
  }),

  dropdownIndicator: provided => ({
    ...provided,

    ...additionalStyling?.dropdownIndicator,
  }),

  group: provided => ({
    ...provided,

    ...additionalStyling?.group,
  }),

  groupHeading: provided => ({
    ...provided,

    ...additionalStyling?.groupHeading,
  }),

  indicatorsContainer: provided => ({
    ...provided,

    ...additionalStyling?.indicatorsContainer,
  }),

  indicatorSeparator: provided => ({
    ...provided,

    ...additionalStyling?.indicatorSeparator,
  }),

  input: provided => ({
    ...provided,

    ...additionalStyling?.input,
  }),

  loadingIndicator: provided => ({
    ...provided,

    ...additionalStyling?.loadingIndicator,
  }),

  loadingMessage: provided => ({
    ...provided,

    ...additionalStyling?.loadingMessage,
  }),

  menu: provided => ({
    ...provided,
    zIndex: 100,
    ...additionalStyling?.menu,
  }),

  menuList: provided => ({
    ...provided,

    ...additionalStyling?.menuList,
  }),

  menuPortal: provided => ({
    ...provided,

    ...additionalStyling?.menuPortal,
  }),

  multiValueLabel: provided => ({
    ...provided,

    ...additionalStyling?.multiValueLabel,
  }),

  multiValueRemove: provided => ({
    ...provided,

    ...additionalStyling?.multiValueRemove,
  }),

  noOptionsMessage: provided => ({
    ...provided,

    ...additionalStyling?.noOptionsMessage,
  }),
});
