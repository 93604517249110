import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeExistingPolicyWarningModal,
  closeManageRejectionModal,
} from '../../store/policies/policiesSlice';
import { createPolicy } from '../../store/policies/policiesThunks';
import {
  AgreementExtended,
  VendorPolicy,
  VendorPolicyVersionBumpEnum,
} from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechRadioButton } from '../AdoptechRadioButton/AdoptechRadioButton';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import './ExistingPolicyWarningModal.scss';
import { createNewVersionOfAgreement } from '../../store/agreements/agreementsThunks';

export interface ExistingPolicyWarningModal {
  onSuccess?: (response: VendorPolicy | AgreementExtended) => void;
}

export enum ExistingPolicyWarningModalMode {
  Add,
  Update,
  Agreement,
}
// TODO: Looks we don't use change version agreement logic and agreement logic can be removed in the future
const ExistingPolicyWarningModal = ({
  onSuccess = () => {},
}: ExistingPolicyWarningModal) => {
  const dispatch = useDispatch();
  const isExistingPolicyWarningModalShowing = useSelector(
    (state: ApplicationState) =>
      state.policies.isExistingPolicyWarningModalShowing
  );
  const mode = useSelector(
    (state: ApplicationState) => state.policies.existingPolicyWarningModalMode
  );
  const getModalTexts = () => {
    switch (mode) {
      case ExistingPolicyWarningModalMode.Add:
        return {
          title: 'Existing policy',
          subtitle:
            'A Live version of the this policy exists. To create a new version, please select whether this will be a minor or major version, enter a reason for creating a new version and click ‘continue’.',
        };
      case ExistingPolicyWarningModalMode.Agreement:
        return {
          title: 'Update agreement',
          subtitle:
            'By clicking continue you will be creating a new version of this agreement. Please select if this is a minor or major version.',
        };
      case ExistingPolicyWarningModalMode.Update:
        return {
          title: 'Update policy',
          subtitle:
            'By clicking continue you will be creating a new version of this policy. Please select if this is a minor or major version.',
        };
    }
  };
  const selectedPolicyId = useSelector(
    (state: ApplicationState) => state.policies.selectedPolicyId
  );
  const isCreatingPolicy = useSelector(
    (state: ApplicationState) => state.policies.isCreatingPolicy
  );
  const isCreatingNewVersionOfAgreement = useSelector(
    (state: ApplicationState) =>
      state.agreements.isCreatingNewVersionOfAgreement
  );

  const [selectedVersion, setSelectedVersion] = useState<string>(
    VendorPolicyVersionBumpEnum.Minor
  );
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    setSelectedVersion(VendorPolicyVersionBumpEnum.Minor);
  }, [isExistingPolicyWarningModalShowing]);

  const handleCreate = () => {
    dispatch(closeManageRejectionModal());
    if (mode === ExistingPolicyWarningModalMode.Agreement) {
      dispatch(
        createNewVersionOfAgreement({
          id: selectedPolicyId,
          versionBump: selectedVersion,
          changeReason: message,
          onSuccess,
        })
      );
    } else {
      dispatch(
        createPolicy({
          policyId: selectedPolicyId,
          versionBump: selectedVersion,
          changeReason: message,
          onSuccess,
        })
      );
    }
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    setMessage(value);
  };

  return (
    <AdoptechModal
      show
      onHide={() => dispatch(closeExistingPolicyWarningModal())}
      className="existingPolicyWarningModal"
    >
      <Modal.Header>{getModalTexts().title}</Modal.Header>
      <Modal.Body>
        <div className="existingPolicyWarningModal--subtitle">
          {getModalTexts().subtitle}
        </div>
        <div className="existingPolicyWarningModal--radioButtons">
          <AdoptechRadioButton
            checked={selectedVersion === VendorPolicyVersionBumpEnum.Minor}
            id={VendorPolicyVersionBumpEnum.Minor}
            label="Minor"
            onChange={setSelectedVersion}
            value={VendorPolicyVersionBumpEnum.Minor}
          />
          <div className="existingPolicyWarningModal--explanation">
            (e.g. 1.1, 1.2, 1.3)
          </div>

          <AdoptechRadioButton
            checked={selectedVersion === VendorPolicyVersionBumpEnum.Major}
            id={VendorPolicyVersionBumpEnum.Major}
            label="Major"
            onChange={setSelectedVersion}
            value={VendorPolicyVersionBumpEnum.Major}
          />
          <div className="existingPolicyWarningModal--explanation">
            (e.g. 1.0, 2.0, 3.0)
          </div>
        </div>

        <AdoptechTextArea
          id="message"
          label="Reason for new version"
          onChange={handleMessageChange}
          placeholder="Please enter a reason for creating a new version"
          value={message}
        />
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={() => dispatch(closeExistingPolicyWarningModal())}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          busy={isCreatingPolicy || isCreatingNewVersionOfAgreement}
          variant={AdoptechButtonVariant.Primary}
          onClick={handleCreate}
        >
          Continue
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};

export default ExistingPolicyWarningModal;
