/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubCompanyExtendedAllOf,
  TrusthubCompanyExtendedAllOfFromJSON,
  TrusthubCompanyExtendedAllOfFromJSONTyped,
  TrusthubCompanyExtendedAllOfToJSON,
  TrusthubCompanyModel,
  TrusthubCompanyModelFromJSON,
  TrusthubCompanyModelFromJSONTyped,
  TrusthubCompanyModelToJSON,
  TrusthubCompanyStatus,
  TrusthubCompanyStatusFromJSON,
  TrusthubCompanyStatusFromJSONTyped,
  TrusthubCompanyStatusToJSON,
  TrusthubVendorUserModel,
  TrusthubVendorUserModelFromJSON,
  TrusthubVendorUserModelFromJSONTyped,
  TrusthubVendorUserModelToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubCompanyExtended
 */
export interface TrusthubCompanyExtended extends TrusthubCompanyModel {
  /**
   *
   * @type {Array<TrusthubVendorUserModel>}
   * @memberof TrusthubCompanyExtended
   */
  trusthubVendorUsers?: Array<TrusthubVendorUserModel>;
}

export function TrusthubCompanyExtendedFromJSON(
  json: any
): TrusthubCompanyExtended {
  return TrusthubCompanyExtendedFromJSONTyped(json, false);
}

export function TrusthubCompanyExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubCompanyExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...TrusthubCompanyModelFromJSONTyped(json, ignoreDiscriminator),
    trusthubVendorUsers: !exists(json, 'trusthub_vendor_users')
      ? undefined
      : (json['trusthub_vendor_users'] as Array<any>).map(
          TrusthubVendorUserModelFromJSON
        ),
  };
}

export function TrusthubCompanyExtendedToJSON(
  value?: TrusthubCompanyExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...TrusthubCompanyModelToJSON(value),
    trusthub_vendor_users:
      value.trusthubVendorUsers === undefined
        ? undefined
        : (value.trusthubVendorUsers as Array<any>).map(
            TrusthubVendorUserModelToJSON
          ),
  };
}
