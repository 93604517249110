/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PaymentMethodUpdatePayload
 */
export interface PaymentMethodUpdatePayload {
  /**
   *
   * @type {string}
   * @memberof PaymentMethodUpdatePayload
   */
  expYear?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodUpdatePayload
   */
  expMonth?: string;
}

export function PaymentMethodUpdatePayloadFromJSON(
  json: any
): PaymentMethodUpdatePayload {
  return PaymentMethodUpdatePayloadFromJSONTyped(json, false);
}

export function PaymentMethodUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentMethodUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    expYear: !exists(json, 'exp_year') ? undefined : json['exp_year'],
    expMonth: !exists(json, 'exp_month') ? undefined : json['exp_month'],
  };
}

export function PaymentMethodUpdatePayloadToJSON(
  value?: PaymentMethodUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    exp_year: value.expYear,
    exp_month: value.expMonth,
  };
}
