import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import { ApplicationState } from '../../../types/applicationState';
import React, { useEffect } from 'react';
import { fetchReports } from '../../../store/reports/reportsThunks';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import {
  ReportsHeader,
  reportTypeValues,
} from '../ReportsHeader/ReportsHeader';
import { ReportsList } from '../ReportsList/ReportsList';
import {
  ReportModel,
  ReportCreatePayloadReportFrameworkEnum,
} from '../../../swagger';
import { selectsReports } from '../../../selectors/reports/selectReports';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { reportsRoute } from '../../Routes/Routes';
import { DataRoomTabs } from '../../../features/compliance/dataRoom/DataRoomTabs/DataRoomTabs';

export const reportsRouteWithType = (type: reportTypeValues) =>
  `${reportsRoute}?type=${type}`;
export const reportsRouteWithAddType = (type: reportTypeValues) =>
  `${reportsRoute}?add-type=${type}`;
export const reportsSoaRoute = (
  framework: ReportCreatePayloadReportFrameworkEnum,
  addType: reportTypeValues = 'soa'
) => `${reportsRoute}?add-type=${addType}&framework=${framework}`;

export const ReportsPage: React.FC = () => {
  const baseCss = 'reportsPage';
  const vendorUser = useSelector(selectCurrentVendorUser);
  const dispatch = useDispatch();
  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );
  const searchText = useSelector(
    (state: ApplicationState) => state.reports.reportsSearch
  );
  const { isFetchingReports, reports, isCreatingReport } = useSelector(
    (state: ApplicationState) => state.reports
  );
  const reportTypeParam = useQueryParams().get('type') as reportTypeValues;
  const selectedType = reportTypeParam || 'all';

  const filteredReports: ReportModel[] = useSelector(
    selectsReports(searchText, selectedType)
  );
  useEffect(() => {
    dispatch(fetchReports(vendorId));
  }, [vendorId, vendorUser.roles]);

  if (isFetchingReports || isCreatingReport) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <DataRoomTabs currentTab="reports" />
      <ReportsHeader reports={filteredReports} />
      <ReportsList reports={filteredReports} />
    </div>
  );
};
