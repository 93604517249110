import React from 'react';
import { faFileSearch } from '@fortawesome/pro-light-svg-icons/faFileSearch';
import { AssessmentTemplateExtended } from '../../swagger';
import { EntityCard } from '../EntityCard/EntityCard';
import './AssessmentsTemplateTile.scss';
import { AdoptechButton } from '../AdoptechButton/AdoptechButton';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

interface AssessmentTemplateTileProps {
  assessmentTemplate: AssessmentTemplateExtended;
}

export const AssessmentTemplateTile: React.FC<AssessmentTemplateTileProps> = ({
  assessmentTemplate,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      {assessmentTemplate && (
        <EntityCard
          disableProgress
          onCardClick={undefined}
          icon={faFileSearch}
          title={assessmentTemplate.name}
          detailsText={{
            asHtml: false,
            text: assessmentTemplate.description,
          }}
          button={
            <AdoptechButton
              extraClass="assessmentTemplateTile--button"
              onClick={() => {
                dispatch(
                  push(
                    `/r/riskManagement/assessments/templates/${assessmentTemplate.id}`
                  )
                );
              }}
            >
              Select
            </AdoptechButton>
          }
          buttonContainerStyle="assessmentTemplateTile--container"
        ></EntityCard>
      )}
    </>
  );
};

export default AssessmentTemplateTile;
