import { AgreementModelSignatoriesStats, VendorUser } from '../swagger';
import { AgreementModel } from '../swagger/models/AgreementModel';
import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';
import { sortByDate } from './sortByDate';
import { sortByOwner } from './sortByOwner';
import { sortBySignatoriesStats } from './sortBySignatoriesStats';

export const agreementSort = (
  a: AgreementModel,
  b: AgreementModel,
  columnSort: ColumnSort
): number => {
  let direction: 1 | -1 = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof AgreementModel =
    columnSort.name as keyof AgreementModel;

  if (typedColumn === 'owner') {
    return sortByOwner(
      a[typedColumn] as VendorUser,
      b[typedColumn] as VendorUser,
      direction
    );
  }

  if (typedColumn === 'reviewDate') {
    return sortByDate(
      a[typedColumn] as string,
      b[typedColumn] as string,
      direction
    );
  }

  if (typedColumn === 'signatoriesStats') {
    return sortBySignatoriesStats(
      a[typedColumn] as AgreementModelSignatoriesStats,
      b[typedColumn] as AgreementModelSignatoriesStats,
      direction
    );
  }

  return sort(a[typedColumn], b[typedColumn], direction);
};
