import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUsers } from '../selectors/selectUsers';
import {
  SharedEmploymentStatus,
  SharedUserType,
  VendorUser,
  VendorUserRoles,
} from '../swagger';

interface OwnerOptionsProps {
  valuesAsGlobalId: boolean;
  includingGuest: boolean;
  onlyAdminable: boolean;
  onlyRoles?: VendorUserRoles[];
  excludeLeft?: boolean;
}

// Special format for API value, which we use in SelectAnswer.tsx if object is vendor user
export const globalUserId = (user: VendorUser) => {
  return `VendorUser:${user.id}`;
};

export const useOwnerOptions = ({
  valuesAsGlobalId,
  includingGuest,
  onlyAdminable,
  onlyRoles,
  excludeLeft = true,
}: Partial<OwnerOptionsProps> = {}) => {
  const users = useSelector(selectUsers);
  const adminRoles = [
    VendorUserRoles.Admin,
    VendorUserRoles.SecurityManager,
    VendorUserRoles.SecurityEditor,
    VendorUserRoles.ExternalConsultant,
  ];

  const ownerOptions = useMemo(
    () =>
      users
        .filter(user =>
          includingGuest ? true : user.userType !== SharedUserType.Guest
        )
        .filter(user =>
          onlyAdminable
            ? user.roles.some(role => adminRoles.includes(role))
            : true
        )
        .filter(user =>
          onlyRoles ? user.roles.some(role => onlyRoles.includes(role)) : true
        )
        .filter(user =>
          excludeLeft
            ? user.employmentStatus !== SharedEmploymentStatus.Left
            : true
        )
        .map(user => {
          return {
            value: valuesAsGlobalId ? globalUserId(user) : user.id,
            label: user.fullName,
          };
        }),
    [
      includingGuest,
      onlyAdminable,
      valuesAsGlobalId,
      onlyRoles,
      excludeLeft,
      users,
    ]
  );

  return { users, ownerOptions };
};
