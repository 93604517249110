import React from 'react';
import classNames from 'classnames';
import './NoDataText.scss';

interface NoDataTextProps {
  text: string;
  extraClass?: string;
}

export const NoDataText = ({ text, extraClass = '' }: NoDataTextProps) => {
  return <div className={classNames('noDataText', extraClass)}>{text}</div>;
};
