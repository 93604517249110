import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import './ProgressBar.scss';

export interface ProgressBarProps {
  disabled?: boolean;
  previousProgress?: number;
  progress: number;
  hasRadius?: boolean;
}

export const ProgressBar: React.FC<ProgressBarProps> = props => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setProgress(props.disabled ? 0 : props.progress);
    }, 100);
    return () => clearTimeout(timer);
  });

  const progressClasses = classNames({
    progressBar: true,
    'progressBar--progress': true,
    [`progressBar--progress-${progress}`]: true,
    'progressBar--progress-full': progress === 100,
    'progressBar--radius': props.hasRadius,
  });

  const defaultStyle = {
    WebkitTransition: 'width 1s ease-in-out',
    MozTransition: 'width 1s ease-in-out',
    OTransition: 'width 1s ease-in-out',
    transition: 'width 1s ease-in-out',
    width: 0,
  };

  const transitionStyle = {
    entering: { width: `${progress}%` },
    entered: { width: `${progress}%` },
    exiting: { width: 0 },
    exited: { width: `${props.previousProgress}%` },
    unmounted: { width: 0 },
  };

  return (
    <Transition appear in={progress > 0} timeout={1000}>
      {state => (
        <div
          className={progressClasses}
          style={{ ...defaultStyle, ...transitionStyle[state] }}
        />
      )}
    </Transition>
  );
};

ProgressBar.defaultProps = {
  previousProgress: 0,
};
