import { createSelector } from '@reduxjs/toolkit';
import { getVendorAssessmentResponses } from '../functions/getVendorAssessmentResponses';
import { getVendorAssessments } from '../functions/getVendorAssessments';
import { AssessmentTabs } from '../store/assessments/assessmentsState';
import { ApplicationState } from '../types/applicationState';

export const selectAssessmentsTab = createSelector(
  getVendorAssessments,
  getVendorAssessmentResponses,
  (state: ApplicationState) => state.assessments.assessmentTabToShow,
  (vendorAssessments, vendorAssessmentResponses, assessmentTabToShow) => {
    const assessmentCount = vendorAssessments.length;
    const assessmentResponsesCount = vendorAssessmentResponses.length;

    if (assessmentCount === 0 && assessmentResponsesCount === 0) {
      return AssessmentTabs.Outgoing;
    } else if (assessmentTabToShow) {
      return assessmentTabToShow;
    } else {
      return assessmentResponsesCount > 0
        ? AssessmentTabs.Incoming
        : AssessmentTabs.Outgoing;
    }
  }
);

/*
if neither populated, open Outgoing as has the tiles
if only one populated, open the non-blank one
if both are populated, open the last one opened
*/
