import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVendorSupplier } from '../../store/vendors/vendorsThunks';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './DeleteVendorModal.scss';
import { VendorSupplierModel } from '../../swagger';

interface DeleteVendorModalProps {
  vendorToDelete: VendorSupplierModel;
  onCancel(): void;
  onConfirm(): void;
}

export const DeleteVendorModal: React.FC<DeleteVendorModalProps> = ({
  vendorToDelete,
  onCancel,
  onConfirm,
}: DeleteVendorModalProps) => {
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(deleteVendorSupplier({ onSuccess: onConfirm }));
  };

  return (
    <AdoptechModal className="deleteVendorModal" onHide={() => {}} show>
      <Modal.Header>Delete Vendor</Modal.Header>
      <Modal.Body>
        <div>Are you sure you wish to delete the following vendor:</div>
        <div className="deleteVendorModal--vendorName">
          {vendorToDelete?.name}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={onCancel}>Cancel</AdoptechButton>
        <AdoptechButton
          onClick={submit}
          variant={AdoptechButtonVariant.Warning}
        >
          Confirm
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
