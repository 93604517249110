/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CorrectiveActionExtendedAllOf,
  CorrectiveActionExtendedAllOfFromJSON,
  CorrectiveActionExtendedAllOfFromJSONTyped,
  CorrectiveActionExtendedAllOfToJSON,
  SharedAttachmentModel,
  SharedAttachmentModelFromJSON,
  SharedAttachmentModelFromJSONTyped,
  SharedAttachmentModelToJSON,
  VendorActionModel,
  VendorActionModelFromJSON,
  VendorActionModelFromJSONTyped,
  VendorActionModelToJSON,
  VendorEventEventType,
  VendorEventEventTypeFromJSON,
  VendorEventEventTypeFromJSONTyped,
  VendorEventEventTypeToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorActionExtended
 */
export interface VendorActionExtended {
  /**
   *
   * @type {string}
   * @memberof VendorActionExtended
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof VendorActionExtended
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof VendorActionExtended
   */
  description?: string | null;
  /**
   *
   * @type {VendorUser}
   * @memberof VendorActionExtended
   */
  owner?: VendorUser;
  /**
   *
   * @type {VendorEventEventType}
   * @memberof VendorActionExtended
   */
  actionType?: VendorEventEventType;
  /**
   *
   * @type {boolean}
   * @memberof VendorActionExtended
   */
  completed?: boolean;
  /**
   *
   * @type {string}
   * @memberof VendorActionExtended
   */
  url?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorActionExtended
   */
  status?: VendorActionExtendedStatusEnum;
  /**
   *
   * @type {string}
   * @memberof VendorActionExtended
   */
  dueDate?: string;
  /**
   *
   * @type {string}
   * @memberof VendorActionExtended
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof VendorActionExtended
   */
  updatedAt?: string;
  /**
   *
   * @type {Array<SharedAttachmentModel>}
   * @memberof VendorActionExtended
   */
  attachments?: Array<SharedAttachmentModel>;
}

export function VendorActionExtendedFromJSON(json: any): VendorActionExtended {
  return VendorActionExtendedFromJSONTyped(json, false);
}

export function VendorActionExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorActionExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    actionType: !exists(json, 'action_type')
      ? undefined
      : VendorEventEventTypeFromJSON(json['action_type']),
    completed: !exists(json, 'completed') ? undefined : json['completed'],
    url: !exists(json, 'url') ? undefined : json['url'],
    status: !exists(json, 'status') ? undefined : json['status'],
    dueDate: !exists(json, 'due_date') ? undefined : json['due_date'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(SharedAttachmentModelFromJSON),
  };
}

export function VendorActionExtendedToJSON(
  value?: VendorActionExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    owner: VendorUserToJSON(value.owner),
    action_type: VendorEventEventTypeToJSON(value.actionType),
    completed: value.completed,
    url: value.url,
    status: value.status,
    due_date: value.dueDate,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    attachments:
      value.attachments === undefined
        ? undefined
        : (value.attachments as Array<any>).map(SharedAttachmentModelToJSON),
  };
}

/**
 * @export
 * @enum {string}
 */
export enum VendorActionExtendedStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Overdue = 'overdue',
}
