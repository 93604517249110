import { controlIsCompliant } from '../../../../../store/compliance/complianceSlice';
import {
  ControlExtended,
  ControlModel,
  TaskModel,
  TaskModelStatusEnum,
} from '../../../../../swagger';

export const completedValue = 100;

export const setTaskStatus = (
  status: TaskModelStatusEnum,
  list: TaskModel[],
  taskId: string
) => {
  const tk = [...list];
  const index = tk.findIndex(t => t.id === taskId);
  tk.splice(index, 1, {
    ...tk[index],
    status,
  });
  return tk;
};

// used in control edit drawer: header and form: not applicable/compliant/not compliant
// for ticks icons: use instead controlIconClass logic: greenTick, redTick, greyTick

export const getControlStatusText = (
  tasks: TaskModel[],
  applicable: boolean
) => {
  if (!applicable) return ControlStatusText.NotApplicable;
  return controlIsCompliant(tasks)
    ? ControlStatusText.Compliant
    : ControlStatusText.NotCompliant;
};

export enum ControlStatusText {
  NotApplicable = 'Not applicable',
  Compliant = 'Compliant',
  NotCompliant = 'Not compliant',
}
