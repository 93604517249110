import { createSelector } from '@reduxjs/toolkit';
import { selectCurrentVendor } from './selectCurrentVendor';
import { ApplicationState } from '../types/applicationState';
import { sortByDate } from '../functions/sortByDate';
import { selectPaymentPlans } from './selectPaymentPlans';

export const selectActiveSubscription = createSelector(
  (state: ApplicationState) => state.user.subscriptions,
  (state: ApplicationState) => selectCurrentVendor(state),
  (subscriptions, currentVendor) => {
    return subscriptions.find(
      s => s.isActive && s.vendorId === currentVendor?.id
    );
  }
);

// select subscription which doesn't have outdated plan
export const selectActiveNotOutdatedSubscription = createSelector(
  (state: ApplicationState) => state.user.subscriptions,
  (state: ApplicationState) => selectCurrentVendor(state),
  (state: ApplicationState) => selectPaymentPlans(state),
  (subscriptions, currentVendor, plans) => {
    return [...subscriptions]
      .sort((a, b) => sortByDate(a.startDate, b.startDate, -1))
      .filter(subscription =>
        plans.map(plan => plan.id).includes(subscription.paymentPlanId)
      )
      .find(s => s.isActive && s.vendorId === currentVendor?.id);
  }
);
// If we have subscription with outdated plan like Bootup => Just ignore it ( use can subscribe to the new plan ) and show warning

export const outdatedActiveSubscription = createSelector(
  (state: ApplicationState) => state.user.subscriptions,
  (state: ApplicationState) => selectCurrentVendor(state),
  (state: ApplicationState) => selectPaymentPlans(state),
  (subscriptions, currentVendor, plans) => {
    return [...subscriptions]
      .filter(
        subscription =>
          !plans.map(plan => plan.id).includes(subscription.paymentPlanId)
      )
      .find(s => s.isActive && s.vendorId === currentVendor?.id);
  }
);
