import React, { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsers } from '../../selectors/selectUsers';
import {
  addUserToGenerate,
  hideGenerateChecklistUsersModal,
  showGenerateChecklistManagersModal,
} from '../../store/checklists/checklistsSlice';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import './GenerateChecklistUsersModal.scss';

export const GenerateChecklistUsersModal: React.FC = () => {
  const dispatch = useDispatch();

  const { isShowingGenerateChecklistUsersModal, checklistTemplateToGenerate } =
    useSelector((state: ApplicationState) => state.checklists);

  const [selectedUser, setSelectedUser] = useState<string>('');
  const users = useSelector(selectUsers);

  const handleCancel = () => {
    dispatch(hideGenerateChecklistUsersModal());
  };

  const handleDone = () => {
    dispatch(addUserToGenerate(selectedUser));
    dispatch(showGenerateChecklistManagersModal(checklistTemplateToGenerate));
    dispatch(hideGenerateChecklistUsersModal());
  };

  const handleSelect = (option: SelectionOption) => {
    setSelectedUser(option.value);
  };

  const options = useMemo(
    () => users.map(u => ({ value: u.id, label: u.email })),
    [users]
  );

  return (
    <React.Fragment>
      <AdoptechModal
        className="generateChecklistUsersModal"
        onHide={handleCancel}
        show={isShowingGenerateChecklistUsersModal}
      >
        <Modal.Header>
          <div className="generateChecklistUsersModal--header">
            <div>Generate checklist</div>
            <div className="generateChecklistUsersModal--subHeader">
              Which member of staff does this checklist relate to?
            </div>
            <div className="generateChecklistUsersModal--guidance">
              Guidance: This is the person for whom you are filling out the
              form.
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="generateChecklistUsersModal--staffMembers">
            <AdoptechReactSelect
              id="generateChecklistUsersModal--userSelect"
              onChange={handleSelect}
              options={options}
              showUserAvatar
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={handleCancel}>Cancel</AdoptechButton>

          <AdoptechButton
            disabled={!selectedUser}
            onClick={handleDone}
            variant={AdoptechButtonVariant.Primary}
          >
            Next
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
