/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorAccess,
  VendorAccessFromJSON,
  VendorAccessFromJSONTyped,
  VendorAccessToJSON,
} from './';

/**
 *
 * @export
 * @interface Vendor
 */
export interface Vendor {
  /**
   *
   * @type {string}
   * @memberof Vendor
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Vendor
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Vendor
   */
  website?: string | null;
  /**
   *
   * @type {string}
   * @memberof Vendor
   */
  domain?: string | null;
  /**
   *
   * @type {string}
   * @memberof Vendor
   */
  linkedin?: string | null;
  /**
   *
   * @type {string}
   * @memberof Vendor
   */
  twitter?: string | null;
  /**
   *
   * @type {VendorAccess}
   * @memberof Vendor
   */
  access?: VendorAccess;
  /**
   *
   * @type {string}
   * @memberof Vendor
   */
  accessGroupId?: string | null;
  /**
   *
   * @type {string}
   * @memberof Vendor
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof Vendor
   */
  updatedAt?: string;
}

export function VendorFromJSON(json: any): Vendor {
  return VendorFromJSONTyped(json, false);
}

export function VendorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Vendor {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    website: !exists(json, 'website') ? undefined : json['website'],
    domain: !exists(json, 'domain') ? undefined : json['domain'],
    linkedin: !exists(json, 'linkedin') ? undefined : json['linkedin'],
    twitter: !exists(json, 'twitter') ? undefined : json['twitter'],
    access: !exists(json, 'access')
      ? undefined
      : VendorAccessFromJSON(json['access']),
    accessGroupId: !exists(json, 'access_group_id')
      ? undefined
      : json['access_group_id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function VendorToJSON(value?: Vendor | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    website: value.website,
    domain: value.domain,
    linkedin: value.linkedin,
    twitter: value.twitter,
    access: VendorAccessToJSON(value.access),
    access_group_id: value.accessGroupId,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
