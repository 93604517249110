import React, { useEffect, useState } from 'react';
import {
  Answer,
  Question,
  QuestionObjectTypeEnum,
  QuestionTableTypeEnum,
} from '../../swagger/models';
import { AdoptechButton } from '../AdoptechButton/AdoptechButton';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { IconButton } from '../IconButton/IconButton';
import './TableAnswer2.scss';
import { CommonAnswerType } from '../../types/CommonAnswerType';
import { TableAnswerEditModal2 } from './TableAnswerEditModal2/TableAnswerEditModal2';
import { OnChangeAnswerOptions } from '../AnswerFormControl/AnswerFormControl';
import { TableAnswerDeleteModal2 } from './TableAnswerDeleteModal2/TableAnswerDeleteModal2';
import { getAnswerDisplayValue } from './tableUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Interweave } from 'interweave';
import { fetchVendorTable } from '../../store/vendors/vendorsThunks';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { ApplicationState } from '../../types/applicationState';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';

export interface TableAnswer2Props {
  onDeleteTable2Answers: (answers: CommonAnswerType[]) => void;
  onChange: (value: CommonAnswerType[], options: OnChangeAnswerOptions) => void;
  question: Question;
}

export enum TableActions {
  Edit = 'Edit',
  Delete = 'Delete',
  Add = 'Add',
  View = 'View',
}

export const TableAnswer2: React.FC<TableAnswer2Props> = ({
  question,
  onChange,
  onDeleteTable2Answers,
}) => {
  const [rowToChange, setRowToChange] = useState<{
    index: number;
    row: Answer[];
  }>();

  const [action, setAction] = useState<TableActions>();

  const handleEdit = (index: number, row: Answer[]) => () => {
    setAction(TableActions.Edit);
    setRowToChange({ index, row });
  };

  const handleView = (index: number, row: Answer[]) => () => {
    setAction(TableActions.View);
    setRowToChange({ index, row });
  };

  const handleDelete = (index: number, row: Answer[]) => () => {
    setAction(TableActions.Delete);
    setRowToChange({
      index,
      row,
    });
  };

  const handleAdd = () => {
    const largestIndex =
      sortedAnswersRows?.length > 0
        ? [...sortedAnswersRows.map(row => row[0].index)].pop() + 1
        : 0;
    setRowToChange({ index: largestIndex, row: null });
    setAction(TableActions.Add);
  };

  const handleCancelUpdateModals = () => {
    setAction(null);
    setRowToChange(null);
  };

  const showEditTableModal =
    action === TableActions.Add ||
    action === TableActions.Edit ||
    action === TableActions.View;

  const showDeleteAnswersModal = action === TableActions.Delete;

  const dispatch = useDispatch();

  const currentVendor = useSelector(selectCurrentVendor);
  const vendorTableCache = useSelector(
    (state: ApplicationState) => state.vendors.vendorTableCache
  );

  const sortedAnswersRows = question.table.answers
    .slice()
    .sort((a, b) => a[0].index - b[0].index);

  useEffect(() => {
    if (!question) {
      return;
    }

    const questionsWithObjects = question.table.questions.filter(
      q => q.objectType
    );

    const alreadyRequested: QuestionObjectTypeEnum[] = [];
    questionsWithObjects.forEach(questionWithObject => {
      if (
        alreadyRequested.indexOf(questionWithObject.objectType) > -1 ||
        (vendorTableCache && vendorTableCache[questionWithObject.objectType])
      ) {
        return;
      }

      dispatch(
        fetchVendorTable({
          type: questionWithObject.objectType,
          vendorId: currentVendor.id,
        })
      );

      alreadyRequested.push(questionWithObject.objectType);
    });
  }, [question]);

  return (
    <div className="tableAnswer2">
      <AdoptechTable embedded>
        <thead>
          <tr>
            {question.table.questions
              .filter(q => q.primary)
              .map((tableQuestion: Question) => (
                <th key={tableQuestion.propertyName}>
                  {tableQuestion.questionText}
                  {tableQuestion.explanatoryText && (
                    <OverlayTrigger
                      trigger={['focus', 'hover']}
                      overlay={
                        <Popover
                          id={`tableAnswer2--popover-${tableQuestion.id}`}
                        >
                          <Popover.Title>
                            <Interweave
                              content={tableQuestion.questionTextAsHtml}
                            />
                          </Popover.Title>
                          <Popover.Content>
                            <Interweave
                              content={tableQuestion.explanatoryTextAsHtml}
                            />
                          </Popover.Content>
                        </Popover>
                      }
                      placement="auto"
                    >
                      <FontAwesomeIcon
                        className="tableAnswer2--explanatoryText"
                        icon={faQuestionCircle}
                      />
                    </OverlayTrigger>
                  )}
                </th>
              ))}
            {question.table.questions.some(q => !q.primary) && (
              <OverlayTrigger
                trigger={['focus', 'hover']}
                overlay={
                  <Popover id="tableAnswer2--popover-extraColumns">
                    <Popover.Title>Additional Columns</Popover.Title>
                    <Popover.Content>
                      This table contains additional columns that are not
                      displayed. In order to see these columns, click on the
                      ellipsis for the row.
                    </Popover.Content>
                  </Popover>
                }
                placement="auto"
              >
                <th>
                  ...
                  <FontAwesomeIcon
                    className="tableAnswer2--explanatoryText"
                    icon={faQuestionCircle}
                  />
                </th>
              </OverlayTrigger>
            )}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedAnswersRows.map((answerRow: Answer[]) => {
            const index = answerRow[0].index;

            return (
              <tr key={index}>
                {question.table.questions
                  .filter(q => q.primary)
                  .map((tableQuestion: Question) => {
                    const answer = answerRow.find(
                      currentAnswer =>
                        currentAnswer.propertyName ===
                        tableQuestion.propertyName
                    );
                    if (!answer) return null;
                    return (
                      <td key={answer?.id}>
                        {answer &&
                          getAnswerDisplayValue(
                            question,
                            answer,
                            vendorTableCache
                          )}
                      </td>
                    );
                  })}
                {question.table.questions.some(q => !q.primary) && (
                  <td
                    className="tableAnswer2--additionalColumns"
                    onClick={handleView(index, answerRow)}
                  >
                    ...
                  </td>
                )}
                <td>
                  <div className="adoptechTable--actions">
                    <IconButton
                      ariaLabel="Edit"
                      icon={faPen}
                      onClick={handleEdit(index, answerRow)}
                      tooltip="Edit"
                    />
                    <IconButton
                      ariaLabel="Delete"
                      icon={faTrashAlt}
                      onClick={handleDelete(index, answerRow)}
                      tooltip="Delete"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </AdoptechTable>
      <div className="tableAnswer2--addButton">
        <AdoptechButton onClick={handleAdd}>+ Add</AdoptechButton>
      </div>
      {showEditTableModal && (
        <TableAnswerEditModal2
          onUpdate={answers => onChange(answers, { isTable2Answer: true })}
          onCancel={handleCancelUpdateModals}
          question={question}
          rowToChange={rowToChange}
          action={action}
        />
      )}
      {showDeleteAnswersModal && (
        <TableAnswerDeleteModal2
          onDelete={onDeleteTable2Answers}
          onCancel={handleCancelUpdateModals}
          question={question}
          rowToChange={rowToChange}
          vendorTableCache={vendorTableCache}
        />
      )}
    </div>
  );
};
