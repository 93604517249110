import classNames from 'classnames';
import { filesize } from 'filesize';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideAddFileOrUrlModal,
  hideFileOrUrlSuccessfulToast,
} from '../../store/assessments/assessmentsSlice';
import { AddFileMode } from '../../store/assessments/assessmentsState';
import { addFileOrUrl } from '../../store/assessments/assessmentsThunks';
import { ApplicationState } from '../../types/applicationState';
import { URL_REGEX } from '../../types/constants';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import { MessageToast } from '../MessageToast/MessageToast';
import './AddFileOrUrlModal.scss';
import { portalRoutes } from '../Routes/Routes';

export const AddFileOrUrlModal: React.FC = () => {
  const fileRef = useRef<HTMLInputElement>();
  const urlRef = useRef<HTMLInputElement>();

  const [description, setDescription] = useState('');
  const [file, setFile] = useState<File>(null);
  const [url, setUrl] = useState('');
  const [valid, setValid] = useState(false);

  const {
    addFileMode,
    isShowingAddFileModal,
    isShowingFileOrUrlSuccessfulToast,
    isUploadingFile,
  } = useSelector((state: ApplicationState) => state.assessments);

  useEffect(() => {
    if (fileRef.current) {
      fileRef.current.focus();
    }
    if (urlRef.current) {
      urlRef.current.focus();
    }
    setDescription('');
    setFile(null);
    setUrl('');
  }, [isShowingAddFileModal]);

  useEffect(() => {
    if (addFileMode === AddFileMode.File) {
      setValid(
        file &&
          file.size <= +process.env.REACT_APP_MAX_FILE_SIZE &&
          description !== ''
      );
    }

    if (addFileMode === AddFileMode.Url) {
      setValid(URL_REGEX.test(url) && description !== '');
    }
  }, [file, description, url]);

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <AdoptechModal
        className="addFileOrUrlModal"
        onHide={() => dispatch(hideAddFileOrUrlModal())}
        show={isShowingAddFileModal}
      >
        <Modal.Header>
          {addFileMode === AddFileMode.File && 'Upload'}
          {addFileMode === AddFileMode.Url && 'Add Link'}
        </Modal.Header>
        <Modal.Body className="addFileOrUrlModal--body">
          <div className="addFileOrUrlModal--prompt">
            {addFileMode === AddFileMode.File && (
              <React.Fragment>
                <p>
                  Please click on Choose file to upload your document up to and
                  enter a description that will be displayed in the portal.
                </p>
                <p>
                  Supported file types: csv, pdf, xls, xlsm, xlsx.
                  <br /> Maximum file size: 10MB.
                </p>
              </React.Fragment>
            )}
            {addFileMode === AddFileMode.Url && (
              <div>
                Enter the URL to the file and a description that will be
                displayed in the portal.
              </div>
            )}
          </div>
          <div className="addFileOrUrlModal--form">
            {addFileMode === AddFileMode.File && (
              <div className="addFileOrUrlModal--row">
                <div className="addFileOrUrlModal--field">
                  <div className="addFileOrUrlModal--label">File*</div>
                  <div className="addFileOrUrlModal--fileUpload-wrapper">
                    <AdoptechButton
                      extraClass="addFileOrUrlModal--fileUpload-button"
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    >
                      Choose file
                    </AdoptechButton>
                    {file ? (
                      <div>
                        {file.name} [{filesize(file.size)}]
                      </div>
                    ) : (
                      <div>No file selected</div>
                    )}
                    <input
                      hidden
                      accept=".csv, .pdf, .xls, .xlsm, .xlsx, .doc, .docx"
                      onChange={e => setFile(e.currentTarget.files[0])}
                      ref={fileRef}
                      type="file"
                    />
                  </div>
                </div>
              </div>
            )}
            {addFileMode === AddFileMode.Url && (
              <div className="addFileOrUrlModal--row">
                <div className="addFileOrUrlModal--field">
                  <div className="addFileOrUrlModal--label">Link URL*</div>
                  <AdoptechTextInput
                    hasError={!URL_REGEX.test(url)}
                    id="link url"
                    onChange={e => setUrl(e.currentTarget.value)}
                    ref={urlRef}
                    placeholder={portalRoutes.myDocument}
                    type="text"
                    value={url}
                  />
                </div>
              </div>
            )}
            <div className="addFileOrUrlModal--row">
              <div className="addFileOrUrlModal--field">
                <div className="addFileOrUrlModal--label">Description*</div>
                <AdoptechTextArea
                  onChange={e => setDescription(e.currentTarget.value)}
                  value={description}
                  id="Description"
                />
              </div>
            </div>
          </div>
          <div className="addFileOrUrlModal--label">*Required fields</div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={() => dispatch(hideAddFileOrUrlModal())}>
            Cancel
          </AdoptechButton>
          <AdoptechButton
            busy={isUploadingFile}
            disabled={isUploadingFile || !valid}
            onClick={() => dispatch(addFileOrUrl({ description, file, url }))}
            variant={AdoptechButtonVariant.Primary}
          >
            {addFileMode === AddFileMode.File && 'UPLOAD'}
            {addFileMode === AddFileMode.Url && 'ADD'}
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>

      <MessageToast
        autohide
        delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
        onClose={() => dispatch(hideFileOrUrlSuccessfulToast())}
        show={isShowingFileOrUrlSuccessfulToast}
      >
        {addFileMode === AddFileMode.File && 'File '}
        {addFileMode === AddFileMode.Url && 'URL '}
        successfully added.
      </MessageToast>
    </React.Fragment>
  );
};
