import React, { DOMAttributes } from 'react';

export const AdoptechDropdownToggle = React.forwardRef<
  HTMLDivElement,
  DOMAttributes<any>
>(({ onClick, children }, ref) => {
  return (
    <div
      ref={ref}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  );
});
