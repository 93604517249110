/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserSignIn
 */
export interface UserSignIn {
  /**
   *
   * @type {string}
   * @memberof UserSignIn
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignIn
   */
  password?: string;
}

export function UserSignInFromJSON(json: any): UserSignIn {
  return UserSignInFromJSONTyped(json, false);
}

export function UserSignInFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserSignIn {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, 'email') ? undefined : json['email'],
    password: !exists(json, 'password') ? undefined : json['password'],
  };
}

export function UserSignInToJSON(value?: UserSignIn | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    password: value.password,
  };
}
