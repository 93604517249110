/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AuditCreatePayloadAudit
 */
export interface AuditCreatePayloadAudit {
  /**
   *
   * @type {string}
   * @memberof AuditCreatePayloadAudit
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AuditCreatePayloadAudit
   */
  auditTemplateId: string;
  /**
   *
   * @type {string}
   * @memberof AuditCreatePayloadAudit
   */
  ownerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditCreatePayloadAudit
   */
  startedAt?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof AuditCreatePayloadAudit
   */
  questionIds: Array<string>;
}

export function AuditCreatePayloadAuditFromJSON(
  json: any
): AuditCreatePayloadAudit {
  return AuditCreatePayloadAuditFromJSONTyped(json, false);
}

export function AuditCreatePayloadAuditFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuditCreatePayloadAudit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    auditTemplateId: json['audit_template_id'],
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    startedAt: !exists(json, 'started_at') ? undefined : json['started_at'],
    questionIds: json['question_ids'],
  };
}

export function AuditCreatePayloadAuditToJSON(
  value?: AuditCreatePayloadAudit | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    audit_template_id: value.auditTemplateId,
    owner_id: value.ownerId,
    started_at: value.startedAt,
    question_ids: value.questionIds,
  };
}
