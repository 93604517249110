export const capitaliseFirst = (input: string) => {
  if (!input || input.length === 0) {
    return '';
  }

  const input_without_underline = input.replace(/_/g, ' ');
  return (
    input_without_underline.charAt(0).toUpperCase() +
    input_without_underline.slice(1)
  );
};
