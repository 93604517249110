import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons/faCircleQuestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import classNames from 'classnames';
import React from 'react';
import {
  chatWidgetArticleId,
  showWidgetChatArticle,
} from '../../functions/chatWidgetHelper';
import './ChatWidgetIcon.scss';

interface ChatWidgetIconProps {
  extraCss: string;
  articleId: chatWidgetArticleId;
  icon?: IconDefinition;
}

export const ChatWidgetIcon: React.FC<ChatWidgetIconProps> = ({
  extraCss,
  articleId,
  icon,
}) => {
  const classes = classNames('intercomIcon--baseStyle', extraCss);

  return (
    <FontAwesomeIcon
      onClick={e => {
        e.stopPropagation();
        showWidgetChatArticle(articleId);
      }}
      icon={icon ? icon : faCircleQuestion}
      className={classes}
    />
  );
};
