/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  RiskModelActionStats,
  RiskModelActionStatsFromJSON,
  RiskModelActionStatsFromJSONTyped,
  RiskModelActionStatsToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface RiskModel
 */
export interface RiskModel {
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  riskTemplateId?: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  riskCategoryId: string;
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  status?: RiskModelStatusEnum;
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  treatmentStrategy?: RiskModelTreatmentStrategyEnum;
  /**
   *
   * @type {VendorUser}
   * @memberof RiskModel
   */
  owner?: VendorUser;
  /**
   *
   * @type {number}
   * @memberof RiskModel
   */
  inherentRisk?: number | null;
  /**
   *
   * @type {number}
   * @memberof RiskModel
   */
  inherentRiskConsequence?: number | null;
  /**
   *
   * @type {number}
   * @memberof RiskModel
   */
  inherentRiskLikelihood?: number | null;
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  inherentRiskComment?: string | null;
  /**
   *
   * @type {number}
   * @memberof RiskModel
   */
  residualRisk?: number | null;
  /**
   *
   * @type {number}
   * @memberof RiskModel
   */
  residualRiskConsequence?: number | null;
  /**
   *
   * @type {number}
   * @memberof RiskModel
   */
  residualRiskLikelihood?: number | null;
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  residualRiskComment?: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  treatmentExplanation?: string | null;
  /**
   *
   * @type {RiskModelActionStats}
   * @memberof RiskModel
   */
  actionStats?: RiskModelActionStats;
  /**
   *
   * @type {RiskModelActionStats}
   * @memberof RiskModel
   */
  checkStats?: RiskModelActionStats;
  /**
   *
   * @type {Array<string>}
   * @memberof RiskModel
   */
  informationSecurityProperties?: Array<RiskModelInformationSecurityPropertiesEnum>;
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof RiskModel
   */
  updatedAt?: string;
}

export function RiskModelFromJSON(json: any): RiskModel {
  return RiskModelFromJSONTyped(json, false);
}

export function RiskModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    riskTemplateId: !exists(json, 'risk_template_id')
      ? undefined
      : json['risk_template_id'],
    riskCategoryId: json['risk_category_id'],
    status: !exists(json, 'status') ? undefined : json['status'],
    treatmentStrategy: !exists(json, 'treatment_strategy')
      ? undefined
      : json['treatment_strategy'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    inherentRisk: !exists(json, 'inherent_risk')
      ? undefined
      : json['inherent_risk'],
    inherentRiskConsequence: !exists(json, 'inherent_risk_consequence')
      ? undefined
      : json['inherent_risk_consequence'],
    inherentRiskLikelihood: !exists(json, 'inherent_risk_likelihood')
      ? undefined
      : json['inherent_risk_likelihood'],
    inherentRiskComment: !exists(json, 'inherent_risk_comment')
      ? undefined
      : json['inherent_risk_comment'],
    residualRisk: !exists(json, 'residual_risk')
      ? undefined
      : json['residual_risk'],
    residualRiskConsequence: !exists(json, 'residual_risk_consequence')
      ? undefined
      : json['residual_risk_consequence'],
    residualRiskLikelihood: !exists(json, 'residual_risk_likelihood')
      ? undefined
      : json['residual_risk_likelihood'],
    residualRiskComment: !exists(json, 'residual_risk_comment')
      ? undefined
      : json['residual_risk_comment'],
    treatmentExplanation: !exists(json, 'treatment_explanation')
      ? undefined
      : json['treatment_explanation'],
    actionStats: !exists(json, 'action_stats')
      ? undefined
      : RiskModelActionStatsFromJSON(json['action_stats']),
    checkStats: !exists(json, 'check_stats')
      ? undefined
      : RiskModelActionStatsFromJSON(json['check_stats']),
    informationSecurityProperties: !exists(
      json,
      'information_security_properties'
    )
      ? undefined
      : json['information_security_properties'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function RiskModelToJSON(value?: RiskModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    risk_template_id: value.riskTemplateId,
    risk_category_id: value.riskCategoryId,
    status: value.status,
    treatment_strategy: value.treatmentStrategy,
    owner: VendorUserToJSON(value.owner),
    inherent_risk: value.inherentRisk,
    inherent_risk_consequence: value.inherentRiskConsequence,
    inherent_risk_likelihood: value.inherentRiskLikelihood,
    inherent_risk_comment: value.inherentRiskComment,
    residual_risk: value.residualRisk,
    residual_risk_consequence: value.residualRiskConsequence,
    residual_risk_likelihood: value.residualRiskLikelihood,
    residual_risk_comment: value.residualRiskComment,
    treatment_explanation: value.treatmentExplanation,
    action_stats: RiskModelActionStatsToJSON(value.actionStats),
    check_stats: RiskModelActionStatsToJSON(value.checkStats),
    information_security_properties: value.informationSecurityProperties,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum RiskModelStatusEnum {
  ReviewPending = 'review_pending',
  Approved = 'approved',
}
/**
 * @export
 * @enum {string}
 */
export enum RiskModelTreatmentStrategyEnum {
  Modify = 'modify',
  Share = 'share',
  Avoid = 'avoid',
  Retain = 'retain',
}
/**
 * @export
 * @enum {string}
 */
export enum RiskModelInformationSecurityPropertiesEnum {
  Confidentiality = 'confidentiality',
  Integrity = 'integrity',
  Availability = 'availability',
}
