import React from 'react';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  setShowRequestDocumentModal,
  showTrustHubAuthModal,
} from '../../store/trusthubSlice';
import { ApplicationState } from '../../../../types/applicationState';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { selectCurrentUser } from '../../store/trusthubSelectors';
import { logoutTrustHub } from '../../store/trusthubThunks';
import { faRightToBracket } from '@fortawesome/pro-light-svg-icons/faRightToBracket';
import { faRightFromBracket } from '@fortawesome/pro-light-svg-icons/faRightFromBracket';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';

export const TrustHubUserPageHeaderButtons: React.FC = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const handleLoginModal = () => dispatch(showTrustHubAuthModal('login'));
  const handleLogout = () => dispatch(logoutTrustHub());
  const handleSignupModal = () => dispatch(showTrustHubAuthModal('required'));
  const handleRequestDocModal = () =>
    dispatch(setShowRequestDocumentModal(true));

  const status = useSelector(
    (state: ApplicationState) => state.trustHub.auth.status
  );

  const baseCss = 'userButtons';

  return (
    <>
      {(status === 'idle' || status === 'failed') && (
        <>
          <AdoptechButton
            icon={faRightToBracket}
            variant={AdoptechButtonVariant.White}
            onClick={handleLoginModal}
          >
            Login
          </AdoptechButton>
          <AdoptechButton
            icon={faUserPlus}
            variant={AdoptechButtonVariant.White}
            onClick={handleSignupModal}
          >
            Create Account
          </AdoptechButton>
        </>
      )}
      {status === 'succeeded' && (
        <>
          <AdoptechButton
            icon={faKey}
            variant={AdoptechButtonVariant.White}
            onClick={handleRequestDocModal}
          >
            Request Access
          </AdoptechButton>
          <AdoptechButton
            icon={faRightFromBracket}
            variant={AdoptechButtonVariant.White}
            onClick={handleLogout}
          >
            Logout
          </AdoptechButton>
        </>
      )}

      {currentUser && (
        <UserAvatar
          user={currentUser}
          hideText
          initialsColor="#666665"
          customColor="#f9f9f9"
          showTooltip
        />
      )}
      {status === 'loading' && <LoadingSpinner inlineSmall />}
    </>
  );
};
