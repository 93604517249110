import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';

export const genericSort = <T>(a: T, b: T, columnSort: ColumnSort): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof T = columnSort.name as keyof T;

  switch (typedColumn) {
    default:
      return sort(a[typedColumn], b[typedColumn], direction);
  }
};
