import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { patchVendorPolicyClause } from '../../store/policies/policiesThunks';
import { VendorPolicyClause } from '../../swagger/models';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';
import './PolicyClauseHeader.scss';

interface PolicyClauseHeaderProps {
  policyClause: VendorPolicyClause;
  progress: number;
}

export const PolicyClauseHeader: React.FC<PolicyClauseHeaderProps> = ({
  policyClause,
  progress,
}) => {
  const dispatch = useDispatch();
  const [leaveOut, setLeaveOut] = useState(policyClause.leaveOut);

  const policyClauseHeaderTitleClasses = classNames({
    'policyClauseHeader--title': true,
    'policyClauseHeader--title-leftOut': leaveOut,
  });

  useEffect(() => {
    setLeaveOut(policyClause.leaveOut);
  }, [policyClause]);

  const tickClass = progress === 1 ? 'greenTick' : 'greyTick';
  const icon = progress === 1 ? faCheckCircle : faCircle;
  const excluded = leaveOut;
  const included = !excluded;

  return (
    <div className={policyClauseHeaderTitleClasses}>
      <FontAwesomeIcon icon={icon} className={tickClass + ' mr-4'} />
      <div className="flex3 policyClauseHeader--field">{policyClause.name}</div>
      <div className="flex3">
        <div className="policyClauseHeader--leaveOutToggle">
          <OverlayTrigger
            overlay={
              <Tooltip id={`hint-tooltip-${policyClause.id}`}>
                {excluded
                  ? 'Slide to include clause'
                  : 'Slide to exclude clause'}
              </Tooltip>
            }
            placement="left"
          >
            <div>
              <ToggleSwitch
                checked={included}
                onChange={() => {
                  dispatch(
                    patchVendorPolicyClause(policyClause.id, {
                      vendorPolicyId: policyClause.vendorPolicyId,
                      leaveOut: !leaveOut,
                    })
                  );
                }}
              />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};
