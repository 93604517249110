import React from 'react';
import { useSelector } from 'react-redux';
import { selectRecentlyCreatedAssessments } from '../../selectors/selectRecentlyCreatedAssessments';
import { Intent, Lozenge } from '../Lozenge/Lozenge';
import { NoDataText } from '../NoDataText/NoDataText';
import { RecentlyCreatedAssessmentsGrid } from '../RecentlyCreatedAssessmentsGrid/RecentlyCreatedAssessmentsGrid';
import './RecentlyCreatedAssessments.scss';

export const RecentlyCreatedAssessments: React.FC = () => {
  const assessments = useSelector(selectRecentlyCreatedAssessments);

  return (
    <div className="recentlyCreatedAssessments">
      <div className="recentlyCreatedAssessments--header">
        <div className="recentlyCreatedAssessments--title">
          Active
          <Lozenge intent={Intent.None} value={assessments.length} />
        </div>
      </div>

      {assessments.length > 0 && (
        <span className="recentlyCreatedAssessments--description">
          Assessments that have been generated or are awaiting response.
        </span>
      )}

      {assessments.length === 0 && (
        <NoDataText text="Assessments you have sent out and are awaiting response will appear here" />
      )}
      {assessments.length > 0 && <RecentlyCreatedAssessmentsGrid />}
    </div>
  );
};
