import assessmentTour1 from 'images/assessmentTour1.png';
import assessmentTour2 from 'images/assessmentTour2.png';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { clearAssessmentHelp } from '../../store/global/globalSlice';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './AssessmentTour.scss';

export const AssessmentTour: React.FC = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const showAssessmentHelp = useSelector(
    (state: ApplicationState) => state.global.showAssessmentHelp
  );

  useEffect(() => {
    setPage(1);
  }, [showAssessmentHelp]);

  const goToAssessment = () => {
    dispatch(clearAssessmentHelp());
  };

  const next = () => {
    setPage(2);
  };
  const back = () => {
    setPage(1);
  };

  return (
    <AdoptechModal
      show={showAssessmentHelp}
      className="assessmentTour"
      onHide={() => dispatch(clearAssessmentHelp())}
    >
      <Modal.Header className="assessmentTour--title">
        Completing the assessment
      </Modal.Header>
      <Modal.Body>
        <div className="assessmentTour--text">
          {page === 1 ? (
            <span>
              <p>
                Please complete all the sections and when finished, click{' '}
                <b>PUBLISH</b> to submit your answers.
              </p>
            </span>
          ) : (
            <span>
              <p>
                To speed up the completion of this assessment we pre-populated
                some answers with information from your company profile and
                previously completed forms.
              </p>
              <p>
                Click <b>CONFIRM</b> to accept your previous answer, click on{' '}
                <b>LIBRARY</b> to review your full answer history or simply edit
                to enter a new answer.
              </p>
            </span>
          )}
        </div>
        <div className="assessmentTour--image">
          {page === 1 ? (
            <img src={assessmentTour1} />
          ) : (
            <img src={assessmentTour2} />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="assessmentTour--footer">
        {page === 2 ? (
          <AdoptechButton onClick={back}>Back</AdoptechButton>
        ) : (
          <div />
        )}

        <AdoptechButton
          onClick={page === 1 ? next : goToAssessment}
          variant={AdoptechButtonVariant.Primary}
        >
          {page === 1 ? 'Next' : 'Get started'}
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
