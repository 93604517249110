import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  showGenerateChecklistManagersModal,
  showGenerateChecklistUsersModal,
} from '../../store/checklists/checklistsSlice';
import {
  ChecklistTemplateExtended,
  ChecklistTemplateRelatedToEnum,
} from '../../swagger';
import { MeatballMenu } from '../MeatballMenu/MeatballMenu';
import { EntityCard } from '../EntityCard/EntityCard';
import { useCanFeature } from '../../functions/access';
import { AccessObject } from '../../types/accessObject';

interface ChecklistTemplateCardProps {
  checklistTemplate: ChecklistTemplateExtended;
}

export const ChecklistTemplateCard: React.FC<ChecklistTemplateCardProps> = ({
  checklistTemplate,
}) => {
  const dispatch = useDispatch();

  const generateChecklist = () => {
    if (
      checklistTemplate.relatedTo === ChecklistTemplateRelatedToEnum.VendorUser
    ) {
      dispatch(showGenerateChecklistUsersModal(checklistTemplate));
    } else {
      dispatch(showGenerateChecklistManagersModal(checklistTemplate));
    }
  };

  const canManageChecklists = useCanFeature(AccessObject.checklists_manage);
  return (
    <EntityCard
      disableProgress
      onCardClick={generateChecklist}
      title={checklistTemplate.name}
      detailsText={{ asHtml: true, text: checklistTemplate.summaryAsHtml }}
      lastUpdatedAt={checklistTemplate.updatedAt}
    >
      <MeatballMenu leftHandPointer>
        {canManageChecklists && (
          <Dropdown.Item
            onClick={e => {
              e.stopPropagation();
              generateChecklist();
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faPen} />
            Generate
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onClick={e => {
            e.stopPropagation();
            dispatch(
              push(
                `/r/companyInformation/checklistTemplates/${checklistTemplate.id}`
              )
            );
          }}
        >
          <FontAwesomeIcon className="meatballMenu--icon" icon={faEye} />
          View
        </Dropdown.Item>
      </MeatballMenu>
    </EntityCard>
  );
};
