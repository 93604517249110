import { push } from 'connected-react-router';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdoptechButton } from '../../../components/AdoptechButton/AdoptechButton';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showExistingPolicyWarningModal } from '../../../store/policies/policiesSlice';
import { ApplicationState } from '../../../types/applicationState';
import { ExistingPolicyWarningModalMode } from '../../../components/ExistingPolicyWarningModal/ExistingPolicyWarningModal';
import { selectInProgressPolicies } from '../../../selectors/selectInProgressPolicies';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Policy } from '../../../swagger';
import { showSubscriptionRequiredModal } from '../../../store/global/globalSlice';

const PolicyItem = ({
  policy,
  subscriptionRequired,
}: {
  policy: Policy;
  subscriptionRequired: boolean;
}) => {
  const dispatch = useDispatch();

  const vendorProducts = useSelector(
    (state: ApplicationState) => state.vendors.vendorProducts
  );

  const handlePolicyClick = (p: Policy) => {
    if (subscriptionRequired) {
      dispatch(showSubscriptionRequiredModal());
      return;
    }
    if (p.vendorPolicyExists) {
      dispatch(
        showExistingPolicyWarningModal({
          policyId: p.id,
          existingPolicyWarningModalMode: ExistingPolicyWarningModalMode.Add,
        })
      );
      return;
    }
    dispatch(push(`${location.pathname}?policyCode=${p.code}`));
  };

  const isPolicyPartOfPlan = (subject: Policy) =>
    vendorProducts?.policies.some(
      p => p.policyId === subject.id && p.available
    );

  const isCreatingPolicy = useSelector(
    (state: ApplicationState) => state.policies.isCreatingPolicy
  );

  const inProgressVendorPolicies = useSelector(selectInProgressPolicies);
  const isPolicyInProgress = (subject: Policy) =>
    inProgressVendorPolicies.some(p => p.policyId === subject.id);

  const card = (draft = false) => (
    <div
      className={classNames('addNewPolicy--policyItem', {
        disabled: draft,
      })}
      key={policy.id}
      onClick={() => !draft && handlePolicyClick(policy)}
    >
      {isPolicyPartOfPlan(policy) && !subscriptionRequired && (
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="addNewPolicy--greenTick absolute"
        />
      )}
      <div className="addNewPolicy--policyItemCategoryName">
        {policy.categoryName}
      </div>
      <div className="addNewPolicy--policyItemName">{policy.name}</div>
      {!draft && (
        <div className="addNewPolicy--policySelectButton">
          <AdoptechButton
            extraClass="addNewPolicy--policySelectButton"
            disabled={isCreatingPolicy}
          >
            {policy.vendorPolicyExists ? 'Update' : 'Select'}
          </AdoptechButton>
        </div>
      )}
    </div>
  );

  if (!isPolicyInProgress(policy)) return card();

  return (
    <OverlayTrigger
      key={policy.id}
      overlay={
        <Tooltip id={`inProgressPolicyTooltip-${policy.id}`}>
          This policy is currently in draft status
        </Tooltip>
      }
    >
      <div>{card(true)}</div>
    </OverlayTrigger>
  );
};

export default PolicyItem;
