/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  AuditExtendedAllOf,
  AuditExtendedAllOfFromJSON,
  AuditExtendedAllOfFromJSONTyped,
  AuditExtendedAllOfToJSON,
  AuditModel,
  AuditModelFromJSON,
  AuditModelFromJSONTyped,
  AuditModelToJSON,
  AuditType,
  AuditTypeFromJSON,
  AuditTypeFromJSONTyped,
  AuditTypeToJSON,
  Survey,
  SurveyFromJSON,
  SurveyFromJSONTyped,
  SurveyToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface AuditExtended
 */
export interface AuditExtended extends AuditModel {
  /**
   *
   * @type {Survey}
   * @memberof AuditExtended
   */
  survey: Survey;
}

export function AuditExtendedFromJSON(json: any): AuditExtended {
  return AuditExtendedFromJSONTyped(json, false);
}

export function AuditExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuditExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...AuditModelFromJSONTyped(json, ignoreDiscriminator),
    survey: SurveyFromJSON(json['survey']),
  };
}

export function AuditExtendedToJSON(value?: AuditExtended | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...AuditModelToJSON(value),
    survey: SurveyToJSON(value.survey),
  };
}
