import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultSelectionOptions } from '../../functions/getDefaultSelectionOptions';
import { fetchTable } from '../../store/vendors/vendorsThunks';
import { QuestionTableTypeEnum } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';

interface AdoptechStateSelectProps {
  onChange?: (value: SelectionOption) => void;
  value: string;
  placeholder?: string;
}

export const AdoptechStateSelect: React.FC<AdoptechStateSelectProps> = ({
  onChange,
  value,
  placeholder,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTable(QuestionTableTypeEnum.UsState));
  }, []);
  const options = useSelector((state: ApplicationState) =>
    getDefaultSelectionOptions(QuestionTableTypeEnum.UsState, state)
  );

  return (
    <AdoptechReactSelect
      id="adoptechStateSelect"
      options={options}
      onChange={onChange}
      value={options.find(x => x.value === value)}
      placeholder={placeholder}
    />
  );
};
