import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgreementsCreateForm } from '../../../selectors/selectAgreementsCreateForm';
import { updateAgreementsCreateFormField } from '../../../store/agreements/agreementsSlice';
import {
  AgreementModel,
  AgreementTemplateCategoryModel,
  AgreementTemplateModel,
  AgreementSignatoryModel,
} from '../../../swagger';
import { NameStep, useAgreementTemplateName } from './NameStep/NameStep';
import { Footer } from './Footer';
import { TermsStep } from './TermsStep/TermsStep';
import AdoptechHS from '../../AdoptechHS/AdoptechHS';
import { useAgreementsTermsRadioOptions } from '../../../hooks/agreements/useAgreementsTermsRadioOptions';
import { SignatoriesStep } from './SignatoriesStep/SignatoriesStep';
import './AddNewAgreementForm.scss';

export interface AgreementFormProps {
  show: boolean;
  onClose(): void;
}

export interface AgreementsCreateForm {
  name: string;
  step: number;
  standardTemplateId: AgreementTemplateCategoryModel['id'];
  selectedTermsId: AgreementModel['id'] | AgreementTemplateModel['id'];
  isTouched: boolean;
  signatory: AgreementSignatoryModel;
}

export const agreementConfirmText =
  'Are you sure you wish to close this window? Your changes will not be saved. To save changes click CANCEL to return to the agreement workflow. Select CLOSE to exit without saving.';

export type AgreementCreateFormFieldPayload = Partial<AgreementsCreateForm>;

export type StepType = 'NameStep' | 'TermsStep' | 'SignatoriesStep';

export const AddNewAgreementForm: React.FC<AgreementFormProps> = ({
  show,
  onClose,
}) => {
  const form = useSelector(selectAgreementsCreateForm);

  const { onlyStandardTermsExist } = useAgreementsTermsRadioOptions(form);

  const Steps = onlyStandardTermsExist
    ? [NameStep, SignatoriesStep]
    : [NameStep, TermsStep, SignatoriesStep];

  const currentStepIndex = (form.step || 1) - 1;
  const isLastStep = !Steps[currentStepIndex + 1];
  const Step = Steps[currentStepIndex];
  const dispatch = useDispatch();
  const goToStep = (step: AgreementsCreateForm['step']) =>
    dispatch(updateAgreementsCreateFormField({ step }));

  const footer = (
    <Footer
      currentStepName={Step.name as StepType}
      onClose={onClose}
      isLastStep={isLastStep}
      goToStep={goToStep}
    />
  );

  useEffect(() => {
    if (!form.step) {
      dispatch(updateAgreementsCreateFormField({ step: 1 }));
    }
  }, []);
  const defaultName = useAgreementTemplateName(form);
  return (
    <AdoptechHS
      title={`Create ${form.name || defaultName}`}
      show={show}
      onClose={onClose}
      footer={footer}
      extraFooterClass="justify-content-end"
      backButton={form.step && form.step > 1}
      onBackClick={() => goToStep(form.step - 1)}
      extraClass="adoptechHS--medium"
      showConfirmationWarning
      confirmText={agreementConfirmText}
    >
      <Step />
    </AdoptechHS>
  );
};
