import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import { selectPolicyRejections } from '../../selectors/selectPolicyRejections';
import {
  closeManageRejectionModal,
  showExistingPolicyWarningModal,
} from '../../store/policies/policiesSlice';
import { fetchDocumentApprovals } from '../../store/policies/policiesThunks';
import {
  AgreementModel,
  AgreementSignatoryModel,
  VendorDocument,
  VendorDocumentApproval,
} from '../../swagger/models';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { ExistingPolicyWarningModalMode } from '../ExistingPolicyWarningModal/ExistingPolicyWarningModal';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import './ManagePolicyRejectionModal.scss';
import { capitalize } from '../../functions/capitalize';
import { selectUsers } from '../../selectors/selectUsers';

export const ManagePolicyRejectionModal = () => {
  const dispatch = useDispatch();
  const rejectedItemToManage: VendorDocument | AgreementModel = useSelector(
    (state: ApplicationState) => state.policies.rejectedItemToManage
  );
  const approvals = useSelector(selectPolicyRejections);
  const isPolicy = 'policyId' in rejectedItemToManage;
  const itemName = isPolicy ? 'policy' : 'agreement';
  const isFetchingDocumentApprovals = useSelector(
    (state: ApplicationState) => state.policies.isFetchingDocumentApprovals
  );

  useEffect(() => {
    if (isPolicy && rejectedItemToManage) {
      dispatch(fetchDocumentApprovals(rejectedItemToManage.id));
    }
  }, [rejectedItemToManage]);

  const handleCancel = () => {
    dispatch(closeManageRejectionModal());
  };

  const handleUpdate = () => {
    const data = isPolicy
      ? {
          policyId: (rejectedItemToManage as VendorDocument).policyId,
          existingPolicyWarningModalMode: ExistingPolicyWarningModalMode.Update,
        }
      : {
          policyId: (rejectedItemToManage as AgreementModel).id,
          existingPolicyWarningModalMode:
            ExistingPolicyWarningModalMode.Agreement,
        };
    dispatch(showExistingPolicyWarningModal(data));
  };

  const vendorUsers = useSelector((state: ApplicationState) =>
    selectUsers(state)
  );

  return (
    <AdoptechModal
      className="managePolicyRejectionModal"
      onHide={handleCancel}
      show
    >
      <Modal.Header>{capitalize(itemName)} rejection management</Modal.Header>
      <Modal.Body>
        <div>
          View and manage {itemName} rejections. Click update below to edit{' '}
          {itemName}.
        </div>

        {isFetchingDocumentApprovals ? (
          <LoadingSpinner />
        ) : (
          <React.Fragment>
            <div className="managePolicyRejectionModal--policyName">
              {rejectedItemToManage.name}
            </div>

            <AdoptechTable modal>
              <thead>
                <tr>
                  <th>Rejected by</th>
                  <th>Date</th>
                  <th>Reason for rejection</th>
                </tr>
              </thead>
              <tbody>
                {isPolicy
                  ? approvals.map((rejection: VendorDocumentApproval) => {
                      const vendorUser = vendorUsers.find(
                        currentVendorUser =>
                          currentVendorUser.id === rejection.vendorUserId
                      );
                      return (
                        <tr key={rejection.id}>
                          <td className="managePolicyRejectionModal--cell">
                            <div>
                              <UserAvatar user={vendorUser} size="small" />
                            </div>
                          </td>
                          <td className="managePolicyRejectionModal--cell">
                            <div>{formatShortDate(rejection.rejectedAt)}</div>
                          </td>
                          <td className="managePolicyRejectionModal--cell">
                            <div>{rejection.rejectionReason}</div>
                          </td>
                        </tr>
                      );
                    })
                  : (
                      rejectedItemToManage as AgreementModel
                    )?.agreementSignatories
                      .filter(
                        (signatory: AgreementSignatoryModel) =>
                          signatory.status === 'rejected'
                      )
                      .map((signatory: AgreementSignatoryModel) => (
                        <tr key={signatory.id}>
                          <td className="managePolicyRejectionModal--cell">
                            <UserAvatar user={signatory.user} size="small" />
                          </td>
                          <td className="managePolicyRejectionModal--cell">
                            <div>{formatShortDate(signatory.updatedAt)}</div>
                          </td>
                          <td className="managePolicyRejectionModal--cell">
                            <div>{signatory.rejectionReason}</div>
                          </td>
                        </tr>
                      ))}
              </tbody>
            </AdoptechTable>
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={handleCancel}>Cancel</AdoptechButton>
        <AdoptechButton
          busy={isFetchingDocumentApprovals}
          onClick={handleUpdate}
          variant={AdoptechButtonVariant.Primary}
          disabled={!rejectedItemToManage.access?.update}
        >
          UPDATE {itemName.toUpperCase()}
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
