/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GlobalSearchItem
 */
export interface GlobalSearchItem {
  /**
   *
   * @type {string}
   * @memberof GlobalSearchItem
   */
  searchableId: string;
  /**
   *
   * @type {string}
   * @memberof GlobalSearchItem
   */
  searchableType: string;
  /**
   *
   * @type {string}
   * @memberof GlobalSearchItem
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GlobalSearchItem
   */
  url: string;
}

export function GlobalSearchItemFromJSON(json: any): GlobalSearchItem {
  return GlobalSearchItemFromJSONTyped(json, false);
}

export function GlobalSearchItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GlobalSearchItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    searchableId: json['searchable_id'],
    searchableType: json['searchable_type'],
    name: json['name'],
    url: json['url'],
  };
}

export function GlobalSearchItemToJSON(value?: GlobalSearchItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    searchable_id: value.searchableId,
    searchable_type: value.searchableType,
    name: value.name,
    url: value.url,
  };
}
