import React, { useEffect } from 'react';
import { AdoptechPanel } from '../../../../../../components/AdoptechPanel/AdoptechPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TrustHubUserAdminVendorUserActivities.scss';
import { NoDataText } from '../../../../../../components/NoDataText/NoDataText';
import AdoptechGridTable from '../../../../../../components/AdoptechGridTable/AdoptechGridTable';
import { Grid } from '../../../../../../types/grid';
import { SortableTableHeader } from '../../../../../../components/SortableTableHeader/SortableTableHeader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrustHubActivities } from '../../../../store/trusthubThunks';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { selectVendorUserActivities } from '../../selectVendorUserActivities';
import { faUserClock } from '@fortawesome/pro-light-svg-icons/faUserClock';
import { selectCurrentVendor } from '../../../../../../selectors/selectCurrentVendor';
import { ApplicationState } from '../../../../../../types/applicationState';
import { formatLongDateTime } from '../../../../../../functions/formatLongDateTime';
import { TrusthubVendorUserActivityModel } from '../../../../../../swagger/trusthub/models';

export const TrustHubUserAdminVendorUserActivities: React.FC = () => {
  const baseCss = 'trustHubUserAdminVendorUserActivities';
  const activities = useSelector(selectVendorUserActivities);
  const dispatch = useDispatch();
  const vendor = useSelector(selectCurrentVendor);
  const { fetchingActivitiesStatus, currentVendorUser: user } = useSelector(
    (state: ApplicationState) => state.trustHub
  );
  useEffect(() => {
    dispatch(
      fetchTrustHubActivities(
        {
          vendorIdOrDomain: vendor.id,
          trusthubVendorUserId: user.id,
        },
        () => {}
      )
    );
  }, []);

  if (fetchingActivitiesStatus === 'loading') return <LoadingSpinner />;

  return (
    <div className={baseCss}>
      <div className={baseCss + '--panel'}>
        <AdoptechPanel padding="24px">
          <div className={baseCss + '--panelTitle'}>
            <div className={baseCss + '--panelInfo'}>
              <FontAwesomeIcon icon={faUserClock} />
              User Activity
            </div>
          </div>

          <div className={baseCss + '--panelTable'}>
            {activities.length <= 0 ? (
              <NoDataText extraClass="pt-4" text="No user activities to show" />
            ) : (
              <AdoptechGridTable
                header={
                  <div className={baseCss + '--tableHeader'}>
                    <SortableTableHeader<TrusthubVendorUserActivityModel>
                      columnName="name"
                      grid={Grid.TrustHubUserAdminVendorUserActivities}
                      label="Name"
                      notInTable
                    />
                    <SortableTableHeader<TrusthubVendorUserActivityModel>
                      columnName="createdAt"
                      grid={Grid.TrustHubUserAdminVendorUserActivities}
                      label="Viewed at"
                      notInTable
                    />
                  </div>
                }
              >
                {activities.map(activity => {
                  const { id } = activity;
                  return (
                    <div className={baseCss + '--tableRow'} key={id}>
                      <div className={baseCss + '--name'}>{activity.name}</div>
                      <div className={baseCss + '--createdAt'}>
                        {formatLongDateTime(activity.createdAt) || '-'}
                      </div>
                    </div>
                  );
                })}
              </AdoptechGridTable>
            )}
          </div>
        </AdoptechPanel>
      </div>
    </div>
  );
};
