import React, { Component, ErrorInfo, ReactNode } from 'react';
import './AdoptechErrorBoundary.scss';

interface Props {
  children?: ReactNode;
  errorMessage?: string;
}

interface State {
  hasError: boolean;
}

class AdoptechErrorBoundary extends Component<Props, State> {
  private defaultMessage = 'Sorry.. there was an error';
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="adoptechErrorBoundary">
          {this.props.errorMessage || this.defaultMessage}
        </div>
      );
    }

    return this.props.children;
  }
}

export default AdoptechErrorBoundary;
