import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './AdoptechPageNavBar.scss';

interface AdoptechPageNavBarProps {
  goBack: () => void;
  content: React.ReactNode;
  leftContent?: React.ReactNode;
}
export const AdoptechPageNavBar: React.FC<AdoptechPageNavBarProps> = ({
  goBack,
  content,
  leftContent,
}) => {
  const baseCss = 'adoptechPageNavBar';

  return (
    <h2 className={baseCss}>
      <div className={baseCss + '--info'}>
        <FontAwesomeIcon
          onClick={goBack}
          className={baseCss + '--navBack'}
          icon={faAngleLeft}
        />
        {leftContent}
      </div>
      {content}
    </h2>
  );
};
