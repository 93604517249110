import { createSelector } from '@reduxjs/toolkit';
import { Checklist } from '../swagger';
import { ApplicationState } from '../types/applicationState';

export const selectActiveChecklists = createSelector(
  (state: ApplicationState) => state.checklists.checklists,
  checklists =>
    checklists.filter(
      (list: Checklist) => list.status === 'in_progress' && list.visibleInLists
    )
);
