import React from 'react';
import { SelectionOption } from '../../types/selectionOption';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { WithCountries } from '../WithCountries/WithCountries';

interface AdoptechCountrySelectContentProps extends AdoptechCountrySelectProps {
  options: SelectionOption[];
}

const AdoptechCountrySelectContent: React.FC<
  AdoptechCountrySelectContentProps
> = ({ onChange, value, disabled, placeholder, isMultiSelect, options }) => {
  if (options?.length) {
    const uk = options.find(x => x.value === 'GB');
    const us = options.find(x => x.value === 'US');
    options.splice(options.indexOf(uk), 1);
    options.splice(options.indexOf(us), 1);
    options.unshift(uk, us);
  }

  return (
    <AdoptechReactSelect
      id="adoptechCountrySelect"
      isDisabled={disabled}
      isMulti={isMultiSelect}
      options={options}
      onChange={onChange}
      value={
        isMultiSelect
          ? options.filter(x => value?.split(',').includes(x.value))
          : options.find(x => x.value === value)
      }
      placeholder={placeholder}
    />
  );
};

interface AdoptechCountrySelectProps {
  onChange?: (value: SelectionOption) => void;
  value: string;
  disabled?: boolean;
  placeholder?: string;
  isMultiSelect?: boolean;
}

export const AdoptechCountrySelect: React.FC<
  AdoptechCountrySelectProps
> = props => (
  <WithCountries>
    {countryOptions => (
      <AdoptechCountrySelectContent {...props} options={countryOptions} />
    )}
  </WithCountries>
);
