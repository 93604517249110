/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ControlCategoryModel
 */
export interface ControlCategoryModel {
  /**
   *
   * @type {string}
   * @memberof ControlCategoryModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ControlCategoryModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ControlCategoryModel
   */
  identifier?: string;
  /**
   *
   * @type {string}
   * @memberof ControlCategoryModel
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof ControlCategoryModel
   */
  descriptionAsHtml: string | null;
  /**
   *
   * @type {string}
   * @memberof ControlCategoryModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ControlCategoryModel
   */
  updatedAt: string;
}

export function ControlCategoryModelFromJSON(json: any): ControlCategoryModel {
  return ControlCategoryModelFromJSONTyped(json, false);
}

export function ControlCategoryModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlCategoryModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    identifier: !exists(json, 'identifier') ? undefined : json['identifier'],
    description: json['description'],
    descriptionAsHtml: json['description_as_html'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function ControlCategoryModelToJSON(
  value?: ControlCategoryModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    identifier: value.identifier,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
