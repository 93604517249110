import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Question } from '../../swagger/models';
import './DateFieldAnswer.scss';
import { CommonAnswerType } from '../../types/CommonAnswerType';
import { policyAnswerCommonFields } from '../../functions/policyAnswerCommonFields';

interface DateFieldAnswerProps {
  previousAnswer?: CommonAnswerType;
  disabled?: boolean;
  readonly?: boolean;
  onChange: (value: CommonAnswerType[]) => void;
  question: Question;
  indexProperty: 'name' | 'propertyName';
  disableDebounce?: boolean;
  asTextReadonly?: boolean;
}

export const DateFieldAnswer: React.FC<DateFieldAnswerProps> = props => {
  const [value, setValue] = useState<Date | undefined>(
    props.previousAnswer
      ? moment(props.previousAnswer.value).toDate()
      : undefined
  );

  useEffect(
    () =>
      setValue(
        props.previousAnswer
          ? moment(props.previousAnswer.value).toDate()
          : undefined
      ),
    [props.previousAnswer]
  );

  return (
    <div className="dateFieldAnswer">
      <DatePicker
        dateFormat="dd/MM/yyyy"
        popperProps={{
          positionFixed: true,
        }}
        disabled={props.disabled || props.readonly}
        onChange={e => {
          setValue(e);
          const momentDate = moment.parseZone(e);
          const normalizedDate = moment.utc(
            `${momentDate.format('YYYY-MM-DD')}T00:00:00Z`
          );
          props.onChange([
            {
              [props.indexProperty]: props.question[props.indexProperty],
              value: normalizedDate.toDate().toISOString(),
              ...policyAnswerCommonFields(props),
            },
          ]);
        }}
        selected={value}
      />
    </div>
  );
};
