/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedLegislationRecurring,
  SharedLegislationRecurringFromJSON,
  SharedLegislationRecurringFromJSONTyped,
  SharedLegislationRecurringToJSON,
} from './';

/**
 *
 * @export
 * @interface LegislationCreatePayloadLegislation
 */
export interface LegislationCreatePayloadLegislation {
  /**
   *
   * @type {string}
   * @memberof LegislationCreatePayloadLegislation
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof LegislationCreatePayloadLegislation
   */
  summary: string;
  /**
   *
   * @type {string}
   * @memberof LegislationCreatePayloadLegislation
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof LegislationCreatePayloadLegislation
   */
  descriptionUrl: string | null;
  /**
   *
   * @type {string}
   * @memberof LegislationCreatePayloadLegislation
   */
  explanationOfCompliance: string | null;
  /**
   *
   * @type {SharedLegislationRecurring}
   * @memberof LegislationCreatePayloadLegislation
   */
  recurring: SharedLegislationRecurring;
  /**
   *
   * @type {string}
   * @memberof LegislationCreatePayloadLegislation
   */
  legislationClassificationId: string;
  /**
   *
   * @type {string}
   * @memberof LegislationCreatePayloadLegislation
   */
  jurisdictionId: string;
}

export function LegislationCreatePayloadLegislationFromJSON(
  json: any
): LegislationCreatePayloadLegislation {
  return LegislationCreatePayloadLegislationFromJSONTyped(json, false);
}

export function LegislationCreatePayloadLegislationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LegislationCreatePayloadLegislation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    summary: json['summary'],
    description: json['description'],
    descriptionUrl: json['description_url'],
    explanationOfCompliance: json['explanation_of_compliance'],
    recurring: SharedLegislationRecurringFromJSON(json['recurring']),
    legislationClassificationId: json['legislation_classification_id'],
    jurisdictionId: json['jurisdiction_id'],
  };
}

export function LegislationCreatePayloadLegislationToJSON(
  value?: LegislationCreatePayloadLegislation | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    summary: value.summary,
    description: value.description,
    description_url: value.descriptionUrl,
    explanation_of_compliance: value.explanationOfCompliance,
    recurring: SharedLegislationRecurringToJSON(value.recurring),
    legislation_classification_id: value.legislationClassificationId,
    jurisdiction_id: value.jurisdictionId,
  };
}
