import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { getInitials } from '../../functions/getInitials';
import { VendorUser, VendorUserRoles } from '../../swagger';
import { AdoptechTooltip } from '../AdoptechTooltip/AdoptechTooltip';
import './UserAvatar.scss';
import { getPaletteColourFromString } from '../../functions/getPaletteColourFromString';

interface UserAvatarProps {
  user: VendorUser;
  size?: 'small' | 'medium' | 'large' | 'tag';
  showTooltip?: boolean;
  hideText?: boolean;
  showUserEmail?: boolean;
  initialsColor?: '#666665';
  customColor?: '#f9f9f9';
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  size = 'large',
  showTooltip = false,
  hideText = false,
  showUserEmail = true,
  initialsColor = '#fff',
  customColor,
}) => {
  if (!user) {
    return null;
  }
  const initials = getInitials({ user });

  const baseClass = 'userAvatar';

  const basesClasses = classNames(baseClass, {
    [baseClass + '--tag']: size === 'tag',
    [baseClass + '--hiddenText']: hideText,
  });
  const circleClasses = classNames(baseClass + '--circle', {
    [baseClass + '--circle__small']: size === 'small',
    [baseClass + '--circle__medium']: size === 'medium',
    [baseClass + '--circle__large']: size === 'large',
    [baseClass + '--circle__tag']: size === 'tag',
  });

  const nameClasses = classNames(baseClass + '--name', {
    [baseClass + '--name__small']: size === 'small',
    [baseClass + '--name__medium']: size === 'medium',
    [baseClass + '--name__large']: size === 'large',
    [baseClass + '--name__tag']: size === 'tag',
  });

  const colorIdentifier = user.id || user.email;

  const customRoleColor = (user?.roles || []).includes(
    VendorUserRoles.SecurityEditor
  )
    ? '#ea5542'
    : null;
  const color =
    customColor ||
    customRoleColor ||
    (colorIdentifier ? getPaletteColourFromString(colorIdentifier) : '#ccc'); // fallback color
  const text = (
    <div className={baseClass + '--text'}>
      {size !== 'tag' && (
        <div className={nameClasses}>
          {user.fullName}{' '}
          {size === 'medium' &&
            user.email !== user.fullName &&
            showUserEmail &&
            `(${user.email})`}
        </div>
      )}
      {size === 'large' && showUserEmail && (
        <div className={baseClass + '--email'}>{user.email}</div>
      )}
      {size === 'tag' && showUserEmail && <div>{user.email}</div>}
    </div>
  );

  return (
    <React.Fragment>
      <AdoptechTooltip
        showTooltip={showTooltip}
        text={user?.fullName}
        identifier={`${user.id}-user`}
      >
        <div className={basesClasses}>
          <div className={circleClasses} style={{ color }}>
            <FontAwesomeIcon icon={faCircle} />
            <div
              className={baseClass + '--initials'}
              style={{ color: initialsColor }}
            >
              {initials}
            </div>
          </div>
          {!hideText && text}
        </div>
      </AdoptechTooltip>
    </React.Fragment>
  );
};
