import React, { useEffect, useRef, useState } from 'react';
import { Question } from '../../swagger';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import './TextAreaAnswer.scss';
import { CommonAnswerType } from '../../types/CommonAnswerType';
import { policyAnswerCommonFields } from '../../functions/policyAnswerCommonFields';
import { useAutoGrowTextArea } from '../../hooks/useAutoGrowTextArea';

interface TextAreaAnswerProps {
  previousAnswer?: CommonAnswerType;
  answers: CommonAnswerType[];
  disabled?: boolean;
  readonly?: boolean;
  onChange: (value: CommonAnswerType[]) => void;
  question: Question;
  indexProperty: 'name' | 'propertyName';
}

export const TextAreaAnswer: React.FC<TextAreaAnswerProps> = props => {
  const [value, setValue] = useState(
    props.previousAnswer ? props.previousAnswer.value : ''
  );

  useEffect(() => {
    setValue(props.previousAnswer ? props.previousAnswer.value : '');
  }, [props.previousAnswer?.propertyName]);

  useEffect(() => {
    if (props.previousAnswer === undefined) return;
    setValue(props.previousAnswer.value);
  }, [props.previousAnswer]);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutoGrowTextArea(textAreaRef.current, value);

  return (
    <div className="textAreaAnswer">
      <AdoptechTextArea
        id={props.question.id}
        disabled={props.disabled || props.readonly}
        onBlur={() => {
          if (!value && !props.previousAnswer) return;

          if (props.previousAnswer && value === props.previousAnswer.value)
            return;

          props.onChange([
            {
              [props.indexProperty]: props.question[props.indexProperty],
              value: value,
              ...policyAnswerCommonFields(props),
            },
          ]);
        }}
        onChange={e => {
          setValue(e.currentTarget.value);
        }}
        value={value}
        ref={textAreaRef}
        rows={7}
      />
    </div>
  );
};
