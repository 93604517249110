import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultSelectionOptions } from '../../functions/getDefaultSelectionOptions';
import { fetchTable } from '../../store/vendors/vendorsThunks';
import { QuestionTableTypeEnum } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';

interface WithCountries {
  children: (countryOptions: SelectionOption[]) => ReactElement<any, any>;
}

export const WithCountries: React.FC<WithCountries> = ({ children }) => {
  const dispatch = useDispatch();
  const isFetchingVendorTable = useSelector(
    (state: ApplicationState) => state.vendors.isFetchingVendorTable
  );
  const countryOptions = useSelector((state: ApplicationState) =>
    getDefaultSelectionOptions(QuestionTableTypeEnum.Country, state)
  );

  useEffect(() => {
    if (
      !countryOptions?.length &&
      !isFetchingVendorTable[QuestionTableTypeEnum.Country]
    ) {
      dispatch(fetchTable(QuestionTableTypeEnum.Country));
    }
  }, []);

  if (!countryOptions?.length) {
    return null;
  }

  return children(countryOptions);
};
