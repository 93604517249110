/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedMaxUsersAmount,
  SharedMaxUsersAmountFromJSON,
  SharedMaxUsersAmountFromJSONTyped,
  SharedMaxUsersAmountToJSON,
  SharedPaymentPeriod,
  SharedPaymentPeriodFromJSON,
  SharedPaymentPeriodFromJSONTyped,
  SharedPaymentPeriodToJSON,
} from './';

/**
 *
 * @export
 * @interface SubscriptionUpdatePayload
 */
export interface SubscriptionUpdatePayload {
  /**
   *
   * @type {string}
   * @memberof SubscriptionUpdatePayload
   */
  paymentPlanId: string;
  /**
   *
   * @type {SharedMaxUsersAmount}
   * @memberof SubscriptionUpdatePayload
   */
  usersLimit?: SharedMaxUsersAmount;
  /**
   *
   * @type {SharedPaymentPeriod}
   * @memberof SubscriptionUpdatePayload
   */
  paymentPeriod: SharedPaymentPeriod;
}

export function SubscriptionUpdatePayloadFromJSON(
  json: any
): SubscriptionUpdatePayload {
  return SubscriptionUpdatePayloadFromJSONTyped(json, false);
}

export function SubscriptionUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubscriptionUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    paymentPlanId: json['payment_plan_id'],
    usersLimit: !exists(json, 'users_limit')
      ? undefined
      : SharedMaxUsersAmountFromJSON(json['users_limit']),
    paymentPeriod: SharedPaymentPeriodFromJSON(json['payment_period']),
  };
}

export function SubscriptionUpdatePayloadToJSON(
  value?: SubscriptionUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    payment_plan_id: value.paymentPlanId,
    users_limit: SharedMaxUsersAmountToJSON(value.usersLimit),
    payment_period: SharedPaymentPeriodToJSON(value.paymentPeriod),
  };
}
