import React, { useEffect } from 'react';
import { LegislationCreatePayloadLegislation } from '../../../swagger/models/LegislationCreatePayloadLegislation';
import './AddOwnLegislation.scss';
import { AdoptechTextInput2 } from '../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { fetchClassifications } from '../../../store/legalRegister/legalRegisterThunks';
import { isValidUrl } from '../../../functions/isValidUrl';
import { AdoptechReactSelect2 } from '../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechTextArea2 } from '../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAddOwnLegislationHandler } from './useAddOwnLegislationHandler';

interface AddOwnLegislationProps {
  addHandler: ReturnType<typeof useAddOwnLegislationHandler>;
}
export const validation = (
  data: OwnLegislationForm
): Record<string, string[]> => ({
  name: (data.name || '').trim().length > 0 ? null : ['Name must be present'],
  legislationClassificationId: data.legislationClassificationId
    ? null
    : ['Classification must be set'],
  summary:
    (data.summary || '').trim().length > 0 ? null : ['Summary must be present'],
  jurisdictionId: data.jurisdictionId ? null : ['Jurisdiction must be set'],
  descriptionUrl:
    !data.descriptionUrl || isValidUrl(data.descriptionUrl)
      ? null
      : ['Link must be valid URL'],
  description:
    (data.description || '').trim().length > 0
      ? null
      : ['Description must be present'],
});
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface OwnLegislationForm
  extends Partial<LegislationCreatePayloadLegislation> {}
export const AddOwnLegislation: React.FC<AddOwnLegislationProps> = props => {
  const baseCss = 'addOwnLegislation';
  const headerCss = 'documentLibraryHeader';
  const {
    formData,
    setFormData,
    classificationOptions,
    allJurisdictionOptions: jurisdictionOptions,
    hasError,
    setClassifications,
  } = props.addHandler;
  const dispatch = useAppDispatch();
  useEffect(() => {
    setFormData({});
    classificationOptions().length === 0 &&
      dispatch(fetchClassifications(data => setClassifications(data)));
  }, []);

  return (
    <div className={baseCss}>
      <div className={headerCss + '--titleRow'}>
        <div className={headerCss + '--title'}>Add my own legislation</div>
      </div>
      <div className={baseCss + '--mainColumns'}>
        <div className={baseCss + '--overviewSection'}>
          <div className={baseCss + '--sectionTitle'}>Overview</div>
          <div className={baseCss + '--sectionContent'}>
            <div className={baseCss + '--field'}>
              <AdoptechTextInput2
                id="legislation-nameInput"
                value={formData.name}
                type="text"
                hasError={hasError('name')}
                rounded
                onChange={e => {
                  setFormData({ ...formData, name: e.currentTarget.value });
                }}
                label="Legislation name"
                placeholder="Example Legislation"
              />
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                id="legislation-classificationInput"
                options={classificationOptions()}
                hasError={hasError('legislationClassificationId')}
                rounded
                onChange={e => {
                  setFormData({
                    ...formData,
                    legislationClassificationId: e.value,
                  });
                }}
                value={classificationOptions().find(
                  o => o.value === formData.legislationClassificationId
                )}
                label="Classification"
                placeholder="Select classification"
              />
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                id="legislation-jurisdictionInput"
                options={jurisdictionOptions}
                hasError={hasError('jurisdictionId')}
                rounded
                onChange={e => {
                  setFormData({
                    ...formData,
                    jurisdictionId: e.value,
                  });
                }}
                value={jurisdictionOptions.find(
                  o => o.value === formData.jurisdictionId
                )}
                label="Jurisdiction"
                placeholder="Select jurisdiction"
              />
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechTextInput2
                id="legislation-linkInput"
                value={formData?.descriptionUrl}
                type="text"
                hasError={hasError('descriptionUrl')}
                rounded
                onChange={e => {
                  setFormData({
                    ...formData,
                    descriptionUrl: e.currentTarget.value,
                  });
                }}
                label="URL link"
                placeholder="Enter URL Address"
              />
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechTextArea2
                id="legislation-summaryInput"
                value={formData?.summary}
                hasError={hasError('summary')}
                rounded
                onChange={e => {
                  setFormData({ ...formData, summary: e.currentTarget.value });
                }}
                label="Summary"
                rows={4}
                placeholder="This is an example of creating a custom legislation"
              />
            </div>
          </div>
        </div>
        <div className={baseCss + '--descriptionSection'}>
          <div className={baseCss + '--sectionTitle'}>Description</div>
          <div className={baseCss + '--sectionContent'}>
            <div className={baseCss + '--field'}>
              <AdoptechTextArea2
                id="legislation-descriptionInput"
                value={formData?.description}
                placeholder="Enter more details of the legislation"
                hasError={hasError('description')}
                rounded
                onChange={e => {
                  setFormData({
                    ...formData,
                    description: e.currentTarget.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
