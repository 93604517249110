/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ReportCreatePayloadReport,
  ReportCreatePayloadReportFromJSON,
  ReportCreatePayloadReportFromJSONTyped,
  ReportCreatePayloadReportToJSON,
} from './';

/**
 *
 * @export
 * @interface ReportCreatePayload
 */
export interface ReportCreatePayload {
  /**
   *
   * @type {ReportCreatePayloadReport}
   * @memberof ReportCreatePayload
   */
  report: ReportCreatePayloadReport;
}

export function ReportCreatePayloadFromJSON(json: any): ReportCreatePayload {
  return ReportCreatePayloadFromJSONTyped(json, false);
}

export function ReportCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReportCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    report: ReportCreatePayloadReportFromJSON(json['report']),
  };
}

export function ReportCreatePayloadToJSON(
  value?: ReportCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    report: ReportCreatePayloadReportToJSON(value.report),
  };
}
