/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PestelItemItemType {
  Risk = 'risk',
  Opportunity = 'opportunity',
}

export function PestelItemItemTypeFromJSON(json: any): PestelItemItemType {
  return PestelItemItemTypeFromJSONTyped(json, false);
}

export function PestelItemItemTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PestelItemItemType {
  return json as PestelItemItemType;
}

export function PestelItemItemTypeToJSON(
  value?: PestelItemItemType | null
): any {
  return value as any;
}
