import React from 'react';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import StartFooter from '../Start/StartFooter/StartFooter';
import './LoadingScreen.scss';
import adoptechLogoDark from 'images/adoptech-logo-dark.svg';

export const LoadingScreen: React.FC = () => {
  return (
    <div className="loadingScreen">
      <div className="loadingScreen--header">
        <div className="loadingScreen--logoAndLink">
          <div className="loadingScreen--logo">
            <img src={adoptechLogoDark} />
          </div>
        </div>
      </div>
      <LoadingSpinner />
      <StartFooter />
    </div>
  );
};
