/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DataRoomEntityUpdatePayloadEntity
 */
export interface DataRoomEntityUpdatePayloadEntity {
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityUpdatePayloadEntity
   */
  filename?: string;
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityUpdatePayloadEntity
   */
  description?: string | null;
}

export function DataRoomEntityUpdatePayloadEntityFromJSON(
  json: any
): DataRoomEntityUpdatePayloadEntity {
  return DataRoomEntityUpdatePayloadEntityFromJSONTyped(json, false);
}

export function DataRoomEntityUpdatePayloadEntityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DataRoomEntityUpdatePayloadEntity {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    filename: !exists(json, 'filename') ? undefined : json['filename'],
    description: !exists(json, 'description') ? undefined : json['description'],
  };
}

export function DataRoomEntityUpdatePayloadEntityToJSON(
  value?: DataRoomEntityUpdatePayloadEntity | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    filename: value.filename,
    description: value.description,
  };
}
