/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Answer,
  AnswerFromJSON,
  AnswerFromJSONTyped,
  AnswerToJSON,
  ControlModel,
  ControlModelFromJSON,
  ControlModelFromJSONTyped,
  ControlModelToJSON,
  PreviousAnswer,
  PreviousAnswerFromJSON,
  PreviousAnswerFromJSONTyped,
  PreviousAnswerToJSON,
  QuestionColumn,
  QuestionColumnFromJSON,
  QuestionColumnFromJSONTyped,
  QuestionColumnToJSON,
  QuestionRow,
  QuestionRowFromJSON,
  QuestionRowFromJSONTyped,
  QuestionRowToJSON,
  QuestionTable,
  QuestionTableFromJSON,
  QuestionTableFromJSONTyped,
  QuestionTableToJSON,
  QuestionVisibilityCondition,
  QuestionVisibilityConditionFromJSON,
  QuestionVisibilityConditionFromJSONTyped,
  QuestionVisibilityConditionToJSON,
} from './';

/**
 *
 * @export
 * @interface Question
 */
export interface Question {
  /**
   *
   * @type {string}
   * @memberof Question
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  questionText?: string;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  questionTextAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  explanatoryText?: string | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  explanatoryTextAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  datatype?: QuestionDatatypeEnum;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  layout?: QuestionLayoutEnum;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  objectType?: QuestionObjectTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  tableType?: QuestionTableTypeEnum;
  /**
   *
   * @type {Array<QuestionRow>}
   * @memberof Question
   */
  questionRows?: Array<QuestionRow> | null;
  /**
   *
   * @type {Array<QuestionColumn>}
   * @memberof Question
   */
  questionColumns?: Array<QuestionColumn> | null;
  /**
   *
   * @type {QuestionVisibilityCondition}
   * @memberof Question
   */
  visibilityCondition?: QuestionVisibilityCondition;
  /**
   *
   * @type {boolean}
   * @memberof Question
   */
  other?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  otherLayout?: QuestionOtherLayoutEnum;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  otherDatatype?: QuestionOtherDatatypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof Question
   */
  allowAttachment?: boolean;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  propertyName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Question
   */
  primary?: boolean;
  /**
   *
   * @type {PreviousAnswer}
   * @memberof Question
   */
  previousAnswer?: PreviousAnswer;
  /**
   *
   * @type {Answer}
   * @memberof Question
   */
  answer?: Answer;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  showWhen?: string | null;
  /**
   *
   * @type {QuestionTable}
   * @memberof Question
   */
  table?: QuestionTable;
  /**
   *
   * @type {ControlModel}
   * @memberof Question
   */
  control?: ControlModel;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  annexRef?: string | null;
}

export function QuestionFromJSON(json: any): Question {
  return QuestionFromJSONTyped(json, false);
}

export function QuestionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Question {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    questionText: !exists(json, 'question_text')
      ? undefined
      : json['question_text'],
    questionTextAsHtml: !exists(json, 'question_text_as_html')
      ? undefined
      : json['question_text_as_html'],
    explanatoryText: !exists(json, 'explanatory_text')
      ? undefined
      : json['explanatory_text'],
    explanatoryTextAsHtml: !exists(json, 'explanatory_text_as_html')
      ? undefined
      : json['explanatory_text_as_html'],
    datatype: !exists(json, 'datatype') ? undefined : json['datatype'],
    layout: !exists(json, 'layout') ? undefined : json['layout'],
    objectType: !exists(json, 'object_type') ? undefined : json['object_type'],
    tableType: !exists(json, 'table_type') ? undefined : json['table_type'],
    questionRows: !exists(json, 'question_rows')
      ? undefined
      : json['question_rows'] === null
        ? null
        : (json['question_rows'] as Array<any>).map(QuestionRowFromJSON),
    questionColumns: !exists(json, 'question_columns')
      ? undefined
      : json['question_columns'] === null
        ? null
        : (json['question_columns'] as Array<any>).map(QuestionColumnFromJSON),
    visibilityCondition: !exists(json, 'visibility_condition')
      ? undefined
      : QuestionVisibilityConditionFromJSON(json['visibility_condition']),
    other: !exists(json, 'other') ? undefined : json['other'],
    otherLayout: !exists(json, 'other_layout')
      ? undefined
      : json['other_layout'],
    otherDatatype: !exists(json, 'other_datatype')
      ? undefined
      : json['other_datatype'],
    allowAttachment: !exists(json, 'allow_attachment')
      ? undefined
      : json['allow_attachment'],
    name: !exists(json, 'name') ? undefined : json['name'],
    propertyName: !exists(json, 'property_name')
      ? undefined
      : json['property_name'],
    primary: !exists(json, 'primary') ? undefined : json['primary'],
    previousAnswer: !exists(json, 'previous_answer')
      ? undefined
      : PreviousAnswerFromJSON(json['previous_answer']),
    answer: !exists(json, 'answer')
      ? undefined
      : AnswerFromJSON(json['answer']),
    showWhen: !exists(json, 'show_when') ? undefined : json['show_when'],
    table: !exists(json, 'table')
      ? undefined
      : QuestionTableFromJSON(json['table']),
    control: !exists(json, 'control')
      ? undefined
      : ControlModelFromJSON(json['control']),
    annexRef: !exists(json, 'annex_ref') ? undefined : json['annex_ref'],
  };
}

export function QuestionToJSON(value?: Question | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    question_text: value.questionText,
    question_text_as_html: value.questionTextAsHtml,
    explanatory_text: value.explanatoryText,
    explanatory_text_as_html: value.explanatoryTextAsHtml,
    datatype: value.datatype,
    layout: value.layout,
    object_type: value.objectType,
    table_type: value.tableType,
    question_rows:
      value.questionRows === undefined
        ? undefined
        : value.questionRows === null
          ? null
          : (value.questionRows as Array<any>).map(QuestionRowToJSON),
    question_columns:
      value.questionColumns === undefined
        ? undefined
        : value.questionColumns === null
          ? null
          : (value.questionColumns as Array<any>).map(QuestionColumnToJSON),
    visibility_condition: QuestionVisibilityConditionToJSON(
      value.visibilityCondition
    ),
    other: value.other,
    other_layout: value.otherLayout,
    other_datatype: value.otherDatatype,
    allow_attachment: value.allowAttachment,
    name: value.name,
    property_name: value.propertyName,
    primary: value.primary,
    previous_answer: PreviousAnswerToJSON(value.previousAnswer),
    answer: AnswerToJSON(value.answer),
    show_when: value.showWhen,
    table: QuestionTableToJSON(value.table),
    control: ControlModelToJSON(value.control),
    annex_ref: value.annexRef,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum QuestionDatatypeEnum {
  String = 'string',
  Integer = 'integer',
  Float = 'float',
  Date = 'date',
  Boolean = 'boolean',
  NoData = 'no_data',
}
/**
 * @export
 * @enum {string}
 */
export enum QuestionLayoutEnum {
  TextField = 'text_field',
  TextArea = 'text_area',
  NumberField = 'number_field',
  RadioButtons = 'radio_buttons',
  CheckBoxes = 'check_boxes',
  RadioGrid = 'radio_grid',
  EmailField = 'email_field',
  SingleSelect = 'single_select',
  MultiSelect = 'multi_select',
  TableLayout = 'table_layout',
  DateField = 'date_field',
  FileSelect = 'file_select',
  AuditReviewTableLayout = 'audit_review_table_layout',
}
/**
 * @export
 * @enum {string}
 */
export enum QuestionObjectTypeEnum {
  VendorUser = 'vendor_user',
  VendorTeam = 'vendor_team',
  Office = 'office',
}
/**
 * @export
 * @enum {string}
 */
export enum QuestionTableTypeEnum {
  Country = 'country',
  Month = 'month',
  UsState = 'us_state',
  OrganisationType = 'organisation_type',
  FundingStage = 'funding_stage',
}
/**
 * @export
 * @enum {string}
 */
export enum QuestionOtherLayoutEnum {
  Field = 'text_field',
  Area = 'text_area',
}
/**
 * @export
 * @enum {string}
 */
export enum QuestionOtherDatatypeEnum {
  String = 'string',
}
