/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChatWidgetLogin
 */
export interface ChatWidgetLogin {
  /**
   *
   * @type {string}
   * @memberof ChatWidgetLogin
   */
  token?: string | null;
}

export function ChatWidgetLoginFromJSON(json: any): ChatWidgetLogin {
  return ChatWidgetLoginFromJSONTyped(json, false);
}

export function ChatWidgetLoginFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChatWidgetLogin {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    token: !exists(json, 'token') ? undefined : json['token'],
  };
}

export function ChatWidgetLoginToJSON(value?: ChatWidgetLogin | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token: value.token,
  };
}
