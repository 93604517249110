import { push } from 'connected-react-router';
import { api } from '../../api/api';
import { getApiErrorMessage } from '../../functions/getApiErrorMessage';
import { getMetaContent } from '../../functions/getMetaContent';
import {
  AssessmentRecipient,
  AssessmentResponse,
  AssessmentResponseAnswer,
  AssessmentResponseAnswerStatusEnum,
  AssessmentResponseFromJSON,
} from '../../swagger';
import { UNKNOWN_ERROR } from '../../types/constants';
import { VoidThunk } from '../../types/voidThunk';
import { download } from '../global/globalSlice';
import { showAutoSave } from '../policies/policiesSlice';
import {
  deleteAssessmentFileOrUrlFailure,
  deleteAssessmentFileOrUrlRequest,
  deleteAssessmentFileOrUrlSuccess,
  deleteAssessmentRecipientFailure,
  deleteAssessmentRecipientRequest,
  deleteAssessmentRecipientSuccess,
  deleteAssessmentResponseFailure,
  deleteAssessmentResponseRequest,
  deleteAssessmentResponseSuccess,
  exportAssessmentResponsesFailure,
  exportAssessmentResponsesRequest,
  exportAssessmentResponsesSuccess,
  fetchAssessmentFailure,
  fetchAssessmentRequest,
  fetchAssessmentResponseFailure,
  fetchAssessmentResponseHistoryFailure,
  fetchAssessmentResponseHistoryRequest,
  fetchAssessmentResponseHistorySuccess,
  fetchAssessmentResponseRequest,
  fetchAssessmentResponseSuccess,
  fetchAssessmentTemplatesRequest,
  fetchAssessmentTemplatesSuccess,
  fetchAssessmentTemplatesError,
  fetchAssessmentTemplateRequest,
  fetchAssessmentTemplateSuccess,
  fetchAssessmentTemplateError,
  fetchAssessmentsFailure,
  fetchAssessmentsRequest,
  fetchAssessmentsSuccess,
  fetchAssessmentSuccess,
  fetchIncomingAssessmentResponsesFailure,
  fetchIncomingAssessmentResponsesRequest,
  fetchIncomingAssessmentResponsesSuccess,
  hideAddFileOrUrlModal,
  hideAssessmentSummaryExportModal,
  hideConfirmDeleteAssessmentResponseModal,
  hideDeleteTableAnswersModal,
  hideEditPublishedAssessmentModal,
  hideEditTableAnswersModal,
  hidePublishAssessmentModal,
  patchAssessmentResponseFailure,
  patchAssessmentResponseRequest,
  patchAssessmentResponseSuccess,
  publishAssessmentResponseRequest,
  publishAssessmentResponseSuccess,
  publishAssessmentResponseFailure,
  reopenAssessmentResponseFailure,
  reopenAssessmentResponseRequest,
  reopenAssessmentResponseSuccess,
  resendAssessmentFailure,
  resendAssessmentRequest,
  resendAssessmentSuccess,
  sendAssessmentFailure,
  sendAssessmentRequest,
  sendAssessmentSuccess,
  showFileOrUrlSuccessfulToast,
  showPublishedAssessmentModal,
  uploadFileOrUrlFailure,
  uploadFileOrUrlRequest,
  uploadFileOrUrlSuccess,
  confirmAssessmentResponseRequest,
  confirmAssessmentResponseSuccess,
  confirmAssessmentResponseFailure,
  patchAssessmentRequest,
  patchAssessmentSuccess,
  patchAssessmentFailure,
  generateAssessmentRequest,
  generateAssessmentSuccess,
  generateAssessmentError,
  deleteAssessmentRequest,
  deleteAssessmentSuccess,
  deleteAssessmentError,
  showDeleteAssessmentToast,
} from './assessmentsSlice';
import { QuestionType } from '../../types/QuestionType';

export const fetchAssessment =
  (assessmentId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAssessmentRequest());
      const response = await api().assessmentsIdGet({ id: assessmentId });
      dispatch(fetchAssessmentSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchAssessmentFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchAssessmentFailure(
              'An error occurred fetching the assessment. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchAssessmentFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchAssessmentResponse =
  (assessmentId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAssessmentResponseRequest());
      const response = await api().assessmentResponsesIdGet({
        id: assessmentId,
      });
      dispatch(fetchAssessmentResponseSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchAssessmentResponseFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchAssessmentResponseFailure(
              'An error occurred fetching the assessment response. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchAssessmentResponseFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchAssessmentResponseHistory =
  (assessmentResponseId: string, questionId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAssessmentResponseHistoryRequest());
      const response =
        await api().assessmentResponsesIdAssessmentResponseAnswersHistoryGet({
          id: assessmentResponseId,
          questionId,
        });
      dispatch(
        fetchAssessmentResponseHistorySuccess({
          id: questionId,
          answers: response,
        })
      );
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchAssessmentResponseHistoryFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchAssessmentResponseHistoryFailure(
              'An error occurred fetching the assessment response history. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchAssessmentResponseHistoryFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchAssessments =
  (vendorId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAssessmentsRequest());
      const response = await Promise.all([
        api().vendorsIdAssessmentsGet({ id: vendorId }),
        api().vendorsIdAssessmentResponsesGet({ id: vendorId }),
      ]);
      dispatch(fetchAssessmentsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchAssessmentsFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchAssessmentsFailure(
              'An error occurred fetching the assessments. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchAssessmentsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const sendAssessment = (): VoidThunk => async (dispatch, getState) => {
  try {
    const { currentAssessment, sendAssessmentDetails } = getState().assessments;
    dispatch(sendAssessmentRequest());
    await api().assessmentsIdSharePost({
      id: sendAssessmentDetails.id,
      externalShare: {
        deadline: sendAssessmentDetails.responseDeadline,
        emailAddresses: sendAssessmentDetails.to,
        message: sendAssessmentDetails.optionalMessage,
        subject: currentAssessment.name,
      },
    });
    dispatch(sendAssessmentSuccess());
    dispatch(fetchAssessment(currentAssessment.id));
  } catch (err) {
    if (err instanceof Response) {
      console.log(
        'API error',
        `Status: ${err.status} Message: ${err.statusText}`
      );
      const apiErrorMessage = await getApiErrorMessage(err);
      if (apiErrorMessage) {
        dispatch(sendAssessmentFailure(apiErrorMessage));
      } else {
        dispatch(
          sendAssessmentFailure(
            'An error occurred sending the assessments. Please try again or contact support.'
          )
        );
      }
    } else {
      console.log(err);
      dispatch(sendAssessmentFailure(UNKNOWN_ERROR));
    }
  }
};

export const resendAssessment =
  ({ message }: { message: string }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const { currentAssessment, resendRecipient } = getState().assessments;
      dispatch(resendAssessmentRequest());
      await api().assessmentsIdSharePost({
        id: currentAssessment.id,
        externalShare: {
          emailAddresses: resendRecipient.email,
          subject: currentAssessment.name,
          message,
        },
      });
      dispatch(resendAssessmentSuccess());
      dispatch(fetchAssessment(currentAssessment.id));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(resendAssessmentFailure(apiErrorMessage));
        } else {
          dispatch(
            resendAssessmentFailure(
              'An error occurred resending the assessments. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(resendAssessmentFailure(UNKNOWN_ERROR));
      }
    }
  };

export const patchAssessmentResponse =
  ({
    id,
    assessmentResponse,
    mode,
    onSuccess,
  }: {
    id: string;
    assessmentResponse: AssessmentResponse;
    mode?: 'table';
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(patchAssessmentResponseRequest());
      const response = await api().assessmentResponsesIdPatch({
        id,
        body: assessmentResponse,
      });
      dispatch(patchAssessmentResponseSuccess(response));
      onSuccess && onSuccess();

      if (mode === 'table') {
        dispatch(hideEditTableAnswersModal());
      } else {
        dispatch(showAutoSave());
      }
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(patchAssessmentResponseFailure(apiErrorMessage));
        } else {
          dispatch(
            patchAssessmentResponseFailure(
              'An error occurred updating the policy. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(patchAssessmentResponseFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchIncomingAssessmentResponses =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchIncomingAssessmentResponsesRequest());
      const response = await api().assessmentsIdAssessmentResponsesGet({ id });
      dispatch(fetchIncomingAssessmentResponsesSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchIncomingAssessmentResponsesFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchIncomingAssessmentResponsesFailure(
              'An error occurred fetching the incoming assessment responses. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchIncomingAssessmentResponsesFailure(UNKNOWN_ERROR));
      }
    }
  };

export interface FileOrUrlDetails {
  description: string;
  file?: File;
  url?: string;
}

export const addFileOrUrl =
  (fileOrUrlDetails: FileOrUrlDetails): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const assessmentResponse =
        getState().assessments.currentAssessmentResponse;
      dispatch(uploadFileOrUrlRequest());

      const formData = new FormData();
      formData.append(
        'assessment_response[assessment_response_answers][][name]',
        getState().assessments.addFileToQuestionName
      );
      formData.append(
        'assessment_response[assessment_response_answers][][status]',
        AssessmentResponseAnswerStatusEnum.Answered
      );
      formData.append(
        'assessment_response[assessment_response_answers][][assessment_response_answer_files][][name]',
        fileOrUrlDetails.description
      );
      if (fileOrUrlDetails.file) {
        formData.append(
          'assessment_response[assessment_response_answers][][assessment_response_answer_files][][document]',
          fileOrUrlDetails.file
        );
      }
      if (fileOrUrlDetails.url) {
        formData.append(
          'assessment_response[assessment_response_answers][][assessment_response_answer_files][][url]',
          fileOrUrlDetails.url
        );
      }

      const response = await fetch(
        `/api/v1/assessment_responses/${assessmentResponse.id}`,
        {
          body: formData,
          headers: {
            Accept: 'application/json',
            'X-CSRF-Token': getMetaContent('csrf-token'),
          },
          method: 'PATCH',
        }
      );

      if (!response.ok) {
        const apiErrorMessage = await getApiErrorMessage(response);
        if (apiErrorMessage) {
          if (apiErrorMessage.includes('url is invalid')) {
            dispatch(uploadFileOrUrlFailure('URL is invalid'));
          } else {
            dispatch(uploadFileOrUrlFailure(apiErrorMessage));
          }
        } else {
          dispatch(
            uploadFileOrUrlFailure(
              'An error occurred uploading the file. Please try again or contact support.'
            )
          );
        }
      } else {
        const updatedAssessmentResponse = AssessmentResponseFromJSON(
          await response.json()
        );

        dispatch(uploadFileOrUrlSuccess(updatedAssessmentResponse));
        dispatch(hideAddFileOrUrlModal());
        dispatch(showFileOrUrlSuccessfulToast());
      }
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(uploadFileOrUrlFailure(apiErrorMessage));
        } else {
          dispatch(
            uploadFileOrUrlFailure(
              'An error occurred uploading the file. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(uploadFileOrUrlFailure(UNKNOWN_ERROR));
      }
    }
  };

export const deleteAssessmentFileOrUrl =
  (id: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(deleteAssessmentFileOrUrlRequest());
      await api().assessmentResponseAnswerFilesIdDelete({ id });
      const updatedAssessmentResponse = await api().assessmentResponsesIdGet({
        id: getState().assessments.currentAssessmentResponse.id,
      });
      dispatch(deleteAssessmentFileOrUrlSuccess(updatedAssessmentResponse));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(deleteAssessmentFileOrUrlFailure(apiErrorMessage));
        } else {
          dispatch(
            deleteAssessmentFileOrUrlFailure(
              'An error occurred deleting the file/url. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(deleteAssessmentFileOrUrlFailure(UNKNOWN_ERROR));
      }
    }
  };

export const deleteAssessmentRecipient =
  (assessmentId: string, recipientId: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(deleteAssessmentRecipientRequest());
      await api().assessmentsAssessmentIdAssessmentRecipientsRecipientIdDelete({
        assessmentId,
        recipientId,
      });
      dispatch(deleteAssessmentRecipientSuccess());
      dispatch(fetchAssessment(getState().assessments.currentAssessment.id));
      dispatch(hideConfirmDeleteAssessmentResponseModal());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(deleteAssessmentRecipientFailure(apiErrorMessage));
        } else {
          dispatch(
            deleteAssessmentRecipientFailure(
              'An error occurred deleting the file/url. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(deleteAssessmentRecipientFailure(UNKNOWN_ERROR));
      }
    }
  };

export const deleteAssessmentResponse =
  (id: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(deleteAssessmentResponseRequest());
      await api().assessmentResponsesIdDelete({ id });
      dispatch(deleteAssessmentResponseSuccess());
      dispatch(fetchAssessment(getState().assessments.currentAssessment.id));
      dispatch(hideConfirmDeleteAssessmentResponseModal());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(deleteAssessmentResponseFailure(apiErrorMessage));
        } else {
          dispatch(
            deleteAssessmentResponseFailure(
              'An error occurred deleting the file/url. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(deleteAssessmentResponseFailure(UNKNOWN_ERROR));
      }
    }
  };
export const deleteAssessmentResponseAnswers =
  (
    ids: string[],
    tableQuestions: QuestionType[],
    onSuccess: () => void = () => {}
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(deleteAssessmentResponseRequest());
      await Promise.all(
        ids.map(
          async id => await api().assessmentResponseAnswersIdDelete({ id })
        )
      );
      dispatch(deleteAssessmentResponseSuccess());
      if (tableQuestions) {
        const tableAnswers: AssessmentResponseAnswer[] = [];
        tableQuestions.forEach(question => {
          const answersToUpdate = question.answers.filter(
            answer => ids.indexOf(answer.id) === -1
          );
          answersToUpdate && tableAnswers.push(...answersToUpdate);
        });
        dispatch(
          patchAssessmentResponse({
            id: getState().assessments.currentAssessmentResponse.id,
            assessmentResponse: {
              assessmentResponseAnswers: tableAnswers.map(answer => ({
                name: answer.name,
                value: answer.value,
                index: answer.index,
                datatype: answer.datatype,
                status: AssessmentResponseAnswerStatusEnum.Answered,
              })),
            },
            mode: 'table',
          })
        );
      }
      onSuccess();
      dispatch(hideDeleteTableAnswersModal());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(deleteAssessmentResponseFailure(apiErrorMessage));
        } else {
          dispatch(
            deleteAssessmentResponseFailure(
              'An error occurred deleting the answers. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(deleteAssessmentResponseFailure(UNKNOWN_ERROR));
      }
    }
  };

export const reopenAssessmentResponse =
  (id: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const assessmentResponse =
        getState().assessments.assessmentResponses.find(ar => ar.id === id);

      if (!assessmentResponse) {
        dispatch(
          reopenAssessmentResponseFailure(
            'An error occurred finding the assessment. Please try again or contact support.'
          )
        );
        return;
      }

      dispatch(reopenAssessmentResponseRequest());
      const response = await api().assessmentResponsesIdPatch({
        id,
        body: { status: 'in_progress' },
      });
      dispatch(reopenAssessmentResponseSuccess(response));
      dispatch(push(`/r/riskManagement/assessments/response/${id}`));
      dispatch(hideEditPublishedAssessmentModal());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(reopenAssessmentResponseFailure(apiErrorMessage));
        } else {
          dispatch(
            reopenAssessmentResponseFailure(
              'An error occurred reopening the assessment. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(reopenAssessmentResponseFailure(UNKNOWN_ERROR));
      }
    }
  };

export const publishAssessmentResponse =
  (assessmentResponse: AssessmentResponse): VoidThunk =>
  async dispatch => {
    try {
      dispatch(publishAssessmentResponseRequest());
      const response = await api().assessmentResponsesIdPublishPatch({
        id: assessmentResponse.id,
      });
      dispatch(publishAssessmentResponseSuccess(response));
      dispatch(hidePublishAssessmentModal());
      dispatch(push('/r/riskManagement/assessments/summary'));
      dispatch(showPublishedAssessmentModal());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(publishAssessmentResponseFailure(apiErrorMessage));
        } else {
          dispatch(
            publishAssessmentResponseFailure(
              'An error occurred publishing the assessment. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(publishAssessmentResponseFailure(UNKNOWN_ERROR));
      }
    }
  };

export const exportAssessmentResponses =
  (assessmentId: string, ids: string[]): VoidThunk =>
  async dispatch => {
    try {
      dispatch(exportAssessmentResponsesRequest(0));
      const response = await api().assessmentsIdAssessmentDownloadsPost({
        id: assessmentId,
        assessmentDownload: {
          assessmentResponseIds: ids,
        },
      });
      dispatch(checkForExportSuccess(response.id, 0));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(exportAssessmentResponsesFailure(apiErrorMessage));
        } else {
          dispatch(
            exportAssessmentResponsesFailure(
              'An error occurred reopening the assessment. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(exportAssessmentResponsesFailure(UNKNOWN_ERROR));
      }
    }
  };

const RECHECK_INTERVAL_MS = 1000;

const checkForExportSuccess =
  (id: string, progress: number): VoidThunk =>
  async (dispatch, getState) => {
    if (getState().assessments.exportCancelled) {
      return;
    }

    try {
      const response = await api().assessmentDownloadsIdGet({ id });
      if (response.status === 'ready') {
        dispatch(exportAssessmentResponsesSuccess(response));
        dispatch(hideAssessmentSummaryExportModal());
        dispatch(download(response.document));
      } else {
        if (progress > 99) {
          progress = 99;
        } else {
          progress = progress + Math.floor(Math.random() * 3) + 1;
        }
        dispatch(exportAssessmentResponsesRequest(progress));
        setTimeout(
          () => dispatch(checkForExportSuccess(id, progress)),
          RECHECK_INTERVAL_MS
        );
      }
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(reopenAssessmentResponseFailure(apiErrorMessage));
        } else {
          dispatch(
            reopenAssessmentResponseFailure(
              'An error occurred exporting the assessment. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(reopenAssessmentResponseFailure(UNKNOWN_ERROR));
      }
    }
  };

export const confirmAssessmentResponse =
  (ids: string[]): VoidThunk =>
  async (dispatch, getState) => {
    if (!ids?.length) {
      return;
    }
    try {
      dispatch(confirmAssessmentResponseRequest());
      await Promise.all(
        ids.map(id => api().assessmentResponseAnswersIdConfirmPatch({ id }))
      );
      const updatedAssessmentResponse = await api().assessmentResponsesIdGet({
        id: getState().assessments.currentAssessmentResponse.id,
      });
      dispatch(confirmAssessmentResponseSuccess(updatedAssessmentResponse));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(confirmAssessmentResponseFailure(apiErrorMessage));
        } else {
          dispatch(
            confirmAssessmentResponseFailure(
              'An error occurred confirming the answer. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(confirmAssessmentResponseFailure(UNKNOWN_ERROR));
      }
    }
  };

export const patchAssessment =
  ({
    assessmentId,
    ownerId,
    onSuccess,
  }: {
    assessmentId: string;
    ownerId?: string;
    name?: string;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(patchAssessmentRequest());
      const result = await api().assessmentsIdPatch({
        id: assessmentId,
        body: {
          ownerId,
        },
      });
      dispatch(patchAssessmentSuccess(result));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(patchAssessmentFailure(apiErrorMessage));
        } else {
          dispatch(
            patchAssessmentFailure(
              'An error occurred updating the assessment. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(patchAssessmentFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchAssessmentTemplates =
  (vendorId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAssessmentTemplatesRequest());
      const response = await api().vendorsVendorIdAssessmentTemplatesGet({
        vendorId: vendorId,
      });
      dispatch(fetchAssessmentTemplatesSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchAssessmentTemplatesError(apiErrorMessage));
        } else {
          dispatch(
            fetchAssessmentTemplatesError(
              'An error occurred fetching assessment templates. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchAssessmentTemplatesError(UNKNOWN_ERROR));
      }
    }
  };

export const fetchAssessmentTemplate =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAssessmentTemplateRequest());
      const response = await api().assessmentTemplatesIdGet({
        id: id,
      });
      dispatch(fetchAssessmentTemplateSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchAssessmentTemplateError(apiErrorMessage));
        } else {
          dispatch(
            fetchAssessmentTemplateError(
              'An error occurred fetching an assessment template. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchAssessmentTemplateError(UNKNOWN_ERROR));
      }
    }
  };

export const generateAssessmentFromTemplate =
  (vendorId: string, templateId: string, name: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(generateAssessmentRequest());
      const response = await api().vendorsIdAssessmentsPost({
        id: vendorId,
        body: { assessmentTemplateId: templateId, name: name },
      });
      dispatch(generateAssessmentSuccess(response));
      dispatch(sendAssessment());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(generateAssessmentError(apiErrorMessage));
        } else {
          dispatch(
            generateAssessmentError(
              'An error occurred fetching an assessment template. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(generateAssessmentError(UNKNOWN_ERROR));
      }
    }
  };

export const deleteAssessment =
  (id: string, recipients: AssessmentRecipient[]): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteAssessmentRequest());
      await api().assessmentsIdDelete({
        id,
        body: {
          notificationRecipients: recipients.map(r => {
            return r.email;
          }),
        },
      });
      dispatch(deleteAssessmentSuccess());
      dispatch(showDeleteAssessmentToast());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(deleteAssessmentError(apiErrorMessage));
        } else {
          dispatch(
            deleteAssessmentError(
              'An error occurred deleting an assessment. Please try again later or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(deleteAssessmentError(UNKNOWN_ERROR));
      }
    }
  };
