import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInitials } from '../../selectors/selectUserInitials';
import { editProfile, toggleProfileMenu } from '../../store/user/userSlice';
import { ApplicationState } from '../../types/applicationState';
import { ManageBillingModal } from '../ManageBillingModal/ManageBillingModal';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { EditProfileDrawer } from '../EditProfileDrawer/EditProfileDrawer';
import './UserBadge.scss';
import { WarningSubscriptionLimitModal } from '../../features/pricing/WarningSubscriptionLimitModal/WarningSubscriptionLimitModal';

export const UserBadge: React.FC = () => {
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(false);

  const initials = useSelector((state: ApplicationState) =>
    selectUserInitials(state)
  );
  const isProfileMenuShowing = useSelector(
    (state: ApplicationState) => state.user.isProfileMenuShowing
  );

  return (
    <React.Fragment>
      <div
        className="userBadge"
        onClick={() => {
          setHovered(false);
          dispatch(toggleProfileMenu());
        }}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        {isProfileMenuShowing && (
          <ProfileMenu
            onEditProfileClick={() => {
              dispatch(editProfile());
            }}
          />
        )}
        <div
          className={`userBadge--circle ${
            hovered ? 'userBadge--circle-hovered' : ''
          }`}
        >
          <FontAwesomeIcon icon={faCircle} />
        </div>
        <div
          className={`userBadge--initials ${
            hovered ? 'userBadge--initials-hovered' : ''
          }`}
        >
          {initials}
        </div>
      </div>
      <EditProfileDrawer />
      <ManageBillingModal />
      <WarningSubscriptionLimitModal />
    </React.Fragment>
  );
};
