/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrusthubVendorUserCreatePayloadTrusthubVendorUser
 */
export interface TrusthubVendorUserCreatePayloadTrusthubVendorUser {
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserCreatePayloadTrusthubVendorUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserCreatePayloadTrusthubVendorUser
   */
  firstName: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserCreatePayloadTrusthubVendorUser
   */
  lastName: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserCreatePayloadTrusthubVendorUser
   */
  trusthubCompanyId?: string | null;
}

export function TrusthubVendorUserCreatePayloadTrusthubVendorUserFromJSON(
  json: any
): TrusthubVendorUserCreatePayloadTrusthubVendorUser {
  return TrusthubVendorUserCreatePayloadTrusthubVendorUserFromJSONTyped(
    json,
    false
  );
}

export function TrusthubVendorUserCreatePayloadTrusthubVendorUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubVendorUserCreatePayloadTrusthubVendorUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    firstName: json['first_name'],
    lastName: json['last_name'],
    trusthubCompanyId: !exists(json, 'trusthub_company_id')
      ? undefined
      : json['trusthub_company_id'],
  };
}

export function TrusthubVendorUserCreatePayloadTrusthubVendorUserToJSON(
  value?: TrusthubVendorUserCreatePayloadTrusthubVendorUser | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    first_name: value.firstName,
    last_name: value.lastName,
    trusthub_company_id: value.trusthubCompanyId,
  };
}
