import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import AdoptechModal from '../../../components/AdoptechModal/AdoptechModal';
import {
  hideSubscriptionRequiredModal,
  showSubscriptionRequiredModal,
} from '../../../store/global/globalSlice';
import { push } from 'connected-react-router';
import { manageSubscriptionRoute } from '../../../components/Routes/Routes';
import subscriptionRequiredPlaceholder from 'images/subscriptionRequiredPlaceholder.png';

import './SubscriptionRequiredModal.scss';
import { fetchPaymentPlans } from '../store/pricingThunks';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import {
  coinsToPounds,
  useHandleSubscribe,
} from '../ManageSubscriptionPage/PaymentPlanCard/PaymentPlanCard';
import { SharedPaymentPeriod } from '../../../swagger';
import { selectPaymentPlans } from '../../../selectors/selectPaymentPlans';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';

export const SubscriptionRequiredModal: React.FC = () => {
  const dispatch = useDispatch();

  const isShowing = useSelector(
    (state: ApplicationState) => state.global.isShowingSubscriptionRequiredModal
  );

  const { isCreatingPaymentPlanVendor } = useSelector(
    (state: ApplicationState) => state.pricing
  );

  const handleManageSubscriptionClick = () => {
    dispatch(push(manageSubscriptionRoute));
    dispatch(hideSubscriptionRequiredModal());
  };
  const handleSubscribe = useHandleSubscribe();

  const paymentPlans = useSelector(selectPaymentPlans) || [];
  const plan = paymentPlans[0];

  const monthlyPrice = plan ? coinsToPounds(plan.monthlyPrice) : null;

  const handleSubscribeClick = () => {
    handleSubscribe({
      plan,
      paymentPeriod: SharedPaymentPeriod.Monthly,
    });
  };

  const baseCss = 'subscriptionRequiredModal';
  const currentVendor = useSelector(selectCurrentVendor);
  const { isFetchingPaymentPlans } = useSelector(
    (state: ApplicationState) => state.pricing
  );
  useEffect(() => {
    isShowing && dispatch(fetchPaymentPlans());
  }, [currentVendor?.id, isShowing]);

  if (!isShowing) return null;

  return (
    <AdoptechModal
      className={baseCss}
      onHide={() => dispatch(hideSubscriptionRequiredModal())}
      show={isShowing}
    >
      <Modal.Header>Continue your journey to automated compliance</Modal.Header>
      <Modal.Body>
        <div className={baseCss + '--body'}>
          <>
            <img
              className={baseCss + '--placeholderImage'}
              src={subscriptionRequiredPlaceholder}
            />

            {isFetchingPaymentPlans ? (
              <LoadingSpinner />
            ) : !plan ? ( // payment plan must always exist on the backend
              <div className={baseCss + '--centeredButton'}>
                <AdoptechButton
                  variant={AdoptechButtonVariant.Primary}
                  onClick={handleManageSubscriptionClick}
                  uppercase
                >
                  MANAGE SUBSCRIPTION
                </AdoptechButton>
              </div>
            ) : (
              <>
                <div className={baseCss + '--description'}>
                  You don’t have access to this feature as yet. Do more with our
                  <span className={baseCss + '--orangeText'}>
                    {' '}
                    {plan.name}
                  </span>{' '}
                  plan for just{' '}
                  <span className={baseCss + '--orangeText'}>
                    £{monthlyPrice} a month
                  </span>
                  , plus 50% off your first month when you use discount code
                  SCALE101.
                </div>
                <div className={baseCss + '--footer'}>
                  <AdoptechButton
                    uppercase
                    variant={AdoptechButtonVariant.Primary}
                    onClick={handleSubscribeClick}
                    busy={isCreatingPaymentPlanVendor}
                  >
                    Subscribe now
                  </AdoptechButton>
                  OR
                  <div
                    onClick={handleManageSubscriptionClick}
                    className={baseCss + '--manageSubscriptionLink'}
                  >
                    MANAGE SUBSCRIPTION
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </Modal.Body>
    </AdoptechModal>
  );
};
