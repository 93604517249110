import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentAgreement } from '../../../../selectors/selectCurrentAgreement';
import {
  closePdfPreview,
  openPdfPreview,
  setPdfPreviewUrl,
} from '../../../../store/global/globalSlice';
import { ApplicationState } from '../../../../types/applicationState';
import { PdfOpenMode } from '../../../../types/pdfOpenMode';
import { PdfPreviewCard } from '../../../PdfPreviewCard/PdfPreviewCard';
import { DocumentType } from '../../../../constants/DocumentType';
import {
  AgreementModel,
  AgreementModelStatusEnum,
  VendorDocument,
} from '../../../../swagger';
import {
  currentAgreementPdfRequestFailure,
  setRequestingAgreementPdf,
} from '../../../../store/agreements/agreementsSlice';
import { getRequestWithRetry } from '../../../../functions/getRequestWithRetry';
import { fetchVendorDocumentDownload } from '../../../../store/vendors/vendorsThunks';

// When RHS submitted, reload PDF;

export const pdfURL = (agreement: AgreementModel) => {
  return `/api/v1/agreements/${agreement.id}/preview`;
};

const openPreview = (url: string, agreement: AgreementModel) =>
  openPdfPreview({
    mode: PdfOpenMode.Preview,
    documentType: DocumentType.Agreement,
    objectId: agreement.id,
    title: agreement.name,
    url,
    inline: true,
  });

// If agreement draft use /preview API else /download

const useFetchFile = (agreement: AgreementModel) => {
  const dispatch = useDispatch();
  const fetchFile = () => {
    dispatch(setRequestingAgreementPdf(true));
    if (agreement.status !== AgreementModelStatusEnum.Draft) {
      dispatch(
        fetchVendorDocumentDownload({
          id: agreement.vendorDocumentId,

          successCallback: vendorDocumentDownload => {
            dispatch(openPreview(vendorDocumentDownload.document, agreement));
            dispatch(setRequestingAgreementPdf(false));
          },
        })
      );
      return;
    }
    getRequestWithRetry({
      url: pdfURL(agreement),
      retries: 5,
      isBinary: true,
    })
      .then((blob: Blob) => {
        const localURL = URL.createObjectURL(blob);
        dispatch(openPreview(localURL, agreement));
      })
      .catch(() => {
        dispatch(
          currentAgreementPdfRequestFailure('Cannot load agreement PDF')
        );
        dispatch(openPreview(pdfURL(agreement), agreement)); // Show default "Failed load pdf" react-pdf library warning
      })
      .finally(() => dispatch(setRequestingAgreementPdf(false)));
  };

  return fetchFile;
};

export const usePreviewModeReload = () => {
  const openedDraftPdfPreview = useSelector(
    (state: ApplicationState) =>
      state.global.pdfPreviewInline && state.global.isPreviewingPdf
  );
  const agreement = useSelector(selectCurrentAgreement);
  const dispatch = useDispatch();
  const fetchFile = useFetchFile(agreement);
  const reloadPdf = () => {
    if (!openedDraftPdfPreview) return;
    dispatch(setPdfPreviewUrl(null));
    fetchFile();
  };

  return { reloadPdf };
};

export const PreviewMode: React.FC = () => {
  const dispatch = useDispatch();
  const agreement = useSelector(selectCurrentAgreement);
  const mode = useSelector(
    (state: ApplicationState) => state.agreements.currentAgreementMode
  );

  const isSavingDataRequiredForPdf = useSelector(
    (state: ApplicationState) =>
      state.agreements.isPatchingAgreement ||
      state.agreements.isPatchingAgreementContent ||
      state.agreements.isPostingAgreementAnswer
  );

  const isRequestingAgreementPdf = useSelector(
    (state: ApplicationState) => state.agreements.isRequestingAgreementPdf
  );

  const fetchFile = useFetchFile(agreement);
  useEffect(() => {
    if (isSavingDataRequiredForPdf) return;
    fetchFile();
    return () => {
      dispatch(closePdfPreview());
    };
  }, [mode, isSavingDataRequiredForPdf]);

  return (
    <PdfPreviewCard
      waitingBeforeLoadingPDF={
        isSavingDataRequiredForPdf || isRequestingAgreementPdf
      }
    />
  );
};
