import { createSelector } from '@reduxjs/toolkit';
import { sort } from '../../../../functions/sort';
import { sortByDate } from '../../../../functions/sortByDate';
import { ColumnSort } from '../../../../types/columnSort';
import { selectSortSettings } from '../../../../selectors/selectSortSettings';
import { ApplicationState } from '../../../../types/applicationState';
import { Grid } from '../../../../types/grid';
import { TrusthubVendorUserActivityModel } from '../../../../swagger/trusthub/models/TrusthubVendorUserActivityModel';

const sortActivities = (
  a: TrusthubVendorUserActivityModel,
  b: TrusthubVendorUserActivityModel,
  columnSort: ColumnSort
) => {
  const typedColumn = columnSort.name as keyof TrusthubVendorUserActivityModel;

  if (['createdAt'].includes(typedColumn)) {
    const dateColumn = typedColumn as 'createdAt';
    return sortByDate(a[dateColumn], b[dateColumn], columnSort.direction);
  }

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

export const selectVendorUserActivities = createSelector(
  (state: ApplicationState) => state.trustHub.vendorUserActivities,
  selectSortSettings(Grid.TrustHubUserAdminVendorUserActivities),
  (activities: TrusthubVendorUserActivityModel[], sortSetting) => {
    return [...activities].sort((a, b) =>
      sortActivities(a, b, sortSetting.columnSort)
    );
  }
);
