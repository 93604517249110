/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChecklistCreatePayloadChecklistUsers
 */
export interface ChecklistCreatePayloadChecklistUsers {
  /**
   *
   * @type {string}
   * @memberof ChecklistCreatePayloadChecklistUsers
   */
  vendorUserId: string;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistCreatePayloadChecklistUsers
   */
  responsible?: boolean;
}

export function ChecklistCreatePayloadChecklistUsersFromJSON(
  json: any
): ChecklistCreatePayloadChecklistUsers {
  return ChecklistCreatePayloadChecklistUsersFromJSONTyped(json, false);
}

export function ChecklistCreatePayloadChecklistUsersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistCreatePayloadChecklistUsers {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vendorUserId: json['vendor_user_id'],
    responsible: !exists(json, 'responsible') ? undefined : json['responsible'],
  };
}

export function ChecklistCreatePayloadChecklistUsersToJSON(
  value?: ChecklistCreatePayloadChecklistUsers | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vendor_user_id: value.vendorUserId,
    responsible: value.responsible,
  };
}
