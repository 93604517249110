import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import {
  addAgreementAnswers,
  deleteAgreementTableAnswers,
  fetchAgreementById,
} from '../../../store/agreements/agreementsThunks';
import {
  addAuditAnswers,
  deleteAuditTableAnswers,
} from '../../../features/compliance/audits/store/auditsThunks';
import { showAutoSave } from '../../../store/policies/policiesSlice';
import {
  AgreementExtended,
  Answer,
  AuditExtended,
  Question,
} from '../../../swagger';
import { OnChangeAnswerOptions } from '../../AnswerFormControl/AnswerFormControl';

interface useHandleAnswerChangeProps {
  currentModel: AgreementExtended | AuditExtended;
  mode: 'agreements' | 'audits';
  question: Question;
}

export const useHandleAnswerChange = ({
  currentModel,
  mode,
  question,
}: useHandleAnswerChangeProps) => {
  const isAgreements = mode === 'agreements';
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const currentVendor = useSelector(selectCurrentVendor);

  const onSuccess = () => {
    if (isAgreements) dispatch(fetchAgreementById(params.id, false));
    dispatch(showAutoSave());
  };

  const handleTableChange = (answers: Answer[]) => {
    const payload = {
      vendorId: currentVendor.id,
      answers: answers.map(answer => ({
        value: answer.value,
        questionId: answer.questionId,
        surveyId: currentModel.survey.id,
        propertyName: answer.propertyName,
        index: answer.index,
        id: answer.id,
      })),
      onSuccess,
    };
    dispatch(
      isAgreements ? addAgreementAnswers(payload) : addAuditAnswers(payload)
    );
  };

  const handleDeleteTableAnswers = (answers: Answer[]) => {
    const payload = {
      vendorId: currentVendor.id,
      answersIds: answers.map(answer => answer.id),
      onSuccess,
    };
    dispatch(
      isAgreements
        ? deleteAgreementTableAnswers(payload)
        : deleteAuditTableAnswers(payload)
    );
  };

  const handleCheckboxAnswer = (answers: Answer[]) => {
    const payload = {
      vendorId: currentVendor.id,
      answers: answers.map(answer => ({
        value: answer.value,
        questionRowId: answer.questionRowId,
        surveyId: currentModel.survey.id,
        propertyName: answer.propertyName,
        id: answer.id,
      })),
      onSuccess,
    };
    dispatch(
      isAgreements ? addAgreementAnswers(payload) : addAuditAnswers(payload)
    );
  };

  const handleChange = (answers: Answer[], options?: OnChangeAnswerOptions) => {
    if (options?.isTable2Answer) {
      handleTableChange(answers);
      return;
    }

    if (options?.isCheckboxAnswer) {
      handleCheckboxAnswer(answers);
      return;
    }

    // Upsert answer

    const payload = {
      vendorId: currentVendor.id,
      answers: answers.map((answer: Answer) => ({
        value: answer.value,
        questionId: question.id,
        surveyId: currentModel.survey.id,
        questionRowId: answer.questionRowId,
        index: answer.index,
        propertyName: answer.propertyName,
      })),
      onSuccess,
    };

    dispatch(
      isAgreements ? addAgreementAnswers(payload) : addAuditAnswers(payload)
    );
  };
  return { handleChange, handleDeleteTableAnswers };
};
