/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedTaskRecurring,
  SharedTaskRecurringFromJSON,
  SharedTaskRecurringFromJSONTyped,
  SharedTaskRecurringToJSON,
  VendorSupplierAccessControl,
  VendorSupplierAccessControlFromJSON,
  VendorSupplierAccessControlFromJSONTyped,
  VendorSupplierAccessControlToJSON,
  VendorSupplierExtendedAllOf,
  VendorSupplierExtendedAllOfFromJSON,
  VendorSupplierExtendedAllOfFromJSONTyped,
  VendorSupplierExtendedAllOfToJSON,
  VendorSupplierModel,
  VendorSupplierModelFromJSON,
  VendorSupplierModelFromJSONTyped,
  VendorSupplierModelToJSON,
  VendorSupplierStatus,
  VendorSupplierStatusFromJSON,
  VendorSupplierStatusFromJSONTyped,
  VendorSupplierStatusToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorSupplierExtended
 */
export interface VendorSupplierExtended extends VendorSupplierModel {
  /**
   *
   * @type {string}
   * @memberof VendorSupplierExtended
   */
  vendorSupplierCategoryId: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierExtended
   */
  contractRenewalDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierExtended
   */
  contractId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierExtended
   */
  contractDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierExtended
   */
  contactEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierExtended
   */
  contactName?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierExtended
   */
  contactPhone?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof VendorSupplierExtended
   */
  certifications?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierExtended
   */
  criticalDependencies?: string | null;
}

export function VendorSupplierExtendedFromJSON(
  json: any
): VendorSupplierExtended {
  return VendorSupplierExtendedFromJSONTyped(json, false);
}

export function VendorSupplierExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorSupplierExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...VendorSupplierModelFromJSONTyped(json, ignoreDiscriminator),
    vendorSupplierCategoryId: json['vendor_supplier_category_id'],
    contractRenewalDate: !exists(json, 'contract_renewal_date')
      ? undefined
      : json['contract_renewal_date'],
    contractId: !exists(json, 'contract_id') ? undefined : json['contract_id'],
    contractDescription: !exists(json, 'contract_description')
      ? undefined
      : json['contract_description'],
    contactEmail: !exists(json, 'contact_email')
      ? undefined
      : json['contact_email'],
    contactName: !exists(json, 'contact_name')
      ? undefined
      : json['contact_name'],
    contactPhone: !exists(json, 'contact_phone')
      ? undefined
      : json['contact_phone'],
    certifications: !exists(json, 'certifications')
      ? undefined
      : json['certifications'],
    criticalDependencies: !exists(json, 'critical_dependencies')
      ? undefined
      : json['critical_dependencies'],
  };
}

export function VendorSupplierExtendedToJSON(
  value?: VendorSupplierExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...VendorSupplierModelToJSON(value),
    vendor_supplier_category_id: value.vendorSupplierCategoryId,
    contract_renewal_date: value.contractRenewalDate,
    contract_id: value.contractId,
    contract_description: value.contractDescription,
    contact_email: value.contactEmail,
    contact_name: value.contactName,
    contact_phone: value.contactPhone,
    certifications: value.certifications,
    critical_dependencies: value.criticalDependencies,
  };
}
