import { useState } from 'react';

export const useAdoptechDebounce = () => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const checkWithDebounce = (callback: () => void, delayTime = 500) => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        callback();
      }, delayTime)
    );
  };
  return checkWithDebounce;
};
