import { useSelector } from 'react-redux';
import { AssessmentTabs } from '../../store/assessments/assessmentsState';

export interface AssessmentsMenuItemConfiguration {
  label: string;
  tabId: AssessmentTabs;
  disabled?: boolean;
  hidden?: boolean;
  onClick?: () => void;
  route?: string;
  tooltip?: string;
  showLozenge?: boolean;
}

export const assessmentsMenuConfig = (
  isPaid: boolean
): AssessmentsMenuItemConfiguration[] => {
  return [
    {
      label: 'Outgoing',
      route: '/r/riskManagement/assessments/outgoing',
      showLozenge: false,
      tabId: AssessmentTabs.Outgoing,
      disabled: !isPaid,
    },
    {
      label: 'Incoming',
      route: '/r/riskManagement/assessments/incoming',
      showLozenge: true,
      tabId: AssessmentTabs.Incoming,
    },
  ];
};
