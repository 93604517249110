import React, { useEffect } from 'react';
import { AssertionRunModel } from '../../../swagger';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssertionRunExtended } from './store/testsThunks';
import { ApplicationState } from '../../../types/applicationState';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import './EventDetailsDrawer.scss';
import { formatShortDateTime } from '../../../functions/formatShortDateTime';
import { TestResult } from './TestResult';
import { Status } from './Status';

const baseCss = 'eventDetailsDrawer';

interface Props {
  show: boolean;
  eventId: AssertionRunModel['id'];
  onClose(): void;
}

export const EventDetailsDrawer: React.FC<Props> = ({
  show,
  eventId,
  onClose,
}) => {
  const dispatch = useDispatch();

  const eventDetails = useSelector(
    (state: ApplicationState) => state.tests.assertionRunExtended
  );

  useEffect(() => {
    if (!eventId) return;
    dispatch(fetchAssertionRunExtended(eventId));
  }, [eventId]);

  const Footer = (
    <>
      <AdoptechButton onClick={onClose} variant={AdoptechButtonVariant.White}>
        Cancel
      </AdoptechButton>
    </>
  );

  const status = useSelector(
    (state: ApplicationState) => state.tests.assertionRunExtendedStatus
  );

  let result = 'Progress';

  switch (eventDetails?.status) {
    case 'compliant':
      result = 'Passed';
      break;
    case 'failed':
      result = 'Failed';
      break;
  }

  const owner = eventDetails?.owner?.fullName || eventDetails?.owner?.email;

  return (
    <AdoptechHS
      title="Test Details"
      show={show}
      onClose={onClose}
      showConfirmationWarning={false}
      footer={Footer}
      extraClass={baseCss}
    >
      {status === 'succeeded' && (
        <>
          <div className={baseCss + '--row'}>{eventDetails.description}</div>
          <div className={baseCss + '--row'}>
            <div className={baseCss + '--field'}>
              <strong>Source</strong>
              {eventDetails.source}
            </div>
            {owner && (
              <div className={baseCss + '--field'}>
                <strong>User</strong>
                {owner}
              </div>
            )}
          </div>
          <div className={baseCss + '--row'}>
            <div className={baseCss + '--field'}>
              <strong>Mode</strong>
              {eventDetails.mode}
            </div>
            <div className={baseCss + '--field'}>
              <strong>Result</strong>
              <Status status={eventDetails.status} /> {result}
            </div>
          </div>
          <div className={baseCss + '--row'}>
            <div className={baseCss + '--field'}>
              <strong>Tested</strong>
              {formatShortDateTime(eventDetails.startedAt)}
            </div>
          </div>
          {eventDetails.assertionResults
            .filter(assertionResult => !assertionResult.excluded)
            .map(testResult => (
              <TestResult key={testResult.id} result={testResult} />
            ))}
        </>
      )}
    </AdoptechHS>
  );
};
