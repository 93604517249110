/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AgreementModelSignatoriesStats
 */
export interface AgreementModelSignatoriesStats {
  /**
   *
   * @type {number}
   * @memberof AgreementModelSignatoriesStats
   */
  signed?: number;
  /**
   *
   * @type {number}
   * @memberof AgreementModelSignatoriesStats
   */
  total?: number;
}

export function AgreementModelSignatoriesStatsFromJSON(
  json: any
): AgreementModelSignatoriesStats {
  return AgreementModelSignatoriesStatsFromJSONTyped(json, false);
}

export function AgreementModelSignatoriesStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementModelSignatoriesStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    signed: !exists(json, 'signed') ? undefined : json['signed'],
    total: !exists(json, 'total') ? undefined : json['total'],
  };
}

export function AgreementModelSignatoriesStatsToJSON(
  value?: AgreementModelSignatoriesStats | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    signed: value.signed,
    total: value.total,
  };
}
