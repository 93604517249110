/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Question,
  QuestionFromJSON,
  QuestionFromJSONTyped,
  QuestionToJSON,
} from './';

/**
 *
 * @export
 * @interface AssessmentSection
 */
export interface AssessmentSection {
  /**
   *
   * @type {string}
   * @memberof AssessmentSection
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentSection
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof AssessmentSection
   */
  answered?: number;
  /**
   *
   * @type {number}
   * @memberof AssessmentSection
   */
  unanswered?: number;
  /**
   *
   * @type {number}
   * @memberof AssessmentSection
   */
  progress?: number;
  /**
   *
   * @type {Array<Question>}
   * @memberof AssessmentSection
   */
  questions?: Array<Question>;
}

export function AssessmentSectionFromJSON(json: any): AssessmentSection {
  return AssessmentSectionFromJSONTyped(json, false);
}

export function AssessmentSectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssessmentSection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    answered: !exists(json, 'answered') ? undefined : json['answered'],
    unanswered: !exists(json, 'unanswered') ? undefined : json['unanswered'],
    progress: !exists(json, 'progress') ? undefined : json['progress'],
    questions: !exists(json, 'questions')
      ? undefined
      : (json['questions'] as Array<any>).map(QuestionFromJSON),
  };
}

export function AssessmentSectionToJSON(value?: AssessmentSection | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    answered: value.answered,
    unanswered: value.unanswered,
    progress: value.progress,
    questions:
      value.questions === undefined
        ? undefined
        : (value.questions as Array<any>).map(QuestionToJSON),
  };
}
