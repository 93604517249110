/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SharedPaymentPeriod {
  Monthly = 'monthly',
  Annually = 'annually',
}

export function SharedPaymentPeriodFromJSON(json: any): SharedPaymentPeriod {
  return SharedPaymentPeriodFromJSONTyped(json, false);
}

export function SharedPaymentPeriodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SharedPaymentPeriod {
  return json as SharedPaymentPeriod;
}

export function SharedPaymentPeriodToJSON(
  value?: SharedPaymentPeriod | null
): any {
  return value as any;
}
