/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DataRoomEntityUpdatePayloadEntity,
  DataRoomEntityUpdatePayloadEntityFromJSON,
  DataRoomEntityUpdatePayloadEntityFromJSONTyped,
  DataRoomEntityUpdatePayloadEntityToJSON,
} from './';

/**
 *
 * @export
 * @interface DataRoomEntityUpdatePayload
 */
export interface DataRoomEntityUpdatePayload {
  /**
   *
   * @type {DataRoomEntityUpdatePayloadEntity}
   * @memberof DataRoomEntityUpdatePayload
   */
  entity: DataRoomEntityUpdatePayloadEntity;
}

export function DataRoomEntityUpdatePayloadFromJSON(
  json: any
): DataRoomEntityUpdatePayload {
  return DataRoomEntityUpdatePayloadFromJSONTyped(json, false);
}

export function DataRoomEntityUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DataRoomEntityUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entity: DataRoomEntityUpdatePayloadEntityFromJSON(json['entity']),
  };
}

export function DataRoomEntityUpdatePayloadToJSON(
  value?: DataRoomEntityUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entity: DataRoomEntityUpdatePayloadEntityToJSON(value.entity),
  };
}
