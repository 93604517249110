import { createSelector } from '@reduxjs/toolkit';
import { FrameworkModel } from '../swagger';
import { ApplicationState } from '../types/applicationState';

const getFrameworks = (state: ApplicationState) => state.compliance.frameworks;
export const getSelectedFrameworkId = (state: ApplicationState) =>
  state.compliance.selectedFrameworkId;

export const selectSelectedFramework = createSelector(
  getFrameworks,
  getSelectedFrameworkId,
  (frameworks: FrameworkModel[], selectedFrameworkId: string) => {
    return frameworks?.find(fw => fw.id === selectedFrameworkId);
  }
);
