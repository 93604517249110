/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrusthubDocumentModelExtra
 */
export interface TrusthubDocumentModelExtra {
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentModelExtra
   */
  controlStatus?: TrusthubDocumentModelExtraControlStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentModelExtra
   */
  frameworkName?: string | null;
}

export function TrusthubDocumentModelExtraFromJSON(
  json: any
): TrusthubDocumentModelExtra {
  return TrusthubDocumentModelExtraFromJSONTyped(json, false);
}

export function TrusthubDocumentModelExtraFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubDocumentModelExtra {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    controlStatus: !exists(json, 'control_status')
      ? undefined
      : json['control_status'],
    frameworkName: !exists(json, 'framework_name')
      ? undefined
      : json['framework_name'],
  };
}

export function TrusthubDocumentModelExtraToJSON(
  value?: TrusthubDocumentModelExtra | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    control_status: value.controlStatus,
    framework_name: value.frameworkName,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum TrusthubDocumentModelExtraControlStatusEnum {
  Pending = 'pending',
  Failed = 'failed',
  Compliant = 'compliant',
}
