import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../../../types/applicationState';
import { ColumnSort } from '../../../types/columnSort';
import { CalendarItemModel } from '../../../swagger/models/CalendarItemModel';
import moment from 'moment';
import { calendarItemsSort } from '../../../functions/calendarItemsSort';

type GroupedByMonth = {
  [month: string]: CalendarItemModel[];
};

export const selectCalendarPageItems = createSelector(
  (state: ApplicationState) => state.calendar.items,
  (state: ApplicationState) => state.global.sortSettings,
  (items: CalendarItemModel[], sortSettings): GroupedByMonth => {
    return Object.fromEntries(
      Object.entries(groupedByMonthItems(items)).map(([month, monthItems]) => {
        const monthName = moment(month).format('MMMM YYYY');
        return [monthName, sortedItems(sortSettings, monthItems, monthName)];
      })
    );
  }
);

const groupedByMonthItems = (items: CalendarItemModel[]): GroupedByMonth => {
  return items.reduce((result, filteredItem) => {
    const month = moment(filteredItem.date).format('YYYY-MM');

    if (!result[month]) result[month] = [];

    result[month].push(filteredItem);
    return result;
  }, {} as GroupedByMonth);
};

const sortedItems = (
  sortSettings: any[],
  monthItems: CalendarItemModel[],
  monthName: string
) => {
  const grid = monthName;
  const columnSort: ColumnSort = {
    direction: undefined,
    name: undefined,
  };
  const sortSetting = sortSettings.find(ss => ss.grid === grid) || {
    columnSort,
    grid,
  };

  return monthItems.sort((a, b) =>
    calendarItemsSort(a, b, sortSetting.columnSort)
  );
};
