import React from 'react';
import './MainMenuItems.scss';
import { isProductPaid, mainMenuConfig } from './mainMenuConfig';
import MainMenuItem from '../MainMenuItem/MainMenuItem';
import { useSelector } from 'react-redux';
import { selectActiveSubscription } from '../../selectors/selectActiveSubscription';
import { useQueryParams } from '../../hooks/useQueryParams';
import { selectVendorProductsFeatures } from '../../selectors/pricing/selectVendorProductsFeatures';

const MainMenuItems: React.FC = () => {
  const subscription = useSelector(selectActiveSubscription);
  const queryParams = useQueryParams();

  const fromSignup = !!queryParams.get('showEnter');
  const features = useSelector(selectVendorProductsFeatures);
  const isPaidAssessments = isProductPaid('assessments', features);

  return (
    <div className="mainMenuItems">
      {mainMenuConfig(subscription, { fromSignup, isPaidAssessments }).map(
        (item, index) => {
          return (
            <MainMenuItem key={index} index={index.toString()} item={item} />
          );
        }
      )}
    </div>
  );
};

export default MainMenuItems;
