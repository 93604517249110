/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserResetPassword
 */
export interface UserResetPassword {
  /**
   *
   * @type {string}
   * @memberof UserResetPassword
   */
  resetPasswordToken?: string;
  /**
   *
   * @type {string}
   * @memberof UserResetPassword
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof UserResetPassword
   */
  passwordConfirmation?: string;
}

export function UserResetPasswordFromJSON(json: any): UserResetPassword {
  return UserResetPasswordFromJSONTyped(json, false);
}

export function UserResetPasswordFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserResetPassword {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    resetPasswordToken: !exists(json, 'reset_password_token')
      ? undefined
      : json['reset_password_token'],
    password: !exists(json, 'password') ? undefined : json['password'],
    passwordConfirmation: !exists(json, 'password_confirmation')
      ? undefined
      : json['password_confirmation'],
  };
}

export function UserResetPasswordToJSON(value?: UserResetPassword | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    reset_password_token: value.resetPasswordToken,
    password: value.password,
    password_confirmation: value.passwordConfirmation,
  };
}
