/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TaskListMeta,
  TaskListMetaFromJSON,
  TaskListMetaFromJSONTyped,
  TaskListMetaToJSON,
  TaskModel,
  TaskModelFromJSON,
  TaskModelFromJSONTyped,
  TaskModelToJSON,
} from './';

/**
 *
 * @export
 * @interface TaskList
 */
export interface TaskList {
  /**
   *
   * @type {Array<TaskModel>}
   * @memberof TaskList
   */
  tasks?: Array<TaskModel>;
  /**
   *
   * @type {TaskListMeta}
   * @memberof TaskList
   */
  meta?: TaskListMeta;
}

export function TaskListFromJSON(json: any): TaskList {
  return TaskListFromJSONTyped(json, false);
}

export function TaskListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TaskList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tasks: !exists(json, 'tasks')
      ? undefined
      : (json['tasks'] as Array<any>).map(TaskModelFromJSON),
    meta: !exists(json, 'meta')
      ? undefined
      : TaskListMetaFromJSON(json['meta']),
  };
}

export function TaskListToJSON(value?: TaskList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tasks:
      value.tasks === undefined
        ? undefined
        : (value.tasks as Array<any>).map(TaskModelToJSON),
    meta: TaskListMetaToJSON(value.meta),
  };
}
