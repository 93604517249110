import { VendorPolicyClause } from '../swagger';
import { Question } from '../swagger/models/Question';
import { VendorPolicyDetails } from '../swagger/models/VendorPolicyDetails';

export const keepCurrentAnswersAndClauses = (
  action: { payload: any; type?: string },
  oldVendorPolicyClauses: VendorPolicyClause[],
  oldVendorPolicy: VendorPolicyDetails
) => {
  const vendorPolicyClauses = action.payload.vendorPolicyClauses.map(
    (pc: VendorPolicyClause) => {
      const oldVendorPolicyClause = oldVendorPolicyClauses.find(
        opc => opc.id === pc.id
      );
      const questions = pc.questions.map((newQuestion: Question) => {
        const oldQuestion = oldVendorPolicyClause.questions.find(
          (old: Question) => old.propertyName === newQuestion.propertyName
        );
        if ((newQuestion.layout || oldQuestion.layout) === 'table_layout')
          return newQuestion;
        return oldQuestion || newQuestion;
      });
      const leaveOut = oldVendorPolicyClause.leaveOut;
      return { ...pc, leaveOut, questions };
    }
  );
  const vendorPolicyAnswers = oldVendorPolicy.vendorPolicyAnswers;
  const payload = {
    ...action.payload,
    vendorPolicyClauses,
    vendorPolicyAnswers,
  };
  return payload;
};
