import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationList } from '../../swagger';
import { NotificationsState } from './notificationsState';

const notificationSlice = createSlice({
  initialState: {
    count: 0,
    notificationsList: { data: [], meta: {} },
  } as unknown as NotificationsState,
  name: 'notificationSlice',
  reducers: {
    setAssessmentsCount: (state, action: PayloadAction<number>) => {
      state.assessmentsCount = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    notificationsRequest: state => {
      state.isRequestingNotifications = true;
    },
    notificationsRequestSuccess: (
      state,
      action: PayloadAction<NotificationList>
    ) => {
      state.isRequestingNotifications = false;
      state.notificationsList = action.payload;
    },
    notificationsRequestFailure: (state, action: PayloadAction<any>) => {
      state.isRequestingNotifications = false;
      state.notificationsRequestError = action.payload;
    },
    markNotificationAsReadFailure: (state, action: PayloadAction<any>) => {
      state.markNotificationAsReadRequestFailure = action.payload;
    },
    createNotificationRequest: (state, action: PayloadAction<boolean>) => {
      state.isCreatingNotification = action.payload;
    },
    createNotificationRequestFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.createNotificationRequestError = action.payload;
    },
  },
});

export const {
  setAssessmentsCount,
  setCount,
  notificationsRequest,
  notificationsRequestSuccess,
  notificationsRequestFailure,
  markNotificationAsReadFailure,
  createNotificationRequest,
  createNotificationRequestFailure,
} = notificationSlice.actions;

export default notificationSlice.reducer;
