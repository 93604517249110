import { Interweave } from 'interweave';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './ExitConfirmationModal.scss';

interface ExitConfirmationModalProps {
  onCancel(): void;
  onConfirm(): void;
  submitButtonText?: string;
  title?: string;
  text?: string;
  submitButtonVariant?: AdoptechButtonVariant;
}

export const ExitConfirmationModal: React.FC<ExitConfirmationModalProps> = ({
  onCancel,
  onConfirm,
  submitButtonText,
  submitButtonVariant,
  title,
  text,
}) => {
  const content =
    text ||
    'Are you sure you wish to close this window? Your changes will not be saved. To save changes click CANCEL to return to Edit profile . Select Close to exit without saving.';

  const [submitting, setSubmitting] = useState(false);
  return (
    <React.Fragment>
      <AdoptechModal onHide={() => {}} show>
        <Modal.Header>{title || 'Close window'}</Modal.Header>
        <Modal.Body>
          <Interweave content={content} />
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton disabled={submitting} onClick={onCancel}>
            CANCEL
          </AdoptechButton>
          <AdoptechButton
            onClick={() => {
              setSubmitting(true);
              onConfirm();
            }}
            uppercase
            busy={submitting}
            variant={submitButtonVariant || AdoptechButtonVariant.Primary}
          >
            {submitButtonText || 'Close'}
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
