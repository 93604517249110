/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChecklistTemplateCreatePayloadQuestions
 */
export interface ChecklistTemplateCreatePayloadQuestions {
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateCreatePayloadQuestions
   */
  questionId?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateCreatePayloadQuestions
   */
  questionText?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateCreatePayloadQuestions
   */
  explanatoryText?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateCreatePayloadQuestions
   */
  questionGroupId: string;
}

export function ChecklistTemplateCreatePayloadQuestionsFromJSON(
  json: any
): ChecklistTemplateCreatePayloadQuestions {
  return ChecklistTemplateCreatePayloadQuestionsFromJSONTyped(json, false);
}

export function ChecklistTemplateCreatePayloadQuestionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistTemplateCreatePayloadQuestions {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    questionId: !exists(json, 'question_id') ? undefined : json['question_id'],
    questionText: !exists(json, 'question_text')
      ? undefined
      : json['question_text'],
    explanatoryText: !exists(json, 'explanatory_text')
      ? undefined
      : json['explanatory_text'],
    questionGroupId: json['question_group_id'],
  };
}

export function ChecklistTemplateCreatePayloadQuestionsToJSON(
  value?: ChecklistTemplateCreatePayloadQuestions | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question_id: value.questionId,
    question_text: value.questionText,
    explanatory_text: value.explanatoryText,
    question_group_id: value.questionGroupId,
  };
}
