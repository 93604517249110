import { api } from '../../../../api/api';
import { PdfOpenMode } from '../../../../types/pdfOpenMode';
import { VoidThunk } from '../../../../types/voidThunk';
import {
  getErrorMessage,
  handleThunkError,
} from '../../../../store/helpers/thunkHelpers';
import {
  fetchAuditsRequest,
  fetchAuditsSuccess,
  setAuditsError,
  fetchAuditTemplatesRequest,
  fetchAuditTemplatesSuccess,
  fetchAuditReviewHistoryRequest,
  fetchAuditReviewHistorySuccess,
  fetchCurrentAuditTemplateRequest,
  fetchCurrentAuditTemplateSuccess,
  deleteAuditSuccess,
  deleteAuditRequest,
  deleteAuditError,
  fetchAuditPDFRequest,
  fetchAuditPDFSuccess,
  fetchAuditPDFError,
  fetchAuditRequest,
  fetchAuditSuccess,
  fetchAuditError,
  completeAuditRequest,
  completeAuditSuccess,
  completeAuditError,
  updateAnswer,
  postAuditAnswerRequest,
  postAuditAnswerSuccess,
  postAuditAnswerError,
  deleteAuditAnswerRequest,
  deleteAuditAnswerSuccess,
  deleteAuditAnswerError,
  cleanupAudits,
  createAuditRequest,
  createAuditSuccess,
  updateAuditRequest,
  updateAuditSuccess,
  fetchAnswerControlRequest,
  fetchAnswerControlSuccess,
  fetchAnswerControlError,
  fetchAuditReviewHistoryError,
} from './auditsSlice';
import {
  getPdfDocumentPath,
  PdfPreviewEntityPaths,
} from '../../../../functions/routePathsHelpers';
import { push } from 'connected-react-router';
import { auditsPageRoute } from '../../../../components/Routes/Routes';
import { mapTableAnswers } from '../../../../functions/mapTableAnswers';
import {
  Answer,
  AuditModel,
  AuditCreatePayloadAudit,
  ControlExtended,
  FrameworkModel,
  VendorsVendorIdAuditsUploadPostAuditOriginEnum,
  VendorsVendorIdAuditsUploadPostAuditRatingEnum,
  VendorsVendorIdAuditsUploadMeetingPostRequest,
} from '../../../../swagger';
import { showGlobalToast } from '../../../../store/global/globalSlice';
import {
  setControlFormModel,
  setEditControlMode,
  setEditTaskMode,
  setShowControlDrawer,
} from '../../../../store/compliance/complianceSlice';
import { EditControl } from '../../../../components/compliance/Types/complianceTypes';
import { fetchPolicies } from '../../../../store/policies/policiesThunks';
import {
  fetchComplianceCategories,
  fetchComplianceFrameworks,
} from '../../../../store/compliance/complianceThunks';
import {
  EditControlMode,
  EditTaskMode,
} from '../../../../store/compliance/complianceState';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { WithoutVendorId } from '../../../calendar/store/calendarThunksApiHelpers';
import { ApplicationState } from '../../../../types/applicationState';

export const fetchAudits =
  (vendorId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAuditsRequest());
      const response = await api().vendorsVendorIdAuditsGet({ vendorId });
      dispatch(fetchAuditsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching audits. Please try again or contact support.'
        );
        console.log(err);
        dispatch(setAuditsError(apiErrorMessage));
      }
    }
  };

export const deleteAudit =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteAuditRequest());
      await api().auditsIdDelete({ id });
      dispatch(deleteAuditSuccess(id));
    } catch (err) {
      dispatch(deleteAuditError());
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while deleting an audit. Please try again or contact support.'
        );
        console.log(err);
        dispatch(setAuditsError(apiErrorMessage));
      }
    }
  };

export const fetchAuditTemplates = (): VoidThunk => async dispatch => {
  try {
    dispatch(fetchAuditTemplatesRequest);
    const response = await api().auditTemplatesGet();
    dispatch(fetchAuditTemplatesSuccess(response));
  } catch (err) {
    if (err instanceof Response) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching audit templates. Please try again or contact support.'
      );
      console.log(apiErrorMessage);
    }
  }
};

export const fetchAuditReviewHistory =
  (auditId: string, questionId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAuditReviewHistoryRequest());
      const response = await api().auditsAuditIdReviewHistoryGet({
        auditId,
        questionId,
      });
      dispatch(fetchAuditReviewHistorySuccess({ questionId, items: response }));
    } catch (err) {
      dispatch(fetchAuditReviewHistoryError());
      if (err instanceof Response) {
        const description =
          'An error occurred while fetching audit review history. Please try again or contact support.';
        await getErrorMessage(err, description);
      }
    }
  };

export const fetchCurrentAuditTemplate =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchCurrentAuditTemplateRequest());
      const response = await api().auditTemplatesIdGet({ id });
      dispatch(fetchCurrentAuditTemplateSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching audit questions. Please try again or contact support.'
        );
        console.log(apiErrorMessage);
      }
    }
  };

export const fetchAuditPDF =
  (audit: AuditModel): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAuditPDFRequest());
      dispatch(fetchAuditPDFSuccess());

      dispatch(
        push(
          getPdfDocumentPath({
            mode: PdfOpenMode.PreviewDocument,
            basePath: PdfPreviewEntityPaths.audits,
            documentableId: audit.id,
            vendorDocumentId: audit.vendorDocumentId,
          })
        )
      );
    } catch (err) {
      dispatch(fetchAuditPDFError());
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching an Audit PDF. Please try again or contact support.'
        );
        console.log(err);
        dispatch(setAuditsError(apiErrorMessage));
      }
    }
  };

export const createAudit =
  (
    audit: AuditModel,
    questionIds: string[],
    onSuccess: () => void,
    onError: (err: string) => void
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(createAuditRequest());
      const vendorId = getState().vendors.currentVendor?.id;
      const body = {
        audit: {
          ...audit,
          questionIds,
        } as AuditCreatePayloadAudit,
      };
      await api().vendorsVendorIdAuditsPost({
        vendorId,
        body,
      });
      dispatch(createAuditSuccess());
      onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        onError(err.statusText);
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while creating an Audit. Please try again or contact support.'
        );
        console.log(apiErrorMessage);
      }
    }
  };

export interface UploadAuditPayload extends AuditModel {
  origin: string;
  file: File;
}

export const uploadAudit =
  (payload: UploadAuditPayload, onSuccess?: () => void): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(createAuditRequest());
      dispatch(showGlobalToast('Uploading audit. Please wait...'));

      const response = await api().vendorsVendorIdAuditsUploadPost({
        vendorId,
        auditName: payload.name,
        auditOwnerId: payload.owner.id,
        auditOrigin:
          payload.origin as VendorsVendorIdAuditsUploadPostAuditOriginEnum,
        auditStartedAt: payload.startedAt,
        auditCompletedAt: payload.completedAt,
        auditRating:
          payload.rating as string as VendorsVendorIdAuditsUploadPostAuditRatingEnum,
        auditFile: payload.file,
      });

      dispatch(createAuditSuccess());
      dispatch(showGlobalToast('A audit has been successfully uploaded.'));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while uploading audit. Please try again or contact support.'
      );
    }
  };

export const updateAudit =
  (
    audit: AuditModel,
    onSuccess: () => void,
    onError: (err: string) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(updateAuditRequest());
      const body = {
        audit: {
          name: audit.name,
          startedAt: audit.startedAt,
          ownerId: audit.owner.id,
        },
      };
      await api().auditsIdPatch({ id: audit.id, body });
      dispatch(updateAuditSuccess());
      onSuccess();
    } catch (err) {
      onError(err.statusText);
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while updating an Audit. Please try again or contact support.'
      );
      console.log(apiErrorMessage);
    }
  };

export const fetchAudit =
  (id: AuditModel['id'], withLoader = true): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAuditRequest(withLoader));
      const response = await api().auditsIdGet({ id });
      const responseWithTables = mapTableAnswers(response);
      dispatch(fetchAuditSuccess(responseWithTables));
    } catch (err) {
      dispatch(fetchAuditError());
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching an Audit. Please try again or contact support.'
        );
        console.log(err);
        dispatch(setAuditsError(apiErrorMessage));
      }
    }
  };

export const completeAudit =
  (id: AuditModel['id']): VoidThunk =>
  async dispatch => {
    try {
      dispatch(cleanupAudits());
      dispatch(completeAuditRequest());
      await api().auditsIdCompletePost({ id });
      dispatch(completeAuditSuccess());
      dispatch(push(auditsPageRoute));
      dispatch(showGlobalToast('Audit has been successfully completed'));
    } catch (err) {
      dispatch(completeAuditError());
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while completing an Audit. Please try again or contact support.'
        );
        console.log(err);
        dispatch(setAuditsError(apiErrorMessage));
      }
    }
  };

export const addAuditAnswers =
  ({
    vendorId,
    answers,
    onSuccess,
  }: {
    vendorId: string;
    answers: Answer[];
    onSuccess?(): void;
  }): VoidThunk =>
  async dispatch => {
    try {
      answers.forEach(a => dispatch(updateAnswer(a)));
      dispatch(postAuditAnswerRequest());
      await api().vendorsIdAnswersPost({
        id: vendorId,
        answers: {
          answers,
        },
      });
      dispatch(postAuditAnswerSuccess());
      onSuccess && onSuccess();
    } catch (err) {
      console.log('err', err);
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while adding audit answer. Please try again or contact support.'
      );
      console.log(apiErrorMessage);
      dispatch(postAuditAnswerError());
    }
  };

export const deleteAuditTableAnswers =
  ({
    answersIds,
    vendorId,
    onSuccess,
  }: {
    answersIds: string[];
    vendorId: string;
    onSuccess: () => void;
  }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      if (answersIds.filter(id => id).length > 0) {
        dispatch(deleteAuditAnswerRequest());
        await api().vendorsIdAnswersDelete({
          id: vendorId,
          body: { answersIds },
        });
        dispatch(deleteAuditAnswerSuccess());
      }
      const id = getState().audits.currentAudit.id;
      onSuccess && onSuccess();
    } catch (err) {
      console.log('err', err);
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while deleting audit answer. Please try again or contact support.'
      );
      console.log(apiErrorMessage);
      dispatch(deleteAuditAnswerError());
    }
  };

export const fetchAnswerControl =
  (controlId: ControlExtended['identifier']): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(fetchAnswerControlRequest());
      const response = await api().controlsControlIdGet({
        controlId,
        vendorId,
      });
      dispatch(fetchPolicies());
      const onSuccess = (frameworkId: FrameworkModel['id']) => {
        // TODO: investigate is it possible to remove it and use framework relations
        dispatch(fetchComplianceCategories(vendorId, frameworkId)); // needed for linked controls
      };
      dispatch(fetchComplianceFrameworks(vendorId, onSuccess));
      dispatch(setControlFormModel(new EditControl(response).simpleObject()));
      dispatch(fetchAnswerControlSuccess());
      dispatch(setEditControlMode(EditControlMode.View));
      dispatch(setEditTaskMode(EditTaskMode.View));
      dispatch(setShowControlDrawer(true));
    } catch (err) {
      alert(
        "Control doesn't exist for the audit question.Please contact to administrator."
      );
      dispatch(fetchAnswerControlError());
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching an Answer Control. Please try again or contact support.'
        );
        console.log(err);
        dispatch(setAuditsError(apiErrorMessage));
      }
    }
  };

export type UploadMeetingsPayload =
  WithoutVendorId<VendorsVendorIdAuditsUploadMeetingPostRequest>;
export const postMeeting = createAsyncThunk(
  'compliance/patchAssertionResultExclude',
  async (params: UploadMeetingsPayload, { dispatch, getState }) => {
    const vendorId = (getState() as ApplicationState).vendors.currentVendor.id;
    const body = {
      ...params,
      ...{ vendorId },
    };
    try {
      await api().vendorsVendorIdAuditsUploadMeetingPost(body);
    } catch (error) {
      await handleThunkError(
        'An error occurred on uploading Meeting Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);
