import React from 'react';
import { getClauseLastUpdatedInfo } from '../../../../../functions/getClauseLastUpdatedInfo';

import {
  AuditExtended,
  Question,
  SurveyClause,
} from '../../../../../swagger/models';
import { LastUpdatedInfo } from '../../../../../components/LastUpdatedInfo/LastUpdatedInfo';
import '../../../../../components/Agreements/AgreementClauseQuestions/AgreementClauseQuestions.scss';
import { AuditQuestion } from '../AuditQuestion/AuditQuestion';
import { selectVisibleClauseQuestions } from '../../../../../components/Agreements/AgreementClauseQuestions/AgreementClauseQuestions';

interface AuditClauseQuestionsProps {
  auditClause: SurveyClause;
  audit: AuditExtended;
}

export const AuditClauseQuestions: React.FC<AuditClauseQuestionsProps> = ({
  auditClause,
  audit,
}) => {
  const lastUpdated = getClauseLastUpdatedInfo(
    auditClause.updatedAt,
    auditClause.updatedBy?.fullName
  );

  const questions = selectVisibleClauseQuestions(auditClause);

  return (
    <React.Fragment>
      {questions.length > 0 &&
        questions.map((question: Question) => (
          <AuditQuestion audit={audit} key={question.id} question={question} />
        ))}

      {lastUpdated && (
        <div className="agreementClauseQuestions--footer">
          <LastUpdatedInfo
            title="Clause last updated"
            lastUpdatedInfo={lastUpdated}
          />
        </div>
      )}
    </React.Fragment>
  );
};
