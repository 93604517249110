import { faCloudDownloadAlt } from '@fortawesome/pro-light-svg-icons/faCloudDownloadAlt';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  openPdfPreview,
  setPdfPreviewUrl,
} from '../../../store/global/globalSlice';
import { ApplicationState } from '../../../types/applicationState';
import { PdfOpenMode } from '../../../types/pdfOpenMode';
import { AdoptechButton } from '../../AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Panel } from '../../Panel/Panel';
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import { DocumentType } from '../../../constants/DocumentType';

import './ReportsPreviewPage.scss';
import { PdfPreviewCard } from '../../PdfPreviewCard/PdfPreviewCard';
import { reportsRoute } from '../../Routes/Routes';
import {
  fetchReport,
  fetchReportDocument,
} from '../../../store/reports/reportsThunks';
import { cleanCurrentReport } from '../../../store/reports/reportsSlice';
import { downloadReportFile } from '../ReportsList/ReportsList';
import { ReportModel } from '../../../swagger/models/ReportModel';

export const ReportsPreviewPage: React.FC = () => {
  const baseCss = 'reportsPreviewPage';
  const dispatch = useDispatch();
  const goBack = () => dispatch(push(reportsRoute));

  const { id } = useParams() as { id: string };
  const pdfPreviewUrl = useSelector(
    (state: ApplicationState) => state.global.pdfPreviewUrl
  );
  const { currentReport, isFetchingReport, isFetchingReportDocument } =
    useSelector((state: ApplicationState) => state.reports);
  useEffect(() => {
    const afterReportFetched = (report: ReportModel) => {
      dispatch(
        fetchReportDocument({
          id,
          successCallback: (url: string) => {
            openPreview(url, report.name);
          },
        })
      );
    };
    dispatch(fetchReport(id, afterReportFetched));
    return () => {
      dispatch(cleanCurrentReport());
      dispatch(setPdfPreviewUrl());
    };
  }, [id]);

  const openPreview = (url: string, name: string) =>
    dispatch(
      openPdfPreview({
        mode: PdfOpenMode.Preview,
        documentType: DocumentType.Report,
        title: name,
        inline: true,
        url,
      })
    );

  if (isFetchingReport || isFetchingReportDocument || !currentReport)
    return <LoadingSpinner />;

  return (
    <div className={baseCss}>
      <Panel>
        <div className={baseCss + '--progressBar'}>
          <ProgressBar progress={0} />
        </div>
        <div className={baseCss + '--mainContent'}>
          <div className={baseCss + '--back'} onClick={() => goBack()}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <div className={baseCss + '--info'}>
            <div className={baseCss + '--column'}>
              <div className={baseCss + '--columnHeader'}>Name</div>
              <div className={baseCss + '--columnData'}>
                {currentReport.name}
              </div>
            </div>
            <div
              className={classNames([
                baseCss + '--column',
                baseCss + '--downloadColumn',
              ])}
            >
              <AdoptechButton
                onClick={() => {
                  downloadReportFile(pdfPreviewUrl, currentReport.name);
                }}
                rounded
                disabled={!pdfPreviewUrl}
              >
                <FontAwesomeIcon
                  className={baseCss + '--downloadIcon'}
                  icon={faCloudDownloadAlt}
                />
                Download
              </AdoptechButton>
            </div>
          </div>
        </div>
      </Panel>
      <PdfPreviewCard waitingBeforeLoadingPDF={!pdfPreviewUrl} />
    </div>
  );
};
