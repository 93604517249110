import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rotateDirection } from '../../functions/rotateDirection';
import {
  AgreementModel,
  Assessment,
  AssessmentResponse,
  ChecklistExtended,
  ChecklistItem,
  GlobalSearchItem,
  PaymentPlan,
  Product,
  VendorDocument,
  VendorDocumentExtended,
} from '../../swagger';
import { Grid } from '../../types/grid';
import { PdfOpenMode } from '../../types/pdfOpenMode';
import { SortDirection } from '../../types/sortDirection';
import { GlobalState } from './globalState';
import { DocumentType } from '../../constants/DocumentType';
import { PurchaseType } from '../../types/PurchaseType';
import { OwnerObjectType } from '../../types/OwnerObjectType';

export interface ShowVideoRequest {
  checklist?: ChecklistExtended;
  item?: ChecklistItem;
  videoId: number;
}

const globalSlice = createSlice({
  initialState: {
    isInitialised: false,
    isInitialising: false,
    isShowingVideoPlayerModal: false,
    isShowingSubscriptionRequiredModal: false,
    globalSearchInProgress: false,
    globalSearchResults: [],
    globalSearchBarVisible: false,
    globalToastConfig: {
      isShowing: false,
      text: '',
    },
    sortSettings: [
      {
        columnSort: { name: 'name', direction: SortDirection.Descending },
        grid: Grid.AllDocuments,
      },
      {
        columnSort: {
          name: 'attestationDueAt',
          direction: SortDirection.Descending,
        },
        grid: Grid.PendingDocuments,
      },
      {
        columnSort: {
          name: 'attestationDueAt',
          direction: SortDirection.Descending,
        },
        grid: Grid.DocumentAdmin,
      },
      {
        columnSort: {
          name: 'status',
          direction: SortDirection.Ascending,
        },
        grid: Grid.AttestationManagement,
      },
      {
        columnSort: {
          name: 'status',
          direction: SortDirection.Ascending,
        },
        grid: Grid.ApprovalManagement,
      },
      {
        columnSort: {
          name: 'fullName',
          direction: SortDirection.Descending,
        },
        grid: Grid.Users,
      },
      ...[
        Grid.DraftAgreements,
        Grid.AwaitingSignatureAgreements,
        Grid.CompletedAgreements,
      ].map(grid => {
        return {
          columnSort: { name: 'name', direction: SortDirection.Ascending },
          grid: grid,
        };
      }),
    ],
    showHelp: false,
  } as unknown as GlobalState,
  name: 'globalSlice',
  reducers: {
    clearErrorMessage: state => {
      state.errorMessage = undefined;
    },
    clearInitialise: state => {
      state.isInitialised = false;
    },
    closePdfPreview: state => {
      state.isPreviewingPdf = false;
      state.pdfPreviewUrl = undefined;
      state.pdfPreviewVendorDocumentId = undefined;
      state.pdfPreviewObjectId = undefined;
      state.pdfPreviewInline = false;
    },
    download: (state, action: PayloadAction<string>) => {
      state.downloadUrl = action.payload;
    },

    setPdfPreviewUrl: (state, action: PayloadAction<string>) => {
      state.pdfPreviewUrl = action.payload;
    },

    initialisationFailure: (state, action: PayloadAction<string>) => {
      state.isInitialising = false;
      state.initialisationError = action.payload;
    },
    initialisationRequest: state => {
      state.initialisationError = undefined;
      state.isInitialising = true;
    },
    initialisationSuccess: state => {
      state.isInitialised = true;
      state.isInitialising = false;
    },
    openPdfPreview: (
      state,
      action: PayloadAction<{
        dueDate?: string;
        documentType: DocumentType;
        mode: PdfOpenMode;
        title: string;
        url?: string;
        vendorDocument?: VendorDocument | VendorDocumentExtended;
        vendorDocumentId?: string;
        objectId?: string;
        documentableId?: string;
        navigateUrlOnClose?: string;
        inline?: boolean;
        customPageHeaderLabel?: string;
      }>
    ) => {
      state.isPreviewingPdf = true;
      state.pdfOpenMode = action.payload.mode;
      state.pdfPreviewDueDate = action.payload.dueDate;
      state.pdfPreviewTitle = action.payload.title;
      state.pdfPreviewUrl = action.payload.url;
      state.pdfPreviewVendorDocument = action.payload.vendorDocument;
      state.pdfPreviewVendorDocumentId = action.payload.vendorDocumentId;
      state.pdfPreviewObjectId = action.payload.objectId;
      state.pdfPreviewDocumentType = action.payload.documentType;
      state.pdfPreviewInline = action.payload.inline || false;
      state.documentableId = action.payload.documentableId;
      state.navigateUrlOnClose = action.payload.navigateUrlOnClose;
    },
    resetScrollToY: state => {
      state.scrollToY = undefined;
    },
    scrollToY: (state, action: PayloadAction<number>) => {
      state.scrollToY = action.payload;
    },
    scrollCardIntoView: (state, action: PayloadAction<string>) => {
      state.scrollCardIntoView = action.payload;
    },
    resetScrollCardIntoView: state => {
      state.scrollCardIntoView = null;
    },
    setPageHeaderLabel: (state, action: PayloadAction<string>) => {
      state.customPageHeaderLabel = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setTableSort: (
      state,
      action: PayloadAction<{ columnName: string; grid: Grid | string }>
    ) => {
      const existingSort = state.sortSettings.find(
        ss => ss.grid === action.payload.grid
      );

      if (existingSort) {
        existingSort.columnSort = {
          direction:
            existingSort.columnSort.name === action.payload.columnName
              ? rotateDirection(existingSort.columnSort.direction)
              : SortDirection.Descending,
          name: action.payload.columnName,
        };
      } else {
        state.sortSettings.push({
          columnSort: {
            direction: SortDirection.Descending,
            name: action.payload.columnName,
          },
          grid: action.payload.grid,
        });
      }
    },
    showGlobalToastWithUndo: (state, action: PayloadAction<string>) => {
      state.globalToastConfig = {
        isShowing: true,
        text: action.payload,
        undoButton: true,
      };
    },
    showGlobalToast: (state, action: PayloadAction<string>) => {
      state.globalToastConfig = {
        isShowing: true,
        text: action.payload,
      };
    },
    hideGlobalToast: state => {
      state.globalToastConfig = {
        isShowing: false,
        text: '',
      };
    },
    showHelp: state => {
      state.showHelp = true;
    },
    clearHelp: state => {
      state.showHelp = false;
    },
    showPolicyHelp: state => {
      state.showPolicyHelp = true;
    },
    clearPolicyHelp: state => {
      state.showPolicyHelp = false;
    },
    showAssessmentHelp: state => {
      state.showAssessmentHelp = true;
    },
    clearAssessmentHelp: state => {
      state.showAssessmentHelp = false;
    },
    showEditOwnerModal: (
      state,
      action: PayloadAction<{
        object:
          | VendorDocument
          | AgreementModel
          | Assessment
          | AssessmentResponse;
        type: OwnerObjectType;
      }>
    ) => {
      state.isEditOwnerModalShowing = true;
      state.ownerObjectToEdit = action.payload.object;
      state.ownerObjectType = action.payload.type;
    },
    showSubscriptionRequiredModal: state => {
      state.isShowingSubscriptionRequiredModal = true;
    },
    hideSubscriptionRequiredModal: state => {
      state.isShowingSubscriptionRequiredModal = false;
    },
    hideEditOwnerModal: state => {
      state.isEditOwnerModalShowing = false;
      state.ownerObjectToEdit = undefined;
      state.ownerObjectType = undefined;
    },
    showEditOwnerToast: state => {
      state.isEditedOwnerToastShowing = true;
    },
    hideEditOwnerToast: state => {
      state.isEditedOwnerToastShowing = false;
    },
    globalSearchRequest: (state, action: PayloadAction<string>) => {
      state.globalSearchResults = [];
      state.globalSearchInProgress = true;
    },
    globalSearchSuccess: (state, action: PayloadAction<GlobalSearchItem[]>) => {
      state.globalSearchResults = action.payload;
      state.globalSearchInProgress = false;
    },
    globalSearchError: (state, action: PayloadAction<string>) => {
      state.globalSearchInProgress = false;
    },
    clearGlobalSearchResults: state => {
      state.globalSearchResults = [];
    },
    setGlobalSearchBarVisible: (state, action: PayloadAction<boolean>) => {
      state.globalSearchBarVisible = action.payload;
    },
    setBackgroundColor: (
      state,
      action: PayloadAction<GlobalState['backgroundColor']>
    ) => {
      state.backgroundColor = action.payload;
    },
    setCustomAppContentClasses: (
      state,
      action: PayloadAction<GlobalState['customAppContentClasses']>
    ) => {
      state.customAppContentClasses = action.payload;
    },
    showHelpMenu: state => {
      state.isHelpMenuShowing = true;
    },
    hideHelpMenu: state => {
      state.isHelpMenuShowing = false;
    },
  },
});

export const {
  clearErrorMessage,
  clearInitialise,
  closePdfPreview,
  download,
  initialisationFailure,
  initialisationRequest,
  initialisationSuccess,
  openPdfPreview,
  resetScrollToY,
  scrollToY,
  scrollCardIntoView,
  resetScrollCardIntoView,
  setErrorMessage,
  setTableSort,
  showGlobalToast,
  hideGlobalToast,
  showHelp,
  clearHelp,
  clearPolicyHelp,
  showPolicyHelp,
  clearAssessmentHelp,
  showAssessmentHelp,
  showEditOwnerModal,
  hideEditOwnerModal,
  showSubscriptionRequiredModal,
  hideSubscriptionRequiredModal,
  showEditOwnerToast,
  hideEditOwnerToast,
  setPdfPreviewUrl,
  showGlobalToastWithUndo,
  setPageHeaderLabel,
  globalSearchRequest,
  globalSearchSuccess,
  globalSearchError,
  clearGlobalSearchResults,
  setGlobalSearchBarVisible,
  setBackgroundColor,
  setCustomAppContentClasses,
  showHelpMenu,
  hideHelpMenu,
} = globalSlice.actions;
export default globalSlice.reducer;
