/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SharedVendorUserStatus {
  Null = 'null',
  Invited = 'invited',
  Active = 'active',
  Deleted = 'deleted',
}

export function SharedVendorUserStatusFromJSON(
  json: any
): SharedVendorUserStatus {
  return SharedVendorUserStatusFromJSONTyped(json, false);
}

export function SharedVendorUserStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SharedVendorUserStatus {
  return json as SharedVendorUserStatus;
}

export function SharedVendorUserStatusToJSON(
  value?: SharedVendorUserStatus | null
): any {
  return value as any;
}
