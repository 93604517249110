import { History } from 'history';
import React, { Suspense } from 'react';
import { LoadingScreen } from '../../../components/LoadingScreen/LoadingScreen';
import { Route, Switch } from 'react-router';
import { ErrorToast } from '../../../components/ErrorToast/ErrorToast';
import { GlobalToast } from '../../../components/GlobalToast/GlobalToast';
import { TrustHubUserPage } from './TrustHubUserPage/TrustHubUserPage';
import { TrustHubAuthModal } from './TrustHubAuthModal/TrustHubAuthModal';

interface RoutesProps {
  history: History;
}

export const trustHubRoutes = {
  registerInvite: '/register-invite',
  resetPassword: '/reset-password',
  login: '/login',
};

export const TrustHubUserRoutes: React.FC<RoutesProps> = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route path={['/', trustHubRoutes.registerInvite]}>
          <TrustHubUserPage />
          <TrustHubAuthModal />
          <ErrorToast />
          <GlobalToast />
        </Route>
      </Switch>
    </Suspense>
  );
};
