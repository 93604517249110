import React from 'react';
import { formatLongDate } from '../../functions/formatLongDate';
import './LastUpdatedInfo.scss';

interface LastUpdatedInfoProps {
  customText?: string;
  includeConfirmation?: boolean;
  isConfirmed?: boolean;
  lastUpdatedInfo: {
    confirmedAt?: string;
    confirmedBy?: string;
    updatedAt: string;
    updatedBy: string;
  };
  title?: string;
}

export const LastUpdatedInfo: React.FC<LastUpdatedInfoProps> = ({
  customText,
  includeConfirmation,
  isConfirmed,
  lastUpdatedInfo,
  title,
}) => {
  return (
    <div className="lastUpdatedInfo">
      {customText}
      {!customText &&
        lastUpdatedInfo &&
        (includeConfirmation ? (
          <div className="lastUpdatedInfo--columns">
            <div className="lastUpdatedInfo--lhs">
              Last updated by
              {lastUpdatedInfo.updatedBy && (
                <div className="lastUpdatedInfo--by">
                  {lastUpdatedInfo.updatedBy}
                </div>
              )}
              <div className="lastUpdatedInfo--at">
                {formatLongDate(lastUpdatedInfo.updatedAt)}
              </div>
            </div>
            <div className="lastUpdatedInfo--rhs">
              {isConfirmed && (
                <>
                  Confirmed by
                  {lastUpdatedInfo.updatedBy && (
                    <div className="lastUpdatedInfo--by">
                      {lastUpdatedInfo.confirmedBy}
                    </div>
                  )}
                  <div className="lastUpdatedInfo--at">
                    {formatLongDate(lastUpdatedInfo.confirmedAt)}
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            {title || 'Last updated by'}
            {lastUpdatedInfo.updatedBy && (
              <div className="lastUpdatedInfo--by">
                {lastUpdatedInfo.updatedBy}
              </div>
            )}
            <div className="lastUpdatedInfo--at">
              {formatLongDate(lastUpdatedInfo.updatedAt)}
            </div>
          </>
        ))}
    </div>
  );
};
