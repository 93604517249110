import React from 'react';
import { ReportForm, ReportSectionProps } from '../ReportsManageDrawer';
import { ReportNameField } from '../ReportNameField';
import { ReportOwnerField } from '../ReportOwnerField';
import { ReportFileField } from '../ReportFileField';
import { ReportCompletionField } from '../ReportCompletionField';
import { ReportFreeVersionField } from '../ReportFreeVersionField';

export const uploadedReportSectionValid = (formData: ReportForm): boolean => {
  return !!(formData.version && formData.ownerId && formData.file);
};

export const UploadedReportSection: React.FC<ReportSectionProps> = ({
  formData,
  updateField,
  updateForm,
}) => {
  const sectionProps = { formData, updateField, updateForm };
  return (
    <div>
      <ReportNameField {...sectionProps} />
      <ReportOwnerField {...sectionProps} />
      <ReportCompletionField {...sectionProps} />
      <ReportFreeVersionField {...sectionProps} />
      <ReportFileField {...sectionProps} />
    </div>
  );
};
