import { AssessmentResponseAnswer, QuestionRow } from '../swagger';

const getCheckboxAnswers = (
  questionRows: QuestionRow[],
  answers: AssessmentResponseAnswer[]
): AssessmentResponseAnswer[] => {
  if (!questionRows?.length || !answers?.length) {
    return null;
  }

  return answers.filter((answer: AssessmentResponseAnswer) => {
    return Boolean(
      questionRows.find(question => question.name === answer.name)
    );
  });
};

export default getCheckboxAnswers;
