import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AssertionCategoryModel,
  AssertionRunExtended,
  AssertionRunList,
} from '../../../../swagger';
import { TestsState } from './testsState';

const initialState: TestsState = {
  assertionRunsStatus: 'idle',
  assertionRuns: {} as AssertionRunList,
  assertionCategoriesStatus: 'idle',
  assertionCategories: [] as AssertionCategoryModel[],
} as TestsState;

const testsSlice = createSlice({
  initialState,
  name: 'testsSlice',
  reducers: {
    fetchAssertionRunsRequest: state => {
      state.assertionRunsStatus = 'loading';
    },
    fetchAssertionRunsSuccess: (
      state,
      action: PayloadAction<AssertionRunList>
    ) => {
      state.assertionRunsStatus = 'succeeded';
      state.assertionRuns = action.payload;
    },
    fetchAssertionRunsFail: state => {
      state.assertionRunsStatus = 'failed';
    },
    fetchAssertionCategoriesRequest: state => {
      state.assertionCategoriesStatus = 'loading';
    },
    fetchAssertionCategoriesSuccess: (
      state,
      action: PayloadAction<AssertionCategoryModel[]>
    ) => {
      state.assertionCategoriesStatus = 'succeeded';
      state.assertionCategories = action.payload;
    },
    fetchAssertionCategoriesFail: state => {
      state.assertionCategoriesStatus = 'failed';
    },
    fetchAssertionRunExtendedRequest: state => {
      state.assertionRunExtendedStatus = 'loading';
    },
    fetchAssertionRunExtendedSuccess: (
      state,
      action: PayloadAction<AssertionRunExtended>
    ) => {
      state.assertionRunExtendedStatus = 'succeeded';
      state.assertionRunExtended = action.payload;
    },
    fetchAssertionRunExtendedFail: state => {
      state.assertionRunExtendedStatus = 'failed';
    },
  },
});

export const {
  fetchAssertionRunsRequest,
  fetchAssertionRunsSuccess,
  fetchAssertionRunsFail,
  fetchAssertionCategoriesRequest,
  fetchAssertionCategoriesSuccess,
  fetchAssertionCategoriesFail,
  fetchAssertionRunExtendedRequest,
  fetchAssertionRunExtendedSuccess,
  fetchAssertionRunExtendedFail,
} = testsSlice.actions;

export default testsSlice.reducer;
