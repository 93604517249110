import { ReportModel } from '../../swagger/models/ReportModel';
import { ReportsState } from './reportsState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  reportsSearch: null,
  isFetchingReports: false,
  reports: [],
  isCreatingReport: false,
  isDeletingReport: false,
} as ReportsState;

const reportsSlice = createSlice({
  initialState,
  name: 'reportsSlice',
  reducers: {
    fetchReportsRequest: state => {
      state.isFetchingReports = true;
    },
    fetchReportsSuccess: (state, action: PayloadAction<ReportModel[]>) => {
      state.isFetchingReports = false;
      state.reports = action.payload;
    },
    fetchReportsFailure: (state, _) => {
      state.isFetchingReports = false;
    },
    deleteReportFailure: (state, _) => {
      state.isDeletingReport = false;
    },
    deleteReportRequest: state => {
      state.isDeletingReport = true;
    },
    deleteReportSuccess: (state, action: PayloadAction<ReportModel['id']>) => {
      state.isDeletingReport = false;
      state.reports = state.reports.filter(
        report => report.id !== action.payload
      );
    },

    createReportRequest: state => {
      state.isCreatingReport = true;
    },
    createReportSuccess: (state, action: PayloadAction<ReportModel>) => {
      state.isCreatingReport = false;
      state.reports.push(action.payload);
    },
    createReportFailure: (state, _) => {
      state.isCreatingReport = false;
    },
    fetchReportRequest: state => {
      state.isFetchingReport = true;
    },
    fetchReportSuccess: (state, action: PayloadAction<ReportModel>) => {
      state.currentReport = action.payload;
      state.isFetchingReport = false;
    },
    fetchReportFailure: (state, _) => {
      state.isFetchingReport = false;
    },
    setReportsSearch: (state, action: PayloadAction<string>) => {
      state.reportsSearch = action.payload;
    },
    fetchReportDocumentRequest: state => {
      state.isFetchingReportDocument = true;
    },
    fetchReportDocumentSuccess: state => {
      state.isFetchingReportDocument = false;
    },
    fetchReportDocumentFailure: (state, _) => {
      state.isFetchingReportDocument = false;
    },
    cleanCurrentReport: state => {
      state.currentReport = null;
    },
  },
});

export const {
  fetchReportsRequest,
  fetchReportsFailure,
  fetchReportsSuccess,
  deleteReportFailure,
  deleteReportRequest,
  deleteReportSuccess,
  createReportFailure,
  createReportRequest,
  createReportSuccess,
  fetchReportRequest,
  fetchReportSuccess,
  fetchReportFailure,
  setReportsSearch,
  fetchReportDocumentRequest,
  fetchReportDocumentSuccess,
  fetchReportDocumentFailure,
  cleanCurrentReport,
} = reportsSlice.actions;

export default reportsSlice.reducer;
