import moment from 'moment';
import { api } from '../../api/api';
import { getApiErrorMessage } from '../../functions/getApiErrorMessage';
import {
  ChecklistChangedStatusPayloadStatusEnum,
  ChecklistCreatePayloadChecklistCheckableTypeEnum,
  ChecklistCreatePayloadChecklistRelatedToEnum,
  ChecklistCreatePayloadQuestions,
  ChecklistExtended,
  ChecklistItem,
  ChecklistTemplateExtended,
} from '../../swagger';
import { UNKNOWN_ERROR } from '../../types/constants';
import { VoidThunk } from '../../types/voidThunk';
import {
  checklistChangeStatusError,
  checklistChangeStatusRequest,
  checklistChangeStatusSuccess,
  fetchChecklistDetailsError,
  fetchChecklistDetailsRequest,
  fetchChecklistDetailsSuccess,
  fetchChecklistTemplateDetailsError,
  fetchChecklistTemplateDetailsRequest,
  fetchChecklistTemplateDetailsSuccess,
  fetchVendorChecklistsError,
  fetchVendorChecklistsRequest,
  fetchVendorChecklistsSuccess,
  fetchVendorChecklistTemplatesError,
  fetchVendorChecklistTemplatesRequest,
  fetchVendorChecklistTemplatesSuccess,
  generateChecklistFromTemplateError,
  generateChecklistFromTemplateRequest,
  generateChecklistFromTemplateSuccess,
  hideGenerateChecklistManagersModal,
  showGenerateChecklistSuccessToast,
  updateChecklistItemError,
  updateChecklistItemRequest,
  updateChecklistItemSuccess,
} from './checklistsSlice';

export const fetchVendorChecklists =
  (vendorId: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchVendorChecklistsRequest());
      const response = await api().vendorsIdChecklistsGet({ id: vendorId });
      dispatch(fetchVendorChecklistsSuccess(response as ChecklistExtended[]));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorChecklistsError(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorChecklistsError(
              'An error occurred fetching checklists. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorChecklistsError(UNKNOWN_ERROR));
      }
    }
  };

export const fetchChecklistDetails =
  ({ checklistId }: { checklistId: string }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchChecklistDetailsRequest());
      const response = await api().checklistsIdGet({ id: checklistId });
      dispatch(fetchChecklistDetailsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchChecklistDetailsError(apiErrorMessage));
        } else {
          dispatch(
            fetchChecklistDetailsError(
              'An error occurred fetching checklist details. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchChecklistDetailsError(UNKNOWN_ERROR));
      }
    }
  };

export const updateChecklistItem =
  ({
    checklistSurveyId,
    checklistId,
    questionGroupId,
    item,
  }: {
    checklistSurveyId: string;
    checklistId: string;
    questionGroupId: string;
    item: ChecklistItem;
  }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateChecklistItemRequest());
      const vendorId = getState().vendors.currentVendor?.id;

      await api().vendorsIdAnswersPost({
        id: vendorId,
        answers: {
          answers: [
            {
              surveyId: checklistSurveyId,
              questionId: item.questionId,
              value: item.answerValue?.toString(),
              notApplicable: item.answerNotApplicable,
              note: item.answerNote,
            },
          ],
        },
      });
      const currentUser = getState().user.userDetails;
      const currentUserName = currentUser.fullName;

      dispatch(
        updateChecklistItemSuccess({
          checklistId,
          questionGroupId,
          item: {
            ...item,
            answerUpdatedAt: moment().toISOString(),
            answerUserName: currentUserName,
          },
        })
      );
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(updateChecklistItemError(apiErrorMessage));
        } else {
          dispatch(
            updateChecklistItemError(
              'An error occurred updating the checklist item. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(updateChecklistItemError(UNKNOWN_ERROR));
      }
    }
  };

export enum GettingStartedChecklistItems {
  complete_profile = 'complete_profile',
  invite_colleagues_to_the_portal = 'invite_colleagues_to_the_portal',
  set_up_company_teams = 'set_up_company_teams',
  watch_policy_generator_video = 'watch_policy_generator_video',
  watch_policy_approval_management_video = 'watch_policy_approval_management_video',
  watch_policy_attestation_management_video_for_admins = 'watch_policy_attestation_management_video_for_admins',
  watch_policy_attestation_management_video_for_viewers = 'watch_policy_attestation_management_video_for_viewers',
}

export const completeGetStartedChecklistItem =
  (completedItem: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const getStartedList = getState().checklists.checklists.find(checklist =>
        checklist.name.includes('Getting Started')
      );
      const item = getStartedList?.itemsGroups[0]?.items?.find(
        (checklistItem: ChecklistItem) =>
          checklistItem.propertyName === completedItem
      );

      if (process.env.NODE_ENV === 'development')
        console.log(completedItem.toString());

      if (!item || item?.answerValue) {
        // return if already checked
        return;
      }

      if (item.answerId) {
        await api().answersIdPatch({
          id: item.answerId.toString(),
          answer: {
            value: 'true',
            surveyId: getStartedList.survey.id,
          },
        });
      } else {
        const vendorId = getState().vendors.currentVendor?.id;

        await api().vendorsIdAnswersPost({
          id: vendorId,
          answers: {
            answers: [
              {
                value: 'true',
                questionId: item.questionId,
                surveyId: getStartedList.survey.id,
              },
            ],
          },
        });
      }
      dispatch(fetchChecklistDetails({ checklistId: getStartedList.id }));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
      } else {
        console.log(err);
      }
    }
  };

export const fetchVendorChecklistTemplates =
  (vendorId: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchVendorChecklistTemplatesRequest());
      const response = await api().vendorsIdChecklistTemplatesGet({
        id: vendorId,
      });
      dispatch(
        fetchVendorChecklistTemplatesSuccess(
          response as ChecklistTemplateExtended[]
        )
      );
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorChecklistTemplatesError(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorChecklistTemplatesError(
              'An error occurred fetching checklist templates. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorChecklistTemplatesError(UNKNOWN_ERROR));
      }
    }
  };

export const fetchChecklistTemplateDetails =
  ({ checklistTemplateId }: { checklistTemplateId: string }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchChecklistTemplateDetailsRequest());
      const response = await api().checklistTemplatesIdGet({
        id: checklistTemplateId,
      });
      dispatch(fetchChecklistTemplateDetailsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchChecklistTemplateDetailsError(apiErrorMessage));
        } else {
          dispatch(
            fetchChecklistTemplateDetailsError(
              'An error occurred fetching checklist template details. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchChecklistTemplateDetailsError(UNKNOWN_ERROR));
      }
    }
  };

export const generateChecklistFromTemplate =
  (vendorId: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(generateChecklistFromTemplateRequest());
      const state = getState();
      const {
        checklistTemplateToGenerate,
        userIdToGenerate,
        managersToGenerate,
        messageToGenerate,
      } = state.checklists;

      const managers = managersToGenerate.map(managerId => ({
        vendorUserId: managerId,
        responsible: true,
      }));

      const template = await api().checklistTemplatesIdGet({
        id: checklistTemplateToGenerate.id,
      });

      const questions: ChecklistCreatePayloadQuestions[] = [];
      template.itemsGroups.forEach(group => {
        group.items.forEach(item => {
          const question: ChecklistCreatePayloadQuestions = {
            questionGroupId: group.questionGroupId,
            explanatoryText: item.explanatoryText,
            questionId: item.questionId,
            questionText: item.questionText,
          };
          questions.push(question);
        });
      });

      await api().vendorsIdChecklistsPost({
        id: vendorId,
        body: {
          checklist: {
            name: template.name,
            common: template.common,
            relatedTo:
              template.relatedTo as unknown as ChecklistCreatePayloadChecklistRelatedToEnum,
            checkableId: userIdToGenerate || undefined,
            checkableType: userIdToGenerate
              ? ChecklistCreatePayloadChecklistCheckableTypeEnum.VendorUser
              : undefined,
          },
          checklistUsers: managers,
          questions,
          message: messageToGenerate,
        },
      });
      dispatch(generateChecklistFromTemplateSuccess());
      dispatch(hideGenerateChecklistManagersModal());
      dispatch(showGenerateChecklistSuccessToast());
      dispatch(fetchVendorChecklists(vendorId));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(generateChecklistFromTemplateError(apiErrorMessage));
        } else {
          dispatch(
            generateChecklistFromTemplateError(
              'An error occurred fetching generating a new checklist. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(generateChecklistFromTemplateError(UNKNOWN_ERROR));
      }
    }
  };

export const updateChecklistStatus =
  ({
    id,
    status,
  }: {
    id: string;
    status: ChecklistChangedStatusPayloadStatusEnum;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(checklistChangeStatusRequest());
      const response = await api().checklistsIdChangeStatusPatch({
        id,
        body: {
          status,
        },
      });
      dispatch(checklistChangeStatusSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(checklistChangeStatusError(apiErrorMessage));
        } else {
          dispatch(
            checklistChangeStatusError(
              'An error occurred fetching checklists. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(checklistChangeStatusError(UNKNOWN_ERROR));
      }
    }
  };
