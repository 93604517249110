import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAgreementsTermsRadioOptions } from '../../../../hooks/agreements/useAgreementsTermsRadioOptions';
import { updateAgreementsCreateFormField } from '../../../../store/agreements/agreementsSlice';
import { AgreementModel, AgreementTemplateModel } from '../../../../swagger';
import { ApplicationState } from '../../../../types/applicationState';
import { AdoptechRadioButtonsGroup } from '../../../AdoptechRadioButtonsGroup/AdoptechRadioButtonsGroup';
import { useAgreementTemplateName } from '../NameStep/NameStep';

export type termsModel = AgreementModel | AgreementTemplateModel;

export const TermsStep: React.FC = () => {
  const form = useSelector(
    (state: ApplicationState) => state.agreements.agreementsCreateForm
  );

  const dispatch = useDispatch();

  const setSelectedTermsId = (selectedTermsId: termsModel['id']) =>
    dispatch(
      updateAgreementsCreateFormField({
        selectedTermsId,
      })
    );

  const { options } = useAgreementsTermsRadioOptions(form);

  const defaultName = useAgreementTemplateName(form);
  const baseCss = 'addNewAgreementForm';
  const values = useMemo(() => options.map(option => option.value), options);
  const labels = useMemo(() => options.map(option => option.label), options);

  return (
    <>
      <span className={baseCss + '--label'}>
        Use our standard terms or copy the terms from a previous {defaultName}.
      </span>
      <div className="mt-2">
        <AdoptechRadioButtonsGroup
          value={form.selectedTermsId ? form.selectedTermsId : values[0]}
          values={values}
          labels={labels}
          onChange={(value: string) => setSelectedTermsId(value)}
        />
      </div>
    </>
  );
};
