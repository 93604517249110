import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideResendAssessmentConfirmationModal } from '../../store/assessments/assessmentsSlice';
import { resendAssessment } from '../../store/assessments/assessmentsThunks';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import './ResendAssessmentConfirmationModal.scss';

export const ResendAssessmentConfirmationModal: React.FC = () => {
  const dispatch = useDispatch();
  const [optionalMessage, setOptionalMessage] = useState('');

  const {
    isResendingAssessment,
    isShowingResendAssessmentConfirmationModal,
    resendRecipient,
  } = useSelector((state: ApplicationState) => state.assessments);

  useEffect(() => {
    setOptionalMessage('');
  }, [isShowingResendAssessmentConfirmationModal]);

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOptionalMessage(e.currentTarget.value);
  };

  return (
    <AdoptechModal
      onHide={() => dispatch(hideResendAssessmentConfirmationModal())}
      show={isShowingResendAssessmentConfirmationModal}
    >
      <Modal.Header>Send a reminder</Modal.Header>
      <Modal.Body className="resendAssessmentConfirmationModal--body">
        <div className="resendAssessmentConfirmationModal--instructions">
          Click 'Send' below to send an email reminder to:
        </div>
        {resendRecipient?.vendorName && (
          <div className="resendAssessmentConfirmationModal--vendorName">
            {resendRecipient.vendorName}
          </div>
        )}
        <div className="resendAssessmentConfirmationModal--email">
          {resendRecipient?.email}
        </div>
        <div className="resendAssessmentConfirmationModal--label">
          Add optional message
        </div>
        <AdoptechTextArea
          id="resendAssessmentModal--optionalMessage"
          onChange={handleMessageChange}
          rows={4}
          value={optionalMessage}
        />
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={() => dispatch(hideResendAssessmentConfirmationModal())}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          busy={isResendingAssessment}
          onClick={() =>
            dispatch(resendAssessment({ message: optionalMessage }))
          }
          variant={AdoptechButtonVariant.Primary}
        >
          Send
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
