/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CardBillingDetails,
  CardBillingDetailsFromJSON,
  CardBillingDetailsFromJSONTyped,
  CardBillingDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface Card
 */
export interface Card {
  /**
   *
   * @type {string}
   * @memberof Card
   */
  brand?: string | null;
  /**
   *
   * @type {string}
   * @memberof Card
   */
  cardNo?: string;
  /**
   *
   * @type {string}
   * @memberof Card
   */
  expMonth?: string;
  /**
   *
   * @type {string}
   * @memberof Card
   */
  expYear?: string;
  /**
   *
   * @type {string}
   * @memberof Card
   */
  cvc?: string;
  /**
   *
   * @type {CardBillingDetails}
   * @memberof Card
   */
  billingDetails?: CardBillingDetails | null;
}

export function CardFromJSON(json: any): Card {
  return CardFromJSONTyped(json, false);
}

export function CardFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Card {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    brand: !exists(json, 'brand') ? undefined : json['brand'],
    cardNo: !exists(json, 'card_no') ? undefined : json['card_no'],
    expMonth: !exists(json, 'exp_month') ? undefined : json['exp_month'],
    expYear: !exists(json, 'exp_year') ? undefined : json['exp_year'],
    cvc: !exists(json, 'cvc') ? undefined : json['cvc'],
    billingDetails: !exists(json, 'billing_details')
      ? undefined
      : CardBillingDetailsFromJSON(json['billing_details']),
  };
}

export function CardToJSON(value?: Card | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    brand: value.brand,
    card_no: value.cardNo,
    exp_month: value.expMonth,
    exp_year: value.expYear,
    cvc: value.cvc,
    billing_details: CardBillingDetailsToJSON(value.billingDetails),
  };
}
