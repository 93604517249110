import { VendorSupplierExtended } from '../../swagger';
import React from 'react';
import { Accordion } from 'react-bootstrap';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';

interface DeleteSectionProps {
  formData: VendorSupplierExtended;
  dispatchDelete?(): void;
}

export const DeleteSection: React.FC<DeleteSectionProps> = ({
  formData,
  dispatchDelete,
}) => {
  const id = formData?.id || 'new';
  const baseCss = 'manageSupplierDrawer';

  if (id === 'new') return <></>;

  return (
    <Accordion>
      <AdoptechAccordionCard
        title="Delete vendor"
        index="0"
        cardBodyClass="card-body--centered withBorderBottom"
        headerClass="noBorderBottom positionInitial"
        iconSize="small"
        noMargin
      >
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <AdoptechButton
              variant={AdoptechButtonVariant.Warning}
              onClick={dispatchDelete}
            >
              Delete
            </AdoptechButton>
          </div>
        </div>
      </AdoptechAccordionCard>
    </Accordion>
  );
};
