/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChecklistTemplateUpdatePayload
 */
export interface ChecklistTemplateUpdatePayload {
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateUpdatePayload
   */
  name?: string;
}

export function ChecklistTemplateUpdatePayloadFromJSON(
  json: any
): ChecklistTemplateUpdatePayload {
  return ChecklistTemplateUpdatePayloadFromJSONTyped(json, false);
}

export function ChecklistTemplateUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistTemplateUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
  };
}

export function ChecklistTemplateUpdatePayloadToJSON(
  value?: ChecklistTemplateUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
  };
}
