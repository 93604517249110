import React from 'react';
import { AssertionModelTypeEnum } from '../../../../../../swagger';
import { ApplicationState } from '../../../../../../types/applicationState';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { EditTestFormMainSection } from './EditTestFormMainSection/EditTestFormMainSection';
import { useChildClass } from '../../../../../../hooks/useChildClass';
import { TestForm } from '../EditTestDrawer';
import { EditTestFormAutoSection } from './EditTestFormAutoSection/EditTestFormAutoSection';
import { EditTestFormLastResultsSection } from './EditTestFormLastResultsSection/EditTestFormLastResultsSection';

interface EditTestFormProps<T> {
  formData: T;
  onChange: (formData: T) => void;
}

export type EditTestFormSection = EditTestFormProps<TestForm>;

export const isTestAutomatic = (formData: EditTestFormSection['formData']) =>
  formData.type === AssertionModelTypeEnum.Automatic;
export const EditTestForm: React.FC<EditTestFormSection> = props => {
  const { fetchAssertionDetailsStatus } = useSelector(
    (state: ApplicationState) => state.compliance
  );

  const baseCss = 'editTestForm';
  const childClass = useChildClass(baseCss);

  if (fetchAssertionDetailsStatus === 'loading') return <LoadingSpinner />;

  return (
    <>
      <div className={baseCss}>
        <div className={childClass('fieldBlocks')}>
          <EditTestFormMainSection {...props} />
          <EditTestFormAutoSection {...props} />
          <EditTestFormLastResultsSection {...props} />
        </div>
      </div>
    </>
  );
};
