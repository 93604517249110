/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsFromJSONTyped,
  UserDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface EvidenceModel
 */
export interface EvidenceModel {
  /**
   *
   * @type {string}
   * @memberof EvidenceModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EvidenceModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof EvidenceModel
   */
  attachmentUrl?: string;
  /**
   *
   * @type {UserDetails}
   * @memberof EvidenceModel
   */
  provider: UserDetails;
  /**
   *
   * @type {string}
   * @memberof EvidenceModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof EvidenceModel
   */
  updatedAt: string;
}

export function EvidenceModelFromJSON(json: any): EvidenceModel {
  return EvidenceModelFromJSONTyped(json, false);
}

export function EvidenceModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EvidenceModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    description: json['description'],
    attachmentUrl: !exists(json, 'attachment_url')
      ? undefined
      : json['attachment_url'],
    provider: UserDetailsFromJSON(json['provider']),
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function EvidenceModelToJSON(value?: EvidenceModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    description: value.description,
    attachment_url: value.attachmentUrl,
    provider: UserDetailsToJSON(value.provider),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
