import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import {
  AttestationFrequencyEnum,
  VendorDocumentExtended,
} from '../../../swagger';
import { SelectionOption } from '../../../types/selectionOption';
import { AdoptechReactSelect2 } from '../../AdoptechReacSelect2/AdoptechReactSelect2';
import classNames from 'classnames';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  openAttestationManagement,
  updatePolicyToEditField,
} from '../../../store/policies/policiesSlice';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import {
  SentAttestationsTable,
  useSentAttestations,
} from '../../SentAttestationsTable/SentAttestationsTable';
import './PolicyReadRequestsSection.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { PolicyTeamsSelector } from '../PolicyTeamsSelector/PolicyTeamsSelector';
import { fetchVendorDocumentAttestations } from '../../../store/vendors/vendorsThunks';

export const PolicyReadRequestsSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const policy = useSelector(
    (state: ApplicationState) =>
      state.policies.policyToEdit as VendorDocumentExtended
  );
  const currentDocument = policy;

  useEffect(() => {
    if (!currentDocument?.id) return;
    dispatch(fetchVendorDocumentAttestations(currentDocument.id));
  }, [currentDocument?.id]);

  const baseCss = 'policyReadRequestsSection';

  const { attestationFrequency } = policy;

  const deprecatedValues = [
    AttestationFrequencyEnum.Annually,
    AttestationFrequencyEnum.Monthly,
    AttestationFrequencyEnum.Quarterly,
  ];
  const attestationFrequencyOptions = Object.values(
    AttestationFrequencyEnum
  ).map(value => {
    return { label: value, value };
  });
  const [
    selectedAttestationFrequencyOption,
    setSelectedAttestationFrequencyOption,
  ] = useState<SelectionOption>(
    attestationFrequencyOptions.find(
      option => option.value === attestationFrequency
    )
  );

  const { sentAttestations } = useSentAttestations(currentDocument);
  const { isFetchingVendorDocumentAttestations } = useSelector(
    (state: ApplicationState) => state.vendors.documentState
  );
  const {
    attestationStats: { attested },
  } = currentDocument;

  const total = (sentAttestations || []).length;

  const requestsHeader = `Shared with ${total} | Read by ${attested}`;
  const handleOpenOldRequestsModal = () =>
    dispatch(openAttestationManagement(policy));

  return (
    <>
      <div className={baseCss + '--section-description'}>
        When a new version of this policy is created, read requests will
        automatically be sent to all People in the selected Group(s) and new
        People added to the selected Group(s).
      </div>
      <PolicyTeamsSelector />
      <div
        className={classNames(baseCss + '--2-columns', 'mt-2')}
        title="Select how long users have to read the policy"
      >
        <AdoptechReactSelect2
          id="attestation-frequency"
          options={attestationFrequencyOptions.filter(
            option => !deprecatedValues.includes(option.value)
          )}
          onChange={(selectedOption: SelectionOption) => {
            setSelectedAttestationFrequencyOption(selectedOption);
            dispatch(
              updatePolicyToEditField({
                attestationFrequency: selectedOption.value,
              })
            );
          }}
          value={selectedAttestationFrequencyOption}
          placeholder="Select deadline"
          label="Set Read request deadline"
        />
      </div>

      {isFetchingVendorDocumentAttestations ? (
        <div className="mt-2">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {total > 0 && (
            <div
              className={baseCss + '--requestsHeader'}
              onClick={handleOpenOldRequestsModal}
            >
              {requestsHeader}
              <FontAwesomeIcon className="pl-2" icon={faExternalLink} />
            </div>
          )}
          <div className={baseCss + '--staffMembers'}>
            <SentAttestationsTable
              attestations={sentAttestations}
              tableClass="smallPadding"
            />
          </div>
        </>
      )}
    </>
  );
};
