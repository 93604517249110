import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getVendorProducts } from '../../store/vendors/vendorsThunks';
import { ApplicationState } from '../../types/applicationState';
import AddNewPolicy from '../../features/policies/AddNewPolicy/AddNewPolicy';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { UploadOwnPolicy } from '../../features/policies/UploadOwnPolicy/UploadOwnPolicy';
import './DocumentLibraryModal.scss';
import { push } from 'connected-react-router';
import { hideDocumentLibraryModal } from '../../store/policies/policiesSlice';
import { draftPoliciesPageRoute } from '../Routes/Routes';

const Tab = {
  policy: 'policy',
  own: 'own',
};

const DocumentLibraryModal = () => {
  const dispatch = useDispatch();
  const location = useSelector(
    (state: ApplicationState) => state.router.location
  );

  const [selectedTab, setSelectedTab] = useState(Tab.policy);
  const tabClasses = (tab: string) =>
    classNames('documentLibraryModal--tab', { selected: tab === selectedTab });

  useEffect(() => {
    if (location.pathname.includes('/policies')) {
      setSelectedTab(Tab.policy);
    }
  }, []);

  return (
    <AdoptechModal
      onHide={() => {
        if (location.pathname.includes('/policies')) {
          dispatch(push(draftPoliciesPageRoute));
        }
        dispatch(hideDocumentLibraryModal());
      }}
      show
      className="documentLibraryModal"
    >
      <Modal.Header>
        <div>Document library</div>
        <div className="documentLibraryModal--tabs">
          <div
            className={tabClasses(Tab.policy)}
            onClick={() => setSelectedTab(Tab.policy)}
          >
            Policies
          </div>
          <div
            className={tabClasses(Tab.own)}
            onClick={() => setSelectedTab(Tab.own)}
          >
            Upload my own
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {selectedTab === Tab.policy && <AddNewPolicy />}
        {selectedTab === Tab.own && <UploadOwnPolicy />}
      </Modal.Body>
    </AdoptechModal>
  );
};

export default DocumentLibraryModal;
