import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { SurveyClause } from '../../../swagger/models';

import './AgreementClauseHeader.scss';

interface AgreementClauseHeaderProps {
  agreementClause: SurveyClause;
  clauseProgress: number;
}

export const AgreementClauseHeader: React.FC<AgreementClauseHeaderProps> = ({
  agreementClause,
  clauseProgress,
}) => {
  const tickClass = clauseProgress === 1 ? 'greenTick' : 'greyTick';
  const icon = clauseProgress === 1 ? faCheckCircle : faCircle;

  return (
    <div className="agreementClauseHeader--title">
      <FontAwesomeIcon icon={icon} className={tickClass + ' mr-4'} />
      <div className="flex2 agreementClauseHeader--field">
        {agreementClause.title}
      </div>
    </div>
  );
};
