/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface QuestionColumn
 */
export interface QuestionColumn {
  /**
   *
   * @type {string}
   * @memberof QuestionColumn
   */
  columnText?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionColumn
   */
  columnValue?: string;
  /**
   *
   * @type {boolean}
   * @memberof QuestionColumn
   */
  primary?: boolean;
}

export function QuestionColumnFromJSON(json: any): QuestionColumn {
  return QuestionColumnFromJSONTyped(json, false);
}

export function QuestionColumnFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionColumn {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    columnText: !exists(json, 'column_text') ? undefined : json['column_text'],
    columnValue: !exists(json, 'column_value')
      ? undefined
      : json['column_value'],
    primary: !exists(json, 'primary') ? undefined : json['primary'],
  };
}

export function QuestionColumnToJSON(value?: QuestionColumn | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    column_text: value.columnText,
    column_value: value.columnValue,
    primary: value.primary,
  };
}
