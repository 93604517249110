/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ReviewHistoryItemCreatePayloadReviewHistoryItem,
  ReviewHistoryItemCreatePayloadReviewHistoryItemFromJSON,
  ReviewHistoryItemCreatePayloadReviewHistoryItemFromJSONTyped,
  ReviewHistoryItemCreatePayloadReviewHistoryItemToJSON,
} from './';

/**
 *
 * @export
 * @interface ReviewHistoryItemCreatePayload
 */
export interface ReviewHistoryItemCreatePayload {
  /**
   *
   * @type {ReviewHistoryItemCreatePayloadReviewHistoryItem}
   * @memberof ReviewHistoryItemCreatePayload
   */
  reviewHistoryItem: ReviewHistoryItemCreatePayloadReviewHistoryItem;
}

export function ReviewHistoryItemCreatePayloadFromJSON(
  json: any
): ReviewHistoryItemCreatePayload {
  return ReviewHistoryItemCreatePayloadFromJSONTyped(json, false);
}

export function ReviewHistoryItemCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReviewHistoryItemCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    reviewHistoryItem: ReviewHistoryItemCreatePayloadReviewHistoryItemFromJSON(
      json['review_history_item']
    ),
  };
}

export function ReviewHistoryItemCreatePayloadToJSON(
  value?: ReviewHistoryItemCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    review_history_item: ReviewHistoryItemCreatePayloadReviewHistoryItemToJSON(
      value.reviewHistoryItem
    ),
  };
}
