/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorTeamUpsertPayloadVendorTeams,
  VendorTeamUpsertPayloadVendorTeamsFromJSON,
  VendorTeamUpsertPayloadVendorTeamsFromJSONTyped,
  VendorTeamUpsertPayloadVendorTeamsToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorTeamUpsertPayload
 */
export interface VendorTeamUpsertPayload {
  /**
   *
   * @type {Array<VendorTeamUpsertPayloadVendorTeams>}
   * @memberof VendorTeamUpsertPayload
   */
  vendorTeams?: Array<VendorTeamUpsertPayloadVendorTeams>;
}

export function VendorTeamUpsertPayloadFromJSON(
  json: any
): VendorTeamUpsertPayload {
  return VendorTeamUpsertPayloadFromJSONTyped(json, false);
}

export function VendorTeamUpsertPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorTeamUpsertPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vendorTeams: !exists(json, 'vendor_teams')
      ? undefined
      : (json['vendor_teams'] as Array<any>).map(
          VendorTeamUpsertPayloadVendorTeamsFromJSON
        ),
  };
}

export function VendorTeamUpsertPayloadToJSON(
  value?: VendorTeamUpsertPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vendor_teams:
      value.vendorTeams === undefined
        ? undefined
        : (value.vendorTeams as Array<any>).map(
            VendorTeamUpsertPayloadVendorTeamsToJSON
          ),
  };
}
