/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ControlCategoryExtendedAllOf,
  ControlCategoryExtendedAllOfFromJSON,
  ControlCategoryExtendedAllOfFromJSONTyped,
  ControlCategoryExtendedAllOfToJSON,
  ControlCategoryModel,
  ControlCategoryModelFromJSON,
  ControlCategoryModelFromJSONTyped,
  ControlCategoryModelToJSON,
  ControlExtended,
  ControlExtendedFromJSON,
  ControlExtendedFromJSONTyped,
  ControlExtendedToJSON,
} from './';

/**
 *
 * @export
 * @interface ControlCategoryExtended
 */
export interface ControlCategoryExtended extends ControlCategoryModel {
  /**
   *
   * @type {Array<ControlExtended>}
   * @memberof ControlCategoryExtended
   */
  controls: Array<ControlExtended>;
}

export function ControlCategoryExtendedFromJSON(
  json: any
): ControlCategoryExtended {
  return ControlCategoryExtendedFromJSONTyped(json, false);
}

export function ControlCategoryExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlCategoryExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...ControlCategoryModelFromJSONTyped(json, ignoreDiscriminator),
    controls: (json['controls'] as Array<any>).map(ControlExtendedFromJSON),
  };
}

export function ControlCategoryExtendedToJSON(
  value?: ControlCategoryExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...ControlCategoryModelToJSON(value),
    controls: (value.controls as Array<any>).map(ControlExtendedToJSON),
  };
}
