import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmailList } from '../../functions/checkEmailList';
import {
  confirmSendAssessment,
  generateAndSendAssessment,
  hideSendAssessmentModal,
} from '../../store/assessments/assessmentsSlice';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import './SendAssessmentModal.scss';
import { SendAssessmentMode } from '../../store/assessments/assessmentsState';
import { generateAssessmentFromTemplate } from '../../store/assessments/assessmentsThunks';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { AdoptechDatePicker } from '../AdoptechDatePicker/AdoptechDatePicker';

export const SendAssessmentModal: React.FC = () => {
  const emailErrorMessage =
    'Please enter a list of valid email addresses, separated by commas, semi-colons or new lines only.';

  const toRef = useRef<HTMLTextAreaElement>();
  const dispatch = useDispatch();
  const currentVendor = useSelector(selectCurrentVendor);
  const clearSendAssessmentDetails = useSelector(
    (state: ApplicationState) => state.assessments.clearSendAssessmentDetails
  );
  const currentAssessment = useSelector(
    (state: ApplicationState) => state.assessments.currentAssessment
  );
  const currentAssessmentTemplate = useSelector(
    (state: ApplicationState) => state.assessments.currentAssessmentTemplate
  );
  const generateAssessmentName = useSelector(
    (state: ApplicationState) => state.assessments.generateAssessmentName
  );
  const sendAssessmentMode = useSelector(
    (state: ApplicationState) => state.assessments.sendAssessmentMode
  );

  const [errorMessage, setErrorMessage] = useState('');
  const [optionalMessage, setOptionalMessage] = useState('');
  const [to, setTo] = useState('');
  const [toError, setToError] = useState(false);
  const [timeoutHandle, setTimeoutHandle] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<Date>(undefined);

  useEffect(() => {
    if (!clearSendAssessmentDetails) {
      return;
    }

    setErrorMessage('');
    setOptionalMessage('');
    setTo('');
    setToError(false);
  }, [clearSendAssessmentDetails]);

  const toValidate = (sendTo: string) => {
    clearTimeout(timeoutHandle);
    setTimeoutHandle(
      setTimeout(async () => {
        const emails = await checkEmailList(sendTo);
        setToError(emails.invalid.length > 0);
        if (emails.invalid.length > 0) {
          setErrorMessage(emailErrorMessage);
        } else {
          setErrorMessage('');
        }
      }, 1000)
    );
  };

  const getDateString = (date: Date) => {
    return date ? new Intl.DateTimeFormat().format(date) : undefined;
  };

  const handleSend = async () => {
    if (to === '') {
      setErrorMessage(emailErrorMessage);
      return;
    }

    const emails = await checkEmailList(to);

    if (emails.invalid.length === 0) {
      setErrorMessage('');
      if (sendAssessmentMode === SendAssessmentMode.Generate) {
        dispatch(
          generateAndSendAssessment({
            count: emails.addresses.length,
            id: currentAssessmentTemplate.id,
            optionalMessage,
            responseDeadline: getDateString(selectedDate),
            to,
          })
        );
        dispatch(
          generateAssessmentFromTemplate(
            currentVendor.id,
            currentAssessmentTemplate.id,
            generateAssessmentName
          )
        );
      } else {
        dispatch(
          confirmSendAssessment({
            count: emails.addresses.length,
            id: currentAssessment.id,
            optionalMessage,
            responseDeadline: getDateString(selectedDate),
            to,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (toRef.current) {
      toRef.current.focus();
    }
  }, []);

  return (
    <React.Fragment>
      <AdoptechModal
        className="sendAssessmentModal"
        onHide={() => dispatch(hideSendAssessmentModal())}
        show
      >
        <Modal.Header>
          {sendAssessmentMode === SendAssessmentMode.Generate
            ? 'Generate'
            : 'Send'}{' '}
          assessment
        </Modal.Header>
        <Modal.Body className="sendAssessmentModal--body">
          <div className="sendAssessmentModal--instructions">
            Add email addresses below to send out this assessment (one email
            recipient per company). Recipients will be sent an invitation to
            complete the assessment.
          </div>
          {errorMessage !== '' && (
            <div className="sendAssessmentModal--errorMessage">
              {errorMessage}
            </div>
          )}
          <div className="sendAssessmentModal--fields">
            <div className="sendAssessmentModal--label">
              Add recipients (separated by comma)
            </div>
            <AdoptechTextArea
              hasError={toError}
              id="sendAssessmentModal--to"
              onChange={e => {
                setTo(e.currentTarget.value);
                toValidate(e.currentTarget.value);
              }}
              ref={toRef}
              rows={4}
              value={to}
            />
            <div className="sendAssessmentModal--label">
              Add message (optional)
            </div>
            <AdoptechTextArea
              id="sendAssessmentModal--optionalMessage"
              onChange={e => setOptionalMessage(e.currentTarget.value)}
              rows={4}
              value={optionalMessage}
            />
            <AdoptechDatePicker
              outputDate={selectedDate}
              onDateSelect={date => setSelectedDate(date)}
              labelStyle="sendAssessmentModal--label"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={() => dispatch(hideSendAssessmentModal())}>
            Cancel
          </AdoptechButton>
          <AdoptechButton
            disabled={errorMessage !== '' || String(to).length <= 0}
            onClick={() => handleSend()}
            variant={AdoptechButtonVariant.Primary}
          >
            SEND
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
