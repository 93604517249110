import React from 'react';
import { ReportForm, ReportSectionProps } from './ReportsManageDrawer';
import { AdoptechDatePicker2 } from '../../AdoptechDatePicker2/AdoptechDatePicker2';
import { getDate } from '../../../functions/getDate';

export const ReportCompletionField: React.FC<ReportSectionProps> = ({
  formData,
  updateField,
  updateForm,
}) => {
  const hasError = (field: keyof ReportForm) => !formData[field];
  const baseCss = 'reportsManageDrawer';

  return (
    <div className={baseCss + '--fieldRow'}>
      <div className={baseCss + '--label'}>
        Select the date the report was completed.
      </div>
      <div className={baseCss + '--field'}>
        <AdoptechDatePicker2
          hasError={hasError('completedAt')}
          id={`${baseCss}CompletedAt`}
          labelText="Date"
          labelStyle={baseCss + '--date-label'}
          minDate={null}
          deadLines={[]}
          outputDate={getDate(formData.completedAt)}
          onDateSelect={(value: Date) => {
            updateField('completedAt', value.toISOString());
          }}
          placeHolderText="Select date"
        />
      </div>
    </div>
  );
};
