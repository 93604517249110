import React from 'react';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { CommandConfirmation } from '../../types/CommandConfirmation';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './ConfirmationModal.scss';

const baseCss = 'confirmationModal';

export const ConfirmationModal: React.FC<{
  command?: CommandConfirmation;
  // If click outside modal or Cancel button => onCancel will be fired with confirmed false
  // if click save inside modal => onCancel also will be fired with confirmed true
  onCancel(confirmed: boolean): void;
}> = ({ command, onCancel }) => {
  const { showCancelButton = true } = command || {};
  let description = `Are you sure you would like to proceed`;

  if (command?.description) {
    description = command.description;
  } else if (command?.action) {
    description = `Are you sure you would like to ${command.action.toLowerCase()}`;
    if (command?.subject)
      description += ` this ${command.subject.type.toLowerCase()}`;
    description += '?';
  }

  const hasSubject = !!command?.subject;

  const title =
    command?.title ||
    (command?.subject && `${command.action} ${command.subject.type}`) ||
    'Confirmation';

  const variant = command
    ? command.buttonVariant
    : AdoptechButtonVariant.Primary;

  return (
    <AdoptechModal
      className={baseCss}
      onHide={() => onCancel(false)}
      show={!!command}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <div className={baseCss + '--body'}>
          <div className={baseCss + '--description'}>{description}</div>
          {command?.subject && (
            <textarea className={baseCss + '--subject'} disabled>
              {command.subject.name}
            </textarea>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={() => onCancel(false)}
          extraClass={showCancelButton ? '' : 'd-none'}
        >
          {command?.cancelButtonText || 'Cancel'}
        </AdoptechButton>
        <AdoptechButton
          onClick={() => {
            command.onConfirm && command.onConfirm();
            onCancel(true);
          }}
          variant={variant}
        >
          {command?.buttonText || command?.action || 'Confirm'}
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
