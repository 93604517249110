/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsFromJSONTyped,
  UserDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface SharesListData
 */
export interface SharesListData {
  /**
   *
   * @type {UserDetails}
   * @memberof SharesListData
   */
  user?: UserDetails;
  /**
   *
   * @type {UserDetails}
   * @memberof SharesListData
   */
  sharedBy?: UserDetails;
  /**
   *
   * @type {string}
   * @memberof SharesListData
   */
  sharedAt?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SharesListData
   */
  directShare?: boolean;
  /**
   *
   * @type {string}
   * @memberof SharesListData
   */
  accessLevel?: SharesListDataAccessLevelEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof SharesListData
   */
  possibleAccessLevels?: Array<SharesListDataPossibleAccessLevelsEnum>;
}

export function SharesListDataFromJSON(json: any): SharesListData {
  return SharesListDataFromJSONTyped(json, false);
}

export function SharesListDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SharesListData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    user: !exists(json, 'user') ? undefined : UserDetailsFromJSON(json['user']),
    sharedBy: !exists(json, 'shared_by')
      ? undefined
      : UserDetailsFromJSON(json['shared_by']),
    sharedAt: !exists(json, 'shared_at') ? undefined : json['shared_at'],
    directShare: !exists(json, 'direct_share')
      ? undefined
      : json['direct_share'],
    accessLevel: !exists(json, 'access_level')
      ? undefined
      : json['access_level'],
    possibleAccessLevels: !exists(json, 'possible_access_levels')
      ? undefined
      : json['possible_access_levels'],
  };
}

export function SharesListDataToJSON(value?: SharesListData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user: UserDetailsToJSON(value.user),
    shared_by: UserDetailsToJSON(value.sharedBy),
    shared_at: value.sharedAt,
    direct_share: value.directShare,
    access_level: value.accessLevel,
    possible_access_levels: value.possibleAccessLevels,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum SharesListDataAccessLevelEnum {
  Viewer = 'viewer',
  Contributor = 'contributor',
  Manager = 'manager',
}
/**
 * @export
 * @enum {string}
 */
export enum SharesListDataPossibleAccessLevelsEnum {
  Viewer = 'viewer',
  Contributor = 'contributor',
  Manager = 'manager',
}
