import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postLegislation } from '../../../store/legalRegister/legalRegisterThunks';
import {
  LegislationClassificationModel,
  LegislationCreatePayloadLegislation,
  SharedLegislationRecurring,
} from '../../../swagger';
import { OwnLegislationForm, validation } from './AddOwnLegislation';
import { useAfterLegislationFormSubmit } from './useAddLegislationFormHandler';
import { useJurisdictionsList } from './useJurisdictionsList';

export const useAddOwnLegislationHandler = (props: { onClose: () => void }) => {
  const dispatch = useDispatch();

  const afterSubmit = useAfterLegislationFormSubmit(props.onClose);

  const { allJurisdictions, allJurisdictionOptions } = useJurisdictionsList();
  const [classifications, setClassifications] =
    useState<LegislationClassificationModel[]>(null);

  const [formData, setFormData] = useState<OwnLegislationForm>({});

  const hasError = (field: string) => !!validation(formData)[field];

  const classificationOptions = () =>
    (classifications || []).map(c => ({
      label: c.name,
      value: c.id,
    }));

  const formValidation = validation(formData);
  const formValid = Object.values(formValidation).some(value => value != null);

  const onSubmit = async () => {
    const {
      name,
      legislationClassificationId,
      summary,
      jurisdictionId,
      descriptionUrl,
      description,
    } = formData;
    const payload: Required<LegislationCreatePayloadLegislation> = {
      name,
      legislationClassificationId,
      summary,
      jurisdictionId,
      descriptionUrl,
      description,
      explanationOfCompliance: null,
      recurring: SharedLegislationRecurring.Annually,
    };
    await dispatch(postLegislation(payload));
    afterSubmit();
  };
  return {
    formData,
    setFormData,
    classificationOptions,
    allJurisdictionOptions,
    hasError,
    allJurisdictions,
    formValid,
    setClassifications,
    onSubmit,
  };
};
