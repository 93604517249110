/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AgreementVersionCreatePayload
 */
export interface AgreementVersionCreatePayload {
  /**
   *
   * @type {string}
   * @memberof AgreementVersionCreatePayload
   */
  changeReason?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementVersionCreatePayload
   */
  versionBump: AgreementVersionCreatePayloadVersionBumpEnum;
}

export function AgreementVersionCreatePayloadFromJSON(
  json: any
): AgreementVersionCreatePayload {
  return AgreementVersionCreatePayloadFromJSONTyped(json, false);
}

export function AgreementVersionCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementVersionCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    changeReason: !exists(json, 'change_reason')
      ? undefined
      : json['change_reason'],
    versionBump: json['version_bump'],
  };
}

export function AgreementVersionCreatePayloadToJSON(
  value?: AgreementVersionCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    change_reason: value.changeReason,
    version_bump: value.versionBump,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AgreementVersionCreatePayloadVersionBumpEnum {
  Minor = 'minor',
  Major = 'major',
}
