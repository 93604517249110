/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Answer,
  AnswerFromJSON,
  AnswerFromJSONTyped,
  AnswerToJSON,
  Question,
  QuestionFromJSON,
  QuestionFromJSONTyped,
  QuestionToJSON,
} from './';

/**
 *
 * @export
 * @interface QuestionTable
 */
export interface QuestionTable {
  /**
   *
   * @type {Array<Question>}
   * @memberof QuestionTable
   */
  questions?: Array<Question>;
  /**
   *
   * @type {Array<Array<Answer>>}
   * @memberof QuestionTable
   */
  answers?: Array<Array<Answer>>;
}

export function QuestionTableFromJSON(json: any): QuestionTable {
  return QuestionTableFromJSONTyped(json, false);
}

export function QuestionTableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionTable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    questions: !exists(json, 'questions')
      ? undefined
      : (json['questions'] as Array<any>).map(QuestionFromJSON),
    answers: !exists(json, 'answers') ? undefined : json['answers'],
  };
}

export function QuestionTableToJSON(value?: QuestionTable | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    questions:
      value.questions === undefined
        ? undefined
        : (value.questions as Array<any>).map(QuestionToJSON),
    answers: value.answers,
  };
}
