import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { hideInvitationSentModal } from '../../store/vendors/vendorsSlice';
import { ApplicationState } from '../../types/applicationState';
import { AdoptechCheckbox } from '../AdoptechCheckbox/AdoptechCheckbox';
import './InvitationSentModal.scss';

export const InvitationSentModal = () => {
  const dispatch = useDispatch();
  const { isInvitationSentModalShowing } = useSelector(
    (state: ApplicationState) => state.vendors.inviteState
  );

  const [cookies, setCookie] = useCookies(['invitationSentMessageNoShow']);
  const [doNotShow, setDoNotShow] = useState(false);
  const show = () =>
    isInvitationSentModalShowing &&
    cookies.invitationSentMessageNoShow !== 'true';

  const handleDoNotShowChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDoNotShow(e.target.checked);
    setCookie('invitationSentMessageNoShow', e.target.checked, {
      expires: moment().add(10, 'years').toDate(),
    });
  };

  useEffect(() => {
    if (!show()) {
      return;
    }

    setTimeout(() => {
      dispatch(hideInvitationSentModal());
    }, +process.env.REACT_APP_LONG_TOAST_DELAY);
  }, [isInvitationSentModalShowing]);

  return (
    <Modal centered className="invitationSentModal" show={show()}>
      <Modal.Header>
        <div
          className="invitationSentModal--cross"
          onClick={() => {
            dispatch(hideInvitationSentModal());
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>The invitation has been sent by email.</p>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechCheckbox
          checked={doNotShow}
          id="doNotShow"
          label="Do not show this message again"
          onChange={handleDoNotShowChange}
        />
      </Modal.Footer>
    </Modal>
  );
};
