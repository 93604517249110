import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { AdoptechCheckbox } from '../AdoptechCheckbox/AdoptechCheckbox';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './CustomizeClauseWarningModal.scss';

const HIDE_WARNING_COOKIE = 'hideCustomizeClauseWarning';

export interface CustomizeClauseWarningModal {
  toggleClicked: boolean;
  hide: () => void;
  onOk: () => void;
}

const CustomizeClauseWarningModal = ({
  toggleClicked,
  hide,
  onOk,
}: CustomizeClauseWarningModal) => {
  const [cookies, setCookie] = useCookies([HIDE_WARNING_COOKIE]);
  const [doNotShowCheckboxValue, setDoNotShowCheckboxValue] = useState(false);
  const shouldShowModal = toggleClicked && !cookies[HIDE_WARNING_COOKIE];

  const handleDoNotShowChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDoNotShowCheckboxValue(e.target.checked);
  };

  useEffect(() => {
    if (toggleClicked && !shouldShowModal) {
      onOk();
    }
  }, [toggleClicked]);

  const handleOkClick = () => {
    if (doNotShowCheckboxValue) {
      setCookie(HIDE_WARNING_COOKIE, true, {
        expires: moment().add(10, 'years').toDate(),
      });
    }
    onOk();
  };

  return (
    <AdoptechModal
      onHide={hide}
      show={shouldShowModal}
      className="customizeClauseWarningModal"
    >
      <Modal.Header>Warning</Modal.Header>
      <Modal.Body>
        <div className="customizeClauseWarningModal--subtitle">
          Your changes will be lost and you will revert to the standard
          generated text.
        </div>
        <AdoptechCheckbox
          checked={doNotShowCheckboxValue}
          id="doNotShowCustomizeClause"
          label="Do not show this warning again"
          onChange={handleDoNotShowChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={hide}>Cancel</AdoptechButton>
        <AdoptechButton
          variant={AdoptechButtonVariant.Primary}
          onClick={handleOkClick}
        >
          Ok
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};

export default CustomizeClauseWarningModal;
