import ReactGA from 'react-ga4';
import { UserDetails } from '../src/swagger';

export const googleAnalyticsInit = () => {
  if (process.env.NODE_ENV !== 'development') {
    ReactGA.initialize(process.env.REACT_APP_GA_PROPERTY_ID);
    ReactGA.send('pageview');
  }
};

export const setGoogleAnalyticsUser = (user: UserDetails) => {
  if (process.env.NODE_ENV !== 'development') {
    ReactGA.set({ userId: user.email, userFullName: user.fullName });
  }
};
