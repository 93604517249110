/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubCompanyModel,
  TrusthubCompanyModelFromJSON,
  TrusthubCompanyModelFromJSONTyped,
  TrusthubCompanyModelToJSON,
  TrusthubVendorUserExtendedAllOf,
  TrusthubVendorUserExtendedAllOfFromJSON,
  TrusthubVendorUserExtendedAllOfFromJSONTyped,
  TrusthubVendorUserExtendedAllOfToJSON,
  TrusthubVendorUserModel,
  TrusthubVendorUserModelFromJSON,
  TrusthubVendorUserModelFromJSONTyped,
  TrusthubVendorUserModelToJSON,
  TrusthubVendorUserStatus,
  TrusthubVendorUserStatusFromJSON,
  TrusthubVendorUserStatusFromJSONTyped,
  TrusthubVendorUserStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubVendorUserExtended
 */
export interface TrusthubVendorUserExtended extends TrusthubVendorUserModel {
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserExtended
   */
  currentSignInAt?: string | null;
}

export function TrusthubVendorUserExtendedFromJSON(
  json: any
): TrusthubVendorUserExtended {
  return TrusthubVendorUserExtendedFromJSONTyped(json, false);
}

export function TrusthubVendorUserExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubVendorUserExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...TrusthubVendorUserModelFromJSONTyped(json, ignoreDiscriminator),
    currentSignInAt: !exists(json, 'current_sign_in_at')
      ? undefined
      : json['current_sign_in_at'],
  };
}

export function TrusthubVendorUserExtendedToJSON(
  value?: TrusthubVendorUserExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...TrusthubVendorUserModelToJSON(value),
    current_sign_in_at: value.currentSignInAt,
  };
}
