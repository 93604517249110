import './AuditsReviewHistoryDropdown.scss';
import React, { useEffect } from 'react';
import { ApplicationState } from '../../../types/applicationState';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatLongDate } from '../../../functions/formatLongDate';
import {
  cleanupAuditReviewHistory,
  setAuditQuestionTableAnswer,
} from './store/auditsSlice';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { fetchAuditReviewHistory } from './store/auditsThunks';
import { useDispatch, useSelector } from 'react-redux';
import {
  AuditExtended,
  AuditReviewHistoryItem,
  Question,
} from '../../../swagger';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { NoDataText } from '../../../components/NoDataText/NoDataText';

interface ItemProps {
  item: AuditReviewHistoryItem;
  onClick(): void;
}

interface Props {
  auditId: AuditExtended['id'];
  questionId: Question['id'];
  onClick(): void;
}

const baseCss = 'auditsReviewHistoryDropdown';

const HistoryItem: React.FC<ItemProps> = ({ item, onClick }) => {
  return (
    <Dropdown.Item className={baseCss + '--item'} onClick={onClick}>
      <div className={baseCss + '--date'}>{formatLongDate(item.updatedAt)}</div>
      <div className={baseCss + '--result'}>
        <div className={baseCss + '--auditor-name'}>
          <strong>Auditor: </strong>
          {item.auditorName}
        </div>
        <div className={baseCss + '--auditor-rating'}>
          <strong>Auditor rating: </strong>
          {item.rating}
        </div>
      </div>
      <div className={baseCss + '--comments'}>
        <div className={baseCss + '--comments-title'}>Auditor comment</div>
        {item.comments}
      </div>
    </Dropdown.Item>
  );
};

export const AuditsReviewHistoryDropdown: React.FC<Props> = ({
  auditId,
  questionId,
  onClick,
}) => {
  const dispatch = useDispatch();
  const history = useSelector(
    (state: ApplicationState) =>
      state.audits.auditReviewHistory.find(h => h.questionId === questionId)
        ?.items || []
  );

  const { isFetchingAuditReviewHistory } = useSelector(
    (state: ApplicationState) => state.audits
  );

  useEffect(() => {
    dispatch(cleanupAuditReviewHistory(questionId));
  }, []);

  const currentVendorUser = useSelector(selectCurrentVendorUser);

  const setReview = (review: AuditReviewHistoryItem) => () => {
    dispatch(
      setAuditQuestionTableAnswer({
        userId: currentVendorUser.id,
        questionId,
        review: review,
      })
    );
    onClick();
  };

  return (
    <Dropdown
      className={baseCss + ' mr-2 adoptechButtonContainer'}
      onToggle={visible => {
        if (visible) dispatch(fetchAuditReviewHistory(auditId, questionId));
      }}
    >
      <Dropdown.Toggle
        className={classNames(baseCss + '--button', 'adoptechButton')}
      >
        <FontAwesomeIcon className="mr-2" icon={faEye} />
        History
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {isFetchingAuditReviewHistory && <LoadingSpinner />}
        {!isFetchingAuditReviewHistory && history.length === 0 && (
          <div className={baseCss + '--noData'}>
            There is no audit history for this clause/control.{' '}
          </div>
        )}
        {history.length > 0 && (
          <>
            <div className={baseCss + '--title'}>Audit history</div>
            {history.map(item => (
              <HistoryItem
                key={item.id}
                item={item}
                onClick={setReview(item)}
              />
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
