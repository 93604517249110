/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  LegislationUpdatePayloadLegislationReviews,
  LegislationUpdatePayloadLegislationReviewsFromJSON,
  LegislationUpdatePayloadLegislationReviewsFromJSONTyped,
  LegislationUpdatePayloadLegislationReviewsToJSON,
  SharedLegislationRecurring,
  SharedLegislationRecurringFromJSON,
  SharedLegislationRecurringFromJSONTyped,
  SharedLegislationRecurringToJSON,
} from './';

/**
 *
 * @export
 * @interface LegislationUpdatePayload
 */
export interface LegislationUpdatePayload {
  /**
   *
   * @type {string}
   * @memberof LegislationUpdatePayload
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof LegislationUpdatePayload
   */
  legislationClassificationId?: string;
  /**
   *
   * @type {string}
   * @memberof LegislationUpdatePayload
   */
  summary?: string;
  /**
   *
   * @type {string}
   * @memberof LegislationUpdatePayload
   */
  jurisdictionId?: string;
  /**
   *
   * @type {string}
   * @memberof LegislationUpdatePayload
   */
  descriptionUrl?: string | null;
  /**
   *
   * @type {SharedLegislationRecurring}
   * @memberof LegislationUpdatePayload
   */
  recurring?: SharedLegislationRecurring;
  /**
   *
   * @type {string}
   * @memberof LegislationUpdatePayload
   */
  explanationOfCompliance?: string | null;
  /**
   *
   * @type {Array<LegislationUpdatePayloadLegislationReviews>}
   * @memberof LegislationUpdatePayload
   */
  legislationReviews?: Array<LegislationUpdatePayloadLegislationReviews>;
  /**
   *
   * @type {string}
   * @memberof LegislationUpdatePayload
   */
  description?: string;
}

export function LegislationUpdatePayloadFromJSON(
  json: any
): LegislationUpdatePayload {
  return LegislationUpdatePayloadFromJSONTyped(json, false);
}

export function LegislationUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LegislationUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    legislationClassificationId: !exists(json, 'legislation_classification_id')
      ? undefined
      : json['legislation_classification_id'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    jurisdictionId: !exists(json, 'jurisdiction_id')
      ? undefined
      : json['jurisdiction_id'],
    descriptionUrl: !exists(json, 'description_url')
      ? undefined
      : json['description_url'],
    recurring: !exists(json, 'recurring')
      ? undefined
      : SharedLegislationRecurringFromJSON(json['recurring']),
    explanationOfCompliance: !exists(json, 'explanation_of_compliance')
      ? undefined
      : json['explanation_of_compliance'],
    legislationReviews: !exists(json, 'legislation_reviews')
      ? undefined
      : (json['legislation_reviews'] as Array<any>).map(
          LegislationUpdatePayloadLegislationReviewsFromJSON
        ),
    description: !exists(json, 'description') ? undefined : json['description'],
  };
}

export function LegislationUpdatePayloadToJSON(
  value?: LegislationUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    legislation_classification_id: value.legislationClassificationId,
    summary: value.summary,
    jurisdiction_id: value.jurisdictionId,
    description_url: value.descriptionUrl,
    recurring: SharedLegislationRecurringToJSON(value.recurring),
    explanation_of_compliance: value.explanationOfCompliance,
    legislation_reviews:
      value.legislationReviews === undefined
        ? undefined
        : (value.legislationReviews as Array<any>).map(
            LegislationUpdatePayloadLegislationReviewsToJSON
          ),
    description: value.description,
  };
}
