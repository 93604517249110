import React, { useEffect, useState } from 'react';
import { isValidEmailAddress } from '../../functions/isValidEmailAddress';
import {
  Question,
  QuestionDatatypeEnum,
  QuestionLayoutEnum,
} from '../../swagger';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import './TextBasedAnswer.scss';
import { CommonAnswerType } from '../../types/CommonAnswerType';
import { usePrevious } from 'react-use';

interface TextBasedAnswerProps {
  previousAnswer?: CommonAnswerType;
  disabled?: boolean;
  readonly?: boolean;
  onChange: (value: CommonAnswerType[]) => void;
  question: Question;
  indexProperty: 'name' | 'propertyName';
  disableDebounce?: boolean;
  responseId?: string;
}

export const TextBasedAnswer: React.FC<TextBasedAnswerProps> = props => {
  const [value, setValue] = useState(props.previousAnswer?.value || '');

  const previousPreviousAnswer = usePrevious(props.previousAnswer);

  useEffect(
    () => setValue(props.previousAnswer?.value),
    [props.previousAnswer?.propertyName]
  );

  useEffect(() => {
    if (!props.readonly && previousPreviousAnswer !== undefined) return;
    if (props.previousAnswer === undefined) return;
    setValue(props.previousAnswer.value);
  }, [props.previousAnswer]);

  const pattern =
    props.question.datatype === QuestionDatatypeEnum.Integer
      ? /[^0123456789]/
      : /[^-0123456789.]/;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      props.question.datatype === QuestionDatatypeEnum.String
        ? e.currentTarget.value
        : e.currentTarget.value.replace(pattern, '');

    if (newValue === value) {
      return;
    }

    setValue(newValue);
  };

  const handleBlur = () => {
    if (
      props.question.layout === QuestionLayoutEnum.EmailField &&
      !isValidEmailAddress(value)
    ) {
      return;
    }

    if (!value && !props.previousAnswer) return;

    if (props.previousAnswer && value === props.previousAnswer.value) return;

    props.onChange([
      {
        [props.indexProperty]: props.question[props.indexProperty],
        value: value,
        closestQuestion: {
          id: props.question.id,
          layout: props.question.layout,
        },
      },
    ]);
  };

  return (
    <div className="textBasedAnswer">
      <AdoptechTextInput
        id={props.responseId + props.question.id}
        disabled={props.disabled || props.readonly}
        type={
          props.question.layout === QuestionLayoutEnum.EmailField
            ? 'email'
            : 'text'
        }
        onBlur={handleBlur}
        onChange={handleChange}
        hasError={
          props.question.layout === QuestionLayoutEnum.EmailField &&
          isValidEmailAddress(value) !== true
        }
        value={value ? value : ''}
      />
    </div>
  );
};
