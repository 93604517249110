import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faTimesSquare } from '@fortawesome/pro-light-svg-icons/faTimesSquare';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { mapShareFeaturesToAccessLevels } from '../../functions/access';
import { getChecklistProgress } from '../../functions/getChecklistProgress';
import { formatShortDate } from '../../functions/formatShortDate';
import { getChecklistAssociatedUsername } from '../../functions/getChecklistAssociatedUsername';
import { selectActiveChecklists } from '../../selectors/selectActiveChecklists';
import { updateChecklistStatus } from '../../store/checklists/checklistsThunks';
import { showShareModal } from '../../store/vendors/vendorsSlice';
import {
  ChecklistChangedStatusPayloadStatusEnum,
  ChecklistExtended,
  ShareableType,
  VendorDocument,
} from '../../swagger';
import { Grid } from '../../types/grid';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { GenericModal } from '../GenericModal/GenericModal';
import { Lozenge } from '../Lozenge/Lozenge';
import { MeatballMenu } from '../MeatballMenu/MeatballMenu';
import { NoDataText } from '../NoDataText/NoDataText';
import { SortableTableHeader } from '../SortableTableHeader/SortableTableHeader';
import './ActiveChecklists.scss';

export const ActiveChecklists: React.FC = () => {
  const dispatch = useDispatch();

  const [showCloseAsIncompletePopup, setShowCloseAsIncompletePopup] =
    useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState('');

  const activeChecklists = useSelector(selectActiveChecklists);

  return (
    <div className="activeChecklists">
      <div className="activeChecklists--header">
        <div className="activeChecklists--title">
          Active
          <Lozenge value={activeChecklists.length} />
        </div>
      </div>
      {activeChecklists.length > 0 ? (
        <div className="activeChecklists--table">
          <AdoptechTable hasMenu>
            <thead>
              <tr>
                <SortableTableHeader<VendorDocument>
                  columnName="name"
                  grid={Grid.ActiveChecklists}
                  label="Name"
                />
                <th className="activeChecklists--table--cell--username">
                  Username
                </th>
                <th className="activeChecklists--table--cell">Progress</th>
                <th className="activeChecklists--table--cell">Last updated</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {activeChecklists.map((checklist: ChecklistExtended) => {
                return (
                  <tr
                    className="activeChecklists--row"
                    key={checklist.id}
                    onClick={() =>
                      dispatch(
                        push(`/r/companyInformation/checklists/${checklist.id}`)
                      )
                    }
                  >
                    <td>{checklist.name}</td>
                    <td>
                      {getChecklistAssociatedUsername(checklist.checkable)}
                    </td>
                    <td>{getChecklistProgress(checklist.itemsGroups)}</td>
                    <td>{formatShortDate(checklist.updatedAt)}</td>
                    <td>
                      <div className="adoptechTable--actions">
                        <MeatballMenu>
                          <Dropdown.Item
                            onClick={e => {
                              e.stopPropagation();
                              dispatch(
                                push(
                                  `/r/companyInformation/checklists/${checklist.id}`
                                )
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              className="meatballMenu--icon"
                              icon={faPen}
                            />
                            Fill out
                          </Dropdown.Item>
                          {checklist.access.share && (
                            <Dropdown.Item
                              className="meatballMenu--item"
                              onClick={e => {
                                e.stopPropagation();
                                dispatch(
                                  showShareModal({
                                    shareableId: checklist.id,
                                    shareableType: ShareableType.Checklist,
                                    accessLevels:
                                      mapShareFeaturesToAccessLevels(
                                        checklist.access.feature
                                      ),
                                    relativePath: checklist.relativePath,
                                  })
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                className="meatballMenu--icon"
                                icon={faUserPlus}
                              />
                              Share
                            </Dropdown.Item>
                          )}
                          {checklist.access.update && (
                            <>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                onClick={e => {
                                  e.stopPropagation();
                                  setSelectedChecklist(checklist.id);
                                  setShowCloseAsIncompletePopup(true);
                                }}
                              >
                                <FontAwesomeIcon
                                  className="meatballMenu--icon"
                                  icon={faTimesSquare}
                                />
                                Close as incomplete
                              </Dropdown.Item>
                            </>
                          )}
                        </MeatballMenu>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </AdoptechTable>
        </div>
      ) : (
        <NoDataText text="Once started, active checklists will appear here" />
      )}
      <GenericModal
        closeButtonLabel="Confirm"
        onCancel={() => setShowCloseAsIncompletePopup(false)}
        onClose={() => {
          dispatch(
            updateChecklistStatus({
              id: selectedChecklist,
              status: ChecklistChangedStatusPayloadStatusEnum.Closed,
            })
          );
          setShowCloseAsIncompletePopup(false);
        }}
        show={showCloseAsIncompletePopup}
        showCancelButton
        title="Close as incomplete"
      >
        <div>Click CONFIRM to close this checklist as incomplete.</div>
      </GenericModal>
    </div>
  );
};
