import React from 'react';
import MainMenuLogo from '../MainMenuLogo/MainMenuLogo';
import MainMenuFooter from '../MainMenuFooter/MainMenuFooter';
import MainMenuItems from '../MainMenuItems/MainMenuItems';
import './MainMenu.scss';
import VendorSelector from '../VendorSelector/VendorSelector';

const MainMenu: React.FC = () => {
  return (
    <div className="mainMenu">
      <MainMenuLogo />
      <VendorSelector />
      <MainMenuItems />
      <MainMenuFooter />
    </div>
  );
};

export default MainMenu;
