export enum DocumentType {
  Policy = 'policy',
  Agreement = 'agreement',
  Assessment = 'assessment',
  Checklist = 'checklist',
  Document = 'document',
  ComplianceReport = 'compliance',
  AgreementSignatory = 'agreement-signatory',
  Audit = 'audit',
  RiskReport = 'risk_report',
  Report = 'report',
  Certificate = 'certificate',
}
