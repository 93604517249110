export const palette: `#${string}`[] = [
  '#12b76a',
  '#6caa45',
  '#a8d63f',
  '#ffc700',
  '#efa82c',
  '#ea5542',
  '#e25eb9',
  '#7f58d6',
  '#02569f',
  '#4496eb',
  '#00baff',
  '#06abc1',
];

export const getPaletteColour = (index: number): `#${string}` => {
  return palette[index];
};
