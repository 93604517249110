import { VoidThunk } from '../../types/voidThunk';
import {
  ReferenceCreatePayloadReferenceReferenceableTypeEnum,
  RiskModel,
  RiskCreatePayloadRisk,
  ControlModel,
  VendorActionModel,
  RiskUpdatePayloadRiskInformationSecurityPropertiesEnum,
  VendorsVendorIdVendorActionsPostRequest,
  VendorActionExtendedFromJSON,
  RiskUpdatePayload,
} from '../../swagger';

import { getErrorMessage, handleThunkError } from '../helpers/thunkHelpers';
import { api } from '../../api/api';

import {
  approveRiskRequest,
  approveRiskFailure,
  approveRiskSuccess,
  fetchRiskControlsRequest,
  fetchRiskControlsSuccess,
  fetchRiskActionsRequest,
  fetchRiskActionsSuccess,
  createCustomRiskFailure,
  createCustomRiskRequest,
  createCustomRiskSuccess,
  createRiskFromTemplateFailure,
  createRiskFromTemplateRequest,
  createRiskFromTemplateSuccess,
  deleteRiskFailure,
  deleteRiskRequest,
  deleteRiskSuccess,
  fetchRisksCategoriesFailure,
  fetchRisksCategoriesRequest,
  fetchRisksCategoriesSuccess,
  fetchRisksFailure,
  fetchRisksRequest,
  fetchRisksSuccess,
  fetchRiskRequest,
  fetchRiskSuccess,
  fetchRiskFailure,
  fetchRisksTemplatesFailure,
  fetchRisksTemplatesRequest,
  fetchRisksTemplatesSuccess,
  updateCurrentRiskFailure,
  showAutoSaveToast,
} from './riskRegistrySlice';
import { showGlobalToast } from '../global/globalSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchRisks =
  (vendorId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchRisksRequest());
      const result = await api().vendorsVendorIdRisksGet({
        vendorId: vendorId,
      });
      dispatch(fetchRisksSuccess(result));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching risks. Please try again or contact support.'
      );
      dispatch(fetchRisksFailure(apiErrorMessage));
    }
  };

export const fetchRisk =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchRiskRequest());
      const result = await api().risksIdGet({ id });
      dispatch(fetchRiskSuccess(result));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching risk entry. Please try again or contact support.'
      );
      dispatch(fetchRiskFailure(apiErrorMessage));
    }
  };

export const fetchRisksCategories =
  (): VoidThunk => async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(fetchRisksCategoriesRequest());
      const result = await api().riskCategoriesGet({ vendorId });
      dispatch(fetchRisksCategoriesSuccess(result));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching risks categories. Please try again or contact support.'
      );
      dispatch(fetchRisksCategoriesFailure(apiErrorMessage));
    }
  };

export const createCustomRisk =
  (payload: RiskCreatePayloadRisk, onSuccess: () => void): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(createCustomRiskRequest());
      await api().vendorsVendorIdRisksPost({
        vendorId,
        body: { risk: payload },
      });
      dispatch(createCustomRiskSuccess());
      onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while creating custom risk. Please try again or contact support.'
      );
      dispatch(createCustomRiskFailure(apiErrorMessage));
    }
  };

// used in RiskEditor
// TODO: send only changed fields
export const commitCurrentRisk =
  (): VoidThunk => async (dispatch, getState) => {
    try {
      const model = getState().riskRegistry.currentRisk;
      const risk = {
        ownerId: model.owner?.id,
        inherentRiskLikelihood: model.inherentRiskLikelihood,
        inherentRiskConsequence: model.inherentRiskConsequence,
        inherentRiskComment: model.inherentRiskComment,
        residualRiskLikelihood: model.residualRiskLikelihood,
        residualRiskConsequence: model.residualRiskConsequence,
        residualRiskComment: model.residualRiskComment,
        treatmentStrategy: model.treatmentStrategy,
        treatmentExplanation: model.treatmentExplanation,
        informationSecurityProperties:
          model.informationSecurityProperties as unknown as RiskUpdatePayloadRiskInformationSecurityPropertiesEnum[],
      };
      await api().risksIdPatch({
        id: model.id,
        body: { risk },
      });
      dispatch(showAutoSaveToast());
    } catch (err) {
      const errorMessage = await getErrorMessage(
        err,
        'An error occurred while updating risk. Please try again or contact support.'
      );
      dispatch(updateCurrentRiskFailure(errorMessage));
    }
  };

// used in Risk Edit Modal
export const patchRisk = createAsyncThunk(
  'riskRegistry/patchRisk',
  async (payload: { id: string; body: RiskUpdatePayload }, { dispatch }) => {
    try {
      return await api().risksIdPatch({
        id: payload.id,
        body: payload.body,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while patching risk. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const createRiskFromTemplate =
  (payload: Pick<RiskCreatePayloadRisk, 'riskTemplateId'>): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(createRiskFromTemplateRequest());
      const risk = await api().vendorsVendorIdRisksPost({
        vendorId,
        body: { risk: payload },
      });
      dispatch(createRiskFromTemplateSuccess(risk));
      dispatch(showGlobalToast('Risk has been successfully added'));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while creating risk. Please try again or contact support.'
      );
      dispatch(createRiskFromTemplateFailure(apiErrorMessage));
    }
  };

export const fetchRisksTemplates = (): VoidThunk => async dispatch => {
  try {
    dispatch(fetchRisksTemplatesRequest());
    const result = await api().riskTemplatesGet();
    dispatch(fetchRisksTemplatesSuccess(result));
  } catch (err) {
    const apiErrorMessage = await getErrorMessage(
      err,
      'An error occurred while fetching risks templates. Please try again or contact support.'
    );
    dispatch(fetchRisksTemplatesFailure(apiErrorMessage));
  }
};

export const approveRisk =
  (id: RiskModel['id'], onSuccess = () => {}): VoidThunk =>
  async dispatch => {
    try {
      dispatch(approveRiskRequest());
      await api().risksIdApprovePost({ id });
      dispatch(approveRiskSuccess(id));
      dispatch(showGlobalToast('Risk has been approved'));
      onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while approving risk. Please try again or contact support.'
      );
      dispatch(approveRiskFailure(apiErrorMessage));
    }
  };

export const deleteRisk =
  (id: RiskModel['id'], onSuccess = () => {}): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteRiskRequest());
      await api().risksIdDelete({ id });
      dispatch(deleteRiskSuccess(id));
      dispatch(showGlobalToast('Risk has been successfully deleted'));
      onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while deleting risk. Please try again or contact support.'
      );
      dispatch(deleteRiskFailure(apiErrorMessage));
    }
  };

export const fetchRiskControls =
  (): VoidThunk => async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(fetchRiskControlsRequest());
      const result = await api().vendorsVendorIdControlsGet({ vendorId });
      dispatch(fetchRiskControlsSuccess(result));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching risks controls. Please try again or contact support.'
      );
      dispatch(fetchRisksCategoriesFailure(apiErrorMessage));
    }
  };

export const fetchVendorActions =
  (): VoidThunk => async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(fetchRiskActionsRequest());
      const result = await api().vendorsVendorIdVendorActionsGet({ vendorId });
      dispatch(fetchRiskActionsSuccess(result));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching risks tasks. Please try again or contact support.'
      );
      dispatch(fetchRisksCategoriesFailure(apiErrorMessage));
    }
  };

export const createRiskControlReference =
  (
    controlId: ControlModel['id'],
    riskId: RiskModel['id'],
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      await api().controlsControlIdReferencesPost({
        controlId,
        body: {
          reference: {
            referenceableId: riskId,
            referenceableType:
              ReferenceCreatePayloadReferenceReferenceableTypeEnum.Risk,
          },
        },
      });
      onSuccess();
    } catch (err) {
      const errorMessage = await getErrorMessage(
        err,
        'An error occurred while adding control. Please try again or contact support.'
      );
      dispatch(updateCurrentRiskFailure(errorMessage));
    }
  };

export const deleteRiskControlReference =
  (
    controlId: ControlModel['id'],
    riskId: RiskModel['id'],
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      await api().controlsControlIdReferencesDelete({
        controlId,
        referenceableId: riskId,
        referenceableType:
          ReferenceCreatePayloadReferenceReferenceableTypeEnum.Risk,
      });
      onSuccess();
    } catch (err) {
      const errorMessage = await getErrorMessage(
        err,
        'An error occurred while deleting control. Please try again or contact support.'
      );
      dispatch(updateCurrentRiskFailure(errorMessage));
    }
  };

export const createRiskActionReference =
  (
    vendorActionId: VendorActionModel['id'],
    riskId: RiskModel['id'],
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      await api().vendorActionsVendorActionIdReferencesPost({
        vendorActionId,
        body: {
          reference: {
            referenceableId: riskId,
            referenceableType:
              ReferenceCreatePayloadReferenceReferenceableTypeEnum.Risk,
          },
        },
      });
      onSuccess();
    } catch (err) {
      const errorMessage = await getErrorMessage(
        err,
        'An error occurred while adding action. Please try again or contact support.'
      );
      dispatch(updateCurrentRiskFailure(errorMessage));
    }
  };

export const deleteRiskActionReference =
  (
    vendorActionId: VendorActionModel['id'],
    riskId: RiskModel['id'],
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      await api().vendorActionsVendorActionIdReferencesDelete({
        vendorActionId,
        referenceableId: riskId,
        referenceableType:
          ReferenceCreatePayloadReferenceReferenceableTypeEnum.Risk,
      });
      onSuccess();
    } catch (err) {
      const errorMessage = await getErrorMessage(
        err,
        'An error occurred while deleting action. Please try again or contact support.'
      );
      dispatch(updateCurrentRiskFailure(errorMessage));
    }
  };
