import React, { useEffect } from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';

import { AdoptechTextArea } from '../../../../components/AdoptechTextArea/AdoptechTextArea';
import {
  CertificatesEditSectionProps,
  EditSectionRow,
} from '../EditSectionRow/EditSectionRow';
import {
  companyProfileRoute,
  draftPoliciesPageRoute,
  policiesPageRoute,
  reportsRoute,
} from '../../../../components/Routes/Routes';
import { faBookAlt } from '@fortawesome/pro-light-svg-icons/faBookAlt';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from '../../../../functions/capitalize';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';

export const DocumentsEditSection: React.FC<CertificatesEditSectionProps> = ({
  section,
  titleBar,
  editSectionHandler,
  baseCss,
  onClose,
  bulkActionsRow,
  identifier,
}) => {
  const {
    fetchingCurrentSection,
    sectionDescription,
    setSectionDescription,
    handleCheckboxChange,
    sortedDocsToEdit,
    fetchCurrentSection,
    formDocuments,
  } = editSectionHandler;
  useEffect(() => {
    fetchCurrentSection();
  }, []);
  const isCertificates = baseCss.includes('certificate');
  const placeholderText = isCertificates
    ? `No certificates templates to display. Please create at least one certificate template.<a href=${companyProfileRoute}> here.</a>`
    : `No policy templates to display. Please create at least one live
    policy<a href=${policiesPageRoute}> here.</a>`;

  if (section.identifier === 'policies' || section.identifier === 'reports') {
    return (
      <InformationSecurityEditSection
        identifier={identifier}
        section={section}
        baseCss={baseCss}
        onClose={onClose}
        editSectionHandler={editSectionHandler}
        bulkActionsRow={bulkActionsRow}
      />
    );
  }
  return (
    <div className={baseCss + '--panel' + ' editMode'}>
      {titleBar}
      <div className={baseCss + '--descriptionRowInput'}>
        <AdoptechTextArea
          value={sectionDescription || ''}
          id={`${baseCss}-description`}
          placeholder={section.description}
          onChange={e => {
            setSectionDescription(e.currentTarget.value);
          }}
        />
      </div>
      {bulkActionsRow}
      {fetchingCurrentSection && <LoadingSpinner />}
      {!fetchingCurrentSection && (
        <>
          {section.trusthubDocuments.length === 0 && (
            <div
              className={baseCss + '--noContent'}
              dangerouslySetInnerHTML={{
                __html: placeholderText,
              }}
            ></div>
          )}
          <div className={baseCss + '--documents'}>
            {sortedDocsToEdit.map(doc => {
              return (
                <EditSectionRow
                  key={doc.name}
                  doc={doc}
                  formDocument={formDocuments.find(
                    formDoc => formDoc.documentId === doc.documentId
                  )}
                  handleCheckboxChange={handleCheckboxChange}
                  baseCss={baseCss}
                  section={section}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export const InformationSecurityEditSection: React.FC<
  CertificatesEditSectionProps
> = ({
  section,
  titleBar,
  identifier,
  editSectionHandler,
  onClose,
  bulkActionsRow,
}) => {
  const {
    fetchCurrentSection,
    handleCheckboxChange,
    sortedDocsToEdit,
    fetchingCurrentSection,
    handleSave,
    sectionDescription,
    updateSectionStatus,
    formDocuments,
  } = editSectionHandler;

  const baseCss = `${identifier}Section`;
  return (
    <div className={baseCss + '--panel'}>
      <div className={baseCss + '--title'}>
        <div className={baseCss + '--titleInfo'}>
          <FontAwesomeIcon
            icon={identifier === 'reports' ? faServer : faBookAlt}
          />
          <div>{capitalize(identifier)}</div>
        </div>

        <div className={baseCss + '--companyActions'}>
          <AdoptechButton
            disabled={updateSectionStatus === 'loading'}
            onClick={() => onClose()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            busy={updateSectionStatus === 'loading'}
            disabled={
              ['policies', 'reports'].includes(identifier)
                ? false
                : !sectionDescription
            }
            onClick={handleSave}
            variant={AdoptechButtonVariant.White}
          >
            Save
          </AdoptechButton>
        </div>
      </div>
      {bulkActionsRow}
      {fetchingCurrentSection && <LoadingSpinner />}
      {!fetchingCurrentSection && (
        <>
          {section.trusthubDocuments.length === 0 && (
            <div className={baseCss + '--noContent'}>
              {`No templates to display. Please create at least one live ${
                identifier === 'policies' ? 'policy' : 'report'
              }`}{' '}
              <a
                href={
                  identifier === 'policies'
                    ? draftPoliciesPageRoute
                    : reportsRoute
                }
              >
                {' '}
                here.
              </a>
            </div>
          )}

          <div className={baseCss + '--documents'}>
            {sortedDocsToEdit.map(doc => {
              return (
                <EditSectionRow
                  key={doc.name}
                  doc={doc}
                  formDocument={formDocuments.find(
                    formDoc => formDoc.documentId === doc.documentId
                  )}
                  handleCheckboxChange={handleCheckboxChange}
                  baseCss={baseCss}
                  section={section}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
