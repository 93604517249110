import { push } from 'connected-react-router';
import './RiskRegistryLibrary.scss';
import React, { useEffect, useState } from 'react';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { AdoptechTile } from '../../AdoptechTile/AdoptechTile';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import {
  riskRegistryCategoryRoute,
  riskRegistryRoute,
} from '../../Routes/Routes';
import { fetchRisksCategories } from '../../../store/riskRegistry/riskRegistryThunks';
import { AdoptechPageNavBar } from '../../AdoptechPageNavBar/AdoptechPageNavBar';
import { RiskCategoryModel } from '../../../swagger';
import { NoDataText } from '../../NoDataText/NoDataText';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBallotCheck } from '@fortawesome/pro-light-svg-icons/faBallotCheck';
import { faCity } from '@fortawesome/pro-light-svg-icons/faCity';
import { faHouseDamage } from '@fortawesome/pro-light-svg-icons/faHouseDamage';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { AccessObject } from '../../../types/accessObject';
import { useCanFeature } from '../../../functions/access';
import { RiskRegistryStartNewDrawer } from '../RiskRegistryStartNewDrawer/RiskRegistryStartNewDrawer';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';

export const sortedRiskCategories = (categories: RiskCategoryModel[]) => {
  return [...categories].sort((a, b) => a.rowOrder - b.rowOrder);
};
export const RiskRegistryLibrary: React.FC = () => {
  const currentVendor = useSelector(selectCurrentVendor);
  const baseCss = 'riskRegistryLibrary';
  const { categories, isFetchingCategories } = useSelector(
    (state: ApplicationState) => state.riskRegistry
  );

  const dispatch = useDispatch();
  const goBack = () => {
    dispatch(push(riskRegistryRoute));
  };
  useEffect(() => {
    dispatch(fetchRisksCategories());
  }, [currentVendor]);
  const [showAddCustomRiskDrawer, setShowAddCustomRiskDrawer] = useState(false);
  const startNewWith = (categoryId: RiskCategoryModel['id']) => {
    dispatch(push(riskRegistryCategoryRoute.replace(':id', categoryId)));
  };
  const canManageRegister = useCanFeature(AccessObject.risk_register_update);
  if (isFetchingCategories) return <LoadingSpinner />;
  const noCategories = !isFetchingCategories && categories.length === 0;
  if (!canManageRegister)
    return (
      <div style={{ marginTop: '10px' }}>
        <NoDataText
          text="There are no abilities to create the risk. Please contact to the
        admin"
        />
      </div>
    );

  return (
    <div className={baseCss}>
      <AdoptechPageNavBar
        goBack={goBack}
        content={
          <>
            Risk library
            {categories.length > 0 && (
              <div className={baseCss + '--headerButtons'}>
                <AdoptechButton
                  onClick={() => setShowAddCustomRiskDrawer(true)}
                  variant={AdoptechButtonVariant.PrimaryOrange}
                  icon={faPlus}
                  iconSize="large"
                  rounded
                >
                  Add custom risk
                </AdoptechButton>
              </div>
            )}
          </>
        }
      />
      {noCategories && (
        <p style={{ padding: '20px' }}>
          <NoDataText text="There are no risk categories. Please contact to administrator." />
        </p>
      )}
      {categories.length > 0 && (
        <div className={baseCss + '--listHeader'}>
          Select the risks that apply to your business and add them to your risk
          register.
        </div>
      )}
      <div className={baseCss + '--list'}>
        {sortedRiskCategories(categories).map(category => {
          const { total, actual } = category.riskStats || {};
          let icon: IconDefinition;
          switch (category.identifier) {
            case 'organisational':
              icon = faCity;
              break;
            case 'people':
              icon = faUsers;
              break;
            case 'technological':
              icon = faServer;
              break;
            case 'physical':
              icon = faHouseDamage;
              break;
            default:
              icon = faBallotCheck;
          }
          return (
            <AdoptechTile
              icon={icon}
              key={category.id}
              size="large"
              name={category.name}
              customTitle={
                <>
                  <span>{category.name}</span>
                  {category.riskStats && total > 0 && (
                    <span className={baseCss + '--tileCounter'}>
                      {`${actual}/${total}`}
                    </span>
                  )}
                </>
              }
              description={category.description}
              onClick={() => startNewWith(category.id)}
              buttonText="Select"
            />
          );
        })}
      </div>

      {showAddCustomRiskDrawer && (
        <RiskRegistryStartNewDrawer
          onClose={() => setShowAddCustomRiskDrawer(false)}
        />
      )}
    </div>
  );
};
