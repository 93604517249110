import { Question, Survey } from '../swagger';

export const dynamicClauses = (survey: Survey) => {
  return survey.surveyClauses.filter(clause => clause.dynamic);
};

export const hiddenForTheUserQuestionIds = (
  survey: Survey
): Question['id'][] => {
  if (!survey) return [];
  return survey.surveyClauses
    .filter(clause => !clause.dynamic)
    .map(clause => clause.questions.map(question => question.id))
    .flat();
};
