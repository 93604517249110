import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVendorDocumentDownload } from '../../store/vendors/vendorsThunks';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechRadioButton } from '../AdoptechRadioButton/AdoptechRadioButton';
import './DownloadDocumentModal.scss';

enum DownloadOptions {
  Document = 'Document',
  Audit = 'Audit',
  All = 'All',
}

interface DownloadDocumentModalProps {
  onClose: () => void;
  show: boolean;
  vendorDocumentId: string;
}

export const DownloadDocumentModal: React.FC<
  DownloadDocumentModalProps
> = props => {
  const dispatch = useDispatch();
  const [downloadOption, setDownloadOption] = useState<string>();

  const vendorDocumentDownload = useSelector(
    (state: ApplicationState) =>
      state.vendors.documentState.vendorDocumentDownload
  );

  useEffect(() => {
    setDownloadOption(null);

    if (props.show && props.vendorDocumentId) {
      dispatch(fetchVendorDocumentDownload({ id: props.vendorDocumentId }));
    }
  }, [props.show]);

  if (!props.vendorDocumentId) {
    return null;
  }

  return (
    <AdoptechModal
      className="downloadDocumentModal"
      onHide={props.onClose}
      show={props.show}
    >
      <Modal.Header>Download</Modal.Header>
      <Modal.Body>
        <div className="downloadDocumentModal--prompt">
          Please select which items to download:
        </div>
        <div className="downloadDocumentModal--options">
          <AdoptechRadioButton
            checked={downloadOption === DownloadOptions.Document}
            id={DownloadOptions.Document}
            label={DownloadOptions.Document}
            onChange={value => setDownloadOption(value)}
            value={DownloadOptions.Document}
          />
          <AdoptechRadioButton
            checked={downloadOption === DownloadOptions.Audit}
            id={DownloadOptions.Audit}
            label={DownloadOptions.Audit}
            onChange={value => setDownloadOption(value)}
            value={DownloadOptions.Audit}
          />
          <AdoptechRadioButton
            checked={downloadOption === DownloadOptions.All}
            id={DownloadOptions.All}
            label={DownloadOptions.All}
            onChange={value => setDownloadOption(value)}
            value={DownloadOptions.All}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={props.onClose}>Cancel</AdoptechButton>
        <AdoptechButton
          disabled={!downloadOption}
          onClick={() => {
            switch (downloadOption) {
              case DownloadOptions.Document:
                window.location.href = vendorDocumentDownload.document;
                break;
              case DownloadOptions.Audit:
                window.location.href = `/api/v1/vendor_documents/${props.vendorDocumentId}/summary`;
                break;
              case DownloadOptions.All:
                window.location.href = vendorDocumentDownload.document;
                setTimeout(() => {
                  window.location.href = `/api/v1/vendor_documents/${props.vendorDocumentId}/summary`;
                }, 1000);
                break;
            }
            props.onClose();
          }}
          variant={AdoptechButtonVariant.Primary}
        >
          EXPORT
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
