import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Survey } from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { AdoptechCheckbox } from '../../../components/AdoptechCheckbox/AdoptechCheckbox';
import './AuditsAreasForm.scss';
import { dynamicClauses } from '../../../functions/dynamicClauses';

const baseCss = 'auditsAreasForm';

interface Question {
  type: 'question';
  name: string;
  id: string;
  annexRef: string;
}

interface QuestionGroup {
  type: 'group';
  name: string;
  questions: Q[];
}

type Q = Question | QuestionGroup;

type Acc = string[];
const getDescendantsIds = (q: Q): Acc => {
  switch (q.type) {
    case 'question': {
      return [q.id];
    }
    case 'group': {
      return q.questions.reduce(
        (acc: Acc, i: Q) => [...acc, ...getDescendantsIds(i)],
        []
      );
    }
  }
};

const isChecked = (q: Q, ids: string[]): boolean =>
  getDescendantsIds(q).every(id => ids.includes(id));

const clausesAndControls = (survey: Survey): [QuestionGroup, QuestionGroup] => {
  const clauses: QuestionGroup = {
    type: 'group',
    name: 'Select All',
    questions: [],
  };
  const controls: QuestionGroup = {
    type: 'group',
    name: 'Select All',
    questions: [],
  };
  dynamicClauses(survey).forEach(clause => {
    const cursorClause = {
      type: 'group',
      name: clause.title,
      questions: [] as Q[],
    };
    const cursorControl = {
      type: 'group',
      name: clause.title,
      questions: [] as Q[],
    };

    clause.questions.forEach(question => {
      if (question.propertyName.startsWith('audit.control_')) {
        cursorControl.questions.push({
          type: 'question',
          name: question.questionText || question.name,
          id: question.id,
          annexRef: question.annexRef,
        });
      } else {
        cursorClause.questions.push({
          type: 'question',
          name: question.questionText || question.name,
          id: question.id,
          annexRef: question.annexRef,
        });
      }
    });

    if (cursorClause.questions.length)
      clauses.questions.push(cursorClause as QuestionGroup);
    if (cursorControl.questions.length)
      controls.questions.push(cursorControl as QuestionGroup);
  });

  return [clauses, controls];
};

interface CheckBoxGroupProps {
  questionGroup: Q;
  ids: string[];
  toggleIds(q: Q, b: boolean): void;
}

const groupCheckBoxId = (q: Q): string => {
  const ids = getDescendantsIds(q);
  return ids[0] + '-' + ids.length.toString() + '-' + ids.pop();
};

const CheckBoxGroup: React.FC<CheckBoxGroupProps> = ({
  questionGroup,
  ids,
  toggleIds,
}) => {
  const renderQ = (q: Q) => {
    switch (q.type) {
      case 'group': {
        return (
          <>
            <AdoptechCheckbox
              color="white"
              size="small"
              id={groupCheckBoxId(q)}
              className={baseCss + '--groupNode'}
              label={q.name || 'no group name'}
              checked={isChecked(q, ids)}
              onChange={e => {
                toggleIds(q, e.currentTarget.checked);
              }}
            />
            <div className={baseCss + '--questionSubGroup'}>
              {q.questions.map(renderQ)}
            </div>
          </>
        );
      }
      case 'question': {
        return (
          <AdoptechCheckbox
            color="white"
            id={q.id}
            className={baseCss + '--questionNode'}
            size="small"
            label={
              q.annexRef
                ? [q.annexRef, q.name].join(' ')
                : q.name || 'no question name'
            }
            checked={isChecked(q, ids)}
            onChange={e => {
              toggleIds(q, e.currentTarget.checked);
            }}
          />
        );
      }
    }
  };

  return (
    <div className={baseCss + '--questionGroup'}>{renderQ(questionGroup)}</div>
  );
};

interface Props {
  ids: string[];
  setIds: Dispatch<SetStateAction<string[]>>;
}

export const AuditsAreasForm: React.FC<Props> = ({ ids, setIds }) => {
  const template = useSelector(
    (state: ApplicationState) => state.audits.currentTemplate
  );

  const [clausesCheck, setClausesCheck] = useState(true);
  const [controlsCheck, setControlsCheck] = useState(false);

  const toggleIds = (q: Q, add: boolean): void => {
    if (add) {
      setIds([...new Set([...ids, ...getDescendantsIds(q)])]);
    } else {
      setIds(ids.filter(id => !getDescendantsIds(q).includes(id)));
    }
  };

  if (!template) return <LoadingSpinner />;

  const [clauses, controls] = clausesAndControls(template.survey);

  const cleanUpClauses = () => toggleIds(clauses, false);
  const cleanUpControls = () => toggleIds(controls, false);

  return (
    <div className={baseCss}>
      <div>Select the areas that you wish to review in this audit</div>
      <div className={baseCss + '--checkBoxes'}>
        <AdoptechCheckbox
          color="white"
          checked={clausesCheck}
          id="clausesCheckBox"
          label="Clauses"
          labelPadding="small"
          onChange={e => {
            setClausesCheck(e.currentTarget.checked);
            cleanUpClauses();
          }}
        />
        <AdoptechCheckbox
          color="white"
          checked={controlsCheck}
          id="controlsCheckBox"
          label="Controls"
          labelPadding="small"
          onChange={e => {
            setControlsCheck(e.currentTarget.checked);
            cleanUpControls();
          }}
        />
      </div>
      <div className={baseCss + '--checkQuestions'}>
        {clausesCheck && (
          <div className={baseCss + '--checkSection'}>
            <div className={baseCss + '--sectionTitle'}>Clauses</div>
            <CheckBoxGroup
              questionGroup={clauses}
              ids={ids}
              toggleIds={toggleIds}
            />
          </div>
        )}
        {controlsCheck && (
          <div>
            <div className={baseCss + '--sectionTitle'}>Controls</div>
            <CheckBoxGroup
              questionGroup={controls}
              ids={ids}
              toggleIds={toggleIds}
            />
          </div>
        )}
        {!clausesCheck && !controlsCheck && (
          <span className={baseCss + '--choseNote'}>
            Please chose category above
          </span>
        )}
      </div>
    </div>
  );
};
