import React, { useEffect } from 'react';
import { PoliciesTabs } from '../PoliciesTabs/PoliciesTabs';
import { AllDocuments } from '../../../components/AllDocuments/AllDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { fetchVendorDocuments } from '../../../store/vendors/vendorsThunks';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { DeleteVendorDocumentModal } from '../../../components/DeleteVendorDocumentModal/DeleteVendorDocumentModal';
import { UploadDocumentModal } from '../../documents/UploadDocumentModal/UploadDocumentModal';
import { useParams } from 'react-router';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { PdfOpenMode } from '../../../types/pdfOpenMode';
import { openPdfPreview } from '../../../store/global/globalSlice';
import { PdfPreviewEntityPaths } from '../../../functions/routePathsHelpers';
import { DocumentType } from '../../../constants/DocumentType';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';

export const useFetchVendorDocuments = () => {
  const currentVendor = useSelector(selectCurrentVendor);
  const dispatch = useDispatch();
  const { vendorDocuments, isFetchingVendorDocuments } = useSelector(
    (state: ApplicationState) => state.vendors.documentState
  );

  useEffect(() => {
    dispatch(
      fetchVendorDocuments({
        vendorId: currentVendor?.id,
        queryShowHistory: true,
      })
    );
  }, [currentVendor?.id]);

  return {
    currentVendor,
    vendorDocuments,
    isFetchingVendorDocuments,
  };
};

export const AllDocumentsPage: React.FC = () => {
  const isPreviewingPdf = useSelector(
    (state: ApplicationState) => state.global.isPreviewingPdf
  );

  const location = useSelector(
    (state: ApplicationState) => state.router.location
  );

  const params = useParams() as { documentId: string };
  const queryParams = useQueryParams();
  const previewMode = queryParams.get('mode') || PdfOpenMode.Preview;
  const dispatch = useDispatch();

  const { vendorDocuments, isFetchingVendorDocuments } =
    useFetchVendorDocuments();

  useEffect(() => {
    const vendorDocumentId = params.documentId;
    if (!vendorDocuments?.length || isPreviewingPdf || !vendorDocumentId) {
      return;
    }

    const vendorDocument = vendorDocuments.find(
      vd => vd.id === vendorDocumentId
    );
    if (!vendorDocument) {
      return;
    }

    dispatch(
      openPdfPreview({
        dueDate: vendorDocument.vendorDocumentAttestation.attestationDueAt,
        mode: previewMode as PdfOpenMode,
        documentType: DocumentType.Document,
        title: vendorDocument.name,
        vendorDocument: vendorDocument,
        navigateUrlOnClose: PdfPreviewEntityPaths.documents,
      })
    );
  }, [location, vendorDocuments, previewMode]);

  return (
    <div>
      <PoliciesTabs currentTab="all" />
      {isFetchingVendorDocuments ? <LoadingSpinner /> : <AllDocuments />}
      <DeleteVendorDocumentModal />
      <UploadDocumentModal />
    </div>
  );
};

export default AllDocumentsPage;
