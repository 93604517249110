import { createSelector } from '@reduxjs/toolkit';
import { AuditModel, AuditModelStatusEnum } from '../swagger/models/AuditModel';
import { ApplicationState } from '../types/applicationState';
import { selectAuditsProgress } from './selectAuditsProgress';

export const selectAuditQuestionsAreFilledIn = createSelector(
  (state: ApplicationState) => state.audits.currentAudit,
  selectAuditsProgress,
  (audit: AuditModel, progress) => {
    return audit
      ? progress.total === 1 && audit.status !== AuditModelStatusEnum.Completed
      : false;
  }
);
