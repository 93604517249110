import { VendorSupplierModel } from '../swagger';
import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';

export const vendorSupplierSort = (
  a: VendorSupplierModel,
  b: VendorSupplierModel,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof VendorSupplierModel =
    columnSort.name as keyof VendorSupplierModel;

  switch (typedColumn) {
    case 'owner':
      return sort(
        a.owner?.fullName || a.owner?.email || '',
        b.owner?.fullName || b.owner?.email || '',
        direction
      );
    default:
      return sort(a[typedColumn], b[typedColumn], direction);
  }
};
