import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { AuditsEditForm, validation } from './AuditsEditForm';
import { AuditsAreasForm } from './AuditsAreasForm';
import { AuditModel } from '../../../swagger';
import './AuditsStartNewDrawer.scss';
import { createAudit, fetchCurrentAuditTemplate } from './store/auditsThunks';
import { cleanUpCurrentTemplate } from './store/auditsSlice';
import { push } from 'connected-react-router';
import { auditsPageRoute } from '../../../components/Routes/Routes';
import { ApplicationState } from '../../../types/applicationState';
import { hiddenForTheUserQuestionIds } from '../../../functions/dynamicClauses';

interface Props {
  templateId: string;
  onClose(): void;
}

export const AuditsStartNewDrawer: React.FC<Props> = ({
  templateId,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [audit, setAudit] = useState({} as AuditModel);
  const [questionIds, setQuestionIds] = useState<string[]>([]);

  const formIsValid = Object.values(validation(audit)).every(v => !v);

  const [step, setStep] = useState('Edit' as Step);

  useEffect(() => {
    setStep('Edit');
    setAudit({ auditTemplateId: templateId } as AuditModel);
    dispatch(fetchCurrentAuditTemplate(templateId));
    return () => {
      dispatch(cleanUpCurrentTemplate());
    };
  }, [templateId]);

  type Step = 'Edit' | 'Areas';

  const onBack = () => setStep('Edit');

  const { currentTemplate, isFetchingCurrentAuditTemplate } = useSelector(
    (state: ApplicationState) => state.audits
  );
  const alwaysRequiredIds = hiddenForTheUserQuestionIds(
    currentTemplate?.survey
  );
  const goNext = () => setStep('Areas');
  const submitChanges = () => {
    setBusy(true);
    const auditPayload = { ...audit, ownerId: audit.owner.id };
    delete auditPayload.owner;

    dispatch(
      createAudit(
        auditPayload,
        alwaysRequiredIds.concat(questionIds),
        onSubmitSuccess,
        onSubmitError
      )
    );
  };

  const onSubmitSuccess = () => {
    onClose();
    dispatch(push(auditsPageRoute));
    dispatch(cleanUpCurrentTemplate());
  };

  const onSubmitError = (err: string) => {
    alert(err);
    setStep('Edit');
    setBusy(false);
  };

  const [busy, setBusy] = useState(false);

  const EditFooter = (
    <>
      <AdoptechButton onClick={onClose} variant={AdoptechButtonVariant.White}>
        Cancel
      </AdoptechButton>
      <AdoptechButton
        disabled={!formIsValid}
        onClick={goNext}
        variant={AdoptechButtonVariant.Primary}
        busy={false}
      >
        Next
      </AdoptechButton>
    </>
  );

  const AreasFooter = (
    <>
      <AdoptechButton onClick={onBack} variant={AdoptechButtonVariant.White}>
        Back
      </AdoptechButton>
      <AdoptechButton
        onClick={submitChanges}
        variant={AdoptechButtonVariant.Primary}
        busy={busy}
        disabled={!currentTemplate || isFetchingCurrentAuditTemplate}
      >
        Confirm
      </AdoptechButton>
    </>
  );

  const Footer = step === 'Edit' ? EditFooter : AreasFooter;

  return (
    <AdoptechHS
      title="Start Audit"
      show={!!templateId}
      onClose={onClose}
      showConfirmationWarning={false}
      footer={Footer}
      extraClass="auditsStartNewDrawer"
    >
      {step == 'Edit' && <AuditsEditForm data={audit} setData={setAudit} />}
      {step == 'Areas' && (
        <AuditsAreasForm ids={questionIds} setIds={setQuestionIds} />
      )}
    </AdoptechHS>
  );
};
