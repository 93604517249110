/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RecurringProduct
 */
export interface RecurringProduct {
  /**
   *
   * @type {string}
   * @memberof RecurringProduct
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof RecurringProduct
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RecurringProduct
   */
  internalName?: string;
  /**
   *
   * @type {number}
   * @memberof RecurringProduct
   */
  monthlyPrice?: number;
  /**
   *
   * @type {number}
   * @memberof RecurringProduct
   */
  annuallyPrice?: number;
  /**
   *
   * @type {string}
   * @memberof RecurringProduct
   */
  stripeMonthlyPriceId?: string;
  /**
   *
   * @type {string}
   * @memberof RecurringProduct
   */
  stripeAnnuallyPriceId?: string;
  /**
   *
   * @type {string}
   * @memberof RecurringProduct
   */
  summary?: string;
  /**
   *
   * @type {string}
   * @memberof RecurringProduct
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof RecurringProduct
   */
  descriptionAsHtml?: string;
}

export function RecurringProductFromJSON(json: any): RecurringProduct {
  return RecurringProductFromJSONTyped(json, false);
}

export function RecurringProductFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RecurringProduct {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    internalName: !exists(json, 'internal_name')
      ? undefined
      : json['internal_name'],
    monthlyPrice: !exists(json, 'monthly_price')
      ? undefined
      : json['monthly_price'],
    annuallyPrice: !exists(json, 'annually_price')
      ? undefined
      : json['annually_price'],
    stripeMonthlyPriceId: !exists(json, 'stripe_monthly_price_id')
      ? undefined
      : json['stripe_monthly_price_id'],
    stripeAnnuallyPriceId: !exists(json, 'stripe_annually_price_id')
      ? undefined
      : json['stripe_annually_price_id'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    description: !exists(json, 'description') ? undefined : json['description'],
    descriptionAsHtml: !exists(json, 'description_as_html')
      ? undefined
      : json['description_as_html'],
  };
}

export function RecurringProductToJSON(value?: RecurringProduct | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    internal_name: value.internalName,
    monthly_price: value.monthlyPrice,
    annually_price: value.annuallyPrice,
    stripe_monthly_price_id: value.stripeMonthlyPriceId,
    stripe_annually_price_id: value.stripeAnnuallyPriceId,
    summary: value.summary,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
  };
}
