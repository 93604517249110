import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { UserDetails } from '../../swagger';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import 'react-phone-number-input/style.css';
import './EditProfileFields.scss';

interface EditProfileFieldsProps {
  onChange: (userDetails: UserDetails) => void;
  userDetails: UserDetails;
  inDrawer?: boolean;
  formValid?: boolean;
  formTouched?: boolean;
  disabledFields?: string[];
}

export const EditProfileFields: React.FC<EditProfileFieldsProps> = props => {
  const [userDetails, setUserDetails] = useState<UserDetails>({
    email: '',
    firstName: '',
    lastName: '',
    mobile: '',
    phone: '',
  });

  useEffect(() => {
    if (props.userDetails) {
      setUserDetails(props.userDetails);
    }
  }, [props.userDetails]);

  const disabledField = (prop: string) => props.disabledFields?.includes(prop);
  return (
    <div className="editProfileFields--fields">
      <div className="editProfileFields--fieldRow">
        <div className="editProfileFields--field">
          <AdoptechTextInput
            id="editProfileFields--firstnameInput"
            autoFocus
            onChange={e => {
              const updatedUserDetails: UserDetails = {
                ...userDetails,
                firstName: e.currentTarget.value,
              };
              setUserDetails(updatedUserDetails);
              props.onChange(updatedUserDetails);
            }}
            placeholder="First name"
            type="text"
            disabled={disabledField('firstName')}
            value={userDetails.firstName || ''}
          />
        </div>
        <div className="editProfileFields--field">
          <AdoptechTextInput
            id="editProfileFields--surnameInput"
            onChange={e => {
              const updatedUserDetails: UserDetails = {
                ...userDetails,
                lastName: e.currentTarget.value,
              };
              setUserDetails(updatedUserDetails);
              props.onChange(updatedUserDetails);
            }}
            placeholder="Last name"
            type="text"
            disabled={disabledField('lastName')}
            value={userDetails.lastName || ''}
          />
        </div>
      </div>
      <div className="editProfileFields--fieldRow">
        <div className="editProfileFields--field">
          <AdoptechTextInput
            id="editProfileFields--emailAddressInput"
            onChange={e => {
              const updatedUserDetails: UserDetails = {
                ...userDetails,
                email: e.currentTarget.value,
              };
              setUserDetails(updatedUserDetails);
              props.onChange(updatedUserDetails);
            }}
            hasError={props.formTouched && !props.formValid}
            placeholder="Email address*"
            type="email"
            disabled={disabledField('email')}
            value={userDetails.email || ''}
          />
        </div>
      </div>
      {!props.inDrawer && (
        <div className="editProfileFields--fieldRow">
          <div className="editProfileFields--field">
            <div>
              <PhoneInput
                defaultCountry="GB"
                id="editProfileFields--phoneInput"
                onChange={phone => {
                  const updatedUserDetails: UserDetails = {
                    ...userDetails,
                    phone: phone ? phone : '',
                  };
                  setUserDetails(updatedUserDetails);
                  props.onChange(updatedUserDetails);
                }}
                placeholder="Phone number"
                value={userDetails.phone || ''}
              />
            </div>
          </div>
          <div className="editProfileFields--field">
            <div>
              <PhoneInput
                defaultCountry="GB"
                id="editProfileFields--mobileInput"
                onChange={mobile => {
                  const updatedUserDetails: UserDetails = {
                    ...userDetails,
                    mobile: mobile ? mobile : '',
                  };
                  setUserDetails(updatedUserDetails);
                  props.onChange(updatedUserDetails);
                }}
                placeholder="Mobile number"
                value={userDetails.mobile || ''}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
