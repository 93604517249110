import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from '../../functions/capitalize';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { ShareableObject } from '../../store/vendors/vendorsState';
import { fetchShares } from '../../store/vendors/vendorsThunks';
import {
  MultipleShareShares,
  ShareAccessLevel,
  SharesListData,
  SharesListDataPossibleAccessLevelsEnum,
} from '../../swagger/models';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { allPermissionsOptions } from '../ShareModal/constants';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import './SharesTable.scss';

interface SharesTableProps {
  shareableObject: ShareableObject;
  hidden: boolean;
  onChangePermissions: (toChange: MultipleShareShares) => void;
  onDeletePermissions: (emailToDelete: string) => void;
  permissionsToUpdate: {
    toChangeList: MultipleShareShares[];
    toDeleteList: string[];
  };
}

export const SharesTable: React.FC<SharesTableProps> = ({
  shareableObject,
  hidden,
  onChangePermissions,
  onDeletePermissions,
  permissionsToUpdate,
}: SharesTableProps) => {
  const currentUser = useSelector(
    (state: ApplicationState) => state.user.userDetails
  );
  const currentVendor = useSelector(selectCurrentVendor);

  const dispatch = useDispatch();
  const sharesList = useSelector(
    (state: ApplicationState) => state.vendors.sharesList
  );
  const displaySharesList = useMemo(() => {
    if (sharesList?.data?.length) {
      const { toChangeList, toDeleteList } = permissionsToUpdate;
      return sharesList.data
        .filter(
          (share: SharesListData) =>
            toDeleteList.indexOf(share.user.email) === -1
        )
        .map((share: SharesListData) => {
          const changedShare = toChangeList.find(
            item => item.email === share.user.email
          );
          if (!changedShare) return share;
          return { ...share, accessLevel: changedShare.accessLevel };
        });
    }
    return [];
  }, [permissionsToUpdate, sharesList]);

  const isFetchingSharesList = useSelector(
    (state: ApplicationState) => state.vendors.isFetchingSharesList
  );

  const execFetchShares = () => {
    dispatch(
      fetchShares({
        id: currentVendor.id,
        shareableType: shareableObject.shareableType,
        shareableId: shareableObject.shareableId,
      })
    );
  };

  useEffect(() => {
    shareableObject && execFetchShares();
  }, [shareableObject]);

  const handleAccessLevelChange = (
    accessLevel: string,
    shareInfo: SharesListData
  ) => {
    onChangePermissions({
      email: shareInfo.user.email,
      accessLevel: accessLevel as ShareAccessLevel,
    });
  };

  const renderAccessLevel = (shareInfo: SharesListData) => {
    if (
      shareInfo.possibleAccessLevels &&
      shareInfo.possibleAccessLevels.length > 1
    ) {
      const options = allPermissionsOptions.filter(
        (opt: SelectionOption) =>
          shareInfo.possibleAccessLevels.indexOf(
            opt.value as SharesListDataPossibleAccessLevelsEnum
          ) !== -1
      );
      return (
        <AdoptechReactSelect
          value={options.find(opt => opt.value === shareInfo.accessLevel)}
          id="sharesTable--selectPermission"
          onChange={(selection: SelectionOption) =>
            handleAccessLevelChange(selection.value, shareInfo)
          }
          options={options}
        />
      );
    }
    return capitalize(shareInfo.accessLevel);
  };

  if (hidden) {
    return null;
  }

  return (
    <div className="sharesTable">
      <AdoptechTable modal>
        <thead>
          <tr>
            <th className="sharesTable--userNameCell">Permissioned users</th>
            <th>Permissions</th>
            <th />
          </tr>
        </thead>
        <tbody className="SharesTable--sharesList">
          {isFetchingSharesList ? (
            <tr>
              <td colSpan={3}>
                <LoadingSpinner />
              </td>
            </tr>
          ) : (
            displaySharesList.map((shareInfo: SharesListData) => (
              <tr key={shareInfo.user.email}>
                <td>
                  <UserAvatar user={shareInfo.user} size="large" />
                </td>
                <td>{renderAccessLevel(shareInfo)}</td>
                <td>
                  {shareInfo.directShare &&
                    shareInfo.user.email !== currentUser.email && (
                      <FontAwesomeIcon
                        className="sharesTable--deletePermissions"
                        icon={faTimes}
                        onClick={() =>
                          onDeletePermissions(shareInfo.user.email)
                        }
                      />
                    )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </AdoptechTable>
    </div>
  );
};
