import { ColumnSort } from '../types/columnSort';
import { Assessment } from '../swagger';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';
import moment from 'moment';

export const assessmentSort = (
  a: Assessment,
  b: Assessment,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof Assessment = columnSort.name as keyof Assessment;

  switch (typedColumn) {
    case 'updatedAt':
      return sort(moment(a.updatedAt), moment(b.updatedAt), direction);
    default:
      return sort(a[typedColumn], b[typedColumn], direction);
  }
};
