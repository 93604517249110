const prepare = (pairs: Record<string, string>) =>
  Object.keys(pairs).reduce((acc: Record<string, any>, key) => {
    const value = pairs[key];
    switch (value) {
      case 't':
        acc[key] = true;
        break;
      case 'f':
        acc[key] = false;
        break;
      default:
        acc[key] = value;
        break;
    }

    return acc;
  }, {});

enum TokenType {
  Number,
  Symbol,
  String,
  And,
  Or,
  Eq,
  LeftParen,
  RightParen,
}

const tokens: [RegExp, TokenType][] = [
  [/^\s+/, null],
  [/^-?\d+(?:\.\d+)?/, TokenType.Number],
  [/^[a-zA-Z]+([._a-zA-Z]+)*/, TokenType.Symbol],
  [/^"[^"]+"/, TokenType.String],
  [/^'[^']+'/, TokenType.String],
  [/^&&/, TokenType.And],
  [/^\|\|/, TokenType.Or],
  [/^=+/, TokenType.Eq],
  [/^\(/, TokenType.LeftParen],
  [/^\)/, TokenType.RightParen],
];

export const tokenize = (expression: string): [TokenType, string][] => {
  for (const [pattern, type] of tokens) {
    const [match] = pattern.exec(expression) || [];

    if (!match) continue;

    const next = tokenize(expression.slice(match.length));

    if (!type) return next;

    return [[type, match.replace(/['"]+/g, '')], ...next];
  }

  return [];
};

type ASTNode = ASTCall | ASTOperation | ASTValue | ASTVariable;

type ASTCall = {
  type: 'call';
  name: string;
  argument: ASTNode;
};

type ASTOperation = {
  type: 'operation';
  code: string;
  left: ASTNode;
  right: ASTNode;
};

type ASTValue = {
  type: 'value';
  value: boolean | number | string;
};

type ASTVariable = {
  type: 'variable';
  name: string;
};

export const parse = (
  buffer: [TokenType, string][],
  nested = false
): ASTNode => {
  const t = buffer.shift();

  let left: ASTNode = null;

  switch (t[0]) {
    case TokenType.LeftParen: {
      left = parse(buffer, true);
      break;
    }
    case TokenType.Symbol: {
      const nxt = buffer[0];
      if (nxt && nxt[0] == TokenType.LeftParen) {
        buffer.shift();
        left = {
          type: 'call',
          name: t[1],
          argument: parse(buffer, true),
        };
      } else {
        switch (t[1]) {
          case 'true':
            left = { type: 'value', value: true };
            break;
          case 'false':
            left = { type: 'value', value: false };
            break;
          default:
            left = { type: 'variable', name: t[1] };
            break;
        }
      }
      break;
    }
    case TokenType.String:
    case TokenType.Number: {
      left = {
        type: 'value',
        value: t[1],
      };
      break;
    }
    default: {
      throw new Error(`Unexpected token ${t[1]}.`);
    }
  }

  let nxt = buffer.shift();

  if (nxt && nxt[0] == TokenType.Eq) {
    // Emulate Eq higher priority
    const right = buffer.shift();
    let rightObject: ASTValue | ASTVariable = null;
    switch (right[0]) {
      case TokenType.String: {
        rightObject = { type: 'value', value: right[1] };
        break;
      }
      case TokenType.Symbol: {
        // TODO: DRY with common Symbol parsing
        switch (right[1]) {
          case 'true':
            rightObject = { type: 'value', value: true };
            break;
          case 'false':
            rightObject = { type: 'value', value: false };
            break;
          default:
            rightObject = { type: 'variable', name: right[1] };
            break;
        }
        break;
      }
      default: {
        throw new Error(`Unexpected comparison right side ${right[1]}.`);
      }
    }
    left = {
      type: 'operation',
      code: '==',
      left: left,
      right: rightObject,
    };
    nxt = buffer.shift();
  }

  if (!nested && !nxt) return left;
  if (nested && nxt[0] == TokenType.RightParen) return left;

  const operators = [TokenType.And, TokenType.Or];
  if (operators.includes(nxt[0])) {
    return {
      type: 'operation',
      code: nxt[1],
      left: left,
      right: parse(buffer, nested),
    };
  }

  throw new Error(`Unexpected token ${nxt[1]}.`);
};

export const dentaku = (expression: string, context = {}): boolean => {
  if (!expression) return true;

  const ctx = prepare(context);

  const operation = (node: ASTOperation): boolean | string | number => {
    const left = evaluate(node.left);
    const right = evaluate(node.right);
    switch (node.code) {
      case '==': {
        return left == right;
      }
      case '&&': {
        return left && right;
      }
      case '||': {
        return left || right;
      }
    }
  };

  const call = (node: ASTCall): boolean => {
    if (node.name.toLowerCase() !== 'not')
      throw new Error(`Unexpected function name ${node.name}.`);
    // Support only not() for now
    return !evaluate(node.argument);
  };

  const evaluate = (ast: ASTNode): boolean | string | number => {
    switch (ast.type) {
      case 'variable': {
        return ctx[ast.name];
      }
      case 'operation': {
        return operation(ast);
      }
      case 'value': {
        return ast.value;
      }
      case 'call': {
        return call(ast);
      }
    }
  };

  const ast = parse(tokenize(expression));
  const result = !!evaluate(ast);

  const debug = false;

  if (debug) {
    console.log('DENTAKU DEBUG');
    console.log('SOURCE: ', expression);
    console.log('AST: ', ast);
    console.log('RESULT: ', result);
  }

  return result;
};
