/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubAccessRequestApprovePayloadTrusthubAccessRequest,
  TrusthubAccessRequestApprovePayloadTrusthubAccessRequestFromJSON,
  TrusthubAccessRequestApprovePayloadTrusthubAccessRequestFromJSONTyped,
  TrusthubAccessRequestApprovePayloadTrusthubAccessRequestToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubAccessRequestApprovePayload
 */
export interface TrusthubAccessRequestApprovePayload {
  /**
   *
   * @type {TrusthubAccessRequestApprovePayloadTrusthubAccessRequest}
   * @memberof TrusthubAccessRequestApprovePayload
   */
  trusthubAccessRequest: TrusthubAccessRequestApprovePayloadTrusthubAccessRequest;
}

export function TrusthubAccessRequestApprovePayloadFromJSON(
  json: any
): TrusthubAccessRequestApprovePayload {
  return TrusthubAccessRequestApprovePayloadFromJSONTyped(json, false);
}

export function TrusthubAccessRequestApprovePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubAccessRequestApprovePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    trusthubAccessRequest:
      TrusthubAccessRequestApprovePayloadTrusthubAccessRequestFromJSON(
        json['trusthub_access_request']
      ),
  };
}

export function TrusthubAccessRequestApprovePayloadToJSON(
  value?: TrusthubAccessRequestApprovePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    trusthub_access_request:
      TrusthubAccessRequestApprovePayloadTrusthubAccessRequestToJSON(
        value.trusthubAccessRequest
      ),
  };
}
