import { Grid } from '../../types/grid';

export const getGridFromPestelArea = (area: string) => {
  let grid;

  switch (area) {
    case 'political':
      grid = Grid.PestelPolitical;
      break;
    case 'economic_commercial':
      grid = Grid.PestelEconomicCommercial;
      break;
    case 'social':
      grid = Grid.PestelSocial;
      break;
    case 'environmental':
      grid = Grid.PestelEnvironmental;
      break;
    case 'legal':
      grid = Grid.PestelLegal;
      break;
    case 'technological':
      grid = Grid.PestelTechnological;
      break;
    default:
      grid = Grid.PestelPolitical;
  }

  return grid;
};
