import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsers } from '../../selectors/selectUsers';
import {
  cancelAddUserModal,
  hideAddUserModal,
  showConfirmResendInvitationModal,
  showConfirmSendInvitationModal,
  updateInviteUserDetails,
} from '../../store/vendors/vendorsSlice';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import './AddUserModal.scss';

export const AddUserModal: React.FC = () => {
  const firstNameRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const { firstName, lastName, emailAddress, isAddUserModalShowing } =
    useSelector((state: ApplicationState) => state.vendors.inviteState);
  const users = useSelector(selectUsers);
  const [userDetails, setUserDetails] = useState({
    firstName,
    lastName,
    emailAddress,
  });

  useEffect(() => {
    setUserDetails({ firstName, lastName, emailAddress });
  }, [firstName, lastName, emailAddress]);

  const handleUserDetailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, id } = event.target;
    setUserDetails(details => ({
      ...details,
      [id]: value,
    }));
  };

  const alreadyInvited = () =>
    users && users.some(user => user.email === userDetails.emailAddress);

  const isFormValid = () =>
    !!userDetails.firstName &&
    !!userDetails.lastName &&
    !!userDetails.emailAddress;
  const clearForm = () => {
    setUserDetails({ firstName: '', lastName: '', emailAddress: '' });
    dispatch(
      updateInviteUserDetails({ firstName: '', lastName: '', emailAddress: '' })
    );
  };

  const submit = () => {
    dispatch(updateInviteUserDetails(userDetails));
    if (alreadyInvited()) {
      dispatch(showConfirmResendInvitationModal());
    } else {
      dispatch(showConfirmSendInvitationModal(false));
    }
    dispatch(hideAddUserModal());
  };

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, [isAddUserModalShowing]);

  const handleCancel = () => {
    clearForm();
    dispatch(cancelAddUserModal());
  };

  return (
    <AdoptechModal
      className="addUserModal"
      onHide={handleCancel}
      show={isAddUserModalShowing}
    >
      <Modal.Header>Add User</Modal.Header>
      <Modal.Body>
        <p>
          Please complete the user information below and we will send them an
          invitation by email.
        </p>
        <div className="addUserModal--name">
          <AdoptechTextInput
            type="text"
            id="firstName"
            label="First name"
            onChange={handleUserDetailChange}
            ref={firstNameRef}
            value={userDetails.firstName}
          />
          <AdoptechTextInput
            type="text"
            id="lastName"
            label="Surname"
            onChange={handleUserDetailChange}
            value={userDetails.lastName}
          />
        </div>
        <div className="addUserModal--emailAddress">
          <AdoptechTextInput
            type="text"
            id="emailAddress"
            label="Email address"
            onChange={handleUserDetailChange}
            value={userDetails.emailAddress}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="addUserModal--footer">
          <AdoptechButton onClick={handleCancel}>Cancel</AdoptechButton>
          <AdoptechButton
            disabled={!isFormValid()}
            onClick={submit}
            variant={AdoptechButtonVariant.Primary}
          >
            Next
          </AdoptechButton>
        </div>
      </Modal.Footer>
    </AdoptechModal>
  );
};
