/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorDocumentAttestation
 */
export interface VendorDocumentAttestation {
  /**
   *
   * @type {string}
   * @memberof VendorDocumentAttestation
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocumentAttestation
   */
  documentName?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocumentAttestation
   */
  vendorUserId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocumentAttestation
   */
  fullName?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocumentAttestation
   */
  email?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof VendorDocumentAttestation
   */
  required?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof VendorDocumentAttestation
   */
  sendEmailNotification?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocumentAttestation
   */
  status?: VendorDocumentAttestationStatusEnum;
  /**
   *
   * @type {string}
   * @memberof VendorDocumentAttestation
   */
  lastAttestedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocumentAttestation
   */
  attestationDueAt?: string | null;
}

export function VendorDocumentAttestationFromJSON(
  json: any
): VendorDocumentAttestation {
  return VendorDocumentAttestationFromJSONTyped(json, false);
}

export function VendorDocumentAttestationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorDocumentAttestation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    documentName: !exists(json, 'document_name')
      ? undefined
      : json['document_name'],
    vendorUserId: !exists(json, 'vendor_user_id')
      ? undefined
      : json['vendor_user_id'],
    fullName: !exists(json, 'full_name') ? undefined : json['full_name'],
    email: !exists(json, 'email') ? undefined : json['email'],
    required: !exists(json, 'required') ? undefined : json['required'],
    sendEmailNotification: !exists(json, 'send_email_notification')
      ? undefined
      : json['send_email_notification'],
    status: !exists(json, 'status') ? undefined : json['status'],
    lastAttestedAt: !exists(json, 'last_attested_at')
      ? undefined
      : json['last_attested_at'],
    attestationDueAt: !exists(json, 'attestation_due_at')
      ? undefined
      : json['attestation_due_at'],
  };
}

export function VendorDocumentAttestationToJSON(
  value?: VendorDocumentAttestation | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    document_name: value.documentName,
    vendor_user_id: value.vendorUserId,
    full_name: value.fullName,
    email: value.email,
    required: value.required,
    send_email_notification: value.sendEmailNotification,
    status: value.status,
    last_attested_at: value.lastAttestedAt,
    attestation_due_at: value.attestationDueAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum VendorDocumentAttestationStatusEnum {
  WasNotRequested = 'was_not_requested',
  Requested = 'requested',
  Current = 'current',
  Imminent = 'imminent',
  Overdue = 'overdue',
  Expired = 'expired',
}
