import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { AdoptechButton } from '../AdoptechButton/AdoptechButton';
import './AdoptechDatePicker.scss';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import classNames from 'classnames';
import { typeOptions } from '../Reports/ReportsHeader/ReportsHeader';
import { instanceOf } from 'prop-types';

export type DeadLineData = {
  label: string;
  value: Date;
};

export interface AdoptechDatePickerProps {
  outputDate: Date;
  onDateSelect: (date: Date) => void;
  onPresetDateSelected?: (date: Date) => void;
  labelText?: string;
  baseCssName?: string;
  placeHolderText?: string;
  labelStyle?: string;
  id?: string;
  endNode?: ReactNode;
  deadLines?: DeadLineData[];
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  hasError?: boolean;
  rounded?: boolean;
  popperPlacement?: string;
  // Example: If refreshDateOnPreselect is false => On preselect ( ex: 1 year from now) => don't update current date ( only call onPresetDateSelected())
  refreshDateOnPreselect?: boolean;
}

export const getFutureDate = (value: number): Date => {
  const date = new Date();
  date.setMonth(date.getMonth() + value);
  return date;
};

const defaultDeadlines: DeadLineData[] = [
  { label: '3 months from today', value: getFutureDate(3) },
  { label: '6 months from today', value: getFutureDate(6) },
  { label: '9 months from today', value: getFutureDate(9) },
  { label: '12 months from today', value: getFutureDate(12) },
];

export const AdoptechDatePicker: React.FC<AdoptechDatePickerProps> = ({
  labelText = 'Set response deadline (optional)',
  baseCssName = 'adoptechDatePicker',
  placeHolderText = 'Select date',
  onDateSelect = () => {},
  labelStyle = baseCssName + '--label',
  id = baseCssName + '--datePicker',
  endNode,
  deadLines = defaultDeadlines,
  minDate = new Date(),
  maxDate = null,
  onPresetDateSelected = () => {},
  disabled = false,
  hasError,
  outputDate,
  popperPlacement = 'top-end',
  refreshDateOnPreselect = true,
}) => {
  const datePickerRef = useRef<DatePicker>();

  let initialDate = outputDate;
  if (
    outputDate &&
    outputDate instanceof Date &&
    outputDate.toString() === 'Invalid Date'
  ) {
    console.error('Invalid date', outputDate);
    initialDate = null;
  }

  useEffect(() => {
    setSelectedDate(initialDate);
  }, [initialDate]);

  const [selectedDate, setSelectedDate] = useState<Date>(initialDate);

  const refreshDate = (date: Date): void => {
    setSelectedDate(date);
    onDateSelect(date);
  };

  const presetDates: DeadLineData[] = deadLines.map(deadline => ({
    label: deadline.label,
    value: deadline.value,
  }));

  const handlePresetDateSelect = (date: Date): void => {
    onPresetDateSelected(date);
    refreshDateOnPreselect && refreshDate(date);
    datePickerRef.current.setOpen(false);
  };

  const popperClassName = baseCssName + '--popperClass';

  return (
    <div
      className={classNames('datepicker--wrapper', {
        'datepicker--wrapper-disabled': disabled,
      })}
      onClick={e => {
        const insideDatepickerModal = (e.target as HTMLDivElement).closest(
          '.' + popperClassName
        );
        if (insideDatepickerModal) return;
        datePickerRef.current.setOpen(true);
      }}
    >
      {labelText && (
        <label htmlFor="adoptechDatePickerContainer" className={labelStyle}>
          {labelText}
        </label>
      )}
      <div
        id="adoptechDatePickerContainer"
        className={classNames(baseCssName + '--dateContainer', {
          singleColumn: (presetDates || []).length === 0,
        })}
      >
        <DatePicker
          className={classNames({
            'adoptech-error': hasError,
          })}
          disabled={disabled}
          ref={datePickerRef}
          minDate={minDate}
          maxDate={maxDate}
          showDisabledMonthNavigation
          id={id}
          dateFormat="dd/MM/yyyy"
          onChange={refreshDate}
          shouldCloseOnSelect
          selected={selectedDate}
          autoComplete="off"
          placeholderText={placeHolderText}
          popperClassName={popperClassName}
          popperPlacement={popperPlacement}
        >
          {presetDates && presetDates.length > 0 && (
            <div className={baseCssName + '--buttonsColumn'}>
              {presetDates.map(option => (
                <AdoptechButton
                  key={option.label}
                  onClick={() => handlePresetDateSelect(option.value)}
                >
                  {option.label}
                </AdoptechButton>
              ))}
            </div>
          )}
        </DatePicker>
        {endNode ? (
          endNode
        ) : (
          <div className={baseCssName + '--selector'}>
            <FontAwesomeIcon
              className={baseCssName + '--icon'}
              icon={faAngleDown}
            />
          </div>
        )}
      </div>
    </div>
  );
};
