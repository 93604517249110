import React from 'react';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './ConfirmModal.scss';

interface ConfirmModalProps {
  close: () => void;
  confirm: () => void;
  headerText: string;
  description: string;
  show: boolean;
}

// TODO: Replace with ConfirmationModal
export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  close = () => {},
  confirm = () => {},
  headerText = 'Confirm',
  description = 'Are you sure you wish to proceed',
  children,
  show,
}) => {
  const baseCss = 'confirmModal';

  return (
    <AdoptechModal className={baseCss} onHide={() => {}} show={show}>
      <Modal.Header>{headerText}</Modal.Header>
      <Modal.Body>
        <div className={baseCss + '--body'}>
          {description && (
            <div className={baseCss + '--description'}>{description}</div>
          )}
          {children}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={() => {
            close();
          }}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          onClick={confirm}
          uppercase
          variant={AdoptechButtonVariant.Warning}
        >
          confirm
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
