import { DataRoomEntityModel, DataRoomEntityModelTypeEnum } from '../swagger';

export interface FolderModel {
  id: string;
  level: number;
  path: string;
  name: string;
  description: string;
}

export const getFolderModel = (entity: DataRoomEntityModel) => {
  const depth =
    entity.filePath === '/' ? 0 : entity.filePath.split('/').length - 1;

  return {
    id: entity.id,
    level: depth,
    name: entity.name,
    path: entity.filePath,
    description: entity.description,
  };
};

export const getFlatFoldersList = (
  entity: DataRoomEntityModel,
  flatList: FolderModel[],
  requiredRights?: string[] // No requiredRights means all folders are available
) => {
  if (
    entity?.type == DataRoomEntityModelTypeEnum.Folder &&
    (!requiredRights ||
      entity.access?.feature?.some(f => requiredRights.includes(f)) ||
      entity.name === '/')
  ) {
    flatList.push(getFolderModel(entity));
    requiredRights = undefined; // all children shall be added with parent access rights
  }

  entity?.children?.map(child => {
    return getFlatFoldersList(child, flatList, requiredRights);
  });
};
