import React from 'react';
import { AssertionRunModelStatusEnum } from '../../../swagger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';

const baseCss = 'testLogPage';

export const Status: React.FC<{ status: AssertionRunModelStatusEnum }> = ({
  status,
}) => {
  if (status === AssertionRunModelStatusEnum.Compliant) {
    return (
      <FontAwesomeIcon
        className={baseCss + '--statusCompliant'}
        icon={faCheckCircle}
      />
    );
  }

  if (status === AssertionRunModelStatusEnum.Failed) {
    return (
      <FontAwesomeIcon
        className={baseCss + '--statusFailed'}
        icon={faExclamationCircle}
      />
    );
  }

  return (
    <FontAwesomeIcon className={baseCss + '--statusNone'} icon={faCircle} />
  );
};
