/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AssessmentDeletePayload
 */
export interface AssessmentDeletePayload {
  /**
   *
   * @type {Array<string>}
   * @memberof AssessmentDeletePayload
   */
  notificationRecipients?: Array<string>;
}

export function AssessmentDeletePayloadFromJSON(
  json: any
): AssessmentDeletePayload {
  return AssessmentDeletePayloadFromJSONTyped(json, false);
}

export function AssessmentDeletePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssessmentDeletePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    notificationRecipients: !exists(json, 'notification_recipients')
      ? undefined
      : json['notification_recipients'],
  };
}

export function AssessmentDeletePayloadToJSON(
  value?: AssessmentDeletePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    notification_recipients: value.notificationRecipients,
  };
}
