import moment from 'moment';
import { AssessmentResponse } from '../swagger';
import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';

export const assessmentResponseSort = (
  a: AssessmentResponse,
  b: AssessmentResponse,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof AssessmentResponse =
    columnSort.name as keyof AssessmentResponse;

  switch (typedColumn) {
    case 'dueDate':
      return sort(moment(a.dueDate), moment(b.dueDate), direction);
    case 'updatedAt':
      return sort(moment(a.updatedAt), moment(b.updatedAt), direction);
    default:
      return sort(a[typedColumn], b[typedColumn], direction);
  }
};
