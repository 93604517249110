import {
  ControlCreatePayloadControl,
  ControlExtended,
  FrameworkRelation,
  ControlUpdatePayloadControl,
  FrameworkModel,
  Policy,
  TaskModel,
  VendorUser,
  ReviewStatusEnum,
  ReviewHistoryItemModel,
  VendorPolicy,
  ControlRiskModel,
} from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';

export const notCompliantText = 'Mark as Not Compliant';
export const compliantText = 'Mark as Compliant';

export enum DrawerType {
  Task,
  Control,
  Test,
}

export interface EditControlFormProperties {
  formValid: boolean;
  formTouched: boolean;
  create: boolean;
  reviewSubmitted: boolean; // deprecated field
}

export const minimumAssignedFrameworks = 1;

export interface ComplianceEditControlFormModel
  extends ControlExtended,
    EditControlFormProperties {}

export class EditControl implements ComplianceEditControlFormModel {
  constructor(controlExtended: ControlExtended) {
    this.formTouched = false;

    const control = controlExtended || EditControl.createNew();

    this.applicable = control.applicable;
    this.reasonForExclusion = control.reasonForExclusion;
    this.access = control.access;
    this.assignee = control.assignee;
    this.create = false;
    this.description = control.description;
    this.policies = control.policies;
    this.vendorPolicies = control.vendorPolicies;
    this.risks = control.risks;
    this.formValid = true;
    this.frameworksRelations = control.frameworksRelations
      ? [...control.frameworksRelations]
      : [];
    this.name = control.name;
    this.reviewStatus = control.reviewStatus;
    this.reviewSubmitted = false;
    this.nextReviewDate = control.nextReviewDate;
    this.lastReview = control.lastReview;
    this.tasks = control.tasks ? [...control.tasks] : [];
    this.treatmentPlan = control.treatmentPlan;
    this.helpArticleUrl = control.helpArticleUrl;
    this.id = control.id;
  }

  // ControlExtended
  frameworksRelations?: FrameworkRelation[];
  tasks?: TaskModel[];
  id: string;
  name: string;
  description: string;
  descriptionAsHtml: string;
  treatmentPlan?: string;
  treatmentPlanAsHtml?: string;
  helpArticleUrl?: string;
  applicable: boolean;
  policies: Policy[];
  vendorPolicies?: VendorPolicy[];
  risks?: ControlRiskModel[];
  assignee?: VendorUser;
  frameworks?: FrameworkModel[];
  reviewStatus?: ReviewStatusEnum;
  nextReviewDate?: string;
  lastReview?: ReviewHistoryItemModel;
  createdAt: string;
  updatedAt: string;
  reasonForExclusion?: string;
  access?: ControlExtended['access'];
  // ControlExtended

  // EditControlFormProperties
  formValid: boolean;
  formTouched: boolean;
  create: boolean;
  reviewSubmitted: boolean;
  // EditControlFormProperties

  simpleObject = () => {
    return {
      formValid: this.formValid,
      formTouched: this.formTouched,
      create: this.create,
      reviewSubmitted: this.reviewSubmitted,

      frameworksRelations: this.frameworksRelations
        ? [...this.frameworksRelations]
        : [],
      tasks: this.tasks ? [...this.tasks] : [],
      id: this.id,
      name: this.name,
      description: this.description,
      descriptionAsHtml: '',
      treatmentPlan: this.treatmentPlan,
      treatmentPlanAsHtml: '',
      helpArticleUrl: this.helpArticleUrl,
      applicable: this.applicable,
      assignee: this.assignee,
      frameworks: this.frameworks ? [...this.frameworks] : [],
      policies: this.policies ? [...this.policies] : [],
      vendorPolicies: this.vendorPolicies ? [...this.vendorPolicies] : [],
      reviewStatus: this.reviewStatus,
      nextReviewDate: this.nextReviewDate,
      lastReview: this.lastReview,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      reasonForExclusion: this.reasonForExclusion,
      access: this.access,
      risks: this.risks ? [...this.risks] : [],
    };
  };

  static createNew = () => {
    return {
      formValid: false,
      formTouched: false,
      create: true,
      reviewSubmitted: false,
      frameworksRelations: [] as FrameworkRelation[],
      tasks: [] as TaskModel[],
      id: '',
      name: '',
      description: '',
      descriptionAsHtml: '',
      treatmentPlan: '',
      treatmentPlanAsHtml: '',
      helpArticleUrl: '',
      applicable: true,
      assignee: {},
      frameworks: [] as FrameworkModel[],
      policies: [] as Policy[],
      vendorPolicies: [] as VendorPolicy[],
      risks: [] as ControlRiskModel[],
      nextReviewDate: '',
      lastReview: null as ReviewHistoryItemModel,
      createdAt: '',
      updatedAt: '',
      reasonForExclusion: '',
      access: null as ControlExtended['access'],
      reviewStatus: ReviewStatusEnum.AwaitingReview,
      reviewFrequency: null as any,
    };
  };
}

export interface ComplianceFilterPayload {
  assigneeId?: string;
  status?: string;
  applicable?: string;
}

const getPayload = (editControl: ComplianceEditControlFormModel) => {
  return {
    name: editControl.name,
    description: editControl.description,
    treatmentPlan: editControl.treatmentPlan,
    helpArticleUrl: editControl.helpArticleUrl,
    assigneeId: editControl.assignee?.id,
    nextReviewDate: editControl.nextReviewDate,
    reviewStatus: editControl.reviewStatus,
    tasksIds: editControl.tasks?.map((t: TaskModel) => t.id),
    policyCodes: (editControl.policies || []).map(policy => policy.code),
    vendorPolicyIds: (editControl.vendorPolicies || []).map(
      vendorPolicy => vendorPolicy.id
    ),
    frameworksRelations: editControl.frameworksRelations?.map(fwr => {
      return {
        controlCategoryId: fwr.controlCategory?.id,
        annexRef: fwr.annexRef,
      };
    }),
    applicable: editControl.applicable,
    reasonForExclusion: editControl.reasonForExclusion,
    risksIds: editControl.risks?.map(risk => risk.id),
  };
};

export const createPayload = (
  editControl: ComplianceEditControlFormModel
): ControlCreatePayloadControl => {
  return getPayload(editControl);
};

export const updatePayload = (
  editControl: ComplianceEditControlFormModel
): ControlUpdatePayloadControl => {
  return getPayload(editControl) as ControlUpdatePayloadControl;
};
