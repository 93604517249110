import { VendorPolicyDetails, VendorPolicyDetailsStatusEnum } from '../swagger';
import { vendorPolicyCalculations } from './vendorPolicyCalculations';

export const isVendorPolicyFilled = (policy: VendorPolicyDetails) => {
  if (!policy) return false;

  const { stats } = vendorPolicyCalculations(policy);
  const overallProgress =
    stats.reduce((acc, s) => acc + s[1], 0) / stats.length;

  return (
    overallProgress === 1 &&
    policy.status === VendorPolicyDetailsStatusEnum.InProgress
  );
};
