/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface CorrectiveActionModel
 */
export interface CorrectiveActionModel {
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  correctiveActions: string;
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  rootCause: string;
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  raisedBy: string;
  /**
   *
   * @type {VendorUser}
   * @memberof CorrectiveActionModel
   */
  owner: VendorUser;
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  actionType: CorrectiveActionModelActionTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof CorrectiveActionModel
   */
  completed?: boolean;
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  url?: string | null;
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  dateIdentified: string;
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof CorrectiveActionModel
   */
  updatedAt?: string;
}

export function CorrectiveActionModelFromJSON(
  json: any
): CorrectiveActionModel {
  return CorrectiveActionModelFromJSONTyped(json, false);
}

export function CorrectiveActionModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CorrectiveActionModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: json['description'],
    identifier: json['identifier'],
    correctiveActions: json['corrective_actions'],
    rootCause: json['root_cause'],
    raisedBy: json['raised_by'],
    owner: VendorUserFromJSON(json['owner']),
    actionType: json['action_type'],
    completed: !exists(json, 'completed') ? undefined : json['completed'],
    url: !exists(json, 'url') ? undefined : json['url'],
    dateIdentified: json['date_identified'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function CorrectiveActionModelToJSON(
  value?: CorrectiveActionModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    identifier: value.identifier,
    corrective_actions: value.correctiveActions,
    root_cause: value.rootCause,
    raised_by: value.raisedBy,
    owner: VendorUserToJSON(value.owner),
    action_type: value.actionType,
    completed: value.completed,
    url: value.url,
    date_identified: value.dateIdentified,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CorrectiveActionModelActionTypeEnum {
  NonConformance = 'non_conformance',
  OpportunityForImprovement = 'opportunity_for_improvement',
}
