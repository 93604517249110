import { AgreementModelSignatoriesStats } from '../swagger';
import { sort } from './sort';

export const sortBySignatoriesStats = (
  a: AgreementModelSignatoriesStats,
  b: AgreementModelSignatoriesStats,
  direction: 1 | -1
) => {
  return sort(
    getSignedRatio(a.total, a.signed),
    getSignedRatio(b.total, b.signed),
    direction
  );
};

const getSignedRatio = (total: number, signed: number) => {
  return total === 0 ? 0 : signed / total;
};
