import React from 'react';
import './StartFooter.scss';
import moment from 'moment/moment';

export const StartFooter: React.FC = () => {
  return (
    <div className="startFooter">
      <div className="startFooter--text">
        &copy; {moment().year()} Adoptech Limited
      </div>
    </div>
  );
};

export default StartFooter;
