import React, { useEffect } from 'react';
import { fetchTrustHubCompany } from '../../../../store/trusthubThunks';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../../types/applicationState';
import { selectCurrentVendor } from '../../../../../../selectors/selectCurrentVendor';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { useParams } from 'react-router';
import { TrusthubCompanyModel } from '../../../../../../swagger/trusthub';
import './TrustHubUserAdminCompanyPage.scss';
import { TrustHubUserAdminEditCompanyForm } from '../TrustHubUserAdminEditCompanyForm/TrustHubUserAdminEditCompanyForm';
import { TrustHubUserAdminApprovedUsers } from '../TrustHubUserAdminApprovedUsers/TrustHubUserAdminApprovedUsers';

export const TrustHubUserAdminCompanyPage: React.FC = () => {
  const baseCss = 'trusthubUserAdminCompanyPage';
  const {
    fetchingSettingsStatus,
    currentCompany: company,
    fetchingCurrentCompanyStatus,
  } = useSelector((state: ApplicationState) => state.trustHub);
  const dispatch = useDispatch();
  const vendor = useSelector(selectCurrentVendor);

  const params = useParams() as { id: TrusthubCompanyModel['id'] };

  useEffect(() => {
    dispatch(fetchTrustHubCompany({ companyId: params.id }, () => {}));
  }, [vendor.id]);
  if (
    fetchingSettingsStatus === 'loading' ||
    fetchingCurrentCompanyStatus === 'loading' ||
    !company
  ) {
    return <LoadingSpinner />;
  }

  return (
    <div className={baseCss}>
      <TrustHubUserAdminEditCompanyForm />
      <TrustHubUserAdminApprovedUsers />
    </div>
  );
};
