import { faArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTableSort } from '../../store/global/globalSlice';
import { ApplicationState } from '../../types/applicationState';
import { Grid } from '../../types/grid';
import { SortDirection } from '../../types/sortDirection';
import './SortableTableHeader.scss';

interface SortableTableHeaderProps<T> {
  className?: string;
  columnName: Extract<keyof T, string>;
  grid: Grid | string;
  label: string;
  notInTable?: boolean;
  disabledSort?: boolean;
}

export const SortableTableHeader = <T,>(
  props: SortableTableHeaderProps<T>
): React.ReactElement | null => {
  const [hovered, setHovered] = useState(false);

  const sortableTableHeaderClassNames = classNames(
    'sortableTableHeader',
    props.className,
    {
      'sortableTableHeader--noSorting': props.disabledSort,
    }
  );

  let columnName: string;
  let direction: SortDirection;

  const existingSort = useSelector((state: ApplicationState) =>
    state.global.sortSettings.find(ss => ss.grid === props.grid)
  );

  if (existingSort) {
    columnName = existingSort.columnSort.name;
    direction = existingSort.columnSort.direction;
  }

  const isAscending =
    columnName === props.columnName && direction === SortDirection.Ascending;

  const isDescending =
    (columnName === props.columnName &&
      direction === SortDirection.Descending) ||
    (!isAscending && hovered);

  const dispatch = useDispatch();

  const Component = props.notInTable ? 'div' : 'th';

  return (
    <Component
      className={sortableTableHeaderClassNames}
      onClick={() =>
        dispatch(
          setTableSort({ grid: props.grid, columnName: props.columnName })
        )
      }
    >
      <div
        className="sortableTableHeader--header"
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        <div className="sortableTableHeader--label">{props.label}</div>
        {!props.disabledSort && (
          <div className="sortableTableHeader--icon">
            {isDescending && <FontAwesomeIcon icon={faArrowDown} />}
            {isAscending && <FontAwesomeIcon icon={faArrowUp} />}
          </div>
        )}
      </div>
    </Component>
  );
};
