/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TaskListMetaStats
 */
export interface TaskListMetaStats {
  /**
   *
   * @type {number}
   * @memberof TaskListMetaStats
   */
  tasksTotal?: number;
  /**
   *
   * @type {number}
   * @memberof TaskListMetaStats
   */
  testsTotal?: number;
  /**
   *
   * @type {number}
   * @memberof TaskListMetaStats
   */
  testsPassing?: number;
  /**
   *
   * @type {number}
   * @memberof TaskListMetaStats
   */
  testsFailing?: number;
  /**
   *
   * @type {number}
   * @memberof TaskListMetaStats
   */
  testsPending?: number;
}

export function TaskListMetaStatsFromJSON(json: any): TaskListMetaStats {
  return TaskListMetaStatsFromJSONTyped(json, false);
}

export function TaskListMetaStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TaskListMetaStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tasksTotal: !exists(json, 'tasks_total') ? undefined : json['tasks_total'],
    testsTotal: !exists(json, 'tests_total') ? undefined : json['tests_total'],
    testsPassing: !exists(json, 'tests_passing')
      ? undefined
      : json['tests_passing'],
    testsFailing: !exists(json, 'tests_failing')
      ? undefined
      : json['tests_failing'],
    testsPending: !exists(json, 'tests_pending')
      ? undefined
      : json['tests_pending'],
  };
}

export function TaskListMetaStatsToJSON(value?: TaskListMetaStats | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tasks_total: value.tasksTotal,
    tests_total: value.testsTotal,
    tests_passing: value.testsPassing,
    tests_failing: value.testsFailing,
    tests_pending: value.testsPending,
  };
}
