import { SurveyClause, AgreementExtended } from '../../../../swagger';
import React from 'react';
import Accordion from 'react-bootstrap/esm/Accordion';
import { AdoptechAccordionCard } from '../../../AdoptechAccordionCard/AdoptechAccordionCard';
import { AgreementClauseHeader } from '../../AgreementClauseHeader/AgreementClauseHeader';
import { AgreementClauseQuestions } from '../../AgreementClauseQuestions/AgreementClauseQuestions';
import { useClauseProgress } from './useClauseProgress';

export const QuestionsMode: React.FC = () => {
  const {
    currentModel,
    getFirstClauseWithQuestions,
    interactiveSurveyClauses,
    clauseProgress,
  } = useClauseProgress('agreements');

  return (
    <Accordion defaultActiveKey={getFirstClauseWithQuestions()}>
      {interactiveSurveyClauses.map((clause: SurveyClause, index) => (
        <AdoptechAccordionCard
          index={index.toString()}
          key={clause.id}
          title={
            <AgreementClauseHeader
              agreementClause={clause}
              clauseProgress={clauseProgress(clause)}
            />
          }
          nested
          nestedVerticalOffset={-245}
          headerOffset={101}
        >
          <AgreementClauseQuestions
            agreement={currentModel as AgreementExtended}
            agreementClause={clause}
          />
        </AdoptechAccordionCard>
      ))}
    </Accordion>
  );
};
