/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Office
 */
export interface Office {
  /**
   *
   * @type {string}
   * @memberof Office
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Office
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Office
   */
  purpose?: string | null;
  /**
   *
   * @type {string}
   * @memberof Office
   */
  address1?: string;
  /**
   *
   * @type {string}
   * @memberof Office
   */
  address2?: string;
  /**
   *
   * @type {string}
   * @memberof Office
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof Office
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof Office
   */
  postCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof Office
   */
  country?: string | null;
  /**
   *
   * @type {string}
   * @memberof Office
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof Office
   */
  updatedAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof Office
   */
  editable?: boolean;
}

export function OfficeFromJSON(json: any): Office {
  return OfficeFromJSONTyped(json, false);
}

export function OfficeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Office {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    purpose: !exists(json, 'purpose') ? undefined : json['purpose'],
    address1: !exists(json, 'address_1') ? undefined : json['address_1'],
    address2: !exists(json, 'address_2') ? undefined : json['address_2'],
    city: !exists(json, 'city') ? undefined : json['city'],
    state: !exists(json, 'state') ? undefined : json['state'],
    postCode: !exists(json, 'post_code') ? undefined : json['post_code'],
    country: !exists(json, 'country') ? undefined : json['country'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    editable: !exists(json, 'editable') ? undefined : json['editable'],
  };
}

export function OfficeToJSON(value?: Office | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    purpose: value.purpose,
    address_1: value.address1,
    address_2: value.address2,
    city: value.city,
    state: value.state,
    post_code: value.postCode,
    country: value.country,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    editable: value.editable,
  };
}
