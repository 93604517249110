import classNames from 'classnames';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { NoDataText } from '../NoDataText/NoDataText';
import { MIN_SEARCH_LENGTH } from './GlobalSearchBar';
import './GlobalSearch.scss';

interface GlobalSearchResultsProps {
  inputText: string;
  baseCss: string;
}

export const GlobalSearchResults: React.FC<GlobalSearchResultsProps> = ({
  inputText,
  baseCss,
}) => {
  const dispatch = useDispatch();

  const results = useSelector(
    (state: ApplicationState) => state.global.globalSearchResults
  );

  const globalSearchInProgress = useSelector(
    (state: ApplicationState) => state.global.globalSearchInProgress
  );

  const searchResultClasses = classNames({
    [`${baseCss}--results`]: true,
  });

  return (
    <>
      {inputText && (
        <div className={searchResultClasses}>
          {inputText.length < MIN_SEARCH_LENGTH ? (
            <NoDataText text="Minimum 3 characters are required." />
          ) : globalSearchInProgress ? (
            <LoadingSpinner />
          ) : results.length > 0 ? (
            <>
              <div className={baseCss + '--resultHeader'}></div>
              <div className={baseCss + '--resultRows'}>
                <br />
                {results.map(item => {
                  return (
                    <div
                      className={baseCss + '--resultRow'}
                      key={item.searchableId}
                      onClick={() => {
                        dispatch(push(item.url));
                      }}
                    >
                      {item.name}
                    </div>
                  );
                })}
                <br />
              </div>
              <div className={baseCss + '--resultFooter'}></div>
            </>
          ) : (
            <NoDataText text="No results found" />
          )}
        </div>
      )}
    </>
  );
};
export default GlobalSearchResults;
