import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import getFilteredQuestionsWithoutTableQuestions from '../../functions/getFilteredQuestionsWithoutTableQuestions';
import { AssessmentResponse } from '../../swagger';
import { COMPLETED_TEXT } from '../../types/constants';
import { AddFileOrUrlModal } from '../AddFileOrUrlModal/AddFileOrUrlModal';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { AssessmentQuestion } from '../AssessmentQuestion/AssessmentQuestion';
import { AssessmentQuestionHeader } from '../AssessmentQuestionHeader/AssessmentQuestionHeader';
import { assessmentResponseContext } from '../AssessmentSummaryTile/AssessmentSummaryTile';
import './AssessmentQuestions.scss';

interface AssessmentQuestionProps {
  assessment: AssessmentResponse;
}

export const AssessmentQuestions: React.FC<AssessmentQuestionProps> = ({
  assessment,
}) => {
  const isAssessmentCompleted = assessment.status === COMPLETED_TEXT;

  const context = assessmentResponseContext(assessment);

  return (
    <React.Fragment>
      <div className="assessmentQuestions">
        <div className="assessmentQuestions--header">
          <div className="flex3">Category</div>
          <div className="flex2">Answered</div>
          <div className="flex2">Unanswered</div>
          <div className="flex5">Progress</div>
        </div>
        <Accordion defaultActiveKey="0">
          {assessment?.assessmentSections?.map((section, index) => (
            <React.Fragment key={section.title}>
              <AdoptechAccordionCard
                index={index.toString()}
                title={<AssessmentQuestionHeader section={section} />}
                hasDoneButton={!isAssessmentCompleted}
              >
                {getFilteredQuestionsWithoutTableQuestions(
                  section,
                  context
                )?.map(question => (
                  <AssessmentQuestion
                    key={question.id}
                    assessmentResponseId={assessment.id}
                    question={question}
                    questions={section.questions}
                    answers={assessment.assessmentResponseAnswers}
                    readonly={isAssessmentCompleted}
                  />
                ))}
                <div className="assessmentQuestions--footer" />
              </AdoptechAccordionCard>
            </React.Fragment>
          ))}
        </Accordion>
      </div>

      <AddFileOrUrlModal />
    </React.Fragment>
  );
};
