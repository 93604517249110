import React, { useReducer, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideAddPaymentMethodModal,
  setCurrentPaymentMethodId,
} from '../../store/user/userSlice';
import {
  addPaymentMethod,
  updatePaymentMethod,
} from '../../store/user/userThunks';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { AdoptechCountrySelect } from '../AdoptechCountrySelect/AdoptechCountrySelect';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import {
  PaymentMethodActionType,
  paymentMethodReducer,
  initialState,
} from './AddPaymentMethod.reducer';
import './AddPaymentMethodModal.scss';
import { PaymentMethodModalMode } from '../../store/user/userState';
import { selectCurrentPaymentMethod } from '../../selectors/selectCurrentPaymentMethod';

export const AddPaymentMethodModal: React.FC = () => {
  const reduxDispatch = useDispatch();

  const isAddPaymentMethodModalShowing = useSelector(
    (state: ApplicationState) => state.user.isAddPaymentMethodModalShowing
  );
  const isAddingPaymentMethod = useSelector(
    (state: ApplicationState) => state.user.isAddingPaymentMethod
  );

  const isUpdatingPaymentMethod = useSelector(
    (state: ApplicationState) => state.user.isUpdatingPaymentMethod
  );

  const mode = useSelector(
    (state: ApplicationState) => state.user.paymentMethodModalMode
  );

  const isAddMode = mode === PaymentMethodModalMode.Add;

  const [paymentMethodState, dispatch] = useReducer(
    paymentMethodReducer,
    initialState
  );

  const handleAdd = () => {
    reduxDispatch(
      addPaymentMethod({
        card: {
          cvc: paymentMethodState.card_cvc,
          cardNo: paymentMethodState.card_number,
          expMonth: paymentMethodState.card_exp_month,
          expYear: paymentMethodState.card_exp_year,
          billingDetails: {
            name: paymentMethodState.name || null,
            address: {
              city: paymentMethodState.address_city || null,
              country: paymentMethodState.address_country || null,
              line1: paymentMethodState.address_line1 || null,
              line2: paymentMethodState.address_line2 || null,
              state: paymentMethodState.address_state || null,
              postalCode: paymentMethodState.address_zip || null,
            },
          },
        },
      })
    );
  };

  const handleUpdate = () => {
    reduxDispatch(
      updatePaymentMethod({
        card: {
          expMonth: paymentMethodState.card_exp_month,
          expYear: paymentMethodState.card_exp_year,
        },
        id: currentId,
      })
    );
  };

  const handleCancel = () => {
    reduxDispatch(hideAddPaymentMethodModal());

    reduxDispatch(setCurrentPaymentMethodId());
    dispatch({
      type: PaymentMethodActionType.Cleanup,
    });
  };

  const handleChange = (event: {
    currentTarget: { id: string; value: string };
  }) => {
    dispatch({
      type: PaymentMethodActionType.Change,
      payload: { [event.currentTarget.id]: event.currentTarget.value },
    });
  };

  const setCountry = (option: SelectionOption) => {
    dispatch({
      type: PaymentMethodActionType.Change,
      payload: { address_country: option.value },
    });
  };

  const formIsValid = () => {
    const { card_number, card_cvc, card_exp_month, card_exp_year } =
      paymentMethodState;

    if (!isAddMode) return card_exp_month && card_exp_year;

    return card_number && card_cvc && card_exp_month && card_exp_year;
  };

  const currentPaymentMethod = useSelector(selectCurrentPaymentMethod);
  const currentId = currentPaymentMethod?.id;

  useEffect(() => {
    if (!currentPaymentMethod) return;

    const { address } = currentPaymentMethod.billingDetails;

    dispatch({
      type: PaymentMethodActionType.Change,
      payload: {
        card_exp_month: currentPaymentMethod.card.expMonth,
        card_exp_year: currentPaymentMethod.card.expYear?.toString().slice(-2),
        name: currentPaymentMethod.billingDetails.name,
        address_line1: address.line1,
        address_line2: address.line2,
        address_city: address.city,
        address_state: address.state,
        address_zip: address.postalCode,
        address_country: address.country,
      },
    });
  }, [currentId]);

  return (
    <React.Fragment>
      <AdoptechModal
        onHide={handleCancel}
        show={isAddPaymentMethodModalShowing}
      >
        <Modal.Header>
          {isAddMode ? 'Add new payment method' : 'Edit expiration date'}{' '}
        </Modal.Header>
        <Modal.Body className="addPaymentMethodModal--body">
          <div className="addPaymentMethodModal--section">
            {isAddMode && (
              <AdoptechTextInput
                id="card_number"
                label="Card information*"
                onChange={handleChange}
                type="text"
                placeholder="1234 1234 1234 1234"
                value={paymentMethodState.card_number}
              />
            )}
            {!isAddMode && (
              <div className="addPaymentMethodModal--label">
                Expiration date*
              </div>
            )}
            <div className="addPaymentMethodModal--row">
              <div className="addPaymentMethodModal--row">
                <AdoptechTextInput
                  id="card_exp_month"
                  onChange={handleChange}
                  maxLength={2}
                  placeholder="MM"
                  type="text"
                  value={paymentMethodState.card_exp_month}
                />
                <AdoptechTextInput
                  id="card_exp_year"
                  maxLength={2}
                  onChange={handleChange}
                  type="text"
                  placeholder="YY"
                  value={paymentMethodState.card_exp_year}
                />
              </div>
              {isAddMode && (
                <AdoptechTextInput
                  id="card_cvc"
                  maxLength={4}
                  onChange={handleChange}
                  type="text"
                  placeholder="CVC"
                  value={paymentMethodState.card_cvc}
                />
              )}
            </div>
          </div>
          <div className="addPaymentMethodModal--section">
            <AdoptechTextInput
              id="name"
              label="Name on card"
              onChange={handleChange}
              type="text"
              value={paymentMethodState.name}
              disabled={!isAddMode}
            />
          </div>
          <div className="addPaymentMethodModal--section">
            <AdoptechTextInput
              id="address_line1"
              label="Billing address"
              placeholder="Address line 1"
              onChange={handleChange}
              type="text"
              value={paymentMethodState.address_line1}
              disabled={!isAddMode}
            />
            <AdoptechTextInput
              id="address_line2"
              placeholder="Address line 2"
              onChange={handleChange}
              type="text"
              value={paymentMethodState.address_line2}
              disabled={!isAddMode}
            />
            <div className="addPaymentMethodModal--row">
              <AdoptechTextInput
                id="address_city"
                placeholder="Town or city"
                onChange={handleChange}
                type="text"
                value={paymentMethodState.address_city}
                disabled={!isAddMode}
              />
              <AdoptechTextInput
                id="address_zip"
                placeholder="Postal code"
                onChange={handleChange}
                type="text"
                value={paymentMethodState.address_zip}
                disabled={!isAddMode}
              />
            </div>
            <AdoptechCountrySelect
              value={paymentMethodState.address_country}
              onChange={setCountry}
              placeholder="Country"
              disabled={!isAddMode}
            />
          </div>
          <div className="addPaymentMethodModal--label">*Required fields</div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={handleCancel}>Cancel</AdoptechButton>
          <AdoptechButton
            busy={isAddingPaymentMethod || isUpdatingPaymentMethod}
            disabled={!formIsValid()}
            onClick={isAddMode ? handleAdd : handleUpdate}
            variant={AdoptechButtonVariant.Primary}
          >
            {isAddMode ? 'ADD' : 'UPDATE'}
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
