import React from 'react';
import classNames from 'classnames';
import {
  AgreementSignatoryModel,
  AgreementSignatoryModelStatusEnum,
} from '../../../../../swagger/models/AgreementSignatoryModel';
import { SignatoriesMeatballMenu } from './SignatoriesMeatballMenu';
import { capitaliseFirst } from '../../../../../functions/capitaliseFirst';
import { useDispatch, useSelector } from 'react-redux';
import { updateSignatory } from '../../../../../store/agreements/agreementsSlice';
import '../../EditAgreementForm.scss';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../AdoptechButton/AdoptechButton';
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons/faCloudArrowUp';
import { PdfOpenMode } from '../../../../../types/pdfOpenMode';
import { DocumentType } from '../../../../../constants/DocumentType';
import { openPdfPreview } from '../../../../../store/global/globalSlice';
import { ApplicationState } from '../../../../../types/applicationState';
import { UserAvatar } from '../../../../UserAvatar/UserAvatar';
import AdoptechOverflowLine from '../../../../AdoptechOverflowLine/AdoptechOverflowLine';
import { signatoryUser } from '../../../AgreementSendReviewModal/AgreementSendReviewModal';

interface ExternalSignatoriesProps {
  externalSignatories: AgreementSignatoryModel[];
  onSendReminderClick(model: AgreementSignatoryModel): void;
  onUploadClick(model: AgreementSignatoryModel): void;
  isDraft: boolean;
}

export const ExternalSignatories: React.FC<ExternalSignatoriesProps> = ({
  externalSignatories,
  onSendReminderClick,
  onUploadClick,
  isDraft,
}) => {
  const baseCss = 'editAgreementForm';
  const dispatch = useDispatch();

  const form = useSelector(
    (state: ApplicationState) => state.agreements.agreementsEditForm
  );

  const signatories = form.signatoriesWithDocs || [];

  if (!externalSignatories) {
    return null;
  }

  return (
    <>
      {externalSignatories.map(signatory => {
        const showUploadButton =
          signatory.status ===
            AgreementSignatoryModelStatusEnum.SignedOffline &&
          !signatory.document;

        const user = signatoryUser(signatory);

        return (
          <div key={signatory.id} className={baseCss + '--external-table-row'}>
            <div
              className={classNames(`${baseCss}--table-row`, 'mt-1', 'pb-2', {
                [`${baseCss}--4-columns`]: showUploadButton,
                [`${baseCss}--3-columns`]: !showUploadButton,
              })}
            >
              <AdoptechOverflowLine>
                <UserAvatar user={user} size="small" />
              </AdoptechOverflowLine>
              <div className={baseCss + '--table-column'}>
                {isDraft
                  ? ''
                  : signatory.status ===
                      AgreementSignatoryModelStatusEnum.NotRequired
                    ? 'Marked as Signature not required'
                    : capitaliseFirst(signatory.status)}
              </div>
              {showUploadButton && (
                <div>
                  <div className={baseCss + '--upload-button'}>
                    <AdoptechButton
                      icon={faCloudArrowUp}
                      onClick={() => onUploadClick(signatory)}
                      variant={AdoptechButtonVariant.PrimaryTransparent}
                    >
                      Upload
                    </AdoptechButton>
                  </div>
                </div>
              )}
              <div>
                <SignatoriesMeatballMenu
                  signatory={signatory}
                  onSendReminderClick={(model: AgreementSignatoryModel) =>
                    onSendReminderClick(model)
                  }
                  onSignedOfflineClick={() =>
                    dispatch(
                      updateSignatory({
                        ...signatory,
                        status: AgreementSignatoryModelStatusEnum.SignedOffline,
                      })
                    )
                  }
                  onSignatureNotRequiredClick={() =>
                    dispatch(
                      updateSignatory({
                        ...signatory,
                        status: AgreementSignatoryModelStatusEnum.NotRequired,
                      })
                    )
                  }
                  onUploadClick={() => onUploadClick(signatory)}
                  onViewClick={() => {
                    const uploadedButNotSavedDocument: Blob = signatories.find(
                      model => model.id === signatory.id
                    )?.documentBinary;

                    const savedDocument = signatory.document;
                    const url = uploadedButNotSavedDocument
                      ? URL.createObjectURL(uploadedButNotSavedDocument)
                      : savedDocument;

                    dispatch(
                      openPdfPreview({
                        mode: PdfOpenMode.Preview,
                        documentType: DocumentType.AgreementSignatory,
                        title: `Signed Offline Document for ${form.name}`,
                        documentableId: signatory.id,
                        navigateUrlOnClose: null,
                        url,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
