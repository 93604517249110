/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  RiskCategoryModelRiskStats,
  RiskCategoryModelRiskStatsFromJSON,
  RiskCategoryModelRiskStatsFromJSONTyped,
  RiskCategoryModelRiskStatsToJSON,
} from './';

/**
 *
 * @export
 * @interface RiskCategoryModel
 */
export interface RiskCategoryModel {
  /**
   *
   * @type {string}
   * @memberof RiskCategoryModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RiskCategoryModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RiskCategoryModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof RiskCategoryModel
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof RiskCategoryModel
   */
  createdAt?: string;
  /**
   *
   * @type {number}
   * @memberof RiskCategoryModel
   */
  rowOrder: number;
  /**
   *
   * @type {string}
   * @memberof RiskCategoryModel
   */
  updatedAt?: string;
  /**
   *
   * @type {RiskCategoryModelRiskStats}
   * @memberof RiskCategoryModel
   */
  riskStats?: RiskCategoryModelRiskStats;
}

export function RiskCategoryModelFromJSON(json: any): RiskCategoryModel {
  return RiskCategoryModelFromJSONTyped(json, false);
}

export function RiskCategoryModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskCategoryModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: json['description'],
    identifier: json['identifier'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    rowOrder: json['row_order'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    riskStats: !exists(json, 'risk_stats')
      ? undefined
      : RiskCategoryModelRiskStatsFromJSON(json['risk_stats']),
  };
}

export function RiskCategoryModelToJSON(value?: RiskCategoryModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    identifier: value.identifier,
    created_at: value.createdAt,
    row_order: value.rowOrder,
    updated_at: value.updatedAt,
    risk_stats: RiskCategoryModelRiskStatsToJSON(value.riskStats),
  };
}
