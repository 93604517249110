import { createSelector } from '@reduxjs/toolkit';
import Fuse from 'fuse.js';
import { sort } from '../../functions/sort';
import { RiskCategoryModel } from '../../swagger/models/RiskCategoryModel';
import { RiskModel } from '../../swagger/models/RiskModel';
import { RiskTemplateModel } from '../../swagger/models/RiskTemplateModel';
import { ApplicationState } from '../../types/applicationState';
import { ColumnSort } from '../../types/columnSort';
import { Grid } from '../../types/grid';
import { selectSortSettings } from '../selectSortSettings';

const sortRisksTemplates = (
  a: RiskTemplateModel,
  b: RiskTemplateModel,
  columnSort: ColumnSort
) => {
  const typedColumn = columnSort.name as keyof RiskTemplateModel;
  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

export const filteredByTextRisksTemplates = (
  items: RiskTemplateModel[],
  searchText: string
) => {
  if (!items) return [];
  if (!searchText) return items;

  const fuseTask = new Fuse(items, {
    ignoreLocation: true,
    includeScore: true,
    keys: ['name', 'description'],
    threshold: 0,
  });
  return fuseTask.search(searchText).map(x => x.item);
};

export const selectRisksTemplates = (
  searchText: string,
  paramsId: RiskCategoryModel['id']
) =>
  createSelector(
    (state: ApplicationState) => state.riskRegistry.risks,
    (state: ApplicationState) => state.riskRegistry.templates,
    selectSortSettings(Grid.RisksTemplates),
    (risks: RiskModel[], templates: RiskTemplateModel[], sortSetting) => {
      const categoryTemplates = templates.filter(
        template => !paramsId || template.riskCategoryId === paramsId
      );

      const templatesIds = risks.map(risk => risk.riskTemplateId);
      const notUsedTemplates = categoryTemplates.filter(
        template => !templatesIds.includes(template.id)
      );

      return [
        ...filteredByTextRisksTemplates(notUsedTemplates, searchText),
      ].sort((a, b) => sortRisksTemplates(a, b, sortSetting.columnSort));
    }
  );
