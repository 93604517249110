/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssertionCategoryModel,
  AssertionCategoryModelFromJSON,
  AssertionCategoryModelFromJSONTyped,
  AssertionCategoryModelToJSON,
  AssertionExtendedAllOf,
  AssertionExtendedAllOfFromJSON,
  AssertionExtendedAllOfFromJSONTyped,
  AssertionExtendedAllOfToJSON,
  AssertionFrequency,
  AssertionFrequencyFromJSON,
  AssertionFrequencyFromJSONTyped,
  AssertionFrequencyToJSON,
  AssertionModel,
  AssertionModelFromJSON,
  AssertionModelFromJSONTyped,
  AssertionModelToJSON,
  AssertionResultExtended,
  AssertionResultExtendedFromJSON,
  AssertionResultExtendedFromJSONTyped,
  AssertionResultExtendedToJSON,
  AssertionSource,
  AssertionSourceFromJSON,
  AssertionSourceFromJSONTyped,
  AssertionSourceToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface AssertionExtended
 */
export interface AssertionExtended extends AssertionModel {
  /**
   *
   * @type {boolean}
   * @memberof AssertionExtended
   */
  overdue?: boolean;
  /**
   *
   * @type {VendorUser}
   * @memberof AssertionExtended
   */
  owner?: VendorUser;
  /**
   *
   * @type {AssertionCategoryModel}
   * @memberof AssertionExtended
   */
  assertionCategory?: AssertionCategoryModel;
  /**
   *
   * @type {Array<AssertionResultExtended>}
   * @memberof AssertionExtended
   */
  latestRunResults?: Array<AssertionResultExtended>;
}

export function AssertionExtendedFromJSON(json: any): AssertionExtended {
  return AssertionExtendedFromJSONTyped(json, false);
}

export function AssertionExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...AssertionModelFromJSONTyped(json, ignoreDiscriminator),
    overdue: !exists(json, 'overdue') ? undefined : json['overdue'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    assertionCategory: !exists(json, 'assertion_category')
      ? undefined
      : AssertionCategoryModelFromJSON(json['assertion_category']),
    latestRunResults: !exists(json, 'latest_run_results')
      ? undefined
      : (json['latest_run_results'] as Array<any>).map(
          AssertionResultExtendedFromJSON
        ),
  };
}

export function AssertionExtendedToJSON(value?: AssertionExtended | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...AssertionModelToJSON(value),
    overdue: value.overdue,
    owner: VendorUserToJSON(value.owner),
    assertion_category: AssertionCategoryModelToJSON(value.assertionCategory),
    latest_run_results:
      value.latestRunResults === undefined
        ? undefined
        : (value.latestRunResults as Array<any>).map(
            AssertionResultExtendedToJSON
          ),
  };
}
