import React from 'react';
import { AssertionResultExtended } from '../../../swagger';
import './TestResult.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { Interweave } from 'interweave';

interface Props {
  result: AssertionResultExtended;
}

const baseCss = 'testResult';

export const testResultEvidences = (result: AssertionResultExtended) => {
  return result.evidences.map(evidence => (
    <div key={evidence.id}>
      {evidence.filename}
      &nbsp;
      <a href={evidence.url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faCloudDownload} />
      </a>
    </div>
  ));
};

export const TestResult: React.FC<Props> = ({ result }) => {
  if (result.evidences.length > 0) {
    return (
      <>
        <strong>Comment</strong>
        <div className={baseCss + '--comment'}>
          <Interweave content={result.comment} />
        </div>

        <div className={baseCss + '--evidences'}>
          <strong>Evidence</strong>
          {testResultEvidences(result)}
        </div>
      </>
    );
  }
  return (
    <div className={baseCss + '--payload'}>
      <strong>JSON Response</strong>
      <pre>{result.payload}</pre>
    </div>
  );
};
