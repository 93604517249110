import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AuditExtended,
  Question,
  QuestionLayoutEnum,
} from '../../../../../swagger';
import './AuditQuestion.scss';
import { Interweave } from 'interweave';
import { AnswerFormControl } from '../../../../../components/AnswerFormControl/AnswerFormControl';
import { LastUpdatedInfo } from '../../../../../components/LastUpdatedInfo/LastUpdatedInfo';
import { useHandleAnswerChange } from '../../../../../components/Agreements/AgreementQuestion/useHandleAnswerChange';
import { useLastUpdated } from '../../../../../components/Agreements/AgreementQuestion/AgreementQuestion';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { AdoptechButton } from '../../../../../components/AdoptechButton/AdoptechButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectCurrentVendorUser } from '../../../../../selectors/selectCurrentVendorUser';
import { AutoSaveToast } from '../../../../../components/AutoSaveToast/AutoSaveToast';
import { addToggleClass, isMyAnswer } from '../../AuditReviewTableAnswer';
import { fetchAnswerControl } from '../../store/auditsThunks';
import classNames from 'classnames';
import { AuditsReviewHistoryDropdown } from '../../AuditsReviewHistoryDropdown';

interface AuditQuestionProps {
  question: Question;
  audit: AuditExtended;
}

export const AuditQuestion: React.FC<AuditQuestionProps> = ({
  question,
  audit,
}) => {
  const lastUpdated = useLastUpdated(question);

  const { handleChange, handleDeleteTableAnswers } = useHandleAnswerChange({
    currentModel: audit,
    mode: 'audits',
    question,
  });

  const currentVendorUser = useSelector(selectCurrentVendorUser);

  const dispatch = useDispatch();
  const answerRef = useRef<HTMLDivElement>();
  const isAuditTable =
    question.layout === QuestionLayoutEnum.AuditReviewTableLayout;

  const emitChange = () => {
    // race condition hack gives the DOM element time to fall into place
    setTimeout(() => answerRef.current.querySelector('textarea')?.focus(), 200);
  };

  const onlyTitleExist = !question.explanatoryTextAsHtml;
  return (
    <div className="agreementQuestion">
      <div className="agreementQuestion--header">
        <div
          className={classNames('agreementQuestion--questionArea', {
            'agreementQuestion--questionArea-centered': onlyTitleExist,
          })}
        >
          <div
            className="agreementQuestion--questionText"
            style={{ display: 'flex' }}
          >
            {question.annexRef && (
              <span style={{ marginRight: '5px' }}>
                <Interweave content={question.annexRef + ' '} />
              </span>
            )}
            <Interweave content={question.questionTextAsHtml} />
          </div>
          {question.explanatoryTextAsHtml && (
            <div className="agreementQuestion--explanatoryText">
              <Interweave content={question.explanatoryTextAsHtml} />
            </div>
          )}
        </div>
        {isAuditTable && (
          <div className="agreementQuestion--rightButtons">
            <AdoptechButton
              extraClass="mr-2"
              disabled={!question.control}
              onClick={() => {
                dispatch(fetchAnswerControl(question.control?.identifier));
              }}
            >
              <FontAwesomeIcon className="mr-2" icon={faEye} />
              Evidence
            </AdoptechButton>
            <AuditsReviewHistoryDropdown
              auditId={audit.id}
              questionId={question.id}
              onClick={emitChange}
            />
            <AdoptechButton
              disabled={question.table.answers
                .flat()
                .some(answer => isMyAnswer(answer, currentVendorUser))}
              onClick={() => {
                const btn = answerRef.current.parentElement.querySelector(
                  `.${addToggleClass}`
                ) as HTMLElement | null;

                btn?.click();
              }}
            >
              <FontAwesomeIcon className="mr-2" icon={faPlus} />
              Review
            </AdoptechButton>
          </div>
        )}
      </div>
      <div className="agreementQuestion--answerArea" ref={answerRef}>
        <AnswerFormControl
          onDeleteTable2Answers={handleDeleteTableAnswers}
          usingNewSurveyStructure
          answers={[]}
          question={question}
          questions={[]}
          onChange={handleChange}
          previousAnswer={question.answer}
          indexProperty="propertyName"
        />
      </div>
      {lastUpdated && <LastUpdatedInfo lastUpdatedInfo={lastUpdated} />}
      <AutoSaveToast />
    </div>
  );
};
