import { api } from '../../api/api';
import {
  AssertionsAssertionIdGetRequest,
  AssertionsAssertionIdPatchRequest,
  AssertionsAssertionIdRunPostRequest,
  ControlCreatePayloadControl,
  ControlExtended,
  ControlUpdatePayloadControl,
  EvidenceModel,
  FrameworkModel,
  FrameworkRequirementModel,
  FrameworkTemplateModel,
  ReviewHistoryItemCreatePayloadReviewHistoryItem,
  ReviewHistoryItemModel,
  TaskCreatePayloadTask,
  TaskExtended,
  TaskModel,
  TasksTaskIdEvidencesPostRequest,
  TaskUpdatePayloadTask,
  Vendor,
  VendorDocument,
  TasksTaskIdAvailableAssertionsPatchRequest,
  VendorsVendorIdControlsGetRequest,
  VendorsVendorIdReviewHistoryItemsGetRelatedToTypeEnum,
  AssertionsAssertionIdCreateReviewPostRequest,
  AssertionResultsAssertionResultIdIncludePatchRequest,
  AssertionResultsAssertionResultIdExcludePatchRequest,
  AssertionExtendedFromJSON,
  FrameworksIdExportEvidencePostRequest,
  VendorsVendorIdDataRoomEvidenceGetRequest,
  EvidencesEvidenceIdDownloadGetRequest,
} from '../../swagger';
import { VoidThunk } from '../../types/voidThunk';
import { getErrorMessage, handleThunkError } from '../helpers/thunkHelpers';
import {
  addApplicableControl,
  addNotApplicableControl,
  createControlError,
  createControlRequest,
  createControlSuccess,
  createVendorRequirementSuccess,
  fetchAssignableComplianceTasksError,
  fetchAssignableComplianceTasksRequest,
  fetchAssignableComplianceTasksSuccess,
  fetchCategoriesError,
  fetchCategoriesRequest,
  fetchCategoriesSuccess,
  fetchFrameworksError,
  fetchFrameworksRequest,
  fetchFrameworksSuccess,
  fetchRequirementsRequest,
  fetchRequirementsSuccess,
  fetchNotApplicableControlsError,
  fetchNotApplicableControlsRequest,
  fetchNotApplicableControlsSuccess,
  fetchTaskDetailsError,
  fetchTaskDetailsRequest,
  fetchTaskDetailsSuccess,
  fetchTasksError,
  fetchTasksRequest,
  fetchTasksSuccess,
  markControlApplicableFailure,
  markControlApplicableRequest,
  markControlApplicableSuccess,
  markControlNotApplicableFailure,
  markControlNotApplicableRequest,
  markControlNotApplicableSuccess,
  patchControlError,
  patchControlRequest,
  patchControlSuccess,
  patchTaskError,
  patchTaskRequest,
  patchTaskSuccess,
  postTaskError,
  postTaskRequest,
  postTaskSuccess,
  postTaskEvidenceError,
  postTaskEvidenceRequest,
  postTaskEvidenceSuccess,
  fetchControlExtendedSuccess,
  fetchEvidenceDownloadDetailsRequest,
  fetchEvidenceDownloadDetailsSuccess,
  fetchEvidenceDownloadDetailsError,
  deleteEvidenceRequest,
  deleteEvidenceSuccess,
  deleteEvidenceError,
  fetchFrameworkPoliciesRequest,
  fetchFrameworkPoliciesSuccess,
  fetchFrameworkPoliciesError,
  removeApplicableControl,
  removeNotApplicableControl,
  createPDFReportRequest,
  createPDFReportError,
  createPDFReportSuccess,
  postFrameworkSuccess,
  postFrameworkRequest,
  postFrameworkError,
  setTempTask,
  setEditControlMode,
  fetchFrameworkGuidanceSuccess,
  isPostingReviewHistoryItemRequest,
  isPostingReviewHistoryItemSuccess,
  isPostingReviewHistoryItemFailure,
  isFetchingReviewHistoryItemsFailure,
  isFetchingReviewHistoryItemsSuccess,
  isFetchingReviewHistoryItemsRequest,
  fetchControlExtendedRequest,
  fetchControlExtendedError,
  updateTaskInTheTree,
} from './complianceSlice';
import { setPdfPreviewUrl } from '../global/globalSlice';
import { getRequest } from '../../functions/getRequest';
import { push } from 'connected-react-router';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { rawRequest } from '../../features/calendar/store/calendarThunksApiHelpers';
import { ApplicationState } from '../../types/applicationState';

export const fetchComplianceFrameworks =
  (
    vendorId: string,
    onSuccess?: (id: FrameworkModel['id']) => void,
    includeStats?: boolean
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchFrameworksRequest());
      const response = await api().vendorsIdFrameworksGet({
        id: vendorId,
        includeStats,
      });
      dispatch(fetchFrameworksSuccess(response as FrameworkModel[]));
      onSuccess && onSuccess(response[0]?.id);
    } catch (err) {
      if (err instanceof Response) {
        if (err.status === 403) return;
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching compliance frameworks. Please try again or contact support.'
        );
        console.log(err);
        dispatch(fetchFrameworksError(apiErrorMessage));
      }
    }
  };

export const fetchComplianceFrameworkPolicies =
  (frameworkId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchFrameworkPoliciesRequest());
      const response = await api().frameworksIdPoliciesGet({ id: frameworkId });
      dispatch(fetchFrameworkPoliciesSuccess(response as VendorDocument[]));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching compliance frameworks. Please try again or contact support.'
        );
        console.log(err);
        dispatch(fetchFrameworkPoliciesError(apiErrorMessage));
      }
    }
  };

export const fetchComplianceTasks =
  ({
    vendorId,
    frameworkId,
    applicable,
    status,
    name,
    includeStats,
  }: {
    vendorId: string;
    frameworkId?: string;
    applicable?: boolean;
    name?: string;
    status?: string;
    includeStats?: boolean;
  }): VoidThunk =>
  async dispatch => {
    try {
      if (!vendorId) {
        dispatch(
          fetchTasksError('Insufficient parameter values for API call.')
        );
        return;
      }
      dispatch(fetchTasksRequest());
      const response = await api().vendorsVendorIdTasksGet({
        vendorId,
        frameworkId,
        applicable,
        name,
        status: status ? [status] : null,
        includeStats,
      });
      dispatch(fetchTasksSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching compliance tasks. Please try again or contact support.'
        );
        console.log(err);
        dispatch(fetchTasksError(apiErrorMessage));
      }
    }
  };

export const fetchControlExtended =
  (controlId: string): VoidThunk =>
  async dispatch => {
    try {
      // cause spinner in checks list
      // dispatch(fetchTasksRequest());
      dispatch(fetchControlExtendedRequest());
      const response = await api().controlsControlIdGet({ controlId });
      dispatch(fetchControlExtendedSuccess(response));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching control details. Please try again or contact support.'
      );
      dispatch(fetchControlExtendedError(apiErrorMessage));
    }
  };

export const fetchAssignableComplianceTasks =
  (vendorId: string, notUsedInFrameworksIds?: string[] | null): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAssignableComplianceTasksRequest());
      const response = await api().vendorsVendorIdTasksGet({
        vendorId,
        notUsedInFrameworksIds,
      });
      dispatch(fetchAssignableComplianceTasksSuccess(response['tasks']));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching assignable compliance tasks for a compliance control. Please try again or contact support.'
        );
        console.log(err);
        dispatch(fetchAssignableComplianceTasksError(apiErrorMessage));
      }
    }
  };

export const postComplianceTask =
  ({
    vendorId,
    task,
  }: {
    vendorId: string;
    task: TaskCreatePayloadTask;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(postTaskRequest());
      const response = await api().vendorsVendorIdTasksPost({
        vendorId,
        body: {
          task,
        },
      });
      dispatch(postTaskSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while creating a compliance task. Please try again or contact support.'
        );
        console.log(err);
        dispatch(postTaskError(apiErrorMessage));
      }
    }
  };

export const postFramework =
  ({
    vendorId,
    templateId,
  }: {
    vendorId: Vendor['id'];
    templateId: FrameworkTemplateModel['id'];
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(postFrameworkRequest());
      await api().vendorsIdFrameworksPost({
        id: vendorId,
        body: {
          framework: { frameworkTemplateId: templateId },
        },
      });
      dispatch(postFrameworkSuccess());
      dispatch(push('/r/compliance/frameworks'));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while creating a framework. Please try again or contact support.'
        );
        console.log(err);
        dispatch(postFrameworkError(apiErrorMessage));
      }
    }
  };

export const patchComplianceTask =
  ({
    taskId,
    task,
    onSuccess = () => {},
  }: {
    taskId: string;
    task: TaskUpdatePayloadTask;
    onSuccess?(response: TaskModel): void;
  }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor.id;
      dispatch(patchTaskRequest());
      const response = await api().tasksTaskIdPatch({
        taskId,
        body: {
          task,
        },
      });
      dispatch(patchTaskSuccess(response));
      dispatch(updateTaskInTheTree(response));
      onSuccess(response);
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while updating a compliance task. Please try again or contact support.'
        );
        console.log(err);
        dispatch(patchTaskError(apiErrorMessage));
      }
    }
  };

export const fetchComplianceControls = createAsyncThunk(
  'compliance/fetchComplianceControls',
  async (params: VendorsVendorIdControlsGetRequest, { dispatch }) => {
    try {
      if (!params.vendorId) {
        throw new Error('Insufficient parameter values for API call.');
      }

      const response = await api().vendorsVendorIdControlsGet(params);
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred on fetching compliance controls. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

// TODO: this will be removed
export const fetchComplianceReportPdf =
  (requestParameters: any): VoidThunk =>
  async dispatch => {
    alert('TODO SoA reports ticket');
    try {
      // TODO: investigate issue on the backend rswag:
      // It makes valid request but generate incorrect empty response in DefaultApi.ts:
      // runtime.VoidApiResponse(response);
      dispatch(createPDFReportRequest());
      const response = await getRequest(
        `/api/v1/frameworks/${requestParameters.id}/statement_of_applicability`
      );
      const convertedData = await response.blob();
      const url = URL.createObjectURL(convertedData);
      dispatch(createPDFReportSuccess());
      dispatch(setPdfPreviewUrl(url));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching compliance report pdf. Please try again or contact support.'
        );
        console.log(err);
        dispatch(createPDFReportError(apiErrorMessage));
      }
    }
  };

export const fetchNotApplicableComplianceControls =
  (vendorId: string, frameworkId?: string): VoidThunk =>
  async dispatch => {
    try {
      if (!vendorId || !frameworkId) {
        dispatch(
          fetchNotApplicableControlsError(
            'Insufficient parameter values for API call.'
          )
        );
        return;
      }
      dispatch(fetchNotApplicableControlsRequest());
      const response = await api().vendorsVendorIdControlsGet({
        vendorId: vendorId,
        frameworkId: frameworkId,
        queryApplicable: 'false',
      });
      dispatch(fetchNotApplicableControlsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching compliance controls. Please try again or contact support.'
        );
        console.log(err);
        dispatch(fetchNotApplicableControlsError(apiErrorMessage));
      }
    }
  };

export const fetchComplianceCategories =
  (vendorId: string, frameworkId: string): VoidThunk =>
  async dispatch => {
    try {
      if (!vendorId) {
        dispatch(
          fetchCategoriesError('Insufficient parameter values for API call.')
        );
        return;
      }
      dispatch(fetchCategoriesRequest());
      const response = await api().vendorsVendorIdControlCategoriesGet({
        vendorId,
        frameworkId,
      });
      dispatch(fetchCategoriesSuccess(response));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching compliance framework categories. Please try again or contact support.'
      );
      console.log(err);
      dispatch(fetchCategoriesError(apiErrorMessage));
    }
  };

export const createComplianceControl =
  (vendorId: string, control: ControlCreatePayloadControl): VoidThunk =>
  async dispatch => {
    try {
      if (!vendorId || !control) {
        dispatch(
          fetchTasksError('Insufficient parameter values for API call.')
        );
        return;
      }
      dispatch(createControlRequest());
      const response = await api().vendorsVendorIdControlsPost({
        vendorId,
        body: { control },
      });
      dispatch(createControlSuccess(response as ControlExtended));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while creating compliance controls. Please try again or contact support.'
        );
        console.log(err);
        dispatch(createControlError(apiErrorMessage));
      }
    }
  };

export const patchComplianceControl =
  (
    controlId: string,
    control: ControlUpdatePayloadControl,
    onSuccess?: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      if (!controlId || !control) {
        dispatch(
          fetchTasksError('Insufficient parameter values for API call.')
        );
        return;
      }
      dispatch(patchControlRequest());
      const response = await api().controlsControlIdPatch({
        controlId,
        body: { control },
      });
      onSuccess && onSuccess();
      dispatch(patchControlSuccess(response as ControlExtended));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while patching compliance controls. Please try again or contact support.'
        );
        console.log(err);
        dispatch(patchControlError(apiErrorMessage));
      }
    }
  };

export const createTaskEvidence =
  (params: TasksTaskIdEvidencesPostRequest): VoidThunk =>
  async dispatch => {
    try {
      dispatch(postTaskEvidenceRequest());
      const response = await api().tasksTaskIdEvidencesPost(params);
      dispatch(postTaskEvidenceSuccess(response as EvidenceModel));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while creating evidence. Please try again or contact support.'
        );
        console.log(err);
        dispatch(postTaskEvidenceError(apiErrorMessage));
      }
    }
  };

// deprecated
// export const tempTaskWithDefaultOwner = (
//   defaultAssigneeId: VendorUser['id'],
//   task: TaskModel
// ) => {
//   return {
//     ...task,
//     assignee: { id: task?.assignee?.id || defaultAssigneeId },
//   };
// };

// On both create/edit we set tempTask fields (name, description, etc) + additional logic:
// On create - always assign default owner
// On edit - assign default owner after fetchTaskDetails thunk finished and not a view mode

// deprecated - no owner for now
// export const setTempTaskInitialFields =
//   (response: TaskModel): VoidThunk =>
//   async (dispatch, getState) => {
//     dispatch(setTempTaskInitialFieldsRequest(true));
//     const defaultAssigneeId = selectCurrentVendorUserId(getState());
//     const tempTask = getState().compliance.tempTask;
//     const selectedTask = {
//       ...tempTask,
//       ...response,
//     };
//     const isViewMode = selectIsViewModeTaskDrawer(getState());

//     const payload = isViewMode
//       ? selectedTask
//       : tempTaskWithDefaultOwner(defaultAssigneeId, selectedTask);
//     dispatch(setTempTask(payload));
//     dispatch(setTempTaskInitialFieldsRequest(false));
//   };

export const fetchTaskDetails =
  (taskId: string, onSuccess?: (response: TaskExtended) => void): VoidThunk =>
  async dispatch => {
    try {
      if (!taskId) {
        dispatch(
          fetchTaskDetailsError('Insufficient parameter values for API call.')
        );
        return;
      }
      dispatch(fetchTaskDetailsRequest());
      const response = await api().tasksTaskIdGet({
        taskId,
      });
      dispatch(setTempTask(response));
      dispatch(fetchTaskDetailsSuccess());
      // deprecated
      // dispatch(setTempTaskInitialFields(response));
      onSuccess && onSuccess(response);
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching Task details. Please try again or contact support.'
        );
        console.log(err);
        dispatch(fetchTaskDetailsError(apiErrorMessage));
      }
    }
  };

// Mark not application consists of 2 steps:
// 1) Click meatball menu in the tree inside categories and mark control
// 2) Save reason for exclusion

// 1 step
export const markControlNotApplicable =
  (categoryId: string, controlId: string): VoidThunk =>
  async (dispatch, getState) => {
    dispatch(markControlNotApplicableRequest(controlId));
    try {
      const category = getState().compliance.categories.find(
        c => c.id === categoryId
      );
      if (!category) return;
      const controlIndex = category.controls.findIndex(c => c.id === controlId);
      const control = category.controls.find(c => c.id === controlId);
      if (!control) return;
      dispatch(removeApplicableControl({ categoryId, controlId }));

      const payload = { applicable: false, orderIndex: controlIndex };
      const newControl = { ...control, ...payload };

      dispatch(addNotApplicableControl(newControl));
      dispatch(markControlNotApplicableSuccess());
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while marking the control not applicable. Please try again or contact support.'
        );
        console.log(err);
        dispatch(markControlNotApplicableFailure(apiErrorMessage));
      }
    }
  };
// 2 step
export const patchReasonForExclusion =
  (controlId: string, reasonForExclusion: string): VoidThunk =>
  async dispatch => {
    dispatch(markControlNotApplicableRequest(controlId));
    try {
      const response = await api().controlsControlIdStatusPatch({
        controlId,
        body: { control: { applicable: false, reasonForExclusion } },
      });
      dispatch(patchControlSuccess(response));
      dispatch(markControlNotApplicableSuccess(response));
      dispatch(setEditControlMode(null));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while saving reason for exclusion. Please try again or contact support.'
        );
        console.log(err);
        dispatch(markControlNotApplicableFailure(apiErrorMessage));
      }
    }
  };

export const markControlApplicable =
  (controlId: string): VoidThunk =>
  async (dispatch, getState) => {
    dispatch(markControlApplicableRequest());
    try {
      const control = getState().compliance.notApplicableControls.find(
        c => c.id === controlId
      );
      if (!control) return;
      dispatch(removeNotApplicableControl({ controlId }));
      dispatch(setEditControlMode(null));

      const response = await api().controlsControlIdStatusPatch({
        controlId,
        body: {
          control: {
            applicable: true,
          },
        },
      });

      dispatch(addApplicableControl(response));
      dispatch(markControlApplicableSuccess());
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while marking the control applicable. Please try again or contact support.'
        );
        console.log(err);
        dispatch(markControlApplicableFailure(apiErrorMessage));
      }
    }
  };

export const fetchEvidenceDownloadDetails =
  (evidenceId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchEvidenceDownloadDetailsRequest());
      const response = await api().evidencesEvidenceIdDownloadGet({
        evidenceId,
      });
      dispatch(fetchEvidenceDownloadDetailsSuccess());
      window.location.href = response.attachmentUrl;
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching Evidence download link. Please try again or contact support.'
        );
        console.log(err);
        dispatch(fetchEvidenceDownloadDetailsError(apiErrorMessage));
      }
    }
  };

export const deleteEvidence =
  (evidenceId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteEvidenceRequest());
      await api().evidencesEvidenceIdDelete({ evidenceId });
      dispatch(deleteEvidenceSuccess(evidenceId));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while deleting Evidence. Please try again or contact support.'
        );
        console.log(err);
        dispatch(deleteEvidenceError(apiErrorMessage));
      }
    }
  };

export const fetchRequirementCategories =
  (frameworkId: FrameworkModel['id']): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchRequirementsRequest());
      const response =
        await api().frameworksFrameworkIdFrameworkRequirementCategoriesGet({
          frameworkId,
        });
      dispatch(fetchRequirementsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching Requirements. Please try again or contact support.'
        );
        console.log(err);
      }
    }
  };

export const createVendorRequirement =
  (frameworkRequirementId: FrameworkRequirementModel['id']): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor.id;
      await api().vendorsVendorIdVendorFrameworkRequirementsPost({
        vendorId,
        body: {
          vendorFrameworkRequirement: { frameworkRequirementId },
        },
      });
      dispatch(createVendorRequirementSuccess(frameworkRequirementId));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching Requirements. Please try again or contact support.'
        );
        console.log(err);
      }
    }
  };

export const fetchFrameworkGuidance =
  (id: FrameworkModel['identifier']): VoidThunk =>
  async dispatch => {
    try {
      const response = await api().frameworkGuidancesIdGet({
        id,
      });
      dispatch(fetchFrameworkGuidanceSuccess([id, response]));
    } catch (err) {
      if (err instanceof Response) {
        if (err.status == 404) {
          dispatch(
            fetchFrameworkGuidanceSuccess([
              id,
              {
                content: 'Guidance not found. Please contact support.',
              },
            ])
          );
          return;
        }
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching Guidance. Please try again or contact support.'
        );
        console.log(err);
      }
    }
  };

export const postReviewHistoryItem =
  (
    reviewHistoryItem: ReviewHistoryItemCreatePayloadReviewHistoryItem,
    onSuccess: (historyItem: ReviewHistoryItemModel) => void
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor.id;
      dispatch(isPostingReviewHistoryItemRequest());
      const historyItem = await api().vendorsVendorIdReviewHistoryItemsPost({
        vendorId,
        body: { reviewHistoryItem },
      });
      dispatch(isPostingReviewHistoryItemSuccess());
      onSuccess(historyItem);
    } catch (err) {
      dispatch(isPostingReviewHistoryItemFailure());
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while adding review comment. Please try again or contact support.'
        );
        console.log(err);
      }
    }
  };

export const fetchReviewHistoryItems =
  ({
    relatedToId,
    relatedToType,
    onSuccess,
  }: {
    relatedToId: string;
    relatedToType: VendorsVendorIdReviewHistoryItemsGetRelatedToTypeEnum;
    onSuccess: (items: ReviewHistoryItemModel[]) => void;
  }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor.id;
      dispatch(isFetchingReviewHistoryItemsRequest());
      const response = await api().vendorsVendorIdReviewHistoryItemsGet({
        vendorId,
        relatedToId,
        relatedToType,
      });
      onSuccess(response);
      dispatch(isFetchingReviewHistoryItemsSuccess());
    } catch (err) {
      dispatch(isFetchingReviewHistoryItemsFailure());
      if (err instanceof Response) {
        if (err.status == 404) return;
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching review history items. Please try again or contact support.'
        );
        console.log(err);
      }
    }
  };

export const patchControlCheckAssertions = createAsyncThunk(
  'compliance/patchControlCheckAssertions',
  async (params: TasksTaskIdAvailableAssertionsPatchRequest, { dispatch }) => {
    try {
      await api().tasksTaskIdAvailableAssertionsPatchRaw(params);
    } catch (error) {
      await handleThunkError(
        'An error occurred while patching control check assertions. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchAssertionDetails = createAsyncThunk(
  'compliance/fetchAssertionDetails',
  async (params: AssertionsAssertionIdGetRequest, { dispatch }) => {
    try {
      const result = await api().assertionsAssertionIdGet(params);
      return result;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching assertion details. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

// no loading spinner. For example fetch status of assertion details
export const fetchAssertionDetailsSilent = createAsyncThunk(
  'compliance/fetchAssertionDetailsSilent',
  async (params: AssertionsAssertionIdGetRequest, { dispatch }) => {
    try {
      const result = await api().assertionsAssertionIdGet(params);
      return result;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching assertion details. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const patchAssertionDetails = createAsyncThunk(
  'compliance/patchAssertionDetails',
  async (params: AssertionsAssertionIdPatchRequest, { dispatch }) => {
    try {
      return await api().assertionsAssertionIdPatch(params);
    } catch (error) {
      await handleThunkError(
        'An error occurred while patching assertion details. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const postAssertionRun = createAsyncThunk(
  'compliance/postAssertionRun',
  async (params: AssertionsAssertionIdRunPostRequest, { dispatch }) => {
    try {
      const assertion = await api().assertionsAssertionIdRunPost(params);
      return assertion;
    } catch (error) {
      await handleThunkError(
        'An error occurred on creating assertion run Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const patchAssertionResultInclude = createAsyncThunk(
  'compliance/patchAssertionResultInclude',
  async (
    params: AssertionResultsAssertionResultIdIncludePatchRequest,
    { dispatch }
  ) => {
    try {
      await api().assertionResultsAssertionResultIdIncludePatch(params);
    } catch (error) {
      await handleThunkError(
        'An error occurred on patching include assertion result Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const patchAssertionResultExclude = createAsyncThunk(
  'compliance/patchAssertionResultExclude',
  async (
    params: AssertionResultsAssertionResultIdExcludePatchRequest,
    { dispatch }
  ) => {
    try {
      await api().assertionResultsAssertionResultIdExcludePatch(params);
    } catch (error) {
      await handleThunkError(
        'An error occurred on patching exclude assertion result Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

const buildAssertionData = (
  payload: AssertionsAssertionIdCreateReviewPostRequest
): FormData => {
  const formData = new FormData();
  const root = 'assertion_review';
  formData.append(`${root}[status]`, payload.assertionReviewStatus);
  formData.append(`${root}[comment]`, payload.assertionReviewComment ?? '');

  if (payload.assertionReviewEvidences) {
    payload.assertionReviewEvidences.forEach(file => {
      formData.append(`${root}[evidences][]`, file);
    });
  }
  return formData;
};

export const postAssertionReview = createAsyncThunk(
  'compliance/postAssertionReview',
  async (
    params: AssertionsAssertionIdCreateReviewPostRequest,
    { dispatch }
  ) => {
    try {
      const formData = buildAssertionData(params);
      const rawResponse = await rawRequest(
        `/api/v1/assertions/${params.assertionId}/create_review`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (rawResponse.status >= 200 && rawResponse.status < 300) {
        const response = AssertionExtendedFromJSON(await rawResponse.json());
        return response;
      } else {
        throw rawResponse;
      }
    } catch (error) {
      await handleThunkError(
        'An error occurred on posting assertion review. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const postFrameworkEvidenceExport = createAsyncThunk(
  'compliance/postFrameworkEvidenceExport',
  async (params: FrameworksIdExportEvidencePostRequest, { dispatch }) => {
    try {
      await api().frameworksIdExportEvidencePost(params);
    } catch (error) {
      await handleThunkError(
        'An error occurred on posting framework evidence. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchEvidence = createAsyncThunk(
  'compliance/fetchEvidence',
  async (params, { dispatch, getState }) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        .id;
      return await api().vendorsVendorIdDataRoomEvidenceGet({ vendorId });
    } catch (error) {
      await handleThunkError(
        'An error occurred on fetching evidence. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);
