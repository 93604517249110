/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssessmentResponseAnswerFile,
  AssessmentResponseAnswerFileFromJSON,
  AssessmentResponseAnswerFileFromJSONTyped,
  AssessmentResponseAnswerFileToJSON,
} from './';

/**
 *
 * @export
 * @interface AssessmentResponseAnswer
 */
export interface AssessmentResponseAnswer {
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  propertyName?: string;
  /**
   *
   * @type {number}
   * @memberof AssessmentResponseAnswer
   */
  index?: number | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  datatype?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  status?: AssessmentResponseAnswerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  updatedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  updatedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  value?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  displayValue?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AssessmentResponseAnswer
   */
  readOnly?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssessmentResponseAnswer
   */
  showAuditInfo?: boolean;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  confirmedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  confirmedBy?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AssessmentResponseAnswer
   */
  isConfirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  assessmentResponseId?: string;
  /**
   *
   * @type {Array<AssessmentResponseAnswerFile>}
   * @memberof AssessmentResponseAnswer
   */
  assessmentResponseAnswerFiles?: Array<AssessmentResponseAnswerFile>;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswer
   */
  sourceType?: AssessmentResponseAnswerSourceTypeEnum;
}

export function AssessmentResponseAnswerFromJSON(
  json: any
): AssessmentResponseAnswer {
  return AssessmentResponseAnswerFromJSONTyped(json, false);
}

export function AssessmentResponseAnswerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssessmentResponseAnswer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    propertyName: !exists(json, 'propertyName')
      ? undefined
      : json['propertyName'],
    index: !exists(json, 'index') ? undefined : json['index'],
    datatype: !exists(json, 'datatype') ? undefined : json['datatype'],
    status: !exists(json, 'status') ? undefined : json['status'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    updatedBy: !exists(json, 'updated_by') ? undefined : json['updated_by'],
    value: !exists(json, 'value') ? undefined : json['value'],
    displayValue: !exists(json, 'display_value')
      ? undefined
      : json['display_value'],
    readOnly: !exists(json, 'read_only') ? undefined : json['read_only'],
    showAuditInfo: !exists(json, 'show_audit_info')
      ? undefined
      : json['show_audit_info'],
    confirmedAt: !exists(json, 'confirmed_at')
      ? undefined
      : json['confirmed_at'],
    confirmedBy: !exists(json, 'confirmed_by')
      ? undefined
      : json['confirmed_by'],
    isConfirmed: !exists(json, 'is_confirmed')
      ? undefined
      : json['is_confirmed'],
    assessmentResponseId: !exists(json, 'assessment_response_id')
      ? undefined
      : json['assessment_response_id'],
    assessmentResponseAnswerFiles: !exists(
      json,
      'assessment_response_answer_files'
    )
      ? undefined
      : (json['assessment_response_answer_files'] as Array<any>).map(
          AssessmentResponseAnswerFileFromJSON
        ),
    sourceType: !exists(json, 'source_type') ? undefined : json['source_type'],
  };
}

export function AssessmentResponseAnswerToJSON(
  value?: AssessmentResponseAnswer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    propertyName: value.propertyName,
    index: value.index,
    datatype: value.datatype,
    status: value.status,
    updated_at: value.updatedAt,
    updated_by: value.updatedBy,
    value: value.value,
    display_value: value.displayValue,
    read_only: value.readOnly,
    show_audit_info: value.showAuditInfo,
    confirmed_at: value.confirmedAt,
    confirmed_by: value.confirmedBy,
    is_confirmed: value.isConfirmed,
    assessment_response_id: value.assessmentResponseId,
    assessment_response_answer_files:
      value.assessmentResponseAnswerFiles === undefined
        ? undefined
        : (value.assessmentResponseAnswerFiles as Array<any>).map(
            AssessmentResponseAnswerFileToJSON
          ),
    source_type: value.sourceType,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AssessmentResponseAnswerStatusEnum {
  Answered = 'answered',
  Unanswered = 'unanswered',
  Confirmed = 'confirmed',
}
/**
 * @export
 * @enum {string}
 */
export enum AssessmentResponseAnswerSourceTypeEnum {
  System = 'system',
  AssessmentResponse = 'assessment_response',
  Prepopulated = 'prepopulated',
}
