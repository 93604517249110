import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRadioButtonOptionsFactory } from '../../functions/selectRadioButtonOptionsFactory';
import { fetchTable } from '../../store/vendors/vendorsThunks';
import { Question } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { AdoptechRadioButton } from '../AdoptechRadioButton/AdoptechRadioButton';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import './RadioButtonsAnswer.scss';
import { TextBasedAnswer } from '../TextBasedAnswer/TextBasedAnswer';
import { CommonAnswerType } from '../../types/CommonAnswerType';
import { policyAnswerCommonFields } from '../../functions/policyAnswerCommonFields';

export interface RadioButtonsAnswerProps {
  previousAnswer?: CommonAnswerType;
  disabled?: boolean;
  readonly?: boolean;
  onChange: (value: CommonAnswerType[]) => void;
  question: Question;
  indexProperty: 'name' | 'propertyName';
  asTextReadonly?: boolean;
}

export const RadioButtonsAnswer: React.FC<RadioButtonsAnswerProps> = props => {
  const dispatch = useDispatch();

  const isTableType =
    !props.question.questionColumns && !!props.question.tableType;

  const selectRadioButtonOptions = useMemo(selectRadioButtonOptionsFactory, []);

  useEffect(() => {
    if (isTableType) {
      dispatch(fetchTable(props.question.tableType));
    }
  }, []);

  const radioButtonOptions = useSelector((state: ApplicationState) =>
    selectRadioButtonOptions(state, props)
  );

  const options = isTableType
    ? radioButtonOptions
    : props.question.questionColumns;

  const [selected, setSelected] = useState<string>();
  const otherInputFieldRef = useRef<HTMLInputElement>(null);
  const [otherValue, setOtherValue] = useState('');

  const isOtherValue = (answer: CommonAnswerType) => {
    return (
      answer &&
      options.length > 0 &&
      !options.some(question => question.columnValue === answer.value)
    );
  };

  useEffect(() => {
    if (props.previousAnswer) {
      setSelected(props.previousAnswer.value);
    }
    if (isOtherValue(props.previousAnswer)) {
      setSelected('other');
      setOtherValue(props.previousAnswer.value);
    }
  }, [props.previousAnswer, options]);

  const isClosedQuestion = () => {
    if (props.question.other) return false;
    const allOptionTexts = options.map(o => o.columnText.toLowerCase());
    return allOptionTexts.sort().join() == 'no,yes';
  };

  let radioButtonAnswerWrapper = 'radioButtonsAnswer--buttons';
  if (isClosedQuestion()) {
    radioButtonAnswerWrapper = 'radioButtonsAnswer--buttonsClosedQuestion';
  }

  const handleOnChange = (value: string) => {
    props.onChange([
      {
        [props.indexProperty]: props.question[props.indexProperty],
        value,
        ...policyAnswerCommonFields(props),
      },
    ]);
  };

  if (props.asTextReadonly) {
    const selectedOption = options.find(opt => opt.columnValue === selected);
    return (
      <TextBasedAnswer
        {...props}
        previousAnswer={{
          value: selectedOption ? selectedOption.columnText : selected,
        }}
      />
    );
  }

  return (
    <div className="radioButtonsAnswer">
      <div className={radioButtonAnswerWrapper}>
        {options.map(c => {
          return (
            <AdoptechRadioButton
              checked={selected === c.columnValue}
              disabled={props.disabled || props.readonly}
              id={`radioButtonsAnswer-${props.question.id}-${c.columnValue}`}
              key={c.columnValue}
              label={c.columnText}
              onChange={value => {
                setSelected(value);
                setOtherValue('');
                handleOnChange(value);
              }}
              value={c.columnValue}
            />
          );
        })}
        {props.question.other && (
          <div className="radioButtonsAnswer--other">
            <AdoptechRadioButton
              checked={selected === 'other'}
              disabled={props.disabled || props.readonly}
              id={`radioButtonsAnswer-${props.question.id}-other`}
              key="other"
              label="Other"
              onChange={value => {
                setSelected(value);
                otherInputFieldRef.current.focus();
              }}
              value="other"
            />
            {props.question.otherLayout === 'text_field' && (
              <div className="radioButtonsAnswer--otherTextContainer">
                <AdoptechTextInput
                  id={`radioButtonsAnswer--otherText-${props.question.id}`}
                  disabled={props.disabled || props.readonly}
                  onChange={event => {
                    setSelected('other');
                    setOtherValue(event.currentTarget.value);
                  }}
                  onBlur={event => {
                    handleOnChange(event.currentTarget.value);
                  }}
                  ref={otherInputFieldRef}
                  type="text"
                  value={otherValue}
                />
                {selected === 'other' && otherValue === '' && (
                  <span className="radioButtonsAnswer--otherError">
                    Please enter a value for 'Other'
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
