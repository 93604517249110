import React, { useState } from 'react';
import { VendorUserRoles } from '../../../../swagger';
import AdoptechHS from '../../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { AdoptechTextInput2 } from '../../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { AdoptechReactSelect2 } from '../../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechDatePicker2 } from '../../../../components/AdoptechDatePicker2/AdoptechDatePicker2';
import { useOwnerOptions } from '../../../../hooks/useOwnerOptions';
import { DropZone } from '../../../../components/DropZone/DropZone';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../types/applicationState';
import './MeetingsUploadDrawer.scss';
import {
  UploadMeetingsPayload,
  fetchAudits,
  postMeeting,
} from '../store/auditsThunks';

const baseCss = 'meetingsEditForm';

export const validation = (
  data: UploadMeetingsPayload
): Record<string, string[]> => ({
  auditName: data.auditName ? null : ['Meeting name must be set'],
  auditOwnerId: data.auditOwnerId ? null : ['Owner must be set'],
  auditStartedAt: data.auditStartedAt ? null : ['Start date must be set'],
  auditFile: data.auditFile ? null : ['File must be attached'],
});

type Field = 'auditName' | 'auditOwnerId' | 'auditStartedAt' | 'auditFile';

interface Props {
  onClose(): void;
}

export const MeetingsUploadDrawer: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({} as UploadMeetingsPayload);
  const handleNameChange = (auditName: string) =>
    setData({ ...data, ...{ auditName } });
  const handleFileChange = (auditFile: File) =>
    setData({ ...data, ...{ auditFile } });

  const handleOwnerChange = (e: { value: any }) =>
    setData({
      ...data,
      ...{ auditOwnerId: e.value },
    });

  const handleDateChange = (d: Date): void => {
    setData({
      ...data,
      auditStartedAt: d.toJSON().split('T')[0],
    });
  };

  const { users, ownerOptions } = useOwnerOptions({
    onlyRoles: [
      VendorUserRoles.Admin,
      VendorUserRoles.SecurityManager,
      VendorUserRoles.Auditor,
    ],
  });

  const startDate = data.auditStartedAt ? new Date(data.auditStartedAt) : null;
  const hasError = (field: Field) => !!validation(data)[field];
  const formIsValid = Object.values(validation(data)).every(v => !v);

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );
  const handleSubmit = async () => {
    await dispatch(postMeeting(data));
    dispatch(fetchAudits(vendorId));
    onClose();
  };

  const isPosting = useSelector(
    (state: ApplicationState) => state.audits.postMeetingStatus === 'loading'
  );

  const Footer = (
    <>
      <AdoptechButton onClick={onClose} variant={AdoptechButtonVariant.White}>
        Cancel
      </AdoptechButton>
      <AdoptechButton
        disabled={!formIsValid}
        onClick={handleSubmit}
        variant={AdoptechButtonVariant.Primary}
        busy={isPosting}
      >
        Add
      </AdoptechButton>
    </>
  );

  return (
    <AdoptechHS
      title="Upload meeting minutes"
      show
      onClose={onClose}
      showConfirmationWarning={false}
      footer={Footer}
      extraClass="meetingsUploadDrawer"
    >
      <div className={baseCss}>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--label'}>Give your meeting a name</div>
          <AdoptechTextInput2
            label="Meeting name"
            placeholder="For example Quarterly Management Review Meeting minutes"
            onChange={e => handleNameChange(e.currentTarget.value)}
            id="meetingsUploadFormName"
            hasError={hasError('auditName')}
            value={data.auditName || ''}
            type="text"
          />
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--label'}>
            Who owns the minutes of this meeting?
          </div>
          <AdoptechReactSelect2
            label="Owner"
            id="meetingsUploadFormOwner"
            options={ownerOptions}
            hasError={hasError('auditOwnerId')}
            onChange={handleOwnerChange}
            value={ownerOptions.find(
              option => option.value === data.auditOwnerId
            )}
          />
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--label'}>
            What was the date of the meeting
          </div>
          <AdoptechDatePicker2
            labelText="Meeting date"
            placeHolderText="Select date"
            outputDate={startDate}
            hasError={hasError('auditStartedAt')}
            deadLines={[]}
            minDate={null}
            onDateSelect={handleDateChange}
          />
        </div>
        <div className={baseCss + '--fieldRow'}>
          <DropZone accept=".pdf" onChange={handleFileChange} />
        </div>
      </div>
    </AdoptechHS>
  );
};
