import moment from 'moment';
import { CompletedAssessment } from '../selectors/selectCompletedAssessments';
import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';

export const completedAssessmentSort = (
  a: CompletedAssessment,
  b: CompletedAssessment,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof CompletedAssessment =
    columnSort.name as keyof CompletedAssessment;

  switch (typedColumn) {
    case 'dateCompleted':
      return sort(moment(a.dateCompleted), moment(b.dateCompleted), direction);
    default:
      return sort(a[typedColumn], b[typedColumn], direction);
  }
};
