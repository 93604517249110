import React, { useEffect, useMemo } from 'react';
import './ControlsEditSection.scss';
import { AdoptechCheckbox } from '../../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { AdoptechReactSelect } from '../../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { reactSelectLightStyleSmall } from '../../../../functions/reactSelectCustomTheme';
import {
  EditSectionHandlerType,
  TrusthubFormDocument,
} from '../useEditTrustHubSection';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag } from 'react-dnd';
import { SelectionOption } from '../../../../types/selectionOption';
import { ControlRow } from '../../ControlsSection/ControlRow';
import { ControlDragPreview } from './ControlDragPreview';

export const ControlEditLeftPanel: React.FC<{
  baseCss: string;
  frameworksFilterOptions: SelectionOption[];
  handleFrameworksFilterChange: (e: { value: any }) => void;
  selectedFrameworkName: string | null;
  editSectionHandler: EditSectionHandlerType;
}> = ({
  baseCss,
  frameworksFilterOptions,
  handleFrameworksFilterChange,
  selectedFrameworkName,
  editSectionHandler,
}) => {
  const {
    handleCheckboxAllChange,
    sortedDocsToEdit,
    handleCheckboxChange,
    formDocuments: allFormDocuments,
  } = editSectionHandler;

  const formDocuments = allFormDocuments.filter(doc => !doc.trusthubCategoryId);

  const sectionDoc = (doc: { documentId: any }) =>
    sortedDocsToEdit.find(sortedDoc => sortedDoc.documentId === doc.documentId);

  const leftPanelControls = formDocuments
    .filter(control => !control.trusthubCategoryId)
    .filter(control =>
      selectedFrameworkName
        ? sectionDoc(control)?.extra?.frameworkName === selectedFrameworkName
        : true
    );

  const controlsIdsToDrag = formDocuments
    .filter(control => control.checked)
    .map(control => control.documentId);

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'TrusthubControls', // uniq name for controls drag and drop
      item: () => {
        return controlsIdsToDrag;
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => {
        return controlsIdsToDrag.length > 0;
      },
    }),
    [formDocuments]
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const leftPanelControlsList = (
    items: TrusthubFormDocument[]
  ): React.ReactNode[] => {
    return items.map(doc => {
      return (
        <ControlRow
          key={doc.documentId}
          showTooltip={false}
          control={sortedDocsToEdit.find(
            sortedDoc => sortedDoc.documentId === doc.documentId
          )}
          baseCss={baseCss}
          isEditableChecked
          isDragging={isDragging}
          editSectionHandler={editSectionHandler}
          dropAllowed={false}
          dragAllowed={false}
        />
      );
    });
  };
  // limit preview height to fix chrome performance issues
  const DRAG_PREVIEW_LIMIT = 15;
  const leftPanelList = leftPanelControlsList(leftPanelControls);
  const leftPanelPreviewList = leftPanelControlsList(
    leftPanelControls.filter(doc => doc.checked).slice(0, DRAG_PREVIEW_LIMIT)
  );
  return (
    <div className={baseCss + '--table'}>
      <div className={baseCss + '--tableHeader'}>
        Drag and drop controls to the category sections on the right hand side
        to make them visible on TrustHub.
      </div>

      <div className={baseCss + '--tablePanel'}>
        <div className={baseCss + '--tablePanelHeader'}>
          <AdoptechCheckbox
            id={`${baseCss}-selectAll`}
            checked={leftPanelControls.every(control => control.checked)}
            onChange={() => {
              const filterIds = leftPanelControls.map(
                control => control.documentId
              );
              handleCheckboxAllChange({ filterIds });
            }}
            label="Select all"
            disabled={leftPanelControls.length === 0}
          />

          <AdoptechReactSelect
            id={`${baseCss}--frameworksFilter`}
            options={frameworksFilterOptions}
            onChange={handleFrameworksFilterChange}
            placeholder="Filter frameworks"
            isDisabled={false}
            value={frameworksFilterOptions.find(
              option => option.value === selectedFrameworkName
            )}
            additionalStyling={reactSelectLightStyleSmall}
          />
        </div>
        <div ref={drag} className={baseCss + '--controlsTemplates'}>
          {leftPanelList}
        </div>
        <ControlDragPreview baseCss={baseCss} list={leftPanelPreviewList} />
      </div>
    </div>
  );
};
