import { faBookAlt } from '@fortawesome/pro-light-svg-icons/faBookAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Interweave } from 'interweave';
import React from 'react';
import { formatShortDate } from '../../functions/formatShortDate';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import './EntityCard.scss';

export interface EntityCardProps {
  enableAnimations?: boolean;
  disableProgress?: boolean;
  hideProgress?: boolean;
  progress?: number;
  title: string;
  icon?: any;
  detailsText: {
    text?: string;
    asHtml?: boolean;
    element?: JSX.Element;
  };
  lastUpdatedAt?: string;
  onCardClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  children?: JSX.Element;
  button?: JSX.Element;
  buttonContainerStyle?: string;
}

export const EntityCard: React.FC<EntityCardProps> = ({
  enableAnimations,
  disableProgress,
  hideProgress,
  progress = 0,
  title,
  detailsText,
  lastUpdatedAt,
  onCardClick,
  children,
  icon = faBookAlt,
  button,
  buttonContainerStyle,
}) => {
  const entityCardClasses = classNames({
    entityCard: true,
    'entityCard--animated': enableAnimations,
    'entityCard--pointer': Boolean(onCardClick),
  });

  return (
    <div className={entityCardClasses}>
      {!hideProgress &&
        (disableProgress ? (
          <div className="entityCard--progressBarStatic" />
        ) : (
          <div className="entityCard--progressBar">
            <ProgressBar progress={progress * 100} />
          </div>
        ))}
      <div className="entityCard--body" onClick={onCardClick}>
        <div className="entityCard--header">
          <div>
            <FontAwesomeIcon
              icon={icon}
              size="lg"
              className="entityCard--icon"
            />
          </div>
          <div>{children}</div>
        </div>
        <div className="entityCard--title">{title}</div>

        <div className="entityCard--detailsText">
          {detailsText.asHtml ? (
            <Interweave content={detailsText.text} />
          ) : (
            detailsText.text || detailsText.element
          )}
        </div>
        {lastUpdatedAt && (
          <div className="entityCard--footer">
            <div className="entityCard--lastUpdate">Last update</div>
            <div className="entityCard--date">
              {formatShortDate(lastUpdatedAt)}
            </div>
          </div>
        )}
        {button && (
          <div
            className={
              buttonContainerStyle ? buttonContainerStyle : 'entityCard--button'
            }
          >
            {button}
          </div>
        )}
      </div>
    </div>
  );
};
