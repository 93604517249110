/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  AuditType,
  AuditTypeFromJSON,
  AuditTypeFromJSONTyped,
  AuditTypeToJSON,
  Survey,
  SurveyFromJSON,
  SurveyFromJSONTyped,
  SurveyToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface AuditModel
 */
export interface AuditModel {
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  id: string;
  /**
   *
   * @type {AuditType}
   * @memberof AuditModel
   */
  type?: AuditType | null;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  relativePath?: string | null;
  /**
   *
   * @type {Access}
   * @memberof AuditModel
   */
  access?: Access;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  auditTemplateId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  status?: AuditModelStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  rating?: AuditModelRatingEnum;
  /**
   *
   * @type {VendorUser}
   * @memberof AuditModel
   */
  createdBy?: VendorUser;
  /**
   *
   * @type {VendorUser}
   * @memberof AuditModel
   */
  owner?: VendorUser;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  origin?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  vendorDocumentId?: string | null;
  /**
   *
   * @type {Survey}
   * @memberof AuditModel
   */
  survey?: Survey;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  startedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  completedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  updatedAt?: string;
}

export function AuditModelFromJSON(json: any): AuditModel {
  return AuditModelFromJSONTyped(json, false);
}

export function AuditModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuditModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: !exists(json, 'type') ? undefined : AuditTypeFromJSON(json['type']),
    relativePath: !exists(json, 'relative_path')
      ? undefined
      : json['relative_path'],
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    name: json['name'],
    auditTemplateId: !exists(json, 'audit_template_id')
      ? undefined
      : json['audit_template_id'],
    status: !exists(json, 'status') ? undefined : json['status'],
    rating: !exists(json, 'rating') ? undefined : json['rating'],
    createdBy: !exists(json, 'created_by')
      ? undefined
      : VendorUserFromJSON(json['created_by']),
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    origin: !exists(json, 'origin') ? undefined : json['origin'],
    vendorDocumentId: !exists(json, 'vendor_document_id')
      ? undefined
      : json['vendor_document_id'],
    survey: !exists(json, 'survey')
      ? undefined
      : SurveyFromJSON(json['survey']),
    startedAt: !exists(json, 'started_at') ? undefined : json['started_at'],
    completedAt: !exists(json, 'completed_at')
      ? undefined
      : json['completed_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function AuditModelToJSON(value?: AuditModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: AuditTypeToJSON(value.type),
    relative_path: value.relativePath,
    access: AccessToJSON(value.access),
    name: value.name,
    audit_template_id: value.auditTemplateId,
    status: value.status,
    rating: value.rating,
    created_by: VendorUserToJSON(value.createdBy),
    owner: VendorUserToJSON(value.owner),
    origin: value.origin,
    vendor_document_id: value.vendorDocumentId,
    survey: SurveyToJSON(value.survey),
    started_at: value.startedAt,
    completed_at: value.completedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AuditModelStatusEnum {
  InProgress = 'in_progress',
  Completed = 'completed',
}
/**
 * @export
 * @enum {string}
 */
export enum AuditModelRatingEnum {
  Compliant = 'Compliant',
  ImprovementNeeded = 'Improvement Needed',
  NotCompliant = 'Not Compliant',
}
