import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuditQuestionsAreFilledIn } from '../../../selectors/selectAuditQuestionsAreFilledIn';
import { selectAuditsProgress } from '../../../selectors/selectAuditsProgress';
import { completeAudit } from './store/auditsThunks';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { AdoptechSliderSwitcher } from '../../../components/AdoptechSliderSwitcher/AdoptechSliderSwitcher';
import { AdoptechTooltip } from '../../../components/AdoptechTooltip/AdoptechTooltip';
import { Panel } from '../../../components/Panel/Panel';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { auditsPageRoute } from '../../../components/Routes/Routes';
import { AuditMode } from './AuditsEditor';
import { AuditsMeatballMenu } from './AuditsMeatballMenu';
import './AuditsSummaryTile.scss';

interface AuditSummaryTileProps {
  mode: AuditMode;
  setMode: React.Dispatch<React.SetStateAction<AuditMode>>;
  onEdit(): void;
}

export const AuditsSummaryTile: React.FC<AuditSummaryTileProps> = ({
  mode,
  setMode,
  onEdit,
}) => {
  const dispatch = useDispatch();
  const progress = useSelector(selectAuditsProgress);
  const { currentAudit } = useSelector(
    (state: ApplicationState) => state.audits
  );
  const questionsAreFilledIn = useSelector(selectAuditQuestionsAreFilledIn);

  const completeButton = (
    <AdoptechButton
      disablePointerEvents={!questionsAreFilledIn}
      disabled={!questionsAreFilledIn}
      onClick={() => dispatch(completeAudit(currentAudit.id))}
      variant={AdoptechButtonVariant.SuccessTransparent}
      rounded
    >
      <FontAwesomeIcon
        className="agreementSummaryTile--completeIcon"
        icon={faFileContract}
      />
      Complete
    </AdoptechButton>
  );
  const values = Object.values(AuditMode);
  return (
    <Panel>
      <div className="auditsSummaryTile">
        <div className="auditsSummaryTile--progressBar">
          <ProgressBar progress={progress.total * 100} />
        </div>
        <div className="auditsSummaryTile--mainContent">
          <div
            className="auditsEditor--back"
            onClick={() => {
              dispatch(push(auditsPageRoute));
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <div className="auditsSummaryTile--info">
            <div className="auditsSummaryTile--column">
              <div className="auditsSummaryTile--columnHeader">Audit</div>
              <div className="auditsSummaryTile--columnData">
                {currentAudit.name}
              </div>
            </div>
            <div className="auditsSummaryTile--column">
              <div className="auditsSummaryTile--columnHeader">Progress</div>
              <div className="auditsSummaryTile--columnData">
                {(progress.total * 100).toFixed(0)}%
              </div>
            </div>
            <div className="auditsSummaryTile--column auditsSummaryTile--column-inline">
              <AdoptechSliderSwitcher
                values={values as string[]}
                setSelected={
                  setMode as unknown as Dispatch<SetStateAction<string>>
                }
                selected={mode as unknown as string}
                onClickHandlers={
                  questionsAreFilledIn ? null : [() => {}, () => {}] // disable all with empty handlers if questions are not filled in
                }
                tooltipsOptions={
                  questionsAreFilledIn
                    ? []
                    : [
                        { enabled: false },
                        {
                          enabled: true,
                          text: 'You have to answer all questions to preview the document.',
                        },
                      ]
                }
              />
            </div>
            <div className="auditsSummaryTile--column">
              {questionsAreFilledIn ? (
                completeButton
              ) : (
                <AdoptechTooltip
                  text="You have to answer all Questions and go to Preview mode to Complete the document."
                  identifier={currentAudit.id}
                  placement="bottom"
                  showTooltip
                >
                  <span>{completeButton}</span>
                </AdoptechTooltip>
              )}
            </div>
            <div className="auditsSummaryTile--column">
              <AuditsMeatballMenu audit={currentAudit} onClick={onEdit} />
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
};
