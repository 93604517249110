import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';

export const isValidMobile = (mobileNumber: string): boolean => {
  if (isPossiblePhoneNumber(mobileNumber) && isValidPhoneNumber(mobileNumber)) {
    return true;
  } else {
    return false;
  }
};
