import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import './Lozenge.scss';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

export enum Intent {
  None,
  Primary,
  Success,
}
export const lozengeTestId = 'lozenge-div';
export const valueTestId = 'lozenge-value';
export const deleteIconTestId = 'lozenge-delete-icon';
export const primaryIntentClass = 'lozenge-primary';
export const successIntentClass = 'lozenge-success';
export const deleteIconClass = 'lozenge--delete';

interface LozengeProps {
  intent?: Intent;
  onDelete?: () => void;
  value: string | number;
  className?: string;
}

export const Lozenge: React.FC<LozengeProps> = props => {
  const lozengeClassNames = classNames(
    {
      lozenge: !props.className,
      [`${primaryIntentClass}`]: props.intent === Intent.Primary,
      [`${successIntentClass}`]: props.intent === Intent.Success,
    },
    props.className
  );

  return (
    <div data-testid={lozengeTestId} className={lozengeClassNames}>
      <div data-testid={valueTestId}>{props.value}</div>
      {props.onDelete && (
        <div
          data-testid={deleteIconTestId}
          className={deleteIconClass}
          onClick={() => props.onDelete()}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
    </div>
  );
};
