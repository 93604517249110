import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { AdoptechRadioButton } from '../AdoptechRadioButton/AdoptechRadioButton';
import './AdoptechRadioButtonsGroup.scss';

type T = string;

interface Props {
  disabled?: boolean;
  labels: string[];
  values: T[];
  value?: T;
  ids?: string[];
  onChange: (value: T) => any;
  wrappers?: React.FC[]; // for the ability to wrap radios inside tooltips
}

export const AdoptechRadioButtonsGroup: React.FC<Props> = ({
  values,
  labels,
  onChange,
  value,
  disabled,
  ids,
  wrappers,
}) => {
  const [groupValue, setGroupValue] = useState(value || null);

  useEffect(() => {
    setGroupValue(value);
  }, [value]);

  const radios = values.map((val, index) => {
    const radio = (
      <AdoptechRadioButton
        checked={groupValue === val}
        key={val}
        id={`adoptech-radio-button-${ids ? ids[index] : val}`}
        value={val}
        disabled={disabled}
        label={labels[index]}
        onChange={newValue => {
          setGroupValue(newValue);
          onChange(newValue);
        }}
      />
    );

    const Wrapper = wrappers ? wrappers[index] : null;
    return Wrapper ? <Wrapper key={val}>{radio}</Wrapper> : radio;
  });

  return (
    <div
      className={classNames('adoptechRadioButtons-group', {
        'adoptechRadioButtons-group--disabled': disabled,
      })}
    >
      {radios}
    </div>
  );
};
