import React, { useState } from 'react';
import './EditControlFrameworksModal.scss';
import { Modal } from 'react-bootstrap';
import AdoptechModal from '../../../../../components/AdoptechModal/AdoptechModal';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import { SelectionOption } from '../../../../../types/selectionOption';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../types/applicationState';
import { ControlModel } from '../../../../../swagger/models/ControlModel';
import { AdoptechReactSelect } from '../../../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { minimumAssignedFrameworks } from '../../../../../components/compliance/Types/complianceTypes';
import { FrameworkModel } from '../../../../../swagger/models/FrameworkModel';
import { AdoptechTextInput } from '../../../../../components/AdoptechTextInput/AdoptechTextInput';
import { reactSelectLightStyle } from '../../../../../functions/reactSelectCustomTheme';

export type HandleAddFrameworkPayload = {
  framework: {
    id: string;
    name: string;
  };
  controlCategory: {
    id: string;
    name: string;
  };
  annexRef: string;
};

interface EditControlFrameworkModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: (payload: HandleAddFrameworkPayload) => void;
  relations: ControlModel['frameworksRelations'];
}
export const EditControlFrameworksModal: React.FC<
  EditControlFrameworkModalProps
> = props => {
  const baseCss = 'editControlFrameworksModal';
  const childClass = (name: string) => `${baseCss}--${name}`;
  const title = 'Add parent framework';
  const handleCancel = () => {
    setSelectedFramework(null);
    setSelectedCategory(null);
    setReferenceText(null);
    props.onHide();
  };
  const handleConfirm = () => {
    const payload = {
      framework: { id: selectedFramework.value, name: selectedFramework.label },
      controlCategory: {
        id: selectedCategory.value,
        name: selectedCategory.label,
      },
      annexRef: referenceText,
    };

    props.onConfirm(payload);
    handleCancel();
  };

  const [selectedFramework, setSelectedFramework] =
    useState<SelectionOption>(undefined);
  const [selectedCategory, setSelectedCategory] =
    useState<SelectionOption>(undefined);
  const [referenceText, setReferenceText] = useState<string>(undefined);

  const frameworkOptions = useSelector((state: ApplicationState) =>
    state.compliance.frameworks.filter(
      framework => !props.relations?.some(r => r.framework.id === framework.id)
    )
  );

  const categoryOptions = useSelector((state: ApplicationState) =>
    state.compliance.categories.filter(
      category =>
        !props.relations?.some(r => r.controlCategory.id === category.id)
    )
  );

  return (
    <AdoptechModal
      show={props.show}
      onHide={props.onHide}
      className={childClass('modal')}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body className={childClass('body')}>
        <div className={childClass('body')}>
          <AdoptechReactSelect
            additionalStyling={reactSelectLightStyle}
            id="frameworkSearch"
            options={frameworkOptions.map(f => {
              return { value: f.id, label: f.name };
            })}
            onChange={(e: SelectionOption) => {
              setSelectedFramework(e);
            }}
            value={selectedFramework}
            placeholder="Select Framework"
          />

          <AdoptechReactSelect
            id="categorySearch"
            additionalStyling={reactSelectLightStyle}
            options={categoryOptions.map(c => {
              return { value: c.id, label: c.name };
            })}
            onChange={(e: SelectionOption) => {
              setSelectedCategory(e);
            }}
            value={selectedCategory}
            placeholder="Select Category"
          />

          <AdoptechTextInput
            id="frameworkReference"
            additionalClass="white-small"
            onChange={e => {
              const ref = e.target.value;
              setReferenceText(ref);
            }}
            placeholder="Add Reference (Optional)"
            type="text"
            value={referenceText}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className={childClass('footer')}>
        <AdoptechButton
          variant={AdoptechButtonVariant.White}
          onClick={handleCancel}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          variant={AdoptechButtonVariant.Primary}
          onClick={handleConfirm}
          disabled={!selectedCategory || !selectedFramework}
        >
          Confirm
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
