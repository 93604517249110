/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  LegislationCreatePayloadLegislation,
  LegislationCreatePayloadLegislationFromJSON,
  LegislationCreatePayloadLegislationFromJSONTyped,
  LegislationCreatePayloadLegislationToJSON,
} from './';

/**
 *
 * @export
 * @interface LegislationCreatePayload
 */
export interface LegislationCreatePayload {
  /**
   *
   * @type {LegislationCreatePayloadLegislation}
   * @memberof LegislationCreatePayload
   */
  legislation: LegislationCreatePayloadLegislation;
}

export function LegislationCreatePayloadFromJSON(
  json: any
): LegislationCreatePayload {
  return LegislationCreatePayloadFromJSONTyped(json, false);
}

export function LegislationCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LegislationCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    legislation: LegislationCreatePayloadLegislationFromJSON(
      json['legislation']
    ),
  };
}

export function LegislationCreatePayloadToJSON(
  value?: LegislationCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    legislation: LegislationCreatePayloadLegislationToJSON(value.legislation),
  };
}
