import React from 'react';
import {
  DocumentPermissionTableRowProps,
  trusthubSectionName,
} from '../../../admin/UserAdmin/TrusthubUserAdminAddDocumentPermissionDrawer/DocumentPermissionTableRow/DocumentPermissionTableRow';
import { AdoptechCheckbox } from '../../../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { TrusthubDocumentExtended } from '../../../../../swagger/trusthub';
import { faCloudDownloadAlt } from '@fortawesome/pro-light-svg-icons/faCloudDownloadAlt';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { useTrusthubDocumentDownload } from '../../TrustHubUserPage/TrustHubUserPage';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../types/applicationState';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { AdoptechTooltip } from '../../../../../components/AdoptechTooltip/AdoptechTooltip';

export const RequestDocumentModalDocumentSection: React.FC<
  DocumentPermissionTableRowProps
> = ({ section, formData, setFormData }) => {
  const baseCss = 'trusthubRequestDocumentModal';

  const sectionDocs = section.trusthubDocuments;
  const { fetchVendorDocumentFileStatus, fetchingVendorDocumentIdFile } =
    useSelector((state: ApplicationState) => state.trustHub);
  const isDocumentChecked = (document: TrusthubDocumentExtended): boolean => {
    return (formData.selectedDocumentsIds || []).includes(document.id);
  };
  const handleDownload = useTrusthubDocumentDownload();
  const handleDocumentSelect = (document: TrusthubDocumentExtended) => {
    setFormData(prevFormData => {
      const docChecked = (prevFormData.selectedDocumentsIds || []).includes(
        document.id
      );
      const allIds = (prevFormData.selectedDocumentsIds || []).concat(
        document.id
      );
      const excludeIds = (prevFormData.selectedDocumentsIds || []).filter(
        id => id !== document.id
      );

      const payload = {
        selectedDocumentsIds: docChecked ? excludeIds : allIds,
      };

      return {
        ...prevFormData,
        ...payload,
      };
    });
  };

  return (
    <>
      <div className={baseCss + '--sectionTitle'}>
        {trusthubSectionName(section.name)}
      </div>

      <div className={baseCss + '--documents'}>
        {sectionDocs.map(doc => {
          return (
            <div
              key={doc.id}
              className={baseCss + '--document'}
              onClick={() => {
                if (doc.requested) return;
                if (doc.restricted) {
                  handleDocumentSelect(doc);
                  return;
                }
                handleDownload(doc);
              }}
            >
              {doc.restricted ? (
                <div className={baseCss + '--documentCheckboxSection'}>
                  {doc.requested ? (
                    <AdoptechTooltip
                      text="Request pending"
                      identifier={`${doc.id}-sent-request-note`}
                      showTooltip
                    >
                      <FontAwesomeIcon icon={faClock} />
                    </AdoptechTooltip>
                  ) : (
                    <AdoptechCheckbox
                      id={doc.id}
                      checked={isDocumentChecked(doc)}
                      onChange={e => {
                        e.stopPropagation();
                        handleDocumentSelect(doc);
                      }}
                      label={<div className="emptyLabel">-</div>}
                    />
                  )}
                  <div className={baseCss + '--documentTitle' + ' editMode'}>
                    {doc.name}
                  </div>
                </div>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <div className={baseCss + '--documentTitle'}>{doc.name}</div>
                </>
              )}
              {fetchVendorDocumentFileStatus === 'loading' &&
              fetchingVendorDocumentIdFile === doc.id ? (
                <LoadingSpinner inlineSmall />
              ) : (
                <FontAwesomeIcon
                  icon={doc.restricted ? faLockAlt : faCloudDownloadAlt}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
