import { createSelector } from '@reduxjs/toolkit';
import { VendorUser } from '../swagger';
import { selectCurrentVendorUser } from './selectCurrentVendorUser';

export const selectUserRoles = createSelector(
  selectCurrentVendorUser,
  (vendorUser: VendorUser) => {
    return vendorUser ? vendorUser.roles : [];
  }
);
