/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedPaymentPeriod,
  SharedPaymentPeriodFromJSON,
  SharedPaymentPeriodFromJSONTyped,
  SharedPaymentPeriodToJSON,
  SubscriptionInvoices,
  SubscriptionInvoicesFromJSON,
  SubscriptionInvoicesFromJSONTyped,
  SubscriptionInvoicesToJSON,
} from './';

/**
 *
 * @export
 * @interface Subscription
 */
export interface Subscription {
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  paymentPlanId?: string;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  vendorId?: string;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  userId?: string;
  /**
   *
   * @type {boolean}
   * @memberof Subscription
   */
  isActive?: boolean;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  cancelledAt?: string | null;
  /**
   *
   * @type {number}
   * @memberof Subscription
   */
  usersLimit?: number;
  /**
   *
   * @type {number}
   * @memberof Subscription
   */
  price?: number;
  /**
   *
   * @type {SharedPaymentPeriod}
   * @memberof Subscription
   */
  paymentPeriod?: SharedPaymentPeriod;
  /**
   *
   * @type {Array<SubscriptionInvoices>}
   * @memberof Subscription
   */
  invoices?: Array<SubscriptionInvoices>;
}

export function SubscriptionFromJSON(json: any): Subscription {
  return SubscriptionFromJSONTyped(json, false);
}

export function SubscriptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Subscription {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    paymentPlanId: !exists(json, 'payment_plan_id')
      ? undefined
      : json['payment_plan_id'],
    vendorId: !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    isActive: !exists(json, 'is_active') ? undefined : json['is_active'],
    startDate: !exists(json, 'start_date') ? undefined : json['start_date'],
    endDate: !exists(json, 'end_date') ? undefined : json['end_date'],
    cancelledAt: !exists(json, 'cancelled_at')
      ? undefined
      : json['cancelled_at'],
    usersLimit: !exists(json, 'users_limit') ? undefined : json['users_limit'],
    price: !exists(json, 'price') ? undefined : json['price'],
    paymentPeriod: !exists(json, 'payment_period')
      ? undefined
      : SharedPaymentPeriodFromJSON(json['payment_period']),
    invoices: !exists(json, 'invoices')
      ? undefined
      : (json['invoices'] as Array<any>).map(SubscriptionInvoicesFromJSON),
  };
}

export function SubscriptionToJSON(value?: Subscription | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    payment_plan_id: value.paymentPlanId,
    vendor_id: value.vendorId,
    user_id: value.userId,
    is_active: value.isActive,
    start_date: value.startDate,
    end_date: value.endDate,
    cancelled_at: value.cancelledAt,
    users_limit: value.usersLimit,
    price: value.price,
    payment_period: SharedPaymentPeriodToJSON(value.paymentPeriod),
    invoices:
      value.invoices === undefined
        ? undefined
        : (value.invoices as Array<any>).map(SubscriptionInvoicesToJSON),
  };
}
