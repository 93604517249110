import classNames from 'classnames';
import React from 'react';

import {
  AdoptechReactSelect,
  AdoptechReactSelectProps,
} from '../AdoptechReactSelect/AdoptechReactSelect';
import './AdoptechReactSelect2.scss';

interface AdoptechReactSelect2Props extends AdoptechReactSelectProps {
  bottomComponent?: JSX.Element;
}

export const AdoptechReactSelect2: React.FC<
  AdoptechReactSelect2Props
> = props => {
  const baseCss = 'adoptech-select2-container';

  const customComponents = {
    IndicatorSeparator: (): null => null,
  };

  const newProps = {
    ...props,
    ...{
      components: !props.components
        ? customComponents
        : { ...props.components, ...customComponents },
      label: null,
      hasError: false,
    },
  };

  return (
    <div
      className={classNames(baseCss, {
        disabled: props.isDisabled,
        'adoptech-error': props.hasError,
        [baseCss + '--rounded']: props.rounded,
      })}
    >
      <span className={baseCss + '--label'}>{props.label}</span>
      <AdoptechReactSelect {...newProps} />
      {props.bottomComponent}
    </div>
  );
};
