/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssertionCategoryModel,
  AssertionCategoryModelFromJSON,
  AssertionCategoryModelFromJSONTyped,
  AssertionCategoryModelToJSON,
  AssertionSource,
  AssertionSourceFromJSON,
  AssertionSourceFromJSONTyped,
  AssertionSourceToJSON,
} from './';

/**
 *
 * @export
 * @interface AssertionRunModel
 */
export interface AssertionRunModel {
  /**
   *
   * @type {string}
   * @memberof AssertionRunModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AssertionRunModel
   */
  mode: AssertionRunModelModeEnum;
  /**
   *
   * @type {AssertionSource}
   * @memberof AssertionRunModel
   */
  source?: AssertionSource;
  /**
   *
   * @type {string}
   * @memberof AssertionRunModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AssertionRunModel
   */
  description: string;
  /**
   *
   * @type {AssertionCategoryModel}
   * @memberof AssertionRunModel
   */
  assertionCategory?: AssertionCategoryModel;
  /**
   *
   * @type {string}
   * @memberof AssertionRunModel
   */
  status: AssertionRunModelStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AssertionRunModel
   */
  startedAt: string;
  /**
   *
   * @type {string}
   * @memberof AssertionRunModel
   */
  finishedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof AssertionRunModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AssertionRunModel
   */
  updatedAt: string;
}

export function AssertionRunModelFromJSON(json: any): AssertionRunModel {
  return AssertionRunModelFromJSONTyped(json, false);
}

export function AssertionRunModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionRunModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    mode: json['mode'],
    source: !exists(json, 'source')
      ? undefined
      : AssertionSourceFromJSON(json['source']),
    name: !exists(json, 'name') ? undefined : json['name'],
    description: json['description'],
    assertionCategory: !exists(json, 'assertion_category')
      ? undefined
      : AssertionCategoryModelFromJSON(json['assertion_category']),
    status: json['status'],
    startedAt: json['started_at'],
    finishedAt: json['finished_at'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function AssertionRunModelToJSON(value?: AssertionRunModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    mode: value.mode,
    source: AssertionSourceToJSON(value.source),
    name: value.name,
    description: value.description,
    assertion_category: AssertionCategoryModelToJSON(value.assertionCategory),
    status: value.status,
    started_at: value.startedAt,
    finished_at: value.finishedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AssertionRunModelModeEnum {
  Manual = 'manual',
  Automatic = 'automatic',
}
/**
 * @export
 * @enum {string}
 */
export enum AssertionRunModelStatusEnum {
  InProgress = 'in_progress',
  Compliant = 'compliant',
  Failed = 'failed',
}
