/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PaymentMethodBillingDetailsAddress,
  PaymentMethodBillingDetailsAddressFromJSON,
  PaymentMethodBillingDetailsAddressFromJSONTyped,
  PaymentMethodBillingDetailsAddressToJSON,
} from './';

/**
 *
 * @export
 * @interface PaymentMethodBillingDetails
 */
export interface PaymentMethodBillingDetails {
  /**
   *
   * @type {string}
   * @memberof PaymentMethodBillingDetails
   */
  name?: string;
  /**
   *
   * @type {PaymentMethodBillingDetailsAddress}
   * @memberof PaymentMethodBillingDetails
   */
  address?: PaymentMethodBillingDetailsAddress;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodBillingDetails
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodBillingDetails
   */
  phone?: string | null;
}

export function PaymentMethodBillingDetailsFromJSON(
  json: any
): PaymentMethodBillingDetails {
  return PaymentMethodBillingDetailsFromJSONTyped(json, false);
}

export function PaymentMethodBillingDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentMethodBillingDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    address: !exists(json, 'address')
      ? undefined
      : PaymentMethodBillingDetailsAddressFromJSON(json['address']),
    email: !exists(json, 'email') ? undefined : json['email'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
  };
}

export function PaymentMethodBillingDetailsToJSON(
  value?: PaymentMethodBillingDetails | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    address: PaymentMethodBillingDetailsAddressToJSON(value.address),
    email: value.email,
    phone: value.phone,
  };
}
