import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ComplianceControlFrameworksSimpleList.scss';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import {
  ComplianceEditControlFormModel,
  minimumAssignedFrameworks,
} from '../../../../../components/compliance/Types/complianceTypes';
import { ApplicationState } from '../../../../../types/applicationState';
import { setControlFormModel } from '../../../../../store/compliance/complianceSlice';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import AdoptechIcon from '../../AdoptechIcon/AdoptechIcon';
import { FrameworksPills } from '../../../../../components/Frameworks/Pills';
import {
  EditControlFrameworksModal,
  HandleAddFrameworkPayload,
} from '../EditControlFrameworksModal/EditControlFrameworksModal';
import { selectIsViewModeControlDrawer } from '../../../../../selectors/compliance/selectIsViewModeEditControlDrawer';
import { FrameworkRelation } from '../../../../../swagger/models/FrameworkRelation';

export interface ComplianceFrameworksListProps {
  control: ComplianceEditControlFormModel;
}

export const ComplianceControlFrameworksSimpleList: React.FC<
  ComplianceFrameworksListProps
> = ({ control }) => {
  const baseCss = 'complianceControlFrameworksSimpleList';
  const dispatch = useDispatch();

  const [relations, setRelations] = useState([...control.frameworksRelations]);

  const {
    editControlFormModel,
    isFetchingCategories,
    frameworks: allFrameworks,
  } = useSelector((state: ApplicationState) => state.compliance);
  // for min limit of frameworks
  const frameworksAssigned = relations.map(relation =>
    allFrameworks.find(framework => framework.id === relation.framework.id)
  );

  const [showFrameworkModal, setShowFrameworkModal] = useState(false);
  const isViewMode = useSelector(selectIsViewModeControlDrawer);

  const dispatchRelations = (newRelations: FrameworkRelation[]) => {
    dispatch(
      setControlFormModel({
        ...editControlFormModel,
        frameworksRelations: [...newRelations],
        formTouched: true,
      })
    );
  };

  const handleRemove = (frameworkId: string) => {
    const tempRelations = relations.filter(
      relation => relation.framework?.id !== frameworkId
    );
    setRelations(tempRelations);
    dispatchRelations(tempRelations);
  };

  const handleAdd = (payload: HandleAddFrameworkPayload) => {
    const newRelations = [...relations, payload];

    setRelations(newRelations);
    dispatchRelations(newRelations);
  };

  if (isFetchingCategories && !editControlFormModel.create)
    return <LoadingSpinner />;
  return (
    <>
      <div className={baseCss + '--header'}>
        <div>Parent frameworks</div>

        {!isViewMode && (
          <AdoptechIcon
            shape="square"
            onClick={() => setShowFrameworkModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </AdoptechIcon>
        )}
      </div>
      {(relations || []).length > 0 && (
        <div className={baseCss + '--frameworks'}>
          <div className={baseCss + '--tableHeaders'}>
            <div className={baseCss + '--label'}>Name</div>
            <div className={baseCss + '--label'}>Category</div>
            <div className={baseCss + '--label'}>Reference</div>
            <div className={baseCss + '--label'}>Action</div>
          </div>
          {relations.map(relation => (
            <div
              className={baseCss + '--tableRow'}
              key={relation.framework?.id}
            >
              <div className={baseCss + '--framework'}>
                <FrameworksPills frameworks={[relation.framework]} />
              </div>
              <div className={baseCss + '--category'}>
                {relation.controlCategory.name}
              </div>
              <div className={baseCss + '--reference'}>{relation.annexRef}</div>
              {!isViewMode && (
                <div
                  className={
                    baseCss +
                    (frameworksAssigned.length > minimumAssignedFrameworks
                      ? '--icon'
                      : '--icon-disabled')
                  }
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    onClick={() => handleRemove(relation.framework?.id)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <EditControlFrameworksModal
        show={showFrameworkModal}
        onHide={() => setShowFrameworkModal(false)}
        onConfirm={handleAdd}
        relations={relations}
      />
    </>
  );
};
