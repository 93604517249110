import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  canCreate,
  useCanFeature,
  hasFeature,
  mapShareFeaturesToAccessLevels,
} from '../../../functions/access';
import {
  getPdfDocumentPath,
  PdfPreviewEntityPaths,
} from '../../../functions/routePathsHelpers';
import {
  ShareableType,
  VendorDocumentAttestationStatusEnum,
  VendorDocumentExtended,
  VendorDocumentStatusEnum,
} from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';
import { ApplicationState } from '../../../types/applicationState';
import { PdfOpenMode } from '../../../types/pdfOpenMode';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import {
  openAttestationManagement,
  showExistingPolicyWarningModal,
  showPolicyDrawer,
  updatePolicyToEdit,
} from '../../../store/policies/policiesSlice';
import classNames from 'classnames';
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { ExistingPolicyWarningModalMode } from '../../../components/ExistingPolicyWarningModal/ExistingPolicyWarningModal';
import { selectInProgressPolicies } from '../../../selectors/selectInProgressPolicies';
import { MeatballMenu } from '../../../components/MeatballMenu/MeatballMenu';
import { formatShortDate } from '../../../functions/formatShortDate';
import { showShareModal } from '../../../store/vendors/vendorsSlice';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { PolicyGetLinkInfo } from '../../../components/InProgressPoliciesGrid/InProgressPolicyRow';

interface LivePolicyRowProps {
  vendorDocument: VendorDocumentExtended;
  baseCss: string;
  baseTableCss: string;
  onDownLoadClick: (vd: VendorDocumentExtended) => void;
  onReadRequestClick: (vd: VendorDocumentExtended) => void;
}
export const isRejected = (vd: VendorDocumentExtended): boolean =>
  vd.status === VendorDocumentStatusEnum.Rejected;

export const LivePolicyRow: React.FC<LivePolicyRowProps> = ({
  vendorDocument,
  baseCss,
  baseTableCss,
  onDownLoadClick,
  onReadRequestClick,
}) => {
  const dispatch = useDispatch();
  const policies = useSelector(selectInProgressPolicies);
  const canManageDocuments = canCreate(AccessObject.vendor_documents);
  const canManageAttestations = useCanFeature(
    AccessObject.manage_read_requests
  );
  const canUpdateVersion = useCanFeature(AccessObject.manage_versions);
  const allVendorPolicies = useSelector(
    (state: ApplicationState) => state.policies.vendorPolicies
  );

  const openDrawer = (document: VendorDocumentExtended) => {
    dispatch(showPolicyDrawer());
    dispatch(updatePolicyToEdit(document));
  };

  const getReadByValue = (vendorDoc: VendorDocumentExtended): string => {
    if (vendorDoc.attestationStats.total > 0) {
      return `${vendorDoc.attestationStats.attested} / ${vendorDoc.attestationStats.total}`;
    }
    if (!vendorDoc.needToAttest) {
      return '0 / 0';
    }
    return '';
  };

  const openPdf = (document: VendorDocumentExtended) => {
    dispatch(
      push(
        getPdfDocumentPath({
          vendorDocumentId: document.id,
          mode: PdfOpenMode.Preview,
          basePath: PdfPreviewEntityPaths.policies,
        })
      )
    );
  };

  const policyCategories = useSelector(
    (state: ApplicationState) => state.policies.policyCategories || []
  );

  const customCategory = policyCategories.find(
    category => category.id === vendorDocument.policyCategoryId
  )?.name; // for manual uploaded policy

  const policyCategoryName =
    allVendorPolicies.find(vp => vp.vendorDocumentId === vendorDocument.id)
      ?.categoryName || customCategory;

  return (
    <div
      key={vendorDocument.id}
      onClick={e => {
        e.stopPropagation();
        openPdf(vendorDocument);
      }}
      className={classNames(
        baseCss,
        baseTableCss + '--highlighted',
        baseTableCss + '--row ',
        baseTableCss + '--slim',
        {
          [`${baseTableCss}--alert`]:
            isRejected(vendorDocument) || vendorDocument.reviewOverdue,
        }
      )}
    >
      <div
        className="pendingApprovalPolicies--clickableColumn"
        onClick={e => {
          e.stopPropagation();
          openPdf(vendorDocument);
        }}
      >
        {`${vendorDocument.name} ${vendorDocument.version}`}
      </div>
      <div>{policyCategoryName}</div>
      <div
        onClick={e => {
          if (!canManageDocuments) return;
          e.stopPropagation();
          openDrawer(vendorDocument);
        }}
      >
        <UserAvatar
          showTooltip
          user={vendorDocument.owner}
          size="tag"
          hideText
        />
      </div>
      <div
        onClick={e => {
          e.stopPropagation();
          openDrawer(vendorDocument);
        }}
      >
        {formatShortDate(vendorDocument.createdAt)}
      </div>
      <div>
        {vendorDocument.reviewOverdue ? (
          <div className={baseTableCss + '--red-color'}>
            <FontAwesomeIcon size="lg" icon={faClock} className="mr-2" />
            <span className="font-weight-bold">Overdue</span>
          </div>
        ) : (
          formatShortDate(vendorDocument.nextReviewDate)
        )}
      </div>
      {canManageDocuments && (
        <div
          onClick={e => {
            e.stopPropagation();
            dispatch(openAttestationManagement(vendorDocument));
          }}
        >
          <div>{getReadByValue(vendorDocument)}</div>
        </div>
      )}
      <div>
        {[
          VendorDocumentAttestationStatusEnum.Requested,
          VendorDocumentAttestationStatusEnum.Overdue,
          VendorDocumentAttestationStatusEnum.Imminent,
        ].includes(vendorDocument.vendorDocumentAttestation.status) && (
          <AdoptechButton
            icon={faFileContract}
            onClick={e => {
              dispatch(
                push(
                  getPdfDocumentPath({
                    vendorDocumentId: vendorDocument.id,
                    mode: PdfOpenMode.Attest,
                    basePath: PdfPreviewEntityPaths.policies,
                  })
                )
              );
              e.stopPropagation();
            }}
            variant={AdoptechButtonVariant.PrimaryTransparent}
          >
            Read
          </AdoptechButton>
        )}
        {vendorDocument.needToAttest && (
          <div
            className="livePolicies--readWarning"
            onClick={e => {
              e.stopPropagation();
              onReadRequestClick(vendorDocument);
            }}
          >
            <FontAwesomeIcon
              inverse={false}
              className="livePolicies--readWarningIcon"
              icon={faExclamationCircle}
            />
          </div>
        )}
      </div>

      <div className="adoptechTable--actions">
        <MeatballMenu>
          <Dropdown.Item
            onClick={e => {
              e.stopPropagation();
              dispatch(
                push(
                  getPdfDocumentPath({
                    vendorDocumentId: vendorDocument.id,
                    mode: PdfOpenMode.Preview,
                    basePath: PdfPreviewEntityPaths.policies,
                  })
                )
              );
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faEye} />
            Preview
          </Dropdown.Item>
          <Dropdown.Item
            disabled={!hasFeature(AccessObject.share, vendorDocument?.access)}
            onClick={e => {
              e.stopPropagation();
              dispatch(
                showShareModal({
                  shareableId: vendorDocument.id,
                  shareableType: ShareableType.VendorDocument,
                  showOnlyGetLink: true,
                  getLinkInfo: PolicyGetLinkInfo,
                  accessLevels: mapShareFeaturesToAccessLevels(
                    vendorDocument.access.feature
                  ),
                  relativePath: vendorDocument.relativePath,
                })
              );
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faLink} />
            Get link
          </Dropdown.Item>
          <Dropdown.Item
            onClick={e => {
              onDownLoadClick(vendorDocument);
              e.stopPropagation();
            }}
          >
            <FontAwesomeIcon
              className="meatballMenu--icon"
              icon={faCloudDownload}
            />
            Download
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="meatballMenu--item"
            disabled={!canManageDocuments}
            onClick={e => {
              e.stopPropagation();
              openDrawer(vendorDocument);
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faGear} />
            Policy settings
          </Dropdown.Item>

          {canUpdateVersion &&
            vendorDocument.policyId &&
            !policies.some(p => p.policyId === vendorDocument.policyId) && (
              <Dropdown.Item
                onClick={e => {
                  dispatch(
                    showExistingPolicyWarningModal({
                      policyId: vendorDocument.policyId,
                      existingPolicyWarningModalMode:
                        ExistingPolicyWarningModalMode.Update,
                    })
                  );
                  e.stopPropagation();
                }}
              >
                <FontAwesomeIcon className="meatballMenu--icon" icon={faSync} />
                Update current version
              </Dropdown.Item>
            )}
        </MeatballMenu>
      </div>
    </div>
  );
};
