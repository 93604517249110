/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubCompanyStatus,
  TrusthubCompanyStatusFromJSON,
  TrusthubCompanyStatusFromJSONTyped,
  TrusthubCompanyStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubCompanyUpdatePayloadTrusthubCompany
 */
export interface TrusthubCompanyUpdatePayloadTrusthubCompany {
  /**
   *
   * @type {string}
   * @memberof TrusthubCompanyUpdatePayloadTrusthubCompany
   */
  name?: string;
  /**
   *
   * @type {TrusthubCompanyStatus}
   * @memberof TrusthubCompanyUpdatePayloadTrusthubCompany
   */
  status?: TrusthubCompanyStatus;
  /**
   *
   * @type {Array<string>}
   * @memberof TrusthubCompanyUpdatePayloadTrusthubCompany
   */
  relationshipOwnerIds?: Array<string>;
}

export function TrusthubCompanyUpdatePayloadTrusthubCompanyFromJSON(
  json: any
): TrusthubCompanyUpdatePayloadTrusthubCompany {
  return TrusthubCompanyUpdatePayloadTrusthubCompanyFromJSONTyped(json, false);
}

export function TrusthubCompanyUpdatePayloadTrusthubCompanyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubCompanyUpdatePayloadTrusthubCompany {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    status: !exists(json, 'status')
      ? undefined
      : TrusthubCompanyStatusFromJSON(json['status']),
    relationshipOwnerIds: !exists(json, 'relationship_owner_ids')
      ? undefined
      : json['relationship_owner_ids'],
  };
}

export function TrusthubCompanyUpdatePayloadTrusthubCompanyToJSON(
  value?: TrusthubCompanyUpdatePayloadTrusthubCompany | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    status: TrusthubCompanyStatusToJSON(value.status),
    relationship_owner_ids: value.relationshipOwnerIds,
  };
}
