import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAgreementsModals } from '../../../hooks/agreements/useAgreementsModals';
import { AgreementsModals } from '../AgreementsModals/AgreementsModals';
import { AdoptechTooltip } from '../../AdoptechTooltip/AdoptechTooltip';
import { selectAgreementQuestionsAreFilledIn } from '../../../selectors/selectAgreementQuestionsAreFilledIn';
import { selectAgreementsProgress } from '../../../selectors/selectAgreementsProgress';
import { patchAgreement } from '../../../store/agreements/agreementsThunks';
import { fetchVendorDocumentDownload } from '../../../store/vendors/vendorsThunks';
import {
  AgreementExtended,
  AgreementExtendedModesAvailableEnum,
  AgreementModelStatusEnum,
} from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { AdoptechSliderSwitcher } from '../../AdoptechSliderSwitcher/AdoptechSliderSwitcher';
import { canEditAgreement } from '../AgreementEditor/AgreementEditor';
import { ExitConfirmationModal } from '../../ExitConfirmationModal/ExitConfirmationModal';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Panel } from '../../Panel/Panel';
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import { agreementsPageRoute } from '../../Routes/Routes';
import { AgreementMeatballMenu } from '../AgreementMeatballMenu/AgreementMeatballMenu';
import { AgreementSendReviewModal } from '../AgreementSendReviewModal/AgreementSendReviewModal';
import SignAgreementModal from '../SignAgreementModal/SignAgreementModal';
import './AgreementSummaryTile.scss';

interface AgreementSummaryTileProps {
  setMode: (mode: AgreementExtendedModesAvailableEnum) => void;
}

export const currentAgreementModes = (agreement: AgreementExtended) => {
  const labelsMap = [
    [AgreementExtendedModesAvailableEnum.Survey, 'Questions'],
    [AgreementExtendedModesAvailableEnum.Preview, 'Preview'],
    [AgreementExtendedModesAvailableEnum.Edit, 'Edit'],
  ].filter(label =>
    (Object.values(agreement.modesAvailable) as string[]).includes(label[0])
  );
  const labels = labelsMap.map(valueAndLabel => valueAndLabel[1]);
  const values = labelsMap.map(valueAndLabel => valueAndLabel[0]);
  return { labels, values };
};

export const AgreementSummaryTile: React.FC<AgreementSummaryTileProps> = ({
  setMode,
}) => {
  const agreement = useSelector(
    (state: ApplicationState) => state.agreements.currentAgreement
  );

  const questionsAreFilledIn = useSelector(selectAgreementQuestionsAreFilledIn);
  const progress = useSelector(selectAgreementsProgress);

  const dispatch = useDispatch();
  const [isShowingSendReviewModal, setIsShowingSendReviewModal] =
    useState(false);

  const mode = useSelector(
    (state: ApplicationState) => state.agreements.currentAgreementMode
  );
  const { labels, values } = currentAgreementModes(agreement);

  const modalsProps = useAgreementsModals();
  const { handleDeleteClick } = modalsProps;

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSignModal, setShowSignModal] = useState(false);
  // Set custom handler on click per each div inside SliderSwitcher
  // For example: On click question mode => show confirmation modal
  // By default: On click any mode => show this mode.

  const onClickModeHandlers = () => {
    const clickSliderModeDisabled =
      !questionsAreFilledIn &&
      mode === AgreementExtendedModesAvailableEnum.Survey;
    if (clickSliderModeDisabled)
      return Array(Object.values(values).length).fill(() => {});

    if (
      values.includes(AgreementExtendedModesAvailableEnum.Survey) &&
      (agreement.contentIsCustom ||
        mode === AgreementExtendedModesAvailableEnum.Edit)
    ) {
      const showWarningOnQuestionsModeClick = [
        (value: AgreementExtendedModesAvailableEnum) => {
          setShowConfirmModal(true);
        },
        null,
        null,
      ];
      return showWarningOnQuestionsModeClick;
    }
  };

  const isPreviewMode = mode === AgreementExtendedModesAvailableEnum.Preview;
  const iconEnabled = isPreviewMode;

  const pdfPreviewUrl = useSelector(
    (state: ApplicationState) => state.global.pdfPreviewUrl
  );

  const isRequestingAgreementPdf = useSelector(
    (state: ApplicationState) => state.agreements.isRequestingAgreementPdf
  );

  const tooltipsPerMode = [
    { enabled: false },
    {
      enabled: true,
      text: 'You have to answer all questions to preview the document.',
    },
    {
      enabled: true,
      text: 'You have to answer all questions to edit the document.',
    },
  ];

  const isCompleted =
    agreement.status === AgreementModelStatusEnum.Signed ||
    agreement.status === AgreementModelStatusEnum.Rejected;

  const downloadRef = useRef(null);

  const canEdit = agreement.access.update;
  if (!agreement) {
    return null;
  }

  return (
    <Panel>
      <div className="agreementSummaryTile">
        <div className="agreementSummaryTile--progressBar">
          <ProgressBar progress={progress.total * 100} />
        </div>
        <div className="agreementSummaryTile--mainContent">
          <div
            className="agreementEditor--back"
            onClick={() => {
              dispatch(push(agreementsPageRoute));
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <div className="agreementSummaryTile--info">
            <div className="agreementSummaryTile--column">
              <div className="agreementSummaryTile--columnHeader">
                Agreement
              </div>
              <div className="agreementSummaryTile--columnData">
                {agreement.name}
              </div>
            </div>
            {canEditAgreement(agreement) && (
              <div className="agreementSummaryTile--column agreementSummaryTile--column-inline">
                <AdoptechSliderSwitcher
                  values={values as string[]}
                  labels={labels}
                  setSelected={setMode}
                  selected={mode}
                  onClickHandlers={onClickModeHandlers()}
                  tooltipsOptions={
                    questionsAreFilledIn ||
                    !values.includes(
                      AgreementExtendedModesAvailableEnum.Survey
                    ) ||
                    mode !== AgreementExtendedModesAvailableEnum.Survey
                      ? []
                      : tooltipsPerMode
                  }
                />
              </div>
            )}

            <div className="agreementSummaryTile--column agreementSummaryTile--column-inline agreementSummaryTile--action-icons">
              {isRequestingAgreementPdf && <LoadingSpinner inlineSmall />}
              {!isRequestingAgreementPdf && (
                <>
                  {!isCompleted && canEdit && (
                    <div
                      className={classNames('action-icon', {
                        disabled: !iconEnabled,
                      })}
                      onClick={() =>
                        iconEnabled ? setIsShowingSendReviewModal(true) : ''
                      }
                    >
                      <AdoptechTooltip
                        text={
                          iconEnabled
                            ? 'Share'
                            : 'To share with counterparty or colleagues, please answer all the questions first and select Done'
                        }
                        identifier="send-for-review-agreement"
                        showTooltip
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </AdoptechTooltip>
                    </div>
                  )}
                  <div
                    className={classNames('action-icon', {
                      disabled: !iconEnabled,
                    })}
                    onClick={() => {
                      if (!iconEnabled) return;
                      // For download if agreement.vendorDocumentId use /download else /preview endpoint.
                      if (agreement.vendorDocumentId) {
                        dispatch(
                          fetchVendorDocumentDownload({
                            id: agreement.vendorDocumentId,
                            successCallback: vendorDocumentDownload => {
                              window.location.href =
                                vendorDocumentDownload.document;
                            },
                          })
                        );
                        return;
                      }
                      downloadRef.current.click();
                    }}
                  >
                    <a
                      ref={downloadRef}
                      href={pdfPreviewUrl}
                      download={agreement.name}
                      className="hidden"
                    />
                    <AdoptechTooltip
                      text={
                        iconEnabled
                          ? 'Download'
                          : 'To download, please complete all questions first and select Done'
                      }
                      identifier="download-agreement"
                      showTooltip
                    >
                      <FontAwesomeIcon icon={faCloudDownload} />
                    </AdoptechTooltip>
                  </div>
                  {agreement.toBeSigned && (
                    <div
                      className={classNames('action-icon', {
                        disabled: !iconEnabled,
                      })}
                      onClick={() =>
                        iconEnabled ? setShowSignModal(true) : ''
                      }
                    >
                      <AdoptechTooltip
                        text={
                          iconEnabled
                            ? 'Sign'
                            : 'To sign, please complete all questions first and select Done'
                        }
                        identifier="sign-agreement"
                        showTooltip
                      >
                        <FontAwesomeIcon icon={faFileContract} />
                      </AdoptechTooltip>
                    </div>
                  )}

                  <div>
                    <AgreementMeatballMenu
                      agreement={agreement}
                      handleDeleteClick={handleDeleteClick}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isShowingSendReviewModal && (
        <AgreementSendReviewModal
          agreementId={agreement.id}
          onCancel={() => setIsShowingSendReviewModal(false)}
        />
      )}
      {showSignModal && (
        <SignAgreementModal onClose={() => setShowSignModal(false)} />
      )}
      <AgreementsModals {...modalsProps} />
      {showConfirmModal && (
        <ExitConfirmationModal
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={() => {
            dispatch(
              patchAgreement({
                agreementId: agreement.id,
                contentIsCustom: false,
                onSuccess: () => {
                  setShowConfirmModal(false);
                  dispatch(setMode(AgreementExtendedModesAvailableEnum.Survey));
                },
              })
            );
          }}
          submitButtonText="Continue"
          title="Warning"
          text="<b>By entering Question mode your text changes will be lost and the agreement will revert to the standard text generated by your answers.</b>"
        />
      )}
    </Panel>
  );
};
