/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AnswerDeletePayload
 */
export interface AnswerDeletePayload {
  /**
   *
   * @type {Array<string>}
   * @memberof AnswerDeletePayload
   */
  answersIds?: Array<string>;
}

export function AnswerDeletePayloadFromJSON(json: any): AnswerDeletePayload {
  return AnswerDeletePayloadFromJSONTyped(json, false);
}

export function AnswerDeletePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AnswerDeletePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    answersIds: !exists(json, 'answers_ids') ? undefined : json['answers_ids'],
  };
}

export function AnswerDeletePayloadToJSON(
  value?: AnswerDeletePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    answers_ids: value.answersIds,
  };
}
