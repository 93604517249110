/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsFromJSONTyped,
  UserDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface LegislationReviewModel
 */
export interface LegislationReviewModel {
  /**
   *
   * @type {string}
   * @memberof LegislationReviewModel
   */
  id: string;
  /**
   *
   * @type {UserDetails}
   * @memberof LegislationReviewModel
   */
  user: UserDetails;
  /**
   *
   * @type {string}
   * @memberof LegislationReviewModel
   */
  reviewDate: string | null;
  /**
   *
   * @type {string}
   * @memberof LegislationReviewModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof LegislationReviewModel
   */
  updatedAt?: string;
}

export function LegislationReviewModelFromJSON(
  json: any
): LegislationReviewModel {
  return LegislationReviewModelFromJSONTyped(json, false);
}

export function LegislationReviewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LegislationReviewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    user: UserDetailsFromJSON(json['user']),
    reviewDate: json['review_date'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function LegislationReviewModelToJSON(
  value?: LegislationReviewModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    user: UserDetailsToJSON(value.user),
    review_date: value.reviewDate,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
