import { createSelector } from '@reduxjs/toolkit';
import { sort } from '../functions/sort';
import { sortByDate } from '../functions/sortByDate';
import { sortByOwner } from '../functions/sortByOwner';
import { AuditModel, AuditModelStatusEnum } from '../swagger/models/AuditModel';
import { VendorUser } from '../swagger/models/VendorUser';
import { ApplicationState } from '../types/applicationState';
import { ColumnSort } from '../types/columnSort';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';

const sortAudits = (a: AuditModel, b: AuditModel, columnSort: ColumnSort) => {
  const typedColumn = columnSort.name as keyof Omit<AuditModel, 'survey'>;

  if (['owner', 'createdBy'].includes(typedColumn)) {
    return sortByOwner(
      a[typedColumn] as VendorUser,
      b[typedColumn] as VendorUser,
      columnSort.direction
    );
  }
  if (['startedAt', 'completedAt'].includes(typedColumn)) {
    const dateColumn = typedColumn as 'startedAt' | 'completedAt';
    return sortByDate(a[dateColumn], b[dateColumn], columnSort.direction);
  }

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

export const selectAudits = createSelector(
  (state: ApplicationState) => state.audits.audits,
  selectSortSettings(Grid.Audits),
  (state: ApplicationState) => state.audits.completedFilter,
  (audits: AuditModel[], sortSetting, completedFilter) => {
    return [...audits]
      .filter(audit =>
        completedFilter ? true : audit.status !== AuditModelStatusEnum.Completed
      )
      .sort((a, b) => sortAudits(a, b, sortSetting.columnSort));
  }
);
