/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface RiskReportModel
 */
export interface RiskReportModel {
  /**
   *
   * @type {string}
   * @memberof RiskReportModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RiskReportModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RiskReportModel
   */
  summary?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof RiskReportModel
   */
  includeUnapprovedRisks?: boolean;
  /**
   *
   * @type {VendorUser}
   * @memberof RiskReportModel
   */
  owner?: VendorUser;
  /**
   *
   * @type {string}
   * @memberof RiskReportModel
   */
  vendorDocumentId?: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskReportModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof RiskReportModel
   */
  updatedAt?: string;
}

export function RiskReportModelFromJSON(json: any): RiskReportModel {
  return RiskReportModelFromJSONTyped(json, false);
}

export function RiskReportModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskReportModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    includeUnapprovedRisks: !exists(json, 'include_unapproved_risks')
      ? undefined
      : json['include_unapproved_risks'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    vendorDocumentId: !exists(json, 'vendor_document_id')
      ? undefined
      : json['vendor_document_id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function RiskReportModelToJSON(value?: RiskReportModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    summary: value.summary,
    include_unapproved_risks: value.includeUnapprovedRisks,
    owner: VendorUserToJSON(value.owner),
    vendor_document_id: value.vendorDocumentId,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
