import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ApplicationState } from '../../../types/applicationState';
import { AutoSaveToast } from '../../AutoSaveToast/AutoSaveToast';
import './AgreementEditor.scss';
import {
  fetchAgreementById,
  patchAgreementContent,
} from '../../../store/agreements/agreementsThunks';
import {
  setAgreementContent,
  setAgreementContentChanged,
  setAgreementMode,
  setCurrentAgreement,
} from '../../../store/agreements/agreementsSlice';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import {
  AgreementSummaryTile,
  currentAgreementModes,
} from '../AgreementSummaryTile/AgreementSummaryTile';
import {
  AgreementExtended,
  AgreementExtendedModesAvailableEnum,
  AgreementModel,
  AgreementModelStatusEnum,
} from '../../../swagger';
import { PreviewMode } from './PreviewMode/PreviewMode';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { selectAgreementQuestionsAreFilledIn } from '../../../selectors/selectAgreementQuestionsAreFilledIn';
import { QuestionsMode } from './QuestionsMode/QuestionsMode';
import AgreementsTextEditor from '../AgreementsTextEditor/AgreementsTextEditor';
import AgreementsTextEditorFooter from '../AgreementsTextEditorFooter/AgreementsTextEditorFooter';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { setErrorMessage } from '../../../store/global/globalSlice';

export const canEditAgreement = (agreement: AgreementModel) =>
  agreement.access.update &&
  agreement.status === AgreementModelStatusEnum.Draft;

export const AgreementEditor: React.FC = () => {
  const params = useParams() as { id: string };

  const {
    currentAgreement,
    agreementContent,
    agreementContentChanged,
    isRequestingCurrentAgreement,
    currentAgreementMode: mode,
    agreementContentChanged: contentChanged,
    agreementContent: content,
  } = useSelector((state: ApplicationState) => state.agreements);
  const questionsAreFilledIn = useSelector(selectAgreementQuestionsAreFilledIn);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentAgreement(null));

    if (params?.id) {
      const onSuccess = (agreement: AgreementExtended) => {
        const { values } = currentAgreementModes(agreement);

        dispatch(
          setAgreementMode(
            canEditAgreement(agreement)
              ? (values[0] as AgreementExtendedModesAvailableEnum)
              : AgreementExtendedModesAvailableEnum.Preview
          )
        );
      };
      dispatch(fetchAgreementById(params.id, true, onSuccess));
    }
    return () => {
      dispatch(setCurrentAgreement(null));
    };
  }, [params?.id]);

  useEffect(() => {
    if (!currentAgreement) return;
    dispatch(setAgreementContent(currentAgreement.content));
    dispatch(setAgreementContentChanged(false));
  }, [currentAgreement]);

  const [isRequestingEditSection, setIsRequestingEditSection] = useState(false);
  const currentModeSection = () => {
    if (mode === AgreementExtendedModesAvailableEnum.Preview)
      return <PreviewMode />;
    if (mode === AgreementExtendedModesAvailableEnum.Edit)
      return (
        <AgreementsTextEditor
          text={content}
          onChange={c => {
            dispatch(setAgreementContent(c));
            dispatch(setAgreementContentChanged(true));
          }}
        />
      );
    return <QuestionsMode />;
  };

  const agreementLoading = isRequestingCurrentAgreement;

  if (agreementLoading) {
    return <LoadingSpinner />;
  }

  if (!currentAgreement) {
    return null;
  }

  const handleDone = () => {
    if (mode === AgreementExtendedModesAvailableEnum.Edit) {
      dispatch(
        patchAgreementContent({
          agreementId: currentAgreement.id,
          content,
          onSuccess: () => {
            dispatch(
              setAgreementMode(AgreementExtendedModesAvailableEnum.Preview)
            );
          },
        })
      );
    } else {
      dispatch(setAgreementMode(AgreementExtendedModesAvailableEnum.Preview));
    }
  };
  const doneDisabled = () => {
    if (
      mode === AgreementExtendedModesAvailableEnum.Survey &&
      questionsAreFilledIn
    ) {
      return false;
    }

    return !(
      mode === AgreementExtendedModesAvailableEnum.Edit && contentChanged
    );
  };

  const setMode = (value: AgreementExtendedModesAvailableEnum) => {
    if (value === AgreementExtendedModesAvailableEnum.Edit) {
      setIsRequestingEditSection(true);
      // refetch agreement, because we need the latest content field
      // ( without page spinner but with spinner below header before showing the editor with new content  )
      dispatch(
        fetchAgreementById(params.id, false, agreement => {
          // if external counterpart signed but owner still on this page and click switch to editor,
          // show "simplified preview only" mode.

          if (!canEditAgreement(agreement)) {
            dispatch(
              setErrorMessage(
                'Agreement should be in draft state to be editable'
              )
            );
            window.location.reload();
            return;
          }
          dispatch(setAgreementMode(value));
          setIsRequestingEditSection(false);
        })
      );
      return;
    }

    if (agreementContentChanged) {
      dispatch(
        patchAgreementContent({
          agreementId: params.id,
          content: agreementContent,
          onSuccess: () => {
            dispatch(setAgreementContentChanged(false));
          },
        })
      );
    }
    dispatch(setAgreementMode(value));
  };
  const baseCss = 'agreementEditor';
  return (
    <div>
      <div className={baseCss}>
        <div className={baseCss + '--summary'}>
          <div className={baseCss + '--summaryTile'}>
            <AgreementSummaryTile setMode={setMode} />
          </div>
        </div>
        <div className={baseCss + '--categoryGrid'}>
          {isRequestingEditSection ? <LoadingSpinner /> : currentModeSection()}
        </div>
        {mode === AgreementExtendedModesAvailableEnum.Survey && (
          <div className={baseCss + '--footer d-flex justify-content-end'}>
            <AdoptechButton
              variant={
                questionsAreFilledIn
                  ? AdoptechButtonVariant.Primary
                  : AdoptechButtonVariant.White
              }
              onClick={handleDone}
              disabled={doneDisabled()}
            >
              Done
            </AdoptechButton>
          </div>
        )}
        {mode === AgreementExtendedModesAvailableEnum.Edit && (
          <AgreementsTextEditorFooter
            doneDisabled={doneDisabled()}
            onDone={handleDone}
            updatedAt={currentAgreement.updatedAt}
            updatedBy={currentAgreement.updatedBy}
          />
        )}
      </div>
      <AutoSaveToast />
    </div>
  );
};
