import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { isValidEmailAddress } from '../../functions/isValidEmailAddress';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import {
  createAgreement,
  createExternalAgreementSignatory,
  updateExternalAgreementSignatory,
} from '../../store/agreements/agreementsThunks';
import { fetchUserDetailsByEmail } from '../../store/user/userThunks';
import {
  AgreementSignatoryModel,
  UserDetails,
  AgreementCreatePayloadAgreementSignatories,
  AgreementExtended,
  AgreementSignatoryModelTypeEnum,
  AgreementSignatoriesIdPatchRequest,
} from '../../swagger';
import './ExternalSignatoryForm.scss';
import { showGlobalToast } from '../../store/global/globalSlice';
import { termsModel } from '../Agreements/AddNewAgreementForm/TermsStep/TermsStep';
import { AdoptechRadioButtonsGroup } from '../AdoptechRadioButtonsGroup/AdoptechRadioButtonsGroup';
import { AdoptechTextInput2 } from '../AdoptechTextInput2/AdoptechTextInput2';
import { capitaliseFirst } from '../../functions/capitaliseFirst';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { agreementShowPage } from '../Routes/Routes';
import { useAdoptechDebounce } from '../../hooks/useAdoptechDebounce';

export enum SignatoryTypeMode {
  employee,
}
interface ExternalSignatoryFormProps {
  agreementId?: string;
  signatory?: AgreementSignatoryModel | null;
  standardTemplateId?: string;
  templateId?: termsModel['id'];
  onSubmit?(): void;
  onCancel?(): void;
  name: string;
  onSubmitError?(): void;
  onChange?(signatory: AgreementCreatePayloadAgreementSignatories): void;
  readonly?: boolean;
  mode?: SignatoryTypeMode; // if employee -> show only individual fields else can be company/invididual
}

const EXTERNAL_SIGNATORY_COMPANY_MODEL: AgreementCreatePayloadAgreementSignatories =
  {
    type: AgreementSignatoryModelTypeEnum.ExternalCompany,
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    companyAddress: '',
    companyNumber: undefined,
  };
const EXTERNAL_SIGNATORY_INDIVIDUAL_MODEL: AgreementCreatePayloadAgreementSignatories =
  {
    type: AgreementSignatoryModelTypeEnum.ExternalIndividual,
    firstName: '',
    lastName: '',
    email: '',
    address: '',
  };

export const handleSubmitClass = 'handleSubmit';

export const ExternalSignatoryForm: React.FC<ExternalSignatoryFormProps> = ({
  agreementId,
  signatory,
  standardTemplateId,
  templateId,
  onSubmit = () => {},
  onSubmitError = () => {},
  name,
  readonly,
  onChange = () => {},
  mode,
}) => {
  const reduceSignatory = (externalSignatory: AgreementSignatoryModel) => {
    return Object.keys(getInitialSignatoryState(signatoryType)).reduce(
      (
        acc: AgreementCreatePayloadAgreementSignatories,
        val: keyof AgreementCreatePayloadAgreementSignatories
      ) => {
        if (val === 'type') {
          acc[val] = signatoryType;
        } else {
          acc[val] = externalSignatory[val];
        }

        return acc;
      },
      {}
    );
  };
  const getInitialSignatoryState = (type: string) => {
    switch (type) {
      case AgreementSignatoryModelTypeEnum.ExternalCompany:
        return EXTERNAL_SIGNATORY_COMPANY_MODEL;
        break;
      case AgreementSignatoryModelTypeEnum.ExternalIndividual:
        return EXTERNAL_SIGNATORY_INDIVIDUAL_MODEL;
        break;
      default:
        return {};
    }
  };
  const dispatch = useDispatch();
  const vendorId = useSelector(selectCurrentVendor)?.id;
  const [signatoryType, setSignatoryType] =
    useState<AgreementSignatoryModelTypeEnum>(signatory?.type);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [currentSignatory, setCurrentSignatory] =
    useState<AgreementCreatePayloadAgreementSignatories>(
      signatory
        ? reduceSignatory(signatory)
        : getInitialSignatoryState(signatoryType)
    );

  const optionalFields = ['companyNumber'];

  const allFieldsAreFilled = !Object.keys(currentSignatory).some(
    (key: keyof AgreementCreatePayloadAgreementSignatories) => {
      return optionalFields.includes(key) ? false : !currentSignatory[key];
    }
  );
  const formValid = allFieldsAreFilled && !isEmailInvalid;

  const handleTypeChange = (type: AgreementSignatoryModelTypeEnum) => {
    setSignatoryType(type);
    setIsEmailInvalid(false);
    const initialState = getInitialSignatoryState(type);
    setCurrentSignatory(initialState);
    onChange(initialState);
  };
  const updateCurrentSignatory = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id } = event.target;
    const value =
      event.target.id === 'email'
        ? event.target.value?.toLowerCase()
        : event.target.value;
    setCurrentSignatory(previousSignatory => ({
      ...previousSignatory,
      [id]: value,
    }));
    const payload = { [id]: value };
    const updatedSignatory = { ...currentSignatory, ...payload };
    onChange(updatedSignatory);
  };
  const employeeMode = mode === SignatoryTypeMode.employee;
  useEffect(() => {
    if (employeeMode) {
      const type = AgreementSignatoryModelTypeEnum.ExternalIndividual;
      setSignatoryType(type);
      setCurrentSignatory(
        agreementId ? currentSignatory : getInitialSignatoryState(type)
      );
      onChange(currentSignatory);
    }
  }, []);

  const handleDebouncedEmailCheck = (email: string): void => {
    if (email && isValidEmailAddress(email)) {
      setIsEmailInvalid(false);
      dispatch(
        fetchUserDetailsByEmail({
          email,
          onSuccess: (users: UserDetails[]) => {
            if (users.length) {
              const user = users[0];

              setCurrentSignatory(previousSignatory => ({
                ...previousSignatory,
                firstName: user.firstName,
                lastName: user.lastName,
              }));
            }
          },
        })
      );
    } else if (!isValidEmailAddress(email)) {
      setIsEmailInvalid(true);
    }
  };

  const checkWithDebounce = useAdoptechDebounce();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value?.toLowerCase();
    updateCurrentSignatory(event);
    checkWithDebounce(() => {
      handleDebouncedEmailCheck(value);
    });
  };

  const onSuccess = (toastText: string) => {
    dispatch(showGlobalToast(toastText));
    onSubmit && onSubmit();
  };

  const handleSubmit = () => {
    if (!formValid) {
      onSubmitError();
      return;
    }

    if (!agreementId) {
      return dispatch(
        createAgreement({
          vendorId,
          body: {
            agreement: {
              agreementTemplateId: standardTemplateId,
              name,
              ...(templateId !== standardTemplateId && {
                sourceAgreementId: templateId,
              }),
            },
            agreementSignatories: [currentSignatory],
          },
          onError: onSubmitError,
          onSuccess: (response: AgreementExtended) => {
            dispatch(push(agreementShowPage.replace(':id', response.id)));
            onSuccess('Agreement has been successfully created.');
          },
        })
      );
    }

    if (!signatory) {
      dispatch(
        createExternalAgreementSignatory({
          agreementId,
          body: {
            agreementSignatories: [currentSignatory],
          },
          onError: onSubmitError,
          onSuccess: () => {
            onSuccess('Signatory has been successfully created.');
          },
        })
      );
    } else {
      const signatoryUpdatePayloadFields = Object.fromEntries(
        Object.entries(currentSignatory).map(([k, v]) => [
          `agreementSignatory${capitaliseFirst(k)}`,
          v,
        ])
      );
      const body: AgreementSignatoriesIdPatchRequest = {
        ...signatoryUpdatePayloadFields,
        id: signatory.id,
      };

      dispatch(
        updateExternalAgreementSignatory({
          body,
          onError: onSubmitError,
          onSuccess: () => {
            onSuccess('Signatory has been successfully updated.');
          },
        })
      );
    }
  };

  const baseCss = 'externalSignatoryForm';

  const signatoryLabel = employeeMode
    ? 'employee'
    : signatoryType === AgreementSignatoryModelTypeEnum.ExternalIndividual
      ? 'individual'
      : 'company';

  const formFields = (
    <>
      <span className={baseCss + '--label'}>
        {`Please enter the ${signatoryLabel}'s details below.`}
      </span>
      <AdoptechTextInput2
        value={currentSignatory?.email}
        hasError={!currentSignatory?.email || isEmailInvalid}
        id="email"
        autoCapitalize="none"
        label="Email address"
        type="email"
        onChange={handleEmailChange}
        disabled={readonly}
        additionalClass="mt-2"
        icon={faPen}
      />
      <AdoptechTextInput2
        value={currentSignatory?.firstName}
        hasError={!currentSignatory?.firstName}
        id="firstName"
        label="First name"
        type="text"
        disabled={readonly}
        onChange={updateCurrentSignatory}
        additionalClass="mt-2"
        icon={faPen}
      />
      <AdoptechTextInput2
        value={currentSignatory?.lastName}
        hasError={!currentSignatory?.lastName}
        id="lastName"
        label="Surname"
        type="text"
        disabled={readonly}
        onChange={updateCurrentSignatory}
        additionalClass="mt-2"
        icon={faPen}
      />
      {signatoryType === AgreementSignatoryModelTypeEnum.ExternalIndividual && (
        <React.Fragment>
          <AdoptechTextInput2
            value={currentSignatory?.address}
            hasError={!currentSignatory?.address}
            id="address"
            label="Address"
            type="text"
            disabled={readonly}
            onChange={updateCurrentSignatory}
            additionalClass="mt-2"
            icon={faPen}
          />
        </React.Fragment>
      )}
      {signatoryType === AgreementSignatoryModelTypeEnum.ExternalCompany && (
        <React.Fragment>
          <AdoptechTextInput2
            value={currentSignatory?.companyName}
            hasError={!currentSignatory?.companyName}
            id="companyName"
            label="Registered company name"
            type="text"
            disabled={readonly}
            onChange={updateCurrentSignatory}
            additionalClass="mt-2"
            icon={faPen}
          />
          <AdoptechTextInput2
            value={currentSignatory?.companyAddress}
            hasError={!currentSignatory?.companyAddress}
            id="companyAddress"
            label="Registered company address"
            type="text"
            disabled={readonly}
            onChange={updateCurrentSignatory}
            additionalClass="mt-2"
            icon={faPen}
          />
          <AdoptechTextInput2
            value={currentSignatory?.companyNumber}
            id="companyNumber"
            label="Company number(optional)"
            type="text"
            disabled={readonly}
            onChange={updateCurrentSignatory}
            additionalClass="mt-2"
            icon={faPen}
          />
        </React.Fragment>
      )}
    </>
  );

  return (
    <div className={baseCss}>
      {agreementId && !employeeMode && (
        <div className={baseCss + 'description'}>
          Enter counterparty details below
        </div>
      )}
      <div className={handleSubmitClass} onClick={() => handleSubmit()}></div>
      <div className={baseCss + '--body'}>
        {!employeeMode && (
          <AdoptechRadioButtonsGroup
            disabled={readonly}
            values={[
              AgreementSignatoryModelTypeEnum.ExternalIndividual,
              AgreementSignatoryModelTypeEnum.ExternalCompany,
            ]}
            labels={['Individual', 'Company']}
            value={signatory?.type}
            onChange={(value: string) =>
              handleTypeChange(value as AgreementSignatoryModelTypeEnum)
            }
          />
        )}
        {signatoryType && formFields}
      </div>
    </div>
  );
};
