/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface NotificationModel
 */
export interface NotificationModel {
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  htmlMessage: string;
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  relatedToId: string | null;
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  relatedToType: string | null;
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  relatedToPath?: string | null;
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  createdAt: string;
}

export function NotificationModelFromJSON(json: any): NotificationModel {
  return NotificationModelFromJSONTyped(json, false);
}

export function NotificationModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NotificationModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    htmlMessage: json['html_message'],
    relatedToId: json['related_to_id'],
    relatedToType: json['related_to_type'],
    relatedToPath: !exists(json, 'related_to_path')
      ? undefined
      : json['related_to_path'],
    createdAt: json['created_at'],
  };
}

export function NotificationModelToJSON(value?: NotificationModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    html_message: value.htmlMessage,
    related_to_id: value.relatedToId,
    related_to_type: value.relatedToType,
    related_to_path: value.relatedToPath,
    created_at: value.createdAt,
  };
}
