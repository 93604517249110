/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TwoFactorVerifyTokenPayloadObj,
  TwoFactorVerifyTokenPayloadObjFromJSON,
  TwoFactorVerifyTokenPayloadObjFromJSONTyped,
  TwoFactorVerifyTokenPayloadObjToJSON,
} from './';

/**
 *
 * @export
 * @interface TwoFactorVerifyTokenPayload
 */
export interface TwoFactorVerifyTokenPayload {
  /**
   *
   * @type {TwoFactorVerifyTokenPayloadObj}
   * @memberof TwoFactorVerifyTokenPayload
   */
  obj?: TwoFactorVerifyTokenPayloadObj;
}

export function TwoFactorVerifyTokenPayloadFromJSON(
  json: any
): TwoFactorVerifyTokenPayload {
  return TwoFactorVerifyTokenPayloadFromJSONTyped(json, false);
}

export function TwoFactorVerifyTokenPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TwoFactorVerifyTokenPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    obj: !exists(json, 'obj')
      ? undefined
      : TwoFactorVerifyTokenPayloadObjFromJSON(json['obj']),
  };
}

export function TwoFactorVerifyTokenPayloadToJSON(
  value?: TwoFactorVerifyTokenPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    obj: TwoFactorVerifyTokenPayloadObjToJSON(value.obj),
  };
}
