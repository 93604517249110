import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { setCurrentAgreementId } from '../../store/agreements/agreementsSlice';
import {
  deleteAgreement,
  fetchAgreements,
} from '../../store/agreements/agreementsThunks';
import { showGlobalToast } from '../../store/global/globalSlice';
import { AgreementModel } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';

export const useAgreementsModals = () => {
  const currentVendor = useSelector(selectCurrentVendor);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const currentId = useSelector(
    (state: ApplicationState) => state.agreements.currentAgreementId
  );

  const setCurrentId = (id: AgreementModel['id']) =>
    dispatch(setCurrentAgreementId(id));

  const isDeletingAgreement = useSelector(
    (state: ApplicationState) => state.agreements.isDeletingAgreement
  );

  const dispatch = useDispatch();

  const handleDeleteClick = (agreementId: AgreementModel['id']) => {
    setCurrentId(agreementId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(
      deleteAgreement(currentId, () => {
        dispatch(fetchAgreements(currentVendor.id, false));
        setShowDeleteModal(false);
        dispatch(showGlobalToast('The agreement has been deleted.'));
      })
    );
  };

  return {
    handleConfirmDelete,
    handleDeleteClick,
    showDeleteModal,
    setShowDeleteModal,
    currentId,
    setCurrentId,
    isDeletingAgreement,
  };
};
