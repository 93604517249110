/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  RiskReportCreatePayloadRiskReport,
  RiskReportCreatePayloadRiskReportFromJSON,
  RiskReportCreatePayloadRiskReportFromJSONTyped,
  RiskReportCreatePayloadRiskReportToJSON,
} from './';

/**
 *
 * @export
 * @interface RiskReportCreatePayload
 */
export interface RiskReportCreatePayload {
  /**
   *
   * @type {RiskReportCreatePayloadRiskReport}
   * @memberof RiskReportCreatePayload
   */
  riskReport: RiskReportCreatePayloadRiskReport;
}

export function RiskReportCreatePayloadFromJSON(
  json: any
): RiskReportCreatePayload {
  return RiskReportCreatePayloadFromJSONTyped(json, false);
}

export function RiskReportCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskReportCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    riskReport: RiskReportCreatePayloadRiskReportFromJSON(json['risk_report']),
  };
}

export function RiskReportCreatePayloadToJSON(
  value?: RiskReportCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    risk_report: RiskReportCreatePayloadRiskReportToJSON(value.riskReport),
  };
}
