import React from 'react';
import { EditTestFormSection, isTestAutomatic } from '../EditTestForm';
import { useChildClass } from '../../../../../../../hooks/useChildClass';
import {
  patchAssertionDetails,
  postAssertionRun,
} from '../../../../../../../store/compliance/complianceThunks';
import { ToggleSwitch } from '../../../../../../../components/ToggleSwitch/ToggleSwitch';
import classNames from 'classnames';
import { AdoptechButton } from '../../../../../../../components/AdoptechButton/AdoptechButton';
import { faArrowRightFromLine } from '@fortawesome/pro-solid-svg-icons/faArrowRightFromLine';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons/faSyncAlt';
import { AssertionModelStatusEnum } from '../../../../../../../swagger';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import { useUpdateAssertionEverywhere } from '../EditTestFormLastResultsSection/EditTestFormLastResultsList/EditTestFormLastResultsList';

export const EditTestFormAutoSection: React.FC<EditTestFormSection> = props => {
  const baseCss = 'editTestForm';
  const childClass = useChildClass(baseCss);
  const isAuto = isTestAutomatic(props.formData);
  const dispatch = useAppDispatch();
  const updateAssertionEveryWhere = useUpdateAssertionEverywhere();
  const handleSwitchAutoRun = async () => {
    const autorun = !props.formData.autorun;
    const patchPayload = {
      autorun,
    };

    await dispatch(
      patchAssertionDetails({
        assertionId: props.formData.id,
        body: {
          assertion: patchPayload,
        },
      })
    );
    props.onChange({
      ...props.formData,
      ...{ autorun },
    });
  };
  if (!isAuto) return null;

  const isInProgress =
    props.formData.status === AssertionModelStatusEnum.InProgress;

  const handleRunClick = async () => {
    props.onChange({
      ...props.formData,
      ...{
        status: AssertionModelStatusEnum.InProgress,
      },
    });

    const newAssertion = await dispatch(
      postAssertionRun({ assertionId: props.formData.id })
    ).unwrap();

    const { status, lastRunAt, latestRunResults, overdue, nextRunAfter } =
      newAssertion;

    const assertionPayload = {
      status,
      lastRunAt,
      latestRunResults,
      overdue,
      nextRunAfter,
    };
    updateAssertionEveryWhere(assertionPayload, props);
  };
  return (
    <div className={childClass('fieldBlock')}>
      <div className={childClass('toggleBadgeRow')}>
        <div
          className={classNames(childClass('toggleBadge'), {
            red: !props.formData.autorun,
            green: props.formData.autorun,
          })}
        >
          <div className={childClass('toggleBadgeText')}>
            {`Automated testing: ${props.formData.autorun ? 'On' : 'Off'}`}
          </div>
          <ToggleSwitch
            handlerStyle="small"
            switchStyles={{
              width: 35,
              height: 18,
              offHandleColor: '#fff',
              onHandleColor: '#fff',
              onColor: '#6CAA45',
              offColor: '#EA5542',
            }}
            checked={props.formData.autorun}
            onChange={handleSwitchAutoRun}
          />
        </div>

        {isInProgress ? (
          <AdoptechButton disabled icon={faSyncAlt} size="middle2">
            Test in progress...
          </AdoptechButton>
        ) : (
          <AdoptechButton
            icon={faArrowRightFromLine}
            size="middle2"
            onClick={handleRunClick}
          >
            Run single test
          </AdoptechButton>
        )}
      </div>
      <div className={childClass('fieldColumn')}>
        <div className={childClass('fieldTitle')}>Frequency</div>
        <div className={childClass('fieldFrequency')}>Daily</div>
      </div>
    </div>
  );
};
