/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FrameworkModelStatsTasks
 */
export interface FrameworkModelStatsTasks {
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsTasks
   */
  count?: number;
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsTasks
   */
  progress?: number;
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsTasks
   */
  compliant?: number;
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsTasks
   */
  failed?: number;
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsTasks
   */
  pending?: number;
}

export function FrameworkModelStatsTasksFromJSON(
  json: any
): FrameworkModelStatsTasks {
  return FrameworkModelStatsTasksFromJSONTyped(json, false);
}

export function FrameworkModelStatsTasksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkModelStatsTasks {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    count: !exists(json, 'count') ? undefined : json['count'],
    progress: !exists(json, 'progress') ? undefined : json['progress'],
    compliant: !exists(json, 'compliant') ? undefined : json['compliant'],
    failed: !exists(json, 'failed') ? undefined : json['failed'],
    pending: !exists(json, 'pending') ? undefined : json['pending'],
  };
}

export function FrameworkModelStatsTasksToJSON(
  value?: FrameworkModelStatsTasks | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    count: value.count,
    progress: value.progress,
    compliant: value.compliant,
    failed: value.failed,
    pending: value.pending,
  };
}
