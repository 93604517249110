import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssessmentPublishedModal } from '../AssessmentPublishedModal/AssessmentPublishedModal';
import { CompletedAssessments } from '../CompletedAssessments/CompletedAssessments';
import '../AssessmentsSummary/AssessmentsSummary.scss';
import { ApplicationState } from '../../types/applicationState';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { PendingAssessments } from '../PendingAssessments/PendingAssessments';
import { saveSelectedAssessmentTab } from '../../store/assessments/assessmentsSlice';
import { AssessmentTabs } from '../../store/assessments/assessmentsState';
import AdoptechErrorBoundary from '../AdoptechErrorBoundary/AdoptechErrorBoundary';

export const AssessmentsIn: React.FC = () => {
  const dispatch = useDispatch();

  const isFetchingAssessments = useSelector(
    (state: ApplicationState) => state.assessments.isFetchingAssessments
  );

  useEffect(() => {
    dispatch(saveSelectedAssessmentTab(AssessmentTabs.Incoming));
  }, []);

  if (isFetchingAssessments) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <AdoptechErrorBoundary errorMessage="An unexpected error has stopped displaying incoming assessment pages. Please contact support!">
        <div className="assessmentsSummary">
          <PendingAssessments />
          <CompletedAssessments assessmentTab={AssessmentTabs.Incoming} />
        </div>

        <AssessmentPublishedModal />
      </AdoptechErrorBoundary>
    </>
  );
};
