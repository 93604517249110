import React, { useRef } from 'react';
import { PestelItemItemType, PestelItemModel } from '../../swagger';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechHS from '../AdoptechHS/AdoptechHS';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { useManagePestelItemsController } from './useManagePestelItemsController';
import './ManagePestelItemsDrawer.scss';
import { OverviewSection } from './OverviewSection';
import { capitalize } from '../../functions/capitalize';
import { AssessmentSection } from './AssessmentSection';

interface FooterProps {
  onClose: () => void;
  onSubmit: () => void;
  disabled: boolean;
  isSubmitting: boolean;
  isNew: boolean;
}

const Footer: React.FC<FooterProps> = ({
  onClose,
  disabled,
  onSubmit,
  isSubmitting,
  isNew,
}) => {
  return (
    <>
      <AdoptechButton onClick={onClose} variant={AdoptechButtonVariant.White}>
        Cancel
      </AdoptechButton>
      <AdoptechButton
        disabled={disabled}
        onClick={onSubmit}
        variant={AdoptechButtonVariant.Primary}
        busy={isSubmitting}
      >
        {isNew ? 'Add' : 'Confirm'}
      </AdoptechButton>
    </>
  );
};
export interface ManagePestelItemsDrawerProps {
  onClose: () => void;
}

export type PestelItemForm = Partial<PestelItemModel>;

export const ManagePestelItemsDrawer: React.FC<
  ManagePestelItemsDrawerProps
> = ({ onClose }) => {
  const drawerBackgroundRef = useRef<HTMLDivElement>();
  const {
    isTouched,
    onCloseAndCleanup,
    onSubmit,
    isSubmitting,
    submitButtonDisabled,
    isLoading,
    formData,
    sectionProps,
  } = useManagePestelItemsController({ onClose });
  const { itemType: type } = formData;
  const isNew = !formData.id;

  return (
    <AdoptechHS
      title={isNew ? `Add ${type}` : formData.description}
      show
      ref={drawerBackgroundRef}
      showConfirmationWarning={isTouched}
      onClose={onCloseAndCleanup}
      badges={type ? [{ title: capitalize(type) }] : []}
      footer={
        <Footer
          onClose={() => drawerBackgroundRef.current.click()}
          disabled={submitButtonDisabled}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          isNew={isNew}
        />
      }
      extraClass="adoptechHS--medium adoptechHS--no-padding"
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <OverviewSection {...sectionProps} />
          <AssessmentSection {...sectionProps} />
        </>
      )}
    </AdoptechHS>
  );
};
