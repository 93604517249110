import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideDeleteVendorDocumentModal,
  hideVendorDocumentDeletedToast,
} from '../../store/vendors/vendorsSlice';
import { deleteVendorDocument } from '../../store/vendors/vendorsThunks';
import { VendorDocument } from '../../swagger/models';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { MessageToast } from '../MessageToast/MessageToast';
import './DeleteVendorDocumentModal.scss';

export const DeleteVendorDocumentModal: React.FC = () => {
  const {
    isDeleteVendorDocumentModalShowing,
    isDeletingVendorDocument,
    isVendorDocumentDeletedToastShowing,
    vendorDocumentIdBeingDeleted,
    vendorDocuments,
  } = useSelector((state: ApplicationState) => state.vendors.documentState);

  let vendorDocument: VendorDocument;

  if (isDeleteVendorDocumentModalShowing) {
    vendorDocument = vendorDocuments.find(
      vd => vd.id === vendorDocumentIdBeingDeleted
    );
  }

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <MessageToast
        autohide
        delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
        onClose={() => dispatch(hideVendorDocumentDeletedToast())}
        show={isVendorDocumentDeletedToastShowing}
      >
        Your document has been successfully deleted.
      </MessageToast>
      <AdoptechModal
        onHide={() => dispatch(hideDeleteVendorDocumentModal())}
        show={isDeleteVendorDocumentModalShowing}
      >
        <Modal.Header>Delete document</Modal.Header>
        <Modal.Body className="deleteVendorDocumentModal--body">
          <div className="deleteVendorDocumentModal--prompt">
            Are you sure you wish to delete the following document?
          </div>
          <div className="deleteVendorDocumentModal--documentName">
            {vendorDocument && vendorDocument.name}
          </div>
          <div className="deleteVendorDocumentModal--documentDescription">
            {vendorDocument && vendorDocument.description}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton
            onClick={() => dispatch(hideDeleteVendorDocumentModal())}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            busy={isDeletingVendorDocument}
            onClick={() =>
              dispatch(deleteVendorDocument(vendorDocumentIdBeingDeleted))
            }
            variant={AdoptechButtonVariant.Primary}
          >
            DELETE
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
