/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorEventEventType,
  VendorEventEventTypeFromJSON,
  VendorEventEventTypeFromJSONTyped,
  VendorEventEventTypeToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorEventModel
 */
export interface VendorEventModel {
  /**
   *
   * @type {string}
   * @memberof VendorEventModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof VendorEventModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof VendorEventModel
   */
  description?: string | null;
  /**
   *
   * @type {VendorUser}
   * @memberof VendorEventModel
   */
  owner?: VendorUser;
  /**
   *
   * @type {VendorEventEventType}
   * @memberof VendorEventModel
   */
  eventType: VendorEventEventType;
  /**
   *
   * @type {string}
   * @memberof VendorEventModel
   */
  status?: VendorEventModelStatusEnum;
  /**
   *
   * @type {string}
   * @memberof VendorEventModel
   */
  date?: string;
  /**
   *
   * @type {boolean}
   * @memberof VendorEventModel
   */
  editable?: boolean;
  /**
   *
   * @type {string}
   * @memberof VendorEventModel
   */
  url?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorEventModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof VendorEventModel
   */
  updatedAt?: string;
}

export function VendorEventModelFromJSON(json: any): VendorEventModel {
  return VendorEventModelFromJSONTyped(json, false);
}

export function VendorEventModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorEventModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    eventType: VendorEventEventTypeFromJSON(json['event_type']),
    status: !exists(json, 'status') ? undefined : json['status'],
    date: !exists(json, 'date') ? undefined : json['date'],
    editable: !exists(json, 'editable') ? undefined : json['editable'],
    url: !exists(json, 'url') ? undefined : json['url'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function VendorEventModelToJSON(value?: VendorEventModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    owner: VendorUserToJSON(value.owner),
    event_type: VendorEventEventTypeToJSON(value.eventType),
    status: value.status,
    date: value.date,
    editable: value.editable,
    url: value.url,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum VendorEventModelStatusEnum {
  Pending = 'pending',
  Overdue = 'overdue',
}
