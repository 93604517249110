import { createSelector } from '@reduxjs/toolkit';
import { completedAssessmentSort } from '../functions/completedAssessmentSort';
import { getVendorAssessments } from '../functions/getVendorAssessments';
import { VendorUser } from '../swagger';
import { COMPLETED_TEXT } from '../types/constants';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';

export enum CompletedAssessmentType {
  Assessment,
  AssessmentResponse,
}

export interface CompletedAssessment {
  id: string;
  name: string;
  createdBy: string;
  owner?: VendorUser;
  dateCompleted: string;
  type: CompletedAssessmentType;
  path: string;
}

export const selectCompletedAssessments = createSelector(
  getVendorAssessments,
  selectSortSettings(Grid.CompletedAssessments),

  (vendorAssessments, sortSetting) => {
    const assessments: CompletedAssessment[] = vendorAssessments
      .filter(a => a.status === COMPLETED_TEXT)
      .map(a => {
        return {
          id: a.id,
          name: a.name,
          createdBy: a.vendorName,
          owner: a.owner,
          dateCompleted: a.updatedAt,
          type: CompletedAssessmentType.Assessment,
          path: a.relativePath,
        };
      });

    return assessments.sort((a, b) =>
      completedAssessmentSort(a, b, sortSetting.columnSort)
    );
  }
);
