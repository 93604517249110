import React from 'react';
import adoptechLogo from 'images/adoptech_logo.svg';
import './MainMenuLogo.scss';
import { useSelector } from 'react-redux';
import { selectVendorDetails } from '../../selectors/selectVendorDetails';

const MainMenuLogo: React.FC = () => {
  const currentVendor = useSelector(selectVendorDetails);
  const logo = currentVendor?.customLogoUrl;
  return (
    <div className="mainMenuLogo">
      <a href={process.env.REACT_APP_DEFAULT_ROUTE}>
        <img
          className="mainMenuLogo--image"
          alt={`${currentVendor?.name || 'Adoptech'} Main Menu Logo`}
          src={logo || adoptechLogo}
        />
      </a>
    </div>
  );
};

export default MainMenuLogo;
