/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BoardMember
 */
export interface BoardMember {
  /**
   *
   * @type {string}
   * @memberof BoardMember
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof BoardMember
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BoardMember
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof BoardMember
   */
  joinedOn?: string;
}

export function BoardMemberFromJSON(json: any): BoardMember {
  return BoardMemberFromJSONTyped(json, false);
}

export function BoardMemberFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BoardMember {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    title: !exists(json, 'title') ? undefined : json['title'],
    joinedOn: !exists(json, 'joined_on') ? undefined : json['joined_on'],
  };
}

export function BoardMemberToJSON(value?: BoardMember | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    title: value.title,
    joined_on: value.joinedOn,
  };
}
