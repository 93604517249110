import React, { useState } from 'react';
import { TrusthubDocumentModel } from '../../../swagger/trusthub';
import { AdoptechCheckbox } from '../../../components/AdoptechCheckbox/AdoptechCheckbox';
import {
  AdoptechTooltip,
  AdoptechTooltipVariant,
} from '../../../components/AdoptechTooltip/AdoptechTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlayCircle,
  faPencil,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import {
  EditSectionHandlerType,
  TrusthubFormDocument,
} from '../admin/useEditTrustHubSection';
import AdoptechOverflowLine from '../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { AdoptechTextArea } from '../../../components/AdoptechTextArea/AdoptechTextArea';

interface ControlRowProps {
  control: TrusthubDocumentModel;
  baseCss: string;

  // props for edit
  isEditableChecked?: boolean;
  formDocument?: TrusthubFormDocument;
  isDragging?: boolean;
  showTooltip: boolean;
  dropAllowed: boolean;
  dragAllowed: boolean;
  editSectionHandler: EditSectionHandlerType;
}

export const ControlRow: React.FC<ControlRowProps> = ({
  control,
  baseCss,
  isEditableChecked, // used for edit checked
  isDragging, // used for edit
  showTooltip,
  dragAllowed,
  dropAllowed,
  editSectionHandler,
}) => {
  const [editDescriptionMode, setEditDescriptionMode] = useState(false);

  const editDescriptionAllowed = dragAllowed;
  const {
    handleDeleteControl,
    handleCheckboxChange,
    handleAddControlsToRow,
    updateDocumentField,
    formDocuments,
  } = editSectionHandler;

  const formDocument = (formDocuments || []).find(
    formDoc => formDoc.documentId === control?.documentId
  );
  const [formDescription, setFormDescription] = useState(
    formDocument?.description
  );
  // drop needed for right panel only
  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: 'TrusthubControls',
      drop: (controlIds: string[]) => {
        const rowTargetRowOrder = control.rowOrder;
        const categoryId = control.trusthubCategoryId;
        handleAddControlsToRow({
          categoryId,
          controlIds,
          rowTargetRowOrder,
        });
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
      canDrop: () => {
        return dropAllowed;
      },
    }),
    [control?.rowOrder, control?.trusthubCategoryId]
  );

  // for dragging between categories / inside category
  const [{ isDragging: isDraggingCurrent }, dragRef, _dragPreview] = useDrag(
    () => ({
      type: 'TrusthubControls',
      item: () => {
        return [control.documentId];
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => {
        return dragAllowed && !editDescriptionMode;
      },
    }),
    [control?.documentId, dragAllowed, editDescriptionMode]
  );

  const handleEdit = () => {
    setEditDescriptionMode(true);
  };

  if (!control) return null;

  const handleRemove = () => {
    handleDeleteControl(control.documentId);
  };

  const isDraggable = isDragging && formDocument.checked;

  const descriptionIsEmpty = (formDescription || '').length === 0;
  const handleFormDescriptionSave = () => {
    updateDocumentField(control.documentId, 'description', formDescription);
    setEditDescriptionMode(false);
  };
  const handleCancel = () => {
    setFormDescription(formDocument?.description);
    setEditDescriptionMode(false);
  };
  return (
    <div ref={dragRef}>
      <div ref={dropRef}>
        <div
          className={classNames([`${baseCss}--control`], {
            hoverable: isEditableChecked,
            draggable: isDraggable || isDraggingCurrent,
            droppable: isOver && dropAllowed,
          })}
          onClick={e => {
            e.preventDefault();
            isEditableChecked &&
              handleCheckboxChange(
                !formDocument.checked,
                control as TrusthubDocumentModel
              );
          }}
        >
          <div className={baseCss + '--controlInfoRow'}>
            {isEditableChecked && (
              <AdoptechCheckbox
                id={control.documentId}
                checked={formDocument?.checked}
                onChange={e => {
                  e.stopPropagation();
                  handleCheckboxChange(
                    e.currentTarget.checked,
                    control as TrusthubDocumentModel
                  );
                }}
                label={<div className="emptyLabel">-</div>}
              />
            )}

            <AdoptechTooltip
              showTooltip={!!control.description && showTooltip}
              text={control.description}
              identifier={baseCss + control.documentId}
              variant={AdoptechTooltipVariant.Large}
              placement="top-start"
            >
              <div
                className={baseCss + '--controlNameBlock'}
                style={showTooltip ? {} : { flexGrow: 1 }}
              >
                <FontAwesomeIcon icon={faPlayCircle} />

                <AdoptechOverflowLine className={baseCss + '--controlName'}>
                  {control.name}
                </AdoptechOverflowLine>
              </div>
            </AdoptechTooltip>
            {editDescriptionMode ? (
              <div className={baseCss + '--controlDescriptionActions'}>
                <AdoptechButton
                  disabled={false}
                  onClick={handleCancel}
                  variant={AdoptechButtonVariant.White}
                >
                  Cancel
                </AdoptechButton>
                <AdoptechButton
                  busy={false}
                  disabled={descriptionIsEmpty}
                  onClick={handleFormDescriptionSave}
                  variant={AdoptechButtonVariant.White}
                >
                  Save
                </AdoptechButton>
              </div>
            ) : (
              editDescriptionAllowed && (
                <>
                  <FontAwesomeIcon icon={faPencil} onClick={handleEdit} />
                  <FontAwesomeIcon icon={faTrash} onClick={handleRemove} />
                </>
              )
            )}
          </div>
          {editDescriptionMode && (
            <div className={baseCss + '--controlFormRow'}>
              <div className={baseCss + '--controlDescription'}>
                This text appears when viewers hover over the Control, by
                default this is the original Control description text.{' '}
                <b>Please note</b> changing this text does affect the original
                Control description.
              </div>

              <AdoptechTextArea
                id={control.documentId}
                value={formDescription || ''}
                onChange={e => {
                  setFormDescription(e.currentTarget.value);
                }}
                hasError={descriptionIsEmpty}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
