import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './ManageBillingModal.scss';
import { hideManageBillingModal } from '../../store/vendors/vendorsSlice';
import { ManageInvoices } from '../ManageInvoices/ManageInvoices';
import { ManagePaymentMethods } from '../ManagePaymentMethods/ManagePaymentMethods';

export const ManageBillingModal: React.FC = () => {
  const { isManageBillingModalShowing } = useSelector(
    (state: ApplicationState) => state.vendors
  );

  const dispatch = useDispatch();

  return (
    <AdoptechModal
      className="manageBillingModal"
      onHide={() => dispatch(hideManageBillingModal())}
      show={isManageBillingModalShowing}
    >
      <Modal.Header>Manage billing information</Modal.Header>
      <Modal.Body>
        <ManagePaymentMethods />
        <ManageInvoices />
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={() => dispatch(hideManageBillingModal())}
          variant={AdoptechButtonVariant.Primary}
        >
          Done
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
