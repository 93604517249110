/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VendorEventEventType {
  AgreementReview = 'agreement_review',
  ControlCheckReview = 'control_check_review',
  ControlReview = 'control_review',
  DocumentReview = 'document_review',
  ExternalAudit = 'external_audit',
  IncidentBcpDrSimulation = 'incident_bcp_dr_simulation',
  InternalAudit = 'internal_audit',
  LegalRegisterReview = 'legal_register_review',
  Other = 'other',
  PestelReview = 'pestel_review',
  PolicyReview = 'policy_review',
  RiskRegisterReview = 'risk_register_review',
  SecurityMeeting = 'security_meeting',
  SecurityTraining = 'security_training',
  VendorRegisterReview = 'vendor_register_review',
  AssertionDueDate = 'assertion_due_date',
  NonConformance = 'non_conformance',
  OpportunityForImprovement = 'opportunity_for_improvement',
  IncidentEvent = 'incident_event',
}

export function VendorEventEventTypeFromJSON(json: any): VendorEventEventType {
  return VendorEventEventTypeFromJSONTyped(json, false);
}

export function VendorEventEventTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorEventEventType {
  return json as VendorEventEventType;
}

export function VendorEventEventTypeToJSON(
  value?: VendorEventEventType | null
): any {
  return value as any;
}
