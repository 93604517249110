import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';

import React from 'react';
import classNames from 'classnames';
import {
  AgreementSignatoryModel,
  AgreementSignatoryModelStatusEnum,
} from '../../../../../swagger/models/AgreementSignatoryModel';
import { SignatoriesMeatballMenu } from './SignatoriesMeatballMenu';
import { UserAvatar } from '../../../../UserAvatar/UserAvatar';
import { formatShortDate } from '../../../../../functions/formatShortDate';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../AdoptechButton/AdoptechButton';
import '../../EditAgreementForm.scss';

interface InternalSignatoriesProps {
  internalSignatories: AgreementSignatoryModel[];
  onSendReminderClick(model: AgreementSignatoryModel): void;
  isDraft: boolean;
}

export const InternalSignatories: React.FC<InternalSignatoriesProps> = ({
  internalSignatories,
  onSendReminderClick,
  isDraft,
}) => {
  const baseCss = 'editAgreementForm';

  if (!internalSignatories) {
    return null;
  }

  const atLeastOneSignatorySigned = internalSignatories.some(
    item => item.status === AgreementSignatoryModelStatusEnum.Signed
  );

  return (
    <>
      {internalSignatories.map(signatory => {
        return (
          <div key={signatory.id}>
            <div
              className={classNames(
                `${baseCss}--table-row`,
                `${baseCss}--4-columns`,
                'mt-1',
                'pb-2'
              )}
            >
              <div>
                <UserAvatar user={signatory.user} size="small" />
              </div>
              <div className={baseCss + '--table-column'}>
                {!isDraft &&
                signatory.status ===
                  AgreementSignatoryModelStatusEnum.Signed ? (
                  <>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={classNames(`${baseCss}--greenTick`, 'mr-2')}
                    />
                    {formatShortDate(signatory.updatedAt)}
                  </>
                ) : (
                  !atLeastOneSignatorySigned &&
                  !isDraft && (
                    <AdoptechButton
                      icon={faEnvelope}
                      onClick={() => onSendReminderClick(signatory)}
                      variant={AdoptechButtonVariant.Default}
                    >
                      Send reminder
                    </AdoptechButton>
                  )
                )}
              </div>
              <div>
                <SignatoriesMeatballMenu
                  signatory={signatory}
                  onSendReminderClick={(model: AgreementSignatoryModel) =>
                    onSendReminderClick(model)
                  }
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
