/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssessmentSection,
  AssessmentSectionFromJSON,
  AssessmentSectionFromJSONTyped,
  AssessmentSectionToJSON,
  AssessmentTemplateExtendedAllOf,
  AssessmentTemplateExtendedAllOfFromJSON,
  AssessmentTemplateExtendedAllOfFromJSONTyped,
  AssessmentTemplateExtendedAllOfToJSON,
  AssessmentTemplateModel,
  AssessmentTemplateModelFromJSON,
  AssessmentTemplateModelFromJSONTyped,
  AssessmentTemplateModelToJSON,
} from './';

/**
 *
 * @export
 * @interface AssessmentTemplateExtended
 */
export interface AssessmentTemplateExtended extends AssessmentTemplateModel {
  /**
   *
   * @type {Array<AssessmentSection>}
   * @memberof AssessmentTemplateExtended
   */
  assessmentTemplateSections?: Array<AssessmentSection>;
}

export function AssessmentTemplateExtendedFromJSON(
  json: any
): AssessmentTemplateExtended {
  return AssessmentTemplateExtendedFromJSONTyped(json, false);
}

export function AssessmentTemplateExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssessmentTemplateExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...AssessmentTemplateModelFromJSONTyped(json, ignoreDiscriminator),
    assessmentTemplateSections: !exists(json, 'assessment_template_sections')
      ? undefined
      : (json['assessment_template_sections'] as Array<any>).map(
          AssessmentSectionFromJSON
        ),
  };
}

export function AssessmentTemplateExtendedToJSON(
  value?: AssessmentTemplateExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...AssessmentTemplateModelToJSON(value),
    assessment_template_sections:
      value.assessmentTemplateSections === undefined
        ? undefined
        : (value.assessmentTemplateSections as Array<any>).map(
            AssessmentSectionToJSON
          ),
  };
}
