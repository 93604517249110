import { Question, Answer } from '../swagger';

const completeRow = (question: Question, row: Answer[]): Answer[] => {
  const questions = question.table.questions;
  if (questions.length == row.length) return row;

  return questions.map(({ propertyName, id }) => {
    const existingAnswer = row.find(a => a.propertyName == propertyName);
    if (existingAnswer) return existingAnswer;

    const prototype = row[0]; // to share fields common for the row
    const { index, userName } = prototype;

    return {
      questionId: id,
      index,
      userName,
      propertyName,
    } as Answer;
  });
};

export const normalizeTableAnswers = (question: Question): Answer[] => {
  const answers = question?.table?.answers?.flat();
  if (!answers) return [];

  const sortedIndexes = [...new Set(answers.map(answer => answer.index))];

  const rows = sortedIndexes.map(index =>
    completeRow(
      question,
      answers.filter(answer => answer.index === index)
    )
  );

  return rows.flat();
};
