/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorUserUpdatePayloadVendorUser,
  VendorUserUpdatePayloadVendorUserFromJSON,
  VendorUserUpdatePayloadVendorUserFromJSONTyped,
  VendorUserUpdatePayloadVendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorUserUpdatePayload
 */
export interface VendorUserUpdatePayload {
  /**
   *
   * @type {VendorUserUpdatePayloadVendorUser}
   * @memberof VendorUserUpdatePayload
   */
  vendorUser: VendorUserUpdatePayloadVendorUser;
}

export function VendorUserUpdatePayloadFromJSON(
  json: any
): VendorUserUpdatePayload {
  return VendorUserUpdatePayloadFromJSONTyped(json, false);
}

export function VendorUserUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorUserUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vendorUser: VendorUserUpdatePayloadVendorUserFromJSON(json['vendor_user']),
  };
}

export function VendorUserUpdatePayloadToJSON(
  value?: VendorUserUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vendor_user: VendorUserUpdatePayloadVendorUserToJSON(value.vendorUser),
  };
}
