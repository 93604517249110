import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import React from 'react';
import Accordion from 'react-bootstrap/esm/Accordion';
import { useSelector } from 'react-redux';
import { PestelItemArea } from '../../swagger/models/PestelItemArea';
import { PestelItemLocationType } from '../../swagger/models/PestelItemLocationType';
import { PestelItemModel } from '../../swagger/models/PestelItemModel';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { AdoptechReactSelect2 } from '../AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechTextArea2 } from '../AdoptechTextArea2/AdoptechTextArea2';
import { PestelItemForm } from './ManagePestelItemsDrawer';

type ValueOf<T> = T[keyof T];
export interface SectionProps {
  formData: PestelItemForm;
  updateField: (
    field: keyof PestelItemModel,
    value: ValueOf<PestelItemModel>
  ) => void;
  isTouched: boolean;
}

const descriptionValidation = (
  formData: PestelItemForm,
  pestelItems: PestelItemModel[]
): string[] => {
  const errors = [];
  const isEmpty = (formData.description || '').trim().length <= 0;
  const hasDuplicate = pestelItems.some(
    item => item.id !== formData.id && item.description === formData.description
  );
  if (isEmpty) errors.push('Description must be present');
  if (hasDuplicate) errors.push('Description must be unique');

  return errors.length > 0 ? errors : null;
};

export const validation = (
  formData: PestelItemForm,
  pestelItems: PestelItemModel[]
): Record<string, string[]> => ({
  description: descriptionValidation(formData, pestelItems),
  area: formData.area ? null : ['Area must be set'],
  locationType: formData.locationType ? null : ['Location type must be set'],
});

export const selectOptions = (
  enumObject: Record<SelectionOption['label'], SelectionOption['value']>
) => {
  return Object.entries(enumObject).map(entry => {
    return { label: entry[0], value: entry[1] };
  });
};

export const OverviewSection: React.FC<SectionProps> = ({
  formData,
  updateField,
  isTouched,
}) => {
  const baseCss = 'managePestelItemsDrawer';
  const pestelItems = useSelector(
    (state: ApplicationState) => state.pestel.pestelItems
  );
  const fieldErrors = (field: string) =>
    validation(formData, pestelItems)[field];
  const hasError = (field: string) => !!fieldErrors(field);

  const areaOptions = selectOptions(PestelItemArea);
  const locationTypeOptions = selectOptions(PestelItemLocationType);
  const errorExceptPresent = (error: string) => !error.includes('present');
  return (
    <div className={baseCss}>
      <Accordion defaultActiveKey="0">
        <AdoptechAccordionCard
          title="Overview"
          index="0"
          headerClass="noBorderBottom positionInitial"
          iconSize="small"
          noMargin
        >
          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--field'}>
              <AdoptechTextArea2
                hasError={hasError('description')}
                errorText={
                  hasError('description') &&
                  fieldErrors('description').some(errorExceptPresent)
                    ? fieldErrors('description').find(errorExceptPresent)
                    : null
                }
                label="Description"
                id={`${baseCss}description`}
                value={formData.description}
                onChange={e =>
                  updateField('description', e.currentTarget.value)
                }
                icon={faPen}
              />
            </div>
          </div>
          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                label="Area"
                id={`${baseCss}area`}
                options={areaOptions}
                hasError={hasError('area')}
                onChange={e => updateField('area', e.value)}
                value={areaOptions.find(
                  option => option.value == formData.area
                )}
                placeholder="Select area"
              />
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                label="Internal or external"
                id={`${baseCss}locationType`}
                options={locationTypeOptions}
                hasError={hasError('locationType')}
                onChange={e => updateField('locationType', e.value)}
                value={locationTypeOptions.find(
                  option => option.value == formData?.locationType
                )}
                placeholder="Please select"
              />
            </div>
          </div>
        </AdoptechAccordionCard>
      </Accordion>
    </div>
  );
};
