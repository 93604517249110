/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AgreementTemplateModel
 */
export interface AgreementTemplateModel {
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateModel
   */
  agreementType?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateModel
   */
  agreementTemplateCategoryId: string;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateModel
   */
  summary: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateModel
   */
  summaryAsHtml: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateModel
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateModel
   */
  descriptionAsHtml: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateModel
   */
  version: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AgreementTemplateModel
   */
  available?: boolean;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateModel
   */
  updatedAt: string;
}

export function AgreementTemplateModelFromJSON(
  json: any
): AgreementTemplateModel {
  return AgreementTemplateModelFromJSONTyped(json, false);
}

export function AgreementTemplateModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementTemplateModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    agreementType: !exists(json, 'agreement_type')
      ? undefined
      : json['agreement_type'],
    name: json['name'],
    agreementTemplateCategoryId: json['agreement_template_category_id'],
    summary: json['summary'],
    summaryAsHtml: json['summary_as_html'],
    description: json['description'],
    descriptionAsHtml: json['description_as_html'],
    version: json['version'],
    available: !exists(json, 'available') ? undefined : json['available'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function AgreementTemplateModelToJSON(
  value?: AgreementTemplateModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    agreement_type: value.agreementType,
    name: value.name,
    agreement_template_category_id: value.agreementTemplateCategoryId,
    summary: value.summary,
    summary_as_html: value.summaryAsHtml,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
    version: value.version,
    available: value.available,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
