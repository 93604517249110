/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ControlCategoryExtended,
  ControlCategoryExtendedFromJSON,
  ControlCategoryExtendedFromJSONTyped,
  ControlCategoryExtendedToJSON,
  FrameworkExtendedAllOf,
  FrameworkExtendedAllOfFromJSON,
  FrameworkExtendedAllOfFromJSONTyped,
  FrameworkExtendedAllOfToJSON,
  FrameworkModel,
  FrameworkModelFromJSON,
  FrameworkModelFromJSONTyped,
  FrameworkModelToJSON,
  FrameworkModelStats,
  FrameworkModelStatsFromJSON,
  FrameworkModelStatsFromJSONTyped,
  FrameworkModelStatsToJSON,
} from './';

/**
 *
 * @export
 * @interface FrameworkExtended
 */
export interface FrameworkExtended extends FrameworkModel {
  /**
   *
   * @type {Array<ControlCategoryExtended>}
   * @memberof FrameworkExtended
   */
  controlCategories: Array<ControlCategoryExtended>;
}

export function FrameworkExtendedFromJSON(json: any): FrameworkExtended {
  return FrameworkExtendedFromJSONTyped(json, false);
}

export function FrameworkExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...FrameworkModelFromJSONTyped(json, ignoreDiscriminator),
    controlCategories: (json['control_categories'] as Array<any>).map(
      ControlCategoryExtendedFromJSON
    ),
  };
}

export function FrameworkExtendedToJSON(value?: FrameworkExtended | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...FrameworkModelToJSON(value),
    control_categories: (value.controlCategories as Array<any>).map(
      ControlCategoryExtendedToJSON
    ),
  };
}
