/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChecklistExtendedAllOfChecklistVendorUsers
 */
export interface ChecklistExtendedAllOfChecklistVendorUsers {
  /**
   *
   * @type {string}
   * @memberof ChecklistExtendedAllOfChecklistVendorUsers
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistExtendedAllOfChecklistVendorUsers
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistExtendedAllOfChecklistVendorUsers
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistExtendedAllOfChecklistVendorUsers
   */
  vendorUserId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistExtendedAllOfChecklistVendorUsers
   */
  responsible?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChecklistExtendedAllOfChecklistVendorUsers
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistExtendedAllOfChecklistVendorUsers
   */
  updatedAt?: string;
}

export function ChecklistExtendedAllOfChecklistVendorUsersFromJSON(
  json: any
): ChecklistExtendedAllOfChecklistVendorUsers {
  return ChecklistExtendedAllOfChecklistVendorUsersFromJSONTyped(json, false);
}

export function ChecklistExtendedAllOfChecklistVendorUsersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistExtendedAllOfChecklistVendorUsers {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    email: !exists(json, 'email') ? undefined : json['email'],
    fullName: !exists(json, 'full_name') ? undefined : json['full_name'],
    vendorUserId: !exists(json, 'vendor_user_id')
      ? undefined
      : json['vendor_user_id'],
    responsible: !exists(json, 'responsible') ? undefined : json['responsible'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function ChecklistExtendedAllOfChecklistVendorUsersToJSON(
  value?: ChecklistExtendedAllOfChecklistVendorUsers | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    email: value.email,
    full_name: value.fullName,
    vendor_user_id: value.vendorUserId,
    responsible: value.responsible,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
