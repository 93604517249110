/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Question,
  QuestionFromJSON,
  QuestionFromJSONTyped,
  QuestionToJSON,
  VendorPolicyDetails,
  VendorPolicyDetailsFromJSON,
  VendorPolicyDetailsFromJSONTyped,
  VendorPolicyDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorPolicyClause
 */
export interface VendorPolicyClause {
  /**
   *
   * @type {string}
   * @memberof VendorPolicyClause
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyClause
   */
  vendorPolicyId?: string;
  /**
   *
   * @type {VendorPolicyDetails}
   * @memberof VendorPolicyClause
   */
  vendorPolicy?: VendorPolicyDetails;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyClause
   */
  policyClauseId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyClause
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyClause
   */
  clauseText?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof VendorPolicyClause
   */
  clauseTextIsCustom?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorPolicyClause
   */
  leaveOut?: boolean;
  /**
   *
   * @type {Array<Question>}
   * @memberof VendorPolicyClause
   */
  questions?: Array<Question>;
  /**
   *
   * @type {boolean}
   * @memberof VendorPolicyClause
   */
  allowCustomText?: boolean;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyClause
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyClause
   */
  updatedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyClause
   */
  updatedBy?: string | null;
}

export function VendorPolicyClauseFromJSON(json: any): VendorPolicyClause {
  return VendorPolicyClauseFromJSONTyped(json, false);
}

export function VendorPolicyClauseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorPolicyClause {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    vendorPolicyId: !exists(json, 'vendor_policy_id')
      ? undefined
      : json['vendor_policy_id'],
    vendorPolicy: !exists(json, 'vendor_policy')
      ? undefined
      : VendorPolicyDetailsFromJSON(json['vendor_policy']),
    policyClauseId: !exists(json, 'policy_clause_id')
      ? undefined
      : json['policy_clause_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    clauseText: !exists(json, 'clause_text') ? undefined : json['clause_text'],
    clauseTextIsCustom: !exists(json, 'clause_text_is_custom')
      ? undefined
      : json['clause_text_is_custom'],
    leaveOut: !exists(json, 'leave_out') ? undefined : json['leave_out'],
    questions: !exists(json, 'questions')
      ? undefined
      : (json['questions'] as Array<any>).map(QuestionFromJSON),
    allowCustomText: !exists(json, 'allow_custom_text')
      ? undefined
      : json['allow_custom_text'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    updatedBy: !exists(json, 'updated_by') ? undefined : json['updated_by'],
  };
}

export function VendorPolicyClauseToJSON(
  value?: VendorPolicyClause | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    vendor_policy_id: value.vendorPolicyId,
    vendor_policy: VendorPolicyDetailsToJSON(value.vendorPolicy),
    policy_clause_id: value.policyClauseId,
    name: value.name,
    clause_text: value.clauseText,
    clause_text_is_custom: value.clauseTextIsCustom,
    leave_out: value.leaveOut,
    questions:
      value.questions === undefined
        ? undefined
        : (value.questions as Array<any>).map(QuestionToJSON),
    allow_custom_text: value.allowCustomText,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    updated_by: value.updatedBy,
  };
}
