import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { useAddLegislationFormHandler } from './useAddLegislationFormHandler';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { AdoptechCheckbox } from '../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { JurisdictionModel, LegislationTemplateModel } from '../../../swagger';
import { AdoptechTooltip } from '../../../components/AdoptechTooltip/AdoptechTooltip';
import classNames from 'classnames';
import Fuse from 'fuse.js';

interface AddNewLegislationProps {
  addHandler: ReturnType<typeof useAddLegislationFormHandler>;
}

export const AddNewLegislation: React.FC<AddNewLegislationProps> = props => {
  const baseCss = 'addNewLegislation';

  const { isLoading, notEmptyJurisdictions: jurisdictions } = props.addHandler;

  if (isLoading) return <LoadingSpinner />;

  const hasJurisdictions = jurisdictions.length > 0;
  if (!hasJurisdictions)
    return (
      <div className="p-3">
        All jurisdictions are already used. Please remove legislation in the
        table or contact to Administrator
      </div>
    );

  return (
    <div className={baseCss}>
      <AddNewLegislationHeader
        search={props.addHandler.search}
        setSearch={props.addHandler.setSearch}
      />
      <div className={baseCss + '--mainColumns'}>
        <SelectJurisdictions addHandler={props.addHandler} />
        <SelectLegislations addHandler={props.addHandler} />
      </div>
    </div>
  );
};

interface AddNewLegislationHeaderProps {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}
export const AddNewLegislationHeader: React.FC<
  AddNewLegislationHeaderProps
> = props => {
  const baseCss = 'documentLibraryHeader';
  const searchPlaceholder = 'Search legislation library';
  return (
    <div className={baseCss + '--titleRow'}>
      <div className={baseCss + '--title'}>Add legislation</div>

      <div className={baseCss + '--search'}>
        <AdoptechTextInput
          id="search"
          value={props.search || ''}
          onChange={event => props.setSearch(event.currentTarget.value)}
          type="text"
          placeholder={searchPlaceholder}
          icon={faSearch}
          additionalClass="adoptechTextInput-search"
        />
      </div>
    </div>
  );
};

interface SelectJurisdictionsProps {
  addHandler: AddNewLegislationProps['addHandler'];
}

const SelectJurisdictions: React.FC<SelectJurisdictionsProps> = props => {
  const {
    form,
    notEmptyJurisdictionOptions: jurisdictionOptions,
    onJurisdictionChange,
  } = props.addHandler;

  const baseCss = 'addNewLegislation';
  const elementCss = 'selectJurisdictionsSection';
  return (
    <div className={`${baseCss}--section ${elementCss}--section`}>
      <div className={`${baseCss}--sectionTitle ${elementCss}--sectionTitle`}>
        Select jurisdiction
      </div>
      <div
        className={`${baseCss}--sectionContent ${elementCss}--sectionContent`}
      >
        {jurisdictionOptions.map(option => {
          return (
            <AdoptechCheckbox
              key={option.value}
              color="white-black"
              checked={(form.selectedJurisdictionsIds || []).includes(
                option.value
              )}
              id={`checkbox-${option.value}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const ids = form.selectedJurisdictionsIds || [];
                const newIds = e.target.checked
                  ? [...ids, option.value]
                  : ids.filter(id => id !== option.value);
                onJurisdictionChange(newIds);
              }}
              label={option.label}
              className="add-jurisdictions-checkbox"
            />
          );
        })}
      </div>
    </div>
  );
};

interface SelectLegislationsProps {
  addHandler: AddNewLegislationProps['addHandler'];
}

export const SelectLegislations: React.FC<SelectLegislationsProps> = props => {
  const baseCss = 'addNewLegislation';
  const { form } = props.addHandler;
  const selectedJurisdictionsIds = form.selectedJurisdictionsIds || [];

  if (selectedJurisdictionsIds.length === 0) {
    return null;
  }
  return (
    <div className={baseCss + '--section ' + 'selectLegislationsSection'}>
      <div className={baseCss + '--sectionTitle'}>Select legislation</div>
      <div className={baseCss + '--sectionContent'}>
        {selectedJurisdictionsIds.map(jurisdictionId => {
          return (
            <JurisdictionTemplates
              id={jurisdictionId}
              key={jurisdictionId}
              addHandler={props.addHandler}
            />
          );
        })}
      </div>
    </div>
  );
};

interface JurisdictionTemplatesProps {
  id: JurisdictionModel['id'];
  addHandler: AddNewLegislationProps['addHandler'];
}
export const JurisdictionTemplates: React.FC<
  JurisdictionTemplatesProps
> = props => {
  const {
    form,
    notEmptyJurisdictions: jurisdictions,
    onSelectAllTemplatesChange,
    search,
  } = props.addHandler;
  const jurisdiction = jurisdictions.find(entity => entity.id === props.id);

  const options = {
    ignoreLocation: true,
    includeScore: true,
    keys: ['name'],
    threshold: 0,
  };

  const filteredJurisdictionTemplates =
    form.legislationTemplates?.filter(
      template => template.jurisdiction?.id === jurisdiction.id
    ) || [];

  const fuseApp = new Fuse(filteredJurisdictionTemplates, options);
  const jurisdictionTemplates = search
    ? fuseApp.search(search).map(x => x.item)
    : filteredJurisdictionTemplates;

  const allSelected = jurisdictionTemplates.every(template =>
    (form.selectedLegislationTemplatesIds || []).includes(template.id)
  );

  const baseCss = 'addNewLegislation';
  const elementCss = 'jurisdictionTemplatesSection';

  if (jurisdictionTemplates.length === 0) return null;
  return (
    <div className={baseCss + ' ' + elementCss}>
      <div className={elementCss + '--header'}>
        <div>{jurisdiction?.name}</div>
        {(form.legislationTemplates || []).length >= 0 && (
          <AdoptechCheckbox
            key={jurisdiction.id + 'select-all'}
            color="white-black"
            checked={allSelected}
            id={jurisdiction.id + 'select-all'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onSelectAllTemplatesChange(e, jurisdictionTemplates)
            }
            disabled={false}
            label="Select All"
            className="select-all-jurisdictions"
          />
        )}
      </div>
      <div className={elementCss + '--jurisdictions'}>
        {jurisdictionTemplates.map(template => {
          return (
            <LegislationTemplate
              template={template}
              key={template.id}
              addHandler={props.addHandler}
            />
          );
        })}
      </div>
    </div>
  );
};

const LegislationTemplate: React.FC<{
  template: LegislationTemplateModel;
  addHandler: AddNewLegislationProps['addHandler'];
}> = props => {
  const baseCss = 'addNewLegislation';
  const elementCss = 'jurisdictionTemplate';
  const { form } = props.addHandler;
  const { template } = props;
  const isChecked = (form.selectedLegislationTemplatesIds || []).includes(
    props.template.id
  );

  const { onSelectTemplateChange } = props.addHandler;

  const templateChecked = (form.selectedLegislationTemplatesIds || []).includes(
    template.id
  );
  return (
    <div className={baseCss + ' ' + elementCss}>
      <AdoptechTooltip
        placement="top"
        text={template.name}
        identifier={template.id}
        showTooltip
      >
        <div className={elementCss + '--name'}>{template.name}</div>
      </AdoptechTooltip>
      <div>
        <AdoptechCheckbox
          key={template.id}
          color="white-black"
          checked={templateChecked}
          id={`checkbox-${template.id}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onSelectTemplateChange(e, template)
          }
          label="Select"
          className="select-jurisdictions"
        />
      </div>
    </div>
  );
};
