/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorTreeNodeModel
 */
export interface VendorTreeNodeModel {
  /**
   *
   * @type {string}
   * @memberof VendorTreeNodeModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof VendorTreeNodeModel
   */
  name: string;
  /**
   *
   * @type {Array<VendorTreeNodeModel>}
   * @memberof VendorTreeNodeModel
   */
  children: Array<VendorTreeNodeModel>;
}

export function VendorTreeNodeModelFromJSON(json: any): VendorTreeNodeModel {
  return VendorTreeNodeModelFromJSONTyped(json, false);
}

export function VendorTreeNodeModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorTreeNodeModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    children: (json['children'] as Array<any>).map(VendorTreeNodeModelFromJSON),
  };
}

export function VendorTreeNodeModelToJSON(
  value?: VendorTreeNodeModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    children: (value.children as Array<any>).map(VendorTreeNodeModelToJSON),
  };
}
