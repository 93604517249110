import { push } from 'connected-react-router';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { selectAllAvailableCategories } from '../../../selectors/selectAllAvailableCategories';
import { selectAvailablePolicies } from '../../../selectors/selectAvailablePolicies';
import {
  hideDocumentLibraryModal,
  showExistingPolicyWarningModal,
} from '../../../store/policies/policiesSlice';
import {
  createPolicy,
  fetchPoliciesForVendor,
  fetchVendorPolicies,
  fetchVendorPoliciesDetails,
} from '../../../store/policies/policiesThunks';
import { Policy, VendorPolicy } from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { AdoptechButton } from '../../../components/AdoptechButton/AdoptechButton';
import { ExistingPolicyWarningModalMode } from '../../../components/ExistingPolicyWarningModal/ExistingPolicyWarningModal';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import PartOfMyPlanBanner from '../../../components/PartOfMyPlanBanner/PartOfMyPlanBanner';
import './AddNewPolicy.scss';
import { canIndex } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { DocumentLibraryHeader } from '../../../components/DocumentLibraryHeader/DocumentLibraryHeader';
import DocumentLibraryDescriptionModal from '../../../components/DocumentLibraryDescriptionModal/DocumentLibraryDescriptionModal';
import PolicyItem from '../PolicyTiles/PolicyItem';
import { selectActiveSubscription } from '../../../selectors/selectActiveSubscription';
import { getVendorPolicies } from '../../../functions/getVendorPolicies';
import { policyPageItemRouteURL } from '../../../components/Routes/Routes';

const options = {
  ignoreLocation: true,
  includeScore: true,
  keys: ['description', 'name', 'summary'],
  threshold: 0,
};

const AddNewPolicy = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [selectedPolicy, setSelectedPolicy] = useState<Policy>();
  const [categoryFilter, setCategoryFilter] = useState<string>();
  const canIndexVendorPolicies = canIndex(AccessObject.vendor_policies);
  const availablePolicies = useSelector(selectAvailablePolicies);
  const allCategories = useSelector(selectAllAvailableCategories);
  const isCreatingPolicy = useSelector(
    (state: ApplicationState) => state.policies.isCreatingPolicy
  );
  const vendorProducts = useSelector(
    (state: ApplicationState) => state.vendors.vendorProducts
  );

  const isFetchingVendorPolicies = useSelector(
    (state: ApplicationState) => state.policies.isFetchingVendorPolicies
  );
  const queryParams = useQueryParams();
  const policyCode = queryParams.get('policyCode');
  const policyCategory = queryParams.get('policyTemplateCategoryName');

  const location = useSelector(
    (state: ApplicationState) => state.router.location
  );

  useEffect(() => {
    dispatch(fetchVendorPoliciesDetails());
    canIndexVendorPolicies && dispatch(fetchPoliciesForVendor());
  }, []);

  useEffect(() => {
    if (
      policyCode &&
      availablePolicies?.length > 0 &&
      !isFetchingVendorPolicies
    ) {
      const policy = availablePolicies.find(p => p.code === policyCode);
      if (policy) {
        setSelectedPolicy(policy);
      } else {
        dispatch(push(location.pathname));
      }
    }
  }, [policyCode, isFetchingVendorPolicies]);

  const handleOnPolicyCreated = (response: VendorPolicy) => {
    dispatch(fetchPoliciesForVendor());
    dispatch(push(policyPageItemRouteURL(response.id)));
  };

  const handleCreate = () => {
    if (!selectedPolicy) {
      return;
    }
    if (selectedPolicy.vendorPolicyExists) {
      dispatch(
        showExistingPolicyWarningModal({
          policyId: selectedPolicy.id,
          existingPolicyWarningModalMode: ExistingPolicyWarningModalMode.Add,
        })
      );
    } else {
      dispatch(
        createPolicy({
          policyId: selectedPolicy.id,
          onSuccess: handleOnPolicyCreated,
        })
      );
      setSelectedPolicy(null);
      dispatch(push(location.pathname));
    }
  };

  let policies = [...availablePolicies];

  if (search) {
    const fusePolicies = new Fuse(policies, options);
    policies = fusePolicies.search(search).map(x => x.item);
  }

  const amongAllPolicies = [...policies];

  if (categoryFilter) {
    policies = policies.filter(
      policy => policy.categoryName === categoryFilter
    );
  }

  const resultIds = policies.map(p => p.id);

  const extraResults = amongAllPolicies.filter(p => !resultIds.includes(p.id));

  policies.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

  const handleCategoryClick = (categoryName?: string) => () => {
    setCategoryFilter(categoryName);
  };

  const onFreePlan = !useSelector(selectActiveSubscription);
  const policiesCount = useSelector(getVendorPolicies).filter(
    p => p.owner
  ).length;
  const freePoliciesLimit = 1;

  const subscriptionRequired = onFreePlan && policiesCount >= freePoliciesLimit;

  useEffect(() => {
    setCategoryFilter(policyCategory);
  }, [policyCategory]);

  return (
    <div className="addNewPolicy">
      <DocumentLibraryHeader
        search={search}
        setSearch={setSearch}
        categories={allCategories.map(name => {
          return { id: name, name: name };
        })}
        categoryFilter={categoryFilter}
        handleCategoryClick={handleCategoryClick}
        title="Policies"
        searchPlaceholder="Keywords, e.g. ISO 27001, Infosec, diversity"
      />

      <div className="addNewPolicy--policyBoxContainer">
        <div className="addNewPolicy--policyBoxHeader">
          <div className="addNewPolicy--categoryName">
            {categoryFilter || 'All'}
            {isCreatingPolicy && <LoadingSpinner inlineSmall />}
          </div>
          {vendorProducts && <PartOfMyPlanBanner />}
        </div>
        <div className="addNewPolicy--policyBoxes">
          {policies.map(p => (
            <PolicyItem
              policy={p}
              subscriptionRequired={subscriptionRequired}
              key={p.id}
            />
          ))}
        </div>
        {!!categoryFilter && !policies.length && (
          <div>Nothing found in&nbsp;{categoryFilter}</div>
        )}
        {!!search && !!extraResults.length && (
          <>
            <div className="addNewPolicy--policyBoxHeader">
              <div className="addNewPolicy--categoryName">All</div>
            </div>
            <div className="addNewPolicy--policyBoxes">
              {extraResults.map(p => (
                <PolicyItem
                  policy={p}
                  subscriptionRequired={subscriptionRequired}
                  key={p.id}
                />
              ))}
            </div>
          </>
        )}
      </div>

      <div className="addNewPolicy--buttons">
        <AdoptechButton
          onClick={() => {
            dispatch(hideDocumentLibraryModal());
          }}
        >
          Cancel
        </AdoptechButton>
      </div>
      <DocumentLibraryDescriptionModal
        selectedDocument={selectedPolicy}
        onCancel={() => {
          setSelectedPolicy(null);
          dispatch(push(location.pathname));
        }}
        onCreate={handleCreate}
      />
    </div>
  );
};

export default AddNewPolicy;
