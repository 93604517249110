/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AssessmentTemplateModel
 */
export interface AssessmentTemplateModel {
  /**
   *
   * @type {string}
   * @memberof AssessmentTemplateModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentTemplateModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentTemplateModel
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentTemplateModel
   */
  vendorId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentTemplateModel
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentTemplateModel
   */
  updatedAt?: string | null;
}

export function AssessmentTemplateModelFromJSON(
  json: any
): AssessmentTemplateModel {
  return AssessmentTemplateModelFromJSONTyped(json, false);
}

export function AssessmentTemplateModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssessmentTemplateModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    vendorId: !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function AssessmentTemplateModelToJSON(
  value?: AssessmentTemplateModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    vendor_id: value.vendorId,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
