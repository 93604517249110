/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserInvitation
 */
export interface UserInvitation {
  /**
   *
   * @type {string}
   * @memberof UserInvitation
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserInvitation
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserInvitation
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserInvitation
   */
  position?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserInvitation
   */
  vendorId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserInvitation
   */
  clientId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserInvitation
   */
  invitationMessage?: string | null;
}

export function UserInvitationFromJSON(json: any): UserInvitation {
  return UserInvitationFromJSONTyped(json, false);
}

export function UserInvitationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserInvitation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, 'email') ? undefined : json['email'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    position: !exists(json, 'position') ? undefined : json['position'],
    vendorId: !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
    clientId: !exists(json, 'client_id') ? undefined : json['client_id'],
    invitationMessage: !exists(json, 'invitation_message')
      ? undefined
      : json['invitation_message'],
  };
}

export function UserInvitationToJSON(value?: UserInvitation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    first_name: value.firstName,
    last_name: value.lastName,
    position: value.position,
    vendor_id: value.vendorId,
    client_id: value.clientId,
    invitation_message: value.invitationMessage,
  };
}
