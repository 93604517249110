import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { complianceFrameworksRoute } from '../../Routes/Routes';
import { AdoptechPageNavBar } from '../../AdoptechPageNavBar/AdoptechPageNavBar';
import { selectSelectedFramework } from '../../../selectors/selectSelectedFramework';

import './FrameworkRequirementsPage.scss';
import {
  fetchComplianceFrameworks,
  fetchRequirementCategories,
} from '../../../store/compliance/complianceThunks';
import { ApplicationState } from '../../../types/applicationState';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { AdoptechAccordionCard } from '../../AdoptechAccordionCard/AdoptechAccordionCard';
import { Accordion } from 'react-bootstrap';
import { FrameworkRequirementCategories } from './FrameworkRequirementCategories';
import { useParams } from 'react-router';

export const FrameworkRequirementsPage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const frameworks = useSelector(
    (state: ApplicationState) => state.compliance.frameworks
  );
  const framework =
    useSelector(selectSelectedFramework) ||
    (frameworks || []).find(frameworkModel => frameworkModel.id === params.id);
  const goBack = () => dispatch(push(complianceFrameworksRoute));

  const baseCss = 'frameworkRequirementsPage';

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  useEffect(() => {
    dispatch(fetchComplianceFrameworks(vendorId));
  }, [vendorId]);

  useEffect(() => {
    if (!framework) return;
    dispatch(fetchRequirementCategories(framework.id));
  }, [framework?.id]);

  const isFetchingRequirements = useSelector(
    (state: ApplicationState) =>
      state.compliance.isFetchingFrameworkRequirements
  );

  const categories = useSelector(
    (state: ApplicationState) => state.compliance.frameworkRequirementCategories
  );

  const frameworkName = framework?.name || 'Framework';

  return (
    <div id={baseCss}>
      <AdoptechPageNavBar
        goBack={goBack}
        content={<>{frameworkName} Requirements</>}
      />
      {isFetchingRequirements ? (
        <LoadingSpinner />
      ) : (
        <FrameworkRequirementCategories categories={categories} />
      )}
    </div>
  );
};
