import complianceImage from 'images/paymentLandingPage/Compliance.svg';
import assessmentsImage from 'images/paymentLandingPage/Assessments.svg';
import checklistsImage from 'images/paymentLandingPage/Checklists.svg';
import escrowImage from 'images/paymentLandingPage/Escrow.svg';
import dataRoomImage from 'images/paymentLandingPage/DataRoom.svg';
import vendorRegisterImage from 'images/paymentLandingPage/VendorRegister.svg';
import legalRegisterImage from 'images/paymentLandingPage/LegalRegister.svg';
import pestelImage from 'images/paymentLandingPage/PESTEL.svg';
import auditsImage from 'images/paymentLandingPage/Audits.svg';
import riskRegisterImage from 'images/paymentLandingPage/RiskRegister.svg';
import reportsImage from 'images/paymentLandingPage/Reports.svg';

import { VendorProductIndexFeatures } from '../../../swagger/models/VendorProductIndexFeatures';

export interface PaymentLandingPageConfig {
  title: string;
  description: string;
  planInfo: string;
  ctaText: string;
  image?: any;
}

export const subscriptionOptionsText = 'SEE SUBSCRIPTION OPTIONS';

type landingConfig = Record<
  keyof VendorProductIndexFeatures,
  PaymentLandingPageConfig
>;

export const paymentLandingPageConfig: landingConfig = {
  assessments: {
    title: 'Vendor risk management',
    description:
      'Say hello to streamlined third-party risk assessments and goodbye to endless emails and spreadsheets. Assess and audit the data security and governance posture of your vendors to ensure they meet your standards. Demonstrate to your clients that your vendors, their 4th parties, adequately manage and mitigate cyber and information security risk.',
    planInfo:
      '<span>Our Assessment services require a Professional or Enterprise subscription.</span>',
    ctaText: subscriptionOptionsText,
    image: assessmentsImage,
  },

  vendorRegister: {
    title: 'Vendor register',
    description:
      'Standardise your third party risk management with all your vendors in one place. Assess their risk profile, track their contracts, understand critical dependencies, assign an owner, and prioritise actions to manage their risk to your company.',
    planInfo:
      '<span>Our Vendor register requires a Professional or Enterprise subscription.</span>',
    ctaText: subscriptionOptionsText,
    image: vendorRegisterImage,
  },

  legalRegister: {
    title: 'Legal register',
    description:
      'Ensure your company is up to date with all applicable legislation and regulatory requirements for the jurisdictions that it operates in. Track your compliance, assign owners and set review dates.',
    planInfo:
      '<span>Our Legal register requires a Professional or Enterprise subscription.</span>',
    ctaText: subscriptionOptionsText,
    image: legalRegisterImage,
  },

  pestel: {
    title: 'Pestel',
    description:
      'Scan the Political, Economic, Social, Technological, Legal and Environmental risks and opportunities your business faces to ensure you are focusing time in the right areas.',
    planInfo:
      '<span>Our PESTEL register requires a Professional or Enterprise subscription.</span>',
    ctaText: subscriptionOptionsText,
    image: pestelImage,
  },

  audits: {
    title: 'Audits',
    description:
      'Conduct internal audits to check that your controls are meeting your company’s requirements, the requirements of relevant certification specifications, and that they are being effectively implemented and maintained.',
    planInfo:
      '<span>Audits require a Professional or Enterprise subscription.</span>',
    ctaText: subscriptionOptionsText,
    image: auditsImage,
  },

  riskRegister: {
    title: 'Risk register',
    description:
      'Continually assess and manage your company’s information security risks, raise actions and assign an owner, set review dates and prioritise those actions to safeguard your company’s information assets.',
    planInfo:
      '<span>Our Risk register requires a Professional or Enterprise subscription.</span>',
    ctaText: subscriptionOptionsText,
    image: riskRegisterImage,
  },

  complianceFrameworks: {
    title: 'Simplifying information security compliance',
    description:
      'Build trust, credibility and close deals faster by preparing for and maintaining industry standards such as ISO 27001 & ISO 9001.',
    planInfo:
      'Our Compliance services requires a Professional or Enterprise subscription.',
    ctaText: 'REQUEST A DEMO',
    image: complianceImage,
  },

  controls: {
    title: 'Simplifying information security compliance',
    description:
      'Build trust, credibility and close deals faster by preparing for and maintaining industry standards such as ISO 27001 & ISO 9001.',
    planInfo:
      'Our Compliance services requires a Professional or Enterprise subscription.',
    ctaText: subscriptionOptionsText,
    image: complianceImage,
  },
  tasks: {
    title: 'Simplifying information security compliance',
    description:
      'Build trust, credibility and close deals faster by preparing for and maintaining industry standards such as ISO 27001 & ISO 9001.',
    planInfo:
      'Our Compliance services requires a Professional or Enterprise subscription.',
    ctaText: subscriptionOptionsText,
    image: complianceImage,
  },

  checklists: {
    title: 'Checklists',
    description:
      'Complete repetitive tasks quickly, efficiently and with fewer mistakes.GDPR, On-boarding, Off-boarding and BCP checklists ready-made for software companies.',
    planInfo: '<span>Our Checklist services requires subscription</span>',
    ctaText: subscriptionOptionsText,
    image: checklistsImage,
  },

  dataRoom: {
    title: 'Data room',
    description:
      'Accelerate audits, due-diligence and other external assessments by collating all the information needed in one secure platform.',
    planInfo:
      '<span>Our Data Security Room services requires subscription</span>',
    ctaText: subscriptionOptionsText,
    image: dataRoomImage,
  },

  escrow: {
    title: 'Cloud continuity and code escrow service',
    description:
      'Protect your code, reputation and customers with source code and credential escrow. Offer as part of your service or on demand for your customers.',
    planInfo: '',
    ctaText: subscriptionOptionsText,
    image: escrowImage,
  },

  policies: {
    title: 'Policies',
    description: 'Our Policies services requires subscription',
    planInfo: '',
    ctaText: subscriptionOptionsText,
    image: dataRoomImage,
  },

  agreements: {
    title: 'Agreements',
    description: 'Our Agreements services requires subscription',
    planInfo: '',
    ctaText: subscriptionOptionsText,
    image: dataRoomImage,
  },
  dashboard: {
    title: 'Dashboard',
    description: 'Our Dashboard services requires subscription',
    planInfo: '',
    ctaText: subscriptionOptionsText,
    image: dataRoomImage,
  },
  calendar: {
    title: 'Calendar',
    description: 'Our Calendar services requires subscription',
    planInfo: '',
    ctaText: subscriptionOptionsText,
    image: dataRoomImage,
  },
  people: {
    title: 'People',
    description: 'Our People services requires subscription',
    planInfo: '',
    ctaText: subscriptionOptionsText,
    image: dataRoomImage,
  },
  profile: {
    title: 'Profile',
    description: 'Profile management requires subscription',
    planInfo: '',
    ctaText: subscriptionOptionsText,
    image: dataRoomImage,
  },
  companyDocuments: {
    title: 'Company Documents',
    description: 'Company Documents requires subscription',
    planInfo: '',
    ctaText: subscriptionOptionsText,
    image: dataRoomImage,
  },
  reports: {
    title: 'Reports',
    description:
      'Create tailored summary reports to efficiently share information with your team and auditors.',
    planInfo:
      'Reports are available with a Professional or Enterprise subscription.',
    ctaText: subscriptionOptionsText,
    image: reportsImage,
  },

  integrations: {
    title: 'Integrations',
    description: 'Integrations requires subscription',
    planInfo: '',
    ctaText: subscriptionOptionsText,
    image: dataRoomImage,
  },
};
