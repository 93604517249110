import React from 'react';
import { Dropdown } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { IconButton } from '../IconButton/IconButton';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';
import { faBellExclamation } from '@fortawesome/pro-light-svg-icons/faBellExclamation';
import { NotificationsItem } from '../NotificationsItem/NotificationsItem';
import { NotificationModel } from '../../swagger';
import { NoDataText } from '../NoDataText/NoDataText';
import './Notifications.scss';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { AdoptechDropdownToggle } from '../AdoptechDropdownToggle/AdoptechDropdownToggle';

export const Notifications: React.FC = () => {
  const notifications = useSelector(
    (state: ApplicationState) => state.notifications.notificationsList.data
  );

  const isLoading = useSelector(
    (state: ApplicationState) => state.notifications.isRequestingNotifications
  );

  const notificationsExists = notifications.length;
  return (
    <Dropdown className="notifications">
      <Dropdown.Toggle as={AdoptechDropdownToggle}>
        <IconButton ariaLabel="Notifications" icon={faBellExclamation} />
        {!!notifications.length && (
          <span className="notifications--counter">{notifications.length}</span>
        )}
      </Dropdown.Toggle>
      <DropdownMenu
        align="right"
        className={notificationsExists ? '' : 'noData'}
      >
        {notificationsExists ? (
          notifications.map((item: NotificationModel) => (
            <div key={item.id}>
              <NotificationsItem notification={item} />
            </div>
          ))
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <NoDataText
                text="No unread notifications"
                extraClass="notifications--empty"
              />
              {isLoading && <LoadingSpinner inlineSmall />}
            </div>
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
