import { SortDirection } from '../types/sortDirection';

export const rotateDirection = (direction: SortDirection): SortDirection => {
  if (direction === undefined) {
    return SortDirection.Descending;
  }

  if (direction === SortDirection.Descending) {
    return SortDirection.Ascending;
  }

  if (direction === SortDirection.Ascending) {
    return SortDirection.Descending;
  }
};
