/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorIntegrationUpdatePayloadVendorIntegration,
  VendorIntegrationUpdatePayloadVendorIntegrationFromJSON,
  VendorIntegrationUpdatePayloadVendorIntegrationFromJSONTyped,
  VendorIntegrationUpdatePayloadVendorIntegrationToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorIntegrationUpdatePayload
 */
export interface VendorIntegrationUpdatePayload {
  /**
   *
   * @type {VendorIntegrationUpdatePayloadVendorIntegration}
   * @memberof VendorIntegrationUpdatePayload
   */
  vendorIntegration: VendorIntegrationUpdatePayloadVendorIntegration;
}

export function VendorIntegrationUpdatePayloadFromJSON(
  json: any
): VendorIntegrationUpdatePayload {
  return VendorIntegrationUpdatePayloadFromJSONTyped(json, false);
}

export function VendorIntegrationUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorIntegrationUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vendorIntegration: VendorIntegrationUpdatePayloadVendorIntegrationFromJSON(
      json['vendor_integration']
    ),
  };
}

export function VendorIntegrationUpdatePayloadToJSON(
  value?: VendorIntegrationUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vendor_integration: VendorIntegrationUpdatePayloadVendorIntegrationToJSON(
      value.vendorIntegration
    ),
  };
}
