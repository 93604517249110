import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import './LoadingSpinner.scss';

export const LoadingSpinner = ({
  delayed,
  inlineSmall,
}: {
  delayed?: boolean;
  inlineSmall?: boolean;
}) => {
  const [show, setShow] = useState(delayed ? false : true);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    show && (
      <div
        className={classNames('loadingSpinner', {
          'inline-small': inlineSmall,
        })}
      >
        <Spinner animation="border" size="sm" />
      </div>
    )
  );
};
