import React, { ReactNode } from 'react';
import './AdoptechIcon.scss';

interface AdoptechIconProps {
  onClick: () => void;
  children: ReactNode;
  shape: 'square';
}
const AdoptechIcon: React.FC<AdoptechIconProps> = ({
  onClick,
  children,
  shape,
}) => {
  return (
    <div className={`light-${shape}-icon`} onClick={onClick}>
      {children}
    </div>
  );
};

export default AdoptechIcon;
