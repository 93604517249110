/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FrameworkCreatePayloadFramework
 */
export interface FrameworkCreatePayloadFramework {
  /**
   *
   * @type {string}
   * @memberof FrameworkCreatePayloadFramework
   */
  frameworkTemplateId: string;
}

export function FrameworkCreatePayloadFrameworkFromJSON(
  json: any
): FrameworkCreatePayloadFramework {
  return FrameworkCreatePayloadFrameworkFromJSONTyped(json, false);
}

export function FrameworkCreatePayloadFrameworkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkCreatePayloadFramework {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    frameworkTemplateId: json['framework_template_id'],
  };
}

export function FrameworkCreatePayloadFrameworkToJSON(
  value?: FrameworkCreatePayloadFramework | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    framework_template_id: value.frameworkTemplateId,
  };
}
