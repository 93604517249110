/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RiskCategoryModelRiskStats
 */
export interface RiskCategoryModelRiskStats {
  /**
   *
   * @type {number}
   * @memberof RiskCategoryModelRiskStats
   */
  actual?: number;
  /**
   *
   * @type {number}
   * @memberof RiskCategoryModelRiskStats
   */
  total?: number;
}

export function RiskCategoryModelRiskStatsFromJSON(
  json: any
): RiskCategoryModelRiskStats {
  return RiskCategoryModelRiskStatsFromJSONTyped(json, false);
}

export function RiskCategoryModelRiskStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskCategoryModelRiskStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    actual: !exists(json, 'actual') ? undefined : json['actual'],
    total: !exists(json, 'total') ? undefined : json['total'],
  };
}

export function RiskCategoryModelRiskStatsToJSON(
  value?: RiskCategoryModelRiskStats | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    actual: value.actual,
    total: value.total,
  };
}
