/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssertionRunModel,
  AssertionRunModelFromJSON,
  AssertionRunModelFromJSONTyped,
  AssertionRunModelToJSON,
  SharedPaginationMeta,
  SharedPaginationMetaFromJSON,
  SharedPaginationMetaFromJSONTyped,
  SharedPaginationMetaToJSON,
} from './';

/**
 *
 * @export
 * @interface AssertionRunList
 */
export interface AssertionRunList {
  /**
   *
   * @type {Array<AssertionRunModel>}
   * @memberof AssertionRunList
   */
  data: Array<AssertionRunModel>;
  /**
   *
   * @type {SharedPaginationMeta}
   * @memberof AssertionRunList
   */
  meta: SharedPaginationMeta;
}

export function AssertionRunListFromJSON(json: any): AssertionRunList {
  return AssertionRunListFromJSONTyped(json, false);
}

export function AssertionRunListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionRunList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(AssertionRunModelFromJSON),
    meta: SharedPaginationMetaFromJSON(json['meta']),
  };
}

export function AssertionRunListToJSON(value?: AssertionRunList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(AssertionRunModelToJSON),
    meta: SharedPaginationMetaToJSON(value.meta),
  };
}
