/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  JurisdictionModel,
  JurisdictionModelFromJSON,
  JurisdictionModelFromJSONTyped,
  JurisdictionModelToJSON,
  LegislationClassificationModel,
  LegislationClassificationModelFromJSON,
  LegislationClassificationModelFromJSONTyped,
  LegislationClassificationModelToJSON,
  SharedLegislationRecurring,
  SharedLegislationRecurringFromJSON,
  SharedLegislationRecurringFromJSONTyped,
  SharedLegislationRecurringToJSON,
} from './';

/**
 *
 * @export
 * @interface LegislationTemplateModel
 */
export interface LegislationTemplateModel {
  /**
   *
   * @type {string}
   * @memberof LegislationTemplateModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof LegislationTemplateModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof LegislationTemplateModel
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof LegislationTemplateModel
   */
  summary?: string;
  /**
   *
   * @type {string}
   * @memberof LegislationTemplateModel
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof LegislationTemplateModel
   */
  descriptionUrl?: string | null;
  /**
   *
   * @type {string}
   * @memberof LegislationTemplateModel
   */
  explanationOfCompliance?: string | null;
  /**
   *
   * @type {SharedLegislationRecurring}
   * @memberof LegislationTemplateModel
   */
  recurring?: SharedLegislationRecurring;
  /**
   *
   * @type {JurisdictionModel}
   * @memberof LegislationTemplateModel
   */
  jurisdiction?: JurisdictionModel;
  /**
   *
   * @type {LegislationClassificationModel}
   * @memberof LegislationTemplateModel
   */
  legislationClassification?: LegislationClassificationModel;
}

export function LegislationTemplateModelFromJSON(
  json: any
): LegislationTemplateModel {
  return LegislationTemplateModelFromJSONTyped(json, false);
}

export function LegislationTemplateModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LegislationTemplateModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    identifier: json['identifier'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    description: !exists(json, 'description') ? undefined : json['description'],
    descriptionUrl: !exists(json, 'description_url')
      ? undefined
      : json['description_url'],
    explanationOfCompliance: !exists(json, 'explanation_of_compliance')
      ? undefined
      : json['explanation_of_compliance'],
    recurring: !exists(json, 'recurring')
      ? undefined
      : SharedLegislationRecurringFromJSON(json['recurring']),
    jurisdiction: !exists(json, 'jurisdiction')
      ? undefined
      : JurisdictionModelFromJSON(json['jurisdiction']),
    legislationClassification: !exists(json, 'legislation_classification')
      ? undefined
      : LegislationClassificationModelFromJSON(
          json['legislation_classification']
        ),
  };
}

export function LegislationTemplateModelToJSON(
  value?: LegislationTemplateModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    identifier: value.identifier,
    summary: value.summary,
    description: value.description,
    description_url: value.descriptionUrl,
    explanation_of_compliance: value.explanationOfCompliance,
    recurring: SharedLegislationRecurringToJSON(value.recurring),
    jurisdiction: JurisdictionModelToJSON(value.jurisdiction),
    legislation_classification: LegislationClassificationModelToJSON(
      value.legislationClassification
    ),
  };
}
