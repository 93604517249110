/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SharedMaxUsersAmount {
  NUMBER_9 = 9,
  NUMBER_19 = 19,
  NUMBER_49 = 49,
  NUMBER_99 = 99,
  NUMBER_249 = 249,
}

export function SharedMaxUsersAmountFromJSON(json: any): SharedMaxUsersAmount {
  return SharedMaxUsersAmountFromJSONTyped(json, false);
}

export function SharedMaxUsersAmountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SharedMaxUsersAmount {
  return json as SharedMaxUsersAmount;
}

export function SharedMaxUsersAmountToJSON(
  value?: SharedMaxUsersAmount | null
): any {
  return value as any;
}
