/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorPolicyPublish
 */
export interface VendorPolicyPublish {
  /**
   *
   * @type {string}
   * @memberof VendorPolicyPublish
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyPublish
   */
  versionBump?: VendorPolicyPublishVersionBumpEnum;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyPublish
   */
  changeReason?: string;
}

export function VendorPolicyPublishFromJSON(json: any): VendorPolicyPublish {
  return VendorPolicyPublishFromJSONTyped(json, false);
}

export function VendorPolicyPublishFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorPolicyPublish {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    versionBump: !exists(json, 'version_bump')
      ? undefined
      : json['version_bump'],
    changeReason: !exists(json, 'change_reason')
      ? undefined
      : json['change_reason'],
  };
}

export function VendorPolicyPublishToJSON(
  value?: VendorPolicyPublish | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    version_bump: value.versionBump,
    change_reason: value.changeReason,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum VendorPolicyPublishVersionBumpEnum {
  Minor = 'minor',
  Major = 'major',
}
