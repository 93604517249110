import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import './AdoptechRoundCheckbox.scss';

interface AdoptechRoundCheckboxProps {
  checked: boolean;
  className?: string;
  id: string;
  label: string;
  onChange?: (e?: React.SyntheticEvent) => void;
  disabled?: boolean;
}

export const AdoptechRoundCheckbox: React.FC<
  AdoptechRoundCheckboxProps
> = props => {
  const classes = classNames(
    {
      adoptechRoundCheckbox: true,
      'adoptechRoundCheckbox--checked': props.checked,
      'adoptechRoundCheckbox--disabled': props.disabled,
    },
    props.className
  );

  return (
    <div className={classes}>
      <label>
        <input
          type="checkbox"
          id={props.id}
          onChange={props.onChange}
          checked={props.checked}
          disabled={props.disabled}
        />
        <div className="adoptechRoundCheckbox--iconContainer">
          <FontAwesomeIcon
            className="adoptechRoundCheckbox--icon"
            icon={faCheck}
          />
        </div>
        <div className="adoptechRoundCheckbox--label">{props.label}</div>
      </label>
    </div>
  );
};
