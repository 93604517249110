import React from 'react';
import { NoDataText } from '../../../components/NoDataText/NoDataText';
import './NoValidRoleText.scss';
import { openChatWidget } from '../../../functions/chatWidgetHelper';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface NoValidRoleText {
  role?: string;
}
export const NoValidRoleText: React.FC<NoValidRoleText> = props => {
  const baseCss = 'NoValidRoleText';
  const roleDetails = props.role ? `(${props.role})` : '';
  const message = `You need to have a valid role ${roleDetails} to view this page.`;
  return (
    <>
      <NoDataText extraClass={baseCss} text={message} />
      <a
        href="#"
        className={baseCss + '--intercom'}
        onClick={e => {
          e.preventDefault();
          openChatWidget();
        }}
      >
        Chat with the team
        <FontAwesomeIcon icon={faExternalLink} />
      </a>
    </>
  );
};
export default NoValidRoleText;
