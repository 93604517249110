/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ReviewFrequencyEnum,
  ReviewFrequencyEnumFromJSON,
  ReviewFrequencyEnumFromJSONTyped,
  ReviewFrequencyEnumToJSON,
} from './';

/**
 *
 * @export
 * @interface PestelRegisterUpdatePayload
 */
export interface PestelRegisterUpdatePayload {
  /**
   *
   * @type {string}
   * @memberof PestelRegisterUpdatePayload
   */
  ownerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof PestelRegisterUpdatePayload
   */
  nextReviewDate?: string;
  /**
   *
   * @type {ReviewFrequencyEnum}
   * @memberof PestelRegisterUpdatePayload
   */
  reviewFrequency?: ReviewFrequencyEnum;
}

export function PestelRegisterUpdatePayloadFromJSON(
  json: any
): PestelRegisterUpdatePayload {
  return PestelRegisterUpdatePayloadFromJSONTyped(json, false);
}

export function PestelRegisterUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PestelRegisterUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    nextReviewDate: !exists(json, 'next_review_date')
      ? undefined
      : json['next_review_date'],
    reviewFrequency: !exists(json, 'review_frequency')
      ? undefined
      : ReviewFrequencyEnumFromJSON(json['review_frequency']),
  };
}

export function PestelRegisterUpdatePayloadToJSON(
  value?: PestelRegisterUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    owner_id: value.ownerId,
    next_review_date: value.nextReviewDate,
    review_frequency: ReviewFrequencyEnumToJSON(value.reviewFrequency),
  };
}
