import { LegislationModel } from '../swagger';
import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';

export const legislationSort = (
  a: LegislationModel,
  b: LegislationModel,
  columnSort: ColumnSort
): number => {
  const direction = columnSort.direction === SortDirection.Ascending ? -1 : 1;

  const typedColumn: keyof LegislationModel =
    columnSort.name as keyof LegislationModel;

  switch (typedColumn) {
    case 'jurisdiction':
      return sort(
        a.jurisdiction?.name || '',
        b.jurisdiction?.name || '',
        direction
      );
    default:
      return sort(a[typedColumn], b[typedColumn], direction);
  }
};
