import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { closeAttestationManagement } from '../../store/policies/policiesSlice';
import {
  fetchVendorDocumentAttestations,
  fetchVendorTeams,
} from '../../store/vendors/vendorsThunks';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import {
  SentAttestationsTable,
  useSentAttestations,
} from '../SentAttestationsTable/SentAttestationsTable';
import './AttestationManagementModal.scss';
import { VendorDocumentExtended } from '../../swagger';

const useFetchAttestations = (currentDocument: VendorDocumentExtended) => {
  const dispatch = useDispatch();
  const { isFetchingVendorDocumentAttestations } = useSelector(
    (state: ApplicationState) => state.vendors.documentState
  );
  const { isAttestationManagementModalShowing } = useSelector(
    (state: ApplicationState) => state.policies
  );

  useEffect(() => {
    if (!currentDocument || !isAttestationManagementModalShowing) return;
    dispatch(fetchVendorDocumentAttestations(currentDocument.id));
  }, [currentDocument?.id]);

  return {
    isFetchingVendorDocumentAttestations,
    isAttestationManagementModalShowing,
  };
};

export const AttestationManagementModal: React.FC = () => {
  const { attestationManagementVendorDocument: currentDocument } = useSelector(
    (state: ApplicationState) => state.policies
  );

  const {
    isFetchingVendorDocumentAttestations,
    isAttestationManagementModalShowing,
  } = useFetchAttestations(currentDocument);
  const dispatch = useDispatch();
  const handleCancel = () => dispatch(closeAttestationManagement());

  const baseCss = 'attestationManagementModal';
  const childClass = (name: string) => `${baseCss}--${name}`;
  const { sentAttestations } = useSentAttestations(currentDocument);

  return (
    <React.Fragment>
      <AdoptechModal
        className={baseCss}
        onHide={handleCancel}
        show={isAttestationManagementModalShowing}
      >
        <Modal.Header>
          <div>
            Read requests
            <div className={childClass('subheader')}>
              <div className={childClass('title')}>{currentDocument.name}</div>
              <div className={childClass('title')}>
                V{currentDocument.version}
              </div>
              <div className={childClass('readCount')}>
                {currentDocument.attestationStats.attested}/
                {currentDocument.attestationStats.total}
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {isFetchingVendorDocumentAttestations ? (
            <LoadingSpinner />
          ) : (
            <div className={childClass('staffMembers')}>
              <SentAttestationsTable
                attestations={sentAttestations}
                tableClass={null}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={handleCancel}>Cancel</AdoptechButton>

          <AdoptechButton
            onClick={handleCancel}
            variant={AdoptechButtonVariant.Primary}
          >
            Done
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
