/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubUserSignInPayloadTrusthubUser,
  TrusthubUserSignInPayloadTrusthubUserFromJSON,
  TrusthubUserSignInPayloadTrusthubUserFromJSONTyped,
  TrusthubUserSignInPayloadTrusthubUserToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubUserSignInPayload
 */
export interface TrusthubUserSignInPayload {
  /**
   *
   * @type {TrusthubUserSignInPayloadTrusthubUser}
   * @memberof TrusthubUserSignInPayload
   */
  trusthubUser: TrusthubUserSignInPayloadTrusthubUser;
}

export function TrusthubUserSignInPayloadFromJSON(
  json: any
): TrusthubUserSignInPayload {
  return TrusthubUserSignInPayloadFromJSONTyped(json, false);
}

export function TrusthubUserSignInPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubUserSignInPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    trusthubUser: TrusthubUserSignInPayloadTrusthubUserFromJSON(
      json['trusthub_user']
    ),
  };
}

export function TrusthubUserSignInPayloadToJSON(
  value?: TrusthubUserSignInPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    trusthub_user: TrusthubUserSignInPayloadTrusthubUserToJSON(
      value.trusthubUser
    ),
  };
}
