/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EmailText
 */
export interface EmailText {
  /**
   *
   * @type {string}
   * @memberof EmailText
   */
  text?: string;
}

export function EmailTextFromJSON(json: any): EmailText {
  return EmailTextFromJSONTyped(json, false);
}

export function EmailTextFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailText {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    text: !exists(json, 'text') ? undefined : json['text'],
  };
}

export function EmailTextToJSON(value?: EmailText | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    text: value.text,
  };
}
