import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  canCreate,
  hasFeature,
  mapShareFeaturesToAccessLevels,
} from '../../functions/access';
import { capitaliseFirst } from '../../functions/capitaliseFirst';
import { formatShortDate } from '../../functions/formatShortDate';
import { selectPendingAssessments } from '../../selectors/selectPendingAssessments';
import { showEditOwnerModal } from '../../store/global/globalSlice';
import { showShareModal } from '../../store/vendors/vendorsSlice';
import { AssessmentResponse, ShareableType } from '../../swagger';
import { AccessObject } from '../../types/accessObject';
import { Grid } from '../../types/grid';
import { OwnerObjectType } from '../../types/OwnerObjectType';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { MeatballMenu } from '../MeatballMenu/MeatballMenu';
import { SortableTableHeader } from '../SortableTableHeader/SortableTableHeader';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import './PendingAssessmentsGrid.scss';

export const PendingAssessmentsGrid: React.FC = () => {
  const assessmentResponses = useSelector(selectPendingAssessments);
  const canCreateAssessments = canCreate(AccessObject.assessments);

  const dispatch = useDispatch();

  const handleRowClick = (id: string) => () => {
    dispatch(push(`/r/riskManagement/assessments/response/${id}`));
  };

  if (!assessmentResponses || assessmentResponses.length === 0) {
    return null;
  }

  return (
    <div className="pendingAssessmentsGrid">
      <AdoptechTable className="pendingAssessments--table">
        <thead>
          <tr>
            <SortableTableHeader<AssessmentResponse>
              className="pendingAssessmentsGrid--nameHeader"
              columnName="name"
              grid={Grid.PendingAssessments}
              label="Name"
            />
            <SortableTableHeader<AssessmentResponse>
              className="pendingAssessmentsGrid--createdByHeader"
              columnName="requestingVendorName"
              grid={Grid.PendingAssessments}
              label="Created by"
            />
            <SortableTableHeader<AssessmentResponse>
              className="pendingAssessmentsGrid--dueDateHeader"
              columnName="dueDate"
              grid={Grid.PendingAssessments}
              label="Due date"
            />
            <SortableTableHeader<AssessmentResponse>
              className="pendingAssessmentsGrid--ownerHeader"
              columnName="requestingVendorName"
              grid={Grid.PendingAssessments}
              label="Owner"
            />
            <SortableTableHeader<AssessmentResponse>
              className="pendingAssessmentsGrid--statusHeader"
              columnName="status"
              grid={Grid.PendingAssessments}
              label="Status"
            />
            <th className="pendingAssessmentsGrid--actionsHeader" />
          </tr>
        </thead>
        <tbody>
          {assessmentResponses.map((assessmentResponse: AssessmentResponse) => {
            return (
              <tr
                className="pendingAssessmentsGrid--row"
                key={assessmentResponse.id}
              >
                <td
                  className="pendingAssessmentsGrid--cell"
                  onClick={handleRowClick(assessmentResponse.id)}
                >
                  {assessmentResponse.name}
                </td>
                <td
                  className="pendingAssessmentsGrid--cell"
                  onClick={handleRowClick(assessmentResponse.id)}
                >
                  {assessmentResponse.requestingVendorName}
                </td>
                <td
                  className="pendingAssessmentsGrid--cell"
                  onClick={handleRowClick(assessmentResponse.id)}
                >
                  {formatShortDate(assessmentResponse.dueDate)}
                </td>
                <td
                  className={classNames(
                    {
                      'adoptechTable--clickableCell': canCreateAssessments,
                    },
                    'pendingAssessmentsGrid--cell'
                  )}
                  onClick={() =>
                    canCreateAssessments
                      ? dispatch(
                          showEditOwnerModal({
                            object: assessmentResponse,
                            type: OwnerObjectType.AssessmentResponse,
                          })
                        )
                      : dispatch(
                          push(
                            `/r/riskManagement/assessments/summary/${assessmentResponse.id}`
                          )
                        )
                  }
                >
                  <UserAvatar user={assessmentResponse.owner} size="small" />
                </td>
                <td
                  className="pendingAssessmentsGrid--cell"
                  onClick={handleRowClick(assessmentResponse.id)}
                >
                  {capitaliseFirst(assessmentResponse.status)}
                </td>
                <td className="pendingAssessmentsGrid--cell">
                  <MeatballMenu>
                    <Dropdown.Item
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(
                          push(
                            `/r/riskManagement/assessments/response/${assessmentResponse.id}`
                          )
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        className="meatballMenu--icon"
                        icon={faPen}
                      />
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="meatballMenu--item"
                      disabled={
                        !hasFeature(
                          AccessObject.share,
                          assessmentResponse?.access
                        )
                      }
                      onClick={e => {
                        dispatch(
                          showShareModal({
                            shareableId: assessmentResponse.id,
                            shareableType: ShareableType.AssessmentResponse,
                            accessLevels: mapShareFeaturesToAccessLevels(
                              assessmentResponse.access.feature
                            ),
                            relativePath: assessmentResponse.relativePath,
                          })
                        );
                        e.stopPropagation();
                      }}
                    >
                      <FontAwesomeIcon
                        className="meatballMenu--icon"
                        icon={faUserPlus}
                      />
                      Share
                    </Dropdown.Item>

                    {canCreateAssessments && (
                      <Dropdown.Item
                        onClick={e => {
                          dispatch(
                            showEditOwnerModal({
                              object: assessmentResponse,
                              type: OwnerObjectType.AssessmentResponse,
                            })
                          );
                          e.stopPropagation();
                        }}
                      >
                        <FontAwesomeIcon
                          className="meatballMenu--icon"
                          icon={faFileContract}
                        />
                        Edit owner
                      </Dropdown.Item>
                    )}
                  </MeatballMenu>
                </td>
              </tr>
            );
          })}
        </tbody>
      </AdoptechTable>
    </div>
  );
};
