import { useSelector } from 'react-redux';
import { AgreementsCreateForm } from '../../components/Agreements/AddNewAgreementForm/AddNewAgreementForm';
import { termsModel } from '../../components/Agreements/AddNewAgreementForm/TermsStep/TermsStep';
import { formatShortDate } from '../../functions/formatShortDate';
import { AgreementTemplateModel } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';

interface AgreementRadioOption {
  value: termsModel['id'];
  label: string;
}

export const useAgreementsTermsRadioOptions = (
  form: AgreementsCreateForm
): { options: AgreementRadioOption[]; onlyStandardTermsExist: boolean } => {
  const allAgreements = useSelector(
    (state: ApplicationState) => state.agreements.agreements
  );

  const agreementTemplates = useSelector(
    (state: ApplicationState) => state.agreements.agreementTemplates
  );

  const standardTemplate = agreementTemplates.find(
    (template: AgreementTemplateModel) =>
      template.id === form.standardTemplateId
  );
  const allAgreementsWithTheSameTemplate = (allAgreements || []).filter(
    agreement => agreement.agreementType === standardTemplate?.agreementType
  );

  const labelWithDate = (agreementOrTemplate: termsModel) => {
    const date = formatShortDate(agreementOrTemplate?.createdAt);
    return `${agreementOrTemplate.name} <span class='additional-label'>Created ${date}</span>`;
  };

  const standardModel = {
    id: standardTemplate.id,
    name: 'Adoptech Standard Terms',
    createdAt: standardTemplate.createdAt,
  };

  const allModels = [standardModel].concat(allAgreementsWithTheSameTemplate);

  const options = allModels.map((agreement: termsModel) => {
    return {
      value: agreement.id,
      label: labelWithDate(agreement),
    };
  });

  const onlyStandardTermsExist = options.length === 1;

  return { options, onlyStandardTermsExist };
};
