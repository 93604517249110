import React from 'react';
import { ReportForm, ReportSectionProps } from '../ReportsManageDrawer';
import { ReportVersionField } from '../ReportVersionField';
import { ReportOwnerField } from '../ReportOwnerField';

export const soaSummarySectionValid = (formData: ReportForm): boolean => {
  const hasVersionSelected =
    formData.hasVersion === false || formData.hasVersion === true;
  return !!(hasVersionSelected && formData.ownerId);
};

export const SoaSummarySection: React.FC<ReportSectionProps> = ({
  formData,
  updateField,
  updateForm,
}) => {
  const sectionProps = { formData, updateField, updateForm };
  return (
    <div>
      <ReportOwnerField {...sectionProps} />
      <ReportVersionField {...sectionProps} />
    </div>
  );
};
