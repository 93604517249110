import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideDeleteAssessmentModal,
  hideDeleteAssessmentToast,
} from '../../store/assessments/assessmentsSlice';
import { deleteAssessment } from '../../store/assessments/assessmentsThunks';
import { AssessmentRecipient } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { AdoptechCheckbox } from '../AdoptechCheckbox/AdoptechCheckbox';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { MessageToast } from '../MessageToast/MessageToast';
import './DeleteAssessmentModal.scss';

export const DeleteAssessmentModal: React.FC = () => {
  const baseCssName = 'deleteAssessmentModal';
  const dispatch = useDispatch();

  const assessmentToDelete = useSelector(
    (state: ApplicationState) => state.assessments.assessmentToDelete
  );

  const isDeleteAssessmentModalShowing = useSelector(
    (state: ApplicationState) =>
      state.assessments.isDeleteAssessmentModalShowing
  );

  const [selectedRecipients, setSelectedRecipients] = useState<
    AssessmentRecipient[]
  >([]);

  const showToast = useSelector(
    (state: ApplicationState) => state.assessments.showDeleteAssessmentToast
  );

  if (!assessmentToDelete) return null;

  const isAllUsersSelected = () =>
    selectedRecipients.length ===
    assessmentToDelete.assessmentRecipients?.length;

  const isUserSelected = (user: AssessmentRecipient) =>
    !!selectedRecipients.find(u => u.vendorId === user.vendorId);

  const updateAllSelectedUsers = () => {
    if (isAllUsersSelected()) {
      setSelectedRecipients([]);
    } else {
      setSelectedRecipients([...assessmentToDelete.assessmentRecipients]);
    }
  };

  const updateSelectedUser = (recipient: AssessmentRecipient) => () => {
    if (selectedRecipients.some(r => r.recipientId === recipient.recipientId)) {
      setSelectedRecipients(
        selectedRecipients.filter(r => r.recipientId !== recipient.recipientId)
      );
    } else {
      setSelectedRecipients([...selectedRecipients, recipient]);
    }
  };

  return (
    <>
      <AdoptechModal
        className={baseCssName}
        onHide={() => dispatch(hideDeleteAssessmentModal())}
        show={isDeleteAssessmentModalShowing}
      >
        <Modal.Header>Delete Assessment</Modal.Header>
        <Modal.Body className={baseCssName + '--modalBody'}>
          <div className={baseCssName + '--description'}>
            Deleting{' "'}
            <span className={baseCssName + '--deleteName'}>
              {assessmentToDelete.name}
              {'" '}
            </span>
            will remove it from the Active table and from all third parties to
            which it has been sent. Select the Third parties you would like to
            notify that this assessment is being deleted.
          </div>
          <div className={baseCssName + '--recipients'}>
            <AdoptechTable modal className={baseCssName + '--recipientsTable'}>
              <thead>
                <tr>
                  <th>
                    <AdoptechCheckbox
                      checked={isAllUsersSelected()}
                      id="selectAll"
                      label="Email address"
                      onChange={updateAllSelectedUsers}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {assessmentToDelete.assessmentRecipients?.map(recipient => {
                  const { email } = recipient;
                  return (
                    <tr key={email}>
                      <td className="adoptechTable--checkbox">
                        <AdoptechCheckbox
                          checked={isUserSelected(recipient)}
                          id={email}
                          label={email}
                          onChange={updateSelectedUser(recipient)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </AdoptechTable>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={() => dispatch(hideDeleteAssessmentModal())}>
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={() => {
              dispatch(
                deleteAssessment(
                  assessmentToDelete.id,
                  assessmentToDelete.assessmentRecipients
                )
              );
              dispatch(hideDeleteAssessmentModal());
            }}
            uppercase
            variant={AdoptechButtonVariant.Warning}
          >
            delete
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
      <MessageToast
        autohide
        delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
        onClose={() => dispatch(hideDeleteAssessmentToast())}
        show={showToast}
      >
        Assessment has been deleted.
      </MessageToast>
    </>
  );
};
