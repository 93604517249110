/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubVendorUserCreatePayloadTrusthubVendorUser,
  TrusthubVendorUserCreatePayloadTrusthubVendorUserFromJSON,
  TrusthubVendorUserCreatePayloadTrusthubVendorUserFromJSONTyped,
  TrusthubVendorUserCreatePayloadTrusthubVendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubVendorUserCreatePayload
 */
export interface TrusthubVendorUserCreatePayload {
  /**
   *
   * @type {TrusthubVendorUserCreatePayloadTrusthubVendorUser}
   * @memberof TrusthubVendorUserCreatePayload
   */
  trusthubVendorUser: TrusthubVendorUserCreatePayloadTrusthubVendorUser;
}

export function TrusthubVendorUserCreatePayloadFromJSON(
  json: any
): TrusthubVendorUserCreatePayload {
  return TrusthubVendorUserCreatePayloadFromJSONTyped(json, false);
}

export function TrusthubVendorUserCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubVendorUserCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    trusthubVendorUser:
      TrusthubVendorUserCreatePayloadTrusthubVendorUserFromJSON(
        json['trusthub_vendor_user']
      ),
  };
}

export function TrusthubVendorUserCreatePayloadToJSON(
  value?: TrusthubVendorUserCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    trusthub_vendor_user:
      TrusthubVendorUserCreatePayloadTrusthubVendorUserToJSON(
        value.trusthubVendorUser
      ),
  };
}
