import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Assessment,
  AssessmentDownload,
  AssessmentRecipient,
  AssessmentResponse,
  AssessmentResponseAnswer,
  AssessmentTemplateExtended,
} from '../../swagger';
import { COMPLETED_TEXT, NOT_FOUND_INDEX } from '../../types/constants';
import { QuestionType } from '../../types/QuestionType';
import {
  AddFileMode,
  AssessmentsState,
  AssessmentTabs,
  SendAssessmentDetails,
  SendAssessmentMode,
} from './assessmentsState';

const assessmentsSlice = createSlice({
  initialState: {
    assessments: [],
    assessmentResponses: [],
    assessmentTemplates: [],
    clearSendAssessmentDetails: false,
    currentAssessment: null,
    incomingAssessmentResponses: [],
    isAssessmentSentToastShowing: false,
    isDeletingAssessmentFileOrUrl: false,
    isDeletingAssessmentRecipient: false,
    isDeletingAssessmentResponse: false,
    isSendingAssessment: false,
    isSharingAssessmentResponse: false,
    isShowingAddFileOrUrlModal: false,
    isShowingConfirmDeleteAssessmentResponse: false,
    isShowingEditPublishedAssessmentModal: false,
    isShowingPublishAssessmentModal: false,
    isShowingPublishedAssessmentModal: false,
    isShowingSendAssessmentConfirmationModal: false,
    isShowingSendAssessmentModal: false,
    isShowingFileOrUrlSuccessfulToast: false,
    sendAssessmentRecipientEmails: [],
    isAssessmentResentToastShowing: false,
    isShowingAssessmentSummaryExportModal: false,
    fetchingAssessmentTemplatesError: '',
  } as unknown as AssessmentsState,
  name: 'assessmentsSlice',
  reducers: {
    addFileAnswer: (state, action: PayloadAction<string>) => {
      state.addFileMode = AddFileMode.File;
      state.addFileToQuestionName = action.payload;
      state.isShowingAddFileModal = true;
    },
    addUrlAnswer: (state, action: PayloadAction<string>) => {
      state.addFileMode = AddFileMode.Url;
      state.addFileToQuestionName = action.payload;
      state.isShowingAddFileModal = true;
    },
    backFromSendAssessmentConfirmationModal: state => {
      state.isShowingSendAssessmentConfirmationModal = false;
      state.isShowingSendAssessmentModal = true;
    },
    confirmSendAssessment: (
      state,
      action: PayloadAction<SendAssessmentDetails>
    ) => {
      state.clearSendAssessmentDetails = false;
      state.isShowingSendAssessmentModal = false;
      state.isShowingSendAssessmentConfirmationModal = true;
      state.sendAssessmentDetails = action.payload;
    },

    generateAndSendAssessment: (
      state,
      action: PayloadAction<SendAssessmentDetails>
    ) => {
      state.clearSendAssessmentDetails = false;
      state.isShowingSendAssessmentModal = false;
      state.isShowingSendAssessmentConfirmationModal = false;
      state.sendAssessmentDetails = action.payload;
    },

    deleteAssessmentFileOrUrlFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.deleteAssessmentFileOrUrlError = action.payload;
      state.isDeletingAssessmentFileOrUrl = false;
    },
    deleteAssessmentFileOrUrlRequest: state => {
      state.deleteAssessmentFileOrUrlError = undefined;
      state.isDeletingAssessmentFileOrUrl = true;
    },
    deleteAssessmentFileOrUrlSuccess: (
      state,
      action: PayloadAction<AssessmentResponse>
    ) => {
      state.isDeletingAssessmentFileOrUrl = false;
      state.currentAssessmentResponse = action.payload;
    },
    deleteAssessmentRecipientFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.deleteAssessmentRecipientError = action.payload;
      state.isDeletingAssessmentRecipient = false;
    },
    deleteAssessmentRecipientRequest: state => {
      state.deleteAssessmentRecipientError = undefined;
      state.isDeletingAssessmentRecipient = true;
    },
    deleteAssessmentRecipientSuccess: state => {
      state.isDeletingAssessmentRecipient = false;
    },
    deleteAssessmentResponseFailure: (state, action: PayloadAction<string>) => {
      state.deleteAssessmentResponseError = action.payload;
      state.isDeletingAssessmentResponse = false;
    },

    deleteAssessmentRequest: state => {
      state.isDeletingAssessment = true;
    },
    deleteAssessmentSuccess: state => {
      state.isDeletingAssessment = false;
      const assessments = state.assessments?.filter(
        a => a.id !== state.assessmentToDelete?.id
      );
      state.assessments = assessments;
      state.assessmentToDelete = null;
    },
    deleteAssessmentError: (state, action: PayloadAction<string>) => {
      state.isDeletingAssessment = false;
      state.deletingAssessmentError = action.payload;
    },

    deleteAssessmentResponseRequest: state => {
      state.deleteAssessmentResponseError = undefined;
      state.isDeletingAssessmentResponse = true;
    },
    deleteAssessmentResponseSuccess: state => {
      state.isDeletingAssessmentResponse = false;
    },
    fetchAssessmentRequest: state => {
      state.isFetchingAssessment = true;
    },
    fetchAssessmentSuccess: (state, action: PayloadAction<Assessment>) => {
      state.isFetchingAssessment = false;
      state.currentAssessment = action.payload;
      const index = state.assessments.findIndex(
        a => a.id === action.payload.id
      );
      if (index > NOT_FOUND_INDEX) state.assessments[index] = action.payload;
      else state.assessments.push(action.payload);
    },
    fetchAssessmentFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingAssessment = false;
      state.fetchingAssessmentError = action.payload;
    },
    fetchAssessmentResponseRequest: state => {
      state.isFetchingAssessmentResponse = true;
      state.currentAssessmentResponse = null;
    },
    fetchAssessmentResponseSuccess: (
      state,
      action: PayloadAction<AssessmentResponse>
    ) => {
      state.isFetchingAssessmentResponse = false;
      state.currentAssessmentResponse = action.payload;
      const index = state.assessmentResponses.findIndex(
        ar => ar.id === action.payload.id
      );
      if (index > NOT_FOUND_INDEX)
        state.assessmentResponses[index] = action.payload;
    },
    fetchAssessmentResponseFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingAssessmentResponse = false;
      state.fetchingAssessmentResponseError = action.payload;
    },

    fetchAssessmentTemplateRequest: state => {
      state.isFetchingAssessmentTemplate = true;
      state.currentAssessmentTemplate = null;
    },
    fetchAssessmentTemplateSuccess: (
      state,
      action: PayloadAction<AssessmentTemplateExtended>
    ) => {
      state.isFetchingAssessmentTemplate = false;
      state.currentAssessmentTemplate = action.payload;
      const index = state.assessmentTemplates.findIndex(
        at => at.id === action.payload.id
      );
      if (index > NOT_FOUND_INDEX)
        state.assessmentTemplates[index] = action.payload;
    },
    fetchAssessmentTemplateError: (state, action: PayloadAction<string>) => {
      state.isFetchingAssessmentTemplate = false;
      state.fetchingAssessmentTemplateError = action.payload;
    },

    fetchAssessmentTemplatesRequest: state => {
      state.isFetchingAssessmentTemplates = true;
      state.assessmentTemplates = null;
    },
    fetchAssessmentTemplatesSuccess: (
      state,
      action: PayloadAction<AssessmentTemplateExtended[]>
    ) => {
      state.isFetchingAssessmentTemplates = false;
      state.assessmentTemplates = action.payload;
    },
    fetchAssessmentTemplatesError: (state, action: PayloadAction<string>) => {
      state.isFetchingAssessmentTemplates = false;
      state.fetchingAssessmentTemplatesError = action.payload;
    },
    fetchAssessmentsRequest: state => {
      state.isFetchingAssessments = true;
    },
    fetchAssessmentsSuccess: (
      state,
      action: PayloadAction<[Assessment[], AssessmentResponse[]]>
    ) => {
      const [assessments, assessmentResponses] = action.payload;
      state.isFetchingAssessments = false;
      state.assessments = assessments;
      state.assessmentResponses = assessmentResponses;
    },
    fetchAssessmentsFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingAssessments = false;
      state.fetchAssessmentsError = action.payload;
    },
    hideAddFileOrUrlModal: state => {
      state.isShowingAddFileModal = false;
    },
    hideDeleteAssessmentModal: state => {
      state.isDeleteAssessmentModalShowing = false;
    },
    hideDeleteAssessmentToast: state => {
      state.showDeleteAssessmentToast = false;
    },
    showDeleteAssessmentToast: state => {
      state.showDeleteAssessmentToast = true;
    },
    showDeleteAssessmentModal: (state, action: PayloadAction<Assessment>) => {
      state.assessmentToDelete = action.payload;
      state.isDeleteAssessmentModalShowing = true;
    },
    fetchIncomingAssessmentResponsesFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.fetchingIncomingAssessmentResponsesError = action.payload;
      state.isFetchingIncomingAssessmentResponses = false;
    },
    fetchIncomingAssessmentResponsesRequest: state => {
      state.fetchingIncomingAssessmentResponsesError = undefined;
      state.isFetchingIncomingAssessmentResponses = true;
    },
    fetchIncomingAssessmentResponsesSuccess: (
      state,
      action: PayloadAction<AssessmentResponse[]>
    ) => {
      state.incomingAssessmentResponses = action.payload;
      state.isFetchingIncomingAssessmentResponses = false;
    },
    fetchAssessmentResponseHistoryRequest: state => {
      state.fetchAssessmentResponseHistoryError = undefined;
      state.isFetchingAssessmentResponseHistory = true;
    },
    cleanupAssessmentHistories: state => {
      state.assessmentResponseHistories = {};
    },
    fetchAssessmentResponseHistorySuccess: (
      state,
      action: PayloadAction<{ id: string; answers: AssessmentResponseAnswer[] }>
    ) => {
      state.assessmentResponseHistories = {
        ...state.assessmentResponseHistories,
        [action.payload.id]: action.payload.answers,
      };
      state.isFetchingAssessmentResponseHistory = false;
    },
    fetchAssessmentResponseHistoryFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.fetchAssessmentResponseHistoryError = action.payload;
      state.isFetchingAssessmentResponseHistory = false;
    },
    hideAssessmentSentToast: state => {
      state.isAssessmentSentToastShowing = false;
    },
    hideConfirmDeleteAssessmentResponseModal: state => {
      state.assessmentResponseForDeletion = undefined;
      state.isShowingConfirmDeleteAssessmentResponse = false;
    },
    hideEditPublishedAssessmentModal: state => {
      state.editPublishedAssessmentResponseId = undefined;
      state.isShowingEditPublishedAssessmentModal = false;
    },
    hideFileOrUrlSuccessfulToast: state => {
      state.isShowingFileOrUrlSuccessfulToast = false;
    },
    hidePublishAssessmentModal: state => {
      state.isShowingPublishAssessmentModal = false;
    },
    hidePublishedAssessmentModal: state => {
      state.isShowingPublishedAssessmentModal = false;
    },
    hideSendAssessmentModal: state => {
      state.isShowingSendAssessmentModal = false;
    },
    initiateSendAssessment: state => {
      state.clearSendAssessmentDetails = true;
      state.sendAssessmentDetails = undefined;
      state.isShowingSendAssessmentModal = true;
      state.sendAssessmentMode = SendAssessmentMode.Send;
    },
    generateAssessment: (state, action: PayloadAction<string>) => {
      state.clearSendAssessmentDetails = true;
      state.generateAssessmentName = action.payload;
      state.isShowingSendAssessmentModal = true;
      state.sendAssessmentDetails = undefined;
      state.sendAssessmentMode = SendAssessmentMode.Generate;
      state.isAssessmentSentToastShowing = false;
    },
    generateAssessmentRequest: state => {
      state.assessmentGenerationInProgress = true;
    },
    generateAssessmentSuccess: (state, action: PayloadAction<Assessment>) => {
      state.generatedAssessmentFromTemplate = action?.payload;
      state.assessmentGenerationInProgress = false;
      state.isAssessmentSentToastShowing = true;
      state.sendAssessmentDetails.id = action?.payload?.id;
      state.currentAssessment = action.payload;
      state.assessments.push(action.payload);
    },
    generateAssessmentError: (state, action: PayloadAction<string>) => {
      state.assessmentGenerationInProgress = false;
      state.assessmentGenerationError = action.payload;
    },
    sendAssessmentFailure: (state, action: PayloadAction<string>) => {
      state.isSendingAssessment = false;
      state.sendAssessmentError = action.payload;
    },
    sendAssessmentRequest: state => {
      state.isSendingAssessment = true;
      state.sendAssessmentError = undefined;
    },
    sendAssessmentSuccess: state => {
      state.isShowingSendAssessmentConfirmationModal = false;
      state.isSendingAssessment = false;
      state.isAssessmentSentToastShowing = true;
    },
    showEditPublishedAssessmentModal: (
      state,
      action: PayloadAction<string>
    ) => {
      state.editPublishedAssessmentResponseId = action.payload;
      state.isShowingEditPublishedAssessmentModal = true;
    },
    showPublishAssessmentModal: state => {
      state.isShowingPublishAssessmentModal = true;
    },
    showPublishedAssessmentModal: state => {
      state.isShowingPublishedAssessmentModal = true;
    },
    reopenAssessmentResponseFailure: (state, action: PayloadAction<string>) => {
      state.isReopeningAssessmentResponse = false;
      state.reopenAssessmentResponseError = action.payload;
    },
    reopenAssessmentResponseRequest: state => {
      state.isReopeningAssessmentResponse = true;
      state.reopenAssessmentResponseError = undefined;
    },
    reopenAssessmentResponseSuccess: (
      state,
      action: PayloadAction<AssessmentResponse>
    ) => {
      state.assessmentResponses[
        state.assessmentResponses.findIndex(ar => ar.id === action.payload.id)
      ] = action.payload;
      state.isReopeningAssessmentResponse = false;
    },
    resendAssessmentFailure: (state, action: PayloadAction<string>) => {
      state.isResendingAssessment = false;
      state.resendAssessmentError = action.payload;
    },
    resendAssessmentRequest: state => {
      state.isResendingAssessment = true;
      state.resendAssessmentError = undefined;
    },
    resendAssessmentSuccess: state => {
      state.isShowingResendAssessmentConfirmationModal = false;
      state.isResendingAssessment = false;
      state.isAssessmentResentToastShowing = true;
    },
    showConfirmDeleteAssessmentResponseModal: (
      state,
      action: PayloadAction<AssessmentRecipient & AssessmentResponse>
    ) => {
      state.assessmentResponseForDeletion = action.payload;
      state.isShowingConfirmDeleteAssessmentResponse = true;
    },
    showResendAssessmentConfirmationModal: (
      state,
      action: PayloadAction<AssessmentRecipient & AssessmentResponse>
    ) => {
      state.isShowingResendAssessmentConfirmationModal = true;
      state.resendRecipient = action.payload;
    },
    hideResendAssessmentConfirmationModal: state => {
      state.isShowingResendAssessmentConfirmationModal = false;
      state.resendRecipient = null;
    },
    hideAssessmentResentToast: state => {
      state.isAssessmentResentToastShowing = false;
    },
    showAssessmentSentToast: state => {
      state.isAssessmentSentToastShowing = true;
    },
    showFileOrUrlSuccessfulToast: state => {
      state.isShowingFileOrUrlSuccessfulToast = true;
    },
    patchAssessmentResponseRequest: state => {
      state.isPatchingAssessmentResponse = true;
      state.patchAssessmentResponseError = undefined;
    },
    patchAssessmentResponseSuccess: (
      state,
      action: PayloadAction<AssessmentResponse>
    ) => {
      state.isPatchingAssessmentResponse = false;
      state.currentAssessmentResponse = action.payload;
      state.assessmentResponses[
        state.assessmentResponses.findIndex(ar => ar.id === action.payload.id)
      ] = action.payload;
    },
    patchAssessmentResponseFailure: (state, action: PayloadAction<string>) => {
      state.isPatchingAssessmentResponse = false;
      state.patchAssessmentResponseError = action.payload;
    },
    publishAssessmentResponseRequest: state => {
      state.isPublishingAssessmentResponse = true;
      state.publishAssessmentResponseError = null;
    },
    publishAssessmentResponseSuccess: (
      state,
      action: PayloadAction<AssessmentResponse>
    ) => {
      state.isPublishingAssessmentResponse = false;
      state.currentAssessmentResponse = action.payload;
      state.assessmentResponses[
        state.assessmentResponses.findIndex(ar => ar.id === action.payload.id)
      ] = action.payload;

      // update assessment as well on client side, if exists
      const assessment = state.assessments.find(
        a => a.id === action.payload.assessmentId
      );

      if (assessment) {
        ++assessment.responsesReceived;

        if (assessment.responsesReceived === assessment.responsesRequested) {
          assessment.status = COMPLETED_TEXT;
          assessment.updatedAt = action.payload.updatedAt;
        }
      }
    },
    publishAssessmentResponseFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isPublishingAssessmentResponse = false;
      state.publishAssessmentResponseError = action.payload;
    },
    uploadFileOrUrlFailure: (state, action: PayloadAction<string>) => {
      state.isUploadingFile = false;
      state.uploadFileError = action.payload;
    },
    uploadFileOrUrlRequest: state => {
      state.isUploadingFile = true;
      state.uploadFileError = undefined;
    },
    uploadFileOrUrlSuccess: (
      state,
      action: PayloadAction<AssessmentResponse>
    ) => {
      state.currentAssessmentResponse = action.payload;
      state.isUploadingFile = false;
    },
    setCurrentTableQuestions: (
      state,
      action: PayloadAction<{ tableQuestions: QuestionType[]; index: number }>
    ) => {
      state.currentTableQuestions = action.payload.tableQuestions;
      state.currentTableAnswerIndex = action.payload.index;
    },
    showEditTableAnswersModal: state => {
      state.isShowingEditTableAnswersModal = true;
    },
    hideEditTableAnswersModal: state => {
      state.isShowingEditTableAnswersModal = false;
    },
    showDeleteTableAnswersModal: state => {
      state.isShowingDeleteTableAnswersModal = true;
    },
    hideDeleteTableAnswersModal: state => {
      state.isShowingDeleteTableAnswersModal = false;
    },
    showAssessmentSummaryExportModal: state => {
      state.isShowingAssessmentSummaryExportModal = true;
      state.exportCancelled = false;
    },
    hideAssessmentSummaryExportModal: state => {
      state.isShowingAssessmentSummaryExportModal = false;
      state.exportCancelled = true;
      state.isExportingAssessmentResponses = false;
    },
    exportAssessmentResponsesRequest: (
      state,
      action: PayloadAction<number>
    ) => {
      state.isExportingAssessmentResponses = true;
      state.exportProgress = action.payload;
    },
    exportAssessmentResponsesSuccess: (
      state,
      action: PayloadAction<AssessmentDownload>
    ) => {
      state.isExportingAssessmentResponses = false;
      state.assessmentDownload = action.payload;
      state.exportProgress = 100;
    },
    exportAssessmentResponsesFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isExportingAssessmentResponses = false;
      state.exportAssessmentResponseError = action.payload;
      state.exportProgress = 0;
    },
    confirmAssessmentResponseRequest: state => {
      state.isConfirmingAssessmentResponse = true;
    },
    confirmAssessmentResponseSuccess: (
      state,
      action: PayloadAction<AssessmentResponse>
    ) => {
      state.isConfirmingAssessmentResponse = false;
      state.currentAssessmentResponse = action.payload;
    },
    confirmAssessmentResponseFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isConfirmingAssessmentResponse = false;
      state.confirmAssessmentResponseFailure = action.payload;
    },
    patchAssessmentRequest: state => {
      state.isPatchingAssessment = true;
      state.patchAssessmentError = undefined;
    },
    patchAssessmentSuccess: (state, action: PayloadAction<Assessment>) => {
      state.isPatchingAssessment = false;
      const assessments = [...state.assessments];
      const index = assessments.findIndex(
        document => document.id === action.payload.id
      );
      if (index === -1) {
        assessments.push(action.payload);
      } else {
        assessments.splice(index, 1, { ...action.payload });
      }
      state.assessments = assessments;
    },
    patchAssessmentFailure: (state, action: PayloadAction<string>) => {
      state.isPatchingAssessment = false;
      state.patchAssessmentError = action.payload;
    },
    saveSelectedAssessmentTab: (
      state,
      action: PayloadAction<AssessmentTabs>
    ) => {
      state.assessmentTabToShow = action.payload;
    },
    updateAnswer: (state, action: PayloadAction<AssessmentResponseAnswer>) => {
      const newAnswer = action.payload;
      const oldAnswer =
        state.currentAssessmentResponse.assessmentResponseAnswers.find(
          a => a.name === newAnswer.name
        );

      if (!oldAnswer) {
        state.currentAssessmentResponse.assessmentResponseAnswers.push({
          value: newAnswer.value,
        });
      } else {
        oldAnswer.value = newAnswer.value;
      }
    },
  },
});

export const {
  addFileAnswer,
  addUrlAnswer,
  backFromSendAssessmentConfirmationModal,
  confirmSendAssessment,
  deleteAssessmentFileOrUrlFailure,
  deleteAssessmentFileOrUrlRequest,
  deleteAssessmentFileOrUrlSuccess,
  deleteAssessmentRecipientFailure,
  deleteAssessmentRecipientRequest,
  deleteAssessmentRecipientSuccess,
  deleteAssessmentResponseFailure,
  deleteAssessmentResponseRequest,
  deleteAssessmentResponseSuccess,
  deleteAssessmentRequest,
  deleteAssessmentSuccess,
  deleteAssessmentError,
  fetchAssessmentFailure,
  fetchAssessmentRequest,
  fetchAssessmentSuccess,
  fetchAssessmentResponseFailure,
  fetchAssessmentResponseRequest,
  fetchAssessmentResponseSuccess,
  fetchAssessmentTemplatesRequest,
  fetchAssessmentTemplatesSuccess,
  fetchAssessmentTemplatesError,
  fetchAssessmentTemplateRequest,
  fetchAssessmentTemplateSuccess,
  fetchAssessmentTemplateError,
  fetchAssessmentsFailure,
  fetchAssessmentsRequest,
  fetchAssessmentsSuccess,
  fetchIncomingAssessmentResponsesFailure,
  fetchIncomingAssessmentResponsesRequest,
  fetchIncomingAssessmentResponsesSuccess,
  generateAndSendAssessment,
  generateAssessmentRequest,
  generateAssessmentSuccess,
  generateAssessmentError,
  hideAddFileOrUrlModal,
  hideDeleteAssessmentModal,
  hideDeleteAssessmentToast,
  hideAssessmentSentToast,
  hideAssessmentResentToast,
  hideConfirmDeleteAssessmentResponseModal,
  hideEditPublishedAssessmentModal,
  hideFileOrUrlSuccessfulToast,
  hideSendAssessmentModal,
  hidePublishAssessmentModal,
  hidePublishedAssessmentModal,
  initiateSendAssessment,
  reopenAssessmentResponseFailure,
  reopenAssessmentResponseRequest,
  reopenAssessmentResponseSuccess,
  sendAssessmentFailure,
  sendAssessmentRequest,
  sendAssessmentSuccess,
  showAssessmentSentToast,
  showConfirmDeleteAssessmentResponseModal,
  showDeleteAssessmentModal,
  showEditPublishedAssessmentModal,
  showFileOrUrlSuccessfulToast,
  showPublishAssessmentModal,
  showPublishedAssessmentModal,
  patchAssessmentResponseRequest,
  patchAssessmentResponseSuccess,
  patchAssessmentResponseFailure,
  uploadFileOrUrlFailure,
  uploadFileOrUrlRequest,
  uploadFileOrUrlSuccess,
  resendAssessmentFailure,
  resendAssessmentRequest,
  resendAssessmentSuccess,
  showResendAssessmentConfirmationModal,
  hideResendAssessmentConfirmationModal,
  setCurrentTableQuestions,
  showEditTableAnswersModal,
  showDeleteAssessmentToast,
  hideEditTableAnswersModal,
  showDeleteTableAnswersModal,
  hideDeleteTableAnswersModal,
  showAssessmentSummaryExportModal,
  hideAssessmentSummaryExportModal,
  exportAssessmentResponsesRequest,
  exportAssessmentResponsesSuccess,
  exportAssessmentResponsesFailure,
  fetchAssessmentResponseHistoryRequest,
  fetchAssessmentResponseHistorySuccess,
  fetchAssessmentResponseHistoryFailure,
  confirmAssessmentResponseRequest,
  confirmAssessmentResponseSuccess,
  confirmAssessmentResponseFailure,
  publishAssessmentResponseRequest,
  publishAssessmentResponseSuccess,
  publishAssessmentResponseFailure,
  patchAssessmentRequest,
  patchAssessmentSuccess,
  patchAssessmentFailure,
  saveSelectedAssessmentTab,
  generateAssessment,
  updateAnswer,
  cleanupAssessmentHistories,
} = assessmentsSlice.actions;

export default assessmentsSlice.reducer;
