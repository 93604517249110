import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CanvasDraw from 'react-canvas-draw';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import AdoptechModal from '../../AdoptechModal/AdoptechModal';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import {
  fetchAgreements,
  fetchAgreementSignatoriesById,
  signAgreement,
  SignAgreementArguments,
} from '../../../store/agreements/agreementsThunks';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { fetchUserSignature } from '../../../store/user/userThunks';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import './SignAgreementModal.scss';
import { faUndo } from '@fortawesome/pro-light-svg-icons/faUndo';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import {
  showGlobalToast,
  closePdfPreview,
} from '../../../store/global/globalSlice';
import { DataURIToBlob } from '../../../functions/b64ToBlob';
import { getCurrentSignatoryByUserEmail } from '../../../functions/getCurrentSignatoryByUserEmail';
import { agreementsPageRoute } from '../../Routes/Routes';
import { clearCurrentAgreementSignatories } from '../../../store/agreements/agreementsSlice';
import { cleanupUserSignature } from '../../../store/user/userSlice';

interface SignAgreementModalProps {
  onClose: () => void;
}
const SignAgreementModal: React.FC<SignAgreementModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const canvasRef = useRef<CanvasDraw>();
  const isSigningAgreement = useSelector(
    (state: ApplicationState) => state.agreements.isSigningAgreement
  );
  const userSignature = useSelector(
    (state: ApplicationState) => state.user.userSignature
  );
  const isRequestingUserSignature = useSelector(
    (state: ApplicationState) => state.user.isRequestingUserSignature
  );

  const isRequestingCurrentAgreement = useSelector(
    (state: ApplicationState) => state.agreements.isRequestingCurrentAgreement
  );
  const currentVendor = useSelector(selectCurrentVendor);
  const currentUser = useSelector(
    (state: ApplicationState) => state.user.userDetails
  );
  const currentSignatories = useSelector(
    (state: ApplicationState) => state.agreements.currentAgreementSignatories
  );
  const currentSignatoryId = getCurrentSignatoryByUserEmail(
    currentSignatories,
    currentUser.email
  )?.id;

  const [isEditingSignature, setIsEditingSignature] = useState(true);
  const [isSignatureDrawn, setIsSignatureDrawn] = useState(false);

  const imageUrl = userSignature?.urls?.full;
  const isSignButtonDisabled = Boolean(
    !currentSignatoryId ||
      isRequestingUserSignature ||
      (isEditingSignature && !isSignatureDrawn) ||
      (!isEditingSignature && !imageUrl)
  );
  const agreement = useSelector(
    (state: ApplicationState) => state.agreements.currentAgreement
  );
  useEffect(() => {
    dispatch(fetchAgreementSignatoriesById(agreement.id));
    dispatch(fetchUserSignature(currentUser.id));

    return () => {
      dispatch(clearCurrentAgreementSignatories());
      dispatch(cleanupUserSignature());
    };
  }, []);

  useEffect(() => {
    if (imageUrl) {
      setIsEditingSignature(false);
    }
  }, [imageUrl]);

  /**
   * We need to put white bg on the canvas before exporting it to image
   * because otherwise the bg will be black
   */
  const getImage = () => {
    const canvasElement: HTMLCanvasElement = (canvasRef.current as any)
      .canvasContainer.childNodes[1];
    const context = canvasElement.getContext('2d');
    const width = canvasElement.width;
    const height = canvasElement.height;

    //get the current ImageData for the canvas.
    const data = context.getImageData(0, 0, width, height);
    //store the signature that has ben drawn
    const compositeOperation = context.globalCompositeOperation;
    //set to draw behind current content
    context.globalCompositeOperation = 'destination-over';
    //set background color
    context.fillStyle = '#fff';
    //draw background
    context.fillRect(0, 0, width, height);
    //get the image data from the canvas
    const imageData = canvasElement.toDataURL('image/jpeg');
    //clear the canvas
    context.clearRect(0, 0, width, height);
    //restore it with original ImageData
    context.putImageData(data, 0, 0);
    //reset the signature to what it was
    context.globalCompositeOperation = compositeOperation;
    //return the Base64 encoded data url string
    return imageData;
  };

  const handleSign = () => {
    const signAgreementArguments: SignAgreementArguments = {
      signatoryId: currentSignatoryId,
      onSuccess: () => {
        handleClose();
        dispatch(closePdfPreview());
        dispatch(push(agreementsPageRoute));
        dispatch(fetchAgreements(currentVendor.id, false));
        dispatch(showGlobalToast('The agreement has been signed.'));
      },
    };
    if (isEditingSignature) {
      signAgreementArguments.userId = currentUser.id;
      signAgreementArguments.image = DataURIToBlob(getImage());
    }
    dispatch(signAgreement(signAgreementArguments));
  };

  const handleClose = () => {
    onClose();
  };

  const baseCss = 'signAgreementModal';

  return (
    <AdoptechModal className={baseCss} onHide={handleClose} show>
      <Modal.Header>Sign agreement</Modal.Header>
      <Modal.Body>
        {isRequestingCurrentAgreement || isRequestingUserSignature ? (
          <LoadingSpinner />
        ) : currentSignatoryId ? (
          <div>
            <div className={baseCss + '--mainText'}>
              By clicking on SIGN you are confirming you have read and
              understood this agreement. I agree for my e-signature below to be
              a valid means of signing.
            </div>
            <div>
              {isEditingSignature ? (
                <div>
                  <div className="signAgreementModal--canvasText">
                    Use your mouse to draw your signature here. Your signature
                    will be saved for future use.
                  </div>
                  <div className={baseCss + '--canvasWrapper'}>
                    <div
                      className="signAgreementModal--actionBtn"
                      onClick={() => {
                        canvasRef.current.clear();
                        setIsSignatureDrawn(false);
                      }}
                    >
                      <FontAwesomeIcon
                        className="agreementSummaryTile--previewIcon"
                        icon={faUndo}
                      />
                    </div>
                    <div className="signAgreementModal--canvasContainer">
                      <CanvasDraw
                        backgroundColor="#f2f2f2"
                        lazyRadius={3}
                        onChange={(canvas: CanvasDraw) => {
                          setIsSignatureDrawn(
                            JSON.parse(canvas.getSaveData())?.lines?.length
                          );
                        }}
                        hideInterface
                        hideGrid
                        brushRadius={1}
                        canvasHeight={137}
                        canvasWidth={418}
                        ref={canvasRef}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className={baseCss + '--imageContainer'}>
                  <div
                    className="signAgreementModal--actionBtn signAgreementModal--actionBtnAlt"
                    onClick={() => setIsEditingSignature(true)}
                  >
                    <FontAwesomeIcon
                      className="agreementSummaryTile--previewIcon"
                      icon={faPen}
                    />
                  </div>
                  <img src={imageUrl} alt="signature" />
                </div>
              )}
            </div>
          </div>
        ) : (
          'No current signatory. Please send agreement for the review to the current user'
        )}
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={handleClose}>Cancel</AdoptechButton>
        <AdoptechButton
          busy={isSigningAgreement}
          disabled={isSignButtonDisabled}
          onClick={handleSign}
          variant={AdoptechButtonVariant.Primary}
        >
          Sign
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};

export default SignAgreementModal;
