/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CompanyIdentifierModel
 */
export interface CompanyIdentifierModel {
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierModel
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierModel
   */
  type: CompanyIdentifierModelTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierModel
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierModel
   */
  country: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierModel
   */
  updatedAt?: string;
}

export function CompanyIdentifierModelFromJSON(
  json: any
): CompanyIdentifierModel {
  return CompanyIdentifierModelFromJSONTyped(json, false);
}

export function CompanyIdentifierModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyIdentifierModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    identifier: json['identifier'],
    type: json['type'],
    displayName: json['display_name'],
    country: json['country'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function CompanyIdentifierModelToJSON(
  value?: CompanyIdentifierModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    identifier: value.identifier,
    type: value.type,
    display_name: value.displayName,
    country: value.country,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CompanyIdentifierModelTypeEnum {
  Ukcrn = 'CompanyIdentifier::Ukcrn',
  Ein = 'CompanyIdentifier::Ein',
  Srn = 'CompanyIdentifier::Srn',
  Siren = 'CompanyIdentifier::Siren',
  Siret = 'CompanyIdentifier::Siret',
  Other = 'CompanyIdentifier::Other',
}
