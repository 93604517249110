/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssertionResultExtendedAllOf,
  AssertionResultExtendedAllOfFromJSON,
  AssertionResultExtendedAllOfFromJSONTyped,
  AssertionResultExtendedAllOfToJSON,
  AssertionResultModel,
  AssertionResultModelFromJSON,
  AssertionResultModelFromJSONTyped,
  AssertionResultModelToJSON,
  SharedAttachmentModel,
  SharedAttachmentModelFromJSON,
  SharedAttachmentModelFromJSONTyped,
  SharedAttachmentModelToJSON,
} from './';

/**
 *
 * @export
 * @interface AssertionResultExtended
 */
export interface AssertionResultExtended extends AssertionResultModel {
  /**
   *
   * @type {Array<SharedAttachmentModel>}
   * @memberof AssertionResultExtended
   */
  evidences?: Array<SharedAttachmentModel>;
}

export function AssertionResultExtendedFromJSON(
  json: any
): AssertionResultExtended {
  return AssertionResultExtendedFromJSONTyped(json, false);
}

export function AssertionResultExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionResultExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...AssertionResultModelFromJSONTyped(json, ignoreDiscriminator),
    evidences: !exists(json, 'evidences')
      ? undefined
      : (json['evidences'] as Array<any>).map(SharedAttachmentModelFromJSON),
  };
}

export function AssertionResultExtendedToJSON(
  value?: AssertionResultExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...AssertionResultModelToJSON(value),
    evidences:
      value.evidences === undefined
        ? undefined
        : (value.evidences as Array<any>).map(SharedAttachmentModelToJSON),
  };
}
