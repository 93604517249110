import React from 'react';
import { useSelector } from 'react-redux';
import { selectPendingAssessments } from '../../selectors/selectPendingAssessments';
import { Intent, Lozenge } from '../Lozenge/Lozenge';
import { NoDataText } from '../NoDataText/NoDataText';
import { PendingAssessmentsGrid } from '../PendingAssessmentsGrid/PendingAssessmentsGrid';
import './PendingAssessments.scss';

export const PendingAssessments: React.FC = () => {
  const assessmentResponses = useSelector(selectPendingAssessments);

  return (
    <div className="pendingAssessments">
      <div className="pendingAssessments--header">
        <div className="pendingAssessments--title">
          Awaiting our response
          <Lozenge intent={Intent.None} value={assessmentResponses.length} />
        </div>
      </div>
      {assessmentResponses.length > 0 ? (
        <PendingAssessmentsGrid />
      ) : (
        <NoDataText text="Assessments that you have been asked to respond to will appear here" />
      )}
    </div>
  );
};
