/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AgreementSignatoryNotifyPayload
 */
export interface AgreementSignatoryNotifyPayload {
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryNotifyPayload
   */
  message?: string | null;
}

export function AgreementSignatoryNotifyPayloadFromJSON(
  json: any
): AgreementSignatoryNotifyPayload {
  return AgreementSignatoryNotifyPayloadFromJSONTyped(json, false);
}

export function AgreementSignatoryNotifyPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementSignatoryNotifyPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: !exists(json, 'message') ? undefined : json['message'],
  };
}

export function AgreementSignatoryNotifyPayloadToJSON(
  value?: AgreementSignatoryNotifyPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
  };
}
