import './RiskRegistryPage.scss';
import exclamationIcon from 'images/icons/exclamationIcon.svg';
import checkmarkIcon from 'images/icons/checkmarkIcon.svg';

import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';

import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Lozenge } from '../../Lozenge/Lozenge';
import { NoDataText } from '../../NoDataText/NoDataText';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import { riskRegistryRoute, riskRegistryStartRoute } from '../../Routes/Routes';
import { fetchRisks } from '../../../store/riskRegistry/riskRegistryThunks';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import AdoptechGridTable from '../../AdoptechGridTable/AdoptechGridTable';
import { SortableTableHeader } from '../../SortableTableHeader/SortableTableHeader';
import { Grid } from '../../../types/grid';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { selectRisks } from '../../../selectors/riskRegistry/selectRisks';
import {
  RiskInfo,
  VendorRegisterRiskProfile,
} from '../../VendorRegisterRiskProfile/VendorRegisterRiskProfile';
import { getPestelRiskText } from '../../pestel/PestelSection/PestelRow';
import { capitalize } from '../../../functions/capitalize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocalStorage } from 'react-use';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { AdoptechTextInput } from '../../AdoptechTextInput/AdoptechTextInput';
import { RiskModel } from '../../../swagger';
import { useCanFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import classNames from 'classnames';
import {
  reportsRouteWithAddType,
  reportsRouteWithType,
} from '../../Reports/ReportsPage/ReportsPage';
import { Dropdown } from 'react-bootstrap';
import { AdoptechDropdownToggle } from '../../AdoptechDropdownToggle/AdoptechDropdownToggle';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { articles } from '../../../functions/chatWidgetHelper';

const riskStatus = (status: RiskModel['status']): string => {
  return status
    .split('_')
    .map((word: string) => capitalize(word))
    .join(' ');
};

const RisksList: React.FC<{
  items: RiskModel[];
  onClick(risk: RiskModel): void;
}> = ({ items, onClick }) => {
  const canUpdateRisks = useCanFeature(AccessObject.risk_register_update);
  if (items.length < 1)
    return <NoDataText extraClass="mt-2" text="No risks to show" />;
  const baseCss = 'risksPage';
  const riskInfo = (
    risk: RiskModel,
    type: 'inherent' | 'residual'
  ): RiskInfo => {
    const riskSeverity =
      (risk[`${type}RiskConsequence`] || 1) *
      (risk[`${type}RiskLikelihood`] || 1);
    return {
      riskSeverity,
      riskLevel: getPestelRiskText(riskSeverity),
    };
  };
  return (
    <div className={baseCss + '--7-columns'}>
      <AdoptechGridTable
        header={
          <div className="adoptechGridTable--header">
            <SortableTableHeader<RiskModel>
              columnName="name"
              grid={Grid.Risks}
              label="Risk name"
              notInTable
            />
            <SortableTableHeader<RiskModel>
              columnName="owner"
              grid={Grid.Risks}
              label="Owner"
              notInTable
            />
            <SortableTableHeader<RiskModel>
              columnName="inherentRisk"
              grid={Grid.Risks}
              label="Inherent risk"
              notInTable
            />
            <SortableTableHeader<RiskModel>
              columnName="treatmentStrategy"
              grid={Grid.Risks}
              label="Treatment"
              notInTable
            />
            <SortableTableHeader<RiskModel>
              columnName="actionStats"
              grid={Grid.Risks}
              label="Checks"
              notInTable
            />
            <SortableTableHeader<RiskModel>
              columnName="residualRisk"
              grid={Grid.Risks}
              label="Residual Risk"
              notInTable
            />
            <SortableTableHeader<RiskModel>
              columnName="status"
              grid={Grid.Risks}
              label="Status"
              notInTable
            />
          </div>
        }
      >
        {items.map(risk => {
          const checksCompleted = risk.checkStats.completed || 0;
          const checksCount = risk.checkStats.total || 0;
          const checksIcon =
            checksCount > 0 && checksCompleted === checksCount
              ? checkmarkIcon
              : exclamationIcon;
          return (
            <div
              key={risk.id}
              className={classNames('adoptechGridTable--row', {
                disabled: !canUpdateRisks,
              })}
              onClick={() => (canUpdateRisks ? onClick(risk) : null)}
            >
              <div className={baseCss + '--longName'}>{risk.name}</div>
              {risk.owner ? (
                <UserAvatar size="tag" user={risk.owner} showTooltip hideText />
              ) : (
                <div>-</div>
              )}
              <div>
                <VendorRegisterRiskProfile
                  riskInfo={riskInfo(risk, 'inherent')}
                  scale={25}
                />
              </div>
              <div>{capitalize(risk.treatmentStrategy) || '-'}</div>
              <div>
                {checksCount > 0 ? (
                  <>
                    <img src={checksIcon} className="mr-2" />
                    {checksCompleted}/{checksCount}
                  </>
                ) : (
                  '-'
                )}
              </div>
              <div>
                <VendorRegisterRiskProfile
                  riskInfo={riskInfo(risk, 'residual')}
                  scale={25}
                />
              </div>
              <div>{riskStatus(risk.status)}</div>
            </div>
          );
        })}
      </AdoptechGridTable>
    </div>
  );
};

const PageHint = () => {
  const baseCss = 'pageHint';
  const vendorUser = useSelector(selectCurrentVendorUser);
  const localStorageKey = `risk-registry-${vendorUser.id}`;
  const [hidden, setHidden] = useLocalStorage(localStorageKey, false);
  if (hidden) return null;

  return (
    <div className={baseCss}>
      <FontAwesomeIcon icon={faCircleInfo} />
      {/* <a target="_blank" href={portalRoutes.home} rel="noreferrer">
        <img src={videoPlaceholder} />
      </a> */}
      <div className={baseCss + '--right-block'}>
        <div className={baseCss + 'description'}>
          <p>
            The risk management process aims to continuously identify the
            information security risks your company faces, assess those risks
            and take actions to treat them. The risk management process is
            aligned with the internationally recognised ISO 31000 standard.
          </p>

          <p>
            For more information on completing the risk register have a read of{' '}
            <a href={articles.risk} target="_blank" rel="noreferrer">
              this article
            </a>
          </p>
          {/* uncomment for video */}
          {/* <p>
            Watch our short introduction video to see how to use the risk
            register and learn about its comprehensive features.
          </p> */}
        </div>
        <div className={baseCss + '--footer'}>
          {/* <div>
            This video can also be found by searching for{' '}
            <b>Risk Management Process</b> in the portal help/Intercom.
          </div> */}
          <AdoptechButton
            onClick={() => setHidden(true)}
            variant={AdoptechButtonVariant.White}
          >
            Got it
          </AdoptechButton>
        </div>
      </div>
    </div>
  );
};

export const RiskRegistryPage: React.FC = () => {
  const baseCss = 'risksPage';

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );
  const vendorUser = useSelector(selectCurrentVendorUser);
  const [searchText, setSearchText] = useState<string>('');

  const filteredRisks: RiskModel[] = useSelector(
    selectRisks(searchText, [
      'name',
      'treatment',
      'status',
      'owner.email',
      'owner.fullName',
    ])
  );
  const canCreateRisks = useCanFeature(AccessObject.risk_register_create);

  const { isFetchingRisks, risks } = useSelector(
    (state: ApplicationState) => state.riskRegistry
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRisks(vendorId));
  }, [vendorId, vendorUser.roles]);

  const canManageReports = useCanFeature(AccessObject.reports_manage);
  const canViewReports = useCanFeature(AccessObject.reports);
  if (isFetchingRisks) {
    return <LoadingSpinner />;
  }

  const handleRowClick = (risk: RiskModel) => {
    dispatch(push(riskRegistryRoute + '/' + risk.id));
  };

  return (
    <div className={baseCss}>
      <div className={baseCss + '--header'}>
        <div className={baseCss + '--title'}>
          Risks
          <Lozenge value={risks.length} />
        </div>
        <div className={baseCss + '--search'}>
          <AdoptechTextInput
            id="search"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            type="text"
            placeholder="Search for risks"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>
        <div className={baseCss + '--headerButtons'}>
          {canViewReports && (
            <Dropdown className={baseCss + '--reportsButton'}>
              <Dropdown.Toggle as={AdoptechDropdownToggle}>
                <AdoptechButton
                  variant={AdoptechButtonVariant.White}
                  icon={faEye}
                  iconSize="large"
                  rounded
                  extraClass={`${baseCss}--reports-btn`}
                >
                  Reports
                </AdoptechButton>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {canManageReports && (
                  <Dropdown.Item
                    onClick={() =>
                      dispatch(push(reportsRouteWithAddType('risk')))
                    }
                  >
                    Add report
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() => dispatch(push(reportsRouteWithType('risk')))}
                >
                  View reports
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {canCreateRisks && (
            <AdoptechButton
              onClick={() => dispatch(push(riskRegistryStartRoute))}
              variant={AdoptechButtonVariant.PrimaryOrange}
              icon={faPlus}
              iconSize="large"
              rounded
            >
              Add risk
            </AdoptechButton>
          )}
        </div>
      </div>
      <PageHint />
      <RisksList items={filteredRisks} onClick={handleRowClick} />
    </div>
  );
};
