/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ControlUpdatePayloadControl,
  ControlUpdatePayloadControlFromJSON,
  ControlUpdatePayloadControlFromJSONTyped,
  ControlUpdatePayloadControlToJSON,
} from './';

/**
 *
 * @export
 * @interface ControlUpdatePayload
 */
export interface ControlUpdatePayload {
  /**
   *
   * @type {ControlUpdatePayloadControl}
   * @memberof ControlUpdatePayload
   */
  control?: ControlUpdatePayloadControl;
}

export function ControlUpdatePayloadFromJSON(json: any): ControlUpdatePayload {
  return ControlUpdatePayloadFromJSONTyped(json, false);
}

export function ControlUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    control: !exists(json, 'control')
      ? undefined
      : ControlUpdatePayloadControlFromJSON(json['control']),
  };
}

export function ControlUpdatePayloadToJSON(
  value?: ControlUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    control: ControlUpdatePayloadControlToJSON(value.control),
  };
}
