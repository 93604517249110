/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  FrameworkModelStatsControls,
  FrameworkModelStatsControlsFromJSON,
  FrameworkModelStatsControlsFromJSONTyped,
  FrameworkModelStatsControlsToJSON,
  FrameworkModelStatsPolicies,
  FrameworkModelStatsPoliciesFromJSON,
  FrameworkModelStatsPoliciesFromJSONTyped,
  FrameworkModelStatsPoliciesToJSON,
  FrameworkModelStatsTasks,
  FrameworkModelStatsTasksFromJSON,
  FrameworkModelStatsTasksFromJSONTyped,
  FrameworkModelStatsTasksToJSON,
} from './';

/**
 *
 * @export
 * @interface FrameworkModelStats
 */
export interface FrameworkModelStats {
  /**
   *
   * @type {FrameworkModelStatsPolicies}
   * @memberof FrameworkModelStats
   */
  policies?: FrameworkModelStatsPolicies;
  /**
   *
   * @type {FrameworkModelStatsTasks}
   * @memberof FrameworkModelStats
   */
  tasks?: FrameworkModelStatsTasks;
  /**
   *
   * @type {FrameworkModelStatsControls}
   * @memberof FrameworkModelStats
   */
  controls?: FrameworkModelStatsControls;
  /**
   *
   * @type {FrameworkModelStatsControls}
   * @memberof FrameworkModelStats
   */
  requirements?: FrameworkModelStatsControls;
}

export function FrameworkModelStatsFromJSON(json: any): FrameworkModelStats {
  return FrameworkModelStatsFromJSONTyped(json, false);
}

export function FrameworkModelStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkModelStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    policies: !exists(json, 'policies')
      ? undefined
      : FrameworkModelStatsPoliciesFromJSON(json['policies']),
    tasks: !exists(json, 'tasks')
      ? undefined
      : FrameworkModelStatsTasksFromJSON(json['tasks']),
    controls: !exists(json, 'controls')
      ? undefined
      : FrameworkModelStatsControlsFromJSON(json['controls']),
    requirements: !exists(json, 'requirements')
      ? undefined
      : FrameworkModelStatsControlsFromJSON(json['requirements']),
  };
}

export function FrameworkModelStatsToJSON(
  value?: FrameworkModelStats | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    policies: FrameworkModelStatsPoliciesToJSON(value.policies),
    tasks: FrameworkModelStatsTasksToJSON(value.tasks),
    controls: FrameworkModelStatsControlsToJSON(value.controls),
    requirements: FrameworkModelStatsControlsToJSON(value.requirements),
  };
}
