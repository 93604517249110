import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import './StripeSignupSuccess.scss';
import { fetchAgreements } from '../../../store/agreements/agreementsThunks';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { fetchVendorPoliciesDetails } from '../../../store/policies/policiesThunks';
import { showIsPaymentSuccessfulShowing } from '../../../store/policies/policiesSlice';
import { ApplicationState } from '../../../types/applicationState';
import { push } from 'connected-react-router';
import { PurchaseType } from '../../../types/PurchaseType';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { postFramework } from '../../../store/compliance/complianceThunks';
import { showGlobalToast } from '../../../store/global/globalSlice';
import {
  agreementsPageRoute,
  policiesPageRoute,
} from '../../../components/Routes/Routes';

export const StripeSignupSuccess: React.FC = () => {
  const params = useParams() as {
    purchasableId: string;
  };
  const queryParams = useQueryParams();
  const agreements = useSelector(
    (state: ApplicationState) => state.agreements.agreements
  );
  const vendorPolicies = useSelector(
    (state: ApplicationState) => state.policies.vendorPolicies
  );
  const currentVendor = useSelector(selectCurrentVendor);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!params.purchasableId) {
      dispatch(push('/r/home'));
      return;
    }

    if (queryParams.get('purchasableType') === PurchaseType.FrameworkTemplate) {
      dispatch(
        showGlobalToast('Generating compliance framework. Please wait...')
      );
      dispatch(
        postFramework({
          vendorId: currentVendor?.id,
          templateId: params.purchasableId,
        })
      );
      dispatch(showIsPaymentSuccessfulShowing());
      return;
    }

    dispatch(fetchAgreements(currentVendor.id));
    dispatch(fetchVendorPoliciesDetails());
    dispatch(showIsPaymentSuccessfulShowing());
  }, []);

  useEffect(() => {
    // TODO: remove code below if we will remove one-time payments backend
    if (!agreements.length && !vendorPolicies.length) {
      return;
    }

    if (
      agreements.findIndex(
        a => a.agreementTemplateId === params.purchasableId
      ) > -1
    ) {
      dispatch(push(agreementsPageRoute));
      return;
    }

    if (
      vendorPolicies.findIndex(p => p.policyId === params.purchasableId) > -1
    ) {
      dispatch(push(policiesPageRoute));
      return;
    }

    dispatch(push('/r/home'));
  }, [agreements, vendorPolicies]);

  return (
    <div className="stripeSignupSuccess">
      <LoadingSpinner />
    </div>
  );
};
