/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ControlUpdateStatusPayloadControl
 */
export interface ControlUpdateStatusPayloadControl {
  /**
   *
   * @type {boolean}
   * @memberof ControlUpdateStatusPayloadControl
   */
  applicable: boolean;
  /**
   *
   * @type {string}
   * @memberof ControlUpdateStatusPayloadControl
   */
  reasonForExclusion?: string | null;
}

export function ControlUpdateStatusPayloadControlFromJSON(
  json: any
): ControlUpdateStatusPayloadControl {
  return ControlUpdateStatusPayloadControlFromJSONTyped(json, false);
}

export function ControlUpdateStatusPayloadControlFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlUpdateStatusPayloadControl {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    applicable: json['applicable'],
    reasonForExclusion: !exists(json, 'reason_for_exclusion')
      ? undefined
      : json['reason_for_exclusion'],
  };
}

export function ControlUpdateStatusPayloadControlToJSON(
  value?: ControlUpdateStatusPayloadControl | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    applicable: value.applicable,
    reason_for_exclusion: value.reasonForExclusion,
  };
}
