import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFileCsv } from '@fortawesome/pro-light-svg-icons/faFileCsv';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faFileSpreadsheet } from '@fortawesome/pro-light-svg-icons/faFileSpreadsheet';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faQuestionSquare } from '@fortawesome/pro-light-svg-icons/faQuestionSquare';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteAssessmentFileOrUrl } from '../../store/assessments/assessmentsThunks';
import { AssessmentResponseAnswer, Question } from '../../swagger';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import '../AdoptechTable/AdoptechTable.scss';
import { GenericModal } from '../GenericModal/GenericModal';
import { IconButton } from '../IconButton/IconButton';
import './FileSelectAnswerGrid.scss';

export interface FileSelectAnswerGridProps {
  answers: AssessmentResponseAnswer[];
  question: Question;
  readonly?: boolean;
}

export const FileSelectAnswerGrid: React.FC<
  FileSelectAnswerGridProps
> = props => {
  const [deletingId, setDeletingId] = useState('');

  const answer = props.answers.find(ara => ara.name === props.question.name);

  const hasFiles =
    answer &&
    answer.assessmentResponseAnswerFiles &&
    answer.assessmentResponseAnswerFiles.length > 0;

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <div className="fileSelectAnswerGrid">
        <AdoptechTable embedded>
          <thead>
            <tr>
              <th>Description</th>
              <th>Link</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {hasFiles &&
              answer.assessmentResponseAnswerFiles.map(file => {
                let fileTypeIcon: IconProp;

                switch (file.contentType) {
                  case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    fileTypeIcon = faFileSpreadsheet;
                    break;
                  case 'application/vnd.ms-excel':
                  case 'application/vnd.ms-excel.sheet.macroenabled.12':
                    fileTypeIcon = faFileExcel;
                    break;
                  case 'application/pdf':
                    fileTypeIcon = faFilePdf;
                    break;
                  case 'text/csv':
                    fileTypeIcon = faFileCsv;
                    break;
                  default:
                    fileTypeIcon = faQuestionSquare;
                }

                return (
                  <tr key={file.id}>
                    <td>{file.name}</td>
                    <td className="fileSelectAnswerGrid--icon">
                      {file.document && (
                        <a href={file.document}>
                          <FontAwesomeIcon icon={fileTypeIcon} />
                        </a>
                      )}
                      {file.url && (
                        <a href={file.url} target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon={faLink} />
                        </a>
                      )}
                    </td>
                    <td>
                      {!props.readonly && (
                        <IconButton
                          ariaLabel="Delete"
                          icon={faTrashAlt}
                          onClick={() => setDeletingId(file.id)}
                          tooltip="Delete"
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            {!hasFiles && (
              <tr>
                <td colSpan={4} className="fileSelectAnswerGrid--row-centered">
                  No files provided
                </td>
              </tr>
            )}
          </tbody>
        </AdoptechTable>
      </div>

      <GenericModal
        closeButtonLabel="CONFIRM"
        onCancel={() => setDeletingId('')}
        onClose={() => {
          dispatch(deleteAssessmentFileOrUrl(deletingId));
          setDeletingId('');
        }}
        show={deletingId !== ''}
        showCancelButton
        title="Confirm delete?"
      >
        <div>Click CONFIRM to permanently delete this file or URL.</div>
      </GenericModal>
    </React.Fragment>
  );
};
