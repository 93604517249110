import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { calendarState } from './calendarState';
import moment from 'moment';
import { CalendarItemList } from '../../../swagger/models/CalendarItemList';
import {
  createCorrectiveAction,
  createEvent,
  createIncident,
  createNewAction,
  deleteAction,
  deleteCorrectiveAction,
  deleteEvent,
  deleteIncident,
  downloadActionAttachment,
  downloadCorrectiveActionAttachment,
  downloadEventAttachment,
  downloadIncidentAttachment,
  fetchAction,
  fetchCalendarItems,
  fetchCorrectiveAction,
  fetchEvent,
  fetchIncident,
  updateCorrectiveAction,
  updateIncident,
} from './calendarThunks';

const initialState = {
  items: [],
  filters: {
    viewMode: 'actions_and_events',
    // store dates as strings to fix serializable redux issue
    startPeriod: moment().startOf('day').toJSON(),
    endPeriod: moment().add(3, 'months').endOf('day').toJSON(),
    showMyItems: false,
    showCompleted: true,
    page: 1,
    totalPages: null,
  },
  isFetchingVendorActions: false,
  isUpdatingCalendarItem: false,
  downloadEventAttachmentStatus: 'idle',
  downloadActionAttachmentStatus: 'idle',
  downloadCorrectiveActionAttachmentStatus: 'idle',
  downloadIncidentAttachmentStatus: 'idle',
  fetchActionStatus: 'idle',
  fetchVendorEventStatus: 'idle',
  deleteActionStatus: 'idle',
  deleteEventStatus: 'idle',
  createEventStatus: 'idle',
  createCorrectiveActionStatus: 'idle',
  deleteCorrectiveActionStatus: 'idle',
  updateCorrectiveActionStatus: 'idle',
  fetchCorrectiveActionStatus: 'idle',
  createIncidentStatus: 'idle',
  deleteIncidentStatus: 'idle',
  updateIncidentStatus: 'idle',
  fetchIncidentStatus: 'idle',
} as calendarState;

const calendarSlice = createSlice({
  initialState,
  name: 'calendarSlice',
  reducers: {
    updateCalendarItemSuccess: state => {
      state.isUpdatingCalendarItem = false;
    },
    updateCalendarItemFailure: (state, _) => {
      state.isUpdatingCalendarItem = false;
    },
    updateCalendarItemRequest: state => {
      state.isUpdatingCalendarItem = true;
    },
    cleanupItems: state => {
      state.items = [];
    },

    fetchVendorActionsFailure: (state, _) => {
      state.isFetchingVendorActions = false;
    },
    fetchVendorActionsRequest: state => {
      state.isFetchingVendorActions = true;
    },
    fetchVendorActionsSuccess: state => {
      state.isFetchingVendorActions = false;
    },

    setCalendarFilters: (
      state,
      action: PayloadAction<calendarState['filters']>
    ) => {
      state.filters = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(downloadEventAttachment.pending, state => {
        state.downloadEventAttachmentStatus = 'loading';
      })
      .addCase(downloadEventAttachment.fulfilled, state => {
        state.downloadEventAttachmentStatus = 'succeeded';
      })
      .addCase(downloadEventAttachment.rejected, state => {
        state.downloadEventAttachmentStatus = 'failed';
      })
      .addCase(downloadActionAttachment.pending, state => {
        state.downloadActionAttachmentStatus = 'loading';
      })
      .addCase(downloadActionAttachment.fulfilled, state => {
        state.downloadActionAttachmentStatus = 'succeeded';
      })
      .addCase(downloadActionAttachment.rejected, state => {
        state.downloadActionAttachmentStatus = 'failed';
      })
      .addCase(fetchAction.pending, state => {
        state.fetchActionStatus = 'loading';
      })
      .addCase(fetchAction.fulfilled, state => {
        state.fetchActionStatus = 'succeeded';
      })
      .addCase(fetchAction.rejected, state => {
        state.fetchActionStatus = 'failed';
      })
      .addCase(fetchEvent.pending, state => {
        state.fetchVendorEventStatus = 'loading';
      })
      .addCase(fetchEvent.fulfilled, state => {
        state.fetchVendorEventStatus = 'succeeded';
      })
      .addCase(fetchEvent.rejected, state => {
        state.fetchVendorEventStatus = 'failed';
      })
      .addCase(deleteEvent.pending, state => {
        state.deleteEventStatus = 'loading';
      })
      .addCase(deleteEvent.fulfilled, state => {
        state.deleteEventStatus = 'succeeded';
      })
      .addCase(deleteEvent.rejected, state => {
        state.deleteEventStatus = 'failed';
      })
      .addCase(deleteAction.pending, state => {
        state.deleteActionStatus = 'loading';
      })
      .addCase(deleteAction.fulfilled, state => {
        state.deleteActionStatus = 'succeeded';
      })
      .addCase(deleteAction.rejected, state => {
        state.deleteActionStatus = 'failed';
      })
      .addCase(createEvent.pending, state => {
        state.createEventStatus = 'loading';
      })
      .addCase(createEvent.fulfilled, state => {
        state.createEventStatus = 'succeeded';
      })
      .addCase(createEvent.rejected, state => {
        state.createEventStatus = 'failed';
      })
      .addCase(createNewAction.pending, state => {
        state.createActionStatus = 'loading';
      })
      .addCase(createNewAction.fulfilled, state => {
        state.createActionStatus = 'succeeded';
      })
      .addCase(createNewAction.rejected, state => {
        state.createActionStatus = 'failed';
      })
      .addCase(fetchCalendarItems.pending, state => {
        state.fetchCalendarItemsStatus = 'loading';
      })
      .addCase(fetchCalendarItems.fulfilled, (state, action) => {
        state.fetchCalendarItemsStatus = 'succeeded';

        if (action.payload.meta.page === 1) {
          state.items = action.payload.data;
          state.filters = {
            ...state.filters,
            ...{ totalPages: action.payload.meta.totalPages },
          };
        } else {
          const newItems = action.payload.data.filter(
            newItem => !state.items.some(item => item.id === newItem.id)
          );
          state.items = state.items.concat(newItems);
        }
      })
      .addCase(createCorrectiveAction.rejected, state => {
        state.createCorrectiveActionStatus = 'failed';
      })
      .addCase(createCorrectiveAction.pending, state => {
        state.createCorrectiveActionStatus = 'loading';
      })
      .addCase(createCorrectiveAction.fulfilled, state => {
        state.createCorrectiveActionStatus = 'succeeded';
      })
      .addCase(deleteCorrectiveAction.rejected, state => {
        state.deleteCorrectiveActionStatus = 'failed';
      })
      .addCase(deleteCorrectiveAction.pending, state => {
        state.deleteCorrectiveActionStatus = 'loading';
      })
      .addCase(deleteCorrectiveAction.fulfilled, state => {
        state.deleteCorrectiveActionStatus = 'succeeded';
      })
      .addCase(fetchCorrectiveAction.pending, state => {
        state.fetchCorrectiveActionStatus = 'loading';
      })
      .addCase(fetchCorrectiveAction.fulfilled, state => {
        state.fetchCorrectiveActionStatus = 'succeeded';
      })
      .addCase(fetchCorrectiveAction.rejected, state => {
        state.fetchCorrectiveActionStatus = 'failed';
      })
      .addCase(updateCorrectiveAction.rejected, state => {
        state.updateCorrectiveActionStatus = 'failed';
      })
      .addCase(updateCorrectiveAction.pending, state => {
        state.updateCorrectiveActionStatus = 'loading';
      })
      .addCase(updateCorrectiveAction.fulfilled, state => {
        state.updateCorrectiveActionStatus = 'succeeded';
      })
      .addCase(createIncident.pending, state => {
        state.createIncidentStatus = 'loading';
      })
      .addCase(createIncident.fulfilled, state => {
        state.createIncidentStatus = 'succeeded';
      })
      .addCase(createIncident.rejected, state => {
        state.createIncidentStatus = 'failed';
      })
      .addCase(deleteIncident.pending, state => {
        state.deleteIncidentStatus = 'loading';
      })
      .addCase(deleteIncident.fulfilled, state => {
        state.deleteIncidentStatus = 'succeeded';
      })
      .addCase(deleteIncident.rejected, state => {
        state.deleteIncidentStatus = 'failed';
      })
      .addCase(updateIncident.pending, state => {
        state.updateIncidentStatus = 'loading';
      })
      .addCase(updateIncident.fulfilled, state => {
        state.updateIncidentStatus = 'succeeded';
      })
      .addCase(updateIncident.rejected, state => {
        state.updateIncidentStatus = 'failed';
      })
      .addCase(fetchIncident.pending, state => {
        state.fetchIncidentStatus = 'loading';
      })
      .addCase(fetchIncident.fulfilled, state => {
        state.fetchIncidentStatus = 'succeeded';
      })
      .addCase(fetchIncident.rejected, state => {
        state.fetchIncidentStatus = 'failed';
      })
      .addCase(downloadCorrectiveActionAttachment.pending, state => {
        state.downloadCorrectiveActionAttachmentStatus = 'loading';
      })
      .addCase(downloadCorrectiveActionAttachment.fulfilled, state => {
        state.downloadCorrectiveActionAttachmentStatus = 'succeeded';
      })
      .addCase(downloadCorrectiveActionAttachment.rejected, state => {
        state.downloadCorrectiveActionAttachmentStatus = 'failed';
      })
      .addCase(downloadIncidentAttachment.pending, state => {
        state.downloadIncidentAttachmentStatus = 'loading';
      })
      .addCase(downloadIncidentAttachment.fulfilled, state => {
        state.downloadIncidentAttachmentStatus = 'succeeded';
      })
      .addCase(downloadIncidentAttachment.rejected, state => {
        state.downloadIncidentAttachmentStatus = 'failed';
      });
  },
});

export const {
  cleanupItems,
  updateCalendarItemSuccess,
  updateCalendarItemFailure,
  updateCalendarItemRequest,
  fetchVendorActionsRequest,
  fetchVendorActionsFailure,
  fetchVendorActionsSuccess,
  setCalendarFilters,
} = calendarSlice.actions;
export default calendarSlice.reducer;
