import React from 'react';
import { ReportForm, ReportSectionProps } from '../ReportsManageDrawer';
import { ReportVersionField } from '../ReportVersionField';
import { ReportOwnerField } from '../ReportOwnerField';
import { ReportSummaryField } from '../ReportSummaryField';
import { AdoptechRadioButtonsGroup } from '../../../AdoptechRadioButtonsGroup/AdoptechRadioButtonsGroup';

export const riskSectionValid = (formData: ReportForm): boolean => {
  const includeUnapprovedRisks =
    formData.includeUnapprovedRisks === false ||
    formData.includeUnapprovedRisks === true;
  return includeUnapprovedRisks && formData.ownerId && formData.summary
    ? true
    : false;
};

export const RiskRegisterSection: React.FC<ReportSectionProps> = ({
  formData,
  updateField,
  updateForm,
}) => {
  const sectionProps = { formData, updateField, updateForm };
  const baseCss = 'reportsManageDrawer';
  return (
    <div>
      <div className={baseCss + '--fieldRow'}>
        <div className={baseCss + '--label'}>
          Do you wish to include or exclude unapproved risks in your report?
        </div>
        <div className={baseCss + '--additionalLabel'}>
          Ideally all risks should be approved following a review and prior to
          generating a report.
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechRadioButtonsGroup
            // value can be null because radio not selected by default
            value={
              formData.includeUnapprovedRisks
                ? 'yes'
                : formData.includeUnapprovedRisks === false
                  ? 'no'
                  : null
            }
            ids={['incomplete1', 'incomplete2']}
            values={['yes', 'no']}
            labels={['Yes', 'No']}
            onChange={(value: 'yes' | 'no') => {
              updateField('includeUnapprovedRisks', value === 'yes');
            }}
          />
        </div>
      </div>
      <ReportOwnerField {...sectionProps} />
      <ReportVersionField {...sectionProps} />
      <ReportSummaryField {...sectionProps} />
    </div>
  );
};
