/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  ChecklistCheckable,
  ChecklistCheckableFromJSON,
  ChecklistCheckableFromJSONTyped,
  ChecklistCheckableToJSON,
  ChecklistItemsGroups,
  ChecklistItemsGroupsFromJSON,
  ChecklistItemsGroupsFromJSONTyped,
  ChecklistItemsGroupsToJSON,
  Survey,
  SurveyFromJSON,
  SurveyFromJSONTyped,
  SurveyToJSON,
} from './';

/**
 *
 * @export
 * @interface Checklist
 */
export interface Checklist {
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  relativePath?: string | null;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  status: ChecklistStatusEnum;
  /**
   *
   * @type {Access}
   * @memberof Checklist
   */
  access?: Access;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  checkableId?: string | null;
  /**
   *
   * @type {Array<ChecklistItemsGroups>}
   * @memberof Checklist
   */
  itemsGroups: Array<ChecklistItemsGroups>;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  checkableType?: ChecklistCheckableTypeEnum;
  /**
   *
   * @type {ChecklistCheckable}
   * @memberof Checklist
   */
  checkable?: ChecklistCheckable | null;
  /**
   *
   * @type {boolean}
   * @memberof Checklist
   */
  common: boolean;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  placement: ChecklistPlacementEnum;
  /**
   *
   * @type {boolean}
   * @memberof Checklist
   */
  visibleInLists: boolean;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  summary?: string | null;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  summaryAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  descriptionAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  updatedAt: string;
  /**
   *
   * @type {Survey}
   * @memberof Checklist
   */
  survey?: Survey;
}

export function ChecklistFromJSON(json: any): Checklist {
  return ChecklistFromJSONTyped(json, false);
}

export function ChecklistFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Checklist {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    relativePath: !exists(json, 'relative_path')
      ? undefined
      : json['relative_path'],
    name: json['name'],
    status: json['status'],
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    checkableId: !exists(json, 'checkable_id')
      ? undefined
      : json['checkable_id'],
    itemsGroups: (json['items_groups'] as Array<any>).map(
      ChecklistItemsGroupsFromJSON
    ),
    checkableType: !exists(json, 'checkable_type')
      ? undefined
      : json['checkable_type'],
    checkable: !exists(json, 'checkable')
      ? undefined
      : ChecklistCheckableFromJSON(json['checkable']),
    common: json['common'],
    placement: json['placement'],
    visibleInLists: json['visible_in_lists'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    summaryAsHtml: !exists(json, 'summary_as_html')
      ? undefined
      : json['summary_as_html'],
    description: !exists(json, 'description') ? undefined : json['description'],
    descriptionAsHtml: !exists(json, 'description_as_html')
      ? undefined
      : json['description_as_html'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
    survey: !exists(json, 'survey')
      ? undefined
      : SurveyFromJSON(json['survey']),
  };
}

export function ChecklistToJSON(value?: Checklist | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    relative_path: value.relativePath,
    name: value.name,
    status: value.status,
    access: AccessToJSON(value.access),
    checkable_id: value.checkableId,
    items_groups: (value.itemsGroups as Array<any>).map(
      ChecklistItemsGroupsToJSON
    ),
    checkable_type: value.checkableType,
    checkable: ChecklistCheckableToJSON(value.checkable),
    common: value.common,
    placement: value.placement,
    visible_in_lists: value.visibleInLists,
    summary: value.summary,
    summary_as_html: value.summaryAsHtml,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    survey: SurveyToJSON(value.survey),
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ChecklistStatusEnum {
  InProgress = 'in_progress',
  Completed = 'completed',
  Closed = 'closed',
}
/**
 * @export
 * @enum {string}
 */
export enum ChecklistCheckableTypeEnum {
  VendorUser = 'VendorUser',
}
/**
 * @export
 * @enum {string}
 */
export enum ChecklistPlacementEnum {
  Sidebar = 'sidebar',
}
