import React, { useEffect, useState } from 'react';
import AdoptechModal from '../../../../components/AdoptechModal/AdoptechModal';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../types/applicationState';
import {
  hideTrustHubAuthModal,
  showTrustHubAuthModal,
} from '../../store/trusthubSlice';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { InputField } from '../../../../../unauthenticated/src/components/InputField/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';

import './TrustHubAuthModal.scss';
import {
  loginTrustHub,
  registerTrustHub,
  recoveryTrustHubUserPassword,
  resetTrustHubUserPassword,
  fetchTrustHubUserDetails,
  fetchTrustHubInvitation,
  patchTrustHubInvitation,
} from '../../store/trusthubThunks';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { PasswordStrength } from '../../../../../unauthenticated/src/components/PasswordStrength/PasswordStrength';
import zxcvbn from 'zxcvbn';
import { trustHubRoutes } from '../TrustHubUserRoutes';
import { TrusthubInvitationModel } from '../../../../swagger/trusthub';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { selectCurrentUser } from '../../store/trusthubSelectors';
import { portalRoutes } from '../../../../components/Routes/Routes';
import adoptechLogoDark from 'images/adoptech-logo-dark.svg';
import { ConfirmationModal } from '../../../../components/ConfirmationModal/ConfirmationModal';
import { CommandConfirmation } from '../../../../types/CommandConfirmation';
import { AdoptechCheckbox } from '../../../../components/AdoptechCheckbox/AdoptechCheckbox';

export const TrustHubAuthModal: React.FC = () => {
  const dispatch = useDispatch();

  const baseCss = 'trustHubAuthModal';

  const {
    auth: { modal, status },
    fetchingInvitationStatus,
    fetchTrustHubUserDetailsRequestStatus,
  } = useSelector((state: ApplicationState) => state.trustHub);
  const { router } = useSelector((state: ApplicationState) => state);
  const currentUser = useSelector(selectCurrentUser);
  const isLoading = status === 'loading';
  const isRegisterInviteStep = modal === 'register-invite';
  const isRequestStep = modal === 'request';

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const passwordStrength = zxcvbn(password);

  const stepTo = (nextStep: ApplicationState['trustHub']['auth']['modal']) => {
    dispatch(showTrustHubAuthModal(nextStep));
  };
  const queryParams = useQueryParams();
  const invitationToken = queryParams.get('invitation_token');
  const token = queryParams.get('token');

  const handleLogin = () => {
    dispatch(loginTrustHub(email, password));
  };

  const [currentCommand, command] = useState<CommandConfirmation>(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const showWarningModal = () =>
    command({
      title: 'Please verify your email address',
      description: `Click the link in the email we have sent you to verify your account. Once confirmed you will be able to login and request access to TrustHub resources.`,
      buttonText: 'OK',
      showCancelButton: false,
      buttonVariant: AdoptechButtonVariant.Primary,
    });

  const handleRegistration = () => {
    dispatch(
      registerTrustHub(
        { firstName, lastName, email, password },
        showWarningModal
      )
    );
  };

  const handleInviteRegistration = () => {
    dispatch(
      patchTrustHubInvitation(
        {
          body: {
            trusthubUser: {
              invitationToken,
              password,
              passwordConfirmation,
            },
          },
        },
        () => {}
      )
    );
  };

  const handleRecovery = () => {
    dispatch(recoveryTrustHubUserPassword(email));
  };

  const handleReset = () => {
    dispatch(resetTrustHubUserPassword(password, token));
  };

  let title = 'Setup Account';
  let content = (
    <div className={baseCss + '--stepRequired'}>
      <div className={baseCss + '--subTitle'}>
        To access TrustHub details you need to login.
      </div>
      <AdoptechButton onClick={() => stepTo('request')}>
        Setup Account
      </AdoptechButton>
      <AdoptechButton
        onClick={() => stepTo('login')}
        variant={AdoptechButtonVariant.DarkGray}
      >
        Login
      </AdoptechButton>

      <div
        className={baseCss + '--contentFooter'}
        onClick={() => (window.location.href = portalRoutes.home)}
      >
        Powered by <img src={adoptechLogoDark} />
      </div>
    </div>
  );

  if (modal === 'login') {
    const valid = !!email && !!password;
    title = 'Login';
    content = (
      <div className={baseCss + '--stepLogin'}>
        <InputField
          autoFocus
          onChange={value => setEmail(value)}
          placeholder="Enter your email address"
          type="email"
        />
        <InputField
          autoFocus
          onChange={value => setPassword(value)}
          placeholder="Enter your password"
          type="password"
        />
        <AdoptechButton disabled={!valid || isLoading} onClick={handleLogin}>
          {isLoading ? <LoadingSpinner inlineSmall /> : 'Login'}
        </AdoptechButton>
        <div className={baseCss + '--link'} onClick={() => stepTo('request')}>
          Don't have an account?
        </div>
        <div className={baseCss + '--link'} onClick={() => stepTo('recovery')}>
          Forgotten your password?
        </div>
      </div>
    );
  }

  if (isRequestStep) {
    const valid =
      !!firstName &&
      !!lastName &&
      !!email &&
      !!termsChecked &&
      !!password &&
      password === passwordConfirmation &&
      passwordStrength.score > 1;

    title = 'Setup Account';
    content = (
      <div className={baseCss + '--stepRequest'}>
        <InputField
          autoFocus
          onChange={value => setFirstName(value)}
          placeholder="Enter your first name"
          type="text"
        />
        <InputField
          onChange={value => setLastName(value)}
          placeholder="Enter your last name"
          type="text"
        />
        <InputField
          onChange={value => setEmail(value)}
          placeholder="Enter your email address"
          type="email"
        />
        <InputField
          onChange={value => setPassword(value)}
          placeholder="Choose a password"
          type="password"
        />
        <PasswordStrength password={password} result={passwordStrength} />
        <InputField
          onChange={value => setPasswordConfirmation(value)}
          placeholder="Repeat password"
          type="password"
        />

        <AdoptechCheckbox
          id="terms-checkbox"
          checked={termsChecked}
          onChange={e => {
            setTermsChecked(e.currentTarget.checked);
          }}
          label={
            <div>
              Tick to agree to the{' '}
              <a
                className="termsLabel"
                href={portalRoutes.privacy}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{' '}
            </div>
          }
        />

        <AdoptechButton
          disabled={!valid || isLoading}
          onClick={handleRegistration}
        >
          {isLoading ? <LoadingSpinner inlineSmall /> : 'Setup Account'}
        </AdoptechButton>
      </div>
    );
  }

  if (isRegisterInviteStep) {
    const valid = !!email && !!password && password === passwordConfirmation;
    title = 'Email invitation sign up';
    content = (
      <div className={baseCss + '--stepRegisterInvite'}>
        {fetchingInvitationStatus === 'loading' ? (
          <LoadingSpinner inlineSmall />
        ) : (
          <>
            <InputField
              placeholder="Enter your email address"
              type="email"
              value={email}
              disabled
            />
            <InputField
              onChange={value => setPassword(value)}
              placeholder="Choose a password"
              type="password"
              value={password}
            />
            <InputField
              onChange={value => setPasswordConfirmation(value)}
              placeholder="Repeat password"
              type="password"
              value={passwordConfirmation}
            />
            <AdoptechButton
              disabled={!valid || isLoading}
              onClick={handleInviteRegistration}
            >
              {isLoading ? <LoadingSpinner inlineSmall /> : 'Confirm'}
            </AdoptechButton>
          </>
        )}
      </div>
    );
  }

  if (modal === 'recovery') {
    const valid = !!email;
    title = 'Forgot your password?';
    content = (
      <div className={baseCss + '--stepLogin'}>
        <p>
          Enter your email below and we will send you password reset
          instructions.
        </p>
        <InputField
          autoFocus
          onChange={value => setEmail(value)}
          placeholder="Enter your email address"
          type="email"
        />
        <AdoptechButton disabled={!valid || isLoading} onClick={handleRecovery}>
          {isLoading ? <LoadingSpinner inlineSmall /> : 'Send'}
        </AdoptechButton>
      </div>
    );
  }

  if (modal === 'reset-password') {
    const valid =
      !!password &&
      password === passwordConfirmation &&
      passwordStrength.score > 1;
    title = 'Reset password';
    content = (
      <div className={baseCss + '--stepRequest'}>
        <InputField
          onChange={value => setPassword(value)}
          placeholder="Choose a password"
          type="password"
        />
        <InputField
          onChange={value => setPasswordConfirmation(value)}
          placeholder="Repeat password"
          type="password"
        />
        <PasswordStrength password={password} result={passwordStrength} />
        <AdoptechButton disabled={!valid || isLoading} onClick={handleReset}>
          {isLoading ? <LoadingSpinner inlineSmall /> : 'Confirm'}
        </AdoptechButton>
      </div>
    );
  }

  // not possible to hide during invite form
  const onClose = () =>
    isRegisterInviteStep ? null : dispatch(hideTrustHubAuthModal());

  useEffect(() => {
    dispatch(fetchTrustHubUserDetails());

    const isRegisterInviteRoute = router.location.pathname.includes(
      trustHubRoutes.registerInvite
    );
    if (isRegisterInviteRoute && invitationToken) {
      dispatch(showTrustHubAuthModal('register-invite'));
      dispatch(
        fetchTrustHubInvitation(
          {
            id: invitationToken,
          },
          (invitation: TrusthubInvitationModel) => {
            setEmail(invitation.email);
          }
        )
      );
    }

    const isResetPasswordRoute = router.location.pathname.includes(
      trustHubRoutes.resetPassword
    );
    if (isResetPasswordRoute && token) {
      dispatch(showTrustHubAuthModal('reset-password'));
    }
  }, []);

  useEffect(() => {
    const isLoginRoute = router.location.pathname.includes(
      trustHubRoutes.login
    );
    const currentUserRequestFinished =
      fetchTrustHubUserDetailsRequestStatus === 'succeeded' ||
      fetchTrustHubUserDetailsRequestStatus === 'failed';
    if (!isLoginRoute || !currentUserRequestFinished) return;

    if (currentUser) {
      window.location.href = window.location.href.replace('login', '');
      return;
    }

    dispatch(showTrustHubAuthModal('login'));
    return;
  }, [fetchTrustHubUserDetailsRequestStatus]);

  useEffect(() => {
    isRequestStep && setTermsChecked(false);
  }, [isRequestStep]);

  return (
    <>
      <AdoptechModal show={!!modal} onHide={onClose} className={baseCss}>
        <div className={baseCss + '--header'}>
          {modal !== 'required' && !isRegisterInviteStep && (
            <FontAwesomeIcon
              icon={faAngleLeft}
              onClick={() => stepTo('required')}
            />
          )}
          <div>{title}</div>
          {!isRegisterInviteStep && (
            <FontAwesomeIcon icon={faXmark} onClick={onClose} />
          )}
        </div>
        <div className={baseCss + '--body'}>{content}</div>
      </AdoptechModal>
      <ConfirmationModal
        command={currentCommand}
        onCancel={() => command(null)}
      />
    </>
  );
};
