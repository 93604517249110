import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closePdfPreview,
  openPdfPreview,
} from '../../../../store/global/globalSlice';
import { ApplicationState } from '../../../../types/applicationState';
import { PdfOpenMode } from '../../../../types/pdfOpenMode';
import { PdfPreviewCard } from '../../../../components/PdfPreviewCard/PdfPreviewCard';
import { DocumentType } from '../../../../constants/DocumentType';
import {
  fetchAuditPDFError,
  fetchAuditPDFRequest,
  fetchAuditPDFSuccess,
} from '../store/auditsSlice';
import { PdfPreviewEntityPaths } from '../../../../functions/routePathsHelpers';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { AuditModel } from '../../../../swagger/models/AuditModel';
import { getRequestWithRetry } from '../../../../functions/getRequestWithRetry';

export const useAuditPreviewModal = () => {
  const params = useQueryParams();
  const documentableId = params.get('documentableId');
  const audits = useSelector((state: ApplicationState) => state.audits.audits);
  const dispatch = useDispatch();
  useEffect(() => {
    const audit = audits.find(item => item.id === documentableId);
    if (!audit) return;
    dispatch(
      openPdfPreview({
        mode: PdfOpenMode.PreviewDocument,
        documentType: DocumentType.Audit,
        objectId: audit.id,
        title: audit.name,
        vendorDocumentId: audit.vendorDocumentId,
        navigateUrlOnClose: PdfPreviewEntityPaths.audits,
      })
    );
  }, [documentableId]);
};

const pdfURL = (audit: AuditModel) => {
  return `/api/v1/audits/${audit.id}/preview`;
};

// TODO: When BE done && RHS submitted, reload PDF may be useful as for agreements
// export const usePreviewModeReload = () => {
//   const openedDraftPdfPreview = useSelector(
//     (state: ApplicationState) =>
//       state.global.pdfPreviewInline && state.global.isPreviewingPdf
//   );
//   const audit = useSelector((state: ApplicationState) => state.audits.currentAudit);
//   const dispatch = useDispatch();
//   const reloadPdf = () => {
//     if (!openedDraftPdfPreview) return;
//     dispatch(setPdfPreviewUrl(null));
//     dispatch(setPdfPreviewUrl(pdfURL(audit)));
//   };

//   return { reloadPdf };
// };

export const PreviewMode: React.FC = () => {
  const dispatch = useDispatch();
  const audit = useSelector(
    (state: ApplicationState) => state.audits.currentAudit
  );

  // Wait if audit from RHS is updating OR audit answer is updating
  const isSavingDataRequiredForPdf = useSelector(
    (state: ApplicationState) =>
      state.audits.isPostingAuditAnswer ||
      state.audits.isDeletingAuditAnswer ||
      state.audits.isUpdatingAudit
  );

  const openPreview = (url: string) =>
    dispatch(
      openPdfPreview({
        mode: PdfOpenMode.Preview,
        documentType: DocumentType.Audit,
        objectId: audit.id,
        title: audit.name,
        url,
        inline: true,
      })
    );

  const isRequestingAuditPdf = useSelector(
    (state: ApplicationState) => state.audits.isFetchingAuditPDF
  );

  useEffect(() => {
    dispatch(fetchAuditPDFRequest());
    getRequestWithRetry({
      url: pdfURL(audit),
      retries: 2,
      isBinary: true,
    })
      .then((blob: Blob) => {
        const localURL = URL.createObjectURL(blob);
        openPreview(localURL);
        dispatch(fetchAuditPDFSuccess());
      })
      .catch(() => {
        dispatch(fetchAuditPDFError());
        openPreview(pdfURL(audit)); // Show default "Failed load pdf" react-pdf library warning
      });

    return () => {
      dispatch(closePdfPreview());
    };
  }, [isSavingDataRequiredForPdf]);

  return (
    <PdfPreviewCard
      waitingBeforeLoadingPDF={
        isSavingDataRequiredForPdf || isRequestingAuditPdf
      }
    />
  );
};
