/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChecklistItem
 */
export interface ChecklistItem {
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  questionId: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  questionText: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  questionTextAsHtml?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  explanatoryText: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  explanatoryTextAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  datatype: string;
  /**
   *
   * @type {number}
   * @memberof ChecklistItem
   */
  answerId: number | null;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistItem
   */
  answerValue: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistItem
   */
  answerNotApplicable?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  answerNote?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  propertyName: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  answerUserName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  answerUpdatedAt?: string | null;
  /**
   *
   * @type {number}
   * @memberof ChecklistItem
   */
  rowOrder?: number | null;
}

export function ChecklistItemFromJSON(json: any): ChecklistItem {
  return ChecklistItemFromJSONTyped(json, false);
}

export function ChecklistItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    questionId: json['question_id'],
    questionText: json['question_text'],
    questionTextAsHtml: !exists(json, 'question_text_as_html')
      ? undefined
      : json['question_text_as_html'],
    explanatoryText: json['explanatory_text'],
    explanatoryTextAsHtml: !exists(json, 'explanatory_text_as_html')
      ? undefined
      : json['explanatory_text_as_html'],
    datatype: json['datatype'],
    answerId: json['answer_id'],
    answerValue: json['answer_value'],
    answerNotApplicable: !exists(json, 'answer_not_applicable')
      ? undefined
      : json['answer_not_applicable'],
    answerNote: !exists(json, 'answer_note') ? undefined : json['answer_note'],
    propertyName: json['property_name'],
    answerUserName: !exists(json, 'answer_user_name')
      ? undefined
      : json['answer_user_name'],
    answerUpdatedAt: !exists(json, 'answer_updated_at')
      ? undefined
      : json['answer_updated_at'],
    rowOrder: !exists(json, 'row_order') ? undefined : json['row_order'],
  };
}

export function ChecklistItemToJSON(value?: ChecklistItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question_id: value.questionId,
    question_text: value.questionText,
    question_text_as_html: value.questionTextAsHtml,
    explanatory_text: value.explanatoryText,
    explanatory_text_as_html: value.explanatoryTextAsHtml,
    datatype: value.datatype,
    answer_id: value.answerId,
    answer_value: value.answerValue,
    answer_not_applicable: value.answerNotApplicable,
    answer_note: value.answerNote,
    property_name: value.propertyName,
    answer_user_name: value.answerUserName,
    answer_updated_at: value.answerUpdatedAt,
    row_order: value.rowOrder,
  };
}
