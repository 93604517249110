import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import policyTour1 from 'images/policyTour1.png';
import policyTour2 from 'images/policyTour2.png';
import policyTour3 from 'images/policyTour3.png';
import { clearPolicyHelp } from '../../store/global/globalSlice';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './PolicyTour.scss';

export const PolicyTour: React.FC = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const showPolicyHelp = useSelector(
    (state: ApplicationState) => state.global.showPolicyHelp
  );

  useEffect(() => {
    setPage(1);
  }, [showPolicyHelp]);

  const renderPage = (pageNumber: number) => {
    switch (pageNumber) {
      case 1:
        return (
          <>
            <Modal.Header className="policyTour--title">
              Drafting the policy
            </Modal.Header>

            <Modal.Body>
              <div className="policyTour--text">
                <span>
                  <p>
                    Simply answer the questions in each category to generate the
                    policy text.
                  </p>
                </span>
              </div>
              <div className="policyTour--image">
                <img alt="Drafting the policy screenshot" src={policyTour1} />
              </div>
            </Modal.Body>
          </>
        );
      case 2:
        return (
          <>
            <Modal.Header className="policyTour--title">
              Editing the contents
            </Modal.Header>
            <Modal.Body>
              <div className="policyTour--text">
                <span>
                  <p>
                    You can edit the text that has been generated by selecting
                    'Edit'.
                  </p>
                </span>
              </div>
              <div className="policyTour--image">
                <img alt="Editing the contents screenshot" src={policyTour2} />
              </div>
            </Modal.Body>
          </>
        );
      case 3:
        return (
          <>
            <Modal.Header className="policyTour--title">
              Completing the policy
            </Modal.Header>
            <Modal.Body>
              <div className="policyTour--text">
                <span>
                  <p>
                    Once all the questions in all categories have been answered
                    (or clauses left out), select COMPLETE to send the policy
                    for approval.
                  </p>
                </span>
              </div>
              <div className="policyTour--image">
                <img alt="Completing the policy screenshot" src={policyTour3} />
              </div>
            </Modal.Body>
          </>
        );
    }
  };

  return (
    <AdoptechModal
      show={showPolicyHelp}
      onHide={() => dispatch(clearPolicyHelp())}
      className="policyTour"
    >
      {renderPage(page)}
      <Modal.Footer className="policyTour--footer">
        {page > 1 ? (
          <AdoptechButton
            onClick={() => {
              setPage(page - 1);
            }}
          >
            Back
          </AdoptechButton>
        ) : (
          <div />
        )}
        {page < 3 && (
          <AdoptechButton
            onClick={() => {
              setPage(page + 1);
            }}
            variant={AdoptechButtonVariant.Primary}
          >
            Next
          </AdoptechButton>
        )}
        {page === 3 && (
          <AdoptechButton
            onClick={() => {
              dispatch(clearPolicyHelp());
            }}
            variant={AdoptechButtonVariant.Primary}
          >
            Got it
          </AdoptechButton>
        )}
      </Modal.Footer>
    </AdoptechModal>
  );
};
