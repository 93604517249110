import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import { fetchInvoices } from '../../store/user/userThunks';
import { ApplicationState } from '../../types/applicationState';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { IconButton } from '../IconButton/IconButton';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import './ManageInvoicesGrid.scss';

export const ManageInvoicesGrid: React.FC = () => {
  const invoices = useSelector(
    (state: ApplicationState) => state.user.invoices
  );

  const isFetchingInvoices = useSelector(
    (state: ApplicationState) => state.user.isFetchingInvoices
  );

  const { isManageBillingModalShowing } = useSelector(
    (state: ApplicationState) => state.vendors
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isManageBillingModalShowing) {
      dispatch(fetchInvoices());
    }
  }, [isManageBillingModalShowing]);

  return (
    <div className="manageInvoicesGrid">
      {invoices.length > 0 && (
        <AdoptechTable modal className="manageInvoicesGrid--table">
          <thead>
            <tr className="manageInvoicesGrid--row">
              <th>Date</th>
              <th>Invoice number</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map(invoice => {
              return (
                <tr className="manageInvoicesGrid--row" key={invoice.number}>
                  <td>{formatShortDate(invoice.createdAt)}</td>
                  <td>{invoice.number}</td>
                  <td>{invoice.description}</td>
                  <td>
                    {getSymbolFromCurrency(invoice.currency)}
                    {(invoice.amount / 100).toFixed(2)}
                  </td>
                  <td>
                    <div className="adoptechTable--actions manageInvoicesGrid--actions">
                      <IconButton
                        ariaLabel="View"
                        icon={faEye}
                        onClick={() => {
                          window.open(invoice.invoiceUrl, '_blank');
                        }}
                      />
                      {invoice.invoicePdf && (
                        <IconButton
                          ariaLabel="Download"
                          icon={faCloudDownload}
                          onClick={() => {
                            window.location.href = invoice.invoicePdf;
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
            {isFetchingInvoices && (
              <tr className="manageInvoicesGrid--row">
                <td colSpan={5}>
                  <LoadingSpinner />
                </td>
              </tr>
            )}
          </tbody>
        </AdoptechTable>
      )}
      {!isFetchingInvoices && invoices.length === 0 && (
        <div className="manageInvoicesGrid--message">No invoices found</div>
      )}
    </div>
  );
};
