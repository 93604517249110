import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { SelectionOption } from '../../../types/selectionOption';
import { VendorDocumentExtended, VendorTeamModel } from '../../../swagger';
import { Lozenge } from '../../Lozenge/Lozenge';

import AsyncSelect from 'react-select/async';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { fetchPolicyAttestationGroups } from '../../../store/policies/policiesThunks';
import debounce from 'debounce-promise';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { fetchVendorTeams } from '../../../store/vendors/vendorsThunks';
import { reactSelectLightTheme } from '../../../functions/reactSelectCustomTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { PoliciesState } from '../../../store/policies/policiesState';
import { updatePolicyToEditField } from '../../../store/policies/policiesSlice';

export const PolicyTeamsSelector: React.FC = () => {
  const baseCss = 'policyTeamsSelector';
  const setSelectedTeams = (
    newTeams: PoliciesState['policyToEdit']['selectedTeams']
  ) => {
    dispatch(
      updatePolicyToEditField({
        selectedTeams: newTeams,
      })
    );
  };
  const selectedTeams = useSelector((state: ApplicationState) => {
    return (state.policies.policyToEdit || {}).selectedTeams || [];
  });

  const handleRemove = async (teamId: VendorTeamModel['id']) => {
    const updatedTeams = selectedTeams.filter(team => team.id !== teamId);
    setSelectedTeams(updatedTeams);
  };

  const handleChange = async (selectedOptions: SelectionOption[]) => {
    const teams = selectedOptions.map(option => ({
      id: option.value,
      name: option.label,
    }));
    setSelectedTeams(teams);
  };

  const dispatch = useAppDispatch();
  const policy = useSelector(
    (state: ApplicationState) =>
      state.policies.policyToEdit as VendorDocumentExtended
  );
  const currentDocument = policy;
  const searchTeams = debounce((query: string) => {
    return new Promise<SelectionOption[]>(resolve => {
      dispatch(
        fetchVendorTeams(
          false,
          (teams: VendorTeamModel[]) => {
            const formattedTeams = teams.map(team => ({
              label: team.name,
              value: team.id,
            }));
            resolve(formattedTeams);
          },
          query
        )
      );
    });
  }, 500);

  const promiseOptions = async (query: string) => {
    if (query === '' || !query || query.length < 1) {
      return [];
    }
    return searchTeams(query);
  };

  useEffect(() => {
    const fetchInitialTeams = async () => {
      const teams = await dispatch(
        fetchPolicyAttestationGroups({ id: currentDocument.id })
      ).unwrap();
      const teamsOptions = teams.map(team => ({
        id: team.id,
        name: team.name,
      }));

      dispatch(
        updatePolicyToEditField({
          initialTeamsFetched: true,
          selectedTeams: teamsOptions,
        })
      );
    };

    fetchInitialTeams();
  }, []);

  const { fetchPolicyAttestationGroupsStatus } = useSelector(
    (state: ApplicationState) => state.policies
  );

  const isLoadingTeams = fetchPolicyAttestationGroupsStatus === 'loading';

  return (
    <div className={baseCss}>
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions={false}
        loadOptions={promiseOptions}
        onChange={handleChange}
        value={selectedTeams.map(team => ({
          value: team.id,
          label: team.name,
        }))}
        noOptionsMessage={() => null}
        components={{
          DropdownIndicator: () => (
            <div style={{ padding: '0 8px' }}>
              <FontAwesomeIcon icon={faSearch} />
            </div>
          ),
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
        }}
        theme={reactSelectLightTheme}
        placeholder="Start typing to search a Group"
      />
      {(isLoadingTeams || selectedTeams.length > 0) && (
        <>
          <div className={baseCss + '--info'}>
            <div className={baseCss + '--subHeader'}>
              Groups required to read
            </div>
            :{' '}
            {isLoadingTeams ? (
              <div className={baseCss + '--teamsSpinner'}>
                <LoadingSpinner inlineSmall />
              </div>
            ) : null}
          </div>

          <div className={baseCss + '--selectedTeams'}>
            {selectedTeams.map(team => (
              <Lozenge
                key={team.id}
                onDelete={() => handleRemove(team.id)}
                value={team.name}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
