import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { UseMeasureRect } from 'react-use/lib/useMeasure';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

interface PdfPreviewDocumentProps {
  fileSource: string;
  bodyBounds: UseMeasureRect;
  handleLoadSuccess(pdf: { numPages: number }): void;
  pageCount: number;
}

export const PdfPreviewDocument: React.FC<PdfPreviewDocumentProps> = ({
  fileSource,
  bodyBounds,
  handleLoadSuccess,
  pageCount,
}) => {
  if (!fileSource) return null;

  return (
    <Document
      externalLinkTarget="_blank"
      file={fileSource}
      loading={<LoadingSpinner />}
      onLoadSuccess={handleLoadSuccess}
    >
      {Array.from(Array(pageCount)).map((_, index: number) => (
        <Page key={index} pageIndex={index} width={bodyBounds.width * 0.95} />
      ))}
    </Document>
  );
};
