import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitaliseFirst } from '../../../../functions/capitaliseFirst';
import { selectCurrentAgreement } from '../../../../selectors/selectCurrentAgreement';
import { updateAgreementsEditFormField } from '../../../../store/agreements/agreementsSlice';
import {
  AgreementModel,
  AgreementSignatoriesIdPatchRequest,
} from '../../../../swagger';
import { ApplicationState } from '../../../../types/applicationState';
import { AdoptechTextInput2 } from '../../../AdoptechTextInput2/AdoptechTextInput2';
import { agreementsAccess } from '../../AgreementMeatballMenu/AgreementMeatballMenu';
import { DropZone } from '../../../DropZone/DropZone';
import {
  AgreementsEditForm,
  AgreementSignatoryModelExtended,
} from '../EditAgreementForm';
import '../EditAgreementForm.scss';

export const DocumentUploadStepIsValid = (
  agreement: AgreementModel,
  form: AgreementsEditForm
) => {
  return (
    agreementsAccess(agreement).canUploadSignatoryDocument &&
    form.signatoryDocumentExist
  );
};

export const DocumentUploadStep: React.FC = () => {
  const agreement = useSelector(selectCurrentAgreement);
  const baseCss = 'editAgreementForm';
  const [agreementName, setAgreementName] = useState<string>(agreement.name);
  const form = useSelector(
    (state: ApplicationState) => state.agreements.agreementsEditForm
  );
  const dispatch = useDispatch();

  const onSignatoriesChange = (
    signatories: AgreementsEditForm['signatoriesWithDocs']
  ) => {
    dispatch(
      updateAgreementsEditFormField({ signatoriesWithDocs: signatories })
    );
  };
  const signatories = form.signatoriesWithDocs || [];

  const signatory = useSelector((state: ApplicationState) => {
    return state.agreements.currentAgreementSignatories.find(
      s => s.id === form.currentSignatoryId
    );
  });

  const [agreementFile, setAgreementFile] = useState<File>();

  useEffect(() => {
    dispatch(updateAgreementsEditFormField({ signatoryDocumentExist: false }));
  }, []);
  const handleOnChange = (file: File) => {
    setAgreementFile(file);
    dispatch(updateAgreementsEditFormField({ signatoryDocumentExist: true }));
    const body: AgreementSignatoryModelExtended = {
      ...signatory,
      documentBinary: file,
    };

    const newSignatories = signatories.map(signatoryWithDoc => {
      if (signatoryWithDoc.id === body.id) return body;
      return signatoryWithDoc;
    });

    if (
      !newSignatories.find(signatoryWithDoc => signatoryWithDoc.id === body.id)
    ) {
      newSignatories.push(body);
    }

    onSignatoriesChange(newSignatories);
  };

  return (
    <>
      <div className={baseCss + '--section-label'}>Document details</div>
      <div className={baseCss + '--section-sub-label'}>
        Please add the agreement name and an optional description.
      </div>

      <div className="mt-2">
        <AdoptechTextInput2
          id="agreement-name"
          onChange={e => setAgreementName(e.currentTarget.value)}
          type="text"
          label="Agreement name"
          value={agreementName || ''}
          hasError={!agreementName}
          disabled={!agreementsAccess(agreement).canEditName}
        />
      </div>
      <div className={baseCss + '--section-label'}>Upload document</div>
      <div className={baseCss + '--upload-area'}>
        <DropZone
          accept=".pdf, .csv, .xls, .xml, .doc, .docx, .xlsx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onChange={handleOnChange}
        />
      </div>
    </>
  );
};
