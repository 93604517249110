import { api } from '../../../api/api';

import {
  CorrectiveActionExtendedFromJSON,
  CorrectiveActionModel,
  CorrectiveActionsCorrectiveActionIdPatchRequest,
  IncidentEventExtendedFromJSON,
  IncidentEventModel,
  LegalRegisterModel,
  ReferenceCreatePayloadReferenceReferenceableTypeEnum,
  SharedAttachmentModel,
  VendorActionExtended,
  VendorActionExtendedFromJSON,
  VendorActionModel,
  VendorActionsVendorActionIdPatchRequest,
  VendorEventExtended,
  VendorEventExtendedFromJSON,
  VendorEventModel,
  VendorEventsVendorEventIdPatchRequest,
  VendorsVendorIdCalendarGetRequest,
  VendorsVendorIdCalendarGetViewModeEnum,
  VendorsVendorIdVendorActionsGetRequest,
  VendorsVendorIdVendorActionsPostRequest,
  VendorsVendorIdVendorEventsPostRequest,
} from '../../../swagger';
import { VoidThunk } from '../../../types/voidThunk';
import {
  getErrorMessage,
  handleThunkError,
} from '../../../store/helpers/thunkHelpers';
import {
  fetchVendorActionsFailure,
  fetchVendorActionsRequest,
  fetchVendorActionsSuccess,
  updateCalendarItemFailure,
  updateCalendarItemRequest,
  updateCalendarItemSuccess,
} from './calendarSlice';
import { calendarState } from './calendarState';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationState } from '../../../types/applicationState';
import {
  IncidentEventsIncidentEventIdPatchRequest,
  VendorsVendorIdCorrectiveActionsPostRequest,
  VendorsVendorIdIncidentEventsPostRequest,
} from '../../../swagger/apis/DefaultApi';
import {
  WithoutVendorId,
  buildActionFormData,
  buildCorrectiveActionFormData,
  buildEventFormData,
  buildIncidentFormData,
  rawRequest,
} from './calendarThunksApiHelpers';

const removeEmptyValues = <T>(obj: T): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => !!value)
  ) as Partial<T>;
};

export const fetchCalendarItems = createAsyncThunk(
  'calendar/fetchItems',
  async (
    {
      filters,
      currentVendorUserId,
    }: {
      filters: calendarState['filters'];
      currentVendorUserId: string;
    },
    { dispatch, getState }
  ) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        .id;
      const ownerIds = filters.showMyItems ? [currentVendorUserId] : null;
      const statuses = filters.showCompleted ? null : ['overdue', 'pending'];
      const payload = {
        vendorId,
        startDate: filters.startPeriod,
        endDate: filters.endPeriod,
        ownerIds,
        statuses,
        textSearch: filters.search,
        perPage: '25',
        page: filters.page.toString(),
        viewMode: filters.viewMode as VendorsVendorIdCalendarGetViewModeEnum,
      };
      const response = await api().vendorsVendorIdCalendarGet(
        removeEmptyValues(payload) as VendorsVendorIdCalendarGetRequest
      );
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while GET /calendar. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export type updateActionParams = Parameters<typeof updateAction>[1];

export const updateAction =
  (
    currentVendorAction: VendorActionExtended,
    payload: Omit<VendorActionsVendorActionIdPatchRequest, 'vendorActionId'>,
    onSuccess: (
      item: VendorActionExtended,
      itemBeforeUpdate: VendorActionExtended
    ) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(updateCalendarItemRequest());

      const formData = buildActionFormData(payload);
      formData.append(
        `vendor_action[completed]`,
        payload.vendorActionCompleted as any
      );

      const rawResponse = await rawRequest(
        `/api/v1/vendor_actions/${currentVendorAction?.id}`,
        {
          method: 'PATCH',
          body: formData,
        }
      );

      if (rawResponse.status >= 200 && rawResponse.status < 300) {
        const response = VendorActionExtendedFromJSON(await rawResponse.json());
        dispatch(updateCalendarItemSuccess());
        onSuccess(response, currentVendorAction);
      } else {
        throw rawResponse;
      }
    } catch (err) {
      const errorMessage = await getErrorMessage(
        err,
        'An error occurred while updating action. Please try again or contact support.'
      );
      dispatch(updateCalendarItemFailure(errorMessage));
    }
  };

export const deleteAction = createAsyncThunk(
  'calendar/deleteAction',
  async (vendorActionId: VendorActionModel['id'], { dispatch }) => {
    try {
      await api().vendorActionsVendorActionIdDelete({
        vendorActionId,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while deleting vendor action. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchEvent = createAsyncThunk(
  'calendar/fetchEvent',
  async (id: VendorEventModel['id'], { dispatch }) => {
    try {
      const event = await api().vendorEventsVendorEventIdGet({
        vendorEventId: id,
      });
      return event;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching vendor event. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export type createEventParams = Parameters<typeof createEvent>[0];

export const createEvent = createAsyncThunk(
  'calendar/createEvent',
  async (
    payload: WithoutVendorId<VendorsVendorIdVendorEventsPostRequest>,
    { dispatch, getState }
  ) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        .id;

      const formData = buildEventFormData(payload);
      const rawResponse = await rawRequest(
        `/api/v1/vendors/${vendorId}/vendor_events`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (rawResponse.status >= 200 && rawResponse.status < 300) {
        const response = VendorEventExtendedFromJSON(await rawResponse.json());
        return response;
      } else {
        throw rawResponse;
      }
    } catch (error) {
      await handleThunkError(
        'An error occurred while creating event. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export type updateEventParams = Parameters<typeof updateEvent>[1];

export const updateEvent =
  (
    eventId: VendorEventModel['id'],
    payload: WithoutVendorId<VendorEventsVendorEventIdPatchRequest>,
    onSuccess: (item: VendorEventExtended) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(updateCalendarItemRequest());

      const formData = buildEventFormData(payload);
      const rawResponse = await rawRequest(`/api/v1/vendor_events/${eventId}`, {
        method: 'PATCH',
        body: formData,
      });

      if (rawResponse.status >= 200 && rawResponse.status < 300) {
        const response = VendorEventExtendedFromJSON(await rawResponse.json());
        dispatch(updateCalendarItemSuccess());
        onSuccess(response);
      } else {
        throw rawResponse;
      }
    } catch (err) {
      const errorMessage = await getErrorMessage(
        err,
        'An error occurred while updating event. Please try again or contact support.'
      );
      dispatch(updateCalendarItemFailure(errorMessage));
    }
  };

export const deleteEvent = createAsyncThunk(
  'calendar/deleteEvent',
  async (vendorEventId: VendorEventModel['id'], { dispatch }) => {
    try {
      await api().vendorEventsVendorEventIdDelete({
        vendorEventId,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while deleting event. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

// For calendar HS in legal/pestel registry:
export const fetchVendorActions =
  (
    registerId: VendorsVendorIdVendorActionsGetRequest['referenceableId'],
    registerType: VendorsVendorIdVendorActionsGetRequest['referenceableType'],
    onSuccess: (actions: VendorActionModel[]) => void
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor.id;
      dispatch(fetchVendorActionsRequest());
      const response = await api().vendorsVendorIdVendorActionsGet({
        vendorId,
        referenceableId: registerId,
        referenceableType: registerType,
      });
      dispatch(fetchVendorActionsSuccess());
      onSuccess(response);
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching Actions. Please try again or contact support.'
        );
        console.log(err);
        dispatch(fetchVendorActionsFailure(apiErrorMessage));
      }
    }
  };

// if pestel or legal
export const createActionReference =
  (
    vendorActionId: VendorActionModel['id'],
    referenceableId: LegalRegisterModel['id'],
    referenceableType: ReferenceCreatePayloadReferenceReferenceableTypeEnum,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      await api().vendorActionsVendorActionIdReferencesPost({
        vendorActionId,
        body: {
          reference: {
            referenceableId,
            referenceableType,
          },
        },
      });
      onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while adding action. Please try again or contact support.'
      );
      // TODO add separate state for failure
      dispatch(fetchVendorActionsFailure(apiErrorMessage));
    }
  };

export const deleteActionReference =
  (
    vendorActionId: VendorActionModel['id'],
    referenceableId: LegalRegisterModel['id'],
    referenceableType: ReferenceCreatePayloadReferenceReferenceableTypeEnum
  ): VoidThunk =>
  async dispatch => {
    try {
      await api().vendorActionsVendorActionIdReferencesDelete({
        vendorActionId,
        referenceableId,
        referenceableType,
      });
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while deleting action reference. Please try again or contact support.'
      );
      // TODO add separate state for failure
      dispatch(fetchVendorActionsFailure(apiErrorMessage));
    }
  };
export const downloadEventAttachment = createAsyncThunk(
  'calendar/downloadEventAttachment',
  async (
    {
      id,
      attachmentId,
    }: {
      id: VendorEventModel['id'];
      attachmentId: SharedAttachmentModel['id'];
    },
    { dispatch }
  ) => {
    try {
      const event = await api().vendorEventsVendorEventIdGet({
        vendorEventId: id,
      });
      return event.attachments.find(
        attachment => attachment.id === attachmentId
      );
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching event attachment. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const downloadActionAttachment = createAsyncThunk(
  'calendar/downloadActionAttachment',
  async (
    {
      id,
      attachmentId,
    }: {
      id: VendorActionModel['id'];
      attachmentId: SharedAttachmentModel['id'];
    },
    { dispatch }
  ) => {
    try {
      const action = await api().vendorActionsVendorActionIdGet({
        vendorActionId: id,
      });
      return action.attachments.find(
        attachment => attachment.id === attachmentId
      );
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching action attachment. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const downloadCorrectiveActionAttachment = createAsyncThunk(
  'calendar/downloadCorrectiveActionAttachment',
  async (
    {
      id,
      attachmentId,
    }: {
      id: CorrectiveActionModel['id'];
      attachmentId: SharedAttachmentModel['id'];
    },
    { dispatch }
  ) => {
    try {
      const model = await api().correctiveActionsCorrectiveActionIdGet({
        correctiveActionId: id,
      });
      return model.attachments.find(
        attachment => attachment.id === attachmentId
      );
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching corrective action attachment. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const downloadIncidentAttachment = createAsyncThunk(
  'calendar/downloadIncidentAttachment',
  async (
    {
      id,
      attachmentId,
    }: {
      id: IncidentEventModel['id'];
      attachmentId: SharedAttachmentModel['id'];
    },
    { dispatch }
  ) => {
    try {
      const model = await api().incidentEventsIncidentEventIdGet({
        incidentEventId: id,
      });
      return model.attachments.find(
        attachment => attachment.id === attachmentId
      );
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching incident attachment. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchAction = createAsyncThunk(
  'calendar/fetchAction',
  async (id: VendorActionModel['id'], { dispatch }) => {
    try {
      const action = await api().vendorActionsVendorActionIdGet({
        vendorActionId: id,
      });
      return action;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching action. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export type createNewActionParams = Parameters<typeof createNewAction>[0];

export const createNewAction = createAsyncThunk(
  'calendar/createAction',
  async (
    {
      payload,
    }: {
      payload: WithoutVendorId<VendorsVendorIdVendorActionsPostRequest>;
    },
    { dispatch, getState }
  ) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        ?.id;
      const formData = buildActionFormData(payload);
      const rawResponse = await rawRequest(
        `/api/v1/vendors/${vendorId}/vendor_actions`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (rawResponse.status >= 200 && rawResponse.status < 300) {
        const response = VendorActionExtendedFromJSON(await rawResponse.json());
        return response;
      } else {
        throw rawResponse;
      }
    } catch (error) {
      await handleThunkError(
        'An error occurred while creating vendor action. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export type createCorrectiveActionParams = Parameters<
  typeof createCorrectiveAction
>[0];

export const createCorrectiveAction = createAsyncThunk(
  'calendar/createCorrectiveAction',
  async (
    body: WithoutVendorId<VendorsVendorIdCorrectiveActionsPostRequest>,
    { dispatch, getState }
  ) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        ?.id;

      const payload: VendorsVendorIdCorrectiveActionsPostRequest = {
        vendorId,
        ...body,
      };
      const formData = buildCorrectiveActionFormData(payload);
      const rawResponse = await rawRequest(
        `/api/v1/vendors/${vendorId}/corrective_actions`,
        {
          method: 'POST',
          body: formData,
        }
      );
      if (isResponse200(rawResponse)) {
        return CorrectiveActionExtendedFromJSON(await rawResponse.json());
      } else {
        throw rawResponse;
      }
    } catch (error) {
      await handleThunkError(
        'An error occurred while creating corrective action. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const deleteCorrectiveAction = createAsyncThunk(
  'calendar/deleteCorrectiveAction',
  async (id: CorrectiveActionModel['id'], { dispatch }) => {
    try {
      await api().correctiveActionsCorrectiveActionIdDelete({
        correctiveActionId: id,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while deleting corrective action. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchCorrectiveAction = createAsyncThunk(
  'calendar/fetchCorrectiveAction',
  async (id: CorrectiveActionModel['id'], { dispatch }) => {
    try {
      return await api().correctiveActionsCorrectiveActionIdGet({
        correctiveActionId: id,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching corrective action. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export type updateCorrectiveActionParams = Parameters<
  typeof updateCorrectiveAction
>[0];

export const updateCorrectiveAction = createAsyncThunk(
  'calendar/updateCorrectiveAction',
  async (
    body: CorrectiveActionsCorrectiveActionIdPatchRequest,
    { dispatch, getState }
  ) => {
    try {
      const formData = buildCorrectiveActionFormData(body);
      const rawResponse = await rawRequest(
        `/api/v1/corrective_actions/${body.correctiveActionId}`,
        {
          method: 'PATCH',
          body: formData,
        }
      );

      if (isResponse200(rawResponse)) {
        return CorrectiveActionExtendedFromJSON(await rawResponse.json());
      } else {
        throw rawResponse;
      }
    } catch (error) {
      await handleThunkError(
        'An error occurred while updating corrective action. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export type createIncidentParams = Parameters<typeof createIncident>[0];

const isResponse200 = (response: Response) =>
  response.status >= 200 && response.status < 300;
export const createIncident = createAsyncThunk(
  'calendar/createIncident',
  async (
    body: WithoutVendorId<VendorsVendorIdIncidentEventsPostRequest>,
    { dispatch, getState }
  ) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        .id;
      const payload = {
        vendorId,
        ...body,
      };

      const formData = buildIncidentFormData(payload);
      const rawResponse = await rawRequest(
        `/api/v1/vendors/${vendorId}/incident_events`,
        {
          method: 'POST',
          body: formData,
        }
      );
      if (isResponse200(rawResponse)) {
        return IncidentEventExtendedFromJSON(await rawResponse.json());
      } else {
        throw rawResponse;
      }
      // return await api().vendorsVendorIdIncidentEventsPost(payload);
    } catch (error) {
      await handleThunkError(
        'An error occurred while creating incident. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const deleteIncident = createAsyncThunk(
  'calendar/deleteIncident',
  async (id: IncidentEventModel['id'], { dispatch }) => {
    try {
      await api().incidentEventsIncidentEventIdDelete({
        incidentEventId: id,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while deleting incident. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchIncident = createAsyncThunk(
  'calendar/fetchIncident',
  async (id: IncidentEventModel['id'], { dispatch }) => {
    try {
      return await api().incidentEventsIncidentEventIdGet({
        incidentEventId: id,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching incident. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export type updateIncidentParams = Parameters<typeof updateIncident>[0];

export const updateIncident = createAsyncThunk(
  'calendar/updateIncident',
  async (body: IncidentEventsIncidentEventIdPatchRequest, { dispatch }) => {
    try {
      const formData = buildIncidentFormData(body);
      const rawResponse = await rawRequest(
        `/api/v1/incident_events/${body.incidentEventId}`,
        {
          method: 'PATCH',
          body: formData,
        }
      );

      if (isResponse200(rawResponse)) {
        return IncidentEventExtendedFromJSON(await rawResponse.json());
      } else {
        throw rawResponse;
      }
    } catch (error) {
      await handleThunkError(
        'An error occurred while updating incident. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);
