import React from 'react';
import './ColorHeader.scss';
import { ApplicationState } from '../../../types/applicationState';
import { useDispatch, useSelector } from 'react-redux';
import { TrusthubSettingsModel } from '../../../swagger/trusthub/models/TrusthubSettingsModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { dashboardRoute } from '../../../components/Routes/Routes';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { TrusthubColorsList } from '../admin/TrustHubSettingsDrawer/TrustHubSettingsDrawer';

const backgroundColor = (settings: TrusthubSettingsModel) => {
  if (!settings.brandingColor) return null;
  if (!settings.useColourGradient) return settings.brandingColor;
  const gradientProps = [
    '90deg',
    '#EBF5FF 0%',
    `${settings.brandingColor} 100%`,
  ].join(', ');
  const gradient = `linear-gradient(${gradientProps})`;
  return gradient;
};

interface ColorHeaderProps {
  showBackButton: boolean;
  buttons?: JSX.Element;
}

export const ColorHeader: React.FC<ColorHeaderProps> = props => {
  const baseCss = 'trusthub-top-bar';
  const { settings } = useSelector((state: ApplicationState) => state.trustHub);
  const dispatch = useDispatch();
  const blackColor = [...TrusthubColorsList].pop();
  const defaultGreyColor = '#999995';
  const isLogoUploaded = settings.logo && settings.logo.urls.full;
  const placeholderColor = isLogoUploaded
    ? settings.brandingColor === blackColor
      ? '#fff'
      : blackColor
    : defaultGreyColor;
  return (
    <div style={{ background: backgroundColor(settings) }} className={baseCss}>
      <div className={baseCss + '--info'}>
        <div className={baseCss + '--backSection'}>
          {props.showBackButton && (
            <FontAwesomeIcon
              onClick={() => dispatch(push(dashboardRoute))}
              className={baseCss + '--backSectionLink'}
              icon={faAngleLeft}
            />
          )}

          {settings.logo && settings.logo.urls.full ? (
            <div className={baseCss + '--backSectionLogo'}>
              <img src={settings.logo.urls.full} />
            </div>
          ) : (
            <div className={baseCss + '--backSectionTitle'}>
              <div>Company Logo</div>
            </div>
          )}
        </div>

        <div className={baseCss + '--buttons'}>{props.buttons}</div>
      </div>
    </div>
  );
};
