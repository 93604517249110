import { handleThunkError } from '../../../../store/helpers/thunkHelpers';

import { VoidThunk } from '../../../../types/voidThunk';
import { api } from '../../../../api/api';
import {
  AssertionRunList,
  AssertionRunModel,
  VendorsVendorIdAssertionRunsGetRequest,
} from '../../../../swagger';
import {
  fetchAssertionRunsFail,
  fetchAssertionRunsRequest,
  fetchAssertionRunsSuccess,
  fetchAssertionCategoriesFail,
  fetchAssertionCategoriesRequest,
  fetchAssertionCategoriesSuccess,
  fetchAssertionRunExtendedRequest,
  fetchAssertionRunExtendedSuccess,
} from './testsSlice';

export const fetchAssertionRuns =
  (
    assertionCategoryIdentifier: string,
    assertionName: string,
    assertionStatus: string,
    showManual: boolean,
    page: string
  ): VoidThunk =>
  async (dispatch, getState) => {
    const vendorId = getState().vendors.currentVendor?.id;
    try {
      dispatch(fetchAssertionRunsRequest);
      const params: VendorsVendorIdAssertionRunsGetRequest = {
        assertionName,
        page,
        showManual,
        vendorId,
      };
      if (assertionCategoryIdentifier)
        params['assertionCategoryIdentifier'] = assertionCategoryIdentifier;
      if (assertionStatus) params['assertionStatus'] = assertionStatus;
      const response = await api().vendorsVendorIdAssertionRunsGet(params);
      dispatch(fetchAssertionRunsSuccess(response as AssertionRunList));
    } catch (error) {
      dispatch(fetchAssertionRunsFail);
      await handleThunkError(
        'An error occurred while fetching events log. Please try again or contact support.',
        { dispatch, error }
      );
    }
  };

export const fetchAssertionCategories =
  (): VoidThunk => async (dispatch, getState) => {
    try {
      dispatch(fetchAssertionCategoriesRequest);
      const response = await api().assertionCategoriesGet();
      dispatch(fetchAssertionCategoriesSuccess(response));
    } catch (error) {
      dispatch(fetchAssertionCategoriesFail);
      await handleThunkError(
        'An error occurred while fetching event categories. Please try again or contact support.',
        { dispatch, error }
      );
    }
  };

export const fetchAssertionRunExtended =
  (assertionRunId: AssertionRunModel['id']): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchAssertionRunExtendedRequest);
      const response = await api().assertionRunsAssertionRunIdGet({
        assertionRunId,
      });
      dispatch(fetchAssertionRunExtendedSuccess(response));
    } catch (error) {
      dispatch(fetchAssertionCategoriesFail);
      await handleThunkError(
        'An error occurred while fetching event details. Please try again or contact support.',
        { dispatch, error }
      );
    }
  };
