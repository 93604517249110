import { createSelector } from '@reduxjs/toolkit';
import Fuse from 'fuse.js';
import { sort } from '../../functions/sort';
import { sortByOwner } from '../../functions/sortByOwner';
import { RiskModel } from '../../swagger/models/RiskModel';
import { VendorUser } from '../../swagger/models/VendorUser';
import { ApplicationState } from '../../types/applicationState';
import { ColumnSort } from '../../types/columnSort';
import { Grid } from '../../types/grid';
import { selectSortSettings } from '../selectSortSettings';

const sortRisks = (a: RiskModel, b: RiskModel, columnSort: ColumnSort) => {
  const typedColumn = columnSort.name as keyof RiskModel;

  if (typedColumn === 'owner') {
    return sortByOwner(
      a[typedColumn] as VendorUser,
      b[typedColumn] as VendorUser,
      columnSort.direction
    );
  }

  const riskColumn =
    typedColumn === 'inherentRisk' || typedColumn === 'residualRisk';
  if (riskColumn) {
    const type = typedColumn === 'inherentRisk' ? 'inherent' : 'residual';

    const severity = (model: RiskModel) =>
      (model[`${type}RiskConsequence`] || 1) *
      (model[`${type}RiskLikelihood`] || 1);
    return sort(severity(a), severity(b), columnSort.direction);
  }
  if (typedColumn === 'actionStats') {
    return sort(a.actionStats.total, b.actionStats.total, columnSort.direction);
  }

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

export const filteredByTextRisks = (
  items: RiskModel[],
  searchText: string,
  searchColumns: string[]
) => {
  if (!items) return [];
  if (!searchText) return items;

  const fuseTask = new Fuse(items, {
    ignoreLocation: true,
    includeScore: true,
    keys: searchColumns,
    threshold: 0,
  });
  return fuseTask.search(searchText).map(x => x.item);
};

export const selectRisks = (searchText: string, searchColumns: string[]) =>
  createSelector(
    (state: ApplicationState) => state.riskRegistry.risks,
    selectSortSettings(Grid.Risks),
    (risks: RiskModel[], sortSetting) => {
      return [...filteredByTextRisks(risks, searchText, searchColumns)].sort(
        (a, b) => sortRisks(a, b, sortSetting.columnSort)
      );
    }
  );
