/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SharedAttachmentModel
 */
export interface SharedAttachmentModel {
  /**
   *
   * @type {number}
   * @memberof SharedAttachmentModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SharedAttachmentModel
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof SharedAttachmentModel
   */
  contentType: string;
  /**
   *
   * @type {string}
   * @memberof SharedAttachmentModel
   */
  url?: string;
}

export function SharedAttachmentModelFromJSON(
  json: any
): SharedAttachmentModel {
  return SharedAttachmentModelFromJSONTyped(json, false);
}

export function SharedAttachmentModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SharedAttachmentModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    filename: json['filename'],
    contentType: json['content_type'],
    url: !exists(json, 'url') ? undefined : json['url'],
  };
}

export function SharedAttachmentModelToJSON(
  value?: SharedAttachmentModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    filename: value.filename,
    content_type: value.contentType,
    url: value.url,
  };
}
