interface PaymentMethodState {
  card_number: string; //req
  card_cvc: string; //req
  card_exp_month: string; //req
  card_exp_year: string; //req
  name: string;
  address_line1: string;
  address_line2: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  address_country: string;
}

export const initialState: PaymentMethodState = {
  card_number: '',
  card_cvc: '',
  card_exp_month: '',
  card_exp_year: '',
  name: '',
  address_line1: '',
  address_line2: '',
  address_city: '',
  address_state: '',
  address_zip: '',
  address_country: '',
};

export enum PaymentMethodActionType {
  Change,
  Cleanup,
}

export type ChangePaymentMethodAction = {
  type: PaymentMethodActionType;
  payload?: { [key: string]: string };
};

export type PaymentMethodAction = ChangePaymentMethodAction;

export const paymentMethodReducer = (
  state: PaymentMethodState,
  action: PaymentMethodAction
): PaymentMethodState => {
  switch (action.type) {
    case PaymentMethodActionType.Change:
      return {
        ...state,
        ...action.payload,
      };
    case PaymentMethodActionType.Cleanup:
      return initialState;
    default:
      return state;
  }
};
