import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitaliseFirst } from '../../../functions/capitaliseFirst';
import { useOwnerOptions } from '../../../hooks/useOwnerOptions';
import { updatePolicyToEditField } from '../../../store/policies/policiesSlice';
import {
  ReviewFrequencyEnum,
  VendorDocumentExtended,
  VendorDocumentStatusEnum,
  VendorPolicyDetails,
  VendorPolicyDetailsStatusEnum,
} from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { SelectionOption } from '../../../types/selectionOption';
import { AdoptechReactSelect2 } from '../../AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechTextInput2 } from '../../AdoptechTextInput2/AdoptechTextInput2';
import './PolicyOverviewSection.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { formatShortDate } from '../../../functions/formatShortDate';
import moment, { DurationInputArg1, DurationInputArg2 } from 'moment';
import { selectLivePolicies } from '../../../selectors/selectLivePolicies';

export const calculateNextReviewDate = (
  createdAt: string,
  reviewFrequency: string
): string => {
  const date = moment(createdAt);
  // ex: '5 months'
  const [periodCount, periodWord] = reviewFrequency.split(' ');
  return date
    .add(periodCount as DurationInputArg1, periodWord as DurationInputArg2)
    .toISOString();
};

export interface PolicyOverviewSection {
  name: VendorPolicyDetails['name'] | VendorDocumentExtended['name'];
  owner: VendorPolicyDetails['owner'] | VendorDocumentExtended['owner'];
  overviewSectionLoaded: boolean;
}

export const PolicyOverviewSection: React.FC = () => {
  const dispatch = useDispatch();
  const policy = useSelector(
    (state: ApplicationState) => state.policies.policyToEdit
  );
  const { name, owner, reviewFrequency, createdAt } = policy;

  const setOwnerId = (id: string) =>
    dispatch(updatePolicyToEditField({ ownerId: id }));

  const { users, ownerOptions } = useOwnerOptions({
    onlyAdminable: true,
  });

  const [selectedOwnerOption, setSelectedOwnerOption] =
    useState<SelectionOption>(
      ownerOptions.find(option => option.value === owner?.id)
    );

  const reviewFrequencyOptions = Object.values(ReviewFrequencyEnum).map(
    value => {
      return { label: value, value };
    }
  );
  const [selectedReviewFrequencyOption, setSelectedReviewFrequencyOption] =
    useState<SelectionOption>(
      reviewFrequencyOptions.find(option => option.value === reviewFrequency)
    );
  const baseCss = 'policyOverview';

  enum PolicyMode {
    Draft = 'draft',
    WaitingForApproval = 'waiting_for_approval',
    WaitingForAttestation = 'waiting_for_attestation',
    Live = 'live',
    Rejected = 'rejected',
    Available = 'available',
  }

  const selectPolicyMode = () => {
    switch (policy.status) {
      case VendorPolicyDetailsStatusEnum.InProgress:
        return PolicyMode.Draft;
      case VendorPolicyDetailsStatusEnum.Available:
        return PolicyMode.Available;
      case VendorPolicyDetailsStatusEnum.Completed:
      case VendorDocumentStatusEnum.Complete:
        return PolicyMode.Live;
      case VendorDocumentStatusEnum.Approval:
        return PolicyMode.WaitingForApproval;
      case VendorDocumentStatusEnum.Attestation:
        return PolicyMode.WaitingForAttestation;
      case VendorDocumentStatusEnum.Rejected:
        return PolicyMode.Rejected;
      default:
        return PolicyMode.Draft;
    }
  };

  const policyMode = selectPolicyMode();
  const livePolicies = useSelector(selectLivePolicies);
  const isLive = livePolicies
    .map(livePolicy => livePolicy.id)
    .includes(policy.id);
  const nextReviewDate =
    isLive && reviewFrequency && createdAt
      ? calculateNextReviewDate(createdAt, reviewFrequency)
      : null;

  return (
    <div className={classNames(baseCss, { 'pb-4': nextReviewDate })}>
      <div className={baseCss + '--section-labels'}>
        <div className={baseCss + '--section-badge'}>
          {capitaliseFirst(policyMode)}
        </div>
      </div>

      <div className="mt-2">
        <AdoptechTextInput2
          id="policy-name"
          onChange={() => {}}
          type="text"
          label="Policy name"
          value={name}
          disabled
        />
      </div>

      <div className={classNames(baseCss + '--2-columns', 'mt-2')}>
        <AdoptechReactSelect2
          id="policy-owner"
          options={ownerOptions}
          onChange={(selectedOption: SelectionOption) => {
            const selectedOwner = users.find(
              user => user.id === selectedOption.value
            );
            setOwnerId(selectedOwner.id);
            setSelectedOwnerOption(selectedOption);
          }}
          value={selectedOwnerOption}
          placeholder="Select owner"
          label="Owner"
          showUserAvatar
        />
        <AdoptechReactSelect2
          id="review-frequency"
          options={reviewFrequencyOptions}
          onChange={(selectedOption: SelectionOption) => {
            setSelectedReviewFrequencyOption(selectedOption);
            dispatch(
              updatePolicyToEditField({
                reviewFrequency: selectedOption.value,
              })
            );
          }}
          value={selectedReviewFrequencyOption}
          placeholder="Set review frequency"
          label="Next review"
          bottomComponent={
            nextReviewDate ? (
              <div className="nextReviewDateHint">
                <FontAwesomeIcon icon={faInfoCircle} />
                {`Next review on ${formatShortDate(nextReviewDate)}`}
              </div>
            ) : null
          }
        />
      </div>
    </div>
  );
};
