/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AnswerSetResponseCounts,
  AnswerSetResponseCountsFromJSON,
  AnswerSetResponseCountsFromJSONTyped,
  AnswerSetResponseCountsToJSON,
  Question,
  QuestionFromJSON,
  QuestionFromJSONTyped,
  QuestionToJSON,
} from './';

/**
 *
 * @export
 * @interface Category
 */
export interface Category {
  /**
   *
   * @type {string}
   * @memberof Category
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  name?: string;
  /**
   *
   * @type {AnswerSetResponseCounts}
   * @memberof Category
   */
  responseCounts?: AnswerSetResponseCounts;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  status?: CategoryStatusEnum;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  statusText?: string;
  /**
   *
   * @type {Array<Question>}
   * @memberof Category
   */
  questions?: Array<Question>;
}

export function CategoryFromJSON(json: any): Category {
  return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Category {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    responseCounts: !exists(json, 'response_counts')
      ? undefined
      : AnswerSetResponseCountsFromJSON(json['response_counts']),
    status: !exists(json, 'status') ? undefined : json['status'],
    statusText: !exists(json, 'status_text') ? undefined : json['status_text'],
    questions: !exists(json, 'questions')
      ? undefined
      : (json['questions'] as Array<any>).map(QuestionFromJSON),
  };
}

export function CategoryToJSON(value?: Category | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    response_counts: AnswerSetResponseCountsToJSON(value.responseCounts),
    status: value.status,
    status_text: value.statusText,
    questions:
      value.questions === undefined
        ? undefined
        : (value.questions as Array<any>).map(QuestionToJSON),
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CategoryStatusEnum {
  InProgress = 'in_progress',
  Completed = 'completed',
}
