/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorIntegrationSyncCreatePayloadVendorIntegrationSync,
  VendorIntegrationSyncCreatePayloadVendorIntegrationSyncFromJSON,
  VendorIntegrationSyncCreatePayloadVendorIntegrationSyncFromJSONTyped,
  VendorIntegrationSyncCreatePayloadVendorIntegrationSyncToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorIntegrationSyncCreatePayload
 */
export interface VendorIntegrationSyncCreatePayload {
  /**
   *
   * @type {VendorIntegrationSyncCreatePayloadVendorIntegrationSync}
   * @memberof VendorIntegrationSyncCreatePayload
   */
  vendorIntegrationSync: VendorIntegrationSyncCreatePayloadVendorIntegrationSync;
}

export function VendorIntegrationSyncCreatePayloadFromJSON(
  json: any
): VendorIntegrationSyncCreatePayload {
  return VendorIntegrationSyncCreatePayloadFromJSONTyped(json, false);
}

export function VendorIntegrationSyncCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorIntegrationSyncCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vendorIntegrationSync:
      VendorIntegrationSyncCreatePayloadVendorIntegrationSyncFromJSON(
        json['vendor_integration_sync']
      ),
  };
}

export function VendorIntegrationSyncCreatePayloadToJSON(
  value?: VendorIntegrationSyncCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vendor_integration_sync:
      VendorIntegrationSyncCreatePayloadVendorIntegrationSyncToJSON(
        value.vendorIntegrationSync
      ),
  };
}
