/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubDocumentModel,
  TrusthubDocumentModelFromJSON,
  TrusthubDocumentModelFromJSONTyped,
  TrusthubDocumentModelToJSON,
  TrusthubSharedStatus,
  TrusthubSharedStatusFromJSON,
  TrusthubSharedStatusFromJSONTyped,
  TrusthubSharedStatusToJSON,
  TrusthubVendorUserModel,
  TrusthubVendorUserModelFromJSON,
  TrusthubVendorUserModelFromJSONTyped,
  TrusthubVendorUserModelToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubAccessRequestModel
 */
export interface TrusthubAccessRequestModel {
  /**
   *
   * @type {string}
   * @memberof TrusthubAccessRequestModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubAccessRequestModel
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubAccessRequestModel
   */
  updatedAt?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TrusthubAccessRequestModel
   */
  all?: boolean;
  /**
   *
   * @type {TrusthubSharedStatus}
   * @memberof TrusthubAccessRequestModel
   */
  status: TrusthubSharedStatus;
  /**
   *
   * @type {TrusthubVendorUserModel}
   * @memberof TrusthubAccessRequestModel
   */
  trusthubVendorUser?: TrusthubVendorUserModel;
  /**
   *
   * @type {TrusthubVendorUserModel}
   * @memberof TrusthubAccessRequestModel
   */
  createdBy?: TrusthubVendorUserModel;
  /**
   *
   * @type {Array<TrusthubDocumentModel>}
   * @memberof TrusthubAccessRequestModel
   */
  trusthubDocuments?: Array<TrusthubDocumentModel>;
}

export function TrusthubAccessRequestModelFromJSON(
  json: any
): TrusthubAccessRequestModel {
  return TrusthubAccessRequestModelFromJSONTyped(json, false);
}

export function TrusthubAccessRequestModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubAccessRequestModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    all: !exists(json, 'all') ? undefined : json['all'],
    status: TrusthubSharedStatusFromJSON(json['status']),
    trusthubVendorUser: !exists(json, 'trusthub_vendor_user')
      ? undefined
      : TrusthubVendorUserModelFromJSON(json['trusthub_vendor_user']),
    createdBy: !exists(json, 'created_by')
      ? undefined
      : TrusthubVendorUserModelFromJSON(json['created_by']),
    trusthubDocuments: !exists(json, 'trusthub_documents')
      ? undefined
      : (json['trusthub_documents'] as Array<any>).map(
          TrusthubDocumentModelFromJSON
        ),
  };
}

export function TrusthubAccessRequestModelToJSON(
  value?: TrusthubAccessRequestModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    all: value.all,
    status: TrusthubSharedStatusToJSON(value.status),
    trusthub_vendor_user: TrusthubVendorUserModelToJSON(
      value.trusthubVendorUser
    ),
    created_by: TrusthubVendorUserModelToJSON(value.createdBy),
    trusthub_documents:
      value.trusthubDocuments === undefined
        ? undefined
        : (value.trusthubDocuments as Array<any>).map(
            TrusthubDocumentModelToJSON
          ),
  };
}
