/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FrameworkModelStatsPolicies
 */
export interface FrameworkModelStatsPolicies {
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsPolicies
   */
  count?: number;
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsPolicies
   */
  progress?: number;
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsPolicies
   */
  live?: number;
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsPolicies
   */
  requestedToApprove?: number;
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsPolicies
   */
  requestedToRead?: number;
}

export function FrameworkModelStatsPoliciesFromJSON(
  json: any
): FrameworkModelStatsPolicies {
  return FrameworkModelStatsPoliciesFromJSONTyped(json, false);
}

export function FrameworkModelStatsPoliciesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkModelStatsPolicies {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    count: !exists(json, 'count') ? undefined : json['count'],
    progress: !exists(json, 'progress') ? undefined : json['progress'],
    live: !exists(json, 'live') ? undefined : json['live'],
    requestedToApprove: !exists(json, 'requested_to_approve')
      ? undefined
      : json['requested_to_approve'],
    requestedToRead: !exists(json, 'requested_to_read')
      ? undefined
      : json['requested_to_read'],
  };
}

export function FrameworkModelStatsPoliciesToJSON(
  value?: FrameworkModelStatsPolicies | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    count: value.count,
    progress: value.progress,
    live: value.live,
    requested_to_approve: value.requestedToApprove,
    requested_to_read: value.requestedToRead,
  };
}
