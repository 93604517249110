import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const Welcome: React.FC = () => {
  const dispatch = useDispatch();

  dispatch(push('/r/welcome/profile'));

  return null;
};

export default Welcome;
