/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ErrorResponseErrors,
  ErrorResponseErrorsFromJSON,
  ErrorResponseErrorsFromJSONTyped,
  ErrorResponseErrorsToJSON,
  VendorIntegrationSyncModel,
  VendorIntegrationSyncModelFromJSON,
  VendorIntegrationSyncModelFromJSONTyped,
  VendorIntegrationSyncModelToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorIntegrationSyncExtended
 */
export interface VendorIntegrationSyncExtended
  extends VendorIntegrationSyncModel {}

export function VendorIntegrationSyncExtendedFromJSON(
  json: any
): VendorIntegrationSyncExtended {
  return VendorIntegrationSyncExtendedFromJSONTyped(json, false);
}

export function VendorIntegrationSyncExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorIntegrationSyncExtended {
  return json;
}

export function VendorIntegrationSyncExtendedToJSON(
  value?: VendorIntegrationSyncExtended | null
): any {
  return value;
}
