import {
  JurisdictionModel,
  LegislationClassificationModel,
  LegislationExtended,
} from '../../../swagger';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import { AdoptechAccordionCard } from '../../../components/AdoptechAccordionCard/AdoptechAccordionCard';
import { isValidUrl } from '../../../functions/isValidUrl';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import {
  fetchClassifications,
  fetchJurisdictions,
} from '../../../store/legalRegister/legalRegisterThunks';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { AdoptechReactSelect } from '../../../components/AdoptechReactSelect/AdoptechReactSelect';

export const validation = (
  data: LegislationExtended
): Record<string, string[]> => ({
  name: (data.name || '').trim().length > 0 ? null : ['Name must be present'],
  classification: data.legislationClassification
    ? null
    : ['Classification must be set'],
  summary:
    (data.summary || '').trim().length > 0 ? null : ['Summary must be present'],
  jurisdiction: data.jurisdiction ? null : ['Jurisdiction must be set'],
  link:
    !data.descriptionUrl || isValidUrl(data.descriptionUrl)
      ? null
      : ['Link must be valid URL'],
});

interface Props {
  formData: LegislationExtended;
  setFormData: Dispatch<SetStateAction<LegislationExtended>>;
}

export const OverviewSection: React.FC<Props> = ({ formData, setFormData }) => {
  const baseCss = 'editLegislationDrawer';

  const dispatch = useDispatch();

  const [jurisdictions, setJurisdictions] = useState<JurisdictionModel[]>(null);
  const [classifications, setClassifications] =
    useState<LegislationClassificationModel[]>(null);

  useEffect(() => {
    dispatch(fetchJurisdictions(data => setJurisdictions(data)));
    dispatch(fetchClassifications(data => setClassifications(data)));
  }, []);

  const hasError = (field: string) => !!validation(formData)[field];

  const classificationOptions = () =>
    (classifications || []).map(c => ({
      label: c.name,
      value: c.id,
    }));

  const jurisdictionOptions = () =>
    (jurisdictions || []).map(j => ({
      label: j.name,
      value: j.id,
    }));

  const id = formData.id;

  return (
    <Accordion defaultActiveKey="0">
      <AdoptechAccordionCard
        title="Overview"
        index="0"
        cardBodyClass="card-body--centered withBorderBottom"
        headerClass="noBorderBottom positionInitial"
        iconSize="small"
        noMargin
      >
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Legislation name</div>
            <AdoptechTextInput
              id={`legislation-${id}-nameInput`}
              value={formData?.name}
              type="text"
              hasError={hasError('name')}
              onChange={e => {
                setFormData({ ...formData, name: e.currentTarget.value });
              }}
              placeholder="Enter legislation name"
            />
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Classification</div>
            {classifications ? (
              <AdoptechReactSelect
                id={`legislation-${id}-classificationInput`}
                options={classificationOptions()}
                hasError={hasError('classification')}
                onChange={e => {
                  setFormData({
                    ...formData,
                    legislationClassification: classifications.find(
                      c => c.id === e.value
                    ),
                  });
                }}
                value={classificationOptions().find(
                  o => o.value === formData.legislationClassification.id
                )}
                placeholder="Select classification"
              />
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Summary</div>
            <AdoptechTextInput
              id={`legislation-${id}-summaryInput`}
              value={formData?.summary}
              type="text"
              hasError={hasError('summary')}
              onChange={e => {
                setFormData({ ...formData, summary: e.currentTarget.value });
              }}
              placeholder="Enter summary"
            />
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Jurisdiction</div>
            {jurisdictions ? (
              <AdoptechReactSelect
                id={`legislation-${id}-jurisdictionInput`}
                options={jurisdictionOptions()}
                hasError={hasError('jurisdiction')}
                onChange={e => {
                  setFormData({
                    ...formData,
                    jurisdiction: jurisdictions.find(j => j.id === e.value),
                  });
                }}
                value={jurisdictionOptions().find(
                  o => o.value === formData.jurisdiction.id
                )}
                placeholder="Select jurisdiction"
              />
            ) : (
              <LoadingSpinner />
            )}
          </div>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>URL link</div>
            <AdoptechTextInput
              id={`legislation-${id}-linkInput`}
              value={formData?.descriptionUrl}
              type="text"
              hasError={hasError('link')}
              onChange={e => {
                setFormData({
                  ...formData,
                  descriptionUrl: e.currentTarget.value,
                });
              }}
              placeholder="Enter URL"
            />
          </div>
        </div>
      </AdoptechAccordionCard>
    </Accordion>
  );
};
