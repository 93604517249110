/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CompanyIdentifierModel,
  CompanyIdentifierModelFromJSON,
  CompanyIdentifierModelFromJSONTyped,
  CompanyIdentifierModelToJSON,
  Office,
  OfficeFromJSON,
  OfficeFromJSONTyped,
  OfficeToJSON,
} from './';

/**
 *
 * @export
 * @interface CompanyUpdatePayload
 */
export interface CompanyUpdatePayload {
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  legalName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  tradeName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  registeredAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  primaryIdentifier?: string | null;
  /**
   *
   * @type {Array<CompanyIdentifierModel>}
   * @memberof CompanyUpdatePayload
   */
  companyIdentifiers?: Array<CompanyIdentifierModel>;
  /**
   *
   * @type {Array<Office>}
   * @memberof CompanyUpdatePayload
   */
  offices?: Array<Office>;
  /**
   *
   * @type {boolean}
   * @memberof CompanyUpdatePayload
   */
  preferTradingName?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  brandingColor?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  workforceModel?: CompanyUpdatePayloadWorkforceModelEnum;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  businessSummary?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  companyType?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  incorporatedOn?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CompanyUpdatePayload
   */
  visiblePolicyFrameworkReferences?: Array<CompanyUpdatePayloadVisiblePolicyFrameworkReferencesEnum>;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  legalEntityIdentifier?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  currencyCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  financialEntityType?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  financialEntityName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyUpdatePayload
   */
  competentAuthority?: string | null;
}

export function CompanyUpdatePayloadFromJSON(json: any): CompanyUpdatePayload {
  return CompanyUpdatePayloadFromJSONTyped(json, false);
}

export function CompanyUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    legalName: !exists(json, 'legal_name') ? undefined : json['legal_name'],
    tradeName: !exists(json, 'trade_name') ? undefined : json['trade_name'],
    registeredAddress: !exists(json, 'registered_address')
      ? undefined
      : json['registered_address'],
    primaryIdentifier: !exists(json, 'primary_identifier')
      ? undefined
      : json['primary_identifier'],
    companyIdentifiers: !exists(json, 'company_identifiers')
      ? undefined
      : (json['company_identifiers'] as Array<any>).map(
          CompanyIdentifierModelFromJSON
        ),
    offices: !exists(json, 'offices')
      ? undefined
      : (json['offices'] as Array<any>).map(OfficeFromJSON),
    preferTradingName: !exists(json, 'prefer_trading_name')
      ? undefined
      : json['prefer_trading_name'],
    brandingColor: !exists(json, 'branding_color')
      ? undefined
      : json['branding_color'],
    workforceModel: !exists(json, 'workforce_model')
      ? undefined
      : json['workforce_model'],
    businessSummary: !exists(json, 'business_summary')
      ? undefined
      : json['business_summary'],
    companyType: !exists(json, 'company_type')
      ? undefined
      : json['company_type'],
    incorporatedOn: !exists(json, 'incorporated_on')
      ? undefined
      : json['incorporated_on'],
    visiblePolicyFrameworkReferences: !exists(
      json,
      'visible_policy_framework_references'
    )
      ? undefined
      : json['visible_policy_framework_references'],
    legalEntityIdentifier: !exists(json, 'legal_entity_identifier')
      ? undefined
      : json['legal_entity_identifier'],
    currencyCode: !exists(json, 'currency_code')
      ? undefined
      : json['currency_code'],
    financialEntityType: !exists(json, 'financial_entity_type')
      ? undefined
      : json['financial_entity_type'],
    financialEntityName: !exists(json, 'financial_entity_name')
      ? undefined
      : json['financial_entity_name'],
    competentAuthority: !exists(json, 'competent_authority')
      ? undefined
      : json['competent_authority'],
  };
}

export function CompanyUpdatePayloadToJSON(
  value?: CompanyUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    legal_name: value.legalName,
    trade_name: value.tradeName,
    registered_address: value.registeredAddress,
    primary_identifier: value.primaryIdentifier,
    company_identifiers:
      value.companyIdentifiers === undefined
        ? undefined
        : (value.companyIdentifiers as Array<any>).map(
            CompanyIdentifierModelToJSON
          ),
    offices:
      value.offices === undefined
        ? undefined
        : (value.offices as Array<any>).map(OfficeToJSON),
    prefer_trading_name: value.preferTradingName,
    branding_color: value.brandingColor,
    workforce_model: value.workforceModel,
    business_summary: value.businessSummary,
    company_type: value.companyType,
    incorporated_on: value.incorporatedOn,
    visible_policy_framework_references: value.visiblePolicyFrameworkReferences,
    legal_entity_identifier: value.legalEntityIdentifier,
    currency_code: value.currencyCode,
    financial_entity_type: value.financialEntityType,
    financial_entity_name: value.financialEntityName,
    competent_authority: value.competentAuthority,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CompanyUpdatePayloadWorkforceModelEnum {
  Distributed = 'distributed',
  Hybrid = 'hybrid',
  Office = 'office',
}
/**
 * @export
 * @enum {string}
 */
export enum CompanyUpdatePayloadVisiblePolicyFrameworkReferencesEnum {
  All = 'all',
  Iso27001 = 'iso_27001',
  Iso270012022 = 'iso_27001_2022',
  Soc2 = 'soc_2',
  CyberEssentials = 'cyber_essentials',
}
