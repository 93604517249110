import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { backFromSendAssessmentConfirmationModal } from '../../store/assessments/assessmentsSlice';
import { sendAssessment } from '../../store/assessments/assessmentsThunks';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './SendAssessmentConfirmationModal.scss';

export const SendAssessmentConfirmationModal: React.FC = () => {
  const {
    currentAssessment,
    isSendingAssessment,
    isShowingSendAssessmentConfirmationModal,
    sendAssessmentDetails,
  } = useSelector((state: ApplicationState) => state.assessments);

  const dispatch = useDispatch();

  return (
    <AdoptechModal
      onHide={null}
      show={isShowingSendAssessmentConfirmationModal}
    >
      <Modal.Header>Confirmation required</Modal.Header>
      <Modal.Body className="sendAssessmentConfirmationModal--body">
        <div className="sendAssessmentConfirmationModal--instructions">
          Please confirm you wish to send the following assessment out to{' '}
          {sendAssessmentDetails ? sendAssessmentDetails.count : ''} recipients:
        </div>
        <div className="sendAssessmentConfirmationModal--name">
          {currentAssessment?.name}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={() => dispatch(backFromSendAssessmentConfirmationModal())}
        >
          Back
        </AdoptechButton>
        <AdoptechButton
          busy={isSendingAssessment}
          disabled={isSendingAssessment}
          onClick={() => dispatch(sendAssessment())}
          variant={AdoptechButtonVariant.Primary}
        >
          CONFIRM
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
