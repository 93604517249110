import { createSelector } from '@reduxjs/toolkit';
import { AgreementModel, AgreementModelStatusEnum } from '../swagger';
import { ApplicationState } from '../types/applicationState';

export const selectCompletedAgreements = createSelector(
  (state: ApplicationState) => state.agreements.agreements,
  (agreements: AgreementModel[]) =>
    agreements.filter(
      (agreement: AgreementModel) =>
        agreement.status === AgreementModelStatusEnum.Signed
    )
);
