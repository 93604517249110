/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubCategorySectionIdentifier,
  TrusthubCategorySectionIdentifierFromJSON,
  TrusthubCategorySectionIdentifierFromJSONTyped,
  TrusthubCategorySectionIdentifierToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubCategoryModel
 */
export interface TrusthubCategoryModel {
  /**
   *
   * @type {string}
   * @memberof TrusthubCategoryModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubCategoryModel
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubCategoryModel
   */
  updatedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubCategoryModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubCategoryModel
   */
  identifier: string;
  /**
   *
   * @type {number}
   * @memberof TrusthubCategoryModel
   */
  rowOrder: number | null;
  /**
   *
   * @type {TrusthubCategorySectionIdentifier}
   * @memberof TrusthubCategoryModel
   */
  sectionIdentifier: TrusthubCategorySectionIdentifier;
}

export function TrusthubCategoryModelFromJSON(
  json: any
): TrusthubCategoryModel {
  return TrusthubCategoryModelFromJSONTyped(json, false);
}

export function TrusthubCategoryModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubCategoryModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    name: json['name'],
    identifier: json['identifier'],
    rowOrder: json['row_order'],
    sectionIdentifier: TrusthubCategorySectionIdentifierFromJSON(
      json['section_identifier']
    ),
  };
}

export function TrusthubCategoryModelToJSON(
  value?: TrusthubCategoryModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    name: value.name,
    identifier: value.identifier,
    row_order: value.rowOrder,
    section_identifier: TrusthubCategorySectionIdentifierToJSON(
      value.sectionIdentifier
    ),
  };
}
