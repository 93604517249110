import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';

import { CertificatesEditSectionProps } from '../EditSectionRow/EditSectionRow';
import './ControlsEditSection.scss';
import { uniqArrayObjects } from '../../../../functions/uniqueArrayObjects';
import { useSelector } from 'react-redux';
import { selectCurrentVendor } from '../../../../selectors/selectCurrentVendor';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ControlEditLeftPanel } from './ControlEditLeftPanel';
import { ControlEditRightPanel } from './ControlEditRightPanel';

export const ControlsEditSection: React.FC<CertificatesEditSectionProps> = ({
  titleBar,
  editSectionHandler,
  baseCss,
}) => {
  const {
    fetchingCurrentSection,
    fetchCurrentSection,
    formDocuments,
    handleDeselectAll,
    fetchCategories,
    sortedDocsToEdit,
  } = editSectionHandler;
  const vendor = useSelector(selectCurrentVendor);
  useEffect(() => {
    fetchCurrentSection();
    fetchCategories(vendor.id);
  }, []);

  const sectionDoc = (doc: { documentId: any }) =>
    sortedDocsToEdit.find(sortedDoc => sortedDoc.documentId === doc.documentId);
  const frameworksFilterOptions = [
    { value: null, label: 'Filter frameworks' },
    ...uniqArrayObjects(
      formDocuments.map(doc => {
        return {
          value: sectionDoc(doc)?.extra?.frameworkName,
          label: sectionDoc(doc)?.extra?.frameworkName,
        };
      }),
      'value'
    ).sort((a, b) => (a.value < b.value ? -1 : 1)),
  ];
  const [selectedFrameworkName, setSelectedFrameworkName] = useState(null);
  const handleFrameworksFilterChange = (e: { value: any }) => {
    setSelectedFrameworkName(e.value);
    handleDeselectAll();
  };

  return (
    <div className={baseCss}>
      <div className={baseCss + '--panel' + ' editMode'}>
        {titleBar}
        {fetchingCurrentSection && <LoadingSpinner />}
        {!fetchingCurrentSection && (
          <DndProvider backend={HTML5Backend}>
            <div className={baseCss + '--tables'}>
              <ControlEditLeftPanel
                baseCss={baseCss}
                frameworksFilterOptions={frameworksFilterOptions}
                handleFrameworksFilterChange={handleFrameworksFilterChange}
                selectedFrameworkName={selectedFrameworkName}
                editSectionHandler={editSectionHandler}
              />
              <ControlEditRightPanel
                baseCss={baseCss}
                editSectionHandler={editSectionHandler}
              />
            </div>
          </DndProvider>
        )}
      </div>
    </div>
  );
};
