/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubInvitationCreatePayloadTrusthubUser,
  TrusthubInvitationCreatePayloadTrusthubUserFromJSON,
  TrusthubInvitationCreatePayloadTrusthubUserFromJSONTyped,
  TrusthubInvitationCreatePayloadTrusthubUserToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubInvitationCreatePayload
 */
export interface TrusthubInvitationCreatePayload {
  /**
   *
   * @type {TrusthubInvitationCreatePayloadTrusthubUser}
   * @memberof TrusthubInvitationCreatePayload
   */
  trusthubUser: TrusthubInvitationCreatePayloadTrusthubUser;
}

export function TrusthubInvitationCreatePayloadFromJSON(
  json: any
): TrusthubInvitationCreatePayload {
  return TrusthubInvitationCreatePayloadFromJSONTyped(json, false);
}

export function TrusthubInvitationCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubInvitationCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    trusthubUser: TrusthubInvitationCreatePayloadTrusthubUserFromJSON(
      json['trusthub_user']
    ),
  };
}

export function TrusthubInvitationCreatePayloadToJSON(
  value?: TrusthubInvitationCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    trusthub_user: TrusthubInvitationCreatePayloadTrusthubUserToJSON(
      value.trusthubUser
    ),
  };
}
