import moment from 'moment';

export const formatRelativeDate = (date: string) => {
  if (!date) return;
  const dateObject = moment(date);
  const today = dateObject.isSame(moment(), 'day');
  const yesterday = dateObject.isSame(moment().subtract(1, 'days'), 'day');

  if (today) return 'Today';
  if (yesterday) return '1 day ago';
  return dateObject.fromNow();
};
