import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../../types/applicationState';
import { selectCurrentVendor } from '../../../../../../selectors/selectCurrentVendor';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { useParams } from 'react-router';
import { TrusthubVendorUserModel } from '../../../../../../swagger/trusthub';
import './TrustHubUserAdminUserPage.scss';
import { fetchTrustHubVendorUser } from '../../../../store/trusthubThunks';
import { TrustHubUserAdminEditUserForm } from '../TrustHubUserAdminEditUserForm/TrustHubUserAdminEditUserForm';
import { TrustHubUserAdminDocumentPermissions } from '../TrustHubUserAdminDocumentPermissions/TrustHubUserAdminDocumentPermissions';
import { TrustHubUserAdminVendorUserActivities } from '../TrustHubUserAdminVendorUserActivities/TrustHubUserAdminVendorUserActivities';

export const TrustHubUserAdminUserPage: React.FC = () => {
  const baseCss = 'trusthubUserAdminUserPage';
  const {
    fetchingSettingsStatus,
    currentVendorUser: user,
    fetchingCurrentVendorUserStatus,
  } = useSelector((state: ApplicationState) => state.trustHub);
  const dispatch = useDispatch();
  const vendor = useSelector(selectCurrentVendor);
  const params = useParams() as { id: TrusthubVendorUserModel['id'] };

  useEffect(() => {
    dispatch(fetchTrustHubVendorUser({ vendorUserId: params.id }, () => {}));
  }, [vendor.id]);
  if (
    fetchingSettingsStatus === 'loading' ||
    fetchingCurrentVendorUserStatus === 'loading' ||
    !user
  ) {
    return <LoadingSpinner />;
  }

  return (
    <div className={baseCss}>
      <TrustHubUserAdminEditUserForm />
      <TrustHubUserAdminDocumentPermissions />
      <TrustHubUserAdminVendorUserActivities />
    </div>
  );
};
