/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssertionResultExcludePayloadAssertionResult,
  AssertionResultExcludePayloadAssertionResultFromJSON,
  AssertionResultExcludePayloadAssertionResultFromJSONTyped,
  AssertionResultExcludePayloadAssertionResultToJSON,
} from './';

/**
 *
 * @export
 * @interface AssertionResultExcludePayload
 */
export interface AssertionResultExcludePayload {
  /**
   *
   * @type {AssertionResultExcludePayloadAssertionResult}
   * @memberof AssertionResultExcludePayload
   */
  assertionResult: AssertionResultExcludePayloadAssertionResult;
}

export function AssertionResultExcludePayloadFromJSON(
  json: any
): AssertionResultExcludePayload {
  return AssertionResultExcludePayloadFromJSONTyped(json, false);
}

export function AssertionResultExcludePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionResultExcludePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assertionResult: AssertionResultExcludePayloadAssertionResultFromJSON(
      json['assertion_result']
    ),
  };
}

export function AssertionResultExcludePayloadToJSON(
  value?: AssertionResultExcludePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assertion_result: AssertionResultExcludePayloadAssertionResultToJSON(
      value.assertionResult
    ),
  };
}
