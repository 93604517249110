/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ControlCategoryTemplateExtendedAllOf,
  ControlCategoryTemplateExtendedAllOfFromJSON,
  ControlCategoryTemplateExtendedAllOfFromJSONTyped,
  ControlCategoryTemplateExtendedAllOfToJSON,
  ControlCategoryTemplateModel,
  ControlCategoryTemplateModelFromJSON,
  ControlCategoryTemplateModelFromJSONTyped,
  ControlCategoryTemplateModelToJSON,
  ControlTemplateExtended,
  ControlTemplateExtendedFromJSON,
  ControlTemplateExtendedFromJSONTyped,
  ControlTemplateExtendedToJSON,
} from './';

/**
 *
 * @export
 * @interface ControlCategoryTemplateExtended
 */
export interface ControlCategoryTemplateExtended
  extends ControlCategoryTemplateModel {
  /**
   *
   * @type {Array<ControlTemplateExtended>}
   * @memberof ControlCategoryTemplateExtended
   */
  controlTemplates: Array<ControlTemplateExtended>;
}

export function ControlCategoryTemplateExtendedFromJSON(
  json: any
): ControlCategoryTemplateExtended {
  return ControlCategoryTemplateExtendedFromJSONTyped(json, false);
}

export function ControlCategoryTemplateExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlCategoryTemplateExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...ControlCategoryTemplateModelFromJSONTyped(json, ignoreDiscriminator),
    controlTemplates: (json['control_templates'] as Array<any>).map(
      ControlTemplateExtendedFromJSON
    ),
  };
}

export function ControlCategoryTemplateExtendedToJSON(
  value?: ControlCategoryTemplateExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...ControlCategoryTemplateModelToJSON(value),
    control_templates: (value.controlTemplates as Array<any>).map(
      ControlTemplateExtendedToJSON
    ),
  };
}
