import React, { useState } from 'react';
import { ReportModel } from '../../../swagger/models/ReportModel';
import { useDispatch } from 'react-redux';
import { NoDataText } from '../../NoDataText/NoDataText';
import AdoptechGridTable from '../../AdoptechGridTable/AdoptechGridTable';
import { SortableTableHeader } from '../../SortableTableHeader/SortableTableHeader';
import { GenericModal } from '../../GenericModal/GenericModal';
import {
  deleteReport,
  fetchReportDocument,
} from '../../../store/reports/reportsThunks';
import { Grid } from '../../../types/grid';
import AdoptechOverflowLine from '../../AdoptechOverflowLine/AdoptechOverflowLine';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { formatShortDate } from '../../../functions/formatShortDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdoptechTooltip } from '../../AdoptechTooltip/AdoptechTooltip';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import './ReportsList.scss';
import { AdoptechPanel } from '../../AdoptechPanel/AdoptechPanel';
import { push } from 'connected-react-router';
import { reportsPreviewRoute } from '../../Routes/Routes';
import { useCanFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { registerTitle } from '../../../functions/registerTitle';

interface ReportsListProps {
  reports: ReportModel[];
}
export const downloadReportFile = (url: string, name: string) => {
  const tempLink = document.createElement('a');
  tempLink.href = url;
  tempLink.setAttribute('download', `${name}.pdf`);
  tempLink.click();
};
export const ReportsList: React.FC<ReportsListProps> = props => {
  const [reportIdToDelete, setReportIdToBeDeleted] =
    useState<ReportModel['id']>();
  const baseCss = 'reportsList';
  const dispatch = useDispatch();
  const clickPreviewHandler =
    (report: ReportModel) => (e: React.MouseEvent) => {
      dispatch(push(reportsPreviewRoute.replace(':id', report.id)));
      e.stopPropagation();
    };

  const clickDownloadHandler =
    (report: ReportModel) => (e: React.MouseEvent) => {
      dispatch(
        fetchReportDocument({
          id: report.id,
          successCallback: (url: string) => {
            downloadReportFile(url, report.name);
          },
        })
      );
      e.stopPropagation();
    };

  const clickDeleteHandler = (report: ReportModel) => (e: React.MouseEvent) => {
    setReportIdToBeDeleted(report.id);
    e.stopPropagation();
  };
  const canManageReports = useCanFeature(AccessObject.reports_manage);

  if (props.reports.length < 1) {
    return (
      <AdoptechPanel>
        <NoDataText text="No reports to show" />
      </AdoptechPanel>
    );
  }

  return (
    <AdoptechPanel className={baseCss}>
      <div className={baseCss + '--5-columns'}>
        <AdoptechGridTable
          header={
            <div className="adoptechGridTable--header">
              <SortableTableHeader<ReportModel>
                columnName="name"
                grid={Grid.Reports}
                label="Name"
                notInTable
              />
              <SortableTableHeader<ReportModel>
                columnName="type"
                grid={Grid.Reports}
                label="Type"
                notInTable
              />
              <SortableTableHeader<ReportModel>
                columnName="owner"
                grid={Grid.Reports}
                label="Owner"
                notInTable
              />
              <SortableTableHeader<ReportModel>
                columnName="createdAt"
                grid={Grid.Reports}
                label="Date"
                notInTable
              />
              <div className={baseCss + '--actionsHeader'}>Actions</div>
            </div>
          }
        >
          {props.reports.map(report => {
            return (
              <div
                key={report.id}
                className="adoptechGridTable--row"
                onClick={clickPreviewHandler(report)}
              >
                <div>{report.name}</div>
                <div>
                  <div className={baseCss + '--badge'}>
                    {registerTitle(report.type.replace('Report::', '')).replace(
                      'Statement Of Applicability',
                      'SoA'
                    )}
                  </div>
                </div>
                <AdoptechOverflowLine>
                  <UserAvatar size="small" user={report.owner} />
                </AdoptechOverflowLine>
                <div>{formatShortDate(report.createdAt)}</div>
                <div className={baseCss + '--actions'}>
                  <AdoptechTooltip
                    text="Preview"
                    showTooltip
                    identifier={`${report.id}.preview`}
                  >
                    <FontAwesomeIcon
                      className="preview--icon"
                      icon={faEye}
                      onClick={clickPreviewHandler(report)}
                    />
                  </AdoptechTooltip>
                  <AdoptechTooltip
                    text="Download"
                    showTooltip
                    identifier={`${report.id}.download`}
                  >
                    <FontAwesomeIcon
                      className="download--icon"
                      icon={faCloudDownload}
                      onClick={clickDownloadHandler(report)}
                    />
                  </AdoptechTooltip>
                  {canManageReports && (
                    <AdoptechTooltip
                      text="Delete"
                      showTooltip
                      identifier={`${report.id}.delete`}
                    >
                      <FontAwesomeIcon
                        className="delete--icon"
                        icon={faTrashAlt}
                        onClick={clickDeleteHandler(report)}
                      />
                    </AdoptechTooltip>
                  )}
                </div>
              </div>
            );
          })}
        </AdoptechGridTable>

        <GenericModal
          closeButtonLabel="CONFIRM"
          onCancel={() => setReportIdToBeDeleted(undefined)}
          onClose={() => {
            dispatch(deleteReport(reportIdToDelete));
            setReportIdToBeDeleted(undefined);
          }}
          show={!!reportIdToDelete}
          showCancelButton
          title="Confirm delete?"
        >
          <br />
          <h3>{props.reports.find(p => p.id === reportIdToDelete)?.name}</h3>
          <br />
          <div>Click CONFIRM to permanently delete this report</div>
        </GenericModal>
      </div>
    </AdoptechPanel>
  );
};
