import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';
import { SharedUserType, SharedVendorUserStatus, VendorUser } from '../swagger';

export const vendorUserSort = (
  a: VendorUser,
  b: VendorUser,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof VendorUser = columnSort.name as keyof VendorUser;

  // @ts-ignore
  if (typedColumn === 'compliant') {
    const aCompliance = a.complianceStats?.overall;
    const bCompliance = b.complianceStats?.overall;
    return (aCompliance === bCompliance ? 0 : aCompliance ? -1 : 1) * direction;
  }

  if (typedColumn === 'employmentStatus') {
    const aIsInvited = a.status === SharedVendorUserStatus.Invited;
    const bIsInvited = b.status === SharedVendorUserStatus.Invited;

    if (aIsInvited && !bIsInvited) return -1;
    if (!aIsInvited && bIsInvited) return 1;

    const aIsSystemUser = a.userType === SharedUserType.SystemUser;
    const bIsSystemUser = b.userType === SharedUserType.SystemUser;

    if (aIsSystemUser && !bIsSystemUser) return -1;
    if (!aIsSystemUser && bIsSystemUser) return 1;

    return sort(a[typedColumn] || '', b[typedColumn] || '', direction);
  }

  return sort(a[typedColumn] || '', b[typedColumn] || '', direction);
};
