/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorDetailsRegisters
 */
export interface VendorDetailsRegisters {
  /**
   *
   * @type {string}
   * @memberof VendorDetailsRegisters
   */
  legalRegisterId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDetailsRegisters
   */
  pestelRegisterId?: string | null;
}

export function VendorDetailsRegistersFromJSON(
  json: any
): VendorDetailsRegisters {
  return VendorDetailsRegistersFromJSONTyped(json, false);
}

export function VendorDetailsRegistersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorDetailsRegisters {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    legalRegisterId: !exists(json, 'legal_register_id')
      ? undefined
      : json['legal_register_id'],
    pestelRegisterId: !exists(json, 'pestel_register_id')
      ? undefined
      : json['pestel_register_id'],
  };
}

export function VendorDetailsRegistersToJSON(
  value?: VendorDetailsRegisters | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    legal_register_id: value.legalRegisterId,
    pestel_register_id: value.pestelRegisterId,
  };
}
