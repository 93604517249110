import { createSelector } from '@reduxjs/toolkit';
import { getPestelRating } from '../components/pestel/PestelSection/PestelRow';
import { sort } from '../functions/sort';
import { PestelItemArea, PestelItemModel } from '../swagger';
import { ApplicationState } from '../types/applicationState';
import { ColumnSort } from '../types/columnSort';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';

const sortPestels = (
  a: PestelItemModel,
  b: PestelItemModel,
  columnSort: ColumnSort
) => {
  const typedColumn: keyof PestelItemModel =
    columnSort.name as keyof PestelItemModel;

  if (typedColumn === 'consequence') {
    return sort(getPestelRating(a), getPestelRating(b), columnSort.direction); // for rating column in Pestel table
  }

  return sort(a[typedColumn], b[typedColumn], columnSort.direction);
};

export const selectPestels = (
  area: PestelItemArea,
  currentGrid: Grid,
  filteredPestels: PestelItemModel[]
) => {
  return createSelector(
    (state: ApplicationState) => filteredPestels.filter(fp => fp.area == area),
    selectSortSettings(currentGrid),
    (pestels: PestelItemModel[], sortSetting) => {
      if (!pestels?.length) {
        return [];
      }
      return [...pestels].sort((a, b) =>
        sortPestels(a, b, sortSetting.columnSort)
      );
    }
  );
};
