import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FrameworkRequirementModel } from '../../../swagger';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { fetchFrameworkGuidance } from '../../../store/compliance/complianceThunks';
import { ApplicationState } from '../../../types/applicationState';

import './FrameworkGuidance.scss';

const baseCss = 'frameworkGuidance';

interface Props {
  guidanceIdentifier: FrameworkRequirementModel['guidanceIdentifier'];
}

export const FrameworkGuidance: React.FC<Props> = ({ guidanceIdentifier }) => {
  const dispatch = useDispatch();

  const guidance = useSelector(
    (state: ApplicationState) =>
      state.compliance.frameworkGuidances[guidanceIdentifier]
  );

  useEffect(() => {
    if (!guidance) dispatch(fetchFrameworkGuidance(guidanceIdentifier));
  }, [guidanceIdentifier]);

  if (!guidance) return <LoadingSpinner />;

  return (
    <div
      className={`${baseCss}--guidance`}
      dangerouslySetInnerHTML={{
        __html: guidance?.content,
      }}
    />
  );
};
