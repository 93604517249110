/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorIntegrationCreatePayloadVendorIntegration,
  VendorIntegrationCreatePayloadVendorIntegrationFromJSON,
  VendorIntegrationCreatePayloadVendorIntegrationFromJSONTyped,
  VendorIntegrationCreatePayloadVendorIntegrationToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorIntegrationCreatePayload
 */
export interface VendorIntegrationCreatePayload {
  /**
   *
   * @type {VendorIntegrationCreatePayloadVendorIntegration}
   * @memberof VendorIntegrationCreatePayload
   */
  vendorIntegration: VendorIntegrationCreatePayloadVendorIntegration;
}

export function VendorIntegrationCreatePayloadFromJSON(
  json: any
): VendorIntegrationCreatePayload {
  return VendorIntegrationCreatePayloadFromJSONTyped(json, false);
}

export function VendorIntegrationCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorIntegrationCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vendorIntegration: VendorIntegrationCreatePayloadVendorIntegrationFromJSON(
      json['vendor_integration']
    ),
  };
}

export function VendorIntegrationCreatePayloadToJSON(
  value?: VendorIntegrationCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vendor_integration: VendorIntegrationCreatePayloadVendorIntegrationToJSON(
      value.vendorIntegration
    ),
  };
}
