import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { AdoptechCheckbox } from '../AdoptechCheckbox/AdoptechCheckbox';
import './MessageToast.scss';

export enum MessageToastVariant {
  Primary,
  Success,
  Warning,
}

interface MessageToastProps {
  autohide?: boolean;
  doNotShowCookieName?: string;
  delay?: number;
  onClose: () => void;
  size?: 'large';
  show: boolean;
  showClose?: boolean;
  variant?: MessageToastVariant;
}

export const MessageToast: React.FC<MessageToastProps> = props => {
  const [cookies, setCookie] = useCookies([props.doNotShowCookieName]);
  const [doNotShow, setDoNotShow] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const messageToastClasses = classNames({
    messageToast: true,
    'messageToast-success': props.variant === MessageToastVariant.Success,
    'messageToast-warning': props.variant === MessageToastVariant.Warning,
    'messageToast-large': props.size === 'large',
  });

  const messageToastContentClasses = classNames({
    'messageToast--content': true,
    'messageToast--content-shiftUp': props.showClose,
  });

  const { doNotShowCookieName, onClose, showClose, show, ...otherProps } =
    props;

  const handleClose = () => {
    if (props.doNotShowCookieName) {
      setCookie(props.doNotShowCookieName, doNotShow, {
        expires: moment().add(10, 'years').toDate(),
      });
    }
    props.onClose();
  };

  const handleDoNotShowChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDoNotShow(e.target.checked);
  };

  useEffect(() => {
    if (cookies[props.doNotShowCookieName] === 'true') {
      setShowToast(false);
      return;
    }

    setShowToast(show);
  }, [show]);

  return (
    <div className={messageToastClasses}>
      <Toast onClose={handleClose} show={showToast} {...otherProps}>
        <Toast.Body>
          <div className="messageToast--body">
            {props.showClose && (
              <div className="messageToast--closeHeader">
                <FontAwesomeIcon
                  className="messageToast--close"
                  icon={faTimes}
                  onClick={() => handleClose()}
                />
              </div>
            )}
            <div className={messageToastContentClasses}>{props.children}</div>
            {props.doNotShowCookieName && (
              <div className="messageToast--doNotShow">
                <AdoptechCheckbox
                  checked={doNotShow}
                  id="doNotShow"
                  label="Do not show this message again"
                  onChange={handleDoNotShowChange}
                />
              </div>
            )}
          </div>
        </Toast.Body>
      </Toast>
    </div>
  );
};

MessageToast.defaultProps = {
  autohide: true,
  showClose: true,
  variant: MessageToastVariant.Primary,
};
