/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedTaskRecurring,
  SharedTaskRecurringFromJSON,
  SharedTaskRecurringFromJSONTyped,
  SharedTaskRecurringToJSON,
} from './';

/**
 *
 * @export
 * @interface TaskUpdatePayloadTask
 */
export interface TaskUpdatePayloadTask {
  /**
   *
   * @type {string}
   * @memberof TaskUpdatePayloadTask
   */
  description?: string | null;
  /**
   *
   * @type {SharedTaskRecurring}
   * @memberof TaskUpdatePayloadTask
   */
  recurring?: SharedTaskRecurring;
  /**
   *
   * @type {string}
   * @memberof TaskUpdatePayloadTask
   */
  assigneeId?: string | null;
  /**
   *
   * @type {string}
   * @memberof TaskUpdatePayloadTask
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof TaskUpdatePayloadTask
   */
  reviewDate?: string | null;
}

export function TaskUpdatePayloadTaskFromJSON(
  json: any
): TaskUpdatePayloadTask {
  return TaskUpdatePayloadTaskFromJSONTyped(json, false);
}

export function TaskUpdatePayloadTaskFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TaskUpdatePayloadTask {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    description: !exists(json, 'description') ? undefined : json['description'],
    recurring: !exists(json, 'recurring')
      ? undefined
      : SharedTaskRecurringFromJSON(json['recurring']),
    assigneeId: !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    reviewDate: !exists(json, 'review_date') ? undefined : json['review_date'],
  };
}

export function TaskUpdatePayloadTaskToJSON(
  value?: TaskUpdatePayloadTask | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    recurring: SharedTaskRecurringToJSON(value.recurring),
    assignee_id: value.assigneeId,
    name: value.name,
    review_date: value.reviewDate,
  };
}
