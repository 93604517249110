import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AssertionExtended,
  AssertionUpdatePayloadAssertion,
} from '../../../../../swagger';
import { ApplicationState } from '../../../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import './EditTestDrawer.scss';
import AdoptechHS from '../../../../../components/AdoptechHS/AdoptechHS';
import classNames from 'classnames';
import { DrawerType } from '../../../../../components/compliance/Types/complianceTypes';
import { EditTestForm, isTestAutomatic } from './EditTestForm/EditTestForm';
import {
  fetchAssertionDetails,
  patchAssertionDetails,
} from '../../../../../store/compliance/complianceThunks';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { useUpdateAssertionEverywhere } from './EditTestForm/EditTestFormLastResultsSection/EditTestFormLastResultsList/EditTestFormLastResultsList';

interface EditTestDrawerProps {
  onClose(): void;
  showBackButton: boolean;
  id: AssertionExtended['id'];
  name?: AssertionExtended['name']; // show name on initial details fetch
}
export type TestForm = {
  id?: AssertionExtended['id'];
  name?: AssertionExtended['name'];
  frequency?: AssertionExtended['frequency'];
  description?: AssertionExtended['description'];
  ownerId?: AssertionExtended['owner']['id'];
  categoryId?: AssertionExtended['assertionCategory']['id'];
  type?: AssertionExtended['type'];
  autorun?: AssertionExtended['autorun'];
  status?: AssertionExtended['status'];
  lastRunAt?: AssertionExtended['lastRunAt'];
  latestRunResults?: AssertionExtended['latestRunResults'];
  overdue?: AssertionExtended['overdue'];
  nextRunAfter?: AssertionExtended['nextRunAfter'];
};

export const EditTestDrawer: React.FC<EditTestDrawerProps> = ({
  onClose,
  showBackButton,
  name,
  id,
}) => {
  const [formTouched, setFormTouched] = useState<boolean>(false);

  const { drawerOnTop: activeDrawer, patchAssertionDetailsStatus } =
    useSelector((state: ApplicationState) => state.compliance);

  const isSubmitting = patchAssertionDetailsStatus === 'loading';
  const [formData, setFormData] = useState<TestForm>({});
  const [originalFormData, setOriginalFormData] = useState<TestForm>({});
  const isAuto = isTestAutomatic(formData);
  const dispatch = useAppDispatch();
  const baseCss = 'editTestDrawer';

  const handleClose = () => {
    onClose();
  };

  const drawerBackgroundRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const fetchDetails = async () => {
      const assertion = await dispatch(
        fetchAssertionDetails({ assertionId: id })
      ).unwrap();
      const {
        frequency,
        description,
        type,
        owner,
        assertionCategory,
        autorun,
        lastRunAt,
        latestRunResults,
        status,
        overdue,
        nextRunAfter,
      } = assertion;

      const formPayload = {
        id: assertion.id,
        name: assertion.name,
        frequency,
        description,
        ownerId: owner?.id,
        categoryId: assertionCategory?.id,
        type,
        autorun,
        lastRunAt,
        latestRunResults,
        status,
        overdue,
        nextRunAfter,
      };
      setFormData(formPayload);
      setOriginalFormData(formPayload);
    };
    fetchDetails();
  }, []);
  const updateAssertionEveryWhere = useUpdateAssertionEverywhere();

  const editTestFormProps = {
    formData,
    onChange: (newFormData: TestForm) => {
      setFormData(newFormData);
      setFormTouched(true);
    },
  };

  const handleDoneClick = async () => {
    const fields = {
      categoryId: formData.categoryId,
      frequency: formData.frequency,
      description: formData.description,
    };

    const changedFields = Object.keys(fields).filter((key: keyof TestForm) => {
      return formData[key] !== originalFormData[key];
    });

    if (formData.id && changedFields.length > 0) {
      const patchPayload: AssertionUpdatePayloadAssertion = {};
      if (changedFields.includes('categoryId')) {
        patchPayload['assertionCategoryId'] = formData.categoryId;
      }
      if (changedFields.includes('frequency')) {
        patchPayload['frequency'] = formData.frequency;
      }
      if (changedFields.includes('description')) {
        patchPayload['description'] = formData.description;
      }
      await dispatch(
        patchAssertionDetails({
          assertionId: formData.id,
          body: { assertion: patchPayload },
        })
      ).unwrap();

      updateAssertionEveryWhere(formData, editTestFormProps);
    }

    handleClose();
  };

  return (
    <AdoptechHS
      noFormPadding
      backButton={showBackButton}
      onBackClick={handleClose}
      title={name || formData.name}
      badges={[{ title: 'Test' }]}
      ref={drawerBackgroundRef}
      showConfirmationWarning={formTouched}
      onClose={handleClose}
      extraClass={classNames({ onTop: activeDrawer === DrawerType.Test }, [
        baseCss,
      ])}
      show
      footer={
        <>
          <AdoptechButton
            onClick={handleClose}
            variant={AdoptechButtonVariant.White}
            extraClass="d-none"
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={handleDoneClick}
            variant={AdoptechButtonVariant.Primary}
            busy={isSubmitting}
          >
            Done
          </AdoptechButton>
        </>
      }
    >
      <EditTestForm {...editTestFormProps} />
    </AdoptechHS>
  );
};
