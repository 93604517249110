/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ControlCreatePayloadControlFrameworksRelations
 */
export interface ControlCreatePayloadControlFrameworksRelations {
  /**
   *
   * @type {string}
   * @memberof ControlCreatePayloadControlFrameworksRelations
   */
  annexRef?: string;
  /**
   *
   * @type {string}
   * @memberof ControlCreatePayloadControlFrameworksRelations
   */
  controlCategoryId?: string;
}

export function ControlCreatePayloadControlFrameworksRelationsFromJSON(
  json: any
): ControlCreatePayloadControlFrameworksRelations {
  return ControlCreatePayloadControlFrameworksRelationsFromJSONTyped(
    json,
    false
  );
}

export function ControlCreatePayloadControlFrameworksRelationsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlCreatePayloadControlFrameworksRelations {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    annexRef: !exists(json, 'annex_ref') ? undefined : json['annex_ref'],
    controlCategoryId: !exists(json, 'control_category_id')
      ? undefined
      : json['control_category_id'],
  };
}

export function ControlCreatePayloadControlFrameworksRelationsToJSON(
  value?: ControlCreatePayloadControlFrameworksRelations | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    annex_ref: value.annexRef,
    control_category_id: value.controlCategoryId,
  };
}
