import classNames from 'classnames';
import React from 'react';
import './Panel.scss';

export interface PanelProps {
  clickable?: boolean;
  onClick?: () => void;
  placedOnDarkBackground?: boolean;
  selected?: boolean;
}

export const Panel: React.FC<PanelProps> = props => {
  const classes = classNames({
    panel: true,
    'panel-clickable': props.clickable,
    'panel-darkerBoxShadow': props.placedOnDarkBackground,
    'panel-selected': props.selected,
  });

  const handleClick = () => {
    if (props.clickable && props.onClick) {
      props.onClick();
    }
  };

  return (
    <div
      className={classes}
      onClick={handleClick}
      onKeyPress={e => {
        if (e.currentTarget === e.target && e.key === 'Enter') {
          handleClick();
        }
      }}
      tabIndex={props.clickable ? 0 : null}
    >
      {props.children}
    </div>
  );
};
