/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CalendarItemModel,
  CalendarItemModelFromJSON,
  CalendarItemModelFromJSONTyped,
  CalendarItemModelToJSON,
  SharedPaginationMeta,
  SharedPaginationMetaFromJSON,
  SharedPaginationMetaFromJSONTyped,
  SharedPaginationMetaToJSON,
} from './';

/**
 *
 * @export
 * @interface CalendarItemList
 */
export interface CalendarItemList {
  /**
   *
   * @type {Array<CalendarItemModel>}
   * @memberof CalendarItemList
   */
  data: Array<CalendarItemModel>;
  /**
   *
   * @type {SharedPaginationMeta}
   * @memberof CalendarItemList
   */
  meta: SharedPaginationMeta;
}

export function CalendarItemListFromJSON(json: any): CalendarItemList {
  return CalendarItemListFromJSONTyped(json, false);
}

export function CalendarItemListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CalendarItemList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(CalendarItemModelFromJSON),
    meta: SharedPaginationMetaFromJSON(json['meta']),
  };
}

export function CalendarItemListToJSON(value?: CalendarItemList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(CalendarItemModelToJSON),
    meta: SharedPaginationMetaToJSON(value.meta),
  };
}
