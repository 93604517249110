import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { Interweave } from 'interweave';
import React, { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getProgressPercentage } from '../../functions/getPercentage';
import {
  fetchChecklistDetails,
  updateChecklistItem,
} from '../../store/checklists/checklistsThunks';
import { ChecklistItem, ChecklistItemsGroups } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { COMPLETED_TEXT } from '../../types/constants';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { ChecklistGroupItem } from '../ChecklistGroupItem/ChecklistGroupItem';
import { ChecklistSummaryTile } from '../ChecklistSummaryTile/ChecklistSummaryTile';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import './Checklist.scss';

export const Checklist: React.FC = () => {
  const dispatch = useDispatch();

  const { checklistId } = useParams() as { checklistId: string };

  useEffect(() => {
    dispatch(fetchChecklistDetails({ checklistId }));
  }, [checklistId]);

  const checklist = useSelector((state: ApplicationState) =>
    state.checklists.checklists.find(chList => chList.id === checklistId)
  );

  const readonly = checklist && checklist.status === COMPLETED_TEXT;

  const handleCheckedChanged =
    (item: ChecklistItem, group: ChecklistItemsGroups) => (value: boolean) => {
      dispatch(
        updateChecklistItem({
          checklistSurveyId: checklist.survey.id,
          checklistId: checklist.id,
          questionGroupId: group.questionGroupId,
          item: { ...item, answerValue: value },
        })
      );
    };
  const handleNotApplicableChanged =
    (item: ChecklistItem, group: ChecklistItemsGroups) => (value: boolean) => {
      dispatch(
        updateChecklistItem({
          checklistSurveyId: checklist.survey.id,
          checklistId: checklist.id,
          questionGroupId: group.questionGroupId,
          item: { ...item, answerNotApplicable: value },
        })
      );
    };
  const handleNoteChanged =
    (item: ChecklistItem, group: ChecklistItemsGroups) => (note: string) => {
      dispatch(
        updateChecklistItem({
          checklistSurveyId: checklist.survey.id,
          checklistId: checklist.id,
          questionGroupId: group.questionGroupId,
          item: { ...item, answerNote: note },
        })
      );
    };

  if (!checklist) {
    return null;
  }

  return (
    <div className="checklist">
      <div className="checklist--summary">
        <div
          className="checklist--back"
          onClick={() => {
            dispatch(push('/r/companyInformation/checklists'));
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
        <div className="checklist--summaryTile">
          <ChecklistSummaryTile checklist={checklist} />
        </div>
      </div>
      {checklist.descriptionAsHtml && (
        <div className="checklist--description">
          <Interweave content={checklist.descriptionAsHtml} />
        </div>
      )}
      <div className="checklist--main">
        <Accordion>
          {checklist?.itemsGroups?.map(group => (
            <AdoptechAccordionCard
              index={group.questionGroupId}
              key={group.questionGroupId}
              title={
                <div className="checklist--groupHeader">
                  <div>{group.questionGroupName}</div>
                  <div className="checklist--groupHeader-progress">
                    <div>
                      {`${getProgressPercentage(group.progress).toFixed(0)}%`}
                    </div>
                    <div className="checklist--groupHeader-progressBar">
                      <ProgressBar
                        progress={getProgressPercentage(group.progress)}
                      />
                    </div>
                  </div>
                </div>
              }
              nested
              nestedVerticalOffset={-245}
              headerOffset={115}
            >
              {group.items.map(item => (
                <ChecklistGroupItem
                  item={item}
                  key={item.questionId}
                  onCheckedChanged={handleCheckedChanged(item, group)}
                  onNotApplicableChanged={handleNotApplicableChanged(
                    item,
                    group
                  )}
                  onNoteChanged={handleNoteChanged(item, group)}
                  readonly={readonly}
                />
              ))}
            </AdoptechAccordionCard>
          ))}
        </Accordion>
      </div>
    </div>
  );
};
