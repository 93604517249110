/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Answer
 */
export interface Answer {
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  updatedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  userName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  propertyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  questionId?: string;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  questionRowId?: string | null;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  value?: string;
  /**
   *
   * @type {boolean}
   * @memberof Answer
   */
  other?: boolean;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  surveyId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Answer
   */
  notApplicable?: boolean;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  note?: string | null;
  /**
   *
   * @type {number}
   * @memberof Answer
   */
  index?: number | null;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  datatype?: string;
  /**
   *
   * @type {number}
   * @memberof Answer
   */
  rowOrder?: number | null;
}

export function AnswerFromJSON(json: any): Answer {
  return AnswerFromJSONTyped(json, false);
}

export function AnswerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Answer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    userName: !exists(json, 'user_name') ? undefined : json['user_name'],
    propertyName: !exists(json, 'property_name')
      ? undefined
      : json['property_name'],
    questionId: !exists(json, 'question_id') ? undefined : json['question_id'],
    questionRowId: !exists(json, 'question_row_id')
      ? undefined
      : json['question_row_id'],
    value: !exists(json, 'value') ? undefined : json['value'],
    other: !exists(json, 'other') ? undefined : json['other'],
    surveyId: !exists(json, 'survey_id') ? undefined : json['survey_id'],
    notApplicable: !exists(json, 'not_applicable')
      ? undefined
      : json['not_applicable'],
    note: !exists(json, 'note') ? undefined : json['note'],
    index: !exists(json, 'index') ? undefined : json['index'],
    datatype: !exists(json, 'datatype') ? undefined : json['datatype'],
    rowOrder: !exists(json, 'row_order') ? undefined : json['row_order'],
  };
}

export function AnswerToJSON(value?: Answer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    user_name: value.userName,
    property_name: value.propertyName,
    question_id: value.questionId,
    question_row_id: value.questionRowId,
    value: value.value,
    other: value.other,
    survey_id: value.surveyId,
    not_applicable: value.notApplicable,
    note: value.note,
    index: value.index,
    datatype: value.datatype,
    row_order: value.rowOrder,
  };
}
