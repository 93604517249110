import React from 'react';
import './AgreementsTextEditor.scss';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/paragraph_format.min';

export interface AgreementsTextEditorProps {
  onChange: (model: string) => void;
  placeholder?: string;
  text: string;
}

const AgreementsTextEditor: React.FC<AgreementsTextEditorProps> = props => {
  const config = {
    key: process.env.REACT_APP_FROALA_EDITOR_KEY,
    attribution: false,
    htmlUntouched: true,
    toolbarButtons: {
      moreText: {
        align: 'left',
        buttons: [
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'subscript',
          'superscript',
          'paragraphFormat',
        ],
        buttonsVisible: 14,
      },
      moreLists: {
        align: 'left',
        buttons: ['formatOL', 'formatUL', 'indent', 'outdent'],
        buttonsVisible: 4,
      },
      moreJustification: {
        align: 'left',
        buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
        buttonsVisible: 4,
      },
      moreRich: {
        align: 'left',
        buttons: ['insertLink', 'insertTable', 'insertHR'],
      },
      moreMisc: {
        align: 'right',
        buttons: ['undo', 'redo', '|', 'html'],
        buttonsVisible: 2,
      },
    },
    toolbarSticky: true,
    toolbarStickyOffset: 209,
  };

  const handleModelChange = (model: Record<string, unknown>) => {
    props.onChange(model.toString());
  };

  return (
    <div className="agreementsTextEditor">
      <FroalaEditor
        config={config}
        model={props.text}
        onModelChange={handleModelChange}
      />
    </div>
  );
};

export default AgreementsTextEditor;
