import React from 'react';
import './ManageInvoices.scss';
import { ManageInvoicesGrid } from '../ManageInvoicesGrid/ManageInvoicesGrid';

export const ManageInvoices: React.FC = () => {
  return (
    <div className="manageInvoices">
      <div className="manageInvoices--header">
        <div className="manageInvoices--title">Invoices</div>
        <ManageInvoicesGrid />
      </div>
    </div>
  );
};
