import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgreementsCreateForm } from '../../../../selectors/selectAgreementsCreateForm';
import { updateAgreementsCreateFormField } from '../../../../store/agreements/agreementsSlice';
import { ApplicationState } from '../../../../types/applicationState';
import { AdoptechTextInput2 } from '../../../AdoptechTextInput2/AdoptechTextInput2';
import { AgreementsCreateForm } from '../AddNewAgreementForm';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';

export const useAgreementTemplateName = (form: AgreementsCreateForm) => {
  const agreementTemplates = useSelector(
    (state: ApplicationState) => state.agreements.agreementTemplates
  );

  const standardTemplate = agreementTemplates.find(
    template => template.id === form.standardTemplateId
  );
  return standardTemplate.name;
};

export const NameStepIsValid = (form: AgreementsCreateForm) => {
  return form.name?.length > 0;
};

export const NameStep: React.FC = () => {
  const form = useSelector(selectAgreementsCreateForm);

  const defaultName = useAgreementTemplateName(form);
  const dispatch = useDispatch();

  const setName = (name: string) =>
    dispatch(updateAgreementsCreateFormField({ name }));

  const baseCss = 'addNewAgreementForm';

  return (
    <>
      <span className={baseCss + '--label'}>
        Give your agreement a unique name:
      </span>
      <div className="mt-2">
        <AdoptechTextInput2
          id="#agreement-name"
          onChange={e => {
            setName(e.currentTarget.value);
          }}
          type="text"
          label="Agreement name"
          value={form.name || ''}
          placeholder={`${defaultName} - Jenny Smith`}
          hasError={!form.name}
          icon={faPen}
        />
      </div>
    </>
  );
};
