/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PestelItemArea,
  PestelItemAreaFromJSON,
  PestelItemAreaFromJSONTyped,
  PestelItemAreaToJSON,
  PestelItemItemType,
  PestelItemItemTypeFromJSON,
  PestelItemItemTypeFromJSONTyped,
  PestelItemItemTypeToJSON,
  PestelItemLocationType,
  PestelItemLocationTypeFromJSON,
  PestelItemLocationTypeFromJSONTyped,
  PestelItemLocationTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface PestelItemModel
 */
export interface PestelItemModel {
  /**
   *
   * @type {string}
   * @memberof PestelItemModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PestelItemModel
   */
  description: string;
  /**
   *
   * @type {PestelItemArea}
   * @memberof PestelItemModel
   */
  area?: PestelItemArea;
  /**
   *
   * @type {PestelItemItemType}
   * @memberof PestelItemModel
   */
  itemType?: PestelItemItemType;
  /**
   *
   * @type {PestelItemLocationType}
   * @memberof PestelItemModel
   */
  locationType?: PestelItemLocationType;
  /**
   *
   * @type {number}
   * @memberof PestelItemModel
   */
  consequence?: number;
  /**
   *
   * @type {number}
   * @memberof PestelItemModel
   */
  likelihood?: number;
  /**
   *
   * @type {string}
   * @memberof PestelItemModel
   */
  mitigationFactors?: string | null;
  /**
   *
   * @type {string}
   * @memberof PestelItemModel
   */
  comment?: string | null;
  /**
   *
   * @type {string}
   * @memberof PestelItemModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof PestelItemModel
   */
  updatedAt?: string;
}

export function PestelItemModelFromJSON(json: any): PestelItemModel {
  return PestelItemModelFromJSONTyped(json, false);
}

export function PestelItemModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PestelItemModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    description: json['description'],
    area: !exists(json, 'area')
      ? undefined
      : PestelItemAreaFromJSON(json['area']),
    itemType: !exists(json, 'item_type')
      ? undefined
      : PestelItemItemTypeFromJSON(json['item_type']),
    locationType: !exists(json, 'location_type')
      ? undefined
      : PestelItemLocationTypeFromJSON(json['location_type']),
    consequence: !exists(json, 'consequence') ? undefined : json['consequence'],
    likelihood: !exists(json, 'likelihood') ? undefined : json['likelihood'],
    mitigationFactors: !exists(json, 'mitigation_factors')
      ? undefined
      : json['mitigation_factors'],
    comment: !exists(json, 'comment') ? undefined : json['comment'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function PestelItemModelToJSON(value?: PestelItemModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    description: value.description,
    area: PestelItemAreaToJSON(value.area),
    item_type: PestelItemItemTypeToJSON(value.itemType),
    location_type: PestelItemLocationTypeToJSON(value.locationType),
    consequence: value.consequence,
    likelihood: value.likelihood,
    mitigation_factors: value.mitigationFactors,
    comment: value.comment,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
