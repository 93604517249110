import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideEditOwnerModal,
  hideEditOwnerToast,
  showEditOwnerToast,
  showGlobalToast,
} from '../../store/global/globalSlice';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { MessageToast } from '../MessageToast/MessageToast';
import { SelectionOption } from '../../types/selectionOption';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { patchVendorDocument } from '../../store/vendors/vendorsThunks';
import { OwnerObjectType } from '../../types/OwnerObjectType';
import { patchAgreement } from '../../store/agreements/agreementsThunks';
import {
  patchAssessment,
  patchAssessmentResponse,
} from '../../store/assessments/assessmentsThunks';
import { Assessment, AssessmentResponse } from '../../swagger';
import './EditOwnerModal.scss';
import { useOwnerOptions } from '../../hooks/useOwnerOptions';

export const EditOwnerModal: React.FC = () => {
  const showModal = useSelector(
    (state: ApplicationState) => state.global.isEditOwnerModalShowing
  );
  const showToast = useSelector(
    (state: ApplicationState) => state.global.isEditedOwnerToastShowing
  );
  const ownerObjectToEdit = useSelector(
    (state: ApplicationState) => state.global.ownerObjectToEdit
  );
  const ownerObjectType = useSelector(
    (state: ApplicationState) => state.global.ownerObjectType
  );

  const [owner, setOwner] = useState(ownerObjectToEdit?.owner);

  const isAgreement = ownerObjectType === OwnerObjectType.Agreement;
  const isVendorDocument = ownerObjectType === OwnerObjectType.VendorDocument; // /documents page OR policy approval
  const isAssessment = window.location.pathname.includes('assessment');
  const { users, ownerOptions: options } = useOwnerOptions({
    includingGuest: isAgreement,
    onlyAdminable: isAssessment || isVendorDocument,
  });
  const selectedUser = options.find(o => {
    return o.value === owner?.id;
  });
  useEffect(() => {
    if (showModal) {
      setOwner(ownerObjectToEdit?.owner);
    }
  }, [showModal]);

  const dispatch = useDispatch();

  if (!ownerObjectToEdit) {
    return null;
  }

  const onSuccess = () => {
    dispatch(showEditOwnerToast());
    dispatch(hideEditOwnerModal());
  };

  const save = () => {
    switch (ownerObjectType) {
      case OwnerObjectType.VendorDocument:
        dispatch(
          patchVendorDocument({
            documentId: ownerObjectToEdit.id,
            vendorDocument: {
              ownerId: owner.id,
            },
            onSuccess: () => {
              onSuccess();
              dispatch(
                showGlobalToast(
                  'Policy document has been successfully updated.'
                )
              );
            },
          })
        );
        break;
      case OwnerObjectType.Agreement:
        dispatch(
          patchAgreement({
            agreementId: ownerObjectToEdit.id,
            ownerId: owner.id,
            onSuccess,
          })
        );
        break;
      case OwnerObjectType.Assessment:
        {
          const assessment = ownerObjectToEdit as Assessment;

          if (assessment) {
            dispatch(
              patchAssessment({
                assessmentId: assessment.id,
                ownerId: owner.id,
                onSuccess,
              })
            );
          }
        }
        break;
      case OwnerObjectType.AssessmentResponse:
        {
          const ar = ownerObjectToEdit as AssessmentResponse;

          if (ar) {
            dispatch(
              patchAssessmentResponse({
                id: ar.id,
                assessmentResponse: { owner: owner, ownerId: owner.id },
                onSuccess,
              })
            );
          }
        }
        break;
    }
  };

  const handleSelect = (option: SelectionOption) => {
    setOwner(users.find(user => user.id === option.value));
  };

  return (
    <React.Fragment>
      <AdoptechModal
        className="editOwnerModal"
        onHide={() => dispatch(hideEditOwnerModal())}
        show={showModal}
      >
        <Modal.Header>Manage Ownership</Modal.Header>
        <Modal.Body>
          <AdoptechReactSelect
            id="generateChecklistManagersModal--userSelect"
            onChange={handleSelect}
            options={options}
            value={selectedUser}
            label="Select the Owner of the document"
            showUserAvatar
          />
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={() => dispatch(hideEditOwnerModal())}>
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={save}
            variant={AdoptechButtonVariant.Primary}
            uppercase
          >
            Update
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
      <MessageToast
        autohide
        delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
        onClose={() => dispatch(hideEditOwnerToast())}
        show={showToast}
      >
        The owner has been updated.
      </MessageToast>
    </React.Fragment>
  );
};
