import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import { selectRecentlyCreatedAssessments } from '../../selectors/selectRecentlyCreatedAssessments';
import { Assessment, ShareableType } from '../../swagger';
import { Grid } from '../../types/grid';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { SortableTableHeader } from '../SortableTableHeader/SortableTableHeader';
import './RecentlyCreatedAssessmentsGrid.scss';
import { MeatballMenu } from '../MeatballMenu/MeatballMenu';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import {
  canCreate,
  hasFeature,
  mapShareFeaturesToAccessLevels,
} from '../../functions/access';
import { AccessObject } from '../../types/accessObject';
import { showShareModal } from '../../store/vendors/vendorsSlice';
import classNames from 'classnames';
import { showEditOwnerModal } from '../../store/global/globalSlice';
import { OwnerObjectType } from '../../types/OwnerObjectType';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { showDeleteAssessmentModal } from '../../store/assessments/assessmentsSlice';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { DeleteAssessmentModal } from '../DeleteAssessmentModal/DeleteAssessmentModal';
import { UserAvatar } from '../UserAvatar/UserAvatar';

export const RecentlyCreatedAssessmentsGrid: React.FC = () => {
  const assessments = useSelector(selectRecentlyCreatedAssessments);
  const canCreateAssessments = canCreate(AccessObject.assessments);
  const dispatch = useDispatch();

  if (assessments.length === 0) {
    return null;
  }

  const handleRowClick = (id: string) => () => {
    dispatch(push(`/r/riskManagement/assessments/summary/${id}`));
  };

  return (
    <div className="recentlyCreatedAssessmentsGrid">
      <DeleteAssessmentModal />
      <AdoptechTable className="recentlyCreatedAssessmentsGrid--table">
        <thead>
          <tr>
            <SortableTableHeader<Assessment>
              columnName="name"
              className="recentlyCreatedAssessmentsGrid--nameHeader"
              grid={Grid.RecentlyCreatedAssessments}
              label="Name"
            />
            <SortableTableHeader<Assessment>
              columnName="owner"
              className="recentlyCreatedAssessmentsGrid--ownerHeader"
              grid={Grid.RecentlyCreatedAssessments}
              label="Owner"
            />
            <SortableTableHeader<Assessment>
              columnName="updatedAt"
              className="recentlyCreatedAssessmentsGrid--lastModifiedHeader"
              grid={Grid.RecentlyCreatedAssessments}
              label="Last modified"
            />
            <th className="recentlyCreatedAssessmentsGrid--responsesHeader">
              Responses
            </th>
            <th className="recentlyCreatedAssessmentsGrid--placeholderHeader" />

            <th className="recentlyCreatedAssessmentsGrid--actions" />
          </tr>
        </thead>
        <tbody>
          {assessments.map((assessment: Assessment) => {
            return (
              <tr
                className="recentlyCreatedAssessmentsGrid--row"
                key={assessment.id}
              >
                <td
                  className="recentlyCreatedAssessmentsGrid--cell"
                  onClick={handleRowClick(assessment.id)}
                >
                  {assessment.name}
                </td>
                <td
                  className={classNames({
                    'adoptechTable--clickableCell': canCreateAssessments,
                    'recentlyCreatedAssessmentsGrid--cell': true,
                  })}
                  onClick={() =>
                    canCreateAssessments
                      ? dispatch(
                          showEditOwnerModal({
                            object: assessment,
                            type: OwnerObjectType.Assessment,
                          })
                        )
                      : dispatch(
                          push(
                            `/r/riskManagement/assessments/summary/${assessment.id}`
                          )
                        )
                  }
                >
                  <UserAvatar user={assessment.owner} size="small" />
                </td>
                <td
                  className="recentlyCreatedAssessmentsGrid--cell"
                  onClick={handleRowClick(assessment.id)}
                >
                  {formatShortDate(assessment.updatedAt)}
                </td>
                <td
                  onClick={handleRowClick(assessment.id)}
                  className="recentlyCreatedAssessmentsGrid--cell"
                >
                  {assessment.responsesReceived}/{assessment.responsesRequested}
                </td>
                <td className="recentlyCreatedAssessmentsGrid--cell"></td>

                <td className="recentlyCreatedAssessmentsGrid--cell">
                  <MeatballMenu>
                    <Dropdown.Item
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(
                          push(
                            `/r/riskManagement/assessments/summary/${assessment.id}`
                          )
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        className="meatballMenu--icon"
                        icon={faEye}
                      />
                      View
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="meatballMenu--item"
                      disabled={
                        !hasFeature(AccessObject.share, assessment?.access)
                      }
                      onClick={e => {
                        dispatch(
                          showShareModal({
                            shareableId: assessment.id,
                            shareableType: ShareableType.Assessment,
                            accessLevels: mapShareFeaturesToAccessLevels(
                              assessment.access.feature
                            ),
                            relativePath: assessment.relativePath,
                          })
                        );
                        e.stopPropagation();
                      }}
                    >
                      <FontAwesomeIcon
                        className="meatballMenu--icon"
                        icon={faUserPlus}
                      />
                      Share
                    </Dropdown.Item>
                    {canCreateAssessments && (
                      <Dropdown.Item
                        onClick={e => {
                          dispatch(
                            showEditOwnerModal({
                              object: assessment,
                              type: OwnerObjectType.Assessment,
                            })
                          );
                          e.stopPropagation();
                        }}
                      >
                        <FontAwesomeIcon
                          className="meatballMenu--icon"
                          icon={faFileContract}
                        />
                        Edit owner
                      </Dropdown.Item>
                    )}
                    {canCreateAssessments && (
                      <Dropdown.Item
                        onClick={e => {
                          dispatch(showDeleteAssessmentModal(assessment));
                          e.stopPropagation();
                        }}
                      >
                        <FontAwesomeIcon
                          className="meatballMenu--icon"
                          icon={faTrash}
                        />
                        Delete
                      </Dropdown.Item>
                    )}
                  </MeatballMenu>
                </td>
              </tr>
            );
          })}
        </tbody>
      </AdoptechTable>
    </div>
  );
};
