import AdoptechModal from '../AdoptechModal/AdoptechModal';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import './GenerateAssessmentModal.scss';
import { useDispatch } from 'react-redux';
import { generateAssessment } from '../../store/assessments/assessmentsSlice';

interface GenerateAssessmentModalProps {
  onHide: () => void;
  show: boolean;
  templateName: string;
}

const GenerateAssessmentModal: React.FC<
  GenerateAssessmentModalProps
> = props => {
  const [assessmentName, setAssessmentName] = useState(props.templateName);

  const dispatch = useDispatch();

  return (
    <AdoptechModal show={props.show} onHide={props.onHide}>
      <Modal.Header>Generate assessment</Modal.Header>
      <Modal.Body>
        <div className="adoptechModal--subtitle">
          Please give your assessment a name
        </div>
        <div className="generateAssessmentModal--inputArea">
          <div className="generateAssessmentModal--label">Assessment name</div>
          <AdoptechTextInput
            id="generateAssessmentModal--input"
            onChange={e => setAssessmentName(e.currentTarget.value)}
            value={assessmentName}
            type="text"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={props.onHide}>Back</AdoptechButton>
        <AdoptechButton
          disabled={assessmentName === ''}
          onClick={() => {
            dispatch(generateAssessment(assessmentName));
            props.onHide();
          }}
          uppercase
          variant={AdoptechButtonVariant.Primary}
        >
          Next
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};

export default GenerateAssessmentModal;
