/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PaymentMethodCard
 */
export interface PaymentMethodCard {
  /**
   *
   * @type {string}
   * @memberof PaymentMethodCard
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodCard
   */
  last4?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodCard
   */
  expMonth?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodCard
   */
  expYear?: string;
  /**
   *
   * @type {boolean}
   * @memberof PaymentMethodCard
   */
  isDefault?: boolean | null;
}

export function PaymentMethodCardFromJSON(json: any): PaymentMethodCard {
  return PaymentMethodCardFromJSONTyped(json, false);
}

export function PaymentMethodCardFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentMethodCard {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    brand: !exists(json, 'brand') ? undefined : json['brand'],
    last4: !exists(json, 'last4') ? undefined : json['last4'],
    expMonth: !exists(json, 'exp_month') ? undefined : json['exp_month'],
    expYear: !exists(json, 'exp_year') ? undefined : json['exp_year'],
    isDefault: !exists(json, 'is_default') ? undefined : json['is_default'],
  };
}

export function PaymentMethodCardToJSON(value?: PaymentMethodCard | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    brand: value.brand,
    last4: value.last4,
    exp_month: value.expMonth,
    exp_year: value.expYear,
    is_default: value.isDefault,
  };
}
