import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { calculateChecklistProgress } from '../../functions/calculateChecklistProgress';
import { getProgressPercentage } from '../../functions/getPercentage';
import {
  Checklist,
  ChecklistExtended,
  ChecklistItem,
  ChecklistItemsGroups,
  ChecklistTemplateExtended,
} from '../../swagger';
import { NOT_FOUND_INDEX } from '../../types/constants';
import { ChecklistsState } from './checklistsState';

const vendorsSlice = createSlice({
  initialState: {
    checklists: [],
    checklistTemplates: [],
    isSharingModalShowing: false,
    isShowingGenerateChecklistSuccessToast: false,
  } as unknown as ChecklistsState,
  name: 'checklistsSlice',
  reducers: {
    fetchVendorChecklistsRequest: state => {
      state.isFetchingChecklists = true;
      state.checklists = [];
    },
    fetchVendorChecklistsSuccess: (
      state,
      action: PayloadAction<ChecklistExtended[]>
    ) => {
      state.checklists = action.payload;
      state.isFetchingChecklists = false;
    },
    fetchVendorChecklistsError: (state, action: PayloadAction<string>) => {
      state.isFetchingChecklists = false;
      state.fetchChecklistsError = action.payload;
    },
    fetchVendorChecklistTemplatesRequest: state => {
      state.checklistTemplates = [];
    },
    fetchVendorChecklistTemplatesSuccess: (
      state,
      action: PayloadAction<ChecklistTemplateExtended[]>
    ) => {
      state.checklistTemplates = action.payload;
    },
    fetchVendorChecklistTemplatesError: (
      state,
      action: PayloadAction<string>
    ) => {},
    fetchChecklistDetailsRequest: state => {
      state.isFetchingChecklistDetails = true;
    },
    fetchChecklistDetailsSuccess: (
      state,
      action: PayloadAction<ChecklistExtended>
    ) => {
      state.isFetchingChecklistDetails = false;
      const newChecklists = [...state.checklists];
      const checklistIndex = newChecklists.findIndex(
        checklist => checklist.id === action.payload.id
      );
      if (checklistIndex === -1) {
        newChecklists.push(action.payload);
      } else {
        newChecklists.splice(checklistIndex, 1, action.payload);
      }
      state.checklists = newChecklists;
    },
    fetchChecklistDetailsError: (state, action: PayloadAction<string>) => {
      state.isFetchingChecklistDetails = false;
      state.fetchChecklistError = action.payload;
    },
    fetchChecklistTemplateDetailsRequest: state => {},
    fetchChecklistTemplateDetailsSuccess: (
      state,
      action: PayloadAction<ChecklistTemplateExtended>
    ) => {
      const newChecklists = [...state.checklistTemplates];
      const checklistIndex = newChecklists.findIndex(
        checklist => checklist.id === action.payload.id
      );
      if (checklistIndex === -1) {
        newChecklists.push(action.payload);
      } else {
        newChecklists.splice(checklistIndex, 1, action.payload);
      }
      state.checklistTemplates = newChecklists;
    },
    fetchChecklistTemplateDetailsError: (
      state,
      action: PayloadAction<string>
    ) => {},
    updateChecklistItemRequest: state => {},
    updateChecklistItemSuccess: (
      state,
      action: PayloadAction<{
        checklistId: string;
        questionGroupId: string;
        item: ChecklistItem;
      }>
    ) => {
      const checklistIndex = state.checklists.findIndex(
        checklist => checklist.id === action.payload.checklistId
      );

      if (checklistIndex === NOT_FOUND_INDEX) return;

      const group = state.checklists[checklistIndex].itemsGroups.find(
        g => g.questionGroupId === action.payload.questionGroupId
      );

      const itemIndex = group.items.findIndex(
        i => i.questionId === action.payload.item.questionId
      );

      group.items[itemIndex] = action.payload.item;

      group.progress.answered = group.items.filter(
        i => i.answerNotApplicable || i.answerValue
      ).length;

      group.progress.unanswered = group.items.length - group.progress.answered;

      group.progress.percents = getProgressPercentage(group.progress);
    },
    updateChecklistItemError: (state, action: PayloadAction<string>) => {},
    showGenerateChecklistUsersModal: (
      state,
      action: PayloadAction<ChecklistTemplateExtended>
    ) => {
      state.isShowingGenerateChecklistUsersModal = true;
      state.checklistTemplateToGenerate = action.payload;
    },
    hideGenerateChecklistUsersModal: state => {
      state.isShowingGenerateChecklistUsersModal = false;
    },
    showGenerateChecklistManagersModal: (
      state,
      action: PayloadAction<ChecklistTemplateExtended>
    ) => {
      state.isShowingGenerateChecklistManagersModal = true;
      state.checklistTemplateToGenerate = action.payload;
    },
    hideGenerateChecklistManagersModal: state => {
      state.isShowingGenerateChecklistManagersModal = false;
    },
    addUserToGenerate: (state, action: PayloadAction<string>) => {
      state.userIdToGenerate = action.payload;
    },
    addManagersToGenerate: (
      state,
      action: PayloadAction<{ users: string[]; message: string }>
    ) => {
      state.managersToGenerate = action.payload.users;
      state.messageToGenerate = action.payload.message;
    },
    generateChecklistFromTemplateRequest: state => {
      state.isGeneratingChecklistFromTemplate = true;
    },
    generateChecklistFromTemplateSuccess: state => {
      state.isGeneratingChecklistFromTemplate = false;
    },
    generateChecklistFromTemplateError: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isGeneratingChecklistFromTemplate = false;
    },
    checklistChangeStatusRequest: state => {},
    checklistChangeStatusSuccess: (
      state,
      action: PayloadAction<ChecklistExtended>
    ) => {
      const newChecklists = [...state.checklists];
      const checklistIndex = newChecklists.findIndex(
        checklist => checklist.id === action.payload.id
      );
      if (checklistIndex === -1) {
        newChecklists.push(action.payload);
      } else {
        newChecklists.splice(checklistIndex, 1, action.payload);
      }
      state.checklists = newChecklists;
    },
    checklistChangeStatusError: (state, action: PayloadAction<string>) => {},
    showGenerateChecklistSuccessToast: state => {
      state.isShowingGenerateChecklistSuccessToast = true;
    },
    hideGenerateChecklistSuccessToast: state => {
      state.isShowingGenerateChecklistSuccessToast = false;
    },
  },
});

export const {
  fetchVendorChecklistsRequest,
  fetchVendorChecklistsSuccess,
  fetchVendorChecklistsError,
  fetchChecklistDetailsRequest,
  fetchChecklistDetailsSuccess,
  fetchChecklistDetailsError,
  updateChecklistItemRequest,
  updateChecklistItemSuccess,
  updateChecklistItemError,
  fetchVendorChecklistTemplatesRequest,
  fetchVendorChecklistTemplatesSuccess,
  fetchVendorChecklistTemplatesError,
  fetchChecklistTemplateDetailsRequest,
  fetchChecklistTemplateDetailsSuccess,
  fetchChecklistTemplateDetailsError,
  showGenerateChecklistUsersModal,
  hideGenerateChecklistUsersModal,
  showGenerateChecklistManagersModal,
  hideGenerateChecklistManagersModal,
  addManagersToGenerate,
  addUserToGenerate,
  generateChecklistFromTemplateRequest,
  generateChecklistFromTemplateSuccess,
  generateChecklistFromTemplateError,
  checklistChangeStatusRequest,
  checklistChangeStatusSuccess,
  checklistChangeStatusError,
  showGenerateChecklistSuccessToast,
  hideGenerateChecklistSuccessToast,
} = vendorsSlice.actions;

export default vendorsSlice.reducer;
