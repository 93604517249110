import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canIndex } from '../../functions/access';
import {
  fetchVendorChecklists,
  fetchVendorChecklistTemplates,
} from '../../store/checklists/checklistsThunks';
import { AccessObject } from '../../types/accessObject';
import { ActiveChecklists } from '../ActiveChecklists/ActiveChecklists';
import { ChecklistTemplates } from '../ChecklistTemplates/ChecklistTemplates';
import { CompleteChecklists } from '../CompleteChecklists/CompleteChecklists';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { ApplicationState } from '../../types/applicationState';
import { PaymentLandingPageChecker } from '../../features/pricing/PaymentLandingPageChecker/PaymentLandingPageChecker';

export const Checklists: React.FC = () => {
  const dispatch = useDispatch();

  const canIndexChecklistTemplates = canIndex(AccessObject.checklist_templates);
  const currentVendor = useSelector(selectCurrentVendor);
  const isFetchingChecklists = useSelector(
    (state: ApplicationState) => state.checklists.isFetchingChecklists
  );

  useEffect(() => {
    dispatch(fetchVendorChecklists(currentVendor.id));

    if (canIndexChecklistTemplates) {
      dispatch(fetchVendorChecklistTemplates(currentVendor.id));
    }
  }, [currentVendor?.id]);

  if (isFetchingChecklists) {
    return <LoadingSpinner />;
  }

  return (
    <PaymentLandingPageChecker type="checklists">
      <div>
        {canIndexChecklistTemplates && <ChecklistTemplates />}
        <ActiveChecklists />
        <CompleteChecklists />
      </div>
    </PaymentLandingPageChecker>
  );
};
