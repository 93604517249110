/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Answer, AnswerFromJSON, AnswerFromJSONTyped, AnswerToJSON } from './';

/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {Array<Answer>}
   * @memberof InlineObject
   */
  answers?: Array<Answer>;
}

export function InlineObjectFromJSON(json: any): InlineObject {
  return InlineObjectFromJSONTyped(json, false);
}

export function InlineObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineObject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    answers: !exists(json, 'answers')
      ? undefined
      : (json['answers'] as Array<any>).map(AnswerFromJSON),
  };
}

export function InlineObjectToJSON(value?: InlineObject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    answers:
      value.answers === undefined
        ? undefined
        : (value.answers as Array<any>).map(AnswerToJSON),
  };
}
