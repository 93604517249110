import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  showAddPaymentMethodModal,
  setCurrentPaymentMethodId,
} from '../../store/user/userSlice';
import { AddPaymentMethodModal } from '../AddPaymentMethodModal/AddPaymentMethodModal';
import { AdoptechButton } from '../AdoptechButton/AdoptechButton';
import { ManagePaymentMethodsGrid } from '../ManagePaymentMethodsGrid/ManagePaymentMethodsGrid';
import './ManagePaymentMethods.scss';
import { selectActiveSubscription } from '../../selectors/selectActiveSubscription';
import { PaymentMethodModalMode } from '../../store/user/userState';

export const ManagePaymentMethods: React.FC = () => {
  const dispatch = useDispatch();

  const hasActiveSubscription = !!useSelector(selectActiveSubscription);

  return (
    <div className="managePaymentMethods">
      <div className="managePaymentMethods--header">
        <div className="managePaymentMethods--title">Payment method</div>
        <div className="managePaymentMethods--uploadButton">
          {hasActiveSubscription && (
            <AdoptechButton
              onClick={() => {
                dispatch(setCurrentPaymentMethodId(null));
                dispatch(showAddPaymentMethodModal(PaymentMethodModalMode.Add));
              }}
            >
              Add payment method
            </AdoptechButton>
          )}
        </div>
      </div>
      <ManagePaymentMethodsGrid />
      <AddPaymentMethodModal />
    </div>
  );
};
