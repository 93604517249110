import React, { useState } from 'react';
import './PaymentPlanCard.scss';
import { Interweave } from 'interweave';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { PaymentPlan } from '../../../../swagger/models/PaymentPlan';
import { createPlanCheckoutSubscription } from '../../store/pricingThunks';
import { useDispatch, useSelector } from 'react-redux';
import { sendAnalytics } from '../../../../functions/sendAnalytics';
import { ApplicationState } from '../../../../types/applicationState';
import classNames from 'classnames';
import { CommandConfirmation } from '../../../../types/CommandConfirmation';
import { ConfirmationModal } from '../../../../components/ConfirmationModal/ConfirmationModal';
import { SharedPaymentPeriod } from '../../../../swagger';
import { selectPaymentPlans } from '../../../../selectors/selectPaymentPlans';
import { selectActiveSubscription } from '../../../../selectors/selectActiveSubscription';
import { PricingState } from '../../store/pricingState';
import { selectCurrentVendor } from '../../../../selectors/selectCurrentVendor';
import { openChatWidget } from '../../../../functions/chatWidgetHelper';

interface PaymentPlanCardProps {
  isActive?: boolean;
  plan: PaymentPlan;
}
// 3000 => 3,000
const numbersWithComma = (pounds: string) =>
  Intl.NumberFormat('en-US').format(+pounds);

export const coinsToPounds = (coins: number) => {
  if (coins === undefined) return;
  return numbersWithComma(Math.round(coins / 100).toFixed(0));
};
export const priceLabel = (
  price: string,
  opts: { showAnnualPrice: boolean }
) => {
  return price
    ? `£${price} + ${opts.showAnnualPrice ? 'VAT' : 'VAT/month'}`
    : '£POA';
};

export const isStarter = (plan: PaymentPlan) => plan.rank === 2;
const isProfessional = (plan: PaymentPlan) => plan.rank === 3;

export const useHandleSubscribe = () => {
  const dispatch = useDispatch();

  const handleSubscribe = ({
    plan,
    paymentPeriod,
  }: {
    plan: PaymentPlan;
    paymentPeriod: PricingState['subscriptionDetails']['paymentPeriod'];
  }) => {
    sendAnalytics('add_payment_info');
    dispatch(
      createPlanCheckoutSubscription({
        planId: plan.id,
        productId: plan.productId,
        purchasableId: null,
        purchasableType: null,
        paymentPeriod,
      })
    );
  };
  return handleSubscribe;
};
export const PaymentPlanCard: React.FC<PaymentPlanCardProps> = ({
  plan,
  isActive,
}) => {
  const baseCss = 'paymentPlanCard';
  const monthlyPrice = coinsToPounds(plan.monthlyPrice);
  const annuallyPrice = coinsToPounds(plan.annuallyPrice);

  const {
    subscriptionDetails,
    isCreatingPaymentPlanVendor,
    isUpdatingSubscription,
  } = useSelector((state: ApplicationState) => state.pricing);

  const { paymentPeriod } = subscriptionDetails;
  const [currentCommand, command] = useState<CommandConfirmation>(null);

  const showAnnualPrice =
    subscriptionDetails.paymentPeriod === SharedPaymentPeriod.Annually;
  const price = showAnnualPrice ? annuallyPrice : monthlyPrice;
  const { isDeletingSubscription, subscriptions } = useSelector(
    (state: ApplicationState) => state.user
  );

  const isStarterPlan = isStarter(plan);
  const isProfessionalPlan = isProfessional(plan);
  const paymentPlans = useSelector(selectPaymentPlans);
  const activeSubscription = useSelector(selectActiveSubscription);
  const activePlan =
    activeSubscription &&
    (paymentPlans || []).find(
      paymentPlan => paymentPlan.id === activeSubscription?.paymentPlanId
    );

  const buttonLabel = (): 'Talk to Sales' | 'Subscribe' =>
    // deprecated
    // | 'Unsubscribe'
    // | 'Upgrade'
    // | 'Downgrade'
    {
      if (plan.rank > 2) return 'Talk to Sales';

      const noSubscription = !activePlan;
      if (noSubscription) return 'Subscribe';

      return 'Talk to Sales';
      // deprecated
      // if (isActive) return 'Talk to Sales';
      // return plan.rank > activePlan.rank ? 'Upgrade' : 'Downgrade';
    };

  const dispatch = useDispatch();
  const currentVendor = useSelector(selectCurrentVendor);

  // deprecated
  // const handleUnsubscribe = () => {
  //   const subscription = subscriptions.find(
  //     (currentSubscription: Subscription) =>
  //       currentSubscription.paymentPlanId === plan.id &&
  //       currentSubscription.isActive &&
  //       currentSubscription.vendorId === currentVendor.id
  //   );
  //   const unsubscribeCommand: CommandConfirmation = {
  //     title: 'Cancel subscription',
  //     subject: { name: plan.name, type: 'Subscription' },
  //     description: 'Are you sure you wish to cancel your subscription?',
  //     buttonVariant: AdoptechButtonVariant.Warning,
  //     onConfirm: () => {
  //       dispatch(deleteSubscription(subscription.id));
  //     },
  //   };
  //   command(unsubscribeCommand);
  // };

  const handleSubscribe = useHandleSubscribe();

  // deprecated
  // const upgradeOrDowngradeCommand: CommandConfirmation = {
  //   title: 'Confirm the action',
  //   subject: { name: [plan.name, priceLabel(price)].join(' '), type: 'Plan' },
  //   description:
  //     'Please confirm you wish to change your plan. Charges will be applied immediately on a pro-rata basis',
  //   buttonVariant: AdoptechButtonVariant.Warning,
  //   onConfirm: () => {
  //     handleUpgradeOrDowngrade();
  //   },
  // };
  // const handleUpgradeOrDowngrade = () => {
  //   dispatch(
  //     updateSubscription({
  //       subscriptionId: activeSubscription?.id,
  //       paymentPlanId: plan.id,
  //       paymentPeriod,
  //       onSuccess: () => {
  //         dispatch(
  //           showGlobalToast(
  //             `Your subscription was successfully ${buttonLabel().toLowerCase()}d.`
  //           )
  //         );
  //       },
  //     })
  //   );
  // };
  const handleButtonClick = () => {
    const label = buttonLabel();

    switch (label) {
      case 'Subscribe':
        handleSubscribe({ plan, paymentPeriod });
        break;
      case 'Talk to Sales':
        sendAnalytics('add_payment_info');
        openChatWidget();
        break;
      // deprecated
      // case 'Upgrade':
      //   command(upgradeOrDowngradeCommand);
      //   break;
      // case 'Downgrade':
      //   command(upgradeOrDowngradeCommand);
      //   break;
      // case 'Unsubscribe':
      //   handleUnsubscribe();
      //   break;
    }
  };

  return (
    <div
      className={classNames([baseCss, { [baseCss + '--active']: isActive }])}
    >
      {isActive && <div className={baseCss + '--activeBadge'}>Subscribed</div>}
      <div className={baseCss + '--top-panel'}>
        <div className={baseCss + '--top-panel-title'}>{plan.name}</div>
        <div className={baseCss + '--top-panel-summary'}>{plan.summary}</div>
        <div className={baseCss + '--top-panel-price'}>
          {priceLabel(price, { showAnnualPrice })}
        </div>
        <div className={baseCss + '--top-panel-period'}>{plan.subheader}</div>
      </div>
      <div className={baseCss + '--main-panel'}>
        <Interweave content={plan.descriptionAsHtml} />
        <div className={baseCss + '--footer'}>
          <div className={baseCss + '--discounts-text'}>
            Start-up & Charity discounts available
          </div>
          <AdoptechButton
            onClick={handleButtonClick}
            uppercase
            busy={
              isDeletingSubscription ||
              isCreatingPaymentPlanVendor ||
              isUpdatingSubscription
            }
            variant={
              isActive
                ? AdoptechButtonVariant.DarkOrangeOnWhite
                : AdoptechButtonVariant.WhiteOnDarkOrange
            }
          >
            {buttonLabel()}
          </AdoptechButton>
        </div>
        <ConfirmationModal
          command={currentCommand}
          onCancel={() => {
            command(null);
          }}
        />
      </div>
    </div>
  );
};
