import { createSelector } from '@reduxjs/toolkit';
import { Vendor } from '../swagger/models';
import { ApplicationState } from '../types/applicationState';

const getCurrentVendor = (state: ApplicationState) =>
  state.vendors?.currentVendor;

export const selectCurrentVendor = createSelector(
  getCurrentVendor,
  (vendor: Vendor) => {
    return vendor;
  }
);
