/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum TrusthubVendorUserStatus {
  Unconfirmed = 'unconfirmed',
  Pending = 'pending',
  WaitingForNda = 'waiting_for_nda',
  Approved = 'approved',
  Suspended = 'suspended',
}

export function TrusthubVendorUserStatusFromJSON(
  json: any
): TrusthubVendorUserStatus {
  return TrusthubVendorUserStatusFromJSONTyped(json, false);
}

export function TrusthubVendorUserStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubVendorUserStatus {
  return json as TrusthubVendorUserStatus;
}

export function TrusthubVendorUserStatusToJSON(
  value?: TrusthubVendorUserStatus | null
): any {
  return value as any;
}
