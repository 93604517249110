import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import { AgreementTemplateCategoryModel } from '../../swagger';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import './DocumentLibraryHeader.scss';

type PolicyTemplateCategoryModel = { id: string; name: string };
type CategoryName =
  | AgreementTemplateCategoryModel['name']
  | PolicyTemplateCategoryModel['name'];

interface HeaderProps {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  categories: AgreementTemplateCategoryModel[] | PolicyTemplateCategoryModel[];
  categoryFilter: CategoryName;
  handleCategoryClick: (name?: CategoryName) => any;
  title: string;
  searchPlaceholder: string;
}

export const DocumentLibraryHeader: React.FC<HeaderProps> = ({
  search,
  setSearch,
  categories,
  categoryFilter,
  handleCategoryClick,
  title,
  searchPlaceholder,
}) => {
  const baseCss = 'documentLibraryHeader';

  return (
    <div className={baseCss + '--titleRow'}>
      <div className={baseCss + '--title'}>{title}</div>
      <div className={baseCss + '--categoryFilter'}>
        <div
          className={classNames(baseCss + '--categoryFilterItem', {
            selected: !categoryFilter,
          })}
          onClick={handleCategoryClick()}
        >
          All
        </div>
        {categories.map((category: HeaderProps['categories'][0]) => (
          <div
            className={classNames(baseCss + '--categoryFilterItem', {
              selected: categoryFilter === category.name,
            })}
            key={category.id}
            onClick={handleCategoryClick(category.name)}
          >
            {category.name}
          </div>
        ))}
      </div>
      <div className={baseCss + '--search'}>
        <AdoptechTextInput
          id="search"
          value={search}
          onChange={event => setSearch(event.currentTarget.value)}
          type="text"
          placeholder={searchPlaceholder}
          icon={faSearch}
          additionalClass="adoptechTextInput-search"
        />
      </div>
    </div>
  );
};
