/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PaymentMethodBillingDetails,
  PaymentMethodBillingDetailsFromJSON,
  PaymentMethodBillingDetailsFromJSONTyped,
  PaymentMethodBillingDetailsToJSON,
  PaymentMethodCard,
  PaymentMethodCardFromJSON,
  PaymentMethodCardFromJSONTyped,
  PaymentMethodCardToJSON,
} from './';

/**
 *
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  id?: string;
  /**
   *
   * @type {PaymentMethodBillingDetails}
   * @memberof PaymentMethod
   */
  billingDetails?: PaymentMethodBillingDetails | null;
  /**
   *
   * @type {PaymentMethodCard}
   * @memberof PaymentMethod
   */
  card?: PaymentMethodCard;
}

export function PaymentMethodFromJSON(json: any): PaymentMethod {
  return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentMethod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    billingDetails: !exists(json, 'billing_details')
      ? undefined
      : PaymentMethodBillingDetailsFromJSON(json['billing_details']),
    card: !exists(json, 'card')
      ? undefined
      : PaymentMethodCardFromJSON(json['card']),
  };
}

export function PaymentMethodToJSON(value?: PaymentMethod | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    billing_details: PaymentMethodBillingDetailsToJSON(value.billingDetails),
    card: PaymentMethodCardToJSON(value.card),
  };
}
