import { createSelector } from '@reduxjs/toolkit';
import { VendorDocumentApproval } from '../swagger';
import { ApplicationState } from '../types/applicationState';

export const selectPolicyRejections = createSelector(
  (state: ApplicationState) => state.policies.approvals,
  approvals =>
    approvals.filter(
      (approval: VendorDocumentApproval) => approval.status === 'rejected'
    )
);
