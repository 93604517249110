/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AuditCreatePayloadAudit,
  AuditCreatePayloadAuditFromJSON,
  AuditCreatePayloadAuditFromJSONTyped,
  AuditCreatePayloadAuditToJSON,
} from './';

/**
 *
 * @export
 * @interface AuditCreatePayload
 */
export interface AuditCreatePayload {
  /**
   *
   * @type {AuditCreatePayloadAudit}
   * @memberof AuditCreatePayload
   */
  audit: AuditCreatePayloadAudit;
}

export function AuditCreatePayloadFromJSON(json: any): AuditCreatePayload {
  return AuditCreatePayloadFromJSONTyped(json, false);
}

export function AuditCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuditCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    audit: AuditCreatePayloadAuditFromJSON(json['audit']),
  };
}

export function AuditCreatePayloadToJSON(
  value?: AuditCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    audit: AuditCreatePayloadAuditToJSON(value.audit),
  };
}
