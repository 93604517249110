import { createSelector } from '@reduxjs/toolkit';
import { selectVisibleClauseQuestions } from '../components/Agreements/AgreementClauseQuestions/AgreementClauseQuestions';
import { Survey } from '../swagger/models/Survey';
import { SurveyClause } from '../swagger/models/SurveyClause';
import { ApplicationState } from '../types/applicationState';
import { Question } from '../swagger';

export interface Stats {
  answered: number;
  unanswered: number;
  percents: number;
  id: string;
  questions: any;
}

const checkIfAnswered = (question: Question): boolean => {
  if (!question.answer) return false;

  if (question.layout == 'audit_review_table_layout') {
    const answerAuthor = question.answer.userName;
    const answerRow = question.table.answers.find(
      row => row[0]?.userName == answerAuthor
    );
    if ((answerRow?.length || 0) < question.questionColumns.length)
      return false;
  }

  return true;
};

const progressPerClause = (clause: SurveyClause): Stats => {
  const { id } = clause;
  const questions = selectVisibleClauseQuestions(clause);
  const answered = questions.filter(q => checkIfAnswered(q)).length;
  const unanswered = questions.length - answered;
  const percents = questions.length === 0 ? 1 : answered / questions.length;

  return {
    id,
    answered,
    unanswered,
    percents,
    questions,
  };
};

export interface AgreementsStats {
  stats: Array<Stats>;
  total: number;
}

export const calculateSurveyProgress = (survey: Survey): AgreementsStats => {
  if (!survey) {
    return { stats: [], total: 0 };
  }

  const interactiveSurveyClauses = survey.surveyClauses.filter(
    clause => clause.questions.length > 0
  );
  const stats = interactiveSurveyClauses.map(clause =>
    progressPerClause(clause)
  );
  const answeredPercentage = stats.reduce(
    (accumulator: number, statsPerClause: Stats) => {
      return accumulator + statsPerClause.percents;
    },
    0
  );
  const total = answeredPercentage / interactiveSurveyClauses.length;

  return { stats, total };
};

export const selectAgreementsProgress = createSelector(
  (state: ApplicationState) => state.agreements.currentAgreement?.survey,
  (survey): AgreementsStats => {
    return calculateSurveyProgress(survey);
  }
);
