/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MultipleShareDelete
 */
export interface MultipleShareDelete {
  /**
   *
   * @type {Array<string>}
   * @memberof MultipleShareDelete
   */
  emails: Array<string>;
}

export function MultipleShareDeleteFromJSON(json: any): MultipleShareDelete {
  return MultipleShareDeleteFromJSONTyped(json, false);
}

export function MultipleShareDeleteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MultipleShareDelete {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    emails: json['emails'],
  };
}

export function MultipleShareDeleteToJSON(
  value?: MultipleShareDelete | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    emails: value.emails,
  };
}
