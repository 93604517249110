/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubUserPasswordResetPayloadTrusthubUser,
  TrusthubUserPasswordResetPayloadTrusthubUserFromJSON,
  TrusthubUserPasswordResetPayloadTrusthubUserFromJSONTyped,
  TrusthubUserPasswordResetPayloadTrusthubUserToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubUserPasswordResetPayload
 */
export interface TrusthubUserPasswordResetPayload {
  /**
   *
   * @type {TrusthubUserPasswordResetPayloadTrusthubUser}
   * @memberof TrusthubUserPasswordResetPayload
   */
  trusthubUser: TrusthubUserPasswordResetPayloadTrusthubUser;
}

export function TrusthubUserPasswordResetPayloadFromJSON(
  json: any
): TrusthubUserPasswordResetPayload {
  return TrusthubUserPasswordResetPayloadFromJSONTyped(json, false);
}

export function TrusthubUserPasswordResetPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubUserPasswordResetPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    trusthubUser: TrusthubUserPasswordResetPayloadTrusthubUserFromJSON(
      json['trusthub_user']
    ),
  };
}

export function TrusthubUserPasswordResetPayloadToJSON(
  value?: TrusthubUserPasswordResetPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    trusthub_user: TrusthubUserPasswordResetPayloadTrusthubUserToJSON(
      value.trusthubUser
    ),
  };
}
