/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssertionFrequency,
  AssertionFrequencyFromJSON,
  AssertionFrequencyFromJSONTyped,
  AssertionFrequencyToJSON,
} from './';

/**
 *
 * @export
 * @interface AssertionUpdatePayloadAssertion
 */
export interface AssertionUpdatePayloadAssertion {
  /**
   *
   * @type {string}
   * @memberof AssertionUpdatePayloadAssertion
   */
  status?: AssertionUpdatePayloadAssertionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AssertionUpdatePayloadAssertion
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AssertionUpdatePayloadAssertion
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AssertionUpdatePayloadAssertion
   */
  autorun?: boolean;
  /**
   *
   * @type {AssertionFrequency}
   * @memberof AssertionUpdatePayloadAssertion
   */
  frequency?: AssertionFrequency;
  /**
   *
   * @type {string}
   * @memberof AssertionUpdatePayloadAssertion
   */
  ownerId?: string;
  /**
   *
   * @type {string}
   * @memberof AssertionUpdatePayloadAssertion
   */
  assertionCategoryId?: string;
}

export function AssertionUpdatePayloadAssertionFromJSON(
  json: any
): AssertionUpdatePayloadAssertion {
  return AssertionUpdatePayloadAssertionFromJSONTyped(json, false);
}

export function AssertionUpdatePayloadAssertionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionUpdatePayloadAssertion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    autorun: !exists(json, 'autorun') ? undefined : json['autorun'],
    frequency: !exists(json, 'frequency')
      ? undefined
      : AssertionFrequencyFromJSON(json['frequency']),
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    assertionCategoryId: !exists(json, 'assertion_category_id')
      ? undefined
      : json['assertion_category_id'],
  };
}

export function AssertionUpdatePayloadAssertionToJSON(
  value?: AssertionUpdatePayloadAssertion | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: value.status,
    name: value.name,
    description: value.description,
    autorun: value.autorun,
    frequency: AssertionFrequencyToJSON(value.frequency),
    owner_id: value.ownerId,
    assertion_category_id: value.assertionCategoryId,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AssertionUpdatePayloadAssertionStatusEnum {
  Pending = 'pending',
  Compliant = 'compliant',
}
