/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ReviewFrequencyEnum {
  _1Month = '1 month',
  _3Months = '3 months',
  _6Months = '6 months',
  _9Months = '9 months',
  _12Months = '12 months',
}

export function ReviewFrequencyEnumFromJSON(json: any): ReviewFrequencyEnum {
  return ReviewFrequencyEnumFromJSONTyped(json, false);
}

export function ReviewFrequencyEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReviewFrequencyEnum {
  return json as ReviewFrequencyEnum;
}

export function ReviewFrequencyEnumToJSON(
  value?: ReviewFrequencyEnum | null
): any {
  return value as any;
}
