import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitaliseFirst } from '../../../../../functions/capitaliseFirst';
import { getDate } from '../../../../../functions/getDate';
import { useOwnerOptions } from '../../../../../hooks/useOwnerOptions';
import { selectAgreementMode } from '../../../../../selectors/selectAgreements';
import { selectAgreementsEditForm } from '../../../../../selectors/selectAgreementsEditForm';
import { selectCurrentAgreement } from '../../../../../selectors/selectCurrentAgreement';
import { updateAgreementsEditFormField } from '../../../../../store/agreements/agreementsSlice';
import { SelectionOption } from '../../../../../types/selectionOption';
import { AdoptechDatePicker2 } from '../../../../AdoptechDatePicker2/AdoptechDatePicker2';
import { AdoptechReactSelect2 } from '../../../../AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechTextInput2 } from '../../../../AdoptechTextInput2/AdoptechTextInput2';
import { agreementsAccess } from '../../../AgreementMeatballMenu/AgreementMeatballMenu';
import { AgreementsEditForm } from '../../EditAgreementForm';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';

export const OverviewSection: React.FC = () => {
  const form = useSelector(selectAgreementsEditForm);
  const dispatch = useDispatch();

  const currentAgreement = useSelector(selectCurrentAgreement);
  const { name, reviewDate, owner } = currentAgreement;
  const baseCss = 'editAgreementForm';
  const mode = selectAgreementMode(currentAgreement);

  const setName = (value: AgreementsEditForm['name']) =>
    dispatch(updateAgreementsEditFormField({ name: value }));

  const setOwner = (value: AgreementsEditForm['owner']) =>
    dispatch(updateAgreementsEditFormField({ owner: value }));

  const setReviewDate = (value: AgreementsEditForm['reviewDate']) =>
    dispatch(updateAgreementsEditFormField({ reviewDate: value }));

  const { users, ownerOptions } = useOwnerOptions();
  const loaded = form.overviewSectionLoaded;

  const setLoaded = () => {
    dispatch(updateAgreementsEditFormField({ overviewSectionLoaded: true }));
  };

  useEffect(() => {
    if (!loaded) {
      dispatch(updateAgreementsEditFormField({ name, owner, reviewDate }));
      setLoaded();
    }
  }, []);

  if (!loaded) return null;
  return (
    <>
      <div className={baseCss + '--section-labels'}>
        <span className={baseCss + '--section-label'}>Overview</span>
        <div className={baseCss + '--section-badge'}>
          {capitaliseFirst(mode)}
        </div>
      </div>

      <div className="mt-2">
        <AdoptechTextInput2
          id="agreement-name"
          onChange={e => {
            setName(e.currentTarget.value);
          }}
          type="text"
          label="Agreement name"
          value={form.name || ''}
          hasError={!form.name}
          disabled={!agreementsAccess(currentAgreement).canEditName}
          icon={faPen}
        />
      </div>

      <div className={classNames(baseCss + '--2-columns', 'mt-2')}>
        <AdoptechReactSelect2
          id="agreement-owner"
          options={ownerOptions}
          onChange={(option: SelectionOption) => {
            const selectedOwner = users.find(user => user.id === option.value);
            setOwner(selectedOwner);
          }}
          value={ownerOptions.find(option => option.value === form.owner?.id)}
          placeholder="Select owner"
          label="Owner"
          showUserAvatar
          isDisabled={!agreementsAccess(currentAgreement).canEditOwner}
        />
        <AdoptechDatePicker2
          labelText="Review date"
          labelStyle={baseCss + '--label'}
          outputDate={getDate(form.reviewDate)}
          onDateSelect={(value: Date) => {
            setReviewDate(value.toISOString());
          }}
          placeHolderText="Select date"
          disabled={!agreementsAccess(currentAgreement).canEditReviewDate}
        ></AdoptechDatePicker2>
      </div>
    </>
  );
};
