/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EscrowAccount
 */
export interface EscrowAccount {
  /**
   *
   * @type {string}
   * @memberof EscrowAccount
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EscrowAccount
   */
  accountNumber: string | null;
  /**
   *
   * @type {string}
   * @memberof EscrowAccount
   */
  accountType: string;
  /**
   *
   * @type {string}
   * @memberof EscrowAccount
   */
  status: EscrowAccountStatusEnum;
  /**
   *
   * @type {string}
   * @memberof EscrowAccount
   */
  name: string | null;
  /**
   *
   * @type {string}
   * @memberof EscrowAccount
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof EscrowAccount
   */
  bucketName: string | null;
  /**
   *
   * @type {string}
   * @memberof EscrowAccount
   */
  accessKey: string | null;
  /**
   *
   * @type {string}
   * @memberof EscrowAccount
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof EscrowAccount
   */
  updatedAt: string;
}

export function EscrowAccountFromJSON(json: any): EscrowAccount {
  return EscrowAccountFromJSONTyped(json, false);
}

export function EscrowAccountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EscrowAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    accountNumber: json['account_number'],
    accountType: json['account_type'],
    status: json['status'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    bucketName: json['bucket_name'],
    accessKey: json['access_key'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function EscrowAccountToJSON(value?: EscrowAccount | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    account_number: value.accountNumber,
    account_type: value.accountType,
    status: value.status,
    name: value.name,
    description: value.description,
    bucket_name: value.bucketName,
    access_key: value.accessKey,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum EscrowAccountStatusEnum {
  Created = 'created',
  Linked = 'linked',
  Deleted = 'deleted',
}
