import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useMeasure from 'react-use/lib/useMeasure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { ApplicationState } from '../../types/applicationState';
import { PdfPreviewDocument } from '../PdfPreviewDocument/PdfPreviewDocument';
import './PdfPreviewCard.scss';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

interface PdfPreviewCardProps {
  waitingBeforeLoadingPDF: boolean;
}

export const PdfPreviewCard: React.FC<PdfPreviewCardProps> = ({
  waitingBeforeLoadingPDF,
}) => {
  const url = useSelector(
    (state: ApplicationState) => state.global.pdfPreviewUrl
  );
  const [pageCount, setPageCount] = useState(0);
  const [minHeightBackground, setMinHeightBackground] = useState(500);

  const handleLoadSuccess = (pdf: { numPages: number }) => {
    setMinHeightBackground(null);
    setPageCount(pdf.numPages);
  };
  const [modalBodyMeasurementRef, modalBodyBounds] = useMeasure();

  return (
    <div
      className="pdfPreviewCard--preview"
      ref={modalBodyMeasurementRef}
      style={{ minHeight: minHeightBackground }}
    >
      <div className="pdfPreviewCard--preview-header">
        <FontAwesomeIcon className="preview--icon mr-2" icon={faEye} />
        Preview
      </div>
      {waitingBeforeLoadingPDF ? (
        <LoadingSpinner />
      ) : (
        <PdfPreviewDocument
          bodyBounds={modalBodyBounds}
          handleLoadSuccess={handleLoadSuccess}
          pageCount={pageCount}
          fileSource={url}
        />
      )}
    </div>
  );
};
