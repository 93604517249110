import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import {
  VendorDocumentApprovalStatusEnum,
  VendorDocumentExtended,
} from '../../../swagger';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { formatShortDate } from '../../../functions/formatShortDate';
import { fetchDocumentApprovals } from '../../../store/policies/policiesThunks';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { DownloadDocumentModal } from '../../DownloadDocumentModal/DownloadDocumentModal';
import './PolicyVersionSection.scss';

export const PolicyVersionSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isShowingDownloadModal, setIsShowingDownloadModal] = useState(false);
  const policy = useSelector(
    (state: ApplicationState) =>
      state.policies.policyToEdit as VendorDocumentExtended
  );

  const { isFetchingDocumentApprovals, approvals } = useSelector(
    (state: ApplicationState) => state.policies
  );

  const currentDocument = policy;
  const baseCss = 'policyVersionSection';

  useEffect(() => {
    policy?.id && dispatch(fetchDocumentApprovals(policy?.id));
  }, [policy?.id]);

  const approvedBy = (approvals || [])
    .filter(
      approval => approval.status === VendorDocumentApprovalStatusEnum.Current
    )
    .map(approval => approval.fullName)
    .join(', ');
  return (
    <div className={baseCss}>
      <div className={baseCss + '--versionNumber'}>
        <div className={baseCss + '--subHeader'}>Current version</div>:{' '}
        {currentDocument.version}
      </div>
      <div className={baseCss + '--approvedBy' + ' d-flex'}>
        <div className={baseCss + '--subHeader'}>Approved by</div>:{' '}
        {isFetchingDocumentApprovals ? (
          <LoadingSpinner inlineSmall />
        ) : (
          approvedBy
        )}
      </div>
      <div className={baseCss + '--liveDate'}>
        <div className={baseCss + '--subHeader'}>Live date</div>:{' '}
        {formatShortDate(currentDocument.createdAt)}
      </div>

      <AdoptechButton
        extraClass="mt-2"
        onClick={() => setIsShowingDownloadModal(true)}
      >
        Download Document Audit
      </AdoptechButton>

      {isShowingDownloadModal && (
        <DownloadDocumentModal
          onClose={() => setIsShowingDownloadModal(false)}
          show
          vendorDocumentId={currentDocument.id}
        />
      )}
    </div>
  );
};
