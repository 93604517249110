import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { showGlobalToast } from '../../store/global/globalSlice';
import { cancelAddTeamModal } from '../../store/vendors/vendorsSlice';
import {
  fetchVendorTable,
  fetchVendorTeams,
  upsertVendorTeams,
} from '../../store/vendors/vendorsThunks';
import { QuestionObjectTypeEnum } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import './AddTeamModal.scss';

export const AddTeamModal: React.FC = () => {
  const teamNameRef = useRef<HTMLInputElement>();
  const [teamName, setTeamName] = useState<string>();

  const { isUpsertingVendorTeams, isAddTeamModalShowing } = useSelector(
    (state: ApplicationState) => state.vendors.teamsState
  );

  const currentVendor = useSelector(selectCurrentVendor);
  const vendorId = currentVendor?.id;

  const teams = useSelector(
    (state: ApplicationState) => state.vendors.teamsState.vendorTeams
  );

  useEffect(() => {
    isAddTeamModalShowing && dispatch(fetchVendorTeams(true));
  }, [isAddTeamModalShowing]);

  useEffect(() => {
    setTeamName('');

    if (teamNameRef.current) {
      teamNameRef.current.focus();
    }
  }, [isAddTeamModalShowing]);

  const dispatch = useDispatch();

  const handleAdd = () => {
    dispatch(
      upsertVendorTeams({
        vendorTeams: teams
          .map(({ id, name }) => ({
            id,
            name,
          }))
          .concat([{ name: teamName, id: null }]),
        vendorId,
        onSuccess: () => {
          dispatch(showGlobalToast('Your team has been added successfully.'));
          handleCancel();
          dispatch(
            fetchVendorTable({
              vendorId,
              type: QuestionObjectTypeEnum.VendorTeam,
            })
          );
        },
      })
    );
  };

  const handleCancel = () => {
    dispatch(cancelAddTeamModal());
  };

  return (
    <React.Fragment>
      <AdoptechModal onHide={handleCancel} show={isAddTeamModalShowing}>
        <Modal.Header>Add new group</Modal.Header>
        <Modal.Body className="addTeamModal--body">
          <div className="addTeamModal--prompt">
            Enter the group name as you would like it to appear in documents
            generated. Do not include "Group" in the group name
          </div>
          <div className="addTeamModal--fields">
            <div className="addTeamModal--label">Group name</div>
            <AdoptechTextInput
              id="addTeamModal--teamNameInput"
              onChange={e => {
                setTeamName(e.currentTarget.value);
              }}
              ref={teamNameRef}
              type="text"
              value={teamName}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={handleCancel}>Cancel</AdoptechButton>
          <AdoptechButton
            busy={isUpsertingVendorTeams}
            disabled={!teamName || teams.some(t => t.name === teamName)}
            onClick={handleAdd}
            variant={AdoptechButtonVariant.Primary}
          >
            ADD
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
