/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PaymentPlanVendorCheckoutModel
 */
export interface PaymentPlanVendorCheckoutModel {
  /**
   *
   * @type {string}
   * @memberof PaymentPlanVendorCheckoutModel
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPlanVendorCheckoutModel
   */
  stripeKey: string;
}

export function PaymentPlanVendorCheckoutModelFromJSON(
  json: any
): PaymentPlanVendorCheckoutModel {
  return PaymentPlanVendorCheckoutModelFromJSONTyped(json, false);
}

export function PaymentPlanVendorCheckoutModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentPlanVendorCheckoutModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    sessionId: json['sessionId'],
    stripeKey: json['stripe_key'],
  };
}

export function PaymentPlanVendorCheckoutModelToJSON(
  value?: PaymentPlanVendorCheckoutModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    sessionId: value.sessionId,
    stripe_key: value.stripeKey,
  };
}
