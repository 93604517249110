import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons/faCloudArrowUp';
import { faCheckSquare } from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faSend } from '@fortawesome/pro-light-svg-icons/faSend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentAgreement } from '../../../../../selectors/selectCurrentAgreement';
import { updateAgreementsEditFormField } from '../../../../../store/agreements/agreementsSlice';
import {
  AgreementModelStatusEnum,
  AgreementSignatoriesIdPatchRequest,
  AgreementSignatoryModel,
  AgreementSignatoryModelStatusEnum,
  AgreementSignatoryModelTypeEnum,
} from '../../../../../swagger';
import { agreementsAccess } from '../../../AgreementMeatballMenu/AgreementMeatballMenu';
import { MeatballMenu } from '../../../../MeatballMenu/MeatballMenu';
import {
  AgreementEditFormStep,
  AgreementSignatoryModelExtended,
} from '../../EditAgreementForm';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import classNames from 'classnames';
import { faSquareXmark } from '@fortawesome/pro-light-svg-icons/faSquareXmark';
import { ApplicationState } from '../../../../../types/applicationState';

interface SignatoryMeatballMenuProps {
  onSendReminderClick(signatory: AgreementSignatoryModel): void;
  onSignedOfflineClick?: (signatory: AgreementSignatoryModel) => void;
  onSignatureNotRequiredClick?: (signatory: AgreementSignatoryModel) => void;
  onUploadClick?: (signatory: AgreementSignatoryModel) => void;
  onViewClick?: () => void;
  signatory: AgreementSignatoryModel;
}
export const SignatoriesMeatballMenu: React.FC<SignatoryMeatballMenuProps> = ({
  onSendReminderClick,
  onSignedOfflineClick,
  onSignatureNotRequiredClick,
  onUploadClick,
  onViewClick,
  signatory,
}) => {
  const isExternal =
    signatory.type !== AgreementSignatoryModelTypeEnum['Internal'];
  const agreement = useSelector(selectCurrentAgreement);

  const isDraft = agreement.status === AgreementModelStatusEnum.Draft;

  const showReminderMenuItem =
    isExternal &&
    !isDraft &&
    signatory.status === AgreementSignatoryModelStatusEnum.AwaitingSignature;

  const showSignedOfflineMenuItem =
    isExternal &&
    agreementsAccess(agreement).canUploadSignatoryDocument &&
    signatory.status === AgreementSignatoryModelStatusEnum.AwaitingSignature;

  const showUploadDocumentMenuItem =
    isExternal &&
    signatory.status === AgreementSignatoryModelStatusEnum.SignedOffline &&
    !signatory.document;

  const showMarkAsNotRequiredMenuItem =
    isExternal &&
    agreementsAccess(agreement).canUploadSignatoryDocument &&
    signatory.status === AgreementSignatoryModelStatusEnum.AwaitingSignature;

  const form = useSelector(
    (state: ApplicationState) => state.agreements.agreementsEditForm
  );
  const binaryDocument = (form.signatoriesWithDocs || []).find(
    (model: AgreementSignatoryModelExtended) => model.id === signatory.id
  )?.documentBinary;

  const document = binaryDocument || signatory.document;
  const documentUploaded =
    isExternal &&
    signatory.status === AgreementSignatoryModelStatusEnum.SignedOffline &&
    document;

  const showSignatoriesMenuItem =
    agreementsAccess(agreement).canEditSignatories;

  const dispatch = useDispatch();
  const menuItemClasses = classNames({
    'meatballMenu-item': true,
    disabled: !documentUploaded,
  });

  const downloadButtonRef = useRef(null);

  if (
    !isExternal ||
    signatory.status === AgreementSignatoryModelStatusEnum.NotRequired
  )
    return null;

  return (
    <MeatballMenu>
      {showReminderMenuItem && (
        <Dropdown.Item
          className="meatballMenu--item"
          onClick={() => onSendReminderClick(signatory)}
        >
          <FontAwesomeIcon className="meatballMenu--icon" icon={faSend} />
          Send reminder
        </Dropdown.Item>
      )}
      {showSignatoriesMenuItem && (
        <Dropdown.Item
          className="meatballMenu--item"
          onClick={() =>
            dispatch(
              updateAgreementsEditFormField({
                step: AgreementEditFormStep.SignatoriesEdit,
                currentSignatoryId: signatory.id,
              })
            )
          }
        >
          <FontAwesomeIcon className="meatballMenu--icon" icon={faEye} />
          Edit signatory
        </Dropdown.Item>
      )}
      {showReminderMenuItem && (
        <Dropdown.Item
          className="meatballMenu--item"
          onClick={() =>
            dispatch(
              updateAgreementsEditFormField({
                step: AgreementEditFormStep.SignatoriesEdit,
                currentSignatoryId: signatory.id,
              })
            )
          }
        >
          <FontAwesomeIcon className="meatballMenu--icon" icon={faEye} />
          View signatory
        </Dropdown.Item>
      )}
      {showSignedOfflineMenuItem && (
        <Dropdown.Item
          className="meatballMenu--item"
          onClick={() => {
            if (onSignedOfflineClick) onSignedOfflineClick(signatory);
          }}
        >
          <FontAwesomeIcon
            className="meatballMenu--icon"
            icon={faCheckSquare}
          />
          Mark as Signed offline
        </Dropdown.Item>
      )}
      {showMarkAsNotRequiredMenuItem && (
        <Dropdown.Item
          className="meatballMenu--item"
          onClick={() => {
            if (onSignatureNotRequiredClick)
              onSignatureNotRequiredClick(signatory);
          }}
        >
          <FontAwesomeIcon
            className="meatballMenu--icon"
            icon={faSquareXmark}
          />
          Mark as Signature not required
        </Dropdown.Item>
      )}
      {showUploadDocumentMenuItem && (
        <Dropdown.Item
          className="meatballMenu--item"
          onClick={() => {
            if (onUploadClick) onUploadClick(signatory);
          }}
        >
          <FontAwesomeIcon
            className="meatballMenu--icon"
            icon={faCloudArrowUp}
          />
          Upload agreement signed offline
        </Dropdown.Item>
      )}
      {!isDraft && (
        <>
          <Dropdown.Item
            className={menuItemClasses}
            onClick={() => {
              if (onViewClick) onViewClick();
            }}
          >
            <FontAwesomeIcon className="meatballMenu--icon" icon={faEye} />
            View agreement signed offline
          </Dropdown.Item>
          <a
            ref={downloadButtonRef}
            href={
              document instanceof File
                ? URL.createObjectURL(document)
                : document
            }
            download="Signatory document"
            style={{ display: 'none' }}
          ></a>
          <Dropdown.Item
            className={menuItemClasses}
            onClick={e => {
              e.preventDefault();
              downloadButtonRef.current.click();
            }}
          >
            <FontAwesomeIcon
              className="meatballMenu--icon"
              icon={faCloudDownload}
            />
            Download agreement signed offline
          </Dropdown.Item>
        </>
      )}
    </MeatballMenu>
  );
};
