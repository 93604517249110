import { createSelector } from '@reduxjs/toolkit';
import { getVendorPolicies } from '../functions/getVendorPolicies';
import { vendorPolicySort } from '../functions/vendorPolicySort';
import { VendorPolicyDetailsStatusEnum } from '../swagger/models';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';

export const selectInProgressPolicies = createSelector(
  getVendorPolicies,
  selectSortSettings(Grid.InProgressPolicies),
  (vendorPolicies, sortSetting) => {
    const clonedVendorPolicies = [...vendorPolicies];
    return clonedVendorPolicies
      .filter(p => p.status === VendorPolicyDetailsStatusEnum.InProgress)
      .sort((a, b) => vendorPolicySort(a, b, sortSetting.columnSort));
  }
);
