import React, { useRef, useState } from 'react';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import './AdoptechColourInput.scss';
import { ColourPalette } from '../ColourPalette/ColourPalette';
import { isDisplayableColour } from '../../features/companyProfile/CompanyBrandingTable/CompanyBrandingTable';
import classNames from 'classnames';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AdoptechColourInputProps<T> {
  value: T;
  onChange: (value: T) => void;
  label: string;
  colourList?: string[];
}

export const AdoptechColourInput: React.FC<
  AdoptechColourInputProps<string>
> = props => {
  const baseCss = 'adoptechColourInput';
  const [colour, setColour] = useState<string>(props.value || '');
  const handleChange = (value: string) => {
    setColour(value);
    (isDisplayableColour(value) || value === '') && props.onChange(value);
  };
  const hasError = !colour || !isDisplayableColour(colour);
  const inputRef = useRef<HTMLInputElement>();
  return (
    <div
      className={classNames(baseCss, {
        hasError,
      })}
    >
      <div>{props.label}</div>
      <div className={baseCss + '--wrapper'}>
        <AdoptechTextInput
          ref={inputRef}
          type="text"
          id="colour-input"
          value={colour}
          onChange={e => handleChange(e.target.value)}
        />

        <div className={baseCss + '--listWrapper'}>
          <ColourPalette
            colourList={props.colourList}
            onSelect={(selectedColour: string) => {
              handleChange(selectedColour);
            }}
            gap="4px"
          />
          <FontAwesomeIcon
            className="icon"
            icon={faPencil}
            onClick={() => inputRef.current.focus()}
          />
        </div>
      </div>
    </div>
  );
};
