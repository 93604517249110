import React from 'react';
import { AssessmentTemplateExtended } from '../../swagger';
import AssessmentTemplateTile from '../AssessmentsTemplateTile/AssessmentsTemplateTile';

import './AssessmentsTemplateTilesGrid.scss';

interface AssessmentsTemplateTilesGridProps {
  templates: AssessmentTemplateExtended[];
}

export const AssessmentsTemplateTilesGrid: React.FC<
  AssessmentsTemplateTilesGridProps
> = ({ templates }) => {
  return (
    <>
      {templates && (
        <div className="assessmentsTemplateTilesGrid--grid">
          {templates.map(item => {
            return (
              <AssessmentTemplateTile key={item.id} assessmentTemplate={item} />
            );
          })}
        </div>
      )}
    </>
  );
};
export default AssessmentsTemplateTilesGrid;
