import {
  Image,
  Invoice,
  PaymentMethod,
  Subscription,
  UserDetails,
} from '../../swagger';
import { ApiRequestStatus } from '../global/globalState';

export enum PaymentMethodModalMode {
  Add,
  Edit,
}

export interface UserState {
  addingTwoFactorError?: string;
  addPaymentMethodError?: string;
  updatePaymentMethodError?: string;
  changePasswordError?: string;
  deletePaymentMethodError?: string;
  deleteSubscriptionError?: string;
  deleteUserError?: string;
  fetchInvoicesError?: string;
  fetchPaymentMethodsError?: string;
  fetchSubscriptionsError?: string;
  fetchingUserDetailsError?: string;
  invoices: Invoice[];
  isAddPaymentMethodModalShowing: boolean;
  paymentMethodModalMode: PaymentMethodModalMode;
  currentPaymentMethodId?: string;
  isAddingPaymentMethod: boolean;
  isUpdatingPaymentMethod: boolean;
  is2FAEnabledShowing: boolean;
  isChangingPassword: boolean;
  isDeletePaymentMethodModalShowing: boolean;
  isDeleteUserModalShowing: boolean;
  isDeletingPaymentMethod: boolean;
  isDeletingSubscription: boolean;
  isDeletingUser: boolean;
  isFetchingPaymentMethods: boolean;
  isFetchingSubscriptions: boolean;
  isFetchingInvoices: boolean;
  isFetchingUserDetails: boolean;
  isPasswordChangeSuccessfulToastShowing: boolean;
  isPasswordModalShowing: boolean;
  isProfileDeletedToastShowing: boolean;
  isProfileMenuShowing: boolean;
  isProfileModalShowing: boolean;
  isProfileSavedToastShowing: boolean;
  isTwoFactorSavedToastShowing: boolean;
  isUpdatingUserDetails: boolean;
  paymentMethods: PaymentMethod[];
  subscriptions: Subscription[];
  subscriptionToDelete: string;
  updatingUserDetailsError?: string;
  userDetails: UserDetails;
  isRequestingUserSignature: boolean;
  userSignature: Image;
  userSignatureRequestFailure: string;
  logoutUserStatus: ApiRequestStatus;
}
