import React from 'react';
import './PoliciesSection.scss';
import { TrustHubSectionProps } from '../CompanyDetailsSection/CompanyDetailsSection';
import { DocumentsSection } from '../DocumentsSection/DocumentsSection';

export const PoliciesSection: React.FC<TrustHubSectionProps> = ({
  isEditable,
  showDocumentDownloadIcon,
  sections,
  onDocumentClick,
  identifier,
}) => {
  return (
    <DocumentsSection
      identifier={identifier}
      isEditable={isEditable}
      sections={sections}
      onDocumentClick={onDocumentClick}
      showDocumentDownloadIcon={showDocumentDownloadIcon}
    />
  );
};
