import { createSelector } from '@reduxjs/toolkit';
import { getVendorDocuments } from '../functions/getVendorDocuments';
import { vendorDocumentSort } from '../functions/vendorDocumentSort';
import { VendorDocumentAttestationStatusEnum } from '../swagger';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';

export const selectArchivedDocuments = createSelector(
  getVendorDocuments,
  selectSortSettings(Grid.ArchivedDocuments),
  (vendorDocuments, sortSetting) => {
    const clonedVendorDocuments = [...vendorDocuments];
    return clonedVendorDocuments
      .filter(
        ad =>
          ad.vendorDocumentAttestation.status ===
            VendorDocumentAttestationStatusEnum.WasNotRequested ||
          ad.vendorDocumentAttestation.status ===
            VendorDocumentAttestationStatusEnum.Current
      )
      .filter(ad => ad.archived)
      .sort((a, b) => vendorDocumentSort(a, b, sortSetting.columnSort));
  }
);
