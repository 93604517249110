import React, { useState } from 'react';
import { ToggleSwitch } from '../../components/ToggleSwitch/ToggleSwitch';
import { useDispatch } from 'react-redux';
import { updateTrustHubSectionVisibility } from './store/trusthubThunks';
import { SectionsSectionIdPatchRequest } from '../../swagger/trusthub/apis/DefaultApi';
import { TrusthubSectionModel } from '../../swagger/trusthub/models/TrusthubSectionModel';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../components/AdoptechButton/AdoptechButton';
import {
  FormDocumentVisibility,
  TrusthubFormDocument,
  useEditTrustHubSection,
} from './admin/useEditTrustHubSection';
import { AdoptechCheckbox } from '../../components/AdoptechCheckbox/AdoptechCheckbox';
import { AdoptechReactSelect } from '../../components/AdoptechReactSelect/AdoptechReactSelect';
import { reactSelectLightBiggerStyle } from '../../functions/reactSelectCustomTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectionOption } from '../../types/selectionOption';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import {
  faLock,
  faLockKeyholeOpen,
  faUserGroup,
} from '@fortawesome/pro-light-svg-icons';
import { AdoptechTooltip } from '../../components/AdoptechTooltip/AdoptechTooltip';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { ConfirmationModal } from '../../components/ConfirmationModal/ConfirmationModal';
import { CommandConfirmation } from '../../types/CommandConfirmation';

export const bulkActionOptions = [
  {
    value: FormDocumentVisibility.Destroy,
    label: 'Do not show',
    icon: faEyeSlash,
    description: 'Hide the document from public view',
  },
  {
    value: FormDocumentVisibility.Public,
    label: 'Public',
    icon: faUserGroup,
    description: 'Document viewable by everyone',
  },
  // deprecated
  // {
  //   value: FormDocumentVisibility.PrivateUnlocked,
  //   label: 'Private and Unlocked',
  //   icon: faLockKeyholeOpen,
  //   description: 'Approved users can view document',
  // },
  {
    value: FormDocumentVisibility.PrivateLocked,
    label: 'Private and Locked',
    icon: faLock,
    description: 'Users require access to view document',
  },
];

export interface UseTrustHubSectionProps {
  identifier: string;
  sections: TrusthubSectionModel[];
  isEditable: boolean;
  confirmOnDelete?: boolean;
}

export const useTrustHubSection = ({
  identifier,
  sections,
  isEditable,
  confirmOnDelete,
}: UseTrustHubSectionProps) => {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const section = (sections || []).find(
    sectionModel => sectionModel.identifier === identifier
  );

  const baseCss = `${
    identifier.startsWith('environmental') ? 'esg' : identifier
  }Section`;
  const publicDocs = section
    ? [...section.trusthubDocuments.filter(doc => doc.id)].sort(
        (a, b) => a.rowOrder - b.rowOrder
      )
    : [];

  const onClose = () => setIsEditMode(false);

  const editSectionHandler = useEditTrustHubSection({
    section,
    onClose,
  });

  const [currentCommand, command] = useState<CommandConfirmation>(null);

  const removeCommand = (model: TrusthubFormDocument): CommandConfirmation => ({
    title: 'Are you sure you want to cancel?',
    description: 'You will lose any changes that have been made.',
    buttonVariant: AdoptechButtonVariant.Warning,
    buttonText: 'Discard changes',
    onConfirm: () => onClose(),
    showCancelButton: true,
    cancelButtonText: 'Keep editing',
  });

  const {
    sectionDescription,
    updateSectionStatus,
    handleSave,
    formDocuments,
    handleCheckboxAllChange,
    handleVisibilityChange,
  } = editSectionHandler;

  const isControls = identifier === 'controls';
  const titleBar = section && (
    <div className={baseCss + '--title'}>
      <div className={baseCss + '--titleInfo'}>
        <div>{section.name}</div>
      </div>
      <div className={baseCss + '--titleActions'}>
        {isEditable && (
          <ToggleSwitch
            label="Exclude this section"
            onChange={() => {
              const payload: SectionsSectionIdPatchRequest = {
                sectionId: section.id,
                body: {
                  trusthubSection: {
                    visible: !section.visible,
                  },
                },
              };

              dispatch(updateTrustHubSectionVisibility(payload));
            }}
            switchStyles={{
              width: 35,
              height: 18,
              offHandleColor: '#fff',
              onColor: '#666665',
            }}
            checked={!section.visible}
          />
        )}

        {isEditable && !isEditMode && isControls && (
          <>
            <div className={baseCss + '--lineDivider'}></div>
            <div>
              <AdoptechButton
                extraClass="editButton"
                icon={faPencil}
                onClick={() => setIsEditMode(true)}
                variant={AdoptechButtonVariant.White}
              >
                Edit
              </AdoptechButton>
            </div>
          </>
        )}

        {isEditMode && (
          <>
            <div className={baseCss + '--lineDivider'}></div>
            <div className={baseCss + '--companyActions'}>
              <AdoptechButton
                disabled={updateSectionStatus === 'loading'}
                onClick={() =>
                  confirmOnDelete ? command(removeCommand) : onClose()
                }
                variant={AdoptechButtonVariant.White}
              >
                Cancel
              </AdoptechButton>
              <AdoptechButton
                busy={updateSectionStatus === 'loading'}
                disabled={isControls ? false : !sectionDescription}
                onClick={handleSave}
                variant={AdoptechButtonVariant.White}
              >
                Save
              </AdoptechButton>
            </div>
            <ConfirmationModal
              command={currentCommand}
              onCancel={() => command(null)}
            />
          </>
        )}
      </div>
    </div>
  );

  const checkedDocs = formDocuments.filter(formDoc => formDoc.checked).length;
  const isBulkActionsDisabled = checkedDocs === 0;

  const bulkActionsRow = section && (
    <div className={baseCss + '--bulkActionsRow'}>
      <AdoptechCheckbox
        id={`${baseCss}-selectAll`}
        checked={checkedDocs === section.trusthubDocuments.length}
        onChange={e => {
          handleCheckboxAllChange();
        }}
        label="Select all"
      />
      <AdoptechTooltip
        showTooltip={isBulkActionsDisabled}
        text="Select at least one document to change visibility."
        identifier={`${baseCss}--bulkActionTooltip`}
      >
        <div>
          <AdoptechReactSelect
            id={`${baseCss}--bulkActionSelect`}
            options={bulkActionOptions}
            onChange={handleVisibilityChange}
            placeholder="Visibility"
            isDisabled={isBulkActionsDisabled}
            additionalStyling={reactSelectLightBiggerStyle}
            formatOptionLabel={(option: SelectionOption) => {
              const configOption = bulkActionOptions.find(
                bulkActionOption => bulkActionOption.value === option.value
              );
              return (
                <div className="bulkOptions">
                  <FontAwesomeIcon icon={configOption.icon} />
                  <div className="bulkOptions--label">
                    <div className="bulkOptions--labelText">
                      {configOption.label}
                    </div>
                    <div className="bulkOptions--labelDescription">
                      {configOption.description}
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </AdoptechTooltip>
    </div>
  );

  return {
    isEditMode,
    setIsEditMode,
    section,
    publicDocs,
    titleBar,
    baseCss,
    bulkActionsRow,
    editSectionHandler,
  };
};
