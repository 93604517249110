import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAgreementsCreateFormField } from '../../../store/agreements/agreementsSlice';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { handleSubmitClass } from '../../ExternalSignatoryForm/ExternalSignatoryForm';
import { AgreementsCreateForm, StepType } from './AddNewAgreementForm';
import { NameStepIsValid } from './NameStep/NameStep';
import { SignatoriesStepIsValid } from './SignatoriesStep/SignatoriesStep';

interface FooterProps {
  onClose: () => void;
  isLastStep: boolean;
  currentStepName: StepType;
  goToStep: (n: AgreementsCreateForm['step']) => void;
}

export const Footer: React.FC<FooterProps> = ({
  onClose,
  isLastStep,
  currentStepName,
  goToStep,
}) => {
  const dispatch = useDispatch();

  const {
    isCreatingAgreement,
    isCreatingOrUpdatingSignatory,
    agreementsCreateForm: form,
  } = useSelector((state: ApplicationState) => state.agreements);

  const isBusy = isCreatingAgreement || isCreatingOrUpdatingSignatory;
  const handleNext = () => {
    if (isLastStep) {
      (
        document.getElementsByClassName(
          handleSubmitClass
        )[0] as HTMLElement | null
      )?.click();
      return;
    }
    dispatch(updateAgreementsCreateFormField({ isTouched: true }));
    goToStep(form.step + 1);
  };

  const isStepValid = () => {
    if (currentStepName === 'NameStep') {
      return NameStepIsValid(form);
    }

    if (currentStepName === 'SignatoriesStep') {
      return SignatoriesStepIsValid(form);
    }

    return true;
  };

  return (
    <AdoptechButton
      disabled={!isStepValid()}
      onClick={handleNext}
      busy={isBusy}
      variant={AdoptechButtonVariant.Primary}
    >
      {isLastStep ? 'DONE' : 'NEXT'}
    </AdoptechButton>
  );
};
