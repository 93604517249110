/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PreviousAnswer
 */
export interface PreviousAnswer {
  /**
   *
   * @type {string}
   * @memberof PreviousAnswer
   */
  value?: string;
  /**
   *
   * @type {boolean}
   * @memberof PreviousAnswer
   */
  other?: boolean;
  /**
   *
   * @type {string}
   * @memberof PreviousAnswer
   */
  updatedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof PreviousAnswer
   */
  updatedAt?: string;
}

export function PreviousAnswerFromJSON(json: any): PreviousAnswer {
  return PreviousAnswerFromJSONTyped(json, false);
}

export function PreviousAnswerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PreviousAnswer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    value: !exists(json, 'value') ? undefined : json['value'],
    other: !exists(json, 'other') ? undefined : json['other'],
    updatedBy: !exists(json, 'updated_by') ? undefined : json['updated_by'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function PreviousAnswerToJSON(value?: PreviousAnswer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    value: value.value,
    other: value.other,
    updated_by: value.updatedBy,
    updated_at: value.updatedAt,
  };
}
