import { Interweave } from 'interweave';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChecklistItem } from '../../swagger';
import { AdoptechRoundCheckbox } from '../AdoptechRoundCheckbox/AdoptechRoundCheckbox';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import { LastUpdatedInfo } from '../LastUpdatedInfo/LastUpdatedInfo';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';
import './ChecklistGroupItem.scss';

interface ChecklistGroupItemProps {
  item: ChecklistItem;
  onCheckedChanged: (value: boolean) => void;
  onNotApplicableChanged: (value: boolean) => void;
  onNoteChanged: (note: string) => void;
  readonly?: boolean;
}

export const ChecklistGroupItem: React.FC<ChecklistGroupItemProps> = ({
  item,
  onCheckedChanged,
  onNotApplicableChanged,
  onNoteChanged,
  readonly,
}) => {
  const [checked, setChecked] = useState(item.answerValue);
  const [notApplicable, setNa] = useState(item.answerNotApplicable);
  const [note, setNote] = useState(item.answerNote);
  const baseCss = 'checklistGroupItem';

  useEffect(() => {
    setChecked(item.answerValue);
  }, [item.answerValue]);

  useEffect(() => {
    setNa(item.answerNotApplicable);
  }, [item.answerNotApplicable]);

  const handleChecked = () => {
    onCheckedChanged(!checked);
    setChecked(c => !c);
  };

  const handleNa = () => {
    setNa(!notApplicable);
    onNotApplicableChanged(!notApplicable);
  };

  const handleNoteChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setNote(value);
  };

  const handleNoteSubmit = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    onNoteChanged(value);
  };

  const lastUpdatedInfo = {
    updatedAt: item.answerUpdatedAt,
    updatedBy: item.answerUserName,
  };

  return (
    <div className={baseCss}>
      <div className={baseCss + '--question'}>
        <div className={baseCss + '--questionText'}>
          <Interweave content={item.questionTextAsHtml} />
        </div>
        <Interweave content={item.explanatoryTextAsHtml} />
        <div className={baseCss + '--questionNote'}>
          <AdoptechTextArea
            id={`note-${item.questionId}`}
            label="Add notes"
            onChange={handleNoteChanged}
            onBlur={handleNoteSubmit}
            value={note}
            disabled={readonly}
          />
        </div>
        <LastUpdatedInfo lastUpdatedInfo={lastUpdatedInfo} />
      </div>
      <div className={baseCss + '--answers'}>
        <OverlayTrigger
          overlay={
            <Tooltip id={`hint-tooltip-${item.questionId}`}>
              {notApplicable
                ? 'Slide to include clause'
                : 'Slide to exclude clause'}
            </Tooltip>
          }
          placement="left"
        >
          <div className={baseCss + '--na'}>
            <ToggleSwitch
              checked={!notApplicable}
              onChange={handleNa}
              label="N/A"
              disabled={readonly}
            />
          </div>
        </OverlayTrigger>
        <div className={baseCss + '--checkbox'}>
          <AdoptechRoundCheckbox
            checked={checked}
            onChange={handleChecked}
            id={item.questionText}
            label=""
            disabled={notApplicable || readonly}
          />
        </div>
      </div>
    </div>
  );
};
