/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  AssessmentRecipient,
  AssessmentRecipientFromJSON,
  AssessmentRecipientFromJSONTyped,
  AssessmentRecipientToJSON,
  AssessmentSection,
  AssessmentSectionFromJSON,
  AssessmentSectionFromJSONTyped,
  AssessmentSectionToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface Assessment
 */
export interface Assessment {
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  relativePath?: string | null;
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  vendorId?: string;
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  vendorName?: string;
  /**
   *
   * @type {Access}
   * @memberof Assessment
   */
  access?: Access;
  /**
   *
   * @type {Array<AssessmentSection>}
   * @memberof Assessment
   */
  assessmentSections?: Array<AssessmentSection>;
  /**
   *
   * @type {Array<AssessmentRecipient>}
   * @memberof Assessment
   */
  assessmentRecipients?: Array<AssessmentRecipient>;
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof Assessment
   */
  responsesRequested?: number;
  /**
   *
   * @type {number}
   * @memberof Assessment
   */
  responsesReceived?: number;
  /**
   *
   * @type {VendorUser}
   * @memberof Assessment
   */
  owner?: VendorUser;
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  ownerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  updatedAt?: string | null;
}

export function AssessmentFromJSON(json: any): Assessment {
  return AssessmentFromJSONTyped(json, false);
}

export function AssessmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Assessment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    relativePath: !exists(json, 'relative_path')
      ? undefined
      : json['relative_path'],
    name: !exists(json, 'name') ? undefined : json['name'],
    vendorId: !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
    vendorName: !exists(json, 'vendor_name') ? undefined : json['vendor_name'],
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    assessmentSections: !exists(json, 'assessment_sections')
      ? undefined
      : (json['assessment_sections'] as Array<any>).map(
          AssessmentSectionFromJSON
        ),
    assessmentRecipients: !exists(json, 'assessment_recipients')
      ? undefined
      : (json['assessment_recipients'] as Array<any>).map(
          AssessmentRecipientFromJSON
        ),
    status: !exists(json, 'status') ? undefined : json['status'],
    responsesRequested: !exists(json, 'responses_requested')
      ? undefined
      : json['responses_requested'],
    responsesReceived: !exists(json, 'responses_received')
      ? undefined
      : json['responses_received'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function AssessmentToJSON(value?: Assessment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    relative_path: value.relativePath,
    name: value.name,
    vendor_id: value.vendorId,
    vendor_name: value.vendorName,
    access: AccessToJSON(value.access),
    assessment_sections:
      value.assessmentSections === undefined
        ? undefined
        : (value.assessmentSections as Array<any>).map(AssessmentSectionToJSON),
    assessment_recipients:
      value.assessmentRecipients === undefined
        ? undefined
        : (value.assessmentRecipients as Array<any>).map(
            AssessmentRecipientToJSON
          ),
    status: value.status,
    responses_requested: value.responsesRequested,
    responses_received: value.responsesReceived,
    owner: VendorUserToJSON(value.owner),
    owner_id: value.ownerId,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
