/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorPolicyAnswerClosestQuestion
 */
export interface VendorPolicyAnswerClosestQuestion {
  /**
   *
   * @type {string}
   * @memberof VendorPolicyAnswerClosestQuestion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyAnswerClosestQuestion
   */
  layout?: string;
}

export function VendorPolicyAnswerClosestQuestionFromJSON(
  json: any
): VendorPolicyAnswerClosestQuestion {
  return VendorPolicyAnswerClosestQuestionFromJSONTyped(json, false);
}

export function VendorPolicyAnswerClosestQuestionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorPolicyAnswerClosestQuestion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    layout: !exists(json, 'layout') ? undefined : json['layout'],
  };
}

export function VendorPolicyAnswerClosestQuestionToJSON(
  value?: VendorPolicyAnswerClosestQuestion | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    layout: value.layout,
  };
}
