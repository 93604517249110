import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CompletedAssessment,
  CompletedAssessmentType,
} from '../../selectors/selectCompletedAssessments';
import { hideEditPublishedAssessmentModal } from '../../store/assessments/assessmentsSlice';
import { reopenAssessmentResponse } from '../../store/assessments/assessmentsThunks';
import { ApplicationState } from '../../types/applicationState';
import { GenericModal } from '../GenericModal/GenericModal';
import { canCreate } from '../../functions/access';
import { AccessObject } from '../../types/accessObject';
import { CompletedAssessmentsGridIn } from '../CompletedAssessmentsGridIn/CompletedAssessmentsGridIn';
import { CompletedAssessmentsGridOut } from '../CompletedAssessmentsGridOut/CompletedAssessmentsGridOut';
import { AssessmentTabs } from '../../store/assessments/assessmentsState';

export interface CompletedAssessmentsGrid {
  assessments: CompletedAssessment[];
}

export const CompletedAssessmentsGrid: React.FC<
  CompletedAssessmentsGrid
> = props => {
  const canCreateAssessments = canCreate(AccessObject.assessments);

  const editPublishedAssessmentResponseId = useSelector(
    (state: ApplicationState) =>
      state.assessments.editPublishedAssessmentResponseId
  );
  const isReopeningAssessmentResponse = useSelector(
    (state: ApplicationState) => state.assessments.isReopeningAssessmentResponse
  );
  const isShowingEditPublishedAssessmentModal = useSelector(
    (state: ApplicationState) =>
      state.assessments.isShowingEditPublishedAssessmentModal
  );
  const tabToSelect = useSelector(
    (state: ApplicationState) => state.assessments.assessmentTabToShow
  );
  const dispatch = useDispatch();

  if (props.assessments.length === 0) {
    return null;
  }

  const handleRowClick = (assessment: CompletedAssessment) => () => {
    if (assessment.type === CompletedAssessmentType.Assessment) {
      dispatch(push(`/r/riskManagement/assessments/summary/${assessment.id}`));
    }
    if (assessment.type === CompletedAssessmentType.AssessmentResponse) {
      dispatch(push(`/r/riskManagement/assessments/response/${assessment.id}`));
    }
  };

  return (
    <React.Fragment>
      {tabToSelect == AssessmentTabs.Incoming ? (
        <CompletedAssessmentsGridIn
          rowClickHandler={handleRowClick}
          assessments={props.assessments}
        ></CompletedAssessmentsGridIn>
      ) : (
        <CompletedAssessmentsGridOut
          rowClickHandler={handleRowClick}
          assessments={props.assessments}
        ></CompletedAssessmentsGridOut>
      )}
      <GenericModal
        cancelButtonLabel="Close"
        className="completedAssessmentsGrid--editModal"
        closeButtonLabel="EDIT"
        isBusy={isReopeningAssessmentResponse}
        onCancel={() => dispatch(hideEditPublishedAssessmentModal())}
        onClose={() => {
          dispatch(reopenAssessmentResponse(editPublishedAssessmentResponseId));
        }}
        show={isShowingEditPublishedAssessmentModal}
        showCancelButton
        title="Edit a Published Assessment"
      >
        Editing this Assessment will change its status back to In Progress and
        you will have to Publish the document again for the Questioner to see
        your response. Click EDIT to open the assessment and make changes or
        Close to go back.
      </GenericModal>
    </React.Fragment>
  );
};
