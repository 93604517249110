/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface ReportModel
 */
export interface ReportModel {
  /**
   *
   * @type {string}
   * @memberof ReportModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ReportModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ReportModel
   */
  type: ReportModelTypeEnum;
  /**
   *
   * @type {VendorUser}
   * @memberof ReportModel
   */
  owner?: VendorUser;
  /**
   *
   * @type {string}
   * @memberof ReportModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ReportModel
   */
  updatedAt?: string;
}

export function ReportModelFromJSON(json: any): ReportModel {
  return ReportModelFromJSONTyped(json, false);
}

export function ReportModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReportModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    type: json['type'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function ReportModelToJSON(value?: ReportModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    type: value.type,
    owner: VendorUserToJSON(value.owner),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ReportModelTypeEnum {
  LegalRegister = 'Report::LegalRegister',
  PestelRegister = 'Report::PestelRegister',
  RiskRegister = 'Report::RiskRegister',
  StatementOfApplicability = 'Report::StatementOfApplicability',
  StatementOfApplicabilitySummary = 'Report::StatementOfApplicabilitySummary',
  VendorRegister = 'Report::VendorRegister',
  Uploaded = 'Report::Uploaded',
}
