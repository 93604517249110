import { createSelector } from '@reduxjs/toolkit';
import { getInitials } from '../functions/getInitials';
import { UserDetails } from '../swagger/models';
import { ApplicationState } from '../types/applicationState';

const getUserDetails = (state: ApplicationState) => state.user.userDetails;

export const selectUserInitials = createSelector(
  getUserDetails,
  (userDetails: UserDetails) => {
    return getInitials({ user: userDetails });
  }
);
