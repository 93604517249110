import { Question, QuestionColumn } from '../swagger';
import flattenArray from './flattenArray';
import { CommonAnswerType } from '../types/CommonAnswerType';

const getTableAnswers = ({
  question,
  answers,
  indexProperty,
}: {
  question: Question;
  answers: CommonAnswerType[];
  indexProperty: 'name' | 'propertyName';
}): CommonAnswerType[] =>
  flattenArray(
    question.questionColumns.map((questionColumn: QuestionColumn) =>
      answers.filter(
        answer => answer[indexProperty] === questionColumn.columnValue
      )
    )
  );

export default getTableAnswers;
