import { createSelector } from '@reduxjs/toolkit';
import { getPolicyDocuments } from '../functions/getPolicyDocuments';
import {
  VendorDocumentAttestationStatusEnum,
  VendorDocumentExtended,
  VendorDocumentStatusEnum,
} from '../swagger';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';
import { getVendorPolicies } from '../functions/getVendorPolicies';
import { vendorDocumentWithCategoryNameSort } from '../functions/vendorDocumentWithCategoryNameSort';
import { ApplicationState } from '../types/applicationState';

export interface VendorDocumentWithCategoryName extends VendorDocumentExtended {
  categoryName: string;
}

export const myPoliciesFilterEnabled = (state: ApplicationState) => {
  return state.vendors.documentState.myPoliciesFilterEnabled;
};

export const selectLivePolicies = createSelector(
  getPolicyDocuments,
  getVendorPolicies,
  selectSortSettings(Grid.LivePolicies),
  myPoliciesFilterEnabled,
  (livePolicies, vendorPolicies, sortSetting, myPoliciesFilter) => {
    const clonedLivePolicies: VendorDocumentExtended[] = [...livePolicies];
    return clonedLivePolicies
      .filter(
        vd =>
          vd.status !== VendorDocumentStatusEnum.Approval &&
          vd.status !== VendorDocumentStatusEnum.Rejected
      )
      .filter(vendorDocument => {
        const attestation = vendorDocument.vendorDocumentAttestation;
        const relatedToMe = // was read by me or require read for me
          attestation &&
          attestation.status !==
            VendorDocumentAttestationStatusEnum.WasNotRequested;
        return myPoliciesFilter ? relatedToMe : true;
      })
      .map(vde => {
        return {
          ...vde,
          categoryName:
            vendorPolicies.find(vp => vp.vendorDocumentId === vde.id)
              ?.categoryName || '',
        } as VendorDocumentWithCategoryName;
      })
      .sort((a, b) =>
        vendorDocumentWithCategoryNameSort(a, b, sortSetting.columnSort)
      );
  }
);
