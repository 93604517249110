import {
  Assessment,
  AssessmentDownload,
  AssessmentRecipient,
  AssessmentResponse,
  AssessmentResponseAnswer,
  AssessmentTemplateExtended,
} from '../../swagger';
import { QuestionType } from '../../types/QuestionType';

export enum AddFileMode {
  File,
  Url,
}

export enum AssessmentTabs {
  Outgoing,
  Incoming,
}
export interface SendAssessmentDetails {
  count: number;
  id: string;
  optionalMessage?: string;
  responseDeadline?: string;
  to: string;
}

export enum SendAssessmentMode {
  Generate = 'Generate',
  Send = 'Send',
}

export interface AssessmentsState {
  addFileMode: AddFileMode;
  addFileToQuestionName: string;
  assessmentGenerationInProgress: boolean;
  assessmentGenerationError: string;
  assessments: Assessment[];
  assessmentResponseForDeletion?: AssessmentRecipient & AssessmentResponse;
  assessmentResponses: AssessmentResponse[];
  assessmentTemplates: AssessmentTemplateExtended[];
  assessmentToDelete: Assessment;
  clearSendAssessmentDetails: boolean;
  currentAssessment: Assessment;
  currentAssessmentResponse: AssessmentResponse;
  currentAssessmentTemplate: AssessmentTemplateExtended;
  assessmentResponseHistories: {
    [key: string]: AssessmentResponseAnswer[];
  };
  deleteAssessmentFileOrUrlError?: string;
  deleteAssessmentRecipientError?: string;
  deleteAssessmentResponseError?: string;
  deletingAssessmentError?: string;
  editPublishedAssessmentResponseId?: string;
  fetchAssessmentResponseHistoryError: string;
  fetchAssessmentsError?: string;
  fetchingAssessmentError?: string;
  fetchingAssessmentResponseError?: string;
  fetchingAssessmentTemplatesError?: string;
  fetchingAssessmentTemplateError?: string;
  fetchingIncomingAssessmentResponsesError?: string;
  generatedAssessmentFromTemplate?: Assessment;
  incomingAssessmentResponses: AssessmentResponse[];
  isAssessmentSentToastShowing: boolean;
  isDeleteAssessmentModalShowing: boolean;
  isDeletingAssessment: boolean;
  isDeletingAssessmentFileOrUrl: boolean;
  isDeletingAssessmentRecipient: boolean;
  isDeletingAssessmentResponse: boolean;
  isFetchingAssessment: boolean;
  isFetchingAssessmentResponse: boolean;
  isFetchingAssessmentResponseHistory: boolean;
  isFetchingAssessments: boolean;
  isFetchingAssessmentTemplates: boolean;
  isFetchingAssessmentTemplate: boolean;
  isFetchingIncomingAssessmentResponses: boolean;
  isReopeningAssessmentResponse: boolean;
  isSendingAssessment: boolean;
  isSharingAssessmentResponse: boolean;
  isShowingAddFileModal: boolean;
  isShowingConfirmDeleteAssessmentResponse: boolean;
  isShowingEditPublishedAssessmentModal: boolean;
  isShowingFileOrUrlSuccessfulToast: boolean;
  isShowingPublishAssessmentModal: boolean;
  isShowingPublishedAssessmentModal: boolean;
  isShowingSendAssessmentConfirmationModal: boolean;
  isShowingSendAssessmentModal: boolean;
  isUploadingFile: boolean;
  reopenAssessmentResponseError?: string;
  sendAssessmentDetails?: SendAssessmentDetails;
  sendAssessmentError?: string;
  sharingAssessmentResponseId?: string;
  isPatchingAssessmentResponse: boolean;
  patchAssessmentResponseError: string;
  isPublishingAssessmentResponse: boolean;
  publishAssessmentResponseError: string;
  uploadFileError?: string;
  isResendingAssessment: boolean;
  resendAssessmentError: string;
  isShowingResendAssessmentConfirmationModal: boolean;
  isAssessmentResentToastShowing: boolean;
  resendRecipient: AssessmentRecipient & AssessmentResponse;
  currentTableQuestions: QuestionType[];
  currentTableAnswerIndex: number;
  isShowingEditTableAnswersModal: boolean;
  isShowingDeleteTableAnswersModal: boolean;
  isShowingAssessmentSummaryExportModal: boolean;
  isExportingAssessmentResponses: boolean;
  exportAssessmentResponseError: string;
  assessmentDownload: AssessmentDownload;
  exportProgress: number;
  exportCancelled: boolean;
  isConfirmingAssessmentResponse: boolean;
  confirmAssessmentResponseFailure: string;
  isPatchingAssessment: boolean;
  patchAssessmentError?: string;
  assessmentTabToShow?: AssessmentTabs;
  generateAssessmentName?: string;
  sendAssessmentMode: SendAssessmentMode;
  showDeleteAssessmentToast: boolean;
}
