/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorIntegrationUpdatePayloadVendorIntegration
 */
export interface VendorIntegrationUpdatePayloadVendorIntegration {
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationUpdatePayloadVendorIntegration
   */
  apiKey?: string;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationUpdatePayloadVendorIntegration
   */
  apiSecret?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationUpdatePayloadVendorIntegration
   */
  region?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationUpdatePayloadVendorIntegration
   */
  detectorId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationUpdatePayloadVendorIntegration
   */
  tenant?: string | null;
}

export function VendorIntegrationUpdatePayloadVendorIntegrationFromJSON(
  json: any
): VendorIntegrationUpdatePayloadVendorIntegration {
  return VendorIntegrationUpdatePayloadVendorIntegrationFromJSONTyped(
    json,
    false
  );
}

export function VendorIntegrationUpdatePayloadVendorIntegrationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorIntegrationUpdatePayloadVendorIntegration {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    apiKey: !exists(json, 'api_key') ? undefined : json['api_key'],
    apiSecret: !exists(json, 'api_secret') ? undefined : json['api_secret'],
    region: !exists(json, 'region') ? undefined : json['region'],
    detectorId: !exists(json, 'detector_id') ? undefined : json['detector_id'],
    tenant: !exists(json, 'tenant') ? undefined : json['tenant'],
  };
}

export function VendorIntegrationUpdatePayloadVendorIntegrationToJSON(
  value?: VendorIntegrationUpdatePayloadVendorIntegration | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    api_key: value.apiKey,
    api_secret: value.apiSecret,
    region: value.region,
    detector_id: value.detectorId,
    tenant: value.tenant,
  };
}
