/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorIntegrationSetting
 */
export interface VendorIntegrationSetting {
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSetting
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSetting
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSetting
   */
  datatype?: VendorIntegrationSettingDatatypeEnum;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSetting
   */
  value?: string | null;
}

export function VendorIntegrationSettingFromJSON(
  json: any
): VendorIntegrationSetting {
  return VendorIntegrationSettingFromJSONTyped(json, false);
}

export function VendorIntegrationSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorIntegrationSetting {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    key: json['key'],
    name: !exists(json, 'name') ? undefined : json['name'],
    datatype: !exists(json, 'datatype') ? undefined : json['datatype'],
    value: !exists(json, 'value') ? undefined : json['value'],
  };
}

export function VendorIntegrationSettingToJSON(
  value?: VendorIntegrationSetting | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    key: value.key,
    name: value.name,
    datatype: value.datatype,
    value: value.value,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum VendorIntegrationSettingDatatypeEnum {
  String = 'string',
  Password = 'password',
}
