/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CardBillingDetailsAddress
 */
export interface CardBillingDetailsAddress {
  /**
   *
   * @type {string}
   * @memberof CardBillingDetailsAddress
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof CardBillingDetailsAddress
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof CardBillingDetailsAddress
   */
  line1?: string;
  /**
   *
   * @type {string}
   * @memberof CardBillingDetailsAddress
   */
  line2?: string;
  /**
   *
   * @type {string}
   * @memberof CardBillingDetailsAddress
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof CardBillingDetailsAddress
   */
  state?: string;
}

export function CardBillingDetailsAddressFromJSON(
  json: any
): CardBillingDetailsAddress {
  return CardBillingDetailsAddressFromJSONTyped(json, false);
}

export function CardBillingDetailsAddressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CardBillingDetailsAddress {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    city: !exists(json, 'city') ? undefined : json['city'],
    country: !exists(json, 'country') ? undefined : json['country'],
    line1: !exists(json, 'line1') ? undefined : json['line1'],
    line2: !exists(json, 'line2') ? undefined : json['line2'],
    postalCode: !exists(json, 'postal_code') ? undefined : json['postal_code'],
    state: !exists(json, 'state') ? undefined : json['state'],
  };
}

export function CardBillingDetailsAddressToJSON(
  value?: CardBillingDetailsAddress | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    city: value.city,
    country: value.country,
    line1: value.line1,
    line2: value.line2,
    postal_code: value.postalCode,
    state: value.state,
  };
}
