import React, { useEffect, useState } from 'react';
import { ComplianceEditControlFormModel } from '../../Types/complianceTypes';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../types/applicationState';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { Modal } from 'react-bootstrap';
import AdoptechModal from '../../../AdoptechModal/AdoptechModal';
import './../../../../features/compliance/controls/EditControlDrawer/EditDocumentModal/EditDocumentModal.scss';
import {
  fetchPoliciesForVendor,
  fetchVendorPolicies,
} from '../../../../store/policies/policiesThunks';
import {
  Policy,
  VendorPolicy,
  VendorPolicyDetails,
  VendorPolicyType,
  VendorsVendorIdVendorPoliciesGetQueryStatusEnum,
} from '../../../../swagger';
import Fuse from 'fuse.js';
import { useDocumentsSelectTree } from '../../../../features/compliance/controls/EditControlDrawer/EditDocumentModal/EditDocumentModal';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { AdoptechTextInput } from '../../../AdoptechTextInput/AdoptechTextInput';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../../../LoadingSpinner/LoadingSpinner';
import { uniqArrayObjects } from '../../../../functions/uniqueArrayObjects';

interface ControlEditPolicyModalProps {
  show: boolean;
  onChange: (control: ComplianceEditControlFormModel) => void;
  onHide: () => void;
}
export const isVendorPolicy = (policy: PolicyOrVendorPolicy) => {
  return !!((policy as VendorPolicyDetails).type || '').includes(
    'VendorPolicy'
  );
};

export type PolicyOrVendorPolicy = Policy | VendorPolicy;
export const ControlEditPolicyModal: React.FC<
  ControlEditPolicyModalProps
> = props => {
  const [search, setSearch] = useState('');
  const { editControlFormModel } = useSelector(
    (state: ApplicationState) => state.compliance
  );

  const { fetchPoliciesStatus, fetchVendorPoliciesStatus } = useSelector(
    (state: ApplicationState) => state.policies
  );

  const isLoading =
    fetchPoliciesStatus === 'loading' ||
    fetchVendorPoliciesStatus === 'loading';
  const dispatch = useAppDispatch();

  const [modalPolicies, setModalPolicies] = useState<PolicyOrVendorPolicy[]>(
    []
  );

  const hasPolicies = modalPolicies.length > 0;
  useEffect(() => {
    const fetchAsyncPolicies = async () => {
      const vendorPolicies = await dispatch(
        fetchVendorPolicies({
          queryStatus: [
            VendorsVendorIdVendorPoliciesGetQueryStatusEnum.Completed,
          ],
          queryType: [VendorPolicyType.Uploaded],
        })
      ).unwrap();
      const policies = await dispatch(fetchPoliciesForVendor()).unwrap();

      const formVendorPoliciesIds = (
        editControlFormModel.vendorPolicies || []
      ).map(vendorPolicy => vendorPolicy.id);
      const formPolicyCodes = (editControlFormModel.policies || []).map(
        policy => policy.code
      );

      const modalVendorPoliciesPayload = vendorPolicies.filter(policy => {
        return !formVendorPoliciesIds.includes(policy.id);
      });

      const modalPoliciesPayload = policies.filter(policy => {
        return !formPolicyCodes.includes((policy as Policy).code);
      });

      const modalRecords = [
        ...modalVendorPoliciesPayload,
        ...modalPoliciesPayload,
      ];
      type ObjectsWithIds = { id: string }[];
      setModalPolicies(uniqArrayObjects(modalRecords as ObjectsWithIds));
    };

    props.show && fetchAsyncPolicies();
  }, [props.show]);

  const options = {
    ignoreLocation: true,
    includeScore: true,
    keys: ['name'],
    threshold: 0,
  };

  const fuseDocuments = new Fuse(modalPolicies, options);
  const filteredDocuments = search
    ? fuseDocuments.search(search).map(x => x.item)
    : modalPolicies;

  const placeholder = 'Search for policies';
  const title = 'Add control policy';
  const baseCss = 'editDocumentModal';

  const { selectedIds, handleSelectAllButton, childClass, treeDiv } =
    useDocumentsSelectTree(filteredDocuments, baseCss);

  const handleConfirm = async () => {
    const newPolicies = modalPolicies.filter(
      policy => selectedIds.includes(policy.id) && !isVendorPolicy(policy)
    );

    const newVendorPolicies = modalPolicies.filter(policy => {
      return selectedIds.includes(policy.id) && isVendorPolicy(policy);
    });

    props.onChange({
      ...editControlFormModel,
      formTouched: true,
      policies: [...(editControlFormModel.policies || []), ...newPolicies],
      vendorPolicies: [
        ...(editControlFormModel.vendorPolicies || []),
        ...newVendorPolicies,
      ],
    });
    props.onHide();
  };

  const handleClose = props.onHide;

  return (
    <AdoptechModal
      show={props.show}
      onHide={props.onHide}
      className={childClass('modal')}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body className={childClass('body')}>
        {isLoading && <LoadingSpinner />}
        {!isLoading && hasPolicies && (
          <>
            <div className={childClass('search')}>
              <AdoptechTextInput
                id="search"
                value={search}
                onChange={e => setSearch(e.target.value)}
                type="text"
                placeholder={placeholder}
                icon={faSearch}
                additionalClass="adoptechTextInput-search"
              />
            </div>
            {treeDiv}
          </>
        )}
        {!isLoading && !hasPolicies && (
          <div>There are no available policies</div>
        )}
      </Modal.Body>
      <Modal.Footer className={childClass('footer')}>
        <AdoptechButton
          variant={AdoptechButtonVariant.White}
          onClick={handleSelectAllButton}
          extraClass={hasPolicies ? '' : 'invisible'}
        >
          Select All
        </AdoptechButton>
        {!hasPolicies && (
          <AdoptechButton
            variant={AdoptechButtonVariant.Primary}
            onClick={handleClose}
          >
            OK
          </AdoptechButton>
        )}
        {hasPolicies && (
          <AdoptechButton
            variant={AdoptechButtonVariant.Primary}
            onClick={handleConfirm}
            busy={isLoading}
            disabled={selectedIds.length === 0}
          >
            Confirm
          </AdoptechButton>
        )}
      </Modal.Footer>
    </AdoptechModal>
  );
};
