/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrusthubDocumentPermissionModel
 */
export interface TrusthubDocumentPermissionModel {
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentPermissionModel
   */
  id: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentPermissionModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentPermissionModel
   */
  documentType: TrusthubDocumentPermissionModelDocumentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentPermissionModel
   */
  status: TrusthubDocumentPermissionModelStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentPermissionModel
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentPermissionModel
   */
  updatedAt?: string | null;
}

export function TrusthubDocumentPermissionModelFromJSON(
  json: any
): TrusthubDocumentPermissionModel {
  return TrusthubDocumentPermissionModelFromJSONTyped(json, false);
}

export function TrusthubDocumentPermissionModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubDocumentPermissionModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    documentType: json['document_type'],
    status: json['status'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function TrusthubDocumentPermissionModelToJSON(
  value?: TrusthubDocumentPermissionModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    document_type: value.documentType,
    status: value.status,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum TrusthubDocumentPermissionModelDocumentTypeEnum {
  VendorDocument = 'VendorDocument',
  Certificate = 'Certificate',
}
/**
 * @export
 * @enum {string}
 */
export enum TrusthubDocumentPermissionModelStatusEnum {
  Pending = 'pending',
  Approved = 'approved',
}
