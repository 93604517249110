import React, { SyntheticEvent, useState } from 'react';
import './TrustHubVendorUserTableRow.scss';
import { push } from 'connected-react-router';
import { TrusthubVendorUserModel } from '../../../../../../swagger/trusthub/models/TrusthubVendorUserModel';
import { useDispatch, useSelector } from 'react-redux';
import { trusthubAdminPageUserRoute } from '../../../../../../components/Routes/Routes';
import { capitalize } from '../../../../../../functions/capitalize';
import { formatShortDate } from '../../../../../../functions/formatShortDate';
import AdoptechOverflowLine from '../../../../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { TrusthubVendorUserStatus } from '../../../../../../swagger/trusthub';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../../components/AdoptechButton/AdoptechButton';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { postTrustHubInvitation } from '../../../../store/trusthubThunks';
import { ApplicationState } from '../../../../../../types/applicationState';

interface CompanyTableRowProps {
  user: TrusthubVendorUserModel;
}

export const userStatus = (status: string) => {
  return capitalize(status.replaceAll('_', ' '));
};

export const TrustHubVendorUserTableRow: React.FC<CompanyTableRowProps> = ({
  user,
}) => {
  const baseCss = 'trusthubVendorUserTableRow panelTableRow';
  const dispatch = useDispatch();
  const { postTrusthubInvitationStatus, settings } = useSelector(
    (state: ApplicationState) => state.trustHub
  );

  const [reminderClicked, setReminderClicked] = useState(false);

  const handleReminder = (e: SyntheticEvent) => {
    e.stopPropagation();
    setReminderClicked(true);
    dispatch(
      postTrustHubInvitation({
        trusthubUser: {
          email: user.email,
          domain: settings.domain,
        },
      })
    );
  };
  const ReminderButton =
    reminderClicked && postTrusthubInvitationStatus === 'succeeded' ? (
      'Reminder sent ✅'
    ) : (
      <AdoptechButton
        icon={faEnvelope}
        variant={AdoptechButtonVariant.Default}
        onClick={handleReminder}
        size="middle"
        busy={reminderClicked && postTrusthubInvitationStatus === 'loading'}
      >
        Reminder
      </AdoptechButton>
    );
  return (
    <div
      className={baseCss}
      onClick={() =>
        dispatch(push(trusthubAdminPageUserRoute.replace(':id', user.id)))
      }
    >
      <AdoptechOverflowLine className={baseCss + '--name'}>
        {user.fullName}
      </AdoptechOverflowLine>
      {user.trusthubCompany?.name ? (
        <div className={baseCss + '--company'}>
          {user.trusthubCompany?.name}
        </div>
      ) : (
        <div>-</div>
      )}

      <AdoptechOverflowLine className={baseCss + '--email'}>
        {user.email}
      </AdoptechOverflowLine>
      <div className={baseCss + '--status'}>
        {user.status === TrusthubVendorUserStatus.Unconfirmed
          ? ReminderButton
          : userStatus(user.status)}
      </div>
      <div className={baseCss + '--lastLoginAt'}>
        {/* TODO when BE will be done */}
        {/* @ts-ignore */}
        {formatShortDate(user.lastLoginAt) || '-'}
      </div>
    </div>
  );
};
