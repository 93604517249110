import { loadStripe } from '@stripe/stripe-js';
import { getMetaContent } from '../../../functions/getMetaContent';
import { UNKNOWN_ERROR } from '../../../types/constants';
import { VoidThunk } from '../../../types/voidThunk';
import {
  createPaymentPlanVendorFailure,
  createPaymentPlanVendorRequest,
  createStripeCheckoutFailure,
  fetchPaymentPlansFailure,
  fetchPaymentPlansRequest,
  fetchPaymentPlansSuccess,
  updateSubscriptionFailure,
  updateSubscriptionRequest,
  updateSubscriptionSuccess,
} from './pricingSlice';
import { api } from '../../../api/api';
import { fetchSubscriptions } from '../../../store/user/userThunks';
import { RecurringPaymentCheckoutCreatePayload } from '../../../swagger/models/RecurringPaymentCheckoutCreatePayload';
import { getErrorMessage } from '../../../store/helpers/thunkHelpers';
import { PaymentPlanVendorCheckoutCreatePayload } from '../../../swagger/models/PaymentPlanVendorCheckoutCreatePayload';
import { showWarningSubscriptionLimitModal } from '../../../store/vendors/vendorsSlice';
import { SharedPaymentPeriod } from '../../../swagger';
import { getApiErrorMessage } from '../../../functions/getApiErrorMessage';
import {
  fetchVendors,
  getVendorProducts,
} from '../../../store/vendors/vendorsThunks';

export const createCheckoutSession =
  ({
    priceId,
    purchasableId,
    purchasableType,
  }: {
    priceId: string;
    planId: string;
    is_monthly: boolean;
    is_annually: boolean;
    purchasableId: string;
    purchasableType: string;
    single?: boolean;
  }): VoidThunk =>
  async (dispatch, getState) => {
    // TODO: remove this if one-time payment backend will be removed
    try {
      const vendorId = getState().vendors.currentVendor?.id;

      dispatch(createPaymentPlanVendorRequest());

      const payload = JSON.stringify({
        vendor_id: vendorId,
        price_id: priceId,
        purchasable_id: purchasableId,
        purchasable_type: purchasableType,
      });
      const response = await fetch(`/api/v1/create_stripe_product`, {
        body: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getMetaContent('csrf-token'),
        },
        method: 'POST',
      });

      if (response.status === 201) {
        const respJson = await response.json();

        const stripePromise = loadStripe(respJson['stripe_key']);
        const stripe = await stripePromise;

        stripe.redirectToCheckout({
          sessionId: respJson['sessionId'],
        });
      } else {
        dispatch(
          createStripeCheckoutFailure(
            'An unexpected error occured, please try again later'
          )
        );
      }
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while checkout product or plan. Please try again or contact support.'
        );
        console.log(err);
        dispatch(createPaymentPlanVendorFailure(apiErrorMessage));
      }
    }
  };

export const createPlanCheckoutSubscription =
  (
    params: Omit<PaymentPlanVendorCheckoutCreatePayload, 'vendorId'>
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;

      dispatch(createPaymentPlanVendorRequest());

      const requestParams = { ...params, vendorId: vendorId };

      const response = await api().createStripeSessionPost({
        body: requestParams,
      });

      const { stripeKey, sessionId } = response;
      const stripePromise = loadStripe(stripeKey);
      const stripe = await stripePromise;
      stripe.redirectToCheckout({ sessionId });
    } catch (err) {
      if (err instanceof Response && err.status == 402) {
        dispatch(showWarningSubscriptionLimitModal());
        dispatch(createPaymentPlanVendorFailure(null));
        return;
      }

      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while checkout product. Please try again or contact support.'
      );
      dispatch(createPaymentPlanVendorFailure(apiErrorMessage));
    }
  };

export const createProductsCheckoutSession =
  (
    params: Omit<RecurringPaymentCheckoutCreatePayload, 'vendorId'>
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;

      dispatch(createPaymentPlanVendorRequest());

      const requestParams = { ...params, vendorId: vendorId };

      const response = await api().createStripeRecurringPaymentPost({
        body: requestParams,
      });

      const { stripeKey, sessionId } = response;
      const stripePromise = loadStripe(stripeKey);
      const stripe = await stripePromise;
      stripe.redirectToCheckout({ sessionId });
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while checkout product. Please try again or contact support.'
        );
        console.log(err);
        dispatch(createPaymentPlanVendorFailure(apiErrorMessage));
      }
    }
  };

interface UpdateSubscriptionPriceProps {
  subscriptionId: string;
  paymentPlanId: string;
  paymentPeriod: SharedPaymentPeriod;
  onSuccess?: () => void;
  onFinish?: () => void;
}
export const updateSubscription =
  ({
    subscriptionId,
    paymentPlanId,
    paymentPeriod,
    onSuccess,
    onFinish,
  }: UpdateSubscriptionPriceProps): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateSubscriptionRequest());
      await api().subscriptionsIdPatch({
        id: subscriptionId,
        body: {
          paymentPlanId,
          paymentPeriod,
        },
      });
      await Promise.all([
        dispatch(fetchVendors()),
        dispatch(fetchSubscriptions()),
        dispatch(getVendorProducts(true)),
      ]);

      dispatch(updateSubscriptionSuccess());

      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        if (err.status == 402) {
          dispatch(showWarningSubscriptionLimitModal());
          dispatch(updateSubscriptionFailure(null));
          return;
        }

        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred updating your subscription. Please try again or contact support.'
        );
        dispatch(updateSubscriptionFailure(apiErrorMessage));
      } else {
        dispatch(updateSubscriptionFailure(UNKNOWN_ERROR));
      }
      console.log(err);
    } finally {
      onFinish && onFinish();
    }
  };

export const fetchPaymentPlans = (): VoidThunk => async dispatch => {
  try {
    dispatch(fetchPaymentPlansRequest());
    const result = await api().paymentPlansGet();
    dispatch(fetchPaymentPlansSuccess(result));
  } catch (err) {
    if (err instanceof Response) {
      console.log(
        'API error',
        `Status: ${err.status} Message: ${err.statusText}`
      );
      const apiErrorMessage = await getApiErrorMessage(err);
      if (apiErrorMessage) {
        dispatch(fetchPaymentPlansFailure(apiErrorMessage));
      } else {
        dispatch(
          fetchPaymentPlansFailure(
            'An error occurred fetching payment plans. Please try again or contact support.'
          )
        );
      }
    } else {
      console.log(err);
      dispatch(fetchPaymentPlansFailure(UNKNOWN_ERROR));
    }
  }
};
