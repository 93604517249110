import { sortByDate } from './sortByDate';

type T = Record<string, any>;

export const sortByDateColumn = (
  arr: T[],
  column: keyof T,
  direction: 1 | -1
): T[] => {
  return arr.sort((a, b) => sortByDate(a[column], b[column], direction));
};
