/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AgreementCreatePayloadAgreement
 */
export interface AgreementCreatePayloadAgreement {
  /**
   *
   * @type {string}
   * @memberof AgreementCreatePayloadAgreement
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementCreatePayloadAgreement
   */
  agreementTemplateId: string;
  /**
   *
   * @type {string}
   * @memberof AgreementCreatePayloadAgreement
   */
  sourceAgreementId?: string;
}

export function AgreementCreatePayloadAgreementFromJSON(
  json: any
): AgreementCreatePayloadAgreement {
  return AgreementCreatePayloadAgreementFromJSONTyped(json, false);
}

export function AgreementCreatePayloadAgreementFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementCreatePayloadAgreement {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    agreementTemplateId: json['agreement_template_id'],
    sourceAgreementId: !exists(json, 'source_agreement_id')
      ? undefined
      : json['source_agreement_id'],
  };
}

export function AgreementCreatePayloadAgreementToJSON(
  value?: AgreementCreatePayloadAgreement | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    agreement_template_id: value.agreementTemplateId,
    source_agreement_id: value.sourceAgreementId,
  };
}
