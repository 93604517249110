import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchResults } from '../../store/global/globalThunks';
import { ApplicationState } from '../../types/applicationState';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import GlobalSearchResults from './GlobalSearchResults';
import './GlobalSearch.scss';
import { faDeleteRight } from '@fortawesome/pro-light-svg-icons/faDeleteRight';
import {
  clearGlobalSearchResults,
  setGlobalSearchBarVisible,
} from '../../store/global/globalSlice';

export const MIN_SEARCH_LENGTH = 3;

export const GlobalSearchBar: React.FC = () => {
  const [inputText, setInputText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const baseCss = 'globalSearch';
  const dispatch = useDispatch();
  const delayMilliseconds = 700;
  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(getSearchResults(vendorId, searchTerm));
    }, delayMilliseconds);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  const searchClasses = classNames({
    [`${baseCss}--input`]: true,
    [`${baseCss}--open`]: !!inputText,
    [`${baseCss}--closed`]: !inputText,
  });

  return (
    <>
      <div
        className={`${baseCss}--background`}
        onClick={() => {
          dispatch(setGlobalSearchBarVisible(false));
        }}
      ></div>
      <AdoptechTextInput
        autoFocus
        onIconClicked={e => {
          setInputText('');
          setSearchTerm('');
          dispatch(clearGlobalSearchResults());
        }}
        id="globalSearch"
        value={inputText}
        onChange={e => {
          setInputText(e.target.value);
          if (e.target.value.length >= MIN_SEARCH_LENGTH)
            setSearchTerm(e.target.value);
          else dispatch(clearGlobalSearchResults());
        }}
        type="text"
        placeholder="Search the Adoptech platform"
        icon={inputText ? faDeleteRight : faSearch}
        additionalClass={searchClasses}
      />
      <GlobalSearchResults baseCss={baseCss} inputText={inputText} />
    </>
  );
};
export default GlobalSearchBar;
