/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrusthubUserPasswordResetPayloadTrusthubUser
 */
export interface TrusthubUserPasswordResetPayloadTrusthubUser {
  /**
   *
   * @type {string}
   * @memberof TrusthubUserPasswordResetPayloadTrusthubUser
   */
  resetPasswordToken: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubUserPasswordResetPayloadTrusthubUser
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubUserPasswordResetPayloadTrusthubUser
   */
  passwordConfirmation: string;
}

export function TrusthubUserPasswordResetPayloadTrusthubUserFromJSON(
  json: any
): TrusthubUserPasswordResetPayloadTrusthubUser {
  return TrusthubUserPasswordResetPayloadTrusthubUserFromJSONTyped(json, false);
}

export function TrusthubUserPasswordResetPayloadTrusthubUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubUserPasswordResetPayloadTrusthubUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    resetPasswordToken: json['reset_password_token'],
    password: json['password'],
    passwordConfirmation: json['password_confirmation'],
  };
}

export function TrusthubUserPasswordResetPayloadTrusthubUserToJSON(
  value?: TrusthubUserPasswordResetPayloadTrusthubUser | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    reset_password_token: value.resetPasswordToken,
    password: value.password,
    password_confirmation: value.passwordConfirmation,
  };
}
