import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import './FrameworkRequirementCategories.scss';
import { AdoptechAccordionCard } from '../../AdoptechAccordionCard/AdoptechAccordionCard';
import { Accordion } from 'react-bootstrap';
import {
  FrameworkRequirementCategoryExtended,
  FrameworkRequirementExtended,
  FrameworkRequirementExtendedStatusEnum,
  FrameworkRequirementModel,
  FrameworkRequirementModelTypeEnum,
} from '../../../swagger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import {
  createVendorRequirement,
  fetchFrameworkGuidance,
} from '../../../store/compliance/complianceThunks';
import { FrameworkGuidance } from './FrameworkGuidence';

interface Props {
  categories: FrameworkRequirementCategoryExtended[];
}

const baseCss = 'frameworkRequirementCategories';
export const FrameworkRequirementCategories: React.FC<Props> = ({
  categories,
}) => {
  const dispatch = useDispatch();

  const categoryTitle = (category: FrameworkRequirementCategoryExtended) => {
    const completed = category.frameworkRequirements.every(
      r => r.status == FrameworkRequirementExtendedStatusEnum.Completed
    );
    const requirementsCount = category.frameworkRequirements.length;
    const requirementsCompleted = category.frameworkRequirements.filter(
      r => r.status == FrameworkRequirementExtendedStatusEnum.Completed
    ).length;
    return (
      <div className={`${baseCss}--categoryHeader`}>
        <FontAwesomeIcon
          icon={completed ? faCheckCircle : faCircle}
          className={
            completed ? baseCss + '--greenTick' : baseCss + '--greyTick'
          }
        />
        <div className={`${baseCss}--title`}>{category.name}</div>
        <div className={`${baseCss}--categoryCompleted`}>
          {requirementsCompleted}/{requirementsCount} Completed
        </div>
      </div>
    );
  };

  const requirementTitle = (requirement: FrameworkRequirementExtended) => {
    const completed =
      requirement.status == FrameworkRequirementExtendedStatusEnum.Completed;
    return (
      <div className={`${baseCss}--header`}>
        <FontAwesomeIcon
          icon={completed ? faCheckCircle : faCircle}
          className={
            completed ? baseCss + '--greenTick' : baseCss + '--greyTick'
          }
        />
        <span className={`${baseCss}--title`}>{requirement.name}</span>
      </div>
    );
  };

  const [openedGuidance, setOpenedGuidance] = useState('');

  const completeHandler =
    (frameworkRequirementId: FrameworkRequirementModel['id']) => () =>
      dispatch(createVendorRequirement(frameworkRequirementId));

  const expandHandler = (
    identifier: FrameworkRequirementModel['identifier']
  ) => {
    dispatch(fetchFrameworkGuidance(identifier));
  };

  const showNextStep = (requirement: FrameworkRequirementModel) =>
    requirement.guidanceIdentifier &&
    requirement.guidanceIdentifier != openedGuidance;
  const hideNextStep = (requirement: FrameworkRequirementModel) =>
    requirement.guidanceIdentifier &&
    requirement.guidanceIdentifier == openedGuidance;

  return (
    <Accordion className={baseCss}>
      {categories.map(category => (
        <AdoptechAccordionCard
          key={category.id}
          index={category.identifier}
          title={categoryTitle(category)}
          cardBodyClass={`${baseCss}--category-body`}
        >
          <Accordion>
            {category.frameworkRequirements.map(requirement => (
              <AdoptechAccordionCard
                key={requirement.id}
                index={requirement.identifier}
                title={requirementTitle(requirement)}
              >
                <div
                  className={`${baseCss}--description`}
                  dangerouslySetInnerHTML={{
                    __html: requirement.descriptionAsHtml,
                  }}
                />
                <div className={`${baseCss}--actions`}>
                  {requirement.type ==
                    FrameworkRequirementModelTypeEnum.Manual &&
                    (requirement.status ==
                    FrameworkRequirementExtendedStatusEnum.Pending ? (
                      <AdoptechButton
                        variant={AdoptechButtonVariant.White}
                        extraClass={`${baseCss}--manual`}
                        onClick={completeHandler(requirement.id)}
                      >
                        Mark as completed
                      </AdoptechButton>
                    ) : (
                      <AdoptechButton
                        disabled
                        variant={AdoptechButtonVariant.SuccessTransparent}
                        extraClass={`${baseCss}--manual`}
                      >
                        Completed
                      </AdoptechButton>
                    ))}
                  {requirement.type ==
                    FrameworkRequirementModelTypeEnum.SendMessage &&
                    (requirement.status ==
                    FrameworkRequirementExtendedStatusEnum.Pending ? (
                      <AdoptechButton
                        variant={AdoptechButtonVariant.White}
                        extraClass={`${baseCss}--send-message`}
                        onClick={completeHandler(requirement.id)}
                      >
                        Request meeting
                      </AdoptechButton>
                    ) : (
                      <AdoptechButton
                        disabled
                        variant={AdoptechButtonVariant.SuccessTransparent}
                        extraClass={`${baseCss}--send-message`}
                      >
                        Meeting requested
                      </AdoptechButton>
                    ))}
                  {requirement.guidanceIdentifier &&
                    (requirement.guidanceIdentifier != openedGuidance ? (
                      <div>
                        <AdoptechButton
                          variant={AdoptechButtonVariant.White}
                          extraClass={`${baseCss}--send-message`}
                          onClick={() =>
                            setOpenedGuidance(requirement.guidanceIdentifier)
                          }
                        >
                          Show Guidance
                        </AdoptechButton>
                      </div>
                    ) : (
                      <div>
                        <AdoptechButton
                          variant={AdoptechButtonVariant.White}
                          extraClass={`${baseCss}--send-message`}
                          onClick={() => setOpenedGuidance(undefined)}
                        >
                          Hide Guidance
                        </AdoptechButton>
                      </div>
                    ))}
                </div>
                {openedGuidance &&
                  requirement.guidanceIdentifier == openedGuidance && (
                    <FrameworkGuidance
                      guidanceIdentifier={requirement.guidanceIdentifier}
                    />
                  )}
              </AdoptechAccordionCard>
            ))}
          </Accordion>
        </AdoptechAccordionCard>
      ))}
    </Accordion>
  );
};
