import React from 'react';
import { Modal } from 'react-bootstrap';
import './ExcludeResultModal.scss';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../../../../components/AdoptechButton/AdoptechButton';
import AdoptechModal from '../../../../../../../../components/AdoptechModal/AdoptechModal';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../../../../types/applicationState';

const baseCss = 'excludeResultModal';

export const ExcludeResultModal: React.FC<{
  show: boolean;
  onCancel: (isConfirmed: boolean, reasonForExclusion?: string) => void;
}> = props => {
  const description = 'Why are you excluding this test result?';
  const title = 'Reason for Exclusion';
  const [reasonForExclusion, setReasonForExclusion] = React.useState('');
  const {
    patchAssertionResultExcludeStatus,
    fetchAssertionDetailsSilentStatus,
  } = useSelector((state: ApplicationState) => state.compliance);
  const isExcluding =
    patchAssertionResultExcludeStatus === 'loading' ||
    fetchAssertionDetailsSilentStatus === 'loading';
  return (
    <AdoptechModal
      className={baseCss}
      onHide={() => props.onCancel(false)}
      show={props.show}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <div className={baseCss + '--body'}>
          <div className={baseCss + '--description'}>{description}</div>
          <textarea
            className={baseCss + '--subject'}
            value={reasonForExclusion}
            onChange={e => setReasonForExclusion(e.target.value)}
            placeholder="For Example, this is a test server and should not be included in the results"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          disabled={isExcluding}
          onClick={() => props.onCancel(false)}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          onClick={() => {
            props.onCancel(true, reasonForExclusion);
          }}
          busy={isExcluding}
          variant={AdoptechButtonVariant.Warning}
          disabled={reasonForExclusion.length === 0}
        >
          Exclude
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
