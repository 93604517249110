import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactElement, useRef, useState } from 'react';
import './AdoptechTextInput.scss';
import { useClickAway } from 'react-use';

export interface AdoptechTextSuggestionProps {
  id: string;
  autoFocus?: boolean;
  label?: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onIconClicked?: (event: React.MouseEvent<HTMLDivElement>) => void;
  hasError?: boolean;
  placeholder?: string;
  value: string;
  rows?: number;
  maxLength?: number;
  type: 'text' | 'email';
  additionalClass?: string;
  errorClass?: string;
  icon?: IconDefinition;
  onlyInput?: boolean;
  rounded?: boolean;
  bottomElement?: React.ReactElement; // for custom error badges at bottom
  autoCapitalize?: string;
  suggestionsSelector?: (value: string) => string[];
  suggestionElement?: (value: string) => ReactElement;
  suggestionSet?: (value: string) => void;
}

const AdoptechTextSuggestion = React.forwardRef<
  HTMLInputElement,
  AdoptechTextSuggestionProps
>((props, ref) => {
  const mainClasses = classNames({
    adoptechTextInput: true,
    'adoptechTextInput-error': props.hasError && !props.errorClass,
    [`${props.errorClass}`]: props.hasError,
  });

  const [suggestionShow, setSuggestionShow] = useState(false);

  const suggestions = props.suggestionsSelector
    ? props.suggestionsSelector(props.value)
    : [];

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (suggestions.length > 0) setSuggestionShow(true);
    props.onChange(event);
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (suggestions.length > 0) setSuggestionShow(true);
    if (props.onFocus) props.onFocus(event);
  };

  const wrapperRef = useRef(null);
  useClickAway(wrapperRef, () => {
    setSuggestionShow(false);
  });

  const input = (
    <input
      id={props.id}
      autoFocus={props.autoFocus}
      autoComplete="off"
      className={mainClasses}
      disabled={props.disabled}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={props.placeholder}
      ref={ref}
      type={props.type}
      value={props.value}
      maxLength={props.maxLength}
      autoCapitalize={props.autoCapitalize}
    />
  );

  if (props.onlyInput) return input;

  const suggestionElement =
    props.suggestionElement || (value => <span>{value}</span>);

  const suggestionClick = (suggestion: string) => {
    setSuggestionShow(false);

    if (props.suggestionSet) props.suggestionSet(suggestion);
  };
  return (
    <div ref={wrapperRef}>
      <div
        className={`adoptechTextInput adoptechTextInputContainer${
          props.icon ? '-withIcon' : ''
        } ${props?.additionalClass}`}
      >
        {props.label && <label htmlFor={props.id}>{props.label}</label>}
        {props.hasError && props.label && (
          <span className="adoptechTextInput-error">
            {`Please fill in ${props.label.toLowerCase()}`}
          </span>
        )}
        {input}
        {props.icon && (
          <div
            className="adoptechTextInput-iconContainer"
            onClick={e => props.onIconClicked && props.onIconClicked(e)}
          >
            <FontAwesomeIcon className="input--icon" icon={props.icon} />
          </div>
        )}
      </div>
      {props.bottomElement}
      {suggestionShow && suggestions.length > 0 && (
        <div className="adoptechTextInput-suggestions">
          {suggestions.map(suggestion => (
            <div
              key={suggestion}
              className="adoptechTextInput-suggestion"
              onClick={e => {
                e.preventDefault();

                suggestionClick(suggestion);
              }}
            >
              {suggestionElement(suggestion)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

AdoptechTextSuggestion.displayName = 'AdoptechTextInput';
export { AdoptechTextSuggestion };
