/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CertificateModel
 */
export interface CertificateModel {
  /**
   *
   * @type {string}
   * @memberof CertificateModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CertificateModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CertificateModel
   */
  certificateType: CertificateModelCertificateTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CertificateModel
   */
  createdAt?: string;
  /**
   *
   * @type {Date}
   * @memberof CertificateModel
   */
  expireAt: Date | null;
  /**
   *
   * @type {string}
   * @memberof CertificateModel
   */
  updatedAt?: string;
}

export function CertificateModelFromJSON(json: any): CertificateModel {
  return CertificateModelFromJSONTyped(json, false);
}

export function CertificateModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CertificateModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    certificateType: json['certificate_type'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    expireAt: json['expire_at'] === null ? null : new Date(json['expire_at']),
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function CertificateModelToJSON(value?: CertificateModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    certificate_type: value.certificateType,
    created_at: value.createdAt,
    expire_at:
      value.expireAt === null
        ? null
        : value.expireAt.toISOString().substr(0, 10),
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CertificateModelCertificateTypeEnum {
  Iso27001Certificate = 'iso_27001_certificate',
  Soc2TypeIiReport = 'soc_2_type_ii_report',
  CyberEssentialsCertificate = 'cyber_essentials_certificate',
  CyberEssentialsPlusCertificate = 'cyber_essentials_plus_certificate',
  IcoRegistrationCertificate = 'ico_registration_certificate',
  Iso20252 = 'iso_20252',
  Iso9001 = 'iso_9001',
}
