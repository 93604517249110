import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideFileUploadedToast } from '../../store/vendors/vendorsSlice';
import { ApplicationState } from '../../types/applicationState';
import { MessageToast } from '../MessageToast/MessageToast';

const FileUploadedToast: React.FC = () => {
  const dispatch = useDispatch();

  const isFileUploadedToastShowing = useSelector(
    (state: ApplicationState) =>
      state.vendors.documentState.isFileUploadedToastShowing
  );

  return (
    <MessageToast
      autohide
      delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
      onClose={() => dispatch(hideFileUploadedToast())}
      show={isFileUploadedToastShowing}
    >
      Your file has been successfully uploaded.
    </MessageToast>
  );
};

export default FileUploadedToast;
