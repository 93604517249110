import { useSelector } from 'react-redux';
import { selectAgreementsProgress } from '../../../../selectors/selectAgreementsProgress';
import { selectAuditsProgress } from '../../../../selectors/selectAuditsProgress';
import { SurveyClause } from '../../../../swagger';
import { ApplicationState } from '../../../../types/applicationState';

export const useClauseProgress = (mode: 'agreements' | 'audits') => {
  const isAgreement = mode === 'agreements';
  const currentModel = useSelector((state: ApplicationState) =>
    isAgreement ? state.agreements.currentAgreement : state.audits.currentAudit
  );

  const getFirstClauseWithQuestions = () =>
    currentModel.survey.surveyClauses
      .filter(surveyClause => surveyClause.questions.length > 0)
      ?.findIndex(
        // TODO: reimplement using 'completed' instead of 'progress'
        clause => clauseProgress(clause) !== 1
      )
      .toString();

  const { stats } = useSelector(
    isAgreement ? selectAgreementsProgress : selectAuditsProgress
  );
  const clauseProgress = ({ id }: SurveyClause) => {
    return stats.find(statsPerClause => statsPerClause.id === id).percents;
  };

  const interactiveSurveyClauses = currentModel.survey.surveyClauses.filter(
    clause => clause.questions.length > 0
  );

  return {
    interactiveSurveyClauses,
    currentModel,
    getFirstClauseWithQuestions,
    clauseProgress,
  };
};
