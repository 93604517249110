import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import {
  AdoptechDatePicker,
  AdoptechDatePickerProps,
} from '../AdoptechDatePicker/AdoptechDatePicker';
import './AdoptechDatePicker2.scss';

export const AdoptechDatePicker2: React.FC<AdoptechDatePickerProps> = props => {
  const baseCss = 'adoptech-datepicker2';
  const customProps = { ...props, hasError: false };
  return (
    <div
      className={classNames(baseCss, {
        disabled: !!props.disabled,
        'adoptech-error': props.hasError,
        [baseCss + '--rounded']: props.rounded,
      })}
    >
      <AdoptechDatePicker
        {...customProps}
        endNode={
          <FontAwesomeIcon
            className={baseCss + '--datepicker-icon'}
            icon={faCalendar}
          />
        }
      />
    </div>
  );
};
