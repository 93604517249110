/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RegisteredCompany
 */
export interface RegisteredCompany {
  /**
   *
   * @type {string}
   * @memberof RegisteredCompany
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredCompany
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredCompany
   */
  identifier?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredCompany
   */
  dateOfIncorporation?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredCompany
   */
  registeredAddress?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredCompany
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredCompany
   */
  identifierType?: string;
}

export function RegisteredCompanyFromJSON(json: any): RegisteredCompany {
  return RegisteredCompanyFromJSONTyped(json, false);
}

export function RegisteredCompanyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegisteredCompany {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    identifier: !exists(json, 'identifier') ? undefined : json['identifier'],
    dateOfIncorporation: !exists(json, 'date_of_incorporation')
      ? undefined
      : json['date_of_incorporation'],
    registeredAddress: !exists(json, 'registered_address')
      ? undefined
      : json['registered_address'],
    country: !exists(json, 'country') ? undefined : json['country'],
    identifierType: !exists(json, 'identifier_type')
      ? undefined
      : json['identifier_type'],
  };
}

export function RegisteredCompanyToJSON(value?: RegisteredCompany | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    identifier: value.identifier,
    date_of_incorporation: value.dateOfIncorporation,
    registered_address: value.registeredAddress,
    country: value.country,
    identifier_type: value.identifierType,
  };
}
