/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorDocumentAttestationsGroupUpdatePayload
 */
export interface VendorDocumentAttestationsGroupUpdatePayload {
  /**
   *
   * @type {Array<string>}
   * @memberof VendorDocumentAttestationsGroupUpdatePayload
   */
  groupIds?: Array<string>;
}

export function VendorDocumentAttestationsGroupUpdatePayloadFromJSON(
  json: any
): VendorDocumentAttestationsGroupUpdatePayload {
  return VendorDocumentAttestationsGroupUpdatePayloadFromJSONTyped(json, false);
}

export function VendorDocumentAttestationsGroupUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorDocumentAttestationsGroupUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    groupIds: !exists(json, 'group_ids') ? undefined : json['group_ids'],
  };
}

export function VendorDocumentAttestationsGroupUpdatePayloadToJSON(
  value?: VendorDocumentAttestationsGroupUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    group_ids: value.groupIds,
  };
}
