/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubInvitationUpdatePayloadTrusthubUser,
  TrusthubInvitationUpdatePayloadTrusthubUserFromJSON,
  TrusthubInvitationUpdatePayloadTrusthubUserFromJSONTyped,
  TrusthubInvitationUpdatePayloadTrusthubUserToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubInvitationUpdatePayload
 */
export interface TrusthubInvitationUpdatePayload {
  /**
   *
   * @type {TrusthubInvitationUpdatePayloadTrusthubUser}
   * @memberof TrusthubInvitationUpdatePayload
   */
  trusthubUser: TrusthubInvitationUpdatePayloadTrusthubUser;
}

export function TrusthubInvitationUpdatePayloadFromJSON(
  json: any
): TrusthubInvitationUpdatePayload {
  return TrusthubInvitationUpdatePayloadFromJSONTyped(json, false);
}

export function TrusthubInvitationUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubInvitationUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    trusthubUser: TrusthubInvitationUpdatePayloadTrusthubUserFromJSON(
      json['trusthub_user']
    ),
  };
}

export function TrusthubInvitationUpdatePayloadToJSON(
  value?: TrusthubInvitationUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    trusthub_user: TrusthubInvitationUpdatePayloadTrusthubUserToJSON(
      value.trusthubUser
    ),
  };
}
