import './CompanyBrandingTable.scss';

import { faCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';
import { HexColorPicker } from 'react-colorful';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideLogoUploadSuccessToast,
  showUploadLogoModal,
} from '../../../store/vendors/vendorsSlice';
import {
  fetchCompanyLogo,
  patchCompanyProfile,
} from '../../../store/vendors/vendorsThunks';
import { AccessObject } from '../../../types/accessObject';
import { ApplicationState } from '../../../types/applicationState';
import { MessageToast } from '../../../components/MessageToast/MessageToast';
import { UploadLogoModal } from '../../../components/UploadLogoModal/UploadLogoModal';
import { useCanFeature } from '../../../functions/access';
import { IconButton } from '../../../components/IconButton/IconButton';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import {
  ColourPalette,
  defaultColoursList,
} from '../../../components/ColourPalette/ColourPalette';
import { CompanyExtended } from '../../../swagger';
import { showGlobalToast } from '../../../store/global/globalSlice';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { Panel } from '../../../components/Panel/Panel';
import { useAdoptechDebounce } from '../../../hooks/useAdoptechDebounce';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';

export interface CompanyBrandingTableProps {
  vendorId: string;
  company: CompanyExtended;
}

export const isDisplayableColour = (newColour: string) => {
  if (defaultColoursList.find(c => c === newColour)) return true;
  const regx = /^#([0-9a-f]{3}|[0-9a-f]{4}|[0-9a-f]{6})$/i;
  const result = regx.test(newColour);
  return result;
};

export const CompanyBrandingTable: React.FC<CompanyBrandingTableProps> = ({
  vendorId,
  company,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanyLogo({ vendorId }));
  }, [vendorId]);

  const logo = useSelector(
    (state: ApplicationState) => state.vendors.companyDetailsState.logo
  );
  const isLogoUploadSuccessToastShowing = useSelector(
    (state: ApplicationState) =>
      state.vendors.companyDetailsState.isLogoUploadSuccessToastShowing
  );

  const currentVendor = useSelector(selectCurrentVendor);
  const canEdit = useCanFeature(AccessObject.company_manage);
  const baseCss = 'companyBrandingTable';
  const [colour, setColour] = useState<string>(company.brandingColor || '');
  // TODO use separate thunk for color change instead of patchCompanyProfile
  const [colorChanging, setColorChanging] = useState(false);
  const debounce = useAdoptechDebounce();
  useEffect(() => {
    setColour(company.brandingColor || '');
  }, [currentVendor]);

  const checkValidColour = (newColour: string) => {
    if (!newColour) return true;

    return isDisplayableColour(newColour);
  };

  const saveCompanyDetails = (updatedCompany: CompanyExtended) => {
    const text =
      updatedCompany.brandingColor !== company.brandingColor
        ? 'Your company colours have been ' +
          `${updatedCompany.brandingColor ? 'applied' : 'cleared'}` +
          '.'
        : 'Your company settings has been updated';
    setColorChanging(true);
    dispatch(
      patchCompanyProfile({
        company: updatedCompany,
        onSuccess: () => {
          setColorChanging(false);
          dispatch(showGlobalToast(text));
        },
      })
    );
  };
  const handleColourChange = (selectedColour: string) => {
    setColour(selectedColour);
    if (checkValidColour(selectedColour)) {
      debounce(() => {
        saveCompanyDetails({
          ...company,
          brandingColor: selectedColour,
        });
      }, 1500);
    }
  };
  const contentsCss = `${baseCss}--contents`;

  return (
    <div className={baseCss}>
      <Panel>
        <div className={baseCss + '--header'}>
          <div className={baseCss + '--title'}>Company branding</div>
        </div>
        <div className={contentsCss}>
          <div className={`${contentsCss}--row`}>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>
                Company logo
                <div className={baseCss + '--actions'}>
                  {canEdit && (
                    <IconButton
                      ariaLabel="Upload company logo"
                      className={baseCss + '--icon'}
                      icon={faCloudUpload}
                      onClick={() => dispatch(showUploadLogoModal())}
                      tooltip="Upload"
                    />
                  )}
                </div>
              </div>
              <div
                className={`${contentsCss}--data ${contentsCss}--data-media-field`}
              >
                {logo?.filename ? (
                  <img src={logo.urls.thumb} />
                ) : (
                  'no logo uploaded'
                )}
              </div>
            </div>
          </div>
          <div className={`${contentsCss}--row`}>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>Company colour</div>
              <div
                className={`${contentsCss}--data ${contentsCss}--data-media-field`}
              >
                {canEdit ? (
                  <div className={contentsCss + '--colourEditor'}>
                    <HexColorPicker
                      color={colour}
                      onChange={handleColourChange}
                    />
                    <div className={contentsCss + '--colourEditor-column'}>
                      <ColourPalette
                        onSelect={(selectedColour: string) => {
                          handleColourChange(selectedColour);
                        }}
                        gap="10px"
                      />
                      <div style={{ display: 'flex' }}>
                        <AdoptechTextInput
                          errorClass={baseCss + '--error'}
                          hasError={!checkValidColour(colour)}
                          id="colourInput"
                          onChange={e => {
                            handleColourChange(e.target.value);
                          }}
                          value={colour}
                          type="text"
                          placeholder="#FFaa33"
                          additionalClass={baseCss + '--colourInput'}
                        />
                        {colorChanging && <LoadingSpinner inlineSmall />}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {colour ? (
                      <div
                        className={contentsCss + '--colourSample'}
                        style={{ backgroundColor: colour }}
                      ></div>
                    ) : (
                      'No color selected  '
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <UploadLogoModal vendorId={vendorId} />
        <MessageToast
          autohide
          delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
          onClose={() => dispatch(hideLogoUploadSuccessToast())}
          show={isLogoUploadSuccessToastShowing}
        >
          Your logo has been successfully uploaded.
        </MessageToast>
      </Panel>
    </div>
  );
};
