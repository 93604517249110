import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './InformationSecuritySection.scss';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';
import { TrustHubSectionProps } from '../CompanyDetailsSection/CompanyDetailsSection';
import { InformationSecurityDocumentsSection } from './InformationSecurityDocumentsSection/InformationSecurityDocumentsSection';

export const InformationSecuritySection: React.FC<
  TrustHubSectionProps
> = props => {
  const baseCss = 'informationSecuritySection';

  const policiesProps = { ...props, ...{ identifier: 'policies' } };
  const reportsProps = { ...props, ...{ identifier: 'reports' } };

  return (
    <div id={baseCss} className={baseCss}>
      <div className={baseCss + '--panel'}>
        <div className={baseCss + '--title'}>
          <div className={baseCss + '--titleInfo'}>
            <div>Information Security</div>
          </div>
        </div>
        <div className={baseCss + '--2-columns'}>
          <InformationSecurityDocumentsSection {...policiesProps} />
          <InformationSecurityDocumentsSection {...reportsProps} />
        </div>
      </div>
    </div>
  );
};
