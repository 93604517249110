import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { ApplicationState } from '../../types/applicationState';
import { Assessment } from '../Assessment/Assessment';
import { AssessmentsIn } from '../AssessmentsIn/AssessmentsIn';
import { AssessmentsOut } from '../AssessmentsOut/AssessmentsOut';
import { AssessmentsSummary } from '../AssessmentsSummary/AssessmentsSummary';
import { AssessmentSummary } from '../AssessmentSummary/AssessmentSummary';
import { push } from 'connected-react-router';
import {
  fetchAssessments,
  fetchAssessmentTemplates,
} from '../../store/assessments/assessmentsThunks';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { AssessmentTemplateSummary } from '../AssessmentTemplateSummary/AssessmentTemplateSummary';
import { welcomeRoute } from '../Routes/Routes';

export const AssessmentsPage: React.FC = () => {
  const { isInitialised } = useSelector(
    (state: ApplicationState) => state.global
  );

  const { vendors } = useSelector((state: ApplicationState) => state.vendors);

  const currentVendor = useSelector(selectCurrentVendor);

  const dispatch = useDispatch();

  // After a vendor has been set up, if we find an assessment response that matches the search, we should redirect to it.
  useEffect(() => {
    if (!isInitialised) return;

    // For "assessment response invite link" vendor is required
    if (!currentVendor) {
      dispatch(push(welcomeRoute));
      return;
    }

    dispatch(fetchAssessments(currentVendor.id));
    dispatch(fetchAssessmentTemplates(currentVendor?.id));
  }, [isInitialised, currentVendor]);

  if (!isInitialised) return null;
  if (!vendors || vendors.length === 0) return null;

  return (
    <div>
      <Switch>
        <Route
          path="/r/riskManagement/assessments/summary/:id"
          component={AssessmentSummary}
        />
        <Route
          path="/r/riskManagement/assessments/templates/:id"
          component={AssessmentTemplateSummary}
        />
        <Route
          path="/r/riskManagement/assessments/summary"
          component={AssessmentsSummary}
        />
        <Route
          path="/r/riskManagement/assessments/response/:id"
          component={Assessment}
        />
        <Route
          path="/r/riskManagement/assessments/incoming"
          component={AssessmentsIn}
        />
        <Route
          path="/r/riskManagement/assessments/outgoing"
          component={AssessmentsOut}
        />
        <Redirect to="/r/riskManagement/assessments/summary" />
      </Switch>
    </div>
  );
};
