/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssertionFrequency,
  AssertionFrequencyFromJSON,
  AssertionFrequencyFromJSONTyped,
  AssertionFrequencyToJSON,
} from './';

/**
 *
 * @export
 * @interface AssertionCreatePayloadAssertion
 */
export interface AssertionCreatePayloadAssertion {
  /**
   *
   * @type {string}
   * @memberof AssertionCreatePayloadAssertion
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AssertionCreatePayloadAssertion
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AssertionCreatePayloadAssertion
   */
  ownerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssertionCreatePayloadAssertion
   */
  assertionCategoryId: string;
  /**
   *
   * @type {string}
   * @memberof AssertionCreatePayloadAssertion
   */
  taskId: string;
  /**
   *
   * @type {AssertionFrequency}
   * @memberof AssertionCreatePayloadAssertion
   */
  frequency: AssertionFrequency;
}

export function AssertionCreatePayloadAssertionFromJSON(
  json: any
): AssertionCreatePayloadAssertion {
  return AssertionCreatePayloadAssertionFromJSONTyped(json, false);
}

export function AssertionCreatePayloadAssertionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionCreatePayloadAssertion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    description: json['description'],
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    assertionCategoryId: json['assertion_category_id'],
    taskId: json['task_id'],
    frequency: AssertionFrequencyFromJSON(json['frequency']),
  };
}

export function AssertionCreatePayloadAssertionToJSON(
  value?: AssertionCreatePayloadAssertion | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    owner_id: value.ownerId,
    assertion_category_id: value.assertionCategoryId,
    task_id: value.taskId,
    frequency: AssertionFrequencyToJSON(value.frequency),
  };
}
