import { getRequest } from './getRequest';

// Non-binary request: Any BE error => retry
// Binary request: Any BE error or PDF is empty => retry
interface FetchRequestWithRetryProps {
  url: string;
  retries: number;
  isBinary: boolean;
}

type PromiseLikeObject = (value: void | PromiseLike<void>) => void;
interface FetchRequestProps extends FetchRequestWithRetryProps {
  resolve: PromiseLikeObject;
  reject: PromiseLikeObject;
}

const fetchRequest = async ({
  url,
  retries,
  isBinary,
  resolve,
  reject,
}: FetchRequestProps): Promise<number> => {
  const response = await getRequest(url);

  let isValid;
  let responseData;

  try {
    if (isBinary) {
      responseData = await response.blob();
      isValid = response.ok && responseData.size > 1;
    } else {
      responseData = await response.json();
      isValid = !!responseData && response.ok;
    }
  } catch (error) {
    isValid = false;
  }
  if (!isValid) {
    if (retries > 0) {
      console.log(`Cannot load ${url} Retry attempt left ${retries}`);
      return fetchRequest({
        url,
        isBinary,
        resolve,
        reject,
        retries: retries - 1,
      });
    }
    reject();
    return;
  }

  resolve(responseData);
};
export const getRequestWithRetry = (
  options: FetchRequestWithRetryProps
): Promise<void | Blob> => {
  return new Promise((resolve, reject) => {
    fetchRequest({ ...options, resolve, reject });
  });
};
