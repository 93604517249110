/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DashboardSummaryData
 */
export interface DashboardSummaryData {
  /**
   *
   * @type {string}
   * @memberof DashboardSummaryData
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof DashboardSummaryData
   */
  value?: string;
}

export function DashboardSummaryDataFromJSON(json: any): DashboardSummaryData {
  return DashboardSummaryDataFromJSONTyped(json, false);
}

export function DashboardSummaryDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DashboardSummaryData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    label: !exists(json, 'label') ? undefined : json['label'],
    value: !exists(json, 'value') ? undefined : json['value'],
  };
}

export function DashboardSummaryDataToJSON(
  value?: DashboardSummaryData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    label: value.label,
    value: value.value,
  };
}
