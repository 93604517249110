/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ChecklistCreatePayloadChecklist,
  ChecklistCreatePayloadChecklistFromJSON,
  ChecklistCreatePayloadChecklistFromJSONTyped,
  ChecklistCreatePayloadChecklistToJSON,
  ChecklistCreatePayloadChecklistUsers,
  ChecklistCreatePayloadChecklistUsersFromJSON,
  ChecklistCreatePayloadChecklistUsersFromJSONTyped,
  ChecklistCreatePayloadChecklistUsersToJSON,
  ChecklistCreatePayloadQuestions,
  ChecklistCreatePayloadQuestionsFromJSON,
  ChecklistCreatePayloadQuestionsFromJSONTyped,
  ChecklistCreatePayloadQuestionsToJSON,
} from './';

/**
 *
 * @export
 * @interface ChecklistCreatePayload
 */
export interface ChecklistCreatePayload {
  /**
   *
   * @type {ChecklistCreatePayloadChecklist}
   * @memberof ChecklistCreatePayload
   */
  checklist?: ChecklistCreatePayloadChecklist;
  /**
   *
   * @type {Array<ChecklistCreatePayloadQuestions>}
   * @memberof ChecklistCreatePayload
   */
  questions?: Array<ChecklistCreatePayloadQuestions>;
  /**
   *
   * @type {Array<ChecklistCreatePayloadChecklistUsers>}
   * @memberof ChecklistCreatePayload
   */
  checklistUsers?: Array<ChecklistCreatePayloadChecklistUsers>;
  /**
   *
   * @type {string}
   * @memberof ChecklistCreatePayload
   */
  message?: string;
}

export function ChecklistCreatePayloadFromJSON(
  json: any
): ChecklistCreatePayload {
  return ChecklistCreatePayloadFromJSONTyped(json, false);
}

export function ChecklistCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    checklist: !exists(json, 'checklist')
      ? undefined
      : ChecklistCreatePayloadChecklistFromJSON(json['checklist']),
    questions: !exists(json, 'questions')
      ? undefined
      : (json['questions'] as Array<any>).map(
          ChecklistCreatePayloadQuestionsFromJSON
        ),
    checklistUsers: !exists(json, 'checklist_users')
      ? undefined
      : (json['checklist_users'] as Array<any>).map(
          ChecklistCreatePayloadChecklistUsersFromJSON
        ),
    message: !exists(json, 'message') ? undefined : json['message'],
  };
}

export function ChecklistCreatePayloadToJSON(
  value?: ChecklistCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    checklist: ChecklistCreatePayloadChecklistToJSON(value.checklist),
    questions:
      value.questions === undefined
        ? undefined
        : (value.questions as Array<any>).map(
            ChecklistCreatePayloadQuestionsToJSON
          ),
    checklist_users:
      value.checklistUsers === undefined
        ? undefined
        : (value.checklistUsers as Array<any>).map(
            ChecklistCreatePayloadChecklistUsersToJSON
          ),
    message: value.message,
  };
}
