import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isValidEmailAddress } from '../../../../functions/isValidEmailAddress';
import { selectAgreementsEditForm } from '../../../../selectors/selectAgreementsEditForm';
import { selectCurrentAgreement } from '../../../../selectors/selectCurrentAgreement';
import { updateAgreementsEditFormField } from '../../../../store/agreements/agreementsSlice';
import { fetchAgreementSignatoriesById } from '../../../../store/agreements/agreementsThunks';
import {
  AgreementCreatePayloadAgreementSignatories,
  AgreementModel,
  AgreementModelStatusEnum,
  AgreementSignatoryModelTypeEnum,
} from '../../../../swagger';
import { ApplicationState } from '../../../../types/applicationState';
import {
  ExternalSignatoryForm,
  SignatoryTypeMode,
} from '../../../ExternalSignatoryForm/ExternalSignatoryForm';
import { LoadingSpinner } from '../../../LoadingSpinner/LoadingSpinner';
import { SignatoriesStepIsValid } from '../../AddNewAgreementForm/SignatoriesStep/SignatoriesStep';
import {
  AgreementEditFormStep,
  AgreementsEditForm,
} from '../EditAgreementForm';

export const SignatoryStepIsValid = (
  agreement: AgreementModel,
  form: AgreementsEditForm
) => {
  const readOnly = agreement.status !== AgreementModelStatusEnum.Draft;
  return (
    readOnly ||
    form.step !== AgreementEditFormStep.SignatoriesEdit ||
    SignatoriesStepIsValid(form)
  );
};

export const SignatoriesEditForm: React.FC = () => {
  const dispatch = useDispatch();
  const form = useSelector(selectAgreementsEditForm);
  const agreement = useSelector(selectCurrentAgreement);

  const handleSubmit = () => {
    dispatch(fetchAgreementSignatoriesById(agreement.id));
    dispatch(
      updateAgreementsEditFormField({
        step: AgreementEditFormStep.Primary,
      })
    );
  };

  const readOnly = agreement.status !== AgreementModelStatusEnum.Draft;

  const signatories = useSelector(
    (state: ApplicationState) =>
      state.agreements.currentAgreementSignatories || []
  );

  useEffect(() => {
    if (agreement.id) {
      dispatch(fetchAgreementSignatoriesById(agreement.id));
    }
  }, [agreement.id]);

  const signatoryFromSignatoriesMeatballMenu = signatories.find(
    model => model.id === form.currentSignatoryId
  ); // if click on the specific external signatory. ( SignatoriesMeatballMenu.tsx )

  const signatoryFromAgreementMeatballMenu = signatories.find(
    model => model.type !== AgreementSignatoryModelTypeEnum.Internal
  ); // if click on agreement row ( AgreementMeatballMenu.tsx )

  const signatory =
    signatoryFromSignatoriesMeatballMenu || signatoryFromAgreementMeatballMenu;
  const isRequestingSignatories = useSelector(
    (state: ApplicationState) =>
      state.agreements.isRequestingCurrentAgreementSignatories
  );

  if (!signatory) return null;

  if (isRequestingSignatories) {
    return <LoadingSpinner />;
  }

  const handleChange = (
    changedSignatory: AgreementCreatePayloadAgreementSignatories
  ) => {
    const formSignatory = { ...changedSignatory };
    if (formSignatory.email && !isValidEmailAddress(formSignatory.email)) {
      formSignatory.email = '';
    }

    dispatch(
      updateAgreementsEditFormField({
        signatory: formSignatory,
      })
    );
  };

  const mode =
    agreement.agreementType === 'employee_agreement'
      ? SignatoryTypeMode.employee
      : null;

  return (
    <>
      <ExternalSignatoryForm
        agreementId={agreement.id}
        signatory={signatory}
        name={form.name}
        onSubmit={handleSubmit}
        onChange={handleChange}
        readonly={readOnly}
        mode={mode}
      />
    </>
  );
};
