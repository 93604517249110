/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TableColumn,
  TableColumnFromJSON,
  TableColumnFromJSONTyped,
  TableColumnToJSON,
  TableRow,
  TableRowFromJSON,
  TableRowFromJSONTyped,
  TableRowToJSON,
} from './';

/**
 *
 * @export
 * @interface Table
 */
export interface Table {
  /**
   *
   * @type {Array<TableColumn>}
   * @memberof Table
   */
  columns?: Array<TableColumn>;
  /**
   *
   * @type {Array<TableRow>}
   * @memberof Table
   */
  rows?: Array<TableRow>;
}

export function TableFromJSON(json: any): Table {
  return TableFromJSONTyped(json, false);
}

export function TableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Table {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    columns: !exists(json, 'columns')
      ? undefined
      : (json['columns'] as Array<any>).map(TableColumnFromJSON),
    rows: !exists(json, 'rows')
      ? undefined
      : (json['rows'] as Array<any>).map(TableRowFromJSON),
  };
}

export function TableToJSON(value?: Table | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    columns:
      value.columns === undefined
        ? undefined
        : (value.columns as Array<any>).map(TableColumnToJSON),
    rows:
      value.rows === undefined
        ? undefined
        : (value.rows as Array<any>).map(TableRowToJSON),
  };
}
