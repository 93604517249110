import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './EditActionModal.scss';
import { VendorActionModel, VendorEventEventType } from '../../swagger';
import { AdoptechTextInput2 } from '../AdoptechTextInput2/AdoptechTextInput2';
import { AdoptechTextArea2 } from '../AdoptechTextArea2/AdoptechTextArea2';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { AdoptechDatePicker2 } from '../AdoptechDatePicker2/AdoptechDatePicker2';
import { AdoptechReactSelect2 } from '../AdoptechReacSelect2/AdoptechReactSelect2';
import moment from 'moment';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { useOwnerOptions } from '../../hooks/useOwnerOptions';
import CalendarAttachments from '../../features/calendar/EditEventModal/CalendarAttachments/CalendarAttachments';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { fetchAction } from '../../features/calendar/store/calendarThunks';
import { ActionFormType } from '../../features/calendar/CalendarPage/CalendarPageModals';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';

interface Props {
  init?:
    | ActionFormType
    | { actionType: ActionFormType['actionType'] }
    | { id: VendorActionModel['id'] };
  close: () => void;
  confirm: ({
    name,
    owner,
    description,
    dueDate,
    completed,
    actionType,
  }: ActionFormType) => void;
  show: boolean;
  disabledFields?: string[];
  onDelete?: (id: VendorActionModel['id']) => void;
}

const baseCss = 'editActionModal';
export const EditActionModal: React.FC<Props> = ({
  init = {},
  confirm = () => {},
  onDelete = () => {},
  disabledFields = [],
  close,
  show,
}) => {
  const { users, ownerOptions: userOptions } = useOwnerOptions({
    includingGuest: true,
  });
  const [model, setModel] = useState(init as ActionFormType);

  const dispatch = useAppDispatch();
  const { createActionStatus, isUpdatingCalendarItem } = useSelector(
    (state: ApplicationState) => state.calendar
  );
  const fetchActionAsync = async (id: string) => {
    const action = await dispatch(fetchAction(id)).unwrap();

    setModel(action);
  };

  useEffect(() => {
    model.id && fetchActionAsync(model.id);
  }, [model.id]);

  const deadline = model.dueDate ? new Date(model.dueDate) : null;

  const dataMissing =
    !model.name || !model.owner || !model.dueDate || !model.actionType;

  const modalTitle = model.id ? 'Edit Action' : 'Create Action';

  const actionName = model.id ? 'Save' : 'Create';

  const actionTypes = [
    ['PESTEL', VendorEventEventType.PestelReview],
    ['Risk register', VendorEventEventType.RiskRegisterReview],
    ['Legal register', VendorEventEventType.LegalRegisterReview],
    ['Vendor register', VendorEventEventType.VendorRegisterReview],
    ['Security Meeting', VendorEventEventType.SecurityMeeting],
    ['Internal Audit', VendorEventEventType.InternalAudit],
    ['External Audit', VendorEventEventType.ExternalAudit],
    ['Other', VendorEventEventType.Other],
  ];

  if (
    (init as VendorActionModel).actionType ===
    VendorEventEventType.ControlCheckReview
  ) {
    actionTypes.push([
      'Control check',
      VendorEventEventType.ControlCheckReview,
    ]);
  }
  const typeOptions = actionTypes.map(item => {
    return {
      label: item[0],
      value: item[1],
    };
  });

  return (
    <AdoptechModal className={baseCss} onHide={close} show={show}>
      <Modal.Header>
        <div>{modalTitle}</div>
        {model.id && (
          <AdoptechButton
            extraClass={model.completed ? 'completedButton' : 'pendingButton'}
          >
            {model.completed ? 'Completed' : 'Pending'}
          </AdoptechButton>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className={baseCss + '--body'}>
          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--field'}>
              <AdoptechTextInput2
                id="createActionName"
                rounded
                label="Title"
                type="text"
                value={model.name}
                onChange={e =>
                  setModel({ ...model, name: e.currentTarget.value })
                }
                hasError={!model.name}
                icon={faPen}
              />
            </div>
          </div>
          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--field'}>
              <AdoptechTextArea2
                rounded
                id="createActionDescription"
                label="Describe this action"
                value={model.description}
                onChange={e =>
                  setModel({ ...model, description: e.currentTarget.value })
                }
                icon={faPen}
              />
            </div>
          </div>
          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                id={baseCss}
                options={typeOptions}
                rounded
                label="Action type"
                isDisabled={disabledFields.includes('actionType')}
                isClearable={false}
                hasError={!model.actionType}
                onChange={option =>
                  setModel({
                    ...model,
                    actionType: option.value as VendorEventEventType,
                  })
                }
                value={typeOptions.filter(
                  option => option.value === model.actionType
                )}
                placeholder="Please select"
              />
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                label="Owner"
                id="createActionOwner"
                rounded
                options={userOptions}
                hasError={!model.owner}
                onChange={e => {
                  setModel({
                    ...model,
                    owner: users.find(user => user.id === e.value),
                  });
                }}
                value={userOptions.find(
                  option => option.value == model.owner?.id
                )}
              />
            </div>
          </div>
          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--field'}>
              <AdoptechDatePicker2
                id="createActionDeadline"
                rounded
                labelText="Deadline"
                placeHolderText="Select a date"
                outputDate={deadline}
                hasError={!model.dueDate}
                minDate={null}
                onDateSelect={d => {
                  const endOfDay = moment(d).endOf('day');
                  const endOfDayToDateTime = endOfDay.toJSON();

                  setModel({
                    ...model,
                    dueDate: endOfDayToDateTime,
                  });
                }}
              />
            </div>
            <div className={baseCss + '--field'}></div>
          </div>
          <CalendarAttachments
            baseCss={baseCss}
            model={model}
            setModel={setModel}
            readonlyMode={false}
            type="action"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {model.id ? (
          <div>
            <AdoptechButton
              disabled={dataMissing}
              onClick={() => {
                const data = { ...model, completed: !model.completed };
                confirm(data);
              }}
            >
              {model.completed ? 'Mark as pending' : 'Mark as completed'}
            </AdoptechButton>

            <AdoptechButton
              extraClass={baseCss + '--deleteButton'}
              icon={faTrashAlt}
              onClick={() => onDelete(model.id)}
            >
              Delete
            </AdoptechButton>
          </div>
        ) : (
          <AdoptechButton
            onClick={() => {
              close();
            }}
          >
            Cancel
          </AdoptechButton>
        )}
        <AdoptechButton
          disabled={dataMissing}
          onClick={() => confirm(model)}
          busy={createActionStatus == 'loading' || isUpdatingCalendarItem}
          variant={AdoptechButtonVariant.Primary}
        >
          {actionName}
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
