/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TaskUpdatePayloadTask,
  TaskUpdatePayloadTaskFromJSON,
  TaskUpdatePayloadTaskFromJSONTyped,
  TaskUpdatePayloadTaskToJSON,
} from './';

/**
 *
 * @export
 * @interface TaskUpdatePayload
 */
export interface TaskUpdatePayload {
  /**
   *
   * @type {TaskUpdatePayloadTask}
   * @memberof TaskUpdatePayload
   */
  task?: TaskUpdatePayloadTask;
}

export function TaskUpdatePayloadFromJSON(json: any): TaskUpdatePayload {
  return TaskUpdatePayloadFromJSONTyped(json, false);
}

export function TaskUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TaskUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    task: !exists(json, 'task')
      ? undefined
      : TaskUpdatePayloadTaskFromJSON(json['task']),
  };
}

export function TaskUpdatePayloadToJSON(value?: TaskUpdatePayload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    task: TaskUpdatePayloadTaskToJSON(value.task),
  };
}
