import { ExistingPolicyWarningModalMode } from '../../components/ExistingPolicyWarningModal/ExistingPolicyWarningModal';
import {
  AgreementModel,
  Policy,
  PolicyCategory,
  VendorDocument,
  VendorDocumentApproval,
  VendorDocumentExtended,
  VendorPolicyDetails,
  VendorTeamModel,
} from '../../swagger';
import { ApiRequestStatus } from '../global/globalState';

export enum SortColumn {
  Name,
  Category,
  Progress,
  UpdatedAt,
  UpdatedBy,
  Version,
}

export enum SortDirection {
  Ascending,
  Descending,
}

export interface PoliciesState {
  attestationManagementVendorDocument?: VendorDocumentExtended;
  approvalManagementVendorDocumentId: string;
  approvalManagementVendorDocumentName: string;
  approvals: VendorDocumentApproval[];
  isPaymentSuccessfulShowing: boolean;
  availableExpandedCategories: string[];
  availableSortColumn?: SortColumn;
  availableSortDirection: SortDirection;
  completeExpandedCategories: string[];
  completePolicyError?: string;
  completeSortColumn?: SortColumn;
  completeSortDirection: SortDirection;
  createPolicyError?: string;
  deletePolicyError?: string;
  fetchVendorPolicyError?: string;
  initialisingCreationOfPolicyId?: string;
  inProgressSortColumn?: SortColumn;
  inProgressSortDirection: SortDirection;
  isDocumentLibraryModalShowing: boolean;
  isAttestationManagementModalShowing: boolean;
  isApprovalManagementModalShowing: boolean;
  isApprovalManagementModalCancelable: boolean;
  isCompletingPolicy: boolean;
  isCreatingPolicy: boolean;
  isDeletingPolicy: boolean;
  isFetchingPolicies: boolean;
  isFetchingVendorPolicies: boolean;
  isFetchingVendorPolicy: boolean;
  isPatchingVendorPolicy: boolean;
  isPatchingVendorPolicyClause: boolean;
  isPolicySummaryToastShowing: boolean;
  isFetchingPolicyCategories: boolean;
  policyCategories: PolicyCategory[];
  patchVendorPolicyError?: string;
  patchVendorPolicyClauseError?: string;
  policies: Policy[];
  policySummaryToastId: string;
  searchTerm?: string;
  searchText?: string;
  showAutoSaveToast: boolean;
  vendorPolicies: VendorPolicyDetails[];
  loadedDataOnLoad: boolean;
  postAttestationError: string;
  isPostingAttestation: boolean;
  patchApprovalError: string;
  isPostingApproval: boolean;
  stripeCreatedVendorPolicy: boolean;
  isSkippingAttestation: boolean;
  isSkippingApproval: boolean;
  skipApprovalError: string;
  skipAttestationError: string;
  policyRefreshRequired: boolean;
  isFetchingDocumentApprovals: boolean;
  isExistingPolicyWarningModalShowing: boolean;
  existingPolicyWarningModalMode: ExistingPolicyWarningModalMode;
  selectedPolicyId: string;
  isManageRejectionModalShowing: boolean;
  rejectedItemToManage: VendorDocument | AgreementModel;
  sendingReadReminder: string;
  readReminderError: string;
  policyToEdit: (VendorPolicyDetails | VendorDocumentExtended) & {
    initialTeamsFetched?: boolean;
    selectedTeams?: {
      id: VendorTeamModel['id'];
      name: VendorTeamModel['name'];
    }[];
  };
  isPolicyDrawerShowing: boolean;
  isPolicyDrawerShowingForReadRequest: boolean;
  fetchPoliciesStatus: ApiRequestStatus;
  fetchVendorPoliciesDetailsStatus: ApiRequestStatus;
  fetchVendorPoliciesStatus: ApiRequestStatus;
  fetchPolicyAttestationGroupsStatus: ApiRequestStatus;
  patchPolicyAttestationGroupsStatus: ApiRequestStatus;
}
