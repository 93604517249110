/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ImageUrls,
  ImageUrlsFromJSON,
  ImageUrlsFromJSONTyped,
  ImageUrlsToJSON,
} from './';

/**
 *
 * @export
 * @interface Image
 */
export interface Image {
  /**
   *
   * @type {string}
   * @memberof Image
   */
  filename: string | null;
  /**
   *
   * @type {ImageUrls}
   * @memberof Image
   */
  urls: ImageUrls;
}

export function ImageFromJSON(json: any): Image {
  return ImageFromJSONTyped(json, false);
}

export function ImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Image {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    filename: json['filename'],
    urls: ImageUrlsFromJSON(json['urls']),
  };
}

export function ImageToJSON(value?: Image | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    filename: value.filename,
    urls: ImageUrlsToJSON(value.urls),
  };
}
