/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PaymentPlan
 */
export interface PaymentPlan {
  /**
   *
   * @type {string}
   * @memberof PaymentPlan
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPlan
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentPlan
   */
  rank?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentPlan
   */
  internalName?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentPlan
   */
  usersLimit?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentPlan
   */
  monthlyPrice?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentPlan
   */
  annuallyPrice?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentPlan
   */
  stripeMonthlyPriceId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPlan
   */
  stripeAnnuallyPriceId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPlan
   */
  summary?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaymentPlan
   */
  subheader?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaymentPlan
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaymentPlan
   */
  descriptionAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaymentPlan
   */
  productId?: string;
}

export function PaymentPlanFromJSON(json: any): PaymentPlan {
  return PaymentPlanFromJSONTyped(json, false);
}

export function PaymentPlanFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentPlan {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    rank: !exists(json, 'rank') ? undefined : json['rank'],
    internalName: !exists(json, 'internal_name')
      ? undefined
      : json['internal_name'],
    usersLimit: !exists(json, 'users_limit') ? undefined : json['users_limit'],
    monthlyPrice: !exists(json, 'monthly_price')
      ? undefined
      : json['monthly_price'],
    annuallyPrice: !exists(json, 'annually_price')
      ? undefined
      : json['annually_price'],
    stripeMonthlyPriceId: !exists(json, 'stripe_monthly_price_id')
      ? undefined
      : json['stripe_monthly_price_id'],
    stripeAnnuallyPriceId: !exists(json, 'stripe_annually_price_id')
      ? undefined
      : json['stripe_annually_price_id'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    subheader: !exists(json, 'subheader') ? undefined : json['subheader'],
    description: !exists(json, 'description') ? undefined : json['description'],
    descriptionAsHtml: !exists(json, 'description_as_html')
      ? undefined
      : json['description_as_html'],
    productId: !exists(json, 'product_id') ? undefined : json['product_id'],
  };
}

export function PaymentPlanToJSON(value?: PaymentPlan | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    rank: value.rank,
    internal_name: value.internalName,
    users_limit: value.usersLimit,
    monthly_price: value.monthlyPrice,
    annually_price: value.annuallyPrice,
    stripe_monthly_price_id: value.stripeMonthlyPriceId,
    stripe_annually_price_id: value.stripeAnnuallyPriceId,
    summary: value.summary,
    subheader: value.subheader,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
    product_id: value.productId,
  };
}
