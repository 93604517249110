import React from 'react';
import { ReportSectionProps } from './ReportsManageDrawer';
import { DropZone } from '../../DropZone/DropZone';

export const ReportFileField: React.FC<ReportSectionProps> = ({
  updateField,
}) => {
  const setFile = (file: File) => updateField('file', file);
  const baseCss = 'reportsManageDrawer';
  return (
    <div className={baseCss + '--fieldRow'}>
      <DropZone accept=".pdf" onChange={setFile} />
    </div>
  );
};
