import React, { useState } from 'react';
import './MainMenuItem.scss';
import { MainMenuItemConfiguration } from '../MainMenuItems/MainMenuItemConfiguration';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { selectRouteName } from '../../selectors/selectRouteName';
import { selectUserRoles } from '../../selectors/selectUserRoles';
import { selectActiveSubscription } from '../../selectors/selectActiveSubscription';

export interface MainMenuItemProps {
  index: string;
  item: MainMenuItemConfiguration;
}

const MainMenuItem: React.FC<MainMenuItemProps> = props => {
  const [collapsed, setCollapsed] = useState(false);

  const routerRouteName = useSelector(selectRouteName);

  const userRoles = useSelector(selectUserRoles);

  const hasAction =
    props.item.onClick !== undefined || props.item.route !== undefined;

  const isDisabled =
    props.item.disabled ||
    (props.item.disabledExpression &&
      props.item.disabledExpression(userRoles)) ||
    !hasAction;

  const isSelected = props.item.routes
    ? props.item.routes.includes(routerRouteName)
    : routerRouteName === props.item.route;

  const itemClasses = classNames(
    'mainMenuItem--item',
    !isDisabled && 'mainMenuItem--item-pointer mainMenuItem--item-dark',
    !hasAction &&
      'mainMenuItem--item-margin mainMenuItem--item-pointer mainMenuItem--item-small mainMenuItem--item-toggle',
    isSelected && 'mainMenuItem--item-highlight',
    isDisabled ? 'mainMenuItem--item-disabled' : ''
  );

  const chevronClasses = classNames(
    'mainMenuItem--chevron',
    collapsed && 'mainMenuItem--chevron-collapsed'
  );

  const childrenClasses = classNames(
    'mainMenuItem--children',
    collapsed && 'mainMenuItem--children-collapsed'
  );

  const dispatch = useDispatch();

  const handleClick = () => {
    if (isDisabled) return;

    if (props.item.onClick) {
      props.item.onClick();
      return;
    }

    if (props.item.route) {
      dispatch(push(props.item.route));
    }
  };

  return (
    <div className="mainMenuItem">
      {hasAction ? (
        <button
          className={itemClasses}
          disabled={isDisabled}
          key={props.index}
          onClick={() => handleClick()}
        >
          {props.item.icon && (
            <div className="mainMenuItem--icon">
              <FontAwesomeIcon icon={props.item.icon} />
            </div>
          )}
          <div className="mainMenuItem--label">{props.item.label}</div>
        </button>
      ) : (
        <div
          className={itemClasses}
          onClick={() => {
            setCollapsed(previousCollapsed => !previousCollapsed);
          }}
        >
          {props.item.label}
          <FontAwesomeIcon className={chevronClasses} icon={faChevronUp} />
        </div>
      )}
      {props.item.childItems && (
        <div className={childrenClasses}>
          {props.item.childItems.map((child, index) => {
            return (
              <MainMenuItem
                key={index}
                index={`${props.index}:${index}`}
                item={child}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MainMenuItem;
