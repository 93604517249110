/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShareSuggestionListMeta,
  ShareSuggestionListMetaFromJSON,
  ShareSuggestionListMetaFromJSONTyped,
  ShareSuggestionListMetaToJSON,
  SharesListData,
  SharesListDataFromJSON,
  SharesListDataFromJSONTyped,
  SharesListDataToJSON,
} from './';

/**
 *
 * @export
 * @interface SharesList
 */
export interface SharesList {
  /**
   *
   * @type {Array<SharesListData>}
   * @memberof SharesList
   */
  data: Array<SharesListData>;
  /**
   *
   * @type {ShareSuggestionListMeta}
   * @memberof SharesList
   */
  meta: ShareSuggestionListMeta;
}

export function SharesListFromJSON(json: any): SharesList {
  return SharesListFromJSONTyped(json, false);
}

export function SharesListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SharesList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(SharesListDataFromJSON),
    meta: ShareSuggestionListMetaFromJSON(json['meta']),
  };
}

export function SharesListToJSON(value?: SharesList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(SharesListDataToJSON),
    meta: ShareSuggestionListMetaToJSON(value.meta),
  };
}
