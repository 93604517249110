import React, { useEffect, useState } from 'react';
import { AdoptechPanel } from '../../../../../../components/AdoptechPanel/AdoptechPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TrustHubUserAdminDocumentPermissions.scss';
import { NoDataText } from '../../../../../../components/NoDataText/NoDataText';
import AdoptechGridTable from '../../../../../../components/AdoptechGridTable/AdoptechGridTable';
import { Grid } from '../../../../../../types/grid';
import { SortableTableHeader } from '../../../../../../components/SortableTableHeader/SortableTableHeader';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTrustHubVendorUserDocumentPermission,
  fetchTrustHubVendorUserDocumentPermissions,
} from '../../../../store/trusthubThunks';
import { showGlobalToast } from '../../../../../../store/global/globalSlice';
import { ApplicationState } from '../../../../../../types/applicationState';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { selectVendorUserDocumentPermissions } from '../../selectVendorUserDocumentPermissions';
import {
  TrusthubDocumentPermissionModel,
  TrusthubDocumentPermissionModelStatusEnum,
  TrusthubVendorUserStatus,
} from '../../../../../../swagger/trusthub';
import { faUserUnlock } from '@fortawesome/pro-light-svg-icons/faUserUnlock';
import { capitalize } from '../../../../../../functions/capitalize';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../../components/AdoptechButton/AdoptechButton';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { TrusthubUserAdminAddDocumentPermissionDrawer } from '../../TrusthubUserAdminAddDocumentPermissionDrawer/TrusthubUserAdminAddDocumentPermissionDrawer';

export const TrustHubUserAdminDocumentPermissions: React.FC = () => {
  const baseCss = 'trustHubUserAdminDocumentPermissions';
  const users = useSelector(selectVendorUserDocumentPermissions);
  const dispatch = useDispatch();
  const permissions = useSelector(selectVendorUserDocumentPermissions);
  const {
    fetchingVendorUserDocumentPermissionsStatus,
    currentVendorUser: user,
  } = useSelector((state: ApplicationState) => state.trustHub);
  useEffect(() => {
    dispatch(
      fetchTrustHubVendorUserDocumentPermissions({
        vendorUserId: user.id,
      })
    );
  }, []);
  const handleDelete = (permission: TrusthubDocumentPermissionModel) => {
    dispatch(
      deleteTrustHubVendorUserDocumentPermission(
        {
          documentPermissionId: permission.id,
        },
        () =>
          dispatch(
            showGlobalToast(
              'Document permission has been successfully removed from the company'
            )
          )
      )
    );
  };

  const [showAddAccessDrawer, setShowAddAccessDrawer] = useState(false);

  if (fetchingVendorUserDocumentPermissionsStatus === 'loading')
    return <LoadingSpinner />;

  return (
    <div className={baseCss}>
      <div className={baseCss + '--panel'}>
        <AdoptechPanel padding="24px">
          <div className={baseCss + '--panelTitle'}>
            <div className={baseCss + '--panelInfo'}>
              <FontAwesomeIcon icon={faUserUnlock} />
              User Access
            </div>

            <div className={baseCss + '--panelTitleButtons'}>
              <AdoptechButton
                variant={AdoptechButtonVariant.PrimaryOrange}
                icon={faPlus}
                disabled={user.status === TrusthubVendorUserStatus.Unconfirmed}
                disabledTooltip="You cannot edit the document permissions until it's unconfirmed"
                iconSize="large"
                onClick={() => {
                  (
                    document.getElementsByClassName(
                      'cancelUserButton'
                    )[0] as HTMLButtonElement
                  )?.click();
                  setShowAddAccessDrawer(true);
                }}
              >
                Add Access
              </AdoptechButton>
            </div>
          </div>

          <div className={baseCss + '--panelTable'}>
            {users.length <= 0 ? (
              <NoDataText
                extraClass="pt-4"
                text="No document permissions to show"
              />
            ) : (
              <AdoptechGridTable
                header={
                  <div className={baseCss + '--tableHeader'}>
                    <SortableTableHeader<TrusthubDocumentPermissionModel>
                      columnName="name"
                      grid={Grid.TrustHubUserAdminVendorUserDocumentPermissions}
                      label="Asset"
                      notInTable
                    />
                    <SortableTableHeader<TrusthubDocumentPermissionModel>
                      columnName="documentType"
                      grid={Grid.TrustHubUserAdminVendorUserDocumentPermissions}
                      label="Type"
                      notInTable
                    />
                    <SortableTableHeader<TrusthubDocumentPermissionModel>
                      columnName="status"
                      grid={Grid.TrustHubUserAdminVendorUserDocumentPermissions}
                      label="Access"
                      notInTable
                    />
                    <div>Action</div>
                  </div>
                }
              >
                {permissions.map(permission => {
                  const { id } = permission;
                  return (
                    <div className={baseCss + '--tableRow'} key={id}>
                      <div className={baseCss + '--name'}>
                        {permission.name}
                      </div>
                      <div className={baseCss + '--type'}>
                        {permission.documentType}
                      </div>
                      <div className={baseCss + '--status'}>
                        {capitalize(permission.status)}
                      </div>
                      {permission.status ===
                      TrusthubDocumentPermissionModelStatusEnum.Approved ? (
                        <div
                          className={baseCss + '--action'}
                          onClick={e => {
                            e.stopPropagation();
                            handleDelete(permission);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  );
                })}
              </AdoptechGridTable>
            )}
          </div>
        </AdoptechPanel>
        {showAddAccessDrawer && (
          <TrusthubUserAdminAddDocumentPermissionDrawer
            onClose={() => setShowAddAccessDrawer(false)}
          />
        )}
      </div>
    </div>
  );
};
