import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cleanupAgreementsEditForm,
  toggleAgreementReminderModal,
  toggleEditAgreementDrawer,
} from '../../../store/agreements/agreementsSlice';
import { ApplicationState } from '../../../types/applicationState';
import { usePreviewModeReload } from '../AgreementEditor/PreviewMode/PreviewMode';
import { EditAgreementForm } from '../EditAgreementForm/EditAgreementForm';
import { GenericModal } from '../../GenericModal/GenericModal';
import { SendReminderModal } from '../../SendReminderModal/SendReminderModal';

interface ModalProps {
  showDeleteModal: boolean;
  setShowDeleteModal: any;
  handleConfirmDelete: any;
  isDeletingAgreement: boolean;
  setCurrentId: any;
}

export const useCleanupAgreementRHS = () => {
  const { reloadPdf } = usePreviewModeReload();
  const dispatch = useDispatch();
  const onCloseHSMenu = () => {
    reloadPdf();
    dispatch(toggleEditAgreementDrawer());
    dispatch(cleanupAgreementsEditForm());
  };
  return { onCloseHSMenu };
};

export const AgreementsModals: React.FC<ModalProps> = ({
  showDeleteModal,
  setShowDeleteModal,
  handleConfirmDelete,
  isDeletingAgreement,
}) => {
  const dispatch = useDispatch();

  const isShowingEditAgreementDrawer = useSelector(
    (state: ApplicationState) => state.agreements.isShowingEditAgreementDrawer
  );

  const { onCloseHSMenu } = useCleanupAgreementRHS();

  const reminderModalData = useSelector(
    (state: ApplicationState) => state.agreements.agreementSendReminderModalData
  );

  const cleanupReminderModal = () =>
    dispatch(toggleAgreementReminderModal({ isShowing: false }));

  const { isShowing: isShowingSendReminderModal, ...currentSignatoryData } =
    reminderModalData;

  return (
    <>
      {showDeleteModal && (
        <GenericModal
          closeButtonLabel="CONFIRM"
          onCancel={() => setShowDeleteModal(false)}
          onClose={handleConfirmDelete}
          show={showDeleteModal}
          showCancelButton
          title="Confirm delete?"
          isBusy={isDeletingAgreement}
        >
          <div>
            Click CONFIRM to permanently delete this version of the agreement.
          </div>
        </GenericModal>
      )}
      {isShowingSendReminderModal && (
        <SendReminderModal
          signatoryData={currentSignatoryData}
          onSubmit={() => cleanupReminderModal()}
          onCancel={() => cleanupReminderModal()}
        />
      )}
      {isShowingEditAgreementDrawer && (
        <EditAgreementForm
          show={isShowingEditAgreementDrawer}
          onClose={onCloseHSMenu}
        />
      )}
    </>
  );
};
