import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import {
  hideConfirmSendInvitationModal,
  showAddUserModal,
} from '../../store/vendors/vendorsSlice';
import { sendInvitation } from '../../store/vendors/vendorsThunks';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import './ConfirmSendInvitationModal.scss';

export const ConfirmSendInvitationModal = () => {
  const messageRef = useRef<HTMLTextAreaElement>();
  const currentVendor = useSelector(selectCurrentVendor);
  const vendorId = currentVendor?.id;
  const {
    firstName,
    lastName,
    emailAddress,
    message: initialMessage,
    isConfirmSendInvitationModalShowing,
    isSendingInvitation,
    resend,
  } = useSelector((state: ApplicationState) => state.vendors.inviteState);

  const [message, setMessage] = useState(initialMessage);

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    setMessage(value);
  };

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.focus();
    }
  }, [isConfirmSendInvitationModalShowing]);

  const dispatch = useDispatch();

  const handleBack = () => {
    !resend && dispatch(showAddUserModal());
    dispatch(hideConfirmSendInvitationModal());
  };

  return (
    <AdoptechModal
      className="confirmSendInvitationModal"
      onHide={handleBack}
      show={isConfirmSendInvitationModalShowing}
    >
      <Modal.Header>{resend ? 'Resend' : 'Send'} invitation</Modal.Header>
      <Modal.Body>
        Please click 'Invite' below to send an invitation to:
        <div className="confirmSendInvitationModal--emailAddress">
          {emailAddress}
        </div>
        <AdoptechTextArea
          id="message"
          label="Add personal message here:"
          onChange={handleMessageChange}
          ref={messageRef}
          value={message}
        />
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={handleBack}>Back</AdoptechButton>
        <AdoptechButton
          onClick={() => {
            dispatch(
              sendInvitation({
                firstName,
                lastName,
                emailAddress,
                message,
                vendorId,
              })
            );
            setMessage('');
          }}
          variant={AdoptechButtonVariant.Primary}
          busy={isSendingInvitation}
        >
          Invite
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
