import React from 'react';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { useTrustHubSection } from '../useTrustHubSection';
import { CertificationsEditSection } from '../admin/CertificationsEditSection/CertificationsEditSection';
import { TrusthubDocumentModel } from '../../../swagger/trusthub/models/TrusthubDocumentModel';
import { PoliciesEditSection } from '../admin/EditSectionRow/EditSectionRow';
import { SectionRow } from '../SectionRow/SectionRow';

interface DocumentsSectionProps {
  identifier: string;
  isEditable: boolean;
  sections: any[];
  onDocumentClick: (doc: TrusthubDocumentModel) => void;
  showDocumentDownloadIcon: boolean;
}

export const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  identifier,
  isEditable,
  sections,
  onDocumentClick,
  showDocumentDownloadIcon,
}) => {
  const {
    isEditMode,
    setIsEditMode,
    section,
    publicDocs,
    titleBar,
    baseCss,
    editSectionHandler,
    bulkActionsRow,
  } = useTrustHubSection({
    identifier,
    sections,
    isEditable,
  });

  if (!section) return null;

  return (
    <div id={baseCss} className={classNames(baseCss)}>
      {isEditMode ? (
        identifier === 'certificates' ? (
          <CertificationsEditSection
            section={section}
            titleBar={titleBar}
            bulkActionsRow={bulkActionsRow}
            editSectionHandler={editSectionHandler}
            onClose={() => setIsEditMode(false)}
          />
        ) : (
          <PoliciesEditSection
            bulkActionsRow={bulkActionsRow}
            baseCss={baseCss}
            section={section}
            titleBar={titleBar}
            editSectionHandler={editSectionHandler}
            onClose={() => setIsEditMode(false)}
          />
        )
      ) : (
        <div className={baseCss + '--panel'}>
          {titleBar}
          <div className={baseCss + '--descriptionRow'}>
            <div className={baseCss + '--description'}>
              {section.description}
            </div>
            {isEditable && (
              <div>
                <AdoptechButton
                  extraClass="editButton"
                  icon={faPencil}
                  onClick={() => setIsEditMode(true)}
                  variant={AdoptechButtonVariant.White}
                >
                  Edit
                </AdoptechButton>
              </div>
            )}
          </div>
          {publicDocs.length == 0 && (
            <div className={baseCss + '--noContent'}>
              No documents selected to display.
            </div>
          )}
          {publicDocs.length > 0 && (
            <div className={baseCss + '--documents'}>
              {publicDocs.map(doc => (
                <SectionRow
                  key={doc.name}
                  doc={doc}
                  identifier={identifier}
                  baseCss={baseCss}
                  showDocumentDownloadIcon={showDocumentDownloadIcon}
                  onDocumentClick={onDocumentClick}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
