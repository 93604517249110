import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { DOMAttributes, useEffect, useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import { formatLongDate } from '../../functions/formatLongDate';
import { fetchAssessmentResponseHistory } from '../../store/assessments/assessmentsThunks';
import {
  AssessmentResponseAnswer,
  Question,
  QuestionLayoutEnum,
} from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { AdoptechButton } from '../AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { AnswerFormControl } from '../AnswerFormControl/AnswerFormControl';
import './AssessmentResponseHistoryPopup.scss';
import { cleanupAssessmentHistories } from '../../store/assessments/assessmentsSlice';

interface AssessmentResponseHistoryPopupProps {
  assessmentResponseId: string;
  previousAnswer: AssessmentResponseAnswer;
  question: Question;
  questions: Question[];
}

const baseCss = 'assessmentResponseHistoryPopup';
// TODO: reuse AdoptechDropdownToggle
const HistoryButton = React.forwardRef<HTMLDivElement, DOMAttributes<any>>(
  (props, ref) => (
    <div
      className={baseCss + '--item'}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        props.onClick(e);
      }}
      ref={ref}
    >
      <AdoptechButton icon={faExclamationTriangle}>History</AdoptechButton>
    </div>
  )
);

interface AssessmentHistoryProps {
  assessmentResponseId: string;
  question: Question;
}

export const useAssessmentHistory = ({
  assessmentResponseId,
  question,
}: AssessmentHistoryProps) => {
  const dispatch = useDispatch();
  const fetchHistory = () => {
    if (!assessmentResponseId) return;
    dispatch(fetchAssessmentResponseHistory(assessmentResponseId, question.id));
  };

  useEffect(() => {
    dispatch(cleanupAssessmentHistories());
  }, []);

  const { assessmentResponseHistories, isFetchingAssessmentResponseHistory } =
    useSelector((state: ApplicationState) => state.assessments);
  const history =
    assessmentResponseHistories?.[question.id]?.filter(
      r => r.assessmentResponseId !== assessmentResponseId
    ) || [];
  return {
    isFetchingAssessmentResponseHistory,
    history,
    fetchHistory,
  };
};

// This component is similar to AssessmentResponseLibraryPopup, but is used only on completed assessments
export const AssessmentResponseHistoryPopup: React.FC<
  AssessmentResponseHistoryPopupProps
> = ({ assessmentResponseId, previousAnswer, question, questions }) => {
  const { history, fetchHistory, isFetchingAssessmentResponseHistory } =
    useAssessmentHistory({ assessmentResponseId, question });

  // No history for file upload questions
  if (question.layout === QuestionLayoutEnum.FileSelect) {
    return null;
  }

  const tableHistory: { [key: string]: AssessmentResponseAnswer[] } = {};
  if (history && question.layout === QuestionLayoutEnum.TableLayout) {
    history.forEach(entry => {
      tableHistory[entry.assessmentResponseId]
        ? tableHistory[entry.assessmentResponseId].push(entry)
        : (tableHistory[entry.assessmentResponseId] = [entry]);
    });
  }

  const renderTableHistory = (
    tableHistoryAnswers: AssessmentResponseAnswer[],
    index: number
  ) => {
    const entry = history[0];
    return renderEntry(entry, index, tableHistoryAnswers);
  };

  let prevAnswer = previousAnswer?.value;
  const exclamation = !!history && prevAnswer !== history?.[0]?.value;

  const renderEntry = (
    entry: AssessmentResponseAnswer,
    index: number,
    historyAnswers: AssessmentResponseAnswer[]
  ) => {
    const isConfirmed = prevAnswer === entry.value;
    prevAnswer = entry.value;

    return (
      <div
        className="assessmentResponseHistoryPopup--historyEntry"
        key={entry.id}
      >
        <div className="assessmentResponseHistoryPopup--lastUpdatedText">
          {isConfirmed ? 'Confirmed' : index === 0 ? 'Last updated' : 'Updated'}
        </div>
        <div className="assessmentResponseHistoryPopup--lastUpdatedAt">
          {formatLongDate(isConfirmed ? entry.confirmedAt : entry.updatedAt)}
        </div>
        <>
          <div className="assessmentResponseHistoryPopup--responseLabel">
            Response
          </div>
          <AnswerFormControl
            question={question}
            questions={questions}
            answers={historyAnswers}
            previousAnswer={entry}
            indexProperty="name"
            onChange={() => {}}
            readonly
            asTextReadonly
            responseId={entry.assessmentResponseId}
          />
        </>
      </div>
    );
  };

  return (
    <div
      className={classNames(baseCss, {
        [`${baseCss}--exclamation`]: exclamation,
      })}
    >
      <Dropdown
        as={ButtonGroup}
        onToggle={isOpen => {
          if (isOpen) fetchHistory();
        }}
        drop="down"
        alignRight
      >
        <Dropdown.Toggle as={HistoryButton} />

        <DropdownMenu alignRight>
          <div className="assessmentResponseHistoryPopup--pointer" />
          <div className="assessmentResponseHistoryPopup--body">
            <div className="assessmentResponseHistoryPopup--title-secondary">
              Version history <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
            <div className="assessmentResponseHistoryPopup--history">
              {!isFetchingAssessmentResponseHistory && history && (
                <>
                  {question.layout === QuestionLayoutEnum.TableLayout
                    ? Object.values(tableHistory).map(renderTableHistory)
                    : history.map(renderEntry)}
                </>
              )}
              {isFetchingAssessmentResponseHistory && <LoadingSpinner />}

              {!isFetchingAssessmentResponseHistory &&
                (!history || history.length === 0) &&
                'There is no previous answer to this question'}
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
