import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PestelItemForm } from '../../components/ManagePestelItemsDrawer/ManagePestelItemsDrawer';
import { MessageToastVariant } from '../../components/MessageToast/MessageToast';
import { PestelItemModel, PestelRegisterModel } from '../../swagger';
import { PestelState } from './pestelState';

const pestelSlice = createSlice({
  initialState: {
    isFetchingPestels: false,
    toastMessage: '',
    toastVariant: MessageToastVariant.Primary,
    pestelItems: [],
    showEditDrawer: false,
    isDeletingPestel: false,
    pestelItemForm: {},
  } as unknown as PestelState,
  name: 'pestelSlice',
  reducers: {
    clearToastMessage: state => {
      state.toastMessage = '';
      state.toastVariant = MessageToastVariant.Primary;
    },
    fetchPestelsRequest: state => {
      state.isFetchingPestels = true;
    },
    fetchPestelsSuccess: (state, action: PayloadAction<PestelItemModel[]>) => {
      state.isFetchingPestels = false;
      state.pestelItems = action.payload;
    },
    setPestelError: (state, action: PayloadAction<string>) => {
      state.isFetchingPestels = false;
      state.toastMessage = action.payload; // Looks like state.toastMessage not required because we already show the error at the right bottom corner
    },
    deletePestelRequest: state => {
      state.isDeletingPestel = true;
    },
    deletePestelSuccess: (state, action: PayloadAction<string>) => {
      state.pestelIdToBeDeleted = undefined;
      state.isDeletingPestel = false;
      const items = state.pestelItems.filter(p => p.id != action.payload);
      state.pestelItems = items;
    },
    setPestelIdToBeDeleted: (state, action: PayloadAction<string>) => {
      state.pestelIdToBeDeleted = action.payload;
    },
    updatePestelItemForm: (state, action: PayloadAction<PestelItemForm>) => {
      state.pestelItemForm = action.payload;
    },
    createPestelItemRequest: state => {
      state.isCreatingPestelItem = true;
    },
    createPestelItemSuccess: (
      state,
      action: PayloadAction<PestelItemModel>
    ) => {
      state.isCreatingPestelItem = false;
      state.pestelItems.push(action.payload);
    },
    createPestelItemFailure: (state, action: PayloadAction<string>) => {
      state.isCreatingPestelItem = false;
    },
    fetchPestelItemRequest: state => {
      state.isFetchingPestelItem = true;
    },
    fetchPestelItemSuccess: (state, action: PayloadAction<PestelItemModel>) => {
      state.isFetchingPestelItem = false;
      state.pestelItemForm = action.payload;
    },
    fetchPestelItemFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingPestelItem = false;
    },

    updatePestelItemRequest: state => {
      state.isFetchingPestelItem = true;
    },
    updatePestelItemSuccess: (
      state,
      action: PayloadAction<PestelItemModel>
    ) => {
      state.isFetchingPestelItem = false;
      const updatedPestel = action.payload;
      state.pestelItems = state.pestelItems.map(pestel => {
        return pestel.id === updatedPestel.id ? updatedPestel : pestel;
      });
    },
    updatePestelItemFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingPestelItem = false;
    },

    setShowEditDrawer: (state, action: PayloadAction<boolean>) => {
      state.showEditDrawer = action.payload;
    },

    fetchPestelRegisterSuccess: (
      state,
      action: PayloadAction<PestelRegisterModel>
    ) => {
      state.isFetchingPestelRegister = false;
      state.pestelRegister = action.payload;
    },
    fetchPestelRegisterFailure: (state, _: PayloadAction<string>) => {
      state.isFetchingPestelRegister = false;
    },
    fetchPestelRegisterRequest: state => {
      state.isFetchingPestelRegister = true;
    },
    patchPestelRegisterSuccess: (
      state,
      action: PayloadAction<PestelRegisterModel>
    ) => {
      state.isPatchingPestelRegister = false;
      state.pestelRegister = { ...state.pestelRegister, ...action.payload };
    },
    patchPestelRegisterFailure: (state, _: PayloadAction<string>) => {
      state.isPatchingPestelRegister = false;
    },
    patchPestelRegisterRequest: state => {
      state.isPatchingPestelRegister = true;
    },

    updatePestelRegisterReviewSuccess: (
      state,
      action: PayloadAction<Partial<PestelRegisterModel>>
    ) => {
      state.pestelRegister = { ...state.pestelRegister, ...action.payload };
    },
  },
});

export const {
  setPestelIdToBeDeleted,
  clearToastMessage,
  fetchPestelsRequest,
  fetchPestelsSuccess,
  setPestelError,
  deletePestelRequest,
  deletePestelSuccess,
  updatePestelItemForm,
  createPestelItemRequest,
  createPestelItemSuccess,
  createPestelItemFailure,
  fetchPestelItemRequest,
  fetchPestelItemSuccess,
  fetchPestelItemFailure,
  updatePestelItemRequest,
  updatePestelItemSuccess,
  updatePestelItemFailure,
  setShowEditDrawer,
  fetchPestelRegisterRequest,
  fetchPestelRegisterSuccess,
  fetchPestelRegisterFailure,
  patchPestelRegisterRequest,
  patchPestelRegisterSuccess,
  patchPestelRegisterFailure,
  updatePestelRegisterReviewSuccess,
} = pestelSlice.actions;

export default pestelSlice.reducer;
