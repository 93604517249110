import { createSelector } from '@reduxjs/toolkit';
import { VendorUser } from '../swagger/models';
import { selectCurrentVendorUser } from './selectCurrentVendorUser';

export const selectCurrentVendorUserId = createSelector(
  selectCurrentVendorUser,
  (user: VendorUser) => {
    return user?.id;
  }
);
