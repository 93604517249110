import { RadioButtonsAnswerProps } from '../components/RadioButtonsAnswer/RadioButtonsAnswer';
import { ApplicationState } from '../types/applicationState';

export const getRadioButtonOptions = (
  state: ApplicationState,
  props: RadioButtonsAnswerProps
) => {
  if (!state.vendors.vendorTableCache) {
    return null;
  }

  if (state.vendors.vendorTableCache[props.question.tableType] === undefined) {
    return null;
  }

  return state.vendors.vendorTableCache[props.question.tableType];
};
