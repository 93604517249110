/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TaskCreatePayloadTask,
  TaskCreatePayloadTaskFromJSON,
  TaskCreatePayloadTaskFromJSONTyped,
  TaskCreatePayloadTaskToJSON,
} from './';

/**
 *
 * @export
 * @interface TaskCreatePayload
 */
export interface TaskCreatePayload {
  /**
   *
   * @type {TaskCreatePayloadTask}
   * @memberof TaskCreatePayload
   */
  task: TaskCreatePayloadTask;
}

export function TaskCreatePayloadFromJSON(json: any): TaskCreatePayload {
  return TaskCreatePayloadFromJSONTyped(json, false);
}

export function TaskCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TaskCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    task: TaskCreatePayloadTaskFromJSON(json['task']),
  };
}

export function TaskCreatePayloadToJSON(value?: TaskCreatePayload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    task: TaskCreatePayloadTaskToJSON(value.task),
  };
}
