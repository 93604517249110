import React from 'react';
import AdoptechGridTable from '../../AdoptechGridTable/AdoptechGridTable';
import { SortableTableHeader } from '../../SortableTableHeader/SortableTableHeader';
import { PestelRow } from './PestelRow';
import './PestelSection.scss';
import { capitaliseFirst } from '../../../functions/capitaliseFirst';
import { NoDataText } from '../../NoDataText/NoDataText';
import { PestelItemArea, PestelItemModel } from '../../../swagger';
import { getGridFromPestelArea } from '../getGridFromPestelArea';
import { useSelector } from 'react-redux';
import { selectPestels } from '../../../selectors/selectPestels';

interface PestelSectionProps {
  pestelsFilteredBySearch: PestelItemModel[];
  itemArea: PestelItemArea;
  baseTableCss: string;
}

export const PestelSection: React.FC<PestelSectionProps> = ({
  pestelsFilteredBySearch,
  itemArea,
  baseTableCss,
}) => {
  const baseCss = 'pestelSection';

  const getHeaderText = (area: string) => {
    const subStrings = area.split('_');
    return subStrings.map(s => capitaliseFirst(s)).join(' / ');
  };

  const grid = getGridFromPestelArea(itemArea);

  const sortedPestels = useSelector(
    selectPestels(itemArea, grid, pestelsFilteredBySearch)
  );

  return (
    <>
      <div className={baseCss + '--pestelTypeSection'}>
        <div className={baseCss + '--pestelHeader'}>
          <div className={baseCss + '--itemTitle'}>
            {getHeaderText(itemArea)}
          </div>
        </div>
        <div className={baseCss + '--table'}>
          {sortedPestels.length > 0 ? (
            <div className={baseCss + '--4-columns'}>
              <AdoptechGridTable
                header={
                  <div className={baseTableCss + '--header'}>
                    <SortableTableHeader<PestelItemModel>
                      columnName="itemType"
                      grid={grid}
                      label="Type"
                      className={baseCss + '--itemTypeHeader'}
                      notInTable
                    />
                    <div>Description</div>
                    <SortableTableHeader<PestelItemModel>
                      columnName="consequence" // custom sorting will be applied
                      grid={grid}
                      label="Score"
                      className={baseCss + '--risk'}
                      notInTable
                    />
                    <div></div>
                  </div>
                }
              >
                {sortedPestels.map((item: PestelItemModel) => {
                  return (
                    <PestelRow
                      key={item.id}
                      pestel={item}
                      baseCss={baseCss}
                      baseTableCss={baseTableCss}
                    />
                  );
                })}
              </AdoptechGridTable>
            </div>
          ) : (
            <NoDataText text={`There are no items in the ${itemArea} area.`} />
          )}
        </div>
      </div>
    </>
  );
};
