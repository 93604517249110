import React from 'react';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import AdoptechModal from '../../AdoptechModal/AdoptechModal';
import { Answer, Question, VendorTable } from '../../../swagger/models';
import { getAnswerDisplayValue } from '../tableUtils';

interface TableAnswerDeleteModal2Props {
  rowToChange: { index: number; row: Answer[] };
  question: Question;
  onCancel(): void;
  onDelete(answers: Answer[]): void;
  vendorTableCache: { [key: string]: VendorTable };
}

export const TableAnswerDeleteModal2: React.FC<
  TableAnswerDeleteModal2Props
> = ({ question, rowToChange, onCancel, onDelete, vendorTableCache }) => {
  const getAnswerValue = (tableQuestion: Question): string => {
    const respectiveAnswer = rowToChange?.row?.find(
      (answer: Answer) => answer.propertyName === tableQuestion.propertyName
    );
    return getAnswerDisplayValue(question, respectiveAnswer, vendorTableCache);
  };

  return (
    <AdoptechModal className="tableAnswerDeleteModal" onHide={onCancel} show>
      <Modal.Header>Delete</Modal.Header>
      <Modal.Body>
        Are you sure you wish to delete this row?
        <br />
        <ul>
          {question.table.questions?.map((tableQuestion: Question) => (
            <React.Fragment key={tableQuestion.id}>
              <li>
                {tableQuestion.questionText}: {getAnswerValue(tableQuestion)}
              </li>
            </React.Fragment>
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={onCancel}>Cancel</AdoptechButton>
        <AdoptechButton
          onClick={() => {
            onDelete(rowToChange.row);
            onCancel();
          }}
          variant={AdoptechButtonVariant.Primary}
        >
          CONFIRM
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
