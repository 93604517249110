import {
  AssessmentSection,
  Question,
  QuestionColumn,
  QuestionDatatypeEnum,
} from '../swagger';
import flattenArray from './flattenArray';
import { dentaku } from './dentaku';

const getFilteredQuestionsWithoutTableQuestions = (
  section: AssessmentSection,
  context?: Record<string, string>
): Question[] => {
  const excludedQuestionList = section.questions
    .filter(
      (question: Question) => question.datatype === QuestionDatatypeEnum.NoData
    )
    .map((question: Question) =>
      question.questionColumns.map(
        (questionColumn: QuestionColumn) => questionColumn.columnValue
      )
    );

  const flattenedList = flattenArray(excludedQuestionList);

  let questions = section.questions.filter(
    (question: Question) => !flattenedList.includes(question.name)
  );

  if (context) {
    questions = questions.filter(q => dentaku(q.showWhen, context));
  }

  return questions;
};

export default getFilteredQuestionsWithoutTableQuestions;
