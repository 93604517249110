/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserForgotPassword
 */
export interface UserForgotPassword {
  /**
   *
   * @type {string}
   * @memberof UserForgotPassword
   */
  email?: string;
}

export function UserForgotPasswordFromJSON(json: any): UserForgotPassword {
  return UserForgotPasswordFromJSONTyped(json, false);
}

export function UserForgotPasswordFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserForgotPassword {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, 'email') ? undefined : json['email'],
  };
}

export function UserForgotPasswordToJSON(
  value?: UserForgotPassword | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
  };
}
