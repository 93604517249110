/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  Survey,
  SurveyFromJSON,
  SurveyFromJSONTyped,
  SurveyToJSON,
} from './';

/**
 *
 * @export
 * @interface ChecklistTemplate
 */
export interface ChecklistTemplate {
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplate
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplate
   */
  name: string;
  /**
   *
   * @type {Access}
   * @memberof ChecklistTemplate
   */
  access?: Access;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistTemplate
   */
  common: boolean;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplate
   */
  relatedTo?: ChecklistTemplateRelatedToEnum;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplate
   */
  summary?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplate
   */
  summaryAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplate
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplate
   */
  descriptionAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplate
   */
  version: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplate
   */
  versionBump: ChecklistTemplateVersionBumpEnum;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplate
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplate
   */
  updatedAt: string;
  /**
   *
   * @type {Survey}
   * @memberof ChecklistTemplate
   */
  survey?: Survey;
}

export function ChecklistTemplateFromJSON(json: any): ChecklistTemplate {
  return ChecklistTemplateFromJSONTyped(json, false);
}

export function ChecklistTemplateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistTemplate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    common: json['common'],
    relatedTo: !exists(json, 'related_to') ? undefined : json['related_to'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    summaryAsHtml: !exists(json, 'summary_as_html')
      ? undefined
      : json['summary_as_html'],
    description: !exists(json, 'description') ? undefined : json['description'],
    descriptionAsHtml: !exists(json, 'description_as_html')
      ? undefined
      : json['description_as_html'],
    version: json['version'],
    versionBump: json['version_bump'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
    survey: !exists(json, 'survey')
      ? undefined
      : SurveyFromJSON(json['survey']),
  };
}

export function ChecklistTemplateToJSON(value?: ChecklistTemplate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    access: AccessToJSON(value.access),
    common: value.common,
    related_to: value.relatedTo,
    summary: value.summary,
    summary_as_html: value.summaryAsHtml,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
    version: value.version,
    version_bump: value.versionBump,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    survey: SurveyToJSON(value.survey),
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ChecklistTemplateRelatedToEnum {
  VendorUser = 'vendor_user',
}
/**
 * @export
 * @enum {string}
 */
export enum ChecklistTemplateVersionBumpEnum {
  Minor = 'minor',
  Major = 'major',
}
