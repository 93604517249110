import React from 'react';
import { useSelector } from 'react-redux';
import { selectCompletedAssessments } from '../../selectors/selectCompletedAssessments';
import { selectCompletedAssessmentsResponses } from '../../selectors/selectCompletedAssessmentsResponses';
import { AssessmentTabs } from '../../store/assessments/assessmentsState';
import { CompletedAssessmentsGrid } from '../CompletedAssessmentsGrid/CompletedAssessmentsGrid';
import { Intent, Lozenge } from '../Lozenge/Lozenge';
import { NoDataText } from '../NoDataText/NoDataText';
import './CompletedAssessments.scss';

export interface CompletedAssessmentsProps {
  assessmentTab: AssessmentTabs;
}

export const CompletedAssessments: React.FC<
  CompletedAssessmentsProps
> = props => {
  const completedAssessmentsResp = useSelector(
    selectCompletedAssessmentsResponses
  );

  const completedAssessments = useSelector(selectCompletedAssessments);

  const assessments =
    AssessmentTabs.Incoming === props.assessmentTab
      ? completedAssessmentsResp
      : completedAssessments;

  const emptyArrayText =
    AssessmentTabs.Incoming === props.assessmentTab
      ? 'Assessments you have responded to will appear here'
      : 'Once all responders have submitted their responses, your assessment will appear here';

  return (
    <div className="completedAssessments">
      <div className="completedAssessments--header">
        <div className="completedAssessments--title">
          Complete <Lozenge intent={Intent.None} value={assessments.length} />
        </div>
      </div>
      {assessments.length > 0 ? (
        <CompletedAssessmentsGrid assessments={assessments} />
      ) : (
        <NoDataText text={emptyArrayText} />
      )}
    </div>
  );
};
