/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ControlCreatePayloadControlFrameworksRelations,
  ControlCreatePayloadControlFrameworksRelationsFromJSON,
  ControlCreatePayloadControlFrameworksRelationsFromJSONTyped,
  ControlCreatePayloadControlFrameworksRelationsToJSON,
} from './';

/**
 *
 * @export
 * @interface ControlCreatePayloadControl
 */
export interface ControlCreatePayloadControl {
  /**
   *
   * @type {string}
   * @memberof ControlCreatePayloadControl
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ControlCreatePayloadControl
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ControlCreatePayloadControl
   */
  treatmentPlan?: string | null;
  /**
   *
   * @type {string}
   * @memberof ControlCreatePayloadControl
   */
  assigneeId?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ControlCreatePayloadControl
   */
  tasksIds: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ControlCreatePayloadControl
   */
  policyCodes?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ControlCreatePayloadControl
   */
  vendorPolicyIds?: Array<string>;
  /**
   *
   * @type {Array<ControlCreatePayloadControlFrameworksRelations>}
   * @memberof ControlCreatePayloadControl
   */
  frameworksRelations: Array<ControlCreatePayloadControlFrameworksRelations>;
}

export function ControlCreatePayloadControlFromJSON(
  json: any
): ControlCreatePayloadControl {
  return ControlCreatePayloadControlFromJSONTyped(json, false);
}

export function ControlCreatePayloadControlFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlCreatePayloadControl {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    treatmentPlan: !exists(json, 'treatment_plan')
      ? undefined
      : json['treatment_plan'],
    assigneeId: !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
    tasksIds: json['tasks_ids'],
    policyCodes: !exists(json, 'policy_codes')
      ? undefined
      : json['policy_codes'],
    vendorPolicyIds: !exists(json, 'vendor_policy_ids')
      ? undefined
      : json['vendor_policy_ids'],
    frameworksRelations: (json['frameworks_relations'] as Array<any>).map(
      ControlCreatePayloadControlFrameworksRelationsFromJSON
    ),
  };
}

export function ControlCreatePayloadControlToJSON(
  value?: ControlCreatePayloadControl | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    treatment_plan: value.treatmentPlan,
    assignee_id: value.assigneeId,
    tasks_ids: value.tasksIds,
    policy_codes: value.policyCodes,
    vendor_policy_ids: value.vendorPolicyIds,
    frameworks_relations: (value.frameworksRelations as Array<any>).map(
      ControlCreatePayloadControlFrameworksRelationsToJSON
    ),
  };
}
