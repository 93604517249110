import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { selectAgreementMode } from '../../../../../selectors/selectAgreements';
import { selectCurrentAgreement } from '../../../../../selectors/selectCurrentAgreement';
import { AdoptechRadioButtonsGroup } from '../../../../AdoptechRadioButtonsGroup/AdoptechRadioButtonsGroup';
import { updateAgreementsEditFormField } from '../../../../../store/agreements/agreementsSlice';
import { AgreementsMode } from '../../../AgreementsList/AgreementsList';
import { fetchAgreementEditFormFields } from '../../../../../store/agreements/agreementsThunks';
import { ApplicationState } from '../../../../../types/applicationState';
import { selectAgreementsEditForm } from '../../../../../selectors/selectAgreementsEditForm';
import { fetchCompanyLogo } from '../../../../../store/vendors/vendorsThunks';
import { selectCurrentVendor } from '../../../../../selectors/selectCurrentVendor';
import { selectCurrentCompany } from '../../../../../selectors/selectCurrentCompany';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCleanupAgreementRHS } from '../../../AgreementsModals/AgreementsModals';
import { selectVendorDetails } from '../../../../../selectors/selectVendorDetails';
import { agreementsAccess } from '../../../AgreementMeatballMenu/AgreementMeatballMenu';

export const BrandingSection: React.FC = () => {
  const dispatch = useDispatch();

  const currentAgreement = useSelector(selectCurrentAgreement);
  const baseCss = 'editAgreementForm';
  const mode = selectAgreementMode(currentAgreement);
  const form = useSelector(selectAgreementsEditForm);

  const currentVendor = useSelector(selectCurrentVendor);
  const vendorId = currentVendor?.id;

  useEffect(() => {
    dispatch(fetchCompanyLogo({ vendorId }));
  }, [vendorId]);

  const logo = useSelector(
    (state: ApplicationState) => state.vendors.companyDetailsState.logo
  );
  const vendorDetails = useSelector(selectVendorDetails);

  const colour = useSelector(selectCurrentCompany)?.brandingColor;

  useEffect(() => {
    if (mode == AgreementsMode.Draft) {
      dispatch(fetchAgreementEditFormFields(currentAgreement.id));
    }
  }, [currentAgreement.id]);

  const { onCloseHSMenu } = useCleanupAgreementRHS();

  if (!agreementsAccess(currentAgreement).canEditBranding) return <></>;
  if (!form.isLoadedExtendedFields) return <></>;

  const setUseLogo = (value: string) =>
    dispatch(
      updateAgreementsEditFormField({
        useCompanyLogo: value == 'useLogo',
      })
    );

  const setUseColor = (value: string) =>
    dispatch(
      updateAgreementsEditFormField({
        useBrandingColor: value == 'useColor',
      })
    );

  const handleAnchor = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const url = event.currentTarget.getAttribute('href');
    onCloseHSMenu();
    dispatch(push(url));
  };

  return (
    <>
      <div className={baseCss + '--section-labels'}>
        <span className={baseCss + '--section-label'}>Company branding</span>
      </div>

      <div className="mt-2">
        <div className={baseCss + '--section-sub-label'}>
          Logo &nbsp;
          {logo?.filename ? (
            <img src={logo.urls.thumb} />
          ) : (
            '(no logo uploaded)'
          )}
        </div>
        <AdoptechRadioButtonsGroup
          value={form.useCompanyLogo ? 'useLogo' : 'noLogo'}
          values={['useLogo', 'noLogo']}
          labels={['Use my company logo', "I'd prefer not to use a logo"]}
          onChange={setUseLogo}
        />
        <div className={baseCss + '--section-sub-label'}>
          Colour &nbsp;
          {colour ? (
            <FontAwesomeIcon fontSize={20} color={colour} icon={faCircle} />
          ) : (
            <FontAwesomeIcon fontSize={20} icon={faBan} />
          )}
        </div>
        <AdoptechRadioButtonsGroup
          value={form.useBrandingColor ? 'useColor' : 'noColor'}
          values={['useColor', 'noColor']}
          labels={[
            'Use my company colour',
            "I'd prefer not to use a company colour",
          ]}
          onChange={setUseColor}
        />
        <span className={baseCss + '--section-hint'}>
          To select a colour and logo please go to the Company profile &nbsp;
          <a href="/r/companyInformation/profile" onClick={handleAnchor}>
            here
          </a>
          .
        </span>
      </div>
    </>
  );
};
