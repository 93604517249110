import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { push } from 'connected-react-router';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomRisk } from '../../../store/riskRegistry/riskRegistryThunks';
import { RiskModel } from '../../../swagger/models/RiskModel';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import AdoptechHS from '../../AdoptechHS/AdoptechHS';
import { AdoptechReactSelect2 } from '../../AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechTextArea2 } from '../../AdoptechTextArea2/AdoptechTextArea2';
import { AdoptechTextInput2 } from '../../AdoptechTextInput2/AdoptechTextInput2';
import { riskRegistryCategoryRoute } from '../../Routes/Routes';
import { sortedRiskCategories } from '../RiskRegistryLibrary/RiskRegistryLibrary';
import './RiskRegistryStartNewDrawer.scss';

interface Props {
  onClose(): void;
}

export const validation = (
  data: RiskModelForm
): Record<keyof RiskModelForm, string[]> => ({
  name: data.name ? null : ['Risk name must be set'],
  riskCategoryId: data.riskCategoryId ? null : ['Risk category must be set'],
  description: data.description ? null : ['Risk description must be set'],
});

export type RiskModelForm = Pick<
  RiskModel,
  'name' | 'riskCategoryId' | 'description'
>;

export const RiskRegistryStartNewDrawer: React.FC<Props> = ({ onClose }) => {
  const [formData, setFormData] = useState({} as RiskModelForm);
  const formIsValid = Object.values(validation(formData)).every(v => !v);
  const baseCss = 'risksStartNewDrawer';
  type ValueOf<T> = T[keyof T];
  const updateField = (
    field: keyof RiskModelForm,
    value: ValueOf<RiskModelForm>
  ) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const hasError = (field: keyof RiskModelForm) => !formData[field];
  const { categories, isCreatingCustomRisk } = useSelector(
    (state: ApplicationState) => state.riskRegistry
  );

  const categoryOptions = useMemo(
    () =>
      sortedRiskCategories(categories).map(category => {
        return { label: category.name, value: category.id };
      }),
    [categories]
  );

  const dispatch = useDispatch();

  const onSuccess = () => {
    dispatch(
      push(riskRegistryCategoryRoute.replace(':id', formData.riskCategoryId))
    );
  };
  const Footer = (
    <>
      <AdoptechButton onClick={onClose} variant={AdoptechButtonVariant.White}>
        Cancel
      </AdoptechButton>
      <AdoptechButton
        disabled={!formIsValid}
        onClick={() => {
          dispatch(createCustomRisk(formData, onSuccess));
        }}
        variant={AdoptechButtonVariant.Primary}
        busy={isCreatingCustomRisk}
      >
        Add
      </AdoptechButton>
    </>
  );

  return (
    <AdoptechHS
      title="Add custom risk"
      show
      onClose={onClose}
      showConfirmationWarning={false}
      footer={Footer}
      extraClass="risksStartNewDrawer"
    >
      <div className={baseCss + '--fieldRow'}>
        <div className={baseCss + '--label'}>Name your risk.</div>
        <div className={baseCss + '--field'}>
          <AdoptechTextInput2
            type="text"
            hasError={hasError('name')}
            label="Risk name"
            id={`${baseCss}name`}
            value={formData.name}
            onChange={e => updateField('name', e.currentTarget.value)}
            icon={faPen}
          />
        </div>
      </div>

      <div className={baseCss + '--fieldRow'}>
        <div className={baseCss + '--label'}>
          What category is this risk in?
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechReactSelect2
            label="Risk category"
            id={`${baseCss}category`}
            options={categoryOptions}
            hasError={hasError('riskCategoryId')}
            onChange={e => updateField('riskCategoryId', e.value)}
            value={categoryOptions.find(
              option => option.value == formData.riskCategoryId
            )}
            placeholder="Please select"
          />
        </div>
      </div>

      <div className={baseCss + '--fieldRow'}>
        <div className={baseCss + '--label'}>
          Provide a more detailed description of the risk.
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechTextArea2
            hasError={hasError('description')}
            label="Description"
            id={`${baseCss}description`}
            value={formData.description}
            onChange={e => updateField('description', e.currentTarget.value)}
            icon={faPen}
          />
        </div>
      </div>
    </AdoptechHS>
  );
};
