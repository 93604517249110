import React from 'react';
import './ControlsSection.scss';
import { Accordion } from 'react-bootstrap';
import { AdoptechAccordionCard } from '../../../components/AdoptechAccordionCard/AdoptechAccordionCard';
import { TrusthubDocumentModel } from '../../../swagger/trusthub';
import {
  EditSectionHandlerType,
  TrusthubFormDocument,
} from '../admin/useEditTrustHubSection';
import { useDrop } from 'react-dnd';
import { ControlRow } from './ControlRow';

interface ControlCategoryRowProps {
  title: string;
  controls: TrusthubDocumentModel[];
  categoryId: string;
  baseCss: string;
  color: 'dark' | 'white';
  isEditable?: boolean;
  editSectionHandler: EditSectionHandlerType;
  dragAllowed: boolean;
  dropAllowed: boolean;
  showTooltip: boolean;
}

export const ControlCategoryRow: React.FC<ControlCategoryRowProps> = ({
  title,
  controls,
  categoryId,
  baseCss,
  color,
  isEditable,
  editSectionHandler,
  dragAllowed,
  dropAllowed,
  showTooltip,
}) => {
  const { handleAddControlsToCategory } = editSectionHandler;
  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: 'TrusthubControls',
      drop: (controlIds: string[], monitor) => {
        if (!monitor.isOver({ shallow: false })) {
          return;
        }
        handleAddControlsToCategory({
          categoryId,
          controlIds,
        });
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
    }),
    [categoryId, isEditable]
  );

  return (
    <Accordion key={categoryId} defaultActiveKey="0" ref={dropRef}>
      <AdoptechAccordionCard
        title={title}
        index={categoryId}
        headerClass={`positionInitial ${color} ${isOver ? 'draggable' : ''}`}
        iconSize="small"
        noMargin
      >
        {controls.length === 0 && <div>No controls added</div>}
        {controls.map((control, i) => (
          <ControlRow
            key={`${control.documentId}`}
            control={control}
            baseCss={baseCss}
            isEditableChecked={false}
            showTooltip={showTooltip}
            editSectionHandler={editSectionHandler}
            dragAllowed={dragAllowed}
            dropAllowed={dropAllowed}
          />
        ))}
      </AdoptechAccordionCard>
    </Accordion>
  );
};
