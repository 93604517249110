/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubCompanyUpdatePayloadTrusthubCompany,
  TrusthubCompanyUpdatePayloadTrusthubCompanyFromJSON,
  TrusthubCompanyUpdatePayloadTrusthubCompanyFromJSONTyped,
  TrusthubCompanyUpdatePayloadTrusthubCompanyToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubCompanyUpdatePayload
 */
export interface TrusthubCompanyUpdatePayload {
  /**
   *
   * @type {TrusthubCompanyUpdatePayloadTrusthubCompany}
   * @memberof TrusthubCompanyUpdatePayload
   */
  trusthubCompany: TrusthubCompanyUpdatePayloadTrusthubCompany;
}

export function TrusthubCompanyUpdatePayloadFromJSON(
  json: any
): TrusthubCompanyUpdatePayload {
  return TrusthubCompanyUpdatePayloadFromJSONTyped(json, false);
}

export function TrusthubCompanyUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubCompanyUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    trusthubCompany: TrusthubCompanyUpdatePayloadTrusthubCompanyFromJSON(
      json['trusthub_company']
    ),
  };
}

export function TrusthubCompanyUpdatePayloadToJSON(
  value?: TrusthubCompanyUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    trusthub_company: TrusthubCompanyUpdatePayloadTrusthubCompanyToJSON(
      value.trusthubCompany
    ),
  };
}
