import { Interweave } from 'interweave';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getQuestionLastUpdatedInfo } from '../../functions/getQuestionLastUpdatedInfo';
import {
  patchAssessmentResponse,
  confirmAssessmentResponse,
  deleteAssessmentResponseAnswers,
} from '../../store/assessments/assessmentsThunks';
import {
  AssessmentResponseAnswer,
  AssessmentResponseAnswerSourceTypeEnum,
  AssessmentResponseAnswerStatusEnum,
  Question,
} from '../../swagger';
import { AssessmentResponseLibraryPopup } from '../AssessmentResponseLibraryPopup/AssessmentResponseLibraryPopup';
import { AssessmentResponseHistoryPopup } from '../AssessmentResponseHistoryPopup/AssessmentResponseHistoryPopup';
import { LastUpdatedInfo } from '../LastUpdatedInfo/LastUpdatedInfo';
import { AnswerFormControl } from '../AnswerFormControl/AnswerFormControl';
import './AssessmentQuestion.scss';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { QuestionType } from '../../types/QuestionType';
import getTableQuestionsAndAnswers from '../../functions/getTableQuestionsAndAnswers';
import doTableAnswersExist from '../../functions/doTableAnswersExist';
import getCheckboxAnswers from '../../functions/getCheckboxAnswers';
import { updateAnswer } from '../../store/assessments/assessmentsSlice';
import { isQuestionConfirmed } from '../AssessmentSummaryTile/AssessmentSummaryTile';

interface AssessmentQuestionProps {
  assessmentResponseId?: string;
  question: Question;
  questions?: Question[];
  answers?: AssessmentResponseAnswer[];
  readonly?: boolean;
  showHistory?: boolean;
  vendorId?: string;
}

export const AssessmentQuestion: React.FC<AssessmentQuestionProps> = ({
  assessmentResponseId,
  question,
  questions,
  answers,
  readonly,
  showHistory,
  vendorId,
}) => {
  const dispatch = useDispatch();
  const isCheckboxAnswer = question.layout === 'check_boxes';
  const isTableAnswer = question.layout === 'table_layout';
  const previousAnswer = useMemo(() => {
    return (answers || []).find(
      (answer: AssessmentResponseAnswer) =>
        (answer.name || answer.propertyName) === question.name
    );
  }, [answers]);

  const indexProperty = 'name';
  const isReadonly = readonly || previousAnswer?.readOnly;
  const [lastUpdatedAnswers, setLastUpdatedAnswers] =
    useState<AssessmentResponseAnswer[]>(null);

  const tableQuestions: QuestionType[] = useMemo(() => {
    if (isTableAnswer) {
      return getTableQuestionsAndAnswers({
        question,
        questions,
        answers,
        indexProperty,
      });
    }
    return null;
  }, [question, questions, answers, indexProperty]);

  const checkboxAnswers = useMemo(
    () => getCheckboxAnswers(question?.questionRows, answers),
    [question?.questionRows, answers]
  );

  const isConfirmed = isQuestionConfirmed({ question, answers, questions });
  const isConfirmedButtonDisabled = useMemo(() => {
    if (isTableAnswer) {
      return !doTableAnswersExist(tableQuestions);
    }
    if (isCheckboxAnswer) {
      return !checkboxAnswers?.length;
    }
    return !previousAnswer;
  }, [
    isTableAnswer,
    tableQuestions,
    previousAnswer,
    isCheckboxAnswer,
    checkboxAnswers,
  ]);

  const lastUpdatedInfo = getQuestionLastUpdatedInfo(
    question,
    answers,
    'name',
    isConfirmed,
    true
  );

  const handleChange = (
    assessmentResponseAnswers: AssessmentResponseAnswer[]
  ) => {
    assessmentResponseAnswers.forEach(ara => {
      dispatch(updateAnswer(ara));
      if (!ara?.sourceType)
        ara.sourceType =
          AssessmentResponseAnswerSourceTypeEnum.AssessmentResponse;
    });
    setLastUpdatedAnswers(assessmentResponseAnswers);
  };

  const handleConfirm = () => {
    if (!isTableAnswer) {
      const ids = isCheckboxAnswer
        ? checkboxAnswers?.map(answer => answer.id)
        : [previousAnswer.id];
      return dispatch(confirmAssessmentResponse(ids));
    }

    const tableAnswers: AssessmentResponseAnswer[] = [];
    tableQuestions.forEach((questionType: QuestionType) => {
      tableAnswers.push(...questionType.answers);
    });

    dispatch(
      patchAssessmentResponse({
        id: assessmentResponseId,
        assessmentResponse: {
          assessmentResponseAnswers: tableAnswers.map(answer => ({
            name: answer.name,
            value: answer.value,
            index: answer.index,
            datatype: answer.datatype,
            status: AssessmentResponseAnswerStatusEnum.Confirmed,
          })),
        },
        mode: 'table',
      })
    );
  };

  const handleAnswerClick = (
    library: AssessmentResponseAnswer[],
    entry: AssessmentResponseAnswer
  ) => {
    const assessmentResponseAnswers = isTableAnswer ? library : [entry];

    const updateAnswers = () =>
      dispatch(
        patchAssessmentResponse({
          id: assessmentResponseId,
          assessmentResponse: {
            assessmentResponseAnswers: assessmentResponseAnswers.map(
              answer => ({
                name: answer.name,
                value: answer.value,
                index: answer.index,
                datatype: answer.datatype,
              })
            ),
          },
          mode: isTableAnswer ? 'table' : null,
        })
      );

    if (!isTableAnswer) {
      return updateAnswers();
    }

    const tableAnswers: string[] = [];
    tableQuestions?.forEach((tableQuestion: QuestionType) => {
      tableQuestion.answers?.length &&
        tableAnswers.push(...tableQuestion.answers.map(answer => answer.id));
    });
    if (tableAnswers?.length) {
      dispatch(
        deleteAssessmentResponseAnswers(tableAnswers, null, updateAnswers)
      );
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (lastUpdatedAnswers) {
        dispatch(
          patchAssessmentResponse({
            id: assessmentResponseId,
            assessmentResponse: {
              assessmentResponseAnswers: lastUpdatedAnswers,
            },
          })
        );
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [lastUpdatedAnswers]);

  return (
    <div className="assessmentQuestion">
      <div className="assessmentQuestion--header">
        <Interweave content={question.questionTextAsHtml} />
        {showHistory && (
          <AssessmentResponseHistoryPopup
            assessmentResponseId={assessmentResponseId}
            previousAnswer={previousAnswer}
            question={question}
            questions={questions}
          />
        )}
      </div>
      <div className="assessmentQuestion--text">
        <Interweave content={question.explanatoryTextAsHtml} />
      </div>
      <div className="assessmentQuestion--answerArea">
        <AnswerFormControl
          question={question}
          questions={questions}
          answers={answers}
          onChange={handleChange}
          disableDebounce
          previousAnswer={previousAnswer}
          indexProperty={indexProperty}
          readonly={isReadonly || (previousAnswer && previousAnswer.readOnly)}
          asTextReadonly={previousAnswer?.readOnly} // readonly and as text input
          vendorId={vendorId}
          responseId={assessmentResponseId}
        />
      </div>
      <div className="assessmentQuestion--footer">
        <div className="assessmentQuestion--invisibleElement" />
        <LastUpdatedInfo
          includeConfirmation
          isConfirmed={isConfirmed}
          lastUpdatedInfo={lastUpdatedInfo}
        />
        <div className="assessmentQuestion--actionButtons">
          {!isCheckboxAnswer && (
            <AssessmentResponseLibraryPopup
              assessmentResponseId={assessmentResponseId}
              previousAnswer={previousAnswer}
              question={question}
              questions={questions}
              onAnswerClick={handleAnswerClick}
            />
          )}
          {!isReadonly &&
            (isConfirmed ? (
              <AdoptechButton
                extraClass="assessmentQuestion--confirmed"
                icon={faCheck}
                variant={AdoptechButtonVariant.SuccessFilled}
              >
                Confirmed
              </AdoptechButton>
            ) : (
              <AdoptechButton
                extraClass="assessmentQuestion--confirmBtn"
                disabled={isConfirmedButtonDisabled}
                onClick={handleConfirm}
                variant={AdoptechButtonVariant.Default}
              >
                Confirm
              </AdoptechButton>
            ))}
        </div>
      </div>
    </div>
  );
};

AssessmentQuestion.defaultProps = {
  answers: [],
  questions: [],
};
