import { VendorPolicyDetails, Answer, AnswerFromJSON } from '../swagger';

export const mapPolicyTable = (
  policy: VendorPolicyDetails
): VendorPolicyDetails => {
  const copiedPolicy: VendorPolicyDetails = JSON.parse(JSON.stringify(policy));
  copiedPolicy.vendorPolicyClauses.forEach(clause =>
    clause.questions.forEach(question => {
      if (question.table) {
        const answers = question.table.answers
          ? (question.table.answers as Answer[][]).map(answersRow =>
              answersRow.map(answer => AnswerFromJSON(answer))
            )
          : undefined;

        if (answers.length) {
          question.table.answers = answers;
        }
      }
    })
  );
  return copiedPolicy;
};
