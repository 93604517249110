/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  FrameworkModelStats,
  FrameworkModelStatsFromJSON,
  FrameworkModelStatsFromJSONTyped,
  FrameworkModelStatsToJSON,
} from './';

/**
 *
 * @export
 * @interface FrameworkModel
 */
export interface FrameworkModel {
  /**
   *
   * @type {string}
   * @memberof FrameworkModel
   */
  id: string | null;
  /**
   *
   * @type {string}
   * @memberof FrameworkModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkModel
   */
  identifier?: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkModel
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof FrameworkModel
   */
  descriptionAsHtml: string | null;
  /**
   *
   * @type {number}
   * @memberof FrameworkModel
   */
  rowOrder: number | null;
  /**
   *
   * @type {FrameworkModelStats}
   * @memberof FrameworkModel
   */
  stats?: FrameworkModelStats;
  /**
   *
   * @type {string}
   * @memberof FrameworkModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkModel
   */
  updatedAt: string;
}

export function FrameworkModelFromJSON(json: any): FrameworkModel {
  return FrameworkModelFromJSONTyped(json, false);
}

export function FrameworkModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    identifier: !exists(json, 'identifier') ? undefined : json['identifier'],
    description: json['description'],
    descriptionAsHtml: json['description_as_html'],
    rowOrder: json['row_order'],
    stats: !exists(json, 'stats')
      ? undefined
      : FrameworkModelStatsFromJSON(json['stats']),
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function FrameworkModelToJSON(value?: FrameworkModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    identifier: value.identifier,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
    row_order: value.rowOrder,
    stats: FrameworkModelStatsToJSON(value.stats),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
