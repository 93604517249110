/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReminderUpdatePayloadReminder
 */
export interface ReminderUpdatePayloadReminder {
  /**
   *
   * @type {string}
   * @memberof ReminderUpdatePayloadReminder
   */
  remindAt?: string;
}

export function ReminderUpdatePayloadReminderFromJSON(
  json: any
): ReminderUpdatePayloadReminder {
  return ReminderUpdatePayloadReminderFromJSONTyped(json, false);
}

export function ReminderUpdatePayloadReminderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReminderUpdatePayloadReminder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    remindAt: !exists(json, 'remind_at') ? undefined : json['remind_at'],
  };
}

export function ReminderUpdatePayloadReminderToJSON(
  value?: ReminderUpdatePayloadReminder | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    remind_at: value.remindAt,
  };
}
