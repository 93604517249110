import { faCircleXmark } from '@fortawesome/pro-light-svg-icons/faCircleXmark';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentAgreement } from '../../../../../selectors/selectCurrentAgreement';
import { selectCurrentVendor } from '../../../../../selectors/selectCurrentVendor';
import { fetchShares } from '../../../../../store/vendors/vendorsThunks';
import {
  ShareableType,
  SharesListData,
  SharesListDataAccessLevelEnum,
} from '../../../../../swagger';
import { ApplicationState } from '../../../../../types/applicationState';
import { LoadingSpinner } from '../../../../LoadingSpinner/LoadingSpinner';
import { UserAvatar } from '../../../../UserAvatar/UserAvatar';
import AdoptechOverflowLine from '../../../../AdoptechOverflowLine/AdoptechOverflowLine';

export const DocumentPermissionsSection: React.FC = () => {
  const baseCss = 'editAgreementForm';
  const agreement = useSelector(selectCurrentAgreement);
  const currentVendor = useSelector(selectCurrentVendor);
  const sharesList = useSelector(
    (state: ApplicationState) => state.vendors.sharesList?.data || []
  );
  const isFetchingSharesList = useSelector(
    (state: ApplicationState) => state.vendors.isFetchingSharesList
  );

  useEffect(() => {
    dispatch(
      fetchShares({
        id: currentVendor.id,
        shareableType: ShareableType.Agreement,
        shareableId: agreement.id,
      })
    );
  }, []);

  const dispatch = useDispatch();

  const accessLevelIcon = (
    share: SharesListData,
    level: SharesListDataAccessLevelEnum
  ) => {
    if (share.accessLevel === level) {
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          className={`${baseCss}--greenTick`}
        />
      );
    }
    const color =
      level === SharesListDataAccessLevelEnum.Contributor ? 'red' : 'grey';
    return (
      <FontAwesomeIcon
        icon={faCircleXmark}
        className={`${baseCss}--${color}Tick`}
      />
    );
  };

  if (isFetchingSharesList) return <LoadingSpinner />;

  return (
    <>
      <div className={baseCss + '--section-labels'}>
        <div className={baseCss + '--section-label'}>Document permissions</div>
      </div>
      <div
        className={classNames(
          `${baseCss}--table-headers`,
          `${baseCss}--4-columns`,
          'mt-2'
        )}
      >
        <div className={baseCss + '--label'}>Name</div>
        <div className={baseCss + '--label'}>Manager</div>
        <div className={baseCss + '--label'}>Contributor</div>
        <div className={baseCss + '--label'}>Viewer</div>
      </div>
      {sharesList.map(share => {
        return (
          <div key={share.user?.id}>
            <div
              className={classNames(
                `${baseCss}--table-row`,
                `${baseCss}--4-columns`,
                'mt-1',
                'pb-2'
              )}
            >
              <AdoptechOverflowLine className={baseCss + '--table-column'}>
                <UserAvatar user={share.user} size="small" />
              </AdoptechOverflowLine>
              <div className={baseCss + '--table-column'}>
                {accessLevelIcon(share, SharesListDataAccessLevelEnum.Manager)}
              </div>
              <div className={baseCss + '--table-column'}>
                {accessLevelIcon(
                  share,
                  SharesListDataAccessLevelEnum.Contributor
                )}
              </div>
              <div className={baseCss + '--table-column'}>
                {accessLevelIcon(share, SharesListDataAccessLevelEnum.Viewer)}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
