/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VendorUserStatus {
  Invited = 'invited',
  Active = 'active',
  Deleted = 'deleted',
}

export function VendorUserStatusFromJSON(json: any): VendorUserStatus {
  return VendorUserStatusFromJSONTyped(json, false);
}

export function VendorUserStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorUserStatus {
  return json as VendorUserStatus;
}

export function VendorUserStatusToJSON(value?: VendorUserStatus | null): any {
  return value as any;
}
