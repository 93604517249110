/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FrameworkRelationFramework
 */
export interface FrameworkRelationFramework {
  /**
   *
   * @type {string}
   * @memberof FrameworkRelationFramework
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkRelationFramework
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkRelationFramework
   */
  identifier?: string;
}

export function FrameworkRelationFrameworkFromJSON(
  json: any
): FrameworkRelationFramework {
  return FrameworkRelationFrameworkFromJSONTyped(json, false);
}

export function FrameworkRelationFrameworkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkRelationFramework {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    identifier: !exists(json, 'identifier') ? undefined : json['identifier'],
  };
}

export function FrameworkRelationFrameworkToJSON(
  value?: FrameworkRelationFramework | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    identifier: value.identifier,
  };
}
