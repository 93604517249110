/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReferenceCreatePayloadReference
 */
export interface ReferenceCreatePayloadReference {
  /**
   *
   * @type {string}
   * @memberof ReferenceCreatePayloadReference
   */
  referenceableId: string;
  /**
   *
   * @type {string}
   * @memberof ReferenceCreatePayloadReference
   */
  referenceableType: ReferenceCreatePayloadReferenceReferenceableTypeEnum;
}

export function ReferenceCreatePayloadReferenceFromJSON(
  json: any
): ReferenceCreatePayloadReference {
  return ReferenceCreatePayloadReferenceFromJSONTyped(json, false);
}

export function ReferenceCreatePayloadReferenceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReferenceCreatePayloadReference {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    referenceableId: json['referenceable_id'],
    referenceableType: json['referenceable_type'],
  };
}

export function ReferenceCreatePayloadReferenceToJSON(
  value?: ReferenceCreatePayloadReference | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    referenceable_id: value.referenceableId,
    referenceable_type: value.referenceableType,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ReferenceCreatePayloadReferenceReferenceableTypeEnum {
  Risk = 'Risk',
  Control = 'Control',
  LegalRegister = 'LegalRegister',
  PestelRegister = 'PestelRegister',
  Task = 'Task',
}
