import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { getApiErrorMessage } from '../../functions/getApiErrorMessage';
import { UNKNOWN_ERROR } from '../../types/constants';
import { setErrorMessage } from '../global/globalSlice';

export const getErrorMessage = async (err: any, specificMessage: string) => {
  let errorFromResponse = ''; // Help troubleshooting
  let userMessage = specificMessage ?? UNKNOWN_ERROR; // Keep it user friendly
  if (err instanceof Response) {
    errorFromResponse = await getApiErrorMessage(err);
    if (!errorFromResponse) {
      errorFromResponse = `API error - Status: ${err.status} Message: ${err.statusText}`;
    } else {
      userMessage = errorFromResponse;
    }
  } else {
    errorFromResponse = specificMessage ?? UNKNOWN_ERROR;
  }
  console.log(errorFromResponse);

  return userMessage;
};

export const handleThunkError = async (
  text: string,
  {
    dispatch,
    error,
    showError = true,
  }: {
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
    error: any;
    showError?: boolean;
  }
) => {
  const apiErrorMessage = await getErrorMessage(error, text);
  console.error(error);
  showError && dispatch(setErrorMessage(apiErrorMessage));
  throw new Error(apiErrorMessage);
};
