import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideWarningSubscriptionLimitModal } from '../../../store/vendors/vendorsSlice';
import { ApplicationState } from '../../../types/applicationState';
import { ExitConfirmationModal } from '../../../components/ExitConfirmationModal/ExitConfirmationModal';
import { manageSubscriptionRoute } from '../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import { AdoptechButtonVariant } from '../../../components/AdoptechButton/AdoptechButton';

export const WarningSubscriptionLimitModal = () => {
  const isShowing = useSelector(
    (state: ApplicationState) =>
      state.vendors.isWarningSubscriptionLimitModalShowing
  );

  const dispatch = useDispatch();

  if (!isShowing) return null;
  return (
    <ExitConfirmationModal
      title="User limit reached"
      text="You have reached the maximum number of users permitted within your subscription plan"
      submitButtonText="View subscription plans"
      submitButtonVariant={AdoptechButtonVariant.WhiteOnDarkOrange}
      onConfirm={() => {
        dispatch(hideWarningSubscriptionLimitModal());
        dispatch(push(manageSubscriptionRoute));
      }}
      onCancel={() => dispatch(hideWarningSubscriptionLimitModal())}
    />
  );
};
