import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideUploadLogoModal } from '../../store/vendors/vendorsSlice';
import { uploadCompanyLogo } from '../../store/vendors/vendorsThunks';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { DropZone } from '../DropZone/DropZone';
import './UploadLogoModal.scss';

interface UploadLogoModalProps {
  vendorId: string;
}

export const UploadLogoModal: React.FC<UploadLogoModalProps> = ({
  vendorId,
}) => {
  const [file, setFile] = useState<File>();

  const isUploadLogoModalShowing = useSelector(
    (state: ApplicationState) =>
      state.vendors.companyDetailsState.isUploadLogoModalShowing
  );
  const isLogoUploading = useSelector(
    (state: ApplicationState) =>
      state.vendors.companyDetailsState.isLogoUploading
  );

  const dispatch = useDispatch();

  const handleUpload = () => {
    dispatch(uploadCompanyLogo({ vendorId, file }));
  };

  return (
    <AdoptechModal
      onHide={() => dispatch(hideUploadLogoModal())}
      show={isUploadLogoModalShowing}
      className="uploadLogoModal"
    >
      <Modal.Header>Upload company logo</Modal.Header>
      <Modal.Body>
        <div className="adoptechModal--subtitle">
          This will be applied to documents that you create.
        </div>
        <div className="uploadLogoModal--dropZone">
          <DropZone
            accept=".jpg,.jpeg,.png"
            onChange={setFile}
            additionalDescription="We accept .jpg and .png formats and files should be no larger than 2MB."
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={() => dispatch(hideUploadLogoModal())}>
          Cancel
        </AdoptechButton>
        <AdoptechButton
          busy={isLogoUploading}
          disabled={!file}
          variant={AdoptechButtonVariant.Primary}
          onClick={handleUpload}
        >
          Upload
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
