/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ShareableType {
  Agreement = 'Agreement',
  Assessment = 'Assessment',
  AssessmentResponse = 'AssessmentResponse',
  Audit = 'Audit',
  Checklist = 'Checklist',
  ChecklistTemplate = 'ChecklistTemplate',
  DataRoomEntity = 'DataRoomEntity',
  VendorDocument = 'VendorDocument',
  VendorPolicy = 'VendorPolicy',
}

export function ShareableTypeFromJSON(json: any): ShareableType {
  return ShareableTypeFromJSONTyped(json, false);
}

export function ShareableTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShareableType {
  return json as ShareableType;
}

export function ShareableTypeToJSON(value?: ShareableType | null): any {
  return value as any;
}
