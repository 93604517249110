import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import React, {
  CSSProperties,
  SyntheticEvent,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetScrollCardIntoView,
  scrollToY,
} from '../../store/global/globalSlice';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import './AdoptechAccordionCard.scss';

export interface AdoptechAccordionCardProps {
  hasDoneButton?: boolean;
  index: string;
  nested?: boolean;
  nestedVerticalOffset?: number;
  noMargin?: boolean;
  headerOffset?: number;
  title: any;
  cardBodyClass?: string;
  headerClass?: string;
  iconSize?: string;
  callbackOnOpen?: (i: string) => void;
  shouldShowCoachmark?: boolean;
  style?: CSSProperties;
  scrollDisabled?: boolean;
  handleDone?: () => void;
  triggerClick?: boolean;
  hasError?: boolean;

  // Needed to customize click flow:
  // on body click run headerHandleClick() without default behavior,
  // on arrow icon click run default behavior ( collapse accordion )
  headerHandleClick?: () => void;
}

export const AdoptechAccordionCard = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<AdoptechAccordionCardProps>
>((props, ref) => {
  const {
    children,
    index,
    nested,
    nestedVerticalOffset,
    noMargin,
    headerOffset,
    title,
    cardBodyClass,
    headerClass,
    iconSize,
    hasDoneButton,
    callbackOnOpen,
    shouldShowCoachmark,
    style,
    scrollDisabled,
    handleDone,
    hasError,
    headerHandleClick,
  } = props;
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === index;

  useEffect(() => {
    if (isCurrentEventKey && callbackOnOpen) {
      callbackOnOpen(currentEventKey);
    }
  }, [isCurrentEventKey]);

  const cardRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => toggleRef.current);

  const handleDoneClick = () => {
    toggleRef.current?.click();
    handleDone && handleDone();
  };

  const { scrollCardIntoView } = useSelector(
    (state: ApplicationState) => state.global
  );

  const dispatch = useDispatch();

  const defaultAccordionToggle = useAccordionToggle(
    index,
    (event: SyntheticEvent) => {
      event.stopPropagation();
      setTimeout(() => {
        scroll();
      }, 250);
    }
  );

  useEffect(() => {
    if (scrollCardIntoView === index) {
      scroll();
      dispatch(resetScrollCardIntoView());
    }
  }, [scrollCardIntoView]);

  const scroll = () => {
    if (scrollDisabled) return;
    if (!nested) {
      cardRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      dispatch(scrollToY(nestedVerticalOffset + cardRef.current.offsetTop));
    }
  };

  const [showCoachmark, setShowCoachmark] = useState(false);
  const [cookies, setCookies] = useCookies(['firstPolicyOpened']);

  const handleCoachmarkClose = () => {
    setShowCoachmark(false);
    setCookies('firstPolicyOpened', true, {
      expires: moment().add(10, 'years').toDate(),
    });
    toggleRef.current.click();
  };

  useEffect(() => {
    if (!cookies.firstPolicyOpened) {
      setShowCoachmark(true);
    }
  }, [cookies.firstPolicyOpened]);

  return (
    <div
      className={classNames({
        adoptechAccordionCard: true,
        'adoptechAccordionCard--error': hasError,
      })}
      ref={cardRef}
      style={style}
    >
      <Card className={classNames({ expanded: isCurrentEventKey, noMargin })}>
        <Card.Header
          as={Card.Header}
          // eventKey={index} Warning: React does not recognize eventKey
          onClick={headerHandleClick || defaultAccordionToggle}
          ref={toggleRef}
          style={{ top: headerOffset || 0 }}
          className={headerClass}
        >
          {title}
          <FontAwesomeIcon
            className={`adoptechAccordionCard--angleIcon ${
              iconSize === 'small' ? 'small--icon' : ''
            }`}
            icon={faAngleDown}
            onClick={defaultAccordionToggle}
          />
        </Card.Header>
        <Accordion.Collapse eventKey={index}>
          <Card.Body ref={bodyRef} className={cardBodyClass}>
            <>
              {children}
              {hasDoneButton && isCurrentEventKey && (
                <div className="adoptechAccordionCard--doneButton">
                  <AdoptechButton
                    variant={AdoptechButtonVariant.Primary}
                    onClick={handleDoneClick}
                  >
                    Done
                  </AdoptechButton>
                </div>
              )}
            </>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      {shouldShowCoachmark && showCoachmark && (
        <div className="adoptechAccordionCard--tooltip">
          <div className="adoptechAccordionCard--tooltip-pointer"></div>
          Complete each section to generate the policy text.
          <div
            className="adoptechAccordionCard--tooltip-button"
            onClick={handleCoachmarkClose}
          >
            GOT IT
          </div>
        </div>
      )}
    </div>
  );
});

AdoptechAccordionCard.defaultProps = {
  nested: false,
  nestedVerticalOffset: 0,
};
