import { createSelector } from '@reduxjs/toolkit';
import { AssessmentRecipient, AssessmentResponse } from '../swagger';
import { ApplicationState } from '../types/applicationState';
import { COMPLETED_TEXT } from '../types/constants';

export const selectAllAssessmentPendingResponses = createSelector(
  (state: ApplicationState) => {
    const currentAssessment = state.assessments.currentAssessment;
    const incomingAssessmentResponses =
      state.assessments.incomingAssessmentResponses;
    return { incomingAssessmentResponses, currentAssessment };
  },

  ({
    incomingAssessmentResponses,
    currentAssessment,
  }): (AssessmentRecipient & AssessmentResponse)[] => {
    if (!incomingAssessmentResponses) {
      return null;
    }

    return currentAssessment.assessmentRecipients
      .map(response => ({
        ...response,
        ...incomingAssessmentResponses.find(
          i => i.vendorId === response.vendorId
        ),
      }))
      .filter(
        response => !response.status || response.status !== COMPLETED_TEXT
      );
  }
);
