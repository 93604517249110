/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorEventEventType,
  VendorEventEventTypeFromJSON,
  VendorEventEventTypeFromJSONTyped,
  VendorEventEventTypeToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface CalendarItemModel
 */
export interface CalendarItemModel {
  /**
   *
   * @type {string}
   * @memberof CalendarItemModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CalendarItemModel
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof CalendarItemModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CalendarItemModel
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof CalendarItemModel
   */
  status: CalendarItemModelStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CalendarItemModel
   */
  date: string;
  /**
   *
   * @type {VendorEventEventType}
   * @memberof CalendarItemModel
   */
  eventType: VendorEventEventType;
  /**
   *
   * @type {VendorUser}
   * @memberof CalendarItemModel
   */
  owner?: VendorUser;
}

export function CalendarItemModelFromJSON(json: any): CalendarItemModel {
  return CalendarItemModelFromJSONTyped(json, false);
}

export function CalendarItemModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CalendarItemModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: json['type'],
    name: json['name'],
    description: json['description'],
    status: json['status'],
    date: json['date'],
    eventType: VendorEventEventTypeFromJSON(json['event_type']),
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
  };
}

export function CalendarItemModelToJSON(value?: CalendarItemModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    name: value.name,
    description: value.description,
    status: value.status,
    date: value.date,
    event_type: VendorEventEventTypeToJSON(value.eventType),
    owner: VendorUserToJSON(value.owner),
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CalendarItemModelStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Overdue = 'overdue',
}
