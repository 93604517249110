/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes,
  TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesFromJSON,
  TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesFromJSONTyped,
  TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubSectionUpdatePayloadTrusthubSection
 */
export interface TrusthubSectionUpdatePayloadTrusthubSection {
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionUpdatePayloadTrusthubSection
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionUpdatePayloadTrusthubSection
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TrusthubSectionUpdatePayloadTrusthubSection
   */
  visible?: boolean;
  /**
   *
   * @type {Array<TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes>}
   * @memberof TrusthubSectionUpdatePayloadTrusthubSection
   */
  trusthubDocumentsAttributes?: Array<TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributes>;
}

export function TrusthubSectionUpdatePayloadTrusthubSectionFromJSON(
  json: any
): TrusthubSectionUpdatePayloadTrusthubSection {
  return TrusthubSectionUpdatePayloadTrusthubSectionFromJSONTyped(json, false);
}

export function TrusthubSectionUpdatePayloadTrusthubSectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubSectionUpdatePayloadTrusthubSection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    visible: !exists(json, 'visible') ? undefined : json['visible'],
    trusthubDocumentsAttributes: !exists(json, 'trusthub_documents_attributes')
      ? undefined
      : (json['trusthub_documents_attributes'] as Array<any>).map(
          TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesFromJSON
        ),
  };
}

export function TrusthubSectionUpdatePayloadTrusthubSectionToJSON(
  value?: TrusthubSectionUpdatePayloadTrusthubSection | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    visible: value.visible,
    trusthub_documents_attributes:
      value.trusthubDocumentsAttributes === undefined
        ? undefined
        : (value.trusthubDocumentsAttributes as Array<any>).map(
            TrusthubSectionUpdatePayloadTrusthubSectionTrusthubDocumentsAttributesToJSON
          ),
  };
}
