/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum AttestationFrequencyEnum {
  _5Days = '5 days',
  _10Days = '10 days',
  _15Days = '15 days',
  _20Days = '20 days',
  _30Days = '30 days',
  _60Days = '60 days',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Annually = 'annually',
}

export function AttestationFrequencyEnumFromJSON(
  json: any
): AttestationFrequencyEnum {
  return AttestationFrequencyEnumFromJSONTyped(json, false);
}

export function AttestationFrequencyEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AttestationFrequencyEnum {
  return json as AttestationFrequencyEnum;
}

export function AttestationFrequencyEnumToJSON(
  value?: AttestationFrequencyEnum | null
): any {
  return value as any;
}
