/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CompanyIdentifierCreatePayload
 */
export interface CompanyIdentifierCreatePayload {
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierCreatePayload
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierCreatePayload
   */
  type: CompanyIdentifierCreatePayloadTypeEnum;
}

export function CompanyIdentifierCreatePayloadFromJSON(
  json: any
): CompanyIdentifierCreatePayload {
  return CompanyIdentifierCreatePayloadFromJSONTyped(json, false);
}

export function CompanyIdentifierCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyIdentifierCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    identifier: json['identifier'],
    type: json['type'],
  };
}

export function CompanyIdentifierCreatePayloadToJSON(
  value?: CompanyIdentifierCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    identifier: value.identifier,
    type: value.type,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CompanyIdentifierCreatePayloadTypeEnum {
  Ukcrn = 'CompanyIdentifier::Ukcrn',
  Ein = 'CompanyIdentifier::Ein',
  Srn = 'CompanyIdentifier::Srn',
  Siren = 'CompanyIdentifier::Siren',
  Siret = 'CompanyIdentifier::Siret',
  Other = 'CompanyIdentifier::Other',
}
