import React from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { clearAutoSave } from '../../store/policies/policiesSlice';
import { ApplicationState } from '../../types/applicationState';
import './AutoSaveToast.scss';

export const AutoSaveToast: React.FC = () => {
  const show = useSelector(
    (state: ApplicationState) => state.policies.showAutoSaveToast
  );

  const dispatch = useDispatch();

  return (
    <div className="autoSaveToast">
      <Toast
        autohide
        delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
        onClose={() => {
          dispatch(clearAutoSave());
        }}
        show={show}
      >
        <Toast.Body>Autosave complete</Toast.Body>
      </Toast>
    </div>
  );
};
