/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Policy
 */
export interface Policy {
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  categoryName?: string;
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  productName?: string;
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  summary?: string | null;
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  summaryAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  descriptionAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  requiredPlan?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Policy
   */
  vendorPolicyExists?: boolean;
}

export function PolicyFromJSON(json: any): Policy {
  return PolicyFromJSONTyped(json, false);
}

export function PolicyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Policy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    code: !exists(json, 'code') ? undefined : json['code'],
    categoryName: !exists(json, 'category_name')
      ? undefined
      : json['category_name'],
    productName: !exists(json, 'product_name')
      ? undefined
      : json['product_name'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    summaryAsHtml: !exists(json, 'summary_as_html')
      ? undefined
      : json['summary_as_html'],
    description: !exists(json, 'description') ? undefined : json['description'],
    descriptionAsHtml: !exists(json, 'description_as_html')
      ? undefined
      : json['description_as_html'],
    requiredPlan: !exists(json, 'required_plan')
      ? undefined
      : json['required_plan'],
    vendorPolicyExists: !exists(json, 'vendor_policy_exists')
      ? undefined
      : json['vendor_policy_exists'],
  };
}

export function PolicyToJSON(value?: Policy | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    code: value.code,
    category_name: value.categoryName,
    product_name: value.productName,
    summary: value.summary,
    summary_as_html: value.summaryAsHtml,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
    required_plan: value.requiredPlan,
    vendor_policy_exists: value.vendorPolicyExists,
  };
}
