/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChecklistCheckable
 */
export interface ChecklistCheckable {
  /**
   *
   * @type {string}
   * @memberof ChecklistCheckable
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistCheckable
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistCheckable
   */
  displayName: string;
}

export function ChecklistCheckableFromJSON(json: any): ChecklistCheckable {
  return ChecklistCheckableFromJSONTyped(json, false);
}

export function ChecklistCheckableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistCheckable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: json['type'],
    displayName: json['display_name'],
  };
}

export function ChecklistCheckableToJSON(
  value?: ChecklistCheckable | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    display_name: value.displayName,
  };
}
