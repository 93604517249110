import { ApplicationState } from '../types/applicationState';

export const selectCurrentAgreement = (state: ApplicationState) => {
  const {
    agreements,
    currentAgreementId,
    currentAgreement: agreementOnShowPage,
  } = state.agreements;

  const { pathname } = state.router.location;

  const agreementOnIndexPage = (agreements || []).find(
    agreement => agreement.id === currentAgreementId
  );

  // replace all slashes or # in the end
  return pathname.replaceAll(/[^\w]/g, '').endsWith('agreements')
    ? agreementOnIndexPage
    : agreementOnShowPage;
};
