/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ControlReviewFrequencyEnum {
  _1Month = '1 month',
  _3Months = '3 months',
  _6Months = '6 months',
  _9Months = '9 months',
  _12Months = '12 months',
  _18Months = '18 months',
  _24Months = '24 months',
}

export function ControlReviewFrequencyEnumFromJSON(
  json: any
): ControlReviewFrequencyEnum {
  return ControlReviewFrequencyEnumFromJSONTyped(json, false);
}

export function ControlReviewFrequencyEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlReviewFrequencyEnum {
  return json as ControlReviewFrequencyEnum;
}

export function ControlReviewFrequencyEnumToJSON(
  value?: ControlReviewFrequencyEnum | null
): any {
  return value as any;
}
