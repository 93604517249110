import { ChecklistCheckable, ChecklistCheckableTypeEnum } from '../swagger';

export const getChecklistAssociatedUsername = (
  checkable: ChecklistCheckable
): string => {
  if (checkable && checkable.type === ChecklistCheckableTypeEnum.VendorUser) {
    return checkable.displayName;
  }
  return '';
};
