import React from 'react';
import { AdoptechRadioButtonsGroup } from '../../AdoptechRadioButtonsGroup/AdoptechRadioButtonsGroup';
import { ReportSectionProps } from './ReportsManageDrawer';
import { ReportCreatePayloadReportVersionBumpEnum } from '../../../swagger/models/ReportCreatePayloadReport';

export const ReportVersionField: React.FC<ReportSectionProps> = ({
  formData,
  updateField,
  updateForm,
}) => {
  const baseCss = 'reportsManageDrawer';
  return (
    <>
      <div className={baseCss + '--fieldRow'}>
        <div className={baseCss + '--label'}>
          Would you like this to be a formal version controlled document?
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechRadioButtonsGroup
            // value can be null because radio not selected by default
            value={
              formData.hasVersion
                ? 'yes'
                : formData.hasVersion === false
                  ? 'no'
                  : null
            }
            values={['yes', 'no']}
            labels={['Yes', 'No']}
            onChange={(value: 'yes' | 'no') => {
              updateForm({
                hasVersion: value === 'yes',
                versionBump:
                  value === 'yes'
                    ? ReportCreatePayloadReportVersionBumpEnum.Minor
                    : null,
              });
            }}
          />
        </div>
      </div>
      {formData.versionBump && (
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--label'}>
            Please select whether this is a minor or major version.
          </div>
          <div className={baseCss + '--field'}>
            <AdoptechRadioButtonsGroup
              value={formData.versionBump}
              values={[
                ReportCreatePayloadReportVersionBumpEnum.Minor,
                ReportCreatePayloadReportVersionBumpEnum.Major,
              ]}
              labels={[
                'Minor (e.g. 1.1, 1.2, 1.3)',
                'Major (e.g. 1.0, 2.0, 3.0) ',
              ]}
              onChange={value => {
                updateField('versionBump', value);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
