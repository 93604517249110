import React, { useEffect, useState } from 'react';
import { IncidentEventModel } from '../../../swagger';
import { IncidentFormType } from '../CalendarPage/CalendarPageModals';
import { useOwnerOptions } from '../../../hooks/useOwnerOptions';
import { ApplicationState } from '../../../types/applicationState';
import { useSelector } from 'react-redux';
import AdoptechModal from '../../../components/AdoptechModal/AdoptechModal';
import { Modal } from 'react-bootstrap';
import './EditIncidentModal.scss';
import { fetchIntegrations } from '../../../store/integrations/integrationsSlice';
import { fetchIncident } from '../store/calendarThunks';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { useCanFeature } from '../../../functions/access';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { AccessObject } from '../../../types/accessObject';
import { AdoptechTextInput2 } from '../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { AdoptechTextArea2 } from '../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { AdoptechReactSelect2 } from '../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechDatePicker2 } from '../../../components/AdoptechDatePicker2/AdoptechDatePicker2';
import moment from 'moment';
import { SelectionOption } from '../../../types/selectionOption';
import CalendarAttachments from '../EditEventModal/CalendarAttachments/CalendarAttachments';

type Model = IncidentEventModel;
type FormType = IncidentFormType;

interface Props {
  init?: Model['id'];
  close: () => void;
  confirm: (params: FormType) => void;
  show: boolean;
  onDelete?: (id: Model['id']) => void;
}

const baseCss = 'editIncidentModal';

export const EditIncidentModal: React.FC<Props> = ({
  init = undefined,
  confirm = () => {},
  onDelete = () => {},
  close,
  show,
}) => {
  const [model, setModel] = useState({ id: init } as IncidentFormType);

  const modalTitle = model.id ? 'Edit Incident' : 'Create Incident';
  const { users, ownerOptions: userOptions } = useOwnerOptions({
    includingGuest: true,
  });
  const actionName = model.id ? 'Save' : 'Create';
  const {
    createIncidentStatus,
    fetchIncidentStatus,
    updateIncidentStatus,
    deleteIncidentStatus,
  } = useSelector((state: ApplicationState) => state.calendar);
  const isDeletingCalendarItem = deleteIncidentStatus === 'loading';
  const isFetchingCalendarItem = fetchIncidentStatus === 'loading';
  const isUpdatingCalendarItem = updateIncidentStatus === 'loading';
  const isCreatingCalendarItem = createIncidentStatus === 'loading';
  const dispatch = useAppDispatch();
  const isLoading = isFetchingCalendarItem || isDeletingCalendarItem;
  const fetchIncidentAsync = async (id: string) => {
    const action = await dispatch(fetchIncident(id)).unwrap();

    setModel(action);
  };

  const { errorMessage } = useSelector(
    (state: ApplicationState) => state.global
  );

  useEffect(() => {
    errorMessage &&
      errorMessage.includes('ID entered') &&
      setModel({
        // highlight field on BE unique error
        ...model,
        identifier: null,
      });
  }, [errorMessage]);

  useEffect(() => {
    model.id && fetchIncidentAsync(model.id);
  }, [model.id]);
  const canManageCalendar = useCanFeature(AccessObject.calendar_manage);
  const readonlyMode = !canManageCalendar;
  const hasNameError = !model.name;
  const hasDescriptionError = !model.description;
  const hasIdentifierError = !model.identifier;
  const hasDateError = !model.dateIdentified;
  const hasOwnerError = !model.owner;
  const hasRaisedByError = !model.raisedBy;
  const hasRootCauseError = !model.rootCause;
  const hasActionsTakenError = !model.actionsTaken;
  const hasPreventativeActionsError = !model.preventativeActions;
  const noDataBreachChosen = model.dataBreach === undefined;
  const hasDataBreachError = noDataBreachChosen;
  // require details only if data breach chosen yes
  const hasDataBreachDetailsError = noDataBreachChosen
    ? false
    : model.dataBreach
      ? !model.dataBreachDetails
      : false;

  const dataMissing =
    hasNameError ||
    hasDescriptionError ||
    hasIdentifierError ||
    hasDateError ||
    hasOwnerError ||
    hasRaisedByError ||
    hasRootCauseError ||
    hasActionsTakenError ||
    hasPreventativeActionsError ||
    hasDataBreachError ||
    hasDataBreachDetailsError;

  const deadline = model.dateIdentified ? new Date(model.dateIdentified) : null;

  const dataBreachOptions: SelectionOption[] = [
    { label: 'yes', value: 'yes' },
    { label: 'no', value: 'no' },
  ];
  return (
    <AdoptechModal className={baseCss} onHide={close} show={show}>
      <Modal.Header>
        <div>{modalTitle}</div>
        {model.id && (
          <AdoptechButton
            extraClass={model.completed ? 'completedButton' : 'pendingButton'}
          >
            {model.completed ? 'Completed' : 'Pending'}
          </AdoptechButton>
        )}
      </Modal.Header>
      <Modal.Body>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <div className={baseCss + '--body'}>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextInput2
                  id="createIncidentName"
                  rounded
                  label="Title"
                  type="text"
                  value={model.name}
                  onChange={e =>
                    setModel({ ...model, name: e.currentTarget.value })
                  }
                  hasError={hasNameError}
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextArea2
                  rounded
                  id="createIncidentDescription"
                  label="Describe this Incident"
                  value={model.description}
                  onChange={e =>
                    setModel({ ...model, description: e.currentTarget.value })
                  }
                  icon={faPen}
                  hasError={hasDescriptionError}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechReactSelect2
                  placeholder="Please choose..."
                  rounded
                  label="Owner"
                  id="createIncidentOwner"
                  options={userOptions}
                  hasError={hasOwnerError}
                  onChange={e => {
                    setModel({
                      ...model,
                      owner: users.find(user => user.id === e.value),
                    });
                  }}
                  value={userOptions.find(
                    option => option.value == model.owner?.id
                  )}
                  isDisabled={readonlyMode}
                />
              </div>
              <div className={baseCss + '--field'}>
                <AdoptechTextInput2
                  id={baseCss + '--incident-id'}
                  rounded
                  label="ID"
                  type="text"
                  value={model.identifier}
                  placeholder="For Example INC1"
                  onChange={e =>
                    setModel({ ...model, identifier: e.currentTarget.value })
                  }
                  hasError={hasIdentifierError}
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechDatePicker2
                  id="createIncidentDate"
                  rounded
                  labelText="Date identified"
                  placeHolderText="Select a date"
                  minDate={null}
                  outputDate={deadline}
                  hasError={hasDateError}
                  onDateSelect={d => {
                    const endOfDay = moment(d).endOf('day');
                    const endOfDayToDateTime = endOfDay.toJSON();

                    setModel({
                      ...model,
                      dateIdentified: endOfDayToDateTime,
                    });
                  }}
                  disabled={readonlyMode}
                />
              </div>
            </div>

            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextArea2
                  id="createIncidentRaisedBy"
                  rounded
                  label="Who/how was the issue raised?"
                  value={model.raisedBy}
                  onChange={e =>
                    setModel({ ...model, raisedBy: e.currentTarget.value })
                  }
                  hasError={hasRaisedByError}
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextArea2
                  id="createIncidentRootCause"
                  rounded
                  label="Detail the root cause"
                  value={model.rootCause}
                  onChange={e =>
                    setModel({ ...model, rootCause: e.currentTarget.value })
                  }
                  hasError={hasRootCauseError}
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextArea2
                  id="createIncidentActions"
                  rounded
                  label="List the actions taken to bring the Incident under control in chronological order"
                  value={model.actionsTaken}
                  onChange={e =>
                    setModel({ ...model, actionsTaken: e.currentTarget.value })
                  }
                  hasError={hasActionsTakenError}
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextArea2
                  id="createIncidentPreventativeActions"
                  rounded
                  label="Detail the actions implemented to prevent recurrence"
                  value={model.preventativeActions}
                  onChange={e =>
                    setModel({
                      ...model,
                      preventativeActions: e.currentTarget.value,
                    })
                  }
                  hasError={hasPreventativeActionsError}
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechReactSelect2
                  id={baseCss}
                  options={dataBreachOptions}
                  rounded
                  label="Was there a data breach?"
                  isDisabled={readonlyMode}
                  isClearable={false}
                  hasError={hasDataBreachError}
                  onChange={option =>
                    setModel({
                      ...model,
                      dataBreach: option.value === 'yes',
                    })
                  }
                  value={
                    hasDataBreachError
                      ? undefined
                      : dataBreachOptions.filter(
                          option =>
                            option.value === (model.dataBreach ? 'yes' : 'no')
                        )
                  }
                  placeholder="Please choose"
                />
              </div>
            </div>

            {model.dataBreach && (
              <div className={baseCss + '--fieldRow'}>
                <div className={baseCss + '--field'}>
                  <AdoptechTextArea2
                    id="createIncidentDataBreachDetails"
                    rounded
                    label="Detail when you contacted the authorities and data subjects in chronological order"
                    value={model.dataBreachDetails}
                    onChange={e =>
                      setModel({
                        ...model,
                        dataBreachDetails: e.currentTarget.value,
                      })
                    }
                    hasError={hasDataBreachDetailsError}
                    icon={faPen}
                    disabled={readonlyMode}
                  />
                </div>
              </div>
            )}
            <CalendarAttachments
              baseCss={baseCss}
              model={model}
              setModel={setModel}
              readonlyMode={readonlyMode}
              type="incident"
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {model.id ? (
          <div>
            <AdoptechButton
              disabled={dataMissing}
              onClick={() => {
                const data = { ...model, completed: !model.completed };
                confirm(data);
              }}
            >
              {model.completed ? 'Mark as pending' : 'Mark as completed'}
            </AdoptechButton>

            <AdoptechButton
              extraClass={baseCss + '--deleteButton'}
              icon={faTrashAlt}
              onClick={() => onDelete(model.id)}
            >
              Delete
            </AdoptechButton>
          </div>
        ) : (
          <AdoptechButton
            onClick={() => {
              close();
            }}
          >
            Cancel
          </AdoptechButton>
        )}
        <AdoptechButton
          disabled={dataMissing || readonlyMode}
          busy={isCreatingCalendarItem || isUpdatingCalendarItem}
          onClick={() => confirm(model)}
          variant={AdoptechButtonVariant.Primary}
        >
          {actionName}
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
