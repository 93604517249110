/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Officer
 */
export interface Officer {
  /**
   *
   * @type {string}
   * @memberof Officer
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Officer
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Officer
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof Officer
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof Officer
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof Officer
   */
  dateOfBirth?: string | null;
  /**
   *
   * @type {string}
   * @memberof Officer
   */
  nationality?: string | null;
  /**
   *
   * @type {string}
   * @memberof Officer
   */
  countryOfResidence?: string | null;
  /**
   *
   * @type {string}
   * @memberof Officer
   */
  occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof Officer
   */
  appointedOn?: string | null;
  /**
   *
   * @type {string}
   * @memberof Officer
   */
  ceasedOn?: string | null;
}

export function OfficerFromJSON(json: any): Officer {
  return OfficerFromJSONTyped(json, false);
}

export function OfficerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Officer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    role: !exists(json, 'role') ? undefined : json['role'],
    status: !exists(json, 'status') ? undefined : json['status'],
    address: !exists(json, 'address') ? undefined : json['address'],
    dateOfBirth: !exists(json, 'date_of_birth')
      ? undefined
      : json['date_of_birth'],
    nationality: !exists(json, 'nationality') ? undefined : json['nationality'],
    countryOfResidence: !exists(json, 'country_of_residence')
      ? undefined
      : json['country_of_residence'],
    occupation: !exists(json, 'occupation') ? undefined : json['occupation'],
    appointedOn: !exists(json, 'appointed_on')
      ? undefined
      : json['appointed_on'],
    ceasedOn: !exists(json, 'ceased_on') ? undefined : json['ceased_on'],
  };
}

export function OfficerToJSON(value?: Officer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    role: value.role,
    status: value.status,
    address: value.address,
    date_of_birth: value.dateOfBirth,
    nationality: value.nationality,
    country_of_residence: value.countryOfResidence,
    occupation: value.occupation,
    appointed_on: value.appointedOn,
    ceased_on: value.ceasedOn,
  };
}
