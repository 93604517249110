/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LegislationUpdatePayloadLegislationReviews
 */
export interface LegislationUpdatePayloadLegislationReviews {
  /**
   *
   * @type {string}
   * @memberof LegislationUpdatePayloadLegislationReviews
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof LegislationUpdatePayloadLegislationReviews
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof LegislationUpdatePayloadLegislationReviews
   */
  reviewDate: string;
}

export function LegislationUpdatePayloadLegislationReviewsFromJSON(
  json: any
): LegislationUpdatePayloadLegislationReviews {
  return LegislationUpdatePayloadLegislationReviewsFromJSONTyped(json, false);
}

export function LegislationUpdatePayloadLegislationReviewsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LegislationUpdatePayloadLegislationReviews {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    userId: json['user_id'],
    reviewDate: json['review_date'],
  };
}

export function LegislationUpdatePayloadLegislationReviewsToJSON(
  value?: LegislationUpdatePayloadLegislationReviews | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    user_id: value.userId,
    review_date: value.reviewDate,
  };
}
