import { VendorDocumentApproval } from '../swagger';
import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { approvalStatusSort } from './approvalStatusSort';
import { sort } from './sort';

export const approvalSort = (
  a: VendorDocumentApproval,
  b: VendorDocumentApproval,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof VendorDocumentApproval =
    columnSort.name as keyof VendorDocumentApproval;

  switch (typedColumn) {
    case 'status':
      return approvalStatusSort(a.status, b.status, direction);
    default:
      return sort(a[typedColumn], b[typedColumn], direction);
  }
};
