import { createSelector } from '@reduxjs/toolkit';
import { AgreementsMode } from '../components/Agreements/AgreementsList/AgreementsList';
import { agreementSort } from '../functions/aggrementSort';
import { AgreementModel, AgreementModelStatusEnum } from '../swagger';
import { Grid } from '../types/grid';
import { selectAwaitingSignatureAgreements } from './selectAwaitingSignatureAgreements';
import { selectCompletedAgreements } from './selectCompletedAgreements';
import { selectDraftAgreements } from './selectDraftAgreements';
import { selectSortSettings } from './selectSortSettings';

const agreementsSelector = (mode: AgreementsMode) => {
  let selector;

  switch (mode) {
    case AgreementsMode.Draft:
      selector = selectDraftAgreements;
      break;
    case AgreementsMode.AwaitingSignature:
      selector = selectAwaitingSignatureAgreements;
      break;
    case AgreementsMode.Completed:
      selector = selectCompletedAgreements;
      break;
  }
  return selector;
};

export const selectAgreementMode = (agreement: AgreementModel) => {
  switch (agreement.status) {
    case AgreementModelStatusEnum.Draft:
      return AgreementsMode.Draft;
      break;
    case AgreementModelStatusEnum.Signed:
      return AgreementsMode.Completed;
      break;
    default:
      return AgreementsMode.AwaitingSignature;
  }
};

export const selectAgreements = (mode: AgreementsMode, currentGrid: Grid) => {
  const selector = agreementsSelector(mode);

  return createSelector(
    selector,
    selectSortSettings(currentGrid),
    (agreements: AgreementModel[], sortSetting) => {
      if (!agreements?.length) {
        return [];
      }
      return [...agreements].sort((a, b) =>
        agreementSort(a, b, sortSetting.columnSort)
      );
    }
  );
};
