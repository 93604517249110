import { createSelector } from '@reduxjs/toolkit';

import Fuse from 'fuse.js';
import { PestelItemModel } from '../swagger';
import { ApplicationState } from '../types/applicationState';

const filterPestels = (items: PestelItemModel[], search: string) => {
  if (!items || items?.length < 1) return [];

  const fuseTask = new Fuse(items, {
    ignoreLocation: true,
    includeScore: true,
    keys: [
      'description',
      'mitigationFactors',
      'reviewDetails',
      'consequence',
      'likelihood',
      'itemType',
    ],
    threshold: 0,
  });
  return fuseTask.search(search).map(x => x.item);
};

export const selectFilteredPestels = (searchText: string) =>
  createSelector(
    (state: ApplicationState) => state.pestel.pestelItems,
    (items: PestelItemModel[]) => {
      if (!searchText) return items;
      else return filterPestels(items, searchText);
    }
  );
