import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuditExtended,
  Answer,
  AuditModel,
  AuditReviewHistoryItem,
  AuditTemplateExtended,
  AuditTemplateModel,
  Question,
  QuestionTable,
  VendorUser,
} from '../../../../swagger';
import { updateAnswerSlice } from '../../../../store/agreements/agreementsSlice';
import { AuditsState, AuditReviewHistory } from './auditsState';
import { globalUserId } from '../../../../hooks/useOwnerOptions';
import { postMeeting } from './auditsThunks';

type AnswersPayload = {
  id: Question['id'];
  answers: QuestionTable['answers'];
};

const initialState = {
  isFetchingAudits: false,
  isFetchingAuditTemplates: false,
  isFetchingCurrentAuditTemplate: false,
  audits: [],
  templates: [],
  completedFilter: true,
  currentTemplate: null,
  currentAudit: null,
  auditQuestionIdToShowHistory: null,
  auditReviewHistory: [],
  postMeetingStatus: 'idle',
} as AuditsState;

const auditsSlice = createSlice({
  initialState,
  name: 'auditsSlice',
  reducers: {
    fetchAuditsRequest: state => {
      state.isFetchingAudits = true;
    },
    fetchAuditTemplatesRequest: state => {
      state.isFetchingAuditTemplates = true;
    },
    fetchCurrentAuditTemplateRequest: state => {
      state.isFetchingCurrentAuditTemplate = true;
    },
    fetchAuditsSuccess: (state, action: PayloadAction<AuditModel[]>) => {
      state.isFetchingAudits = false;
      state.audits = action.payload;
    },
    fetchAuditReviewHistoryRequest: state => {
      state.isFetchingAuditReviewHistory = true;
    },
    fetchAuditReviewHistorySuccess: (
      state,
      action: PayloadAction<AuditReviewHistory>
    ) => {
      state.isFetchingAuditReviewHistory = false;
      state.auditReviewHistory.push(action.payload);
    },
    fetchAuditReviewHistoryError: state => {
      state.isFetchingAuditReviewHistory = false;
    },
    cleanupAuditReviewHistory: (
      state,
      action: PayloadAction<Question['id']>
    ) => {
      state.auditQuestionIdToShowHistory = null;
      state.auditReviewHistory = state.auditReviewHistory.filter(
        h => h.questionId != action.payload
      );
    },
    showHistoryForQuestionId: (state, action: PayloadAction<string>) => {
      state.auditQuestionIdToShowHistory = action.payload;
    },
    setAuditsError: (state, _) => {
      state.isFetchingAudits = false;
    },
    fetchAuditTemplatesSuccess: (
      state,
      action: PayloadAction<AuditTemplateModel[]>
    ) => {
      state.isFetchingAuditTemplates = false;
      state.templates = action.payload;
    },
    fetchCurrentAuditTemplateSuccess: (
      state,
      action: PayloadAction<AuditTemplateExtended>
    ) => {
      state.isFetchingCurrentAuditTemplate = false;
      state.currentTemplate = action.payload;
    },
    cleanUpCurrentTemplate: state => {
      state.currentTemplate = null;
    },
    createAuditRequest: state => {
      state.isCreatingAudit = true;
    },
    createAuditSuccess: state => {
      state.isCreatingAudit = false;
    },
    updateAuditRequest: state => {
      state.isUpdatingAudit = true;
    },
    updateAuditSuccess: state => {
      state.isUpdatingAudit = false;
    },
    toggleCompletedFilter: state => {
      state.completedFilter = !state.completedFilter;
    },
    cleanupAudits: state => {
      state.completedFilter = initialState.completedFilter;
      state.audits = initialState.audits;
    },
    deleteAuditRequest: state => {
      state.isDeletingAudit = true;
    },
    deleteAuditSuccess: (state, action: PayloadAction<AuditModel['id']>) => {
      state.isDeletingAudit = false;
      state.audits = state.audits.filter(audit => audit.id !== action.payload);
      state.auditIdToDelete = null;
    },
    deleteAuditError: state => {
      state.isDeletingAudit = false;
      state.auditIdToDelete = null;
    },
    setAuditIdToBeDeleted: (state, action: PayloadAction<AuditModel['id']>) => {
      state.auditIdToDelete = action.payload;
    },

    fetchAuditPDFRequest: state => {
      state.isFetchingAuditPDF = true;
    },
    fetchAuditPDFSuccess: state => {
      state.isFetchingAuditPDF = false;
    },
    fetchAuditPDFError: state => {
      state.isFetchingAuditPDF = false;
    },
    setCurrentAudit: (state, action: PayloadAction<AuditExtended>) => {
      state.currentAudit = action.payload;
    },
    fetchAuditRequest: (state, action: PayloadAction<boolean>) => {
      state.isFetchingAudit = true;
      state.isFetchingAuditSpinner = action.payload;
    },
    fetchAuditSuccess: (state, action: PayloadAction<AuditExtended>) => {
      state.currentAudit = action.payload;
      state.isFetchingAudit = false;
      state.isFetchingAuditSpinner = false;
    },
    fetchAuditError: state => {
      state.isFetchingAudit = false;
      state.isFetchingAuditSpinner = false;
    },
    completeAuditRequest: state => {
      state.isCompletingAudit = true;
    },
    completeAuditSuccess: state => {
      state.isCompletingAudit = false;
      state.completedFilter = true;
    },
    completeAuditError: state => {
      state.isCompletingAudit = false;
    },
    updateAnswer: (state, action: PayloadAction<Answer>) => {
      updateAnswerSlice(state.currentAudit, action);
    },
    postAuditAnswerRequest: state => {
      state.isPostingAuditAnswer = true;
    },
    postAuditAnswerSuccess: state => {
      state.isPostingAuditAnswer = false;
    },
    postAuditAnswerError: state => {
      state.isPostingAuditAnswer = false;
    },
    deleteAuditAnswerRequest: state => {
      state.isDeletingAuditAnswer = true;
    },
    deleteAuditAnswerSuccess: state => {
      state.isDeletingAuditAnswer = false;
    },
    deleteAuditAnswerError: state => {
      state.isDeletingAuditAnswer = false;
    },
    setAuditQuestionTableAnswers: (
      state,
      action: PayloadAction<AnswersPayload>
    ) => {
      const clauses = state.currentAudit.survey.surveyClauses;
      const answersPayload = action.payload;
      const currentClause = clauses.find(clause =>
        clause.questions.map(q => q.id).includes(answersPayload.id)
      );
      const currentQuestion = currentClause.questions.find(
        question => question.id === answersPayload.id
      );
      currentQuestion.answer = answersPayload.answers[0][0];
      currentQuestion.table.answers = answersPayload.answers;
    },
    setAuditQuestionTableAnswer: (
      state,
      action: PayloadAction<{
        userId: VendorUser['id'];
        questionId: Question['id'];
        review: AuditReviewHistoryItem;
      }>
    ) => {
      const currentQuestion = state.currentAudit.survey.surveyClauses
        .map(c => c.questions)
        .reduce((acc, qs) => acc.concat(qs), [])
        .find(q => q.id === action.payload.questionId);
      const questions = currentQuestion.table.questions;
      const review = action.payload.review;

      const userId = globalUserId({ id: action.payload.userId });

      const auditorQuestion = questions.find(q =>
        q.propertyName.endsWith('.auditor_name')
      );
      const ratingQuestion = questions.find(q =>
        q.propertyName.endsWith('.rating')
      );
      const commentsQuestion = questions.find(q =>
        q.propertyName.endsWith('.comments')
      );

      const answers: Answer[] = [
        {
          questionId: auditorQuestion.id,
          propertyName: auditorQuestion.propertyName,
          value: userId,
        },
        {
          questionId: ratingQuestion.id,
          propertyName: ratingQuestion.propertyName,
          value: review.rating,
        },
        {
          questionId: commentsQuestion.id,
          propertyName: commentsQuestion.propertyName,
          value: review.comments,
        },
      ];

      // Remove existing answer of current user
      currentQuestion.table.answers = currentQuestion.table.answers.filter(
        answerColumns => !answerColumns.some(column => column.value === userId)
      );

      currentQuestion.table.answers.push(answers);
    },
    fetchAnswerControlRequest: (state, action: PayloadAction<boolean>) => {
      state.isFetchingAnswerControl = true;
    },
    fetchAnswerControlSuccess: state => {
      state.isFetchingAnswerControl = false;
    },
    fetchAnswerControlError: state => {
      state.isFetchingAnswerControl = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(postMeeting.pending, state => {
        state.postMeetingStatus = 'loading';
      })
      .addCase(postMeeting.fulfilled, (state, action) => {
        state.postMeetingStatus = 'succeeded';
      })
      .addCase(postMeeting.rejected, state => {
        state.postMeetingStatus = 'failed';
      });
  },
});
export const {
  fetchAuditsRequest,
  fetchAuditsSuccess,
  setAuditsError,
  fetchAuditTemplatesSuccess,
  fetchAuditTemplatesRequest,
  fetchAuditReviewHistoryRequest,
  fetchAuditReviewHistorySuccess,
  fetchAuditReviewHistoryError,
  cleanupAuditReviewHistory,
  showHistoryForQuestionId,
  fetchCurrentAuditTemplateRequest,
  fetchCurrentAuditTemplateSuccess,
  createAuditRequest,
  createAuditSuccess,
  updateAuditRequest,
  updateAuditSuccess,
  cleanUpCurrentTemplate,
  toggleCompletedFilter,
  deleteAuditRequest,
  deleteAuditSuccess,
  deleteAuditError,
  setAuditIdToBeDeleted,
  fetchAuditPDFRequest,
  fetchAuditPDFSuccess,
  fetchAuditPDFError,
  setCurrentAudit,
  fetchAuditRequest,
  fetchAuditSuccess,
  fetchAuditError,
  completeAuditRequest,
  completeAuditSuccess,
  completeAuditError,
  updateAnswer,
  postAuditAnswerRequest,
  postAuditAnswerSuccess,
  postAuditAnswerError,
  deleteAuditAnswerRequest,
  deleteAuditAnswerSuccess,
  deleteAuditAnswerError,
  setAuditQuestionTableAnswers,
  setAuditQuestionTableAnswer,
  cleanupAudits,
  fetchAnswerControlRequest,
  fetchAnswerControlSuccess,
  fetchAnswerControlError,
} = auditsSlice.actions;

export default auditsSlice.reducer;
