/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  RiskUpdatePayloadRisk,
  RiskUpdatePayloadRiskFromJSON,
  RiskUpdatePayloadRiskFromJSONTyped,
  RiskUpdatePayloadRiskToJSON,
} from './';

/**
 *
 * @export
 * @interface RiskUpdatePayload
 */
export interface RiskUpdatePayload {
  /**
   *
   * @type {RiskUpdatePayloadRisk}
   * @memberof RiskUpdatePayload
   */
  risk: RiskUpdatePayloadRisk;
}

export function RiskUpdatePayloadFromJSON(json: any): RiskUpdatePayload {
  return RiskUpdatePayloadFromJSONTyped(json, false);
}

export function RiskUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    risk: RiskUpdatePayloadRiskFromJSON(json['risk']),
  };
}

export function RiskUpdatePayloadToJSON(value?: RiskUpdatePayload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    risk: RiskUpdatePayloadRiskToJSON(value.risk),
  };
}
