import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCompany } from '../../selectors/selectCurrentCompany';
import {
  cancelAddCompanyOfficeAddressModal,
  hideOfficeAddressAddedToast,
} from '../../store/vendors/vendorsSlice';
import { updateOfficeAddress } from '../../store/vendors/vendorsThunks';
import { Office } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { AdoptechCountrySelect } from '../AdoptechCountrySelect/AdoptechCountrySelect';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechStateSelect } from '../AdoptechStateSelect/AdoptechStateSelect';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import { MessageToast } from '../MessageToast/MessageToast';
import './AddOfficeAddressModal.scss';

export const AddOfficeAddressModal: React.FC = () => {
  const company = useSelector(selectCurrentCompany);

  const showModal = useSelector(
    (state: ApplicationState) =>
      state.vendors.companyDetailsState.isAddOfficeAddressModalShowing
  );
  const showToast = useSelector(
    (state: ApplicationState) =>
      state.vendors.companyDetailsState.showAddedToast
  );

  const [currentOffice, setCurrentOffice] = useState<Office>();
  const [formTouched, setFormTouchedStatus] = useState<boolean>(false);

  const addressValid = !!(currentOffice?.address1 || currentOffice?.address2);
  const formValid = !!(
    currentOffice?.name &&
    (currentOffice?.address1 || currentOffice?.address2)
  );

  const dispatch = useDispatch();

  const save = () => {
    if (!formTouched) {
      setFormTouchedStatus(true);
    }

    if (formValid) {
      dispatch(
        updateOfficeAddress({
          company,
          office: currentOffice,
          isExisting: false,
          onSuccess: () => {
            clearForm();
          },
        })
      );
    }
  };

  const setAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    setCurrentOffice(office => ({
      ...office,
      [id]: value,
    }));
  };

  const setCountry = (option: SelectionOption) => {
    setCurrentOffice(office => ({
      ...office,
      country: option.value,
    }));
  };

  const setState = (option: SelectionOption) => {
    setCurrentOffice(office => ({
      ...office,
      state: option.value,
    }));
  };

  const handleCancel = () => {
    dispatch(cancelAddCompanyOfficeAddressModal());
    clearForm();
  };

  const clearForm = () => {
    setCurrentOffice(null);
    setFormTouchedStatus(false);
  };

  return (
    <React.Fragment>
      <AdoptechModal
        className="addOfficeAddressModal"
        show={showModal}
        onHide={clearForm}
      >
        <Modal.Header>Add office location</Modal.Header>
        <Modal.Body>
          <div className="adoptechModal--subtitle">
            Please add your office location below.
          </div>
          <div className="addOfficeAddressModal--form">
            <AdoptechTextInput
              value={currentOffice?.name}
              hasError={formTouched && !currentOffice?.name}
              id="name"
              type="text"
              onChange={setAddress}
              placeholder="Location Name"
            />
            <AdoptechTextInput
              value={currentOffice?.address1}
              hasError={formTouched && !addressValid}
              id="address1"
              placeholder="Address line 1"
              type="text"
              onChange={setAddress}
            />
            <AdoptechTextInput
              value={currentOffice?.address2}
              hasError={formTouched && !addressValid}
              id="address2"
              placeholder="Address line 2"
              type="text"
              onChange={setAddress}
            />
            {currentOffice?.country === 'US' && (
              <AdoptechStateSelect
                value={currentOffice?.state}
                placeholder="Please select state ..."
                onChange={setState}
              />
            )}
            {currentOffice?.country !== 'US' && (
              <AdoptechTextInput
                value={currentOffice?.state}
                id="state"
                placeholder="County / State"
                type="text"
                onChange={setAddress}
              />
            )}
            <AdoptechTextInput
              value={currentOffice?.postCode}
              id="postCode"
              placeholder="Postcode / Zip Code"
              type="text"
              onChange={setAddress}
            />
            <AdoptechCountrySelect
              value={currentOffice?.country}
              onChange={setCountry}
              placeholder="Country"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={handleCancel}>Cancel</AdoptechButton>
          <AdoptechButton
            onClick={save}
            variant={AdoptechButtonVariant.Primary}
            disabled={formTouched && !formValid}
          >
            Add
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
      <MessageToast
        autohide
        delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
        onClose={() => dispatch(hideOfficeAddressAddedToast())}
        show={showToast}
      >
        Your new location has been added.
      </MessageToast>
    </React.Fragment>
  );
};
