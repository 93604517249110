/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AssertionResultModel
 */
export interface AssertionResultModel {
  /**
   *
   * @type {string}
   * @memberof AssertionResultModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AssertionResultModel
   */
  status: AssertionResultModelStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof AssertionResultModel
   */
  excluded: boolean;
  /**
   *
   * @type {string}
   * @memberof AssertionResultModel
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof AssertionResultModel
   */
  comment: string | null;
  /**
   *
   * @type {string}
   * @memberof AssertionResultModel
   */
  payload: string;
  /**
   *
   * @type {string}
   * @memberof AssertionResultModel
   */
  message: string | null;
  /**
   *
   * @type {string}
   * @memberof AssertionResultModel
   */
  reasonForExclusion?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssertionResultModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AssertionResultModel
   */
  updatedAt: string;
}

export function AssertionResultModelFromJSON(json: any): AssertionResultModel {
  return AssertionResultModelFromJSONTyped(json, false);
}

export function AssertionResultModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionResultModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: json['status'],
    excluded: json['excluded'],
    key: json['key'],
    comment: json['comment'],
    payload: json['payload'],
    message: json['message'],
    reasonForExclusion: !exists(json, 'reason_for_exclusion')
      ? undefined
      : json['reason_for_exclusion'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function AssertionResultModelToJSON(
  value?: AssertionResultModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: value.status,
    excluded: value.excluded,
    key: value.key,
    comment: value.comment,
    payload: value.payload,
    message: value.message,
    reason_for_exclusion: value.reasonForExclusion,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AssertionResultModelStatusEnum {
  Compliant = 'compliant',
  Failed = 'failed',
}
