import React from 'react';
import { useSelector } from 'react-redux';
import { AssessmentPublishedModal } from '../AssessmentPublishedModal/AssessmentPublishedModal';
import './AssessmentsSummary.scss';
import { AssessmentTabs } from '../../store/assessments/assessmentsState';
import { AssessmentsIn } from '../AssessmentsIn/AssessmentsIn';
import { AssessmentsOut } from '../AssessmentsOut/AssessmentsOut';
import { selectAssessmentsTab } from '../../selectors/selectAssessmentsTab';

export const AssessmentsSummary: React.FC = () => {
  const tabToShow = useSelector(selectAssessmentsTab);

  return (
    <React.Fragment>
      {tabToShow == AssessmentTabs.Incoming ? (
        <AssessmentsIn />
      ) : (
        <AssessmentsOut />
      )}
      <AssessmentPublishedModal />
    </React.Fragment>
  );
};
