/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorUserCreatePayloadVendorUsers,
  VendorUserCreatePayloadVendorUsersFromJSON,
  VendorUserCreatePayloadVendorUsersFromJSONTyped,
  VendorUserCreatePayloadVendorUsersToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorUserCreatePayload
 */
export interface VendorUserCreatePayload {
  /**
   *
   * @type {Array<VendorUserCreatePayloadVendorUsers>}
   * @memberof VendorUserCreatePayload
   */
  vendorUsers: Array<VendorUserCreatePayloadVendorUsers>;
}

export function VendorUserCreatePayloadFromJSON(
  json: any
): VendorUserCreatePayload {
  return VendorUserCreatePayloadFromJSONTyped(json, false);
}

export function VendorUserCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorUserCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vendorUsers: (json['vendor_users'] as Array<any>).map(
      VendorUserCreatePayloadVendorUsersFromJSON
    ),
  };
}

export function VendorUserCreatePayloadToJSON(
  value?: VendorUserCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vendor_users: (value.vendorUsers as Array<any>).map(
      VendorUserCreatePayloadVendorUsersToJSON
    ),
  };
}
