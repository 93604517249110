import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setShowControlDrawer,
  setShowTaskDrawer,
} from '../../../../store/compliance/complianceSlice';
import { AuditExtended, ControlExtended } from '../../../../swagger';
import { SurveyClause } from '../../../../swagger/models/SurveyClause';
import { ApplicationState } from '../../../../types/applicationState';
import { AdoptechAccordionCard } from '../../../../components/AdoptechAccordionCard/AdoptechAccordionCard';
import { AgreementClauseHeader } from '../../../../components/Agreements/AgreementClauseHeader/AgreementClauseHeader';
import { useClauseProgress } from '../../../../components/Agreements/AgreementEditor/QuestionsMode/useClauseProgress';
import { ComplianceEditControlDrawer } from '../../controls/EditControlDrawer/ComplianceEditControlDrawer/ComplianceEditControlDrawer';
import { ComplianceEditTaskDrawer } from '../../controls/EditControlCheckDrawer/ComplianceEditTaskDrawer/ComplianceEditTaskDrawer';
import { AuditClauseQuestions } from './AuditClauseQuestions/AuditClauseQuestions';

export const QuestionsMode: React.FC = () => {
  const {
    currentModel,
    getFirstClauseWithQuestions,
    interactiveSurveyClauses,
    clauseProgress,
  } = useClauseProgress('audits');

  const { editControlFormModel, showControlDrawer, showTaskDrawer } =
    useSelector((state: ApplicationState) => state.compliance);

  const dispatch = useDispatch();
  return (
    <>
      <Accordion defaultActiveKey={getFirstClauseWithQuestions()}>
        {interactiveSurveyClauses.map((clause: SurveyClause, index) => (
          <AdoptechAccordionCard
            index={index.toString()}
            key={clause.id}
            title={
              <AgreementClauseHeader
                agreementClause={clause}
                clauseProgress={clauseProgress(clause)}
              />
            }
            nested
            nestedVerticalOffset={-245}
            headerOffset={101}
          >
            <AuditClauseQuestions
              audit={currentModel as AuditExtended}
              auditClause={clause}
            />
          </AdoptechAccordionCard>
        ))}
      </Accordion>
      <ComplianceEditControlDrawer
        show={showControlDrawer}
        control={editControlFormModel}
        onClose={() => dispatch(setShowControlDrawer(false))}
      />

      <ComplianceEditTaskDrawer
        show={showTaskDrawer}
        onClose={() => dispatch(setShowTaskDrawer(false))}
      />
    </>
  );
};
