import React, { ChangeEvent, useEffect, useRef } from 'react';
import { EditTestFormSection, isTestAutomatic } from '../EditTestForm';
import { useChildClass } from '../../../../../../../hooks/useChildClass';
import { Interweave } from 'interweave';
import { useSelector } from 'react-redux';
import { fetchAssertionCategories } from '../../../../../tests/store/testsThunks';
import { ApplicationState } from '../../../../../../../types/applicationState';
import { reactSelectLightStyleSmall } from '../../../../../../../functions/reactSelectCustomTheme';
import { AdoptechReactSelect } from '../../../../../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { SelectionOption } from '../../../../../../../types/selectionOption';
import { AssertionFrequency } from '../../../../../../../swagger';
import { LoadingSpinner } from '../../../../../../../components/LoadingSpinner/LoadingSpinner';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import { AdoptechTextArea2 } from '../../../../../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { TestForm } from '../../EditTestDrawer';
import { useAutoGrowTextArea } from '../../../../../../../hooks/useAutoGrowTextArea';

export const EditTestFormMainSection: React.FC<EditTestFormSection> = props => {
  const baseCss = 'editTestForm';
  const childClass = useChildClass(baseCss);
  const isAuto = isTestAutomatic(props.formData);
  const dispatch = useAppDispatch();
  const { patchAssertionDetailsStatus } = useSelector(
    (state: ApplicationState) => state.compliance
  );
  useEffect(() => {
    !isAuto && dispatch(fetchAssertionCategories());
  }, [isAuto]);

  const { assertionCategories: categories } = useSelector(
    (state: ApplicationState) => state.tests
  );

  const categoryOptions = categories.map(category => ({
    value: category.id,
    label: category.name,
  }));

  const enumToSelectOptions = <T extends Record<string, string>>(
    enumObject: T
  ) =>
    Object.keys(enumObject).map(key => ({
      label: key,
      value: enumObject[key as keyof T],
    }));
  const frequencyOptions = enumToSelectOptions(AssertionFrequency);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  useAutoGrowTextArea(descriptionRef.current, props.formData.description);

  if (isAuto)
    return (
      <div className={childClass('fieldBlock')}>
        <div className={childClass('fieldTitle')}>Test description</div>
        <Interweave content={props.formData.description} />

        <div className={childClass('fieldRow')}>
          <div className={childClass('fieldColumn')}>
            <div className={childClass('fieldTitle')}>Category</div>
            <div className={childClass('fieldUser')}>
              {categories.find(
                category => category.id === props.formData.categoryId
              )?.name || '-'}
            </div>
          </div>
        </div>
      </div>
    );

  const handleChange = (
    field: keyof TestForm,
    value: string | AssertionFrequency
  ) => {
    const patchPayload: Partial<TestForm> = {
      [field]: value,
    };

    props.onChange({
      ...props.formData,
      ...patchPayload,
    });
  };

  const handleCategoryChange = (option: SelectionOption) =>
    handleChange('categoryId', option.value);

  const handleFrequencyChange = async (option: SelectionOption) =>
    handleChange('frequency', option.value as AssertionFrequency);

  const handleDescriptionChange = async (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => handleChange('description', event.target.value);

  return (
    <div className={childClass('fieldBlock')}>
      <div className={childClass('fieldTitle')}>Test description</div>
      <div className={childClass('field')}>
        <AdoptechTextArea2
          rounded
          ref={descriptionRef}
          id="testDescription"
          value={props.formData.description}
          onChange={handleDescriptionChange}
          label=""
          rows={3}
        />
      </div>
      <div className={childClass('testTypeBlock')}>
        <div className={childClass('fieldColumn')}>
          <div className={childClass('fieldTitle')}>Test type</div>
          <div className={childClass('fieldUser') + ' d-flex'}>
            Manual
            {patchAssertionDetailsStatus === 'loading' && (
              <LoadingSpinner inlineSmall />
            )}
          </div>
        </div>
      </div>
      <div className={childClass('fieldRow')}>
        <div className={childClass('fieldColumn')}>
          <div className={childClass('fieldTitle')}>Category</div>
          <div className={childClass('fieldCategory')}>
            <AdoptechReactSelect
              id="testCategory"
              additionalStyling={reactSelectLightStyleSmall}
              options={categoryOptions}
              onChange={handleCategoryChange}
              value={categoryOptions.find(
                option => option.value === props.formData.categoryId
              )}
              placeholder="Select category"
            />
          </div>
        </div>
        <div className={childClass('fieldColumn')}>
          <div className={childClass('fieldTitle')}>Frequency</div>
          <div className={childClass('fieldFrequency')}>
            <AdoptechReactSelect
              id="testFrequency"
              additionalStyling={reactSelectLightStyleSmall}
              options={frequencyOptions}
              onChange={handleFrequencyChange}
              value={frequencyOptions.find(
                option => option.value === props.formData.frequency
              )}
              placeholder="Select frequency"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
