/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubSectionUpdatePayloadTrusthubSection,
  TrusthubSectionUpdatePayloadTrusthubSectionFromJSON,
  TrusthubSectionUpdatePayloadTrusthubSectionFromJSONTyped,
  TrusthubSectionUpdatePayloadTrusthubSectionToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubSectionUpdatePayload
 */
export interface TrusthubSectionUpdatePayload {
  /**
   *
   * @type {TrusthubSectionUpdatePayloadTrusthubSection}
   * @memberof TrusthubSectionUpdatePayload
   */
  trusthubSection: TrusthubSectionUpdatePayloadTrusthubSection;
}

export function TrusthubSectionUpdatePayloadFromJSON(
  json: any
): TrusthubSectionUpdatePayload {
  return TrusthubSectionUpdatePayloadFromJSONTyped(json, false);
}

export function TrusthubSectionUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubSectionUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    trusthubSection: TrusthubSectionUpdatePayloadTrusthubSectionFromJSON(
      json['trusthub_section']
    ),
  };
}

export function TrusthubSectionUpdatePayloadToJSON(
  value?: TrusthubSectionUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    trusthub_section: TrusthubSectionUpdatePayloadTrusthubSectionToJSON(
      value.trusthubSection
    ),
  };
}
