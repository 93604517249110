import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectApprovals } from '../../selectors/selectApprovals';
import { selectCurrentVendorUserId } from '../../selectors/selectCurrentVendorUserId';
import { showGlobalToast } from '../../store/global/globalSlice';
import { fetchNotifications } from '../../store/notifications/notificationsThunks';
import { closeApprovalManagement } from '../../store/policies/policiesSlice';
import {
  fetchDocumentApprovals,
  patchApproval,
  skipApproval,
} from '../../store/policies/policiesThunks';
import { VendorDocumentApproval } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { Grid } from '../../types/grid';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { AdoptechCheckbox } from '../AdoptechCheckbox/AdoptechCheckbox';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import { ApprovedStatus } from '../ApprovedStatus/ApprovedStatus';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { SortableTableHeader } from '../SortableTableHeader/SortableTableHeader';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import './ApprovalManagementModal.scss';
import { selectUsers } from '../../selectors/selectUsers';

export const ApprovalManagementModal: React.FC = () => {
  const isApprovalManagementModalShowing = useSelector(
    (state: ApplicationState) => state.policies.isApprovalManagementModalShowing
  );
  const vendorUserId = useSelector(selectCurrentVendorUserId);
  const isCancelable = useSelector(
    (state: ApplicationState) =>
      state.policies.isApprovalManagementModalCancelable
  );
  const isFetchingDocumentApprovals = useSelector(
    (state: ApplicationState) => state.policies.isFetchingDocumentApprovals
  );
  const isPostingApproval = useSelector(
    (state: ApplicationState) => state.policies.isPostingApproval
  );
  const isSkippingApproval = useSelector(
    (state: ApplicationState) => state.policies.isSkippingApproval
  );
  const id = useSelector(
    (state: ApplicationState) =>
      state.policies.approvalManagementVendorDocumentId
  );
  const name = useSelector(
    (state: ApplicationState) =>
      state.policies.approvalManagementVendorDocumentName
  );

  const approvals = useSelector(selectApprovals);

  const [message, setMessage] = useState<string>('');

  const [selectedUsers, setSelectedUsers] = useState<VendorDocumentApproval[]>(
    approvals.filter(v => v.required) || []
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchDocumentApprovals(id));
    }
  }, [id]);

  useEffect(() => {
    if (approvals) {
      setSelectedUsers(approvals.filter(v => v.required) || []);
    }
  }, [approvals]);

  const dispatch = useDispatch();

  const handleDone = () => {
    dispatch(
      patchApproval({
        policyId: id,
        approvalMessage: message,
        approvals,
        selectedUsers,
        onSuccess: () =>
          dispatch(showGlobalToast('The policy has been sent for Approval.')),
      })
    );
  };

  const handleSkip = () => {
    dispatch(
      skipApproval({
        policyId: id,
        onSuccess: () => {
          dispatch(fetchNotifications(vendorUserId, false));
          dispatch(
            showGlobalToast(
              'Approval has been skipped. The policy will move to the Live table where you can configure Read requests.'
            )
          );
        },
      })
    );
  };

  const isAllUsersSelected = () => selectedUsers.length === approvals.length;
  const isUserSelected = (user: VendorDocumentApproval) =>
    !!selectedUsers.find(u => u.vendorUserId === user.vendorUserId);

  const updateAllSelectedUsers = () => {
    if (isAllUsersSelected()) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(approvals);
    }
  };

  const updateSelectedUser = (user: VendorDocumentApproval) => () => {
    if (selectedUsers.find(u => u.vendorUserId === user.vendorUserId)) {
      setSelectedUsers(
        selectedUsers.filter(u => u.vendorUserId !== user.vendorUserId)
      );
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    setMessage(value);
  };

  const vendorUsers = useSelector((state: ApplicationState) =>
    selectUsers(state)
  );
  return (
    <React.Fragment>
      <AdoptechModal
        className="approvalManagementModal"
        onHide={null}
        show={isApprovalManagementModalShowing}
      >
        <Modal.Header>
          {isCancelable && (
            <div
              className="approvalManagementModal--close"
              onClick={() => dispatch(closeApprovalManagement())}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
          <div className="approvalManagementModal--header">
            <div>Send for approval</div>
            <div className="approvalManagementModal--subHeader">
              Select and notify Approvers or skip to confirm Approval is not
              required.
            </div>
            <div className="approvalManagementModal--title">
              Document: {name}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="approvalManagementModal--staffMembers">
            <AdoptechTable modal>
              <thead>
                <tr>
                  <th>
                    <AdoptechCheckbox
                      checked={isAllUsersSelected()}
                      id="selectAll"
                      label="Email address"
                      onChange={updateAllSelectedUsers}
                    />
                  </th>
                  <SortableTableHeader<VendorDocumentApproval>
                    columnName="status"
                    grid={Grid.ApprovalManagement}
                    label="Status"
                  />
                </tr>
              </thead>
              <tbody>
                {isFetchingDocumentApprovals ? (
                  <tr>
                    <td colSpan={2}>
                      <LoadingSpinner />
                    </td>
                  </tr>
                ) : (
                  approvals?.map(user => {
                    const { email } = user;
                    const vendorUser = vendorUsers.find(
                      currentVendorUser =>
                        currentVendorUser.id === user.vendorUserId
                    );
                    return (
                      <tr key={email}>
                        <td className="adoptechTable--checkbox">
                          <AdoptechCheckbox
                            checked={isUserSelected(user)}
                            id={email}
                            label={
                              <UserAvatar user={vendorUser} size="medium" />
                            }
                            onChange={updateSelectedUser(user)}
                          />
                        </td>
                        <td>
                          <ApprovedStatus approval={user} />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </AdoptechTable>
          </div>
          <div className="approvalManagementModal--message">
            <AdoptechTextArea
              id="message"
              label="Add optional message"
              onChange={handleMessageChange}
              value={message}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="approvalManagementModal--skip">
            <AdoptechButton onClick={handleSkip}>Skip</AdoptechButton>
            <div className="approvalManagementModal--skipLink">
              Skip this step, if no approval is required
            </div>
          </div>
          <AdoptechButton
            disabled={selectedUsers.length === 0}
            onClick={handleDone}
            variant={AdoptechButtonVariant.Primary}
            busy={isPostingApproval || isSkippingApproval}
          >
            Send
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
