/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BoardMember,
  BoardMemberFromJSON,
  BoardMemberFromJSONTyped,
  BoardMemberToJSON,
  CompanyIdentifierModel,
  CompanyIdentifierModelFromJSON,
  CompanyIdentifierModelFromJSONTyped,
  CompanyIdentifierModelToJSON,
  CompanySector,
  CompanySectorFromJSON,
  CompanySectorFromJSONTyped,
  CompanySectorToJSON,
  Office,
  OfficeFromJSON,
  OfficeFromJSONTyped,
  OfficeToJSON,
  OfficerRole,
  OfficerRoleFromJSON,
  OfficerRoleFromJSONTyped,
  OfficerRoleToJSON,
} from './';

/**
 *
 * @export
 * @interface Company
 */
export interface Company {
  /**
   *
   * @type {string}
   * @memberof Company
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  legalName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  tradeName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  countryOfIncorporation?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  countryOfIncorporationName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  registeredAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  primaryIdentifier?: string | null;
  /**
   *
   * @type {Array<CompanyIdentifierModel>}
   * @memberof Company
   */
  companyIdentifiers?: Array<CompanyIdentifierModel>;
  /**
   *
   * @type {Array<Office>}
   * @memberof Company
   */
  offices?: Array<Office>;
  /**
   *
   * @type {Array<OfficerRole>}
   * @memberof Company
   */
  officerRoles?: Array<OfficerRole>;
  /**
   *
   * @type {Array<BoardMember>}
   * @memberof Company
   */
  boardMembers?: Array<BoardMember>;
  /**
   *
   * @type {Array<CompanySector>}
   * @memberof Company
   */
  companySectors?: Array<CompanySector>;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  companyType?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  companyTypeDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  incorporatedOn?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  latestAnnualReturnOn?: string | null;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  updatedAt?: string;
}

export function CompanyFromJSON(json: any): Company {
  return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Company {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    legalName: !exists(json, 'legal_name') ? undefined : json['legal_name'],
    tradeName: !exists(json, 'trade_name') ? undefined : json['trade_name'],
    countryOfIncorporation: !exists(json, 'country_of_incorporation')
      ? undefined
      : json['country_of_incorporation'],
    countryOfIncorporationName: !exists(json, 'country_of_incorporation_name')
      ? undefined
      : json['country_of_incorporation_name'],
    registeredAddress: !exists(json, 'registered_address')
      ? undefined
      : json['registered_address'],
    primaryIdentifier: !exists(json, 'primary_identifier')
      ? undefined
      : json['primary_identifier'],
    companyIdentifiers: !exists(json, 'company_identifiers')
      ? undefined
      : (json['company_identifiers'] as Array<any>).map(
          CompanyIdentifierModelFromJSON
        ),
    offices: !exists(json, 'offices')
      ? undefined
      : (json['offices'] as Array<any>).map(OfficeFromJSON),
    officerRoles: !exists(json, 'officer_roles')
      ? undefined
      : (json['officer_roles'] as Array<any>).map(OfficerRoleFromJSON),
    boardMembers: !exists(json, 'board_members')
      ? undefined
      : (json['board_members'] as Array<any>).map(BoardMemberFromJSON),
    companySectors: !exists(json, 'company_sectors')
      ? undefined
      : (json['company_sectors'] as Array<any>).map(CompanySectorFromJSON),
    companyType: !exists(json, 'company_type')
      ? undefined
      : json['company_type'],
    companyTypeDescription: !exists(json, 'company_type_description')
      ? undefined
      : json['company_type_description'],
    incorporatedOn: !exists(json, 'incorporated_on')
      ? undefined
      : json['incorporated_on'],
    latestAnnualReturnOn: !exists(json, 'latest_annual_return_on')
      ? undefined
      : json['latest_annual_return_on'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function CompanyToJSON(value?: Company | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    legal_name: value.legalName,
    trade_name: value.tradeName,
    country_of_incorporation: value.countryOfIncorporation,
    country_of_incorporation_name: value.countryOfIncorporationName,
    registered_address: value.registeredAddress,
    primary_identifier: value.primaryIdentifier,
    company_identifiers:
      value.companyIdentifiers === undefined
        ? undefined
        : (value.companyIdentifiers as Array<any>).map(
            CompanyIdentifierModelToJSON
          ),
    offices:
      value.offices === undefined
        ? undefined
        : (value.offices as Array<any>).map(OfficeToJSON),
    officer_roles:
      value.officerRoles === undefined
        ? undefined
        : (value.officerRoles as Array<any>).map(OfficerRoleToJSON),
    board_members:
      value.boardMembers === undefined
        ? undefined
        : (value.boardMembers as Array<any>).map(BoardMemberToJSON),
    company_sectors:
      value.companySectors === undefined
        ? undefined
        : (value.companySectors as Array<any>).map(CompanySectorToJSON),
    company_type: value.companyType,
    company_type_description: value.companyTypeDescription,
    incorporated_on: value.incorporatedOn,
    latest_annual_return_on: value.latestAnnualReturnOn,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
