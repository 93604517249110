import { VendorPolicyAnswerClosestQuestion } from '../swagger';

export const policyAnswerCommonFields = (
  props: any
): { closestQuestion: VendorPolicyAnswerClosestQuestion } => {
  const question = props.closestQuestion || props.question;
  return {
    closestQuestion: {
      id: question.id,
      layout: question.layout,
    },
  };
};
