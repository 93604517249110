import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import React from 'react';
import './AdoptechTextArea.scss';

export interface AdoptechTextAreaProps {
  id: string;
  label?: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  hasError?: boolean;
  placeholder?: string;
  value: string;
  rows?: number;
  autoFocus?: boolean;
  errorClass?: string;
  textAreaOnly?: boolean;
  icon?: IconDefinition;
  errorText?: string;
  rounded?: boolean;
  customLabel?: JSX.Element;
  extraStyles?: React.CSSProperties;
}

export const AdoptechTextArea = React.forwardRef<
  HTMLTextAreaElement,
  AdoptechTextAreaProps
>((props, ref) => {
  const mainClasses = classNames({
    adoptechTextArea: true,
    'adoptechTextArea-error': props.hasError && !props.errorClass,
    [`${props.errorClass}`]: props.hasError,
  });

  const textArea = (
    <textarea
      id={props.id}
      className={mainClasses}
      disabled={props.disabled}
      onChange={props.onChange}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      ref={ref}
      rows={props.rows}
      value={props.value}
      autoFocus={props.autoFocus}
      style={props.extraStyles}
    />
  );

  if (props.textAreaOnly) return textArea;

  return (
    <div className="adoptechTextArea">
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      {textArea}
    </div>
  );
});
