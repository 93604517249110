import { createSelector } from '@reduxjs/toolkit';
import { approvalSort } from '../functions/approvalSort';
import { getCurrentApprovals } from '../functions/getCurrentApprovals';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';

export const selectApprovals = createSelector(
  getCurrentApprovals,
  selectSortSettings(Grid.ApprovalManagement),
  (approvals, sortSetting) => {
    const clonedAttestations = [...approvals];
    return clonedAttestations.sort((a, b) =>
      approvalSort(a, b, sortSetting.columnSort)
    );
  }
);
