import React from 'react';
import './DashboardTile.scss';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { Interweave } from 'interweave';

export interface SingleMetricWithLabel {
  label: string;
  value: string;
}

export type TileMetrics = string | SingleMetricWithLabel[];

enum DashboardTileStyle {
  SingleMetric,
  SingleMetricWithLabel,
  DualMetric,
  SingleText,
}

interface DashboardTileProps {
  action?: () => void;
  icon: IconDefinition;
  isBusy?: boolean;
  title: string;
  footerText?: string;
  className?: string;
}

interface DashboardMetricsProps extends DashboardTileProps {
  metrics: TileMetrics;
}

interface DashboardTextProps extends DashboardTileProps {
  text: string;
}

type TileProps = DashboardMetricsProps | DashboardTextProps;

const Card: React.FC<TileProps> = props => {
  if ('text' in props) {
    return (
      <div className="dashboardTile--metrics">
        <div className="dashboardTile--singleText">
          <Interweave content={props.text} />
        </div>
      </div>
    );
  }

  const style: DashboardTileStyle = Array.isArray(props.metrics)
    ? props.metrics.length > 1
      ? DashboardTileStyle.DualMetric
      : DashboardTileStyle.SingleMetricWithLabel
    : DashboardTileStyle.SingleMetric;

  const metricsWithLabels = props.metrics as SingleMetricWithLabel[];

  if (!metricsWithLabels || metricsWithLabels?.length === 0) return null;

  switch (style) {
    case DashboardTileStyle.DualMetric:
      return (
        <div className="dashboardTile--metrics">
          <div className="dashboardTile--dualMetrics">
            <div className="dashboardTile--dualMetric">
              <div>{metricsWithLabels[0].label}</div>
              <div>{metricsWithLabels[0].value}</div>
            </div>
            <div className="dashboardTile--dualMetric dashboardTile--dualMetric-noBorder">
              <div>{metricsWithLabels[1].label}</div>
              <div>{metricsWithLabels[1].value}</div>
            </div>
          </div>
        </div>
      );
    case DashboardTileStyle.SingleMetric: {
      return (
        <div className="dashboardTile--metrics">
          <div className="dashboardTile--singleMetric">{metricsWithLabels}</div>
        </div>
      );
    }
    case DashboardTileStyle.SingleMetricWithLabel:
      return (
        <div className="dashboardTile--metrics">
          <div className="dashboardTile--singleMetricWithLabel">
            <div className="dashboardTile--singleMetricLabel">
              {metricsWithLabels[0].label}
            </div>
            <div className="dashboardTile--singleMetricValue">
              {metricsWithLabels[0].value}
            </div>
          </div>
        </div>
      );
  }
};

export const DashboardTile: React.FC<TileProps> = props => {
  return (
    <div className={'dashboardTile' + ' ' + props.className}>
      <div className="dashboardTile--header">
        <div className="dashboardTile--iconContainer">
          <div className="dashboardTile--icon">
            <FontAwesomeIcon icon={props.icon} />
          </div>
        </div>
        <div className="dashboardTile--title">{props.title}</div>
      </div>
      {!props.isBusy && <Card {...props} />}
      {!props.isBusy && props.action && (
        <button
          className="dashboardTile--footer"
          onClick={() => props.action()}
        >
          <div className="dashboardTile--footerLabel">
            {props.footerText || 'See details'}
          </div>
          <div className="dashboardTile--footerIcon">
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </button>
      )}
      {props.isBusy && <LoadingSpinner />}
    </div>
  );
};
