import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { AgreementsState } from './agreementsState';
import {
  AgreementExtended,
  AgreementModel,
  AgreementTemplateModel,
  AgreementSignatoryModel,
  AgreementTemplateCategoryModel,
  Answer,
  AgreementExtendedModesAvailableEnum,
} from '../../swagger';
import {
  AgreementCreateFormFieldPayload,
  AgreementsCreateForm,
} from '../../components/Agreements/AddNewAgreementForm/AddNewAgreementForm';
import {
  AgreementEditFormFieldPayload,
  AgreementsEditForm,
} from '../../components/Agreements/EditAgreementForm/EditAgreementForm';
import { NOT_FOUND_INDEX } from '../../types/constants';
import { AuditsState } from '../../features/compliance/audits/store/auditsState';

export interface AgreementReminderModal {
  isShowing: boolean;
  id?: AgreementModel['id'];
  email?: string;
}

export const updateAnswerSlice = (
  model: AgreementsState['currentAgreement'] | AuditsState['currentAudit'],
  action: PayloadAction<Answer>
) => {
  const newAnswer = action.payload;
  model.survey.surveyClauses.forEach(sc => {
    sc.questions.forEach(q => {
      if (q.propertyName === newAnswer.propertyName) {
        if (!q.answer) {
          // Initially before BE request we don't have "q.answer". We need to wait a couple of seconds to wait the BE response.
          // But we need "q.answer.value" for conditional question logic without wait => create it
          q.answer = { value: newAnswer.value };
        } else {
          q.answer.value = newAnswer.value;
        }
      }
    });
  });
};

const agreementsSlice = createSlice({
  initialState: {
    agreementTemplates: [],
    agreementTemplateCategories: [],
    vendorAgreementDocuments: [],
    signAgreementModalData: { isShowing: false },
    agreementsCreateForm: {} as AgreementsCreateForm,
    agreementsEditForm: {} as AgreementsEditForm,
    agreements: [], // Used: /agreements, Edit RHS (on /agreements page)
    currentAgreementId: null as AgreementModel['id'], // Used in meatballMenu modals ( useAgreementsModals.ts )
    currentAgreement: null as AgreementExtended, // Used: /agreements/:id, Edit RHS (on /agreements/:id page), Edit RHS Branding
    archivingAgreement: {},
    currentAgreementSignatories: [],
    currentAgreementSignatoriesUpdated: [],
    agreementSendReminderModalData: {
      isShowing: false,
    } as AgreementReminderModal,
    currentAgreementMode: null as AgreementExtendedModesAvailableEnum,
  } as unknown as AgreementsState,
  name: 'agreementsSlice',
  reducers: {
    agreementTemplateCategoriesRequest: state => {
      state.isRequestingAgreementTemplateCategories = true;
    },
    agreementTemplateCategoriesRequestSuccess: (
      state,
      action: PayloadAction<AgreementTemplateCategoryModel[]>
    ) => {
      state.agreementTemplateCategories = action.payload;
      state.isRequestingAgreementTemplateCategories = false;
    },
    agreementTemplateCategoriesRequestFailure: (
      state,
      action: PayloadAction<any>
    ) => {
      state.agreementTemplateCategoriesRequestError = action.payload;
      state.isRequestingAgreementTemplateCategories = false;
    },
    toggleAddAgreementDrawer: state => {
      state.isShowingAddAgreementDrawer = !state.isShowingAddAgreementDrawer;
    },
    toggleEditAgreementDrawer: state => {
      state.isShowingEditAgreementDrawer = !state.isShowingEditAgreementDrawer;
    },
    agreementTemplatesRequest: state => {
      state.isRequestingAgreementTemplates = true;
    },
    agreementTemplatesRequestSuccess: (
      state,
      action: PayloadAction<AgreementTemplateModel[]>
    ) => {
      state.agreementTemplates = action.payload;
      state.isRequestingAgreementTemplates = false;
    },
    agreementTemplatesRequestFailure: (state, action: PayloadAction<any>) => {
      state.agreementTemplatesRequestError = action.payload;
      state.isRequestingAgreementTemplates = false;
    },
    createAgreementRequestFailure: (state, action: PayloadAction<any>) => {
      state.createAgreementRequestFailure = action.payload;
      state.isCreatingAgreement = false;
    },
    setNotificationDispatchStatus: (state, action: PayloadAction<any>) => {
      state.isDispatchingNotification = action.payload;
    },
    notificationDispatchError: (state, action: PayloadAction<any>) => {
      state.notificationDispatchRequestFailure = action.payload;
      state.isDispatchingNotification = false;
    },
    setAgreementCreationStatus: (state, action: PayloadAction<boolean>) => {
      state.isCreatingAgreement = action.payload;
    },
    agreementsRequest: (state, action: PayloadAction<boolean>) => {
      state.isRequestingAgreements = action.payload;
    },
    agreementsRequestSuccess: (
      state,
      action: PayloadAction<AgreementModel[]>
    ) => {
      state.agreements = action.payload;
      state.isRequestingAgreements = false;
    },
    agreementsRequestFailure: (state, action: PayloadAction<string>) => {
      state.agreementsRequestError = action.payload;
      state.isRequestingAgreements = false;
    },
    setCurrentAgreementLoading: (state, action: PayloadAction<boolean>) => {
      state.isRequestingCurrentAgreement = action.payload;
    },
    setAgreementEditFormExtendedFieldsLoaded: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.agreementsEditForm.isLoadedExtendedFields = action.payload;
    },
    setCurrentAgreement: (state, action: PayloadAction<AgreementExtended>) => {
      state.currentAgreement = action.payload; // used on show draft page ( because we don't have state.agreements here )
      state.currentAgreementId = state.currentAgreement?.id; // used for RHS/modals and selectCurrentAgreement selector
      state.currentAgreementMode = AgreementExtendedModesAvailableEnum.Survey;
    },
    currentAgreementRequestFailure: (state, action: PayloadAction<string>) => {
      state.currentAgreementRequestError = action.payload;
      state.isRequestingCurrentAgreement = false;
    },
    setRequestingAgreementPdf: (state, action: PayloadAction<boolean>) => {
      state.isRequestingAgreementPdf = action.payload;
    },
    currentAgreementPdfRequestFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentAgreementPdfRequestError = action.payload;
    },
    agreementAnswersFailure: (state, action: PayloadAction<string>) => {
      state.agreementAnswersError = action.payload;
    },
    setSignatoryCreationOrUpdateStatus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCreatingOrUpdatingSignatory = action.payload;
    },
    createOrUpdateSignatoryRequestFailure: (
      state,
      action: PayloadAction<any>
    ) => {
      state.createOrUpdateSignatoryRequestError = action.payload;
      state.isCreatingOrUpdatingSignatory = false;
    },
    currentAgreementSignatoriesRequest: state => {
      state.isRequestingCurrentAgreementSignatories = true;
    },
    currentAgreementSignatoriesRequestSuccess: (
      state,
      action: PayloadAction<AgreementSignatoryModel[]>
    ) => {
      state.currentAgreementSignatories = action.payload;
      state.isRequestingCurrentAgreementSignatories = false;
    },
    currentAgreementSignatoriesRequestFailure: (
      state,
      action: PayloadAction<any>
    ) => {
      state.currentAgreementSignatoriesRequestError = action.payload;
      state.isRequestingCurrentAgreementSignatories = false;
    },
    updateSignatory: (
      state,
      action: PayloadAction<AgreementSignatoryModel>
    ) => {
      const signatoryIndex = state.currentAgreementSignatories.findIndex(
        s => s.id === action.payload.id
      );
      if (signatoryIndex > NOT_FOUND_INDEX) {
        state.currentAgreementSignatories[signatoryIndex] = action.payload;

        const addToList = !state.currentAgreementSignatoriesUpdated.includes(
          action.payload.id
        );
        if (addToList)
          state.currentAgreementSignatoriesUpdated.push(action.payload.id);
      }
    },

    clearCurrentAgreementSignatories: state => {
      state.currentAgreementSignatories = null;
    },
    setDeleteAgreementStatus: (state, action: PayloadAction<boolean>) => {
      state.isDeletingAgreement = action.payload;
    },
    deleteAgreementFailure: (state, action: PayloadAction<string>) => {
      state.deleteAgreementFailure = action.payload;
      state.isDeletingAgreement = false;
    },
    deleteAgreementTableAnswersFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.deleteAgreementTableAnswersFailure = action.payload;
    },
    setSignAgreementStatus: (state, action: PayloadAction<boolean>) => {
      state.isSigningAgreement = action.payload;
    },
    signAgreementFailure: (state, action: PayloadAction<string>) => {
      state.signAgreementFailure = action.payload;
      state.isSigningAgreement = false;
    },
    editAgreementClauseFailure: (state, action: PayloadAction<string>) => {
      state.editAgreementClauseFailure = action.payload;
    },
    revertAgreementClauseFailure: (state, action: PayloadAction<string>) => {
      state.revertAgreementClauseFailure = action.payload;
    },
    undoArchivedAgreement: (state, _: PayloadAction) => {
      const indexAgreement = +localStorage.getItem('agreement-archived-index');
      state.agreements.splice(indexAgreement, 0, state.archivingAgreement);
    },
    createNewVersionOfAgreementRequest: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCreatingNewVersionOfAgreement = action.payload;
    },
    createNewVersionOfAgreementFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.createNewVersionOfAgreementFailure = action.payload;
      state.isCreatingNewVersionOfAgreement = false;
    },

    sendToReviewRequest: state => {
      state.isSendingToReview = true;
    },

    sendToReviewSuccess: state => {
      state.isSendingToReview = false;
    },

    sendToReviewFailure: (state, _) => {
      state.isSendingToReview = false;
    },

    archiveAgreementRequest: state => {
      state.isArchivingAgreement = true;
      state.isArchivingError = undefined;
    },

    archiveAgreementSuccess: (state, action: PayloadAction<string>) => {
      state.isArchivingAgreement = false;
      state.isArchivingError = undefined;

      state.agreements = state.agreements.filter(
        agreement => agreement.id !== action.payload
      );
    },

    unArchiveAgreementRequest: (state, action: PayloadAction) => {
      state.unArchivingError = undefined;
    },

    unArchiveAgreementRequestFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.unArchivingError = action.payload;
    },
    archiveAgreementFailure: (state, action: PayloadAction<string>) => {
      state.isArchivingAgreement = false;
      state.archiveAgreementError = action.payload;
    },
    patchAgreementRequest: state => {
      state.isPatchingAgreement = true;
      state.patchAgreementError = undefined;
    },

    setArchivingAgreement: (
      state,
      action: PayloadAction<AgreementModel['id']>
    ) => {
      state.archivingAgreement = state.agreements.find(
        agreement => agreement.id === action.payload
      );
    },
    patchAgreementSuccess: (
      state,
      action: PayloadAction<AgreementExtended>
    ) => {
      state.isPatchingAgreement = false;

      const agreements = [...state.agreements];
      const index = agreements.findIndex(
        document => document.id === action.payload.id
      );
      if (index === -1) {
        agreements.push(action.payload);
      } else {
        agreements.splice(index, 1, { ...action.payload });
      }
      state.agreements = agreements;

      // We don't have state.agreements on /r/governance/agreements/id page)
      if (state.currentAgreement?.id === action.payload.id) {
        state.currentAgreement = {
          ...state.currentAgreement,
          ...action.payload,
        };
      }
    },
    patchAgreementFailure: (state, action: PayloadAction<string>) => {
      state.isPatchingAgreement = false;
      state.patchAgreementError = action.payload;
    },

    cleanupAgreementsCreateForm: (state, action: PayloadAction) => {
      state.agreementsCreateForm = {} as AgreementsCreateForm;
    },

    updateAgreementsCreateFormField: (
      state,
      action: PayloadAction<AgreementCreateFormFieldPayload>
    ) => {
      state.agreementsCreateForm = {
        ...state.agreementsCreateForm,
        ...action.payload,
      };
    },

    cleanupAgreementsEditForm: (state, action: PayloadAction) => {
      state.agreementsEditForm = {} as AgreementsEditForm;
      state.currentAgreementId = null as AgreementModel['id'];
      state.currentAgreementSignatories = null;
      state.currentAgreementSignatoriesUpdated = [];
    },

    setCurrentAgreementId: (
      state,
      action: PayloadAction<AgreementModel['id']>
    ) => {
      state.currentAgreementId = action.payload;
    },

    updateAgreementsEditFormField: (
      state,
      action: PayloadAction<AgreementEditFormFieldPayload>
    ) => {
      state.agreementsEditForm = {
        ...state.agreementsEditForm,
        ...action.payload,
      };
    },

    toggleAgreementReminderModal: (
      state,
      action: PayloadAction<AgreementReminderModal>
    ) => {
      state.agreementSendReminderModalData = action.payload;
    },

    setAgreementMode: (
      state,
      action: PayloadAction<AgreementExtendedModesAvailableEnum>
    ) => {
      state.currentAgreementMode = action.payload;
    },

    postAgreementAnswer: state => {
      state.isPostingAgreementAnswer = true;
    },
    updateAnswer: (state, action: PayloadAction<Answer>) => {
      updateAnswerSlice(state.currentAgreement, action);
    },
    isPostingAgreementAnswerFinished: state => {
      state.isPostingAgreementAnswer = false;
    },
    patchAgreementContentRequest: state => {
      state.isPatchingAgreementContent = true;
      state.patchAgreementContentError = undefined;
    },
    patchAgreementContentSuccess: (
      state,
      action: PayloadAction<AgreementExtended>
    ) => {
      state.isPatchingAgreementContent = false;
      state.currentAgreement = action.payload;
      state.agreements = state.agreements.map(agreement => {
        return agreement.id === action.payload.id
          ? { ...agreement, ...action.payload }
          : agreement;
      });
    },
    patchAgreementContentFailure: (state, action: PayloadAction<string>) => {
      state.isPatchingAgreementContent = false;
      state.patchAgreementContentError = action.payload;
    },
    setAgreementContent: (state, action: PayloadAction<string>) => {
      state.agreementContent = action.payload;
    },
    setAgreementContentChanged: (state, action: PayloadAction<boolean>) => {
      state.agreementContentChanged = action.payload;
    },
    agreementUploadRequest: state => {
      state.isUploadingAgreement = true;
    },
    agreementUploadRequestSuccess: (
      state,
      action: PayloadAction<AgreementModel>
    ) => {
      state.agreements.push(action.payload);
      state.isUploadingAgreement = false;
    },
    agreementUploadRequestFailure: (state, action: PayloadAction<string>) => {
      state.agreementUploadError = action.payload;
      state.isUploadingAgreement = false;
    },
  },
});

export const {
  agreementTemplatesRequest,
  agreementTemplatesRequestSuccess,
  agreementTemplatesRequestFailure,
  agreementsRequest,
  agreementsRequestSuccess,
  agreementsRequestFailure,
  setCurrentAgreementLoading,
  setAgreementEditFormExtendedFieldsLoaded,
  setCurrentAgreement,
  currentAgreementRequestFailure,
  setAgreementCreationStatus,
  createAgreementRequestFailure,
  agreementAnswersFailure,
  setNotificationDispatchStatus,
  notificationDispatchError,
  setSignatoryCreationOrUpdateStatus,
  createOrUpdateSignatoryRequestFailure,
  currentAgreementSignatoriesRequest,
  currentAgreementSignatoriesRequestSuccess,
  currentAgreementSignatoriesRequestFailure,
  clearCurrentAgreementSignatories,
  setDeleteAgreementStatus,
  deleteAgreementFailure,
  deleteAgreementTableAnswersFailure,
  setSignAgreementStatus,
  signAgreementFailure,
  editAgreementClauseFailure,
  revertAgreementClauseFailure,
  createNewVersionOfAgreementFailure,
  createNewVersionOfAgreementRequest,
  patchAgreementFailure,
  patchAgreementRequest,
  patchAgreementSuccess,
  agreementTemplateCategoriesRequest,
  agreementTemplateCategoriesRequestSuccess,
  agreementTemplateCategoriesRequestFailure,
  toggleAddAgreementDrawer,
  updateAgreementsCreateFormField,
  cleanupAgreementsCreateForm,
  updateAgreementsEditFormField,
  cleanupAgreementsEditForm,
  toggleEditAgreementDrawer,
  setCurrentAgreementId,
  toggleAgreementReminderModal,
  setAgreementMode,
  archiveAgreementFailure,
  archiveAgreementSuccess,
  archiveAgreementRequest,
  setArchivingAgreement,
  unArchiveAgreementRequest,
  unArchiveAgreementRequestFailure,
  undoArchivedAgreement,
  patchAgreementContentRequest,
  patchAgreementContentSuccess,
  patchAgreementContentFailure,
  setAgreementContent,
  setAgreementContentChanged,
  updateSignatory,
  postAgreementAnswer,
  isPostingAgreementAnswerFinished,
  agreementUploadRequest,
  agreementUploadRequestSuccess,
  agreementUploadRequestFailure,
  currentAgreementPdfRequestFailure,
  setRequestingAgreementPdf,
  updateAnswer,
  sendToReviewRequest,
  sendToReviewFailure,
  sendToReviewSuccess,
} = agreementsSlice.actions;

export default agreementsSlice.reducer;
