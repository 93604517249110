import { useEffect } from 'react';

export const useAutoGrowTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
      const scrollHeight = textAreaRef.scrollHeight;
      const height = textAreaRef.style.height;
      const heightNumber = height ? parseInt(height) : 0;

      if (scrollHeight >= heightNumber) {
        textAreaRef.style.height = scrollHeight + 'px';
      }
    }
  }, [textAreaRef, value]);
};
