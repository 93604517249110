import { push } from 'connected-react-router';
import { api } from '../../api/api';
import { getApiErrorMessage } from '../../functions/getApiErrorMessage';
import {
  VendorDocumentApproval,
  VendorDocumentAttestation,
  VendorDocumentsIdVendorDocumentAttestationGroupsGetRequest,
  VendorDocumentsIdVendorDocumentAttestationGroupsPatchRequest,
  VendorPolicy,
  VendorPolicyClause,
  VendorPolicyDetails,
  VendorPolicyVersionBumpEnum,
  VendorsVendorIdVendorPoliciesGetRequest,
} from '../../swagger';
import { UNKNOWN_ERROR } from '../../types/constants';
import { PdfOpenMode } from '../../types/pdfOpenMode';
import { VoidThunk } from '../../types/voidThunk';
import {
  clearErrorMessage,
  download,
  openPdfPreview,
  setErrorMessage,
  showGlobalToast,
  showSubscriptionRequiredModal,
} from '../global/globalSlice';
import { getErrorMessage, handleThunkError } from '../helpers/thunkHelpers';
import { fetchVendorPolicyDocuments } from '../vendors/vendorsThunks';
import {
  closeApprovalManagement,
  closeAttestationManagement,
  closeExistingPolicyWarningModal,
  completePolicyFailure,
  completePolicyRequest,
  completePolicySuccess,
  createPolicyFailure,
  createPolicyRequest,
  createPolicySuccess,
  deletePolicyFailure,
  deletePolicyRequest,
  deletePolicySuccess,
  fetchDocumentApprovalsFailure,
  fetchDocumentApprovalsRequest,
  fetchDocumentApprovalsSuccess,
  fetchPolicyTemplateCategoriesRequest,
  fetchPolicyTemplateCategoriesSuccess,
  fetchVendorPolicyFailure,
  fetchVendorPolicyRequest,
  fetchVendorPolicySuccess,
  hideDocumentLibraryModal,
  openApprovalManagement,
  patchApprovalFailure,
  patchApprovalRequest,
  patchApprovalSuccess,
  patchVendorPolicyClauseFailure,
  patchVendorPolicyClauseRequest,
  patchVendorPolicyClauseSuccess,
  patchVendorPolicyFailure,
  patchVendorPolicyRequest,
  patchVendorPolicySuccess,
  postAttestationFailure,
  postAttestationRequest,
  postAttestationSuccess,
  readReminderFailure,
  readReminderRequest,
  readReminderSuccess,
  showAutoSave,
  skipApprovalFailure,
  skipApprovalRequest,
  skipApprovalSuccess,
  skipAttestationFailure,
  skipAttestationRequest,
  skipAttestationSuccess,
  updateVendorPoliciesAnswers,
  updateVendorPolicyProgress,
} from './policiesSlice';
import { DocumentType } from '../../constants/DocumentType';
import { mapPolicyTable } from '../../functions/mapPolicyTable';
import moment from 'moment';
import { vendorPolicyCalculations } from '../../functions/vendorPolicyCalculations';
import { removeDupFromArrayByProp } from '../../functions/removeDupFromArrayByProp';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationState } from '../../types/applicationState';
import { WithoutVendorId } from '../../features/calendar/store/calendarThunksApiHelpers';
import { policiesPageRoute } from '../../components/Routes/Routes';

export const calculatePolicyProgress =
  (vendorPolicyId: VendorPolicyDetails['id']): VoidThunk =>
  async dispatch => {
    const policy = await api().vendorPoliciesIdGet({
      id: vendorPolicyId,
      omitClauseText: true,
    });
    const { stats } = vendorPolicyCalculations(policy);
    const progress =
      stats.reduce((acc, s) => acc + s[1], 0) / stats.length || 0;
    dispatch(updateVendorPolicyProgress({ ...policy, progress }));
    dispatch(patchVendorPolicyProgress(policy.id, progress));
  };

export const deletePolicy =
  (policyId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deletePolicyRequest());
      await api().vendorPoliciesIdDelete({ id: policyId });
      dispatch(deletePolicySuccess());
      dispatch(fetchPolicies());
      dispatch(fetchVendorPoliciesDetails());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(deletePolicyFailure(apiErrorMessage));
        } else {
          dispatch(
            deletePolicyFailure(
              'An error occurred deleting the policy. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(deletePolicyFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorPolicy =
  (
    vendorPolicyId: string,
    onSuccess?: (response: VendorPolicyDetails) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchVendorPolicyRequest());
      const response = await api().vendorPoliciesIdGet({ id: vendorPolicyId });
      if (onSuccess) onSuccess(response);
      dispatch(fetchVendorPolicySuccess(mapPolicyTable(response)));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorPolicyFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorPolicyFailure(
              'An error occurred fetching the vendor policy. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorPolicyFailure(UNKNOWN_ERROR));
      }
    }
  };

export const completePolicy =
  (vendorPolicyId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(completePolicyRequest());
      const response = await api().vendorPoliciesIdPublishPost({
        id: vendorPolicyId,
      });
      dispatch(completePolicySuccess());
      dispatch(
        openApprovalManagement({
          id: response.vendorDocumentId,
          name: response.name,
          isCancelable: false,
        })
      );
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(completePolicyFailure(apiErrorMessage));
        } else {
          dispatch(
            completePolicyFailure(
              'An error occurred completing the policy. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(completePolicyFailure(UNKNOWN_ERROR));
      }
    }
  };

export const createPolicy =
  ({
    policyId,
    versionBump,
    changeReason,
    onSuccess = () => {},
  }: {
    policyId: string;
    versionBump?: string;
    changeReason?: string;
    onSuccess?: (response: VendorPolicy) => void;
  }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(createPolicyRequest(policyId));
      const response = await api().vendorsIdVendorPoliciesPost({
        id: vendorId,
        vendorPolicy: {
          policyId,
          versionBump: versionBump as VendorPolicyVersionBumpEnum,
          changeReason,
        },
      });
      dispatch(createPolicySuccess(response));
      dispatch(hideDocumentLibraryModal());
      dispatch(closeExistingPolicyWarningModal());
      onSuccess(response);
    } catch (err) {
      if (err instanceof Response) {
        if (err.status === 402) {
          dispatch(hideDocumentLibraryModal());
          dispatch(createPolicyFailure());
          dispatch(showSubscriptionRequiredModal());
          return;
        }
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(createPolicyFailure(apiErrorMessage));
        } else {
          dispatch(
            createPolicyFailure(
              'An error occurred creating the policy. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(createPolicyFailure(UNKNOWN_ERROR));
      }
    }
  };

export const patchVendorPolicyClause =
  (id: string, vendorPolicyClause: VendorPolicyClause): VoidThunk =>
  async dispatch => {
    try {
      dispatch(patchVendorPolicyClauseRequest({ ...vendorPolicyClause, id }));
      const response = await api().vendorPolicyClausesIdPatch({
        id,
        vendorPolicyClause,
      });
      dispatch(patchVendorPolicyClauseSuccess(response));
      dispatch(showAutoSave());
    } catch (error) {
      await handleThunkError(
        'An error occurred updating the policy clause. Please try again or contact support.',
        { dispatch, error }
      );
    }
  };

export const patchVendorPolicyProgress =
  (
    id: VendorPolicyDetails['id'],
    progress: VendorPolicyDetails['progress']
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      await api().vendorPoliciesIdProgressPatch({
        id,
        progress: { progress },
      });
    } catch (err) {
      console.log(err);
      dispatch(patchVendorPolicyFailure(UNKNOWN_ERROR));
    }
  };

export const patchVendorPolicy =
  (
    id: string,
    vendorPolicy: VendorPolicyDetails,
    isAutoSave: boolean
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const answersPropertyNames = vendorPolicy.vendorPolicyAnswers?.map(
        answer => answer.propertyName
      );

      const updatedBy = getState().user.userDetails.fullName;
      const updatedAt = moment().toISOString();
      dispatch(patchVendorPolicyRequest({ ...vendorPolicy, id }));
      dispatch(
        updateVendorPoliciesAnswers({
          answersPropertyNames,
          updatedBy,
          updatedAt,
          policyId: id,
        })
      );
      const response = await api().vendorPoliciesIdPatch({
        id,
        vendorPolicy,
      });
      dispatch(patchVendorPolicySuccess(mapPolicyTable(response)));
      dispatch(fetchVendorPolicy(id));
      if (isAutoSave) {
        dispatch(showAutoSave());
      } else {
        dispatch(showGlobalToast('Policy has been successfully updated.'));
      }
    } catch (error) {
      await handleThunkError(
        'An error occurred updating the policy. Please try again or contact support.',
        { dispatch, error }
      );
    }
  };

export const openVendorPolicyPdf =
  (id: string, mode: PdfOpenMode): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchVendorPolicyRequest());
      const response = await api().vendorPoliciesIdGet({ id });
      dispatch(fetchVendorPolicySuccess(response));
      if (
        mode === PdfOpenMode.Attest ||
        mode === PdfOpenMode.Approve ||
        mode === PdfOpenMode.Preview
      ) {
        dispatch(
          openPdfPreview({
            mode,
            documentType: DocumentType.Policy,
            vendorDocumentId: response.vendorDocumentId,
            title: response.name,
            url:
              response.document || `/api/v1/vendor_policies/${response.id}/pdf`,
          })
        );
      } else {
        dispatch(
          download(
            response.document || `/api/v1/vendor_policies/${response.id}/pdf`
          )
        );
      }
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred opening the policy PDF. Please try again or contact support.'
        );
        console.log(err);
        dispatch(fetchVendorPolicyFailure(apiErrorMessage));
      }
    }
  };

export const postAttestation =
  ({
    policyId,
    attestationDueAt,
    attestationMessage,
    attestations,
    selectedUsers,
    onSuccess,
  }: {
    policyId: string;
    attestationDueAt: string;
    attestationMessage: string;
    attestations: VendorDocumentAttestation[];
    selectedUsers: VendorDocumentAttestation[];
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(postAttestationRequest());
      const selectedAttestationsToSend = selectedUsers.map(attestation => ({
        ...attestation,
        required: true,
        attestationDueAt,
      }));

      const filteredAttestations: VendorDocumentAttestation[] =
        attestations.filter(attestation => {
          const attestationIsSelected = !!selectedAttestationsToSend.find(
            selectedAttest =>
              attestation.vendorUserId === selectedAttest.vendorUserId
          );
          return !attestationIsSelected;
        });

      const newAttestations = [
        ...filteredAttestations,
        ...selectedAttestationsToSend,
      ];
      const uniqueNewAttestationsArr = [...new Set(newAttestations)];
      const uniqueNewAttestations = removeDupFromArrayByProp(
        uniqueNewAttestationsArr,
        'email'
      );

      await api().vendorDocumentsIdPatch({
        id: policyId,
        vendorDocument: {
          attestationMessage,
          vendorDocumentAttestations: uniqueNewAttestations,
        },
      });
      dispatch(postAttestationSuccess());
      dispatch(closeAttestationManagement());
      dispatch(fetchVendorPolicyDocuments());
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(postAttestationFailure(apiErrorMessage));
        } else {
          dispatch(
            postAttestationFailure(
              'An error occurred posting the read request. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(postAttestationFailure(UNKNOWN_ERROR));
      }
    }
  };

export const sendReadReminder =
  ({
    attestationId,
    onSuccess,
  }: {
    attestationId: string;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(readReminderRequest(attestationId));

      await api().vendorDocumentAttestationsAttestationIdNotifyPost({
        attestationId,
      });
      dispatch(readReminderSuccess());
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(readReminderFailure(apiErrorMessage));
        } else {
          dispatch(
            readReminderFailure(
              'An error occurred sending read reminder. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(readReminderFailure(UNKNOWN_ERROR));
      }
    }
  };

export const patchApproval =
  ({
    policyId,
    approvals,
    approvalMessage,
    selectedUsers,
    onSuccess,
  }: {
    policyId: string;
    approvals: VendorDocumentApproval[];
    approvalMessage: string;
    selectedUsers: VendorDocumentApproval[];
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(patchApprovalRequest());
      const newApprovals: VendorDocumentApproval[] = approvals.map(u => ({
        id: u.id,
        vendorUserId: u.vendorUserId,
        required: !!selectedUsers.find(s => s.vendorUserId === u.vendorUserId),
      }));
      await api().vendorDocumentsIdPatch({
        id: policyId,
        vendorDocument: {
          vendorDocumentApprovals: newApprovals,
          approvalMessage,
        },
      });
      dispatch(patchApprovalSuccess());
      dispatch(closeApprovalManagement());
      dispatch(push(policiesPageRoute));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(patchApprovalFailure(apiErrorMessage));
        } else {
          dispatch(
            patchApprovalFailure(
              'An error occurred posting the approval(s). Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(patchApprovalFailure(UNKNOWN_ERROR));
      }
    }
  };

export const skipApproval =
  ({
    policyId,
    onSuccess,
  }: {
    policyId: string;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(skipApprovalRequest());
      await api().vendorDocumentsIdSkipApprovalPost({
        id: policyId,
      });
      dispatch(skipApprovalSuccess());
      dispatch(closeApprovalManagement());
      dispatch(fetchVendorPolicyDocuments());
      dispatch(push(policiesPageRoute));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(skipApprovalFailure(apiErrorMessage));
        } else {
          dispatch(
            skipApprovalFailure(
              'An error occurred skipping the approval(s). Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(patchApprovalFailure(UNKNOWN_ERROR));
      }
    }
  };

export const skipAttestation =
  ({
    policyId,
    onSuccess,
  }: {
    policyId: string;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(skipAttestationRequest());
      await api().vendorDocumentsIdSkipAttestationPost({
        id: policyId,
      });
      dispatch(skipAttestationSuccess());
      onSuccess && onSuccess();
      dispatch(fetchVendorPolicyDocuments(false));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(skipAttestationFailure(apiErrorMessage));
        } else {
          dispatch(
            skipAttestationFailure(
              'An error occurred skipping the read request(s). Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(skipAttestationFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchDocumentApprovals =
  (documentId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchDocumentApprovalsRequest());
      const response = await api().vendorDocumentsIdVendorDocumentApprovalsGet({
        id: documentId,
      });
      dispatch(fetchDocumentApprovalsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchDocumentApprovalsFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchDocumentApprovalsFailure(
              'An error occurred fetching the vendor policy. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchDocumentApprovalsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchPolicyTemplateCategories =
  (): VoidThunk => async dispatch => {
    try {
      dispatch(fetchPolicyTemplateCategoriesRequest());
      dispatch(clearErrorMessage());
      const response = await api().policyCategoriesGet();
      dispatch(fetchPolicyTemplateCategoriesSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(setErrorMessage(apiErrorMessage));
        } else {
          dispatch(
            setErrorMessage(
              'An error occurred fetching the policy categories. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(setErrorMessage(UNKNOWN_ERROR));
      }
    }
  };

// Used in main /policies page and return <VendorPolicyDetails> model

export const fetchVendorPoliciesDetails = createAsyncThunk(
  'vendorPolicies/fetchVendorPoliciesDetails',
  async (_, { getState, dispatch }) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        ?.id;

      const response = await api().vendorsIdVendorPoliciesGet({ id: vendorId });
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred fetching vendor policies details. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export type fetchVendorPolicies =
  | WithoutVendorId<VendorsVendorIdVendorPoliciesGetRequest>
  | undefined;

// used in control RHS to fetch policies list
export const fetchVendorPolicies = createAsyncThunk(
  'vendorPolicies/fetchVendorPolicies',
  async (filterParams: fetchVendorPolicies, { getState, dispatch }) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        ?.id;

      const params: VendorsVendorIdVendorPoliciesGetRequest = {
        vendorId,
        ...filterParams,
      };
      const response = await api().vendorsVendorIdVendorPoliciesGet(params);
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred fetching vendor policies. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchPolicies = createAsyncThunk(
  'policies/fetchPolicies',
  async (_, { dispatch }) => {
    try {
      const response = await api().policiesGet();
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred fetching policies. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchPoliciesForVendor = createAsyncThunk(
  'policies/fetchPolicies',
  async (_, { dispatch, getState }) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        ?.id;
      const response = await api().vendorsIdPoliciesGet({ id: vendorId });
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred fetching policies for vendor. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchPolicyAttestationGroups = createAsyncThunk(
  'policies/fetchPolicyAttestationGroups',
  async (
    params: VendorDocumentsIdVendorDocumentAttestationGroupsGetRequest,
    { dispatch }
  ) => {
    try {
      const response =
        await api().vendorDocumentsIdVendorDocumentAttestationGroupsGet(params);
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred fetching attestation groups for policy drawer. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const patchPolicyAttestationGroups = createAsyncThunk(
  'policies/patchPolicyAttestationGroups',
  async (
    params: VendorDocumentsIdVendorDocumentAttestationGroupsPatchRequest,
    { dispatch }
  ) => {
    try {
      const response =
        await api().vendorDocumentsIdVendorDocumentAttestationGroupsPatch(
          params
        );
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred patching attestation groups for policy drawer. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);
