import React, { useMemo, useState } from 'react';
import './../../EditControlDrawer/EditDocumentModal/EditDocumentModal.scss';
import AdoptechModal from '../../../../../components/AdoptechModal/AdoptechModal';
import { AdoptechTextInput } from '../../../../../components/AdoptechTextInput/AdoptechTextInput';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import { Modal } from 'react-bootstrap';
import Fuse from 'fuse.js';
import {
  TaskExtended,
  TasksTaskIdAvailableAssertionsPatchRequest,
} from '../../../../../swagger';
import { useDocumentsSelectTree } from '../../EditControlDrawer/EditDocumentModal/EditDocumentModal';
import { reactSelectLightStyleSmall } from '../../../../../functions/reactSelectCustomTheme';
import { SelectionOption } from '../../../../../types/selectionOption';
import { AdoptechReactSelect } from '../../../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { uniqArrayObjects } from '../../../../../functions/uniqueArrayObjects';
import { patchControlCheckAssertions } from '../../../../../store/compliance/complianceThunks';
import { ApplicationState } from '../../../../../types/applicationState';
import { calculateTaskStatus } from '../EditTestDrawer/EditTestForm/EditTestFormLastResultsSection/EditTestFormLastResultsList/EditTestFormLastResultsList';
import { updateTaskInTheTree } from '../../../../../store/compliance/complianceSlice';

interface EditDocumentModalProps {
  show: boolean;
  onChange: (task: TaskExtended) => void;
  onHide: () => void;
}

export const EditAssertionModal: React.FC<EditDocumentModalProps> = props => {
  const baseCss = 'editDocumentModal';
  const [search, setSearch] = useState('');
  const { tempTask, patchControlCheckAssertionsStatus } = useSelector(
    (state: ApplicationState) => state.compliance
  );
  const dispatch = useDispatch();
  const modalAssertions = (tempTask.assertions || []).filter(
    assertion => !assertion.available
  );

  const options = {
    ignoreLocation: true,
    includeScore: true,
    keys: ['name'],
    threshold: 0,
  };
  const fuseDocuments = new Fuse(modalAssertions, options);
  const [categoryId, setCategoryId] = useState(null);

  const documents = search
    ? fuseDocuments.search(search).map(x => x.item)
    : modalAssertions;
  const hasTests = modalAssertions.length > 0;

  const filteredDocuments = categoryId
    ? documents.filter(
        document => categoryId === document.assertionCategory?.id
      )
    : documents;

  const placeholder = 'Search for tests';
  const title = 'Add test';
  const { selectedIds, handleSelectAllButton, childClass, treeDiv } =
    useDocumentsSelectTree(filteredDocuments, baseCss);
  const handleConfirm = async () => {
    const availableAssertionIds = tempTask.assertions
      .filter(assertion => assertion.available)
      .map(assertion => assertion.id)
      .concat(selectedIds);

    const assertionsPayload: TasksTaskIdAvailableAssertionsPatchRequest = {
      taskId: tempTask.id,
      body: {
        availableAssertionIds,
      },
    };
    await dispatch(patchControlCheckAssertions(assertionsPayload));
    const newAssertions = tempTask.assertions.map(assertion => {
      return {
        ...assertion,
        ...{
          available: availableAssertionIds.includes(assertion.id),
        },
      };
    });

    const formPayload = {
      ...tempTask,
      ...{
        assertions: newAssertions,
        status: calculateTaskStatus(newAssertions),
      },
    };
    const taskPayload = {
      id: formPayload.id,
      assertions: formPayload.assertions,
      status: formPayload.status,
      partialUpdate: true,
    };

    dispatch(updateTaskInTheTree(taskPayload));
    props.onChange(formPayload);
    props.onHide();
  };

  const categoriesOptions = useMemo(() => {
    const categories = (
      (tempTask.assertions || []).filter(assertion => !assertion.available) ||
      []
    )
      .map(assertion => assertion.assertionCategory)
      .filter(category => category);

    return [{ label: 'Filter category', value: null }].concat(
      [...uniqArrayObjects(categories)].map(category => {
        return {
          label: category.name,
          value: category.id,
        };
      })
    );
  }, [tempTask.assertions]);

  const handleClose = props.onHide;

  return (
    <AdoptechModal
      show={props.show}
      onHide={props.onHide}
      className={childClass('modal')}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body className={childClass('body')}>
        {hasTests && (
          <>
            <div className={childClass('search')}>
              <AdoptechTextInput
                id="search"
                value={search}
                onChange={e => setSearch(e.target.value)}
                type="text"
                placeholder={placeholder}
                icon={faSearch}
                additionalClass="adoptechTextInput-search"
              />
            </div>
            <div className={childClass('filter')}>
              <AdoptechReactSelect
                id="assertionCategoriesSelect"
                additionalStyling={reactSelectLightStyleSmall}
                options={categoriesOptions}
                onChange={(option: SelectionOption) => {
                  setCategoryId(option.value);
                }}
                value={categoriesOptions.find(
                  option => option.value === categoryId
                )}
                placeholder="Filter category"
              />
            </div>

            {treeDiv}
          </>
        )}
        {!hasTests && <div>There are no available tests</div>}
      </Modal.Body>
      <Modal.Footer className={childClass('footer')}>
        <AdoptechButton
          variant={AdoptechButtonVariant.White}
          onClick={handleSelectAllButton}
          extraClass={hasTests ? '' : 'invisible'}
        >
          Select All
        </AdoptechButton>
        {!hasTests && (
          <AdoptechButton
            variant={AdoptechButtonVariant.Primary}
            onClick={handleClose}
          >
            OK
          </AdoptechButton>
        )}
        {hasTests && (
          <AdoptechButton
            variant={AdoptechButtonVariant.Primary}
            onClick={handleConfirm}
            busy={patchControlCheckAssertionsStatus === 'loading'}
            disabled={selectedIds.length === 0}
          >
            Confirm
          </AdoptechButton>
        )}
      </Modal.Footer>
    </AdoptechModal>
  );
};
