import React from 'react';
import './AdoptechGridTable.scss';

interface Props {
  header: React.ReactElement<any>;
}

const AdoptechGridTable: React.FC<Props> = props => {
  return (
    <>
      {props.header}
      {props.children}
    </>
  );
};

export default AdoptechGridTable;
