/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AssessmentResponseAnswerFile
 */
export interface AssessmentResponseAnswerFile {
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswerFile
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswerFile
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswerFile
   */
  url?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswerFile
   */
  document?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseAnswerFile
   */
  contentType?: string | null;
}

export function AssessmentResponseAnswerFileFromJSON(
  json: any
): AssessmentResponseAnswerFile {
  return AssessmentResponseAnswerFileFromJSONTyped(json, false);
}

export function AssessmentResponseAnswerFileFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssessmentResponseAnswerFile {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    url: !exists(json, 'url') ? undefined : json['url'],
    document: !exists(json, 'document') ? undefined : json['document'],
    contentType: !exists(json, 'content_type')
      ? undefined
      : json['content_type'],
  };
}

export function AssessmentResponseAnswerFileToJSON(
  value?: AssessmentResponseAnswerFile | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    url: value.url,
    document: value.document,
    content_type: value.contentType,
  };
}
