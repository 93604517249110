import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import getTableValue from '../../functions/getTableValue';
import { hideDeleteTableAnswersModal } from '../../store/assessments/assessmentsSlice';
import { deleteAssessmentResponseAnswers } from '../../store/assessments/assessmentsThunks';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';

export const TableAnswerDeleteModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    isShowingDeleteTableAnswersModal,
    currentTableQuestions,
    currentTableAnswerIndex,
  } = useSelector((state: ApplicationState) => state.assessments);

  const handleDelete = () => {
    dispatch(
      deleteAssessmentResponseAnswers(
        currentTableQuestions?.map(
          q => q.answers.find(x => x.index === currentTableAnswerIndex)?.id
        ),
        currentTableQuestions
      )
    );
  };

  return (
    <AdoptechModal
      className="tableAnswerDeleteModal"
      onHide={() => dispatch(hideDeleteTableAnswersModal())}
      show={isShowingDeleteTableAnswersModal}
    >
      <Modal.Header>Delete</Modal.Header>
      <Modal.Body>
        Are you sure you wish to delete this row?
        <br />
        <ul>
          {currentTableQuestions?.map(question => (
            <React.Fragment key={question.id}>
              <li>
                {question.questionText}:{' '}
                {getTableValue(question, currentTableAnswerIndex)}
              </li>
            </React.Fragment>
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={() => dispatch(hideDeleteTableAnswersModal())}>
          Cancel
        </AdoptechButton>
        <AdoptechButton
          onClick={handleDelete}
          variant={AdoptechButtonVariant.Primary}
        >
          CONFIRM
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
