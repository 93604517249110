/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PaymentPlan,
  PaymentPlanFromJSON,
  PaymentPlanFromJSONTyped,
  PaymentPlanToJSON,
  Product,
  ProductFromJSON,
  ProductFromJSONTyped,
  ProductToJSON,
  RecurringProduct,
  RecurringProductFromJSON,
  RecurringProductFromJSONTyped,
  RecurringProductToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorProductModel
 */
export interface VendorProductModel {
  /**
   *
   * @type {string}
   * @memberof VendorProductModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof VendorProductModel
   */
  identifier?: string;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductModel
   */
  available?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductModel
   */
  response?: boolean;
  /**
   *
   * @type {string}
   * @memberof VendorProductModel
   */
  errorCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorProductModel
   */
  errorText?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorProductModel
   */
  policyId?: string;
  /**
   *
   * @type {string}
   * @memberof VendorProductModel
   */
  purchasableId?: string;
  /**
   *
   * @type {string}
   * @memberof VendorProductModel
   */
  purchasableType?: string;
  /**
   *
   * @type {PaymentPlan}
   * @memberof VendorProductModel
   */
  minimumPlan?: PaymentPlan;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductModel
   */
  paymentProductAvailable?: boolean;
  /**
   *
   * @type {RecurringProduct}
   * @memberof VendorProductModel
   */
  paymentProduct?: RecurringProduct;
  /**
   *
   * @type {Product}
   * @memberof VendorProductModel
   */
  singlePurchase?: Product;
}

export function VendorProductModelFromJSON(json: any): VendorProductModel {
  return VendorProductModelFromJSONTyped(json, false);
}

export function VendorProductModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorProductModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    identifier: !exists(json, 'identifier') ? undefined : json['identifier'],
    available: !exists(json, 'available') ? undefined : json['available'],
    response: !exists(json, 'response') ? undefined : json['response'],
    errorCode: !exists(json, 'error_code') ? undefined : json['error_code'],
    errorText: !exists(json, 'error_text') ? undefined : json['error_text'],
    policyId: !exists(json, 'policy_id') ? undefined : json['policy_id'],
    purchasableId: !exists(json, 'purchasable_id')
      ? undefined
      : json['purchasable_id'],
    purchasableType: !exists(json, 'purchasable_type')
      ? undefined
      : json['purchasable_type'],
    minimumPlan: !exists(json, 'minimum_plan')
      ? undefined
      : PaymentPlanFromJSON(json['minimum_plan']),
    paymentProductAvailable: !exists(json, 'payment_product_available')
      ? undefined
      : json['payment_product_available'],
    paymentProduct: !exists(json, 'payment_product')
      ? undefined
      : RecurringProductFromJSON(json['payment_product']),
    singlePurchase: !exists(json, 'single_purchase')
      ? undefined
      : ProductFromJSON(json['single_purchase']),
  };
}

export function VendorProductModelToJSON(
  value?: VendorProductModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    identifier: value.identifier,
    available: value.available,
    response: value.response,
    error_code: value.errorCode,
    error_text: value.errorText,
    policy_id: value.policyId,
    purchasable_id: value.purchasableId,
    purchasable_type: value.purchasableType,
    minimum_plan: PaymentPlanToJSON(value.minimumPlan),
    payment_product_available: value.paymentProductAvailable,
    payment_product: RecurringProductToJSON(value.paymentProduct),
    single_purchase: ProductToJSON(value.singlePurchase),
  };
}
