import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trusthubAdminPageUsersRoute } from '../../../../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';

import { AdoptechPanel } from '../../../../../../components/AdoptechPanel/AdoptechPanel';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../../components/AdoptechButton/AdoptechButton';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../../types/applicationState';
import {
  fetchTrustHubCompanies,
  patchTrusthubVendorUser,
} from '../../../../store/trusthubThunks';
import { showGlobalToast } from '../../../../../../store/global/globalSlice';
import { AdoptechTextInput } from '../../../../../../components/AdoptechTextInput/AdoptechTextInput';
import { AdoptechReactSelect } from '../../../../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { SelectionOption } from '../../../../../../types/selectionOption';
import { selectCurrentVendor } from '../../../../../../selectors/selectCurrentVendor';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal/ConfirmationModal';
import { CommandConfirmation } from '../../../../../../types/CommandConfirmation';
import {
  TrusthubCompanyModel,
  TrusthubVendorUserModel,
  TrusthubVendorUserStatus,
  TrusthubCompanyStatus,
  TrusthubVendorUserUpdatePayload,
  TrusthubVendorUserUpdatePayloadTrusthubVendorUser,
} from '../../../../../../swagger/trusthub';
import { faUserAlt } from '@fortawesome/pro-light-svg-icons/faUserAlt';
import { userStatus } from '../../TrustHubUserAdminUsersPage/TrustHubVendorUserTableRow/TrustHubVendorUserTableRow';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';

interface EditUserFormData {
  companyId: TrusthubCompanyModel['id'];
  firstName: TrusthubVendorUserModel['firstName'];
  lastName: TrusthubVendorUserModel['lastName'];
  status: TrusthubVendorUserModel['status'];
}
export const TrustHubUserAdminEditUserForm: React.FC = () => {
  const baseCss = 'trusthubUserAdminUserPage';
  const baseHeaderCss = baseCss + '--header';

  const {
    currentVendorUser: user,
    patchVendorUserStatus,
    fetchingCompaniesStatus,
    companies,
  } = useSelector((state: ApplicationState) => state.trustHub);

  const dispatch = useDispatch();
  const initialFormState = {
    companyId: user.trusthubCompany?.id,
    status: user.status,
    firstName: user.firstName,
    lastName: user.lastName,
  };

  const [formData, setFormData] = useState<EditUserFormData>(initialFormState);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    isEditMode && setFormData(initialFormState);
  }, [isEditMode]);
  const handleDelete = () =>
    dispatch(
      patchTrusthubVendorUser(
        {
          vendorUserId: user.id,
          body: {
            trusthubVendorUser: {
              status: TrusthubVendorUserStatus.Suspended,
            },
          },
        },
        () => {
          dispatch(showGlobalToast('User has been successfully suspended'));
          dispatch(push(trusthubAdminPageUsersRoute));
        }
      )
    );
  const [currentCommand, command] = useState<CommandConfirmation>(null);

  const handleEdit = () => {
    setIsEditMode(true);
  };
  const handleCancel = () => {
    setFormData(initialFormState);
    setIsEditMode(false);
  };
  const handleSubmit = () => {
    const updatedFields: TrusthubVendorUserUpdatePayloadTrusthubVendorUser = {};
    if (formData.companyId !== user.trusthubCompany?.id) {
      updatedFields.trusthubCompanyId = formData.companyId;
    }
    const fieldsToCheck: (keyof Omit<EditUserFormData, 'companyId'>)[] = [
      'status',
      'firstName',
      'lastName',
    ];
    type ValueOf<T> = T[keyof T];
    fieldsToCheck.forEach(field => {
      if (formData[field] !== user[field]) {
        (updatedFields[
          field
        ] as ValueOf<TrusthubVendorUserUpdatePayloadTrusthubVendorUser>) =
          formData[field];
      }
    });
    dispatch(
      patchTrusthubVendorUser(
        {
          vendorUserId: user.id,
          body: {
            trusthubVendorUser: updatedFields,
          },
        },
        () => {
          setIsEditMode(false);
          dispatch(showGlobalToast('User has been successfully updated.'));
        }
      )
    );
  };

  const statusOptions = [
    {
      label: 'Pending',
      value: TrusthubVendorUserStatus.Pending,
    },
    {
      label: 'Approved',
      value: TrusthubVendorUserStatus.Approved,
    },
    {
      label: 'Suspended',
      value: TrusthubVendorUserStatus.Suspended,
    },
    {
      label: 'Waiting for NDA',
      value: TrusthubVendorUserStatus.WaitingForNda,
    },
  ];
  const currentVendor = useSelector(selectCurrentVendor);
  useEffect(() => {
    isEditMode &&
      dispatch(
        fetchTrustHubCompanies(
          {
            vendorIdOrDomain: currentVendor?.id,
            statuses: [TrusthubCompanyStatus.Approved],
          },
          () => {}
        )
      );
  }, [isEditMode]);

  const companyOptions = (companies || []).map(company => {
    return {
      label: company.name,
      value: company.id,
    };
  });
  const deleteUserCommand: CommandConfirmation = {
    title: 'Confirm Delete',
    subject: { name: user?.fullName, type: 'Action' },
    description: 'Click CONFIRM to delete (suspend) this user.',
    buttonVariant: AdoptechButtonVariant.Warning,
    onConfirm: handleDelete,
  };

  return (
    <div>
      <div className={baseCss + '--header'}>
        <div className={baseHeaderCss + '--info'}>
          <div className={baseHeaderCss + '--backSection'}>
            <FontAwesomeIcon
              onClick={() => dispatch(push(trusthubAdminPageUsersRoute))}
              className={baseHeaderCss + '--backSectionLink'}
              icon={faAngleLeft}
            />
            <div className={baseHeaderCss + '--backSectionTitle'}>
              {user.fullName}
            </div>
          </div>
        </div>
        <div className={baseCss + '--buttons'}>
          <AdoptechButton
            variant={AdoptechButtonVariant.White}
            icon={faTrashAlt}
            disabled={user.status === TrusthubVendorUserStatus.Unconfirmed}
            disabledTooltip="You cannot delete the user until it's unconfirmed"
            busy={patchVendorUserStatus === 'loading'}
            onClick={() => command(deleteUserCommand)}
          >
            Delete User
          </AdoptechButton>
        </div>
      </div>
      <div className={baseCss + '--panel'}>
        <AdoptechPanel padding="24px">
          <div className={baseCss + '--panelTitle'}>
            <div className={baseCss + '--panelInfo'}>
              <FontAwesomeIcon icon={faUserAlt} />
              User Profile
            </div>
            {!isEditMode && (
              <div className={baseCss + '--buttons'}>
                <AdoptechButton
                  variant={AdoptechButtonVariant.White}
                  icon={faPencil}
                  onClick={handleEdit}
                  disabled={
                    user.status === TrusthubVendorUserStatus.Unconfirmed
                  }
                  disabledTooltip="You cannot edit the user until it's unconfirmed"
                >
                  Edit
                </AdoptechButton>
              </div>
            )}

            {isEditMode && (
              <div className={baseCss + '--buttons'}>
                <AdoptechButton
                  disabled={patchVendorUserStatus === 'loading'}
                  onClick={handleCancel}
                  variant={AdoptechButtonVariant.White}
                  extraClass="cancelUserButton" // className used in TrustHubUserAdminDocumentPermissions
                >
                  Cancel
                </AdoptechButton>
                <AdoptechButton
                  busy={patchVendorUserStatus === 'loading'}
                  disabled={
                    !formData.firstName ||
                    !formData.companyId ||
                    !formData.lastName ||
                    !formData.status
                  }
                  onClick={handleSubmit}
                  variant={AdoptechButtonVariant.White}
                >
                  Save
                </AdoptechButton>
              </div>
            )}
          </div>
          <div className={baseCss + '--panelSection'}>
            <div className={baseCss + '--panelSectionTitle'}>Overview</div>

            <div className={baseCss + '--panelSectionRow'}>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>Company</div>
                {isEditMode && fetchingCompaniesStatus === 'loading' && (
                  <LoadingSpinner inlineSmall />
                )}
                {isEditMode && fetchingCompaniesStatus !== 'loading' && (
                  <AdoptechReactSelect
                    id="userCompanyId"
                    options={companyOptions}
                    hasError={!formData.companyId}
                    onChange={(option: SelectionOption) => {
                      setFormData({
                        ...formData,
                        companyId: option.value,
                      });
                    }}
                    value={companyOptions.find(
                      option => option.value === formData.companyId
                    )}
                    placeholder="Select a company"
                  />
                )}

                {!isEditMode && <div>{user.trusthubCompany?.name}</div>}
              </div>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>User status</div>

                {isEditMode ? (
                  <AdoptechReactSelect
                    id="userStatus"
                    options={statusOptions}
                    hasError={!formData.status}
                    onChange={(option: SelectionOption) => {
                      setFormData({
                        ...formData,
                        status: option.value as TrusthubVendorUserStatus,
                      });
                    }}
                    value={statusOptions.find(
                      option => option.value === formData.status
                    )}
                    placeholder="Select status"
                  />
                ) : (
                  <div>{userStatus(user.status)}</div>
                )}
              </div>
            </div>
          </div>
          <div className={baseCss + '--panelDivider'}></div>
          <div className={baseCss + '--panelSection'}>
            <div className={baseCss + '--panelSectionTitle'}>
              User information
            </div>

            <div className={baseCss + '--panelSectionRow'}>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>First name</div>
                {isEditMode ? (
                  <div>
                    <AdoptechTextInput
                      id="firstName-input"
                      type="text"
                      value={formData.firstName}
                      placeholder="Enter first name"
                      hasError={!formData.firstName}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          firstName: e.currentTarget.value,
                        })
                      }
                    />
                  </div>
                ) : (
                  <div>{user.firstName}</div>
                )}
              </div>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>Last name</div>
                {isEditMode ? (
                  <div>
                    <AdoptechTextInput
                      id="lastName-input"
                      type="text"
                      value={formData.lastName}
                      placeholder="Enter last name"
                      hasError={!formData.lastName}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          lastName: e.currentTarget.value,
                        })
                      }
                    />
                  </div>
                ) : (
                  <div>{user.lastName}</div>
                )}
              </div>
            </div>
            <div className={baseCss + '--panelSectionRow'}>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>Email</div>
                {user.email}
              </div>
            </div>
          </div>
          <ConfirmationModal
            command={currentCommand}
            onCancel={_confirmed => command(null)}
          />
        </AdoptechPanel>
      </div>
    </div>
  );
};
