/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
  /**
   *
   * @type {string}
   * @memberof UserDetails
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetails
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetails
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDetails
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDetails
   */
  fullName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDetails
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDetails
   */
  mobile?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserDetails
   */
  authyUserid?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserDetails
   */
  authyEnabled?: boolean | null;
}

export function UserDetailsFromJSON(json: any): UserDetails {
  return UserDetailsFromJSONTyped(json, false);
}

export function UserDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    email: !exists(json, 'email') ? undefined : json['email'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    fullName: !exists(json, 'full_name') ? undefined : json['full_name'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    mobile: !exists(json, 'mobile') ? undefined : json['mobile'],
    authyUserid: !exists(json, 'authy_userid')
      ? undefined
      : json['authy_userid'],
    authyEnabled: !exists(json, 'authy_enabled')
      ? undefined
      : json['authy_enabled'],
  };
}

export function UserDetailsToJSON(value?: UserDetails | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    email: value.email,
    first_name: value.firstName,
    last_name: value.lastName,
    full_name: value.fullName,
    phone: value.phone,
    mobile: value.mobile,
    authy_userid: value.authyUserid,
    authy_enabled: value.authyEnabled,
  };
}
