import React, { useEffect } from 'react';
import { ReportForm, ReportSectionProps } from '../ReportsManageDrawer';
import { ReportVersionField } from '../ReportVersionField';
import { ReportOwnerField } from '../ReportOwnerField';
import { AdoptechRadioButtonsGroup } from '../../../AdoptechRadioButtonsGroup/AdoptechRadioButtonsGroup';
import { ReportCreatePayloadReportFrameworkEnum } from '../../../../swagger/models/ReportCreatePayloadReport';
import { useQueryParams } from '../../../../hooks/useQueryParams';

export const soaSectionValid = (formData: ReportForm): boolean => {
  const hasVersionSelected =
    formData.hasVersion === false || formData.hasVersion === true;
  return hasVersionSelected && formData.ownerId && formData.framework
    ? true
    : false;
};

export const SoaSection: React.FC<ReportSectionProps> = ({
  formData,
  updateField,
  updateForm,
}) => {
  const sectionProps = { formData, updateField, updateForm };
  const baseCss = 'reportsManageDrawer';
  const frameworkParam = useQueryParams().get(
    'framework'
  ) as ReportCreatePayloadReportFrameworkEnum;

  useEffect(() => {
    frameworkParam && updateField('framework', frameworkParam);
  }, []);
  return (
    <div>
      <ReportOwnerField {...sectionProps} />
      <div className={baseCss + '--fieldRow'}>
        <div className={baseCss + '--label'}>
          Is this report for ISO 27001:2013 or 2022?
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechRadioButtonsGroup
            value={formData.framework}
            ids={['27001', '270012022']}
            values={[
              ReportCreatePayloadReportFrameworkEnum._27001,
              ReportCreatePayloadReportFrameworkEnum._270012022,
            ]}
            labels={['2013', '2022']}
            onChange={value => {
              updateField('framework', value);
            }}
          />
        </div>
      </div>
      <ReportVersionField {...sectionProps} />
    </div>
  );
};
