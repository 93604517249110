/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  AgreementModelSignatoriesStats,
  AgreementModelSignatoriesStatsFromJSON,
  AgreementModelSignatoriesStatsFromJSONTyped,
  AgreementModelSignatoriesStatsToJSON,
  AgreementSignatoryModel,
  AgreementSignatoryModelFromJSON,
  AgreementSignatoryModelFromJSONTyped,
  AgreementSignatoryModelToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface AgreementModel
 */
export interface AgreementModel {
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  status?: AgreementModelStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  summary: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  summaryAsHtml: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  descriptionAsHtml?: string | null;
  /**
   *
   * @type {VendorUser}
   * @memberof AgreementModel
   */
  createdBy?: VendorUser;
  /**
   *
   * @type {VendorUser}
   * @memberof AgreementModel
   */
  owner?: VendorUser;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  version: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof AgreementModel
   */
  externalSignatoriesNames?: Array<string>;
  /**
   *
   * @type {AgreementModelSignatoriesStats}
   * @memberof AgreementModel
   */
  signatoriesStats?: AgreementModelSignatoriesStats;
  /**
   *
   * @type {boolean}
   * @memberof AgreementModel
   */
  toBeSigned?: boolean;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  agreementType?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  agreementTemplateName?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  agreementTemplateId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  vendorDocumentId?: string | null;
  /**
   *
   * @type {Array<AgreementSignatoryModel>}
   * @memberof AgreementModel
   */
  agreementSignatories?: Array<AgreementSignatoryModel>;
  /**
   *
   * @type {Access}
   * @memberof AgreementModel
   */
  access?: Access;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  relativePath?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AgreementModel
   */
  available?: boolean;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  reviewDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AgreementModel
   */
  updatedAt: string;
}

export function AgreementModelFromJSON(json: any): AgreementModel {
  return AgreementModelFromJSONTyped(json, false);
}

export function AgreementModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    summary: json['summary'],
    summaryAsHtml: json['summary_as_html'],
    description: !exists(json, 'description') ? undefined : json['description'],
    descriptionAsHtml: !exists(json, 'description_as_html')
      ? undefined
      : json['description_as_html'],
    createdBy: !exists(json, 'created_by')
      ? undefined
      : VendorUserFromJSON(json['created_by']),
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    version: json['version'],
    externalSignatoriesNames: !exists(json, 'external_signatories_names')
      ? undefined
      : json['external_signatories_names'],
    signatoriesStats: !exists(json, 'signatories_stats')
      ? undefined
      : AgreementModelSignatoriesStatsFromJSON(json['signatories_stats']),
    toBeSigned: !exists(json, 'to_be_signed')
      ? undefined
      : json['to_be_signed'],
    agreementType: !exists(json, 'agreement_type')
      ? undefined
      : json['agreement_type'],
    agreementTemplateName: !exists(json, 'agreement_template_name')
      ? undefined
      : json['agreement_template_name'],
    agreementTemplateId: !exists(json, 'agreement_template_id')
      ? undefined
      : json['agreement_template_id'],
    vendorDocumentId: !exists(json, 'vendor_document_id')
      ? undefined
      : json['vendor_document_id'],
    agreementSignatories: !exists(json, 'agreement_signatories')
      ? undefined
      : (json['agreement_signatories'] as Array<any>).map(
          AgreementSignatoryModelFromJSON
        ),
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    relativePath: !exists(json, 'relative_path')
      ? undefined
      : json['relative_path'],
    available: !exists(json, 'available') ? undefined : json['available'],
    reviewDate: !exists(json, 'review_date') ? undefined : json['review_date'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function AgreementModelToJSON(value?: AgreementModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    status: value.status,
    summary: value.summary,
    summary_as_html: value.summaryAsHtml,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
    created_by: VendorUserToJSON(value.createdBy),
    owner: VendorUserToJSON(value.owner),
    version: value.version,
    external_signatories_names: value.externalSignatoriesNames,
    signatories_stats: AgreementModelSignatoriesStatsToJSON(
      value.signatoriesStats
    ),
    to_be_signed: value.toBeSigned,
    agreement_type: value.agreementType,
    agreement_template_name: value.agreementTemplateName,
    agreement_template_id: value.agreementTemplateId,
    vendor_document_id: value.vendorDocumentId,
    agreement_signatories:
      value.agreementSignatories === undefined
        ? undefined
        : (value.agreementSignatories as Array<any>).map(
            AgreementSignatoryModelToJSON
          ),
    access: AccessToJSON(value.access),
    relative_path: value.relativePath,
    available: value.available,
    review_date: value.reviewDate,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AgreementModelStatusEnum {
  Draft = 'draft',
  AwaitingSignature = 'awaiting_signature',
  Rejected = 'rejected',
  Signed = 'signed',
}
