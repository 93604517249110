import React, { useEffect, useRef } from 'react';
import './Download.scss';

export interface DownloadProps {
  url: string;
}

export const Download: React.FC<DownloadProps> = props => {
  const anchorRef = useRef<HTMLAnchorElement>();

  useEffect(() => {
    anchorRef.current.click();
  }, [props.url]);

  return (
    <div className="download">
      <a href={props.url} ref={anchorRef} target="_blank" rel="noreferrer" />
    </div>
  );
};
