import classNames from 'classnames';
import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import './VendorRegisterRiskProfile.scss';

export interface RiskInfo {
  riskSeverity: number;
  riskLevel?: keyof RiskLevel;
}

interface VendorRegisterRiskProfileProps {
  riskInfo: RiskInfo;
  scale?: number;
}

export enum RiskLevel {
  'Very Low',
  'Low',
  'Medium',
  'High',
  'Critical',
}

export const VendorRegisterRiskProfile: React.FC<
  VendorRegisterRiskProfileProps
> = ({ riskInfo, scale = 10 }) => {
  const baseCss = 'vendorRegisterRiskProfile';
  return (
    <div
      className={classNames(
        baseCss,
        baseCss +
          '--color-' +
          (riskInfo.riskLevel || '').toLowerCase().replace(' ', '')
      )}
    >
      <div>
        <div className={baseCss + '--text'}>{riskInfo.riskLevel}</div>
        <div className={baseCss + '--line'}>
          <ProgressBar now={(riskInfo.riskSeverity / scale) * 100} />
        </div>
      </div>
      <div className={baseCss + '--number'}>{riskInfo.riskSeverity}</div>
    </div>
  );
};
