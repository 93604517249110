import React from 'react';
import { AssessmentCompletedResponses } from '../AssessmentCompletedResponses/AssessmentCompletedResponses';
import { AssessmentPendingResponses } from '../AssessmentPendingResponses/AssessmentPendingResponses';
import './AssessmentSummaryResponses.scss';

export const AssessmentSummaryResponses: React.FC = () => {
  return (
    <div className="assessmentSummaryResponses">
      <AssessmentCompletedResponses />
      <AssessmentPendingResponses />
    </div>
  );
};
