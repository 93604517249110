/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TableColumn
 */
export interface TableColumn {
  /**
   *
   * @type {string}
   * @memberof TableColumn
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TableColumn
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof TableColumn
   */
  format?: string | null;
}

export function TableColumnFromJSON(json: any): TableColumn {
  return TableColumnFromJSONTyped(json, false);
}

export function TableColumnFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TableColumn {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    type: !exists(json, 'type') ? undefined : json['type'],
    format: !exists(json, 'format') ? undefined : json['format'],
  };
}

export function TableColumnToJSON(value?: TableColumn | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    type: value.type,
    format: value.format,
  };
}
