import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { dateSort } from './dateSort';
import { sort } from './sort';
import { CalendarItemModel } from '../swagger';

export const calendarItemsSort = (
  a: CalendarItemModel,
  b: CalendarItemModel,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn = columnSort.name as keyof CalendarItemModel;

  switch (typedColumn) {
    case 'date':
      return dateSort(a.date, b.date, direction);
    case 'name':
      return sort(a.name.toLowerCase(), b.name.toLowerCase(), direction);
  }
};
