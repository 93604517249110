/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChecklistTemplateCreatePayloadChecklistTemplate
 */
export interface ChecklistTemplateCreatePayloadChecklistTemplate {
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateCreatePayloadChecklistTemplate
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistTemplateCreatePayloadChecklistTemplate
   */
  common?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateCreatePayloadChecklistTemplate
   */
  relatedTo?: ChecklistTemplateCreatePayloadChecklistTemplateRelatedToEnum;
}

export function ChecklistTemplateCreatePayloadChecklistTemplateFromJSON(
  json: any
): ChecklistTemplateCreatePayloadChecklistTemplate {
  return ChecklistTemplateCreatePayloadChecklistTemplateFromJSONTyped(
    json,
    false
  );
}

export function ChecklistTemplateCreatePayloadChecklistTemplateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistTemplateCreatePayloadChecklistTemplate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    common: !exists(json, 'common') ? undefined : json['common'],
    relatedTo: !exists(json, 'related_to') ? undefined : json['related_to'],
  };
}

export function ChecklistTemplateCreatePayloadChecklistTemplateToJSON(
  value?: ChecklistTemplateCreatePayloadChecklistTemplate | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    common: value.common,
    related_to: value.relatedTo,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ChecklistTemplateCreatePayloadChecklistTemplateRelatedToEnum {
  VendorUser = 'vendor_user',
}
