import { api } from '../../api/api';
import {
  JurisdictionModel,
  LegalRegisterModel,
  LegalRegisterUpdatePayload,
  LegislationAddPayload,
  LegislationClassificationModel,
  LegislationCreatePayloadLegislation,
  LegislationUpdatePayload,
} from '../../swagger';
import { VoidThunk } from '../../types/voidThunk';
import { setErrorMessage, showGlobalToast } from '../global/globalSlice';
import { getErrorMessage, handleThunkError } from '../helpers/thunkHelpers';
import { fetchVendorSuppliersRequest } from '../vendors/vendorsSlice';
import {
  createLegislationFromTemplatesFailure,
  createLegislationFromTemplatesRequest,
  createLegislationFromTemplatesSuccess,
  deleteLegislationFailure,
  deleteLegislationRequest,
  deleteLegislationSuccess,
  fetchJurisdictionsFailure,
  fetchJurisdictionsRequest,
  fetchJurisdictionsSuccess,
  fetchLegalRegisterFailure,
  fetchLegalRegisterRequest,
  fetchLegalRegisterSuccess,
  fetchLegislationTemplatesFailure,
  fetchLegislationTemplatesRequest,
  fetchLegislationTemplatesSuccess,
  fetchVendorLegislationsFailure,
  fetchVendorLegislationsRequest,
  fetchVendorLegislationsSuccess,
  patchLegalRegisterFailure,
  patchLegalRegisterRequest,
  patchLegalRegisterSuccess,
  updateVendorLegislation,
} from './legalRegisterSlice';
import { ApplicationState } from '../../types/applicationState';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchLegislation =
  (id: string, onSuccess: (payload: any) => void): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchVendorSuppliersRequest());
      const response = await api().legislationsIdGet({ id });
      onSuccess(response);
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred while loading a legislation. Please try again or contact support.'
      );
      dispatch(setErrorMessage(errorMessageToUser));
    }
  };

export const deleteLegislation =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteLegislationRequest(id));
      await api().legislationsIdDelete({ id });
      dispatch(deleteLegislationSuccess());
      dispatch(fetchLegalRegister());
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred while deleting a vendor legislation. Please try again or contact support.'
      );
      dispatch(deleteLegislationFailure());
      dispatch(setErrorMessage(errorMessageToUser));
    }
  };
export const fetchJurisdictions =
  (onSuccess?: (payload: JurisdictionModel[]) => void): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchJurisdictionsRequest());
      const response = await api().jurisdictionsGet();
      if (onSuccess) {
        onSuccess(response);
      } else {
        dispatch(fetchJurisdictionsSuccess(response));
      }
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred fetching jurisdictions. Please try again or contact support.'
      );
      dispatch(fetchJurisdictionsFailure());
      dispatch(setErrorMessage(errorMessageToUser));
    }
  };
export const fetchLegislationTemplates = (): VoidThunk => async dispatch => {
  try {
    dispatch(fetchLegislationTemplatesRequest());
    const response = await api().legislationTemplatesGet();
    dispatch(fetchLegislationTemplatesSuccess(response));
  } catch (err) {
    const errorMessageToUser = await getErrorMessage(
      err,
      'An error occurred fetching legislation templates. Please try again or contact support.'
    );
    dispatch(fetchLegislationTemplatesFailure());
    dispatch(setErrorMessage(errorMessageToUser));
  }
};

export const createLegislationFromTemplates =
  (body: LegislationAddPayload): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(createLegislationFromTemplatesRequest());
      const payload = { vendorId: getState().vendors.currentVendor.id, body };
      await api().vendorsVendorIdLegislationsAddPost(payload);
      dispatch(createLegislationFromTemplatesSuccess());
      dispatch(fetchVendorLegislations());
      dispatch(showGlobalToast('Legislation(s) has been successfully added'));
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred adding legislation. Please try again or contact support.'
      );
      dispatch(createLegislationFromTemplatesFailure());
      dispatch(setErrorMessage(errorMessageToUser));
    }
  };

export const updateLegislation =
  (
    id: string,
    body: LegislationUpdatePayload,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      const legislation = await api().legislationsIdPatch({ id, body });
      dispatch(updateVendorLegislation(legislation));
      onSuccess();
      dispatch(showGlobalToast('Legislation has been successfully updated'));
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred during update legislation. Please try again or contact support.'
      );
      dispatch(setErrorMessage(errorMessageToUser));
    }
  };

export const fetchClassifications =
  (
    onSuccess?: (payload: LegislationClassificationModel[]) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      const response = await api().legislationClassificationsGet();
      if (onSuccess) {
        onSuccess(response);
      } else {
        // TODO: implement generic success handler if needed
        // dispatch(fetchClassificationsSuccess(response));
      }
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred fetching classifications. Please try again or contact support.'
      );
      dispatch(setErrorMessage(errorMessageToUser));
    }
  };

export const fetchLegalRegister =
  (onSuccess?: (register: LegalRegisterModel) => void): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;

      dispatch(fetchLegalRegisterRequest());
      const response = await api().vendorsVendorIdLegalRegisterGet({
        vendorId,
      });
      dispatch(fetchLegalRegisterSuccess(response));
      onSuccess && onSuccess(response);
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred while loading a legal register. Please try again or contact support.'
      );
      dispatch(setErrorMessage(errorMessageToUser));
      dispatch(fetchLegalRegisterFailure(errorMessageToUser));
    }
  };

export const fetchVendorLegislations =
  (): VoidThunk => async (dispatch, getState) => {
    try {
      dispatch(fetchVendorLegislationsRequest());
      const vendorId = getState().vendors.currentVendor?.id;
      const response = await api().vendorsVendorIdLegislationsGet({
        vendorId,
      });
      dispatch(fetchVendorLegislationsSuccess(response));
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred fetching vendor legislations. Please try again or contact support.'
      );
      dispatch(fetchVendorLegislationsFailure());
      dispatch(setErrorMessage(errorMessageToUser));
    }
  };

export const patchLegalRegister =
  (body: LegalRegisterUpdatePayload, onSuccess?: () => void): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(patchLegalRegisterRequest());
      const payload = { vendorId: getState().vendors.currentVendor.id, body };
      const response = await api().vendorsVendorIdLegalRegisterPatch(payload);
      dispatch(patchLegalRegisterSuccess(response));
      onSuccess && onSuccess();
      dispatch(showGlobalToast('Legal register has been successfully updated'));
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred updating legal register. Please try again or contact support.'
      );
      dispatch(patchLegalRegisterFailure());
      dispatch(setErrorMessage(errorMessageToUser));
    }
  };

export const postLegislation = createAsyncThunk(
  'legislations/fetchTrusthubCategories',
  async (
    params: LegislationCreatePayloadLegislation,
    { dispatch, getState }
  ) => {
    const vendorId = (getState() as ApplicationState).vendors.currentVendor?.id;

    try {
      return await api().vendorsVendorIdLegislationsPost({
        vendorId,
        body: {
          legislation: params,
        },
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while posting vendor legislation. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);
