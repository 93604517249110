/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  Checklist,
  ChecklistFromJSON,
  ChecklistFromJSONTyped,
  ChecklistToJSON,
  ChecklistCheckable,
  ChecklistCheckableFromJSON,
  ChecklistCheckableFromJSONTyped,
  ChecklistCheckableToJSON,
  ChecklistExtendedAllOf,
  ChecklistExtendedAllOfFromJSON,
  ChecklistExtendedAllOfFromJSONTyped,
  ChecklistExtendedAllOfToJSON,
  ChecklistExtendedAllOfChecklistVendorUsers,
  ChecklistExtendedAllOfChecklistVendorUsersFromJSON,
  ChecklistExtendedAllOfChecklistVendorUsersFromJSONTyped,
  ChecklistExtendedAllOfChecklistVendorUsersToJSON,
  ChecklistItemsGroups,
  ChecklistItemsGroupsFromJSON,
  ChecklistItemsGroupsFromJSONTyped,
  ChecklistItemsGroupsToJSON,
  Survey,
  SurveyFromJSON,
  SurveyFromJSONTyped,
  SurveyToJSON,
} from './';

/**
 *
 * @export
 * @interface ChecklistExtended
 */
export interface ChecklistExtended extends Checklist {
  /**
   *
   * @type {string}
   * @memberof ChecklistExtended
   */
  relatedTo: ChecklistExtendedRelatedToEnum;
  /**
   *
   * @type {Array<ChecklistItemsGroups>}
   * @memberof ChecklistExtended
   */
  itemsGroups: Array<ChecklistItemsGroups>;
  /**
   *
   * @type {Array<ChecklistExtendedAllOfChecklistVendorUsers>}
   * @memberof ChecklistExtended
   */
  checklistVendorUsers: Array<ChecklistExtendedAllOfChecklistVendorUsers>;
}

export function ChecklistExtendedFromJSON(json: any): ChecklistExtended {
  return ChecklistExtendedFromJSONTyped(json, false);
}

export function ChecklistExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...ChecklistFromJSONTyped(json, ignoreDiscriminator),
    relatedTo: json['related_to'],
    itemsGroups: (json['items_groups'] as Array<any>).map(
      ChecklistItemsGroupsFromJSON
    ),
    checklistVendorUsers: (json['checklist_vendor_users'] as Array<any>).map(
      ChecklistExtendedAllOfChecklistVendorUsersFromJSON
    ),
  };
}

export function ChecklistExtendedToJSON(value?: ChecklistExtended | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...ChecklistToJSON(value),
    related_to: value.relatedTo,
    items_groups: (value.itemsGroups as Array<any>).map(
      ChecklistItemsGroupsToJSON
    ),
    checklist_vendor_users: (value.checklistVendorUsers as Array<any>).map(
      ChecklistExtendedAllOfChecklistVendorUsersToJSON
    ),
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ChecklistExtendedRelatedToEnum {
  VendorUser = 'vendor_user',
}
