/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PestelItemLocationType {
  Internal = 'internal',
  External = 'external',
}

export function PestelItemLocationTypeFromJSON(
  json: any
): PestelItemLocationType {
  return PestelItemLocationTypeFromJSONTyped(json, false);
}

export function PestelItemLocationTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PestelItemLocationType {
  return json as PestelItemLocationType;
}

export function PestelItemLocationTypeToJSON(
  value?: PestelItemLocationType | null
): any {
  return value as any;
}
