import React, { useEffect } from 'react';
import './AssessmentsMenu.scss';
import { assessmentsMenuConfig } from './AssessmentsMenuConfig';
import AssessmentsMenuItem from '../AssessmentsMenuItem/AssessmentsMenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectAssessmentsTab } from '../../selectors/selectAssessmentsTab';
import { saveSelectedAssessmentTab } from '../../store/assessments/assessmentsSlice';
import { AssessmentTabs } from '../../store/assessments/assessmentsState';
import { push } from 'connected-react-router';
import { selectVendorProductsFeatures } from '../../selectors/pricing/selectVendorProductsFeatures';
import { isProductPaid } from '../MainMenuItems/mainMenuConfig';

const AssessmentsMenu: React.FC = () => {
  const dispatch = useDispatch();
  const tabToShow = useSelector(selectAssessmentsTab);
  const features = useSelector(selectVendorProductsFeatures);
  const isPaid = isProductPaid('assessments', features);

  useEffect(() => {
    dispatch(saveSelectedAssessmentTab(tabToShow));

    if (
      location.pathname.includes('outgoing') &&
      tabToShow === AssessmentTabs.Incoming
    ) {
      dispatch(push('r/riskManagement/assessments/incoming'));
    }
  }, [tabToShow]);

  return (
    <div className="assessmentsMenu">
      {assessmentsMenuConfig(isPaid).map((item, index) => {
        return <AssessmentsMenuItem key={index} item={item} />;
      })}
    </div>
  );
};

export default AssessmentsMenu;

export const assessmentsMenuVisibleRoute = '/r/riskManagement/assessments';
