import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { fetchEscrowAccounts } from '../../store/vendors/vendorsThunks';
import { ApplicationState } from '../../types/applicationState';
import { Grid } from '../../types/grid';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { Intent, Lozenge } from '../Lozenge/Lozenge';
import { NoDataText } from '../NoDataText/NoDataText';
import { SortableTableHeader } from '../SortableTableHeader/SortableTableHeader';
import './EscrowAccounts.scss';
import { EscrowAccount } from '../../swagger/models/EscrowAccount';

export const EscrowAccountsPage: React.FC = () => {
  const dispatch = useDispatch();
  const currentVendor = useSelector(selectCurrentVendor);
  const baseCss = 'escrowAccounts';
  const isFetchingEscrowAccounts = useSelector(
    (state: ApplicationState) => state.vendors.isFetchingEscrowAccounts
  );
  const escrowAccounts = useSelector(
    (state: ApplicationState) => state.vendors.escrowAccounts
  );

  useEffect(() => {
    dispatch(fetchEscrowAccounts(currentVendor?.id));
  }, [currentVendor?.id]);

  if (isFetchingEscrowAccounts) {
    return <LoadingSpinner />;
  }

  return (
    <div className={baseCss}>
      <div className={baseCss + '--header'}>
        <div className={baseCss + '--title'}>
          Accounts
          <Lozenge intent={Intent.None} value={escrowAccounts.length} />
        </div>
      </div>

      {escrowAccounts.length > 0 ? (
        <>
          <div className={baseCss + '--description'}>
            Existing escrow accounts.
          </div>
          <AdoptechTable>
            <thead>
              <tr>
                <SortableTableHeader<EscrowAccount>
                  columnName="name"
                  grid={Grid.EscrowAccounts}
                  label="Name"
                />
                <SortableTableHeader<EscrowAccount>
                  columnName="accountNumber"
                  grid={Grid.EscrowAccounts}
                  label="Account number"
                />
                <SortableTableHeader<EscrowAccount>
                  columnName="accessKey"
                  grid={Grid.EscrowAccounts}
                  label="Access key"
                />
                <SortableTableHeader<EscrowAccount>
                  columnName="accountType"
                  grid={Grid.EscrowAccounts}
                  label="Type"
                />
                <SortableTableHeader<EscrowAccount>
                  columnName="status"
                  grid={Grid.EscrowAccounts}
                  label="Status"
                />
                <SortableTableHeader<EscrowAccount>
                  columnName="bucketName"
                  grid={Grid.EscrowAccounts}
                  label="Bucket name"
                />
                <SortableTableHeader<EscrowAccount>
                  columnName="updatedAt"
                  grid={Grid.EscrowAccounts}
                  label="Last updated"
                />
              </tr>
            </thead>
            <tbody>
              {escrowAccounts.map(ea => {
                return (
                  <tr key={ea.id}>
                    <td>{ea.name}</td>
                    <td>{ea.accountNumber}</td>
                    <td>{ea.accessKey}</td>
                    <td>{ea.accountType}</td>
                    <td>{ea.status}</td>
                    <td>{ea.bucketName}</td>
                    <td>{formatShortDate(ea.updatedAt)}</td>
                  </tr>
                );
              })}
            </tbody>
          </AdoptechTable>
        </>
      ) : (
        <NoDataText text="No escrow account found" />
      )}
    </div>
  );
};

export default EscrowAccountsPage;
