/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PestelItemArea {
  Political = 'political',
  EconomicCommercial = 'economic_commercial',
  Social = 'social',
  Technological = 'technological',
  Environmental = 'environmental',
  Legal = 'legal',
}

export function PestelItemAreaFromJSON(json: any): PestelItemArea {
  return PestelItemAreaFromJSONTyped(json, false);
}

export function PestelItemAreaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PestelItemArea {
  return json as PestelItemArea;
}

export function PestelItemAreaToJSON(value?: PestelItemArea | null): any {
  return value as any;
}
