import { useHistory, useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const useRemoveQueryParam = () => {
  const history = useHistory();

  const queryParams = useQueryParams();

  return (key: string) => {
    queryParams.delete(key);

    history.push({
      search: queryParams.toString(),
    });
  };
};
