import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../types/applicationState';
import {
  AgreementsStats,
  calculateSurveyProgress,
} from './selectAgreementsProgress';

export const selectAuditsProgress = createSelector(
  (state: ApplicationState) => state.audits.currentAudit?.survey,
  (survey): AgreementsStats => {
    return calculateSurveyProgress(survey);
  }
);
