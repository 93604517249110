/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrusthubUserModel
 */
export interface TrusthubUserModel {
  /**
   *
   * @type {string}
   * @memberof TrusthubUserModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubUserModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubUserModel
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubUserModel
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubUserModel
   */
  fullName?: string | null;
}

export function TrusthubUserModelFromJSON(json: any): TrusthubUserModel {
  return TrusthubUserModelFromJSONTyped(json, false);
}

export function TrusthubUserModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubUserModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    email: !exists(json, 'email') ? undefined : json['email'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    fullName: !exists(json, 'full_name') ? undefined : json['full_name'],
  };
}

export function TrusthubUserModelToJSON(value?: TrusthubUserModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    email: value.email,
    first_name: value.firstName,
    last_name: value.lastName,
    full_name: value.fullName,
  };
}
