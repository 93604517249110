import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import './InputField.scss';

interface InputFieldProps {
  autoFocus?: boolean;
  disableAutoComplete?: boolean;
  disabled?: boolean;
  isPassword?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  type: 'email' | 'password' | 'text';
  value?: string;
}

export const InputField: React.FC<InputFieldProps> = props => {
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(props.value);
  const [showPassword, setShowPassword] = useState(false);
  const inputAreaClasses = classNames({
    'inputField--inputArea': true,
    'inputField--inputArea-light': value === props.placeholder,
  });

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    if (props.onChange) {
      props.onChange(e.currentTarget.value);
    }
  };

  let controlType = props.type;

  if (props.type === 'password' && showPassword) {
    controlType = 'text';
  }

  useEffect(() => {
    if (props.autoFocus) {
      inputFieldRef.current.focus();
    }
  }, []);

  useEffect(() => setValue(props.value), [props.value]);

  return (
    <div className="inputField">
      <input
        autoComplete={props.disableAutoComplete ? 'new-password' : undefined}
        className={inputAreaClasses}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onChange={handleChange}
        ref={inputFieldRef}
        type={controlType}
        value={value}
      />
      {props.type === 'password' && (
        <div className="inputField--showPasswordButton">
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          />
        </div>
      )}
    </div>
  );
};
