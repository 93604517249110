/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Image, ImageFromJSON, ImageFromJSONTyped, ImageToJSON } from './';

/**
 *
 * @export
 * @interface TrusthubSettingsModel
 */
export interface TrusthubSettingsModel {
  /**
   *
   * @type {string}
   * @memberof TrusthubSettingsModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubSettingsModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubSettingsModel
   */
  domain: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubSettingsModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubSettingsModel
   */
  description: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TrusthubSettingsModel
   */
  published: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TrusthubSettingsModel
   */
  useColourGradient?: boolean;
  /**
   *
   * @type {string}
   * @memberof TrusthubSettingsModel
   */
  brandingColor: string | null;
  /**
   *
   * @type {Image}
   * @memberof TrusthubSettingsModel
   */
  logo: Image;
  /**
   *
   * @type {Image}
   * @memberof TrusthubSettingsModel
   */
  favicon: Image;
}

export function TrusthubSettingsModelFromJSON(
  json: any
): TrusthubSettingsModel {
  return TrusthubSettingsModelFromJSONTyped(json, false);
}

export function TrusthubSettingsModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubSettingsModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    domain: json['domain'],
    email: json['email'],
    description: json['description'],
    published: json['published'],
    useColourGradient: !exists(json, 'use_colour_gradient')
      ? undefined
      : json['use_colour_gradient'],
    brandingColor: json['branding_color'],
    logo: ImageFromJSON(json['logo']),
    favicon: ImageFromJSON(json['favicon']),
  };
}

export function TrusthubSettingsModelToJSON(
  value?: TrusthubSettingsModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    domain: value.domain,
    email: value.email,
    description: value.description,
    published: value.published,
    use_colour_gradient: value.useColourGradient,
    branding_color: value.brandingColor,
    logo: ImageToJSON(value.logo),
    favicon: ImageToJSON(value.favicon),
  };
}
