/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReminderModel
 */
export interface ReminderModel {
  /**
   *
   * @type {string}
   * @memberof ReminderModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ReminderModel
   */
  type: ReminderModelTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ReminderModel
   */
  relatedToId: string | null;
  /**
   *
   * @type {string}
   * @memberof ReminderModel
   */
  relatedToType: ReminderModelRelatedToTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ReminderModel
   */
  recipientId?: string;
  /**
   *
   * @type {string}
   * @memberof ReminderModel
   */
  remindAt: string;
  /**
   *
   * @type {string}
   * @memberof ReminderModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ReminderModel
   */
  updatedAt?: string;
}

export function ReminderModelFromJSON(json: any): ReminderModel {
  return ReminderModelFromJSONTyped(json, false);
}

export function ReminderModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReminderModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    type: json['type'],
    relatedToId: json['related_to_id'],
    relatedToType: json['related_to_type'],
    recipientId: !exists(json, 'recipient_id')
      ? undefined
      : json['recipient_id'],
    remindAt: json['remind_at'],
    createdAt: json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function ReminderModelToJSON(value?: ReminderModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    related_to_id: value.relatedToId,
    related_to_type: value.relatedToType,
    recipient_id: value.recipientId,
    remind_at: value.remindAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ReminderModelTypeEnum {
  ReminderReview = 'Reminder::Review',
}
/**
 * @export
 * @enum {string}
 */
export enum ReminderModelRelatedToTypeEnum {
  VendorPolicy = 'VendorPolicy',
  Agreement = 'Agreement',
}
