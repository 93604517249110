import { faFileArrowUp } from '@fortawesome/pro-light-svg-icons/faFileArrowUp';
import { push } from 'connected-react-router';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { uploadCompletedAgreement } from '../../../store/agreements/agreementsThunks';
import { hideDocumentUploadModal } from '../../../store/vendors/vendorsSlice';
import {
  fetchVendorDocuments,
  uploadVendorDocument,
} from '../../../store/vendors/vendorsThunks';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import AdoptechModal from '../../../components/AdoptechModal/AdoptechModal';
import { AdoptechReactSelect } from '../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechTextArea } from '../../../components/AdoptechTextArea/AdoptechTextArea';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { DropZone } from '../../../components/DropZone/DropZone';
import './UploadDocumentModal.scss';
import { SelectionOption } from '../../../types/selectionOption';
import { fetchPolicyTemplateCategories } from '../../../store/policies/policiesThunks';
import { allDocumentsPageRoute } from '../../../components/Routes/Routes';

export const UploadDocumentModal: React.FC = () => {
  const isAgreementMode = location.pathname.includes('agreements');
  const isDocumentMode = !isAgreementMode;

  const DocumentTypeEnum = {
    Policy: 'policy',
    Other: 'other',
  };

  const [description, setDescription] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [documentType, setDocumentType] = useState<string>(null);
  const [documentCategoryId, setDocumentCategoryId] = useState<string>(null);
  const [file, setFile] = useState<File>();
  const [nameError, setNameError] = useState(false);
  const documentNameRef = useRef<HTMLInputElement>();

  const currentVendor = useSelector(selectCurrentVendor);
  const {
    isDocumentUploadModalShowing,
    isUploadingVendorDocument: isUploadingDocument,
  } = useSelector((state: ApplicationState) => state.vendors.documentState);
  const policyCategories = useSelector(
    (state: ApplicationState) => state.policies.policyCategories
  );

  const policyCategoriesOptions: SelectionOption[] = policyCategories.map(
    category => {
      return {
        value: category.id,
        label: category.name,
      };
    }
  );
  const isUploadingAgreement = useSelector(
    (state: ApplicationState) => state.agreements.isUploadingAgreement
  );

  useEffect(() => {
    setDescription(null);
    setDocumentName(null);
    setDocumentCategoryId(null);
    setDocumentType(null);
    setFile(null);

    if (documentNameRef.current) {
      documentNameRef.current.focus();
    }

    isDocumentUploadModalShowing && dispatch(fetchPolicyTemplateCategories());
  }, [isDocumentUploadModalShowing]);

  const dispatch = useDispatch();

  const handleAgreementUpload = async () => {
    const payload = {
      id: currentVendor?.id,
      agreementName: documentName,
      agreementDescription: description,
      agreementDocument: file,
    };
    dispatch(uploadCompletedAgreement(payload));
  };

  const handleUpload = async () => {
    if (isAgreementMode) {
      handleAgreementUpload();
      return;
    }

    const payload = {
      vendorDocumentDocument: file,
      vendorDocumentName: documentName,
      vendorDocumentDocumentType: documentType,
      vendorDocumentDescription: description,
      vendorDocumentFileSizeInBytes: file.size,
      vendorDocumentPolicyCategoryId: documentCategoryId,
    };
    try {
      await dispatch(uploadVendorDocument(payload));
      dispatch(
        fetchVendorDocuments({
          vendorId: currentVendor?.id,
          queryShowHistory: true,
        })
      );
      dispatch(hideDocumentUploadModal());
      dispatch(push(allDocumentsPageRoute));
    } catch (error) {
      if (error?.includes('name')) {
        setNameError(true);
      }
    }
  };

  const title = isAgreementMode
    ? 'Upload your own agreement'
    : 'Add a new document';

  return (
    <AdoptechModal
      onHide={() => dispatch(hideDocumentUploadModal())}
      show={isDocumentUploadModalShowing}
      className="uploadDocumentModal"
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        {isDocumentMode && (
          <>
            <div className="uploadDocumentModal--title">
              Upload your own document here.
            </div>
            <div className="uploadDocumentModal--subTitle">
              Please note that we currently only accept PDF documents.
            </div>
          </>
        )}
        {isAgreementMode && (
          <div className="uploadDocumentModal--label">Agreement name*</div>
        )}
        <div className="uploadDocumentModal--body">
          <div className="uploadDocumentModal--leftBody">
            {isDocumentMode && (
              <div className="uploadDocumentModal--label">Document name*</div>
            )}
            <AdoptechTextInput
              id="uploadDocumentModal--documentNameInput"
              onChange={e => setDocumentName(e.currentTarget.value)}
              ref={documentNameRef}
              type="text"
              value={documentName}
              hasError={nameError}
            />
            <div className="uploadDocumentModal--documentTypeSelect">
              {isDocumentMode && (
                <AdoptechReactSelect
                  id="document_type"
                  onChange={value => setDocumentType(value.value)}
                  options={Object.entries(DocumentTypeEnum).map(
                    ([label, value]) => ({ label, value })
                  )}
                  label="Document type*"
                />
              )}

              {isAgreementMode && (
                <AdoptechReactSelect
                  id="document_type"
                  onChange={() => {}}
                  value={{ label: 'Agreement', value: 'agreement' }}
                  options={[{ label: 'Agreement', value: 'agreement' }]}
                  isDisabled
                  label="Document type*"
                />
              )}
            </div>

            {documentType === 'policy' && (
              <div className="uploadDocumentModal--documentCategorySelect">
                <AdoptechReactSelect
                  id="policy_category"
                  onChange={option => setDocumentCategoryId(option.value)}
                  options={policyCategoriesOptions}
                  label="Policy Category*"
                  value={policyCategoriesOptions.find(
                    option => option.value === documentCategoryId
                  )}
                />
              </div>
            )}

            <div className="uploadDocumentModal--label">Description</div>
            <AdoptechTextArea
              id="uploadDocumentModal--descriptionInput"
              onChange={e => setDescription(e.currentTarget.value)}
              value={description}
            />
          </div>
          <div className="uploadDocumentModal--rightBody">
            <DropZone
              dottedBorder
              placeholderText={
                isAgreementMode &&
                'Drag and drop your agreement here or <u>click</u> to select.'
              }
              onChange={setFile}
              accept=".pdf"
              icon={faFileArrowUp}
            />
          </div>
        </div>
        <div className="uploadDocumentModal--label">*Required fields</div>
      </Modal.Body>

      <Modal.Footer>
        <AdoptechButton onClick={() => dispatch(hideDocumentUploadModal())}>
          Cancel
        </AdoptechButton>
        {isAgreementMode && (
          <AdoptechButton
            busy={isUploadingAgreement}
            disabled={!file || !documentName}
            variant={AdoptechButtonVariant.Primary}
            onClick={handleUpload}
          >
            Upload
          </AdoptechButton>
        )}
        {isDocumentMode && (
          <AdoptechButton
            busy={isUploadingDocument}
            disabled={
              !file ||
              !documentName ||
              !documentType ||
              (documentType === 'policy' && !documentCategoryId)
            }
            variant={AdoptechButtonVariant.Primary}
            onClick={handleUpload}
          >
            Upload
          </AdoptechButton>
        )}
      </Modal.Footer>
    </AdoptechModal>
  );
};
