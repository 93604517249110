/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RiskReportCreatePayloadRiskReport
 */
export interface RiskReportCreatePayloadRiskReport {
  /**
   *
   * @type {boolean}
   * @memberof RiskReportCreatePayloadRiskReport
   */
  includeUnapprovedRisks?: boolean;
  /**
   *
   * @type {string}
   * @memberof RiskReportCreatePayloadRiskReport
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RiskReportCreatePayloadRiskReport
   */
  summary?: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskReportCreatePayloadRiskReport
   */
  ownerId?: string;
}

export function RiskReportCreatePayloadRiskReportFromJSON(
  json: any
): RiskReportCreatePayloadRiskReport {
  return RiskReportCreatePayloadRiskReportFromJSONTyped(json, false);
}

export function RiskReportCreatePayloadRiskReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskReportCreatePayloadRiskReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    includeUnapprovedRisks: !exists(json, 'include_unapproved_risks')
      ? undefined
      : json['include_unapproved_risks'],
    name: !exists(json, 'name') ? undefined : json['name'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
  };
}

export function RiskReportCreatePayloadRiskReportToJSON(
  value?: RiskReportCreatePayloadRiskReport | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    include_unapproved_risks: value.includeUnapprovedRisks,
    name: value.name,
    summary: value.summary,
    owner_id: value.ownerId,
  };
}
