/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AuditUpdatePayloadAudit,
  AuditUpdatePayloadAuditFromJSON,
  AuditUpdatePayloadAuditFromJSONTyped,
  AuditUpdatePayloadAuditToJSON,
} from './';

/**
 *
 * @export
 * @interface AuditUpdatePayload
 */
export interface AuditUpdatePayload {
  /**
   *
   * @type {AuditUpdatePayloadAudit}
   * @memberof AuditUpdatePayload
   */
  audit: AuditUpdatePayloadAudit;
}

export function AuditUpdatePayloadFromJSON(json: any): AuditUpdatePayload {
  return AuditUpdatePayloadFromJSONTyped(json, false);
}

export function AuditUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuditUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    audit: AuditUpdatePayloadAuditFromJSON(json['audit']),
  };
}

export function AuditUpdatePayloadToJSON(
  value?: AuditUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    audit: AuditUpdatePayloadAuditToJSON(value.audit),
  };
}
