import { createSelector } from '@reduxjs/toolkit';
import { getRadioButtonOptions } from './getRadioButtonOptions';
import { QuestionColumn } from '../swagger/models/QuestionColumn';

export const selectRadioButtonOptionsFactory = () => {
  return createSelector(getRadioButtonOptions, vendorTable => {
    let results: QuestionColumn[] = [];
    if (vendorTable) {
      results = vendorTable.rows.map(r => ({
        columnValue: r.value,
        columnText: r.cells[0],
      }));
    }
    return results;
  });
};
