import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  showPolicyDrawerForReadRequest,
  updatePolicyToEdit,
} from '../../store/policies/policiesSlice';
import { skipAttestation } from '../../store/policies/policiesThunks';
import { VendorDocumentExtended } from '../../swagger';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './ReadRequestModal.scss';

interface ReadRequestModalProps {
  vendorDocument: VendorDocumentExtended;
  onClose: () => void;
}

const ReadRequestModal: React.FC<ReadRequestModalProps> = ({
  vendorDocument,
  onClose,
}) => {
  const dispatch = useDispatch();
  if (!vendorDocument) {
    return null;
  }

  const handleCancel = () => {
    onClose();
  };

  const handleIgnore = () => {
    dispatch(
      skipAttestation({
        policyId: vendorDocument.id,
        onSuccess: onClose,
      })
    );
  };

  const openDrawer = () => {
    onClose();
    dispatch(showPolicyDrawerForReadRequest());
    dispatch(updatePolicyToEdit(vendorDocument));
  };

  return (
    <AdoptechModal onHide={handleCancel} show className="readRequestModal">
      <Modal.Header>Read request</Modal.Header>
      <Modal.Body className="documentLibraryModal--body">
        <p>
          This policy has not been shared with staff. You can choose to ‘Ignore’
          this or ‘Setup Read Request’.
        </p>
        <p>You can always access ‘Read requests’ in Policy Settings.</p>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={handleCancel}
          variant={AdoptechButtonVariant.Default}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          onClick={handleIgnore}
          variant={AdoptechButtonVariant.Primary}
        >
          Ignore
        </AdoptechButton>
        <AdoptechButton
          onClick={openDrawer}
          variant={AdoptechButtonVariant.SuccessFilled}
        >
          SETUP READ REQUEST
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};

export default ReadRequestModal;
