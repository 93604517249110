/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorPolicyAnswerClosestQuestion,
  VendorPolicyAnswerClosestQuestionFromJSON,
  VendorPolicyAnswerClosestQuestionFromJSONTyped,
  VendorPolicyAnswerClosestQuestionToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorPolicyAnswer
 */
export interface VendorPolicyAnswer {
  /**
   *
   * @type {string}
   * @memberof VendorPolicyAnswer
   */
  propertyName?: string;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyAnswer
   */
  updatedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyAnswer
   */
  updatedBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyAnswer
   */
  value?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyAnswer
   */
  displayValue?: string | null;
  /**
   *
   * @type {VendorPolicyAnswerClosestQuestion}
   * @memberof VendorPolicyAnswer
   */
  closestQuestion?: VendorPolicyAnswerClosestQuestion;
  /**
   *
   * @type {number}
   * @memberof VendorPolicyAnswer
   */
  index?: number | null;
}

export function VendorPolicyAnswerFromJSON(json: any): VendorPolicyAnswer {
  return VendorPolicyAnswerFromJSONTyped(json, false);
}

export function VendorPolicyAnswerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorPolicyAnswer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    propertyName: !exists(json, 'property_name')
      ? undefined
      : json['property_name'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    updatedBy: !exists(json, 'updated_by') ? undefined : json['updated_by'],
    value: !exists(json, 'value') ? undefined : json['value'],
    displayValue: !exists(json, 'display_value')
      ? undefined
      : json['display_value'],
    closestQuestion: !exists(json, 'closest_question')
      ? undefined
      : VendorPolicyAnswerClosestQuestionFromJSON(json['closest_question']),
    index: !exists(json, 'index') ? undefined : json['index'],
  };
}

export function VendorPolicyAnswerToJSON(
  value?: VendorPolicyAnswer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    property_name: value.propertyName,
    updated_at: value.updatedAt,
    updated_by: value.updatedBy,
    value: value.value,
    display_value: value.displayValue,
    closest_question: VendorPolicyAnswerClosestQuestionToJSON(
      value.closestQuestion
    ),
    index: value.index,
  };
}
