/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  TrusthubAccessRequestApprovePayload,
  TrusthubAccessRequestApprovePayloadFromJSON,
  TrusthubAccessRequestApprovePayloadToJSON,
  TrusthubAccessRequestCreatePayload,
  TrusthubAccessRequestCreatePayloadFromJSON,
  TrusthubAccessRequestCreatePayloadToJSON,
  TrusthubAccessRequestModel,
  TrusthubAccessRequestModelFromJSON,
  TrusthubAccessRequestModelToJSON,
  TrusthubCategoryModel,
  TrusthubCategoryModelFromJSON,
  TrusthubCategoryModelToJSON,
  TrusthubCompanyCreatePayload,
  TrusthubCompanyCreatePayloadFromJSON,
  TrusthubCompanyCreatePayloadToJSON,
  TrusthubCompanyExtended,
  TrusthubCompanyExtendedFromJSON,
  TrusthubCompanyExtendedToJSON,
  TrusthubCompanyModel,
  TrusthubCompanyModelFromJSON,
  TrusthubCompanyModelToJSON,
  TrusthubCompanyStatus,
  TrusthubCompanyStatusFromJSON,
  TrusthubCompanyStatusToJSON,
  TrusthubCompanyUpdatePayload,
  TrusthubCompanyUpdatePayloadFromJSON,
  TrusthubCompanyUpdatePayloadToJSON,
  TrusthubDocumentPermissionModel,
  TrusthubDocumentPermissionModelFromJSON,
  TrusthubDocumentPermissionModelToJSON,
  TrusthubInvitationCreatePayload,
  TrusthubInvitationCreatePayloadFromJSON,
  TrusthubInvitationCreatePayloadToJSON,
  TrusthubInvitationModel,
  TrusthubInvitationModelFromJSON,
  TrusthubInvitationModelToJSON,
  TrusthubInvitationUpdatePayload,
  TrusthubInvitationUpdatePayloadFromJSON,
  TrusthubInvitationUpdatePayloadToJSON,
  TrusthubSectionModel,
  TrusthubSectionModelFromJSON,
  TrusthubSectionModelToJSON,
  TrusthubSectionUpdatePayload,
  TrusthubSectionUpdatePayloadFromJSON,
  TrusthubSectionUpdatePayloadToJSON,
  TrusthubSettingsModel,
  TrusthubSettingsModelFromJSON,
  TrusthubSettingsModelToJSON,
  TrusthubUserModel,
  TrusthubUserModelFromJSON,
  TrusthubUserModelToJSON,
  TrusthubUserPasswordForgotPayload,
  TrusthubUserPasswordForgotPayloadFromJSON,
  TrusthubUserPasswordForgotPayloadToJSON,
  TrusthubUserPasswordResetPayload,
  TrusthubUserPasswordResetPayloadFromJSON,
  TrusthubUserPasswordResetPayloadToJSON,
  TrusthubUserSignInPayload,
  TrusthubUserSignInPayloadFromJSON,
  TrusthubUserSignInPayloadToJSON,
  TrusthubUserSignUpPayload,
  TrusthubUserSignUpPayloadFromJSON,
  TrusthubUserSignUpPayloadToJSON,
  TrusthubVendorUserActivityList,
  TrusthubVendorUserActivityListFromJSON,
  TrusthubVendorUserActivityListToJSON,
  TrusthubVendorUserCreatePayload,
  TrusthubVendorUserCreatePayloadFromJSON,
  TrusthubVendorUserCreatePayloadToJSON,
  TrusthubVendorUserExtended,
  TrusthubVendorUserExtendedFromJSON,
  TrusthubVendorUserExtendedToJSON,
  TrusthubVendorUserModel,
  TrusthubVendorUserModelFromJSON,
  TrusthubVendorUserModelToJSON,
  TrusthubVendorUserUpdatePayload,
  TrusthubVendorUserUpdatePayloadFromJSON,
  TrusthubVendorUserUpdatePayloadToJSON,
} from '../models';

export interface AccessRequestsAccessRequestIdApprovePatchRequest {
  accessRequestId: string;
  body?: TrusthubAccessRequestApprovePayload;
}

export interface AccessRequestsAccessRequestIdDenyPatchRequest {
  accessRequestId: string;
  notify?: boolean;
  comment?: string;
}

export interface AccessRequestsAccessRequestIdGetRequest {
  accessRequestId: string;
}

export interface CompaniesCompanyIdDeleteRequest {
  companyId: string;
}

export interface CompaniesCompanyIdGetRequest {
  companyId: string;
}

export interface CompaniesCompanyIdPatchRequest {
  companyId: string;
  body?: TrusthubCompanyUpdatePayload;
}

export interface DocumentPermissionsDocumentPermissionIdDeleteRequest {
  documentPermissionId: string;
}

export interface DocumentsDocumentIdDownloadGetRequest {
  documentId: string;
}

export interface SectionsSectionIdGetRequest {
  sectionId: string;
  includeTemplates?: boolean;
}

export interface SectionsSectionIdPatchRequest {
  sectionId: string;
  body: TrusthubSectionUpdatePayload;
}

export interface UsersConfirmationGetRequest {
  confirmationToken: string;
  vendorDomain: string;
}

export interface UsersInvitationPatchRequest {
  body: TrusthubInvitationUpdatePayload;
}

export interface UsersInvitationPostRequest {
  body: TrusthubInvitationCreatePayload;
}

export interface UsersInvitationsIdGetRequest {
  id: string;
}

export interface UsersPasswordPatchRequest {
  body?: TrusthubUserPasswordResetPayload;
}

export interface UsersPasswordPostRequest {
  body?: TrusthubUserPasswordForgotPayload;
}

export interface UsersPostRequest {
  body: TrusthubUserSignUpPayload;
}

export interface UsersSignInPostRequest {
  body?: TrusthubUserSignInPayload;
}

export interface VendorTrusthubsVendorIdOrDomainAccessRequestsGetRequest {
  vendorIdOrDomain: string;
  statuses?: Array<VendorTrusthubsVendorIdOrDomainAccessRequestsGetStatusesEnum>;
  perPage?: string;
  page?: string;
}

export interface VendorTrusthubsVendorIdOrDomainAccessRequestsPostRequest {
  vendorIdOrDomain: string;
  body?: TrusthubAccessRequestCreatePayload;
}

export interface VendorTrusthubsVendorIdOrDomainCategoriesGetRequest {
  vendorIdOrDomain: string;
}

export interface VendorTrusthubsVendorIdOrDomainCompaniesGetRequest {
  vendorIdOrDomain: string;
  statuses?: Array<TrusthubCompanyStatus>;
  perPage?: string;
  page?: string;
}

export interface VendorTrusthubsVendorIdOrDomainCompaniesPostRequest {
  vendorIdOrDomain: string;
  body?: TrusthubCompanyCreatePayload;
}

export interface VendorTrusthubsVendorIdOrDomainSectionsGetRequest {
  vendorIdOrDomain: string;
  trusthubVendorUserId?: string;
  includeInvisible?: boolean;
}

export interface VendorTrusthubsVendorIdOrDomainSettingsGetRequest {
  vendorIdOrDomain: string;
}

export interface VendorTrusthubsVendorIdOrDomainSettingsPatchRequest {
  vendorIdOrDomain: string;
  logo?: Blob;
  favicon?: Blob;
  name?: string;
  description?: string;
  domain?: string;
  email?: string;
  brandingColor?: string;
  published?: boolean;
  useColourGradient?: boolean;
}

export interface VendorTrusthubsVendorIdOrDomainVendorUsersActivityGetRequest {
  vendorIdOrDomain: string;
  trusthubVendorUserId?: string;
  perPage?: string;
  page?: string;
}

export interface VendorTrusthubsVendorIdOrDomainVendorUsersGetRequest {
  vendorIdOrDomain: string;
  trusthubCompanyId?: string;
  perPage?: string;
  page?: string;
  onlyAdmin?: boolean;
}

export interface VendorTrusthubsVendorIdOrDomainVendorUsersPostRequest {
  vendorIdOrDomain: string;
  body?: TrusthubVendorUserCreatePayload;
}

export interface VendorTrusthubsVendorTrusthubIdCurrentVendorUserDetailsGetRequest {
  vendorTrusthubId: string;
}

export interface VendorUsersVendorUserIdDocumentPermissionsGetRequest {
  vendorUserId: string;
}

export interface VendorUsersVendorUserIdGetRequest {
  vendorUserId: string;
}

export interface VendorUsersVendorUserIdPatchRequest {
  vendorUserId: string;
  body?: TrusthubVendorUserUpdatePayload;
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   * Approve Trusthub access request
   */
  async accessRequestsAccessRequestIdApprovePatchRaw(
    requestParameters: AccessRequestsAccessRequestIdApprovePatchRequest
  ): Promise<runtime.ApiResponse<TrusthubAccessRequestModel>> {
    if (
      requestParameters.accessRequestId === null ||
      requestParameters.accessRequestId === undefined
    ) {
      throw new runtime.RequiredError(
        'accessRequestId',
        'Required parameter requestParameters.accessRequestId was null or undefined when calling accessRequestsAccessRequestIdApprovePatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/access_requests/{access_request_id}/approve`.replace(
        `{${'access_request_id'}}`,
        encodeURIComponent(String(requestParameters.accessRequestId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubAccessRequestApprovePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubAccessRequestModelFromJSON(jsonValue)
    );
  }

  /**
   * Approve Trusthub access request
   */
  async accessRequestsAccessRequestIdApprovePatch(
    requestParameters: AccessRequestsAccessRequestIdApprovePatchRequest
  ): Promise<TrusthubAccessRequestModel> {
    const response =
      await this.accessRequestsAccessRequestIdApprovePatchRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Deny Trusthub access request
   */
  async accessRequestsAccessRequestIdDenyPatchRaw(
    requestParameters: AccessRequestsAccessRequestIdDenyPatchRequest
  ): Promise<runtime.ApiResponse<TrusthubAccessRequestModel>> {
    if (
      requestParameters.accessRequestId === null ||
      requestParameters.accessRequestId === undefined
    ) {
      throw new runtime.RequiredError(
        'accessRequestId',
        'Required parameter requestParameters.accessRequestId was null or undefined when calling accessRequestsAccessRequestIdDenyPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.notify !== undefined) {
      queryParameters['notify'] = requestParameters.notify;
    }

    if (requestParameters.comment !== undefined) {
      queryParameters['comment'] = requestParameters.comment;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/access_requests/{access_request_id}/deny`.replace(
        `{${'access_request_id'}}`,
        encodeURIComponent(String(requestParameters.accessRequestId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubAccessRequestModelFromJSON(jsonValue)
    );
  }

  /**
   * Deny Trusthub access request
   */
  async accessRequestsAccessRequestIdDenyPatch(
    requestParameters: AccessRequestsAccessRequestIdDenyPatchRequest
  ): Promise<TrusthubAccessRequestModel> {
    const response =
      await this.accessRequestsAccessRequestIdDenyPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Trusthub access request
   */
  async accessRequestsAccessRequestIdGetRaw(
    requestParameters: AccessRequestsAccessRequestIdGetRequest
  ): Promise<runtime.ApiResponse<TrusthubAccessRequestModel>> {
    if (
      requestParameters.accessRequestId === null ||
      requestParameters.accessRequestId === undefined
    ) {
      throw new runtime.RequiredError(
        'accessRequestId',
        'Required parameter requestParameters.accessRequestId was null or undefined when calling accessRequestsAccessRequestIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/access_requests/{access_request_id}`.replace(
        `{${'access_request_id'}}`,
        encodeURIComponent(String(requestParameters.accessRequestId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubAccessRequestModelFromJSON(jsonValue)
    );
  }

  /**
   * Get Trusthub access request
   */
  async accessRequestsAccessRequestIdGet(
    requestParameters: AccessRequestsAccessRequestIdGetRequest
  ): Promise<TrusthubAccessRequestModel> {
    const response =
      await this.accessRequestsAccessRequestIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete Trusthub company
   */
  async companiesCompanyIdDeleteRaw(
    requestParameters: CompaniesCompanyIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.companyId === null ||
      requestParameters.companyId === undefined
    ) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling companiesCompanyIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/companies/{company_id}`.replace(
        `{${'company_id'}}`,
        encodeURIComponent(String(requestParameters.companyId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Trusthub company
   */
  async companiesCompanyIdDelete(
    requestParameters: CompaniesCompanyIdDeleteRequest
  ): Promise<void> {
    await this.companiesCompanyIdDeleteRaw(requestParameters);
  }

  /**
   * Get Trusthub company
   */
  async companiesCompanyIdGetRaw(
    requestParameters: CompaniesCompanyIdGetRequest
  ): Promise<runtime.ApiResponse<TrusthubCompanyExtended>> {
    if (
      requestParameters.companyId === null ||
      requestParameters.companyId === undefined
    ) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling companiesCompanyIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/companies/{company_id}`.replace(
        `{${'company_id'}}`,
        encodeURIComponent(String(requestParameters.companyId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubCompanyExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get Trusthub company
   */
  async companiesCompanyIdGet(
    requestParameters: CompaniesCompanyIdGetRequest
  ): Promise<TrusthubCompanyExtended> {
    const response = await this.companiesCompanyIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update Trusthub company
   */
  async companiesCompanyIdPatchRaw(
    requestParameters: CompaniesCompanyIdPatchRequest
  ): Promise<runtime.ApiResponse<TrusthubCompanyExtended>> {
    if (
      requestParameters.companyId === null ||
      requestParameters.companyId === undefined
    ) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling companiesCompanyIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/companies/{company_id}`.replace(
        `{${'company_id'}}`,
        encodeURIComponent(String(requestParameters.companyId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubCompanyUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubCompanyExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Update Trusthub company
   */
  async companiesCompanyIdPatch(
    requestParameters: CompaniesCompanyIdPatchRequest
  ): Promise<TrusthubCompanyExtended> {
    const response = await this.companiesCompanyIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete Trusthub vendor user document permission
   */
  async documentPermissionsDocumentPermissionIdDeleteRaw(
    requestParameters: DocumentPermissionsDocumentPermissionIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.documentPermissionId === null ||
      requestParameters.documentPermissionId === undefined
    ) {
      throw new runtime.RequiredError(
        'documentPermissionId',
        'Required parameter requestParameters.documentPermissionId was null or undefined when calling documentPermissionsDocumentPermissionIdDelete.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/document_permissions/{document_permission_id}`.replace(
        `{${'document_permission_id'}}`,
        encodeURIComponent(String(requestParameters.documentPermissionId))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Trusthub vendor user document permission
   */
  async documentPermissionsDocumentPermissionIdDelete(
    requestParameters: DocumentPermissionsDocumentPermissionIdDeleteRequest
  ): Promise<void> {
    await this.documentPermissionsDocumentPermissionIdDeleteRaw(
      requestParameters
    );
  }

  /**
   * Get Trusthub document pdf
   */
  async documentsDocumentIdDownloadGetRaw(
    requestParameters: DocumentsDocumentIdDownloadGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.documentId === null ||
      requestParameters.documentId === undefined
    ) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling documentsDocumentIdDownloadGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/documents/{document_id}/download`.replace(
        `{${'document_id'}}`,
        encodeURIComponent(String(requestParameters.documentId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Get Trusthub document pdf
   */
  async documentsDocumentIdDownloadGet(
    requestParameters: DocumentsDocumentIdDownloadGetRequest
  ): Promise<void> {
    await this.documentsDocumentIdDownloadGetRaw(requestParameters);
  }

  /**
   * Get vendor Trusthub sections
   */
  async sectionsSectionIdGetRaw(
    requestParameters: SectionsSectionIdGetRequest
  ): Promise<runtime.ApiResponse<TrusthubSectionModel>> {
    if (
      requestParameters.sectionId === null ||
      requestParameters.sectionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sectionId',
        'Required parameter requestParameters.sectionId was null or undefined when calling sectionsSectionIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.includeTemplates !== undefined) {
      queryParameters['include_templates'] = requestParameters.includeTemplates;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/sections/{section_id}`.replace(
        `{${'section_id'}}`,
        encodeURIComponent(String(requestParameters.sectionId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubSectionModelFromJSON(jsonValue)
    );
  }

  /**
   * Get vendor Trusthub sections
   */
  async sectionsSectionIdGet(
    requestParameters: SectionsSectionIdGetRequest
  ): Promise<TrusthubSectionModel> {
    const response = await this.sectionsSectionIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update Trusthub section
   */
  async sectionsSectionIdPatchRaw(
    requestParameters: SectionsSectionIdPatchRequest
  ): Promise<runtime.ApiResponse<TrusthubSectionModel>> {
    if (
      requestParameters.sectionId === null ||
      requestParameters.sectionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sectionId',
        'Required parameter requestParameters.sectionId was null or undefined when calling sectionsSectionIdPatch.'
      );
    }

    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling sectionsSectionIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/sections/{section_id}`.replace(
        `{${'section_id'}}`,
        encodeURIComponent(String(requestParameters.sectionId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubSectionUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubSectionModelFromJSON(jsonValue)
    );
  }

  /**
   * Update Trusthub section
   */
  async sectionsSectionIdPatch(
    requestParameters: SectionsSectionIdPatchRequest
  ): Promise<TrusthubSectionModel> {
    const response = await this.sectionsSectionIdPatchRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the details of the current user
   */
  async userDetailsGetRaw(): Promise<runtime.ApiResponse<TrusthubUserModel>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/user_details`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubUserModelFromJSON(jsonValue)
    );
  }

  /**
   * Get the details of the current user
   */
  async userDetailsGet(): Promise<TrusthubUserModel> {
    const response = await this.userDetailsGetRaw();
    return await response.value();
  }

  /**
   * Confirm Trusthub User
   */
  async usersConfirmationGetRaw(
    requestParameters: UsersConfirmationGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.confirmationToken === null ||
      requestParameters.confirmationToken === undefined
    ) {
      throw new runtime.RequiredError(
        'confirmationToken',
        'Required parameter requestParameters.confirmationToken was null or undefined when calling usersConfirmationGet.'
      );
    }

    if (
      requestParameters.vendorDomain === null ||
      requestParameters.vendorDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorDomain',
        'Required parameter requestParameters.vendorDomain was null or undefined when calling usersConfirmationGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.confirmationToken !== undefined) {
      queryParameters['confirmation_token'] =
        requestParameters.confirmationToken;
    }

    if (requestParameters.vendorDomain !== undefined) {
      queryParameters['vendor_domain'] = requestParameters.vendorDomain;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users/confirmation`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Confirm Trusthub User
   */
  async usersConfirmationGet(
    requestParameters: UsersConfirmationGetRequest
  ): Promise<void> {
    await this.usersConfirmationGetRaw(requestParameters);
  }

  /**
   * Update Trusthub invitation
   */
  async usersInvitationPatchRaw(
    requestParameters: UsersInvitationPatchRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling usersInvitationPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/invitation`,
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubInvitationUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update Trusthub invitation
   */
  async usersInvitationPatch(
    requestParameters: UsersInvitationPatchRequest
  ): Promise<void> {
    await this.usersInvitationPatchRaw(requestParameters);
  }

  /**
   * Create Trusthub invitation
   */
  async usersInvitationPostRaw(
    requestParameters: UsersInvitationPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling usersInvitationPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/invitation`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubInvitationCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create Trusthub invitation
   */
  async usersInvitationPost(
    requestParameters: UsersInvitationPostRequest
  ): Promise<void> {
    await this.usersInvitationPostRaw(requestParameters);
  }

  /**
   * Get Trusthub invitation
   */
  async usersInvitationsIdGetRaw(
    requestParameters: UsersInvitationsIdGetRequest
  ): Promise<runtime.ApiResponse<TrusthubInvitationModel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling usersInvitationsIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users/invitations/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubInvitationModelFromJSON(jsonValue)
    );
  }

  /**
   * Get Trusthub invitation
   */
  async usersInvitationsIdGet(
    requestParameters: UsersInvitationsIdGetRequest
  ): Promise<TrusthubInvitationModel> {
    const response = await this.usersInvitationsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Set new password
   */
  async usersPasswordPatchRaw(
    requestParameters: UsersPasswordPatchRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/password`,
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubUserPasswordResetPayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Set new password
   */
  async usersPasswordPatch(
    requestParameters: UsersPasswordPatchRequest
  ): Promise<void> {
    await this.usersPasswordPatchRaw(requestParameters);
  }

  /**
   * Forgot my password
   */
  async usersPasswordPostRaw(
    requestParameters: UsersPasswordPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/password`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubUserPasswordForgotPayloadToJSON(requestParameters.body),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Forgot my password
   */
  async usersPasswordPost(
    requestParameters: UsersPasswordPostRequest
  ): Promise<void> {
    await this.usersPasswordPostRaw(requestParameters);
  }

  /**
   * Sign up to the platform
   */
  async usersPostRaw(
    requestParameters: UsersPostRequest
  ): Promise<runtime.ApiResponse<TrusthubUserModel>> {
    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling usersPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubUserSignUpPayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubUserModelFromJSON(jsonValue)
    );
  }

  /**
   * Sign up to the platform
   */
  async usersPost(
    requestParameters: UsersPostRequest
  ): Promise<TrusthubUserModel> {
    const response = await this.usersPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Sign in to the platform
   */
  async usersSignInPostRaw(
    requestParameters: UsersSignInPostRequest
  ): Promise<runtime.ApiResponse<TrusthubUserModel>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/users/sign_in`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubUserSignInPayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubUserModelFromJSON(jsonValue)
    );
  }

  /**
   * Sign in to the platform
   */
  async usersSignInPost(
    requestParameters: UsersSignInPostRequest
  ): Promise<TrusthubUserModel> {
    const response = await this.usersSignInPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Sign out of the platform
   */
  async usersSignOutDeleteRaw(): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users/sign_out`,
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Sign out of the platform
   */
  async usersSignOutDelete(): Promise<void> {
    await this.usersSignOutDeleteRaw();
  }

  /**
   * Get Trusthub access requests
   */
  async vendorTrusthubsVendorIdOrDomainAccessRequestsGetRaw(
    requestParameters: VendorTrusthubsVendorIdOrDomainAccessRequestsGetRequest
  ): Promise<runtime.ApiResponse<Array<TrusthubAccessRequestModel>>> {
    if (
      requestParameters.vendorIdOrDomain === null ||
      requestParameters.vendorIdOrDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIdOrDomain',
        'Required parameter requestParameters.vendorIdOrDomain was null or undefined when calling vendorTrusthubsVendorIdOrDomainAccessRequestsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.statuses) {
      queryParameters['statuses[]'] = requestParameters.statuses;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_id_or_domain}/access_requests`.replace(
        `{${'vendor_id_or_domain'}}`,
        encodeURIComponent(String(requestParameters.vendorIdOrDomain))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TrusthubAccessRequestModelFromJSON)
    );
  }

  /**
   * Get Trusthub access requests
   */
  async vendorTrusthubsVendorIdOrDomainAccessRequestsGet(
    requestParameters: VendorTrusthubsVendorIdOrDomainAccessRequestsGetRequest
  ): Promise<Array<TrusthubAccessRequestModel>> {
    const response =
      await this.vendorTrusthubsVendorIdOrDomainAccessRequestsGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Create Trusthub access request
   */
  async vendorTrusthubsVendorIdOrDomainAccessRequestsPostRaw(
    requestParameters: VendorTrusthubsVendorIdOrDomainAccessRequestsPostRequest
  ): Promise<runtime.ApiResponse<TrusthubAccessRequestModel>> {
    if (
      requestParameters.vendorIdOrDomain === null ||
      requestParameters.vendorIdOrDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIdOrDomain',
        'Required parameter requestParameters.vendorIdOrDomain was null or undefined when calling vendorTrusthubsVendorIdOrDomainAccessRequestsPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_id_or_domain}/access_requests`.replace(
        `{${'vendor_id_or_domain'}}`,
        encodeURIComponent(String(requestParameters.vendorIdOrDomain))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubAccessRequestCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubAccessRequestModelFromJSON(jsonValue)
    );
  }

  /**
   * Create Trusthub access request
   */
  async vendorTrusthubsVendorIdOrDomainAccessRequestsPost(
    requestParameters: VendorTrusthubsVendorIdOrDomainAccessRequestsPostRequest
  ): Promise<TrusthubAccessRequestModel> {
    const response =
      await this.vendorTrusthubsVendorIdOrDomainAccessRequestsPostRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get vendor Trusthub categories
   */
  async vendorTrusthubsVendorIdOrDomainCategoriesGetRaw(
    requestParameters: VendorTrusthubsVendorIdOrDomainCategoriesGetRequest
  ): Promise<runtime.ApiResponse<Array<TrusthubCategoryModel>>> {
    if (
      requestParameters.vendorIdOrDomain === null ||
      requestParameters.vendorIdOrDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIdOrDomain',
        'Required parameter requestParameters.vendorIdOrDomain was null or undefined when calling vendorTrusthubsVendorIdOrDomainCategoriesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_id_or_domain}/categories`.replace(
        `{${'vendor_id_or_domain'}}`,
        encodeURIComponent(String(requestParameters.vendorIdOrDomain))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TrusthubCategoryModelFromJSON)
    );
  }

  /**
   * Get vendor Trusthub categories
   */
  async vendorTrusthubsVendorIdOrDomainCategoriesGet(
    requestParameters: VendorTrusthubsVendorIdOrDomainCategoriesGetRequest
  ): Promise<Array<TrusthubCategoryModel>> {
    const response =
      await this.vendorTrusthubsVendorIdOrDomainCategoriesGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get Trusthub companies
   */
  async vendorTrusthubsVendorIdOrDomainCompaniesGetRaw(
    requestParameters: VendorTrusthubsVendorIdOrDomainCompaniesGetRequest
  ): Promise<runtime.ApiResponse<Array<TrusthubCompanyModel>>> {
    if (
      requestParameters.vendorIdOrDomain === null ||
      requestParameters.vendorIdOrDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIdOrDomain',
        'Required parameter requestParameters.vendorIdOrDomain was null or undefined when calling vendorTrusthubsVendorIdOrDomainCompaniesGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.statuses) {
      queryParameters['statuses[]'] = requestParameters.statuses;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_id_or_domain}/companies`.replace(
        `{${'vendor_id_or_domain'}}`,
        encodeURIComponent(String(requestParameters.vendorIdOrDomain))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TrusthubCompanyModelFromJSON)
    );
  }

  /**
   * Get Trusthub companies
   */
  async vendorTrusthubsVendorIdOrDomainCompaniesGet(
    requestParameters: VendorTrusthubsVendorIdOrDomainCompaniesGetRequest
  ): Promise<Array<TrusthubCompanyModel>> {
    const response =
      await this.vendorTrusthubsVendorIdOrDomainCompaniesGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Create Trusthub company
   */
  async vendorTrusthubsVendorIdOrDomainCompaniesPostRaw(
    requestParameters: VendorTrusthubsVendorIdOrDomainCompaniesPostRequest
  ): Promise<runtime.ApiResponse<TrusthubCompanyExtended>> {
    if (
      requestParameters.vendorIdOrDomain === null ||
      requestParameters.vendorIdOrDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIdOrDomain',
        'Required parameter requestParameters.vendorIdOrDomain was null or undefined when calling vendorTrusthubsVendorIdOrDomainCompaniesPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_id_or_domain}/companies`.replace(
        `{${'vendor_id_or_domain'}}`,
        encodeURIComponent(String(requestParameters.vendorIdOrDomain))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubCompanyCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubCompanyExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create Trusthub company
   */
  async vendorTrusthubsVendorIdOrDomainCompaniesPost(
    requestParameters: VendorTrusthubsVendorIdOrDomainCompaniesPostRequest
  ): Promise<TrusthubCompanyExtended> {
    const response =
      await this.vendorTrusthubsVendorIdOrDomainCompaniesPostRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get vendor Trusthub sections
   */
  async vendorTrusthubsVendorIdOrDomainSectionsGetRaw(
    requestParameters: VendorTrusthubsVendorIdOrDomainSectionsGetRequest
  ): Promise<runtime.ApiResponse<Array<TrusthubSectionModel>>> {
    if (
      requestParameters.vendorIdOrDomain === null ||
      requestParameters.vendorIdOrDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIdOrDomain',
        'Required parameter requestParameters.vendorIdOrDomain was null or undefined when calling vendorTrusthubsVendorIdOrDomainSectionsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.trusthubVendorUserId !== undefined) {
      queryParameters['trusthub_vendor_user_id'] =
        requestParameters.trusthubVendorUserId;
    }

    if (requestParameters.includeInvisible !== undefined) {
      queryParameters['include_invisible'] = requestParameters.includeInvisible;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_id_or_domain}/sections`.replace(
        `{${'vendor_id_or_domain'}}`,
        encodeURIComponent(String(requestParameters.vendorIdOrDomain))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TrusthubSectionModelFromJSON)
    );
  }

  /**
   * Get vendor Trusthub sections
   */
  async vendorTrusthubsVendorIdOrDomainSectionsGet(
    requestParameters: VendorTrusthubsVendorIdOrDomainSectionsGetRequest
  ): Promise<Array<TrusthubSectionModel>> {
    const response =
      await this.vendorTrusthubsVendorIdOrDomainSectionsGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get vendor Trusthub settings
   */
  async vendorTrusthubsVendorIdOrDomainSettingsGetRaw(
    requestParameters: VendorTrusthubsVendorIdOrDomainSettingsGetRequest
  ): Promise<runtime.ApiResponse<TrusthubSettingsModel>> {
    if (
      requestParameters.vendorIdOrDomain === null ||
      requestParameters.vendorIdOrDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIdOrDomain',
        'Required parameter requestParameters.vendorIdOrDomain was null or undefined when calling vendorTrusthubsVendorIdOrDomainSettingsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_id_or_domain}/settings`.replace(
        `{${'vendor_id_or_domain'}}`,
        encodeURIComponent(String(requestParameters.vendorIdOrDomain))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubSettingsModelFromJSON(jsonValue)
    );
  }

  /**
   * Get vendor Trusthub settings
   */
  async vendorTrusthubsVendorIdOrDomainSettingsGet(
    requestParameters: VendorTrusthubsVendorIdOrDomainSettingsGetRequest
  ): Promise<TrusthubSettingsModel> {
    const response =
      await this.vendorTrusthubsVendorIdOrDomainSettingsGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Update vendor Trusthub settings
   */
  async vendorTrusthubsVendorIdOrDomainSettingsPatchRaw(
    requestParameters: VendorTrusthubsVendorIdOrDomainSettingsPatchRequest
  ): Promise<runtime.ApiResponse<TrusthubSettingsModel>> {
    if (
      requestParameters.vendorIdOrDomain === null ||
      requestParameters.vendorIdOrDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIdOrDomain',
        'Required parameter requestParameters.vendorIdOrDomain was null or undefined when calling vendorTrusthubsVendorIdOrDomainSettingsPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.logo !== undefined) {
      formParams.append('logo', requestParameters.logo as any);
    }

    if (requestParameters.favicon !== undefined) {
      formParams.append('favicon', requestParameters.favicon as any);
    }

    if (requestParameters.name !== undefined) {
      formParams.append('name', requestParameters.name as any);
    }

    if (requestParameters.description !== undefined) {
      formParams.append('description', requestParameters.description as any);
    }

    if (requestParameters.domain !== undefined) {
      formParams.append('domain', requestParameters.domain as any);
    }

    if (requestParameters.email !== undefined) {
      formParams.append('email', requestParameters.email as any);
    }

    if (requestParameters.brandingColor !== undefined) {
      formParams.append(
        'branding_color',
        requestParameters.brandingColor as any
      );
    }

    if (requestParameters.published !== undefined) {
      formParams.append('published', requestParameters.published as any);
    }

    if (requestParameters.useColourGradient !== undefined) {
      formParams.append(
        'use_colour_gradient',
        requestParameters.useColourGradient as any
      );
    }

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_id_or_domain}/settings`.replace(
        `{${'vendor_id_or_domain'}}`,
        encodeURIComponent(String(requestParameters.vendorIdOrDomain))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubSettingsModelFromJSON(jsonValue)
    );
  }

  /**
   * Update vendor Trusthub settings
   */
  async vendorTrusthubsVendorIdOrDomainSettingsPatch(
    requestParameters: VendorTrusthubsVendorIdOrDomainSettingsPatchRequest
  ): Promise<TrusthubSettingsModel> {
    const response =
      await this.vendorTrusthubsVendorIdOrDomainSettingsPatchRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get Trusthub vendor user activity
   */
  async vendorTrusthubsVendorIdOrDomainVendorUsersActivityGetRaw(
    requestParameters: VendorTrusthubsVendorIdOrDomainVendorUsersActivityGetRequest
  ): Promise<runtime.ApiResponse<TrusthubVendorUserActivityList>> {
    if (
      requestParameters.vendorIdOrDomain === null ||
      requestParameters.vendorIdOrDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIdOrDomain',
        'Required parameter requestParameters.vendorIdOrDomain was null or undefined when calling vendorTrusthubsVendorIdOrDomainVendorUsersActivityGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.trusthubVendorUserId !== undefined) {
      queryParameters['trusthub_vendor_user_id'] =
        requestParameters.trusthubVendorUserId;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_id_or_domain}/vendor_users/activity`.replace(
        `{${'vendor_id_or_domain'}}`,
        encodeURIComponent(String(requestParameters.vendorIdOrDomain))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubVendorUserActivityListFromJSON(jsonValue)
    );
  }

  /**
   * Get Trusthub vendor user activity
   */
  async vendorTrusthubsVendorIdOrDomainVendorUsersActivityGet(
    requestParameters: VendorTrusthubsVendorIdOrDomainVendorUsersActivityGetRequest
  ): Promise<TrusthubVendorUserActivityList> {
    const response =
      await this.vendorTrusthubsVendorIdOrDomainVendorUsersActivityGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get Trusthub vendor users
   */
  async vendorTrusthubsVendorIdOrDomainVendorUsersGetRaw(
    requestParameters: VendorTrusthubsVendorIdOrDomainVendorUsersGetRequest
  ): Promise<runtime.ApiResponse<Array<TrusthubVendorUserModel>>> {
    if (
      requestParameters.vendorIdOrDomain === null ||
      requestParameters.vendorIdOrDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIdOrDomain',
        'Required parameter requestParameters.vendorIdOrDomain was null or undefined when calling vendorTrusthubsVendorIdOrDomainVendorUsersGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.trusthubCompanyId !== undefined) {
      queryParameters['trusthub_company_id'] =
        requestParameters.trusthubCompanyId;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.onlyAdmin !== undefined) {
      queryParameters['only_admin'] = requestParameters.onlyAdmin;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_id_or_domain}/vendor_users`.replace(
        `{${'vendor_id_or_domain'}}`,
        encodeURIComponent(String(requestParameters.vendorIdOrDomain))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TrusthubVendorUserModelFromJSON)
    );
  }

  /**
   * Get Trusthub vendor users
   */
  async vendorTrusthubsVendorIdOrDomainVendorUsersGet(
    requestParameters: VendorTrusthubsVendorIdOrDomainVendorUsersGetRequest
  ): Promise<Array<TrusthubVendorUserModel>> {
    const response =
      await this.vendorTrusthubsVendorIdOrDomainVendorUsersGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Create Trusthub vendor user
   */
  async vendorTrusthubsVendorIdOrDomainVendorUsersPostRaw(
    requestParameters: VendorTrusthubsVendorIdOrDomainVendorUsersPostRequest
  ): Promise<runtime.ApiResponse<TrusthubVendorUserExtended>> {
    if (
      requestParameters.vendorIdOrDomain === null ||
      requestParameters.vendorIdOrDomain === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorIdOrDomain',
        'Required parameter requestParameters.vendorIdOrDomain was null or undefined when calling vendorTrusthubsVendorIdOrDomainVendorUsersPost.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_id_or_domain}/vendor_users`.replace(
        `{${'vendor_id_or_domain'}}`,
        encodeURIComponent(String(requestParameters.vendorIdOrDomain))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubVendorUserCreatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubVendorUserExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Create Trusthub vendor user
   */
  async vendorTrusthubsVendorIdOrDomainVendorUsersPost(
    requestParameters: VendorTrusthubsVendorIdOrDomainVendorUsersPostRequest
  ): Promise<TrusthubVendorUserExtended> {
    const response =
      await this.vendorTrusthubsVendorIdOrDomainVendorUsersPostRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get the details of the current vendor user
   */
  async vendorTrusthubsVendorTrusthubIdCurrentVendorUserDetailsGetRaw(
    requestParameters: VendorTrusthubsVendorTrusthubIdCurrentVendorUserDetailsGetRequest
  ): Promise<runtime.ApiResponse<TrusthubVendorUserModel>> {
    if (
      requestParameters.vendorTrusthubId === null ||
      requestParameters.vendorTrusthubId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorTrusthubId',
        'Required parameter requestParameters.vendorTrusthubId was null or undefined when calling vendorTrusthubsVendorTrusthubIdCurrentVendorUserDetailsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_trusthubs/{vendor_trusthub_id}/current_vendor_user_details`.replace(
        `{${'vendor_trusthub_id'}}`,
        encodeURIComponent(String(requestParameters.vendorTrusthubId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubVendorUserModelFromJSON(jsonValue)
    );
  }

  /**
   * Get the details of the current vendor user
   */
  async vendorTrusthubsVendorTrusthubIdCurrentVendorUserDetailsGet(
    requestParameters: VendorTrusthubsVendorTrusthubIdCurrentVendorUserDetailsGetRequest
  ): Promise<TrusthubVendorUserModel> {
    const response =
      await this.vendorTrusthubsVendorTrusthubIdCurrentVendorUserDetailsGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get Trusthub vendor user document permissions
   */
  async vendorUsersVendorUserIdDocumentPermissionsGetRaw(
    requestParameters: VendorUsersVendorUserIdDocumentPermissionsGetRequest
  ): Promise<runtime.ApiResponse<Array<TrusthubDocumentPermissionModel>>> {
    if (
      requestParameters.vendorUserId === null ||
      requestParameters.vendorUserId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorUserId',
        'Required parameter requestParameters.vendorUserId was null or undefined when calling vendorUsersVendorUserIdDocumentPermissionsGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_users/{vendor_user_id}/document_permissions`.replace(
        `{${'vendor_user_id'}}`,
        encodeURIComponent(String(requestParameters.vendorUserId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TrusthubDocumentPermissionModelFromJSON)
    );
  }

  /**
   * Get Trusthub vendor user document permissions
   */
  async vendorUsersVendorUserIdDocumentPermissionsGet(
    requestParameters: VendorUsersVendorUserIdDocumentPermissionsGetRequest
  ): Promise<Array<TrusthubDocumentPermissionModel>> {
    const response =
      await this.vendorUsersVendorUserIdDocumentPermissionsGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   * Get Trusthub vendor user
   */
  async vendorUsersVendorUserIdGetRaw(
    requestParameters: VendorUsersVendorUserIdGetRequest
  ): Promise<runtime.ApiResponse<TrusthubVendorUserExtended>> {
    if (
      requestParameters.vendorUserId === null ||
      requestParameters.vendorUserId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorUserId',
        'Required parameter requestParameters.vendorUserId was null or undefined when calling vendorUsersVendorUserIdGet.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/vendor_users/{vendor_user_id}`.replace(
        `{${'vendor_user_id'}}`,
        encodeURIComponent(String(requestParameters.vendorUserId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubVendorUserExtendedFromJSON(jsonValue)
    );
  }

  /**
   * Get Trusthub vendor user
   */
  async vendorUsersVendorUserIdGet(
    requestParameters: VendorUsersVendorUserIdGetRequest
  ): Promise<TrusthubVendorUserExtended> {
    const response =
      await this.vendorUsersVendorUserIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * update Trusthub vendor user
   */
  async vendorUsersVendorUserIdPatchRaw(
    requestParameters: VendorUsersVendorUserIdPatchRequest
  ): Promise<runtime.ApiResponse<TrusthubVendorUserExtended>> {
    if (
      requestParameters.vendorUserId === null ||
      requestParameters.vendorUserId === undefined
    ) {
      throw new runtime.RequiredError(
        'vendorUserId',
        'Required parameter requestParameters.vendorUserId was null or undefined when calling vendorUsersVendorUserIdPatch.'
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request({
      path: `/vendor_users/{vendor_user_id}`.replace(
        `{${'vendor_user_id'}}`,
        encodeURIComponent(String(requestParameters.vendorUserId))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: TrusthubVendorUserUpdatePayloadToJSON(requestParameters.body),
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TrusthubVendorUserExtendedFromJSON(jsonValue)
    );
  }

  /**
   * update Trusthub vendor user
   */
  async vendorUsersVendorUserIdPatch(
    requestParameters: VendorUsersVendorUserIdPatchRequest
  ): Promise<TrusthubVendorUserExtended> {
    const response =
      await this.vendorUsersVendorUserIdPatchRaw(requestParameters);
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum VendorTrusthubsVendorIdOrDomainAccessRequestsGetStatusesEnum {
  Pending = 'pending',
  Approved = 'approved',
  Denied = 'denied',
}
