/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VendorSupplierStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export function VendorSupplierStatusFromJSON(json: any): VendorSupplierStatus {
  return VendorSupplierStatusFromJSONTyped(json, false);
}

export function VendorSupplierStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorSupplierStatus {
  return json as VendorSupplierStatus;
}

export function VendorSupplierStatusToJSON(
  value?: VendorSupplierStatus | null
): any {
  return value as any;
}
