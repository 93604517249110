import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import './CalendarPage.scss';
import { AdoptechButtonVariant } from '../../../components/AdoptechButton/AdoptechButton';
import {
  createActionReference,
  createCorrectiveAction,
  createCorrectiveActionParams,
  createEvent,
  createEventParams,
  createIncident,
  createIncidentParams,
  createNewAction,
  createNewActionParams,
  deleteAction,
  deleteActionReference,
  deleteCorrectiveAction,
  deleteEvent,
  deleteIncident,
  updateAction,
  updateActionParams,
  updateCorrectiveAction,
  updateCorrectiveActionParams,
  updateEvent,
  updateIncident,
  updateIncidentParams,
} from '../store/calendarThunks';
import { ApplicationState } from '../../../types/applicationState';
import {
  CorrectiveActionExtended,
  CorrectiveActionModel,
  CorrectiveActionsCorrectiveActionIdPatchCorrectiveActionActionTypeEnum,
  IncidentEventExtended,
  IncidentEventModel,
  ReferenceCreatePayloadReferenceReferenceableTypeEnum,
  SharedAttachmentModel,
  VendorActionExtended,
  VendorActionModel,
  VendorEventEventType,
  VendorEventExtended,
  VendorEventModel,
  VendorsVendorIdCorrectiveActionsPostCorrectiveActionActionTypeEnum,
} from '../../../swagger';
import { EditActionModal } from '../../../components/EditActionModal/EditActionModal';
import { showGlobalToast } from '../../../store/global/globalSlice';
import { CommandConfirmation } from '../../../types/CommandConfirmation';
import { ConfirmationModal } from '../../../components/ConfirmationModal/ConfirmationModal';
import { EditEventModal } from '../EditEventModal/EditEventModal';
import { selectVendorDetails } from '../../../selectors/selectVendorDetails';
import { calendarRoute } from '../../../components/Routes/Routes';
import { EditCorrectiveActionModal } from '../EditCorrectiveActionModal/EditCorrectiveActionModal';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { EditIncidentModal } from '../EditIncidentModal/EditIncidentModal';
import { useHistory, useParams } from 'react-router';
import { push } from 'connected-react-router';
import { CurrentCalendarItem } from './CalendarPage';

interface CalendarFormAttachment extends SharedAttachmentModel {
  rawFile?: File;
}
type CalendarForm = {
  deletedAttachmentIds?: SharedAttachmentModel['id'][];
  attachments?: CalendarFormAttachment[];
};

export type EventFormType = VendorEventExtended & CalendarForm;
export type ActionFormType = VendorActionExtended & CalendarForm;
export type CorrectiveActionFormType = CorrectiveActionExtended & CalendarForm;
export type IncidentFormType = IncidentEventExtended & CalendarForm;

const mapFormAttachmentsToPayload = (attachments: CalendarFormAttachment[]) => {
  return (attachments || [])
    .filter((attachment: CalendarFormAttachment) => attachment.rawFile)
    .map((attachment: CalendarFormAttachment) => attachment.rawFile);
};
// we use "map payload" to use multiple attachments
export const mapVendorActonFormToPostPayload = (params: ActionFormType) => {
  const { name, owner, description, dueDate, actionType, attachments, url } =
    params;
  const payload: createNewActionParams['payload'] = {
    vendorActionName: name,
    vendorActionOwnerId: owner.id,
    vendorActionDescription: description,
    vendorActionDueDate: dueDate,
    vendorActionActionType: actionType,
    vendorActionUrl: url,
    vendorActionAttachments: mapFormAttachmentsToPayload(attachments),
  };
  return payload;
};

const mapIncidentFormTypeToPostOrPatchPayload = (
  params: IncidentFormType
): createIncidentParams => {
  const {
    name,
    description,
    dateIdentified,
    raisedBy,
    rootCause,
    preventativeActions,
    actionsTaken,
    dataBreach,
    dataBreachDetails,
    url,
    attachments,
    identifier,
    owner,
  } = params;
  return {
    incidentEventName: name,
    incidentEventDescription: description,
    incidentEventDateIdentified: dateIdentified,
    incidentEventRaisedBy: raisedBy,
    incidentEventRootCause: rootCause,
    incidentEventPreventativeActions: preventativeActions,
    incidentEventActionsTaken: actionsTaken,
    incidentEventIdentifier: identifier,
    incidentEventOwnerId: owner?.id,
    incidentEventDataBreach: dataBreach,
    incidentEventDataBreachDetails: dataBreachDetails,
    incidentEventUrl: url,
    incidentEventAttachments: mapFormAttachmentsToPayload(attachments),
  };
};

const mapCorrectiveActionFormTypeToPostOrPatchPayload = (
  payload: CorrectiveActionFormType
): createCorrectiveActionParams => {
  const postOrPatchPayload: createCorrectiveActionParams = {
    correctiveActionName: payload.name,
    correctiveActionDescription: payload.description,
    correctiveActionDateIdentified: payload.dateIdentified,
    correctiveActionRaisedBy: payload.raisedBy,
    correctiveActionRootCause: payload.rootCause,
    correctiveActionActionType:
      payload.actionType as unknown as VendorsVendorIdCorrectiveActionsPostCorrectiveActionActionTypeEnum,
    correctiveActionCorrectiveActions: payload.correctiveActions,
    correctiveActionIdentifier: payload.identifier,
    correctiveActionOwnerId: payload.owner?.id,
    correctiveActionUrl: payload.url,
    correctiveActionAttachments: mapFormAttachmentsToPayload(
      payload.attachments
    ),
  };
  return postOrPatchPayload;
};
interface CalendarPageModalProps {
  refetchCurrentDateCalendarItems: () => void;
  cleanup: () => void;
  setShowEditEventModal: (value: boolean) => void;
  setShowCorrectiveActionModal: (value: boolean) => void;
  setShowIncidentModal: (value: boolean) => void;
  setShowEditActionModal: (value: boolean) => void;
  showEditEventModal: boolean;
  showEditActionModal: boolean;
  showCorrectiveActionModal: boolean;
  showIncidentModal: boolean;
  currentCalendarItem: CurrentCalendarItem;
  setCurrentCalendarItem: (value: CurrentCalendarItem) => void;
}

export const CalendarPageModals: React.FC<CalendarPageModalProps> = ({
  refetchCurrentDateCalendarItems,
  cleanup,
  setShowEditEventModal,
  setShowCorrectiveActionModal,
  setShowIncidentModal,
  setShowEditActionModal,
  showEditActionModal,
  showIncidentModal,
  showEditEventModal,
  showCorrectiveActionModal,
  currentCalendarItem,
  setCurrentCalendarItem,
}) => {
  const dispatch = useAppDispatch();
  const currentCalendarItemId = currentCalendarItem?.id;
  const currentCalendarItemType = currentCalendarItem?.type;

  const currentVendor = useSelector(selectVendorDetails);
  const [currentCommand, command] = useState<CommandConfirmation>(null);
  const { fetchCalendarItemsStatus } = useSelector(
    (state: ApplicationState) => state.calendar
  );
  const isFetchingItems = fetchCalendarItemsStatus === 'loading';

  useEffect(() => {
    if (!currentCalendarItemType) return;
    switch (currentCalendarItemType) {
      case 'event':
        setShowEditEventModal(true);
        break;
      case 'corrective-action':
        setShowCorrectiveActionModal(true);
        break;
      case 'incident':
        setShowIncidentModal(true);
        break;
      case 'action':
        setShowEditActionModal(true);
        break;
      default:
        alert('Unknown calendar item type');
    }
  }, [currentCalendarItemId]);

  const deleteReference = (
    action: VendorActionExtended,
    oldAction?: VendorActionExtended
  ) => {
    const typeChanged = oldAction.actionType !== action.actionType;

    const oldActionHasRegistry = isPestel(oldAction) || isLegal(oldAction);

    if (typeChanged && oldActionHasRegistry) {
      const referenceableId = isPestel(oldAction)
        ? currentVendor.registers.pestelRegisterId
        : currentVendor.registers.legalRegisterId;
      const referenceableType =
        oldAction.actionType === VendorEventEventType.PestelReview
          ? ReferenceCreatePayloadReferenceReferenceableTypeEnum.PestelRegister
          : ReferenceCreatePayloadReferenceReferenceableTypeEnum.LegalRegister;

      action?.id &&
        referenceableId &&
        dispatch(
          deleteActionReference(action?.id, referenceableId, referenceableType)
        );
    }
  };

  const afterCreateOrUpdate = async (
    action: VendorActionExtended,
    oldAction?: VendorActionExtended
  ) => {
    if (oldAction?.id) deleteReference(action, oldAction);

    if (isLegal(action)) {
      await dispatch(
        createActionReference(
          action.id,
          currentVendor.registers.legalRegisterId,
          ReferenceCreatePayloadReferenceReferenceableTypeEnum.LegalRegister,
          () => {}
        )
      );
    }

    if (isPestel(action)) {
      await dispatch(
        createActionReference(
          action.id,
          currentVendor.registers.pestelRegisterId,
          ReferenceCreatePayloadReferenceReferenceableTypeEnum.PestelRegister,
          () => {}
        )
      );
    }

    const message = 'Action was successfully saved';
    await refetchPageItems();
    dispatch(showGlobalToast(message));
    return;
  };

  const createOrUpdateAction = async (params: ActionFormType) => {
    const { completed, deletedAttachmentIds } = params;

    const createPayload = mapVendorActonFormToPostPayload(params);

    if (!currentActionId) {
      const newAction = await dispatch(
        createNewAction({ payload: createPayload })
      ).unwrap();

      afterCreateOrUpdate(newAction);

      return;
    }

    const updatePayload: updateActionParams = {
      ...createPayload,
      ...{ vendorActionDeletedAttachmentIds: deletedAttachmentIds },
      ...{ vendorActionCompleted: completed },
    };

    dispatch(updateAction(params, updatePayload, afterCreateOrUpdate));
  };

  const createOrUpdateIncident = async (params: IncidentFormType) => {
    const createPayload = mapIncidentFormTypeToPostOrPatchPayload(params);

    const message = 'Incident was successfully saved';
    if (!currentIncidentId) {
      try {
        await dispatch(createIncident(createPayload)).unwrap();
      } catch (error) {
        return;
      }
    } else {
      const updatePayload: updateIncidentParams = {
        ...createPayload,
        incidentEventCompleted: params.completed,
        incidentEventId: currentIncidentId,
        incidentEventDeletedAttachmentIds: params.deletedAttachmentIds,
      };
      try {
        await dispatch(updateIncident(updatePayload)).unwrap();
      } catch (error) {
        return;
      }
    }

    await refetchPageItems();
    dispatch(showGlobalToast(message));
    return;
  };

  const createOrUpdateEvent = async ({
    name,
    owner,
    description,
    date,
    eventType,
    url,
    attachments,
    deletedAttachmentIds,
  }: EventFormType) => {
    const payload: createEventParams = {
      vendorEventName: name,
      vendorEventOwnerId: owner.id,
      vendorEventDescription: description,
      vendorEventDate: date,
      vendorEventEventType: eventType,
      vendorEventUrl: url,
      vendorEventAttachments: mapFormAttachmentsToPayload(attachments),
    };

    const message = 'Event was saved successfully.';

    if (currentEventId) {
      const updatePayload = {
        ...payload,
        ...{ vendorEventDeletedAttachmentIds: deletedAttachmentIds },
        ...{ vendorEventId: currentEventId },
      };
      await dispatch(updateEvent(currentEventId, updatePayload, () => {}));
    } else {
      await dispatch(createEvent(payload));
    }

    await refetchPageItems();
    dispatch(showGlobalToast(message));
  };

  const createOrUpdateCorrectiveAction = async (
    params: CorrectiveActionFormType
  ) => {
    const createPayload: createCorrectiveActionParams =
      mapCorrectiveActionFormTypeToPostOrPatchPayload(params);

    const message = 'Corrective Action was successfully saved';

    if (!currentCorrectiveActionId) {
      try {
        const newAction = await dispatch(
          createCorrectiveAction(createPayload)
        ).unwrap();
      } catch (error) {
        return;
      }
    } else {
      const { completed, deletedAttachmentIds } = params;
      const updatePayload: updateCorrectiveActionParams = {
        correctiveActionId: currentCorrectiveActionId,
        ...createPayload,
        ...{
          correctiveActionCompleted: completed,
          correctiveActionDeletedAttachmentIds: deletedAttachmentIds,
          correctiveActionActionType:
            createPayload.correctiveActionActionType as unknown as CorrectiveActionsCorrectiveActionIdPatchCorrectiveActionActionTypeEnum,
        },
      };
      try {
        const updatedAction = await dispatch(
          updateCorrectiveAction(updatePayload)
        ).unwrap();
      } catch (error) {
        return;
      }
    }

    await refetchPageItems();
    dispatch(showGlobalToast(message));
    return;
  };

  // calculate currentActionId not from "items" because calendar item can be opened by direct link
  // and not visible in the items ( for example calendar item in the past)

  const currentActionId: VendorActionModel['id'] =
    currentCalendarItemType === 'action' ? currentCalendarItemId : undefined;

  const currentEventId: VendorEventModel['id'] =
    currentCalendarItemType === 'event' ? currentCalendarItemId : undefined;

  const currentCorrectiveActionId: CorrectiveActionModel['id'] =
    currentCalendarItemType === 'corrective-action'
      ? currentCalendarItemId
      : undefined;

  const currentIncidentId: IncidentEventModel['id'] =
    currentCalendarItemType === 'incident' ? currentCalendarItemId : undefined;

  const deleteActionCommand: CommandConfirmation = {
    title: 'Confirm Delete',
    subject: { name: 'TODO: item?.name', type: 'Action' },
    description: 'Click CONFIRM to delete this Action',
    buttonVariant: AdoptechButtonVariant.Warning,
    onConfirm: async () => {
      setShowEditActionModal(false);
      await dispatch(deleteAction(currentCalendarItemId));
      await refetchPageItems();
      dispatch(showGlobalToast('Action was deleted successfully.'));
    },
  };

  const deleteEventCommand: CommandConfirmation = {
    ...deleteActionCommand,
    description: 'Click CONFIRM to delete this Event',
    onConfirm: async () => {
      const id = currentCalendarItemId;
      setShowEditEventModal(false);

      await dispatch(deleteEvent(id));
      await refetchPageItems();
      dispatch(showGlobalToast('Event was deleted successfully.'));
    },
  };

  const deleteCorrectiveActionCommand: CommandConfirmation = {
    ...deleteActionCommand,
    description: 'Click CONFIRM to delete this Corrective Action',
    onConfirm: async () => {
      setShowCorrectiveActionModal(false);
      await dispatch(deleteCorrectiveAction(currentCalendarItemId));
      await refetchPageItems();
      dispatch(showGlobalToast('Corrective Action was deleted successfully.'));
    },
  };

  const deleteIncidentCommand: CommandConfirmation = {
    ...deleteActionCommand,
    description: 'Click CONFIRM to delete this Incident',
    onConfirm: async () => {
      setShowIncidentModal(false);
      await dispatch(deleteIncident(currentCalendarItemId));
      await refetchPageItems();
      dispatch(showGlobalToast('Incident was deleted successfully.'));
    },
  };

  // If we post/patch action type => post/delete action reference.

  // 3 cases: (Ignore risk/control related logic until risk register future ticket)
  // Create new action -> create new reference
  // Change action type legal-pestel or pestel-legal -> remove old reference async && create new reference
  // Change action type from legal/pestel -> remove old reference
  const isPestel = (calendarItem: VendorActionModel | VendorActionExtended) =>
    calendarItem.actionType === VendorEventEventType.PestelReview;
  const isLegal = (calendarItem: VendorActionModel | VendorActionExtended) =>
    calendarItem.actionType === VendorEventEventType.LegalRegisterReview;

  const refetchPageItems = async () => {
    setShowEditActionModal(false);
    setShowEditEventModal(false);
    setShowCorrectiveActionModal(false);
    setShowIncidentModal(false);
    cleanup();
    await refetchCurrentDateCalendarItems();
  };
  return (
    <>
      {showEditActionModal && (
        <EditActionModal
          init={{ id: currentActionId }}
          confirm={createOrUpdateAction}
          show={showEditActionModal}
          close={() => {
            setShowEditActionModal(false);
          }}
          onDelete={(id: VendorActionModel['id']) => {
            setShowEditActionModal(false);
            command(deleteActionCommand);
          }}
        />
      )}
      {showEditEventModal && (
        <EditEventModal
          init={currentEventId}
          confirm={createOrUpdateEvent}
          show={showEditEventModal}
          onDelete={(id: VendorEventModel['id']) => {
            setShowEditEventModal(false);
            command(deleteEventCommand);
          }}
          close={() => {
            setShowEditEventModal(false);
          }}
        />
      )}

      {showCorrectiveActionModal && (
        <EditCorrectiveActionModal
          init={currentCorrectiveActionId}
          confirm={createOrUpdateCorrectiveAction}
          show={showCorrectiveActionModal}
          onDelete={(id: CorrectiveActionExtended['id']) => {
            setShowCorrectiveActionModal(false);
            command(deleteCorrectiveActionCommand);
          }}
          close={() => {
            setShowCorrectiveActionModal(false);
          }}
        />
      )}

      {showIncidentModal && (
        <EditIncidentModal
          init={currentIncidentId}
          confirm={createOrUpdateIncident}
          show={showIncidentModal}
          onDelete={(id: IncidentEventExtended['id']) => {
            setShowIncidentModal(false);
            command(deleteIncidentCommand);
          }}
          close={() => {
            setShowIncidentModal(false);
          }}
        />
      )}

      <ConfirmationModal
        command={currentCommand}
        onCancel={confirmed => {
          // if we click cancel or outside delete modal, show edit modal
          if (!confirmed) {
            if (currentActionId) return setShowEditActionModal(true);
            if (currentEventId) return setShowEditEventModal(true);
            if (currentCorrectiveActionId)
              return setShowCorrectiveActionModal(true);
            if (currentIncidentId) return setShowIncidentModal(true);
          }
          command(null);
        }}
      />
    </>
  );
};
