import { faArrowSquareUp } from '@fortawesome/pro-light-svg-icons/faArrowSquareUp';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canCreate } from '../../functions/access';
import { capitalize } from '../../functions/capitalize';
import { formatShortDate } from '../../functions/formatShortDate';
import { selectArchivedDocuments } from '../../selectors/selectArchivedDocuments';
import { showEditOwnerModal } from '../../store/global/globalSlice';
import {
  deleteVendorDocument,
  unarchiveVendorDocument,
} from '../../store/vendors/vendorsThunks';
import { VendorDocument } from '../../swagger';
import { AccessObject } from '../../types/accessObject';
import { ApplicationState } from '../../types/applicationState';
import { Grid } from '../../types/grid';
import { OwnerObjectType } from '../../types/OwnerObjectType';
import { IconButton } from '../IconButton/IconButton';
import { NoDataText } from '../NoDataText/NoDataText';
import { SortableTableHeader } from '../SortableTableHeader/SortableTableHeader';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import './ArchivedDocuments.scss';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import AdoptechOverflowLine from '../AdoptechOverflowLine/AdoptechOverflowLine';
import AdoptechGridTable from '../AdoptechGridTable/AdoptechGridTable';

const ArchivedDocumentsRow: React.FC<{
  document: VendorDocument;
}> = ({ document }) => {
  const canManageDocuments = canCreate(AccessObject.vendor_documents);

  const archivingVendorDocumentId = useSelector(
    (state: ApplicationState) =>
      state.vendors.documentState.archivingVendorDocumentId
  );

  const isBusy = useSelector(
    (state: ApplicationState) =>
      state.vendors.documentState.isUnarchivingVendorDocument
  );

  const dispatch = useDispatch();

  const baseTableCss = 'adoptechGridTable';

  return (
    <div className={baseTableCss + '--row'}>
      <AdoptechOverflowLine>{document.name}</AdoptechOverflowLine>
      <div>{document.version}</div>
      <AdoptechOverflowLine>
        {capitalize(document.documentType)}
      </AdoptechOverflowLine>
      <div>{formatShortDate(document.updatedAt)}</div>
      <AdoptechOverflowLine>
        <div
          onClick={() =>
            canManageDocuments
              ? dispatch(
                  showEditOwnerModal({
                    object: document,
                    type: OwnerObjectType.VendorDocument,
                  })
                )
              : {}
          }
        >
          <UserAvatar user={document.owner} size="small" />
        </div>
      </AdoptechOverflowLine>
      <div className="adoptechGridTable--actions">
        <IconButton
          ariaLabel="Unarchive"
          icon={faArrowSquareUp}
          isBusy={isBusy && archivingVendorDocumentId === document.id}
          onClick={() => dispatch(unarchiveVendorDocument(document))}
          tooltip="Unarchive"
        />
        {canManageDocuments && (
          <>
            <IconButton
              ariaLabel="Edit owner"
              onClick={() => {
                dispatch(
                  showEditOwnerModal({
                    object: document,
                    type: OwnerObjectType.VendorDocument,
                  })
                );
              }}
              tooltip="Edit owner"
              icon={faFileContract}
            />
            <IconButton
              ariaLabel="Remove"
              onClick={() => {
                dispatch(deleteVendorDocument(document.id));
              }}
              tooltip="Remove"
              icon={faTrash}
            />
          </>
        )}
      </div>
    </div>
  );
};

export const ArchivedDocuments: React.FC = () => {
  const archivedDocuments = useSelector(selectArchivedDocuments);
  const baseTableCss = 'adoptechGridTable';

  return (
    <div className="archivedDocuments">
      {archivedDocuments.length > 0 ? (
        <AdoptechGridTable
          header={
            <div className={baseTableCss + '--header'}>
              <SortableTableHeader<VendorDocument>
                columnName="name"
                grid={Grid.ArchivedDocuments}
                label="Name"
              />
              <SortableTableHeader<VendorDocument>
                columnName="version"
                grid={Grid.ArchivedDocuments}
                label="Version"
              />
              <SortableTableHeader<VendorDocument>
                columnName="documentType"
                grid={Grid.ArchivedDocuments}
                label="Type"
              />
              <SortableTableHeader<VendorDocument>
                columnName="updatedAt"
                grid={Grid.ArchivedDocuments}
                label="Last updated"
              />
              <SortableTableHeader<VendorDocument>
                columnName="owner"
                grid={Grid.ArchivedDocuments}
                label="Owner"
              />
              <SortableTableHeader<VendorDocument>
                columnName="owner"
                grid={Grid.ArchivedDocuments}
                label="Actions"
                disabledSort
              />
            </div>
          }
        >
          {archivedDocuments.map(doc => (
            <ArchivedDocumentsRow key={doc.id} document={doc} />
          ))}
        </AdoptechGridTable>
      ) : (
        <NoDataText text="No documents found" />
      )}
    </div>
  );
};
