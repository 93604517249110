import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { capitaliseFirst } from '../../functions/capitaliseFirst';
import { hideDeletePaymentMethodModal } from '../../store/user/userSlice';
import { deletePaymentMethod } from '../../store/user/userThunks';
import { PaymentMethod } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './DeletePaymentMethodModal.scss';

interface DeletePaymentMethodModalProps {
  paymentMethod: PaymentMethod;
}

export const DeletePaymentMethodModal: React.FC<
  DeletePaymentMethodModalProps
> = props => {
  const { isDeletePaymentMethodModalShowing, isDeletingPaymentMethod } =
    useSelector((state: ApplicationState) => state.user);

  const dispatch = useDispatch();

  if (!props.paymentMethod) {
    return null;
  } else {
    return (
      <AdoptechModal
        onHide={() => dispatch(hideDeletePaymentMethodModal())}
        show={isDeletePaymentMethodModalShowing}
      >
        <Modal.Header>Delete payment method</Modal.Header>
        <Modal.Body>
          <div className="deletePaymentMethodModal--prompt">
            Please confirm that you wish to permanently delete this payment
            method?
          </div>
          <div>
            <div className="deletePaymentMethodModal--card">
              {capitaliseFirst(props.paymentMethod.card.brand)} ending in{' '}
              {props.paymentMethod.card.last4}, expiring{' '}
              {props.paymentMethod.card.expMonth}/
              {props.paymentMethod.card.expYear}.
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton
            onClick={() => {
              dispatch(hideDeletePaymentMethodModal());
            }}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            busy={isDeletingPaymentMethod}
            onClick={() => {
              dispatch(deletePaymentMethod(props.paymentMethod.id));
            }}
            variant={AdoptechButtonVariant.Primary}
          >
            Confirm
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    );
  }
};
