import { faCloudDownloadAlt } from '@fortawesome/pro-light-svg-icons/faCloudDownloadAlt';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  openPdfPreview,
  setPdfPreviewUrl,
} from '../../../store/global/globalSlice';
import { ApplicationState } from '../../../types/applicationState';
import { PdfOpenMode } from '../../../types/pdfOpenMode';
import './CompanyCertificatesPreviewPage.scss';
import { PdfPreviewCard } from '../../../components/PdfPreviewCard/PdfPreviewCard';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { AdoptechButton } from '../../../components/AdoptechButton/AdoptechButton';
import { Panel } from '../../../components/Panel/Panel';
import { companyProfileRoute } from '../../../components/Routes/Routes';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import {
  fetchCertificate,
  fetchCertificateDocument,
} from '../store/companyProfileThunks';
import { CertificateModel } from '../../../swagger/models/CertificateModel';
import { cleanCurrentCertificate } from '../store/companyProfileSlice';
import { DocumentType } from '../../../constants/DocumentType';
import { downloadReportFile } from '../../../components/Reports/ReportsList/ReportsList';

export const CompanyCertificatesPreviewPage: React.FC = () => {
  const baseCss = 'certificatesPreviewPage';
  const dispatch = useDispatch();
  const goBack = () => dispatch(push(companyProfileRoute));

  const { id } = useParams() as { id: CertificateModel['id'] };
  const pdfPreviewUrl = useSelector(
    (state: ApplicationState) => state.global.pdfPreviewUrl
  );
  const {
    currentCertificate,
    fetchingCertificatesStatus,
    fetchingCertificateDocumentStatus,
  } = useSelector((state: ApplicationState) => state.companyProfile);
  useEffect(() => {
    const afterCertificateFetched = (certificate: CertificateModel) => {
      dispatch(
        fetchCertificateDocument({
          id,
          successCallback: (url: string) => {
            openPreview(url, certificate.name);
          },
        })
      );
    };
    dispatch(fetchCertificate(id, afterCertificateFetched));
    return () => {
      dispatch(cleanCurrentCertificate());
      dispatch(setPdfPreviewUrl());
    };
  }, [id]);

  const openPreview = (url: string, name: string) =>
    dispatch(
      openPdfPreview({
        mode: PdfOpenMode.Preview,
        documentType: DocumentType.Certificate,
        title: name,
        inline: true,
        url,
      })
    );

  if (
    fetchingCertificatesStatus === 'loading' ||
    fetchingCertificateDocumentStatus === 'loading' ||
    !currentCertificate
  )
    return <LoadingSpinner />;

  return (
    <div className={baseCss}>
      <Panel>
        <div className={baseCss + '--progressBar'}>
          <ProgressBar progress={0} />
        </div>
        <div className={baseCss + '--mainContent'}>
          <div className={baseCss + '--back'} onClick={() => goBack()}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <div className={baseCss + '--info'}>
            <div className={baseCss + '--column'}>
              <div className={baseCss + '--columnHeader'}>Name</div>
              <div className={baseCss + '--columnData'}>
                {currentCertificate.name}
              </div>
            </div>
            <div
              className={classNames([
                baseCss + '--column',
                baseCss + '--downloadColumn',
              ])}
            >
              <AdoptechButton
                onClick={() => {
                  downloadReportFile(pdfPreviewUrl, currentCertificate.name);
                }}
                rounded
                disabled={!pdfPreviewUrl}
              >
                <FontAwesomeIcon
                  className={baseCss + '--downloadIcon'}
                  icon={faCloudDownloadAlt}
                />
                Download
              </AdoptechButton>
            </div>
          </div>
        </div>
      </Panel>
      <PdfPreviewCard waitingBeforeLoadingPDF={!pdfPreviewUrl} />
    </div>
  );
};
