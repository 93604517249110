/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShareAccessLevel,
  ShareAccessLevelFromJSON,
  ShareAccessLevelFromJSONTyped,
  ShareAccessLevelToJSON,
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsFromJSONTyped,
  UserDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface ShareReceiver
 */
export interface ShareReceiver {
  /**
   *
   * @type {UserDetails}
   * @memberof ShareReceiver
   */
  receiver: UserDetails;
  /**
   *
   * @type {ShareAccessLevel}
   * @memberof ShareReceiver
   */
  accessLevel: ShareAccessLevel;
}

export function ShareReceiverFromJSON(json: any): ShareReceiver {
  return ShareReceiverFromJSONTyped(json, false);
}

export function ShareReceiverFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShareReceiver {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    receiver: UserDetailsFromJSON(json['receiver']),
    accessLevel: ShareAccessLevelFromJSON(json['access_level']),
  };
}

export function ShareReceiverToJSON(value?: ShareReceiver | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    receiver: UserDetailsToJSON(value.receiver),
    access_level: ShareAccessLevelToJSON(value.accessLevel),
  };
}
