import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DocumentsSection.scss';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
import { TrustHubSectionProps } from '../../CompanyDetailsSection/CompanyDetailsSection';
import { useTrustHubSection } from '../../useTrustHubSection';
import { capitalize } from '../../../../functions/capitalize';
import { faCloudDownloadAlt } from '@fortawesome/pro-light-svg-icons/faCloudDownloadAlt';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../types/applicationState';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import classNames from 'classnames';
import { faBookAlt } from '@fortawesome/pro-light-svg-icons/faBookAlt';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { TrusthubDocumentModel } from '../../../../swagger/trusthub/models/TrusthubDocumentModel';
import {
  draftPoliciesPageRoute,
  reportsRoute,
} from '../../../../components/Routes/Routes';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { push } from 'connected-react-router';
import { DocumentsEditSection } from '../../admin/DocumentsEditSection/DocumentsEditSection';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';

export interface DocumentSectionProps extends TrustHubSectionProps {
  identifier: string;
}
export const InformationSecurityDocumentsSection: React.FC<
  DocumentSectionProps
> = ({
  isEditable,
  sections,
  identifier,
  onDocumentClick,
  showDocumentDownloadIcon,
}) => {
  const {
    isEditMode,
    setIsEditMode,
    section,
    publicDocs,
    baseCss,
    editSectionHandler,
    bulkActionsRow,
  } = useTrustHubSection({
    identifier,
    sections,
    isEditable,
  });
  const dispatch = useAppDispatch();
  const { fetchVendorDocumentFileStatus, fetchingVendorDocumentIdFile } =
    useSelector((state: ApplicationState) => state.trustHub);
  if (!section) return null;

  if (isEditMode) {
    return (
      <DocumentsEditSection
        section={section}
        bulkActionsRow={bulkActionsRow}
        onClose={() => setIsEditMode(false)}
        editSectionHandler={editSectionHandler}
        identifier={identifier}
        baseCss={baseCss}
      />
    );
  }

  const isReports = identifier === 'reports';

  return (
    <div className={baseCss}>
      <div className={baseCss + '--panel'}>
        <div className={baseCss + '--title'}>
          <div className={baseCss + '--titleInfo'}>
            <FontAwesomeIcon icon={isReports ? faServer : faBookAlt} />
            <div>{capitalize(identifier)}</div>
          </div>
          {isEditable && (
            <div>
              <AdoptechButton
                extraClass="editButton"
                icon={faPencil}
                onClick={() => setIsEditMode(true)}
                variant={AdoptechButtonVariant.White}
              >
                Edit
              </AdoptechButton>
            </div>
          )}
        </div>
        <div className={baseCss + '--documents'}>
          {publicDocs.map(doc => (
            <InformationSecurityDocumentsRow
              key={doc.name}
              doc={doc}
              baseCss={baseCss}
              onDocumentClick={onDocumentClick}
              isReports={isReports}
              fetchVendorDocumentFileStatus={fetchVendorDocumentFileStatus}
              fetchingVendorDocumentIdFile={fetchingVendorDocumentIdFile}
              showDocumentDownloadIcon={showDocumentDownloadIcon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const InformationSecurityDocumentsRow: React.FC<{
  doc: TrusthubDocumentModel;
  onDocumentClick: (doc: TrusthubDocumentModel) => void;
  isReports: boolean;
  fetchVendorDocumentFileStatus: string;
  fetchingVendorDocumentIdFile: string;
  showDocumentDownloadIcon: boolean;
  baseCss: string;
}> = ({
  doc,
  onDocumentClick,
  isReports,
  fetchVendorDocumentFileStatus,
  fetchingVendorDocumentIdFile,
  showDocumentDownloadIcon,
  baseCss,
}) => {
  return (
    <div
      className={baseCss + '--document'}
      onClick={() => onDocumentClick(doc)}
    >
      <div className={baseCss + '--documentTitle'}>
        {!isReports && <FontAwesomeIcon icon={faCheckCircle} />}
        {doc.name}
      </div>
      {fetchVendorDocumentFileStatus === 'loading' &&
      fetchingVendorDocumentIdFile === doc.id ? (
        <LoadingSpinner inlineSmall />
      ) : (
        <FontAwesomeIcon
          icon={doc.restricted ? faLockAlt : faCloudDownloadAlt}
          className={classNames({
            invisible: !showDocumentDownloadIcon,
          })}
        />
      )}
    </div>
  );
};
