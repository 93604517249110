/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ControlUpdateStatusPayloadControl,
  ControlUpdateStatusPayloadControlFromJSON,
  ControlUpdateStatusPayloadControlFromJSONTyped,
  ControlUpdateStatusPayloadControlToJSON,
} from './';

/**
 *
 * @export
 * @interface ControlUpdateStatusPayload
 */
export interface ControlUpdateStatusPayload {
  /**
   *
   * @type {ControlUpdateStatusPayloadControl}
   * @memberof ControlUpdateStatusPayload
   */
  control?: ControlUpdateStatusPayloadControl;
}

export function ControlUpdateStatusPayloadFromJSON(
  json: any
): ControlUpdateStatusPayload {
  return ControlUpdateStatusPayloadFromJSONTyped(json, false);
}

export function ControlUpdateStatusPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlUpdateStatusPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    control: !exists(json, 'control')
      ? undefined
      : ControlUpdateStatusPayloadControlFromJSON(json['control']),
  };
}

export function ControlUpdateStatusPayloadToJSON(
  value?: ControlUpdateStatusPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    control: ControlUpdateStatusPayloadControlToJSON(value.control),
  };
}
