import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './PolicyTiles.scss';
import { DashboardTile } from '../../../components/DashboardTile/DashboardTile';
import { faLaptopMobile } from '@fortawesome/pro-light-svg-icons/faLaptopMobile';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck';
import { faFileLock } from '@fortawesome/pro-light-svg-icons/faFileLock';
import { faSeedling } from '@fortawesome/pro-light-svg-icons/faSeedling';
import { faBookAlt } from '@fortawesome/pro-light-svg-icons/faBookAlt';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { ApplicationState } from '../../../types/applicationState';
import { fetchPolicyTemplateCategories } from '../../../store/policies/policiesThunks';
import { governancePoliciesLibraryRoute } from '../../../components/Routes/Routes';

const PolicyTiles: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPolicyTemplateCategories());
  }, []);

  const isRequestingCategories = useSelector(
    (state: ApplicationState) => state.policies.isFetchingPolicyCategories
  );

  const policyCategories = useSelector(
    (state: ApplicationState) => state.policies.policyCategories
  );

  const baseCss = 'policyTiles';

  const getIcon = (name: string): IconDefinition => {
    switch (name) {
      case 'All':
        return faBookAlt;
      case 'ESG':
        return faSeedling;
      case 'Team':
        return faUsers;
      case 'Data Protection':
        return faFileLock;
      case 'Compliance':
        return faCircleCheck;
      case 'IT & InfoSec':
        return faLaptopMobile;
      default:
        return faUsers;
    }
  };

  if (isRequestingCategories) {
    return <LoadingSpinner />;
  }

  return (
    <div className={baseCss}>
      <div className={baseCss + '--title'}>Templates</div>
      <div className="d-flex">
        {policyCategories.map(cat => {
          return (
            <DashboardTile
              key={cat.id}
              title={cat.name}
              icon={getIcon(cat.name)}
              action={() =>
                dispatch(
                  push(
                    `${governancePoliciesLibraryRoute}?policyTemplateCategoryName=${encodeURIComponent(
                      cat.name
                    )}`
                  )
                )
              }
              text={cat.summary}
              footerText="Select"
              className="dashboardTile--light-orange dashboardTile--medium"
            />
          );
        })}
      </div>
    </div>
  );
};

export default PolicyTiles;
