import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import './Pager.scss';

export interface Props {
  page: number;
  setPage(page: number): void;
  totalPages: number;
}

const baseCss = 'adoptechPager';

export const Pager: React.FC<Props> = ({ page, setPage, totalPages }) => {
  const goLeft = () => goTo(page - 1);
  const goTo = (pageNumber: number) => {
    setPage(Math.max(1, Math.min(pageNumber, totalPages)));
  };
  const goRight = () => goTo(page + 1);

  const leftEnd = page <= 1;
  const rightEnd = page >= totalPages;

  return (
    <div className={baseCss}>
      <FontAwesomeIcon
        className={leftEnd ? 'arrow-disabled' : 'arrow-active'}
        icon={faAngleLeft}
        onClick={goLeft}
      />
      Page
      <input
        id="pageNumber"
        onChange={e => goTo(+e.currentTarget.value)}
        value={page}
        type="number"
      />
      of {totalPages}
      <FontAwesomeIcon
        className={rightEnd ? 'arrow-disabled' : 'arrow-active'}
        icon={faAngleRight}
        onClick={goRight}
      />
    </div>
  );
};
