import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import AdoptechModal from '../../AdoptechModal/AdoptechModal';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { patchRisk } from '../../../store/riskRegistry/riskRegistryThunks';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { AdoptechTextArea2 } from '../../AdoptechTextArea2/AdoptechTextArea2';
import { useAutoGrowTextArea } from '../../../hooks/useAutoGrowTextArea';

interface EditRiskModalProps {
  onHide: () => void;
}

export const EditRiskModal: React.FC<EditRiskModalProps> = props => {
  const { currentRisk: model, patchRiskStatus } = useSelector(
    (state: ApplicationState) => state.riskRegistry
  );

  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    try {
      await dispatch(
        patchRisk({
          id: model.id,
          body: {
            risk: {
              name: form.name,
            },
          },
        })
      ).unwrap();
      props.onHide();
    } catch (error) {
      setError(error.message);
    }
  };

  const [form, setForm] = useState({ name: model.name });
  const [formCopy, setFormCopy] = useState({ name: model.name });
  const [error, setError] = useState<string | null>(null);
  const formChanged = form.name !== formCopy.name;
  const submitDisabled = form.name === '' || !formChanged;
  const isLoading = patchRiskStatus === 'loading';

  const nameRef = useRef<HTMLTextAreaElement>(null);
  useAutoGrowTextArea(nameRef.current, form.name);

  return (
    <AdoptechModal className="editRiskModal" onHide={props.onHide} show>
      <Modal.Header>Edit Risk</Modal.Header>
      <Modal.Body>
        <AdoptechTextArea2
          id="riskName"
          label="Risk Name"
          value={form.name}
          hasError={!!error}
          onChange={e => setForm({ ...form, name: e.target.value })}
          rows={5}
          ref={nameRef}
        />
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton disabled={isLoading} onClick={props.onHide}>
          Cancel
        </AdoptechButton>
        <AdoptechButton
          onClick={handleSubmit}
          disabled={submitDisabled}
          variant={AdoptechButtonVariant.Primary}
          busy={isLoading}
          uppercase
        >
          Update
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
