import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAuditIdToBeDeleted } from './store/auditsSlice';
import {
  useCanFeature,
  hasFeature,
  mapShareFeaturesToAccessLevels,
} from '../../../functions/access';
import { MeatballMenu } from '../../../components/MeatballMenu/MeatballMenu';
import { fetchAuditPDF } from './store/auditsThunks';
import { ApplicationState } from '../../../types/applicationState';
import { AccessObject } from '../../../types/accessObject';
import { showShareModal } from '../../../store/vendors/vendorsSlice';
import {
  AuditModel,
  AuditModelStatusEnum,
  ShareableType,
} from '../../../swagger';
import { fetchVendorDocumentDownload } from '../../../store/vendors/vendorsThunks';
import { auditType } from './AuditsPage';
import { capitaliseFirst } from '../../../functions/capitaliseFirst';

interface MeatballProps {
  audit: AuditModel;
  onClick: (audit: AuditModel) => void; // TODO: rename to onEditClick
}

export const auditAccess = (audit: AuditModel, isIndexPage: boolean) => {
  const completed = audit.status === AuditModelStatusEnum.Completed;
  return {
    canEdit: !completed,
    canDelete: isIndexPage,
    canView: completed,
    canDownload: completed,
  };
};

export const AuditsMeatballMenu: React.FC<MeatballProps> = ({
  audit,
  onClick,
}) => {
  const currentAudit = useSelector(
    (state: ApplicationState) => state.audits.currentAudit
  );
  const dispatch = useDispatch();
  const isIndexPage = !currentAudit;
  const access = auditAccess(audit, isIndexPage);
  const canManageAudits = useCanFeature(AccessObject.audits_manage);
  const hasVisibleSectionBeforeDelete = Object.entries(access).some(
    section => section[0] !== 'canDelete' && section[1]
  );

  return (
    <MeatballMenu>
      {access.canEdit && canManageAudits && (
        <Dropdown.Item
          className="meatballMenu--item"
          onClick={() => onClick(audit)}
        >
          <FontAwesomeIcon className="meatballMenu--icon" icon={faPen} />
          Edit
        </Dropdown.Item>
      )}
      {access.canView && (
        <Dropdown.Item
          onClick={() => {
            dispatch(fetchAuditPDF(audit));
          }}
        >
          <FontAwesomeIcon className="meatballMenu--icon" icon={faEye} />
          View
        </Dropdown.Item>
      )}
      <Dropdown.Item
        className="meatballMenu--item"
        disabled={!hasFeature(AccessObject.share, audit.access)}
        onClick={e => {
          dispatch(
            showShareModal({
              shareableId: audit.id,
              shareableType: ShareableType.Audit,
              accessLevels: mapShareFeaturesToAccessLevels(
                audit.access.feature
              ),
              title: capitaliseFirst(auditType(audit)),
              relativePath: audit.relativePath,
            })
          );
          e.stopPropagation();
        }}
      >
        <FontAwesomeIcon className="meatballMenu--icon" icon={faUserPlus} />
        Share
      </Dropdown.Item>
      {access.canDownload && (
        <Dropdown.Item
          onClick={() => {
            dispatch(
              fetchVendorDocumentDownload({
                id: audit.vendorDocumentId,
                successCallback: vendorDocumentDownload => {
                  window.location.href = vendorDocumentDownload.document;
                },
              })
            );
          }}
        >
          <FontAwesomeIcon
            className="meatballMenu--icon"
            icon={faCloudDownload}
          />
          Download
        </Dropdown.Item>
      )}
      {hasVisibleSectionBeforeDelete && isIndexPage && <Dropdown.Divider />}
      {access.canDelete && canManageAudits && (
        <Dropdown.Item
          className="meatballMenu--item"
          onClick={() => {
            dispatch(setAuditIdToBeDeleted(audit.id));
          }}
        >
          <FontAwesomeIcon className="meatballMenu--icon" icon={faTrashAlt} />
          Delete
        </Dropdown.Item>
      )}
    </MeatballMenu>
  );
};
