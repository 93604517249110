import { Interweave } from 'interweave';
import React from 'react';
import { ChecklistTemplateItem as ChecklistTemplateItemAPI } from '../../swagger';
import './ChecklistTemplateItem.scss';

interface ChecklistTemplateItemProps {
  item: ChecklistTemplateItemAPI;
}

export const ChecklistTemplateItem: React.FC<ChecklistTemplateItemProps> = ({
  item,
}) => {
  return (
    <div className="checklistTemplateItem">
      <div className="checklistTemplateItem--question">
        <div className="checklistTemplateItem--questionText">
          <Interweave content={item.questionTextAsHtml} />
        </div>
        <Interweave content={item.explanatoryTextAsHtml} />
      </div>
    </div>
  );
};
