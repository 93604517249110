/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  AttestationFrequencyEnum,
  AttestationFrequencyEnumFromJSON,
  AttestationFrequencyEnumFromJSONTyped,
  AttestationFrequencyEnumToJSON,
  ReviewFrequencyEnum,
  ReviewFrequencyEnumFromJSON,
  ReviewFrequencyEnumFromJSONTyped,
  ReviewFrequencyEnumToJSON,
  VendorDocument,
  VendorDocumentFromJSON,
  VendorDocumentFromJSONTyped,
  VendorDocumentToJSON,
  VendorDocumentApproval,
  VendorDocumentApprovalFromJSON,
  VendorDocumentApprovalFromJSONTyped,
  VendorDocumentApprovalToJSON,
  VendorDocumentApprovalStats,
  VendorDocumentApprovalStatsFromJSON,
  VendorDocumentApprovalStatsFromJSONTyped,
  VendorDocumentApprovalStatsToJSON,
  VendorDocumentAttestation,
  VendorDocumentAttestationFromJSON,
  VendorDocumentAttestationFromJSONTyped,
  VendorDocumentAttestationToJSON,
  VendorDocumentAttestationStats,
  VendorDocumentAttestationStatsFromJSON,
  VendorDocumentAttestationStatsFromJSONTyped,
  VendorDocumentAttestationStatsToJSON,
  VendorDocumentExtendedAllOf,
  VendorDocumentExtendedAllOfFromJSON,
  VendorDocumentExtendedAllOfFromJSONTyped,
  VendorDocumentExtendedAllOfToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorDocumentExtended
 */
export interface VendorDocumentExtended extends VendorDocument {
  /**
   *
   * @type {string}
   * @memberof VendorDocumentExtended
   */
  createdBy?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocumentExtended
   */
  updatedBy?: string | null;
}

export function VendorDocumentExtendedFromJSON(
  json: any
): VendorDocumentExtended {
  return VendorDocumentExtendedFromJSONTyped(json, false);
}

export function VendorDocumentExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorDocumentExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...VendorDocumentFromJSONTyped(json, ignoreDiscriminator),
    createdBy: !exists(json, 'created_by') ? undefined : json['created_by'],
    updatedBy: !exists(json, 'updated_by') ? undefined : json['updated_by'],
  };
}

export function VendorDocumentExtendedToJSON(
  value?: VendorDocumentExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...VendorDocumentToJSON(value),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
  };
}
