import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CompanyDetailsSection.scss';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import classNames from 'classnames';
import { TrusthubSettingsModel } from '../../../swagger/trusthub/models/TrusthubSettingsModel';
import { Interweave } from 'interweave';
import { CompanyDetailsEditSection } from '../admin/CompanyDetailsEditSection/CompanyDetailsEditSection';
import { TrusthubSectionModel } from '../../../swagger/trusthub/models/TrusthubSectionModel';
import { TrusthubDocumentModel } from '../../../swagger/trusthub/models/TrusthubDocumentModel';
import { faList } from '@fortawesome/pro-light-svg-icons/faList';

export interface TrustHubSectionProps {
  sections: TrusthubSectionModel[];
  isEditable: boolean; // show/hide Edit button
  settings: TrusthubSettingsModel;
  onDocumentClick?: (document: TrusthubDocumentModel) => void;
  showDocumentDownloadIcon: boolean;
  identifier?: string;
}

type CompanyDetailsSectionProps = Omit<
  Omit<TrustHubSectionProps, 'sections'>,
  'showDocumentDownloadIcon'
>;

export const CompanyDetailsSection: React.FC<CompanyDetailsSectionProps> = ({
  isEditable,
  settings,
}) => {
  const baseCss = 'companyDetailsSection';
  // Modes: noData, edit, show
  const isNoDataMode = !settings.description;
  const [isEditMode, setIsEditMode] = useState(false);

  if (isEditMode)
    return (
      <CompanyDetailsEditSection
        settings={settings}
        onClose={() => setIsEditMode(false)}
      />
    );

  return (
    <div
      className={classNames(baseCss, { [baseCss + '--noData']: isNoDataMode })}
    >
      <div className={baseCss + '--title'}>
        <div className={baseCss + '--titleInfo'}>
          <div>{[settings.name, 'TrustHub'].join(' ')}</div>
        </div>
      </div>
      <div className={baseCss + '--panel'}>
        <div className={baseCss + '--companyNameRow'}>
          <div className={baseCss + '--companyNameColumn'}>
            <div className={baseCss + '--companyNameAndEmail'}>
              <div className={baseCss + '--companyEmailRow'}>
                <FontAwesomeIcon icon={faEnvelope} />
                <div className={baseCss + '--companyEmailTitle'}>
                  {settings.email || 'Company Email'}
                </div>
              </div>
            </div>
          </div>
          {isEditable && (
            <div className={baseCss + '--editButton'}>
              <AdoptechButton
                icon={faPencil}
                extraClass="editButton"
                variant={AdoptechButtonVariant.White}
                onClick={() => setIsEditMode(true)}
              >
                Edit
              </AdoptechButton>
            </div>
          )}
        </div>
        <div className={baseCss + '--companyDescription'}>
          <Interweave
            content={settings.description || 'Company TrustHub Service Summary'}
          />
        </div>
      </div>
    </div>
  );
};
