import { VendorUser } from '../swagger';

export const getInitials = ({
  user,
  placeholder = ' ',
}: {
  user: VendorUser;
  placeholder?: string;
}) => {
  let initials;
  if (user.firstName || user.lastName) {
    // Use first name and last name if exists
    const firstInitial = user.firstName?.[0] || placeholder;
    const lastInitial = user.lastName?.[0] || placeholder;
    initials = firstInitial + lastInitial;
  } else if (user.fullName) {
    // Use full name otherwise
    const [first, second] = user.fullName
      .replace(/ +/gi, ' ') // remove multiple spaces in full name like External  Consultant
      .split(' ');
    const firstInitial = first?.[0] || placeholder;
    const secondInitial = second?.[0] || placeholder;
    initials = firstInitial + secondInitial;
  } else {
    // If no name is present, use email
    initials = user.email ? user.email[0] : '';
  }
  return initials.toUpperCase();
};
