import moment from 'moment';
import { sort } from './sort';

export const dateSort = (a: string, b: string, direction: number): number => {
  if (a && !b) {
    return direction;
  }

  if (b && !a) {
    return -direction;
  }

  if (!a && !b) {
    return 0;
  }

  return sort(moment(a), moment(b), direction);
};
