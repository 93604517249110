import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { filesize } from 'filesize';
import { Interweave } from 'interweave';
import React, { useRef, useState } from 'react';
import './DropZone.scss';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt';

type DropSingleFile = {
  multiple?: false;
  onChange: (file: File) => void;
};

type DropMultipleFiles = {
  multiple?: true;
  onChange: (files: File[]) => void;
};
type DropZoneProps = {
  accept?: string;
  additionalDescription?: string; // only displayed if file is not uploaded
  fileDescription?: string; // always displayed
  icon?: IconDefinition;
  placeholderText?: string;
  placeholderFileListText?: string;
  showPlaceholderText?: boolean;
  showFileName?: boolean;
  dottedBorder?: boolean;
  smallHeight?: boolean;
} & (DropMultipleFiles | DropSingleFile);

export const DropZone: React.FC<DropZoneProps> = ({
  showPlaceholderText = true,
  showFileName = true,
  ...props
}) => {
  const [dragCounter, setDragCounter] = useState(0);
  const [fileState, setFiles] = useState<File[]>([]);
  const files = fileState;
  const inputRef = useRef<HTMLInputElement>();

  type DragEvent = React.DragEvent<HTMLDivElement>;
  const dragOver = (e: DragEvent) => e.preventDefault();
  const dragEnter = (e: DragEvent) => {
    e.preventDefault();
    setDragCounter(c => c + 1);
  };
  const dragLeave = (e: DragEvent) => {
    e.preventDefault();
    setDragCounter(c => c - 1);
  };

  const verifyFile = (fileToVerify: File) => {
    if (!props.accept) return true;
    const allowedTypes = props.accept.split(',').map(x => x.replace(' ', ''));
    const parts = fileToVerify.name.split('.');

    return (
      props.accept !== '' &&
      allowedTypes
        .map(s => s.toLowerCase())
        .includes('.' + parts[parts.length - 1].toLowerCase())
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newFiles = e.currentTarget.files;

    const validFiles = Array.from(newFiles).filter(newFile =>
      verifyFile(newFile)
    );

    handleFileChanges(validFiles);
  };

  const handleFileDrop = (e: DragEvent) => {
    e.preventDefault();
    setDragCounter(0);
    if (!e.dataTransfer) return;
    const newFiles = e.dataTransfer.files;

    const validFiles = Array.from(newFiles).filter(newFile =>
      verifyFile(newFile)
    );

    handleFileChanges(validFiles);
  };

  const onChangeCall = (arr: File[]) =>
    props.multiple
      ? props.onChange(arr)
      : (props.onChange as DropSingleFile['onChange'])(arr[0]);

  const handleFileChanges = (newFiles: File[]) => {
    setFiles((prevFiles: File[]) => {
      const arr = props.multiple ? prevFiles.concat(newFiles) : newFiles;
      onChangeCall(arr);
      return arr;
    });
  };

  const dropZoneHitAreaClasses = classNames('dropZone--hitArea', {
    'dropZone--hitArea-dragging': dragCounter > 0,
    'dropZone--hitArea-dotted': props.dottedBorder,
    'dropZone--hitArea-smallHeight': props.smallHeight,
  });

  const baseCss = 'dropZone';

  const filesBaseCss = 'dropZoneFiles';

  const handleRemoveAttachment = (e: React.MouseEvent, file: File) => {
    e.preventDefault();
    setFiles((prevFiles: File[]) => {
      const arr = prevFiles.filter(f => f !== file);
      onChangeCall(arr);
      return arr;
    });
  };

  return (
    <>
      <div className={baseCss}>
        <div
          className={dropZoneHitAreaClasses}
          onClick={() => inputRef.current.click()}
          onDragOver={dragOver}
          data-testid="dropzone"
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onChange={handleFileDrop}
        >
          {!props.multiple && files.length > 0 ? (
            <>
              {showFileName && (
                <>
                  {files.map(fileObject => {
                    const isImage =
                      !!fileObject && fileObject.type.startsWith('image/');
                    return (
                      <div key={fileObject.name} className="dropZone--file">
                        {isImage ? (
                          <img
                            src={URL.createObjectURL(fileObject)}
                            className="dropZone--preview"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="dropZone--icon"
                            icon={faFile}
                          />
                        )}
                        <div className="dropZone--fileName">
                          {fileObject.name}
                        </div>
                        <div className="dropZone--fileSize">
                          {filesize(fileObject.size)}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
              <div className="dropZone--fileDescription">
                {props.fileDescription}
              </div>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                className="dropZone--icon"
                icon={props.icon || faCloudUpload}
              />
              <div className="dropZone--prompt">
                {showPlaceholderText && (
                  <div className="dropZone--placeholderText">
                    <Interweave
                      content={
                        props.placeholderText ||
                        `Drag and drop your file${props.multiple ? 's' : ''} here or <u>click</u> to select.`
                      }
                    />
                  </div>
                )}
                {props.additionalDescription && (
                  <div className="dropZone--additionalDescription">
                    {props.additionalDescription}
                  </div>
                )}

                <div className="dropZone--fileDescription">
                  {props.fileDescription}
                </div>
              </div>
            </>
          )}
          <input
            accept={props.accept}
            hidden
            data-testid="dropzone-input"
            onChange={handleInputChange}
            ref={inputRef}
            type="file"
            multiple={props.multiple}
          />
        </div>
      </div>
      <div className={filesBaseCss}>
        {props.multiple && (
          <>
            <div className={filesBaseCss + '--placeholder'}>
              {props.placeholderFileListText}
            </div>

            {files
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(fileObject => {
                const isImage =
                  !!fileObject && fileObject.type.startsWith('image/');
                return (
                  <div key={fileObject.name} className="dropZoneFiles--file">
                    <div className="dropZoneFiles--fileName">
                      {fileObject.name}
                      {isImage && (
                        <img
                          src={URL.createObjectURL(fileObject)}
                          onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const newTab = window.open();
                            newTab.location = URL.createObjectURL(fileObject);
                          }}
                          className="dropZoneFiles--preview"
                        />
                      )}
                      <FontAwesomeIcon
                        className="dropZoneFiles--removeIcon"
                        icon={faTrashAlt}
                        onClick={e => handleRemoveAttachment(e, fileObject)}
                      />
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </>
  );
};

DropZone.defaultProps = {
  accept: '',
};
