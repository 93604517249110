import React, { useEffect, useState } from 'react';
import './CalendarPage.scss';
import { useSelector } from 'react-redux';
import { fetchCalendarItems } from '../store/calendarThunks';
import { cleanupItems, setCalendarFilters } from '../store/calendarSlice';
import { ApplicationState } from '../../../types/applicationState';
import moment from 'moment';
import {
  CalendarItemItemType,
  CalendarItemModel,
  CalendarItemModelStatusEnum,
} from '../../../swagger';
import { selectVendorDetails } from '../../../selectors/selectVendorDetails';
import { CalendarHeader } from '../CalendarHeader/CalendarHeader';
import {
  CalendarItemsList,
  calendarItemClass,
  calendarItemRoute,
} from '../CalendarItemsList/CalendarItemsList';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import { AdoptechPanel } from '../../../components/AdoptechPanel/AdoptechPanel';
import { CalendarSearch } from '../CalendarSearch/CalendarSearch';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import {
  calendarRoute,
  scrollContainer,
} from '../../../components/Routes/Routes';
import { selectCalendarPageItems } from '../selectors/selectCalendarPageItems';
import { NoDataText } from '../../../components/NoDataText/NoDataText';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { push } from 'connected-react-router';
import { CalendarPageModals } from './CalendarPageModals';
import { useParams } from 'react-router';
import { url } from 'inspector';

export const completedStatus = CalendarItemModelStatusEnum.Completed;
export const overdueStatus = CalendarItemModelStatusEnum.Overdue;

export const endOfCurrentDateMonth = (currentDate: string | Date) => {
  return moment(currentDate).endOf('month').toDate();
};

export type CurrentCalendarItem = {
  id?: CalendarItemModel['id'];
  type?: 'event' | 'corrective-action' | 'incident' | 'action';
};

const CalendarPage: React.FC = () => {
  const baseCss = 'calendarPage';
  const currentVendor = useSelector(selectVendorDetails);
  const dispatch = useAppDispatch();
  const currentVendorUser = useSelector(selectCurrentVendorUser);
  const [showEditActionModal, setShowEditActionModal] = useState(false);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const [showCorrectiveActionModal, setShowCorrectiveActionModal] =
    useState(false);
  const [showIncidentModal, setShowIncidentModal] = useState(false);

  const [currentCalendarItem, setCurrentCalendarItem] =
    useState<CurrentCalendarItem>();

  const urlParams = useParams() as {
    id?: string;
    type?: 'events' | 'corrective-actions' | 'incidents' | 'actions';
  };

  const urlParamsType = urlParams.type?.slice(
    0,
    urlParams.type.length - 1
  ) as CurrentCalendarItem['type'];

  const {
    filters,
    filters: { showMyItems },
  } = useSelector((state: ApplicationState) => state.calendar);
  const showAllItems = !showMyItems;

  const refetchCurrentDateCalendarItems = async () => {
    const filtersPayload = {
      ...filters,
      ...{
        page: 1,
      },
    };

    await dispatch(
      fetchCalendarItems({
        filters: filtersPayload,
        currentVendorUserId: currentVendorUser.id,
      })
    );
  };

  const cleanup = () => {
    dispatch(cleanupItems());
  };

  useEffect(() => {
    cleanup();
    refetchCurrentDateCalendarItems();
    return () => {
      cleanup();
    };
  }, [currentVendor]);

  useEffect(() => {
    if (!urlParams?.id) return;

    setCurrentCalendarItem({
      type: urlParamsType,
      id: urlParams?.id,
    });

    // For simplicity use /r/calendar everywhere:
    // => redirect from /r/calendar/events/:id silently to /r/calendar
    window.history.pushState(null, '', calendarRoute);
  }, [urlParams?.id]);

  const { fetchCalendarItemsStatus } = useSelector(
    (state: ApplicationState) => state.calendar
  );

  const isFetchingItems = fetchCalendarItemsStatus === 'loading';
  const filteredGroupedByMonthItems = useSelector(selectCalendarPageItems);

  const handleRowClick = (item: CalendarItemModel) => {
    const calendarType = calendarItemClass(item);
    setCurrentCalendarItem({
      id: item.id,
      type: calendarType === 'unknown' ? null : calendarType,
    });
  };

  const noMorePages = () => {
    const noPages = filters.totalPages === 0;
    const pageLimitReached = filters.page + 1 > filters.totalPages;
    return filters.totalPages !== null && (noPages || pageLimitReached);
  };

  const onScroll = () => {
    const container = document.getElementById(scrollContainer);
    const reachedToBottom =
      container.offsetHeight + container.scrollTop >= container.scrollHeight;

    if (reachedToBottom && !noMorePages()) {
      const nextPage = filters.page + 1;
      const filtersPayload = {
        ...filters,
        ...{
          page: nextPage,
        },
      };

      dispatch(setCalendarFilters(filtersPayload));
      dispatch(
        fetchCalendarItems({
          filters: filtersPayload,
          currentVendorUserId: currentVendorUser.id,
        })
      );
    }
  };
  useEffect(() => {
    const container = document.getElementById(scrollContainer);
    container.addEventListener('scroll', onScroll);
    return () => {
      container.removeEventListener('scroll', onScroll);
    };
  }, [filters, currentVendorUser.id]);

  const noDataPlaceholders = {
    actions_and_events:
      'No Actions or Events have been created, to begin select Add new.',
    incident_log: 'No Incidents have been created, to begin select Add new.',
    audit_schedule: 'No Events have been scheduled, to begin select Add new.',
    corrective_actions:
      'No Corrective Actions have been created, to begin select Add new.',
  };
  const isLoading = isFetchingItems;

  const CalendarHeaderProps = {
    setShowEditActionModal,
    setShowEditEventModal,
    setShowCorrectiveActionModal,
    setShowIncidentModal,
    setCurrentCalendarItem,
  };

  return (
    <div className={baseCss}>
      <CalendarHeader {...CalendarHeaderProps} />

      <AdoptechPanel>
        <CalendarSearch />
        {isLoading && filters.page === 1 ? (
          <LoadingSpinner />
        ) : (
          <>
            <>
              {Object.entries(filteredGroupedByMonthItems).length === 0 && (
                <NoDataText
                  extraClass="mt-2"
                  text={noDataPlaceholders[filters.viewMode]}
                />
              )}
            </>
            <>
              {Object.entries(filteredGroupedByMonthItems).map(
                ([monthName, sortedMonthItems]) => {
                  return (
                    <div key={monthName}>
                      <div className={baseCss + `--${monthName}`}>
                        {monthName}
                      </div>
                      <CalendarItemsList
                        showTooltip
                        gridName={monthName}
                        items={sortedMonthItems}
                        onClick={handleRowClick}
                        showAllItems={showAllItems}
                      />
                    </div>
                  );
                }
              )}
            </>
          </>
        )}

        {isLoading && filters.page > 1 && <LoadingSpinner inlineSmall />}
      </AdoptechPanel>
      <CalendarPageModals
        cleanup={cleanup}
        refetchCurrentDateCalendarItems={refetchCurrentDateCalendarItems}
        showEditActionModal={showEditActionModal}
        showEditEventModal={showEditEventModal}
        showCorrectiveActionModal={showCorrectiveActionModal}
        showIncidentModal={showIncidentModal}
        currentCalendarItem={currentCalendarItem}
        {...CalendarHeaderProps}
      />
    </div>
  );
};

export default CalendarPage;
