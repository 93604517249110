import { api } from '../../api/api';
import {
  PestelItemModel,
  PestelItemUpdatePayload,
  PestelItemCreatePayload,
  PestelRegisterUpdatePayload,
  PestelRegisterModel,
} from '../../swagger';
import { VoidThunk } from '../../types/voidThunk';
import { setErrorMessage, showGlobalToast } from '../global/globalSlice';
import { getErrorMessage } from '../helpers/thunkHelpers';
import {
  fetchPestelsRequest,
  fetchPestelsSuccess,
  setPestelError,
  deletePestelSuccess,
  deletePestelRequest,
  createPestelItemRequest,
  createPestelItemSuccess,
  createPestelItemFailure,
  fetchPestelItemRequest,
  fetchPestelItemSuccess,
  fetchPestelItemFailure,
  updatePestelItemRequest,
  updatePestelItemSuccess,
  updatePestelItemFailure,
  fetchPestelRegisterRequest,
  fetchPestelRegisterSuccess,
  fetchPestelRegisterFailure,
  patchPestelRegisterRequest,
  patchPestelRegisterSuccess,
  patchPestelRegisterFailure,
} from './pestelSlice';

export const fetchPestels =
  (vendorId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchPestelsRequest());
      const response = await api().vendorsVendorIdPestelItemsGet({
        vendorId,
      });
      dispatch(fetchPestelsSuccess(response as PestelItemModel[]));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching pestel items. Please try again or contact support.'
      );
      dispatch(setPestelError(apiErrorMessage));
    }
  };

export const deletePestelItem =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deletePestelRequest());
      await api().pestelItemsIdDelete({
        id,
      });
      dispatch(deletePestelSuccess(id));
      dispatch(fetchPestelRegister());
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while deleting a pestel item. Please try again or contact support.'
      );
      dispatch(setPestelError(apiErrorMessage));
    }
  };

export const createPestelItem =
  (body: PestelItemCreatePayload, onSuccess: () => void): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(createPestelItemRequest());
      const payload = { vendorId: getState().vendors.currentVendor.id, body };
      const response = await api().vendorsVendorIdPestelItemsPost(payload);
      dispatch(createPestelItemSuccess(response));
      onSuccess();
      dispatch(showGlobalToast('Pestel item has been successfully added'));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while creating pestel item. Please try again or contact support.'
      );
      dispatch(createPestelItemFailure(apiErrorMessage));
    }
  };

export const fetchPestelItem =
  (id: PestelItemModel['id']): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchPestelItemRequest());
      const response = await api().pestelItemsIdGet({ id });
      dispatch(fetchPestelItemSuccess(response));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching pestel item. Please try again or contact support.'
      );
      dispatch(fetchPestelItemFailure(apiErrorMessage));
    }
  };

export const updatePestelItem =
  (
    id: PestelItemModel['id'],
    body: PestelItemUpdatePayload,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(updatePestelItemRequest());
      const payload = { id, body };
      const response = await api().pestelItemsIdPatch(payload);
      dispatch(updatePestelItemSuccess(response));
      onSuccess();
      dispatch(showGlobalToast('Pestel item has been successfully updated'));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while updating pestel item. Please try again or contact support.'
      );
      dispatch(updatePestelItemFailure(apiErrorMessage));
    }
  };

export const fetchPestelRegister =
  (onSuccess?: (result: PestelRegisterModel) => void): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;

      dispatch(fetchPestelRegisterRequest());
      const response = await api().vendorsVendorIdPestelRegisterGet({
        vendorId,
      });
      dispatch(fetchPestelRegisterSuccess(response));
      onSuccess && onSuccess(response);
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred while loading a pestel register. Please try again or contact support.'
      );
      dispatch(setErrorMessage(errorMessageToUser));
      dispatch(fetchPestelRegisterFailure(errorMessageToUser));
    }
  };

export const patchPestelRegister =
  (body: PestelRegisterUpdatePayload, onSuccess?: () => void): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(patchPestelRegisterRequest());
      const payload = { vendorId: getState().vendors.currentVendor.id, body };
      const response = await api().vendorsVendorIdPestelRegisterPatch(payload);
      dispatch(patchPestelRegisterSuccess(response));
      onSuccess && onSuccess();
      dispatch(
        showGlobalToast('Pestel register has been successfully updated')
      );
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred updating pestel register. Please try again or contact support.'
      );
      dispatch(patchPestelRegisterFailure());
      dispatch(setErrorMessage(errorMessageToUser));
    }
  };
