/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Question,
  QuestionFromJSON,
  QuestionFromJSONTyped,
  QuestionToJSON,
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsFromJSONTyped,
  UserDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface SurveyClause
 */
export interface SurveyClause {
  /**
   *
   * @type {string}
   * @memberof SurveyClause
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SurveyClause
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof SurveyClause
   */
  rowOrder?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SurveyClause
   */
  leaveOut: boolean;
  /**
   *
   * @type {Array<Question>}
   * @memberof SurveyClause
   */
  questions?: Array<Question>;
  /**
   *
   * @type {UserDetails}
   * @memberof SurveyClause
   */
  updatedBy?: UserDetails;
  /**
   *
   * @type {boolean}
   * @memberof SurveyClause
   */
  dynamic?: boolean;
  /**
   *
   * @type {string}
   * @memberof SurveyClause
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof SurveyClause
   */
  updatedAt?: string;
}

export function SurveyClauseFromJSON(json: any): SurveyClause {
  return SurveyClauseFromJSONTyped(json, false);
}

export function SurveyClauseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SurveyClause {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    rowOrder: !exists(json, 'row_order') ? undefined : json['row_order'],
    leaveOut: json['leave_out'],
    questions: !exists(json, 'questions')
      ? undefined
      : (json['questions'] as Array<any>).map(QuestionFromJSON),
    updatedBy: !exists(json, 'updated_by')
      ? undefined
      : UserDetailsFromJSON(json['updated_by']),
    dynamic: !exists(json, 'dynamic') ? undefined : json['dynamic'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function SurveyClauseToJSON(value?: SurveyClause | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    row_order: value.rowOrder,
    leave_out: value.leaveOut,
    questions:
      value.questions === undefined
        ? undefined
        : (value.questions as Array<any>).map(QuestionToJSON),
    updated_by: UserDetailsToJSON(value.updatedBy),
    dynamic: value.dynamic,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
