/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReportCreatePayloadReport
 */
export interface ReportCreatePayloadReport {
  /**
   *
   * @type {string}
   * @memberof ReportCreatePayloadReport
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ReportCreatePayloadReport
   */
  ownerId: string;
  /**
   *
   * @type {string}
   * @memberof ReportCreatePayloadReport
   */
  versionBump?: ReportCreatePayloadReportVersionBumpEnum;
  /**
   *
   * @type {string}
   * @memberof ReportCreatePayloadReport
   */
  summary?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReportCreatePayloadReport
   */
  framework?: ReportCreatePayloadReportFrameworkEnum;
  /**
   *
   * @type {boolean}
   * @memberof ReportCreatePayloadReport
   */
  includeUnapprovedRisks?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ReportCreatePayloadReport
   */
  includeIncompleteActions?: boolean;
}

export function ReportCreatePayloadReportFromJSON(
  json: any
): ReportCreatePayloadReport {
  return ReportCreatePayloadReportFromJSONTyped(json, false);
}

export function ReportCreatePayloadReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReportCreatePayloadReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    ownerId: json['owner_id'],
    versionBump: !exists(json, 'version_bump')
      ? undefined
      : json['version_bump'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    framework: !exists(json, 'framework') ? undefined : json['framework'],
    includeUnapprovedRisks: !exists(json, 'include_unapproved_risks')
      ? undefined
      : json['include_unapproved_risks'],
    includeIncompleteActions: !exists(json, 'include_incomplete_actions')
      ? undefined
      : json['include_incomplete_actions'],
  };
}

export function ReportCreatePayloadReportToJSON(
  value?: ReportCreatePayloadReport | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    owner_id: value.ownerId,
    version_bump: value.versionBump,
    summary: value.summary,
    framework: value.framework,
    include_unapproved_risks: value.includeUnapprovedRisks,
    include_incomplete_actions: value.includeIncompleteActions,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ReportCreatePayloadReportVersionBumpEnum {
  Minor = 'minor',
  Major = 'major',
}
/**
 * @export
 * @enum {string}
 */
export enum ReportCreatePayloadReportFrameworkEnum {
  _27001 = 'iso_27001',
  _270012022 = 'iso_27001_2022',
}
