/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RiskUpdatePayloadRisk
 */
export interface RiskUpdatePayloadRisk {
  /**
   *
   * @type {string}
   * @memberof RiskUpdatePayloadRisk
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RiskUpdatePayloadRisk
   */
  ownerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskUpdatePayloadRisk
   */
  treatmentStrategy?: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskUpdatePayloadRisk
   */
  treatmentExplanation?: string | null;
  /**
   *
   * @type {number}
   * @memberof RiskUpdatePayloadRisk
   */
  inherentRiskLikelihood?: number | null;
  /**
   *
   * @type {number}
   * @memberof RiskUpdatePayloadRisk
   */
  inherentRiskConsequence?: number | null;
  /**
   *
   * @type {string}
   * @memberof RiskUpdatePayloadRisk
   */
  inherentRiskComment?: string | null;
  /**
   *
   * @type {number}
   * @memberof RiskUpdatePayloadRisk
   */
  residualRiskLikelihood?: number | null;
  /**
   *
   * @type {number}
   * @memberof RiskUpdatePayloadRisk
   */
  residualRiskConsequence?: number | null;
  /**
   *
   * @type {string}
   * @memberof RiskUpdatePayloadRisk
   */
  residualRiskComment?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof RiskUpdatePayloadRisk
   */
  informationSecurityProperties?: Array<RiskUpdatePayloadRiskInformationSecurityPropertiesEnum>;
}

export function RiskUpdatePayloadRiskFromJSON(
  json: any
): RiskUpdatePayloadRisk {
  return RiskUpdatePayloadRiskFromJSONTyped(json, false);
}

export function RiskUpdatePayloadRiskFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskUpdatePayloadRisk {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    treatmentStrategy: !exists(json, 'treatment_strategy')
      ? undefined
      : json['treatment_strategy'],
    treatmentExplanation: !exists(json, 'treatment_explanation')
      ? undefined
      : json['treatment_explanation'],
    inherentRiskLikelihood: !exists(json, 'inherent_risk_likelihood')
      ? undefined
      : json['inherent_risk_likelihood'],
    inherentRiskConsequence: !exists(json, 'inherent_risk_consequence')
      ? undefined
      : json['inherent_risk_consequence'],
    inherentRiskComment: !exists(json, 'inherent_risk_comment')
      ? undefined
      : json['inherent_risk_comment'],
    residualRiskLikelihood: !exists(json, 'residual_risk_likelihood')
      ? undefined
      : json['residual_risk_likelihood'],
    residualRiskConsequence: !exists(json, 'residual_risk_consequence')
      ? undefined
      : json['residual_risk_consequence'],
    residualRiskComment: !exists(json, 'residual_risk_comment')
      ? undefined
      : json['residual_risk_comment'],
    informationSecurityProperties: !exists(
      json,
      'information_security_properties'
    )
      ? undefined
      : json['information_security_properties'],
  };
}

export function RiskUpdatePayloadRiskToJSON(
  value?: RiskUpdatePayloadRisk | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    owner_id: value.ownerId,
    treatment_strategy: value.treatmentStrategy,
    treatment_explanation: value.treatmentExplanation,
    inherent_risk_likelihood: value.inherentRiskLikelihood,
    inherent_risk_consequence: value.inherentRiskConsequence,
    inherent_risk_comment: value.inherentRiskComment,
    residual_risk_likelihood: value.residualRiskLikelihood,
    residual_risk_consequence: value.residualRiskConsequence,
    residual_risk_comment: value.residualRiskComment,
    information_security_properties: value.informationSecurityProperties,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum RiskUpdatePayloadRiskInformationSecurityPropertiesEnum {
  Confidentiality = 'confidentiality',
  Integrity = 'integrity',
  Availability = 'availability',
}
