import { VendorDocumentAttestationStatusEnum } from '../swagger';

export const attestationStatusSort = (
  a: VendorDocumentAttestationStatusEnum,
  b: VendorDocumentAttestationStatusEnum,
  direction: number
): number => {
  if (
    a === VendorDocumentAttestationStatusEnum.Requested ||
    a === VendorDocumentAttestationStatusEnum.Imminent ||
    a === VendorDocumentAttestationStatusEnum.Overdue
  ) {
    return direction;
  } else {
    return direction * -1;
  }
};
