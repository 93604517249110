/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShareSuggestionListData,
  ShareSuggestionListDataFromJSON,
  ShareSuggestionListDataFromJSONTyped,
  ShareSuggestionListDataToJSON,
  ShareSuggestionListMeta,
  ShareSuggestionListMetaFromJSON,
  ShareSuggestionListMetaFromJSONTyped,
  ShareSuggestionListMetaToJSON,
} from './';

/**
 *
 * @export
 * @interface ShareSuggestionList
 */
export interface ShareSuggestionList {
  /**
   *
   * @type {Array<ShareSuggestionListData>}
   * @memberof ShareSuggestionList
   */
  data: Array<ShareSuggestionListData>;
  /**
   *
   * @type {ShareSuggestionListMeta}
   * @memberof ShareSuggestionList
   */
  meta: ShareSuggestionListMeta;
}

export function ShareSuggestionListFromJSON(json: any): ShareSuggestionList {
  return ShareSuggestionListFromJSONTyped(json, false);
}

export function ShareSuggestionListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShareSuggestionList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(ShareSuggestionListDataFromJSON),
    meta: ShareSuggestionListMetaFromJSON(json['meta']),
  };
}

export function ShareSuggestionListToJSON(
  value?: ShareSuggestionList | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(ShareSuggestionListDataToJSON),
    meta: ShareSuggestionListMetaToJSON(value.meta),
  };
}
