/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorDetailsCounts
 */
export interface VendorDetailsCounts {
  /**
   *
   * @type {number}
   * @memberof VendorDetailsCounts
   */
  answerSets?: number;
  /**
   *
   * @type {number}
   * @memberof VendorDetailsCounts
   */
  corporatePolicies?: number;
  /**
   *
   * @type {number}
   * @memberof VendorDetailsCounts
   */
  industryCertifications?: number;
}

export function VendorDetailsCountsFromJSON(json: any): VendorDetailsCounts {
  return VendorDetailsCountsFromJSONTyped(json, false);
}

export function VendorDetailsCountsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorDetailsCounts {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    answerSets: !exists(json, 'answer_sets') ? undefined : json['answer_sets'],
    corporatePolicies: !exists(json, 'corporate_policies')
      ? undefined
      : json['corporate_policies'],
    industryCertifications: !exists(json, 'industry_certifications')
      ? undefined
      : json['industry_certifications'],
  };
}

export function VendorDetailsCountsToJSON(
  value?: VendorDetailsCounts | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    answer_sets: value.answerSets,
    corporate_policies: value.corporatePolicies,
    industry_certifications: value.industryCertifications,
  };
}
