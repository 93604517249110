import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { UserDetails } from '../../swagger/models';
import PhoneInput from 'react-phone-number-input';
import { isValidMobile } from '../../functions/isValidMobile';
import 'react-phone-number-input/style.css';
import './Enable2FAForm.scss';

interface EditProfileFieldsProps {
  onChange: (userDetails: UserDetails) => void;
  userDetails: UserDetails;
}

export const Enable2FAForm: React.FC<EditProfileFieldsProps> = props => {
  const [userDetails, setUserDetails] = useState<UserDetails>({
    email: '',
    firstName: '',
    lastName: '',
    mobile: '',
    phone: '',
    authyUserid: '',
    authyEnabled: false,
  });

  const errorClasses = classNames({
    'enable2FAForm--error': true,
    'enable2FAForm--error-hidden':
      userDetails.mobile === undefined || isValidMobile(userDetails.mobile),
  });

  useEffect(() => {
    setUserDetails(props.userDetails);
  }, [props.userDetails]);

  return (
    <div>
      <div>
        <div className="enable2FAForm--label">Phone number</div>
        <div>
          <PhoneInput
            defaultCountry="GB"
            onChange={mobile => {
              const updatedUserDetails: UserDetails = {
                ...userDetails,
                mobile: mobile ? mobile : '',
              };
              setUserDetails(updatedUserDetails);
              props.onChange(updatedUserDetails);
            }}
            placeholder="Enter mobile number"
            value={userDetails.mobile || ''}
          />
          <div className={errorClasses}>Invalid Mobile</div>
        </div>
      </div>
    </div>
  );
};
