/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ImageUrls
 */
export interface ImageUrls {
  /**
   *
   * @type {string}
   * @memberof ImageUrls
   */
  full: string | null;
  /**
   *
   * @type {string}
   * @memberof ImageUrls
   */
  thumb?: string | null;
}

export function ImageUrlsFromJSON(json: any): ImageUrls {
  return ImageUrlsFromJSONTyped(json, false);
}

export function ImageUrlsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ImageUrls {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    full: json['full'],
    thumb: !exists(json, 'thumb') ? undefined : json['thumb'],
  };
}

export function ImageUrlsToJSON(value?: ImageUrls | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    full: value.full,
    thumb: value.thumb,
  };
}
