import {
  Answer,
  Question,
  QuestionDatatypeEnum,
  VendorTable,
} from '../../swagger/models';
import { formatShortDate } from '../../functions/formatShortDate';

export const getAnswerDisplayValue = (
  question: Question,
  answer: Answer,
  vendorTableCache: { [key: string]: VendorTable }
) => {
  if (!question || !answer) {
    return '';
  }
  const respectiveQuestion = question.table.questions.find(
    (tableQuestion: Question) =>
      tableQuestion.propertyName === answer.propertyName
  );

  if (
    respectiveQuestion.objectType &&
    (!vendorTableCache || !vendorTableCache[respectiveQuestion.objectType])
  ) {
    return;
  }

  if (respectiveQuestion.objectType) {
    const vendorTable = vendorTableCache[respectiveQuestion.objectType];
    const value = vendorTable.rows.find(r => r.value === answer.value)
      ?.cells[0];
    return value;
  }

  return respectiveQuestion.datatype === QuestionDatatypeEnum.Date
    ? formatShortDate(answer.value)
    : answer.value;
};
