import React from 'react';
import './ControlsEditSection.scss';
import { EditSectionHandlerType } from '../useEditTrustHubSection';
import { ControlCategoryRow } from '../../ControlsSection/ControlCategoryRow';

export const ControlEditRightPanel: React.FC<{
  baseCss: string;
  editSectionHandler: EditSectionHandlerType;
}> = ({ baseCss, editSectionHandler }) => {
  const { formCategories, sortedDocsToEdit, formDocuments } =
    editSectionHandler;
  return (
    <div className={baseCss + '--table'}>
      <div className={baseCss + '--tableHeader'}>
        Drag controls between categories, and edit the hover text if required.
      </div>
      <div className={baseCss + '--tablePanel'}>
        <div className={baseCss + '--controlsCategories'}>
          {formCategories
            .sort((a, b) => a.rowOrder - b.rowOrder)
            .map(category => {
              return (
                <ControlCategoryRow
                  key={category.id}
                  showTooltip={false}
                  color="dark"
                  title={category.name}
                  editSectionHandler={editSectionHandler}
                  dropAllowed
                  dragAllowed
                  controls={sortedDocsToEdit
                    .map(sortedDoc => {
                      const formDoc = formDocuments.find(
                        formDocument =>
                          formDocument.documentId === sortedDoc.documentId &&
                          formDocument.trusthubCategoryId === category.id
                      );

                      if (!formDoc) return null;
                      return {
                        ...sortedDoc,
                        ...{
                          rowOrder: formDoc.rowOrder,
                          trusthubCategoryId: formDoc.trusthubCategoryId,
                        },
                      };
                    })
                    .filter(doc => doc)
                    .sort((a, b) => a.rowOrder - b.rowOrder)}
                  categoryId={category.id.toString()}
                  baseCss={baseCss}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
