import React, { useEffect, useRef, useState } from 'react';
import { AdoptechDrawer } from '../AdoptechDrawer/AdoptechDrawer';
import { useDispatch, useSelector } from 'react-redux';
import {
  submitVendorSupplier,
  fetchVendorSupplier,
} from '../../store/vendors/vendorsThunks';
import { VendorSupplierExtended, VendorSupplierModel } from '../../swagger';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import './ManageSupplierDrawer.scss';
import { DeleteVendorModal } from '../DeleteVendorModal/DeleteVendorModal';
import { ApplicationState } from '../../types/applicationState';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import {
  initVendorSupplierForm,
  setVendorSupplierForm,
  clearVendorSupplierForm,
} from '../../store/vendors/vendorsSlice';

// Sections
import {
  OverviewSection,
  validation as overviewValidation,
} from './OverviewSection';
import {
  ContractSection,
  validation as contractValidation,
} from './ContractSection';
import {
  ContactSection,
  validation as contactValidation,
} from './ContactsSection';
import { RiskSection, validation as riskValidation } from './RiskSection';
import { DeleteSection } from './DeleteSection';
import AdoptechHS from '../AdoptechHS/AdoptechHS';

interface ManageSupplierDrawerProps {
  showReason: VendorSupplierModel;
  onClose: (updateNeeded: boolean) => void;
}

export const ManageSupplierDrawer: React.FC<ManageSupplierDrawerProps> = ({
  showReason,
  onClose,
}) => {
  const baseCss = 'manageSupplierDrawer';
  const isNew = !showReason?.id;
  const dispatch = useDispatch();

  // prepare form data
  useEffect(() => {
    if (showReason) {
      if (showReason.id) {
        dispatch(fetchVendorSupplier(showReason.id));
      } else {
        dispatch(initVendorSupplierForm(showReason));
      }
    } else {
      dispatch(clearVendorSupplierForm());
    }
  }, [showReason]);

  // form state getter and setter
  const formData = useSelector(
    (state: ApplicationState) => state.vendors.vendorSupplierForm
  );

  const { isCreatingVendorSupplier, isPatchingVendorSupplier } = useSelector(
    (state: ApplicationState) => state.vendors
  );
  const setFormData = (data: VendorSupplierExtended) => {
    dispatch(setVendorSupplierForm(data));
  };

  // track changes presence
  const [originalData, setOriginalData] = useState(formData);
  useEffect(() => setOriginalData(formData), [formData?.id]);
  const formHasChanges = formData != originalData;

  const [
    isDeleteConfirmationModalShowing,
    setIsDeleteConfirmationModalShowing,
  ] = useState(false);

  const validation = !!formData && {
    ...overviewValidation(formData),
    ...contractValidation(formData),
    ...contactValidation(formData),
    ...riskValidation(formData),
  };
  const formValid =
    !!validation &&
    Object.values(validation).reduce((acc, v) => acc && !v, true);

  const title = showReason?.id ? `Edit ${showReason.name}` : 'Add vendor';

  const dispatchSubmit = () => {
    if (formValid) {
      dispatch(submitVendorSupplier({ onSuccess: () => onClose(true) }));
    }
  };

  const dispatchDelete = () => {
    setIsDeleteConfirmationModalShowing(true);
  };

  // ref needed to toggle confirm close dialog logic
  const drawerBackgroundRef = useRef<HTMLDivElement>();

  const Footer = (
    <>
      <AdoptechButton
        onClick={() => drawerBackgroundRef.current.click()}
        variant={AdoptechButtonVariant.White}
      >
        Cancel
      </AdoptechButton>
      <AdoptechButton
        disabled={!formValid || !formHasChanges}
        onClick={dispatchSubmit}
        busy={isCreatingVendorSupplier || isPatchingVendorSupplier}
        variant={AdoptechButtonVariant.Primary}
      >
        {isNew ? 'ADD' : 'DONE'}
      </AdoptechButton>
    </>
  );
  return (
    <AdoptechHS
      noFormPadding
      title={title}
      ref={drawerBackgroundRef}
      show={!!showReason}
      onClose={() => onClose(formHasChanges)}
      showConfirmationWarning={formHasChanges}
      footer={Footer}
      extraClass="manageSupplierDrawer"
    >
      {formData ? (
        <div className={baseCss + '--formContainer'}>
          <OverviewSection formData={formData} setFormData={setFormData} />
          <ContractSection formData={formData} setFormData={setFormData} />
          <ContactSection formData={formData} setFormData={setFormData} />
          <RiskSection formData={formData} setFormData={setFormData} />
          <DeleteSection formData={formData} dispatchDelete={dispatchDelete} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
      {isDeleteConfirmationModalShowing && (
        <DeleteVendorModal
          vendorToDelete={formData as VendorSupplierModel}
          onConfirm={() => {
            setIsDeleteConfirmationModalShowing(false);
            onClose(true);
          }}
          onCancel={() => {
            setIsDeleteConfirmationModalShowing(false);
          }}
        />
      )}
    </AdoptechHS>
  );
};
