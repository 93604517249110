/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ReferenceCreatePayloadReference,
  ReferenceCreatePayloadReferenceFromJSON,
  ReferenceCreatePayloadReferenceFromJSONTyped,
  ReferenceCreatePayloadReferenceToJSON,
} from './';

/**
 *
 * @export
 * @interface ReferenceCreatePayload
 */
export interface ReferenceCreatePayload {
  /**
   *
   * @type {ReferenceCreatePayloadReference}
   * @memberof ReferenceCreatePayload
   */
  reference: ReferenceCreatePayloadReference;
}

export function ReferenceCreatePayloadFromJSON(
  json: any
): ReferenceCreatePayload {
  return ReferenceCreatePayloadFromJSONTyped(json, false);
}

export function ReferenceCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReferenceCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    reference: ReferenceCreatePayloadReferenceFromJSON(json['reference']),
  };
}

export function ReferenceCreatePayloadToJSON(
  value?: ReferenceCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    reference: ReferenceCreatePayloadReferenceToJSON(value.reference),
  };
}
