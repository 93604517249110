/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserPassword
 */
export interface UserPassword {
  /**
   *
   * @type {string}
   * @memberof UserPassword
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof UserPassword
   */
  passwordConfirmation?: string;
  /**
   *
   * @type {string}
   * @memberof UserPassword
   */
  currentPassword?: string | null;
}

export function UserPasswordFromJSON(json: any): UserPassword {
  return UserPasswordFromJSONTyped(json, false);
}

export function UserPasswordFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserPassword {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    password: !exists(json, 'password') ? undefined : json['password'],
    passwordConfirmation: !exists(json, 'password_confirmation')
      ? undefined
      : json['password_confirmation'],
    currentPassword: !exists(json, 'current_password')
      ? undefined
      : json['current_password'],
  };
}

export function UserPasswordToJSON(value?: UserPassword | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    password: value.password,
    password_confirmation: value.passwordConfirmation,
    current_password: value.currentPassword,
  };
}
