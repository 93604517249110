import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SharedPaymentPeriod,
  PaymentPlanVendor,
  PaymentPlan,
} from '../../../swagger';
import { PricingState } from './pricingState';

export interface UpdateDynamicPriceSuccessProps {
  planId: string;
  priceValue: number;
  paymentPeriod: SharedPaymentPeriod;
}

const pricingSlice = createSlice({
  initialState: {
    isUpdatingSubscription: false,
    paymentPlanVendors: [],
    showAutoSaveToast: false,
    subscriptionDetails: {
      paymentPeriod: SharedPaymentPeriod.Monthly,
    },
  } as unknown as PricingState,
  name: 'pricingSlice',
  reducers: {
    createPaymentPlanVendorRequest: state => {
      state.createPaymentPlanVendorError = undefined;
      state.isCreatingPaymentPlanVendor = true;
    },
    // not used
    createPaymentPlanVendorSuccess: (
      state,
      action: PayloadAction<PaymentPlanVendor>
    ) => {
      state.creatingPaymentPlanVendorId = action.payload.id;
      state.isCreatingPaymentPlanVendor = false;
    },
    createPaymentPlanVendorFailure: (state, action: PayloadAction<string>) => {
      state.createPaymentPlanVendorError = action.payload;
      state.isCreatingPaymentPlanVendor = false;
    },
    createStripeCheckoutFailure: (state, action: PayloadAction<string>) => {
      state.createPaymentPlanVendorError = action.payload;
    },
    updateSubscriptionRequest: state => {
      state.isUpdatingSubscription = true;
      state.updateSubscriptionError = undefined;
    },
    updateSubscriptionSuccess: state => {
      state.isUpdatingSubscription = false;
    },
    updateSubscriptionFailure: (state, action: PayloadAction<string>) => {
      state.isUpdatingSubscription = false;
      state.updateSubscriptionError = action.payload;
    },
    setShowSubscriptionWarningModal: (
      // TODO: implement modal after subscription restrictions
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showSubscriptionWarningModal = action.payload;
    },
    setSubscriptionDetails: (
      state,
      action: PayloadAction<PricingState['subscriptionDetails']>
    ) => {
      state.subscriptionDetails = action.payload;
    },

    getDynamicPriceRequestStatus: (
      state,
      action: PayloadAction<PricingState['getDynamicPriceRequestStatus']>
    ) => {
      state.getDynamicPriceRequestStatus = action.payload;
    },

    outdatedActiveSubscriptionFailure: (
      state,
      action: PayloadAction<string>
    ) => {},
    fetchPaymentPlansRequest: state => {
      state.isFetchingPaymentPlans = true;
    },
    fetchPaymentPlansSuccess: (state, action: PayloadAction<PaymentPlan[]>) => {
      state.paymentPlans = action.payload;
      state.isFetchingPaymentPlans = false;
    },
    fetchPaymentPlansFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingPaymentPlans = false;
      state.fetchingPaymentPlansError = action.payload;
    },
    updateDynamicPlanPriceSuccess: (
      state,
      action: PayloadAction<UpdateDynamicPriceSuccessProps>
    ) => {
      const { planId, priceValue, paymentPeriod } = action.payload;

      const plan = state.paymentPlans.find(
        paymentPlan => paymentPlan.id === planId
      );
      const isAnnualy = paymentPeriod === SharedPaymentPeriod.Annually;

      if (isAnnualy) {
        plan.annuallyPrice = priceValue;
        plan.monthlyPrice = null;
      } else {
        plan.annuallyPrice = null;
        plan.monthlyPrice = priceValue;
      }
    },
  },
});

export const {
  createPaymentPlanVendorRequest,
  createPaymentPlanVendorSuccess,
  createPaymentPlanVendorFailure,
  createStripeCheckoutFailure,
  updateSubscriptionFailure,
  updateSubscriptionRequest,
  updateSubscriptionSuccess,
  setShowSubscriptionWarningModal,
  setSubscriptionDetails,
  getDynamicPriceRequestStatus,
  outdatedActiveSubscriptionFailure,
  fetchPaymentPlansRequest,
  fetchPaymentPlansSuccess,
  fetchPaymentPlansFailure,
  updateDynamicPlanPriceSuccess,
} = pricingSlice.actions;

export default pricingSlice.reducer;
