// On click "clause done" we found the ref of the next "clause toggle ref body" and click on it

import { useRef } from 'react';
import { VendorPolicyClause } from '../../../swagger/models/VendorPolicyClause';

export type ToggleRefDiv = HTMLDivElement;
interface ToggleRefs {
  [key: string]: ToggleRefDiv;
}

export const useExpandNextClause = (
  clauses: VendorPolicyClause[],
  clauseProgress: (clause: VendorPolicyClause) => number
) => {
  const toggleRefs = useRef<ToggleRefs>({});

  const handleDone = (id: VendorPolicyClause['id']) => {
    const clauseIndex = clauses.findIndex(clause => clause.id === id);
    const nextNotFinishedClause = clauses.find((clause, index) => {
      const nextClause = index > clauseIndex;
      const notFinishedClause =
        clause.questions?.length > 0 && clauseProgress(clause) !== 1;

      return nextClause && notFinishedClause;
    });

    toggleRefs.current[nextNotFinishedClause?.id]?.click();
  };

  return { toggleRefs, handleDone };
};
