import React from 'react';
import { AssessmentSection } from '../../swagger';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import './AssessmentQuestionHeader.scss';

interface AssessmentQuestionHeaderProps {
  section: AssessmentSection;
}

export const AssessmentQuestionHeader: React.FC<
  AssessmentQuestionHeaderProps
> = ({ section }) => {
  return (
    <div className="assessmentQuestionHeader--title">
      <div className="flex3">{section.title}</div>
      <div className="flex2">{section.answered}</div>
      <div className="flex2">{section.unanswered}</div>
      <div className="flex5 assessmentQuestionHeader--categoryProgress">
        <div className="assessmentQuestionHeader--categoryProgressAmount">
          {section.progress !== undefined &&
            (section.progress * 100).toFixed(0) + '%'}
        </div>
        <div className="assessmentQuestionHeader--categoryProgressBar">
          {section.progress !== undefined && (
            <ProgressBar progress={section.progress * 100} />
          )}
        </div>
      </div>
    </div>
  );
};
