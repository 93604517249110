import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import { hideCompleteDocumentAuditModal } from '../../store/vendors/vendorsSlice';
import { ApplicationState } from '../../types/applicationState';
import { AdoptechButton } from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { DownloadDocumentModal } from '../DownloadDocumentModal/DownloadDocumentModal';
import './CompleteDocumentAuditModal.scss';
import {
  SentAttestationsTable,
  useSentAttestations,
} from '../SentAttestationsTable/SentAttestationsTable';
import { fetchVendorDocumentAttestations } from '../../store/vendors/vendorsThunks';
import { VendorDocumentApprovalStatusEnum } from '../../swagger';

export const CompleteDocumentAuditModal: React.FC = () => {
  const dispatch = useDispatch();
  const [isShowingDownloadModal, setIsShowingDownloadModal] = useState(false);
  const completeDocument = useSelector(
    (state: ApplicationState) => state.vendors.documentState.completeDocument
  );
  const isCompleteDocumentAuditModalOpen = useSelector(
    (state: ApplicationState) =>
      state.vendors.documentState.isCompleteDocumentAuditModalOpen
  );

  const getApprovalStatus = (): string => {
    if (
      completeDocument.vendorDocumentApproval.status ===
      VendorDocumentApprovalStatusEnum.WasNotRequested
    ) {
      return 'Skipped';
    }
    if (
      completeDocument.vendorDocumentApproval.status ===
      VendorDocumentApprovalStatusEnum.Current
    ) {
      return 'Approved';
    }
  };

  useEffect(() => {
    if (!completeDocument) return;
    dispatch(fetchVendorDocumentAttestations(completeDocument.id));
  }, [completeDocument?.id]);
  const { sentAttestations } = useSentAttestations(completeDocument);
  if (!completeDocument) {
    return null;
  }

  const handleCancel = () => {
    dispatch(hideCompleteDocumentAuditModal());
  };

  return (
    <React.Fragment>
      <AdoptechModal
        className="completeDocumentAuditModal"
        onHide={handleCancel}
        show={isCompleteDocumentAuditModalOpen}
      >
        <Modal.Header>
          <div className="completeDocumentAuditModal--header">
            <div>Document Audit</div>
            <div className="completeDocumentAuditModal--properties">
              <div>
                <div>Document name:</div>
                <div>{completeDocument.name}</div>
              </div>
              <div>
                <div>Live date:</div>
                <div>{formatShortDate(completeDocument.createdAt)}</div>
              </div>
              <div>
                <div>Approval:</div>
                <div>{getApprovalStatus()}</div>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="completeDocumentAuditModal--staffMembers">
            <SentAttestationsTable
              attestations={sentAttestations}
              tableClass={null}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={() => setIsShowingDownloadModal(true)}>
            Download
          </AdoptechButton>
          <AdoptechButton onClick={handleCancel}>Close</AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
      <DownloadDocumentModal
        onClose={() => setIsShowingDownloadModal(false)}
        show={isShowingDownloadModal}
        vendorDocumentId={completeDocument.id}
      />
    </React.Fragment>
  );
};
