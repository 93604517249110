import React from 'react';
import './AssessmentsMenuItem.scss';
import { AssessmentsMenuItemConfiguration } from '../AssessmentsMenu/AssessmentsMenuConfig';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { ApplicationState } from '../../types/applicationState';
import { Intent, Lozenge } from '../Lozenge/Lozenge';
import { selectPendingAssessments } from '../../selectors/selectPendingAssessments';

export interface AssessmentsMenuItemProps {
  item: AssessmentsMenuItemConfiguration;
}

const AssessmentsMenuItem: React.FC<AssessmentsMenuItemProps> = props => {
  const dispatch = useDispatch();

  const tabToSelect = useSelector(
    (state: ApplicationState) => state.assessments.assessmentTabToShow
  );
  const assessmentResponses = useSelector(selectPendingAssessments);

  const isSelected = tabToSelect === props.item.tabId;

  const classes = classNames(
    'assessmentsMenuItem',
    {
      'assessmentsMenuItem-disabled': props.item.disabled,
    },
    { 'assessmentsMenuItem-selected': isSelected }
  );

  const handleClick = (item: AssessmentsMenuItemConfiguration) => {
    if (item.disabled) return;

    if (item.onClick) {
      item.onClick();
      return;
    }

    if (item.route) {
      dispatch(push(item.route));
    }
  };

  return (
    <>
      <div
        className={classes}
        key={props.item.label}
        onClick={() => handleClick(props.item)}
      >
        <div className="assessmentsMenuItem--label">{props.item.label}</div>
      </div>
      {props.item.showLozenge && assessmentResponses.length > 0 && (
        <Lozenge
          className="assessmentsMenuItem--lozenge"
          intent={Intent.Primary}
          value={assessmentResponses.length}
        />
      )}
    </>
  );
};

export default AssessmentsMenuItem;
