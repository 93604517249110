import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  ControlExtended,
  VendorsVendorIdReviewHistoryItemsGetRelatedToTypeEnum,
} from '../../../../../swagger';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import { ComplianceEditControlForm } from '../ComplianceEditControlForm/ComplianceEditControlForm';
import './ComplianceEditControlDrawer.scss';
import { ApplicationState } from '../../../../../types/applicationState';
import {
  createPayload,
  DrawerType,
  updatePayload,
} from '../../../../../components/compliance/Types/complianceTypes';
import {
  createComplianceControl,
  fetchControlExtended,
  patchComplianceControl,
  patchReasonForExclusion,
} from '../../../../../store/compliance/complianceThunks';
import classNames from 'classnames';
import { EditControlMode } from '../../../../../store/compliance/complianceState';
import {
  addApplicableControl,
  removeNotApplicableControl,
} from '../../../../../store/compliance/complianceSlice';
import { getControlStatusText } from '../ComplianceEditControlForm/complianceStatus';
import { ReviewHistoryItems } from '../../../../../components/compliance/ReviewHistoryItems/ReviewHistoryItems';
import AdoptechHS from '../../../../../components/AdoptechHS/AdoptechHS';

interface EditControlDrawerProps {
  show: boolean;
  control?: ControlExtended;
  onClose(): void;
}

export enum TabsEnum {
  Details = 'Details',
  ReviewHistoryItemsTab = 'Review History',
}

export const ComplianceEditControlDrawer: React.FC<EditControlDrawerProps> = ({
  show,
  control,
  onClose = () => {},
}) => {
  const dispatch = useDispatch();
  const baseCss = 'complianceEditControlDrawer';
  const [selectedTab, setSelectedTab] = useState<TabsEnum>(TabsEnum.Details);
  const { editControlMode, isFetchingControlDetails } = useSelector(
    (state: ApplicationState) => state.compliance
  );

  useEffect(() => {
    setSelectedTab(TabsEnum.Details);
    if (show && control?.id && !control?.tasks) {
      dispatch(fetchControlExtended(control.id));
    }
    return () => {
      setSelectedTab(null);
    };
  }, [show]);

  const editControlFormModel = useSelector(
    (state: ApplicationState) => state.compliance.editControlFormModel
  );

  const currentVendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  const activeDrawer = useSelector(
    (state: ApplicationState) => state.compliance.drawerOnTop
  );

  const submit = () => {
    if (editControlFormModel.create) {
      dispatch(
        createComplianceControl(
          currentVendorId,
          createPayload(editControlFormModel)
        )
      );
    } else {
      const setNotApplicable =
        editControlMode === EditControlMode.MarkNotApplicable;
      if (setNotApplicable) {
        dispatch(
          patchReasonForExclusion(
            editControlFormModel?.id,
            editControlFormModel.reasonForExclusion
          )
        );
        onClose();
        return;
      }
      dispatch(
        patchComplianceControl(
          editControlFormModel?.id,
          updatePayload(editControlFormModel)
        )
      );
    }
    onClose();
  };

  const handleClose = () => {
    const handleNoSave = () => {
      if (editControlMode === EditControlMode.MarkNotApplicable) {
        // 1) Click meatball to mark control as not applicable
        // 2) Click Cancel without saving reason for exclusion
        // 3) => Restore control to the same place inside category

        dispatch(removeNotApplicableControl({ controlId: control.id }));
        dispatch(addApplicableControl(control));
      }
    };

    onClose();
    if (!editControlFormModel?.formTouched) handleNoSave();
  };

  const isDoneDisabled = useMemo(() => {
    const isMarkNotApplicable =
      editControlMode === EditControlMode.MarkNotApplicable &&
      (!editControlFormModel.reasonForExclusion ||
        editControlFormModel.reasonForExclusion.length === 0);

    const isNormal =
      editControlMode === EditControlMode.Normal &&
      (!editControlFormModel?.formTouched || !editControlFormModel?.formValid);

    return isMarkNotApplicable || isNormal;
  }, [editControlFormModel, editControlMode]);

  const statusText = useMemo(
    () =>
      getControlStatusText(
        editControlFormModel?.tasks,
        editControlFormModel?.applicable
      ),
    [editControlFormModel]
  );
  const drawerBackgroundRef = useRef<HTMLDivElement>();
  return (
    <AdoptechHS
      noFormPadding
      title={
        editControlFormModel.create
          ? 'Add a control'
          : editControlFormModel.name
      }
      badges={
        editControlFormModel.create
          ? null
          : isFetchingControlDetails
            ? [{ title: 'Control' }]
            : [{ title: 'Control' }, { title: statusText }]
      }
      show={show}
      ref={drawerBackgroundRef}
      showConfirmationWarning={editControlFormModel?.formTouched}
      onClose={handleClose}
      extraClass={classNames({ onTop: activeDrawer === DrawerType.Control }, [
        baseCss,
      ])}
      footer={
        <>
          <AdoptechButton
            onClick={handleClose}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            disabled={isDoneDisabled}
            onClick={submit}
            variant={AdoptechButtonVariant.Primary}
          >
            {editControlFormModel.create ? 'ADD' : 'DONE'}
          </AdoptechButton>
        </>
      }
    >
      <div className={baseCss + '--formContainer'}>
        <Tabs
          activeKey={selectedTab}
          onSelect={(key: string) => {
            setSelectedTab(key as TabsEnum);
          }}
        >
          <Tab title={TabsEnum.Details} eventKey={TabsEnum.Details}>
            {selectedTab === TabsEnum.Details && (
              <ComplianceEditControlForm control={editControlFormModel} />
            )}
          </Tab>
          <Tab
            title={TabsEnum.ReviewHistoryItemsTab}
            eventKey={TabsEnum.ReviewHistoryItemsTab}
            disabled={!control?.id}
          >
            {selectedTab === TabsEnum.ReviewHistoryItemsTab && (
              <ReviewHistoryItems
                title="Review history"
                noCommentsMode
                type={
                  VendorsVendorIdReviewHistoryItemsGetRelatedToTypeEnum.Control
                }
                id={control?.id}
              />
            )}
          </Tab>
        </Tabs>
      </div>
    </AdoptechHS>
  );
};
