/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TableRow
 */
export interface TableRow {
  /**
   *
   * @type {string}
   * @memberof TableRow
   */
  value?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TableRow
   */
  cells?: Array<string>;
}

export function TableRowFromJSON(json: any): TableRow {
  return TableRowFromJSONTyped(json, false);
}

export function TableRowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TableRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    value: !exists(json, 'value') ? undefined : json['value'],
    cells: !exists(json, 'cells') ? undefined : json['cells'],
  };
}

export function TableRowToJSON(value?: TableRow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    value: value.value,
    cells: value.cells,
  };
}
