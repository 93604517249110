import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExistingPolicyWarningModalMode } from '../../components/ExistingPolicyWarningModal/ExistingPolicyWarningModal';
import { PolicyOverviewSection } from '../../components/PolicyDrawer/PolicyOverviewSection/PolicyOverviewSection';
import { keepCurrentAnswersAndClauses } from '../../functions/keepCurrentAnswersAndClauses';
import { safelyDeleteItemFromArray } from '../../functions/safelyDeleteItemFromArray';
import {
  AgreementModel,
  Policy,
  PolicyCategory,
  VendorDocument,
  VendorDocumentApproval,
  VendorDocumentExtended,
  VendorPolicy,
  VendorPolicyAnswer,
  VendorPolicyClause,
  VendorPolicyDetails,
  VendorPolicyStatusEnum,
} from '../../swagger';
import { PoliciesState, SortDirection } from './policiesState';
import {
  fetchPolicies,
  fetchPolicyAttestationGroups,
  fetchVendorPolicies,
  fetchVendorPoliciesDetails,
  patchPolicyAttestationGroups,
} from './policiesThunks';

const policiesSlice = createSlice({
  initialState: {
    attestationManagementVendorDocument: undefined,
    approvalManagementVendorDocumentId: undefined,
    approvalManagementVendorDocumentName: undefined,
    approvals: [],
    isPaymentSuccessfulShowing: false,
    availableExpandedCategories: [],
    availableSortColumn: undefined,
    availableSortDirection: SortDirection.Ascending,
    completeExpandedCategories: [],
    completePolicyError: undefined,
    completeSortColumn: undefined,
    completeSortDirection: SortDirection.Ascending,
    createPolicyError: undefined,
    deletePolicyError: undefined,
    fetchVendorPolicyError: undefined,
    initialisingCreationOfPolicyId: undefined,
    inProgressSortColumn: undefined,
    inProgressSortDirection: SortDirection.Ascending,
    isDocumentLibraryModalShowing: false,
    isAttestationManagementModalShowing: false,
    isApprovalManagementModalShowing: false,
    isApprovalManagementModalCancelable: false,
    isCompletingPolicy: false,
    isCreatingPolicy: false,
    isDeletingPolicy: false,
    isFetchingPolicies: false,
    isFetchingVendorPolicies: false,
    isFetchingVendorPolicy: false,
    isPatchingVendorPolicy: false,
    isPatchingVendorPolicyClause: false,
    isPolicySummaryToastShowing: false,
    isFetchingPolicyCategories: false,
    policyCategories: [],
    patchVendorPolicyError: undefined,
    patchVendorPolicyClauseError: undefined,
    policies: [],
    policySummaryToastId: undefined,
    searchTerm: undefined,
    searchText: undefined,
    showAutoSaveToast: false,
    vendorPolicies: [],
    loadedDataOnLoad: false,
    postAttestationError: undefined,
    isPostingAttestation: false,
    patchApprovalError: undefined,
    isPostingApproval: false,
    stripeCreatedVendorPolicy: false,
    isSkippingAttestation: false,
    isSkippingApproval: false,
    skipApprovalError: undefined,
    skipAttestationError: undefined,
    policyRefreshRequired: true,
    isFetchingDocumentApprovals: false,
    isExistingPolicyWarningModalShowing: false,
    existingPolicyWarningModalMode: undefined,
    selectedPolicyId: undefined,
    isManageRejectionModalShowing: false,
    rejectedItemToManage: null,
    sendingReadReminder: undefined,
    readReminderError: undefined,
    policyToEdit: undefined,
    isPolicyDrawerShowing: true,
    isPolicyDrawerShowingForReadRequest: false,
    fetchPoliciesStatus: 'idle',
    fetchVendorPoliciesDetailsStatus: 'idle',
    fetchVendorPoliciesStatus: 'idle',
  } as PoliciesState,
  name: 'policiesSlice',
  reducers: {
    clearAutoSave: state => {
      state.showAutoSaveToast = false;
    },
    collapseCategory: (
      state,
      action: PayloadAction<{
        section: VendorPolicyStatusEnum;
        categoryName: string;
      }>
    ) => {
      switch (action.payload.section) {
        case VendorPolicyStatusEnum.Available:
          safelyDeleteItemFromArray(
            state.availableExpandedCategories,
            action.payload.categoryName
          );
          break;
        case VendorPolicyStatusEnum.Completed:
          safelyDeleteItemFromArray(
            state.completeExpandedCategories,
            action.payload.categoryName
          );
          break;
      }
    },
    completePolicyRequest: state => {
      state.completePolicyError = undefined;
      state.isCompletingPolicy = true;
    },
    completePolicySuccess: state => {
      state.isCompletingPolicy = false;
    },
    completePolicyFailure: (state, action: PayloadAction<string>) => {
      state.completePolicyError = action.payload;
      state.isCompletingPolicy = false;
    },
    createPolicyRequest: (state, action: PayloadAction<string>) => {
      state.createPolicyError = undefined;
      state.initialisingCreationOfPolicyId = action.payload;
      state.isCreatingPolicy = true;
    },
    createPolicySuccess: (state, action: PayloadAction<VendorPolicy>) => {
      state.availableExpandedCategories = [];
      state.initialisingCreationOfPolicyId = undefined;
      state.isCreatingPolicy = false;
    },
    createPolicyFailure: (state, action: PayloadAction<string>) => {
      state.createPolicyError = action.payload;
      state.isCreatingPolicy = false;
    },
    deletePolicyRequest: state => {
      state.deletePolicyError = undefined;
      state.isDeletingPolicy = true;
    },
    deletePolicySuccess: state => {
      state.isDeletingPolicy = false;
    },
    deletePolicyFailure: (state, action: PayloadAction<string>) => {
      state.deletePolicyError = action.payload;
      state.isDeletingPolicy = false;
    },
    expandCategory: (
      state,
      action: PayloadAction<{
        section: VendorPolicyStatusEnum;
        categoryName: string;
      }>
    ) => {
      switch (action.payload.section) {
        case VendorPolicyStatusEnum.Available:
          state.availableExpandedCategories.push(action.payload.categoryName);
          break;
        case VendorPolicyStatusEnum.Completed:
          state.completeExpandedCategories.push(action.payload.categoryName);
          break;
      }
    },
    fetchVendorPolicyRequest: state => {
      state.fetchVendorPolicyError = undefined;
      state.isFetchingVendorPolicy = true;
    },
    fetchVendorPolicySuccess: (
      state,
      action: PayloadAction<VendorPolicyDetails>
    ) => {
      state.isFetchingVendorPolicy = false;
      const newVendorPolicies = [...state.vendorPolicies];
      const policyIndex = newVendorPolicies.findIndex(
        vp => vp.id === action.payload.id
      );
      const oldVendorPolicy = { ...state.vendorPolicies[policyIndex] };
      const oldVendorPolicyClauses = oldVendorPolicy?.vendorPolicyClauses;
      let payload: VendorPolicyDetails;
      if (oldVendorPolicyClauses) {
        payload = keepCurrentAnswersAndClauses(
          action,
          oldVendorPolicyClauses,
          oldVendorPolicy
        );
      } else {
        payload = action.payload;
      }

      if (policyIndex === -1) {
        newVendorPolicies.push(payload);
      } else {
        newVendorPolicies.splice(policyIndex, 1, payload);
      }
      state.vendorPolicies = newVendorPolicies;
    },
    fetchVendorPolicyFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingVendorPolicy = false;
      state.fetchVendorPolicyError = action.payload;
    },
    patchVendorPolicyClauseRequest: (
      state,
      action: PayloadAction<VendorPolicyClause>
    ) => {
      state.isPatchingVendorPolicyClause = true;
      state.patchVendorPolicyClauseError = undefined;
      const policy = state.vendorPolicies.find(
        ({ id }) => id === action.payload.vendorPolicyId
      );
      const clause = policy.vendorPolicyClauses.find(
        ({ id }) => id === action.payload.id
      );
      if (!action.payload.clauseTextIsCustom)
        // fast revert
        clause.clauseTextIsCustom = action.payload.clauseTextIsCustom;
      clause.leaveOut = action.payload.leaveOut;
    },
    patchVendorPolicyClauseSuccess: (
      state,
      action: PayloadAction<VendorPolicyClause>
    ) => {
      const policy = state.vendorPolicies.find(
        ({ id }) => id === action.payload.vendorPolicyId
      );
      const clause = policy.vendorPolicyClauses.find(
        ({ name }) => name === action.payload.name
      );
      clause.clauseText = action.payload.clauseText;
      clause.clauseTextIsCustom = action.payload.clauseTextIsCustom;
      clause.questions = action.payload.questions;
      clause.updatedAt = action.payload.updatedAt;
      clause.updatedBy = action.payload.updatedBy;
      state.isPatchingVendorPolicyClause = false;
    },
    patchVendorPolicyClauseFailure: (state, action: PayloadAction<string>) => {
      state.isPatchingVendorPolicyClause = false;
      state.patchVendorPolicyClauseError = action.payload;
    },
    updateVendorPolicyProgress: (
      state,
      action: PayloadAction<VendorPolicyDetails>
    ) => {
      const policy = state.vendorPolicies.find(
        vendorPolicy => vendorPolicy.id === action.payload.id
      );
      policy.progress = action.payload.progress;
    },
    updateVendorPoliciesAnswers: (state, action: PayloadAction<any>) => {
      const policyId = action.payload.policyId;
      const answersPropertyNames = action.payload.answersPropertyNames;
      const { updatedBy, updatedAt } = action.payload;
      const newFields = { updatedAt, updatedBy };

      const policy = state.vendorPolicies.find(
        vendorPolicy => vendorPolicy.id === policyId
      );

      policy.vendorPolicyAnswers = policy.vendorPolicyAnswers.map(answer => {
        if (answersPropertyNames?.includes(answer.propertyName)) {
          return { ...answer, ...newFields };
        }

        return answer;
      });
    },
    patchVendorPolicyRequest: (
      state,
      action: PayloadAction<VendorPolicyDetails>
    ) => {
      state.isPatchingVendorPolicy = true;
      state.patchVendorPolicyError = undefined;

      // Saving current answers to buffer(cache) during request to the backend
      const buffer: Record<string, VendorPolicyAnswer> = {};
      const policyIndex = state.vendorPolicies.findIndex(
        vp => vp.id === action.payload.id
      );
      state.vendorPolicies[policyIndex].vendorPolicyAnswers.forEach(
        answer => (buffer[answer.propertyName] = answer)
      );
      // vendorPolicyAnswers can be null if we patch policy from PolicyDrawer
      action.payload.vendorPolicyAnswers?.forEach(answer => {
        buffer[answer.propertyName] ||= answer;
        buffer[answer.propertyName].value = answer.value;
      });
      state.vendorPolicies[policyIndex].vendorPolicyAnswers =
        Object.values(buffer);
    },
    patchVendorPolicySuccess: (
      state,
      action: PayloadAction<VendorPolicyDetails>
    ) => {
      state.isPatchingVendorPolicy = false;
      const newVendorPolicies = [...state.vendorPolicies];
      const policyIndex = newVendorPolicies.findIndex(
        vp => vp.id === action.payload.id
      );
      const oldVendorPolicy = { ...state.vendorPolicies[policyIndex] };
      const oldVendorPolicyClauses = oldVendorPolicy?.vendorPolicyClauses;
      let payload: VendorPolicyDetails;
      if (oldVendorPolicyClauses) {
        payload = keepCurrentAnswersAndClauses(
          action,
          oldVendorPolicyClauses,
          oldVendorPolicy
        );
      } else {
        payload = action.payload;
      }
      if (policyIndex === -1) {
        newVendorPolicies.push(payload);
      } else {
        newVendorPolicies.splice(policyIndex, 1, payload);
      }
      state.vendorPolicies = newVendorPolicies;
    },
    patchVendorPolicyFailure: (state, action: PayloadAction<string>) => {
      state.isPatchingVendorPolicy = false;
      state.patchVendorPolicyError = action.payload;
    },

    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    showAutoSave: state => {
      state.showAutoSaveToast = true;
    },
    hideIsPaymentSuccessfulShowing: state => {
      state.isPaymentSuccessfulShowing = false;
    },
    showIsPaymentSuccessfulShowing: state => {
      state.isPaymentSuccessfulShowing = true;
    },
    showPolicySummaryToast: (state, action: PayloadAction<string>) => {
      state.isPolicySummaryToastShowing = true;
      state.policySummaryToastId = action.payload;
    },
    hidePolicySummaryToast: state => {
      state.isPolicySummaryToastShowing = false;
      state.policySummaryToastId = undefined;
    },
    closeAttestationManagement: state => {
      state.attestationManagementVendorDocument = undefined;
      state.isAttestationManagementModalShowing = false;
    },
    openAttestationManagement: (
      state,
      action: PayloadAction<VendorDocumentExtended>
    ) => {
      state.attestationManagementVendorDocument = action.payload;
      state.isAttestationManagementModalShowing = true;
    },
    closeApprovalManagement: state => {
      state.approvalManagementVendorDocumentId = undefined;
      state.isApprovalManagementModalShowing = false;
    },
    openApprovalManagement: (
      state,
      action: PayloadAction<{
        id: string;
        name: string;
        isCancelable?: boolean;
      }>
    ) => {
      state.approvalManagementVendorDocumentId = action.payload.id;
      state.approvalManagementVendorDocumentName = action.payload.name;
      state.isApprovalManagementModalShowing = true;
      state.isApprovalManagementModalCancelable = action.payload.isCancelable;
    },
    fetchDocumentApprovalsRequest: state => {
      state.approvals = [];
      state.isFetchingDocumentApprovals = true;
    },
    fetchDocumentApprovalsSuccess: (
      state,
      action: PayloadAction<VendorDocumentApproval[]>
    ) => {
      state.approvals = action.payload;
      state.isFetchingDocumentApprovals = false;
    },
    fetchDocumentApprovalsFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingDocumentApprovals = false;
    },
    postAttestationRequest: state => {
      state.isPostingAttestation = true;
      state.postAttestationError = undefined;
    },
    postAttestationSuccess: state => {
      state.isPostingAttestation = false;
    },
    postAttestationFailure: (state, action: PayloadAction<string>) => {
      state.isPostingAttestation = false;
      state.postAttestationError = action.payload;
    },
    patchApprovalRequest: state => {
      state.isPostingApproval = true;
      state.patchApprovalError = undefined;
    },
    patchApprovalSuccess: state => {
      state.isPostingApproval = false;
    },
    patchApprovalFailure: (state, action: PayloadAction<string>) => {
      state.isPostingApproval = false;
      state.patchApprovalError = action.payload;
    },
    skipAttestationRequest: state => {
      state.isSkippingAttestation = true;
      state.skipAttestationError = undefined;
    },
    skipAttestationSuccess: state => {
      state.isSkippingAttestation = false;
    },
    skipAttestationFailure: (state, action: PayloadAction<string>) => {
      state.isSkippingAttestation = false;
      state.skipAttestationError = action.payload;
    },
    skipApprovalRequest: state => {
      state.isSkippingApproval = true;
      state.skipApprovalError = undefined;
    },
    skipApprovalSuccess: state => {
      state.isSkippingApproval = false;
    },
    skipApprovalFailure: (state, action: PayloadAction<string>) => {
      state.isSkippingApproval = false;
      state.skipApprovalError = action.payload;
    },
    showDocumentLibraryModal: state => {
      state.isDocumentLibraryModalShowing = true;
    },
    hideDocumentLibraryModal: state => {
      state.isDocumentLibraryModalShowing = false;
    },
    showExistingPolicyWarningModal: (
      state,
      action: PayloadAction<{
        policyId: string;
        existingPolicyWarningModalMode: ExistingPolicyWarningModalMode;
      }>
    ) => {
      state.existingPolicyWarningModalMode =
        action.payload.existingPolicyWarningModalMode;
      state.isExistingPolicyWarningModalShowing = true;
      state.selectedPolicyId = action.payload.policyId;
    },
    closeExistingPolicyWarningModal: state => {
      state.isExistingPolicyWarningModalShowing = false;
    },
    openManageRejectionModal: (
      state,
      action: PayloadAction<VendorDocument | AgreementModel>
    ) => {
      state.isManageRejectionModalShowing = true;
      state.rejectedItemToManage = action.payload;
    },
    closeManageRejectionModal: state => {
      state.isManageRejectionModalShowing = false;
      state.rejectedItemToManage = null;
    },
    readReminderRequest: (state, action: PayloadAction<string>) => {
      state.sendingReadReminder = action.payload;
      state.readReminderError = undefined;
    },
    readReminderSuccess: state => {
      state.sendingReadReminder = null;
    },
    readReminderFailure: (state, action: PayloadAction<string>) => {
      state.sendingReadReminder = null;
      state.readReminderError = action.payload;
    },
    showPolicyDrawer: state => {
      state.isPolicyDrawerShowing = true;
    },
    showPolicyDrawerForReadRequest: state => {
      state.isPolicyDrawerShowing = true;
      state.isPolicyDrawerShowingForReadRequest = true;
    },
    hidePolicyDrawer: state => {
      state.isPolicyDrawerShowing = false;
      state.isPolicyDrawerShowingForReadRequest = false;
      state.policyToEdit = null;
    },
    updatePolicyToEdit: (
      state,
      action: PayloadAction<VendorPolicyDetails | VendorDocumentExtended>
    ) => {
      state.policyToEdit = action.payload;
    },
    updatePolicyToEditField: (
      state,
      action: PayloadAction<Partial<PolicyOverviewSection>>
    ) => {
      state.policyToEdit = {
        ...state.policyToEdit,
        ...action.payload,
      };
    },
    fetchPolicyTemplateCategoriesRequest: state => {
      state.isFetchingPolicyCategories = true;
    },
    fetchPolicyTemplateCategoriesSuccess: (
      state,
      action: PayloadAction<PolicyCategory[]>
    ) => {
      state.isFetchingPolicyCategories = false;
      state.policyCategories = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchVendorPoliciesDetails.pending, state => {
        state.fetchVendorPoliciesDetailsStatus = 'loading';
      })
      .addCase(
        fetchVendorPoliciesDetails.fulfilled,
        (state, action: PayloadAction<VendorPolicyDetails[]>) => {
          state.fetchVendorPoliciesDetailsStatus = 'succeeded';
          if (
            window.location.pathname.endsWith('/policies/draft') ||
            window.location.pathname.endsWith('/policies')
          ) {
            state.vendorPolicies = action.payload;
          }
        }
      )
      .addCase(fetchVendorPoliciesDetails.rejected, state => {
        state.fetchVendorPoliciesDetailsStatus = 'failed';
      })
      .addCase(fetchVendorPolicies.pending, state => {
        state.fetchVendorPoliciesStatus = 'loading';
      })
      .addCase(
        fetchVendorPolicies.fulfilled,
        (state, action: PayloadAction<VendorPolicy[]>) => {
          state.fetchVendorPoliciesStatus = 'succeeded';
        }
      )
      .addCase(fetchVendorPolicies.rejected, state => {
        state.fetchVendorPoliciesStatus = 'failed';
      })
      .addCase(fetchPolicies.pending, state => {
        state.fetchPoliciesStatus = 'loading';
      })
      .addCase(
        fetchPolicies.fulfilled,
        (state, action: PayloadAction<Policy[]>) => {
          state.fetchPoliciesStatus = 'succeeded';
          state.policies = action.payload;
        }
      )
      .addCase(fetchPolicies.rejected, state => {
        state.fetchPoliciesStatus = 'failed';
      })
      .addCase(fetchPolicyAttestationGroups.pending, state => {
        state.fetchPolicyAttestationGroupsStatus = 'loading';
      })
      .addCase(fetchPolicyAttestationGroups.fulfilled, (state, _) => {
        state.fetchPolicyAttestationGroupsStatus = 'succeeded';
      })
      .addCase(fetchPolicyAttestationGroups.rejected, state => {
        state.fetchPolicyAttestationGroupsStatus = 'failed';
      })
      .addCase(patchPolicyAttestationGroups.pending, state => {
        state.patchPolicyAttestationGroupsStatus = 'loading';
      })
      .addCase(patchPolicyAttestationGroups.fulfilled, (state, _) => {
        state.patchPolicyAttestationGroupsStatus = 'succeeded';
      })
      .addCase(patchPolicyAttestationGroups.rejected, state => {
        state.patchPolicyAttestationGroupsStatus = 'failed';
      });
  },
});

export const {
  clearAutoSave,
  collapseCategory,
  completePolicyRequest,
  completePolicySuccess,
  completePolicyFailure,
  createPolicyRequest,
  createPolicySuccess,
  createPolicyFailure,
  deletePolicyRequest,
  deletePolicySuccess,
  deletePolicyFailure,
  expandCategory,
  fetchVendorPolicyRequest,
  fetchVendorPolicySuccess,
  fetchVendorPolicyFailure,
  patchVendorPolicyClauseRequest,
  patchVendorPolicyClauseSuccess,
  patchVendorPolicyClauseFailure,
  patchVendorPolicyRequest,
  patchVendorPolicySuccess,
  patchVendorPolicyFailure,
  updateVendorPoliciesAnswers,
  updateVendorPolicyProgress,
  setSearchTerm,
  setSearchText,
  showAutoSave,
  hidePolicySummaryToast,
  showPolicySummaryToast,
  hideIsPaymentSuccessfulShowing,
  showIsPaymentSuccessfulShowing,
  closeAttestationManagement,
  openAttestationManagement,
  postAttestationRequest,
  postAttestationSuccess,
  postAttestationFailure,
  patchApprovalRequest,
  patchApprovalSuccess,
  patchApprovalFailure,
  skipAttestationRequest,
  skipAttestationSuccess,
  skipAttestationFailure,
  skipApprovalRequest,
  skipApprovalSuccess,
  skipApprovalFailure,
  closeApprovalManagement,
  openApprovalManagement,
  fetchDocumentApprovalsRequest,
  fetchDocumentApprovalsSuccess,
  fetchDocumentApprovalsFailure,
  fetchPolicyTemplateCategoriesRequest,
  fetchPolicyTemplateCategoriesSuccess,
  showDocumentLibraryModal,
  hideDocumentLibraryModal,
  showExistingPolicyWarningModal,
  closeExistingPolicyWarningModal,
  openManageRejectionModal,
  closeManageRejectionModal,
  readReminderRequest,
  readReminderSuccess,
  readReminderFailure,
  hidePolicyDrawer,
  showPolicyDrawer,
  showPolicyDrawerForReadRequest,
  updatePolicyToEdit,
  updatePolicyToEditField,
} = policiesSlice.actions;

export default policiesSlice.reducer;
