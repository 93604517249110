import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calculatePolicyProgress } from '../../store/policies/policiesThunks';
import { VendorPolicyDetails } from '../../swagger';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { ApplicationState } from '../../types/applicationState';

import './PolicyProgress.scss';
import classNames from 'classnames';

export const PolicyProgress: React.FC<VendorPolicyDetails> = policy => {
  const dispatch = useDispatch();

  const selectVendorPolicy = (state: ApplicationState) =>
    state.policies.vendorPolicies.find(vp => vp.id === policy.id);

  const storePolicy = useSelector(selectVendorPolicy);
  // allows to use component as view only for externally prepared progress value.
  const progress = policy.progress || storePolicy?.progress || 0;

  const percentage = Math.round(progress * 100);
  const completedPercentage = 100;

  const barClasses = classNames('policyProgress--bar', {
    'policyProgress--green': percentage >= completedPercentage,
    'policyProgress--orange': percentage < completedPercentage,
  });

  return (
    <div className="policyProgress">
      <div className="policyProgress--percentage">{percentage}%</div>
      <div className={barClasses}>
        <div style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );
};
