import moment from 'moment';
import { Question, QuestionLayoutEnum } from '../swagger';
import getTableAnswers from './getTableAnswers';
import { CommonAnswerType } from '../types/CommonAnswerType';

export const getQuestionLastUpdatedInfo = (
  question: Question,
  answers: CommonAnswerType[],
  indexProperty: 'name' | 'propertyName',
  isConfirmed?: boolean,
  checkShowAuditInfo?: boolean
) => {
  if (!question) {
    return undefined;
  }

  if (!answers) {
    return undefined;
  }

  const timeField = isConfirmed ? 'confirmedAt' : 'updatedAt';

  if (question.layout === QuestionLayoutEnum.TableLayout) {
    const lastUpdatedAnswer = getTableAnswers({
      question,
      answers,
      indexProperty,
    }).sort((a: CommonAnswerType, b: CommonAnswerType) =>
      moment(a[timeField]).isBefore(b[timeField]) ? 1 : -1
    )?.[0];

    if (
      !lastUpdatedAnswer ||
      (checkShowAuditInfo && !lastUpdatedAnswer.showAuditInfo)
    ) {
      return null;
    }

    return {
      confirmedAt: lastUpdatedAnswer.confirmedAt,
      confirmedBy: lastUpdatedAnswer.confirmedBy,
      updatedAt: lastUpdatedAnswer.updatedAt,
      updatedBy: lastUpdatedAnswer.updatedBy,
    };
  }

  if (question.layout === QuestionLayoutEnum.CheckBoxes) {
    const checkboxAnswers = answers
      .filter(a => a[indexProperty]?.startsWith(question[indexProperty]))
      .sort((a, b) => (moment(a[timeField]).isBefore(b[timeField]) ? 1 : -1));

    if (
      !checkboxAnswers ||
      checkboxAnswers.length === 0 ||
      (checkShowAuditInfo &&
        checkboxAnswers.some(
          (checkboxAnswer: CommonAnswerType) => !checkboxAnswer.showAuditInfo
        ))
    ) {
      return null;
    }

    return {
      confirmedAt: checkboxAnswers[0].confirmedAt,
      confirmedBy: checkboxAnswers[0].confirmedBy,
      updatedAt: checkboxAnswers[0].updatedAt,
      updatedBy: checkboxAnswers[0].updatedBy,
    };
  }

  const answer = answers.find(
    a => a[indexProperty] === question[indexProperty]
  );

  if (!answer || (checkShowAuditInfo && !answer.showAuditInfo)) {
    return null;
  }

  return {
    confirmedAt: answer.confirmedAt,
    confirmedBy: answer.confirmedBy,
    updatedAt: answer.updatedAt,
    updatedBy: answer.updatedBy,
  };
};
