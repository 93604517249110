import { createSelector } from '@reduxjs/toolkit';
import { legislationSort } from '../functions/legislationSort';
import { LegislationModel } from '../swagger';
import { ApplicationState } from '../types/applicationState';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';
import Fuse from 'fuse.js';

export const selectLegislations = createSelector(
  (state: ApplicationState) => state.legalRegister.vendorLegislations,
  (state: ApplicationState) => state.legalRegister.legislationsSearch,
  selectSortSettings(Grid.LegalRegister),
  (legislations: LegislationModel[], search, sortSetting) => {
    if (!legislations?.length) return [];

    let filteredLegislations = legislations;

    const fuse = new Fuse(filteredLegislations, {
      ignoreLocation: true,
      includeScore: true,
      keys: [
        'name',
        'jurisdiction.name',
        'legislationClassification.name',
        'summary',
        'owner.fullName',
        'owner.email',
        'reviewDate',
      ],
      threshold: 0,
    });

    if (search) {
      filteredLegislations = fuse.search(search).map(x => x.item);
    }

    return [...filteredLegislations].sort((a, b) =>
      legislationSort(a, b, sortSetting.columnSort)
    );
  }
);
