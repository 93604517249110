import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { setCurrentAudit } from './store/auditsSlice';
import { fetchAudit } from './store/auditsThunks';
import { AuditModelStatusEnum } from '../../../swagger/models/AuditModel';
import { ApplicationState } from '../../../types/applicationState';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { auditsPageRoute } from '../../../components/Routes/Routes';
import { AuditsSummaryTile } from './AuditsSummaryTile';
import './AuditsEditor.scss';
import { PreviewMode } from './PreviewMode/PreviewMode';
import { AuditsEditDrawer } from './AuditsEditDrawer';
import { QuestionsMode } from './QuestionsMode/QuestionsMode';

export enum AuditMode {
  Edit = 'edit',
  Preview = 'preview',
}

export const AuditsEditor: React.FC = () => {
  const baseCss = 'auditsEditor';

  const { currentAudit, isFetchingAuditSpinner, isCompletingAudit } =
    useSelector((state: ApplicationState) => state.audits);

  const [mode, setMode] = useState<AuditMode>(AuditMode.Edit);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const showEditDrawer = () => setDrawerVisible(true);
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  useEffect(() => {
    dispatch(setCurrentAudit(null));

    if (params?.id) {
      dispatch(fetchAudit(params.id));
    }
    return () => {
      dispatch(setCurrentAudit(null));
    };
  }, [params?.id]);

  if (
    isFetchingAuditSpinner ||
    isCompletingAudit ||
    !currentAudit ||
    currentAudit?.id !== params.id
  )
    return <LoadingSpinner />;

  if (currentAudit?.status === AuditModelStatusEnum.Completed) {
    dispatch(push(auditsPageRoute));
  }

  return (
    <div className={baseCss}>
      <div className={baseCss + '--summary'}>
        <div className={baseCss + '--summaryTile'}>
          <AuditsSummaryTile
            mode={mode}
            setMode={setMode}
            onEdit={showEditDrawer}
          />
        </div>
      </div>
      {mode === AuditMode.Edit && <QuestionsMode />}
      {mode === AuditMode.Preview && <PreviewMode />}
      <AuditsEditDrawer
        audit={currentAudit}
        show={drawerVisible}
        onClose={() => setDrawerVisible(false)}
      />
    </div>
  );
};
