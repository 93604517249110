import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';
import { ChecklistTemplateCard } from '../ChecklistTemplateCard/ChecklistTemplateCard';
import { GenerateChecklistManagersModal } from '../GenerateChecklistManagersModal/GenerateChecklistManagersModal';
import { GenerateChecklistSuccessToast } from '../GenerateChecklistSuccessToast/GenerateChecklistSuccessToast';
import { GenerateChecklistUsersModal } from '../GenerateChecklistUsersModal/GenerateChecklistUsersModal';
import { Lozenge } from '../Lozenge/Lozenge';
import './ChecklistTemplates.scss';

export const ChecklistTemplates: React.FC = () => {
  const checklistTemplates = useSelector(
    (state: ApplicationState) => state.checklists.checklistTemplates
  );

  return (
    <div className="checklistTemplates">
      <div className="checklistTemplates--title">
        Templates
        <Lozenge value={checklistTemplates.length} />
      </div>

      <div className="checklistTemplates--grid">
        {checklistTemplates.map(checklistTemplate => (
          <ChecklistTemplateCard
            checklistTemplate={checklistTemplate}
            key={checklistTemplate.id}
          />
        ))}
      </div>
      <GenerateChecklistUsersModal />
      <GenerateChecklistManagersModal />
      <GenerateChecklistSuccessToast />
    </div>
  );
};
