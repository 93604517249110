/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorUserComplianceStats
 */
export interface VendorUserComplianceStats {
  /**
   *
   * @type {boolean}
   * @memberof VendorUserComplianceStats
   */
  overall?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorUserComplianceStats
   */
  policies?: boolean;
}

export function VendorUserComplianceStatsFromJSON(
  json: any
): VendorUserComplianceStats {
  return VendorUserComplianceStatsFromJSONTyped(json, false);
}

export function VendorUserComplianceStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorUserComplianceStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    overall: !exists(json, 'overall') ? undefined : json['overall'],
    policies: !exists(json, 'policies') ? undefined : json['policies'],
  };
}

export function VendorUserComplianceStatsToJSON(
  value?: VendorUserComplianceStats | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    overall: value.overall,
    policies: value.policies,
  };
}
