/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedPaymentPeriod,
  SharedPaymentPeriodFromJSON,
  SharedPaymentPeriodFromJSONTyped,
  SharedPaymentPeriodToJSON,
} from './';

/**
 *
 * @export
 * @interface RecurringPaymentCheckoutCreatePayload
 */
export interface RecurringPaymentCheckoutCreatePayload {
  /**
   *
   * @type {string}
   * @memberof RecurringPaymentCheckoutCreatePayload
   */
  productId: string;
  /**
   *
   * @type {string}
   * @memberof RecurringPaymentCheckoutCreatePayload
   */
  vendorId: string;
  /**
   *
   * @type {string}
   * @memberof RecurringPaymentCheckoutCreatePayload
   */
  purchasableId: string;
  /**
   *
   * @type {string}
   * @memberof RecurringPaymentCheckoutCreatePayload
   */
  purchasableType: string;
  /**
   *
   * @type {SharedPaymentPeriod}
   * @memberof RecurringPaymentCheckoutCreatePayload
   */
  paymentPeriod: SharedPaymentPeriod;
}

export function RecurringPaymentCheckoutCreatePayloadFromJSON(
  json: any
): RecurringPaymentCheckoutCreatePayload {
  return RecurringPaymentCheckoutCreatePayloadFromJSONTyped(json, false);
}

export function RecurringPaymentCheckoutCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RecurringPaymentCheckoutCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    productId: json['product_id'],
    vendorId: json['vendor_id'],
    purchasableId: json['purchasable_id'],
    purchasableType: json['purchasable_type'],
    paymentPeriod: SharedPaymentPeriodFromJSON(json['payment_period']),
  };
}

export function RecurringPaymentCheckoutCreatePayloadToJSON(
  value?: RecurringPaymentCheckoutCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    product_id: value.productId,
    vendor_id: value.vendorId,
    purchasable_id: value.purchasableId,
    purchasable_type: value.purchasableType,
    payment_period: SharedPaymentPeriodToJSON(value.paymentPeriod),
  };
}
