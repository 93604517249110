import { VendorSupplierExtended, VendorSupplierStatus } from '../../swagger';
import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { SelectionOption } from '../../types/selectionOption';
import { Accordion } from 'react-bootstrap';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { AdoptechDatePicker } from '../AdoptechDatePicker/AdoptechDatePicker';
import { ApplicationState } from '../../types/applicationState';
import { useOwnerOptions } from '../../hooks/useOwnerOptions';
import {
  supportedSuppliers,
  VendorSupplierLogo,
} from '../../features/risk/vendor/VendorSupplierLogo';
import { AdoptechTextSuggestion } from '../AdoptechTextInput/AdoptechTextSuggestion';

interface OverviewSectionProps {
  formData: VendorSupplierExtended;
  setFormData: Dispatch<SetStateAction<VendorSupplierExtended>>;
}

export const validation = (
  data: VendorSupplierExtended
): Record<string, string[]> => ({
  name: (data.name || '').trim().length > 0 ? null : ['Name must be present'],
  owner: data.owner ? null : ['Owner must be set'],
  category: data.vendorSupplierCategoryId ? null : ['Category must be set'],
  reviewDate: data.reviewDate ? null : ['Review date must be set'],
  status: data.status ? null : ['Status must be set'],
});

export const OverviewSection: React.FC<OverviewSectionProps> = ({
  formData,
  setFormData,
}) => {
  const baseCss = 'manageSupplierDrawer';
  const { users, ownerOptions } = useOwnerOptions({
    onlyAdminable: true,
  });
  const reviewDate = formData.reviewDate ? new Date(formData.reviewDate) : null;

  const categories = useSelector(
    (state: ApplicationState) => state.vendors.vendorSupplierCategories
  );

  const categoryOptions = categories.map(category => ({
    value: category.id,
    label: category.name,
  }));

  const statusOptions = [
    {
      value: VendorSupplierStatus.Active,
      label: 'Active',
    },
    {
      value: VendorSupplierStatus.Inactive,
      label: 'Inactive',
    },
  ];

  const hasError = (field: string) => !!validation(formData)[field];

  const suggestionsSelector = (value: string) => {
    if (!value) return [];
    const names = supportedSuppliers.map(ss => ss.name);
    return names.filter(n => n.toLowerCase().indexOf(value.toLowerCase()) > -1);
  };

  const id = formData?.id || 'new';
  return (
    <Accordion defaultActiveKey="0">
      <AdoptechAccordionCard
        title="Overview"
        index="0"
        cardBodyClass="card-body--centered withBorderBottom"
        headerClass="noBorderBottom positionInitial"
        iconSize="small"
        noMargin
      >
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Vendor name</div>
            <AdoptechTextSuggestion
              id={`vendor-${id}-nameInput`}
              value={formData?.name || ''}
              type="text"
              hasError={hasError('name')}
              onChange={e =>
                setFormData({ ...formData, name: e.currentTarget.value })
              }
              placeholder="Enter Vendor name"
              suggestionsSelector={suggestionsSelector}
              suggestionElement={value => <VendorSupplierLogo name={value} />}
              suggestionSet={suggestion =>
                setFormData({ ...formData, name: suggestion })
              }
            />
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Owner</div>
            <AdoptechReactSelect
              id={`vendor-${id}-ownerSelect`}
              isDisabled={false}
              options={ownerOptions}
              hasError={hasError('owner')}
              onChange={(e: SelectionOption) => {
                setFormData({
                  ...formData,
                  owner: users.find(user => user.id === e.value),
                });
              }}
              value={ownerOptions.find(
                option => option.value == formData?.owner?.id
              )}
              placeholder="Select owner"
              showUserAvatar
            />
          </div>
          <div
            className={
              baseCss +
              '--field ' +
              // TODO: implement hasError prop in DatePicker
              (hasError('reviewDate') ? baseCss + '--error' : '')
            }
          >
            <AdoptechDatePicker
              disabled={false}
              labelText="Review date"
              labelStyle={baseCss + '--label'}
              outputDate={reviewDate}
              onDateSelect={d => {
                setFormData({
                  ...formData,
                  reviewDate: d.toJSON().split('T')[0],
                });
              }}
              placeHolderText="Select review date"
            />
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Category</div>
            <AdoptechReactSelect
              id={`vendor-${id}-categorySelect`}
              isDisabled={false}
              options={categoryOptions}
              hasError={hasError('category')}
              onChange={(e: SelectionOption) => {
                setFormData({
                  ...formData,
                  vendorSupplierCategoryId: e.value,
                });
              }}
              value={categoryOptions.find(
                o => o.value == formData?.vendorSupplierCategoryId
              )}
              placeholder="Select category"
            />
          </div>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Status</div>
            <AdoptechReactSelect
              id={`vendor-${id}-statusSelect`}
              isDisabled={false}
              options={statusOptions}
              hasError={hasError('status')}
              onChange={(e: SelectionOption) => {
                setFormData({
                  ...formData,
                  status:
                    e.value == 'active'
                      ? VendorSupplierStatus.Active
                      : VendorSupplierStatus.Inactive,
                });
              }}
              value={statusOptions.find(o => o.value === formData?.status)}
              placeholder="Set status"
            />
          </div>
        </div>
      </AdoptechAccordionCard>
    </Accordion>
  );
};
