import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './AdoptechDrawer.scss';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { moveChatWidgetPosition } from '../../functions/chatWidgetHelper';

export interface AdoptechDrawerProps {
  title: string;
  inverted?: boolean;
  show: boolean;
  className?: string;
  onClose?(): void;
  backButton?: boolean;
  onBackClick?(): void;
  badges?: Array<{ title: string }>;
}

// for RHS please use AdoptechHS component
// Need to create a controller for dynamic drawers and modals, to reduce code duplication and styles collision
export const AdoptechDrawer: React.FC<AdoptechDrawerProps> = ({
  title = '',
  inverted,
  className = '',
  show = false,
  children,
  backButton,
  badges,
  onClose = () => {},
  onBackClick = () => {},
}) => {
  const openClass = 'adoptechDrawer--open';
  const classes = classNames(
    'adoptechDrawer',
    show ? openClass : '',
    className
  );

  const headerClasses = classNames(
    'adoptechDrawer--header',
    inverted ? 'adoptechDrawer--header-inverted' : '',
    backButton ? 'adoptechDrawer--header--with-back-button' : ''
  );

  const ref = useRef(null);

  useEffect(() => {
    const toggleChatWidgetPosition = () => {
      setTimeout(() => {
        const offsetWidth =
          (document.querySelector(`.${openClass}`) as HTMLElement)
            ?.offsetWidth || 0;
        moveChatWidgetPosition(offsetWidth + 20);
      }, 100);
    };

    toggleChatWidgetPosition();
    return () => {
      toggleChatWidgetPosition();
    };
  }, [show]);
  return ReactDOM.createPortal(
    <div className={classes} ref={ref}>
      <div className={headerClasses}>
        <div className="adoptechDrawer--titleContainer">
          {backButton && (
            <span className="adoptechDrawer--back-button" onClick={onBackClick}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </span>
          )}
          {(badges || []).map(badge => (
            <div
              key={badge.title}
              className={classNames(
                'adoptechDrawer--badge',
                `adoptechDrawer--badge-${(badge.title || '')
                  .toLowerCase()
                  .split(' ')
                  .join('-')}`
              )}
            >
              {badge.title}
            </div>
          ))}

          <span className="adoptechDrawer--title">{title}</span>
        </div>
        <FontAwesomeIcon
          icon={faXmark}
          onClick={onClose}
          className="adoptechDrawer--close"
        />
      </div>
      {show && children}
    </div>,
    document.querySelector('#drawer')
  );
};
