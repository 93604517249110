import { api } from '../api/api';
import { EmailArray } from '../swagger';

export const checkEmailList = async (
  emailAddressList: string
): Promise<EmailArray> => {
  return await api().parseEmailsPost({
    email: { text: emailAddressList },
  });
};
