import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgreementsCreateForm } from '../../../../selectors/selectAgreementsCreateForm';
import {
  cleanupAgreementsCreateForm,
  toggleAddAgreementDrawer,
  updateAgreementsCreateFormField,
} from '../../../../store/agreements/agreementsSlice';
import { hideDocumentLibraryModal } from '../../../../store/policies/policiesSlice';
import {
  ExternalSignatoryForm,
  SignatoryTypeMode,
} from '../../../ExternalSignatoryForm/ExternalSignatoryForm';
import { AgreementsCreateForm } from '../AddNewAgreementForm';
import {
  AgreementCreatePayloadAgreementSignatories,
  AgreementSignatoryModelTypeEnum,
} from '../../../../swagger';
import { isValidEmailAddress } from '../../../../functions/isValidEmailAddress';
import { ApplicationState } from '../../../../types/applicationState';
import { AgreementsEditForm } from '../../EditAgreementForm/EditAgreementForm';

// We reuse this method both in Add and Edit form
export const SignatoriesStepIsValid = (
  form: AgreementsCreateForm | AgreementsEditForm
) => {
  type Fields = Array<keyof AgreementsCreateForm['signatory']>;
  const requiredIndividualFields: Fields = [
    'email',
    'firstName',
    'lastName',
    'address',
  ];
  const requiredCompanyFields: Fields = [
    'email',
    'firstName',
    'lastName',
    'companyAddress',
    'companyName',
  ];

  const requiredFields =
    (form.signatory || {}).type ===
    AgreementSignatoryModelTypeEnum.ExternalIndividual
      ? requiredIndividualFields
      : requiredCompanyFields;
  return requiredFields.every(field => (form.signatory || {})[field]);
};

export const SignatoriesStep: React.FC = () => {
  const baseCss = 'addNewAgreementForm';
  const form = useSelector(selectAgreementsCreateForm);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(hideDocumentLibraryModal());
    dispatch(toggleAddAgreementDrawer());
    dispatch(cleanupAgreementsCreateForm());
  };

  const handleChange = (
    signatory: AgreementCreatePayloadAgreementSignatories
  ) => {
    const formSignatory = { ...signatory };
    if (formSignatory.email && !isValidEmailAddress(formSignatory.email)) {
      formSignatory.email = '';
    }

    dispatch(
      updateAgreementsCreateFormField({
        signatory: formSignatory,
      })
    );
  };

  const agreementTemplates = useSelector(
    (state: ApplicationState) => state.agreements.agreementTemplates
  );
  const standardTemplate = agreementTemplates.find(
    template => template.id === form.standardTemplateId
  );

  const mode =
    standardTemplate.agreementType === 'employee_agreement'
      ? SignatoryTypeMode.employee
      : null;

  return (
    <>
      {mode != SignatoryTypeMode.employee && (
        <span className={baseCss + '--label'}>
          Is the other party an individual or company?
        </span>
      )}
      <ExternalSignatoryForm
        standardTemplateId={form.standardTemplateId}
        templateId={form.selectedTermsId}
        name={form.name}
        signatory={form.signatory}
        onSubmit={handleSubmit}
        mode={mode}
        onChange={handleChange}
      />
    </>
  );
};
