import { useDispatch, useSelector } from 'react-redux';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import React from 'react';
import { ApplicationState } from '../../../types/applicationState';
import { calendarState } from '../store/calendarState';
import { setCalendarFilters } from '../store/calendarSlice';
import { useAdoptechDebounce } from '../../../hooks/useAdoptechDebounce';
import { fetchCalendarItems } from '../store/calendarThunks';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';

export const CalendarSearch: React.FC = () => {
  const {
    filters,
    filters: { search, viewMode },
  } = useSelector((state: ApplicationState) => state.calendar);
  const dispatch = useDispatch();
  const checkWithDebounce = useAdoptechDebounce();
  const currentVendorUser = useSelector(selectCurrentVendorUser);
  const updateFilters = (filter: any) => {
    const filtersPayload: calendarState['filters'] = {
      ...filters,
      ...filter,
      page: 1,
    };
    dispatch(setCalendarFilters(filtersPayload));
    checkWithDebounce(() => {
      const value = (filter.search as string) || '';
      if (value.length >= 3 || value.length === 0) {
        dispatch(
          fetchCalendarItems({
            filters: filtersPayload,
            currentVendorUserId: currentVendorUser.id,
          })
        );
      }
    });
  };

  const placeholders = {
    actions_and_events: 'Search for actions and events',
    incident_log: 'Search for incidents',
    audit_schedule:
      'Search for security meetings, internal and external audits',
    corrective_actions: 'Search for corrective actions',
  };

  return (
    <AdoptechTextInput
      id="search"
      value={search}
      onChange={e => {
        updateFilters({ search: e.target.value });
      }}
      type="text"
      placeholder={placeholders[viewMode]}
      icon={faSearch}
      additionalClass="adoptechTextInput-search"
    />
  );
};
