import React, { useMemo, useState } from 'react';
import AdoptechModal from '../../../../components/AdoptechModal/AdoptechModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { AdoptechTextInput } from '../../../../components/AdoptechTextInput/AdoptechTextInput';
import { useDispatch, useSelector } from 'react-redux';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import './TrustHubRequestDocumentModal.scss';
import { AddDocumentPermissionFormData } from '../../admin/UserAdmin/TrusthubUserAdminAddDocumentPermissionDrawer/TrusthubUserAdminAddDocumentPermissionDrawer';
import { ApplicationState } from '../../../../types/applicationState';
import { postTrustHubAccessRequest } from '../../store/trusthubThunks';
import { showGlobalToast } from '../../../../store/global/globalSlice';
import { currentTrustHubDomain } from '../TrustHubUserPage/TrustHubUserPage';
import { RequestDocumentModalDocumentSection } from './RequestDocumentModalDocumentSection/RequestDocumentModalDocumentSection';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import Fuse from 'fuse.js';
import { NoDataText } from '../../../../components/NoDataText/NoDataText';
import { selectCurrentUser } from '../../store/trusthubSelectors';

interface TrustHubRequestDocumentModalProps {
  onClose: () => void;
}
export const TrustHubRequestDocumentModal: React.FC<
  TrustHubRequestDocumentModalProps
> = ({ onClose }) => {
  const baseCss = 'trusthubRequestDocumentModal';

  const [documentsSearch, setDocumentsSearch] = useState('');
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<AddDocumentPermissionFormData>({});
  const user = useSelector(selectCurrentUser);
  const { createAccessRequestStatus, sections: allSections } = useSelector(
    (state: ApplicationState) => state.trustHub
  );
  const domain = currentTrustHubDomain();
  const handleSubmit = () => {
    dispatch(
      postTrustHubAccessRequest(
        {
          vendorIdOrDomain: domain,
          body: {
            trusthubAccessRequest: {
              trusthubVendorUserId: user.id,
              trusthubDocumentIds: formData.selectedDocumentsIds,
            },
          },
        },
        _ => {
          dispatch(
            showGlobalToast(
              'Request sent. Our team is reviewing your request and you will receive an email if access is approved.'
            )
          );
          onClose();
        }
      )
    );
  };

  let sections = [...allSections];

  const fuse = new Fuse(sections, {
    ignoreLocation: true,
    includeScore: true,
    keys: ['name', 'trusthubDocuments.name'],
    threshold: 0,
  });

  if (documentsSearch) {
    sections = fuse.search(documentsSearch).map(x => x.item);
  }

  const sectionsList = useMemo(() => {
    return sections
      .filter(section => section.visible)
      .sort((a, b) => a.rowOrder || 0 - b.rowOrder || 0)
      .filter(section => section.trusthubDocuments.length > 0)
      .map(section => {
        return (
          <RequestDocumentModalDocumentSection
            key={section.id}
            section={section}
            formData={formData}
            setFormData={setFormData}
          />
        );
      });
  }, [sections, formData]);

  const allIds: string[] = sections
    .map(section =>
      section.trusthubDocuments.filter(doc => doc.restricted).map(doc => doc.id)
    )
    .flat();

  const handleSelectAll = () => {
    setFormData(prevFormData => {
      const allChecked =
        (prevFormData.selectedDocumentsIds || []).length === allIds.length;
      const payload = {
        selectedDocumentsIds: allChecked ? [] : allIds,
      };

      return {
        ...prevFormData,
        ...payload,
      };
    });
  };
  return (
    <AdoptechModal show onHide={onClose} className={baseCss}>
      <div className={baseCss + '--header'}>
        <div className={baseCss + '--headerTitle'}>Request Document Access</div>
        <FontAwesomeIcon icon={faXmark} onClick={onClose} />
      </div>
      <div className={baseCss + '--body'}>
        <div className={baseCss + '--search'}>
          <AdoptechTextInput
            id="search"
            value={documentsSearch}
            onChange={e => setDocumentsSearch(e.currentTarget.value)}
            type="text"
            placeholder="Search for documents"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>
        <div className={baseCss + '--divider'} />
        {sectionsList}
        {sections.length === 0 && (
          <NoDataText extraClass="mt-2" text="No documents found" />
        )}
      </div>
      <div className={baseCss + '--footer'}>
        <AdoptechButton
          variant={AdoptechButtonVariant.White}
          onClick={handleSelectAll}
          disabled={sections.length === 0}
        >
          Select All
        </AdoptechButton>
        <AdoptechButton
          variant={AdoptechButtonVariant.DarkGray}
          onClick={() => handleSubmit()}
          busy={createAccessRequestStatus === 'loading'}
          disabled={(formData.selectedDocumentsIds || []).length === 0}
        >
          Confirm
        </AdoptechButton>
      </div>
    </AdoptechModal>
  );
};
