import classNames from 'classnames';
import React from 'react';
import './AdoptechCircleIcon.scss';

interface AdoptechCircleIconProps {
  className?: string;
  variant: 'lightBlue' | 'green' | 'red' | 'lightBlue2';
}

export const AdoptechCircleIcon: React.FC<AdoptechCircleIconProps> = props => {
  return (
    <div
      className={classNames(
        'adoptechCircleIcon',
        'adoptechCircleIcon--' + props.variant
      )}
    >
      {props.children}
    </div>
  );
};
