/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  FrameworkRelation,
  FrameworkRelationFromJSON,
  FrameworkRelationFromJSONTyped,
  FrameworkRelationToJSON,
  ReviewStatusEnum,
  ReviewStatusEnumFromJSON,
  ReviewStatusEnumFromJSONTyped,
  ReviewStatusEnumToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface ControlModel
 */
export interface ControlModel {
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  identifier?: string;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  descriptionAsHtml: string | null;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  treatmentPlan?: string | null;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  treatmentPlanAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  reasonForExclusion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ControlModel
   */
  applicable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ControlModel
   */
  custom?: boolean;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  status?: ControlModelStatusEnum;
  /**
   *
   * @type {VendorUser}
   * @memberof ControlModel
   */
  assignee?: VendorUser;
  /**
   *
   * @type {Array<FrameworkRelation>}
   * @memberof ControlModel
   */
  frameworksRelations?: Array<FrameworkRelation>;
  /**
   *
   * @type {ReviewStatusEnum}
   * @memberof ControlModel
   */
  reviewStatus?: ReviewStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ControlModel
   */
  reviewOverdue?: boolean;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  nextReviewDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  helpArticleUrl?: string | null;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ControlModel
   */
  updatedAt: string;
}

export function ControlModelFromJSON(json: any): ControlModel {
  return ControlModelFromJSONTyped(json, false);
}

export function ControlModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    identifier: !exists(json, 'identifier') ? undefined : json['identifier'],
    description: json['description'],
    descriptionAsHtml: json['description_as_html'],
    treatmentPlan: !exists(json, 'treatment_plan')
      ? undefined
      : json['treatment_plan'],
    treatmentPlanAsHtml: !exists(json, 'treatment_plan_as_html')
      ? undefined
      : json['treatment_plan_as_html'],
    reasonForExclusion: !exists(json, 'reason_for_exclusion')
      ? undefined
      : json['reason_for_exclusion'],
    applicable: json['applicable'],
    custom: !exists(json, 'custom') ? undefined : json['custom'],
    status: !exists(json, 'status') ? undefined : json['status'],
    assignee: !exists(json, 'assignee')
      ? undefined
      : VendorUserFromJSON(json['assignee']),
    frameworksRelations: !exists(json, 'frameworks_relations')
      ? undefined
      : (json['frameworks_relations'] as Array<any>).map(
          FrameworkRelationFromJSON
        ),
    reviewStatus: !exists(json, 'review_status')
      ? undefined
      : ReviewStatusEnumFromJSON(json['review_status']),
    reviewOverdue: !exists(json, 'review_overdue')
      ? undefined
      : json['review_overdue'],
    nextReviewDate: !exists(json, 'next_review_date')
      ? undefined
      : json['next_review_date'],
    helpArticleUrl: !exists(json, 'help_article_url')
      ? undefined
      : json['help_article_url'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function ControlModelToJSON(value?: ControlModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    identifier: value.identifier,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
    treatment_plan: value.treatmentPlan,
    treatment_plan_as_html: value.treatmentPlanAsHtml,
    reason_for_exclusion: value.reasonForExclusion,
    applicable: value.applicable,
    custom: value.custom,
    status: value.status,
    assignee: VendorUserToJSON(value.assignee),
    frameworks_relations:
      value.frameworksRelations === undefined
        ? undefined
        : (value.frameworksRelations as Array<any>).map(
            FrameworkRelationToJSON
          ),
    review_status: ReviewStatusEnumToJSON(value.reviewStatus),
    review_overdue: value.reviewOverdue,
    next_review_date: value.nextReviewDate,
    help_article_url: value.helpArticleUrl,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ControlModelStatusEnum {
  Pending = 'pending',
  Failed = 'failed',
  Compliant = 'compliant',
}
