/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  NotificationModel,
  NotificationModelFromJSON,
  NotificationModelFromJSONTyped,
  NotificationModelToJSON,
  SharedPaginationMeta,
  SharedPaginationMetaFromJSON,
  SharedPaginationMetaFromJSONTyped,
  SharedPaginationMetaToJSON,
} from './';

/**
 *
 * @export
 * @interface NotificationList
 */
export interface NotificationList {
  /**
   *
   * @type {Array<NotificationModel>}
   * @memberof NotificationList
   */
  data: Array<NotificationModel>;
  /**
   *
   * @type {SharedPaginationMeta}
   * @memberof NotificationList
   */
  meta: SharedPaginationMeta;
}

export function NotificationListFromJSON(json: any): NotificationList {
  return NotificationListFromJSONTyped(json, false);
}

export function NotificationListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NotificationList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(NotificationModelFromJSON),
    meta: SharedPaginationMetaFromJSON(json['meta']),
  };
}

export function NotificationListToJSON(value?: NotificationList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(NotificationModelToJSON),
    meta: SharedPaginationMetaToJSON(value.meta),
  };
}
