import React from 'react';
import { ReportForm, ReportSectionProps } from './ReportsManageDrawer';
import { AdoptechReactSelect2 } from '../../AdoptechReacSelect2/AdoptechReactSelect2';
import { useOwnerOptions } from '../../../hooks/useOwnerOptions';

export const ReportOwnerField: React.FC<ReportSectionProps> = ({
  formData,
  updateField,
  updateForm,
}) => {
  const { ownerOptions } = useOwnerOptions({
    onlyAdminable: true,
  });
  const hasError = (field: keyof ReportForm) => !formData[field];
  const baseCss = 'reportsManageDrawer';
  return (
    <div className={baseCss + '--fieldRow'}>
      <div className={baseCss + '--label'}>
        Who is the owner of this report document?
      </div>
      <div className={baseCss + '--field'}>
        <AdoptechReactSelect2
          label="Owner"
          id={`${baseCss}-Owner`}
          options={ownerOptions}
          hasError={hasError('ownerId')}
          onChange={e => updateField('ownerId', e.value)}
          value={ownerOptions.find(option => option.value == formData.ownerId)}
          placeholder="Select owner"
          showUserAvatar
          showUserEmail={false}
        />
      </div>
    </div>
  );
};
