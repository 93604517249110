import React, { useEffect, useState } from 'react';
import { AdoptechPanel } from '../../AdoptechPanel/AdoptechPanel';
import { Lozenge } from '../../Lozenge/Lozenge';
import { ReportModel, ReportModelTypeEnum } from '../../../swagger';
import { AdoptechReactSelect } from '../../AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechTextInput } from '../../AdoptechTextInput/AdoptechTextInput';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setReportsSearch } from '../../../store/reports/reportsSlice';
import { ApplicationState } from '../../../types/applicationState';
import { Dropdown } from 'react-bootstrap';
import { AdoptechDropdownToggle } from '../../AdoptechDropdownToggle/AdoptechDropdownToggle';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import './ReportsHeader.scss';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useHistory } from 'react-router-dom';
import { ReportsManageDrawer } from '../ReportsManageDrawer/ReportsManageDrawer';
import { useCanFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { reactSelectLightStyle } from '../../../functions/reactSelectCustomTheme';

interface ReportsHeaderProps {
  reports: ReportModel[];
}
export type reportTypeValues =
  | 'all'
  | 'legal'
  | 'pestel'
  | 'risk'
  | 'soa'
  | 'soa_summary'
  | 'vendor';

interface TypeOption {
  label: string;
  value: reportTypeValues;
  enum: ReportModelTypeEnum;
}

export const typeOptions: TypeOption[] = [
  {
    label: 'All Reports',
    value: 'all',
    enum: null,
  },
  {
    label: 'Legal Register reports',
    value: 'legal',
    enum: ReportModelTypeEnum.LegalRegister,
  },
  {
    label: 'PESTEL Reports',
    value: 'pestel',
    enum: ReportModelTypeEnum.PestelRegister,
  },
  {
    label: 'Risk Register Reports',
    value: 'risk',
    enum: ReportModelTypeEnum.RiskRegister,
  },
  {
    label: 'SoA Reports',
    value: 'soa',
    enum: ReportModelTypeEnum.StatementOfApplicability,
  },
  {
    label: 'SoA Summary Reports',
    value: 'soa_summary',
    enum: ReportModelTypeEnum.StatementOfApplicabilitySummary,
  },
  {
    label: 'Vendor Register Reports',
    value: 'vendor',
    enum: ReportModelTypeEnum.VendorRegister,
  },
];

export const ReportsHeader: React.FC<ReportsHeaderProps> = props => {
  const baseCss = 'reportsHeader';
  const search = useSelector(
    (state: ApplicationState) => state.reports.reportsSearch
  );
  const onTypeChange = (param: reportTypeValues) => {
    history.push({
      pathname: window.location.pathname,
      search: `?type=${param}`,
    });
  };
  const canManageReports = useCanFeature(AccessObject.reports_manage);

  const dispatch = useDispatch();
  const [isShowingAddDrawer, setIsShowingAddDrawer] = useState<
    false | ReportModelTypeEnum
  >(false);

  const reportTypeParam = useQueryParams().get('type') as reportTypeValues;
  const addReportTypeParam = useQueryParams().get(
    'add-type'
  ) as reportTypeValues;

  useEffect(() => {
    const addDrawerType = () => {
      if (addReportTypeParam === 'legal')
        return ReportModelTypeEnum.LegalRegister;
      if (addReportTypeParam === 'pestel')
        return ReportModelTypeEnum.PestelRegister;
      if (addReportTypeParam === 'risk')
        return ReportModelTypeEnum.RiskRegister;
      if (addReportTypeParam === 'soa')
        return ReportModelTypeEnum.StatementOfApplicability;
      if (addReportTypeParam === 'soa_summary')
        return ReportModelTypeEnum.StatementOfApplicabilitySummary;
      if (addReportTypeParam === 'vendor')
        return ReportModelTypeEnum.VendorRegister;

      alert(`type param ${addReportTypeParam} is unsupported`);
    };
    addReportTypeParam && setIsShowingAddDrawer(addDrawerType());
  }, [addReportTypeParam]);

  const selectedType = reportTypeParam || 'all';

  const history = useHistory();
  return (
    <div className={baseCss}>
      <AdoptechPanel className={baseCss + '--header'}>
        <div className={baseCss + '--titleColumn'}>
          Reports
          <Lozenge value={props.reports.length} />
        </div>
        <div className={baseCss + '--divider'} />
        <div className={baseCss + '--filterByTypeColumn'}>
          <div>Filter</div>
          <AdoptechReactSelect
            label={null}
            id="type-filter"
            options={typeOptions}
            onChange={selectOption => {
              onTypeChange(selectOption.value as reportTypeValues);
            }}
            value={typeOptions.find(option => option.value === selectedType)}
            placeholder="All Reports"
            additionalStyling={reactSelectLightStyle}
          />
        </div>
        <div className={baseCss + '--divider'} />
        <div className={baseCss + '--search'}>
          <AdoptechTextInput
            id="search"
            value={search}
            onChange={e => dispatch(setReportsSearch(e.currentTarget.value))}
            type="text"
            placeholder="Search for reports"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>
        <div className={baseCss + '--divider'} />
        {canManageReports && (
          <Dropdown className={baseCss + '--addButton'}>
            <Dropdown.Toggle as={AdoptechDropdownToggle}>
              <AdoptechButton
                variant={AdoptechButtonVariant.PrimaryOrange}
                icon={faPlus}
                iconSize="large"
                rounded
              >
                Add new
              </AdoptechButton>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  setIsShowingAddDrawer(ReportModelTypeEnum.LegalRegister)
                }
              >
                Legal Register
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setIsShowingAddDrawer(ReportModelTypeEnum.PestelRegister)
                }
              >
                PESTEL Register
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setIsShowingAddDrawer(ReportModelTypeEnum.RiskRegister)
                }
              >
                Risk Register
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setIsShowingAddDrawer(
                    ReportModelTypeEnum.StatementOfApplicability
                  )
                }
              >
                SoA
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setIsShowingAddDrawer(
                    ReportModelTypeEnum.StatementOfApplicabilitySummary
                  )
                }
              >
                SoA Summary
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setIsShowingAddDrawer(ReportModelTypeEnum.VendorRegister)
                }
              >
                Vendor Register
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setIsShowingAddDrawer(ReportModelTypeEnum.Uploaded)
                }
              >
                Upload Report
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {isShowingAddDrawer && (
          <ReportsManageDrawer
            type={isShowingAddDrawer}
            onClose={() => setIsShowingAddDrawer(false)}
          />
        )}
      </AdoptechPanel>
    </div>
  );
};
