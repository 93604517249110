/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CompanyIdentifierType
 */
export interface CompanyIdentifierType {
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierType
   */
  type: CompanyIdentifierTypeTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierType
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof CompanyIdentifierType
   */
  country: string | null;
}

export function CompanyIdentifierTypeFromJSON(
  json: any
): CompanyIdentifierType {
  return CompanyIdentifierTypeFromJSONTyped(json, false);
}

export function CompanyIdentifierTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyIdentifierType {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    displayName: json['display_name'],
    country: json['country'],
  };
}

export function CompanyIdentifierTypeToJSON(
  value?: CompanyIdentifierType | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    display_name: value.displayName,
    country: value.country,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CompanyIdentifierTypeTypeEnum {
  Ukcrn = 'CompanyIdentifier::Ukcrn',
  Ein = 'CompanyIdentifier::Ein',
  Srn = 'CompanyIdentifier::Srn',
  Siren = 'CompanyIdentifier::Siren',
  Siret = 'CompanyIdentifier::Siret',
  Other = 'CompanyIdentifier::Other',
}
