/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChecklistTemplateItem
 */
export interface ChecklistTemplateItem {
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateItem
   */
  questionId: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateItem
   */
  questionText: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateItem
   */
  questionTextAsHtml?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateItem
   */
  explanatoryText: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateItem
   */
  explanatoryTextAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateItem
   */
  datatype: string;
  /**
   *
   * @type {number}
   * @memberof ChecklistTemplateItem
   */
  rowOrder?: number | null;
}

export function ChecklistTemplateItemFromJSON(
  json: any
): ChecklistTemplateItem {
  return ChecklistTemplateItemFromJSONTyped(json, false);
}

export function ChecklistTemplateItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistTemplateItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    questionId: json['question_id'],
    questionText: json['question_text'],
    questionTextAsHtml: !exists(json, 'question_text_as_html')
      ? undefined
      : json['question_text_as_html'],
    explanatoryText: json['explanatory_text'],
    explanatoryTextAsHtml: !exists(json, 'explanatory_text_as_html')
      ? undefined
      : json['explanatory_text_as_html'],
    datatype: json['datatype'],
    rowOrder: !exists(json, 'row_order') ? undefined : json['row_order'],
  };
}

export function ChecklistTemplateItemToJSON(
  value?: ChecklistTemplateItem | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question_id: value.questionId,
    question_text: value.questionText,
    question_text_as_html: value.questionTextAsHtml,
    explanatory_text: value.explanatoryText,
    explanatory_text_as_html: value.explanatoryTextAsHtml,
    datatype: value.datatype,
    row_order: value.rowOrder,
  };
}
