import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityDetails } from '../../components/dataRoom/DataRoomEntityMeatballMenu/DataRoomEntityMeatballMenu';
import {
  FolderModel,
  getFlatFoldersList,
} from '../../functions/getFlatFoldersList';
import { DataRoomEntityModel } from '../../swagger';
import { DataRoomState } from './dataRoomState';
import { DataRoomMoveEntitiesTabsInfo } from '../../components/dataRoom/DataRoomMoveEntitiesModal/DataRoomMoveEntitiesModalTabsInfo';

const dataRoomSlice = createSlice({
  initialState: {
    isFetchingFiles: false,
    isFetchingAllFiles: false,
    isUploadingFile: false,
    allFiles: undefined,
    files: [],
    folders: [],
    cursor: [],
    currentEntityIdProcessing: undefined,
    showDeleteModal: false,
    entityToEdit: [],
    entitiesToMove: [],
    entitiesToDelete: [],
    showMoveEntitiesModal: false,
    showEditEntityModal: false,
    selectedModalTabId: undefined,
  } as unknown as DataRoomState,
  name: 'dataRoomSlice',
  reducers: {
    createFolderRequest: state => {
      state.isCreatingFolder = true;
    },
    createFolderSuccess: state => {
      state.isCreatingFolder = false;
      state.showEditEntityModal = false;
    },
    createFolderError: state => {
      state.isCreatingFolder = false;
    },
    dataRoomShowDeleteModal: state => {
      state.showDeleteModal = true;
    },
    dataRoomShowMoveModal: state => {
      state.showMoveEntitiesModal = true;
      state.selectedModalTabId = DataRoomMoveEntitiesTabsInfo.selectFolder.id;
    },
    dataRoomShowEditEntityModal: state => {
      state.showEditEntityModal = true;
    },
    deleteEntitiesRequest: (state, action: PayloadAction<string[]>) => {
      state.currentEntityIdProcessing = action.payload[0]; // We only support single action, bulk actions to be added
    },
    deleteEntitiesSuccess: (state, action: PayloadAction<string[]>) => {
      state.currentEntityIdProcessing = undefined;
      state.files = state.files.filter(f => !action.payload.includes(f.id));
    },
    updateEntityRequest: (state, action: PayloadAction<string[]>) => {
      state.currentEntityIdProcessing = action.payload[0]; // We only support single action, bulk actions to be added
    },
    updateEntitySuccess: (state, action: PayloadAction<string[]>) => {
      state.currentEntityIdProcessing = undefined;
      state.showEditEntityModal = false;
    },
    updateEntityError: state => {
      state.currentEntityIdProcessing = undefined;
    },
    moveEntitiesRequest: (state, action: PayloadAction<string[]>) => {
      state.currentEntityIdProcessing = action.payload[0]; // We only support single action, bulk actions to be added
    },
    uploadToFolderRequest: (state, action: PayloadAction<string[]>) => {
      state.currentEntityIdProcessing = action.payload[0]; // We only support single action, bulk actions to be added
    },
    moveEntitiesSuccess: (state, action: PayloadAction<string[]>) => {
      state.currentEntityIdProcessing = undefined;
      state.showMoveEntitiesModal = false;
    },
    moveEntitiesError: state => {
      // contains Id of entity moving failed to
      state.currentEntityIdProcessing = undefined;
    },
    deleteEntitiesError: state => {
      state.currentEntityIdProcessing = undefined;
    },
    fetchFilesRequest: state => {
      state.isFetchingFiles = true;
    },
    fetchFilesSuccess: (
      state,
      action: PayloadAction<DataRoomEntityModel[]>
    ) => {
      state.isFetchingFiles = false;
      state.files = action.payload;
    },
    fetchFilesError: state => {
      state.isFetchingFiles = false;
    },
    fetchEntitiesTreeRequest: state => {
      state.isFetchingEntitiesTree = true;
    },
    fetchEntitiesTreeSuccess: (
      state,
      action: PayloadAction<DataRoomEntityModel>
    ) => {
      state.isFetchingEntitiesTree = false;
      state.entitiesTree = action.payload;
      const list: FolderModel[] = [];
      getFlatFoldersList(action.payload, list);
      state.folders = list;
    },
    fetchEntitiesTreeError: state => {
      state.isFetchingEntitiesTree = false;
    },
    getFileDetailsRequest: (state, action: PayloadAction<string>) => {
      state.currentEntityIdProcessing = action.payload;
    },
    getFileDetailsSuccess: state => {
      state.currentEntityIdProcessing = undefined;
    },
    getFileDetailsError: state => {
      state.currentEntityIdProcessing = undefined;
    },
    setCursor: (state: DataRoomState, action: PayloadAction<string[]>) => {
      state.cursor = action.payload;
    },
    setEntityToEdit: (
      state: DataRoomState,
      action: PayloadAction<EntityDetails>
    ) => {
      state.entityToEdit = action.payload;
    },
    setEntitiesToMove: (
      state: DataRoomState,
      action: PayloadAction<EntityDetails[]>
    ) => {
      state.entitiesToMove = action.payload;
    },
    setEntitiesToDelete: (state, action: PayloadAction<EntityDetails[]>) => {
      state.entitiesToDelete = action.payload;
    },
    setShowEditEntityModal: (state, action: PayloadAction<boolean>) => {
      state.showEditEntityModal = action.payload;
    },
    hideModals: state => {
      state.showMoveEntitiesModal = false;
      state.showEditEntityModal = false;
    },
    uploadFileRequest: (state, action: PayloadAction<string>) => {
      state.isUploadingFile = true;
      state.currentEntityIdProcessing = action.payload;
    },
    uploadFileSuccess: (
      state,
      action: PayloadAction<DataRoomEntityModel[]>
    ) => {
      state.isUploadingFile = false;
      state.currentEntityIdProcessing = undefined;
    },
    uploadFileError: state => {
      state.isUploadingFile = false;
      state.currentEntityIdProcessing = undefined;
    },
    selectModalTab: (state, action: PayloadAction<string>) => {
      state.selectedModalTabId = action.payload;
    },
  },
});

export const {
  createFolderRequest,
  createFolderSuccess,
  createFolderError,
  dataRoomShowDeleteModal,
  dataRoomShowMoveModal,
  dataRoomShowEditEntityModal,
  deleteEntitiesRequest,
  deleteEntitiesSuccess,
  deleteEntitiesError,
  moveEntitiesRequest,
  moveEntitiesSuccess,
  moveEntitiesError,
  updateEntityRequest,
  updateEntitySuccess,
  updateEntityError,
  fetchFilesSuccess,
  fetchFilesRequest,
  fetchFilesError,
  fetchEntitiesTreeSuccess,
  fetchEntitiesTreeRequest,
  fetchEntitiesTreeError,
  getFileDetailsRequest,
  getFileDetailsSuccess,
  getFileDetailsError,
  setCursor,
  setEntityToEdit,
  setEntitiesToMove,
  setEntitiesToDelete,
  setShowEditEntityModal,
  hideModals,
  uploadFileRequest,
  uploadFileSuccess,
  uploadFileError,
  selectModalTab,
  uploadToFolderRequest,
} = dataRoomSlice.actions;

export default dataRoomSlice.reducer;
