import React from 'react';
import { ReportForm, ReportSectionProps } from '../ReportsManageDrawer';
import { ReportVersionField } from '../ReportVersionField';
import { ReportOwnerField } from '../ReportOwnerField';
import { ReportSummaryField } from '../ReportSummaryField';

export const vendorSectionValid = (formData: ReportForm): boolean => {
  const versionSelected =
    formData.hasVersion === false || formData.hasVersion === true;
  return !!(versionSelected && formData.ownerId && formData.summary);
};

export const VendorRegisterSection: React.FC<ReportSectionProps> = ({
  formData,
  updateField,
  updateForm,
}) => {
  const sectionProps = { formData, updateField, updateForm };
  return (
    <div>
      <ReportOwnerField {...sectionProps} />
      <ReportVersionField {...sectionProps} />
      <ReportSummaryField {...sectionProps} />
    </div>
  );
};
