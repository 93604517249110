import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';

import React, { useState } from 'react';
import './ComplianceControlPoliciesSimpleList.scss';
import { Policy, VendorPolicy } from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { useSelector } from 'react-redux';
import { selectIsViewModeControlDrawer } from '../../../selectors/compliance/selectIsViewModeEditControlDrawer';
import AdoptechIcon from '../../../features/compliance/controls/AdoptechIcon/AdoptechIcon';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { setControlFormModel } from '../../../store/compliance/complianceSlice';
import { ComplianceEditControlFormModel } from '../Types/complianceTypes';
import {
  ControlEditPolicyModal,
  isVendorPolicy,
} from './ControlEditPolicyModal/ControlEditPolicyModal';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';

export const ComplianceControlPoliciesSimpleList: React.FC = () => {
  const {
    editControlFormModel: { policies, vendorPolicies },
    editControlFormModel,
  } = useSelector((state: ApplicationState) => state.compliance);
  const formPolicies = [...(policies || []), ...(vendorPolicies || [])].sort(
    (a, b) => (a.name > b.name ? 1 : -1)
  );
  const dispatch = useAppDispatch();

  const baseCss = 'complianceControlPoliciesSimpleList';

  const handleRemove = (policy: (typeof formPolicies)[0]) => {
    if (isVendorPolicy(policy)) {
      const vendorPoliciesExceptCurrent = (vendorPolicies || []).filter(
        formPolicy => formPolicy.id !== policy.id
      );
      dispatch(
        setControlFormModel({
          ...editControlFormModel,
          ...{ vendorPolicies: vendorPoliciesExceptCurrent, formTouched: true },
        })
      );
      return;
    }
    const policiesExceptCurrent = (policies || []).filter(
      formPolicy => (formPolicy as Policy).code !== (policy as Policy).code
    );

    dispatch(
      setControlFormModel({
        ...editControlFormModel,
        ...{ policies: policiesExceptCurrent, formTouched: true },
      })
    );
  };

  const isViewMode = useSelector(selectIsViewModeControlDrawer);
  const [showPolicyEditModal, setShowPolicyEditModal] = useState(false);

  const [policyToRemove, setPolicyToRemove] = useState<Policy | VendorPolicy>(
    undefined
  );

  return (
    <>
      <div className={baseCss + '--header'}>
        <div>Linked policies</div>
        <AdoptechIcon
          shape="square"
          onClick={() => setShowPolicyEditModal(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </AdoptechIcon>
      </div>

      {(formPolicies || []).length > 0 && (
        <div className={baseCss + '--policies'}>
          <div className={baseCss + '--tableHeaders'}>
            <div className={baseCss + '--label'}>Name</div>
            <div className={baseCss + '--label'}>Action</div>
          </div>

          {formPolicies?.map(policy => (
            <div className={baseCss + '--tableRow'} key={policy.id}>
              <div className={baseCss + '--name'}>{policy.name}</div>
              <div className={baseCss + '--icon'}>
                {!isViewMode && (
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    onClick={e => {
                      e.stopPropagation();
                      setPolicyToRemove(policy);
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <ConfirmModal
        close={() => {
          setPolicyToRemove(undefined);
        }}
        headerText="Remove Control Policy"
        description="Are you sure you would like to remove policy:"
        show={Boolean(policyToRemove)}
        confirm={() => {
          handleRemove(policyToRemove);
          setPolicyToRemove(undefined);
        }}
      >
        <div className={baseCss + '--policyName'}> {policyToRemove?.name} </div>
      </ConfirmModal>

      {!isViewMode && showPolicyEditModal && (
        <ControlEditPolicyModal
          show
          onHide={() => setShowPolicyEditModal(false)}
          onChange={(controlForm: ComplianceEditControlFormModel) => {
            dispatch(
              setControlFormModel({
                ...controlForm,
              })
            );
          }}
        />
      )}
    </>
  );
};
