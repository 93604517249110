import { api } from '../../api/api';
import { canIndex } from '../../functions/access';
import { getApiErrorMessage } from '../../functions/getApiErrorMessage';
import { AccessObject } from '../../types/accessObject';
import { UNKNOWN_ERROR } from '../../types/constants';
import { VoidThunk } from '../../types/voidThunk';
import { getErrorMessage } from '../helpers/thunkHelpers';
import { fetchNotifications } from '../notifications/notificationsThunks';
import { fetchSubscriptions, fetchUserDetails } from '../user/userThunks';
import { setCurrentVendor } from '../vendors/vendorsSlice';
import {
  fetchCurrentCompany,
  fetchVendorDetails,
  fetchVendors,
  fetchVendorTeams,
  fetchVendorUsers,
  getVendorProducts,
} from '../vendors/vendorsThunks';
import {
  globalSearchError,
  globalSearchRequest,
  globalSearchSuccess,
  initialisationFailure,
  initialisationRequest,
  initialisationSuccess,
} from './globalSlice';

export const initialise = (): VoidThunk => async (dispatch, getState) => {
  try {
    dispatch(initialisationRequest());
    await Promise.all([dispatch(fetchUserDetails()), dispatch(fetchVendors())]);

    const vendors = getState().vendors.vendors;
    const currentUser = getState().user.userDetails;
    let currentVendor = getState().vendors.currentVendor;
    if (vendors.length > 0) {
      if (!currentVendor) {
        const savedVendor = vendors.find(
          vendor => vendor.id === localStorage.getItem('currentVendorId')
        );
        currentVendor = savedVendor || vendors[0];
        dispatch(setCurrentVendor(currentVendor));
      }
      const requests = [];

      requests.push(fetchSubscriptions());
      canIndex(AccessObject.vendor_details, getState()) &&
        requests.push(fetchVendorDetails(currentVendor.id));
      requests.push(fetchVendorUsers(currentVendor.id));
      requests.push(getVendorProducts(true));
      await Promise.all(requests.map(request => dispatch(request)));
    }
    dispatch(fetchCurrentCompany());
    const vendorUsers = getState().vendors.users;

    if (vendorUsers.length) {
      const currentVendorUser = vendorUsers.find(
        user => user.email === currentUser.email
      );

      dispatch(fetchNotifications(currentVendorUser.id, false));
    }

    dispatch(initialisationSuccess());
  } catch (err) {
    if (err instanceof Response) {
      console.log(
        'API error',
        `Status: ${err.status} Message: ${err.statusText}`
      );
      const apiErrorMessage = await getApiErrorMessage(err);
      if (apiErrorMessage) {
        dispatch(initialisationFailure(apiErrorMessage));
      } else {
        dispatch(
          initialisationFailure(
            'An error occurred fetching vendors. Please try again or contact support.'
          )
        );
      }
    } else {
      console.log(err);
      dispatch(initialisationFailure(UNKNOWN_ERROR));
    }
  }
};

export const getSearchResults =
  (vendorId: string, term: string): VoidThunk =>
  async dispatch => {
    try {
      if (!term) return;
      dispatch(globalSearchRequest());
      const response = await api().vendorsVendorIdSearchGet({
        vendorId,
        term,
      });
      dispatch(globalSearchSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while getting search results. Please try again or contact support.'
        );
        dispatch(globalSearchError(apiErrorMessage));
      }
    }
  };
