/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedTaskRecurring,
  SharedTaskRecurringFromJSON,
  SharedTaskRecurringFromJSONTyped,
  SharedTaskRecurringToJSON,
  VendorSupplierAccessControl,
  VendorSupplierAccessControlFromJSON,
  VendorSupplierAccessControlFromJSONTyped,
  VendorSupplierAccessControlToJSON,
  VendorSupplierStatus,
  VendorSupplierStatusFromJSON,
  VendorSupplierStatusFromJSONTyped,
  VendorSupplierStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorSupplierPayload
 */
export interface VendorSupplierPayload {
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  ownerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  reviewDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  vendorSupplierCategoryId: string;
  /**
   *
   * @type {VendorSupplierStatus}
   * @memberof VendorSupplierPayload
   */
  status?: VendorSupplierStatus;
  /**
   *
   * @type {SharedTaskRecurring}
   * @memberof VendorSupplierPayload
   */
  contractRecurring?: SharedTaskRecurring;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  contractRenewalDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  contractId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  contractDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  contactEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  contactName?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  contactPhone?: string | null;
  /**
   *
   * @type {number}
   * @memberof VendorSupplierPayload
   */
  riskProfile?: number;
  /**
   *
   * @type {VendorSupplierAccessControl}
   * @memberof VendorSupplierPayload
   */
  accessControl?: VendorSupplierAccessControl;
  /**
   *
   * @type {Array<string>}
   * @memberof VendorSupplierPayload
   */
  certifications?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  criticalDependencies?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierPayload
   */
  continuityPlan?: string | null;
}

export function VendorSupplierPayloadFromJSON(
  json: any
): VendorSupplierPayload {
  return VendorSupplierPayloadFromJSONTyped(json, false);
}

export function VendorSupplierPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorSupplierPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    reviewDate: !exists(json, 'review_date') ? undefined : json['review_date'],
    vendorSupplierCategoryId: json['vendor_supplier_category_id'],
    status: !exists(json, 'status')
      ? undefined
      : VendorSupplierStatusFromJSON(json['status']),
    contractRecurring: !exists(json, 'contract_recurring')
      ? undefined
      : SharedTaskRecurringFromJSON(json['contract_recurring']),
    contractRenewalDate: !exists(json, 'contract_renewal_date')
      ? undefined
      : json['contract_renewal_date'],
    contractId: !exists(json, 'contract_id') ? undefined : json['contract_id'],
    contractDescription: !exists(json, 'contract_description')
      ? undefined
      : json['contract_description'],
    contactEmail: !exists(json, 'contact_email')
      ? undefined
      : json['contact_email'],
    contactName: !exists(json, 'contact_name')
      ? undefined
      : json['contact_name'],
    contactPhone: !exists(json, 'contact_phone')
      ? undefined
      : json['contact_phone'],
    riskProfile: !exists(json, 'risk_profile')
      ? undefined
      : json['risk_profile'],
    accessControl: !exists(json, 'access_control')
      ? undefined
      : VendorSupplierAccessControlFromJSON(json['access_control']),
    certifications: !exists(json, 'certifications')
      ? undefined
      : json['certifications'],
    criticalDependencies: !exists(json, 'critical_dependencies')
      ? undefined
      : json['critical_dependencies'],
    continuityPlan: !exists(json, 'continuity_plan')
      ? undefined
      : json['continuity_plan'],
  };
}

export function VendorSupplierPayloadToJSON(
  value?: VendorSupplierPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    owner_id: value.ownerId,
    review_date: value.reviewDate,
    vendor_supplier_category_id: value.vendorSupplierCategoryId,
    status: VendorSupplierStatusToJSON(value.status),
    contract_recurring: SharedTaskRecurringToJSON(value.contractRecurring),
    contract_renewal_date: value.contractRenewalDate,
    contract_id: value.contractId,
    contract_description: value.contractDescription,
    contact_email: value.contactEmail,
    contact_name: value.contactName,
    contact_phone: value.contactPhone,
    risk_profile: value.riskProfile,
    access_control: VendorSupplierAccessControlToJSON(value.accessControl),
    certifications: value.certifications,
    critical_dependencies: value.criticalDependencies,
    continuity_plan: value.continuityPlan,
  };
}
