import React from 'react';
import { useDispatch } from 'react-redux';
import {
  addFileAnswer,
  addUrlAnswer,
} from '../../store/assessments/assessmentsSlice';
import { AssessmentResponseAnswer, Question } from '../../swagger';
import { AdoptechButton } from '../AdoptechButton/AdoptechButton';
import { FileSelectAnswerGrid } from '../FileSelectAnswerGrid/FileSelectAnswerGrid';
import './FileSelectAnswer.scss';

interface FileSelectAnswerProps {
  answers: AssessmentResponseAnswer[];
  disabled?: boolean;
  readonly?: boolean;
  onChange: (value: AssessmentResponseAnswer[]) => void;
  question: Question;
  indexProperty: 'name' | 'propertyName';
}

/***
 * TODO: This Component only support assessments for now, rewrite to be common for all entities
 */
export const FileSelectAnswer: React.FC<FileSelectAnswerProps> = props => {
  const dispatch = useDispatch();

  return (
    <div className="fileSelectAnswer">
      {!props.readonly && (
        <div className="fileSelectAnswer--buttons">
          <AdoptechButton
            onClick={() => dispatch(addUrlAnswer(props.question.name))}
          >
            Add Link
          </AdoptechButton>
          <AdoptechButton
            onClick={() => dispatch(addFileAnswer(props.question.name))}
          >
            Upload File
          </AdoptechButton>
        </div>
      )}
      <FileSelectAnswerGrid
        question={props.question}
        answers={props.answers}
        readonly={props.readonly}
      />
    </div>
  );
};
