import React, { useEffect } from 'react';
import './ControlsSection.scss';
import { TrustHubSectionProps } from '../CompanyDetailsSection/CompanyDetailsSection';
import { useTrustHubSection } from '../useTrustHubSection';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { ControlsEditSection } from '../admin/ControlsEditSection/ControlsEditSection';
import { currentTrustHubDomain } from '../user/TrustHubUserPage/TrustHubUserPage';
import { selectCurrentVendor } from '../../../selectors/selectCurrentVendor';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ControlCategoryRow } from './ControlCategoryRow';

export const ControlsSection: React.FC<TrustHubSectionProps> = ({
  isEditable,
  sections,
  identifier,
  onDocumentClick,
  showDocumentDownloadIcon,
}) => {
  const {
    isEditMode,
    setIsEditMode,
    section,
    publicDocs,
    baseCss,
    editSectionHandler,
    bulkActionsRow,
    titleBar,
  } = useTrustHubSection({
    identifier,
    sections,
    isEditable,
    confirmOnDelete: true,
  });
  const dispatch = useAppDispatch();
  const { fetchVendorDocumentFileStatus, fetchingVendorDocumentIdFile } =
    useSelector((state: ApplicationState) => state.trustHub);

  const { formCategories, fetchCategories } = editSectionHandler;
  const domain = currentTrustHubDomain();
  const vendor = useSelector(selectCurrentVendor);
  useEffect(() => {
    fetchCategories(isEditable ? vendor.id : domain);
  }, []);

  if (!section) return null;

  if (isEditMode) {
    return (
      <ControlsEditSection
        section={section}
        onClose={() => setIsEditMode(false)}
        editSectionHandler={editSectionHandler}
        identifier={identifier}
        baseCss={baseCss}
        titleBar={titleBar}
      />
    );
  }

  return (
    // dnd provider not used, needed to fix https://github.com/react-dnd/react-dnd/issues/3149
    <DndProvider backend={HTML5Backend}>
      <div className={baseCss}>
        <div className={baseCss + '--panel'}>
          {titleBar}
          <div className={baseCss + '--categories'}>
            {formCategories
              .sort((a, b) => a.rowOrder - b.rowOrder)
              .map(category => {
                return (
                  <ControlCategoryRow
                    key={category.id}
                    color="white"
                    title={category.name}
                    controls={publicDocs.filter(
                      publicDoc => publicDoc.trusthubCategoryId === category.id
                    )}
                    categoryId={category.id.toString()}
                    baseCss={baseCss}
                    dragAllowed={false}
                    dropAllowed={false}
                    editSectionHandler={editSectionHandler}
                    showTooltip
                  />
                );
              })}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};
