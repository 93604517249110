import React, { CSSProperties } from 'react';
import './ControlsEditSection.scss';
import { useDragLayer, XYCoord } from 'react-dnd';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  opacity: 0.5,
};

const getItemStyles = (
  initialCursorOffset: XYCoord,
  initialOffset: XYCoord,
  currentOffset: XYCoord
) => {
  if (!initialOffset || !currentOffset || !initialCursorOffset) {
    return {
      display: 'none',
    };
  }

  const x = initialCursorOffset.x + (currentOffset.x - initialOffset.x);
  const y = initialCursorOffset.y + (currentOffset.y - initialOffset.y);
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};
interface ControlDragPreviewProps {
  baseCss: string;
  list: React.ReactNode[];
}

// Custom drag preview to display only selected  controls
export const ControlDragPreview: React.FC<ControlDragPreviewProps> = ({
  baseCss,
  list,
}) => {
  const {
    isDragging,
    initialCursorOffset,
    initialFileOffset,
    currentFileOffset,
  } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialCursorOffset: monitor.getInitialClientOffset(),
    initialFileOffset: monitor.getInitialSourceClientOffset(),
    currentFileOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(
          initialCursorOffset,
          initialFileOffset,
          currentFileOffset
        )}
      >
        <div className={baseCss + '--controlsTemplates'}>{list}</div>
      </div>
    </div>
  );
};
