/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  ControlExtendedAllOf,
  ControlExtendedAllOfFromJSON,
  ControlExtendedAllOfFromJSONTyped,
  ControlExtendedAllOfToJSON,
  ControlModel,
  ControlModelFromJSON,
  ControlModelFromJSONTyped,
  ControlModelToJSON,
  ControlRiskModel,
  ControlRiskModelFromJSON,
  ControlRiskModelFromJSONTyped,
  ControlRiskModelToJSON,
  FrameworkRelation,
  FrameworkRelationFromJSON,
  FrameworkRelationFromJSONTyped,
  FrameworkRelationToJSON,
  Policy,
  PolicyFromJSON,
  PolicyFromJSONTyped,
  PolicyToJSON,
  ReviewHistoryItemModel,
  ReviewHistoryItemModelFromJSON,
  ReviewHistoryItemModelFromJSONTyped,
  ReviewHistoryItemModelToJSON,
  ReviewStatusEnum,
  ReviewStatusEnumFromJSON,
  ReviewStatusEnumFromJSONTyped,
  ReviewStatusEnumToJSON,
  TaskModel,
  TaskModelFromJSON,
  TaskModelFromJSONTyped,
  TaskModelToJSON,
  VendorPolicy,
  VendorPolicyFromJSON,
  VendorPolicyFromJSONTyped,
  VendorPolicyToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface ControlExtended
 */
export interface ControlExtended extends ControlModel {
  /**
   *
   * @type {Access}
   * @memberof ControlExtended
   */
  access?: Access;
  /**
   *
   * @type {ReviewHistoryItemModel}
   * @memberof ControlExtended
   */
  lastReview?: ReviewHistoryItemModel;
  /**
   *
   * @type {Array<Policy>}
   * @memberof ControlExtended
   */
  policies?: Array<Policy>;
  /**
   *
   * @type {Array<VendorPolicy>}
   * @memberof ControlExtended
   */
  vendorPolicies?: Array<VendorPolicy>;
  /**
   *
   * @type {Array<ControlRiskModel>}
   * @memberof ControlExtended
   */
  risks?: Array<ControlRiskModel>;
  /**
   *
   * @type {Array<TaskModel>}
   * @memberof ControlExtended
   */
  tasks?: Array<TaskModel>;
}

export function ControlExtendedFromJSON(json: any): ControlExtended {
  return ControlExtendedFromJSONTyped(json, false);
}

export function ControlExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...ControlModelFromJSONTyped(json, ignoreDiscriminator),
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    lastReview: !exists(json, 'last_review')
      ? undefined
      : ReviewHistoryItemModelFromJSON(json['last_review']),
    policies: !exists(json, 'policies')
      ? undefined
      : (json['policies'] as Array<any>).map(PolicyFromJSON),
    vendorPolicies: !exists(json, 'vendor_policies')
      ? undefined
      : (json['vendor_policies'] as Array<any>).map(VendorPolicyFromJSON),
    risks: !exists(json, 'risks')
      ? undefined
      : (json['risks'] as Array<any>).map(ControlRiskModelFromJSON),
    tasks: !exists(json, 'tasks')
      ? undefined
      : (json['tasks'] as Array<any>).map(TaskModelFromJSON),
  };
}

export function ControlExtendedToJSON(value?: ControlExtended | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...ControlModelToJSON(value),
    access: AccessToJSON(value.access),
    last_review: ReviewHistoryItemModelToJSON(value.lastReview),
    policies:
      value.policies === undefined
        ? undefined
        : (value.policies as Array<any>).map(PolicyToJSON),
    vendor_policies:
      value.vendorPolicies === undefined
        ? undefined
        : (value.vendorPolicies as Array<any>).map(VendorPolicyToJSON),
    risks:
      value.risks === undefined
        ? undefined
        : (value.risks as Array<any>).map(ControlRiskModelToJSON),
    tasks:
      value.tasks === undefined
        ? undefined
        : (value.tasks as Array<any>).map(TaskModelToJSON),
  };
}
