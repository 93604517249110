/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum AssertionFrequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  BiAnnually = 'bi_annually',
  Annually = 'annually',
}

export function AssertionFrequencyFromJSON(json: any): AssertionFrequency {
  return AssertionFrequencyFromJSONTyped(json, false);
}

export function AssertionFrequencyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionFrequency {
  return json as AssertionFrequency;
}

export function AssertionFrequencyToJSON(
  value?: AssertionFrequency | null
): any {
  return value as any;
}
