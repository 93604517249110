/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ChecklistTemplateCreatePayloadChecklistTemplate,
  ChecklistTemplateCreatePayloadChecklistTemplateFromJSON,
  ChecklistTemplateCreatePayloadChecklistTemplateFromJSONTyped,
  ChecklistTemplateCreatePayloadChecklistTemplateToJSON,
  ChecklistTemplateCreatePayloadQuestions,
  ChecklistTemplateCreatePayloadQuestionsFromJSON,
  ChecklistTemplateCreatePayloadQuestionsFromJSONTyped,
  ChecklistTemplateCreatePayloadQuestionsToJSON,
} from './';

/**
 *
 * @export
 * @interface ChecklistTemplateCreatePayload
 */
export interface ChecklistTemplateCreatePayload {
  /**
   *
   * @type {ChecklistTemplateCreatePayloadChecklistTemplate}
   * @memberof ChecklistTemplateCreatePayload
   */
  checklistTemplate?: ChecklistTemplateCreatePayloadChecklistTemplate;
  /**
   *
   * @type {Array<ChecklistTemplateCreatePayloadQuestions>}
   * @memberof ChecklistTemplateCreatePayload
   */
  questions?: Array<ChecklistTemplateCreatePayloadQuestions>;
}

export function ChecklistTemplateCreatePayloadFromJSON(
  json: any
): ChecklistTemplateCreatePayload {
  return ChecklistTemplateCreatePayloadFromJSONTyped(json, false);
}

export function ChecklistTemplateCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistTemplateCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    checklistTemplate: !exists(json, 'checklist_template')
      ? undefined
      : ChecklistTemplateCreatePayloadChecklistTemplateFromJSON(
          json['checklist_template']
        ),
    questions: !exists(json, 'questions')
      ? undefined
      : (json['questions'] as Array<any>).map(
          ChecklistTemplateCreatePayloadQuestionsFromJSON
        ),
  };
}

export function ChecklistTemplateCreatePayloadToJSON(
  value?: ChecklistTemplateCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    checklist_template: ChecklistTemplateCreatePayloadChecklistTemplateToJSON(
      value.checklistTemplate
    ),
    questions:
      value.questions === undefined
        ? undefined
        : (value.questions as Array<any>).map(
            ChecklistTemplateCreatePayloadQuestionsToJSON
          ),
  };
}
