/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubVendorUserStatus,
  TrusthubVendorUserStatusFromJSON,
  TrusthubVendorUserStatusFromJSONTyped,
  TrusthubVendorUserStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubVendorUserUpdatePayloadTrusthubVendorUser
 */
export interface TrusthubVendorUserUpdatePayloadTrusthubVendorUser {
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserUpdatePayloadTrusthubVendorUser
   */
  trusthubCompanyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserUpdatePayloadTrusthubVendorUser
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserUpdatePayloadTrusthubVendorUser
   */
  lastName?: string;
  /**
   *
   * @type {TrusthubVendorUserStatus}
   * @memberof TrusthubVendorUserUpdatePayloadTrusthubVendorUser
   */
  status?: TrusthubVendorUserStatus;
}

export function TrusthubVendorUserUpdatePayloadTrusthubVendorUserFromJSON(
  json: any
): TrusthubVendorUserUpdatePayloadTrusthubVendorUser {
  return TrusthubVendorUserUpdatePayloadTrusthubVendorUserFromJSONTyped(
    json,
    false
  );
}

export function TrusthubVendorUserUpdatePayloadTrusthubVendorUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubVendorUserUpdatePayloadTrusthubVendorUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    trusthubCompanyId: !exists(json, 'trusthub_company_id')
      ? undefined
      : json['trusthub_company_id'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    status: !exists(json, 'status')
      ? undefined
      : TrusthubVendorUserStatusFromJSON(json['status']),
  };
}

export function TrusthubVendorUserUpdatePayloadTrusthubVendorUserToJSON(
  value?: TrusthubVendorUserUpdatePayloadTrusthubVendorUser | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    trusthub_company_id: value.trusthubCompanyId,
    first_name: value.firstName,
    last_name: value.lastName,
    status: TrusthubVendorUserStatusToJSON(value.status),
  };
}
