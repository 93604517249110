/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  Company,
  CompanyFromJSON,
  CompanyFromJSONTyped,
  CompanyToJSON,
  VendorDetailsCounts,
  VendorDetailsCountsFromJSON,
  VendorDetailsCountsFromJSONTyped,
  VendorDetailsCountsToJSON,
  VendorDetailsRegisters,
  VendorDetailsRegistersFromJSON,
  VendorDetailsRegistersFromJSONTyped,
  VendorDetailsRegistersToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorDetails
 */
export interface VendorDetails {
  /**
   *
   * @type {string}
   * @memberof VendorDetails
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VendorDetails
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof VendorDetails
   */
  website?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDetails
   */
  domain?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDetails
   */
  linkedin?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDetails
   */
  twitter?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDetails
   */
  accessGroupId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDetails
   */
  serviceProviderId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDetails
   */
  customLogoUrl?: string | null;
  /**
   *
   * @type {Access}
   * @memberof VendorDetails
   */
  access?: Access;
  /**
   *
   * @type {Array<Company>}
   * @memberof VendorDetails
   */
  companies?: Array<Company>;
  /**
   *
   * @type {VendorDetailsCounts}
   * @memberof VendorDetails
   */
  counts?: VendorDetailsCounts;
  /**
   *
   * @type {VendorDetailsRegisters}
   * @memberof VendorDetails
   */
  registers?: VendorDetailsRegisters;
  /**
   *
   * @type {string}
   * @memberof VendorDetails
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof VendorDetails
   */
  updatedAt?: string;
}

export function VendorDetailsFromJSON(json: any): VendorDetails {
  return VendorDetailsFromJSONTyped(json, false);
}

export function VendorDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    website: !exists(json, 'website') ? undefined : json['website'],
    domain: !exists(json, 'domain') ? undefined : json['domain'],
    linkedin: !exists(json, 'linkedin') ? undefined : json['linkedin'],
    twitter: !exists(json, 'twitter') ? undefined : json['twitter'],
    accessGroupId: !exists(json, 'access_group_id')
      ? undefined
      : json['access_group_id'],
    serviceProviderId: !exists(json, 'service_provider_id')
      ? undefined
      : json['service_provider_id'],
    customLogoUrl: !exists(json, 'custom_logo_url')
      ? undefined
      : json['custom_logo_url'],
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    companies: !exists(json, 'companies')
      ? undefined
      : (json['companies'] as Array<any>).map(CompanyFromJSON),
    counts: !exists(json, 'counts')
      ? undefined
      : VendorDetailsCountsFromJSON(json['counts']),
    registers: !exists(json, 'registers')
      ? undefined
      : VendorDetailsRegistersFromJSON(json['registers']),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function VendorDetailsToJSON(value?: VendorDetails | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    website: value.website,
    domain: value.domain,
    linkedin: value.linkedin,
    twitter: value.twitter,
    access_group_id: value.accessGroupId,
    service_provider_id: value.serviceProviderId,
    custom_logo_url: value.customLogoUrl,
    access: AccessToJSON(value.access),
    companies:
      value.companies === undefined
        ? undefined
        : (value.companies as Array<any>).map(CompanyToJSON),
    counts: VendorDetailsCountsToJSON(value.counts),
    registers: VendorDetailsRegistersToJSON(value.registers),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
