import { createSelector } from '@reduxjs/toolkit';
import { attestationSort } from '../functions/attestationSort';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';
import { VendorDocumentExtended } from '../swagger';
import { ApplicationState } from '../types/applicationState';

export const selectAttestations = (vendorDocument: VendorDocumentExtended) =>
  createSelector(
    selectSortSettings(Grid.AttestationManagement),
    (state: ApplicationState) =>
      state.vendors.documentState.vendorDocumentAttestations,
    (sortSetting, attestations) => {
      const clonedAttestations = [
        ...attestations.filter(a => a.vendorDocumentId === vendorDocument?.id),
      ];
      return clonedAttestations.sort((a, b) =>
        attestationSort(a, b, sortSetting.columnSort)
      );
    }
  );
