import { QuestionDatatypeEnum } from '../swagger';
import { QuestionType } from '../types/QuestionType';
import { formatShortDate } from './formatShortDate';

const getTableValue = (question: QuestionType, index: number) => {
  const value = question.answers.find(a => a.index === index)?.value;
  if (question.datatype === QuestionDatatypeEnum.Date) {
    return formatShortDate(value);
  } else {
    return value;
  }
};

export default getTableValue;
