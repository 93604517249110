import React from 'react';
import { Accordion } from 'react-bootstrap';
import getFilteredQuestionsWithoutTableQuestions from '../../functions/getFilteredQuestionsWithoutTableQuestions';
import { AssessmentSection } from '../../swagger';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { AssessmentQuestion } from '../AssessmentQuestion/AssessmentQuestion';
import './AssessmentSummaryQuestions.scss';

interface AssessmentQuestionProps {
  sections: AssessmentSection[];
}

export const AssessmentSummaryQuestions: React.FC<AssessmentQuestionProps> = ({
  sections,
}) => {
  return (
    <div className="assessmentSummaryQuestions">
      <Accordion>
        {sections?.map((section, index) => (
          <AdoptechAccordionCard
            index={index.toString()}
            key={section.title}
            title={section.title}
          >
            {getFilteredQuestionsWithoutTableQuestions(section)?.map(
              question => (
                <AssessmentQuestion
                  key={question.id}
                  question={question}
                  readonly
                />
              )
            )}
            <div className="assessmentSummaryQuestions--footer" />
          </AdoptechAccordionCard>
        ))}
      </Accordion>
    </div>
  );
};
