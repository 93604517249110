import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './PartOfMyPlanBanner.scss';

const PartOfMyPlanBanner = () => (
  <div className="partOfMyPlanBanner">
    <FontAwesomeIcon
      icon={faCheckCircle}
      className="partOfMyPlanBanner--greenTick"
    />
    <div className="partOfMyPlanBanner--text">Part of my plan</div>
  </div>
);

export default PartOfMyPlanBanner;
