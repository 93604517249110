import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '../store/global/globalState';
import { ApplicationState } from '../types/applicationState';
import { ColumnSort } from '../types/columnSort';
import { Grid } from '../types/grid';

export const selectSortSettings = (grid: Grid) => {
  return createSelector(
    (state: ApplicationState) => state.global,
    (state: GlobalState) => {
      const columnSort: ColumnSort = { direction: undefined, name: undefined };
      const sortSetting = state.sortSettings.find(ss => ss.grid === grid);
      return sortSetting ? sortSetting : { columnSort, grid };
    }
  );
};
