/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PaymentPlanPrice
 */
export interface PaymentPlanPrice {
  /**
   *
   * @type {string}
   * @memberof PaymentPlanPrice
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPlanPrice
   */
  internalName: string;
  /**
   *
   * @type {number}
   * @memberof PaymentPlanPrice
   */
  price: number;
}

export function PaymentPlanPriceFromJSON(json: any): PaymentPlanPrice {
  return PaymentPlanPriceFromJSONTyped(json, false);
}

export function PaymentPlanPriceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentPlanPrice {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    internalName: json['internal_name'],
    price: json['price'],
  };
}

export function PaymentPlanPriceToJSON(value?: PaymentPlanPrice | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    internal_name: value.internalName,
    price: value.price,
  };
}
