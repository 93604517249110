import React, { Dispatch, SetStateAction } from 'react';
import { AdoptechTextInput2 } from '../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { AdoptechReactSelect2 } from '../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechDatePicker2 } from '../../../components/AdoptechDatePicker2/AdoptechDatePicker2';
import './AuditsEditForm.scss';
import { AuditModel, VendorUserRoles } from '../../../swagger';
import { useOwnerOptions } from '../../../hooks/useOwnerOptions';

export const validation = (data: AuditModel): Record<string, string[]> => ({
  name: data.name ? null : ['Audit name must be set'],
  owner: data.owner?.id ? null : ['Owner must be set'],
  startAt: data.startedAt ? null : ['Start date must be set'],
});

interface Props {
  data: AuditModel;
  setData: Dispatch<SetStateAction<AuditModel>>;
}

export const AuditsEditForm: React.FC<Props> = ({ data, setData }) => {
  const baseCss = 'auditsEditForm';

  const { users, ownerOptions } = useOwnerOptions({
    onlyRoles: [
      VendorUserRoles.Admin,
      VendorUserRoles.SecurityManager,
      VendorUserRoles.Auditor,
      VendorUserRoles.ExternalConsultant,
    ],
  });
  const startDate = data.startedAt ? new Date(data.startedAt) : null;

  type Field = 'name' | 'owner' | 'startAt';

  const fieldErrors = (field: Field) => validation(data)[field];

  const hasError = (field: Field) => !!fieldErrors(field);

  const setName = (name: string) => setData({ ...data, name });

  return (
    <div className={baseCss}>
      <div className={baseCss + '--fieldRow'}>
        <div className={baseCss + '--label'}>Name your audit/review</div>
        <AdoptechTextInput2
          label="Audit name"
          placeholder="Audit name example: ISO 27001 internal audit"
          onChange={e => setName(e.currentTarget.value)}
          id="auditsEditFormName"
          hasError={hasError('name')}
          value={data.name || ''}
          type="text"
        />
      </div>
      <div className={baseCss + '--fieldRow'}>
        <div className={baseCss + '--label'}>
          Who is responsible for completing this audit
        </div>
        <AdoptechReactSelect2
          label="Owner"
          id="auditsEditFormDate"
          options={ownerOptions}
          hasError={hasError('owner')}
          onChange={e => {
            setData({
              ...data,
              owner: users.find(user => user.id === e.value),
            });
          }}
          value={ownerOptions.find(option => option.value == data.owner?.id)}
        />
      </div>
      <div className={baseCss + '--fieldRow'}>
        <div className={baseCss + '--label'}>
          On what date will the audit/review start (start date)
        </div>
        <AdoptechDatePicker2
          labelText="Start date"
          placeHolderText="select date"
          outputDate={startDate}
          hasError={hasError('startAt')}
          onDateSelect={d => {
            setData({
              ...data,
              startedAt: d.toJSON().split('T')[0],
            });
          }}
        />
      </div>
    </div>
  );
};
