/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PestelRegisterModelStats,
  PestelRegisterModelStatsFromJSON,
  PestelRegisterModelStatsFromJSONTyped,
  PestelRegisterModelStatsToJSON,
  ReviewFrequencyEnum,
  ReviewFrequencyEnumFromJSON,
  ReviewFrequencyEnumFromJSONTyped,
  ReviewFrequencyEnumToJSON,
  ReviewStatusEnum,
  ReviewStatusEnumFromJSON,
  ReviewStatusEnumFromJSONTyped,
  ReviewStatusEnumToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface PestelRegisterModel
 */
export interface PestelRegisterModel {
  /**
   *
   * @type {string}
   * @memberof PestelRegisterModel
   */
  id: string;
  /**
   *
   * @type {VendorUser}
   * @memberof PestelRegisterModel
   */
  owner?: VendorUser;
  /**
   *
   * @type {ReviewFrequencyEnum}
   * @memberof PestelRegisterModel
   */
  reviewFrequency?: ReviewFrequencyEnum;
  /**
   *
   * @type {ReviewStatusEnum}
   * @memberof PestelRegisterModel
   */
  reviewStatus?: ReviewStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof PestelRegisterModel
   */
  reviewOverdue?: boolean;
  /**
   *
   * @type {string}
   * @memberof PestelRegisterModel
   */
  nextReviewDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof PestelRegisterModel
   */
  lastReviewedAt?: string | null;
  /**
   *
   * @type {PestelRegisterModelStats}
   * @memberof PestelRegisterModel
   */
  stats?: PestelRegisterModelStats;
}

export function PestelRegisterModelFromJSON(json: any): PestelRegisterModel {
  return PestelRegisterModelFromJSONTyped(json, false);
}

export function PestelRegisterModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PestelRegisterModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    reviewFrequency: !exists(json, 'review_frequency')
      ? undefined
      : ReviewFrequencyEnumFromJSON(json['review_frequency']),
    reviewStatus: !exists(json, 'review_status')
      ? undefined
      : ReviewStatusEnumFromJSON(json['review_status']),
    reviewOverdue: !exists(json, 'review_overdue')
      ? undefined
      : json['review_overdue'],
    nextReviewDate: !exists(json, 'next_review_date')
      ? undefined
      : json['next_review_date'],
    lastReviewedAt: !exists(json, 'last_reviewed_at')
      ? undefined
      : json['last_reviewed_at'],
    stats: !exists(json, 'stats')
      ? undefined
      : PestelRegisterModelStatsFromJSON(json['stats']),
  };
}

export function PestelRegisterModelToJSON(
  value?: PestelRegisterModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    owner: VendorUserToJSON(value.owner),
    review_frequency: ReviewFrequencyEnumToJSON(value.reviewFrequency),
    review_status: ReviewStatusEnumToJSON(value.reviewStatus),
    review_overdue: value.reviewOverdue,
    next_review_date: value.nextReviewDate,
    last_reviewed_at: value.lastReviewedAt,
    stats: PestelRegisterModelStatsToJSON(value.stats),
  };
}
