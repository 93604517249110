import { Theme } from 'react-select';

export const reactSelectCustomTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#666665',
    primary25: '#cccccc',
    primary50: '#f1881f',
  },
});

export const reactSelectLightTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#ccc',
    primary25: '#cccccc',
    primary50: '#cccccc',
  },
});

export const reactSelectLightStyle = {
  control: {
    backgroundColor: 'white',
    borderRadius: '4px',
    border: '1px solid #ddd',
    height: '32px',
    minHeight: '32px',
  },
  indicatorSeparator: {
    display: 'none',
  },
  valueContainer: {
    height: '32px',
  },
  indicatorsContainer: {
    height: '32px',
  },
};

export const reactSelectLightCheckboxStyle = {
  control: {
    backgroundColor: 'white',
    borderRadius: '4px',
    border: '1px solid #ddd',
    minHeight: '32px',
    padding: '0px',
  },
  indicatorSeparator: {
    display: 'none',
  },
  valueContainer: {
    height: '32px',
    padding: '0px 8px',
  },
  indicatorsContainer: {
    height: '32px',
  },
};

export const reactSelectLightStyleSmall = {
  ...reactSelectLightStyle,
  control: {
    ...reactSelectLightStyle.control,
    paddingLeft: 10,
    fontSize: 14,
  },
};

export const reactSelectLightBiggerStyle = {
  ...reactSelectLightStyle,
  control: {
    ...reactSelectLightStyle.control,
    height: 'auto',
    minHeight: 'auto',
  },
  valueContainer: {
    ...reactSelectLightStyle.valueContainer,
    height: 'auto',
  },
  indicatorsContainer: {
    ...reactSelectLightStyle.indicatorsContainer,
    height: 'auto',
  },
};
