/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Access
 */
export interface Access {
  /**
   *
   * @type {Array<string>}
   * @memberof Access
   */
  create?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Access
   */
  index?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Access
   */
  feature?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof Access
   */
  show?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Access
   */
  update?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Access
   */
  destroy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Access
   */
  share?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Access
   */
  tables?: boolean;
}

export function AccessFromJSON(json: any): Access {
  return AccessFromJSONTyped(json, false);
}

export function AccessFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Access {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    create: !exists(json, 'create') ? undefined : json['create'],
    index: !exists(json, 'index') ? undefined : json['index'],
    feature: !exists(json, 'feature') ? undefined : json['feature'],
    show: !exists(json, 'show') ? undefined : json['show'],
    update: !exists(json, 'update') ? undefined : json['update'],
    destroy: !exists(json, 'destroy') ? undefined : json['destroy'],
    share: !exists(json, 'share') ? undefined : json['share'],
    tables: !exists(json, 'tables') ? undefined : json['tables'],
  };
}

export function AccessToJSON(value?: Access | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    create: value.create,
    index: value.index,
    feature: value.feature,
    show: value.show,
    update: value.update,
    destroy: value.destroy,
    share: value.share,
    tables: value.tables,
  };
}
