/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AgreementCreatePayloadAgreement,
  AgreementCreatePayloadAgreementFromJSON,
  AgreementCreatePayloadAgreementFromJSONTyped,
  AgreementCreatePayloadAgreementToJSON,
  AgreementCreatePayloadAgreementSignatories,
  AgreementCreatePayloadAgreementSignatoriesFromJSON,
  AgreementCreatePayloadAgreementSignatoriesFromJSONTyped,
  AgreementCreatePayloadAgreementSignatoriesToJSON,
} from './';

/**
 *
 * @export
 * @interface AgreementCreatePayload
 */
export interface AgreementCreatePayload {
  /**
   *
   * @type {AgreementCreatePayloadAgreement}
   * @memberof AgreementCreatePayload
   */
  agreement: AgreementCreatePayloadAgreement;
  /**
   *
   * @type {Array<AgreementCreatePayloadAgreementSignatories>}
   * @memberof AgreementCreatePayload
   */
  agreementSignatories?: Array<AgreementCreatePayloadAgreementSignatories>;
}

export function AgreementCreatePayloadFromJSON(
  json: any
): AgreementCreatePayload {
  return AgreementCreatePayloadFromJSONTyped(json, false);
}

export function AgreementCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    agreement: AgreementCreatePayloadAgreementFromJSON(json['agreement']),
    agreementSignatories: !exists(json, 'agreement_signatories')
      ? undefined
      : (json['agreement_signatories'] as Array<any>).map(
          AgreementCreatePayloadAgreementSignatoriesFromJSON
        ),
  };
}

export function AgreementCreatePayloadToJSON(
  value?: AgreementCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    agreement: AgreementCreatePayloadAgreementToJSON(value.agreement),
    agreement_signatories:
      value.agreementSignatories === undefined
        ? undefined
        : (value.agreementSignatories as Array<any>).map(
            AgreementCreatePayloadAgreementSignatoriesToJSON
          ),
  };
}
