/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsFromJSONTyped,
  UserDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface SharedDocumentListData
 */
export interface SharedDocumentListData {
  /**
   *
   * @type {string}
   * @memberof SharedDocumentListData
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SharedDocumentListData
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof SharedDocumentListData
   */
  link?: string | null;
  /**
   *
   * @type {UserDetails}
   * @memberof SharedDocumentListData
   */
  sharedBy?: UserDetails;
  /**
   *
   * @type {string}
   * @memberof SharedDocumentListData
   */
  sharedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof SharedDocumentListData
   */
  accessLevel?: SharedDocumentListDataAccessLevelEnum;
}

export function SharedDocumentListDataFromJSON(
  json: any
): SharedDocumentListData {
  return SharedDocumentListDataFromJSONTyped(json, false);
}

export function SharedDocumentListDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SharedDocumentListData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: !exists(json, 'title') ? undefined : json['title'],
    type: !exists(json, 'type') ? undefined : json['type'],
    link: !exists(json, 'link') ? undefined : json['link'],
    sharedBy: !exists(json, 'shared_by')
      ? undefined
      : UserDetailsFromJSON(json['shared_by']),
    sharedAt: !exists(json, 'shared_at') ? undefined : json['shared_at'],
    accessLevel: !exists(json, 'access_level')
      ? undefined
      : json['access_level'],
  };
}

export function SharedDocumentListDataToJSON(
  value?: SharedDocumentListData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    type: value.type,
    link: value.link,
    shared_by: UserDetailsToJSON(value.sharedBy),
    shared_at: value.sharedAt,
    access_level: value.accessLevel,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum SharedDocumentListDataAccessLevelEnum {
  Viewer = 'viewer',
  Contributor = 'contributor',
  Manager = 'manager',
}
