/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SharedTaskRecurring {
  NonRecurring = 'non_recurring',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  BiAnnually = 'bi_annually',
  Annually = 'annually',
  Other = 'other',
}

export function SharedTaskRecurringFromJSON(json: any): SharedTaskRecurring {
  return SharedTaskRecurringFromJSONTyped(json, false);
}

export function SharedTaskRecurringFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SharedTaskRecurring {
  return json as SharedTaskRecurring;
}

export function SharedTaskRecurringToJSON(
  value?: SharedTaskRecurring | null
): any {
  return value as any;
}
