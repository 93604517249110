import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';
import AssessmentsTemplateTilesGrid from '../AssessmentsTemplateTilesGrid/AssessmentsTemplateTilesGrid';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { Intent, Lozenge } from '../Lozenge/Lozenge';
import { NoDataText } from '../NoDataText/NoDataText';
import './AssessmentsTemplates.scss';

export const AssessmentsTemplates: React.FC = () => {
  const isFetchingAssessmentTemplates = useSelector(
    (state: ApplicationState) => state.assessments.isFetchingAssessmentTemplates
  );
  const assessmentTemplates = useSelector(
    (state: ApplicationState) => state.assessments.assessmentTemplates
  );

  if (isFetchingAssessmentTemplates) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="assessmentsTemplates">
        <div className="assessmentsTemplates--header">
          <div className="assessmentsTemplates--title">
            Assessment templates
            <Lozenge
              intent={Intent.None}
              value={assessmentTemplates?.length || 0}
            />
          </div>
        </div>
        <div className="assessmentsTemplates--tiles">
          {!assessmentTemplates || assessmentTemplates?.length === 0 ? (
            <NoDataText text="There are no recently created assessment Templates" />
          ) : (
            <AssessmentsTemplateTilesGrid templates={assessmentTemplates} />
          )}
        </div>
      </div>
    </>
  );
};
export default AssessmentsTemplates;
