import { ErrorResponse } from '../swagger/models/ErrorResponse';
import { capitalize } from './capitalize';

export const getApiErrorMessage = async (err: Response): Promise<string> => {
  let json: ErrorResponse | string | null | undefined;
  try {
    json = await err.json();
  } catch (error) {
    console.log('Error attempting to get API error JSON:', error);
    console.log('Response object:', err);
    return 'An unexpected error was encountered. Please try again or contact support.';
  }

  if (typeof json === 'string') return json;

  const baseErrors = json?.errors?.base;
  if (baseErrors) return baseErrors;
  const permissionErrors = json?.messages;
  if (permissionErrors) return json.messages.join(' ');

  const validFormatErrors = Object.keys(json?.errors || {});

  if (validFormatErrors.length > 0) {
    if (json.details) console.log(json.details);
    return Object.entries(validFormatErrors)
      .map(error => `${capitalize(error[0])} ${error[1]}.`)
      .join(' ');
  }

  const output =
    // for login we have old errors format [{message: ''}]
    Array.isArray(json) && json[0]?.message
      ? json.map(item => item.message).join(', ')
      : JSON.stringify(json);

  return `Error: ${output}`;
};
