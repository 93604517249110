/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedMaxUsersAmount,
  SharedMaxUsersAmountFromJSON,
  SharedMaxUsersAmountFromJSONTyped,
  SharedMaxUsersAmountToJSON,
  SharedPaymentPeriod,
  SharedPaymentPeriodFromJSON,
  SharedPaymentPeriodFromJSONTyped,
  SharedPaymentPeriodToJSON,
} from './';

/**
 *
 * @export
 * @interface PaymentPlanVendorCheckoutCreatePayload
 */
export interface PaymentPlanVendorCheckoutCreatePayload {
  /**
   *
   * @type {string}
   * @memberof PaymentPlanVendorCheckoutCreatePayload
   */
  planId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPlanVendorCheckoutCreatePayload
   */
  vendorId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPlanVendorCheckoutCreatePayload
   */
  productId?: string;
  /**
   *
   * @type {SharedMaxUsersAmount}
   * @memberof PaymentPlanVendorCheckoutCreatePayload
   */
  usersLimit?: SharedMaxUsersAmount;
  /**
   *
   * @type {SharedPaymentPeriod}
   * @memberof PaymentPlanVendorCheckoutCreatePayload
   */
  paymentPeriod: SharedPaymentPeriod;
  /**
   *
   * @type {string}
   * @memberof PaymentPlanVendorCheckoutCreatePayload
   */
  purchasableId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPlanVendorCheckoutCreatePayload
   */
  purchasableType?: string;
}

export function PaymentPlanVendorCheckoutCreatePayloadFromJSON(
  json: any
): PaymentPlanVendorCheckoutCreatePayload {
  return PaymentPlanVendorCheckoutCreatePayloadFromJSONTyped(json, false);
}

export function PaymentPlanVendorCheckoutCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentPlanVendorCheckoutCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    planId: json['plan_id'],
    vendorId: json['vendor_id'],
    productId: !exists(json, 'product_id') ? undefined : json['product_id'],
    usersLimit: !exists(json, 'users_limit')
      ? undefined
      : SharedMaxUsersAmountFromJSON(json['users_limit']),
    paymentPeriod: SharedPaymentPeriodFromJSON(json['payment_period']),
    purchasableId: !exists(json, 'purchasable_id')
      ? undefined
      : json['purchasable_id'],
    purchasableType: !exists(json, 'purchasable_type')
      ? undefined
      : json['purchasable_type'],
  };
}

export function PaymentPlanVendorCheckoutCreatePayloadToJSON(
  value?: PaymentPlanVendorCheckoutCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    plan_id: value.planId,
    vendor_id: value.vendorId,
    product_id: value.productId,
    users_limit: SharedMaxUsersAmountToJSON(value.usersLimit),
    payment_period: SharedPaymentPeriodToJSON(value.paymentPeriod),
    purchasable_id: value.purchasableId,
    purchasable_type: value.purchasableType,
  };
}
