/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Officer,
  OfficerFromJSON,
  OfficerFromJSONTyped,
  OfficerToJSON,
} from './';

/**
 *
 * @export
 * @interface OfficerRole
 */
export interface OfficerRole {
  /**
   *
   * @type {string}
   * @memberof OfficerRole
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof OfficerRole
   */
  name?: string;
  /**
   *
   * @type {Array<Officer>}
   * @memberof OfficerRole
   */
  officers?: Array<Officer>;
}

export function OfficerRoleFromJSON(json: any): OfficerRole {
  return OfficerRoleFromJSONTyped(json, false);
}

export function OfficerRoleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OfficerRole {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    officers: !exists(json, 'officers')
      ? undefined
      : (json['officers'] as Array<any>).map(OfficerFromJSON),
  };
}

export function OfficerRoleToJSON(value?: OfficerRole | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    officers:
      value.officers === undefined
        ? undefined
        : (value.officers as Array<any>).map(OfficerToJSON),
  };
}
