import { ApplicationState } from '../types/applicationState';
import { createSelector } from '@reduxjs/toolkit';
import {
  VendorDocumentApprovalStatusEnum,
  VendorDocumentExtended,
  VendorDocumentStatusEnum,
} from '../swagger';
import { AccessObject } from '../types/accessObject';
import { selectSortSettings } from './selectSortSettings';
import { Grid } from '../types/grid';
import { VendorDocumentWithCategoryName } from './selectLivePolicies';
import { vendorDocumentWithCategoryNameSort } from '../functions/vendorDocumentWithCategoryNameSort';
import { selectCurrentVendor } from './selectCurrentVendor';

export const selectPendingApprovalPolicies = createSelector(
  (state: ApplicationState) => state.user.userDetails.email,
  (state: ApplicationState) => state.vendors.documentState.policyDocuments,
  (state: ApplicationState) => state.policies.vendorPolicies,
  (state: ApplicationState) => state.policies.policyCategories,
  selectSortSettings(Grid.PendingApprovalPolicies),
  selectCurrentVendor,
  (state: ApplicationState) =>
    state.vendors.documentState.vendorDocumentApprovals,
  (
    email,
    policyDocuments,
    vendorPolicies,
    policyCategories,
    sortSetting,
    currentVendor,
    approvals
  ) => {
    let pendingApprovalPolicies: VendorDocumentExtended[];

    const allDocumentsPendingApproval = policyDocuments.filter(
      pd => pd.status === VendorDocumentStatusEnum.Approval
    );
    const allDocumentsRejected = policyDocuments.filter(
      pd => pd.status === VendorDocumentStatusEnum.Rejected
    );

    const canSeeAllPolicies = currentVendor?.access?.create?.includes(
      AccessObject.vendor_policies
    );
    // if policy editor
    if (canSeeAllPolicies) {
      pendingApprovalPolicies = [
        ...allDocumentsPendingApproval,
        ...allDocumentsRejected,
      ];
    } else {
      // else find approvals requested from me or was shared with me when was drafted
      pendingApprovalPolicies = allDocumentsPendingApproval.filter(vd => {
        approvals.some(
          vda =>
            vda.vendorDocumentId === vd.id &&
            vda.email === email &&
            vda.required &&
            vda.status === VendorDocumentApprovalStatusEnum.Requested
        ) || vd.access.feature.includes(AccessObject.manage_approvals);
      });
    }

    const pendingApprovalPoliciesWithCategoryName: VendorDocumentWithCategoryName[] =
      pendingApprovalPolicies.map(pap => {
        const isManualUploadedPolicy = pap.policyCategoryId;

        const categoryName = isManualUploadedPolicy
          ? policyCategories.find(
              policyCategory => policyCategory.id === pap.policyCategoryId
            )?.name
          : vendorPolicies.find(vp => vp.vendorDocumentId === pap.id)
              ?.categoryName;
        return {
          ...pap,
          categoryName: categoryName || '',
        };
      });

    return pendingApprovalPoliciesWithCategoryName.sort((a, b) =>
      vendorDocumentWithCategoryNameSort(a, b, sortSetting.columnSort)
    );
  }
);
