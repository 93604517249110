/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FrameworkRequirementModel
 */
export interface FrameworkRequirementModel {
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementModel
   */
  type: FrameworkRequirementModelTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementModel
   */
  identifier?: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementModel
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementModel
   */
  descriptionAsHtml: string | null;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementModel
   */
  guidanceIdentifier?: string | null;
  /**
   *
   * @type {number}
   * @memberof FrameworkRequirementModel
   */
  progressPoints: number;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof FrameworkRequirementModel
   */
  updatedAt: string;
}

export function FrameworkRequirementModelFromJSON(
  json: any
): FrameworkRequirementModel {
  return FrameworkRequirementModelFromJSONTyped(json, false);
}

export function FrameworkRequirementModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkRequirementModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    type: json['type'],
    identifier: !exists(json, 'identifier') ? undefined : json['identifier'],
    description: json['description'],
    descriptionAsHtml: json['description_as_html'],
    guidanceIdentifier: !exists(json, 'guidance_identifier')
      ? undefined
      : json['guidance_identifier'],
    progressPoints: json['progress_points'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function FrameworkRequirementModelToJSON(
  value?: FrameworkRequirementModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    type: value.type,
    identifier: value.identifier,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
    guidance_identifier: value.guidanceIdentifier,
    progress_points: value.progressPoints,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum FrameworkRequirementModelTypeEnum {
  Manual = 'FrameworkRequirement::Manual',
  SendMessage = 'FrameworkRequirement::SendMessage',
}
