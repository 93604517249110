import React, { useState } from 'react';
import { EditTestFormSection, isTestAutomatic } from '../EditTestForm';
import { useChildClass } from '../../../../../../../hooks/useChildClass';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssertionModelStatusEnum } from '../../../../../../../swagger';
import { capitaliseFirst } from '../../../../../../../functions/capitaliseFirst';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { EditTestFormLastResultsList } from './EditTestFormLastResultsList/EditTestFormLastResultsList';
import { AdoptechButton } from '../../../../../../../components/AdoptechButton/AdoptechButton';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { EditTestFormReviewTestModal } from '../EditTestFormReviewTestModal/EditTestFormReviewTestModal';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { formatShortDate } from '../../../../../../../functions/formatShortDate';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';

export const EditTestFormLastResultsSection: React.FC<
  EditTestFormSection
> = props => {
  const baseCss = 'editTestForm';
  const childClass = useChildClass(baseCss);
  const isAuto = isTestAutomatic(props.formData);
  const isCompliant =
    props.formData.status === AssertionModelStatusEnum.Compliant;
  const isFailed = props.formData.status === AssertionModelStatusEnum.Failed;
  const [showReviewTestModal, setReviewTestModal] = useState(false);
  const handleReviewClick = () => setReviewTestModal(true);
  const [reviewConfirmed, setReviewConfirmed] = useState(false);

  return (
    <div className={baseCss + '--fieldBlock'}>
      <div className={baseCss + '--field'}>
        <div className={baseCss + '--results-header'}>
          <div>Test status</div>
          <div
            className={classNames(baseCss + '--results-header-icon', {
              green: isCompliant,
              red: isFailed,
            })}
          >
            <FontAwesomeIcon
              icon={isFailed ? faExclamationCircle : faCheckCircle}
            />
            <div>{capitaliseFirst(props.formData.status)}</div>
          </div>
          {!isAuto && (
            <>
              <div className={baseCss + '--run-review'}>
                <AdoptechButton
                  icon={faCheck}
                  size="middle2"
                  onClick={handleReviewClick}
                  disabled={reviewConfirmed}
                >
                  {reviewConfirmed ? 'Review confirmed' : 'Run Test'}
                </AdoptechButton>
              </div>
              {showReviewTestModal && (
                <EditTestFormReviewTestModal
                  {...props}
                  show={showReviewTestModal}
                  onHide={() => setReviewTestModal(false)}
                  onConfirm={() => setReviewConfirmed(true)}
                />
              )}
            </>
          )}
        </div>
        <div className={baseCss + '--nextRunDate-header'}>
          <div>Next Test due</div>
          <div>{formatShortDate(props.formData.nextRunAfter)}</div>
          {props.formData.overdue && (
            <div className={baseCss + '--overdueIcon'}>
              <FontAwesomeIcon size="lg" icon={faClock} className="mr-2" />
              <span className="font-weight-bold">Overdue</span>
            </div>
          )}
        </div>
        <EditTestFormLastResultsList {...props} />
      </div>
    </div>
  );
};
