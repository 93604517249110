import { api } from '../../api/api';
import {
  DataRoomEntityFolderCreatePayloadFolder,
  DataRoomEntityModel,
  DataRoomEntityModelTypeEnum,
} from '../../swagger';
import { VoidThunk } from '../../types/voidThunk';
import { showGlobalToast } from '../global/globalSlice';
import { getErrorMessage } from '../helpers/thunkHelpers';
import {
  fetchFilesRequest,
  fetchFilesSuccess,
  fetchFilesError,
  fetchEntitiesTreeRequest,
  fetchEntitiesTreeSuccess,
  fetchEntitiesTreeError,
  createFolderRequest,
  createFolderSuccess,
  createFolderError,
  getFileDetailsRequest,
  getFileDetailsSuccess,
  getFileDetailsError,
  uploadFileRequest,
  uploadFileSuccess,
  uploadFileError,
  deleteEntitiesRequest,
  deleteEntitiesSuccess,
  deleteEntitiesError,
  moveEntitiesRequest,
  moveEntitiesSuccess,
  moveEntitiesError,
  updateEntityRequest,
  updateEntitySuccess,
  updateEntityError,
} from './dataRoomSlice';

export const fetchFiles =
  (vendorId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchFilesRequest());
      const response = await api().vendorsVendorIdDataRoomEntitiesGet({
        vendorId,
      });
      dispatch(fetchFilesSuccess(response as DataRoomEntityModel[]));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching files. Please try again or contact support.'
        );
        console.log(err);
        dispatch(fetchFilesError());
        dispatch(showGlobalToast(apiErrorMessage));
      }
    }
  };

export const fetchEntitiesTree =
  (
    vendorId: string,
    type?: DataRoomEntityModelTypeEnum,
    onSuccess?: (tree: DataRoomEntityModel) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntitiesTreeRequest());
      const response = await api().vendorsVendorIdDataRoomEntitiesTreeGet({
        vendorId,
        type,
      });
      dispatch(fetchEntitiesTreeSuccess(response as DataRoomEntityModel));
      onSuccess && onSuccess(response);
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching files. Please try again or contact support.'
        );
        console.log(err);
        dispatch(fetchEntitiesTreeError());
        dispatch(showGlobalToast(apiErrorMessage));
      }
    }
  };

export const createFolder =
  (
    vendorId: string,
    folder: DataRoomEntityFolderCreatePayloadFolder
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(createFolderRequest());
      const response = await api().vendorsVendorIdDataRoomFoldersPost({
        vendorId,
        body: { folder },
      });
      dispatch(createFolderSuccess());
      dispatch(fetchEntitiesTree(vendorId));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching files. Please try again or contact support.'
        );
        console.log(err);
        dispatch(createFolderError());
        dispatch(showGlobalToast(apiErrorMessage));
      }
    }
  };

export const downloadFile =
  (fileId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(getFileDetailsRequest(fileId));
      const response = await api().dataRoomFilesFileIdDownloadGet({
        fileId,
      });
      dispatch(getFileDetailsSuccess());
      window.open(response.attachmentUrl, '_blank');
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching file details. Please try again or contact support.'
        );
        console.log(err);
        dispatch(getFileDetailsError());
        dispatch(showGlobalToast(apiErrorMessage));
      }
    }
  };

export const uploadFile =
  (
    vendorId: string,
    parentFolderId: string,
    files0Attachment: Blob,
    files0Filename: string
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(uploadFileRequest(parentFolderId));
      const response = await api().vendorsVendorIdDataRoomFilesPost({
        vendorId,
        parentFolderId,
        files0Attachment,
        files0Filename,
      });
      dispatch(uploadFileSuccess(response as DataRoomEntityModel[]));
      dispatch(fetchEntitiesTree(vendorId));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while fetching files. Please try again or contact support.'
        );
        console.log(err);
        dispatch(uploadFileError());
        dispatch(showGlobalToast(apiErrorMessage));
      }
    }
  };

export const deleteEntities =
  (
    vendorId: string,
    entitiesIds: string[],
    canManageRootTree: boolean
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteEntitiesRequest(entitiesIds));
      await api().vendorsVendorIdDataRoomEntitiesDelete({
        vendorId,
        body: { entitiesIds },
      });
      dispatch(deleteEntitiesSuccess(entitiesIds));
      if (canManageRootTree) {
        dispatch(fetchEntitiesTree(vendorId));
      }
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while deleting an entity. Please try again or contact support.'
        );
        console.log(err);
        dispatch(deleteEntitiesError());
        dispatch(showGlobalToast(apiErrorMessage));
      }
    }
  };

export const updateEntity =
  (
    vendorId: string,
    entityId: string,
    filename: string,
    description = '',
    canManageRootTree: boolean
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(updateEntityRequest([entityId]));
      await api().dataRoomEntitiesEntityIdPatch({
        entityId,
        body: { entity: { filename, description } },
      });

      dispatch(updateEntitySuccess([entityId]));
      dispatch(fetchFiles(vendorId));
      if (canManageRootTree) {
        dispatch(fetchEntitiesTree(vendorId));
      }
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while moving entities. Please try again or contact support.'
        );
        console.log(err);
        dispatch(updateEntityError());
        dispatch(showGlobalToast(apiErrorMessage));
      }
    }
  };

export const moveEntities =
  (
    vendorId: string,
    parentEntityId: string,
    entitiesIds: string[]
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(moveEntitiesRequest([parentEntityId]));
      await api().vendorsVendorIdDataRoomEntitiesMovePatch({
        vendorId,
        body: { parentEntityId, entitiesIds },
      });
      dispatch(moveEntitiesSuccess([parentEntityId]));
      // TODO: replace fetch with optimistic client side store mutations
      dispatch(fetchEntitiesTree(vendorId));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while moving entities. Please try again or contact support.'
        );
        console.log(err);
        dispatch(moveEntitiesError());
        dispatch(showGlobalToast(apiErrorMessage));
      }
    }
  };
