/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AuditExtendedAllOf,
  AuditExtendedAllOfFromJSON,
  AuditExtendedAllOfFromJSONTyped,
  AuditExtendedAllOfToJSON,
  AuditTemplateModel,
  AuditTemplateModelFromJSON,
  AuditTemplateModelFromJSONTyped,
  AuditTemplateModelToJSON,
  Survey,
  SurveyFromJSON,
  SurveyFromJSONTyped,
  SurveyToJSON,
} from './';

/**
 *
 * @export
 * @interface AuditTemplateExtended
 */
export interface AuditTemplateExtended {
  /**
   *
   * @type {string}
   * @memberof AuditTemplateExtended
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AuditTemplateExtended
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AuditTemplateExtended
   */
  summary: string;
  /**
   *
   * @type {string}
   * @memberof AuditTemplateExtended
   */
  identifier: string;
  /**
   *
   * @type {number}
   * @memberof AuditTemplateExtended
   */
  rowOrder: number;
  /**
   *
   * @type {string}
   * @memberof AuditTemplateExtended
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof AuditTemplateExtended
   */
  updatedAt?: string;
  /**
   *
   * @type {Survey}
   * @memberof AuditTemplateExtended
   */
  survey: Survey;
}

export function AuditTemplateExtendedFromJSON(
  json: any
): AuditTemplateExtended {
  return AuditTemplateExtendedFromJSONTyped(json, false);
}

export function AuditTemplateExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuditTemplateExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    summary: json['summary'],
    identifier: json['identifier'],
    rowOrder: json['row_order'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    survey: SurveyFromJSON(json['survey']),
  };
}

export function AuditTemplateExtendedToJSON(
  value?: AuditTemplateExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    summary: value.summary,
    identifier: value.identifier,
    row_order: value.rowOrder,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    survey: SurveyToJSON(value.survey),
  };
}
