import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';

import React, { useState } from 'react';
import './ComplianceControlTasksSimpleList.scss';
import { TaskModel, TaskModelStatusEnum } from '../../../../../swagger';
import { faCheckCircle as solidFaCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { ApplicationState } from '../../../../../types/applicationState';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeControlRelation,
  setControlFormModel,
} from '../../../../../store/compliance/complianceSlice';
import { ConfirmModal } from '../../../../../components/ConfirmModal/ConfirmModal';
import { selectIsViewModeControlDrawer } from '../../../../../selectors/compliance/selectIsViewModeEditControlDrawer';
import { useOnTaskViewClick } from '../../../../../hooks/compliance/useOnTaskViewClick';
import AdoptechIcon from '../../AdoptechIcon/AdoptechIcon';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { EditDocumentModal } from '../EditDocumentModal/EditDocumentModal';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import AdoptechOverflowLine from '../../../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';

export const ComplianceControlTasksSimpleList: React.FC = () => {
  const baseCss = 'complianceControlTasksSimpleList';
  const [taskToRemove, setTaskToRemove] = useState<TaskModel>(undefined);
  const dispatch = useDispatch();

  const {
    editControlFormModel,
    isFetchingCategories,
    isFetchingControlDetails,
  } = useSelector((state: ApplicationState) => state.compliance);
  const updateTaskListOnModel = (tasks: TaskModel[]) => {
    dispatch(
      setControlFormModel({
        ...editControlFormModel,
        tasks,
        formTouched: true,
      })
    );
  };

  const handleRemoveTask = (task: TaskModel) => {
    if (!task) return;
    let tasks: TaskModel[] = [];

    tasks = editControlFormModel.tasks?.filter(t => t.id !== task.id);
    dispatch(removeControlRelation(editControlFormModel.id));

    updateTaskListOnModel(tasks);
  };

  const isViewMode = useSelector(selectIsViewModeControlDrawer);
  const { onTaskViewClick } = useOnTaskViewClick();
  const [showCheckModal, setShowCheckModal] = useState(false);
  if (isFetchingControlDetails) return <LoadingSpinner />;
  return (
    <>
      <div className={baseCss + '--header'}>
        <div>Associated checks</div>
        {!isViewMode && (
          <AdoptechIcon shape="square" onClick={() => setShowCheckModal(true)}>
            <FontAwesomeIcon icon={faPlus} />
          </AdoptechIcon>
        )}

        <EditDocumentModal
          show={showCheckModal}
          onHide={() => setShowCheckModal(false)}
        />
      </div>

      {(editControlFormModel?.tasks || []).length > 0 && (
        <div className={baseCss + '--tasks'}>
          <div className={baseCss + '--tableHeaders'}>
            <div className={baseCss + '--label'}>Status</div>
            <div className={baseCss + '--label'}>Name</div>
            <div className={baseCss + '--label'}>Action</div>
          </div>
          {editControlFormModel?.tasks?.map(task => (
            <div
              className={baseCss + '--tableRow'}
              key={task.id}
              onClick={e => {
                e.stopPropagation();
                onTaskViewClick(task.id);
              }}
            >
              <div className={baseCss + '--status'}>
                <FontAwesomeIcon
                  icon={
                    task.status === TaskModelStatusEnum.Failed
                      ? faExclamationCircle
                      : solidFaCheckCircle
                  }
                  className={
                    task.status === TaskModelStatusEnum.Compliant
                      ? baseCss + '--greenTick'
                      : task.status === TaskModelStatusEnum.Failed
                        ? baseCss + '--redTick'
                        : baseCss + '--greyTick'
                  }
                />
              </div>
              <AdoptechOverflowLine className={baseCss + '--name'}>
                {task.name}
              </AdoptechOverflowLine>
              <div className={baseCss + '--icon'}>
                {editControlFormModel?.create ? (
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    onClick={e => {
                      handleRemoveTask(task);
                      e.stopPropagation();
                    }}
                  />
                ) : !isViewMode ? (
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    onClick={e => {
                      setTaskToRemove(task);
                      e.stopPropagation();
                    }}
                  />
                ) : null}
              </div>
            </div>
          ))}

          <ConfirmModal
            close={() => {
              setTaskToRemove(undefined);
            }}
            headerText="Remove Check"
            description="Are you sure you would like to remove check:"
            show={Boolean(taskToRemove)}
            confirm={() => {
              handleRemoveTask(taskToRemove);
              setTaskToRemove(undefined);
            }}
          >
            <div className={baseCss + '--taskName'}> {taskToRemove?.name} </div>
          </ConfirmModal>
        </div>
      )}
    </>
  );
};
