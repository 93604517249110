import React from 'react';
import './AdoptechTagAutocomplete.scss';
import ReactTags, { ReactTagsProps } from 'react-tag-autocomplete';

type AdoptechTagAutocompleteProps = ReactTagsProps;

export const AdoptechTagAutocomplete = (
  props: AdoptechTagAutocompleteProps
) => {
  return <ReactTags {...props} />;
};
