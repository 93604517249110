/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AgreementSendPayload
 */
export interface AgreementSendPayload {
  /**
   *
   * @type {Array<string>}
   * @memberof AgreementSendPayload
   */
  signatoriesToNotifyIds?: Array<string>;
}

export function AgreementSendPayloadFromJSON(json: any): AgreementSendPayload {
  return AgreementSendPayloadFromJSONTyped(json, false);
}

export function AgreementSendPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementSendPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    signatoriesToNotifyIds: !exists(json, 'signatories_to_notify_ids')
      ? undefined
      : json['signatories_to_notify_ids'],
  };
}

export function AgreementSendPayloadToJSON(
  value?: AgreementSendPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    signatories_to_notify_ids: value.signatoriesToNotifyIds,
  };
}
