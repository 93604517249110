/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrusthubInvitationCreatePayloadTrusthubUser
 */
export interface TrusthubInvitationCreatePayloadTrusthubUser {
  /**
   *
   * @type {string}
   * @memberof TrusthubInvitationCreatePayloadTrusthubUser
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof TrusthubInvitationCreatePayloadTrusthubUser
   */
  vendorTrusthubId?: number;
  /**
   *
   * @type {string}
   * @memberof TrusthubInvitationCreatePayloadTrusthubUser
   */
  domain?: string;
}

export function TrusthubInvitationCreatePayloadTrusthubUserFromJSON(
  json: any
): TrusthubInvitationCreatePayloadTrusthubUser {
  return TrusthubInvitationCreatePayloadTrusthubUserFromJSONTyped(json, false);
}

export function TrusthubInvitationCreatePayloadTrusthubUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubInvitationCreatePayloadTrusthubUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, 'email') ? undefined : json['email'],
    vendorTrusthubId: !exists(json, 'vendor_trusthub_id')
      ? undefined
      : json['vendor_trusthub_id'],
    domain: !exists(json, 'domain') ? undefined : json['domain'],
  };
}

export function TrusthubInvitationCreatePayloadTrusthubUserToJSON(
  value?: TrusthubInvitationCreatePayloadTrusthubUser | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    vendor_trusthub_id: value.vendorTrusthubId,
    domain: value.domain,
  };
}
