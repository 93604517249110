/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrusthubAccessRequestApprovePayloadTrusthubAccessRequest
 */
export interface TrusthubAccessRequestApprovePayloadTrusthubAccessRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof TrusthubAccessRequestApprovePayloadTrusthubAccessRequest
   */
  documentToApproveIds?: Array<string>;
}

export function TrusthubAccessRequestApprovePayloadTrusthubAccessRequestFromJSON(
  json: any
): TrusthubAccessRequestApprovePayloadTrusthubAccessRequest {
  return TrusthubAccessRequestApprovePayloadTrusthubAccessRequestFromJSONTyped(
    json,
    false
  );
}

export function TrusthubAccessRequestApprovePayloadTrusthubAccessRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubAccessRequestApprovePayloadTrusthubAccessRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    documentToApproveIds: !exists(json, 'document_to_approve_ids')
      ? undefined
      : json['document_to_approve_ids'],
  };
}

export function TrusthubAccessRequestApprovePayloadTrusthubAccessRequestToJSON(
  value?: TrusthubAccessRequestApprovePayloadTrusthubAccessRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    document_to_approve_ids: value.documentToApproveIds,
  };
}
