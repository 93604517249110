import React, { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';
import './ErrorToast.scss';
import { clearErrorMessage } from '../../store/global/globalSlice';

export const ErrorToast: React.FC = () => {
  const [show, setShow] = useState(false);

  const errorMessage = useSelector(
    (state: ApplicationState) => state.global.errorMessage
  );

  useEffect(() => {
    if (errorMessage) {
      setShow(true);
    }
  }, [errorMessage]);

  const dispatch = useDispatch();

  return (
    <div className="errorToast">
      <Toast
        autohide
        delay={+process.env.REACT_APP_LONG_TOAST_DELAY}
        onClose={() => {
          dispatch(clearErrorMessage());
          setShow(false);
        }}
        show={show}
      >
        <Toast.Header>
          <div className="errorToast--header">Adoptech Portal Error</div>
        </Toast.Header>
        <Toast.Body>
          <div className="errorToast--body">{errorMessage}</div>
        </Toast.Body>
      </Toast>
    </div>
  );
};
