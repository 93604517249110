/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ShareSuggestionListMeta
 */
export interface ShareSuggestionListMeta {
  /**
   *
   * @type {number}
   * @memberof ShareSuggestionListMeta
   */
  totalPages?: number | null;
  /**
   *
   * @type {number}
   * @memberof ShareSuggestionListMeta
   */
  totalEntries?: number | null;
  /**
   *
   * @type {number}
   * @memberof ShareSuggestionListMeta
   */
  perPage?: number | null;
  /**
   *
   * @type {number}
   * @memberof ShareSuggestionListMeta
   */
  page?: number;
}

export function ShareSuggestionListMetaFromJSON(
  json: any
): ShareSuggestionListMeta {
  return ShareSuggestionListMetaFromJSONTyped(json, false);
}

export function ShareSuggestionListMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShareSuggestionListMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    totalPages: !exists(json, 'total_pages') ? undefined : json['total_pages'],
    totalEntries: !exists(json, 'total_entries')
      ? undefined
      : json['total_entries'],
    perPage: !exists(json, 'per_page') ? undefined : json['per_page'],
    page: !exists(json, 'page') ? undefined : json['page'],
  };
}

export function ShareSuggestionListMetaToJSON(
  value?: ShareSuggestionListMeta | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total_pages: value.totalPages,
    total_entries: value.totalEntries,
    per_page: value.perPage,
    page: value.page,
  };
}
