/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ChecklistTemplateItem,
  ChecklistTemplateItemFromJSON,
  ChecklistTemplateItemFromJSONTyped,
  ChecklistTemplateItemToJSON,
} from './';

/**
 *
 * @export
 * @interface ChecklistTemplateExtendedAllOfItemsGroups
 */
export interface ChecklistTemplateExtendedAllOfItemsGroups {
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateExtendedAllOfItemsGroups
   */
  questionGroupId?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistTemplateExtendedAllOfItemsGroups
   */
  questionGroupName?: string;
  /**
   *
   * @type {Array<ChecklistTemplateItem>}
   * @memberof ChecklistTemplateExtendedAllOfItemsGroups
   */
  items?: Array<ChecklistTemplateItem>;
}

export function ChecklistTemplateExtendedAllOfItemsGroupsFromJSON(
  json: any
): ChecklistTemplateExtendedAllOfItemsGroups {
  return ChecklistTemplateExtendedAllOfItemsGroupsFromJSONTyped(json, false);
}

export function ChecklistTemplateExtendedAllOfItemsGroupsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistTemplateExtendedAllOfItemsGroups {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    questionGroupId: !exists(json, 'question_group_id')
      ? undefined
      : json['question_group_id'],
    questionGroupName: !exists(json, 'question_group_name')
      ? undefined
      : json['question_group_name'],
    items: !exists(json, 'items')
      ? undefined
      : (json['items'] as Array<any>).map(ChecklistTemplateItemFromJSON),
  };
}

export function ChecklistTemplateExtendedAllOfItemsGroupsToJSON(
  value?: ChecklistTemplateExtendedAllOfItemsGroups | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    question_group_id: value.questionGroupId,
    question_group_name: value.questionGroupName,
    items:
      value.items === undefined
        ? undefined
        : (value.items as Array<any>).map(ChecklistTemplateItemToJSON),
  };
}
