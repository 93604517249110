import React from 'react';
import { ReportForm, ReportSectionProps } from './ReportsManageDrawer';
import { AdoptechTextArea2 } from '../../AdoptechTextArea2/AdoptechTextArea2';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { AdoptechTextInput2 } from '../../AdoptechTextInput2/AdoptechTextInput2';

export const ReportNameField: React.FC<ReportSectionProps> = ({
  formData,
  updateField,
  updateForm,
}) => {
  const hasError = (field: keyof ReportForm) => !formData[field];
  const baseCss = 'reportsManageDrawer';

  return (
    <div className={baseCss + '--fieldRow'}>
      <div className={baseCss + '--label'}>Report name.</div>
      <div className={baseCss + '--field'}>
        <AdoptechTextInput2
          hasError={hasError('name')}
          label="Name"
          id={`${baseCss}Name`}
          type="text"
          value={formData.name}
          onChange={e => updateField('name', e.currentTarget.value)}
          icon={faPen}
        />
      </div>
    </div>
  );
};
