/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  QuestionGroup,
  QuestionGroupFromJSON,
  QuestionGroupFromJSONTyped,
  QuestionGroupToJSON,
  SurveyClause,
  SurveyClauseFromJSON,
  SurveyClauseFromJSONTyped,
  SurveyClauseToJSON,
} from './';

/**
 *
 * @export
 * @interface Survey
 */
export interface Survey {
  /**
   *
   * @type {string}
   * @memberof Survey
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Survey
   */
  status: SurveyStatusEnum;
  /**
   *
   * @type {Array<SurveyClause>}
   * @memberof Survey
   */
  surveyClauses?: Array<SurveyClause>;
  /**
   *
   * @type {string}
   * @memberof Survey
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof Survey
   */
  updatedAt?: string;
  /**
   *
   * @type {Array<QuestionGroup>}
   * @memberof Survey
   */
  questionGroups?: Array<QuestionGroup>;
}

export function SurveyFromJSON(json: any): Survey {
  return SurveyFromJSONTyped(json, false);
}

export function SurveyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Survey {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: json['status'],
    surveyClauses: !exists(json, 'survey_clauses')
      ? undefined
      : (json['survey_clauses'] as Array<any>).map(SurveyClauseFromJSON),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    questionGroups: !exists(json, 'question_groups')
      ? undefined
      : (json['question_groups'] as Array<any>).map(QuestionGroupFromJSON),
  };
}

export function SurveyToJSON(value?: Survey | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: value.status,
    survey_clauses:
      value.surveyClauses === undefined
        ? undefined
        : (value.surveyClauses as Array<any>).map(SurveyClauseToJSON),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    question_groups:
      value.questionGroups === undefined
        ? undefined
        : (value.questionGroups as Array<any>).map(QuestionGroupToJSON),
  };
}

/**
 * @export
 * @enum {string}
 */
export enum SurveyStatusEnum {
  InProgress = 'in_progress',
  Completed = 'completed',
  Closed = 'closed',
}
