import React, { useEffect } from 'react';
import { AdoptechPanel } from '../../../../../../components/AdoptechPanel/AdoptechPanel';
import { faUserCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TrustHubUserAdminApprovedUsers.scss';
import { NoDataText } from '../../../../../../components/NoDataText/NoDataText';
import AdoptechGridTable from '../../../../../../components/AdoptechGridTable/AdoptechGridTable';
import { Grid } from '../../../../../../types/grid';
import { SortableTableHeader } from '../../../../../../components/SortableTableHeader/SortableTableHeader';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { selectCompanyUsers } from '../../selectCompanyUsers';
import { useDispatch, useSelector } from 'react-redux';
import { TrusthubVendorUserModel } from '../../../../../../swagger/trusthub/models/TrusthubVendorUserModel';
import {
  fetchTrustHubCompanyVendorUsers,
  patchTrusthubVendorUser,
} from '../../../../store/trusthubThunks';
import { showGlobalToast } from '../../../../../../store/global/globalSlice';
import { selectCurrentVendor } from '../../../../../../selectors/selectCurrentVendor';
import { trusthubAdminPageUserRoute } from '../../../../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import { useParams } from 'react-router';
import { TrusthubCompanyModel } from '../../../../../../swagger/trusthub/models/TrusthubCompanyModel';
import { ApplicationState } from '../../../../../../types/applicationState';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';

export const TrustHubUserAdminApprovedUsers: React.FC = () => {
  const baseCss = 'trustHubUserAdminApprovedUsers';
  const users = useSelector(selectCompanyUsers);
  const dispatch = useDispatch();
  const { fetchingVendorUsersStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );
  const currentVendor = useSelector(selectCurrentVendor);
  const params = useParams() as { id: TrusthubCompanyModel['id'] };
  useEffect(() => {
    dispatch(
      fetchTrustHubCompanyVendorUsers(
        {
          vendorIdOrDomain: currentVendor?.id,
          trusthubCompanyId: params.id,
        },
        () => {}
      )
    );
  }, []);
  const handleDelete = (user: TrusthubVendorUserModel) => {
    dispatch(
      patchTrusthubVendorUser(
        {
          vendorUserId: user.id,
          body: {
            trusthubVendorUser: {
              trusthubCompanyId: null,
            },
          },
        },
        () =>
          dispatch(
            showGlobalToast(
              'Approved user has been successfully removed from the company'
            )
          )
      )
    );
  };

  if (fetchingVendorUsersStatus === 'loading') return <LoadingSpinner />;

  return (
    <div className={baseCss}>
      <div className={baseCss + '--panel'}>
        <AdoptechPanel padding="24px">
          <div className={baseCss + '--panelTitle'}>
            <div className={baseCss + '--panelInfo'}>
              <FontAwesomeIcon icon={faUserCheck} />
              Approved Users
            </div>
          </div>

          <div className={baseCss + '--panelTable'}>
            {users.length <= 0 ? (
              <NoDataText extraClass="pt-4" text="No users to show" />
            ) : (
              <AdoptechGridTable
                header={
                  <div className={baseCss + '--tableHeader'}>
                    <SortableTableHeader<TrusthubVendorUserModel>
                      columnName="fullName"
                      grid={Grid.TrustHubUserAdminCompanyUsers}
                      label="Name"
                      notInTable
                    />
                    <SortableTableHeader<TrusthubVendorUserModel>
                      columnName="email"
                      grid={Grid.TrustHubUserAdminCompanyUsers}
                      label="Email"
                      notInTable
                    />
                    <div>Action</div>
                  </div>
                }
              >
                {users.map(user => {
                  const { id } = user;
                  return (
                    <div
                      className={baseCss + '--tableRow'}
                      key={id}
                      onClick={() =>
                        dispatch(
                          push(
                            trusthubAdminPageUserRoute.replace(':id', user.id)
                          )
                        )
                      }
                    >
                      <div className={baseCss + '--name'}>{user.fullName}</div>
                      <div className={baseCss + '--email'}>{user.email}</div>
                      <div
                        className={baseCss + '--action'}
                        onClick={e => {
                          e.stopPropagation();
                          handleDelete(user);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  );
                })}
              </AdoptechGridTable>
            )}
          </div>
        </AdoptechPanel>
      </div>
    </div>
  );
};
