import {
  SharedTaskRecurring,
  AgreementModel,
  VendorSupplierExtended,
} from '../../swagger';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { AdoptechDatePicker } from '../AdoptechDatePicker/AdoptechDatePicker';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import { selectCompletedAgreements } from '../../selectors/selectCompletedAgreements';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { fetchAgreements } from '../../store/agreements/agreementsThunks';

interface ContractSectionProps {
  formData: VendorSupplierExtended;
  setFormData: Dispatch<SetStateAction<VendorSupplierExtended>>;
}

interface FrequencySelectionOption {
  value: SharedTaskRecurring;
  label: string;
}

interface ContractSelectionOption {
  value: AgreementModel['id'];
  label: string;
}

export const validation = (
  data: VendorSupplierExtended
): Record<string, string[]> => ({});

export const ContractSection: React.FC<ContractSectionProps> = ({
  formData,
  setFormData,
}) => {
  const baseCss = 'manageSupplierDrawer';
  const id = formData?.id || 'new';

  const agreements = useSelector(selectCompletedAgreements);
  const contractOptions: ContractSelectionOption[] = agreements.map(a => ({
    value: a.id,
    label: a.name,
  }));

  const currentVendor = useSelector(selectCurrentVendor);
  const dispatch = useDispatch();

  const contractRenewalDate = formData.contractRenewalDate
    ? new Date(formData.contractRenewalDate)
    : null;

  useEffect(() => {
    dispatch(fetchAgreements(currentVendor.id));
  }, [currentVendor.id]);

  const frequencyOptions: FrequencySelectionOption[] = [
    {
      value: SharedTaskRecurring.Monthly,
      label: 'Monthly',
    },
    {
      value: SharedTaskRecurring.Quarterly,
      label: 'Quarterly',
    },
    {
      value: SharedTaskRecurring.BiAnnually,
      label: 'Bi-Annual',
    },
    {
      value: SharedTaskRecurring.Annually,
      label: 'Annual',
    },
    {
      value: SharedTaskRecurring.Other,
      label: 'Other',
    },
  ];

  return (
    <Accordion defaultActiveKey="0">
      <AdoptechAccordionCard
        title="Contract"
        index="0"
        cardBodyClass="card-body--centered withBorderBottom"
        headerClass="noBorderBottom positionInitial"
        iconSize="small"
        noMargin
      >
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Recurring</div>
            <AdoptechReactSelect
              id={`vendor-${id}-frequencySelect`}
              isDisabled={false}
              options={frequencyOptions}
              hasError={false}
              onChange={(e: FrequencySelectionOption) => {
                setFormData({
                  ...formData,
                  contractRecurring: e.value,
                });
              }}
              value={frequencyOptions.find(
                o => o.value == formData?.contractRecurring
              )}
              placeholder="Select frequency"
              showUserAvatar
            />
          </div>
          <div className={baseCss + '--field'}>
            <AdoptechDatePicker
              disabled={false}
              labelText="Renewal date"
              labelStyle={baseCss + '--label'}
              outputDate={contractRenewalDate}
              onDateSelect={d => {
                setFormData({
                  ...formData,
                  contractRenewalDate: d.toJSON().split('T')[0],
                });
              }}
              placeHolderText="Select renewal date"
            />
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Linked contract</div>
            <AdoptechReactSelect
              id={`vendor-${id}-agreementSelect`}
              isDisabled={false}
              options={contractOptions}
              hasError={false}
              onChange={(e: ContractSelectionOption) => {
                setFormData({
                  ...formData,
                  contractId: e.value,
                });
              }}
              value={contractOptions.find(o => o.value == formData?.contractId)}
              placeholder="Select agreement"
              showUserAvatar
            />
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Description</div>
            <AdoptechTextArea
              id={`vendor-${id}-contractDescriptionText`}
              value={formData?.contractDescription}
              onChange={e => {
                setFormData({
                  ...formData,
                  contractDescription: e.currentTarget.value,
                });
              }}
              placeholder="Enter details of the contractual review, detail any risks identified or non-conformities with Company policy. It is important to note who signed off any legal risks that were identified."
              rows={2}
            />
          </div>
        </div>
      </AdoptechAccordionCard>
    </Accordion>
  );
};
