/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChecklistCreatePayloadChecklist
 */
export interface ChecklistCreatePayloadChecklist {
  /**
   *
   * @type {string}
   * @memberof ChecklistCreatePayloadChecklist
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistCreatePayloadChecklist
   */
  placement?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistCreatePayloadChecklist
   */
  relatedTo?: ChecklistCreatePayloadChecklistRelatedToEnum;
  /**
   *
   * @type {string}
   * @memberof ChecklistCreatePayloadChecklist
   */
  checkableId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChecklistCreatePayloadChecklist
   */
  checkableType?: ChecklistCreatePayloadChecklistCheckableTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistCreatePayloadChecklist
   */
  common?: boolean;
}

export function ChecklistCreatePayloadChecklistFromJSON(
  json: any
): ChecklistCreatePayloadChecklist {
  return ChecklistCreatePayloadChecklistFromJSONTyped(json, false);
}

export function ChecklistCreatePayloadChecklistFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistCreatePayloadChecklist {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    placement: !exists(json, 'placement') ? undefined : json['placement'],
    relatedTo: !exists(json, 'related_to') ? undefined : json['related_to'],
    checkableId: !exists(json, 'checkable_id')
      ? undefined
      : json['checkable_id'],
    checkableType: !exists(json, 'checkable_type')
      ? undefined
      : json['checkable_type'],
    common: !exists(json, 'common') ? undefined : json['common'],
  };
}

export function ChecklistCreatePayloadChecklistToJSON(
  value?: ChecklistCreatePayloadChecklist | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    placement: value.placement,
    related_to: value.relatedTo,
    checkable_id: value.checkableId,
    checkable_type: value.checkableType,
    common: value.common,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ChecklistCreatePayloadChecklistRelatedToEnum {
  VendorUser = 'vendor_user',
}
/**
 * @export
 * @enum {string}
 */
export enum ChecklistCreatePayloadChecklistCheckableTypeEnum {
  VendorUser = 'VendorUser',
}
