import React from 'react';
import { useSelector } from 'react-redux';
import { VendorDocumentWithCategoryName } from '../../../selectors/selectLivePolicies';
import { selectPendingApprovalPolicies } from '../../../selectors/selectPendingApprovalPolicies';
import { Grid } from '../../../types/grid';
import AdoptechGridTable from '../../../components/AdoptechGridTable/AdoptechGridTable';
import { Lozenge } from '../../../components/Lozenge/Lozenge';
import { NoDataText } from '../../../components/NoDataText/NoDataText';
import { SortableTableHeader } from '../../../components/SortableTableHeader/SortableTableHeader';
import { PendingApprovalPolicyRow } from './PendingApprovalPolicyRow';
import './PendingApprovalPolicies.scss';
import { canIndex } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';

// only for pending approvals, not for draft ( we cannot share draft via "sharing modal")
const useHideNotLivePolicies = (count: number) => {
  const canSeePolicyCategories = canIndex(AccessObject.policy_categories);
  // if we cannot see categories ( we are not policy editor ) and we don't have
  // shared policies, hide this section
  return count === 0 && !canSeePolicyCategories;
};

export const PendingApprovalPolicies: React.FC = () => {
  const baseTableCss = 'adoptechGridTable';
  const baseCss = 'policyGrid';

  const vendorDocumentsPendingApproval = useSelector(
    selectPendingApprovalPolicies
  );

  const count = vendorDocumentsPendingApproval.length;
  if (useHideNotLivePolicies(count)) return null;

  return (
    <div className="pendingApprovalPolicies">
      <div className="pendingApprovalPolicies--header">
        <div className="pendingApprovalPolicies--title">
          Approvals
          <Lozenge value={count} />
        </div>
      </div>
      {count > 0 ? (
        <React.Fragment>
          <div className={baseCss + ' ' + baseCss + '--7-columns'}>
            <AdoptechGridTable
              header={
                <div className={baseTableCss + '--header'}>
                  <SortableTableHeader<VendorDocumentWithCategoryName>
                    columnName="name"
                    grid={Grid.PendingApprovalPolicies}
                    label="Name"
                    className={baseCss + '--name'}
                    notInTable
                  />
                  <div>Category</div>
                  <div>Owner</div>
                  <div>Status</div>
                  <div>Approvers</div>
                  <div></div>
                  <div></div>
                </div>
              }
            >
              {vendorDocumentsPendingApproval.map(
                (policy: VendorDocumentWithCategoryName) => {
                  return (
                    <PendingApprovalPolicyRow
                      key={policy.id}
                      baseCss={baseCss}
                      vendorDocument={policy}
                      baseTableCss={baseTableCss}
                    />
                  );
                }
              )}
            </AdoptechGridTable>
          </div>
        </React.Fragment>
      ) : (
        <NoDataText text="Policies sent for approval will appear here" />
      )}
    </div>
  );
};
