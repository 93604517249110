import React from 'react';
import './VendorSupplierLogo.scss';

import adobeLogo from 'images/suppliers/adobe.svg';
import adoptechLogo from 'images/suppliers/adoptech.svg';
import asanaLogo from 'images/suppliers/asana.svg';
import atlassianLogo from 'images/suppliers/atlassian.svg';
import awsLogo from 'images/suppliers/aws.png';
import breatheLogo from 'images/suppliers/breathe.png';
import cartaLogo from 'images/suppliers/carta.png';
import cassiniLogo from 'images/suppliers/cassini.png';
import cloudflareLogo from 'images/suppliers/cloudflare.svg';
import curveLogo from 'images/suppliers/curve.png';
import cyberSmartLogo from 'images/suppliers/cyberSmart.png';
import datadogLogo from 'images/suppliers/datadog.svg';
import deelLogo from 'images/suppliers/deel.svg';
import docusignLogo from 'images/suppliers/docusign.png';
import dropboxLogo from 'images/suppliers/dropbox.svg';
import figmaLogo from 'images/suppliers/figma.svg';
import fontawesomeLogo from 'images/suppliers/fontawesome.svg';
import freshworksLogo from 'images/suppliers/freshworks.svg';
import froalaLogo from 'images/suppliers/froala.png';
import gitHubLogo from 'images/suppliers/gitHub.svg';
import goDaddyLogo from 'images/suppliers/goDaddy.svg';
import googleLogo from 'images/suppliers/googleSuite.png';
import hubspotLogo from 'images/suppliers/hubspot.svg';
import intercomLogo from 'images/suppliers/intercom.svg';
import jiraLogo from 'images/suppliers/jira.svg';
import mailchimpLogo from 'images/suppliers/mailchimp.svg';
import microsoftLogo from 'images/suppliers/microsoft.svg';
import oceanLogo from 'images/suppliers/ocean.png';
import onePasswordLogo from 'images/suppliers/1password.svg';
import oracleLogo from 'images/suppliers/oracle.svg';
import salesforceLogo from 'images/suppliers/salesforce.svg';
import seedLegalsLogo from 'images/suppliers/seedLegals.png';
import senchaLogo from 'images/suppliers/sencha.svg';
import sendGridLogo from 'images/suppliers/sendGrid.svg';
import sentryLogo from 'images/suppliers/sentry.svg';
import servicenowLogo from 'images/suppliers/servicenow.svg';
import shopifyLogo from 'images/suppliers/shopify.svg';
import slackLogo from 'images/suppliers/slack.svg';
import stripeLogo from 'images/suppliers/stripe.png';
import surveymonkeyLogo from 'images/suppliers/surveymonkey.svg';
import twilioLogo from 'images/suppliers/twilio.svg';
import wordpressLogo from 'images/suppliers/wordpress.svg';
import workdayLogo from 'images/suppliers/workday.svg';
import xeroLogo from 'images/suppliers/xero.svg';
import zendeskLogo from 'images/suppliers/zendesk.svg';
import zoomLogo from 'images/suppliers/zoom.svg';

interface Props {
  name: string;
}

interface Supplier {
  name: string;
  logo: any;
}

export const supportedSuppliers = [
  { name: '1Password', logo: onePasswordLogo },
  { name: 'Adobe', logo: adobeLogo },
  { name: 'Adoptech', logo: adoptechLogo },
  { name: 'Amazon Web Services', logo: awsLogo },
  { name: 'Asana', logo: asanaLogo },
  { name: 'Atlassian', logo: atlassianLogo },
  { name: 'Breathe', logo: breatheLogo },
  { name: 'Carta', logo: cartaLogo },
  { name: 'Cassini System Ltd', logo: cassiniLogo },
  { name: 'Cloudflare', logo: cloudflareLogo },
  { name: 'Curve', logo: curveLogo },
  { name: 'CyberSmart', logo: cyberSmartLogo },
  { name: 'Datadog', logo: datadogLogo },
  { name: 'Deel', logo: deelLogo },
  { name: 'DocuSign', logo: docusignLogo },
  { name: 'Dropbox', logo: dropboxLogo },
  { name: 'Figma', logo: figmaLogo },
  { name: 'FontAwesome', logo: fontawesomeLogo },
  { name: 'Freshworks', logo: freshworksLogo },
  { name: 'Froala', logo: froalaLogo },
  { name: 'GitHub', logo: gitHubLogo },
  { name: 'GoDaddy', logo: goDaddyLogo },
  { name: 'Google Suite', logo: googleLogo },
  { name: 'HubSpot', logo: hubspotLogo },
  { name: 'Intercom, Inc.', logo: intercomLogo },
  { name: 'Jira', logo: jiraLogo },
  { name: 'Mailchimp', logo: mailchimpLogo },
  { name: 'Microsoft', logo: microsoftLogo },
  { name: 'Ocean.io', logo: oceanLogo },
  { name: 'Oracle', logo: oracleLogo },
  { name: 'SalesForce', logo: salesforceLogo },
  { name: 'SeedLegals', logo: seedLegalsLogo },
  { name: 'Sencha', logo: senchaLogo },
  { name: 'SendGrid', logo: sendGridLogo },
  { name: 'Sentry', logo: sentryLogo },
  { name: 'ServiceNow', logo: servicenowLogo },
  { name: 'Shopify', logo: shopifyLogo },
  { name: 'Slack', logo: slackLogo },
  { name: 'Stripe', logo: stripeLogo },
  { name: 'SurveyMonkey', logo: surveymonkeyLogo },
  { name: 'Twilio', logo: twilioLogo },
  { name: 'WordPress', logo: wordpressLogo },
  { name: 'Workday, Inc.', logo: workdayLogo },
  { name: 'Xero', logo: xeroLogo },
  { name: 'Zendesk', logo: zendeskLogo },
  { name: 'Zoom', logo: zoomLogo },
];

export const VendorSupplierLogo: React.FC<Props> = ({ name }) => {
  const supplier: Supplier = supportedSuppliers.find(
    (sp: Supplier) => sp.name === name
  );
  const logoImage = supplier?.logo;

  if (!logoImage) return <div>{name}</div>;

  return (
    <>
      <div className="vendorSupplierLogoContainer">
        <img alt={name} src={logoImage} />
      </div>
      <div>{name}</div>
    </>
  );
};
