import { getMetaContent } from '../functions/getMetaContent';
import { Configuration, HTTPHeaders } from '../swagger';
import { Configuration as TrustHubConfiguration } from '../swagger/trusthub';

import { DefaultApi as Api } from '../swagger/apis';
import { DefaultApi as TrustHubApi } from '../swagger/trusthub/apis';

const configuration = (additionalHeaders?: HTTPHeaders) => {
  const csrfHeader = { 'X-CSRF-Token': getMetaContent('csrf-token') };
  const headers = Object.assign({}, csrfHeader, additionalHeaders);
  return new Configuration({
    basePath: `${window.location.origin}/api/v1`,
    headers,
  });
};

export const TRUSTHUB_BASE_PATH = '/trusthub/api/v1';

const TrustHubConfig = (additionalHeaders?: HTTPHeaders) => {
  const csrfHeader = { 'X-CSRF-Token': getMetaContent('csrf-token') };
  const headers = Object.assign({}, csrfHeader, additionalHeaders);
  return new TrustHubConfiguration({
    basePath: `${window.location.origin}${TRUSTHUB_BASE_PATH}`,
    headers,
  });
};
export const api = (additionalHeaders?: HTTPHeaders) =>
  new Api(configuration(additionalHeaders));

export const trusthubApi = (additionalHeaders?: HTTPHeaders) =>
  new TrustHubApi(TrustHubConfig(additionalHeaders));
