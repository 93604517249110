import { api } from '../../api/api';
import { getApiErrorMessage } from '../../functions/getApiErrorMessage';
import {
  notificationsRequest,
  notificationsRequestSuccess,
  notificationsRequestFailure,
  markNotificationAsReadFailure,
  createNotificationRequest,
  createNotificationRequestFailure,
} from './notificationSlice';
import { VoidThunk } from '../../types/voidThunk';
import { UNKNOWN_ERROR } from '../../types/constants';
import { ReminderCreatePayloadReminder } from '../../swagger';

export const fetchNotifications =
  (vendorUserId: string, showRead: boolean): VoidThunk =>
  async dispatch => {
    try {
      dispatch(notificationsRequest());
      const response = await api().vendorUsersVendorUserIdNotificationsGet({
        vendorUserId,
        showRead,
      });
      dispatch(notificationsRequestSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(notificationsRequestFailure(apiErrorMessage));
        } else {
          dispatch(
            notificationsRequestFailure(
              'An error occurred fetching notifications. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(notificationsRequestFailure(UNKNOWN_ERROR));
      }
    }
  };

export const markNotificationAsRead =
  ({
    notificationId,
    onSuccess,
  }: {
    notificationId: string;
    onSuccess: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      await api().notificationsNotificationIdReadPatch({ notificationId });
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(markNotificationAsReadFailure(apiErrorMessage));
        } else {
          dispatch(
            markNotificationAsReadFailure(
              'An error occurred marking notification as read. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(markNotificationAsReadFailure(UNKNOWN_ERROR));
      }
    }
  };

export const createNotification =
  ({
    vendorUserId,
    reminder,
    onSuccess,
  }: {
    vendorUserId: string;
    reminder: ReminderCreatePayloadReminder;
    onSuccess: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(createNotificationRequest(true));
      await api().vendorUsersVendorUserIdRemindersPost({
        vendorUserId,
        body: { reminder },
      });
      dispatch(createNotificationRequest(false));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(createNotificationRequestFailure(apiErrorMessage));
        } else {
          dispatch(
            createNotificationRequestFailure(
              'An error occurred creating a notification. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(createNotificationRequestFailure(UNKNOWN_ERROR));
      }
    }
  };
