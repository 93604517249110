import { createSelector } from '@reduxjs/toolkit';
import { Checklist } from '../swagger';
import { ApplicationState } from '../types/applicationState';
import { COMPLETED_TEXT } from '../types/constants';

export const selectCompletedChecklists = createSelector(
  (state: ApplicationState) => state.checklists.checklists,
  checklists =>
    checklists.filter(
      (list: Checklist) =>
        (list.status === COMPLETED_TEXT || list.status === 'closed') &&
        list.visibleInLists
    )
);
