import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import { AdoptechTooltip } from '../AdoptechTooltip/AdoptechTooltip';
import './AdoptechSliderSwitcher.scss';

type Values = string[];

interface TooltipOption {
  enabled: boolean;
  text?: string;
}

interface SliderProps {
  values: Values;
  labels?: Values;
  selected: Values[0];
  setSelected: Dispatch<SetStateAction<string>>;
  tooltipsOptions?: TooltipOption[];
  onClickHandlers?: ((value: Values[0]) => void)[];
  fieldWidth?: number;
}

export const AdoptechSliderSwitcher: React.FC<SliderProps> = ({
  values,
  labels,
  selected,
  setSelected,
  tooltipsOptions,
  onClickHandlers,
  fieldWidth,
}) => {
  const baseCss = 'adoptechSliderSwitcher';

  const backgroundOffset = (selectedValue: Values[0]) => {
    const roundedBorderOffset = 3;
    const width = fieldWidth || (values.length === 2 ? 80 : 100);
    const index = values.indexOf(selectedValue);

    if (index == 0) return 0 + roundedBorderOffset;
    return width * index - roundedBorderOffset;
  };

  const style = { left: backgroundOffset(selected) };

  return (
    <div
      className={classNames(
        baseCss,
        `${baseCss}--${values.length}-fields`,
        `${baseCss}--${selected}Selected`
      )}
    >
      <div className={baseCss + '--fields'}>
        {values.map((value, index) => {
          const customClickHandler = (onClickHandlers || [])[index];

          const fieldClass = value === selected ? '-selected' : '';
          const labelDiv = (
            <div
              key={value}
              onClick={() => {
                customClickHandler
                  ? customClickHandler(value)
                  : setSelected(value);
              }}
              className={
                baseCss + '--field' + ' ' + baseCss + '--field' + fieldClass
              }
            >
              <span className={baseCss + '--label'}>
                {labels ? labels[index] : value}
              </span>
            </div>
          );
          const tooltip = (tooltipsOptions || [])[index];

          return tooltip?.enabled ? (
            <AdoptechTooltip
              showTooltip
              text={tooltip.text}
              identifier={value}
              placement="bottom"
            >
              {labelDiv}
            </AdoptechTooltip>
          ) : (
            labelDiv
          );
        })}
      </div>
      {/* needed for applying css transition effect */}
      <div className={baseCss + '--selectedBackground'} style={style}></div>
    </div>
  );
};
