import { faEllipsisH } from '@fortawesome/pro-light-svg-icons/faEllipsisH';
import classNames from 'classnames';
import React, { useState, useRef } from 'react';
import { ButtonGroup, Dropdown, Overlay } from 'react-bootstrap';
import { IconButton } from '../IconButton/IconButton';
import './MeatballMenu.scss';
import { AdoptechDropdownToggle } from '../AdoptechDropdownToggle/AdoptechDropdownToggle';

export interface MeatballMenuProps {
  leftHandPointer?: boolean;
  popperStrategy?: 'absolute' | 'fixed';
}

export const MeatballMenu: React.FC<MeatballMenuProps> = props => {
  const [open, setOpen] = useState(false);

  const meatballMenuButtonClasses = classNames({
    'meatballMenu--button': true,
    'meatballMenu--button-open': open,
  });
  const classes = classNames(
    'meatballMenu--pointer',
    props.leftHandPointer
      ? 'meatballMenu--pointer-lhs'
      : 'meatballMenu--pointer-rhs'
  );

  const target = useRef(null);

  return (
    <div className="meatballMenu">
      <Dropdown as={ButtonGroup} onToggle={isOpen => setOpen(isOpen)}>
        <Dropdown.Toggle as={AdoptechDropdownToggle} ref={target}>
          <IconButton
            className={meatballMenuButtonClasses}
            ariaLabel="Open menu"
            icon={faEllipsisH}
          />
        </Dropdown.Toggle>
        <Overlay target={target.current} show={open}>
          <Dropdown.Menu
            className="meatballMenu"
            popperConfig={{
              strategy: props.popperStrategy ? props.popperStrategy : 'fixed',
            }}
          >
            <>
              <div className={classes} />
              {props.children}
            </>
          </Dropdown.Menu>
        </Overlay>
      </Dropdown>
    </div>
  );
};
