/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  AttestationFrequencyEnum,
  AttestationFrequencyEnumFromJSON,
  AttestationFrequencyEnumFromJSONTyped,
  AttestationFrequencyEnumToJSON,
  ReviewFrequencyEnum,
  ReviewFrequencyEnumFromJSON,
  ReviewFrequencyEnumFromJSONTyped,
  ReviewFrequencyEnumToJSON,
  VendorDocumentApproval,
  VendorDocumentApprovalFromJSON,
  VendorDocumentApprovalFromJSONTyped,
  VendorDocumentApprovalToJSON,
  VendorDocumentApprovalStats,
  VendorDocumentApprovalStatsFromJSON,
  VendorDocumentApprovalStatsFromJSONTyped,
  VendorDocumentApprovalStatsToJSON,
  VendorDocumentAttestation,
  VendorDocumentAttestationFromJSON,
  VendorDocumentAttestationFromJSONTyped,
  VendorDocumentAttestationToJSON,
  VendorDocumentAttestationStats,
  VendorDocumentAttestationStatsFromJSON,
  VendorDocumentAttestationStatsFromJSONTyped,
  VendorDocumentAttestationStatsToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorDocument
 */
export interface VendorDocument {
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  relativePath?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  vendorId?: string;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  policyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  policyCategoryId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  status?: VendorDocumentStatusEnum;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  documentType?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  documentableId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  version?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof VendorDocument
   */
  live?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  versionBump?: VendorDocumentVersionBumpEnum;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  changeReason?: string;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  approvalMessage?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof VendorDocument
   */
  needToAttest?: boolean | null;
  /**
   *
   * @type {AttestationFrequencyEnum}
   * @memberof VendorDocument
   */
  attestationFrequency?: AttestationFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  attestationMessage?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  attestationDueAt?: string | null;
  /**
   *
   * @type {VendorDocumentAttestation}
   * @memberof VendorDocument
   */
  vendorDocumentAttestation?: VendorDocumentAttestation;
  /**
   *
   * @type {VendorDocumentApproval}
   * @memberof VendorDocument
   */
  vendorDocumentApproval?: VendorDocumentApproval;
  /**
   *
   * @type {Access}
   * @memberof VendorDocument
   */
  access?: Access;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  accessGroupId?: string | null;
  /**
   *
   * @type {VendorUser}
   * @memberof VendorDocument
   */
  owner?: VendorUser;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  ownerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  updatedAt?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof VendorDocument
   */
  archived?: boolean | null;
  /**
   *
   * @type {ReviewFrequencyEnum}
   * @memberof VendorDocument
   */
  reviewFrequency?: ReviewFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof VendorDocument
   */
  nextReviewDate?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof VendorDocument
   */
  reviewOverdue?: boolean | null;
  /**
   *
   * @type {VendorDocumentApprovalStats}
   * @memberof VendorDocument
   */
  approvalStats?: VendorDocumentApprovalStats;
  /**
   *
   * @type {VendorDocumentAttestationStats}
   * @memberof VendorDocument
   */
  attestationStats?: VendorDocumentAttestationStats;
}

export function VendorDocumentFromJSON(json: any): VendorDocument {
  return VendorDocumentFromJSONTyped(json, false);
}

export function VendorDocumentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorDocument {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    relativePath: !exists(json, 'relative_path')
      ? undefined
      : json['relative_path'],
    vendorId: !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
    policyId: !exists(json, 'policy_id') ? undefined : json['policy_id'],
    policyCategoryId: !exists(json, 'policy_category_id')
      ? undefined
      : json['policy_category_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    description: !exists(json, 'description') ? undefined : json['description'],
    documentType: !exists(json, 'document_type')
      ? undefined
      : json['document_type'],
    documentableId: !exists(json, 'documentable_id')
      ? undefined
      : json['documentable_id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    live: !exists(json, 'live') ? undefined : json['live'],
    versionBump: !exists(json, 'version_bump')
      ? undefined
      : json['version_bump'],
    changeReason: !exists(json, 'change_reason')
      ? undefined
      : json['change_reason'],
    approvalMessage: !exists(json, 'approval_message')
      ? undefined
      : json['approval_message'],
    needToAttest: !exists(json, 'need_to_attest')
      ? undefined
      : json['need_to_attest'],
    attestationFrequency: !exists(json, 'attestation_frequency')
      ? undefined
      : AttestationFrequencyEnumFromJSON(json['attestation_frequency']),
    attestationMessage: !exists(json, 'attestation_message')
      ? undefined
      : json['attestation_message'],
    attestationDueAt: !exists(json, 'attestation_due_at')
      ? undefined
      : json['attestation_due_at'],
    vendorDocumentAttestation: !exists(json, 'vendor_document_attestation')
      ? undefined
      : VendorDocumentAttestationFromJSON(json['vendor_document_attestation']),
    vendorDocumentApproval: !exists(json, 'vendor_document_approval')
      ? undefined
      : VendorDocumentApprovalFromJSON(json['vendor_document_approval']),
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    accessGroupId: !exists(json, 'access_group_id')
      ? undefined
      : json['access_group_id'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    archived: !exists(json, 'archived') ? undefined : json['archived'],
    reviewFrequency: !exists(json, 'review_frequency')
      ? undefined
      : ReviewFrequencyEnumFromJSON(json['review_frequency']),
    nextReviewDate: !exists(json, 'next_review_date')
      ? undefined
      : json['next_review_date'],
    reviewOverdue: !exists(json, 'review_overdue')
      ? undefined
      : json['review_overdue'],
    approvalStats: !exists(json, 'approval_stats')
      ? undefined
      : VendorDocumentApprovalStatsFromJSON(json['approval_stats']),
    attestationStats: !exists(json, 'attestation_stats')
      ? undefined
      : VendorDocumentAttestationStatsFromJSON(json['attestation_stats']),
  };
}

export function VendorDocumentToJSON(value?: VendorDocument | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    relative_path: value.relativePath,
    vendor_id: value.vendorId,
    policy_id: value.policyId,
    policy_category_id: value.policyCategoryId,
    name: value.name,
    status: value.status,
    description: value.description,
    document_type: value.documentType,
    documentable_id: value.documentableId,
    version: value.version,
    live: value.live,
    version_bump: value.versionBump,
    change_reason: value.changeReason,
    approval_message: value.approvalMessage,
    need_to_attest: value.needToAttest,
    attestation_frequency: AttestationFrequencyEnumToJSON(
      value.attestationFrequency
    ),
    attestation_message: value.attestationMessage,
    attestation_due_at: value.attestationDueAt,
    vendor_document_attestation: VendorDocumentAttestationToJSON(
      value.vendorDocumentAttestation
    ),
    vendor_document_approval: VendorDocumentApprovalToJSON(
      value.vendorDocumentApproval
    ),
    access: AccessToJSON(value.access),
    access_group_id: value.accessGroupId,
    owner: VendorUserToJSON(value.owner),
    owner_id: value.ownerId,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    archived: value.archived,
    review_frequency: ReviewFrequencyEnumToJSON(value.reviewFrequency),
    next_review_date: value.nextReviewDate,
    review_overdue: value.reviewOverdue,
    approval_stats: VendorDocumentApprovalStatsToJSON(value.approvalStats),
    attestation_stats: VendorDocumentAttestationStatsToJSON(
      value.attestationStats
    ),
  };
}

/**
 * @export
 * @enum {string}
 */
export enum VendorDocumentStatusEnum {
  Approval = 'approval',
  Attestation = 'attestation',
  Complete = 'complete',
  Rejected = 'rejected',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorDocumentVersionBumpEnum {
  Minor = 'minor',
  Major = 'major',
}
