import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import './../../compliance/dataRoom/DataRoomTabs/PageTabs.scss';
import {
  allDocumentsPageRoute,
  archivedDocumentsPageRoute,
  draftPoliciesPageRoute,
  policiesPageRoute,
} from '../../../components/Routes/Routes';
import { capitalize } from '../../../functions/capitalize';
import { canCreate, useCanFeature, canIndex } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { setPageHeaderLabel } from '../../../store/global/globalSlice';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { faCloudUploadAlt } from '@fortawesome/pro-light-svg-icons/faCloudUploadAlt';
import { showDocumentUploadModal } from '../../../store/vendors/vendorsSlice';

interface Props {
  currentTab: 'live' | 'draft' | 'archived' | 'all';
}

export const PoliciesTabs: React.FC<Props> = ({ currentTab }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageHeaderLabel('Policies and Docs'));
  }, []);
  const goToLive = () => dispatch(push(policiesPageRoute));
  const goToArchived = () => dispatch(push(archivedDocumentsPageRoute));
  const goToAll = () => dispatch(push(allDocumentsPageRoute));
  const goToDraft = () => dispatch(push(draftPoliciesPageRoute));
  const canViewCompanyDocuments = useCanFeature(AccessObject.company_documents);
  const canManageDocuments = canCreate(AccessObject.vendor_documents);
  const canViewDraftPolicies = canIndex(AccessObject.policy_categories);
  return (
    <>
      <div className="pageTabs">
        <div
          className={classNames('pageTabs--tab', {
            active: currentTab === 'live',
          })}
          onClick={goToLive}
        >
          Live
        </div>
        {canViewDraftPolicies && (
          <div
            className={classNames('pageTabs--tab', {
              active: currentTab === 'draft',
            })}
            onClick={goToDraft}
          >
            Draft
          </div>
        )}
        {canViewCompanyDocuments && (
          <div
            className={classNames('pageTabs--tab', {
              active: currentTab == 'all',
            })}
            onClick={goToAll}
          >
            All Docs
          </div>
        )}
        {canViewCompanyDocuments && (
          <div
            className={classNames('pageTabs--tab', {
              active: currentTab === 'archived',
            })}
            onClick={goToArchived}
          >
            Archived
          </div>
        )}
      </div>
      <div className="pageTabs--actions">
        {currentTab === 'all' && canManageDocuments && (
          <AdoptechButton
            onClick={() => dispatch(showDocumentUploadModal())}
            variant={AdoptechButtonVariant.PrimaryOrange}
            icon={faCloudUploadAlt}
            iconSize="large"
            rounded
          >
            Upload
          </AdoptechButton>
        )}
      </div>
    </>
  );
};
