/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  FrameworkRequirementCategoryExtendedAllOf,
  FrameworkRequirementCategoryExtendedAllOfFromJSON,
  FrameworkRequirementCategoryExtendedAllOfFromJSONTyped,
  FrameworkRequirementCategoryExtendedAllOfToJSON,
  FrameworkRequirementCategoryModel,
  FrameworkRequirementCategoryModelFromJSON,
  FrameworkRequirementCategoryModelFromJSONTyped,
  FrameworkRequirementCategoryModelToJSON,
  FrameworkRequirementExtended,
  FrameworkRequirementExtendedFromJSON,
  FrameworkRequirementExtendedFromJSONTyped,
  FrameworkRequirementExtendedToJSON,
} from './';

/**
 *
 * @export
 * @interface FrameworkRequirementCategoryExtended
 */
export interface FrameworkRequirementCategoryExtended
  extends FrameworkRequirementCategoryModel {
  /**
   *
   * @type {Array<FrameworkRequirementExtended>}
   * @memberof FrameworkRequirementCategoryExtended
   */
  frameworkRequirements: Array<FrameworkRequirementExtended>;
}

export function FrameworkRequirementCategoryExtendedFromJSON(
  json: any
): FrameworkRequirementCategoryExtended {
  return FrameworkRequirementCategoryExtendedFromJSONTyped(json, false);
}

export function FrameworkRequirementCategoryExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkRequirementCategoryExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...FrameworkRequirementCategoryModelFromJSONTyped(
      json,
      ignoreDiscriminator
    ),
    frameworkRequirements: (json['framework_requirements'] as Array<any>).map(
      FrameworkRequirementExtendedFromJSON
    ),
  };
}

export function FrameworkRequirementCategoryExtendedToJSON(
  value?: FrameworkRequirementCategoryExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...FrameworkRequirementCategoryModelToJSON(value),
    framework_requirements: (value.frameworkRequirements as Array<any>).map(
      FrameworkRequirementExtendedToJSON
    ),
  };
}
