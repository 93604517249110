/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DashboardSummaryWidgets,
  DashboardSummaryWidgetsFromJSON,
  DashboardSummaryWidgetsFromJSONTyped,
  DashboardSummaryWidgetsToJSON,
} from './';

/**
 *
 * @export
 * @interface DashboardSummary
 */
export interface DashboardSummary {
  /**
   *
   * @type {Array<DashboardSummaryWidgets>}
   * @memberof DashboardSummary
   */
  widgets?: Array<DashboardSummaryWidgets>;
}

export function DashboardSummaryFromJSON(json: any): DashboardSummary {
  return DashboardSummaryFromJSONTyped(json, false);
}

export function DashboardSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DashboardSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    widgets: !exists(json, 'widgets')
      ? undefined
      : (json['widgets'] as Array<any>).map(DashboardSummaryWidgetsFromJSON),
  };
}

export function DashboardSummaryToJSON(value?: DashboardSummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    widgets:
      value.widgets === undefined
        ? undefined
        : (value.widgets as Array<any>).map(DashboardSummaryWidgetsToJSON),
  };
}
