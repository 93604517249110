/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsFromJSONTyped,
  UserDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface AgreementSignatoryModel
 */
export interface AgreementSignatoryModel {
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  status: AgreementSignatoryModelStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  type: AgreementSignatoryModelTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  email: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  rejectionReason?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  companyAddress?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  companyNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  lastName?: string | null;
  /**
   *
   * @type {UserDetails}
   * @memberof AgreementSignatoryModel
   */
  user?: UserDetails;
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryModel
   */
  document?: string | null;
}

export function AgreementSignatoryModelFromJSON(
  json: any
): AgreementSignatoryModel {
  return AgreementSignatoryModelFromJSONTyped(json, false);
}

export function AgreementSignatoryModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementSignatoryModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: json['status'],
    type: json['type'],
    email: json['email'],
    rejectionReason: !exists(json, 'rejection_reason')
      ? undefined
      : json['rejection_reason'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
    companyName: !exists(json, 'company_name')
      ? undefined
      : json['company_name'],
    companyAddress: !exists(json, 'company_address')
      ? undefined
      : json['company_address'],
    companyNumber: !exists(json, 'company_number')
      ? undefined
      : json['company_number'],
    address: !exists(json, 'address') ? undefined : json['address'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    user: !exists(json, 'user') ? undefined : UserDetailsFromJSON(json['user']),
    document: !exists(json, 'document') ? undefined : json['document'],
  };
}

export function AgreementSignatoryModelToJSON(
  value?: AgreementSignatoryModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: value.status,
    type: value.type,
    email: value.email,
    rejection_reason: value.rejectionReason,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    company_name: value.companyName,
    company_address: value.companyAddress,
    company_number: value.companyNumber,
    address: value.address,
    first_name: value.firstName,
    last_name: value.lastName,
    user: UserDetailsToJSON(value.user),
    document: value.document,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AgreementSignatoryModelStatusEnum {
  AwaitingSignature = 'awaiting_signature',
  Rejected = 'rejected',
  Signed = 'signed',
  SignedOffline = 'signed_offline',
  NotRequired = 'not_required',
}
/**
 * @export
 * @enum {string}
 */
export enum AgreementSignatoryModelTypeEnum {
  ExternalCompany = 'AgreementSignatory::ExternalCompany',
  ExternalIndividual = 'AgreementSignatory::ExternalIndividual',
  Internal = 'AgreementSignatory::Internal',
}
