/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RiskCreatePayloadRisk
 */
export interface RiskCreatePayloadRisk {
  /**
   *
   * @type {string}
   * @memberof RiskCreatePayloadRisk
   */
  riskTemplateId?: string;
  /**
   *
   * @type {string}
   * @memberof RiskCreatePayloadRisk
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RiskCreatePayloadRisk
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskCreatePayloadRisk
   */
  riskCategoryId?: string;
}

export function RiskCreatePayloadRiskFromJSON(
  json: any
): RiskCreatePayloadRisk {
  return RiskCreatePayloadRiskFromJSONTyped(json, false);
}

export function RiskCreatePayloadRiskFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskCreatePayloadRisk {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    riskTemplateId: !exists(json, 'risk_template_id')
      ? undefined
      : json['risk_template_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    riskCategoryId: !exists(json, 'risk_category_id')
      ? undefined
      : json['risk_category_id'],
  };
}

export function RiskCreatePayloadRiskToJSON(
  value?: RiskCreatePayloadRisk | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    risk_template_id: value.riskTemplateId,
    name: value.name,
    description: value.description,
    risk_category_id: value.riskCategoryId,
  };
}
