/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedPaginationMeta,
  SharedPaginationMetaFromJSON,
  SharedPaginationMetaFromJSONTyped,
  SharedPaginationMetaToJSON,
  TrusthubVendorUserActivityModel,
  TrusthubVendorUserActivityModelFromJSON,
  TrusthubVendorUserActivityModelFromJSONTyped,
  TrusthubVendorUserActivityModelToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubVendorUserActivityList
 */
export interface TrusthubVendorUserActivityList {
  /**
   *
   * @type {Array<TrusthubVendorUserActivityModel>}
   * @memberof TrusthubVendorUserActivityList
   */
  data: Array<TrusthubVendorUserActivityModel>;
  /**
   *
   * @type {SharedPaginationMeta}
   * @memberof TrusthubVendorUserActivityList
   */
  meta: SharedPaginationMeta;
}

export function TrusthubVendorUserActivityListFromJSON(
  json: any
): TrusthubVendorUserActivityList {
  return TrusthubVendorUserActivityListFromJSONTyped(json, false);
}

export function TrusthubVendorUserActivityListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubVendorUserActivityList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(
      TrusthubVendorUserActivityModelFromJSON
    ),
    meta: SharedPaginationMetaFromJSON(json['meta']),
  };
}

export function TrusthubVendorUserActivityListToJSON(
  value?: TrusthubVendorUserActivityList | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(TrusthubVendorUserActivityModelToJSON),
    meta: SharedPaginationMetaToJSON(value.meta),
  };
}
