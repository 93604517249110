/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  AgreementExtendedAllOf,
  AgreementExtendedAllOfFromJSON,
  AgreementExtendedAllOfFromJSONTyped,
  AgreementExtendedAllOfToJSON,
  AgreementModel,
  AgreementModelFromJSON,
  AgreementModelFromJSONTyped,
  AgreementModelToJSON,
  AgreementModelSignatoriesStats,
  AgreementModelSignatoriesStatsFromJSON,
  AgreementModelSignatoriesStatsFromJSONTyped,
  AgreementModelSignatoriesStatsToJSON,
  AgreementSignatoryModel,
  AgreementSignatoryModelFromJSON,
  AgreementSignatoryModelFromJSONTyped,
  AgreementSignatoryModelToJSON,
  Survey,
  SurveyFromJSON,
  SurveyFromJSONTyped,
  SurveyToJSON,
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsFromJSONTyped,
  UserDetailsToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface AgreementExtended
 */
export interface AgreementExtended extends AgreementModel {
  /**
   *
   * @type {Survey}
   * @memberof AgreementExtended
   */
  survey: Survey;
  /**
   *
   * @type {UserDetails}
   * @memberof AgreementExtended
   */
  updatedBy?: UserDetails;
  /**
   *
   * @type {string}
   * @memberof AgreementExtended
   */
  content?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AgreementExtended
   */
  contentIsCustom?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AgreementExtended
   */
  useBrandingColor?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AgreementExtended
   */
  useCompanyLogo?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof AgreementExtended
   */
  modesAvailable?: Array<AgreementExtendedModesAvailableEnum>;
}

export function AgreementExtendedFromJSON(json: any): AgreementExtended {
  return AgreementExtendedFromJSONTyped(json, false);
}

export function AgreementExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...AgreementModelFromJSONTyped(json, ignoreDiscriminator),
    survey: SurveyFromJSON(json['survey']),
    updatedBy: !exists(json, 'updated_by')
      ? undefined
      : UserDetailsFromJSON(json['updated_by']),
    content: !exists(json, 'content') ? undefined : json['content'],
    contentIsCustom: !exists(json, 'content_is_custom')
      ? undefined
      : json['content_is_custom'],
    useBrandingColor: !exists(json, 'use_branding_color')
      ? undefined
      : json['use_branding_color'],
    useCompanyLogo: !exists(json, 'use_company_logo')
      ? undefined
      : json['use_company_logo'],
    modesAvailable: !exists(json, 'modes_available')
      ? undefined
      : json['modes_available'],
  };
}

export function AgreementExtendedToJSON(value?: AgreementExtended | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...AgreementModelToJSON(value),
    survey: SurveyToJSON(value.survey),
    updated_by: UserDetailsToJSON(value.updatedBy),
    content: value.content,
    content_is_custom: value.contentIsCustom,
    use_branding_color: value.useBrandingColor,
    use_company_logo: value.useCompanyLogo,
    modes_available: value.modesAvailable,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AgreementExtendedModesAvailableEnum {
  Survey = 'survey',
  Preview = 'preview',
  Edit = 'edit',
}
