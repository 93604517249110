import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canCreate } from '../../../functions/access';
import {
  selectLivePolicies,
  VendorDocumentWithCategoryName,
} from '../../../selectors/selectLivePolicies';
import {
  hideSuccessfulVendorDocumentAttestationToast,
  setMyDocumentsFilter,
  toggleMyDocumentsFilter,
} from '../../../store/vendors/vendorsSlice';
import { VendorDocumentExtended, VendorUserRoles } from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';
import { ApplicationState } from '../../../types/applicationState';
import { Grid } from '../../../types/grid';
import { AttestationManagementModal } from '../../../components/AttestationManagementModal/AttestationManagementModal';
import { CompleteDocumentAuditModal } from '../../../components/CompleteDocumentAuditModal/CompleteDocumentAuditModal';
import { DownloadDocumentModal } from '../../../components/DownloadDocumentModal/DownloadDocumentModal';
import { Lozenge } from '../../../components/Lozenge/Lozenge';
import { MessageToast } from '../../../components/MessageToast/MessageToast';
import { NoDataText } from '../../../components/NoDataText/NoDataText';
import ReadRequestModal from '../../../components/ReadRequestModal/ReadRequestModal';
import { SortableTableHeader } from '../../../components/SortableTableHeader/SortableTableHeader';
import './LivePolicies.scss';
import { LivePolicyRow } from './LivePolicyRow';
import AdoptechGridTable from '../../../components/AdoptechGridTable/AdoptechGridTable';
import { ToggleSwitch } from '../../../components/ToggleSwitch/ToggleSwitch';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';

export const LivePolicies: React.FC = () => {
  const baseTableCss = 'adoptechGridTable';
  const baseCss = 'policyGrid';

  const canManageDocuments = canCreate(AccessObject.vendor_documents);
  const [isShowingDownloadDocumentModal, setIsShowingDownloadDocumentModal] =
    useState(false);
  const [vendorDocument, setVendorDocument] =
    useState<VendorDocumentExtended>();
  const [showReadRequestModal, setShowReadRequestModal] =
    useState<boolean>(false);
  const [vendorDocumentToMakeReadRequest, setVendorDocumentToMakeReadRequest] =
    useState<VendorDocumentExtended>();

  const livePolicyDocuments: VendorDocumentWithCategoryName[] =
    useSelector(selectLivePolicies);

  const isAttestationManagementModalShowing = useSelector(
    (state: ApplicationState) =>
      state.policies.isAttestationManagementModalShowing
  );

  const isShowingSuccessfulVendorDocumentAttestationToast = useSelector(
    (state: ApplicationState) =>
      state.vendors.documentState
        .isShowingSuccessfulVendorDocumentAttestationToast
  );

  const dispatch = useDispatch();

  const onDownload = (vd: VendorDocumentExtended) => {
    setVendorDocument(vd);
    setIsShowingDownloadDocumentModal(true);
  };

  const onReadRequest = (vd: VendorDocumentExtended) => {
    setVendorDocumentToMakeReadRequest(vd);
    setShowReadRequestModal(true);
  };

  const myPoliciesFilter = useSelector(
    (state: ApplicationState) =>
      state.vendors.documentState.myPoliciesFilterEnabled
  );
  const currentVendorUser = useSelector(selectCurrentVendorUser);
  useEffect(() => {
    if (
      currentVendorUser &&
      currentVendorUser.roles.some(userRole =>
        [VendorUserRoles.Employee].includes(userRole)
      )
    ) {
      dispatch(setMyDocumentsFilter(true));
    }
  }, []);
  return (
    <>
      <div className="livePolicies">
        <div className="livePolicies--header">
          <div className="livePolicies--title">
            Live policies
            <Lozenge value={livePolicyDocuments.length} />
          </div>
          <div className="livePolicies--filter">
            <ToggleSwitch
              checked={myPoliciesFilter}
              onChange={() => dispatch(toggleMyDocumentsFilter())}
              handlerStyle="small"
            />
            <span>My Policies</span>
          </div>
        </div>
        <div className="livePolicies--table">
          {livePolicyDocuments.length > 0 ? (
            <div
              className={
                baseCss +
                ' ' +
                baseCss +
                `--${canManageDocuments ? 8 : 7}-columns`
              }
            >
              <AdoptechGridTable
                header={
                  <div className={baseTableCss + '--header'}>
                    <SortableTableHeader<VendorDocumentWithCategoryName>
                      columnName="name"
                      grid={Grid.LivePolicies}
                      label="Name"
                      className={baseCss + '--name'}
                      notInTable
                    />
                    <div>Category</div>
                    <div>Owner</div>
                    <div>Live</div>
                    <SortableTableHeader<VendorDocumentWithCategoryName>
                      columnName="nextReviewDate"
                      grid={Grid.LivePolicies}
                      label="Next Review"
                      className={baseCss + '--nextReview'}
                      notInTable
                    />
                    <div>{canManageDocuments && <span>Read by #</span>}</div>
                    <div></div>
                    <div>Actions</div>
                  </div>
                }
              >
                {livePolicyDocuments.map((policy: VendorDocumentExtended) => {
                  return (
                    <LivePolicyRow
                      key={policy.id}
                      baseCss={baseCss}
                      vendorDocument={policy}
                      baseTableCss={baseTableCss}
                      onDownLoadClick={vd => onDownload(vd)}
                      onReadRequestClick={vd => onReadRequest(vd)}
                    />
                  );
                })}
              </AdoptechGridTable>
            </div>
          ) : (
            <NoDataText text="Once approved, live policies will appear here" />
          )}
        </div>
      </div>

      {isAttestationManagementModalShowing && <AttestationManagementModal />}
      <CompleteDocumentAuditModal />

      <DownloadDocumentModal
        onClose={() => setIsShowingDownloadDocumentModal(false)}
        show={isShowingDownloadDocumentModal}
        vendorDocumentId={vendorDocument?.id}
      />
      <MessageToast
        autohide
        show={isShowingSuccessfulVendorDocumentAttestationToast}
        onClose={() => dispatch(hideSuccessfulVendorDocumentAttestationToast())}
      >
        Policy has been read.
      </MessageToast>
      {showReadRequestModal && (
        <ReadRequestModal
          vendorDocument={vendorDocumentToMakeReadRequest}
          onClose={() => {
            setVendorDocumentToMakeReadRequest(null);
            setShowReadRequestModal(false);
          }}
        />
      )}
    </>
  );
};
