/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubDocumentModelExtra,
  TrusthubDocumentModelExtraFromJSON,
  TrusthubDocumentModelExtraFromJSONTyped,
  TrusthubDocumentModelExtraToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubDocumentModel
 */
export interface TrusthubDocumentModel {
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentModel
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentModel
   */
  updatedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentModel
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof TrusthubDocumentModel
   */
  rowOrder?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof TrusthubDocumentModel
   */
  restricted?: boolean;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentModel
   */
  visibility: TrusthubDocumentModelVisibilityEnum;
  /**
   *
   * @type {TrusthubDocumentModelExtra}
   * @memberof TrusthubDocumentModel
   */
  extra?: TrusthubDocumentModelExtra | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentModel
   */
  documentId: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentModel
   */
  documentType: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubDocumentModel
   */
  trusthubCategoryId: string | null;
}

export function TrusthubDocumentModelFromJSON(
  json: any
): TrusthubDocumentModel {
  return TrusthubDocumentModelFromJSONTyped(json, false);
}

export function TrusthubDocumentModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubDocumentModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    rowOrder: !exists(json, 'row_order') ? undefined : json['row_order'],
    restricted: !exists(json, 'restricted') ? undefined : json['restricted'],
    visibility: json['visibility'],
    extra: !exists(json, 'extra')
      ? undefined
      : TrusthubDocumentModelExtraFromJSON(json['extra']),
    documentId: json['document_id'],
    documentType: json['document_type'],
    trusthubCategoryId: json['trusthub_category_id'],
  };
}

export function TrusthubDocumentModelToJSON(
  value?: TrusthubDocumentModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    name: value.name,
    description: value.description,
    row_order: value.rowOrder,
    restricted: value.restricted,
    visibility: value.visibility,
    extra: TrusthubDocumentModelExtraToJSON(value.extra),
    document_id: value.documentId,
    document_type: value.documentType,
    trusthub_category_id: value.trusthubCategoryId,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum TrusthubDocumentModelVisibilityEnum {
  Public = 'public',
  PrivateUnlocked = 'private_unlocked',
  PrivateLocked = 'private_locked',
}
