/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SubscriptionInvoices
 */
export interface SubscriptionInvoices {
  /**
   *
   * @type {string}
   * @memberof SubscriptionInvoices
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionInvoices
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionInvoices
   */
  invoiceUrl?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionInvoices
   */
  invoicePdf?: string;
  /**
   *
   * @type {number}
   * @memberof SubscriptionInvoices
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof SubscriptionInvoices
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionInvoices
   */
  createdAt?: string;
}

export function SubscriptionInvoicesFromJSON(json: any): SubscriptionInvoices {
  return SubscriptionInvoicesFromJSONTyped(json, false);
}

export function SubscriptionInvoicesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubscriptionInvoices {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    number: !exists(json, 'number') ? undefined : json['number'],
    description: !exists(json, 'description') ? undefined : json['description'],
    invoiceUrl: !exists(json, 'invoice_url') ? undefined : json['invoice_url'],
    invoicePdf: !exists(json, 'invoice_pdf') ? undefined : json['invoice_pdf'],
    amount: !exists(json, 'amount') ? undefined : json['amount'],
    currency: !exists(json, 'currency') ? undefined : json['currency'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
  };
}

export function SubscriptionInvoicesToJSON(
  value?: SubscriptionInvoices | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    number: value.number,
    description: value.description,
    invoice_url: value.invoiceUrl,
    invoice_pdf: value.invoicePdf,
    amount: value.amount,
    currency: value.currency,
    created_at: value.createdAt,
  };
}
