import React from 'react';
import { FrameworkRelationFramework } from '../../swagger';
import { AdoptechTooltip } from '../AdoptechTooltip/AdoptechTooltip';
import './Pills.scss';

const baseCss = 'frameworksPills';

interface Props {
  frameworks: FrameworkRelationFramework[];
}

export const FrameworksPills: React.FC<Props> = ({ frameworks }) => {
  return (
    <div className={baseCss}>
      {frameworks.map(framework => (
        <AdoptechTooltip
          showTooltip
          text={framework.name}
          identifier={framework.id}
          key={framework.id}
          placement="top"
        >
          <div
            className={
              baseCss + '--framework ' + baseCss + '--' + framework.identifier
            }
            key={framework.id}
          >
            {framework.name.split(':')[0]}
          </div>
        </AdoptechTooltip>
      ))}
    </div>
  );
};
