import { faFileArrowUp } from '@fortawesome/pro-light-svg-icons/faFileArrowUp';
import { push } from 'connected-react-router';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideDocumentLibraryModal } from '../../../store/policies/policiesSlice';
import {
  fetchVendorPolicyDocuments,
  uploadVendorDocument,
} from '../../../store/vendors/vendorsThunks';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { AdoptechReactSelect } from '../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechTextArea } from '../../../components/AdoptechTextArea/AdoptechTextArea';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { DropZone } from '../../../components/DropZone/DropZone';
import './UploadOwnPolicy.scss';
import { fetchPolicyTemplateCategories } from '../../../store/policies/policiesThunks';
import { SelectionOption } from '../../../types/selectionOption';
import {
  draftPoliciesPageRoute,
  policiesPageRoute,
} from '../../../components/Routes/Routes';

const DocumentTypeEnum: { [key: string]: string } = {
  Policy: 'policy',
  Other: 'other',
};

export const UploadOwnPolicy: React.FC = () => {
  const [description, setDescription] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [file, setFile] = useState<File>();
  const [documentType, setDocumentType] = useState<string>(null);
  const [documentCategoryId, setDocumentCategoryId] = useState<string>(null);
  const documentNameRef = useRef<HTMLInputElement>();

  const { isDocumentLibraryModalShowing } = useSelector(
    (state: ApplicationState) => state.policies
  );

  const { isUploadingVendorDocument } = useSelector(
    (state: ApplicationState) => state.vendors.documentState
  );

  useEffect(() => {
    setDescription(null);
    setDocumentName(null);
    setDocumentCategoryId(null);
    setDocumentType(null);
    setFile(null);

    if (documentNameRef.current) {
      documentNameRef.current.focus();
    }
  }, [isDocumentLibraryModalShowing]);

  useEffect(() => {
    isDocumentLibraryModalShowing && dispatch(fetchPolicyTemplateCategories());
  }, [isDocumentLibraryModalShowing]);

  const dispatch = useDispatch();
  const policyCategories = useSelector(
    (state: ApplicationState) => state.policies.policyCategories
  );

  const policyCategoriesOptions: SelectionOption[] = policyCategories.map(
    category => {
      return {
        value: category.id,
        label: category.name,
      };
    }
  );

  const handleUpload = async () => {
    const payload = {
      vendorDocumentDocument: file,
      vendorDocumentName: documentName,
      vendorDocumentDocumentType: documentType,
      vendorDocumentDescription: description,
      vendorDocumentFileSizeInBytes: file.size,
      vendorDocumentPolicyCategoryId: documentCategoryId,
    };
    await dispatch(uploadVendorDocument(payload));
    dispatch(fetchVendorPolicyDocuments());
    dispatch(hideDocumentLibraryModal());
    documentType === 'policy' && dispatch(push(policiesPageRoute));
  };

  const documentTypeOptions = Object.keys(DocumentTypeEnum).map(key => ({
    label: key,
    value: DocumentTypeEnum[key],
  }));
  return (
    <div className="uploadOwnPolicy">
      <div className="uploadOwnPolicy--header">
        Upload your own policy here.
      </div>
      <div className="uploadOwnPolicy--subTitle">
        Please note that we currently only accept PDF documents.
      </div>

      <div className="uploadOwnPolicy--body">
        <div className="uploadOwnPolicy--leftBody">
          <div className="uploadOwnPolicy--label">Document name*</div>
          <AdoptechTextInput
            id="uploadOwnPolicy--documentNameInput"
            onChange={e => {
              setDocumentName(e.currentTarget.value);
            }}
            ref={documentNameRef}
            type="text"
            value={documentName}
          />
          <div className="uploadOwnPolicy--documentTypeSelect">
            <AdoptechReactSelect
              id="document_type"
              onChange={option => {
                setDocumentCategoryId(null);
                setDocumentType(option.value);
              }}
              options={documentTypeOptions}
              label="Document type*"
              value={documentTypeOptions.find(
                option => option.value === documentType
              )}
            />
          </div>
          {documentType === 'policy' && (
            <div className="uploadOwnPolicy--documentCategorySelect">
              <AdoptechReactSelect
                id="policy_category"
                onChange={option => setDocumentCategoryId(option.value)}
                options={policyCategoriesOptions}
                label="Policy Category*"
                value={policyCategoriesOptions.find(
                  option => option.value === documentCategoryId
                )}
              />
            </div>
          )}
          <div className="uploadOwnPolicy--label">Description</div>
          <AdoptechTextArea
            id="uploadOwnPolicy--additionalDescriptionInput"
            onChange={e => {
              setDescription(e.currentTarget.value);
            }}
            value={description}
          />
        </div>
        <div className="uploadOwnPolicy--rightBody">
          <DropZone
            dottedBorder
            onChange={setFile}
            accept=".pdf"
            icon={faFileArrowUp}
          />
        </div>
      </div>
      <div className="uploadOwnPolicy--label">*Required fields</div>
      <div className="uploadOwnPolicy--footer">
        <AdoptechButton
          onClick={() => {
            dispatch(hideDocumentLibraryModal());
            dispatch(push(draftPoliciesPageRoute));
          }}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          busy={isUploadingVendorDocument}
          disabled={
            !file ||
            !documentName ||
            !documentType ||
            (documentType === 'policy' && !documentCategoryId)
          }
          variant={AdoptechButtonVariant.Primary}
          onClick={handleUpload}
        >
          Upload
        </AdoptechButton>
      </div>
    </div>
  );
};
