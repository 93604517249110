import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons/faCircleQuestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  VendorSupplierExtended,
  VendorSupplierAccessControl,
} from '../../swagger';
import React, { Dispatch, SetStateAction } from 'react';
import { Accordion } from 'react-bootstrap';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { AdoptechRadioButton } from '../AdoptechRadioButton/AdoptechRadioButton';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import { ChatWidgetIcon } from '../ChatWidgetIcon/ChatWidgetIcon';

export interface AccessControlSelectionOption {
  value: VendorSupplierAccessControl;
  label: string;
}

interface RiskSectionProps {
  formData: VendorSupplierExtended;
  setFormData: Dispatch<SetStateAction<VendorSupplierExtended>>;
}

export const validation = (
  data: VendorSupplierExtended
): Record<string, string[]> => ({
  riskProfile: data.riskProfile ? null : ['Risk profile must be set'],
  accessControl: data.accessControl ? null : ['Access control must be set'],
});

export const RiskSection: React.FC<RiskSectionProps> = ({
  formData,
  setFormData,
}) => {
  const baseCss = 'manageSupplierDrawer';

  const id = formData?.id || 'new';

  const riskProfileOptions = [
    {
      value: 2,
      label: 'Very Low',
    },
    {
      value: 4,
      label: 'Low',
    },
    {
      value: 6,
      label: 'Medium',
    },
    {
      value: 8,
      label: 'High',
    },
    {
      value: 10,
      label: 'Critical',
    },
  ];

  const controlsOptions = [
    {
      value: VendorSupplierAccessControl.Sso,
      label: 'SSO (single sign on)',
    },
    {
      value: VendorSupplierAccessControl._2fa,
      label: '2FA (two factor authentication)',
    },
    {
      value: VendorSupplierAccessControl.Password,
      label: 'U/P (username and password)',
    },
    {
      value: VendorSupplierAccessControl.NotApplicable,
      label: 'N/A (not applicable)',
    },
  ];

  // Normalise ten-points profile for five-options selector
  // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] => [2, 2, 4, 4, 6, 6, 8, 8, 8, 10]
  const tenToFive = (i: number) => Math.floor((i + 1) / 2) * 2;

  const certificationsOptions = [
    {
      value: 'iso_27001',
      label: 'ISO 27001',
    },
    {
      value: 'soc_2',
      label: 'SOC 2',
    },
    {
      value: 'cyber_essentials',
      label: 'Cyber Essentials',
    },
    {
      value: 'iso_9001',
      label: 'ISO 9001',
    },
  ];

  const certificationsOptionsUnused = certificationsOptions.filter(
    c => !formData.certifications.includes(c.value)
  );

  const hasError = (field: string) => !!validation(formData)[field];
  const vendorRegisterIntercomArticleId = 6547626;

  return (
    <Accordion defaultActiveKey="0">
      <AdoptechAccordionCard
        title="Risk"
        index="0"
        cardBodyClass="card-body--centered withBorderBottom"
        headerClass="noBorderBottom positionInitial"
        iconSize="small"
        noMargin
      >
        {hasError('riskProfile') && (
          <div className={baseCss + '--error-text'}>Choose risk level</div>
        )}
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--severitySelect'}>
              {riskProfileOptions.map(({ value, label }) => (
                <div key={value} className={baseCss + '--severityOption'}>
                  <AdoptechRadioButton
                    checked={formData?.riskProfile == value}
                    disabled={false}
                    id={`vendor-${id}-riskSeverity${value}`}
                    onChange={riskProfile => {
                      setFormData({
                        ...formData,
                        riskProfile: parseInt(riskProfile),
                      });
                    }}
                    value={tenToFive(value).toString()}
                  />
                  <div>{label}</div>
                </div>
              ))}
            </div>
          </div>

          <ChatWidgetIcon
            articleId="vendor"
            extraCss={baseCss + '--questionMarkIcon'}
          />
        </div>

        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Access controls</div>
            <AdoptechReactSelect
              id={`vendor-${id}-riskAccessControls`}
              isDisabled={false}
              options={controlsOptions}
              hasError={hasError('accessControl')}
              onChange={(e: AccessControlSelectionOption) => {
                setFormData({
                  ...formData,
                  accessControl: e.value,
                });
              }}
              value={controlsOptions.find(
                o => o.value == formData?.accessControl
              )}
              placeholder="Select access controls"
              showUserAvatar
            />
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Certifications</div>
            <AdoptechReactSelect
              id={`vendor-${id}-riskCertifications`}
              isDisabled={certificationsOptionsUnused.length < 1}
              options={certificationsOptionsUnused}
              hasError={false}
              onChange={({ value }: { value: string }) =>
                setFormData({
                  ...formData,
                  certifications: [
                    ...new Set([...(formData?.certifications || []), value]),
                  ],
                })
              }
              value={null}
              placeholder={
                certificationsOptionsUnused.length > 0
                  ? 'Select a certification to add'
                  : 'All certifications added'
              }
              showUserAvatar
            />
          </div>
        </div>
        {(formData?.certifications || []).length == 0 ? (
          <span>No certifications have been added</span>
        ) : (
          Array.from(formData?.certifications || []).map((cert: string) => (
            <span key={cert} className={baseCss + '--certificationSlug'}>
              {certificationsOptions.find(o => o.value == cert).label}
              <FontAwesomeIcon
                icon={faCircleXmark}
                className={baseCss + '--xmark'}
                onClick={() =>
                  setFormData({
                    ...formData,
                    certifications: (formData?.certifications || []).filter(
                      c => c != cert
                    ),
                  })
                }
              />
            </span>
          ))
        )}
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>
              Vendor's critical dependencies
            </div>
            <AdoptechTextArea
              id={`vendor-${id}-riskCriticalDeps`}
              value={formData?.criticalDependencies}
              onChange={e => {
                setFormData({
                  ...formData,
                  criticalDependencies: e.currentTarget.value,
                });
              }}
              placeholder="Enter the critical 4th party details."
              rows={2}
            />
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Service continuity plan</div>
            <AdoptechTextArea
              id={`vendor-${id}-continuityPlan`}
              value={formData?.continuityPlan}
              onChange={e => {
                setFormData({
                  ...formData,
                  continuityPlan: e.currentTarget.value,
                });
              }}
              placeholder="Outline the steps that would be taken if you needed to stop using the vendor service. This exit could be driven by security, commercial or technical reasons. For detailed plans, we suggest you provide a link to the exit plan."
              rows={2}
            />
          </div>
        </div>
      </AdoptechAccordionCard>
    </Accordion>
  );
};
