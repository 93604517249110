import React from 'react';
import { useCanFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { useDispatch, useSelector } from 'react-redux';
import { AdoptechPanel } from '../../../components/AdoptechPanel/AdoptechPanel';
import { useBackground } from '../../../hooks/useBackground';
import { setCalendarFilters } from '../store/calendarSlice';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechDatePicker,
  getFutureDate,
} from '../../../components/AdoptechDatePicker/AdoptechDatePicker';
import moment from 'moment';
import { AdoptechCheckbox } from '../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { Dropdown } from 'react-bootstrap';
import { AdoptechDropdownToggle } from '../../../components/AdoptechDropdownToggle/AdoptechDropdownToggle';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import './CalendarHeader.scss';
import { useAdoptechDebounce } from '../../../hooks/useAdoptechDebounce';
import { fetchCalendarItems } from '../store/calendarThunks';
import { calendarState } from '../store/calendarState';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import classNames from 'classnames';
import { AdoptechTooltip } from '../../../components/AdoptechTooltip/AdoptechTooltip';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { SelectionOption } from '../../../types/selectionOption';
import { AdoptechReactSelect } from '../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { reactSelectLightStyle } from '../../../functions/reactSelectCustomTheme';
import { CurrentCalendarItem } from '../CalendarPage/CalendarPage';

interface CalendarHeaderProps {
  setShowEditActionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEditEventModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCorrectiveActionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowIncidentModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentCalendarItem: (value: CurrentCalendarItem) => void;
}

interface AuditScheduleTooltipProps {
  identifier: string;
  isButton?: boolean;
  showTooltip: boolean;
}
const AuditScheduleTooltip: React.FC<
  React.PropsWithChildren<AuditScheduleTooltipProps>
> = props => {
  const text = `Return to the Actions & Events View Mode to ${
    props.isButton ? 'use this menu' : 'change this field'
  }`;
  return (
    <AdoptechTooltip
      showTooltip={props.showTooltip}
      text={text}
      identifier={props.identifier}
    >
      <div>{props.children}</div>
    </AdoptechTooltip>
  );
};
export const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  setShowEditEventModal,
  setShowEditActionModal,
  setShowCorrectiveActionModal,
  setShowIncidentModal,
  setCurrentCalendarItem,
}) => {
  const baseCss = 'calendarHeader';
  const dispatch = useDispatch();
  const canManageCalendar = useCanFeature(AccessObject.calendar_manage);

  const {
    filters,
    filters: { viewMode, startPeriod, showMyItems, showCompleted, endPeriod },
  } = useSelector((state: ApplicationState) => state.calendar);
  useBackground('grey');
  const checkWithDebounce = useAdoptechDebounce();
  const currentVendorUser = useSelector(selectCurrentVendorUser);
  const updateFilters = (filter: any) => {
    const filtersPayload: calendarState['filters'] = {
      ...filters,
      ...filter,
      page: 1,
    };
    dispatch(setCalendarFilters(filtersPayload));
    checkWithDebounce(() => {
      dispatch(
        fetchCalendarItems({
          filters: filtersPayload,
          currentVendorUserId: currentVendorUser.id,
        })
      );
    });
  };
  const isAuditScheduleMode = viewMode === 'audit_schedule';
  const showCompletedFilter =
    viewMode === 'actions_and_events' ||
    viewMode === 'corrective_actions' ||
    viewMode === 'incident_log';
  const startDeadlines = [
    { label: '3 months from today', value: getFutureDate(3) },
    { label: '6 months from today', value: getFutureDate(6) },
    { label: '1 year from today', value: getFutureDate(12) },
    { label: '2 years from today', value: getFutureDate(24) },
  ];

  const endMinDate = (startPeriod ? moment(startPeriod) : moment())
    .endOf('day')
    .toDate();
  const endMaxDate = moment().endOf('day').add(5, 'years').toDate();

  type ViewModeOption = {
    label: string;
    value: calendarState['filters']['viewMode'];
  };

  const viewModeOptions: ViewModeOption[] = [
    { label: 'Actions & Events', value: 'actions_and_events' },
    { label: 'Audit Schedule', value: 'audit_schedule' },
    { label: 'Incident log', value: 'incident_log' },
    { label: 'Corrective Actions', value: 'corrective_actions' },
  ];

  const handleViewModeChange = (option: SelectionOption) =>
    updateFilters({ viewMode: option.value });

  const currentViewMode = viewModeOptions.find(
    option => option.value === viewMode
  );
  return (
    <div className="sticky-header">
      <AdoptechPanel className={classNames([`${baseCss}--header`])}>
        <div className={baseCss + '--viewModeColumn'}>
          <div className={baseCss + '--columnLabel'}>View Mode</div>
          <div className={baseCss + '--viewModeSelector'}>
            <AdoptechReactSelect
              id="viewModeSelect"
              options={viewModeOptions}
              onChange={handleViewModeChange}
              value={currentViewMode}
              placeholder="View Mode"
              additionalStyling={reactSelectLightStyle}
            />
          </div>
        </div>
        <div className={baseCss + '--divider'} />
        <div className={baseCss + '--startPeriodColumn'}>
          <div className={baseCss + '--columnLabel'}>Start Period</div>

          <AdoptechDatePicker
            labelText={null}
            placeHolderText="Select a date"
            deadLines={startDeadlines}
            popperPlacement="bottom-start"
            outputDate={startPeriod ? moment(startPeriod).toDate() : null}
            minDate={null}
            refreshDateOnPreselect={false}
            onPresetDateSelected={date => {
              updateFilters({
                startPeriod: moment().toJSON(),
                endPeriod: moment(date).toJSON(),
              });
            }}
            onDateSelect={d => {
              // Edge case - if select start date greater than end date
              if (moment(d) > moment(endPeriod)) {
                updateFilters({
                  startPeriod: moment(d).toJSON(),
                  endPeriod: moment(d).toJSON(),
                });
                return;
              }
              updateFilters({ startPeriod: moment(d).toJSON() });
            }}
          ></AdoptechDatePicker>
        </div>
        <div className={baseCss + '--endPeriodColumn'}>
          <div className={baseCss + '--columnLabel'}>End Period</div>
          <AdoptechDatePicker
            labelText={null}
            outputDate={endPeriod ? moment(endPeriod).toDate() : null}
            onDateSelect={d => {
              updateFilters({ endPeriod: moment(d).toJSON() });
            }}
            deadLines={[]}
            popperPlacement="bottom-start"
            minDate={endMinDate}
            maxDate={endMaxDate}
          ></AdoptechDatePicker>
        </div>
        <div className={baseCss + '--divider'} />
        <div className={baseCss + '--ownerColumn'}>
          <div className={baseCss + '--columnLabel'}>Owner</div>
          <div className={baseCss + '--ownerSwitcher'}>
            <div
              onClick={() => {
                updateFilters({ showMyItems: true });
              }}
              className={showMyItems ? 'active' : ''}
            >
              My Items
            </div>
            <div
              className={!showMyItems ? 'active' : ''}
              onClick={() => {
                updateFilters({ showMyItems: false });
              }}
            >
              All Items
            </div>
          </div>
        </div>
        {showCompletedFilter && (
          <div className={baseCss + '--showCompletedColumn'}>
            <div className={baseCss + '--columnLabel'}></div>
            <AdoptechCheckbox
              id="completed-filter"
              checked={showCompleted}
              label="Show Completed Actions"
              onChange={value => {
                updateFilters({ showCompleted: value.currentTarget.checked });
              }}
            />
          </div>
        )}
        <div className={baseCss + '--divider'} />
        <div className={baseCss + '--addColumn'}>
          {canManageCalendar && (
            <Dropdown>
              <Dropdown.Toggle as={AdoptechDropdownToggle}>
                <AdoptechButton
                  variant={AdoptechButtonVariant.PrimaryOrange}
                  icon={faPlus}
                  iconSize="large"
                  rounded
                >
                  Add new
                </AdoptechButton>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setCurrentCalendarItem(null);
                    setShowEditActionModal(true);
                  }}
                >
                  Action
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setCurrentCalendarItem(null);
                    setShowEditEventModal(true);
                  }}
                >
                  Event
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setCurrentCalendarItem(null);
                    setShowCorrectiveActionModal(true);
                  }}
                >
                  Corrective Action
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setCurrentCalendarItem(null);
                    setShowIncidentModal(true);
                  }}
                >
                  Incident
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </AdoptechPanel>
    </div>
  );
};
