import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearProfileDeletedToast,
  closeDeleteProfileModal,
} from '../../store/user/userSlice';
import { deleteUser } from '../../store/user/userThunks';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import {
  MessageToast,
  MessageToastVariant,
} from '../MessageToast/MessageToast';
import './DeleteProfileModal.scss';

export const DeleteProfileModal: React.FC = () => {
  const [check, setCheck] = useState('');
  const show = useSelector(
    (state: ApplicationState) => state.user.isDeleteUserModalShowing
  );

  const showToast = useSelector(
    (state: ApplicationState) => state.user.isProfileDeletedToastShowing
  );

  const isDeleting = useSelector(
    (state: ApplicationState) => state.user.isDeletingUser
  );

  const deleteDisabled = check.toUpperCase() !== 'DELETE';

  const dispatch = useDispatch();

  useEffect(() => {
    setCheck('');
  }, [show]);

  return (
    <React.Fragment>
      <MessageToast
        autohide={false}
        onClose={() => dispatch(clearProfileDeletedToast())}
        show={showToast}
        showClose={false}
        variant={MessageToastVariant.Warning}
      >
        <div className="deleteProfileModal--toastBody">
          <div className="deleteProfileModal--text">
            Your account has successfully been deleted.
          </div>
          <div className="deleteProfileModal--button">
            <AdoptechButton
              onClick={() => {
                dispatch(clearProfileDeletedToast());
                window.location.href = '/';
              }}
              variant={AdoptechButtonVariant.Inverse}
            >
              CONTINUE
            </AdoptechButton>
          </div>
        </div>
      </MessageToast>
      <AdoptechModal
        className="deleteProfileModal"
        onHide={() => dispatch(closeDeleteProfileModal())}
        show={show}
      >
        <Modal.Header>Delete account</Modal.Header>
        <Modal.Body className="deleteProfileModal--body">
          <div className="deleteProfileModal--text">
            You are about to permanently delete your account. This operation
            cannot be undone. To confirm your request, please type 'delete' into
            the field below.
          </div>
          <div className="deleteProfileModal--input">
            <AdoptechTextInput
              id="deleteProfileModal--input"
              autoFocus
              onChange={e => setCheck(e.currentTarget.value)}
              placeholder="Enter 'delete' in this field to proceed"
              type="text"
              value={check}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={() => dispatch(closeDeleteProfileModal())}>
            Cancel
          </AdoptechButton>
          <AdoptechButton
            busy={isDeleting}
            disabled={deleteDisabled}
            onClick={() => dispatch(deleteUser())}
            variant={AdoptechButtonVariant.Primary}
          >
            DELETE
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
