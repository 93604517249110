import React from 'react';

import './CertificationsEditSection.scss';
import { DocumentsEditSection } from '../DocumentsEditSection/DocumentsEditSection';
import { CertificatesEditSectionProps } from '../EditSectionRow/EditSectionRow';

export const CertificationsEditSection: React.FC<
  CertificatesEditSectionProps
> = props => {
  return <DocumentsEditSection {...props} baseCss="certificatesSection" />;
};
