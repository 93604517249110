/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AssessmentRecipient
 */
export interface AssessmentRecipient {
  /**
   *
   * @type {string}
   * @memberof AssessmentRecipient
   */
  assessmentResponseId?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentRecipient
   */
  recipientId?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentRecipient
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentRecipient
   */
  vendorId?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentRecipient
   */
  vendorName?: string;
}

export function AssessmentRecipientFromJSON(json: any): AssessmentRecipient {
  return AssessmentRecipientFromJSONTyped(json, false);
}

export function AssessmentRecipientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssessmentRecipient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assessmentResponseId: !exists(json, 'assessment_response_id')
      ? undefined
      : json['assessment_response_id'],
    recipientId: !exists(json, 'recipient_id')
      ? undefined
      : json['recipient_id'],
    email: !exists(json, 'email') ? undefined : json['email'],
    vendorId: !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
    vendorName: !exists(json, 'vendor_name') ? undefined : json['vendor_name'],
  };
}

export function AssessmentRecipientToJSON(
  value?: AssessmentRecipient | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assessment_response_id: value.assessmentResponseId,
    recipient_id: value.recipientId,
    email: value.email,
    vendor_id: value.vendorId,
    vendor_name: value.vendorName,
  };
}
