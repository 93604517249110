import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trusthubAdminPageCompaniesRoute } from '../../../../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';

import { AdoptechPanel } from '../../../../../../components/AdoptechPanel/AdoptechPanel';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../../components/AdoptechButton/AdoptechButton';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { TrusthubCompanyStatus } from '../../../../../../swagger/trusthub';
import { formatShortDate } from '../../../../../../functions/formatShortDate';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../../types/applicationState';
import {
  deleteTrusthubCompany,
  fetchTrustHubVendorUsers,
  patchTrusthubCompany,
} from '../../../../store/trusthubThunks';
import { showGlobalToast } from '../../../../../../store/global/globalSlice';
import { AdoptechTextInput } from '../../../../../../components/AdoptechTextInput/AdoptechTextInput';
import { AdoptechReactSelect } from '../../../../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { SelectionOption } from '../../../../../../types/selectionOption';
import { selectCurrentVendor } from '../../../../../../selectors/selectCurrentVendor';
import { AdoptechReactSelect2 } from '../../../../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal/ConfirmationModal';
import { CommandConfirmation } from '../../../../../../types/CommandConfirmation';
import moment from 'moment';
import { formatLongDateTime } from '../../../../../../functions/formatLongDateTime';

interface EditCompanyFormData {
  name: string;
  status: TrusthubCompanyStatus;
  ownerId: string;
  nda: boolean;
}
export const TrustHubUserAdminEditCompanyForm: React.FC = () => {
  const baseCss = 'trusthubUserAdminCompanyPage';
  const baseHeaderCss = baseCss + '--header';

  const {
    currentCompany: company,
    patchCurrentCompanyStatus,
    fetchingVendorUsersStatus,
    vendorUsers,
    deletingCurrentCompanyStatus,
  } = useSelector((state: ApplicationState) => state.trustHub);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState<EditCompanyFormData>({
    name: company.name,
    ownerId: (company.relationshipOwners || [])[0]?.id,
    status: company.status,
    nda: company.status === TrusthubCompanyStatus.Approved,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const handleDelete = () =>
    dispatch(
      deleteTrusthubCompany({ companyId: company.id }, () => {
        dispatch(showGlobalToast('Company has been successfully deleted'));
        dispatch(push(trusthubAdminPageCompaniesRoute));
      })
    );
  const [currentCommand, command] = useState<CommandConfirmation>(null);

  const handleEdit = () => setIsEditMode(true);
  const handleCancel = () => setIsEditMode(false);
  const handleSubmit = () => {
    dispatch(
      patchTrusthubCompany(
        {
          companyId: company.id,
          body: {
            trusthubCompany: {
              status: !formData.nda
                ? TrusthubCompanyStatus.WaitingForNda
                : formData.status,
              name: formData.name,
              relationshipOwnerIds: [formData.ownerId],
            },
          },
        },
        () => {
          setIsEditMode(false);
          dispatch(showGlobalToast('Company has been successfully updated.'));
        }
      )
    );
  };

  const statusOptions = [
    {
      label: 'Enabled',
      value: TrusthubCompanyStatus.Approved,
    },
    {
      label: 'Disabled',
      value: TrusthubCompanyStatus.Suspended,
    },
  ];
  const currentVendor = useSelector(selectCurrentVendor);
  useEffect(() => {
    isEditMode &&
      dispatch(
        fetchTrustHubVendorUsers(
          { vendorIdOrDomain: currentVendor?.id },
          () => {}
        )
      );
  }, [isEditMode]);

  const ownerOptions = (vendorUsers || []).map(user => {
    return {
      label: user.fullName,
      value: user.id,
    };
  });
  const ndaOptions = [
    {
      label: 'Yes',
      value: 'yes',
    },
    { label: 'No', value: 'no' },
  ];

  const deleteCompanyCommand: CommandConfirmation = {
    title: 'Confirm Delete',
    subject: { name: company?.name, type: 'Action' },
    description:
      'All users associated with this company will also be deleted. Click CONFIRM to delete this company.',
    buttonVariant: AdoptechButtonVariant.Warning,
    onConfirm: handleDelete,
  };

  return (
    <div>
      <div className={baseCss + '--header'}>
        <div className={baseHeaderCss + '--info'}>
          <div className={baseHeaderCss + '--backSection'}>
            <FontAwesomeIcon
              onClick={() => dispatch(push(trusthubAdminPageCompaniesRoute))}
              className={baseHeaderCss + '--backSectionLink'}
              icon={faAngleLeft}
            />
            <div className={baseHeaderCss + '--backSectionTitle'}>
              {company.name}
            </div>
          </div>
        </div>
        <div className={baseCss + '--buttons'}>
          <AdoptechButton
            variant={AdoptechButtonVariant.White}
            icon={faTrashAlt}
            busy={deletingCurrentCompanyStatus === 'loading'}
            onClick={() => command(deleteCompanyCommand)}
          >
            Delete Company
          </AdoptechButton>
        </div>
      </div>
      <div className={baseCss + '--panel'}>
        <AdoptechPanel padding="24px">
          <div className={baseCss + '--panelTitle'}>
            <div className={baseCss + '--panelInfo'}>
              <FontAwesomeIcon icon={faBuilding} />
              Company Profile
            </div>
            {!isEditMode && (
              <div className={baseCss + '--buttons'}>
                <AdoptechButton
                  variant={AdoptechButtonVariant.White}
                  icon={faPencil}
                  onClick={handleEdit}
                >
                  Edit
                </AdoptechButton>
              </div>
            )}

            {isEditMode && (
              <div className={baseCss + '--buttons'}>
                <AdoptechButton
                  disabled={patchCurrentCompanyStatus === 'loading'}
                  onClick={handleCancel}
                  variant={AdoptechButtonVariant.White}
                >
                  Cancel
                </AdoptechButton>
                <AdoptechButton
                  busy={patchCurrentCompanyStatus === 'loading'}
                  disabled={!formData.name || !formData.ownerId}
                  onClick={handleSubmit}
                  variant={AdoptechButtonVariant.White}
                >
                  Save
                </AdoptechButton>
              </div>
            )}
          </div>
          <div className={baseCss + '--panelSection'}>
            <div className={baseCss + '--panelSectionTitle'}>Overview</div>

            <div className={baseCss + '--panelSectionRow'}>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>Company</div>
                {isEditMode ? (
                  <AdoptechTextInput
                    id="companyName-input"
                    type="text"
                    value={formData.name}
                    placeholder="Enter company name"
                    hasError={!formData.name}
                    onChange={e =>
                      setFormData({ ...formData, name: e.currentTarget.value })
                    }
                  />
                ) : (
                  <div>{company.name} </div>
                )}
              </div>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>Status</div>

                {isEditMode ? (
                  <AdoptechReactSelect
                    id="companyStatus"
                    options={statusOptions}
                    onChange={(option: SelectionOption) => {
                      setFormData({
                        ...formData,
                        status: option.value as TrusthubCompanyStatus,
                        nda: option.label === 'Enabled' ? true : false,
                      });
                    }}
                    value={statusOptions.find(
                      option => option.value === formData.status
                    )}
                    placeholder="Select status"
                  />
                ) : (
                  <div>
                    {company.status === TrusthubCompanyStatus.Approved
                      ? 'Enabled'
                      : 'Disabled'}
                  </div>
                )}
              </div>
            </div>
            <div className={baseCss + '--panelSectionRow'}>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>Relationship owner</div>
                {isEditMode ? (
                  fetchingVendorUsersStatus === 'loading' ? (
                    <LoadingSpinner inlineSmall />
                  ) : (
                    <AdoptechReactSelect
                      id="companyOwner"
                      options={ownerOptions}
                      onChange={(option: SelectionOption) => {
                        setFormData({
                          ...formData,
                          ownerId: option.value,
                        });
                      }}
                      value={ownerOptions.find(
                        option => option.value === formData.ownerId
                      )}
                      placeholder="Select owner"
                    />
                  )
                ) : (
                  <div>
                    {company.relationshipOwners
                      .map(owner => owner.fullName)
                      .join(', ')}{' '}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={baseCss + '--panelDivider'}></div>
          <div className={baseCss + '--panelSection'}>
            <div className={baseCss + '--panelSectionTitle'}>Metadata</div>

            <div className={baseCss + '--panelSectionRow'}>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>Created at</div>
                <div>{formatShortDate(company.createdAt)} </div>
              </div>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>Created by</div>
                <div>{company.createdBy?.fullName}</div>
              </div>
            </div>
          </div>
          <div className={baseCss + '--panelDivider'}></div>
          <div className={baseCss + '--panelSection'}>
            <div className={baseCss + '--panelSectionTitle'}>
              Confidentiality
            </div>

            <div className={baseCss + '--panelSectionRow'}>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>NDA/Confidentiality Agreement</div>

                {isEditMode ? (
                  <AdoptechReactSelect2
                    id="nda"
                    options={ndaOptions}
                    onChange={(option: SelectionOption) => {
                      setFormData({
                        ...formData,
                        nda: option.value === 'yes',
                        status:
                          option.value === 'yes'
                            ? TrusthubCompanyStatus.Approved
                            : TrusthubCompanyStatus.Suspended,
                      });
                    }}
                    value={ndaOptions.find(
                      option => option.value === (formData.nda ? 'yes' : 'no')
                    )}
                    placeholder="Please select NDA"
                  />
                ) : (
                  <div>
                    {company.status === TrusthubCompanyStatus.WaitingForNda
                      ? 'No'
                      : 'Yes'}{' '}
                  </div>
                )}
              </div>
              <div className={baseCss + '--panelSectionRowColumn'}>
                <div>Approved by</div>
                <div>{`${company.approvedBy?.fullName}`}</div>
                <div>{formatLongDateTime(company.approvedAt) || ''}</div>
              </div>
            </div>
          </div>
          <ConfirmationModal
            command={currentCommand}
            onCancel={_confirmed => command(null)}
          />
        </AdoptechPanel>
      </div>
    </div>
  );
};
