/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RiskTemplateModel
 */
export interface RiskTemplateModel {
  /**
   *
   * @type {string}
   * @memberof RiskTemplateModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RiskTemplateModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RiskTemplateModel
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof RiskTemplateModel
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof RiskTemplateModel
   */
  riskCategoryId: string;
  /**
   *
   * @type {string}
   * @memberof RiskTemplateModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof RiskTemplateModel
   */
  updatedAt?: string;
}

export function RiskTemplateModelFromJSON(json: any): RiskTemplateModel {
  return RiskTemplateModelFromJSONTyped(json, false);
}

export function RiskTemplateModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskTemplateModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: json['description'],
    identifier: json['identifier'],
    riskCategoryId: json['risk_category_id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function RiskTemplateModelToJSON(value?: RiskTemplateModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    identifier: value.identifier,
    risk_category_id: value.riskCategoryId,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
