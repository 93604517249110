/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AgreementUpdatePayload
 */
export interface AgreementUpdatePayload {
  /**
   *
   * @type {string}
   * @memberof AgreementUpdatePayload
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementUpdatePayload
   */
  ownerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementUpdatePayload
   */
  reviewDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementUpdatePayload
   */
  content?: string;
  /**
   *
   * @type {boolean}
   * @memberof AgreementUpdatePayload
   */
  contentIsCustom?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AgreementUpdatePayload
   */
  useCompanyLogo?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AgreementUpdatePayload
   */
  useBrandingColor?: boolean;
}

export function AgreementUpdatePayloadFromJSON(
  json: any
): AgreementUpdatePayload {
  return AgreementUpdatePayloadFromJSONTyped(json, false);
}

export function AgreementUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    reviewDate: !exists(json, 'review_date') ? undefined : json['review_date'],
    content: !exists(json, 'content') ? undefined : json['content'],
    contentIsCustom: !exists(json, 'content_is_custom')
      ? undefined
      : json['content_is_custom'],
    useCompanyLogo: !exists(json, 'use_company_logo')
      ? undefined
      : json['use_company_logo'],
    useBrandingColor: !exists(json, 'use_branding_color')
      ? undefined
      : json['use_branding_color'],
  };
}

export function AgreementUpdatePayloadToJSON(
  value?: AgreementUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    owner_id: value.ownerId,
    review_date: value.reviewDate,
    content: value.content,
    content_is_custom: value.contentIsCustom,
    use_company_logo: value.useCompanyLogo,
    use_branding_color: value.useBrandingColor,
  };
}
