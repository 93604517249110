import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './ColourPalette.scss';
import classNames from 'classnames';

interface ColourPaletteProps {
  onSelect: (colour: string) => void;
  colourList?: string[];
  gap: '4px' | '10px';
}

export const defaultColoursList = [
  '#9400D3',
  '#4B0082',
  '#0000FF',
  '#00FF00',
  '#FFFF00',
  '#FF7F00',
  '#FF0000',
  '#7B3F00',
  '#FF33F9',
  '#ffa727',
  '#3880fa',
  '#01436f',
  '#222222',
];

export const ColourPalette: React.FC<ColourPaletteProps> = ({
  onSelect,
  colourList,
  gap,
}) => {
  const colours = colourList ? colourList : defaultColoursList;
  const baseCss = 'colourPalette';
  return (
    <div className={classNames([baseCss, `${baseCss}--gap-${gap}`])}>
      {colours?.map(c => {
        return (
          <div
            key={c}
            className={baseCss + '--paletteItem'}
            onClick={() => onSelect(c)}
          >
            <FontAwesomeIcon
              className={baseCss + '--iconShadow'}
              fontSize={20}
              color={c}
              icon={faCircle}
            />
          </div>
        );
      })}
      <div className={baseCss + '--paletteItem'} onClick={() => onSelect('')}>
        <FontAwesomeIcon fontSize={20} icon={faBan} />
      </div>
    </div>
  );
};
