/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorUserExternalIdentifier
 */
export interface VendorUserExternalIdentifier {
  /**
   *
   * @type {string}
   * @memberof VendorUserExternalIdentifier
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof VendorUserExternalIdentifier
   */
  value?: string;
}

export function VendorUserExternalIdentifierFromJSON(
  json: any
): VendorUserExternalIdentifier {
  return VendorUserExternalIdentifierFromJSONTyped(json, false);
}

export function VendorUserExternalIdentifierFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorUserExternalIdentifier {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    value: !exists(json, 'value') ? undefined : json['value'],
  };
}

export function VendorUserExternalIdentifierToJSON(
  value?: VendorUserExternalIdentifier | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    value: value.value,
  };
}
