/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ShareAccessLevel {
  Viewer = 'viewer',
  Contributor = 'contributor',
  Manager = 'manager',
}

export function ShareAccessLevelFromJSON(json: any): ShareAccessLevel {
  return ShareAccessLevelFromJSONTyped(json, false);
}

export function ShareAccessLevelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShareAccessLevel {
  return json as ShareAccessLevel;
}

export function ShareAccessLevelToJSON(value?: ShareAccessLevel | null): any {
  return value as any;
}
