import { createSelector } from '@reduxjs/toolkit';
import { vendorSupplierSort } from '../functions/vendorSupplierSort';
import { VendorSupplierModel } from '../swagger';
import { ApplicationState } from '../types/applicationState';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';

export const selectVendorSuppliers = createSelector(
  (state: ApplicationState) => state.vendors.vendorSuppliers,
  selectSortSettings(Grid.VendorRegister),
  (vendorSuppliers: VendorSupplierModel[], sortSetting) => {
    if (vendorSuppliers?.length) {
      return [...vendorSuppliers].sort((a, b) =>
        vendorSupplierSort(a, b, sortSetting.columnSort)
      );
    }
    return [];
  }
);
