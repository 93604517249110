import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import flattenArray from '../../functions/flattenArray';
import getTableValue from '../../functions/getTableValue';
import {
  setCurrentTableQuestions,
  showDeleteTableAnswersModal,
  showEditTableAnswersModal,
} from '../../store/assessments/assessmentsSlice';
import { Question } from '../../swagger/models';
import { QuestionType } from '../../types/QuestionType';
import { AdoptechButton } from '../AdoptechButton/AdoptechButton';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { IconButton } from '../IconButton/IconButton';
import './TableAnswer.scss';
import getTableQuestionsAndAnswers from '../../functions/getTableQuestionsAndAnswers';
import { CommonAnswerType } from '../../types/CommonAnswerType';

interface TableAnswerProps {
  answers: CommonAnswerType[];
  disabled?: boolean;
  readonly?: boolean;
  onChange: (value: CommonAnswerType[]) => void;
  question: Question;
  questions: Question[];
  indexProperty: 'name' | 'propertyName';
}

export const TableAnswer: React.FC<TableAnswerProps> = ({
  question,
  questions,
  answers,
  indexProperty,
  disabled,
  readonly,
}) => {
  const dispatch = useDispatch();

  const tableQuestions: QuestionType[] = useMemo(() => {
    return getTableQuestionsAndAnswers({
      question,
      questions,
      answers,
      indexProperty,
    });
  }, [question, questions, answers, indexProperty]);

  const handleEdit = (index: number) => () => {
    dispatch(setCurrentTableQuestions({ tableQuestions, index }));
    dispatch(showEditTableAnswersModal());
  };

  const handleDelete = (index: number) => () => {
    dispatch(setCurrentTableQuestions({ tableQuestions, index }));
    dispatch(showDeleteTableAnswersModal());
  };

  const allIndexes = flattenArray(
    tableQuestions.map(q => q.answers.map(a => a.index || 0))
  );
  const maxIndex = allIndexes.length ? Math.max(...allIndexes) : 0;

  const handleAdd = () => {
    dispatch(setCurrentTableQuestions({ tableQuestions, index: maxIndex + 1 }));
    dispatch(showEditTableAnswersModal());
  };

  const rowHasAnyValue = (index: number) =>
    tableQuestions.some(q => !!q.answers.find(a => a.index === index)?.value);

  return (
    <div className="tableAnswer">
      <AdoptechTable embedded>
        <thead>
          <tr>
            {tableQuestions.map((tableQuestion: QuestionType) => (
              <th key={tableQuestion.id}>{tableQuestion.questionText}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {new Array(maxIndex + 1).fill(undefined).map(
            (_, index) =>
              rowHasAnyValue(index) && (
                <tr key={index}>
                  {tableQuestions.map((tableQuestion: QuestionType) => (
                    <td key={tableQuestion.id}>
                      {getTableValue(tableQuestion, index)}
                    </td>
                  ))}
                  <td>
                    {!disabled && !readonly && (
                      <div className="adoptechTable--actions">
                        <IconButton
                          ariaLabel="Edit"
                          icon={faPen}
                          onClick={handleEdit(index)}
                          tooltip="Edit"
                        />
                        <IconButton
                          ariaLabel="Delete"
                          icon={faTrashAlt}
                          onClick={handleDelete(index)}
                          tooltip="Delete"
                        />
                      </div>
                    )}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </AdoptechTable>
      {!disabled && !readonly && (
        <div className="tableAnswer--addButton">
          <AdoptechButton onClick={handleAdd}>+ Add</AdoptechButton>
        </div>
      )}
    </div>
  );
};
