/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChecklistChangedStatusPayload
 */
export interface ChecklistChangedStatusPayload {
  /**
   *
   * @type {string}
   * @memberof ChecklistChangedStatusPayload
   */
  status: ChecklistChangedStatusPayloadStatusEnum;
}

export function ChecklistChangedStatusPayloadFromJSON(
  json: any
): ChecklistChangedStatusPayload {
  return ChecklistChangedStatusPayloadFromJSONTyped(json, false);
}

export function ChecklistChangedStatusPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistChangedStatusPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: json['status'],
  };
}

export function ChecklistChangedStatusPayloadToJSON(
  value?: ChecklistChangedStatusPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: value.status,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ChecklistChangedStatusPayloadStatusEnum {
  Completed = 'completed',
  InProgress = 'in_progress',
  Closed = 'closed',
}
