import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LegalRegisterState } from './legalRegisterState';
import { LegislationModel } from '../../swagger/models/LegislationModel';
import { LegislationTemplateModel } from '../../swagger/models/LegislationTemplateModel';
import { AddLegislationForm } from '../../features/legalRegister/AddLegislationModal/useAddLegislationFormHandler';
import { LegalRegisterModel } from '../../swagger';

const legalRegisterSlice = createSlice({
  initialState: {
    legislationsSearch: null,
    legislationIdToDelete: null,
    isRequestingFetchJurisdictions: false,
    fetchJurisdictionsError: null,
    isRequestingVendorLegislations: false,
    vendorLegislations: null,
    addLegislationForm: {},
    isRequestingFetchLegislationTemplates: false,
    fetchLegislationTemplatesError: null,
    isPostingLegislation: 'idle',
  } as LegalRegisterState,
  name: 'legalRegisterSlice',
  reducers: {
    setLegislationsSearch: (state, action: PayloadAction<string>) => {
      state.legislationsSearch = action.payload;
    },
    fetchVendorLegislationsSuccess: (
      state,
      action: PayloadAction<LegislationModel[]>
    ) => {
      state.isRequestingVendorLegislations = false;
      state.vendorLegislations = action.payload;
    },
    fetchVendorLegislationsFailure: state => {
      state.isRequestingVendorLegislations = false;
    },
    fetchVendorLegislationsRequest: state => {
      state.isRequestingVendorLegislations = true;
    },
    deleteLegislationRequest: (state, action: PayloadAction<string>) => {
      state.legislationIdToDelete = action.payload;
    },
    deleteLegislationSuccess: state => {
      state.vendorLegislations = state.vendorLegislations.filter(
        l => l.id != state.legislationIdToDelete
      );
      state.legislationIdToDelete = '';
    },
    deleteLegislationFailure: state => {
      state.legislationIdToDelete = '';
    },
    updateVendorLegislation: (
      state,
      action: PayloadAction<LegislationModel>
    ) => {
      state.vendorLegislations = state.vendorLegislations.map(legislation => {
        return legislation.id === action.payload.id
          ? action.payload
          : legislation;
      });
    },
    fetchLegislationTemplatesFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isRequestingFetchLegislationTemplates = false;
      state.fetchLegislationTemplatesError = action.payload;
    },
    fetchLegislationTemplatesRequest: state => {
      state.isRequestingFetchLegislationTemplates = true;
    },
    fetchLegislationTemplatesSuccess: (
      state,
      action: PayloadAction<LegislationTemplateModel[]>
    ) => {
      state.isRequestingFetchLegislationTemplates = false;
      const alreadyUsedTemplatesIds = state.vendorLegislations.map(
        legislation => legislation.legislationTemplateId
      );
      state.addLegislationForm.legislationTemplates = action.payload.filter(
        template => !alreadyUsedTemplatesIds.includes(template.id)
      );
    },
    fetchJurisdictionsFailure: (state, action: PayloadAction<string>) => {
      state.isRequestingFetchJurisdictions = false;
      state.fetchJurisdictionsError = action.payload;
    },
    fetchJurisdictionsRequest: state => {
      state.isRequestingFetchJurisdictions = true;
    },
    fetchJurisdictionsSuccess: (
      state,
      action: PayloadAction<LegislationModel[]>
    ) => {
      state.isRequestingFetchJurisdictions = false;
      state.addLegislationForm.jurisdictions = action.payload;
    },
    updateAddLegislationForm: (
      state,
      action: PayloadAction<AddLegislationForm>
    ) => {
      state.addLegislationForm = action.payload;
    },
    createLegislationFromTemplatesFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isRequestingCreateLegislationFromTemplates = false;
      state.createLegislationFromTemplatesError = action.payload;
    },
    createLegislationFromTemplatesRequest: state => {
      state.isRequestingCreateLegislationFromTemplates = true;
    },
    createLegislationFromTemplatesSuccess: state => {
      state.isRequestingCreateLegislationFromTemplates = false;
    },

    fetchLegalRegisterSuccess: (
      state,
      action: PayloadAction<LegalRegisterModel>
    ) => {
      state.isFetchingLegalRegister = false;
      state.legalRegister = action.payload;
    },
    fetchLegalRegisterFailure: (state, _: PayloadAction<string>) => {
      state.isFetchingLegalRegister = false;
    },
    fetchLegalRegisterRequest: state => {
      state.isFetchingLegalRegister = true;
    },
    patchLegalRegisterSuccess: (
      state,
      action: PayloadAction<LegalRegisterModel>
    ) => {
      state.isPatchingLegalRegister = false;
      state.legalRegister = { ...state.legalRegister, ...action.payload };
    },
    patchLegalRegisterFailure: (state, _: PayloadAction<string>) => {
      state.isPatchingLegalRegister = false;
    },
    patchLegalRegisterRequest: state => {
      state.isPatchingLegalRegister = true;
    },
    updateLegalRegisterReviewSuccess: (
      state,
      action: PayloadAction<Partial<LegalRegisterModel>>
    ) => {
      state.legalRegister = { ...state.legalRegister, ...action.payload };
    },
  },
});

export const {
  setLegislationsSearch,
  fetchVendorLegislationsSuccess,
  fetchVendorLegislationsFailure,
  fetchVendorLegislationsRequest,
  deleteLegislationRequest,
  deleteLegislationSuccess,
  deleteLegislationFailure,
  updateVendorLegislation,
  fetchLegislationTemplatesFailure,
  fetchLegislationTemplatesRequest,
  fetchLegislationTemplatesSuccess,
  updateAddLegislationForm,
  fetchJurisdictionsFailure,
  fetchJurisdictionsRequest,
  fetchJurisdictionsSuccess,
  createLegislationFromTemplatesFailure,
  createLegislationFromTemplatesRequest,
  createLegislationFromTemplatesSuccess,
  fetchLegalRegisterFailure,
  fetchLegalRegisterRequest,
  fetchLegalRegisterSuccess,
  patchLegalRegisterFailure,
  patchLegalRegisterRequest,
  patchLegalRegisterSuccess,
  updateLegalRegisterReviewSuccess,
} = legalRegisterSlice.actions;

export default legalRegisterSlice.reducer;
