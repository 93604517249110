import { CalendarItemModel } from '../../../swagger/models/CalendarItemModel';
import React from 'react';
import { Grid } from '../../../types/grid';
import { NoDataText } from '../../../components/NoDataText/NoDataText';
import AdoptechGridTable from '../../../components/AdoptechGridTable/AdoptechGridTable';
import { SortableTableHeader } from '../../../components/SortableTableHeader/SortableTableHeader';
import classNames from 'classnames';
import {
  isAction,
  isCorrectiveAction,
  isEvent,
  isIncident,
} from '../selectors/selectCalendarHSItems';
import { completedStatus, overdueStatus } from '../CalendarPage/CalendarPage';
import { AdoptechTooltip } from '../../../components/AdoptechTooltip/AdoptechTooltip';
import { formatShortDate } from '../../../functions/formatShortDate';
import moment from 'moment';
import AdoptechOverflowLine from '../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import { capitalize } from '../../../functions/capitalize';
import { ShareableType, VendorEventEventType } from '../../../swagger';
import { eventTypes } from '../EditEventModal/EditEventModal';
import { MeatballMenu } from '../../../components/MeatballMenu/MeatballMenu';
import { Dropdown } from 'react-bootstrap';
import { showShareModal } from '../../../store/vendors/vendorsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { calendarRoute } from '../../../components/Routes/Routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { ApplicationState } from '../../../types/applicationState';

const tooltipText = (item: CalendarItemModel) => {
  if (isAction(item)) return 'Action';
  if (isEvent(item)) return `Event: ${eventTypeValueToLabel(item.eventType)}`;
  if (isCorrectiveAction(item)) return 'Corrective Action';
  if (isIncident(item)) return 'Incident';
  return 'Unknown';
};

const eventTypeValueToLabel = (itemEventType: VendorEventEventType) =>
  eventTypes.find(eventType => eventType[1] === itemEventType)?.[0] ||
  itemEventType
    .split('_')
    .map(word => capitalize(word))
    .join(' ');

export const calendarItemClass = (item: CalendarItemModel) => {
  if (isEvent(item)) return 'event';
  if (isAction(item)) return 'action';
  if (isCorrectiveAction(item)) return 'corrective-action';
  if (isIncident(item)) return 'incident';
  return 'unknown';
};

export const calendarItemRoute = (
  item: CalendarItemModel,
  currentVendorId?: string
) => {
  const itemType = calendarItemClass(item);
  const pluralizedType = itemType === 'unknown' ? itemType : `${itemType}s`;

  let uri = `${calendarRoute}/${pluralizedType}/${item.id}`;
  if (currentVendorId) uri += `?vendorId=${currentVendorId}`;
  return uri;
};

export const CalendarItemsList: React.FC<{
  items: CalendarItemModel[];
  onClick(item: CalendarItemModel): void;
  showAllItems: boolean;
  showTooltip: boolean;
  noDataText?: string;
  gridName?: string;
  extraClass?: 'wide-table';
}> = ({
  items,
  onClick,
  showTooltip,
  showAllItems,
  noDataText,
  gridName,
  extraClass,
}) => {
  const dispatch = useDispatch();
  const currentVendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );
  if (items.length < 1)
    return (
      <NoDataText
        extraClass="mt-2"
        text={noDataText || 'No calendar items to show'}
      />
    );
  const baseCss = 'calendarPageTable';

  const handleShare = (
    e: { stopPropagation: () => void },
    item: CalendarItemModel
  ) => {
    e.stopPropagation();
    dispatch(
      showShareModal({
        shareableId: item.id,
        shareableType: ShareableType.VendorDocument,
        showOnlyGetLink: true,
        accessLevels: [],
        relativePath: calendarItemRoute(item, currentVendorId),
      })
    );
  };
  return (
    <div className={classNames(baseCss, { [`${extraClass}`]: extraClass })}>
      <div className={baseCss + (showAllItems ? '--4-columns' : '--3-columns')}>
        <AdoptechGridTable
          header={
            <div className="adoptechGridTable--header">
              <SortableTableHeader<CalendarItemModel>
                columnName="date"
                grid={gridName || Grid.Calendar}
                label="Deadline"
                notInTable
              />

              {showAllItems && <div className="owner-column">Owner</div>}
              <SortableTableHeader<CalendarItemModel>
                columnName="name"
                grid={gridName || Grid.Calendar}
                label="Item"
                notInTable
              />
              <div></div>
            </div>
          }
        >
          {items.map(item => {
            return (
              <div
                key={item.id}
                className={classNames('adoptechGridTable--row', {
                  'adoptechGridTable--row-completed':
                    isAction(item) && item.status === completedStatus,

                  'adoptechGridTable--row-expired':
                    isAction(item) && item.status === overdueStatus,
                })}
                onClick={() => onClick(item)}
              >
                <AdoptechTooltip
                  text={tooltipText(item)}
                  identifier={item.id}
                  showTooltip
                >
                  <div
                    className={`${baseCss}--band-column ${calendarItemClass(item)}`}
                  ></div>
                </AdoptechTooltip>
                <div className={baseCss + '--date-column'}>
                  <div>{formatShortDate(item.date)}</div>
                  <div className={baseCss + '--day'}>
                    {item.date ? moment(item.date).format('dddd') : null}
                  </div>
                </div>
                {showAllItems && (
                  <AdoptechOverflowLine>
                    {item.owner && (
                      <UserAvatar
                        size="small"
                        user={item.owner}
                        showTooltip={showTooltip}
                      />
                    )}
                  </AdoptechOverflowLine>
                )}
                <div className={baseCss + '--item-column'}>
                  <div className={baseCss + '--title'}>{item.name}</div>
                  <div className={baseCss + '--description'}>
                    {item.description}
                  </div>
                </div>
                <MeatballMenu>
                  <Dropdown.Item
                    disabled={false}
                    onClick={e => handleShare(e, item)}
                  >
                    <FontAwesomeIcon
                      className="meatballMenu--icon"
                      icon={faLink}
                    />
                    Get link
                  </Dropdown.Item>
                </MeatballMenu>
              </div>
            );
          })}
        </AdoptechGridTable>
      </div>
    </div>
  );
};
