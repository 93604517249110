import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsViewModeTaskDrawer } from '../../../../../selectors/compliance/selectIsViewModeEditTaskDrawer';
import {
  TaskExtended,
  TaskModel,
  TaskModelStatusEnum,
} from '../../../../../swagger';
import { ApplicationState } from '../../../../../types/applicationState';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import './ComplianceEditTaskForm.scss';
import classNames from 'classnames';
import { AdoptechTextInput2 } from '../../../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { AdoptechTextArea2 } from '../../../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { Interweave } from 'interweave';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { capitaliseFirst } from '../../../../../functions/capitaliseFirst';
import { ControlCheckTests } from '../ControlCheckTests/ControlCheckTests';
import { ControlCheckCalendarActions } from '../ControlCheckCalendarActions/ControlCheckCalendarActions';
import { ControlCheckControls } from '../ControlCheckControls/ControlCheckControls';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { AdoptechReactSelect2 } from '../../../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { useOwnerOptions } from '../../../../../hooks/useOwnerOptions';
import { SelectionOption } from '../../../../../types/selectionOption';
import { hasFeature } from '../../../../../functions/access';
import { AccessObject } from '../../../../../types/accessObject';
import { SecurityEditorOwnerWarning } from '../../EditControlDrawer/ComplianceEditControlForm/ComplianceEditControlForm';

export interface ComplianceEditTaskFormProps {
  onChange: (task: TaskExtended) => void;
  disabledFields?: ComplianceEditTaskFormFields[];
}

export enum ComplianceEditTaskFormFields {
  Name,
  Description,
  Assignee,
  ReviewDate,
  Recurring,
  Status,
}

export const ComplianceEditTaskForm: React.FC<ComplianceEditTaskFormProps> = ({
  onChange,
  disabledFields,
}) => {
  const baseCss = 'complianceEditTaskForm';
  const rowNumber = 5;

  const { tempTask, selectedTaskId, tasks } = useSelector(
    (state: ApplicationState) => state.compliance
  );
  const create = !selectedTaskId;
  const selectedTask = tasks.find(c => c.id === selectedTaskId);

  const isViewMode = useSelector(selectIsViewModeTaskDrawer);
  const disabledField = (prop: ComplianceEditTaskFormFields) =>
    isViewMode || disabledFields?.includes(prop);

  const dispatch = useDispatch();
  const { users, ownerOptions } = useOwnerOptions({
    onlyAdminable: true,
  });
  const isFetchingTaskDetails = useSelector(
    (state: ApplicationState) => state.compliance.isFetchingTaskDetails
  );
  const canManageOwner = create
    ? true
    : hasFeature(AccessObject.manage_ownership, tempTask?.access);
  const ownerTitle =
    create || canManageOwner ? null : SecurityEditorOwnerWarning;

  if (isFetchingTaskDetails) {
    return <LoadingSpinner />;
  }

  const fieldBlock = (condition: boolean) =>
    classNames(baseCss + '--fieldBlock', {
      [`${baseCss}--fieldBlock-error`]: condition,
    });

  const nameError = !tempTask?.name?.trim() || tempTask?.name?.length > 100;
  const showDescriptionTextArea = create || tempTask?.custom;
  const showName = create || tempTask?.custom;

  const status = tempTask?.status;

  const isCompliant = status === TaskModelStatusEnum.Compliant;
  const isFailed = status === TaskModelStatusEnum.Failed;

  return (
    <div className={baseCss}>
      <div className={baseCss + '--fieldBlocks'}>
        <>
          {showName && (
            <div className={fieldBlock(nameError)}>
              <div className={baseCss + '--field'}>
                <>
                  <AdoptechTextInput2
                    rounded
                    label="Name"
                    id={baseCss + '--nameInput'}
                    autoFocus
                    errorClass="adoptech-error"
                    onChange={e => {
                      const updatedTask: TaskModel = {
                        ...tempTask,
                        name: e.currentTarget.value,
                      };
                      onChange(updatedTask);
                    }}
                    placeholder="Enter check name here"
                    type="text"
                    disabled={disabledField(ComplianceEditTaskFormFields.Name)}
                    value={tempTask?.name}
                  />
                </>
              </div>
            </div>
          )}
          <div className={baseCss + '--fieldBlock'}>
            <div className={baseCss + '--field'} title={ownerTitle}>
              <AdoptechReactSelect2
                rounded
                id="userSelector"
                isDisabled={!canManageOwner}
                options={ownerOptions}
                onChange={async (e: SelectionOption) => {
                  const ownerId = e.value;
                  const updatedTask: TaskModel = {
                    ...tempTask,
                    assignee: users.find(user => user.id === ownerId),
                  };
                  onChange(updatedTask);
                }}
                value={ownerOptions.find(
                  option => option.value == tempTask?.assignee?.id
                )}
                placeholder="Select assignee"
                label="Owner"
                showUserAvatar
              />
            </div>
          </div>
          <div className={baseCss + '--fieldBlock'}>
            <div className={baseCss + '--fieldTitle'}>
              <div>Check description</div>
            </div>
            <div className={baseCss + '--field'}>
              {showDescriptionTextArea ? (
                <AdoptechTextArea2
                  rounded
                  disabled={disabledField(
                    ComplianceEditTaskFormFields.Description
                  )}
                  label=""
                  onChange={e => {
                    const updatedTask: TaskModel = {
                      ...tempTask,
                      description: e.currentTarget.value,
                    };
                    onChange(updatedTask);
                  }}
                  value={tempTask?.description}
                  placeholder="Add description here"
                  id="description"
                  rows={rowNumber}
                />
              ) : (
                <Interweave content={tempTask?.descriptionAsHtml} />
              )}
            </div>
          </div>

          {!create && (
            <div className={baseCss + '--fieldBlock'}>
              <div className={baseCss + '--field'}>
                <div className={baseCss + '--control-check-header'}>
                  <div>Check status</div>
                  <div
                    className={classNames(
                      baseCss + '--control-check-header-icon',
                      {
                        green: isCompliant,
                        red: isFailed,
                      }
                    )}
                  >
                    <FontAwesomeIcon
                      icon={isFailed ? faExclamationCircle : faCheckCircle}
                    />
                    <div>{capitaliseFirst(status)}</div>
                  </div>
                </div>
                <ControlCheckTests onChange={onChange} />
              </div>
            </div>
          )}

          {!create && (
            <div className={baseCss + '--fieldBlock'}>
              <div className={baseCss + '--field'}>
                <ControlCheckCalendarActions onChange={onChange} />
              </div>
            </div>
          )}

          {!create && (
            <div className={baseCss + '--fieldBlock'}>
              <div className={baseCss + '--field'}>
                <ControlCheckControls />
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default ComplianceEditTaskForm;
