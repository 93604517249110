import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { hideAssessmentSentToast } from '../../store/assessments/assessmentsSlice';
import { fetchAssessmentTemplate } from '../../store/assessments/assessmentsThunks';
import { ApplicationState } from '../../types/applicationState';
import { AssessmentSummaryQuestions } from '../AssessmentSummaryQuestions/AssessmentSummaryQuestions';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { MessageToast } from '../MessageToast/MessageToast';
import { SendAssessmentConfirmationModal } from '../SendAssessmentConfirmationModal/SendAssessmentConfirmationModal';
import { SendAssessmentModal } from '../SendAssessmentModal/SendAssessmentModal';
import './AssessmentTemplateSummary.scss';
import OutgoingAssessmentHeader from '../OutgoingAssessmentHeader/OutgoingAssessmentHeader';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { usePrevious } from 'react-use';

export const AssessmentTemplateSummary: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const assessmentTemplateId = params.id;
  const baseCss = 'assessmentTemplateSummary';
  const toastMessage =
    'The assessment has been successfully generated and sent.';
  const toBackRoute = '/r/riskManagement/assessments/outgoing';

  useEffect(() => {
    if (!assessmentTemplateId) {
      dispatch(push(toBackRoute));
    } else {
      dispatch(fetchAssessmentTemplate(params.id));
    }
  }, []);

  const isAssessmentSentToastShowing = useSelector(
    (state: ApplicationState) => state.assessments.isAssessmentSentToastShowing
  );
  const isShowingSendAssessmentModal = useSelector(
    (state: ApplicationState) => state.assessments.isShowingSendAssessmentModal
  );
  const route = useSelector(
    (state: ApplicationState) => state.router.location.pathname
  );
  const currentAssessmentTemplate = useSelector(
    (state: ApplicationState) => state.assessments.currentAssessmentTemplate
  );

  const generationInProgress = useSelector(
    (state: ApplicationState) =>
      state.assessments.assessmentGenerationInProgress
  );

  const currentVendor = useSelector(selectCurrentVendor);

  const previousVendorRef = usePrevious(currentVendor);
  useEffect(() => {
    if (previousVendorRef && previousVendorRef.id !== currentVendor.id) {
      dispatch(push('/r/riskManagement/assessments'));
    }
  }, [currentVendor]);

  if (!assessmentTemplateId) {
    return;
  }

  if (!currentAssessmentTemplate || generationInProgress) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className={baseCss}>
        <div className={baseCss + '--summary'}>
          <div
            className={baseCss + '--back'}
            onClick={() => {
              dispatch(push(toBackRoute));
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <OutgoingAssessmentHeader assessment={currentAssessmentTemplate} />
        </div>
        <div className={baseCss + '--tabs'}>
          <div className={baseCss + '--tabs--tab selected'}>
            <span className={baseCss + '--tabs--tab-text'}>Questions</span>
          </div>
          <div className={baseCss + '--tabs--tab'}>
            <span className={baseCss + '--tabs--tab-text'}>Responses</span>
            <span className={baseCss + '--tabs--tab-lozenge'}></span>
          </div>
        </div>

        <AssessmentSummaryQuestions
          sections={currentAssessmentTemplate.assessmentTemplateSections}
        />
      </div>

      <MessageToast
        onClose={() => {
          dispatch(hideAssessmentSentToast());
          dispatch(push(toBackRoute));
        }}
        show={isAssessmentSentToastShowing}
        showClose
      >
        {toastMessage}
      </MessageToast>
      <SendAssessmentConfirmationModal />
      {isShowingSendAssessmentModal && <SendAssessmentModal />}
    </>
  );
};
