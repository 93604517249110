/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  FrameworkRelationControlCategory,
  FrameworkRelationControlCategoryFromJSON,
  FrameworkRelationControlCategoryFromJSONTyped,
  FrameworkRelationControlCategoryToJSON,
  FrameworkRelationFramework,
  FrameworkRelationFrameworkFromJSON,
  FrameworkRelationFrameworkFromJSONTyped,
  FrameworkRelationFrameworkToJSON,
} from './';

/**
 *
 * @export
 * @interface FrameworkRelation
 */
export interface FrameworkRelation {
  /**
   *
   * @type {FrameworkRelationFramework}
   * @memberof FrameworkRelation
   */
  framework?: FrameworkRelationFramework;
  /**
   *
   * @type {FrameworkRelationControlCategory}
   * @memberof FrameworkRelation
   */
  controlCategory?: FrameworkRelationControlCategory;
  /**
   *
   * @type {FrameworkRelationControlCategory}
   * @memberof FrameworkRelation
   */
  control?: FrameworkRelationControlCategory;
  /**
   *
   * @type {string}
   * @memberof FrameworkRelation
   */
  annexRef?: string | null;
}

export function FrameworkRelationFromJSON(json: any): FrameworkRelation {
  return FrameworkRelationFromJSONTyped(json, false);
}

export function FrameworkRelationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkRelation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    framework: !exists(json, 'framework')
      ? undefined
      : FrameworkRelationFrameworkFromJSON(json['framework']),
    controlCategory: !exists(json, 'control_category')
      ? undefined
      : FrameworkRelationControlCategoryFromJSON(json['control_category']),
    control: !exists(json, 'control')
      ? undefined
      : FrameworkRelationControlCategoryFromJSON(json['control']),
    annexRef: !exists(json, 'annex_ref') ? undefined : json['annex_ref'],
  };
}

export function FrameworkRelationToJSON(value?: FrameworkRelation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    framework: FrameworkRelationFrameworkToJSON(value.framework),
    control_category: FrameworkRelationControlCategoryToJSON(
      value.controlCategory
    ),
    control: FrameworkRelationControlCategoryToJSON(value.control),
    annex_ref: value.annexRef,
  };
}
