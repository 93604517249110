import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  getCountryCallingCode,
  parsePhoneNumber,
} from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearTwoFactorSavedToast,
  closeTwoFactorAuthentication,
} from '../../store/user/userSlice';
import { registerTwoFA } from '../../store/user/userThunks';
import { UserDetails } from '../../swagger/models';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { Enable2FAForm } from '../Enable2FAForm/Enable2FAForm';
import { MessageToast } from '../MessageToast/MessageToast';
import './Enable2FAModal.scss';

export const Enable2FAModal: React.FC = () => {
  const [userDetails, setUserDetails] = useState<UserDetails>();
  const userState = useSelector((state: ApplicationState) => state.user);

  const dispatch = useDispatch();

  const errorMessage = useSelector(
    (state: ApplicationState) => state.user.addingTwoFactorError
  );

  const errorMessageClasses = classNames({
    'enable2FAModal--infoMessage': true,
    'enable2FAModal--infoMessage--error': Boolean(errorMessage),
    'enable2FAModal--infoMessage--hidden': !errorMessage,
  });

  useEffect(() => {
    setUserDetails(
      userState.userDetails ? userState.userDetails : { email: '' }
    );
  }, [userState]);

  const save = () => {
    const country_code = getCountryCallingCode(
      parsePhoneNumber(userDetails.mobile).country
    );

    dispatch(registerTwoFA(userDetails.mobile, country_code));
  };

  return (
    <React.Fragment>
      <MessageToast
        autohide
        delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
        onClose={() => dispatch(clearTwoFactorSavedToast())}
        show={
          userState.isTwoFactorSavedToastShowing !== false &&
          userState.isTwoFactorSavedToastShowing !== undefined
        }
      >
        Two Factor Authentication has been enabled
      </MessageToast>
      <AdoptechModal
        onHide={() => dispatch(closeTwoFactorAuthentication())}
        show={userState.is2FAEnabledShowing}
        className="enable2FAModal"
      >
        <Modal.Header>Enable Two Factor Authentication</Modal.Header>
        <Modal.Body>
          <div className="subheader">
            Please provide a valid mobile number to register with Authy Two
            Factor authentication. Once registered, you will be prompted to
            download the Authy app on your smartphone.
          </div>
          <Enable2FAForm userDetails={userDetails} onChange={setUserDetails} />
          <div className={errorMessageClasses}>{errorMessage}</div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton
            onClick={() => dispatch(closeTwoFactorAuthentication())}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={save}
            variant={AdoptechButtonVariant.Primary}
          >
            SAVE
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
