/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsFromJSONTyped,
  UserDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface ShareSuggestionListData
 */
export interface ShareSuggestionListData {
  /**
   *
   * @type {UserDetails}
   * @memberof ShareSuggestionListData
   */
  user?: UserDetails;
}

export function ShareSuggestionListDataFromJSON(
  json: any
): ShareSuggestionListData {
  return ShareSuggestionListDataFromJSONTyped(json, false);
}

export function ShareSuggestionListDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShareSuggestionListData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    user: !exists(json, 'user') ? undefined : UserDetailsFromJSON(json['user']),
  };
}

export function ShareSuggestionListDataToJSON(
  value?: ShareSuggestionListData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user: UserDetailsToJSON(value.user),
  };
}
