import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import getFilteredQuestionsWithoutTableQuestions from '../../functions/getFilteredQuestionsWithoutTableQuestions';
import { ApplicationState } from '../../types/applicationState';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { AssessmentQuestion } from '../AssessmentQuestion/AssessmentQuestion';
import { AssessmentQuestionHeader } from '../AssessmentQuestionHeader/AssessmentQuestionHeader';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

export const AssessmentCompletedResponsesBody: React.FC = () => {
  const [showHistory, setShowHistory] = useState<{ [key: string]: boolean }>(
    {}
  );

  const response = useSelector(
    (state: ApplicationState) => state.assessments.currentAssessmentResponse
  );

  if (!response) {
    return <LoadingSpinner />;
  }

  const handleSectionLoad = (index: string) => () => {
    setShowHistory(s => ({ ...s, [index]: true }));
  };

  return (
    <Accordion>
      {response.assessmentSections.map(section => (
        <AdoptechAccordionCard
          key={section.title}
          index={section.title}
          nested
          nestedVerticalOffset={164}
          title={<AssessmentQuestionHeader section={section} />}
          callbackOnOpen={handleSectionLoad(section.title)}
        >
          {getFilteredQuestionsWithoutTableQuestions(section)?.map(question => (
            <AssessmentQuestion
              key={question.id}
              assessmentResponseId={response.id}
              question={question}
              questions={section.questions}
              answers={response.assessmentResponseAnswers}
              readonly
              vendorId={response.vendorId}
              showHistory={Boolean(showHistory[section.title])}
            />
          ))}
          <div className="assessmentQuestions--footer" />
        </AdoptechAccordionCard>
      ))}
    </Accordion>
  );
};
