import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { UserDetails } from '../src/swagger/models/UserDetails';

export const sentryInit = () => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: process.env.SENTRY_URL,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      environment: process.env.RAILS_ENV || process.env.NODE_ENV,
    });
  }
};

export const getSentryReduxEnhancer = () => {
  if (process.env.NODE_ENV !== 'development') {
    return Sentry.createReduxEnhancer({});
  }
  return undefined;
};

export const setSentryUser = (user: UserDetails) => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.setUser({
      email: user.email,
      username: user.fullName,
    });
  }
};
