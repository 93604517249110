import { MainMenuItemConfiguration } from '../components/MainMenuItems/MainMenuItemConfiguration';

export const flattenMainMenuConfig = (
  mainMenuConfig: MainMenuItemConfiguration[]
): MainMenuItemConfiguration[] => {
  const result: MainMenuItemConfiguration[] = [];

  mainMenuConfig.map(mmc => {
    const newMainMenuConfig: MainMenuItemConfiguration = { ...mmc };
    newMainMenuConfig.childItems = undefined;
    result.push(newMainMenuConfig);
    if (mmc.childItems && mmc.childItems.length > 0) {
      result.push(...flattenMainMenuConfig(mmc.childItems));
    }
  });

  return result;
};
