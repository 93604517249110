import { AgreementModel, AgreementModelStatusEnum } from '../swagger';
import { selectAgreementsProgress } from './selectAgreementsProgress';
import { selectCurrentAgreement } from './selectCurrentAgreement';
import { createSelector } from '@reduxjs/toolkit';

export const selectAgreementQuestionsAreFilledIn = createSelector(
  selectCurrentAgreement,
  selectAgreementsProgress,
  (_agreement: AgreementModel, progress) => {
    return progress.total === 1;
  }
);
