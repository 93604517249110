/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChecklistUpdatePayload
 */
export interface ChecklistUpdatePayload {
  /**
   *
   * @type {string}
   * @memberof ChecklistUpdatePayload
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistUpdatePayload
   */
  placement?: ChecklistUpdatePayloadPlacementEnum;
}

export function ChecklistUpdatePayloadFromJSON(
  json: any
): ChecklistUpdatePayload {
  return ChecklistUpdatePayloadFromJSONTyped(json, false);
}

export function ChecklistUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    placement: !exists(json, 'placement') ? undefined : json['placement'],
  };
}

export function ChecklistUpdatePayloadToJSON(
  value?: ChecklistUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    placement: value.placement,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ChecklistUpdatePayloadPlacementEnum {
  Sidebar = 'sidebar',
}
