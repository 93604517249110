/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VendorPolicyType {
  Generic = 'VendorPolicy::Generic',
  Uploaded = 'VendorPolicy::Uploaded',
}

export function VendorPolicyTypeFromJSON(json: any): VendorPolicyType {
  return VendorPolicyTypeFromJSONTyped(json, false);
}

export function VendorPolicyTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorPolicyType {
  return json as VendorPolicyType;
}

export function VendorPolicyTypeToJSON(value?: VendorPolicyType | null): any {
  return value as any;
}
