import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgreementsEditForm } from '../../../../../selectors/selectAgreementsEditForm';
import { selectCurrentAgreement } from '../../../../../selectors/selectCurrentAgreement';
import { fetchAgreementSignatoriesById } from '../../../../../store/agreements/agreementsThunks';
import { AgreementSignatoryModel } from '../../../../../swagger/models/AgreementSignatoryModel';
import { LoadingSpinner } from '../../../../LoadingSpinner/LoadingSpinner';
import {
  AgreementReminderModal,
  toggleAgreementReminderModal,
  updateAgreementsEditFormField,
} from '../../../../../store/agreements/agreementsSlice';
import { ExternalSignatories } from './ExternalSignatories';
import { InternalSignatories } from './InternalSignatories';
import { AgreementModelStatusEnum } from '../../../../../swagger';
import { AgreementEditFormStep } from '../../EditAgreementForm';
import { useAgreementSignatories } from '../../../AgreementSendReviewModal/AgreementSendReviewModal';

export const SignatoriesSection: React.FC = () => {
  const baseCss = 'editAgreementForm';
  const form = useSelector(selectAgreementsEditForm);
  const dispatch = useDispatch();
  const currentAgreement = useSelector(selectCurrentAgreement);
  const isDraft = currentAgreement.status === AgreementModelStatusEnum.Draft;
  useEffect(() => {
    if (currentAgreement.id && !form.signatoriesSectionLoaded) {
      dispatch(
        updateAgreementsEditFormField({ signatoriesSectionLoaded: true })
      );
      dispatch(fetchAgreementSignatoriesById(currentAgreement.id));
    }
  }, []);

  const { isRequestingSignatories, internalSignatories, externalSignatories } =
    useAgreementSignatories();

  const showReminderModal = (modalProps: AgreementReminderModal) => {
    dispatch(toggleAgreementReminderModal(modalProps));
  };

  const onSendReminderClick = (signatory: AgreementSignatoryModel) => {
    showReminderModal({
      email: signatory.email,
      id: signatory.id,
      isShowing: true,
    });
  };

  const onShowUpload = (signatory: AgreementSignatoryModel) => {
    dispatch(
      updateAgreementsEditFormField({
        step: AgreementEditFormStep.DocumentUpload,
        currentSignatoryId: signatory.id,
        document: signatory.document,
      })
    );
  };

  const signatoryTables = [
    {
      ['Type']: 'Internal',
      ['UiElement']: (
        <InternalSignatories
          onSendReminderClick={onSendReminderClick}
          internalSignatories={internalSignatories}
          isDraft={isDraft}
        />
      ),
    },
    {
      ['Type']: 'External',
      ['UiElement']: (
        <ExternalSignatories
          onSendReminderClick={onSendReminderClick}
          externalSignatories={externalSignatories}
          onUploadClick={onShowUpload}
          isDraft={isDraft}
        />
      ),
    },
  ];

  if (isRequestingSignatories) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className={baseCss + '--section-label mt-2'}>Signatories</div>
      {signatoryTables.map(table => {
        return (
          <>
            <div
              key={table.Type}
              className={baseCss + '--section-sub-label mt-2'}
            >
              {table.Type}
            </div>
            <div
              className={classNames(
                `${baseCss}--table-headers`,
                `${baseCss}--4-columns`,
                'mt-2'
              )}
            >
              <div className={baseCss + '--label'}>Name</div>
              <div className={baseCss + '--label'}>
                {isDraft ? '' : 'Status'}
              </div>
              <div className={baseCss + '--label'}></div>
              <div className={baseCss + '--label'}></div>
            </div>
            {table.UiElement}
          </>
        );
      })}
    </>
  );
};
