/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  JurisdictionModel,
  JurisdictionModelFromJSON,
  JurisdictionModelFromJSONTyped,
  JurisdictionModelToJSON,
  LegislationClassificationModel,
  LegislationClassificationModelFromJSON,
  LegislationClassificationModelFromJSONTyped,
  LegislationClassificationModelToJSON,
  LegislationExtendedAllOf,
  LegislationExtendedAllOfFromJSON,
  LegislationExtendedAllOfFromJSONTyped,
  LegislationExtendedAllOfToJSON,
  LegislationModel,
  LegislationModelFromJSON,
  LegislationModelFromJSONTyped,
  LegislationModelToJSON,
  LegislationReviewModel,
  LegislationReviewModelFromJSON,
  LegislationReviewModelFromJSONTyped,
  LegislationReviewModelToJSON,
  SharedLegislationRecurring,
  SharedLegislationRecurringFromJSON,
  SharedLegislationRecurringFromJSONTyped,
  SharedLegislationRecurringToJSON,
} from './';

/**
 *
 * @export
 * @interface LegislationExtended
 */
export interface LegislationExtended extends LegislationModel {
  /**
   *
   * @type {Array<LegislationReviewModel>}
   * @memberof LegislationExtended
   */
  legislationReviews: Array<LegislationReviewModel>;
}

export function LegislationExtendedFromJSON(json: any): LegislationExtended {
  return LegislationExtendedFromJSONTyped(json, false);
}

export function LegislationExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LegislationExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...LegislationModelFromJSONTyped(json, ignoreDiscriminator),
    legislationReviews: (json['legislation_reviews'] as Array<any>).map(
      LegislationReviewModelFromJSON
    ),
  };
}

export function LegislationExtendedToJSON(
  value?: LegislationExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...LegislationModelToJSON(value),
    legislation_reviews: (value.legislationReviews as Array<any>).map(
      LegislationReviewModelToJSON
    ),
  };
}
