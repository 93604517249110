import { ChecklistItemsGroups } from '../swagger';

export const calculateChecklistProgress = (
  itemsGroups: ChecklistItemsGroups[]
): number => {
  if (itemsGroups.length === 0) return 0;
  const progress = itemsGroups.reduce(
    (accumulator: number, item: ChecklistItemsGroups) =>
      item.progress.percents + accumulator,
    0
  );
  return (progress * 100) / itemsGroups.length;
};
