import { ApplicationState } from '../types/applicationState';
import { SelectionOption } from '../types/selectionOption';

export const getDefaultSelectionOptions = (
  type: string,
  state: ApplicationState
): SelectionOption[] => {
  if (!state.vendors.vendorTableCache) {
    return [];
  }

  if (state.vendors.vendorTableCache[type] === undefined) {
    return [];
  }

  return state.vendors.vendorTableCache[type].rows.map(vt => {
    return { value: vt.value, label: vt.cells[0] };
  });
};
