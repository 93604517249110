/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FrameworkModelStatsControls
 */
export interface FrameworkModelStatsControls {
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsControls
   */
  count?: number;
  /**
   *
   * @type {number}
   * @memberof FrameworkModelStatsControls
   */
  progress?: number;
}

export function FrameworkModelStatsControlsFromJSON(
  json: any
): FrameworkModelStatsControls {
  return FrameworkModelStatsControlsFromJSONTyped(json, false);
}

export function FrameworkModelStatsControlsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkModelStatsControls {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    count: !exists(json, 'count') ? undefined : json['count'],
    progress: !exists(json, 'progress') ? undefined : json['progress'],
  };
}

export function FrameworkModelStatsControlsToJSON(
  value?: FrameworkModelStatsControls | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    count: value.count,
    progress: value.progress,
  };
}
