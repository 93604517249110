import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import {
  fetchAssessmentResponse,
  fetchAssessments,
} from '../../store/assessments/assessmentsThunks';
import { ApplicationState } from '../../types/applicationState';
import { AssessmentQuestions } from '../AssessmentQuestions/AssessmentQuestions';
import {
  ActionType,
  AssessmentSummaryTile,
} from '../AssessmentSummaryTile/AssessmentSummaryTile';
import { AutoSaveToast } from '../AutoSaveToast/AutoSaveToast';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import './Assessment.scss';

export const Assessment: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const assessmentId = params.id;

  const currentVendor = useSelector(selectCurrentVendor);
  const currentAssessmentResponse = useSelector(
    (state: ApplicationState) => state.assessments.currentAssessmentResponse
  );

  useEffect(() => {
    if (!assessmentId) {
      dispatch(push('/r/riskManagement/assessments/summary'));
    } else {
      dispatch(fetchAssessmentResponse(assessmentId));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAssessments(currentVendor?.id));
  }, [currentVendor?.id]);

  if (!assessmentId) {
    return;
  }

  if (!currentAssessmentResponse) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      <div className="assessment">
        <div className="assessment--summary">
          {currentAssessmentResponse.status !==
            ActionType[ActionType.completed] && (
            <div className="assessment--overlay" />
          )}
          <div
            className="assessment--back"
            onClick={() => {
              dispatch(push('/r/riskManagement/assessments/summary'));
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <div className="assessment--summaryTile">
            <AssessmentSummaryTile
              assessment={currentAssessmentResponse}
              actionType={
                currentAssessmentResponse.status ===
                ActionType[ActionType.completed]
                  ? ActionType.completed
                  : ActionType.answer
              }
            />
          </div>
        </div>
        <AssessmentQuestions assessment={currentAssessmentResponse} />
      </div>
      <AutoSaveToast />
    </React.Fragment>
  );
};
