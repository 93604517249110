/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Progress
 */
export interface Progress {
  /**
   *
   * @type {number}
   * @memberof Progress
   */
  answered: number;
  /**
   *
   * @type {number}
   * @memberof Progress
   */
  unanswered: number;
  /**
   *
   * @type {number}
   * @memberof Progress
   */
  percents: number;
}

export function ProgressFromJSON(json: any): Progress {
  return ProgressFromJSONTyped(json, false);
}

export function ProgressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Progress {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    answered: json['answered'],
    unanswered: json['unanswered'],
    percents: json['percents'],
  };
}

export function ProgressToJSON(value?: Progress | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    answered: value.answered,
    unanswered: value.unanswered,
    percents: value.percents,
  };
}
