import React, { useState } from 'react';
import { ApplicationState } from '../../../../types/applicationState';
import { useDispatch, useSelector } from 'react-redux';
import { AdoptechTextInput } from '../../../../components/AdoptechTextInput/AdoptechTextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { updateTrustHubSettings } from '../../store/trusthubThunks';
import { AdoptechTextArea } from '../../../../components/AdoptechTextArea/AdoptechTextArea';
import classNames from 'classnames';
import { TrustHubSectionProps } from '../../CompanyDetailsSection/CompanyDetailsSection';
import { selectCurrentVendor } from '../../../../selectors/selectCurrentVendor';

interface CompanyDetailsEditSectionProps {
  onClose: () => void;
  settings: TrustHubSectionProps['settings'];
}

export const CompanyDetailsEditSection: React.FC<
  CompanyDetailsEditSectionProps
> = ({ settings, onClose }) => {
  const baseCss = 'companyDetailsSection';
  const [companyEmail, setCompanyEmail] = useState(settings.email);
  const [companyDescription, setCompanyDescription] = useState(
    settings.description
  );

  const vendor = useSelector(selectCurrentVendor);

  const { updateSettingsStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );
  const dispatch = useDispatch();

  return (
    <div className={classNames(baseCss, `${baseCss}--editMode`)}>
      <div className={baseCss + '--panel'}>
        <div className={baseCss + '--companyNameRow'}>
          <div className={baseCss + '--companyNameColumn'}>
            <div className={baseCss + '--companyNameAndEmail'}>
              <div className={baseCss + '--companyEmailRow'}>
                <FontAwesomeIcon icon={faEnvelope} />
                <div className={baseCss + '--companyEmailTitle'}>
                  <AdoptechTextInput
                    id="companyEmail-input"
                    type="text"
                    hasError={!companyEmail}
                    value={companyEmail}
                    placeholder="Enter company email"
                    onChange={e => setCompanyEmail(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={baseCss + '--companyActions'}>
            <AdoptechButton
              disabled={updateSettingsStatus === 'loading'}
              onClick={() => onClose()}
              variant={AdoptechButtonVariant.White}
            >
              Cancel
            </AdoptechButton>
            <AdoptechButton
              busy={updateSettingsStatus === 'loading'}
              disabled={!companyDescription || !companyEmail}
              onClick={() => {
                const payload = {
                  email: companyEmail,
                  description: companyDescription,
                  vendorIdOrDomain: vendor.id,
                };

                dispatch(updateTrustHubSettings(payload, () => onClose()));
              }}
              variant={AdoptechButtonVariant.White}
            >
              Save
            </AdoptechButton>
          </div>
        </div>
        <div className={baseCss + '--companyDescription'}>
          <AdoptechTextArea
            id="companyDesc-input"
            placeholder="Enter company TrustHub service summary"
            value={companyDescription}
            onChange={e => setCompanyDescription(e.currentTarget.value)}
            hasError={!companyDescription}
          />
        </div>
      </div>
    </div>
  );
};
