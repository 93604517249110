import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { push } from 'connected-react-router';
import { useQueryParams } from '../../hooks/useQueryParams';
import { showWarningSubscriptionLimitModal } from '../../store/vendors/vendorsSlice';

export const StripeSignupError: React.FC = () => {
  const queryParams = useQueryParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (queryParams.get('subscription_users_limit_error')) {
      dispatch(showWarningSubscriptionLimitModal());
      dispatch(push('/r/home'));
      return;
    }
  }, []);

  return <LoadingSpinner />;
};
