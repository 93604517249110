import { createSelector } from '@reduxjs/toolkit';
import { Vendor, VendorDetails } from '../swagger/models';
import { ApplicationState } from '../types/applicationState';
import { selectCurrentVendor } from './selectCurrentVendor';

const getVendorDetails = (state: ApplicationState) =>
  state.vendors.vendorDetails;

export const selectVendorDetails = createSelector(
  selectCurrentVendor,
  getVendorDetails,
  (vendor: Vendor, vendorDetails: VendorDetails[]) => {
    if (!vendor) {
      return undefined;
    }
    if (vendorDetails.length === 0) {
      return undefined;
    }
    return vendorDetails.find(vd => vd.id === vendor.id);
  }
);
