import React, { useEffect, useMemo, useState } from 'react';
import { TrusthubDocumentModel } from '../../../../../swagger/trusthub/models/TrusthubDocumentModel';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../types/applicationState';
import { selectCurrentVendor } from '../../../../../selectors/selectCurrentVendor';
import {
  fetchTrustHubSections,
  fetchTrustHubVendorUserDocumentPermissions,
  postTrustHubAccessRequest,
} from '../../../store/trusthubThunks';
import { showGlobalToast } from '../../../../../store/global/globalSlice';
import AdoptechHS from '../../../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { DocumentPermissionTableRow } from './DocumentPermissionTableRow/DocumentPermissionTableRow';
import './TrusthubUserAdminAddDocumentPermissionDrawer.scss';

export interface AddDocumentPermissionFormData {
  selectedDocumentsIds?: TrusthubDocumentModel['id'][];
}

interface TrusthubUserAdminAddDocumentPermissionDrawerProps {
  onClose: () => void;
}

export const TrusthubUserAdminAddDocumentPermissionDrawer: React.FC<
  TrusthubUserAdminAddDocumentPermissionDrawerProps
> = ({ onClose }) => {
  const baseCss = 'trusthubUserAdminAddDocumentPermissionDrawer';
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<AddDocumentPermissionFormData>({});
  const {
    fetchingSectionsStatus,
    createAccessRequestStatus,
    sections,
    currentVendorUser: user,
  } = useSelector((state: ApplicationState) => state.trustHub);
  const currentVendor = useSelector(selectCurrentVendor);
  const dispatchSubmit = () => {
    dispatch(
      postTrustHubAccessRequest(
        {
          vendorIdOrDomain: currentVendor?.id,
          body: {
            trusthubAccessRequest: {
              trusthubVendorUserId: user.id,
              trusthubDocumentIds: formData.selectedDocumentsIds,
            },
          },
        },
        _company => {
          // reload permissions table
          dispatch(
            fetchTrustHubVendorUserDocumentPermissions({
              vendorUserId: user.id,
            })
          );

          dispatch(
            showGlobalToast('Access request has been successfully created')
          );
          onClose();
        }
      )
    );
  };

  useEffect(() => {
    dispatch(
      fetchTrustHubSections(
        { vendorIdOrDomain: currentVendor?.id, trusthubVendorUserId: user.id },
        () => {}
      )
    );
  }, []);

  const filteredSections = sections
    .filter(section => section.visible)
    .filter(section => section.trusthubDocuments.length > 0);
  const sectionsList = useMemo(() => {
    return filteredSections
      .sort((a, b) => a.rowOrder || 0 - b.rowOrder || 0)
      .map(section => {
        return (
          <DocumentPermissionTableRow
            key={section.id}
            section={section}
            formData={formData}
            setFormData={setFormData}
          />
        );
      });
  }, [sections, formData]);

  return (
    <AdoptechHS
      title="Add New Access"
      show
      showConfirmationWarning={false}
      onClose={() => onClose()}
      footer={
        <>
          <AdoptechButton
            onClick={() => onClose()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={dispatchSubmit}
            variant={AdoptechButtonVariant.Primary}
            busy={createAccessRequestStatus === 'loading'}
            disabled={(formData.selectedDocumentsIds || []).length === 0}
          >
            Approve
          </AdoptechButton>
        </>
      }
      extraClass="adoptechHS--medium addAccessRequestHS"
    >
      {fetchingSectionsStatus === 'loading' ? (
        <LoadingSpinner />
      ) : (
        <div className={baseCss}>
          {filteredSections.length > 0 ? (
            <>
              <div className={baseCss + '--label'}>
                {user.fullName} ({user.email}) has requested access to the
                following documents. Select the document(s) you would like to
                grant access to. Any documents not selected will have their
                access request declined.
              </div>
              <div className={baseCss + '--fieldRow'}>{sectionsList}</div>
            </>
          ) : (
            <div className={baseCss + '--label'}>
              There are no available documents for request.
            </div>
          )}
        </div>
      )}
    </AdoptechHS>
  );
};
