import React, { useEffect } from 'react';
import { DataRoomTabs } from '../DataRoomTabs/DataRoomTabs';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { fetchEvidence } from '../../../../store/compliance/complianceThunks';
import { ApplicationState } from '../../../../types/applicationState';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { DataRoomEntityModel } from '../../../../swagger';
import AdoptechOverflowLine from '../../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/pro-light-svg-icons/faCloudDownloadAlt';
import './DataRoomEvidencePage.scss';
import { formatShortDate } from '../../../../functions/formatShortDate';
import { filesize } from 'filesize';
import { downloadFile } from '../../../../store/dataRoom/dataRoomThunks';
import { sortByDate } from '../../../../functions/sortByDate';

interface EvidenceListProps {
  evidence: DataRoomEntityModel[];
}

const EvidenceRow: React.FC<{ evidence: DataRoomEntityModel }> = ({
  evidence,
}) => {
  const baseCss = 'peopleTable';
  const dispatch = useAppDispatch();
  const handleDownload = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    dispatch(downloadFile(evidence.id));
  };

  return (
    <div className={`${baseCss}--row`} onClick={handleDownload}>
      <AdoptechOverflowLine>{`${evidence.name}.${evidence.fileType}`}</AdoptechOverflowLine>
      <div>{formatShortDate(evidence.createdAt)}</div>
      <AdoptechOverflowLine>
        <UserAvatar
          user={evidence.creator}
          size="medium"
          showUserEmail={false}
        />
      </AdoptechOverflowLine>
      <div>{filesize(evidence.fileSize)}</div>
      <div>
        <FontAwesomeIcon icon={faCloudDownloadAlt} />
      </div>
    </div>
  );
};

export const EvidenceList: React.FC<EvidenceListProps> = props => {
  const baseCss = 'peopleTable';

  return (
    <div className={baseCss + ' ' + baseCss + '--5-columns'}>
      {(props.evidence || []).map(evidenceItem => {
        return <EvidenceRow key={evidenceItem.id} evidence={evidenceItem} />;
      })}
    </div>
  );
};

export const DataRoomEvidencePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { evidence, fetchEvidenceStatus } = useSelector(
    (state: ApplicationState) => state.compliance
  );
  useEffect(() => {
    dispatch(fetchEvidence());
  }, []);
  return (
    <div>
      <DataRoomTabs currentTab="evidence" />

      {fetchEvidenceStatus === 'loading' ? (
        <LoadingSpinner />
      ) : (
        <EvidenceList
          evidence={[...evidence].sort((a, b) =>
            sortByDate(a.createdAt, b.createdAt, -1)
          )}
        />
      )}
    </div>
  );
};
