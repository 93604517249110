/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  FrameworkRelation,
  FrameworkRelationFromJSON,
  FrameworkRelationFromJSONTyped,
  FrameworkRelationToJSON,
  ReviewFrequencyEnum,
  ReviewFrequencyEnumFromJSON,
  ReviewFrequencyEnumFromJSONTyped,
  ReviewFrequencyEnumToJSON,
  VendorPolicyAnswer,
  VendorPolicyAnswerFromJSON,
  VendorPolicyAnswerFromJSONTyped,
  VendorPolicyAnswerToJSON,
  VendorPolicyClause,
  VendorPolicyClauseFromJSON,
  VendorPolicyClauseFromJSONTyped,
  VendorPolicyClauseToJSON,
  VendorPolicyType,
  VendorPolicyTypeFromJSON,
  VendorPolicyTypeFromJSONTyped,
  VendorPolicyTypeToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorPolicyDetails
 */
export interface VendorPolicyDetails {
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  relativePath?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  policyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  vendorId?: string;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  name?: string;
  /**
   *
   * @type {VendorPolicyType}
   * @memberof VendorPolicyDetails
   */
  type?: VendorPolicyType | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  categoryName?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  summary?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  summaryAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  descriptionAsHtml?: string | null;
  /**
   *
   * @type {number}
   * @memberof VendorPolicyDetails
   */
  answered?: number;
  /**
   *
   * @type {number}
   * @memberof VendorPolicyDetails
   */
  unanswered?: number;
  /**
   *
   * @type {number}
   * @memberof VendorPolicyDetails
   */
  progress?: number | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  source?: string;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  status?: VendorPolicyDetailsStatusEnum;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  versionBump?: VendorPolicyDetailsVersionBumpEnum;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  changeReason?: string;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  document?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  vendorDocumentId?: string | null;
  /**
   *
   * @type {Array<VendorPolicyClause>}
   * @memberof VendorPolicyDetails
   */
  vendorPolicyClauses?: Array<VendorPolicyClause>;
  /**
   *
   * @type {Array<VendorPolicyAnswer>}
   * @memberof VendorPolicyDetails
   */
  vendorPolicyAnswers?: Array<VendorPolicyAnswer>;
  /**
   *
   * @type {Access}
   * @memberof VendorPolicyDetails
   */
  access?: Access;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  accessGroupId?: string | null;
  /**
   *
   * @type {VendorUser}
   * @memberof VendorPolicyDetails
   */
  owner?: VendorUser;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  ownerId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof VendorPolicyDetails
   */
  available?: boolean;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  reviewDate?: string | null;
  /**
   *
   * @type {ReviewFrequencyEnum}
   * @memberof VendorPolicyDetails
   */
  reviewFrequency?: ReviewFrequencyEnum;
  /**
   *
   * @type {Array<FrameworkRelation>}
   * @memberof VendorPolicyDetails
   */
  frameworksRelations?: Array<FrameworkRelation>;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  updatedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorPolicyDetails
   */
  updatedBy?: string | null;
}

export function VendorPolicyDetailsFromJSON(json: any): VendorPolicyDetails {
  return VendorPolicyDetailsFromJSONTyped(json, false);
}

export function VendorPolicyDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorPolicyDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    relativePath: !exists(json, 'relative_path')
      ? undefined
      : json['relative_path'],
    policyId: !exists(json, 'policy_id') ? undefined : json['policy_id'],
    vendorId: !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    type: !exists(json, 'type')
      ? undefined
      : VendorPolicyTypeFromJSON(json['type']),
    categoryName: !exists(json, 'category_name')
      ? undefined
      : json['category_name'],
    summary: !exists(json, 'summary') ? undefined : json['summary'],
    summaryAsHtml: !exists(json, 'summary_as_html')
      ? undefined
      : json['summary_as_html'],
    description: !exists(json, 'description') ? undefined : json['description'],
    descriptionAsHtml: !exists(json, 'description_as_html')
      ? undefined
      : json['description_as_html'],
    answered: !exists(json, 'answered') ? undefined : json['answered'],
    unanswered: !exists(json, 'unanswered') ? undefined : json['unanswered'],
    progress: !exists(json, 'progress') ? undefined : json['progress'],
    source: !exists(json, 'source') ? undefined : json['source'],
    status: !exists(json, 'status') ? undefined : json['status'],
    version: !exists(json, 'version') ? undefined : json['version'],
    versionBump: !exists(json, 'version_bump')
      ? undefined
      : json['version_bump'],
    changeReason: !exists(json, 'change_reason')
      ? undefined
      : json['change_reason'],
    document: !exists(json, 'document') ? undefined : json['document'],
    vendorDocumentId: !exists(json, 'vendor_document_id')
      ? undefined
      : json['vendor_document_id'],
    vendorPolicyClauses: !exists(json, 'vendor_policy_clauses')
      ? undefined
      : (json['vendor_policy_clauses'] as Array<any>).map(
          VendorPolicyClauseFromJSON
        ),
    vendorPolicyAnswers: !exists(json, 'vendor_policy_answers')
      ? undefined
      : (json['vendor_policy_answers'] as Array<any>).map(
          VendorPolicyAnswerFromJSON
        ),
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    accessGroupId: !exists(json, 'access_group_id')
      ? undefined
      : json['access_group_id'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    available: !exists(json, 'available') ? undefined : json['available'],
    reviewDate: !exists(json, 'review_date') ? undefined : json['review_date'],
    reviewFrequency: !exists(json, 'review_frequency')
      ? undefined
      : ReviewFrequencyEnumFromJSON(json['review_frequency']),
    frameworksRelations: !exists(json, 'frameworks_relations')
      ? undefined
      : (json['frameworks_relations'] as Array<any>).map(
          FrameworkRelationFromJSON
        ),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    updatedBy: !exists(json, 'updated_by') ? undefined : json['updated_by'],
  };
}

export function VendorPolicyDetailsToJSON(
  value?: VendorPolicyDetails | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    relative_path: value.relativePath,
    policy_id: value.policyId,
    vendor_id: value.vendorId,
    name: value.name,
    type: VendorPolicyTypeToJSON(value.type),
    category_name: value.categoryName,
    summary: value.summary,
    summary_as_html: value.summaryAsHtml,
    description: value.description,
    description_as_html: value.descriptionAsHtml,
    answered: value.answered,
    unanswered: value.unanswered,
    progress: value.progress,
    source: value.source,
    status: value.status,
    version: value.version,
    version_bump: value.versionBump,
    change_reason: value.changeReason,
    document: value.document,
    vendor_document_id: value.vendorDocumentId,
    vendor_policy_clauses:
      value.vendorPolicyClauses === undefined
        ? undefined
        : (value.vendorPolicyClauses as Array<any>).map(
            VendorPolicyClauseToJSON
          ),
    vendor_policy_answers:
      value.vendorPolicyAnswers === undefined
        ? undefined
        : (value.vendorPolicyAnswers as Array<any>).map(
            VendorPolicyAnswerToJSON
          ),
    access: AccessToJSON(value.access),
    access_group_id: value.accessGroupId,
    owner: VendorUserToJSON(value.owner),
    owner_id: value.ownerId,
    available: value.available,
    review_date: value.reviewDate,
    review_frequency: ReviewFrequencyEnumToJSON(value.reviewFrequency),
    frameworks_relations:
      value.frameworksRelations === undefined
        ? undefined
        : (value.frameworksRelations as Array<any>).map(
            FrameworkRelationToJSON
          ),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    updated_by: value.updatedBy,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum VendorPolicyDetailsStatusEnum {
  Available = 'available',
  Completed = 'completed',
  InProgress = 'in_progress',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorPolicyDetailsVersionBumpEnum {
  Minor = 'minor',
  Major = 'major',
}
