/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TaskAvailableAssertionsPayload
 */
export interface TaskAvailableAssertionsPayload {
  /**
   *
   * @type {Array<string>}
   * @memberof TaskAvailableAssertionsPayload
   */
  availableAssertionIds?: Array<string>;
}

export function TaskAvailableAssertionsPayloadFromJSON(
  json: any
): TaskAvailableAssertionsPayload {
  return TaskAvailableAssertionsPayloadFromJSONTyped(json, false);
}

export function TaskAvailableAssertionsPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TaskAvailableAssertionsPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    availableAssertionIds: !exists(json, 'available_assertion_ids')
      ? undefined
      : json['available_assertion_ids'],
  };
}

export function TaskAvailableAssertionsPayloadToJSON(
  value?: TaskAvailableAssertionsPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    available_assertion_ids: value.availableAssertionIds,
  };
}
