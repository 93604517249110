/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CardBillingDetailsAddress,
  CardBillingDetailsAddressFromJSON,
  CardBillingDetailsAddressFromJSONTyped,
  CardBillingDetailsAddressToJSON,
} from './';

/**
 *
 * @export
 * @interface CardBillingDetails
 */
export interface CardBillingDetails {
  /**
   *
   * @type {string}
   * @memberof CardBillingDetails
   */
  name?: string;
  /**
   *
   * @type {CardBillingDetailsAddress}
   * @memberof CardBillingDetails
   */
  address?: CardBillingDetailsAddress;
}

export function CardBillingDetailsFromJSON(json: any): CardBillingDetails {
  return CardBillingDetailsFromJSONTyped(json, false);
}

export function CardBillingDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CardBillingDetails {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    address: !exists(json, 'address')
      ? undefined
      : CardBillingDetailsAddressFromJSON(json['address']),
  };
}

export function CardBillingDetailsToJSON(
  value?: CardBillingDetails | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    address: CardBillingDetailsAddressToJSON(value.address),
  };
}
