import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import './AdoptechTextInput.scss';

export interface AdoptechTextInputProps {
  id: string;
  autoFocus?: boolean;
  label?: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onIconClicked?: (event: React.MouseEvent<HTMLDivElement>) => void;
  hasError?: boolean;
  placeholder?: string;
  value: string;
  rows?: number;
  maxLength?: number;
  type: 'text' | 'email' | 'password';
  additionalClass?: string;
  errorClass?: string;
  icon?: IconDefinition;
  onlyInput?: boolean;
  rounded?: boolean;
  bottomElement?: React.ReactElement; // for custom error badges at bottom
  autoCapitalize?: string;
  prefixText?: string; // for read only bold prefix before input
}

const AdoptechTextInput = React.forwardRef<
  HTMLInputElement,
  AdoptechTextInputProps
>((props, ref) => {
  const mainClasses = classNames({
    adoptechTextInput: true,
    'adoptechTextInput-error': props.hasError && !props.errorClass,
    [`${props.errorClass}`]: props.hasError,
  });

  const input = (
    <input
      id={props.id}
      autoFocus={props.autoFocus}
      autoComplete="off"
      className={mainClasses}
      disabled={props.disabled}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      ref={ref}
      type={props.type}
      value={props.value}
      maxLength={props.maxLength}
      autoCapitalize={props.autoCapitalize}
    />
  );

  if (props.onlyInput) return input;

  return (
    <>
      <div
        className={`adoptechTextInput adoptechTextInputContainer${
          props.icon ? '-withIcon' : ''
        } ${props?.additionalClass}`}
      >
        {props.label && <label htmlFor={props.id}>{props.label}</label>}
        {props.hasError && props.label && (
          <span className="adoptechTextInput-error">
            {`Please fill in ${props.label.toLowerCase()}`}
          </span>
        )}

        {input}
        {props.icon && (
          <div
            className="adoptechTextInput-iconContainer"
            onClick={e => props.onIconClicked && props.onIconClicked(e)}
          >
            <FontAwesomeIcon className="input--icon" icon={props.icon} />
          </div>
        )}
      </div>
      {props.bottomElement}
    </>
  );
});

AdoptechTextInput.displayName = 'AdoptechTextInput';
export { AdoptechTextInput };
