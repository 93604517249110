import { push } from 'connected-react-router';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hidePublishedAssessmentModal } from '../../store/assessments/assessmentsSlice';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './AssessmentPublishedModal.scss';
import { logoutUser } from '../../store/user/userThunks';

export const AssessmentPublishedModal: React.FC = () => {
  const isShowingPublishedAssessmentModal = useSelector(
    (state: ApplicationState) =>
      state.assessments.isShowingPublishedAssessmentModal
  );

  const dispatch = useDispatch();

  return (
    <AdoptechModal
      className="assessmentPublishedModal"
      onHide={null}
      show={isShowingPublishedAssessmentModal}
    >
      <Modal.Body>
        <p>Thank you!</p>
        <p>Your assessment has been submitted.</p>
        <p>
          Please feel free to logout, or click CONTINUE to stay in the platform.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={() => {
            dispatch(logoutUser());
          }}
          variant={AdoptechButtonVariant.Success}
        >
          Logout
        </AdoptechButton>
        <AdoptechButton
          onClick={() => {
            dispatch(hidePublishedAssessmentModal());
            dispatch(push('/r/dashboard'));
          }}
          variant={AdoptechButtonVariant.Inverse}
        >
          CONTINUE
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
