import React from 'react';
import './CertificationsSection.scss';
import { TrustHubSectionProps } from '../CompanyDetailsSection/CompanyDetailsSection';
import { DocumentsSection } from '../DocumentsSection/DocumentsSection';

export const CertificationsSection: React.FC<TrustHubSectionProps> = ({
  isEditable,
  sections,
  onDocumentClick,
  showDocumentDownloadIcon,
}) => {
  return (
    <DocumentsSection
      identifier="certificates"
      isEditable={isEditable}
      sections={sections}
      onDocumentClick={onDocumentClick}
      showDocumentDownloadIcon={showDocumentDownloadIcon}
    />
  );
};
