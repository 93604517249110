import React, { useEffect, useState } from 'react';
import './ReviewHistoryItems.scss';
import {
  ControlModel,
  LegalRegisterModel,
  PestelRegisterModel,
  ReviewHistoryItemModel,
  VendorsVendorIdReviewHistoryItemsGetRelatedToTypeEnum,
} from '../../../swagger';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReviewHistoryItems } from '../../../store/compliance/complianceThunks';
import { formatShortDate } from '../../../functions/formatShortDate';
import { ApplicationState } from '../../../types/applicationState';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { NoDataText } from '../../NoDataText/NoDataText';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { formatLongDateTime } from '../../../functions/formatLongDateTime';

interface ReviewHistoryItemsProps {
  type: VendorsVendorIdReviewHistoryItemsGetRelatedToTypeEnum;
  title: string;
  id: ControlModel['id'] | PestelRegisterModel['id'] | LegalRegisterModel['id'];
  noCommentsMode?: boolean; // for control
}

export const ReviewHistoryItems: React.FC<ReviewHistoryItemsProps> = props => {
  const baseCss = 'reviewHistoryItems';
  const baseCss2 = 'reviewHistoryItems--noCommentsMode';

  const dispatch = useDispatch();
  const [reviewHistoryItems, setReviewHistoryItems] = useState<
    ReviewHistoryItemModel[]
  >([]);
  const isFetchingReviewHistoryItems = useSelector(
    (state: ApplicationState) => state.compliance.isFetchingReviewHistoryItems
  );
  useEffect(() => {
    dispatch(
      fetchReviewHistoryItems({
        relatedToId: props.id,
        relatedToType: props.type,
        onSuccess: items => {
          setReviewHistoryItems(items);
        },
      })
    );
  }, []);

  if (props.noCommentsMode)
    return (
      <div className={baseCss2}>
        <div className={baseCss2 + '--fieldBlock'}>
          <div className={baseCss2 + '--field'}>
            <div className={baseCss2 + '--header'}>
              <div>{props.title}</div>
            </div>
            {isFetchingReviewHistoryItems && <LoadingSpinner />}
            {!isFetchingReviewHistoryItems &&
              reviewHistoryItems.length == 0 && (
                <NoDataText text="No review history found" />
              )}
            {!isFetchingReviewHistoryItems &&
              reviewHistoryItems.map(historyItem => {
                return (
                  <div key={historyItem.id} className={baseCss2 + '--tableRow'}>
                    Last reviewed by{' '}
                    <UserAvatar
                      size="small"
                      showTooltip
                      hideText
                      user={historyItem.owner}
                    />{' '}
                    {formatLongDateTime(historyItem.createdAt)}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  return (
    <div className={baseCss}>
      <div className={baseCss + '--header'}>{props.title}</div>
      {isFetchingReviewHistoryItems && <LoadingSpinner />}
      {!isFetchingReviewHistoryItems && reviewHistoryItems.length == 0 && (
        <NoDataText text="No review history found" />
      )}
      {!isFetchingReviewHistoryItems &&
        reviewHistoryItems.map(historyItem => {
          return (
            <div key={historyItem.id} className={baseCss + '--historyItem'}>
              <div className={baseCss + '--commentTitle'}>Comment</div>
              <div
                className={
                  baseCss +
                  (historyItem.comment ? '--historyComment' : '--emptyComment')
                }
              >
                {historyItem.comment || 'N/A'}
              </div>

              <div className={baseCss + '--historyItemFooter'}>
                <div>Submitted by</div>
                <div>{historyItem.owner?.fullName}</div>
                <div>{formatShortDate(historyItem.createdAt)}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
