/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AuditReviewHistoryItem
 */
export interface AuditReviewHistoryItem {
  /**
   *
   * @type {string}
   * @memberof AuditReviewHistoryItem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AuditReviewHistoryItem
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AuditReviewHistoryItem
   */
  rating: AuditReviewHistoryItemRatingEnum;
  /**
   *
   * @type {string}
   * @memberof AuditReviewHistoryItem
   */
  auditorName: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditReviewHistoryItem
   */
  comments: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditReviewHistoryItem
   */
  updatedAt?: string;
}

export function AuditReviewHistoryItemFromJSON(
  json: any
): AuditReviewHistoryItem {
  return AuditReviewHistoryItemFromJSONTyped(json, false);
}

export function AuditReviewHistoryItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuditReviewHistoryItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    rating: json['rating'],
    auditorName: json['auditor_name'],
    comments: json['comments'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function AuditReviewHistoryItemToJSON(
  value?: AuditReviewHistoryItem | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    rating: value.rating,
    auditor_name: value.auditorName,
    comments: value.comments,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AuditReviewHistoryItemRatingEnum {
  Compliant = 'Compliant',
  ImprovementNeeded = 'Improvement Needed',
  NotCompliant = 'Not Compliant',
}
