import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  hasFeature,
  mapShareFeaturesToAccessLevels,
} from '../../functions/access';
import { capitaliseFirst } from '../../functions/capitaliseFirst';
import { formatShortDate } from '../../functions/formatShortDate';
import { getChecklistAssociatedUsername } from '../../functions/getChecklistAssociatedUsername';
import { selectCompletedChecklists } from '../../selectors/selectCompletedChecklists';
import { showShareModal } from '../../store/vendors/vendorsSlice';
import {
  ChecklistStatusEnum,
  ShareableType,
  VendorDocument,
} from '../../swagger';
import { AccessObject } from '../../types/accessObject';
import { Grid } from '../../types/grid';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { Lozenge } from '../Lozenge/Lozenge';
import { MeatballMenu } from '../MeatballMenu/MeatballMenu';
import { NoDataText } from '../NoDataText/NoDataText';
import { SortableTableHeader } from '../SortableTableHeader/SortableTableHeader';
import './CompleteChecklists.scss';

export const CompleteChecklists: React.FC = () => {
  const dispatch = useDispatch();

  const completeChecklists = useSelector(selectCompletedChecklists);

  return (
    <div className="completeChecklists">
      <div className="completeChecklists--header">
        <div className="completeChecklists--title">
          Complete
          <Lozenge value={completeChecklists.length} />
        </div>
      </div>
      {completeChecklists.length > 0 ? (
        <div className="completeChecklists--table">
          <AdoptechTable hasMenu>
            <thead>
              <tr>
                <SortableTableHeader<VendorDocument>
                  columnName="name"
                  grid={Grid.CompleteChecklists}
                  label="Name"
                />
                <th className="completeChecklists--table--cell--username">
                  Username
                </th>
                <th className="completeChecklists--table--cell">Status</th>
                <th className="completeChecklists--table--cell">
                  Date completed
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {completeChecklists.map(checklist => {
                return (
                  <tr
                    className="completeChecklists--row"
                    key={checklist.id}
                    onClick={() =>
                      dispatch(
                        push(`/r/companyInformation/checklists/${checklist.id}`)
                      )
                    }
                  >
                    <td>{checklist.name}</td>
                    <td>
                      {getChecklistAssociatedUsername(checklist.checkable)}
                    </td>
                    <td>{capitaliseFirst(checklist.status)}</td>
                    <td>
                      {checklist.status === ChecklistStatusEnum.Closed
                        ? 'Closed as incomplete'
                        : formatShortDate(checklist.updatedAt)}
                    </td>
                    <td>
                      <div className="adoptechTable--actions">
                        <MeatballMenu>
                          <Dropdown.Item
                            onClick={e => {
                              e.stopPropagation();
                              dispatch(
                                push(
                                  `/r/companyInformation/checklists/${checklist.id}`
                                )
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              className="meatballMenu--icon"
                              icon={faEye}
                            />
                            View
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="meatballMenu--item"
                            disabled={
                              !hasFeature(AccessObject.share, checklist?.access)
                            }
                            onClick={e => {
                              e.stopPropagation();
                              dispatch(
                                showShareModal({
                                  shareableId: checklist.id,
                                  shareableType: ShareableType.Checklist,
                                  accessLevels: mapShareFeaturesToAccessLevels(
                                    checklist.access.feature
                                  ),
                                  relativePath: checklist.relativePath,
                                })
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              className="meatballMenu--icon"
                              icon={faUserPlus}
                            />
                            Share
                          </Dropdown.Item>
                        </MeatballMenu>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </AdoptechTable>
        </div>
      ) : (
        <NoDataText text="Once all actions have been ticked off, completed checklists will appear here" />
      )}
    </div>
  );
};
