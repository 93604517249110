import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Interweave } from 'interweave';
import { NotificationModel } from '../../swagger';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import {
  fetchNotifications,
  markNotificationAsRead,
} from '../../store/notifications/notificationsThunks';
import { selectCurrentVendorUserId } from '../../selectors/selectCurrentVendorUserId';
import { formatRelativeDate } from '../../functions/formatRelativeDate';
import './NotificationsItem.scss';

interface NotificationsItemProps {
  notification: NotificationModel;
}

export const NotificationsItem: React.FC<NotificationsItemProps> = ({
  notification,
}) => {
  const dispatch = useDispatch();
  const vendorUserId = useSelector(selectCurrentVendorUserId);

  const goToEntity = () => {
    dispatch(push(notification.relatedToPath));
  };

  return (
    <Dropdown.Item className="notification-item" onClick={goToEntity}>
      <div className="notification-item--content">
        <div className="notification-item--message">
          <Interweave content={notification.htmlMessage} />
        </div>
        <div className="notification-item--button">
          <AdoptechButton
            variant={AdoptechButtonVariant.Gray}
            onClick={e => {
              e.stopPropagation();
              dispatch(
                markNotificationAsRead({
                  notificationId: notification.id,
                  onSuccess: () => {
                    dispatch(fetchNotifications(vendorUserId, false));
                  },
                })
              );
            }}
          >
            Clear
          </AdoptechButton>
        </div>
      </div>
      <div className="notification-item--footer">
        {formatRelativeDate(notification.createdAt)}
      </div>
    </Dropdown.Item>
  );
};
