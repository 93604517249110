import { faFileExport } from '@fortawesome/pro-light-svg-icons/faFileExport';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import {
  hidePublishAssessmentModal,
  initiateSendAssessment,
  showAssessmentSummaryExportModal,
  showPublishAssessmentModal,
} from '../../store/assessments/assessmentsSlice';
import { publishAssessmentResponse } from '../../store/assessments/assessmentsThunks';
import {
  Assessment,
  AssessmentResponse,
  AssessmentResponseAnswer,
  Question,
  ShareableType,
} from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { AssessmentSummaryExportModal } from '../AssessmentSummaryExportModal/AssessmentSummaryExportModal';
import { GenericModal } from '../GenericModal/GenericModal';
import { Panel } from '../Panel/Panel';
import './AssessmentSummaryTile.scss';
import { showShareModal } from '../../store/vendors/vendorsSlice';
import {
  hasFeature,
  mapShareFeaturesToAccessLevels,
} from '../../functions/access';
import { AccessObject } from '../../types/accessObject';
import { COMPLETED_TEXT } from '../../types/constants';
import getFilteredQuestionsWithoutTableQuestions from '../../functions/getFilteredQuestionsWithoutTableQuestions';
import getCheckboxAnswers from '../../functions/getCheckboxAnswers';
import getTableQuestionsAndAnswers from '../../functions/getTableQuestionsAndAnswers';
import isTableAnswerConfirmed from '../../functions/IsTableAnswerConfirmed';

export enum ActionType {
  'summary',
  'answer',
  'completed',
}

interface AssessmentSummaryTileProps {
  assessment: Assessment | AssessmentResponse;
  actionType: ActionType;
}

interface isQuestionConfirmedProps {
  question: Question;
  answers: AssessmentResponseAnswer[];
  questions: Question[];
}

export const isQuestionConfirmed = ({
  question,
  answers,
  questions,
}: isQuestionConfirmedProps) => {
  if (!question) return false;

  const isTableAnswer = question.layout === 'table_layout';
  const isCheckboxAnswer = question.layout === 'check_boxes';
  const previousAnswer = (answers || []).find(
    (answer: AssessmentResponseAnswer) =>
      (answer.name || answer.propertyName) === question.name
  );

  if (isTableAnswer) {
    const tableQuestions = getTableQuestionsAndAnswers({
      question,
      questions,
      answers,
      indexProperty: 'name',
    });
    return isTableAnswerConfirmed(tableQuestions);
  }
  if (isCheckboxAnswer) {
    const checkboxAnswers = getCheckboxAnswers(question?.questionRows, answers);
    return (
      Boolean(checkboxAnswers?.length) &&
      checkboxAnswers.every(answer => answer.isConfirmed)
    );
  }
  return previousAnswer?.isConfirmed;
};

export const assessmentResponseContext = (response: AssessmentResponse) => {
  if (!response) return {};
  return response.assessmentResponseAnswers.reduce(
    (
      acc: Record<
        AssessmentResponseAnswer['name'],
        AssessmentResponseAnswer['value']
      >,
      answer
    ) => ({
      ...acc,
      [answer.name]: answer.value,
    }),
    {}
  );
};

export const AssessmentSummaryTile: React.FC<AssessmentSummaryTileProps> = ({
  assessment,
  actionType,
}) => {
  const currentAssessmentResponse = useSelector(
    (state: ApplicationState) => state.assessments.currentAssessmentResponse
  );
  const currentAssessment = useSelector(
    (state: ApplicationState) => state.assessments.currentAssessment
  );
  const isShowingPublishAssessmentModal = useSelector(
    (state: ApplicationState) =>
      state.assessments.isShowingPublishAssessmentModal
  );
  const isPublishingAssessmentResponse = useSelector(
    (state: ApplicationState) =>
      state.assessments.isPublishingAssessmentResponse
  );

  const context = assessmentResponseContext(currentAssessmentResponse);

  const questions = currentAssessmentResponse
    ? currentAssessmentResponse.assessmentSections
        ?.map(section => {
          return (
            getFilteredQuestionsWithoutTableQuestions(section, context) || []
          );
        })
        .flat()
    : [];

  const answers = currentAssessmentResponse?.assessmentResponseAnswers;

  const allFilledInAndConfirmed = questions.every(question =>
    isQuestionConfirmed({ questions, answers, question })
  );
  const disableButton =
    (answers || []).length === 0 || !allFilledInAndConfirmed;
  const handleShare = (
    shareableType: ShareableType,
    assessmentData: Assessment | AssessmentResponse
  ) => {
    dispatch(
      showShareModal({
        shareableId: assessmentData.id,
        shareableType,
        accessLevels: mapShareFeaturesToAccessLevels(
          assessmentData.access.feature
        ),
        relativePath: assessmentData.relativePath,
      })
    );
  };

  const dispatch = useDispatch();
  const baseCss = 'assessmentSummaryTile';

  const getActions = () => {
    switch (actionType) {
      case ActionType.summary:
        return (
          <React.Fragment>
            <AdoptechButton
              disabled={
                !hasFeature(AccessObject.share, currentAssessment?.access)
              }
              onClick={() =>
                handleShare(ShareableType.Assessment, currentAssessment)
              }
            >
              <FontAwesomeIcon
                className={baseCss + '--completeIcon'}
                icon={faUserPlus}
              />
              Share
            </AdoptechButton>
            <AdoptechButton
              onClick={() => dispatch(showAssessmentSummaryExportModal())}
              disabled={currentAssessment.status !== COMPLETED_TEXT}
              disabledTooltip="You have no completed responses yet."
            >
              <FontAwesomeIcon
                className={baseCss + '--exportIcon'}
                icon={faFileExport}
              />
              Export
            </AdoptechButton>
            <AdoptechButton
              onClick={() => dispatch(initiateSendAssessment())}
              variant={AdoptechButtonVariant.Primary}
            >
              Send
            </AdoptechButton>
          </React.Fragment>
        );
      case ActionType.answer:
        return (
          <>
            <AdoptechButton
              disabled={disableButton}
              onClick={() => dispatch(showPublishAssessmentModal())}
              variant={AdoptechButtonVariant.SuccessFilled}
              busy={isPublishingAssessmentResponse}
              disabledTooltip="All questions need to be answered and answers confirmed to publish the assessment"
            >
              <FontAwesomeIcon
                className={baseCss + '--completeIcon'}
                icon={faLockAlt}
              />
              Publish
            </AdoptechButton>
            <AdoptechButton
              disabled={
                !hasFeature(
                  AccessObject.share,
                  currentAssessmentResponse?.access
                )
              }
              onClick={() =>
                handleShare(
                  ShareableType.AssessmentResponse,
                  currentAssessmentResponse
                )
              }
            >
              <FontAwesomeIcon
                className={baseCss + '--completeIcon'}
                icon={faUserPlus}
              />
              Share
            </AdoptechButton>
            <AdoptechButton
              onClick={() =>
                dispatch(push('/r/riskManagement/assessments/summary'))
              }
              variant={AdoptechButtonVariant.Primary}
            >
              Close
            </AdoptechButton>
          </>
        );
      case ActionType.completed:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Panel>
        <div className={baseCss}>
          <div className={baseCss + '--mainContent'}>
            <div className={baseCss + '--info'}>
              <div className={baseCss + '--column'}>
                <div className={baseCss + '--columnHeader'}>Name</div>
                <div className={baseCss + '--columnData'}>
                  {assessment?.name}
                </div>
              </div>
              <div className={baseCss + '--column'}>
                <div className={baseCss + '--columnHeader'}>Created by</div>
                <div className={baseCss + '--columnData'}>
                  {actionType === ActionType.summary
                    ? assessment?.vendorName
                    : (assessment as AssessmentResponse)?.requestingVendorName}
                </div>
              </div>
              {actionType !== ActionType.summary && (
                <div className={baseCss + '--column'}>
                  <div className={baseCss + '--columnHeader'}>
                    Last modified
                  </div>
                  <div className={baseCss + '--columnData'}>
                    {formatShortDate(assessment.updatedAt)}
                  </div>
                </div>
              )}
              <div
                className={baseCss + '--column ' + baseCss + '--column-grow'}
              >
                <div className={baseCss + '--column-verticallyCentered'}>
                  {getActions()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Panel>

      <GenericModal
        className={baseCss + '--publishModal'}
        closeButtonLabel="PUBLISH"
        closeButtonVariant={AdoptechButtonVariant.SuccessFilled}
        isBusy={isPublishingAssessmentResponse}
        onCancel={() => dispatch(hidePublishAssessmentModal())}
        onClose={() =>
          dispatch(publishAssessmentResponse(currentAssessmentResponse))
        }
        show={isShowingPublishAssessmentModal}
        showCancelButton
        title="Publish"
      >
        Click on PUBLISH to make your answers available to the Questioner. This
        assessment will be moved to the Complete section.
      </GenericModal>
      <AssessmentSummaryExportModal />
    </React.Fragment>
  );
};
