/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  FrameworkCreatePayloadFramework,
  FrameworkCreatePayloadFrameworkFromJSON,
  FrameworkCreatePayloadFrameworkFromJSONTyped,
  FrameworkCreatePayloadFrameworkToJSON,
} from './';

/**
 *
 * @export
 * @interface FrameworkCreatePayload
 */
export interface FrameworkCreatePayload {
  /**
   *
   * @type {FrameworkCreatePayloadFramework}
   * @memberof FrameworkCreatePayload
   */
  framework: FrameworkCreatePayloadFramework;
}

export function FrameworkCreatePayloadFromJSON(
  json: any
): FrameworkCreatePayload {
  return FrameworkCreatePayloadFromJSONTyped(json, false);
}

export function FrameworkCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    framework: FrameworkCreatePayloadFrameworkFromJSON(json['framework']),
  };
}

export function FrameworkCreatePayloadToJSON(
  value?: FrameworkCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    framework: FrameworkCreatePayloadFrameworkToJSON(value.framework),
  };
}
