/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ErrorResponseErrors,
  ErrorResponseErrorsFromJSON,
  ErrorResponseErrorsFromJSONTyped,
  ErrorResponseErrorsToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorIntegrationSyncModel
 */
export interface VendorIntegrationSyncModel {
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSyncModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSyncModel
   */
  direction: VendorIntegrationSyncModelDirectionEnum;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSyncModel
   */
  subtype: string;
  /**
   *
   * @type {boolean}
   * @memberof VendorIntegrationSyncModel
   */
  daily: boolean;
  /**
   *
   * @type {ErrorResponseErrors}
   * @memberof VendorIntegrationSyncModel
   */
  payload?: ErrorResponseErrors;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSyncModel
   */
  state: VendorIntegrationSyncModelStateEnum;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSyncModel
   */
  lastSyncedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSyncModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSyncModel
   */
  updatedAt?: string;
}

export function VendorIntegrationSyncModelFromJSON(
  json: any
): VendorIntegrationSyncModel {
  return VendorIntegrationSyncModelFromJSONTyped(json, false);
}

export function VendorIntegrationSyncModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorIntegrationSyncModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    direction: json['direction'],
    subtype: json['subtype'],
    daily: json['daily'],
    payload: !exists(json, 'payload')
      ? undefined
      : ErrorResponseErrorsFromJSON(json['payload']),
    state: json['state'],
    lastSyncedAt: !exists(json, 'last_synced_at')
      ? undefined
      : json['last_synced_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function VendorIntegrationSyncModelToJSON(
  value?: VendorIntegrationSyncModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    direction: value.direction,
    subtype: value.subtype,
    daily: value.daily,
    payload: ErrorResponseErrorsToJSON(value.payload),
    state: value.state,
    last_synced_at: value.lastSyncedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum VendorIntegrationSyncModelDirectionEnum {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorIntegrationSyncModelStateEnum {
  Idle = 'idle',
  InProgress = 'in_progress',
  Failed = 'failed',
}
