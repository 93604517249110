import { VendorDocumentAttestation } from '../swagger';
import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { attestationStatusSort } from './attestationStatusSort';
import { sort } from './sort';

export const attestationSort = (
  a: VendorDocumentAttestation,
  b: VendorDocumentAttestation,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof VendorDocumentAttestation =
    columnSort.name as keyof VendorDocumentAttestation;

  switch (typedColumn) {
    case 'status':
      return attestationStatusSort(a.status, b.status, direction);
    default:
      return sort(a[typedColumn], b[typedColumn], direction);
  }
};
