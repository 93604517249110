/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubDocumentExtended,
  TrusthubDocumentExtendedFromJSON,
  TrusthubDocumentExtendedFromJSONTyped,
  TrusthubDocumentExtendedToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubSectionModel
 */
export interface TrusthubSectionModel {
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionModel
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionModel
   */
  updatedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionModel
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubSectionModel
   */
  description: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TrusthubSectionModel
   */
  required: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TrusthubSectionModel
   */
  visible: boolean;
  /**
   *
   * @type {number}
   * @memberof TrusthubSectionModel
   */
  rowOrder?: number | null;
  /**
   *
   * @type {Array<TrusthubDocumentExtended>}
   * @memberof TrusthubSectionModel
   */
  trusthubDocuments?: Array<TrusthubDocumentExtended>;
}

export function TrusthubSectionModelFromJSON(json: any): TrusthubSectionModel {
  return TrusthubSectionModelFromJSONTyped(json, false);
}

export function TrusthubSectionModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubSectionModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    name: json['name'],
    identifier: json['identifier'],
    description: json['description'],
    required: json['required'],
    visible: json['visible'],
    rowOrder: !exists(json, 'row_order') ? undefined : json['row_order'],
    trusthubDocuments: !exists(json, 'trusthub_documents')
      ? undefined
      : (json['trusthub_documents'] as Array<any>).map(
          TrusthubDocumentExtendedFromJSON
        ),
  };
}

export function TrusthubSectionModelToJSON(
  value?: TrusthubSectionModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    name: value.name,
    identifier: value.identifier,
    description: value.description,
    required: value.required,
    visible: value.visible,
    row_order: value.rowOrder,
    trusthub_documents:
      value.trusthubDocuments === undefined
        ? undefined
        : (value.trusthubDocuments as Array<any>).map(
            TrusthubDocumentExtendedToJSON
          ),
  };
}
