/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorsCreatePayloadCompany
 */
export interface VendorsCreatePayloadCompany {
  /**
   *
   * @type {string}
   * @memberof VendorsCreatePayloadCompany
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof VendorsCreatePayloadCompany
   */
  legalName?: string;
  /**
   *
   * @type {string}
   * @memberof VendorsCreatePayloadCompany
   */
  countryOfIncorporation?: string;
  /**
   *
   * @type {string}
   * @memberof VendorsCreatePayloadCompany
   */
  registeredAddress?: string;
}

export function VendorsCreatePayloadCompanyFromJSON(
  json: any
): VendorsCreatePayloadCompany {
  return VendorsCreatePayloadCompanyFromJSONTyped(json, false);
}

export function VendorsCreatePayloadCompanyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorsCreatePayloadCompany {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    legalName: !exists(json, 'legal_name') ? undefined : json['legal_name'],
    countryOfIncorporation: !exists(json, 'country_of_incorporation')
      ? undefined
      : json['country_of_incorporation'],
    registeredAddress: !exists(json, 'registered_address')
      ? undefined
      : json['registered_address'],
  };
}

export function VendorsCreatePayloadCompanyToJSON(
  value?: VendorsCreatePayloadCompany | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    legal_name: value.legalName,
    country_of_incorporation: value.countryOfIncorporation,
    registered_address: value.registeredAddress,
  };
}
