import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';
import { MessageToast } from '../MessageToast/MessageToast';
import { hideGlobalToast } from '../../store/global/globalSlice';
import './GlobalToast.scss';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { unarchiveAgreement } from '../../store/agreements/agreementsThunks';
import classNames from 'classnames';
import { unarchiveVendorDocument } from '../../store/vendors/vendorsThunks';

export const GlobalToast: React.FC = () => {
  const dispatch = useDispatch();

  const { isShowing, text, undoButton } = useSelector(
    (state: ApplicationState) => state.global.globalToastConfig
  );

  // In trusthub user part we don't use router for now => can be null
  const route = useSelector(
    (state: ApplicationState) => state.router?.location?.pathname
  );

  return (
    <div
      className={classNames('globalToast', {
        'globalToast--with-undo-button': undoButton,
      })}
    >
      <MessageToast
        autohide
        delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
        onClose={() => {
          dispatch(hideGlobalToast());
        }}
        show={isShowing}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="globalToast--text">{text}</div>
          {undoButton && (
            <div className="globalToast--undo">
              <AdoptechButton
                onClick={() => {
                  route && route.endsWith('agreements')
                    ? dispatch(unarchiveAgreement({ showToast: false }))
                    : route.endsWith('documents')
                      ? dispatch(unarchiveVendorDocument())
                      : '';
                  dispatch(hideGlobalToast());
                }}
                variant={AdoptechButtonVariant.Inverse}
              >
                Undo
              </AdoptechButton>
            </div>
          )}
        </div>
      </MessageToast>
    </div>
  );
};
