/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorDocumentAttestationStats
 */
export interface VendorDocumentAttestationStats {
  /**
   *
   * @type {number}
   * @memberof VendorDocumentAttestationStats
   */
  attested?: number;
  /**
   *
   * @type {number}
   * @memberof VendorDocumentAttestationStats
   */
  total?: number;
}

export function VendorDocumentAttestationStatsFromJSON(
  json: any
): VendorDocumentAttestationStats {
  return VendorDocumentAttestationStatsFromJSONTyped(json, false);
}

export function VendorDocumentAttestationStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorDocumentAttestationStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    attested: !exists(json, 'attested') ? undefined : json['attested'],
    total: !exists(json, 'total') ? undefined : json['total'],
  };
}

export function VendorDocumentAttestationStatsToJSON(
  value?: VendorDocumentAttestationStats | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    attested: value.attested,
    total: value.total,
  };
}
