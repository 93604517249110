/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrusthubUserSignUpPayloadTrusthubUser
 */
export interface TrusthubUserSignUpPayloadTrusthubUser {
  /**
   *
   * @type {string}
   * @memberof TrusthubUserSignUpPayloadTrusthubUser
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubUserSignUpPayloadTrusthubUser
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubUserSignUpPayloadTrusthubUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubUserSignUpPayloadTrusthubUser
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubUserSignUpPayloadTrusthubUser
   */
  passwordConfirmation?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubUserSignUpPayloadTrusthubUser
   */
  domain: string;
}

export function TrusthubUserSignUpPayloadTrusthubUserFromJSON(
  json: any
): TrusthubUserSignUpPayloadTrusthubUser {
  return TrusthubUserSignUpPayloadTrusthubUserFromJSONTyped(json, false);
}

export function TrusthubUserSignUpPayloadTrusthubUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubUserSignUpPayloadTrusthubUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    email: json['email'],
    password: json['password'],
    passwordConfirmation: !exists(json, 'password_confirmation')
      ? undefined
      : json['password_confirmation'],
    domain: json['domain'],
  };
}

export function TrusthubUserSignUpPayloadTrusthubUserToJSON(
  value?: TrusthubUserSignUpPayloadTrusthubUser | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    first_name: value.firstName,
    last_name: value.lastName,
    email: value.email,
    password: value.password,
    password_confirmation: value.passwordConfirmation,
    domain: value.domain,
  };
}
