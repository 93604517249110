/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SharedPaginationMeta
 */
export interface SharedPaginationMeta {
  /**
   *
   * @type {number}
   * @memberof SharedPaginationMeta
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof SharedPaginationMeta
   */
  totalEntries: number;
  /**
   *
   * @type {number}
   * @memberof SharedPaginationMeta
   */
  perPage: number;
  /**
   *
   * @type {number}
   * @memberof SharedPaginationMeta
   */
  page: number;
}

export function SharedPaginationMetaFromJSON(json: any): SharedPaginationMeta {
  return SharedPaginationMetaFromJSONTyped(json, false);
}

export function SharedPaginationMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SharedPaginationMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    totalPages: json['total_pages'],
    totalEntries: json['total_entries'],
    perPage: json['per_page'],
    page: json['page'],
  };
}

export function SharedPaginationMetaToJSON(
  value?: SharedPaginationMeta | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total_pages: value.totalPages,
    total_entries: value.totalEntries,
    per_page: value.perPage,
    page: value.page,
  };
}
