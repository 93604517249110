/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PestelItemArea,
  PestelItemAreaFromJSON,
  PestelItemAreaFromJSONTyped,
  PestelItemAreaToJSON,
  PestelItemItemType,
  PestelItemItemTypeFromJSON,
  PestelItemItemTypeFromJSONTyped,
  PestelItemItemTypeToJSON,
  PestelItemLocationType,
  PestelItemLocationTypeFromJSON,
  PestelItemLocationTypeFromJSONTyped,
  PestelItemLocationTypeToJSON,
} from './';

/**
 *
 * @export
 * @interface PestelItemCreatePayloadPestelItem
 */
export interface PestelItemCreatePayloadPestelItem {
  /**
   *
   * @type {string}
   * @memberof PestelItemCreatePayloadPestelItem
   */
  description: string;
  /**
   *
   * @type {PestelItemArea}
   * @memberof PestelItemCreatePayloadPestelItem
   */
  area: PestelItemArea;
  /**
   *
   * @type {PestelItemItemType}
   * @memberof PestelItemCreatePayloadPestelItem
   */
  itemType: PestelItemItemType;
  /**
   *
   * @type {PestelItemLocationType}
   * @memberof PestelItemCreatePayloadPestelItem
   */
  locationType: PestelItemLocationType;
  /**
   *
   * @type {number}
   * @memberof PestelItemCreatePayloadPestelItem
   */
  consequence: number;
  /**
   *
   * @type {number}
   * @memberof PestelItemCreatePayloadPestelItem
   */
  likelihood: number;
  /**
   *
   * @type {string}
   * @memberof PestelItemCreatePayloadPestelItem
   */
  mitigationFactors?: string | null;
  /**
   *
   * @type {string}
   * @memberof PestelItemCreatePayloadPestelItem
   */
  comment?: string | null;
}

export function PestelItemCreatePayloadPestelItemFromJSON(
  json: any
): PestelItemCreatePayloadPestelItem {
  return PestelItemCreatePayloadPestelItemFromJSONTyped(json, false);
}

export function PestelItemCreatePayloadPestelItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PestelItemCreatePayloadPestelItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    description: json['description'],
    area: PestelItemAreaFromJSON(json['area']),
    itemType: PestelItemItemTypeFromJSON(json['item_type']),
    locationType: PestelItemLocationTypeFromJSON(json['location_type']),
    consequence: json['consequence'],
    likelihood: json['likelihood'],
    mitigationFactors: !exists(json, 'mitigation_factors')
      ? undefined
      : json['mitigation_factors'],
    comment: !exists(json, 'comment') ? undefined : json['comment'],
  };
}

export function PestelItemCreatePayloadPestelItemToJSON(
  value?: PestelItemCreatePayloadPestelItem | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    area: PestelItemAreaToJSON(value.area),
    item_type: PestelItemItemTypeToJSON(value.itemType),
    location_type: PestelItemLocationTypeToJSON(value.locationType),
    consequence: value.consequence,
    likelihood: value.likelihood,
    mitigation_factors: value.mitigationFactors,
    comment: value.comment,
  };
}
