/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  UserDetails,
  UserDetailsFromJSON,
  UserDetailsFromJSONTyped,
  UserDetailsToJSON,
} from './';

/**
 *
 * @export
 * @interface DataRoomEntityModel
 */
export interface DataRoomEntityModel {
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityModel
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityModel
   */
  type: DataRoomEntityModelTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityModel
   */
  fileType: string | null;
  /**
   *
   * @type {number}
   * @memberof DataRoomEntityModel
   */
  fileSize?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityModel
   */
  filePath: string;
  /**
   *
   * @type {Access}
   * @memberof DataRoomEntityModel
   */
  access?: Access;
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityModel
   */
  attachmentUrl?: string;
  /**
   *
   * @type {UserDetails}
   * @memberof DataRoomEntityModel
   */
  creator?: UserDetails;
  /**
   *
   * @type {Array<DataRoomEntityModel>}
   * @memberof DataRoomEntityModel
   */
  children: Array<DataRoomEntityModel>;
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof DataRoomEntityModel
   */
  updatedAt: string;
}

export function DataRoomEntityModelFromJSON(json: any): DataRoomEntityModel {
  return DataRoomEntityModelFromJSONTyped(json, false);
}

export function DataRoomEntityModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DataRoomEntityModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    type: json['type'],
    fileType: json['file_type'],
    fileSize: !exists(json, 'file_size') ? undefined : json['file_size'],
    filePath: json['file_path'],
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    attachmentUrl: !exists(json, 'attachment_url')
      ? undefined
      : json['attachment_url'],
    creator: !exists(json, 'creator')
      ? undefined
      : UserDetailsFromJSON(json['creator']),
    children: (json['children'] as Array<any>).map(DataRoomEntityModelFromJSON),
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function DataRoomEntityModelToJSON(
  value?: DataRoomEntityModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    type: value.type,
    file_type: value.fileType,
    file_size: value.fileSize,
    file_path: value.filePath,
    access: AccessToJSON(value.access),
    attachment_url: value.attachmentUrl,
    creator: UserDetailsToJSON(value.creator),
    children: (value.children as Array<any>).map(DataRoomEntityModelToJSON),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum DataRoomEntityModelTypeEnum {
  File = 'DataRoomEntity::File',
  Folder = 'DataRoomEntity::Folder',
}
