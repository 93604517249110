/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ControlTemplateExtendedAllOf,
  ControlTemplateExtendedAllOfFromJSON,
  ControlTemplateExtendedAllOfFromJSONTyped,
  ControlTemplateExtendedAllOfToJSON,
  ControlTemplateModel,
  ControlTemplateModelFromJSON,
  ControlTemplateModelFromJSONTyped,
  ControlTemplateModelToJSON,
  TaskTemplateModel,
  TaskTemplateModelFromJSON,
  TaskTemplateModelFromJSONTyped,
  TaskTemplateModelToJSON,
} from './';

/**
 *
 * @export
 * @interface ControlTemplateExtended
 */
export interface ControlTemplateExtended extends ControlTemplateModel {
  /**
   *
   * @type {Array<TaskTemplateModel>}
   * @memberof ControlTemplateExtended
   */
  taskTemplates: Array<TaskTemplateModel>;
}

export function ControlTemplateExtendedFromJSON(
  json: any
): ControlTemplateExtended {
  return ControlTemplateExtendedFromJSONTyped(json, false);
}

export function ControlTemplateExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ControlTemplateExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...ControlTemplateModelFromJSONTyped(json, ignoreDiscriminator),
    taskTemplates: (json['task_templates'] as Array<any>).map(
      TaskTemplateModelFromJSON
    ),
  };
}

export function ControlTemplateExtendedToJSON(
  value?: ControlTemplateExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...ControlTemplateModelToJSON(value),
    task_templates: (value.taskTemplates as Array<any>).map(
      TaskTemplateModelToJSON
    ),
  };
}
