import moment from 'moment';
import { VendorPolicyDetails } from '../swagger';
import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';

export const vendorPolicySort = (
  a: VendorPolicyDetails,
  b: VendorPolicyDetails,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof VendorPolicyDetails =
    columnSort.name as keyof VendorPolicyDetails;

  switch (typedColumn) {
    case 'updatedAt':
      return sort(moment(a.updatedAt), moment(b.updatedAt), direction);
    case 'vendorPolicyAnswers':
    case 'vendorPolicyClauses':
      return 0;
    default:
      return sort(a[typedColumn], b[typedColumn], direction);
  }
};
