/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ReminderUpdatePayloadReminder,
  ReminderUpdatePayloadReminderFromJSON,
  ReminderUpdatePayloadReminderFromJSONTyped,
  ReminderUpdatePayloadReminderToJSON,
} from './';

/**
 *
 * @export
 * @interface ReminderUpdatePayload
 */
export interface ReminderUpdatePayload {
  /**
   *
   * @type {ReminderUpdatePayloadReminder}
   * @memberof ReminderUpdatePayload
   */
  reminder: ReminderUpdatePayloadReminder;
}

export function ReminderUpdatePayloadFromJSON(
  json: any
): ReminderUpdatePayload {
  return ReminderUpdatePayloadFromJSONTyped(json, false);
}

export function ReminderUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReminderUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    reminder: ReminderUpdatePayloadReminderFromJSON(json['reminder']),
  };
}

export function ReminderUpdatePayloadToJSON(
  value?: ReminderUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    reminder: ReminderUpdatePayloadReminderToJSON(value.reminder),
  };
}
