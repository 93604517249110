/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorIntegrationSetting,
  VendorIntegrationSettingFromJSON,
  VendorIntegrationSettingFromJSONTyped,
  VendorIntegrationSettingToJSON,
  VendorIntegrationSyncModel,
  VendorIntegrationSyncModelFromJSON,
  VendorIntegrationSyncModelFromJSONTyped,
  VendorIntegrationSyncModelToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorIntegrationModel
 */
export interface VendorIntegrationModel {
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  status?: VendorIntegrationModelStatusEnum;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  learnMore?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  learnMoreAsHtml?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  serviceStatus?: VendorIntegrationModelServiceStatusEnum;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  serviceMessage?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  authorizationMethod?: VendorIntegrationModelAuthorizationMethodEnum;
  /**
   *
   * @type {Array<VendorIntegrationSetting>}
   * @memberof VendorIntegrationModel
   */
  settings?: Array<VendorIntegrationSetting>;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  authorizeUrl?: string | null;
  /**
   *
   * @type {Array<VendorIntegrationSyncModel>}
   * @memberof VendorIntegrationModel
   */
  vendorIntegrationSyncs?: Array<VendorIntegrationSyncModel>;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationModel
   */
  updatedAt?: string;
}

export function VendorIntegrationModelFromJSON(
  json: any
): VendorIntegrationModel {
  return VendorIntegrationModelFromJSONTyped(json, false);
}

export function VendorIntegrationModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorIntegrationModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    type: !exists(json, 'type') ? undefined : json['type'],
    status: !exists(json, 'status') ? undefined : json['status'],
    learnMore: !exists(json, 'learn_more') ? undefined : json['learn_more'],
    learnMoreAsHtml: !exists(json, 'learn_more_as_html')
      ? undefined
      : json['learn_more_as_html'],
    serviceStatus: !exists(json, 'service_status')
      ? undefined
      : json['service_status'],
    serviceMessage: !exists(json, 'service_message')
      ? undefined
      : json['service_message'],
    authorizationMethod: !exists(json, 'authorization_method')
      ? undefined
      : json['authorization_method'],
    settings: !exists(json, 'settings')
      ? undefined
      : (json['settings'] as Array<any>).map(VendorIntegrationSettingFromJSON),
    authorizeUrl: !exists(json, 'authorize_url')
      ? undefined
      : json['authorize_url'],
    vendorIntegrationSyncs: !exists(json, 'vendor_integration_syncs')
      ? undefined
      : (json['vendor_integration_syncs'] as Array<any>).map(
          VendorIntegrationSyncModelFromJSON
        ),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function VendorIntegrationModelToJSON(
  value?: VendorIntegrationModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    type: value.type,
    status: value.status,
    learn_more: value.learnMore,
    learn_more_as_html: value.learnMoreAsHtml,
    service_status: value.serviceStatus,
    service_message: value.serviceMessage,
    authorization_method: value.authorizationMethod,
    settings:
      value.settings === undefined
        ? undefined
        : (value.settings as Array<any>).map(VendorIntegrationSettingToJSON),
    authorize_url: value.authorizeUrl,
    vendor_integration_syncs:
      value.vendorIntegrationSyncs === undefined
        ? undefined
        : (value.vendorIntegrationSyncs as Array<any>).map(
            VendorIntegrationSyncModelToJSON
          ),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum VendorIntegrationModelStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorIntegrationModelServiceStatusEnum {
  Ok = 'ok',
  Error = 'error',
}
/**
 * @export
 * @enum {string}
 */
export enum VendorIntegrationModelAuthorizationMethodEnum {
  Oauth = 'oauth',
  ApiKey = 'api_key',
  ApiKeyWithSecret = 'api_key_with_secret',
  AppInstall = 'app_install',
}
