import React, { useMemo } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { selectAttestations } from '../../selectors/selectAttestations';
import {
  VendorDocumentAttestation,
  VendorDocumentExtended,
} from '../../swagger';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { AttestedStatus } from '../AttestedStatus/AttestedStatus';
import './SentAttestationsTable.scss';
import { formatShortDate } from '../../functions/formatShortDate';
import { NoDataText } from '../NoDataText/NoDataText';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { ApplicationState } from '../../types/applicationState';
import AdoptechOverflowLine from '../AdoptechOverflowLine/AdoptechOverflowLine';
import { selectUsers } from '../../selectors/selectUsers';

interface SentAttestationsTableProps {
  tableClass: 'smallPadding';
  attestations: VendorDocumentAttestation[];
  mode?: 'vendorDocument' | 'vendorUser';
}

export const useSentAttestations = (
  currentDocument: VendorDocumentExtended
) => {
  const attestations = useSelector(selectAttestations(currentDocument)) || [];
  const sentAttestations = useMemo(
    () =>
      attestations.filter(
        (attestation: VendorDocumentAttestation) =>
          attestation.attestationDueAt || attestation.lastAttestedAt
      ),
    [attestations]
  );

  return { sentAttestations };
};

export const SentAttestationsTable: React.FC<SentAttestationsTableProps> = ({
  tableClass,
  attestations,
  mode = 'vendorDocument',
}) => {
  const sentAttestations = attestations;

  const vendorUsers = useSelector((state: ApplicationState) =>
    selectUsers(state)
  );

  const isVendorDocumentMode = mode === 'vendorDocument';
  const columns = isVendorDocumentMode
    ? ['People', 'Deadline', 'Status']
    : ['Policy', 'Read request deadline', 'Status'];

  const baseCss = 'sentAttestationsTable';
  return (
    <div className={baseCss}>
      {sentAttestations?.length ? (
        <AdoptechTable modal className={tableClass}>
          <thead>
            <tr>
              <th>{columns[0]}</th>
              <th>{columns[1]}</th>
              <th>{columns[2]}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {sentAttestations?.map((attestation: VendorDocumentAttestation) => {
              const vendorUser = vendorUsers.find(
                currentVendorUser =>
                  currentVendorUser.id === attestation.vendorUserId
              );

              return (
                <tr key={`${attestation.vendorUserId}-${attestation.id}`}>
                  <td
                    className={
                      isVendorDocumentMode ? 'emailField' : 'policyNameField'
                    }
                  >
                    {isVendorDocumentMode ? (
                      <UserAvatar
                        user={vendorUser}
                        size="large"
                        showUserEmail={false}
                      />
                    ) : (
                      <AdoptechOverflowLine>
                        {attestation.documentName}
                      </AdoptechOverflowLine>
                    )}
                  </td>
                  <td>{formatShortDate(attestation.attestationDueAt)}</td>
                  <td>
                    <AttestedStatus attestation={attestation} />
                  </td>
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </AdoptechTable>
      ) : (
        <NoDataText
          text="No read requests sent"
          extraClass="sentAttestationsTable--noDataText"
        />
      )}
    </div>
  );
};
