/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorIntegrationSyncCreatePayloadVendorIntegrationSync
 */
export interface VendorIntegrationSyncCreatePayloadVendorIntegrationSync {
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSyncCreatePayloadVendorIntegrationSync
   */
  direction?: VendorIntegrationSyncCreatePayloadVendorIntegrationSyncDirectionEnum;
  /**
   *
   * @type {boolean}
   * @memberof VendorIntegrationSyncCreatePayloadVendorIntegrationSync
   */
  daily?: boolean;
  /**
   *
   * @type {string}
   * @memberof VendorIntegrationSyncCreatePayloadVendorIntegrationSync
   */
  subtype?: string;
}

export function VendorIntegrationSyncCreatePayloadVendorIntegrationSyncFromJSON(
  json: any
): VendorIntegrationSyncCreatePayloadVendorIntegrationSync {
  return VendorIntegrationSyncCreatePayloadVendorIntegrationSyncFromJSONTyped(
    json,
    false
  );
}

export function VendorIntegrationSyncCreatePayloadVendorIntegrationSyncFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorIntegrationSyncCreatePayloadVendorIntegrationSync {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    direction: !exists(json, 'direction') ? undefined : json['direction'],
    daily: !exists(json, 'daily') ? undefined : json['daily'],
    subtype: !exists(json, 'subtype') ? undefined : json['subtype'],
  };
}

export function VendorIntegrationSyncCreatePayloadVendorIntegrationSyncToJSON(
  value?: VendorIntegrationSyncCreatePayloadVendorIntegrationSync | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    direction: value.direction,
    daily: value.daily,
    subtype: value.subtype,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum VendorIntegrationSyncCreatePayloadVendorIntegrationSyncDirectionEnum {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}
