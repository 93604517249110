import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsViewModeTaskDrawer } from '../../../../../selectors/compliance/selectIsViewModeEditTaskDrawer';
import AdoptechIcon from '../../AdoptechIcon/AdoptechIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { EditActionModal } from '../../../../../components/EditActionModal/EditActionModal';
import { VendorActionModel } from '../../../../../swagger/models/VendorActionModel';
import {
  createActionReference,
  createNewAction,
} from '../../../../calendar/store/calendarThunks';
import { ApplicationState } from '../../../../../types/applicationState';
import { showGlobalToast } from '../../../../../store/global/globalSlice';
import { ReferenceCreatePayloadReferenceReferenceableTypeEnum } from '../../../../../swagger/models/ReferenceCreatePayloadReference';
import { VendorEventEventType } from '../../../../../swagger/models/VendorEventEventType';
import { ComplianceEditTaskFormProps } from '../ComplianceEditTaskForm/ComplianceEditTaskForm';
import {
  CalendarHSList,
  mapVendorActionToItem,
} from '../../../../calendar/CalendarHS/CalendarHS';
import {
  CalendarItemModel,
  VendorActionExtended,
} from '../../../../../swagger';
import './ControlCheckCalendarActions.scss';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { mapVendorActonFormToPostPayload } from '../../../../calendar/CalendarPage/CalendarPageModals';

export const ControlCheckCalendarActions: React.FC<
  ComplianceEditTaskFormProps
> = props => {
  const baseCss = 'controlCheckCalendarActions';
  const isViewMode = useSelector(selectIsViewModeTaskDrawer);
  const childClass = (name: string) => `${baseCss}--${name}`;
  const dispatch = useAppDispatch();
  const [showEditActionModal, setShowEditActionModal] = useState(false);
  const { tempTask } = useSelector(
    (state: ApplicationState) => state.compliance
  );
  const createAction = async (params: VendorActionExtended) => {
    const createPayload = mapVendorActonFormToPostPayload(params);

    const afterCreate = (
      action: VendorActionExtended,
      _oldAction?: VendorActionExtended
    ) => {
      dispatch(
        createActionReference(
          action.id,
          tempTask.id,
          ReferenceCreatePayloadReferenceReferenceableTypeEnum.Task,
          () => {
            setShowEditActionModal(false);
            const vendorActions = [
              ...tempTask.vendorActions,
              action,
            ] as VendorActionModel[];
            const newTask = {
              ...tempTask,
              ...{ vendorActions },
            };
            props.onChange(newTask);
            dispatch(showGlobalToast('Action was successfully saved'));
          }
        )
      );
    };

    const newAction = await dispatch(
      createNewAction({ payload: createPayload })
    ).unwrap();

    afterCreate(newAction);
  };

  const calendarItems: CalendarItemModel[] = (
    tempTask?.vendorActions || []
  ).map(vendorAction => {
    return mapVendorActionToItem(vendorAction);
  });
  return (
    <>
      <div className={childClass('header')}>
        <div>Actions</div>
        {!isViewMode && (
          <AdoptechIcon
            shape="square"
            onClick={() => setShowEditActionModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </AdoptechIcon>
        )}
        {showEditActionModal && (
          <EditActionModal
            init={{
              actionType: VendorEventEventType.ControlCheckReview,
            }}
            confirm={createAction}
            disabledFields={['actionType']}
            show={showEditActionModal}
            close={() => setShowEditActionModal(false)}
          />
        )}
      </div>
      <CalendarHSList
        showAllItems
        items={calendarItems}
        extraClass="wide-table"
      />
    </>
  );
};
