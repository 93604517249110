/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {string}
   * @memberof Product
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  internalName?: string;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  stripePriceId?: string;
}

export function ProductFromJSON(json: any): Product {
  return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Product {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    internalName: !exists(json, 'internal_name')
      ? undefined
      : json['internal_name'],
    price: !exists(json, 'price') ? undefined : json['price'],
    stripePriceId: !exists(json, 'stripe_price_id')
      ? undefined
      : json['stripe_price_id'],
  };
}

export function ProductToJSON(value?: Product | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    internal_name: value.internalName,
    price: value.price,
    stripe_price_id: value.stripePriceId,
  };
}
