import React from 'react';
import { PoliciesTabs } from '../PoliciesTabs/PoliciesTabs';
import { ArchivedDocuments } from '../../../components/ArchivedDocuments/ArchivedDocuments';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { useFetchVendorDocuments } from '../AllDocumentsPage/AllDocumentsPage';
import { DeleteVendorDocumentModal } from '../../../components/DeleteVendorDocumentModal/DeleteVendorDocumentModal';

export const ArchivedDocumentsPage: React.FC = () => {
  const { isFetchingVendorDocuments } = useFetchVendorDocuments();

  return (
    <div>
      <PoliciesTabs currentTab="archived" />
      {isFetchingVendorDocuments ? <LoadingSpinner /> : <ArchivedDocuments />}
      <DeleteVendorDocumentModal />
    </div>
  );
};
