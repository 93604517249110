import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllAssessmentPendingResponses } from '../../selectors/selectAllAssessmentPendingResponses';
import {
  hideAssessmentResentToast,
  showConfirmDeleteAssessmentResponseModal,
  showResendAssessmentConfirmationModal,
} from '../../store/assessments/assessmentsSlice';
import { AssessmentRecipient, AssessmentResponse } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { AdoptechButton } from '../AdoptechButton/AdoptechButton';
import { ConfirmDeleteAssessmentResponseModal } from '../ConfirmDeleteAssessmentResponseModal/ConfirmDeleteAssessmentResponseModal';
import { IconButton } from '../IconButton/IconButton';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { MessageToast } from '../MessageToast/MessageToast';
import { Panel } from '../Panel/Panel';
import { ResendAssessmentConfirmationModal } from '../ResendAssessmentConfirmationModal/ResendAssessmentConfirmationModal';
import './AssessmentPendingResponses.scss';

export const AssessmentPendingResponses: React.FC = () => {
  const dispatch = useDispatch();

  const responses = useSelector(selectAllAssessmentPendingResponses);
  const isAssessmentResentToastShowing = useSelector(
    (state: ApplicationState) =>
      state.assessments.isAssessmentResentToastShowing
  );
  if (!responses) {
    return <LoadingSpinner />;
  }

  const handleReminderClick =
    (response: AssessmentRecipient & AssessmentResponse) => () => {
      dispatch(showResendAssessmentConfirmationModal(response));
    };

  return (
    <React.Fragment>
      <div className="assessmentPendingResponses">
        <div className="assessmentPendingResponses--header">
          <div className="assessmentPendingResponses--pendingHeader">
            Pending ({responses.length})
          </div>
        </div>
        <div className="assessmentPendingResponses--body">
          {!responses.length && (
            <Panel>
              <div className="assessmentPendingResponses--panelContent">
                No responses
              </div>
            </Panel>
          )}
          {responses.map(response => {
            const stateIconClasses = classNames({
              'assessmentPendingResponses--stateIcon': true,
              'assessmentPendingResponses--stateIcon-orange':
                response.assessmentResponseAnswers?.length > 0,
            });

            return (
              <div
                className="assessmentPendingResponses--assessmentResponse"
                key={response.recipientId}
              >
                <Panel>
                  <div className="assessmentPendingResponses--panelContent">
                    <div className="assessmentPendingResponses--vendorName">
                      {response.vendorName || response.email}
                    </div>
                    <div className="assessmentPendingResponses--lastModified"></div>
                    <div className="assessmentPendingResponses--reminderButton">
                      <AdoptechButton onClick={handleReminderClick(response)}>
                        <FontAwesomeIcon
                          className="assessmentPendingResponses--reminderIcon"
                          icon={faEnvelope}
                        />
                        Reminder
                      </AdoptechButton>
                      <IconButton
                        ariaLabel="Delete"
                        className="assessmentPendingResponses--delete"
                        icon={faTrashAlt}
                        onClick={() => {
                          dispatch(
                            showConfirmDeleteAssessmentResponseModal(response)
                          );
                        }}
                        tooltip="Delete"
                      />
                    </div>
                  </div>
                </Panel>
              </div>
            );
          })}
        </div>
      </div>

      <MessageToast
        onClose={() => dispatch(hideAssessmentResentToast())}
        show={isAssessmentResentToastShowing}
        showClose
      >
        Your reminder has been sent.
      </MessageToast>

      <ConfirmDeleteAssessmentResponseModal />
      <ResendAssessmentConfirmationModal />
    </React.Fragment>
  );
};
