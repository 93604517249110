import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { SyntheticEvent } from 'react';
import { Spinner } from 'react-bootstrap';
import './IconButton.scss';

interface IconButtonProps {
  ariaLabel: string;
  className?: string;
  disabled?: boolean;
  icon: IconDefinition;
  isBusy?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  tooltip?: string;
}

export const IconButton: React.FC<IconButtonProps> = props => {
  const iconButtonClasses = classNames(
    { iconButton: true, 'iconButton--disabled': props.disabled },
    props.className
  );

  return (
    <div
      aria-label={props.ariaLabel}
      className={iconButtonClasses}
      onClick={e => {
        if (props.disabled) {
          return;
        }
        if (!props.onClick) {
          return;
        }
        props.onClick(e);
      }}
      onKeyPress={e => {
        if (props.disabled) {
          return;
        }
        if (e.currentTarget === e.target && e.key === 'Enter') {
          props.onClick(e);
        }
      }}
      tabIndex={0}
      role="button"
      title={props.tooltip}
    >
      {props.isBusy ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <FontAwesomeIcon icon={props.icon} />
      )}
    </div>
  );
};

IconButton.defaultProps = {
  ariaLabel: 'Button',
};
