import React from 'react';
import { getClauseLastUpdatedInfo } from '../../../functions/getClauseLastUpdatedInfo';

import {
  AgreementExtended,
  Question,
  SurveyClause,
} from '../../../swagger/models';
import { AgreementQuestion } from '../AgreementQuestion/AgreementQuestion';
import { LastUpdatedInfo } from '../../LastUpdatedInfo/LastUpdatedInfo';
import './AgreementClauseQuestions.scss';
import { dentaku } from '../../../functions/dentaku';

interface AgreementClauseQuestionsProps {
  agreementClause: SurveyClause;
  agreement: AgreementExtended;
}

export const selectVisibleClauseQuestions = (clause: SurveyClause) => {
  const context = clause.questions.reduce(
    (acc: Record<string, string>, { answer }) =>
      answer
        ? {
            ...acc,
            [answer.propertyName]: answer.value,
          }
        : acc,
    {}
  );
  const questions = clause.questions.filter(q => dentaku(q.showWhen, context));

  return questions;
};
export const AgreementClauseQuestions: React.FC<
  AgreementClauseQuestionsProps
> = ({ agreementClause, agreement }) => {
  const lastUpdated = getClauseLastUpdatedInfo(
    agreementClause.updatedAt,
    agreementClause.updatedBy?.fullName
  );

  const questions = selectVisibleClauseQuestions(agreementClause);

  return (
    <React.Fragment>
      {questions.length > 0 &&
        questions.map((question: Question) => (
          <AgreementQuestion
            agreement={agreement}
            key={question.id}
            question={question}
          />
        ))}

      {lastUpdated && (
        <div className="agreementClauseQuestions--footer">
          <LastUpdatedInfo
            title="Clause last updated"
            lastUpdatedInfo={lastUpdated}
          />
        </div>
      )}
    </React.Fragment>
  );
};
