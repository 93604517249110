import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { setErrorMessage } from '../store/global/globalSlice';

export const errorHandler: Middleware =
  (api: MiddlewareAPI) => (next: Dispatch) => action => {
    const apiFailureRegEx = /^.*failure$/gim;
    if (apiFailureRegEx.test(action.type)) {
      console.log('Error', action.type, action.payload);
      api.dispatch(setErrorMessage(action.payload));
    }
    return next(action);
  };
