import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';

import Fuse from 'fuse.js';
import React, { useState } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import { hideAssessmentSummaryExportModal } from '../../store/assessments/assessmentsSlice';
import { exportAssessmentResponses } from '../../store/assessments/assessmentsThunks';
import { AssessmentResponse } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { COMPLETED_TEXT } from '../../types/constants';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { AdoptechCheckbox } from '../AdoptechCheckbox/AdoptechCheckbox';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import './AssessmentSummaryExportModal.scss';

const options = {
  includeScore: true,
  keys: ['vendorName', 'updatedAt'],
};

const MAX_EXPORT_ITEMS = 100;

export const AssessmentSummaryExportModal: React.FC = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const {
    isShowingAssessmentSummaryExportModal,
    incomingAssessmentResponses,
    isExportingAssessmentResponses,
    exportProgress,
  } = useSelector((state: ApplicationState) => state.assessments);

  const isCompleted = (response: AssessmentResponse) =>
    response.status === COMPLETED_TEXT;

  const completedResponses = [...incomingAssessmentResponses]?.filter(
    isCompleted
  );

  const fuse = new Fuse(completedResponses, options);
  const responses = search
    ? fuse.search(search).map(x => x.item)
    : completedResponses;

  const handleExport = () => {
    const ids = selectedResponses.map(r => r.id);

    if (ids.length > 0) {
      const assessmentId = incomingAssessmentResponses[0].assessmentId;
      dispatch(exportAssessmentResponses(assessmentId, ids));
    }
  };

  const [selectedResponses, setSelectedResponses] = useState<
    AssessmentResponse[]
  >([]);

  const updateSelectedResponse = (response: AssessmentResponse) => () => {
    if (selectedResponses.find(selected => selected.id === response.id)) {
      setSelectedResponses(
        selectedResponses.filter(selected => selected.id !== response.id)
      );
    } else {
      if (selectedResponses.length < MAX_EXPORT_ITEMS) {
        setSelectedResponses(
          (selectedAssessmentResponses: AssessmentResponse[]) => [
            ...selectedAssessmentResponses,
            response,
          ]
        );
      }
    }
  };

  const isResponseSelected = (response: AssessmentResponse) =>
    !!selectedResponses.find(selected => selected.id === response.id);

  return (
    <React.Fragment>
      <AdoptechModal
        className="assessmentSummaryExportModal"
        onHide={() => dispatch(hideAssessmentSummaryExportModal())}
        show={isShowingAssessmentSummaryExportModal}
      >
        <Modal.Header>Export responses</Modal.Header>
        <Modal.Body>
          <div className="assessmentSummaryExportModal--subheader">
            Please select the published responses you would like to download.
            Please select up to a maximum of one hundred Responders.
          </div>
          <div>
            <div className="assessmentSummaryExportModal--selectTitleRow">
              <div className="assessmentSummaryExportModal--subheader">
                Select responder(s)
              </div>
              <div>
                <AdoptechTextInput
                  id="search"
                  value={search}
                  onChange={event => setSearch(event.currentTarget.value)}
                  type="text"
                  placeholder="Filter"
                  icon={faSearch}
                />
              </div>
            </div>
          </div>
          <AdoptechTable modal className="assessmentSummaryExportModal--table">
            <thead>
              <tr>
                <th />
                <th>Respondee</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {responses.map(response => {
                return (
                  <tr key={response.id}>
                    <td className="adoptechTable--checkbox">
                      <AdoptechCheckbox
                        checked={isResponseSelected(response)}
                        id={response.id}
                        onChange={updateSelectedResponse(response)}
                      />
                    </td>
                    <td onClick={updateSelectedResponse(response)}>
                      {response.vendorName}
                    </td>
                    <td onClick={updateSelectedResponse(response)}>
                      {formatShortDate(response.updatedAt)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </AdoptechTable>

          <div className="assessmentSummaryExportModal--progress">
            {isExportingAssessmentResponses && (
              <ProgressBar
                animated
                striped
                now={exportProgress}
                title="Exporting..."
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton
            onClick={() => dispatch(hideAssessmentSummaryExportModal())}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={handleExport}
            variant={AdoptechButtonVariant.Primary}
            disabled={selectedResponses.length === 0}
            busy={isExportingAssessmentResponses}
          >
            Export
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
