import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { JurisdictionModel, LegislationTemplateModel } from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { updateAddLegislationForm } from '../../../store/legalRegister/legalRegisterSlice';
import {
  createLegislationFromTemplates,
  fetchJurisdictions,
  fetchLegalRegister,
  fetchLegislationTemplates,
  fetchVendorLegislations,
} from '../../../store/legalRegister/legalRegisterThunks';
import { useJurisdictionsList } from './useJurisdictionsList';

export interface AddLegislationForm {
  jurisdictions: JurisdictionModel[];
  selectedJurisdictionsIds: Array<JurisdictionModel['id']>;
  isTouched: boolean;
  legislationTemplates: LegislationTemplateModel[];
  selectedLegislationTemplatesIds: Array<LegislationTemplateModel['id']>;
}

type Field = {
  [key in keyof AddLegislationForm]?: AddLegislationForm[keyof AddLegislationForm];
};

export const useAfterLegislationFormSubmit = (onClose: () => void) => {
  const dispatch = useDispatch();

  return () => {
    dispatch(updateAddLegislationForm({} as AddLegislationForm));
    dispatch(fetchLegalRegister());
    dispatch(fetchVendorLegislations());
    onClose();
  };
};

export const useAddLegislationFormHandler = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  useEffect(() => {
    dispatch(fetchJurisdictions());
    dispatch(fetchLegislationTemplates());
  }, []);
  const [search, setSearch] = useState(null);
  const afterSubmit = useAfterLegislationFormSubmit(onClose);

  const dispatch = useDispatch();

  const {
    addLegislationForm: form,
    isRequestingFetchLegislationTemplates,
    isRequestingFetchJurisdictions,
    isRequestingCreateLegislationFromTemplates: isSubmitting,
  } = useSelector((state: ApplicationState) => state.legalRegister);

  const isLoading =
    isRequestingFetchLegislationTemplates || isRequestingFetchJurisdictions;
  const { notEmptyJurisdictions, notEmptyJurisdictionOptions } =
    useJurisdictionsList();

  const updateFields = (fields: Field[]) => {
    const payload = {
      ...form,
      isTouched: true,
      ...Object.assign({}, ...fields),
    };

    dispatch(updateAddLegislationForm(payload));
  };

  const onJurisdictionChange = (
    selectedJurisdictionsIds: JurisdictionModel['id'][]
  ) => {
    // For new jurisdiction select all jurisdiction templates.
    // For existing jurisdiction select existing templates
    const selectedLegislationTemplatesIds = selectedJurisdictionsIds
      .map(jurisdictionId => {
        const alreadySelected = (form.selectedJurisdictionsIds || []).includes(
          jurisdictionId
        );

        if (alreadySelected) {
          const selectedIdsForJurisdiction =
            form.selectedLegislationTemplatesIds.filter(templateId => {
              return (
                form.legislationTemplates.find(
                  template => template.id === templateId
                ).jurisdiction?.id === jurisdictionId
              );
            });

          return selectedIdsForJurisdiction;
        }

        const allTemplatesIds = form.legislationTemplates
          .filter(template => template.jurisdiction?.id === jurisdictionId)
          .map(template => template.id);

        return allTemplatesIds;
      })
      .flat();

    updateFields([
      { selectedJurisdictionsIds },
      { selectedLegislationTemplatesIds },
    ]);
  };

  const onSelectAllTemplatesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    jurisdictionTemplates: LegislationTemplateModel[]
  ) => {
    {
      const jurisdictionTemplatesIds = jurisdictionTemplates.map(
        template => template.id
      );
      const ids =
        form.selectedLegislationTemplatesIds?.filter(
          templateId => !jurisdictionTemplatesIds.includes(templateId)
        ) || [];
      const updatedIds = e.target.checked
        ? ids.concat(jurisdictionTemplatesIds)
        : ids;

      updateFields([
        {
          selectedLegislationTemplatesIds: updatedIds,
        },
      ]);
    }
  };

  const onSelectTemplateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    template: LegislationTemplateModel
  ) => {
    const { checked } = e.target;
    const ids = (form.selectedLegislationTemplatesIds || []).filter(
      templateId => templateId !== template.id
    );
    const updatedIds = checked ? ids.concat(template.id) : ids;

    updateFields([{ selectedLegislationTemplatesIds: updatedIds }]);
  };

  const jurisdictionsIds = form.selectedJurisdictionsIds || [];
  const legislationsIds = form.selectedLegislationTemplatesIds || [];
  const submitButtonDisabled =
    !form.isTouched ||
    jurisdictionsIds.length === 0 ||
    legislationsIds.length === 0;
  const onTabChange = () => {
    updateFields([
      { selectedLegislationTemplatesIds: [], selectedJurisdictionsIds: [] },
    ]);
  };
  const onSubmit = async () => {
    await dispatch(
      createLegislationFromTemplates({
        legislationTemplateIds: form.selectedLegislationTemplatesIds,
      })
    );

    afterSubmit();
  };

  return {
    onJurisdictionChange,
    onSelectAllTemplatesChange,
    onSelectTemplateChange,
    submitButtonDisabled,
    onSubmit,
    isLoading,
    isSubmitting,
    form,
    notEmptyJurisdictions,
    notEmptyJurisdictionOptions,
    onTabChange,
    search,
    setSearch,
  };
};
