/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorDocumentApprovalStats
 */
export interface VendorDocumentApprovalStats {
  /**
   *
   * @type {number}
   * @memberof VendorDocumentApprovalStats
   */
  approved?: number;
  /**
   *
   * @type {number}
   * @memberof VendorDocumentApprovalStats
   */
  total?: number;
}

export function VendorDocumentApprovalStatsFromJSON(
  json: any
): VendorDocumentApprovalStats {
  return VendorDocumentApprovalStatsFromJSONTyped(json, false);
}

export function VendorDocumentApprovalStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorDocumentApprovalStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    approved: !exists(json, 'approved') ? undefined : json['approved'],
    total: !exists(json, 'total') ? undefined : json['total'],
  };
}

export function VendorDocumentApprovalStatsToJSON(
  value?: VendorDocumentApprovalStats | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    approved: value.approved,
    total: value.total,
  };
}
