import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, useState } from 'react';
import {
  AdoptechTextArea,
  AdoptechTextAreaProps,
} from '../AdoptechTextArea/AdoptechTextArea';
import './AdoptechTextArea2.scss';
import classNames from 'classnames';

const AdoptechTextArea2 = forwardRef<
  HTMLTextAreaElement,
  AdoptechTextAreaProps
>((props, ref) => {
  const inputProps = {
    ...props,
    ...{
      hasError: false,
      label: null,
      textAreaOnly: true,
    },
  };

  const baseCss = 'adoptechTextAreaInternalLabel';

  return (
    <>
      {props.hasError && props.errorText && (
        <div className={baseCss + '-errorText'}>{props.errorText}</div>
      )}
      <div
        className={classNames(baseCss, {
          [baseCss + '--rounded']: props.rounded,
          [baseCss + '-error']: props.hasError,
        })}
      >
        <div className={baseCss + '-container'}>
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          {props.customLabel}

          <AdoptechTextArea {...inputProps} ref={ref} />
          {props.icon && (
            <FontAwesomeIcon
              className="icon"
              icon={props.icon}
              onClick={e => {
                const target = e.target as HTMLInputElement;

                (
                  target.previousElementSibling as HTMLInputElement | null
                )?.select();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
});

AdoptechTextArea2.displayName = 'AdoptechTextArea2';
export { AdoptechTextArea2 };
