/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ErrorResponseErrors
 */
export interface ErrorResponseErrors {
  /**
   *
   * @type {string}
   * @memberof ErrorResponseErrors
   */
  base?: string;
}

export function ErrorResponseErrorsFromJSON(json: any): ErrorResponseErrors {
  return ErrorResponseErrorsFromJSONTyped(json, false);
}

export function ErrorResponseErrorsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ErrorResponseErrors {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    base: !exists(json, 'base') ? undefined : json['base'],
  };
}

export function ErrorResponseErrorsToJSON(
  value?: ErrorResponseErrors | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    base: value.base,
  };
}
