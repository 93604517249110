/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorIntegrationModel,
  VendorIntegrationModelFromJSON,
  VendorIntegrationModelFromJSONTyped,
  VendorIntegrationModelToJSON,
  VendorIntegrationSetting,
  VendorIntegrationSettingFromJSON,
  VendorIntegrationSettingFromJSONTyped,
  VendorIntegrationSettingToJSON,
  VendorIntegrationSyncModel,
  VendorIntegrationSyncModelFromJSON,
  VendorIntegrationSyncModelFromJSONTyped,
  VendorIntegrationSyncModelToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorIntegrationExtended
 */
export interface VendorIntegrationExtended extends VendorIntegrationModel {}

export function VendorIntegrationExtendedFromJSON(
  json: any
): VendorIntegrationExtended {
  return VendorIntegrationExtendedFromJSONTyped(json, false);
}

export function VendorIntegrationExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorIntegrationExtended {
  return json;
}

export function VendorIntegrationExtendedToJSON(
  value?: VendorIntegrationExtended | null
): any {
  return value;
}
