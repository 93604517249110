import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import {
  CompletedAssessment,
  CompletedAssessmentType,
} from '../../selectors/selectCompletedAssessments';
import { showEditPublishedAssessmentModal } from '../../store/assessments/assessmentsSlice';
import { Grid } from '../../types/grid';
import AdoptechTable from '../AdoptechTable/AdoptechTable';
import { SortableTableHeader } from '../SortableTableHeader/SortableTableHeader';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import './CompletedAssessmentsGridIn.scss';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { canCreate } from '../../functions/access';
import { AccessObject } from '../../types/accessObject';
import { MeatballMenu } from '../MeatballMenu/MeatballMenu';
import classNames from 'classnames';
import { showEditOwnerModal } from '../../store/global/globalSlice';
import { OwnerObjectType } from '../../types/OwnerObjectType';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';

export interface CompletedAssessmentsGridIn {
  assessments: CompletedAssessment[];
  rowClickHandler: (assessment: CompletedAssessment) => () => void;
}

export const CompletedAssessmentsGridIn: React.FC<
  CompletedAssessmentsGridIn
> = props => {
  const canCreateAssessments = canCreate(AccessObject.assessments);

  const dispatch = useDispatch();
  const baseClassName = 'completedAssessmentsGridIn';
  const handleRowClick = props.rowClickHandler;
  if (props.assessments.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={baseClassName}>
        <AdoptechTable className={baseClassName + '--table'}>
          <thead>
            <tr>
              <SortableTableHeader<CompletedAssessment>
                className={baseClassName + '--nameHeader'}
                columnName="name"
                grid={Grid.CompletedAssessments}
                label="Name"
              />
              <SortableTableHeader<CompletedAssessment>
                className={baseClassName + '--createdByHeader'}
                columnName="createdBy"
                grid={Grid.CompletedAssessments}
                label="Created by"
              />
              <SortableTableHeader<CompletedAssessment>
                className={baseClassName + '--dateCompletedHeader'}
                columnName="dateCompleted"
                grid={Grid.CompletedAssessments}
                label="Date completed"
              />
              <SortableTableHeader<CompletedAssessment>
                className={baseClassName + '--ownerHeader'}
                columnName="owner"
                grid={Grid.CompletedAssessments}
                label="Owner"
              />
              <th className={baseClassName + '--placeholderHeader'} />
              <th className={baseClassName + '--actionsHeader'} />
            </tr>
          </thead>
          <tbody>
            {props.assessments.map(assessment => {
              return (
                <tr
                  className={baseClassName + '--row'}
                  key={`${assessment.type}:${assessment.id}`}
                >
                  <td
                    onClick={handleRowClick(assessment)}
                    className={baseClassName + '--cell'}
                  >
                    {assessment.name}
                  </td>
                  <td
                    onClick={handleRowClick(assessment)}
                    className={baseClassName + '--cell'}
                  >
                    {assessment.createdBy}
                  </td>
                  <td
                    onClick={handleRowClick(assessment)}
                    className={baseClassName + '--cell'}
                  >
                    {formatShortDate(assessment.dateCompleted)}
                  </td>
                  <td
                    colSpan={2}
                    className={classNames(
                      {
                        'adoptechTable--clickableCell': canCreateAssessments,
                      },
                      baseClassName + '--cell'
                    )}
                    onClick={() =>
                      canCreateAssessments
                        ? dispatch(
                            showEditOwnerModal({
                              object: assessment,
                              type: OwnerObjectType.AssessmentResponse,
                            })
                          )
                        : handleRowClick(assessment)()
                    }
                  >
                    <UserAvatar user={assessment.owner} size="small" />
                  </td>

                  <td className={baseClassName + '--cell'}></td>
                  <td className={baseClassName + '--cell'}>
                    <MeatballMenu>
                      <Dropdown.Item
                        disabled={
                          assessment.type !==
                          CompletedAssessmentType.AssessmentResponse
                        }
                        onClick={e => {
                          e.stopPropagation();
                          dispatch(
                            push(
                              `/r/riskManagement/assessments/response/${assessment.id}`
                            )
                          );
                        }}
                      >
                        <FontAwesomeIcon
                          className="meatballMenu--icon"
                          icon={faEye}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          assessment.type !==
                          CompletedAssessmentType.AssessmentResponse
                        }
                        onClick={e => {
                          dispatch(
                            showEditPublishedAssessmentModal(assessment.id)
                          );
                          e.stopPropagation();
                        }}
                      >
                        <FontAwesomeIcon
                          className="meatballMenu--icon"
                          icon={faPen}
                        />
                        Edit
                      </Dropdown.Item>
                      {canCreateAssessments && (
                        <Dropdown.Item
                          onClick={e => {
                            dispatch(
                              showEditOwnerModal({
                                object: Object.assign(assessment),
                                type: OwnerObjectType.AssessmentResponse,
                              })
                            );
                            e.stopPropagation();
                          }}
                        >
                          <FontAwesomeIcon
                            className="meatballMenu--icon"
                            icon={faFileContract}
                          />
                          Edit owner
                        </Dropdown.Item>
                      )}
                    </MeatballMenu>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </AdoptechTable>
      </div>
    </React.Fragment>
  );
};
