/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorFrameworkRequirementCreatePayloadVendorFrameworkRequirement,
  VendorFrameworkRequirementCreatePayloadVendorFrameworkRequirementFromJSON,
  VendorFrameworkRequirementCreatePayloadVendorFrameworkRequirementFromJSONTyped,
  VendorFrameworkRequirementCreatePayloadVendorFrameworkRequirementToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorFrameworkRequirementCreatePayload
 */
export interface VendorFrameworkRequirementCreatePayload {
  /**
   *
   * @type {VendorFrameworkRequirementCreatePayloadVendorFrameworkRequirement}
   * @memberof VendorFrameworkRequirementCreatePayload
   */
  vendorFrameworkRequirement: VendorFrameworkRequirementCreatePayloadVendorFrameworkRequirement;
}

export function VendorFrameworkRequirementCreatePayloadFromJSON(
  json: any
): VendorFrameworkRequirementCreatePayload {
  return VendorFrameworkRequirementCreatePayloadFromJSONTyped(json, false);
}

export function VendorFrameworkRequirementCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorFrameworkRequirementCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vendorFrameworkRequirement:
      VendorFrameworkRequirementCreatePayloadVendorFrameworkRequirementFromJSON(
        json['vendor_framework_requirement']
      ),
  };
}

export function VendorFrameworkRequirementCreatePayloadToJSON(
  value?: VendorFrameworkRequirementCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vendor_framework_requirement:
      VendorFrameworkRequirementCreatePayloadVendorFrameworkRequirementToJSON(
        value.vendorFrameworkRequirement
      ),
  };
}
