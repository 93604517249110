import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { canCreate } from '../../functions/access';
import { selectInProgressPolicies } from '../../selectors/selectInProgressPolicies';
import { deletePolicy } from '../../store/policies/policiesThunks';
import { VendorPolicyDetails } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { AccessObject } from '../../types/accessObject';
import DocumentLibraryModal from '../DocumentLibraryModal/DocumentLibraryModal';
import { GenericModal } from '../GenericModal/GenericModal';

import AdoptechGridTable from '../AdoptechGridTable/AdoptechGridTable';
import { NoDataText } from '../NoDataText/NoDataText';
import { SortableTableHeader } from '../SortableTableHeader/SortableTableHeader';
import { Grid } from '../../types/grid';
import { InProgressPolicyRow } from './InProgressPolicyRow';

import './InProgressPoliciesGrid.scss';
import '../AdoptechGridTable/AdoptechGridTable.scss';

export const InProgressPoliciesGrid: React.FC = () => {
  const policies = useSelector(selectInProgressPolicies);
  const canManageDocuments = canCreate(AccessObject.vendor_documents);
  const { isDocumentLibraryModalShowing } = useSelector(
    (state: ApplicationState) => state.policies
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPolicyId, setSelectedPolicyId] = useState<string>();
  const dispatch = useDispatch();
  const previousPolicies = usePrevious(policies);
  const baseCss = 'policyGrid';
  const baseTableCss = 'adoptechGridTable';

  const allPolicies: VendorPolicyDetails[] = [...policies];
  if (previousPolicies) {
    previousPolicies.forEach(previousPolicy => {
      if (
        policies.findIndex(policy => policy.id === previousPolicy.id) === -1
      ) {
        allPolicies.push(previousPolicy);
      }
    });
  }

  return (
    <>
      <div className={baseCss + ' ' + baseCss + '--5-columns'}>
        <GenericModal
          closeButtonLabel="CONFIRM"
          onCancel={() => setShowDeleteModal(false)}
          onClose={() => {
            dispatch(deletePolicy(selectedPolicyId));
            setShowDeleteModal(false);
          }}
          show={showDeleteModal}
          showCancelButton
          title="Confirm delete?"
        >
          <div>
            Click CONFIRM to permanently delete this version of the policy.
          </div>
        </GenericModal>

        {allPolicies.length <= 0 ? (
          <NoDataText text="Once started, Draft policies will appear here" />
        ) : (
          <AdoptechGridTable
            header={
              <div className={baseTableCss + '--header'}>
                <SortableTableHeader<VendorPolicyDetails>
                  columnName="name"
                  grid={Grid.InProgressPolicies}
                  label="Name"
                  className={baseCss + '--name'}
                  notInTable
                />
                <div>Category</div>
                <div>Owner</div>
                <div>Progress</div>
                <div></div>
              </div>
            }
          >
            {allPolicies.map((policy: VendorPolicyDetails) => {
              return (
                <>
                  <InProgressPolicyRow
                    baseCss={baseCss}
                    policy={policy}
                    baseTableCss={baseTableCss}
                    setSelectedPolicyId={setSelectedPolicyId}
                    setShowDeleteModal={setShowDeleteModal}
                  />
                </>
              );
            })}
          </AdoptechGridTable>
        )}
      </div>

      {canManageDocuments && isDocumentLibraryModalShowing && (
        <DocumentLibraryModal />
      )}
    </>
  );
};
