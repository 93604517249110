import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../types/applicationState';

const getAllPolicies = (state: ApplicationState) => [
  ...state.policies.policies,
  ...state.policies.vendorPolicies,
];

export const selectAllAvailableCategories = createSelector(
  getAllPolicies,
  allPolicies =>
    [...new Set(allPolicies.map(policy => policy.categoryName))].sort()
);
