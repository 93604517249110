import React from 'react';
import { ReportForm, ReportSectionProps } from './ReportsManageDrawer';
import { AdoptechTextArea2 } from '../../AdoptechTextArea2/AdoptechTextArea2';
import { faPen } from '@fortawesome/pro-solid-svg-icons';

export const ReportSummaryField: React.FC<ReportSectionProps> = ({
  formData,
  updateField,
  updateForm,
}) => {
  const hasError = (field: keyof ReportForm) => !formData[field];
  const baseCss = 'reportsManageDrawer';

  let description;
  switch (formData.type) {
    case 'Report::LegalRegister':
      description = `Provide details of changes to the legislation or jurisdictions covered since the last review and outline how the register was/ will be reviewed. For example “Since our last Legal Register review the company has decided to expand into Europe, as a result a number of additional laws in the jurisdiction of France have been added to the register. The register was reviewed by the senior management team in our quarterly security management meeting held on 01/04/2023 and all legislation and regulations were approved”`;
      break;
    case 'Report::PestelRegister':
      description = `Provide details of any major changes in the business risks and opportunities since the last report, any significant strategic decisions being discussed or external factors affecting the strategic direction of the company. For example “Since our last PESTEL review the company has decided to expand into Europe, as a result a number of additional risks, and opportunities have been added to the register. The register was reviewed by the senior management team in our quarterly security management meeting held on 01/04/2023 and all risks and opportunities have subsequently been approved.”`;
      break;
    case 'Report::RiskRegister':
      description = `Provide details of any major changes in risk since the last report, any significant tasks completed, overdue or new tasks added. For example "Since our last risk review the company implemented a SaaS service on GCP, as a result a number of addition risks, controls and tasks have been added to the register. The register was reviewed by the senior management team in our quarterly security management meeting held on 01/10/2022 and all risks have subsequently been approved."`;
      break;
    case 'Report::VendorRegister':
      description = `Provide details of changes to the vendor register since the last review and outline how the register was/ will be reviewed. For example “Since our last vendor register review vendor A has been added, the risk profile of vendor B was amended and vendor C has been set to inactive. The register was reviewed by the senior management team in our quarterly security management meeting held on 01/04/2023.”`;
      break;
    default:
      alert(`Description not found for report type ${formData.type}`);
  }

  return (
    <div className={baseCss + '--fieldRow'}>
      <div className={baseCss + '--label'}>
        Enter a summary or comments to support the report.
      </div>
      <div className={baseCss + '--additionalLabel'}>{description}</div>
      <div className={baseCss + '--field'}>
        <AdoptechTextArea2
          hasError={hasError('summary')}
          label="Description"
          id={`${baseCss}Summary`}
          value={formData.summary}
          onChange={e => updateField('summary', e.currentTarget.value)}
          icon={faPen}
        />
      </div>
    </div>
  );
};
