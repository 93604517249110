import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { push } from 'connected-react-router';
import { AuditsEditForm, validation } from './AuditsEditForm';
import { AuditModel } from '../../../swagger';
import { updateAudit } from './store/auditsThunks';
import { auditsPageRoute } from '../../../components/Routes/Routes';
import './AuditsEditDrawer.scss';

interface Props {
  audit: AuditModel;
  show: boolean;
  onClose(): void;
}

export const AuditsEditDrawer: React.FC<Props> = ({ audit, show, onClose }) => {
  const dispatch = useDispatch();
  const [changedAudit, setAudit] = useState<AuditModel>(audit);
  const [busy, setBusy] = useState(false);

  const onSubmitSuccess = () => {
    onClose();
    dispatch(push(auditsPageRoute));
  };

  const formIsValid = Object.values(validation(changedAudit)).every(v => !v);

  const onSubmitError = (err: string) => {
    alert(err);
    setBusy(false);
  };

  const submitChanges = () => {
    setBusy(true);
    dispatch(updateAudit(changedAudit, onSubmitSuccess, onSubmitError));
  };

  const Footer = (
    <>
      <AdoptechButton onClick={onClose} variant={AdoptechButtonVariant.White}>
        Cancel
      </AdoptechButton>
      <AdoptechButton
        disabled={!formIsValid}
        onClick={submitChanges}
        variant={AdoptechButtonVariant.Primary}
        busy={busy}
      >
        Confirm
      </AdoptechButton>
    </>
  );

  return (
    <AdoptechHS
      title="Edit Audit"
      show={show}
      onClose={onClose}
      showConfirmationWarning={false}
      footer={Footer}
      extraClass="auditsEditDrawer"
    >
      <AuditsEditForm data={changedAudit} setData={setAudit} />
    </AdoptechHS>
  );
};
