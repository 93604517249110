import { sort } from '../../../../functions/sort';
import { ColumnSort } from '../../../../types/columnSort';
import { ApplicationState } from '../../../../types/applicationState';
import { selectSortSettings } from '../../../../selectors/selectSortSettings';
import { Grid } from '../../../../types/grid';
import { createSelector } from '@reduxjs/toolkit';
import { TrusthubDocumentPermissionModel } from '../../../../swagger/trusthub';

const sortVendorUserDocumentPermissions = (
  a: TrusthubDocumentPermissionModel,
  b: TrusthubDocumentPermissionModel,
  columnSort: ColumnSort
) => {
  const typedColumn = columnSort.name as keyof TrusthubDocumentPermissionModel;

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

export const selectVendorUserDocumentPermissions = createSelector(
  (state: ApplicationState) => state.trustHub.vendorUserDocumentPermissions,
  selectSortSettings(Grid.TrustHubUserAdminVendorUserDocumentPermissions),
  (permissions: TrusthubDocumentPermissionModel[], sortSetting) => {
    return [...permissions].sort((a, b) =>
      sortVendorUserDocumentPermissions(a, b, sortSetting.columnSort)
    );
  }
);
