import classNames from 'classnames';
import { Interweave } from 'interweave';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionLastUpdatedInfo } from '../../functions/getQuestionLastUpdatedInfo';
import {
  patchVendorPolicy,
  fetchVendorPolicy,
} from '../../store/policies/policiesThunks';
import {
  Question,
  VendorPolicyAnswer,
  VendorPolicyDetails,
} from '../../swagger/models';
import { LastUpdatedInfo } from '../LastUpdatedInfo/LastUpdatedInfo';
import { AnswerFormControl } from '../AnswerFormControl/AnswerFormControl';
import './PolicyQuestion.scss';
import { Answer } from '../../swagger/models/Answer';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { deleteAgreementTableAnswers } from '../../store/agreements/agreementsThunks';

interface PolicyQuestionProps {
  answers: VendorPolicyAnswer[];
  disabled: boolean;
  question: Question;
  questions: Question[];
  vendorPolicyId: string;
}

export const PolicyQuestion: React.FC<PolicyQuestionProps> = props => {
  const dispatch = useDispatch();

  const policyQuestionClasses = classNames({
    policyQuestion: true,
    'policyQuestion--disabled': props.disabled,
  });

  const lastUpdatedInfo = getQuestionLastUpdatedInfo(
    props.question,
    props.answers,
    'propertyName'
  );

  const handleChange = (answers: Answer[] | VendorPolicyAnswer[]) => {
    const patch: VendorPolicyDetails = { vendorPolicyAnswers: answers };
    dispatch(patchVendorPolicy(props.vendorPolicyId, patch, true));
  };

  let previousAnswer: VendorPolicyAnswer;
  if (props.answers) {
    previousAnswer = props.answers.find(
      a => a.propertyName === props.question.propertyName
    );
  }

  const onSuccess = () => {
    dispatch(fetchVendorPolicy(props.vendorPolicyId));
  };
  const currentVendor = useSelector(selectCurrentVendor);
  const handleDeleteTableAnswers = (answers: Answer[]) => {
    dispatch(
      deleteAgreementTableAnswers({
        vendorId: currentVendor.id,
        answersIds: answers.map(answer => answer.id),
        onSuccess,
      })
    );
  };

  return (
    <div className={policyQuestionClasses}>
      <div className="policyQuestion--questionText">
        <Interweave content={props.question.questionTextAsHtml} />
      </div>
      {props.question.explanatoryTextAsHtml && (
        <div className="policyQuestion--explanatoryText">
          <Interweave content={props.question.explanatoryTextAsHtml} />
        </div>
      )}
      <div className="policyQuestion--answerArea">
        <AnswerFormControl
          answers={props.answers}
          question={props.question}
          questions={props.questions}
          disabled={props.disabled}
          onChange={handleChange}
          previousAnswer={previousAnswer}
          indexProperty="propertyName"
          usingNewSurveyStructure
          onDeleteTable2Answers={handleDeleteTableAnswers}
        />
      </div>
      {lastUpdatedInfo && <LastUpdatedInfo lastUpdatedInfo={lastUpdatedInfo} />}
    </div>
  );
};
