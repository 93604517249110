import { createSelector } from '@reduxjs/toolkit';
import { genericSort } from '../functions/genericSort';
import { getPolicies } from '../functions/getPolicies';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';

export const selectAvailablePolicies = createSelector(
  getPolicies,
  selectSortSettings(Grid.AvailablePolicies),
  (policies, sortSettings) => {
    const clonedPolicies = [...policies];
    return clonedPolicies.sort((a, b) =>
      genericSort(a, b, sortSettings.columnSort)
    );
  }
);
