import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../types/applicationState';
import { VendorDocumentExtended, VendorPolicyDetails } from '../swagger';

export const selectVendorPolicyToEdit = createSelector(
  (state: ApplicationState) => state.policies.vendorPolicies,
  (state: ApplicationState) => state.policies.policyToEdit,
  (policies: VendorPolicyDetails[], policyToEdit: VendorDocumentExtended) => {
    if (policyToEdit?.documentType === 'policy') {
      const vendorPolicyId = policyToEdit?.documentableId;
      return policies.find(p => p.id === vendorPolicyId);
    }
    return policyToEdit;
  }
);
