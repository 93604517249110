/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum TrusthubCategorySectionIdentifier {
  EnvironmentalSocialAndGovernance = 'environmental_social_and_governance',
  Compliance = 'compliance',
  Certificates = 'certificates',
  Policies = 'policies',
  Reports = 'reports',
  Controls = 'controls',
}

export function TrusthubCategorySectionIdentifierFromJSON(
  json: any
): TrusthubCategorySectionIdentifier {
  return TrusthubCategorySectionIdentifierFromJSONTyped(json, false);
}

export function TrusthubCategorySectionIdentifierFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubCategorySectionIdentifier {
  return json as TrusthubCategorySectionIdentifier;
}

export function TrusthubCategorySectionIdentifierToJSON(
  value?: TrusthubCategorySectionIdentifier | null
): any {
  return value as any;
}
