/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssertionCategoryModel,
  AssertionCategoryModelFromJSON,
  AssertionCategoryModelFromJSONTyped,
  AssertionCategoryModelToJSON,
  AssertionResultExtended,
  AssertionResultExtendedFromJSON,
  AssertionResultExtendedFromJSONTyped,
  AssertionResultExtendedToJSON,
  AssertionRunExtendedAllOf,
  AssertionRunExtendedAllOfFromJSON,
  AssertionRunExtendedAllOfFromJSONTyped,
  AssertionRunExtendedAllOfToJSON,
  AssertionRunModel,
  AssertionRunModelFromJSON,
  AssertionRunModelFromJSONTyped,
  AssertionRunModelToJSON,
  AssertionSource,
  AssertionSourceFromJSON,
  AssertionSourceFromJSONTyped,
  AssertionSourceToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface AssertionRunExtended
 */
export interface AssertionRunExtended extends AssertionRunModel {
  /**
   *
   * @type {VendorUser}
   * @memberof AssertionRunExtended
   */
  owner?: VendorUser;
  /**
   *
   * @type {Array<AssertionResultExtended>}
   * @memberof AssertionRunExtended
   */
  assertionResults?: Array<AssertionResultExtended>;
}

export function AssertionRunExtendedFromJSON(json: any): AssertionRunExtended {
  return AssertionRunExtendedFromJSONTyped(json, false);
}

export function AssertionRunExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssertionRunExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...AssertionRunModelFromJSONTyped(json, ignoreDiscriminator),
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    assertionResults: !exists(json, 'assertion_results')
      ? undefined
      : (json['assertion_results'] as Array<any>).map(
          AssertionResultExtendedFromJSON
        ),
  };
}

export function AssertionRunExtendedToJSON(
  value?: AssertionRunExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...AssertionRunModelToJSON(value),
    owner: VendorUserToJSON(value.owner),
    assertion_results:
      value.assertionResults === undefined
        ? undefined
        : (value.assertionResults as Array<any>).map(
            AssertionResultExtendedToJSON
          ),
  };
}
