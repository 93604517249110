import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './SendReminderModal.scss';
import { sendSignatoryNotification } from '../../store/agreements/agreementsThunks';
import { ApplicationState } from '../../types/applicationState';
import { showGlobalToast } from '../../store/global/globalSlice';

interface SendReminderModalProps {
  signatoryData: { email?: string; id?: string };
  onSubmit: () => void;
  onCancel: () => void;
}

export const SendReminderModal: React.FC<SendReminderModalProps> = ({
  signatoryData = null,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const dispatch = useDispatch();
  const isDispatchingNotification = useSelector(
    (state: ApplicationState) => state.agreements.isDispatchingNotification
  );
  const [message, setMessage] = useState('');
  const submit = () => {
    dispatch(
      sendSignatoryNotification({
        signatoryId: signatoryData.id,
        body: { message },
        onSuccess: () => {
          dispatch(showGlobalToast('Notification has been sent successfully.'));
          onSubmit();
        },
      })
    );
  };

  return (
    <AdoptechModal className="sendReminderModal" onHide={onCancel} show>
      <Modal.Header>Resend signatory notification</Modal.Header>
      <Modal.Body>
        <div className="sendReminderModal--description">
          <b>To: </b> {signatoryData?.email}
        </div>
        <div className="sendReminderModal--textarea">
          <AdoptechTextArea
            onChange={e => setMessage(e.currentTarget.value)}
            value={message}
            id="Add optional message"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={onCancel}>Cancel</AdoptechButton>
        <AdoptechButton
          onClick={submit}
          variant={AdoptechButtonVariant.Primary}
          busy={isDispatchingNotification}
          uppercase
        >
          send
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
