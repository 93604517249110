import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Footer } from './Footer';
import {
  agreementConfirmText,
  AgreementFormProps,
} from '../AddNewAgreementForm/AddNewAgreementForm';
import AdoptechHS from '../../AdoptechHS/AdoptechHS';
import {
  AgreementExtended,
  AgreementModel,
  AgreementModelStatusEnum,
  AgreementSignatoriesIdPatchRequest,
  AgreementSignatoryModel,
} from '../../../swagger';
import { selectAgreementsEditForm } from '../../../selectors/selectAgreementsEditForm';
import { updateAgreementsEditFormField } from '../../../store/agreements/agreementsSlice';
import { PrimaryStep } from './PrimaryStep/PrimaryStep';
import './EditAgreementForm.scss';
import { SignatoriesEditForm } from './SignatoriesEditStep/SignatoriesEditStep';
import { selectCurrentAgreement } from '../../../selectors/selectCurrentAgreement';
import { DocumentUploadStep } from './DocumentUploadStep/DocumentUploadStep';

interface OverviewSection {
  name: AgreementModel['name'];
  owner: AgreementModel['owner'];
  reviewDate: AgreementModel['reviewDate'];
  overviewSectionLoaded: boolean;
}

interface BrandingSection {
  useBrandingColor: AgreementExtended['useBrandingColor'];
  useCompanyLogo: AgreementExtended['useCompanyLogo'];
}

export interface AgreementSignatoryModelExtended
  extends AgreementSignatoryModel {
  documentBinary: File;
}

interface SignatoriesSection {
  currentSignatoryId: AgreementSignatoryModel['id'];
  // TODO refactoring: use form.signatories
  // instead of form.signatoriesWithDocs ( for upload ) and state.agreements.currentAgreementSignatories ( for display the list )
  signatoriesWithDocs: AgreementSignatoryModelExtended[];
  signatoriesSectionLoaded: boolean;
  signatory: AgreementSignatoryModel;
}

interface DocumentUploadSection {
  signatoryDocumentExist: boolean;
}

export enum AgreementEditFormStep {
  Primary = 'primary',
  SignatoriesEdit = 'signatories-edit',
  DocumentUpload = 'document-upload',
}

type sections = OverviewSection &
  BrandingSection &
  SignatoriesSection &
  DocumentUploadSection;

export interface AgreementsEditForm extends sections {
  step: AgreementEditFormStep;
  isLoadedExtendedFields: boolean;
}

export type AgreementEditFormFieldPayload = Partial<sections>;

export const EditAgreementForm: React.FC<AgreementFormProps> = ({
  show,
  onClose,
}) => {
  const form = useSelector(selectAgreementsEditForm);
  const footer = (
    <Footer
      onClose={onClose}
      upload={form.step === AgreementEditFormStep.DocumentUpload}
    />
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!form.step) {
      dispatch(
        updateAgreementsEditFormField({
          step: AgreementEditFormStep.Primary,
        })
      );
    }
  }, []);

  const currentAgreement = useSelector(selectCurrentAgreement);
  const fillHeader = () => {
    if (!form.name)
      dispatch(updateAgreementsEditFormField({ name: currentAgreement.name }));
  };

  // We need name in the header for all steps
  useEffect(() => {
    fillHeader();
  }, []);

  if (!form.step) {
    return null;
  }

  const steps = {
    [AgreementEditFormStep.SignatoriesEdit]: <SignatoriesEditForm />,
    [AgreementEditFormStep.Primary]: <PrimaryStep />,
    [AgreementEditFormStep.DocumentUpload]: <DocumentUploadStep />,
  };

  const subHeaders = {
    [AgreementEditFormStep.SignatoriesEdit]:
      currentAgreement.status === AgreementModelStatusEnum.Draft
        ? 'Edit signatory'
        : 'View signatory',
    [AgreementEditFormStep.Primary]: null as string,
    [AgreementEditFormStep.DocumentUpload]:
      'Upload the agreement signed offline',
  };

  const onBackClick = () => {
    dispatch(
      updateAgreementsEditFormField({ step: AgreementEditFormStep.Primary })
    );
  };

  const subHeaderTitle = subHeaders[form.step];

  return (
    <AdoptechHS
      title={form.name}
      show={show}
      onClose={onClose}
      footer={footer}
      extraClass="adoptechHS--medium"
      subHeaderTitle={subHeaderTitle}
      onBackClick={onBackClick}
      showConfirmationWarning
      extraFooterClass={
        form.step === AgreementEditFormStep.SignatoriesEdit
          ? 'justify-content-end'
          : ''
      }
      confirmText={agreementConfirmText}
    >
      {steps[form.step]}
    </AdoptechHS>
  );
};
