import React, { useEffect, useState } from 'react';
import AdoptechModal from '../../../../../components/AdoptechModal/AdoptechModal';
import './EditDocumentModal.scss';
import { AdoptechTextInput } from '../../../../../components/AdoptechTextInput/AdoptechTextInput';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssignableComplianceTasks } from '../../../../../store/compliance/complianceThunks';
import { ApplicationState } from '../../../../../types/applicationState';
import { AdoptechCheckbox } from '../../../../../components/AdoptechCheckbox/AdoptechCheckbox';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import { setControlFormModel } from '../../../../../store/compliance/complianceSlice';
import { Modal } from 'react-bootstrap';
import Fuse from 'fuse.js';
import { TaskModel } from '../../../../../swagger/models/TaskModel';
import { AssertionModel } from '../../../../../swagger';
import { PolicyOrVendorPolicy } from '../../../../../components/compliance/ComplianceControlPoliciesSimpleList/ControlEditPolicyModal/ControlEditPolicyModal';

interface EditDocumentModalProps {
  show: boolean;
  onHide: () => void;
}

export const useDocumentsSelectTree = (
  documents: TaskModel[] | AssertionModel[] | PolicyOrVendorPolicy[],
  baseCss: string
) => {
  const [selectedIds, setSelectedIds] = useState<document['id'][]>([]);
  const isSelectAllChecked =
    selectedIds.length > 0 && selectedIds.length === documents.length;
  type document = (typeof documents)[0];
  const isChecked = (doc: document) => selectedIds.includes(doc.id);
  const childClass = (name: string) => `${baseCss}--${name}`;

  const handleSelectAll = (e: { currentTarget: { checked: any } }) => {
    const checked = e.currentTarget.checked;

    if (checked) {
      setSelectedIds(documents.map(doc => doc.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleSelectAllButton = () => {
    handleSelectAll({ currentTarget: { checked: true } });
  };

  const handleSelect = (e: { currentTarget: { checked: any; id: string } }) => {
    const id = e.currentTarget.id;
    const checked = e.currentTarget.checked;
    if (checked) {
      setSelectedIds(prevState => {
        return [...prevState, id];
      });
    } else {
      setSelectedIds(prevState => {
        return prevState.filter(docId => docId !== id);
      });
    }
  };
  const treeDiv = (
    <div className={childClass('documentsTree')}>
      <AdoptechCheckbox
        color="white"
        size="small"
        id="select-all-docs"
        className={childClass('selectAll')}
        label="Select all"
        checked={isSelectAllChecked}
        onChange={handleSelectAll}
      />
      {documents.map(document => {
        return (
          <AdoptechCheckbox
            color="white"
            id={document.id}
            key={document.id}
            className={childClass('document')}
            size="small"
            label={document.name}
            checked={isChecked(document)}
            onChange={handleSelect}
          />
        );
      })}
    </div>
  );

  return {
    selectedIds,
    handleSelectAllButton,
    childClass,
    treeDiv,
  };
};

export const EditDocumentModal: React.FC<EditDocumentModalProps> = props => {
  const baseCss = 'editDocumentModal';
  const [search, setSearch] = useState('');
  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );
  const editControlFormModel = useSelector(
    (state: ApplicationState) => state.compliance.editControlFormModel
  );

  const assignableTasks = useSelector(
    (state: ApplicationState) => state.compliance.assignableTasks
  ).filter(
    task =>
      !editControlFormModel.tasks.map(formTask => formTask.id).includes(task.id)
  );

  const options = {
    ignoreLocation: true,
    includeScore: true,
    keys: ['name'],
    threshold: 0,
  };
  const fuseDocuments = new Fuse(assignableTasks, options);
  const documents = search
    ? fuseDocuments.search(search).map(x => x.item)
    : assignableTasks;

  const placeholder = 'Search for control checks';
  const title = 'Add control check';

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAssignableComplianceTasks(vendorId));
  }, []);

  const { selectedIds, handleSelectAllButton, childClass, treeDiv } =
    useDocumentsSelectTree(documents, baseCss);
  const handleConfirm = () => {
    dispatch(
      setControlFormModel({
        ...editControlFormModel,
        tasks: [
          ...editControlFormModel.tasks,
          ...assignableTasks.filter(task => selectedIds.includes(task.id)),
        ],
        formTouched: true,
      })
    );
    props.onHide();
  };

  return (
    <AdoptechModal
      show={props.show}
      onHide={props.onHide}
      className={childClass('modal')}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body className={childClass('body')}>
        <>
          <div className={childClass('search')}>
            <AdoptechTextInput
              id="search"
              value={search}
              onChange={e => setSearch(e.target.value)}
              type="text"
              placeholder={placeholder}
              icon={faSearch}
              additionalClass="adoptechTextInput-search"
            />
          </div>
          {treeDiv}
        </>
      </Modal.Body>
      <Modal.Footer className={childClass('footer')}>
        <AdoptechButton
          variant={AdoptechButtonVariant.White}
          onClick={handleSelectAllButton}
        >
          Select All
        </AdoptechButton>
        <AdoptechButton
          variant={AdoptechButtonVariant.Primary}
          onClick={handleConfirm}
        >
          Confirm
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
