import React, { useEffect, useState } from 'react';
import { TrusthubSettingsModel } from '../../../../swagger/trusthub';
import AdoptechHS from '../../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { AdoptechTextInput2 } from '../../../../components/AdoptechTextInput2/AdoptechTextInput2';
import './TrustHubSettingsDrawer.scss';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { AdoptechColourInput } from '../../../../components/AdoptechColourInput/AdoptechColourInput';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../types/applicationState';
import {
  fetchTrustHubSettings,
  fetchTrusthubAdminCurrentVendorUser,
  updateTrustHubSettings,
} from '../../store/trusthubThunks';
import { selectCurrentVendor } from '../../../../selectors/selectCurrentVendor';
import { AdoptechCheckbox } from '../../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { AdoptechFileInput } from '../../../../components/AdoptechFileInput/AdoptechFileInput';

interface TrustHubSettingsDrawer {
  onClose: () => void;
  settings: TrusthubSettingsModel;
}

interface TrustHubSettingsFormData {
  brandingColor?: TrusthubSettingsModel['brandingColor'];
  domain?: TrusthubSettingsModel['domain'];
  useColourGradient?: TrusthubSettingsModel['useColourGradient'];
  logo?: File;
  favicon?: File;
  name?: string;
}
// on change order please check ColorHeader.tsx blackColor
export const TrusthubColorsList = [
  '#12B76A',
  '#A8D63F',
  '#FFC700',
  '#EFA82C',
  '#EA5542',
  '#E25EB9',
  '#7F58D6',
  '#02569F',
  '#4496EB',
  '#00BAFF',
  '#06ABC1',
  '#01436f',
  '#222222',
];
export const TrustHubSettingsDrawer: React.FC<
  TrustHubSettingsDrawer
> = props => {
  const staticText = 'https://trusthub.info/';

  const { favicon, logo, ...settingsFields } = props.settings;
  const [formData, setFormData] =
    useState<TrustHubSettingsFormData>(settingsFields);

  const [formDataCopy, _] = useState<TrustHubSettingsFormData>(settingsFields);
  const baseCss = 'trusthubSettingsDrawer';
  const dispatch = useDispatch();
  const vendor = useSelector(selectCurrentVendor);
  const { updateSettingsStatus } = useSelector(
    (state: ApplicationState) => state.trustHub
  );

  const handleClose = () => {
    props.onClose();
  };

  const handleDomainChange = (e: { currentTarget: { value: string } }) => {
    const { value: newValue } = e.currentTarget;
    const maxLength = 60;

    setFormData(prevFormData => ({
      ...prevFormData,
      domain: newValue
        .replace(/[^a-zA-Z0-9-_]/g, '') // remove special chars
        .substring(0, maxLength), // cut the length
    }));
  };

  const handleNameChange = (e: { currentTarget: { value: string } }) => {
    const { value } = e.currentTarget;

    setFormData(prevFormData => {
      return {
        ...prevFormData,
        ...{ name: value },
      };
    });
  };

  return (
    <AdoptechHS
      extraClass="trusthubSettingsHS"
      title="Edit TrustHub Settings"
      show
      onClose={handleClose}
      showConfirmationWarning={false}
      footer={
        <>
          <AdoptechButton
            onClick={handleClose}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={() => {
              const payload = {
                brandingColor: formData.brandingColor,
                domain: formData.domain.replaceAll(staticText, ''),
                vendorIdOrDomain: vendor.id,
                useColourGradient: formData.useColourGradient,
                name: formData.name,
                logo: formData.logo,
                favicon: formData.favicon,
              };
              dispatch(updateTrustHubSettings(payload, handleClose));
            }}
            variant={AdoptechButtonVariant.Primary}
            disabled={
              !formData.brandingColor || !formData.domain || !formData.name
            }
            busy={updateSettingsStatus === 'loading'}
          >
            Update
          </AdoptechButton>
        </>
      }
    >
      {props.settings ? (
        <div className={baseCss}>
          <div className={baseCss + '--formContainer'}>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <div className={baseCss + '--fieldLabel'}>
                  Type the name of your TrustHub page as you want it to appear
                  in the URL.
                </div>
                <AdoptechTextInput2
                  icon={faPencil}
                  id="trusthub-domain"
                  type="text"
                  hasError={!formData.domain}
                  label="Page name"
                  placeholder="company_name"
                  value={formData.domain}
                  onChange={handleDomainChange}
                  prefixText={staticText}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <div className={baseCss + '--fieldLabel'}>
                  Add a company colour to the top of your TrustHub page.
                </div>
                <AdoptechColourInput
                  colourList={TrusthubColorsList}
                  onChange={value => {
                    setFormData(prevFormData => {
                      return { ...prevFormData, ...{ brandingColor: value } };
                    });
                  }}
                  value={formData.brandingColor}
                  label="Company colour"
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechCheckbox
                  id="trusthub-gradient"
                  checked={formData.useColourGradient}
                  label="Enable colour gradient"
                  color="white-black"
                  onChange={e => {
                    const useColourGradient = e.currentTarget?.checked;
                    setFormData(prevFormData => {
                      return {
                        ...prevFormData,
                        ...{ useColourGradient },
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <div className={baseCss + '--fieldLabel'}>
                  Upload a company logo to display at the top of your TrustHub
                  page.
                </div>
                <AdoptechFileInput
                  label="Company logo"
                  urls={logo && logo.urls.full ? [logo.urls.full] : []}
                  name="logo"
                  accept=".jpg,.jpeg,.png"
                  onChange={files => {
                    setFormData(prevFormData => {
                      return {
                        ...prevFormData,
                        ...{ logo: files[0] },
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <div className={baseCss + '--fieldLabel'}>
                  Add a company favicon to display in your browser tab.
                </div>
                <AdoptechFileInput
                  label="Company favicon"
                  urls={favicon && logo.urls.full ? [favicon.urls.full] : []}
                  name="logo"
                  accept=".jpg,.jpeg,.png"
                  onChange={files => {
                    setFormData(prevFormData => {
                      return {
                        ...prevFormData,
                        ...{ favicon: files[0] },
                      };
                    });
                  }}
                />
              </div>
            </div>

            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <div className={baseCss + '--fieldLabel'}>
                  Type the name of your TrustHub company name as you want it to
                  appear under the logo
                </div>
                <AdoptechTextInput2
                  icon={faPencil}
                  id="trusthub-company-name"
                  type="text"
                  label="Company name"
                  hasError={!formData.name}
                  placeholder="Company name"
                  value={formData.name}
                  onChange={handleNameChange}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </AdoptechHS>
  );
};
