import React from 'react';
import { AgreementsEditForm } from '../EditAgreementForm';
import { DocumentPermissionsSection } from './DocumentPermissionsSection/DocumentPermissionsSection';
import { OverviewSection } from './OverviewSection/OverviewSection';
import { BrandingSection } from './BrandingSection/BrandingSection';
import { SignatoriesSection } from './SignatoriesSection/SignatoriesSection';

export const PrimaryStepIsValid = (form: AgreementsEditForm) => {
  return form.name?.length > 0;
};

export const PrimaryStep: React.FC = () => {
  return (
    <>
      <OverviewSection />
      <BrandingSection />
      <SignatoriesSection />
      <DocumentPermissionsSection />
    </>
  );
};
