import React, { forwardRef } from 'react';
import {
  AdoptechTextInput,
  AdoptechTextInputProps,
} from '../AdoptechTextInput/AdoptechTextInput';
import './AdoptechTextInput2.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const AdoptechTextInput2 = forwardRef<HTMLInputElement, AdoptechTextInputProps>(
  (props, ref) => {
    const inputProps = {
      ...props,
      ...{
        hasError: false,
        label: null,
        additionalClass: null,
        onlyInput: true,
      },
    };
    const baseCss = 'adoptechTextInput2';
    return (
      <>
        <div
          className={classNames(baseCss, {
            [baseCss + '-withIcon']: props.icon,
            [baseCss + '-error']: props.hasError,
            [baseCss + '--rounded']: props.rounded,
          })}
        >
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          <div className="adoptechTextInput2-container">
            {props.prefixText && (
              <span
                className="adoptechTextInput2--readOnlyPrefix"
                onClick={e => {
                  e.stopPropagation();
                  const parentContainer = e.currentTarget.parentNode;
                  parentContainer?.querySelector('input')?.focus();
                }}
              >
                {props.prefixText}
              </span>
            )}
            <AdoptechTextInput {...inputProps} ref={ref} />

            {props.icon && (
              <FontAwesomeIcon className="icon" icon={props.icon} />
            )}
          </div>
        </div>
      </>
    );
  }
);

AdoptechTextInput2.displayName = 'AdoptechTextInput2';
export { AdoptechTextInput2 };
