/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VendorSupplierAccessControl {
  Sso = 'sso',
  _2fa = '2fa',
  Password = 'password',
  NotApplicable = 'not_applicable',
}

export function VendorSupplierAccessControlFromJSON(
  json: any
): VendorSupplierAccessControl {
  return VendorSupplierAccessControlFromJSONTyped(json, false);
}

export function VendorSupplierAccessControlFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorSupplierAccessControl {
  return json as VendorSupplierAccessControl;
}

export function VendorSupplierAccessControlToJSON(
  value?: VendorSupplierAccessControl | null
): any {
  return value as any;
}
