import { createSelector } from '@reduxjs/toolkit';
import Fuse from 'fuse.js';
import { sort } from '../../functions/sort';
import { sortByDate } from '../../functions/sortByDate';
import { sortByOwner } from '../../functions/sortByOwner';
import { ReportModel } from '../../swagger/models/ReportModel';
import { VendorUser } from '../../swagger/models/VendorUser';
import { ApplicationState } from '../../types/applicationState';
import { ColumnSort } from '../../types/columnSort';
import { Grid } from '../../types/grid';
import { selectSortSettings } from '../selectSortSettings';
import {
  reportTypeValues,
  typeOptions,
} from '../../components/Reports/ReportsHeader/ReportsHeader';

const sorts = (a: ReportModel, b: ReportModel, columnSort: ColumnSort) => {
  const typedColumn = columnSort.name as keyof ReportModel;

  if (typedColumn === 'owner') {
    return sortByOwner(
      a[typedColumn] as VendorUser,
      b[typedColumn] as VendorUser,
      columnSort.direction
    );
  }

  if (typedColumn === 'createdAt') {
    return sortByDate(a[typedColumn], b[typedColumn], columnSort.direction);
  }

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

export const filteredByTextsReports = (
  items: ReportModel[],
  searchText: string
) => {
  if (!items) return [];
  if (!searchText) return items;

  const fuseTask = new Fuse(items, {
    ignoreLocation: true,
    includeScore: true,
    keys: ['name', 'owner.email', 'owner.fullName', 'createdAt', 'type'],
    threshold: 0,
  });
  return fuseTask.search(searchText).map(x => x.item);
};

const filteredByTypeReports = (
  reports: ReportModel[],
  selectedType: reportTypeValues
) => {
  return reports.filter(report => {
    if (!selectedType || selectedType === 'all') return true;
    const option = typeOptions.find(type => type.value === selectedType);
    return option.enum === report.type;
  });
};

export const selectsReports = (
  searchText: string,
  selectedType: reportTypeValues
) =>
  createSelector(
    (state: ApplicationState) => state.reports.reports,
    selectSortSettings(Grid.Reports),
    (reports: ReportModel[], sortSetting) => {
      return [
        ...filteredByTextsReports(
          filteredByTypeReports(reports, selectedType),
          searchText
        ),
      ].sort((a, b) => sorts(a, b, sortSetting.columnSort));
    }
  );
