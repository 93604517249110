import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideGenerateChecklistSuccessToast } from '../../store/checklists/checklistsSlice';
import { ApplicationState } from '../../types/applicationState';
import { MessageToast } from '../MessageToast/MessageToast';

export const GenerateChecklistSuccessToast: React.FC = () => {
  const dispatch = useDispatch();

  const isShowingGenerateChecklistSuccessToast = useSelector(
    (state: ApplicationState) =>
      state.checklists.isShowingGenerateChecklistSuccessToast
  );

  return (
    <MessageToast
      autohide
      delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
      onClose={() => dispatch(hideGenerateChecklistSuccessToast())}
      show={isShowingGenerateChecklistSuccessToast}
      size="large"
    >
      Your checklist has been generated and can be found in the Active checklist
      table. Email notifications have been sent.
    </MessageToast>
  );
};
