import { createSelector } from '@reduxjs/toolkit';
import { assessmentResponseSort } from '../functions/assessmentResponseSort';
import { getVendorAssessmentResponses } from '../functions/getVendorAssessmentResponses';
import { COMPLETED_TEXT } from '../types/constants';
import { Grid } from '../types/grid';
import { selectSortSettings } from './selectSortSettings';

export const selectPendingAssessments = createSelector(
  getVendorAssessmentResponses,
  selectSortSettings(Grid.PendingAssessments),
  (vendorAssessmentResponses, sortSetting) => {
    const clonedVendorAssessmentResponses = [...vendorAssessmentResponses];
    return clonedVendorAssessmentResponses
      .filter(ar => ar.status !== COMPLETED_TEXT)
      .sort((a, b) => assessmentResponseSort(a, b, sortSetting.columnSort));
  }
);
