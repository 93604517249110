/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PestelRegisterModelStats
 */
export interface PestelRegisterModelStats {
  /**
   *
   * @type {number}
   * @memberof PestelRegisterModelStats
   */
  pestelItemsCount?: number;
  /**
   *
   * @type {number}
   * @memberof PestelRegisterModelStats
   */
  actionsCount?: number;
}

export function PestelRegisterModelStatsFromJSON(
  json: any
): PestelRegisterModelStats {
  return PestelRegisterModelStatsFromJSONTyped(json, false);
}

export function PestelRegisterModelStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PestelRegisterModelStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pestelItemsCount: !exists(json, 'pestel_items_count')
      ? undefined
      : json['pestel_items_count'],
    actionsCount: !exists(json, 'actions_count')
      ? undefined
      : json['actions_count'],
  };
}

export function PestelRegisterModelStatsToJSON(
  value?: PestelRegisterModelStats | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pestel_items_count: value.pestelItemsCount,
    actions_count: value.actionsCount,
  };
}
