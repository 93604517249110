import React from 'react';
import { SortableTableHeader } from '../../SortableTableHeader/SortableTableHeader';
import { AgreementModel } from '../../../swagger';
import './AgreementsList.scss';
import { Lozenge } from '../../Lozenge/Lozenge';
import { NoDataText } from '../../NoDataText/NoDataText';
import AdoptechGridTable from '../../AdoptechGridTable/AdoptechGridTable';
import { AgreementsRow } from './AgreementsRow';
import { useAgreementsList } from '../../../hooks/agreements/useAgreementsList';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import classNames from 'classnames';
import { showDocumentUploadModal } from '../../../store/vendors/vendorsSlice';
import { useDispatch } from 'react-redux';
import { useCanFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { agreementsStartRoute } from '../../Routes/Routes';
import { push } from 'connected-react-router';
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons/faCloudArrowUp';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';

export enum AgreementsMode {
  Draft = 'draft',
  AwaitingSignature = 'awaiting_signature',
  Completed = 'completed',
}

interface AgreementsListProps {
  mode: AgreementsMode;
  handleDeleteClick: (agreementId: AgreementModel['id']) => void;
}

export const AgreementsList: React.FC<AgreementsListProps> = ({
  mode,
  handleDeleteClick,
}) => {
  const baseCss = 'agreementsList';

  const { agreements, header, placeholderText, currentGrid } =
    useAgreementsList(mode);

  const showStatusColumn = [
    AgreementsMode.AwaitingSignature,
    AgreementsMode.Completed,
  ].includes(mode);
  const showReviewDateColumn = mode === AgreementsMode.Completed;
  const baseTableCss = 'adoptechGridTable';

  const isCompleted = mode === AgreementsMode.Completed;
  const dispatch = useDispatch();
  const isDraft = mode === AgreementsMode.Draft;
  const canManageAgreements = useCanFeature(AccessObject.agreements_manage);

  return (
    <>
      {isDraft && (
        <div className={baseCss + '--header'}>
          <div className={classNames(`${baseCss}--title`)}>
            {header}
            <Lozenge
              value={agreements.length}
              className="lozenge lozenge--light-grey"
            />
          </div>
          <div className={baseCss + '--headerButton'}>
            {canManageAgreements && (
              <AdoptechButton
                onClick={() => dispatch(push(agreementsStartRoute))}
                rounded
                variant={AdoptechButtonVariant.PrimaryOrange}
                icon={faPlus}
                iconSize="large"
              >
                Start new
              </AdoptechButton>
            )}
          </div>
        </div>
      )}
      <div className={classNames([baseCss, `${baseCss}--6-columns`])}>
        {!isDraft && (
          <div
            className={classNames(`${baseCss}--title`, { 'mb-2': isCompleted })}
          >
            {header}
            <Lozenge
              value={agreements.length}
              className="lozenge lozenge--light-grey"
            />
            {isCompleted && canManageAgreements && (
              <AdoptechButton
                onClick={() => dispatch(showDocumentUploadModal())}
                variant={AdoptechButtonVariant.Default}
                icon={faCloudArrowUp}
                extraClass="adoptechButton-small ml-5 adoptechButton--large"
                iconAlignment="right"
              >
                Upload
              </AdoptechButton>
            )}
          </div>
        )}

        {agreements.length <= 0 ? (
          <NoDataText text={placeholderText} />
        ) : (
          <AdoptechGridTable
            header={
              <div className={baseTableCss + '--header'}>
                <SortableTableHeader<AgreementModel>
                  columnName="name"
                  grid={currentGrid}
                  label="Name"
                  className={baseCss + '--name'}
                  notInTable
                />
                <SortableTableHeader<AgreementModel>
                  columnName="externalSignatoriesNames"
                  grid={currentGrid}
                  label="Counterparty"
                  notInTable
                />
                <SortableTableHeader<AgreementModel>
                  columnName="owner"
                  grid={currentGrid}
                  label="Owner"
                  notInTable
                />
                {mode === AgreementsMode.AwaitingSignature && (
                  <SortableTableHeader<AgreementModel>
                    columnName="signatoriesStats"
                    grid={currentGrid}
                    label="Signatories"
                    notInTable
                  />
                )}
                {showReviewDateColumn && (
                  <SortableTableHeader<AgreementModel>
                    columnName="reviewDate"
                    grid={currentGrid}
                    label="Review date"
                    notInTable
                  />
                )}
                {showStatusColumn && (
                  <SortableTableHeader<AgreementModel>
                    columnName="toBeSigned"
                    grid={currentGrid}
                    label={
                      mode === AgreementsMode.AwaitingSignature ? 'Status' : ''
                    }
                    notInTable
                  />
                )}
                {isDraft ? (
                  <>
                    <div></div>
                    <div></div>
                    <div className={baseCss + '--lastColumn'}>Actions</div>
                  </>
                ) : (
                  <div className={baseCss + '--lastColumn'}>Actions</div>
                )}
              </div>
            }
          >
            <div>
              {agreements.map((agreement: AgreementModel) => {
                return (
                  <AgreementsRow
                    key={agreement.id}
                    agreement={agreement}
                    mode={mode}
                    baseCss={baseCss}
                    baseTableCss={baseTableCss}
                    showReviewDateColumn={showReviewDateColumn}
                    handleDeleteClick={handleDeleteClick}
                    showStatusColumn={showStatusColumn}
                  />
                );
              })}
            </div>
          </AdoptechGridTable>
        )}
      </div>
    </>
  );
};
