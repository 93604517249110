/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AuditUpdatePayloadAudit
 */
export interface AuditUpdatePayloadAudit {
  /**
   *
   * @type {string}
   * @memberof AuditUpdatePayloadAudit
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AuditUpdatePayloadAudit
   */
  rating?: AuditUpdatePayloadAuditRatingEnum;
  /**
   *
   * @type {string}
   * @memberof AuditUpdatePayloadAudit
   */
  ownerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditUpdatePayloadAudit
   */
  startedAt?: string | null;
}

export function AuditUpdatePayloadAuditFromJSON(
  json: any
): AuditUpdatePayloadAudit {
  return AuditUpdatePayloadAuditFromJSONTyped(json, false);
}

export function AuditUpdatePayloadAuditFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuditUpdatePayloadAudit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    rating: !exists(json, 'rating') ? undefined : json['rating'],
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    startedAt: !exists(json, 'started_at') ? undefined : json['started_at'],
  };
}

export function AuditUpdatePayloadAuditToJSON(
  value?: AuditUpdatePayloadAudit | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    rating: value.rating,
    owner_id: value.ownerId,
    started_at: value.startedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum AuditUpdatePayloadAuditRatingEnum {
  Compliant = 'compliant',
  ImprovementNeeded = 'improvement_needed',
  NotCompliant = 'not_compliant',
}
