import { VendorSupplierExtended } from '../../swagger';
import React, { Dispatch, SetStateAction } from 'react';
import { Accordion } from 'react-bootstrap';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';

interface ContactSectionProps {
  formData: VendorSupplierExtended;
  setFormData: Dispatch<SetStateAction<VendorSupplierExtended>>;
}

export const validation = (
  data: VendorSupplierExtended
): Record<string, string[]> => ({});

export const ContactSection: React.FC<ContactSectionProps> = ({
  formData,
  setFormData,
}) => {
  const baseCss = 'manageSupplierDrawer';
  const id = formData?.id || 'new';

  return (
    <Accordion defaultActiveKey="0">
      <AdoptechAccordionCard
        title="Contact"
        index="0"
        cardBodyClass="card-body--centered withBorderBottom"
        headerClass="noBorderBottom positionInitial"
        iconSize="small"
        noMargin
      >
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Email address</div>
            <AdoptechTextInput
              id={`vendor-${id}-contactEmail`}
              type="email"
              onChange={e => {
                setFormData({
                  ...formData,
                  contactEmail: e.currentTarget.value,
                });
              }}
              value={formData?.contactEmail}
              placeholder="Enter email address"
            />
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Primary contact name</div>
            <AdoptechTextInput
              id={`vendor-${id}-contactName`}
              value={formData?.contactName}
              type="text"
              onChange={e => {
                setFormData({
                  ...formData,
                  contactName: e.currentTarget.value,
                });
              }}
              placeholder="Enter name"
            />
          </div>
          <div className={baseCss + '--field'}>
            <div className={baseCss + '--label'}>Contact number</div>
            <AdoptechTextInput
              id={`vendor-${id}-contactNumber`}
              value={formData?.contactPhone}
              type="text"
              onChange={e => {
                setFormData({
                  ...formData,
                  contactPhone: e.currentTarget.value,
                });
              }}
              placeholder="Enter number"
            />
          </div>
        </div>
      </AdoptechAccordionCard>
    </Accordion>
  );
};
