import React from 'react';
import { Question, QuestionLayoutEnum } from '../../swagger';
import { CheckboxesAnswer } from '../CheckboxesAnswer/CheckboxesAnswer';
import { DateFieldAnswer } from '../DateFieldAnswer/DateFieldAnswer';
import { FileSelectAnswer } from '../FileSelectAnswer/FileSelectAnswer';
import { RadioButtonsAnswer } from '../RadioButtonsAnswer/RadioButtonsAnswer';
import { SelectAnswer } from '../SelectAnswer/SelectAnswer';
import { TableAnswer } from '../TableAnswer/TableAnswer';
import { TextAreaAnswer } from '../TextAreaAnswer/TextAreaAnswer';
import { TextBasedAnswer } from '../TextBasedAnswer/TextBasedAnswer';
import { CommonAnswerType } from '../../types/CommonAnswerType';
import { TableAnswer2 } from '../TableAnswer2/TableAnswer2';
import { AuditReviewTableAnswer } from '../../features/compliance/audits/AuditReviewTableAnswer';

export interface OnChangeAnswerOptions {
  isTable2Answer?: boolean;
  isCheckboxAnswer?: boolean;
}

interface AnswerProps {
  question: Question;
  questions: Question[];
  disabled?: boolean;
  readonly?: boolean;
  answers: CommonAnswerType[];
  previousAnswer?: CommonAnswerType;
  onChange: (
    answers: CommonAnswerType[],
    options?: OnChangeAnswerOptions
  ) => void;
  onDeleteTable2Answers?: (answers: CommonAnswerType[]) => void;
  indexProperty: 'name' | 'propertyName';
  vendorId?: string;
  disableDebounce?: boolean;
  asTextReadonly?: boolean;
  responseId?: string;
  usingNewSurveyStructure?: boolean;
}

export const AnswerFormControl: React.FC<AnswerProps> = props => {
  const questionTypes: { [key in QuestionLayoutEnum]: any } = {
    check_boxes: CheckboxesAnswer,
    date_field: DateFieldAnswer,
    email_field: TextBasedAnswer,
    file_select: FileSelectAnswer,
    multi_select: SelectAnswer,
    number_field: TextBasedAnswer,
    radio_buttons: RadioButtonsAnswer,
    radio_grid: RadioButtonsAnswer,
    single_select: SelectAnswer,
    text_area: TextAreaAnswer,
    text_field: TextBasedAnswer,
    audit_review_table_layout: AuditReviewTableAnswer,
    table_layout: props.usingNewSurveyStructure ? TableAnswer2 : TableAnswer,
  };
  const AnswerComponent = questionTypes[props.question.layout];

  return (
    <div>
      <AnswerComponent {...props} onChange={props.onChange} />
    </div>
  );
};
