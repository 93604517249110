import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import {
  AdoptechDrawer,
  AdoptechDrawerProps,
} from '../AdoptechDrawer/AdoptechDrawer';
import { ExitConfirmationModal } from '../ExitConfirmationModal/ExitConfirmationModal';
import './AdoptechHS.scss';

interface AdoptechHSProps {
  show: boolean;
  onClose: () => void;
  footer?: JSX.Element;
  title: string;
  extraClass?: string;
  extraFooterClass?: string;
  backButton?: AdoptechDrawerProps['backButton'];
  onBackClick?: AdoptechDrawerProps['onBackClick'];
  subHeaderTitle?: string;
  showConfirmationWarning: boolean;
  confirmText?: string;
  badges?: AdoptechDrawerProps['badges'];
  noFormPadding?: boolean;
}

export const AdoptechHS = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<AdoptechHSProps>
>((props, ref) => {
  const {
    children,
    show,
    onClose,
    footer,
    title,
    extraClass,
    extraFooterClass,
    backButton,
    onBackClick,
    subHeaderTitle,
    showConfirmationWarning,
    confirmText,
    noFormPadding,
    badges,
  } = props;
  const [isConfirmationShowing, setIsConfirmationShowing] = useState(false);

  const onClickCloseOrOutsideDrawer = (value: boolean) => {
    if (!showConfirmationWarning) {
      // Skip confirmation dialog:
      // For example, if form is not touched, don't show the confirmation
      setIsConfirmationShowing(false);
      onClose();
      return;
    }

    setIsConfirmationShowing(value);
  };
  const baseCss = 'adoptechHS--drawer';
  const mainClasses = classNames(baseCss, extraClass, {
    [`${baseCss}--with-sub-header`]: subHeaderTitle,
  });

  return (
    <div
      className="drawerBackgroundScreen"
      ref={ref}
      onClick={() => {
        onClickCloseOrOutsideDrawer(true);
      }}
      style={{ display: show ? 'block' : 'none' }}
    >
      <div
        onClick={event => {
          event.stopPropagation();
          // Don't use preventDefault, it brakes <input type="radio" /> behaviors.
        }}
      >
        <AdoptechDrawer
          inverted
          title={title}
          backButton={backButton}
          onBackClick={onBackClick}
          badges={badges || []}
          show={show}
          onClose={() => onClickCloseOrOutsideDrawer(true)}
          className={mainClasses}
        >
          {subHeaderTitle && (
            <div className={baseCss + '--sub-header pl-2'}>
              <span
                className={baseCss + '--back-button' + ' mr-2'}
                onClick={onBackClick}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </span>

              <span className={baseCss + '--sub-title'}>{subHeaderTitle}</span>
            </div>
          )}
          <div
            className={classNames({
              [baseCss + '--formContainer']: true,
              noFormPadding,
            })}
          >
            {isConfirmationShowing && (
              <ExitConfirmationModal
                text={props.confirmText}
                onConfirm={() => {
                  setIsConfirmationShowing(false);
                  onClose();
                }}
                onCancel={() => {
                  setIsConfirmationShowing(false);
                }}
              />
            )}
            {children}
          </div>
          {footer && (
            <div className={classNames(baseCss + '--footer', extraFooterClass)}>
              {footer}
            </div>
          )}
        </AdoptechDrawer>
      </div>
    </div>
  );
});

export default AdoptechHS;
