/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Answer,
  AnswerFromJSON,
  AnswerFromJSONTyped,
  AnswerToJSON,
  PreviousAnswer,
  PreviousAnswerFromJSON,
  PreviousAnswerFromJSONTyped,
  PreviousAnswerToJSON,
} from './';

/**
 *
 * @export
 * @interface QuestionRow
 */
export interface QuestionRow {
  /**
   *
   * @type {string}
   * @memberof QuestionRow
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionRow
   */
  rowText?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionRow
   */
  rowTextRight?: string | null;
  /**
   *
   * @type {string}
   * @memberof QuestionRow
   */
  propertyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof QuestionRow
   */
  name?: string | null;
  /**
   *
   * @type {PreviousAnswer}
   * @memberof QuestionRow
   */
  previousAnswer?: PreviousAnswer;
  /**
   *
   * @type {Answer}
   * @memberof QuestionRow
   */
  answer?: Answer;
}

export function QuestionRowFromJSON(json: any): QuestionRow {
  return QuestionRowFromJSONTyped(json, false);
}

export function QuestionRowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    rowText: !exists(json, 'row_text') ? undefined : json['row_text'],
    rowTextRight: !exists(json, 'row_text_right')
      ? undefined
      : json['row_text_right'],
    propertyName: !exists(json, 'property_name')
      ? undefined
      : json['property_name'],
    name: !exists(json, 'name') ? undefined : json['name'],
    previousAnswer: !exists(json, 'previous_answer')
      ? undefined
      : PreviousAnswerFromJSON(json['previous_answer']),
    answer: !exists(json, 'answer')
      ? undefined
      : AnswerFromJSON(json['answer']),
  };
}

export function QuestionRowToJSON(value?: QuestionRow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    row_text: value.rowText,
    row_text_right: value.rowTextRight,
    property_name: value.propertyName,
    name: value.name,
    previous_answer: PreviousAnswerToJSON(value.previousAnswer),
    answer: AnswerToJSON(value.answer),
  };
}
