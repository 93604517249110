/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorProductIndexFeatures,
  VendorProductIndexFeaturesFromJSON,
  VendorProductIndexFeaturesFromJSONTyped,
  VendorProductIndexFeaturesToJSON,
  VendorProductModel,
  VendorProductModelFromJSON,
  VendorProductModelFromJSONTyped,
  VendorProductModelToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorProductIndex
 */
export interface VendorProductIndex {
  /**
   *
   * @type {Array<VendorProductModel>}
   * @memberof VendorProductIndex
   */
  policies?: Array<VendorProductModel>;
  /**
   *
   * @type {Array<VendorProductModel>}
   * @memberof VendorProductIndex
   */
  agreementTemplates?: Array<VendorProductModel>;
  /**
   *
   * @type {Array<VendorProductModel>}
   * @memberof VendorProductIndex
   */
  complianceFrameworks?: Array<VendorProductModel>;
  /**
   *
   * @type {VendorProductIndexFeatures}
   * @memberof VendorProductIndex
   */
  features?: VendorProductIndexFeatures;
}

export function VendorProductIndexFromJSON(json: any): VendorProductIndex {
  return VendorProductIndexFromJSONTyped(json, false);
}

export function VendorProductIndexFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorProductIndex {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    policies: !exists(json, 'policies')
      ? undefined
      : (json['policies'] as Array<any>).map(VendorProductModelFromJSON),
    agreementTemplates: !exists(json, 'agreement_templates')
      ? undefined
      : (json['agreement_templates'] as Array<any>).map(
          VendorProductModelFromJSON
        ),
    complianceFrameworks: !exists(json, 'compliance_frameworks')
      ? undefined
      : (json['compliance_frameworks'] as Array<any>).map(
          VendorProductModelFromJSON
        ),
    features: !exists(json, 'features')
      ? undefined
      : VendorProductIndexFeaturesFromJSON(json['features']),
  };
}

export function VendorProductIndexToJSON(
  value?: VendorProductIndex | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    policies:
      value.policies === undefined
        ? undefined
        : (value.policies as Array<any>).map(VendorProductModelToJSON),
    agreement_templates:
      value.agreementTemplates === undefined
        ? undefined
        : (value.agreementTemplates as Array<any>).map(
            VendorProductModelToJSON
          ),
    compliance_frameworks:
      value.complianceFrameworks === undefined
        ? undefined
        : (value.complianceFrameworks as Array<any>).map(
            VendorProductModelToJSON
          ),
    features: VendorProductIndexFeaturesToJSON(value.features),
  };
}
