/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RiskModelActionStats
 */
export interface RiskModelActionStats {
  /**
   *
   * @type {number}
   * @memberof RiskModelActionStats
   */
  completed?: number;
  /**
   *
   * @type {number}
   * @memberof RiskModelActionStats
   */
  total?: number;
}

export function RiskModelActionStatsFromJSON(json: any): RiskModelActionStats {
  return RiskModelActionStatsFromJSONTyped(json, false);
}

export function RiskModelActionStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskModelActionStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    completed: !exists(json, 'completed') ? undefined : json['completed'],
    total: !exists(json, 'total') ? undefined : json['total'],
  };
}

export function RiskModelActionStatsToJSON(
  value?: RiskModelActionStats | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    completed: value.completed,
    total: value.total,
  };
}
