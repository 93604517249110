import { createSelector } from '@reduxjs/toolkit';
import { getVendorAssessments } from '../functions/getVendorAssessments';
import { selectSortSettings } from './selectSortSettings';
import { Grid } from '../types/grid';
import { assessmentSort } from '../functions/assessmentSort';
import { COMPLETED_TEXT } from '../types/constants';

export const selectRecentlyCreatedAssessments = createSelector(
  getVendorAssessments,
  selectSortSettings(Grid.RecentlyCreatedAssessments),
  (vendorAssessments, sortSetting) => {
    const clonedVendorAssessments = [...vendorAssessments];
    return clonedVendorAssessments
      .filter(a => a.status !== COMPLETED_TEXT)
      .sort((a, b) => assessmentSort(a, b, sortSetting.columnSort));
  }
);
