import { VoidThunk } from '../../types/voidThunk';

import { getErrorMessage } from '../helpers/thunkHelpers';
import { api } from '../../api/api';
import {
  createReportFailure,
  createReportRequest,
  createReportSuccess,
  deleteReportFailure,
  deleteReportRequest,
  deleteReportSuccess,
  fetchReportDocumentFailure,
  fetchReportDocumentRequest,
  fetchReportDocumentSuccess,
  fetchReportFailure,
  fetchReportRequest,
  fetchReportsFailure,
  fetchReportsRequest,
  fetchReportsSuccess,
  fetchReportSuccess,
} from './reportsSlice';
import {
  ReportModel,
  ReportCreatePayloadReport,
  VendorsVendorIdReportsUploadPostRequest,
} from '../../swagger';
import { showGlobalToast } from '../global/globalSlice';
import { getRequest } from '../../functions/getRequest';

export const fetchReports =
  (vendorId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchReportsRequest());
      const result = await api().vendorsVendorIdReportsGet({
        vendorId: vendorId,
      });
      dispatch(fetchReportsSuccess(result));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching s reports. Please try again or contact support.'
      );
      dispatch(fetchReportsFailure(apiErrorMessage));
    }
  };

export const deleteReport =
  (id: ReportModel['id']): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteReportRequest());
      await api().reportsIdDelete({ id });
      dispatch(deleteReportSuccess(id));
      dispatch(showGlobalToast('Report has been successfully deleted'));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while deleting  report. Please try again or contact support.'
      );
      dispatch(deleteReportFailure(apiErrorMessage));
    }
  };

export const createReport =
  (payload: ReportCreatePayloadReport, onSuccess?: () => void): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(createReportRequest());
      const body = { report: payload };
      dispatch(showGlobalToast('Generating report. Please wait...'));
      const report = await api().vendorsVendorIdReportsPost({
        vendorId,
        body,
      });
      dispatch(createReportSuccess(report));
      dispatch(showGlobalToast('A report has been successfully generated.'));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while creating report. Please try again or contact support.'
      );
      dispatch(createReportFailure(apiErrorMessage));
    }
  };

interface UploadReportPayload {
  completedAt: string;
  file: File;
  name: string;
  ownerId: string;
  version: string;
}

export const uploadReport =
  (payload: UploadReportPayload, onSuccess?: () => void): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(createReportRequest());
      dispatch(showGlobalToast('Uploading report. Please wait...'));

      const response = await api().vendorsVendorIdReportsUploadPost({
        vendorId,
        reportCompletedAt: payload.completedAt,
        reportFile: payload.file,
        reportName: payload.name,
        reportOwnerId: payload.ownerId,
        reportVersion: payload.version,
      });

      dispatch(createReportSuccess(response));
      dispatch(showGlobalToast('A report has been successfully uploaded.'));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while uploading report. Please try again or contact support.'
      );
      dispatch(createReportFailure(apiErrorMessage));
    }
  };

export const fetchReport =
  (id: string, onSuccess?: (report: ReportModel) => void): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchReportRequest());
      const result = await api().reportsIdGet({ id });
      dispatch(fetchReportSuccess(result));
      onSuccess && onSuccess(result);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching report. Please try again or contact support.'
      );
      dispatch(fetchReportFailure(apiErrorMessage));
    }
  };

export const fetchReportDocument =
  ({
    id,
    successCallback,
  }: {
    id: ReportModel['id'];
    successCallback?: (response: string) => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchReportDocumentRequest());
      // TODO: fix BE swag void type issue
      const response = await getRequest(`/api/v1/reports/${id}/document`);
      const convertedData = await response.blob();
      const url = URL.createObjectURL(convertedData);

      dispatch(fetchReportDocumentSuccess());
      successCallback && successCallback(url);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching report document. Please try again or contact support.'
      );
      dispatch(fetchReportDocumentFailure(apiErrorMessage));
    }
  };
