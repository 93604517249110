import { useSelector } from 'react-redux';
import { JurisdictionModel } from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';

export const useJurisdictionsList = () => {
  const { addLegislationForm: form } = useSelector(
    (state: ApplicationState) => state.legalRegister
  );

  const allJurisdictions = [...(form.jurisdictions || [])].sort((a, b) => {
    const aHasTemplates = (form.legislationTemplates || []).some(
      template => template.jurisdiction?.id === a.id
    );
    const bHasTemplates = (form.legislationTemplates || []).some(
      template => template.jurisdiction?.id === b.id
    );

    if (aHasTemplates && !bHasTemplates) {
      return -1;
    } else if (!aHasTemplates && bHasTemplates) {
      return 1;
    } else {
      return 0;
    }
  });

  const notEmptyJurisdictions =
    allJurisdictions?.filter(jurisdiction => {
      return (form.legislationTemplates || []).some(
        template => template.jurisdiction?.id === jurisdiction.id
      );
    }) || [];

  const mapToOptions = (jurisdictions: JurisdictionModel[]) => {
    return jurisdictions.map(jurisdiction => ({
      value: jurisdiction.id,
      label: jurisdiction.name,
    }));
  };

  const notEmptyJurisdictionOptions = mapToOptions(notEmptyJurisdictions);
  const allJurisdictionOptions = mapToOptions(allJurisdictions);

  return {
    notEmptyJurisdictions,
    notEmptyJurisdictionOptions,
    allJurisdictions,
    allJurisdictionOptions,
  };
};
