import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideConfirmDeleteAssessmentResponseModal } from '../../store/assessments/assessmentsSlice';
import {
  deleteAssessmentRecipient,
  deleteAssessmentResponse,
} from '../../store/assessments/assessmentsThunks';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import './ConfirmDeleteAssessmentResponseModal.scss';

export const ConfirmDeleteAssessmentResponseModal: React.FC = () => {
  const dispatch = useDispatch();

  const {
    assessmentResponseForDeletion,
    currentAssessment,
    isDeletingAssessmentRecipient,
    isDeletingAssessmentResponse,
    isShowingConfirmDeleteAssessmentResponse,
  } = useSelector((state: ApplicationState) => state.assessments);

  const handleDelete = () => {
    if (assessmentResponseForDeletion.assessmentResponseId) {
      dispatch(
        deleteAssessmentResponse(
          assessmentResponseForDeletion.assessmentResponseId
        )
      );
    } else {
      dispatch(
        deleteAssessmentRecipient(
          currentAssessment.id,
          assessmentResponseForDeletion.recipientId
        )
      );
    }
  };

  return (
    <AdoptechModal
      onHide={() => dispatch(hideConfirmDeleteAssessmentResponseModal())}
      show={isShowingConfirmDeleteAssessmentResponse}
    >
      <Modal.Header>Delete Responder Assessment</Modal.Header>
      <Modal.Body className="confirmDeleteAssessmentResponseModal--body">
        <div className="confirmDeleteAssessmentResponseModal--instructions">
          Deleting the assessment from this Responder will invalidate the email
          link sent to them or, if already activated, will delete the assessment
          from their portal. This cannot be undone. Select DELETE to remove this
          Responder or Cancel to go back.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={() => dispatch(hideConfirmDeleteAssessmentResponseModal())}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          busy={isDeletingAssessmentRecipient || isDeletingAssessmentResponse}
          onClick={handleDelete}
          variant={AdoptechButtonVariant.Primary}
        >
          DELETE
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
