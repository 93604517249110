import { createSelector } from '@reduxjs/toolkit';
import { completedAssessmentSort } from '../functions/completedAssessmentSort';
import { getVendorAssessmentResponses } from '../functions/getVendorAssessmentResponses';
import { COMPLETED_TEXT } from '../types/constants';
import { Grid } from '../types/grid';
import {
  CompletedAssessment,
  CompletedAssessmentType,
} from './selectCompletedAssessments';
import { selectSortSettings } from './selectSortSettings';

export const selectCompletedAssessmentsResponses = createSelector(
  getVendorAssessmentResponses,
  selectSortSettings(Grid.CompletedAssessments),

  (vendorAssessmentResponses, sortSetting) => {
    const assessmentResponses: CompletedAssessment[] = vendorAssessmentResponses
      .filter(ar => ar.status === COMPLETED_TEXT)
      .map(ar => {
        return {
          id: ar.id,
          name: ar.name,
          createdBy: ar.requestingVendorName,
          owner: ar.owner,
          dateCompleted: ar.updatedAt,
          type: CompletedAssessmentType.AssessmentResponse,
          path: ar.relativePath,
        };
      });

    return assessmentResponses.sort((a, b) =>
      completedAssessmentSort(a, b, sortSetting.columnSort)
    );
  }
);
