import React from 'react';
import { AgreementExtended, Answer, Question } from '../../../swagger';
import './AgreementQuestion.scss';
import { Interweave } from 'interweave';
import { AnswerFormControl } from '../../AnswerFormControl/AnswerFormControl';
import { LastUpdatedInfo } from '../../LastUpdatedInfo/LastUpdatedInfo';
import { getClauseLastUpdatedInfo } from '../../../functions/getClauseLastUpdatedInfo';
import { sortByDateColumn } from '../../../functions/sortByDateColumn';
import { useHandleAnswerChange } from './useHandleAnswerChange';

interface AgreementQuestionProps {
  question: Question;
  agreement: AgreementExtended;
}

export const useLastUpdated = (question: Question) => {
  const latestAnswer = () => {
    const questionHasManyAnswers = question?.answer?.questionRowId;
    if (!questionHasManyAnswers) return question.answer;

    const arr = (question?.questionRows || []).map(q => q.answer);
    return sortByDateColumn(arr, 'updatedAt', -1)[0];
  };
  const lastUpdatedAnswer = latestAnswer();
  return lastUpdatedAnswer
    ? getClauseLastUpdatedInfo(
        lastUpdatedAnswer.updatedAt,
        lastUpdatedAnswer.userName
      )
    : null;
};

export const AgreementQuestion: React.FC<AgreementQuestionProps> = ({
  question,
  agreement,
}) => {
  const lastUpdated = useLastUpdated(question);
  const { handleChange, handleDeleteTableAnswers } = useHandleAnswerChange({
    currentModel: agreement,
    mode: 'agreements',
    question,
  });

  return (
    <div className="agreementQuestion">
      <div className="agreementQuestion--questionText">
        <Interweave content={question.questionTextAsHtml} />
      </div>
      {question.explanatoryTextAsHtml && (
        <div className="agreementQuestion--explanatoryText">
          <Interweave content={question.explanatoryTextAsHtml} />
        </div>
      )}
      <div className="agreementQuestion--answerArea">
        <AnswerFormControl
          onDeleteTable2Answers={handleDeleteTableAnswers}
          usingNewSurveyStructure
          answers={[]}
          question={question}
          questions={[]}
          onChange={handleChange}
          previousAnswer={question.answer}
          indexProperty="propertyName"
        />
      </div>
      {lastUpdated && <LastUpdatedInfo lastUpdatedInfo={lastUpdated} />}
    </div>
  );
};
