import { QuestionType } from '../types/QuestionType';

const isTableAnswerConfirmed = (tableQuestions: QuestionType[]): boolean => {
  return tableQuestions.every(
    question =>
      question.answers.length &&
      question.answers.every(answer => answer.isConfirmed)
  );
};

export default isTableAnswerConfirmed;
