/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  AttestationFrequencyEnum,
  AttestationFrequencyEnumFromJSON,
  AttestationFrequencyEnumFromJSONTyped,
  AttestationFrequencyEnumToJSON,
  ReviewFrequencyEnum,
  ReviewFrequencyEnumFromJSON,
  ReviewFrequencyEnumFromJSONTyped,
  ReviewFrequencyEnumToJSON,
  VendorDocument,
  VendorDocumentFromJSON,
  VendorDocumentFromJSONTyped,
  VendorDocumentToJSON,
  VendorDocumentApproval,
  VendorDocumentApprovalFromJSON,
  VendorDocumentApprovalFromJSONTyped,
  VendorDocumentApprovalToJSON,
  VendorDocumentApprovalStats,
  VendorDocumentApprovalStatsFromJSON,
  VendorDocumentApprovalStatsFromJSONTyped,
  VendorDocumentApprovalStatsToJSON,
  VendorDocumentAttestation,
  VendorDocumentAttestationFromJSON,
  VendorDocumentAttestationFromJSONTyped,
  VendorDocumentAttestationToJSON,
  VendorDocumentAttestationStats,
  VendorDocumentAttestationStatsFromJSON,
  VendorDocumentAttestationStatsFromJSONTyped,
  VendorDocumentAttestationStatsToJSON,
  VendorDocumentDownloadAllOf,
  VendorDocumentDownloadAllOfFromJSON,
  VendorDocumentDownloadAllOfFromJSONTyped,
  VendorDocumentDownloadAllOfToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorDocumentDownload
 */
export interface VendorDocumentDownload extends VendorDocument {
  /**
   *
   * @type {string}
   * @memberof VendorDocumentDownload
   */
  document: string | null;
  /**
   *
   * @type {number}
   * @memberof VendorDocumentDownload
   */
  fileSizeInBytes: number | null;
}

export function VendorDocumentDownloadFromJSON(
  json: any
): VendorDocumentDownload {
  return VendorDocumentDownloadFromJSONTyped(json, false);
}

export function VendorDocumentDownloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorDocumentDownload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...VendorDocumentFromJSONTyped(json, ignoreDiscriminator),
    document: json['document'],
    fileSizeInBytes: json['file_size_in_bytes'],
  };
}

export function VendorDocumentDownloadToJSON(
  value?: VendorDocumentDownload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...VendorDocumentToJSON(value),
    document: value.document,
    file_size_in_bytes: value.fileSizeInBytes,
  };
}
