import { createSelector } from '@reduxjs/toolkit';
import { AgreementModel, AgreementModelStatusEnum } from '../swagger';
import { ApplicationState } from '../types/applicationState';

export const selectAwaitingSignatureAgreements = createSelector(
  (state: ApplicationState) => state.agreements.agreements,
  (agreements: AgreementModel[]) => {
    const awaitingSignatureAgreements = agreements.filter(
      (agreement: AgreementModel) =>
        agreement.status === AgreementModelStatusEnum.AwaitingSignature
    );
    const rejectedAgreements = agreements.filter(
      (agreement: AgreementModel) =>
        agreement.status === AgreementModelStatusEnum.Rejected
    );

    return [...awaitingSignatureAgreements, ...rejectedAgreements];
  }
);
