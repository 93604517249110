/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubCompanyCreatePayloadTrusthubCompany,
  TrusthubCompanyCreatePayloadTrusthubCompanyFromJSON,
  TrusthubCompanyCreatePayloadTrusthubCompanyFromJSONTyped,
  TrusthubCompanyCreatePayloadTrusthubCompanyToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubCompanyCreatePayload
 */
export interface TrusthubCompanyCreatePayload {
  /**
   *
   * @type {TrusthubCompanyCreatePayloadTrusthubCompany}
   * @memberof TrusthubCompanyCreatePayload
   */
  trusthubCompany: TrusthubCompanyCreatePayloadTrusthubCompany;
}

export function TrusthubCompanyCreatePayloadFromJSON(
  json: any
): TrusthubCompanyCreatePayload {
  return TrusthubCompanyCreatePayloadFromJSONTyped(json, false);
}

export function TrusthubCompanyCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubCompanyCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    trusthubCompany: TrusthubCompanyCreatePayloadTrusthubCompanyFromJSON(
      json['trusthub_company']
    ),
  };
}

export function TrusthubCompanyCreatePayloadToJSON(
  value?: TrusthubCompanyCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    trusthub_company: TrusthubCompanyCreatePayloadTrusthubCompanyToJSON(
      value.trusthubCompany
    ),
  };
}
