import { api } from '../../api/api';
import { getApiErrorMessage } from '../../functions/getApiErrorMessage';
import {
  agreementAnswersFailure,
  agreementsRequest,
  agreementsRequestFailure,
  agreementsRequestSuccess,
  agreementTemplatesRequest,
  agreementTemplatesRequestFailure,
  agreementTemplatesRequestSuccess,
  createAgreementRequestFailure,
  createOrUpdateSignatoryRequestFailure,
  currentAgreementRequestFailure,
  currentAgreementSignatoriesRequest,
  currentAgreementSignatoriesRequestFailure,
  currentAgreementSignatoriesRequestSuccess,
  deleteAgreementFailure,
  deleteAgreementTableAnswersFailure,
  notificationDispatchError,
  patchAgreementContentFailure,
  patchAgreementContentRequest,
  patchAgreementContentSuccess,
  setAgreementCreationStatus,
  setCurrentAgreement,
  setCurrentAgreementLoading,
  setAgreementEditFormExtendedFieldsLoaded,
  setDeleteAgreementStatus,
  setNotificationDispatchStatus,
  setSignAgreementStatus,
  setSignatoryCreationOrUpdateStatus,
  signAgreementFailure,
  editAgreementClauseFailure,
  revertAgreementClauseFailure,
  createNewVersionOfAgreementFailure,
  createNewVersionOfAgreementRequest,
  patchAgreementRequest,
  patchAgreementSuccess,
  patchAgreementFailure,
  agreementTemplateCategoriesRequest,
  agreementTemplateCategoriesRequestSuccess,
  agreementTemplateCategoriesRequestFailure,
  archiveAgreementRequest,
  archiveAgreementSuccess,
  archiveAgreementFailure,
  setArchivingAgreement,
  unArchiveAgreementRequest,
  unArchiveAgreementRequestFailure,
  undoArchivedAgreement,
  updateAgreementsEditFormField,
  postAgreementAnswer,
  agreementUploadRequest,
  agreementUploadRequestSuccess,
  agreementUploadRequestFailure,
  updateAnswer,
  sendToReviewRequest,
  sendToReviewSuccess,
  sendToReviewFailure,
  isPostingAgreementAnswerFinished,
  setAgreementMode,
} from './agreementsSlice';
import { VoidThunk } from '../../types/voidThunk';
import { UNKNOWN_ERROR } from '../../types/constants';
import {
  AgreementCreatePayload,
  AgreementExtended,
  AgreementModel,
  AgreementSignatoryCreateExternalPayload,
  AgreementSignatoryNotifyPayload,
  AgreementSignatoriesIdPatchRequest,
  AgreementVersionCreatePayloadVersionBumpEnum,
  Answer,
  SurveyClauseUpdatePayload,
  VendorsIdAgreementsUploadPostRequest,
  AgreementSignatoryModel,
} from '../../swagger';
import { mapTableAnswers } from '../../functions/mapTableAnswers';
import { getErrorMessage } from '../helpers/thunkHelpers';
import {
  showGlobalToast,
  showGlobalToastWithUndo,
} from '../global/globalSlice';
import {
  hideDocumentUploadModal,
  uploadVendorDocumentFailure,
  uploadVendorDocumentRequest,
  uploadVendorDocumentSuccess,
} from '../vendors/vendorsSlice';

export const createAgreement =
  ({
    vendorId,
    body,
    onSuccess = () => {},
    onError = () => {},
  }: {
    vendorId: string;
    body: AgreementCreatePayload;
    onSuccess?: (response: AgreementExtended) => void;
    onError?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(setAgreementCreationStatus(true));
      const response = await api().vendorsIdAgreementsPostRaw({
        id: vendorId,
        body,
      });
      const data = await response.value();
      dispatch(setAgreementCreationStatus(false));
      onSuccess && onSuccess(data);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while creating the agreement. Please try again or contact support.'
      );
      dispatch(createAgreementRequestFailure(apiErrorMessage));
      onError && onError();
    }
  };

export const fetchAgreementTemplates =
  (): VoidThunk => async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor.id;
      dispatch(agreementTemplatesRequest());
      const response = await api().agreementTemplatesGet({ vendorId });
      dispatch(agreementTemplatesRequestSuccess(response));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred fetching agreements templates. Please try again or contact support.'
      );
      dispatch(agreementTemplatesRequestFailure(apiErrorMessage));
    }
  };

export const fetchAgreementTemplateCategories =
  (): VoidThunk => async dispatch => {
    try {
      dispatch(agreementTemplateCategoriesRequest());
      const response = await api().agreementTemplateCategoriesGet();
      dispatch(agreementTemplateCategoriesRequestSuccess(response));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching agreements categories templates. Please try again or contact support.'
      );
      dispatch(agreementTemplateCategoriesRequestFailure(apiErrorMessage));
    }
  };

export const fetchAgreements =
  (
    vendorId: string,
    withLoader = true,
    onSuccess?: (response: AgreementModel[]) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(agreementsRequest(withLoader));
      const response = await api().vendorsIdAgreementsGet({
        id: vendorId,
      });
      dispatch(agreementsRequestSuccess(response));
      onSuccess && onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred fetching in progress agreements. Please try again or contact support.'
      );
      dispatch(agreementsRequestFailure(apiErrorMessage));
    }
  };

export const uploadCompletedAgreement =
  (params: VendorsIdAgreementsUploadPostRequest): VoidThunk =>
  async dispatch => {
    try {
      dispatch(agreementUploadRequest());
      const response = await api().vendorsIdAgreementsUploadPost(params);
      dispatch(agreementUploadRequestSuccess(response));
      dispatch(hideDocumentUploadModal());
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while uploading agreement. Please try again or contact support.'
      );
      dispatch(agreementUploadRequestFailure(apiErrorMessage));
    }
  };

export const fetchAgreementEditFormFields =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      const response = await api().agreementsIdGet({
        id,
      });
      const { useBrandingColor, useCompanyLogo } = response;
      dispatch(
        updateAgreementsEditFormField({ useBrandingColor, useCompanyLogo })
      );
      dispatch(setAgreementEditFormExtendedFieldsLoaded(true));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(currentAgreementRequestFailure(apiErrorMessage));
        } else {
          dispatch(
            currentAgreementRequestFailure(
              'An error occurred fetching extended agreement data. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(currentAgreementRequestFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchAgreementById =
  (
    id: string,
    withLoader = true,
    onSuccess?: (response: AgreementExtended) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(setCurrentAgreementLoading(withLoader));
      const response = await api().agreementsIdGet({
        id,
      });
      dispatch(setCurrentAgreement(mapTableAnswers(response)));
      dispatch(setCurrentAgreementLoading(false));
      onSuccess && onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred fetching agreement. Please try again or contact support.'
      );
      dispatch(currentAgreementRequestFailure(apiErrorMessage));
    }
  };

export const addAgreementAnswers =
  ({
    vendorId,
    answers,
    onSuccess,
  }: {
    vendorId: string;
    answers: Answer[];
    onSuccess?(): void;
  }): VoidThunk =>
  async dispatch => {
    try {
      answers.forEach(a => dispatch(updateAnswer(a)));
      dispatch(postAgreementAnswer());
      await api().vendorsIdAnswersPost({
        id: vendorId,
        answers: {
          answers,
        },
      });
      dispatch(isPostingAgreementAnswerFinished());
      onSuccess && onSuccess();
    } catch (err) {
      dispatch(isPostingAgreementAnswerFinished());
      console.log('err', err);
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while adding agreement answer. Please try again or contact support.'
      );
      console.log(apiErrorMessage);
      dispatch(agreementAnswersFailure(apiErrorMessage));
      reloadPageIfAgreementSigned(apiErrorMessage);
    }
  };

// If agreement signed by external counterpart => it's not in draft state => we cannot edit agreement
const reloadPageIfAgreementSigned = (message: string) => {
  if (message.includes('should be in draft state')) {
    window.location.reload();
  }
};
export const fetchAgreementSignatoriesById =
  (
    agreementId: string,
    onSuccess?: (signatories: AgreementSignatoryModel[]) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(currentAgreementSignatoriesRequest());
      const response =
        await api().agreementsAgreementIdAgreementSignatoriesGetRaw({
          agreementId,
        });
      const data = await response.value();
      dispatch(currentAgreementSignatoriesRequestSuccess(data));
      onSuccess && onSuccess(data);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching agreementSignatories. Please try again or contact support.'
      );
      dispatch(currentAgreementSignatoriesRequestFailure(apiErrorMessage));
    }
  };

export const updateInternalAgreementSignatory =
  ({
    agreementId,
    body,
    onSuccess = () => {},
  }: {
    agreementId: string;
    body: any;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(setSignatoryCreationOrUpdateStatus(true));
      await api().agreementsAgreementIdAgreementSignatoriesInternalPatchRaw({
        agreementId,
        body,
      });
      onSuccess && onSuccess();
      dispatch(setSignatoryCreationOrUpdateStatus(false));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(createOrUpdateSignatoryRequestFailure(apiErrorMessage));
        } else {
          dispatch(
            createOrUpdateSignatoryRequestFailure(
              'An error occurred updating an agreement internal signatory. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(createOrUpdateSignatoryRequestFailure(UNKNOWN_ERROR));
      }
    }
  };

export const updateExternalAgreementSignatory =
  ({
    body,
    onSuccess = () => {},
    onError = () => {},
  }: {
    body: AgreementSignatoriesIdPatchRequest;
    onSuccess?: () => void;
    onError?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(setSignatoryCreationOrUpdateStatus(true));
      await api().agreementSignatoriesIdPatchRaw(body);
      onSuccess && onSuccess();
      dispatch(setSignatoryCreationOrUpdateStatus(false));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(createOrUpdateSignatoryRequestFailure(apiErrorMessage));
        } else {
          dispatch(
            createOrUpdateSignatoryRequestFailure(
              'An error occurred updating an agreement external signatory. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(createOrUpdateSignatoryRequestFailure(UNKNOWN_ERROR));
      }
      onError && onError();
    }
  };

export const createExternalAgreementSignatory =
  ({
    agreementId,
    body,
    onSuccess = () => {},
    onError = () => {},
  }: {
    agreementId: string;
    body: AgreementSignatoryCreateExternalPayload;
    onSuccess?: () => void;
    onError?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(setSignatoryCreationOrUpdateStatus(true));
      await api().agreementsAgreementIdAgreementSignatoriesExternalPostRaw({
        agreementId,
        body,
      });
      onSuccess && onSuccess();
      dispatch(setSignatoryCreationOrUpdateStatus(false));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(createOrUpdateSignatoryRequestFailure(apiErrorMessage));
        } else {
          dispatch(
            createOrUpdateSignatoryRequestFailure(
              'An error occurred creating an agreement external signatory. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(createOrUpdateSignatoryRequestFailure(UNKNOWN_ERROR));
      }
      onError && onError();
    }
  };

export const sendSignatoryNotification =
  ({
    signatoryId,
    body,
    onSuccess = () => {},
  }: {
    signatoryId: string;
    body: AgreementSignatoryNotifyPayload;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(setNotificationDispatchStatus(true));
      await api().agreementSignatoriesSignatoryIdNotifyPostRaw({
        signatoryId,
        body,
      });
      onSuccess && onSuccess();
      dispatch(setNotificationDispatchStatus(false));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(notificationDispatchError(apiErrorMessage));
        } else {
          dispatch(
            notificationDispatchError(
              'An error occurred creating an agreement external signatory. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(notificationDispatchError(UNKNOWN_ERROR));
      }
    }
  };

export const deleteAgreement =
  (id: string, onSuccess: () => void): VoidThunk =>
  async dispatch => {
    try {
      dispatch(setDeleteAgreementStatus(true));
      await api().agreementsIdDelete({ id });
      dispatch(setDeleteAgreementStatus(false));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(deleteAgreementFailure(apiErrorMessage));
        } else {
          dispatch(
            deleteAgreementFailure(
              'An error occurred while deleting agreement. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(deleteAgreementFailure(UNKNOWN_ERROR));
      }
    }
  };

export const deleteAgreementTableAnswers =
  ({
    answersIds,
    vendorId,
    onSuccess,
  }: {
    answersIds: string[];
    vendorId: string;
    onSuccess: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      await api().vendorsIdAnswersDelete({
        id: vendorId,
        body: { answersIds },
      });
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(deleteAgreementTableAnswersFailure(apiErrorMessage));
        } else {
          dispatch(
            deleteAgreementTableAnswersFailure(
              'An error occurred while deleting answer. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(deleteAgreementTableAnswersFailure(UNKNOWN_ERROR));
      }
    }
  };

export const sendToReviewAgreement =
  ({
    agreementId,
    signatoryIds,
    onSuccess,
  }: {
    agreementId: string;
    signatoryIds: AgreementSignatoryModel['id'][];
    onSuccess: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(sendToReviewRequest());
      await api().agreementsIdSendPost({
        id: agreementId,
        body: { signatoriesToNotifyIds: signatoryIds },
      });
      dispatch(sendToReviewSuccess());
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while sending to review. Please try again or contact support.'
      );
      console.log(err);
      dispatch(sendToReviewFailure(apiErrorMessage));
    }
  };

export interface SignAgreementArguments {
  image?: Blob;
  userId?: string;
  signatoryId: string;
  onSuccess?(): void;
}

export const signAgreement =
  ({
    image,
    userId,
    signatoryId,
    onSuccess,
  }: SignAgreementArguments): VoidThunk =>
  async dispatch => {
    try {
      dispatch(setSignAgreementStatus(true));
      if (image && userId) {
        await api().usersIdSignaturePatch({
          id: userId,
          image,
        });
      }
      await api().agreementSignatoriesSignatoryIdSignPatch({
        signatoryId,
      });
      dispatch(setSignAgreementStatus(false));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while signing agreement. Please try again or contact support.'
      );
      console.log(err);
      dispatch(signAgreementFailure(apiErrorMessage));
    }
  };

export const editAgreementClause =
  ({
    surveyClauseId,
    agreementId,
    renderedText,
    useCustomText,
    onSuccess,
  }: {
    surveyClauseId: string;
    agreementId: string;
    renderedText: string;
    useCustomText: boolean;
    onSuccess?(): void;
  }): VoidThunk =>
  async dispatch => {
    try {
      const body: SurveyClauseUpdatePayload = { renderedText, useCustomText };
      await api().surveyClausesSurveyClauseIdPatch({
        surveyClauseId,
        body,
      });
      await dispatch(fetchAgreementById(agreementId, false));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(editAgreementClauseFailure(apiErrorMessage));
        } else {
          dispatch(
            editAgreementClauseFailure(
              'An error occurred while editing agreement clause. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(signAgreementFailure(UNKNOWN_ERROR));
      }
    }
  };

export const revertAgreementClause =
  ({
    surveyClauseId,
    agreementId,
    onSuccess,
  }: {
    surveyClauseId: string;
    agreementId: string;
    onSuccess?(): void;
  }): VoidThunk =>
  async dispatch => {
    try {
      await api().surveyClausesSurveyClauseIdRevertCustomTextPatch({
        surveyClauseId,
      });
      await dispatch(fetchAgreementById(agreementId, false));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(revertAgreementClauseFailure(apiErrorMessage));
        } else {
          dispatch(
            revertAgreementClauseFailure(
              'An error occurred while reverting agreement clause. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(revertAgreementClauseFailure(UNKNOWN_ERROR));
      }
    }
  };

export const createNewVersionOfAgreement =
  ({
    id,
    versionBump,
    changeReason,
    onSuccess = () => {},
  }: {
    id: string;
    versionBump?: string;
    changeReason?: string;
    onSuccess?: (response: AgreementExtended) => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(createNewVersionOfAgreementRequest(true));
      const response = await api().agreementsIdVersionsPost({
        id,
        body: {
          versionBump:
            versionBump as AgreementVersionCreatePayloadVersionBumpEnum,
          changeReason,
        },
      });
      dispatch(createNewVersionOfAgreementRequest(false));
      onSuccess && onSuccess(response);
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(createNewVersionOfAgreementFailure(apiErrorMessage));
        } else {
          dispatch(
            createNewVersionOfAgreementFailure(
              'An error occurred while updating the agreement. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(createNewVersionOfAgreementFailure(UNKNOWN_ERROR));
      }
    }
  };

export const patchAgreement =
  ({
    agreementId,
    ownerId,
    name,
    useBrandingColor,
    useCompanyLogo,
    reviewDate,
    contentIsCustom,
    onSuccess,
    onError,
  }: {
    agreementId: string;
    ownerId?: string;
    name?: string;
    useBrandingColor?: boolean;
    useCompanyLogo?: boolean;
    reviewDate?: string;
    contentIsCustom?: boolean;
    onSuccess?: () => void;
    onError?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(patchAgreementRequest());
      const result = await api().agreementsIdPatch({
        id: agreementId,
        body: {
          ownerId,
          name,
          reviewDate,
          useBrandingColor,
          useCompanyLogo,
          contentIsCustom,
        },
      });
      const agreementWithTableAnswers = mapTableAnswers(result);
      dispatch(patchAgreementSuccess(agreementWithTableAnswers));
      onSuccess && onSuccess();
    } catch (err) {
      onError && onError();

      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred updating the agreement. Please try again or contact support.'
        );
        console.log(err);
        dispatch(patchAgreementFailure(apiErrorMessage));
        reloadPageIfAgreementSigned(apiErrorMessage);
      }
    }
  };

export const unarchiveAgreement =
  ({ showToast }: { showToast: boolean }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(unArchiveAgreementRequest());
      const { id, name } = getState().agreements.archivingAgreement || {};
      if (!id) return;

      await api().agreementsIdUnarchivePost({ id });

      if (showToast)
        dispatch(
          showGlobalToast(
            `${name} has been removed from the archive and will now be visible on the Agreement page.`
          )
        );

      dispatch(undoArchivedAgreement());
    } catch (err) {
      console.log('error');
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred unarchiving the agreement. Please try again or contact support.'
        );
        console.log(err);
        dispatch(unArchiveAgreementRequestFailure(apiErrorMessage));
      }
    }
  };

export const archiveAgreement =
  ({
    id,
    name,
  }: {
    id: AgreementModel['id'];
    name: AgreementModel['name'];
  }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setArchivingAgreement(id));

      const agreementIndex = getState().agreements.agreements.findIndex(
        model => model.id === id
      );
      window.localStorage.setItem(
        'agreement-archived-index',
        agreementIndex.toString()
      );
      dispatch(archiveAgreementRequest());
      await api().agreementsIdArchivePost({ id });

      dispatch(archiveAgreementSuccess(id));
      dispatch(
        showGlobalToastWithUndo(
          `${name} has been moved to the archive on the Documents Page. It will no longer be visible on the Agreement page.`
        )
      );
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred archiving the agreement. Please try again or contact support.'
        );
        console.log(err);
        dispatch(archiveAgreementFailure(apiErrorMessage));
      }
    }
  };

export const patchAgreementContent =
  ({
    agreementId,
    content,
    onSuccess,
  }: {
    agreementId: string;
    content: string;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(patchAgreementContentRequest());
      const result = await api().agreementsIdPatch({
        id: agreementId,
        body: {
          content,
          contentIsCustom: true,
        },
      });
      dispatch(patchAgreementContentSuccess(result));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred updating the agreement content. Please try again or contact support.'
        );
        console.log(err);
        dispatch(patchAgreementContentFailure(apiErrorMessage));
        reloadPageIfAgreementSigned(apiErrorMessage);
      }
    }
  };

export const uploadAgreement =
  ({
    id,
    agreementName,
    agreementDocument,
    agreementDescription,
  }: {
    id: string;
    agreementName: string;
    agreementDocument: File;
    agreementDescription?: string;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(uploadVendorDocumentRequest());
      await api().vendorsIdAgreementsUploadPost({
        id,
        agreementName,
        agreementDocument,
        agreementDescription,
      });

      dispatch(uploadVendorDocumentSuccess());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(uploadVendorDocumentFailure(apiErrorMessage));
        } else {
          dispatch(
            uploadVendorDocumentFailure(
              'An error occurred while uploading an agreement. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(uploadVendorDocumentFailure(UNKNOWN_ERROR));
      }
    }
  };
