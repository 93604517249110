import { createSelector } from '@reduxjs/toolkit';
import { CompanyExtended, VendorDetails } from '../swagger';
import { selectVendorDetails } from './selectVendorDetails';

// Flow on initial load:
// 1) fetch all vendors with companies
// 2) fetch current company extended and save it to the same store to avoid dup state

export const selectCurrentCompany = createSelector(
  selectVendorDetails,
  (vendorDetails: VendorDetails): CompanyExtended => {
    if (vendorDetails && vendorDetails.companies.length > 0) {
      return vendorDetails.companies[0];
    }

    return undefined;
  }
);
