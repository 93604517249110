import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router';
import { hideAssessmentSentToast } from '../../store/assessments/assessmentsSlice';
import {
  fetchAssessment,
  fetchIncomingAssessmentResponses,
} from '../../store/assessments/assessmentsThunks';
import { ApplicationState } from '../../types/applicationState';
import { AssessmentSummaryQuestions } from '../AssessmentSummaryQuestions/AssessmentSummaryQuestions';
import { AssessmentSummaryResponses } from '../AssessmentSummaryResponses/AssessmentSummaryResponses';
import {
  ActionType,
  AssessmentSummaryTile,
} from '../AssessmentSummaryTile/AssessmentSummaryTile';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { Intent, Lozenge } from '../Lozenge/Lozenge';
import { MessageToast } from '../MessageToast/MessageToast';
import { SendAssessmentConfirmationModal } from '../SendAssessmentConfirmationModal/SendAssessmentConfirmationModal';
import { SendAssessmentModal } from '../SendAssessmentModal/SendAssessmentModal';
import './AssessmentSummary.scss';
import { useQueryParams } from '../../hooks/useQueryParams';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { usePrevious } from 'react-use';
import { COMPLETED_TEXT } from '../../types/constants';

export const AssessmentSummary: React.FC = () => {
  const params = useParams() as { id: string };
  const searchVendorId = useQueryParams().get('vendor_id');
  const assessmentId = params.id;

  const currentVendor = useSelector(selectCurrentVendor);
  const assessmentResponses = useSelector(
    (state: ApplicationState) => state.assessments.assessmentResponses
  );

  const dispatch = useDispatch();

  const previousVendorRef = usePrevious(currentVendor);
  useEffect(() => {
    if (previousVendorRef && previousVendorRef.id !== currentVendor.id) {
      dispatch(push('/r/riskManagement/assessments/summary'));
    }
  }, [currentVendor]);

  useEffect(() => {
    // If we have a different vendor ID as a search param on the URL then it's likely we have got here from
    // an invitation to complete an assessment. See if we have a match in assessment responses and redirect there
    // if one is found.
    if (searchVendorId && searchVendorId !== currentVendor.id) {
      const matchingAssessmentResponse = assessmentResponses.find(
        ar => ar.assessmentId === assessmentId
      );
      if (!matchingAssessmentResponse) return;
      dispatch(
        push(
          `/r/riskManagement/assessments/response/${matchingAssessmentResponse.id}`
        )
      );
      return;
    }

    if (!assessmentId) {
      dispatch(push('/r/riskManagement/assessments/summary'));
    } else {
      dispatch(fetchAssessment(assessmentId));
      dispatch(fetchIncomingAssessmentResponses(assessmentId));
    }
  }, [assessmentResponses]);

  const currentAssessment = useSelector(
    (state: ApplicationState) => state.assessments.currentAssessment
  );

  const isAssessmentSentToastShowing = useSelector(
    (state: ApplicationState) => state.assessments.isAssessmentSentToastShowing
  );

  const incomingAssessmentResponses = useSelector(
    (state: ApplicationState) => state.assessments.incomingAssessmentResponses
  );

  const isShowingSendAssessmentModal = useSelector(
    (state: ApplicationState) => state.assessments.isShowingSendAssessmentModal
  );

  const completedResponses = incomingAssessmentResponses?.filter(
    response => response.status === COMPLETED_TEXT
  );

  const route = useSelector(
    (state: ApplicationState) => state.router.location.pathname
  );

  if (!assessmentId) {
    return;
  }

  const questionsTabClasses = classNames({
    'assessmentSummary--tabs--tab': true,
    selected: route === `/r/riskManagement/assessments/summary/${assessmentId}`,
  });

  const responsesTabClasses = classNames({
    'assessmentSummary--tabs--tab': true,
    selected:
      route ===
      `/r/riskManagement/assessments/summary/${assessmentId}/responses`,
  });

  if (!currentAssessment) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="assessmentSummary">
        <div className="assessmentSummary--summary">
          <div
            className="assessmentSummary--back"
            onClick={() => {
              dispatch(push('/r/riskManagement/assessments/summary'));
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <div className="assessmentSummary--summaryTile">
            <AssessmentSummaryTile
              assessment={currentAssessment}
              actionType={ActionType.summary}
            />
          </div>
        </div>
        <div className="assessmentSummary--tabs">
          <div
            className={questionsTabClasses}
            onClick={() => {
              dispatch(
                push(`/r/riskManagement/assessments/summary/${assessmentId}`)
              );
            }}
          >
            <span className="assessmentSummary--tabs--tab-text">Questions</span>
          </div>
          <div
            className={responsesTabClasses}
            onClick={() => {
              dispatch(
                push(
                  `/r/riskManagement/assessments/summary/${assessmentId}/responses`
                )
              );
            }}
          >
            <span className="assessmentSummary--tabs--tab-text">Responses</span>
            <span className="assessmentSummary--tabs--tab-lozenge">
              <Lozenge
                value={completedResponses?.length || 0}
                intent={Intent.Success}
              />
            </span>
          </div>
        </div>
        <Switch>
          <Route
            exact
            path="/r/riskManagement/assessments/summary/:id"
            render={() => (
              <AssessmentSummaryQuestions
                sections={currentAssessment.assessmentSections}
              />
            )}
          />
          <Route
            component={AssessmentSummaryResponses}
            exact
            path="/r/riskManagement/assessments/summary/:id/responses"
          />
        </Switch>
      </div>

      <MessageToast
        onClose={() => dispatch(hideAssessmentSentToast())}
        show={isAssessmentSentToastShowing}
        showClose
      >
        The assessment has been sent successfully.
      </MessageToast>
      <SendAssessmentConfirmationModal />
      {isShowingSendAssessmentModal && <SendAssessmentModal />}
    </>
  );
};
