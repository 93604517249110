import {
  VendorDocument,
  VendorDocumentAttestation,
  VendorDocumentExtended,
} from '../swagger';
import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { dateSort } from './dateSort';
import { sort } from './sort';

export const vendorDocumentSort = (
  a: VendorDocument | VendorDocumentExtended,
  b: VendorDocument | VendorDocumentExtended,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn:
    | keyof VendorDocument
    | keyof VendorDocumentExtended
    | keyof VendorDocumentAttestation = columnSort.name as
    | keyof VendorDocument
    | keyof VendorDocumentAttestation;

  switch (typedColumn) {
    case 'attestationDueAt':
      return dateSort(a.attestationDueAt, b.attestationDueAt, direction);
    case 'lastAttestedAt':
      return dateSort(
        a.vendorDocumentAttestation.lastAttestedAt,
        b.vendorDocumentAttestation.lastAttestedAt,
        direction
      );
    case 'name':
      return sort(a.name.toLowerCase(), b.name.toLowerCase(), direction);
    case 'version':
      return sort(a.version, b.version, direction);
    case 'documentType':
      return sort(a.documentType, b.documentType, direction);
    case 'updatedAt':
      return dateSort(a.updatedAt, b.updatedAt, direction);
  }
};
