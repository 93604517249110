import { ChecklistItemsGroups } from '../swagger';
import { calculateChecklistProgress } from './calculateChecklistProgress';

export const getChecklistProgress = (
  itemsGroups: ChecklistItemsGroups[]
): string => {
  if (itemsGroups && itemsGroups.length) {
    return `${calculateChecklistProgress(itemsGroups).toFixed(0)}%`;
  }
};
