import { Question } from '../swagger';
import { QuestionType } from '../types/QuestionType';
import { CommonAnswerType } from '../types/CommonAnswerType';

const getTableQuestionsAndAnswers = ({
  question,
  questions,
  answers,
  indexProperty,
}: {
  question: Question;
  questions: Question[];
  answers: CommonAnswerType[];
  indexProperty: 'name' | 'propertyName';
}): QuestionType[] => {
  return questions
    .filter(q =>
      question.questionColumns.some(
        column => column.columnValue === q[indexProperty]
      )
    )
    .map((tableQuestion: Question) => ({
      ...tableQuestion,
      answers: answers.filter(
        answer => answer[indexProperty] === tableQuestion[indexProperty]
      ),
    }));
};

export default getTableQuestionsAndAnswers;
