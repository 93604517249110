import './AuditsPage.scss';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from '../../../functions/capitalize';
import { formatShortDate } from '../../../functions/formatShortDate';
import { selectAudits } from '../../../selectors/selectAudits';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import {
  cleanupAudits,
  setAuditIdToBeDeleted,
  toggleCompletedFilter,
} from './store/auditsSlice';
import { deleteAudit, fetchAuditPDF, fetchAudits } from './store/auditsThunks';
import { getVendorProducts } from '../../../store/vendors/vendorsThunks';
import { ApplicationState } from '../../../types/applicationState';
import { Grid } from '../../../types/grid';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import AdoptechGridTable from '../../../components/AdoptechGridTable/AdoptechGridTable';
import AdoptechOverflowLine from '../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { GenericModal } from '../../../components/GenericModal/GenericModal';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Lozenge } from '../../../components/Lozenge/Lozenge';
import { NoDataText } from '../../../components/NoDataText/NoDataText';
import { SortableTableHeader } from '../../../components/SortableTableHeader/SortableTableHeader';
import { ToggleSwitch } from '../../../components/ToggleSwitch/ToggleSwitch';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import { AuditsMeatballMenu } from './AuditsMeatballMenu';
import { auditStartRoute } from '../../../components/Routes/Routes';
import { useAuditPreviewModal } from './PreviewMode/PreviewMode';
import { AuditModel, AuditModelStatusEnum } from '../../../swagger';
import { useCanFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { fetchComplianceControls } from '../../../store/compliance/complianceThunks';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { AdoptechDropdownToggle } from '../../../components/AdoptechDropdownToggle/AdoptechDropdownToggle';
import { AuditsUploadDrawer } from './AuditsUploadDrawer';
import { MeetingsUploadDrawer } from './MeetingsUploadDrawer/MeetingsUploadDrawer';

export const auditRating = (rating: string) => {
  const ratingString = rating ?? 'n/a';
  if (ratingString === 'n/a') return ratingString;
  return capitalize(ratingString?.replace('_', ' '));
};

const AuditsList: React.FC<{
  items?: AuditModel[];
  onClick(audit: AuditModel): void;
}> = ({ items, onClick }) => {
  if (items.length < 1) return <NoDataText text="No audits/meetings to show" />;
  const baseCss = 'auditsPage';

  return (
    <div className={baseCss + '--6-columns'}>
      <div className="hidden add-new-toggle" />
      <AdoptechGridTable
        header={
          <div className="adoptechGridTable--header">
            <SortableTableHeader<AuditModel>
              columnName="name"
              grid={Grid.Audits}
              label="Audit/Meeting name"
              notInTable
            />
            <SortableTableHeader<AuditModel>
              columnName="owner"
              grid={Grid.Audits}
              label="Owner"
              notInTable
            />
            <SortableTableHeader<AuditModel>
              columnName="startedAt"
              grid={Grid.Audits}
              label="Audit/Meeting date"
              notInTable
            />
            <SortableTableHeader<AuditModel>
              columnName="rating"
              grid={Grid.Audits}
              label="Rating"
              notInTable
            />
            <SortableTableHeader<AuditModel>
              columnName="status"
              grid={Grid.Audits}
              label="Status"
              notInTable
            />
          </div>
        }
      >
        {items.map(audit => (
          <div
            key={audit.id}
            className="adoptechGridTable--row"
            onClick={() => onClick(audit)}
          >
            <div>{audit.name}</div>
            <AdoptechOverflowLine>
              <UserAvatar size="small" user={audit.owner} />
            </AdoptechOverflowLine>
            <div>{formatShortDate(audit.startedAt)}</div>
            <div>{auditRating(audit.rating)}</div>
            <div>{capitalize(audit.status.replace('_', ' '))}</div>
            <div onClick={e => e.stopPropagation()}>
              <AuditsMeatballMenu audit={audit} onClick={onClick} />
            </div>
          </div>
        ))}
      </AdoptechGridTable>
    </div>
  );
};

export const auditType = (audit: AuditModel) => {
  return audit?.type === 'Audit::Meeting' ? 'meeting' : 'audit';
};

export const AuditsPage: React.FC = () => {
  const baseCss = 'auditsPage';

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );
  const vendorUser = useSelector(selectCurrentVendorUser);

  const filteredAudits = useSelector(selectAudits);
  const auditsState = useSelector((state: ApplicationState) => state.audits);
  const [showUploadDrawer, setShowUploadDrawer] = useState(false);
  const [showMeetingsUploadDrawer, setShowMeetingsUploadDrawer] =
    useState(false);
  const {
    completedFilter,
    isFetchingAudits,
    isDeletingAudit,
    auditIdToDelete,
    audits, // select all audits from redux, no matter what filter value is
  } = auditsState;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchComplianceControls({ vendorId }));
    dispatch(getVendorProducts(true));
    dispatch(fetchAudits(vendorId));
  }, [vendorId, vendorUser.roles]);

  useAuditPreviewModal();
  useEffect(() => {
    return () => {
      dispatch(cleanupAudits());
    };
  }, []);
  const canManageAudits = useCanFeature(AccessObject.audits_manage);

  if (isFetchingAudits || isDeletingAudit) {
    return <LoadingSpinner />;
  }

  const handleRowClick = (audit: AuditModel) => {
    if (audit.status === AuditModelStatusEnum.Completed) {
      dispatch(fetchAuditPDF(audit));
      return;
    }
    dispatch(push(`/r/compliance/audits/${audit.id}`));
  };

  const auditToRemove = filteredAudits.find(p => p.id === auditIdToDelete);
  return (
    <div className={baseCss}>
      <div className={baseCss + '--header'}>
        <div className={baseCss + '--title'}>
          Audits and Management Reviews
          <Lozenge value={audits.length} />
        </div>

        <div className={baseCss + '--filter'}>
          <ToggleSwitch
            checked={completedFilter}
            onChange={() => dispatch(toggleCompletedFilter())}
            handlerStyle="small"
          />
          <span>Show completed audits/meetings</span>
        </div>
        {canManageAudits && (
          <div className={baseCss + '--headerButton'}>
            <Dropdown className={baseCss + '--addButton'}>
              <Dropdown.Toggle as={AdoptechDropdownToggle}>
                <AdoptechButton
                  variant={AdoptechButtonVariant.PrimaryOrange}
                  icon={faPlus}
                  iconSize="large"
                  rounded
                >
                  Add new
                </AdoptechButton>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => dispatch(push(auditStartRoute))}>
                  Start new
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowUploadDrawer(true)}>
                  Upload audit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setShowMeetingsUploadDrawer(true)}
                >
                  Upload meeting minutes
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
      <AuditsList items={filteredAudits} onClick={handleRowClick} />
      <GenericModal
        closeButtonLabel="CONFIRM"
        onCancel={() => dispatch(setAuditIdToBeDeleted(undefined))}
        onClose={() => {
          dispatch(deleteAudit(auditIdToDelete));
        }}
        show={!!auditIdToDelete}
        showCancelButton
        title="Confirm delete?"
      >
        <br />
        <h3>{auditToRemove?.name}</h3>
        <br />
        <div>
          Click CONFIRM to permanently delete this {auditType(auditToRemove)}
        </div>
      </GenericModal>
      <AuditsUploadDrawer
        show={showUploadDrawer}
        onClose={() => {
          setShowUploadDrawer(false);
        }}
      />
      {showMeetingsUploadDrawer && (
        <MeetingsUploadDrawer
          onClose={() => {
            setShowMeetingsUploadDrawer(false);
          }}
        />
      )}
    </div>
  );
};
