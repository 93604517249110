import { SelectionOption } from '../types/selectionOption';

export const getSelectionValue = (
  values: SelectionOption[] | SelectionOption
): string | undefined => {
  if (!values) {
    return undefined;
  }
  if (Array.isArray(values)) {
    return values.map((r: { value: string }) => r.value).join(',');
  } else {
    return values.value;
  }
};
