/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ControlCategoryTemplateExtended,
  ControlCategoryTemplateExtendedFromJSON,
  ControlCategoryTemplateExtendedFromJSONTyped,
  ControlCategoryTemplateExtendedToJSON,
  FrameworkTemplateExtendedAllOf,
  FrameworkTemplateExtendedAllOfFromJSON,
  FrameworkTemplateExtendedAllOfFromJSONTyped,
  FrameworkTemplateExtendedAllOfToJSON,
  FrameworkTemplateModel,
  FrameworkTemplateModelFromJSON,
  FrameworkTemplateModelFromJSONTyped,
  FrameworkTemplateModelToJSON,
} from './';

/**
 *
 * @export
 * @interface FrameworkTemplateExtended
 */
export interface FrameworkTemplateExtended extends FrameworkTemplateModel {
  /**
   *
   * @type {Array<ControlCategoryTemplateExtended>}
   * @memberof FrameworkTemplateExtended
   */
  controlCategoryTemplates: Array<ControlCategoryTemplateExtended>;
}

export function FrameworkTemplateExtendedFromJSON(
  json: any
): FrameworkTemplateExtended {
  return FrameworkTemplateExtendedFromJSONTyped(json, false);
}

export function FrameworkTemplateExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FrameworkTemplateExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...FrameworkTemplateModelFromJSONTyped(json, ignoreDiscriminator),
    controlCategoryTemplates: (
      json['control_category_templates'] as Array<any>
    ).map(ControlCategoryTemplateExtendedFromJSON),
  };
}

export function FrameworkTemplateExtendedToJSON(
  value?: FrameworkTemplateExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...FrameworkTemplateModelToJSON(value),
    control_category_templates: (
      value.controlCategoryTemplates as Array<any>
    ).map(ControlCategoryTemplateExtendedToJSON),
  };
}
