import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { formatShortDate } from '../../functions/formatShortDate';
import {
  ChecklistExtended,
  ChecklistTemplateExtended,
  ShareableType,
} from '../../swagger/models';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { Panel } from '../Panel/Panel';
import './ChecklistSummaryTile.scss';
import { showShareModal } from '../../store/vendors/vendorsSlice';
import {
  hasFeature,
  mapShareFeaturesToAccessLevels,
} from '../../functions/access';
import { AccessObject } from '../../types/accessObject';

interface ChecklistSummaryTileProps {
  checklist: ChecklistExtended | ChecklistTemplateExtended;
  isTemplate?: boolean;
}

export const ChecklistSummaryTile: React.FC<ChecklistSummaryTileProps> = ({
  checklist,
  isTemplate,
}) => {
  const dispatch = useDispatch();

  if (!checklist) {
    return null;
  }

  const handleChecklistShare = () => {
    dispatch(
      showShareModal({
        shareableId: checklist.id,
        shareableType: ShareableType.Checklist,
        accessLevels: mapShareFeaturesToAccessLevels(checklist.access.feature),
        relativePath: (checklist as ChecklistExtended).relativePath,
      })
    );
  };

  return (
    <Panel>
      <div className="checklistSummaryTile">
        <div className="checklistSummaryTile--progressBar" />
        <div className="checklistSummaryTile--mainContent">
          <div className="checklistSummaryTile--info">
            <div className="checklistSummaryTile--column">
              <div className="checklistSummaryTile--columnHeader">
                Checklist
              </div>
              <div className="checklistSummaryTile--columnData">
                {checklist.name}
              </div>
            </div>

            <div className="checklistSummaryTile--column">
              <div className="checklistSummaryTile--columnHeader">
                Last updated at
              </div>
              <div className="checklistSummaryTile--columnData">
                {formatShortDate(checklist.updatedAt)}
              </div>
            </div>
            <div className="checklistSummaryTile--column checklistSummaryTile--column-grow">
              <div className="checklistSummaryTile--column-verticallyCentered">
                {!isTemplate && (
                  <AdoptechButton
                    onClick={handleChecklistShare}
                    disabled={
                      !hasFeature(AccessObject.share, checklist?.access)
                    }
                  >
                    <FontAwesomeIcon
                      className="checklistSummaryTile--completeIcon"
                      icon={faUserPlus}
                    />
                    Share
                  </AdoptechButton>
                )}
                <AdoptechButton
                  onClick={() =>
                    dispatch(push('/r/companyInformation/checklists'))
                  }
                  variant={AdoptechButtonVariant.Primary}
                >
                  CLOSE
                </AdoptechButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
};
