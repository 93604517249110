import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import './AdoptechCheckbox.scss';

interface AdoptechCheckboxProps {
  checked: boolean;
  className?: string;
  id: string;
  label?: JSX.Element | string;
  onChange?: (e?: React.SyntheticEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  color?: 'white' | 'white-black';
  style?: CSSProperties;
  labelPadding?: 'small';
  size?: 'small';
}

export const AdoptechCheckbox: React.FC<AdoptechCheckboxProps> = props => {
  const classes = classNames(
    {
      adoptechCheckbox: true,
      'adoptechCheckbox--disabled': props.disabled,
      'adoptechCheckbox--white-background': props.color === 'white',
      'adoptechCheckbox--white-black-background': props.color === 'white-black',
      'adoptechCheckbox--small-label-padding': props.labelPadding === 'small',
      'adoptechCheckbox--small': props.size === 'small',
    },
    props.className
  );

  return (
    <div className={classes}>
      <input
        type="checkbox"
        id={props.id}
        onChange={props.onChange}
        checked={props.checked}
        disabled={props.disabled}
        style={props.style}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  );
};

AdoptechCheckbox.defaultProps = {
  label: '',
};
