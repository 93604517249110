import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canCreate } from '../../functions/access';
import { getDefaultSelectionOptions } from '../../functions/getDefaultSelectionOptions';
import { getSelectionValue } from '../../functions/getSelectionValue';
import {
  showAddCompanyOfficeAddressModal,
  showAddTeamModal,
  showAddUserModal,
} from '../../store/vendors/vendorsSlice';
import {
  fetchTable,
  fetchVendorTable,
} from '../../store/vendors/vendorsThunks';
import {
  Question,
  QuestionLayoutEnum,
  QuestionObjectTypeEnum,
  QuestionTableTypeEnum,
} from '../../swagger';
import { AccessObject } from '../../types/accessObject';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import { AdoptechCountrySelect } from '../AdoptechCountrySelect/AdoptechCountrySelect';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { GenericModal } from '../GenericModal/GenericModal';
import './SelectAnswer.scss';
import { TextBasedAnswer } from '../TextBasedAnswer/TextBasedAnswer';
import { WithCountries } from '../WithCountries/WithCountries';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { CommonAnswerType } from '../../types/CommonAnswerType';
import { policyAnswerCommonFields } from '../../functions/policyAnswerCommonFields';

interface SelectAnswerProps {
  previousAnswer?: CommonAnswerType;
  disabled?: boolean;
  readonly?: boolean;
  onChange: (value: CommonAnswerType[]) => void;
  question: Question;
  indexProperty: 'name' | 'propertyName';
  vendorId?: string;
  asTextReadonly?: boolean;
}

export const SelectAnswer: React.FC<SelectAnswerProps> = props => {
  let canCreateOffice = false;
  let canCreateTeam = false;
  let canCreateUser = false;

  // TODO: Hook up when permission exists
  canCreateOffice = true;
  canCreateTeam = canCreate(AccessObject.vendor_teams);
  canCreateUser = canCreate(AccessObject.vendor_users);
  const currentVendor = useSelector(selectCurrentVendor);

  const [
    isShowingInsufficientPermissionModal,
    setIsShowingInsufficientPermissionModal,
  ] = useState(false);
  const [objectType, setObjectType] = useState('');
  const [selected, setSelected] = useState<
    SelectionOption | SelectionOption[]
  >();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      props.question.objectType &&
      !props.readonly &&
      currentVendor.access.tables
    ) {
      dispatch(
        fetchVendorTable({
          type: props.question.objectType,
          vendorId: props.vendorId,
        })
      );
    }
    if (props.question.tableType && !isCountrySelect()) {
      dispatch(fetchTable(props.question.tableType));
    }
  }, []);

  const isFetchingVendorTable = useSelector(
    (state: ApplicationState) => state.vendors.isFetchingVendorTable
  );

  const options = useSelector((state: ApplicationState) => {
    if (props.question.objectType) {
      const opts = getDefaultSelectionOptions(
        props.question.objectType || props.question.tableType,
        state
      );
      return opts;
    }
    return (props.question.questionRows || []).map(row => {
      return { value: row.rowText, label: row.propertyName.split('.').pop() };
    });
  });

  useEffect(() => {
    if (!props.previousAnswer) {
      return;
    }

    const selectedOptions = options.filter(
      o => props.previousAnswer.value?.split(',').indexOf(o.value) > -1
    );

    setSelected(selectedOptions);
  }, [props.previousAnswer?.value, options?.length]);

  const handleChange = (values: SelectionOption[] | SelectionOption) => {
    setSelected(values);
    props.onChange([
      {
        [props.indexProperty]: props.question[props.indexProperty],
        value: getSelectionValue(values),
        ...policyAnswerCommonFields(props),
      },
    ]);
  };

  const isMultiSelect = () =>
    props.question.layout === QuestionLayoutEnum.MultiSelect;

  const isCountrySelect = () =>
    props.question.tableType === QuestionTableTypeEnum.Country;

  const add = () => {
    switch (props.question.objectType) {
      case QuestionObjectTypeEnum.Office:
        if (!canCreateOffice) {
          setObjectType('Office');
          setIsShowingInsufficientPermissionModal(true);
          return;
        }
        dispatch(showAddCompanyOfficeAddressModal(true));
        break;
      case QuestionObjectTypeEnum.VendorTeam:
        if (!canCreateTeam) {
          setObjectType('Team');
          setIsShowingInsufficientPermissionModal(true);
          return;
        }
        dispatch(showAddTeamModal(true));
        break;
      case QuestionObjectTypeEnum.VendorUser:
        if (!canCreateUser) {
          setObjectType('User');
          setIsShowingInsufficientPermissionModal(true);
          return;
        }
        dispatch(showAddUserModal(true));
        break;
    }
  };

  if (props.asTextReadonly) {
    return (
      <WithCountries>
        {countryOptions => {
          const previousAnswer = {
            value: Array.isArray(selected)
              ? selected.length > 0
                ? selected[0].label
                : undefined
              : selected?.label,
          };
          if (isCountrySelect() && countryOptions?.length) {
            const countryName = countryOptions.find(
              opt => previousAnswer.value === opt.value
            );
            if (countryName) {
              previousAnswer.value = countryName.label;
            }
          }

          return <TextBasedAnswer {...props} previousAnswer={previousAnswer} />;
        }}
      </WithCountries>
    );
  }

  return (
    <>
      <div className="selectAnswer">
        {isCountrySelect() && (
          <AdoptechCountrySelect
            value={getSelectionValue(selected)}
            onChange={handleChange}
            placeholder="Please choose ..."
            isMultiSelect={isMultiSelect()}
            disabled={props.disabled || props.readonly}
          />
        )}
        {!isCountrySelect() && (
          <AdoptechReactSelect
            allowAdd={
              props.question.objectType && !props.disabled && !props.readonly
            }
            id="selectAnswer"
            isDisabled={props.disabled || props.readonly}
            isMulti={isMultiSelect()}
            options={options}
            onChange={handleChange}
            onAdd={add}
            value={selected}
            isLoading={isFetchingVendorTable[props.question.objectType]}
          />
        )}
      </div>

      <GenericModal
        onClose={() => setIsShowingInsufficientPermissionModal(false)}
        show={isShowingInsufficientPermissionModal}
        title="Insufficient permission"
      >
        You do not have permission to add a new {objectType}, please contact
        your Administrator.
      </GenericModal>
    </>
  );
};
