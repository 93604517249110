import {
  AgreementExtended,
  Answer,
  AnswerFromJSON,
  AuditExtended,
} from '../swagger';

// Looks like a swagger bug ? We convert tables answers manually from underscore keys to capitalize keys from API

export const mapTableAnswers = <T extends AgreementExtended | AuditExtended>(
  model: T
): T => {
  const copiedModel: T = JSON.parse(JSON.stringify(model));
  copiedModel.survey.surveyClauses.forEach(clause =>
    clause.questions.forEach(question => {
      if (question.table) {
        const answers = question.table.answers
          ? (question.table.answers as Answer[][]).map(answersRow =>
              answersRow.map(answer => AnswerFromJSON(answer))
            )
          : undefined;

        if (answers.length) {
          question.table.answers = answers;
        }
      }
    })
  );
  return copiedModel;
};
