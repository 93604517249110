/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  AssessmentResponseAnswer,
  AssessmentResponseAnswerFromJSON,
  AssessmentResponseAnswerFromJSONTyped,
  AssessmentResponseAnswerToJSON,
  AssessmentSection,
  AssessmentSectionFromJSON,
  AssessmentSectionFromJSONTyped,
  AssessmentSectionToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface AssessmentResponse
 */
export interface AssessmentResponse {
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  relativePath?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  assessmentId?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  vendorId?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  vendorName?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  requestingVendorName?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  dueDate?: string | null;
  /**
   *
   * @type {Access}
   * @memberof AssessmentResponse
   */
  access?: Access;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  updatedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  createdAt?: string;
  /**
   *
   * @type {Array<AssessmentSection>}
   * @memberof AssessmentResponse
   */
  assessmentSections?: Array<AssessmentSection>;
  /**
   *
   * @type {Array<AssessmentResponseAnswer>}
   * @memberof AssessmentResponse
   */
  assessmentResponseAnswers?: Array<AssessmentResponseAnswer>;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponse
   */
  ownerId?: string | null;
  /**
   *
   * @type {VendorUser}
   * @memberof AssessmentResponse
   */
  owner?: VendorUser;
}

export function AssessmentResponseFromJSON(json: any): AssessmentResponse {
  return AssessmentResponseFromJSONTyped(json, false);
}

export function AssessmentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AssessmentResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    relativePath: !exists(json, 'relative_path')
      ? undefined
      : json['relative_path'],
    assessmentId: !exists(json, 'assessment_id')
      ? undefined
      : json['assessment_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    vendorId: !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
    vendorName: !exists(json, 'vendor_name') ? undefined : json['vendor_name'],
    requestingVendorName: !exists(json, 'requesting_vendor_name')
      ? undefined
      : json['requesting_vendor_name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    dueDate: !exists(json, 'due_date') ? undefined : json['due_date'],
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    assessmentSections: !exists(json, 'assessment_sections')
      ? undefined
      : (json['assessment_sections'] as Array<any>).map(
          AssessmentSectionFromJSON
        ),
    assessmentResponseAnswers: !exists(json, 'assessment_response_answers')
      ? undefined
      : (json['assessment_response_answers'] as Array<any>).map(
          AssessmentResponseAnswerFromJSON
        ),
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
  };
}

export function AssessmentResponseToJSON(
  value?: AssessmentResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    relative_path: value.relativePath,
    assessment_id: value.assessmentId,
    name: value.name,
    vendor_id: value.vendorId,
    vendor_name: value.vendorName,
    requesting_vendor_name: value.requestingVendorName,
    status: value.status,
    due_date: value.dueDate,
    access: AccessToJSON(value.access),
    updated_at: value.updatedAt,
    created_at: value.createdAt,
    assessment_sections:
      value.assessmentSections === undefined
        ? undefined
        : (value.assessmentSections as Array<any>).map(AssessmentSectionToJSON),
    assessment_response_answers:
      value.assessmentResponseAnswers === undefined
        ? undefined
        : (value.assessmentResponseAnswers as Array<any>).map(
            AssessmentResponseAnswerToJSON
          ),
    owner_id: value.ownerId,
    owner: VendorUserToJSON(value.owner),
  };
}
