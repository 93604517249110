/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AgreementSignatoryRejectPayload
 */
export interface AgreementSignatoryRejectPayload {
  /**
   *
   * @type {string}
   * @memberof AgreementSignatoryRejectPayload
   */
  rejectionReason?: string | null;
}

export function AgreementSignatoryRejectPayloadFromJSON(
  json: any
): AgreementSignatoryRejectPayload {
  return AgreementSignatoryRejectPayloadFromJSONTyped(json, false);
}

export function AgreementSignatoryRejectPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementSignatoryRejectPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    rejectionReason: !exists(json, 'rejection_reason')
      ? undefined
      : json['rejection_reason'],
  };
}

export function AgreementSignatoryRejectPayloadToJSON(
  value?: AgreementSignatoryRejectPayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    rejection_reason: value.rejectionReason,
  };
}
