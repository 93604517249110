/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  LegalRegisterModelStats,
  LegalRegisterModelStatsFromJSON,
  LegalRegisterModelStatsFromJSONTyped,
  LegalRegisterModelStatsToJSON,
  ReviewFrequencyEnum,
  ReviewFrequencyEnumFromJSON,
  ReviewFrequencyEnumFromJSONTyped,
  ReviewFrequencyEnumToJSON,
  ReviewStatusEnum,
  ReviewStatusEnumFromJSON,
  ReviewStatusEnumFromJSONTyped,
  ReviewStatusEnumToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface LegalRegisterModel
 */
export interface LegalRegisterModel {
  /**
   *
   * @type {string}
   * @memberof LegalRegisterModel
   */
  id: string;
  /**
   *
   * @type {VendorUser}
   * @memberof LegalRegisterModel
   */
  owner?: VendorUser;
  /**
   *
   * @type {ReviewFrequencyEnum}
   * @memberof LegalRegisterModel
   */
  reviewFrequency?: ReviewFrequencyEnum;
  /**
   *
   * @type {ReviewStatusEnum}
   * @memberof LegalRegisterModel
   */
  reviewStatus?: ReviewStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof LegalRegisterModel
   */
  reviewOverdue?: boolean;
  /**
   *
   * @type {string}
   * @memberof LegalRegisterModel
   */
  nextReviewDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof LegalRegisterModel
   */
  lastReviewedAt?: string | null;
  /**
   *
   * @type {LegalRegisterModelStats}
   * @memberof LegalRegisterModel
   */
  stats?: LegalRegisterModelStats;
}

export function LegalRegisterModelFromJSON(json: any): LegalRegisterModel {
  return LegalRegisterModelFromJSONTyped(json, false);
}

export function LegalRegisterModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LegalRegisterModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    reviewFrequency: !exists(json, 'review_frequency')
      ? undefined
      : ReviewFrequencyEnumFromJSON(json['review_frequency']),
    reviewStatus: !exists(json, 'review_status')
      ? undefined
      : ReviewStatusEnumFromJSON(json['review_status']),
    reviewOverdue: !exists(json, 'review_overdue')
      ? undefined
      : json['review_overdue'],
    nextReviewDate: !exists(json, 'next_review_date')
      ? undefined
      : json['next_review_date'],
    lastReviewedAt: !exists(json, 'last_reviewed_at')
      ? undefined
      : json['last_reviewed_at'],
    stats: !exists(json, 'stats')
      ? undefined
      : LegalRegisterModelStatsFromJSON(json['stats']),
  };
}

export function LegalRegisterModelToJSON(
  value?: LegalRegisterModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    owner: VendorUserToJSON(value.owner),
    review_frequency: ReviewFrequencyEnumToJSON(value.reviewFrequency),
    review_status: ReviewStatusEnumToJSON(value.reviewStatus),
    review_overdue: value.reviewOverdue,
    next_review_date: value.nextReviewDate,
    last_reviewed_at: value.lastReviewedAt,
    stats: LegalRegisterModelStatsToJSON(value.stats),
  };
}
