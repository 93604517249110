import classNames from 'classnames';
import React from 'react';
import { Table } from 'react-bootstrap';
import './AdoptechTable.scss';

interface AdoptechTableProps {
  embedded?: boolean;
  hasMenu?: boolean;
  modal?: boolean;
  className?: string;
}

const AdoptechTable: React.FC<AdoptechTableProps> = props => {
  const classes = classNames(
    {
      adoptechTable: true,
      'adoptechTable-embedded': props.embedded,
      'adoptechTable-hasMenu': props.hasMenu,
      'adoptechTable-modal': props.modal,
    },
    props.className
  );

  return (
    <div className={classes}>
      <Table responsive className="adoptechTable--wrapper">
        <>{props.children}</>
      </Table>
    </div>
  );
};

export default AdoptechTable;
