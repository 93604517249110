/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AgreementTemplateCategoryModel
 */
export interface AgreementTemplateCategoryModel {
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateCategoryModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateCategoryModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateCategoryModel
   */
  identifier: string;
  /**
   *
   * @type {number}
   * @memberof AgreementTemplateCategoryModel
   */
  rowOrder: number;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateCategoryModel
   */
  summary: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementTemplateCategoryModel
   */
  summaryAsHtml: string | null;
}

export function AgreementTemplateCategoryModelFromJSON(
  json: any
): AgreementTemplateCategoryModel {
  return AgreementTemplateCategoryModelFromJSONTyped(json, false);
}

export function AgreementTemplateCategoryModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementTemplateCategoryModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    identifier: json['identifier'],
    rowOrder: json['row_order'],
    summary: json['summary'],
    summaryAsHtml: json['summary_as_html'],
  };
}

export function AgreementTemplateCategoryModelToJSON(
  value?: AgreementTemplateCategoryModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    identifier: value.identifier,
    row_order: value.rowOrder,
    summary: value.summary,
    summary_as_html: value.summaryAsHtml,
  };
}
