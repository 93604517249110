import { VendorDocumentApprovalStatusEnum } from '../swagger';

export const approvalStatusSort = (
  a: VendorDocumentApprovalStatusEnum,
  b: VendorDocumentApprovalStatusEnum,
  direction: number
): number => {
  if (a === VendorDocumentApprovalStatusEnum.Requested) {
    return direction;
  } else {
    return direction * -1;
  }
};
