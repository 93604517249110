/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  ChecklistTemplate,
  ChecklistTemplateFromJSON,
  ChecklistTemplateFromJSONTyped,
  ChecklistTemplateToJSON,
  ChecklistTemplateExtendedAllOf,
  ChecklistTemplateExtendedAllOfFromJSON,
  ChecklistTemplateExtendedAllOfFromJSONTyped,
  ChecklistTemplateExtendedAllOfToJSON,
  ChecklistTemplateExtendedAllOfItemsGroups,
  ChecklistTemplateExtendedAllOfItemsGroupsFromJSON,
  ChecklistTemplateExtendedAllOfItemsGroupsFromJSONTyped,
  ChecklistTemplateExtendedAllOfItemsGroupsToJSON,
  Survey,
  SurveyFromJSON,
  SurveyFromJSONTyped,
  SurveyToJSON,
} from './';

/**
 *
 * @export
 * @interface ChecklistTemplateExtended
 */
export interface ChecklistTemplateExtended extends ChecklistTemplate {
  /**
   *
   * @type {Array<ChecklistTemplateExtendedAllOfItemsGroups>}
   * @memberof ChecklistTemplateExtended
   */
  itemsGroups: Array<ChecklistTemplateExtendedAllOfItemsGroups>;
}

export function ChecklistTemplateExtendedFromJSON(
  json: any
): ChecklistTemplateExtended {
  return ChecklistTemplateExtendedFromJSONTyped(json, false);
}

export function ChecklistTemplateExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistTemplateExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...ChecklistTemplateFromJSONTyped(json, ignoreDiscriminator),
    itemsGroups: (json['items_groups'] as Array<any>).map(
      ChecklistTemplateExtendedAllOfItemsGroupsFromJSON
    ),
  };
}

export function ChecklistTemplateExtendedToJSON(
  value?: ChecklistTemplateExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...ChecklistTemplateToJSON(value),
    items_groups: (value.itemsGroups as Array<any>).map(
      ChecklistTemplateExtendedAllOfItemsGroupsToJSON
    ),
  };
}
