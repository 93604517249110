/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  DashboardSummaryData,
  DashboardSummaryDataFromJSON,
  DashboardSummaryDataFromJSONTyped,
  DashboardSummaryDataToJSON,
} from './';

/**
 *
 * @export
 * @interface DashboardSummaryWidgets
 */
export interface DashboardSummaryWidgets {
  /**
   *
   * @type {string}
   * @memberof DashboardSummaryWidgets
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof DashboardSummaryWidgets
   */
  widgetId?: DashboardSummaryWidgetsWidgetIdEnum;
  /**
   *
   * @type {Array<DashboardSummaryData>}
   * @memberof DashboardSummaryWidgets
   */
  data?: Array<DashboardSummaryData>;
}

export function DashboardSummaryWidgetsFromJSON(
  json: any
): DashboardSummaryWidgets {
  return DashboardSummaryWidgetsFromJSONTyped(json, false);
}

export function DashboardSummaryWidgetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DashboardSummaryWidgets {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: !exists(json, 'title') ? undefined : json['title'],
    widgetId: !exists(json, 'widget_id') ? undefined : json['widget_id'],
    data: !exists(json, 'data')
      ? undefined
      : (json['data'] as Array<any>).map(DashboardSummaryDataFromJSON),
  };
}

export function DashboardSummaryWidgetsToJSON(
  value?: DashboardSummaryWidgets | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    widget_id: value.widgetId,
    data:
      value.data === undefined
        ? undefined
        : (value.data as Array<any>).map(DashboardSummaryDataToJSON),
  };
}

/**
 * @export
 * @enum {string}
 */
export enum DashboardSummaryWidgetsWidgetIdEnum {
  PoliciesSummary = 'vendor_policies_summary',
  MyPoliciesSummary = 'vendor_my_policies_summary',
  AgreementsSummary = 'vendor_agreements_summary',
  MyAgreementsSummary = 'vendor_my_agreements_summary',
  AssessmentsSummary = 'vendor_assessments_summary',
  ControlsSummary = 'vendor_controls_summary',
  MyControlsSummary = 'vendor_my_controls_summary',
  TasksSummary = 'vendor_tasks_summary',
  MyTasksSummary = 'vendor_my_tasks_summary',
  UsersSummary = 'vendor_users_summary',
}
