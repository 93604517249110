import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import Accordion from 'react-bootstrap/esm/Accordion';
import { useSelector } from 'react-redux';
import { showWidgetChatArticle } from '../../functions/chatWidgetHelper';
import { PestelItemModel } from '../../swagger/models/PestelItemModel';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { AdoptechReactSelect2 } from '../AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechTextArea2 } from '../AdoptechTextArea2/AdoptechTextArea2';
import { getPestelRiskInfo } from '../pestel/PestelSection/PestelRow';
import { VendorRegisterRiskProfile } from '../VendorRegisterRiskProfile/VendorRegisterRiskProfile';
import { PestelItemForm } from './ManagePestelItemsDrawer';
import { SectionProps } from './OverviewSection';
import { capitalize } from '../../functions/capitalize';
import { PestelItemItemType } from '../../swagger';

export const validation = (
  formData: PestelItemForm,
  pestelItems: PestelItemModel[]
): Record<string, string[]> => {
  const isMitigationPresent = formData.mitigationFactors
    ? null
    : ['Mitigation factors must be set'];
  return {
    mitigationFactors:
      formData.itemType === PestelItemItemType.Opportunity
        ? null
        : isMitigationPresent,

    consequence: formData.consequence ? null : ['Consequence must be set'],

    likelihood: formData.likelihood ? null : ['Likelihood must be set'],
  };
};

export const selectOptions = (
  enumObject: Record<SelectionOption['label'], SelectionOption['value']>
) => {
  return Object.entries(enumObject).map(entry => {
    return { label: entry[0], value: entry[1] };
  });
};

export const AssessmentSection: React.FC<SectionProps> = ({
  formData,
  updateField,
  isTouched,
}) => {
  const baseCss = 'managePestelItemsDrawer';
  const pestelItems = useSelector(
    (state: ApplicationState) => state.pestel.pestelItems
  );
  const fieldErrors = (field: string) =>
    validation(formData, pestelItems)[field];
  const hasError = (field: string) => !!fieldErrors(field);

  const numberOptions = [1, 2, 3, 4, 5].map(number => {
    return {
      label: number as unknown as SelectionOption['label'],
      value: number as unknown as SelectionOption['value'],
    };
  });
  const { itemType: type } = formData;

  return (
    <div className={baseCss}>
      <Accordion defaultActiveKey="0">
        <AdoptechAccordionCard
          title={`${capitalize(type)} Assessment`}
          index="0"
          headerClass="noBorderBottom positionInitial"
          iconSize="small"
          noMargin
        >
          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--field'}>
              <AdoptechTextArea2
                hasError={hasError('mitigationFactors')}
                label="Current mitigation factors"
                id={`${baseCss}mitigation`}
                value={formData.mitigationFactors}
                onChange={e =>
                  updateField('mitigationFactors', e.currentTarget.value)
                }
                icon={faPen}
              />
            </div>
          </div>

          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                label="Likelihood"
                id={`${baseCss}likelihood`}
                options={numberOptions}
                hasError={hasError('likelihood')}
                onChange={e => updateField('likelihood', e.value)}
                value={numberOptions.find(
                  option =>
                    (option.value as unknown as PestelItemModel['likelihood']) ==
                    formData.likelihood
                )}
                placeholder="Please select"
              />
            </div>
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                label={
                  type === PestelItemItemType.Risk ? 'Consequence' : 'Impact'
                }
                id={`${baseCss}consequence`}
                options={numberOptions}
                hasError={hasError('consequence')}
                onChange={e => updateField('consequence', e.value)}
                value={numberOptions.find(
                  option =>
                    (option.value as unknown as PestelItemModel['consequence']) ==
                    formData.consequence
                )}
                placeholder="Please select"
              />
            </div>
            <div
              className={classNames(
                baseCss + '--field',
                baseCss + '--field-risk'
              )}
            >
              <VendorRegisterRiskProfile
                scale={25}
                riskInfo={getPestelRiskInfo({
                  consequence: formData.consequence || 0,
                  likelihood: formData.likelihood || 0,
                })}
              />
              <a
                href="#"
                onClick={() => {
                  showWidgetChatArticle('pestel');
                }}
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </a>
            </div>
          </div>
          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--field'}>
              <AdoptechTextArea2
                label="Additional comments"
                id={`${baseCss}action`}
                value={formData.comment}
                onChange={e => updateField('comment', e.currentTarget.value)}
                icon={faPen}
              />
            </div>
          </div>
        </AdoptechAccordionCard>
      </Accordion>
    </div>
  );
};
