/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubCompanyModel,
  TrusthubCompanyModelFromJSON,
  TrusthubCompanyModelFromJSONTyped,
  TrusthubCompanyModelToJSON,
  TrusthubVendorUserStatus,
  TrusthubVendorUserStatusFromJSON,
  TrusthubVendorUserStatusFromJSONTyped,
  TrusthubVendorUserStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubVendorUserModel
 */
export interface TrusthubVendorUserModel {
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserModel
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserModel
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubVendorUserModel
   */
  fullName?: string | null;
  /**
   *
   * @type {TrusthubVendorUserStatus}
   * @memberof TrusthubVendorUserModel
   */
  status?: TrusthubVendorUserStatus;
  /**
   *
   * @type {TrusthubCompanyModel}
   * @memberof TrusthubVendorUserModel
   */
  trusthubCompany?: TrusthubCompanyModel;
}

export function TrusthubVendorUserModelFromJSON(
  json: any
): TrusthubVendorUserModel {
  return TrusthubVendorUserModelFromJSONTyped(json, false);
}

export function TrusthubVendorUserModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubVendorUserModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    email: !exists(json, 'email') ? undefined : json['email'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    fullName: !exists(json, 'full_name') ? undefined : json['full_name'],
    status: !exists(json, 'status')
      ? undefined
      : TrusthubVendorUserStatusFromJSON(json['status']),
    trusthubCompany: !exists(json, 'trusthub_company')
      ? undefined
      : TrusthubCompanyModelFromJSON(json['trusthub_company']),
  };
}

export function TrusthubVendorUserModelToJSON(
  value?: TrusthubVendorUserModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    email: value.email,
    first_name: value.firstName,
    last_name: value.lastName,
    full_name: value.fullName,
    status: TrusthubVendorUserStatusToJSON(value.status),
    trusthub_company: TrusthubCompanyModelToJSON(value.trusthubCompany),
  };
}
