import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../types/applicationState';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import './ComplianceControlRisksList.scss';
import {
  riskRegistryItemRoute,
  riskRegistryRoute,
} from '../../../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import { ControlRiskModel } from '../../../../../swagger';
import AdoptechOverflowLine from '../../../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';

export const ComplianceControlRisksList: React.FC = () => {
  const {
    editControlFormModel: { risks },
    isFetchingControlDetails,
  } = useSelector((state: ApplicationState) => state.compliance);
  const formRisks = [...(risks || [])].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  const dispatch = useAppDispatch();

  const baseCss = 'complianceControlRisksList';

  const handleRiskClick = (
    e: { stopPropagation: () => void },
    risk: ControlRiskModel
  ) => {
    dispatch(push(riskRegistryItemRoute.replace(':riskRegisterId', risk.id)));
  };
  return (
    <>
      <div className={baseCss + '--header'}>
        <div>Linked risks</div>
      </div>

      {isFetchingControlDetails ? (
        <LoadingSpinner />
      ) : (formRisks || []).length === 0 ? (
        <div className={baseCss + '--noRisks'}>
          No linked risks found{' '}
          <a href={riskRegistryRoute} target="_blank" rel="noreferrer">
            at the risk register{' '}
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
        </div>
      ) : (
        <div className={baseCss + '--risks'}>
          <div className={baseCss + '--tableHeaders'}>
            <div className={baseCss + '--label'}>Name</div>
          </div>

          {formRisks?.map(risk => (
            <div
              className={baseCss + '--tableRow'}
              key={risk.id}
              onClick={e => handleRiskClick(e, risk)}
            >
              <AdoptechOverflowLine>{risk.name}</AdoptechOverflowLine>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
