/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShareAccessLevel,
  ShareAccessLevelFromJSON,
  ShareAccessLevelFromJSONTyped,
  ShareAccessLevelToJSON,
} from './';

/**
 *
 * @export
 * @interface MultipleShareShares
 */
export interface MultipleShareShares {
  /**
   *
   * @type {string}
   * @memberof MultipleShareShares
   */
  email?: string;
  /**
   *
   * @type {ShareAccessLevel}
   * @memberof MultipleShareShares
   */
  accessLevel?: ShareAccessLevel;
}

export function MultipleShareSharesFromJSON(json: any): MultipleShareShares {
  return MultipleShareSharesFromJSONTyped(json, false);
}

export function MultipleShareSharesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MultipleShareShares {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, 'email') ? undefined : json['email'],
    accessLevel: !exists(json, 'access_level')
      ? undefined
      : ShareAccessLevelFromJSON(json['access_level']),
  };
}

export function MultipleShareSharesToJSON(
  value?: MultipleShareShares | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    access_level: ShareAccessLevelToJSON(value.accessLevel),
  };
}
