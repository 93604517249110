import { SelectionOption } from '../../types/selectionOption';
import { ShareableType, ShareAccessLevel } from '../../swagger/models';

export const allPermissionsOptions: SelectionOption[] = [
  {
    value: ShareAccessLevel.Contributor,
    label: 'Contributor',
  },
  {
    value: ShareAccessLevel.Manager,
    label: 'Manager',
  },
  {
    value: ShareAccessLevel.Viewer,
    label: 'Viewer',
  },
];

export const ShareTypesAndTitles = {
  [ShareableType.Agreement]: 'Agreement',
  [ShareableType.Assessment]: 'Assessment',
  [ShareableType.AssessmentResponse]: 'Assessment',
  [ShareableType.Audit]: 'Audit',
  [ShareableType.Checklist]: 'Checklist',
  [ShareableType.ChecklistTemplate]: 'Checklist',
  [ShareableType.VendorDocument]: 'Document',
  [ShareableType.VendorPolicy]: 'Policy',
  [ShareableType.DataRoomEntity]: 'DataRoomEntity',
};
