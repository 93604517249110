/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedTaskRecurring,
  SharedTaskRecurringFromJSON,
  SharedTaskRecurringFromJSONTyped,
  SharedTaskRecurringToJSON,
  VendorSupplierAccessControl,
  VendorSupplierAccessControlFromJSON,
  VendorSupplierAccessControlFromJSONTyped,
  VendorSupplierAccessControlToJSON,
  VendorSupplierStatus,
  VendorSupplierStatusFromJSON,
  VendorSupplierStatusFromJSONTyped,
  VendorSupplierStatusToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorSupplierModel
 */
export interface VendorSupplierModel {
  /**
   *
   * @type {string}
   * @memberof VendorSupplierModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierModel
   */
  name: string;
  /**
   *
   * @type {VendorSupplierStatus}
   * @memberof VendorSupplierModel
   */
  status: VendorSupplierStatus;
  /**
   *
   * @type {VendorUser}
   * @memberof VendorSupplierModel
   */
  owner: VendorUser;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierModel
   */
  reviewDate: string | null;
  /**
   *
   * @type {SharedTaskRecurring}
   * @memberof VendorSupplierModel
   */
  contractRecurring: SharedTaskRecurring;
  /**
   *
   * @type {number}
   * @memberof VendorSupplierModel
   */
  riskProfile: number;
  /**
   *
   * @type {VendorSupplierAccessControl}
   * @memberof VendorSupplierModel
   */
  accessControl: VendorSupplierAccessControl;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierModel
   */
  continuityPlan?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof VendorSupplierModel
   */
  updatedAt: string;
}

export function VendorSupplierModelFromJSON(json: any): VendorSupplierModel {
  return VendorSupplierModelFromJSONTyped(json, false);
}

export function VendorSupplierModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorSupplierModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    status: VendorSupplierStatusFromJSON(json['status']),
    owner: VendorUserFromJSON(json['owner']),
    reviewDate: json['review_date'],
    contractRecurring: SharedTaskRecurringFromJSON(json['contract_recurring']),
    riskProfile: json['risk_profile'],
    accessControl: VendorSupplierAccessControlFromJSON(json['access_control']),
    continuityPlan: !exists(json, 'continuity_plan')
      ? undefined
      : json['continuity_plan'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function VendorSupplierModelToJSON(
  value?: VendorSupplierModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    status: VendorSupplierStatusToJSON(value.status),
    owner: VendorUserToJSON(value.owner),
    review_date: value.reviewDate,
    contract_recurring: SharedTaskRecurringToJSON(value.contractRecurring),
    risk_profile: value.riskProfile,
    access_control: VendorSupplierAccessControlToJSON(value.accessControl),
    continuity_plan: value.continuityPlan,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
