import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import flattenArray from '../../functions/flattenArray';
import { hideEditTableAnswersModal } from '../../store/assessments/assessmentsSlice';
import { patchAssessmentResponse } from '../../store/assessments/assessmentsThunks';
import {
  AssessmentResponseAnswer,
  AssessmentResponseAnswerStatusEnum,
} from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AnswerFormControl } from '../AnswerFormControl/AnswerFormControl';
import './TableAnswerEditModal.scss';
import { QuestionType } from '../../types/QuestionType';
import { CommonAnswerType } from '../../types/CommonAnswerType';

interface TableAnswers {
  [key: string]: string;
}

const transformAnswerToResponse = ({
  name,
  value,
  index,
  datatype,
}: {
  name?: string;
  value?: string;
  index?: number;
  datatype?: string;
}) => {
  return {
    name,
    value,
    index,
    datatype,
    status: AssessmentResponseAnswerStatusEnum.Answered,
  };
};

export const TableAnswerEditModal: React.FC = () => {
  const dispatch = useDispatch();

  const routeParts = useSelector((state: ApplicationState) =>
    state.router.location.pathname.split('/')
  );
  const assessmentResponseId = routeParts[routeParts.length - 1];

  const isShowingEditTableAnswersModal = useSelector(
    (state: ApplicationState) =>
      state.assessments.isShowingEditTableAnswersModal
  );
  const currentTableQuestions = useSelector(
    (state: ApplicationState) => state.assessments.currentTableQuestions
  );
  const currentTableAnswerIndex = useSelector(
    (state: ApplicationState) => state.assessments.currentTableAnswerIndex
  );

  const maxIndex = Math.max(
    ...flattenArray(
      currentTableQuestions?.map(q => q.answers.map(a => a.index || 0))
    )
  );

  let isAdd = false;
  if (currentTableAnswerIndex > maxIndex) {
    isAdd = true;
  }

  const getTableAnswers = () => {
    const answers: TableAnswers = {};
    currentTableQuestions?.forEach(q => {
      answers[q.name] =
        q.answers.find(a => a.index === currentTableAnswerIndex)?.value || '';
    });
    return answers;
  };

  const [tableAnswers, setTableAnswers] =
    useState<TableAnswers>(getTableAnswers());

  useEffect(() => {
    setTableAnswers(getTableAnswers());
  }, [currentTableQuestions, currentTableAnswerIndex]);

  useEffect(() => {
    if (!isShowingEditTableAnswersModal) {
      setTableAnswers({});
    }
  }, [isShowingEditTableAnswersModal]);

  const handleAdd = () => {
    const assessmentResponseAnswers: AssessmentResponseAnswer[] = [];
    currentTableQuestions.forEach((question: QuestionType) => {
      question.answers.forEach(answer => {
        const answerToAdd: CommonAnswerType =
          answer.index === currentTableAnswerIndex
            ? transformAnswerToResponse({
                name: question.name,
                value: tableAnswers[question.name],
                index: currentTableAnswerIndex,
                datatype: currentTableQuestions?.find(
                  q => q.name === question.name
                ).datatype,
              })
            : transformAnswerToResponse(answer);
        assessmentResponseAnswers.push(answerToAdd);
      });
    });
    if (isAdd) {
      Object.keys(tableAnswers).forEach(questionName => {
        assessmentResponseAnswers.push(
          transformAnswerToResponse({
            name: questionName,
            value: tableAnswers[questionName],
            index: currentTableAnswerIndex,
            datatype: currentTableQuestions?.find(q => q.name === questionName)
              .datatype,
          })
        );
      });
    }

    dispatch(
      patchAssessmentResponse({
        id: assessmentResponseId,
        assessmentResponse: {
          assessmentResponseAnswers,
        },
        mode: 'table',
      })
    );
  };

  const handleChange = (answers: AssessmentResponseAnswer[]) => {
    const answer = answers[0];
    setTableAnswers(a => ({ ...a, [answer.name]: answer.value }));
  };

  return (
    <AdoptechModal
      className="tableAnswerEditModal"
      onHide={() => dispatch(hideEditTableAnswersModal())}
      show={isShowingEditTableAnswersModal}
    >
      <Modal.Header>{isAdd ? 'Add' : 'Edit'}</Modal.Header>
      <Modal.Body>
        {currentTableQuestions?.map(question => (
          <React.Fragment key={question.id}>
            <div>{question.questionText}</div>
            <div>
              <AnswerFormControl
                question={question}
                questions={[]}
                answers={[]}
                previousAnswer={
                  tableAnswers[question.name]
                    ? {
                        name: question.name,
                        value: tableAnswers[question.name],
                      }
                    : undefined
                }
                indexProperty="name"
                onChange={handleChange}
                disableDebounce
              />
            </div>
          </React.Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={() => {
            dispatch(hideEditTableAnswersModal());
          }}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          variant={AdoptechButtonVariant.Primary}
          onClick={handleAdd}
        >
          {isAdd ? 'Add' : 'Edit'}
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
