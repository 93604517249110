/* tslint:disable */
/* eslint-disable */
/**
 * Trusthub API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  TrusthubCompanyStatus,
  TrusthubCompanyStatusFromJSON,
  TrusthubCompanyStatusFromJSONTyped,
  TrusthubCompanyStatusToJSON,
  TrusthubVendorUserModel,
  TrusthubVendorUserModelFromJSON,
  TrusthubVendorUserModelFromJSONTyped,
  TrusthubVendorUserModelToJSON,
} from './';

/**
 *
 * @export
 * @interface TrusthubCompanyModel
 */
export interface TrusthubCompanyModel {
  /**
   *
   * @type {string}
   * @memberof TrusthubCompanyModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TrusthubCompanyModel
   */
  name: string;
  /**
   *
   * @type {TrusthubCompanyStatus}
   * @memberof TrusthubCompanyModel
   */
  status: TrusthubCompanyStatus;
  /**
   *
   * @type {Array<TrusthubVendorUserModel>}
   * @memberof TrusthubCompanyModel
   */
  relationshipOwners?: Array<TrusthubVendorUserModel>;
  /**
   *
   * @type {TrusthubVendorUserModel}
   * @memberof TrusthubCompanyModel
   */
  createdBy?: TrusthubVendorUserModel;
  /**
   *
   * @type {TrusthubVendorUserModel}
   * @memberof TrusthubCompanyModel
   */
  approvedBy?: TrusthubVendorUserModel;
  /**
   *
   * @type {string}
   * @memberof TrusthubCompanyModel
   */
  approvedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubCompanyModel
   */
  createdAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof TrusthubCompanyModel
   */
  updatedAt?: string | null;
}

export function TrusthubCompanyModelFromJSON(json: any): TrusthubCompanyModel {
  return TrusthubCompanyModelFromJSONTyped(json, false);
}

export function TrusthubCompanyModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TrusthubCompanyModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    status: TrusthubCompanyStatusFromJSON(json['status']),
    relationshipOwners: !exists(json, 'relationship_owners')
      ? undefined
      : (json['relationship_owners'] as Array<any>).map(
          TrusthubVendorUserModelFromJSON
        ),
    createdBy: !exists(json, 'created_by')
      ? undefined
      : TrusthubVendorUserModelFromJSON(json['created_by']),
    approvedBy: !exists(json, 'approved_by')
      ? undefined
      : TrusthubVendorUserModelFromJSON(json['approved_by']),
    approvedAt: !exists(json, 'approved_at') ? undefined : json['approved_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function TrusthubCompanyModelToJSON(
  value?: TrusthubCompanyModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    status: TrusthubCompanyStatusToJSON(value.status),
    relationship_owners:
      value.relationshipOwners === undefined
        ? undefined
        : (value.relationshipOwners as Array<any>).map(
            TrusthubVendorUserModelToJSON
          ),
    created_by: TrusthubVendorUserModelToJSON(value.createdBy),
    approved_by: TrusthubVendorUserModelToJSON(value.approvedBy),
    approved_at: value.approvedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
