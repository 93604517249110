import React from 'react';
import { ReportSectionProps } from './ReportsManageDrawer';
import { AdoptechTextInput2 } from '../../AdoptechTextInput2/AdoptechTextInput2';

const format = (v: string) =>
  (/\d+\.\d+/.exec(v) || /\d+\./.exec(v) || /\d+/.exec(v) || '')[0];

export const formatFinal = (v: string) => {
  if (/\d+\.\d+/.test(v)) return v;
  if (/\d+/.test(v)) return `${/\d+/.exec(v)}.0`;
  return '1.0';
};

export const ReportFreeVersionField: React.FC<ReportSectionProps> = ({
  formData,
  updateField,
  updateForm,
}) => {
  const baseCss = 'reportsManageDrawer';

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateField('version', format(e.currentTarget.value));

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateField('version', formatFinal(e.currentTarget.value));

  return (
    <>
      <div className={baseCss + '--fieldRow'}>
        <div className={baseCss + '--label'}>Document version.</div>
        <div className={baseCss + '--field'}>
          <AdoptechTextInput2
            id={baseCss + '--version'}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="1.0"
            label="Version"
            value={formData.version}
            type="text"
          />
        </div>
      </div>
    </>
  );
};
