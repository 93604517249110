import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../types/applicationState';

const getPaymentMethods = (state: ApplicationState) =>
  state.user.paymentMethods;
const currentId = (state: ApplicationState) =>
  state.user.currentPaymentMethodId;

export const selectCurrentPaymentMethod = createSelector(
  getPaymentMethods,
  currentId,
  (paymentMethods, id) => {
    return paymentMethods.find(model => model.id === id);
  }
);
