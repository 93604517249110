/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ReminderModel,
  ReminderModelFromJSON,
  ReminderModelFromJSONTyped,
  ReminderModelToJSON,
  SharedPaginationMeta,
  SharedPaginationMetaFromJSON,
  SharedPaginationMetaFromJSONTyped,
  SharedPaginationMetaToJSON,
} from './';

/**
 *
 * @export
 * @interface ReminderList
 */
export interface ReminderList {
  /**
   *
   * @type {Array<ReminderModel>}
   * @memberof ReminderList
   */
  data: Array<ReminderModel>;
  /**
   *
   * @type {SharedPaginationMeta}
   * @memberof ReminderList
   */
  meta: SharedPaginationMeta;
}

export function ReminderListFromJSON(json: any): ReminderList {
  return ReminderListFromJSONTyped(json, false);
}

export function ReminderListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReminderList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(ReminderModelFromJSON),
    meta: SharedPaginationMetaFromJSON(json['meta']),
  };
}

export function ReminderListToJSON(value?: ReminderList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(ReminderModelToJSON),
    meta: SharedPaginationMetaToJSON(value.meta),
  };
}
