import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api/api';
import { getApiErrorMessage } from '../../functions/getApiErrorMessage';
import {
  Company,
  CompanyExtended,
  CompanyIdentifierCreatePayloadTypeEnum,
  CompanyUpdatePayload,
  MultipleShare,
  Office,
  QuestionObjectTypeEnum,
  QuestionTableTypeEnum,
  SharedVendorUserStatus,
  UserInvitation,
  VendorDetails,
  VendorDocument,
  VendorDocumentDownload,
  VendorDocumentExtended,
  VendorsIdPatchRequest,
  VendorsIdSharesDeleteShareableTypeEnum,
  VendorsIdSharesGetShareableTypeEnum,
  VendorsIdSharesPostShareableTypeEnum,
  VendorsIdSharesSuggestionsGetShareableTypeEnum,
  VendorsIdVendorUsersChatWidgetLoginPostRequest,
  VendorsIdVendorUsersPostRequest,
  VendorSupplierPayload,
  VendorSuppliersIdDeleteRequest,
  VendorSuppliersIdPatchRequest,
  VendorsVendorIdVendorDocumentAttestationsGetTargetTypeEnum,
  VendorsVendorIdVendorDocumentsPostRequest,
  VendorsVendorIdVendorSuppliersPostRequest,
  VendorTable,
  VendorTeamModel,
  VendorTeamUpsertPayloadVendorTeams,
  VendorUser,
  VendorUserCreatePayloadVendorUsers,
  VendorUserRoles,
  VendorUsersIdDeleteRequest,
  VendorUsersIdPatchRequest,
  VendorUserStatus,
  VendorUserUpdatePayloadVendorUser,
} from '../../swagger';
import { UNKNOWN_ERROR } from '../../types/constants';
import { InviteUserDetails } from '../../types/inviteUserDetails';
import { VoidThunk } from '../../types/voidThunk';
import {
  completeGetStartedChecklistItem,
  GettingStartedChecklistItems,
} from '../checklists/checklistsThunks';
import {
  setErrorMessage,
  showGlobalToast,
  showGlobalToastWithUndo,
} from '../global/globalSlice';
import { getErrorMessage, handleThunkError } from '../helpers/thunkHelpers';
import {
  approveVendorDocumentFailure,
  approveVendorDocumentRequest,
  approveVendorDocumentSuccess,
  archiveVendorDocumentFailure,
  archiveVendorDocumentRequest,
  archiveVendorDocumentSuccess,
  attestVendorDocumentFailure,
  attestVendorDocumentRequest,
  attestVendorDocumentSuccess,
  createCompanyIdentifierFailure,
  createCompanyIdentifierRequest,
  createCompanyIdentifierSuccess,
  createVendorSupplierRequest,
  createVendorSupplierSuccess,
  deleteCompanyIdentifierFailure,
  deleteCompanyIdentifierRequest,
  deleteCompanyIdentifierSuccess,
  deleteOfficeAddressFailure,
  deleteOfficeAddressRequest,
  deleteOfficeAddressSuccess,
  deleteSharePermissionsFailure,
  deleteSharePermissionsRequest,
  deleteSharePermissionsSuccess,
  deleteVendorDocumentFailure,
  deleteVendorDocumentRequest,
  deleteVendorDocumentSuccess,
  editCompanyIdentifierFailure,
  editCompanyIdentifierRequest,
  editCompanyIdentifierSuccess,
  fetchCompanyIdentifiersFailure,
  fetchCompanyIdentifiersRequest,
  fetchCompanyIdentifiersSuccess,
  fetchCurrentCompanyError,
  fetchCurrentCompanyRequest,
  fetchCurrentCompanySuccess,
  fetchDashboardError,
  fetchDashboardRequest,
  fetchDashboardSuccess,
  fetchEscrowAccountsFailure,
  fetchEscrowAccountsRequest,
  fetchEscrowAccountsSuccess,
  fetchIdentifierTypesFailure,
  fetchIdentifierTypesRequest,
  fetchIdentifierTypesSuccess,
  fetchLogoError,
  fetchLogoRequest,
  fetchLogoSuccess,
  fetchSharesListFailure,
  fetchSharesListRequest,
  fetchSharesListSuccess,
  fetchShareSuggestionsFailure,
  fetchShareSuggestionsRequest,
  fetchShareSuggestionsSuccess,
  fetchVendorDetailsFailure,
  fetchVendorDetailsRequest,
  fetchVendorDetailsSuccess,
  fetchVendorDocumentApprovalsFailure,
  fetchVendorDocumentApprovalsRequest,
  fetchVendorDocumentApprovalsSuccess,
  fetchVendorDocumentAttestationsFailure,
  fetchVendorDocumentAttestationsRequest,
  fetchVendorDocumentAttestationsSuccess,
  fetchVendorDocumentByIdFailure,
  fetchVendorDocumentDownloadSuccess,
  fetchVendorDocumentsDownloadError,
  fetchVendorDocumentsDownloadRequest,
  fetchVendorDocumentsFailure,
  fetchVendorDocumentsRequest,
  fetchVendorDocumentsSuccess,
  fetchVendorPolicyDocumentsFailure,
  fetchVendorPolicyDocumentsRequest,
  fetchVendorPolicyDocumentsSuccess,
  fetchVendorsFailure,
  fetchVendorsRequest,
  fetchVendorsSuccess,
  fetchVendorSupplierCategoriesSuccess,
  fetchVendorSuppliersFailure,
  fetchVendorSuppliersRequest,
  fetchVendorSuppliersSuccess,
  fetchVendorSupplierSuccess,
  fetchVendorTableFailure,
  fetchVendorTableRequest,
  fetchVendorTableSuccess,
  fetchVendorTeamsFailure,
  fetchVendorTeamsRequest,
  fetchVendorTeamsSuccess,
  fetchVendorUserFailure,
  fetchVendorUserRequest,
  fetchVendorUsersFailure,
  fetchVendorUsersRequest,
  fetchVendorUsersSuccess,
  fetchVendorUserSuccess,
  getVendorProductsFailure,
  getVendorProductsRequest,
  getVendorProductsSuccess,
  getVendorUserPermissionsFailure,
  getVendorUserPermissionsRequest,
  getVendorUserPermissionsSuccess,
  hideAddCompanyOfficeAddressModal,
  hideConfirmSendInvitationModal,
  hideDeleteCompanyIdentifierModal,
  hideDeleteCompanyOfficeAddressModal,
  hideDeleteVendorDocumentModal,
  hideDocumentUploadModal,
  hideEditMediaModal,
  hideUploadLogoModal,
  patchVendorDocumentFailure,
  patchVendorDocumentRequest,
  patchVendorDocumentSuccess,
  patchVendorSupplierRequest,
  patchVendorSupplierSuccess,
  sendInvitationFailure,
  sendInvitationRequest,
  sendInvitationSuccess,
  setCompanyState,
  setCurrentVendorDocument,
  setFetchVendorDocumentByIdLoading,
  setOrgChart,
  shareFailure,
  shareRequest,
  shareSuccess,
  showDeleteCompanyIdentifierToast,
  showEditCompanyProfileToast,
  showEditMediaToast,
  showFileUploadedToast,
  showLogoUploadSuccessToast,
  showOfficeAddressAddedToast,
  showOfficeAddressDeletedToast,
  showOfficeAddressEditedToast,
  showSuccessfulVendorDocumentApprovalToast,
  showSuccessfulVendorDocumentAttestationToast,
  showSuccessfulVendorDocumentRejectionToast,
  showVendorDocumentDeletedToast,
  showWarningSubscriptionLimitModal,
  unarchiveVendorDocumentFailure,
  unarchiveVendorDocumentRequest,
  unarchiveVendorDocumentSuccess,
  updateCompanyProfileError,
  updateCompanyProfileRequest,
  updateCompanyProfileSuccess,
  updateInviteUserDetails,
  updateOfficeAddressFailure,
  updateOfficeAddressRequest,
  updateOfficeAddressSuccess,
  updateOrgChartFailure,
  updateOrgChartRequest,
  updateVendorDetailsFailure,
  updateVendorDetailsRequest,
  updateVendorDetailsSuccess,
  updateVendorUserRoles,
  uploadLogoFailure,
  uploadLogoRequest,
  uploadLogoSuccess,
  uploadVendorDocumentFailure,
  uploadVendorDocumentRequest,
  uploadVendorDocumentSuccess,
  upsertVendorTeamsFailure,
  upsertVendorTeamsRequest,
  upsertVendorTeamsSuccess,
  userCreateFailure,
  userDeleteFailure,
  userDeleteSuccess,
  userRolesChangeFailure,
  userUpdateFailure,
} from './vendorsSlice';
import { ApplicationState } from '../../types/applicationState';
import { peopleComplianceRoute } from '../../components/Routes/Routes';
import { rawDownloadFile } from '../../functions/rawDownloadFile';

export const fetchEscrowAccounts =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchEscrowAccountsRequest());
      const response = await api().vendorsIdEscrowAccountsGet({ id });
      dispatch(fetchEscrowAccountsSuccess(response));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred fetching escrow accounts. Please try again or contact support.'
      );
      dispatch(fetchEscrowAccountsFailure());
      dispatch(setErrorMessage(apiErrorMessage));
      console.log(err);
    }
  };

export const fetchVendors =
  (onSuccess?: () => void): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchVendorsRequest());
      const response = await api().vendorsGet();
      dispatch(fetchVendorsSuccess(response));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorsFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorsFailure(
              'An error occurred fetching vendors. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorSupplierCategories =
  (): VoidThunk => async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      const response = await api().vendorsVendorIdVendorSuppliersCategoriesGet({
        vendorId,
      });
      dispatch(fetchVendorSupplierCategoriesSuccess(response));
    } catch (err) {
      console.log(err);
    }
  };

export const sendInvitation =
  (userDetails: InviteUserDetails): VoidThunk =>
  async dispatch => {
    const {
      emailAddress: email,
      firstName,
      lastName,
      vendorId,
      message: invitationMessage,
    } = userDetails;
    try {
      dispatch(sendInvitationRequest());
      const invitation: UserInvitation = {
        email,
        firstName,
        lastName,
        vendorId,
        invitationMessage,
      };
      await api().usersInvitationsPost({ invitation });
      dispatch(sendInvitationSuccess());
      dispatch(hideConfirmSendInvitationModal());
      dispatch(
        updateInviteUserDetails({
          firstName: '',
          lastName: '',
          emailAddress: '',
          message: '',
        })
      );
      dispatch(fetchVendorUsers(vendorId));
      dispatch(
        fetchVendorTable({ vendorId, type: QuestionObjectTypeEnum.VendorUser })
      );
      dispatch(
        completeGetStartedChecklistItem(
          GettingStartedChecklistItems.invite_colleagues_to_the_portal
        )
      );
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        if (err.status === 422) {
          dispatch(
            sendInvitationFailure(`User ${email} has already been invited.`)
          );
        }
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(sendInvitationFailure(apiErrorMessage));
        } else {
          dispatch(
            sendInvitationFailure(
              'An error occurred during sending the invitation. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(sendInvitationFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorDetails =
  (vendorId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchVendorDetailsRequest());
      const response = await api().vendorsIdGet({ id: vendorId });
      dispatch(fetchVendorDetailsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorDetailsFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorDetailsFailure(
              'An error occurred fetching vendor details. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorDetailsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorUser =
  (vendorUserId: string, onSuccess: (user: VendorUser) => void): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchVendorUserRequest());
      const response = await api().vendorUsersIdGet({ id: vendorUserId });
      dispatch(fetchVendorUserSuccess(response));
      onSuccess(response);
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorUserFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorUserFailure(
              'An error occurred fetching the user. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorUserFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorUsers =
  (
    vendorId: string,
    options?: {
      silent?: boolean;
    }
  ): VoidThunk =>
  async dispatch => {
    try {
      if (!options?.silent) dispatch(fetchVendorUsersRequest());
      const response = await api().vendorsIdVendorUsersGet({
        id: vendorId,
      });
      dispatch(fetchVendorUsersSuccess(response));
    } catch (err) {
      console.log(
        'API error',
        `Status: ${err.status} Message: ${err.statusText}`
      );
      const apiErrorMessage = await getApiErrorMessage(err);
      if (apiErrorMessage) {
        dispatch(fetchVendorUsersFailure(apiErrorMessage));
      } else {
        dispatch(
          fetchVendorUsersFailure(
            'An error occurred fetching users. Please try again or contact support.'
          )
        );
      }
    }
  };

export const updateUserRoles =
  ({
    user,
    roles,
    vendorId,
    onSuccess = () => {},
  }: {
    user: VendorUser;
    roles: VendorUserRoles[];
    vendorId: string;
    onSuccess: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      const request: VendorUsersIdPatchRequest = {
        id: user.id,
        body: {
          vendorUser: {
            roles,
          },
        },
      };
      await api().vendorUsersIdPatch(request);
      dispatch(fetchVendorUsers(vendorId));
      onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(userRolesChangeFailure(apiErrorMessage));
        } else {
          dispatch(
            userRolesChangeFailure(
              'An error occurred during changing the access level. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(userRolesChangeFailure(UNKNOWN_ERROR));
      }
    }
  };

export const createUser =
  ({
    vendorId,
    vendorUsers,
    onSuccess,
    onError,
  }: {
    vendorId: string;
    vendorUsers: VendorUserCreatePayloadVendorUsers[];
    onSuccess(): void;
    onError(): void;
  }): VoidThunk =>
  async dispatch => {
    try {
      const request: VendorsIdVendorUsersPostRequest = {
        id: vendorId,
        body: { vendorUsers },
      };
      await api().vendorsIdVendorUsersPost(request);
      dispatch(fetchVendorUsers(vendorId));
      onSuccess && onSuccess();
    } catch (err) {
      onError();
      if (err instanceof Response) {
        if (err.status == 402) {
          dispatch(showWarningSubscriptionLimitModal());
          console.log('Payment required');
          return;
        }

        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred while creating user. Please try again or contact support.'
        );
        console.log(err);
        dispatch(userCreateFailure(apiErrorMessage));
      } else {
        console.log(err);
        dispatch(userCreateFailure(UNKNOWN_ERROR));
      }
    }
  };

export const updateUser =
  ({
    vendorUserId,
    vendorUser,
    onSuccess,
  }: {
    vendorUserId: string;
    vendorUser: VendorUserUpdatePayloadVendorUser;
    onSuccess(): void;
  }): VoidThunk =>
  async dispatch => {
    try {
      const request: VendorUsersIdPatchRequest = {
        id: vendorUserId,
        body: { vendorUser },
      };
      const result = await api().vendorUsersIdPatch(request);
      dispatch(updateVendorUserRoles(result));
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while updating user. Please try again or contact support.'
      );
      dispatch(userUpdateFailure(apiErrorMessage));
    }
  };

export const submitVendorSupplier =
  ({ onSuccess }: { onSuccess(): void }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorSupplier = getState().vendors.vendorSupplierForm;
      const body: VendorSupplierPayload = {
        ...vendorSupplier,
        ownerId: vendorSupplier.owner.id,
      };
      if (vendorSupplier.id) {
        dispatch(patchVendorSupplierRequest());
        const request: VendorSuppliersIdPatchRequest = {
          id: vendorSupplier.id,
          body,
        };
        await api().vendorSuppliersIdPatch(request);
        dispatch(patchVendorSupplierSuccess());
      } else {
        dispatch(createVendorSupplierRequest());
        const vendorId = getState().vendors.currentVendor.id;
        const request: VendorsVendorIdVendorSuppliersPostRequest = {
          vendorId: vendorId,
          body,
        };
        await api().vendorsVendorIdVendorSuppliersPost(request);
        dispatch(createVendorSupplierSuccess());
      }
      onSuccess && onSuccess();
    } catch (err) {
      let errorMessage =
        'Vendor submit error. Please try again or contact support.';
      if (err instanceof Response) {
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) errorMessage = apiErrorMessage;
      }
      dispatch(setErrorMessage(errorMessage));
    }
  };

export const deleteVendorSupplier =
  ({ onSuccess }: { onSuccess(): void }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorSupplier = getState().vendors.vendorSupplierForm;
      const request: VendorSuppliersIdDeleteRequest = {
        id: vendorSupplier.id,
      };
      await api().vendorSuppliersIdDelete(request);
      onSuccess && onSuccess();
    } catch (err) {
      let errorMessage =
        'Vendor delete error. Please try again or contact support.';
      if (err instanceof Response) {
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) errorMessage = apiErrorMessage;
      }
      dispatch(setErrorMessage(errorMessage));
    }
  };

export const deleteUser =
  ({ user, vendorId }: { user: VendorUser; vendorId: string }): VoidThunk =>
  async dispatch => {
    try {
      const request: VendorUsersIdDeleteRequest = {
        id: user.id,
      };
      await api().vendorUsersIdDelete(request);
      dispatch(userDeleteSuccess());
      dispatch(fetchVendorUsers(vendorId));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(userDeleteFailure(apiErrorMessage));
        } else {
          dispatch(
            userDeleteFailure(
              'An error occurred while deleting user. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(userDeleteFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorTeams =
  (
    viewExtended?: boolean,
    onSuccess?: (response: VendorTeamModel[]) => void,
    searchQuery?: string
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(fetchVendorTeamsRequest());
      const response = await api().vendorsIdVendorTeamsGet({
        id: vendorId,
        viewExtended,
        query: searchQuery,
      });
      dispatch(fetchVendorTeamsSuccess(response));
      onSuccess && onSuccess(response);
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorTeamsFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorTeamsFailure(
              'An error occurred fetching vendor teams. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorTeamsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const upsertVendorTeams =
  ({
    vendorTeams,
    vendorId,
    onSuccess,
  }: {
    vendorTeams: Array<VendorTeamUpsertPayloadVendorTeams>;
    vendorId: string;
    onSuccess(): void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(upsertVendorTeamsRequest());
      await api().vendorsIdVendorTeamsPost({
        id: vendorId,
        body: { vendorTeams },
      });
      dispatch(fetchVendorTeams(true));
      dispatch(upsertVendorTeamsSuccess());
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(upsertVendorTeamsFailure(apiErrorMessage));
        } else {
          dispatch(
            upsertVendorTeamsFailure(
              'An error occurred while editing teams. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(upsertVendorTeamsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorDocuments =
  ({
    vendorId,
    queryShowHistory,
    queryDocumentType,
    viewExtended,
  }: {
    vendorId: string;
    queryShowHistory?: boolean;
    queryDocumentType?: string;
    viewExtended?: boolean;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchVendorDocumentsRequest());
      const response = await api().vendorsIdVendorDocumentsGet({
        id: vendorId,
        queryDocumentType,
        queryShowHistory,
      });
      dispatch(fetchVendorDocumentsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorDocumentsFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorDocumentsFailure(
              'An error occurred fetching the documents. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorDocumentsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorDocumentDownload =
  ({
    id,
    successCallback,
  }: {
    id: string;
    successCallback?: (response: VendorDocumentDownload) => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchVendorDocumentsDownloadRequest());
      const response = await api().vendorDocumentsIdDownloadGet({
        id,
      });
      dispatch(fetchVendorDocumentDownloadSuccess(response));
      successCallback && successCallback(response);
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorDocumentsDownloadError(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorDocumentsDownloadError(
              'An error occurred fetching the dowload details. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorDocumentsDownloadError(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorPolicyDocuments =
  (showLoading = true): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(fetchVendorPolicyDocumentsRequest(showLoading));
      const response = await api().vendorsIdVendorDocumentsGet({
        id: vendorId,
        queryDocumentType: 'policy',
      });
      dispatch(fetchVendorPolicyDocumentsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorPolicyDocumentsFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorPolicyDocumentsFailure(
              'An error occurred fetching the policy documents. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorPolicyDocumentsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const uploadVendorDocument =
  (
    params: Omit<VendorsVendorIdVendorDocumentsPostRequest, 'vendorId'>
  ): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(uploadVendorDocumentRequest());
      if (!params.vendorDocumentPolicyCategoryId) {
        // fix send "null" as a string to the backend
        delete params.vendorDocumentPolicyCategoryId;
      }
      await api().vendorsVendorIdVendorDocumentsPost({
        vendorId,
        ...params,
      });
      dispatch(uploadVendorDocumentSuccess());
      dispatch(fetchVendorDocuments({ vendorId }));
      dispatch(hideDocumentUploadModal());
      dispatch(showFileUploadedToast());
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred uploading the document. Please try again or contact support.'
      );
      dispatch(uploadVendorDocumentFailure(apiErrorMessage));
      return Promise.reject(apiErrorMessage);
    }
  };

export const deleteVendorDocument =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteVendorDocumentRequest());
      await api().vendorDocumentsIdDelete({ id });
      dispatch(deleteVendorDocumentSuccess(id));
      dispatch(hideDeleteVendorDocumentModal());
      dispatch(showVendorDocumentDeletedToast());
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while deleting a vendor document. Please try again or contact support.'
      );
      dispatch(deleteVendorDocumentFailure(apiErrorMessage));
    }
  };

export const fetchVendorTable =
  ({
    type,
    vendorId,
  }: {
    type: QuestionObjectTypeEnum;
    vendorId?: string;
  }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchVendorTableRequest(type));
      const id = vendorId || getState().vendors.currentVendor?.id;
      const response = await api().vendorsIdTablesGet({
        id,
        type,
        statuses: [VendorUserStatus.Active, VendorUserStatus.Invited],
      });
      dispatch(fetchVendorTableSuccess({ type, vendorTable: response }));
    } catch (err) {
      if (err instanceof Response) {
        if (err.status === 404) {
          const emptyTable: VendorTable = { columns: [], rows: [] };
          dispatch(fetchVendorTableSuccess({ type, vendorTable: emptyTable }));
          return;
        }
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorTableFailure({ error: apiErrorMessage, type }));
        } else {
          dispatch(
            fetchVendorTableFailure({
              error:
                'An error occurred fetching the vendor table. Please try again or contact support.',
              type,
            })
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorTableFailure({ error: UNKNOWN_ERROR, type }));
      }
    }
  };

export const fetchTable =
  (type: QuestionTableTypeEnum): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchVendorTableRequest(type));
      const response = await api().tablesTypeGet({ type });
      dispatch(fetchVendorTableSuccess({ type, vendorTable: response }));
    } catch (err) {
      if (err instanceof Response) {
        if (err.status === 404) {
          const emptyTable: VendorTable = { columns: [], rows: [] };
          dispatch(fetchVendorTableSuccess({ type, vendorTable: emptyTable }));
          return;
        }
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorTableFailure({ error: apiErrorMessage, type }));
        } else {
          dispatch(
            fetchVendorTableFailure({
              error:
                'An error occurred fetching the table. Please try again or contact support.',
              type,
            })
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorTableFailure({ error: UNKNOWN_ERROR, type }));
      }
    }
  };

export const attestVendorDocument =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(attestVendorDocumentRequest());
      await api().vendorDocumentsIdAttestPost({ id });
      dispatch(attestVendorDocumentSuccess());
      dispatch(fetchVendorPolicyDocuments());
      dispatch(showSuccessfulVendorDocumentAttestationToast());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(attestVendorDocumentFailure(apiErrorMessage));
        } else {
          dispatch(
            attestVendorDocumentFailure(
              'An error occurred attesting the document. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(attestVendorDocumentFailure(UNKNOWN_ERROR));
      }
    }
  };

export const approveVendorDocument =
  ({ id, onSuccess }: { id: string; onSuccess: () => void }): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(approveVendorDocumentRequest());
      await api().vendorDocumentsIdApprovePost({ id });
      dispatch(approveVendorDocumentSuccess());

      const vendorId = getState().vendors.currentVendor?.id;
      dispatch(fetchVendorDocuments({ vendorId }));

      dispatch(fetchVendorPolicyDocuments());
      dispatch(showSuccessfulVendorDocumentApprovalToast());
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(approveVendorDocumentFailure(apiErrorMessage));
        } else {
          dispatch(
            approveVendorDocumentFailure(
              'An error occurred approving the document. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(approveVendorDocumentFailure(UNKNOWN_ERROR));
      }
    }
  };

export const rejectVendorDocument =
  ({
    vendorDocumentId,
    reason,
    onSuccess,
  }: {
    vendorDocumentId: string;
    reason: string;
    onSuccess: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(approveVendorDocumentRequest());
      await api().vendorDocumentsIdRejectPost({
        id: vendorDocumentId,
        vendorDocumentApproval: { rejectionReason: reason },
      });
      dispatch(approveVendorDocumentSuccess());
      dispatch(fetchVendorPolicyDocuments());
      dispatch(showSuccessfulVendorDocumentRejectionToast());
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(approveVendorDocumentFailure(apiErrorMessage));
        } else {
          dispatch(
            approveVendorDocumentFailure(
              'An error occurred rejecting the document. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(approveVendorDocumentFailure(UNKNOWN_ERROR));
      }
    }
  };

export const updateOfficeAddress =
  ({
    company,
    office,
    isExisting,
    onSuccess,
  }: {
    company: Company;
    office: Office;
    isExisting: boolean;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(updateOfficeAddressRequest());
      const result = await api().companiesIdPatch({
        id: company.id,
        body: {
          ...company,
          offices: [office],
        },
      });
      dispatch(updateOfficeAddressSuccess(result));
      dispatch(hideAddCompanyOfficeAddressModal());
      dispatch(
        isExisting
          ? showOfficeAddressEditedToast()
          : showOfficeAddressAddedToast()
      );
      onSuccess && onSuccess();
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while updating office location. Please try again or contact support.'
      );
      dispatch(updateOfficeAddressFailure(apiErrorMessage));
    }
  };

export const deleteOfficeAddress =
  ({ company, office }: { company: Company; office: Office }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteOfficeAddressRequest());
      await api().companiesIdOfficesOfficeIdDelete({
        id: company.id,
        officeId: office.id,
      });
      dispatch(deleteOfficeAddressSuccess(office.id));
      dispatch(hideDeleteCompanyOfficeAddressModal());
      dispatch(showOfficeAddressDeletedToast());
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while deleting office location. Please try again or contact support.'
      );
      dispatch(deleteOfficeAddressFailure(apiErrorMessage));
    }
  };

export const updateVendorDetails =
  ({ vendorDetails }: { vendorDetails: VendorDetails }): VoidThunk =>
  async dispatch => {
    const { access, ...vendorFields } = vendorDetails;
    try {
      dispatch(updateVendorDetailsRequest());
      const request: VendorsIdPatchRequest = {
        id: vendorDetails.id,
        vendor: vendorFields,
      };
      await api().vendorsIdPatch(request);
      dispatch(updateVendorDetailsSuccess());
      dispatch(hideEditMediaModal());
      dispatch(showEditMediaToast());
      dispatch(fetchVendorDetails(vendorDetails.id));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while updating vendor details. Please try again or contact support.'
      );
      dispatch(updateVendorDetailsFailure(apiErrorMessage));
    }
  };

export const fetchOrgChart = createAsyncThunk(
  'vendors/fetchOrgChart',
  async (id: string, { dispatch }) => {
    try {
      const response = await api().vendorsIdOrgChartGet({ id });
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred during fetching org chart. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const downloadOrgChart = createAsyncThunk(
  'vendors/downloadOrgChart',
  async (id: string, { dispatch }) => {
    try {
      const response = await api().vendorsIdOrgChartGet({ id });
      await rawDownloadFile(response.urls.full, response.filename);

      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while downloading org chart. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const updateOrgChart =
  ({
    id,
    image,
    onSuccess,
  }: {
    id: string;
    image: Blob;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(updateOrgChartRequest(true));
      const response = await api().vendorsIdOrgChartPatch({ id, image });
      dispatch(setOrgChart(response));
      dispatch(updateOrgChartRequest(false));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(updateOrgChartFailure(apiErrorMessage));
        } else {
          dispatch(
            updateOrgChartFailure(
              'An error occurred during updating org chart. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(updateOrgChartFailure(UNKNOWN_ERROR));
      }
    }
  };

export const patchCompanyProfile =
  ({
    company,
    onSuccess,
  }: {
    company: CompanyExtended;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(updateCompanyProfileRequest());
      const result = await api().companiesIdPatch({
        id: company.id,
        body: company as unknown as CompanyUpdatePayload,
      });
      dispatch(setCompanyState(result));
      dispatch(updateCompanyProfileSuccess(result));
      onSuccess && onSuccess();
      dispatch(showEditCompanyProfileToast());
    } catch (err) {
      const errorMessageToUser = await getErrorMessage(
        err,
        'An error occurred during updating company profile. Please try again or contact support.'
      );
      dispatch(setErrorMessage(errorMessageToUser));
      dispatch(updateCompanyProfileError(errorMessageToUser));
    }
  };

export const fetchCurrentCompany =
  (): VoidThunk => async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor?.id;
      const companyId = getState().vendors.vendorDetails.find(
        details => details.id === vendorId
      ).companies[0].id;
      dispatch(fetchCurrentCompanyRequest());
      const response = await api().companiesIdGet({ id: companyId });
      dispatch(fetchCurrentCompanySuccess(response));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching company details. Please try again or contact support.'
      );
      dispatch(fetchCurrentCompanyError(apiErrorMessage));
    }
  };

export const fetchCompanyLogo =
  ({ vendorId }: { vendorId: string }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchLogoRequest());
      const response = await api().vendorsIdLogoGet({
        id: vendorId,
      });
      dispatch(fetchLogoSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchLogoError(apiErrorMessage));
        } else {
          dispatch(
            fetchLogoError(
              'An error occurred fetching vendors. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchLogoError(UNKNOWN_ERROR));
      }
    }
  };

export const uploadCompanyLogo =
  ({ vendorId, file }: { vendorId: string; file: File }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(uploadLogoRequest());
      const response = await api().vendorsIdLogoPatch({
        id: vendorId,
        image: file,
      });
      dispatch(uploadLogoSuccess(response));
      dispatch(hideUploadLogoModal());
      dispatch(showLogoUploadSuccessToast());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(uploadLogoFailure(apiErrorMessage));
        } else {
          dispatch(
            uploadLogoFailure(
              'An error occurred uploading the logo. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(uploadLogoFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchDashboard =
  ({ vendorId }: { vendorId: string }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchDashboardRequest());
      const response = await api().vendorsIdDashboardSummaryGet({
        id: vendorId,
      });
      dispatch(fetchDashboardSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchDashboardError(apiErrorMessage));
        } else {
          dispatch(
            fetchDashboardError(
              'An error occurred fetching vendors. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchDashboardError(UNKNOWN_ERROR));
      }
    }
  };

export const archiveVendorDocument =
  (document: VendorDocument): VoidThunk =>
  async dispatch => {
    try {
      const { name, id, documentType, documentableId } = document;
      dispatch(archiveVendorDocumentRequest(id));
      documentType === 'agreement'
        ? await api().agreementsIdArchivePost({ id: documentableId })
        : await api().vendorDocumentsIdArchivePost({ id });

      dispatch(archiveVendorDocumentSuccess(id));
      dispatch(
        showGlobalToastWithUndo(`${name} has been moved to the archive.`)
      );
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(archiveVendorDocumentFailure(apiErrorMessage));
        } else {
          dispatch(
            archiveVendorDocumentFailure(
              'An error occurred archiving the document. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(archiveVendorDocumentFailure(UNKNOWN_ERROR));
      }
    }
  };

export const unarchiveVendorDocument =
  (document?: VendorDocument): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const archivingDocument =
        getState().vendors.documentState.vendorDocuments.find(
          doc =>
            doc.id ===
            getState().vendors.documentState.archivingVendorDocumentId
        );
      // From meatball menu OR current archiving doc when click undo button in toast message
      const targetDocument = document || archivingDocument;
      if (!targetDocument) return;
      const { id, name, documentType, documentableId } = targetDocument;

      dispatch(unarchiveVendorDocumentRequest(id));
      documentType === 'agreement'
        ? await api().agreementsIdUnarchivePost({
            id: documentableId,
          })
        : await api().vendorDocumentsIdUnarchivePost({ id });

      dispatch(unarchiveVendorDocumentSuccess(id));
      if (documentType === 'agreement')
        dispatch(
          showGlobalToast(
            `${name} has been removed from the archive and will now be visible on the Agreement page.`
          )
        );
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(unarchiveVendorDocumentFailure(apiErrorMessage));
        } else {
          dispatch(
            unarchiveVendorDocumentFailure(
              'An error occurred unarchiving the document. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(unarchiveVendorDocumentFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchIdentifierTypes = (): VoidThunk => async dispatch => {
  try {
    dispatch(fetchIdentifierTypesRequest());
    const response = await api().companyIdentifiersTypesGet();
    dispatch(fetchIdentifierTypesSuccess(response));
  } catch (err) {
    if (err instanceof Response) {
      console.log(
        'API error',
        `Status: ${err.status} Message: ${err.statusText}`
      );
      const apiErrorMessage = await getApiErrorMessage(err);
      if (apiErrorMessage) {
        dispatch(fetchIdentifierTypesFailure(apiErrorMessage));
      } else {
        dispatch(
          fetchIdentifierTypesFailure(
            'An error occurred fetching identifier types. Please try again or contact support.'
          )
        );
      }
    } else {
      console.log(err);
      dispatch(fetchIdentifierTypesFailure(UNKNOWN_ERROR));
    }
  }
};

export const fetchCompanyIdentifiers =
  (companyId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchCompanyIdentifiersRequest());
      const response = await api().companiesIdCompanyIdentifiersGet({
        id: companyId,
      });
      dispatch(fetchCompanyIdentifiersSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchCompanyIdentifiersFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchCompanyIdentifiersFailure(
              'An error occurred fetching identifiers. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchCompanyIdentifiersFailure(UNKNOWN_ERROR));
      }
    }
  };

export const simpleDeleteCompanyIdentifier =
  (companyIdentifierId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteCompanyIdentifierRequest());
      await api().companyIdentifiersIdDelete({ id: companyIdentifierId });
      dispatch(deleteCompanyIdentifierSuccess());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(deleteCompanyIdentifierFailure(apiErrorMessage));
        } else {
          dispatch(
            deleteCompanyIdentifierFailure(
              'An error occurred creating a company identifier. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(deleteCompanyIdentifierFailure(UNKNOWN_ERROR));
      }
    }
  };

export const deleteCompanyIdentifier =
  (companyId: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      const identifierBeingDeleted =
        getState().vendors.companyDetailsState.identifierBeingDeleted;
      dispatch(deleteCompanyIdentifierRequest());
      await api().companyIdentifiersIdDelete({
        id: identifierBeingDeleted.id,
      });
      dispatch(deleteCompanyIdentifierSuccess());
      dispatch(hideDeleteCompanyIdentifierModal());
      dispatch(showDeleteCompanyIdentifierToast());

      dispatch(fetchCompanyIdentifiers(companyId));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(deleteCompanyIdentifierFailure(apiErrorMessage));
        } else {
          dispatch(
            deleteCompanyIdentifierFailure(
              'An error occurred deleting company identifier. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(deleteCompanyIdentifierFailure(UNKNOWN_ERROR));
      }
    }
  };

export const editCompanyIdentifier =
  (
    { identifier, id }: { id: string; identifier: string },
    companyId: string,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(editCompanyIdentifierRequest());
      await api().companyIdentifiersIdPatch({
        id,
        body: { identifier },
      });
      onSuccess();
      dispatch(fetchCompanyIdentifiers(companyId));
      dispatch(editCompanyIdentifierSuccess());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(editCompanyIdentifierFailure(apiErrorMessage));
        } else {
          dispatch(
            editCompanyIdentifierFailure(
              'An error occurred updating company identifier. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(editCompanyIdentifierFailure(UNKNOWN_ERROR));
      }
    }
  };

export const createCompanyIdentifier =
  (
    { identifier, type }: { identifier: string; type: string },
    companyId: string,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(createCompanyIdentifierRequest());
      await api().companiesIdCompanyIdentifiersPost({
        id: companyId,
        body: {
          identifier,
          type: type as CompanyIdentifierCreatePayloadTypeEnum,
        },
      });
      onSuccess();
      dispatch(fetchCompanyIdentifiers(companyId));
      dispatch(createCompanyIdentifierSuccess());
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(createCompanyIdentifierFailure(apiErrorMessage));
        } else {
          dispatch(
            createCompanyIdentifierFailure(
              'An error occurred creating company identifier. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(createCompanyIdentifierFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchShares =
  ({
    id,
    shareableType,
    shareableId,
  }: {
    id: string;
    shareableType: string;
    shareableId: string;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchSharesListRequest());
      const result = await api().vendorsIdSharesGet({
        id,
        shareableType: <VendorsIdSharesGetShareableTypeEnum>shareableType,
        shareableId,
      });
      dispatch(fetchSharesListSuccess(result));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchSharesListFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchSharesListFailure(
              'An error occurred fetching shares. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchSharesListFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchShareSuggestions =
  ({
    textSearch,
    id,
    shareableType,
    shareableId,
  }: {
    textSearch: string;
    id: string;
    shareableType: string;
    shareableId: string;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchShareSuggestionsRequest());
      const result = await api().vendorsIdSharesSuggestionsGet({
        shareableType: <VendorsIdSharesSuggestionsGetShareableTypeEnum>(
          shareableType
        ),
        textSearch,
        id,
        shareableId,
      });
      dispatch(fetchShareSuggestionsSuccess(result));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchShareSuggestionsFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchShareSuggestionsFailure(
              'An error occurred fetching suggestions. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchShareSuggestionsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const shareEntity =
  ({
    id,
    shareableType,
    shareableId,
    multipleShare,
    onSuccess,
  }: {
    id: string;
    shareableType: string;
    shareableId: string;
    multipleShare: MultipleShare;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(shareRequest());
      await api().vendorsIdSharesPost({
        id,
        shareableType: <VendorsIdSharesPostShareableTypeEnum>shareableType,
        shareableId,
        body: multipleShare,
      });
      dispatch(shareSuccess());
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(shareFailure(apiErrorMessage));
        } else {
          dispatch(
            shareFailure(
              'An error occurred during sharing. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(shareFailure(UNKNOWN_ERROR));
      }
    }
  };

export const updateSharePermissions =
  ({
    id,
    shareableType,
    shareableId,
    onSuccess,
    multipleShare,
    emailsToDelete,
  }: {
    id: string;
    shareableType: string;
    shareableId: string;
    onSuccess?: () => void;
    multipleShare?: MultipleShare;
    emailsToDelete?: string[];
  }): VoidThunk =>
  async dispatch => {
    try {
      if (multipleShare?.shares?.length) {
        dispatch(shareRequest());
        await api().vendorsIdSharesPost({
          id,
          shareableType: <VendorsIdSharesPostShareableTypeEnum>shareableType,
          shareableId,
          body: multipleShare,
        });
        dispatch(shareSuccess());
      }
      if (emailsToDelete?.length) {
        dispatch(deleteSharePermissionsRequest());
        await api().vendorsIdSharesDeleteRaw({
          id,
          shareableType: <VendorsIdSharesDeleteShareableTypeEnum>shareableType,
          shareableId,
          body: { emails: emailsToDelete },
        });
        dispatch(deleteSharePermissionsSuccess());
      }
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(deleteSharePermissionsFailure(apiErrorMessage));
        } else {
          dispatch(
            deleteSharePermissionsFailure(
              'An error occurred updating permissions. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(deleteSharePermissionsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorUserPermissions =
  (userId: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(getVendorUserPermissionsRequest());
      const response = await api().vendorUsersIdPermissionsGet({
        id: userId,
      });
      dispatch(getVendorUserPermissionsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(getVendorUserPermissionsFailure(apiErrorMessage));
        } else {
          dispatch(
            getVendorUserPermissionsFailure(
              'An error occurred during fetching permissions. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(getVendorUserPermissionsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const getVendorProducts =
  (showUnavailable: boolean): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(getVendorProductsRequest());
      const vendorId = getState().vendors.currentVendor?.id;
      const response = await api().vendorsIdProductsGet({
        id: vendorId,
        queryShowUnavailable: showUnavailable,
      });
      dispatch(getVendorProductsSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        const apiErrorMessage = await getErrorMessage(
          err,
          'An error occurred fetching vendor products. Please try again or contact support.'
        );
        console.log(err);
        dispatch(getVendorProductsFailure(apiErrorMessage));
      }
    }
  };

export const fetchVendorDocumentById =
  ({
    id,
    onSuccess = () => {},
  }: {
    id: string;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(setFetchVendorDocumentByIdLoading(true));
      const response = await api().vendorDocumentsIdGet({
        id,
      });
      dispatch(setFetchVendorDocumentByIdLoading(false));
      dispatch(setCurrentVendorDocument(response));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorDocumentByIdFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorDocumentByIdFailure(
              'An error occurred fetching vendor document. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorDocumentByIdFailure(UNKNOWN_ERROR));
      }
    }
  };

export const patchVendorDocument =
  ({
    documentId,
    vendorDocument,
    onSuccess,
  }: {
    documentId: string;
    vendorDocument: VendorDocumentExtended;
    onSuccess?: () => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(patchVendorDocumentRequest());
      const result = await api().vendorDocumentsIdPatch({
        id: documentId,
        vendorDocument,
      });
      dispatch(patchVendorDocumentSuccess(result));
      onSuccess && onSuccess();
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(patchVendorDocumentFailure(apiErrorMessage));
        } else {
          dispatch(
            patchVendorDocumentFailure(
              'An error occurred updating the document. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(patchVendorDocumentFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorSuppliers =
  (): VoidThunk => async (dispatch, getState) => {
    try {
      dispatch(fetchVendorSuppliersRequest());
      const vendorId = getState().vendors.currentVendor?.id;
      const response = await api().vendorsVendorIdVendorSuppliersGet({
        vendorId,
      });
      dispatch(fetchVendorSuppliersSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorSuppliersFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorSuppliersFailure(
              'An error occurred fetching vendor suppliers. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorSuppliersFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorSupplier =
  (id: VendorDocumentExtended['id']): VoidThunk =>
  async dispatch => {
    try {
      const response = await api().vendorSuppliersIdGet({
        id,
      });
      dispatch(fetchVendorSupplierSuccess(response));
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorSuppliersFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorSuppliersFailure(
              'An error occurred fetching vendor supplier. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorSuppliersFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorDocumentApprovals =
  (vendorDocumentId: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchVendorDocumentApprovalsRequest());
      const response = await api().vendorDocumentsIdVendorDocumentApprovalsGet({
        id: vendorDocumentId,
      });
      dispatch(
        fetchVendorDocumentApprovalsSuccess({
          vendorDocumentId,
          approvals: response,
        })
      );
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorDocumentApprovalsFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorDocumentApprovalsFailure(
              'An error occurred fetching the document approvals. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorDocumentApprovalsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const fetchVendorDocumentAttestations =
  (vendorDocumentId: string): VoidThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchVendorDocumentAttestationsRequest());
      const response = await api().vendorsVendorIdVendorDocumentAttestationsGet(
        {
          vendorId: getState().vendors.currentVendor?.id,
          targetId: vendorDocumentId,
          targetType:
            VendorsVendorIdVendorDocumentAttestationsGetTargetTypeEnum.VendorDocument,
        }
      );
      dispatch(
        fetchVendorDocumentAttestationsSuccess({
          vendorDocumentId,
          attestations: response,
        })
      );
    } catch (err) {
      if (err instanceof Response) {
        console.log(
          'API error',
          `Status: ${err.status} Message: ${err.statusText}`
        );
        const apiErrorMessage = await getApiErrorMessage(err);
        if (apiErrorMessage) {
          dispatch(fetchVendorDocumentAttestationsFailure(apiErrorMessage));
        } else {
          dispatch(
            fetchVendorDocumentAttestationsFailure(
              'An error occurred fetching the document attestations. Please try again or contact support.'
            )
          );
        }
      } else {
        console.log(err);
        dispatch(fetchVendorDocumentAttestationsFailure(UNKNOWN_ERROR));
      }
    }
  };

export const postChatWidgetToken = createAsyncThunk(
  'vendors/postChatWidgetToken',
  async (_, { dispatch, getState }) => {
    const vendorId = (getState() as ApplicationState).vendors.currentVendor?.id;
    const params: VendorsIdVendorUsersChatWidgetLoginPostRequest = {
      id: vendorId,
    };
    try {
      const response =
        await api().vendorsIdVendorUsersChatWidgetLoginPost(params);
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while posting chat widget token. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchVendorUserAttestations = createAsyncThunk(
  'vendors/fetchVendorUserAttestations',
  async (vendorUserId: string, { dispatch, getState }) => {
    const vendorId = (getState() as ApplicationState).vendors.currentVendor?.id;
    try {
      const response = await api().vendorsVendorIdVendorDocumentAttestationsGet(
        {
          vendorId,
          targetId: vendorUserId,
          targetType:
            VendorsVendorIdVendorDocumentAttestationsGetTargetTypeEnum.VendorUser,
        }
      );
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching user attestations. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchComplianceUsers = createAsyncThunk(
  'vendorUsers/fetchComplianceUsers',
  async (_params, { dispatch, getState }) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        ?.id;
      const response = await api().vendorsIdVendorUsersGet({
        id: vendorId,
        includeComplianceStats: true,
        queryStatuses: [SharedVendorUserStatus.Active],
      });
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching compliance users. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);
