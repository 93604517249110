import classNames from 'classnames';
import React, { ForwardedRef } from 'react';
import Switch, { ReactSwitchProps } from 'react-switch';
import './ToggleSwitch.scss';

interface ToggleSwitchProps {
  checked: boolean;
  disabled?: boolean;
  label?: string;
  onChange: () => void;
  handlerStyle?: 'small';
  rightLabel?: string;
  switchStyles?: Partial<ReactSwitchProps>;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = props => {
  const labelClasses = classNames({
    'toggleSwitch--label': true,
    'toggleSwitch--label-disabled': props.disabled,
  });

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const switchStyles: Partial<ReactSwitchProps> =
    props.switchStyles ||
    (props.handlerStyle === 'small'
      ? {
          width: 35,
          height: 18,
          offHandleColor: '#fff',
          onColor: '#666665',
        }
      : {
          width: 30,
          height: 12,
          handleDiameter: 20,
          offHandleColor: '#999995',
          onHandleColor: '#75bc46',
        });

  return (
    <div className="toggleSwitch" onClick={handleClick}>
      <div className={labelClasses} onClick={props.onChange}>
        {props.label}
      </div>
      <div className="toggleSwitch--switch">
        <Switch
          checked={props.checked ? props.checked : false}
          checkedIcon={false}
          disabled={props.disabled}
          offColor="#d2d2d2"
          onChange={props.onChange}
          onColor="#d2d2d2"
          activeBoxShadow="transparent"
          uncheckedIcon={false}
          {...switchStyles}
        />
      </div>
      {props.rightLabel && (
        <div className={labelClasses} onClick={props.onChange}>
          {props.rightLabel}
        </div>
      )}
    </div>
  );
};
